import { Object3D } from 'three';

import { ClientSideWorkbenchElementData } from '../../../lib/clientState';
import { getElementSize } from '../../helpers';
import { filterChildByWorkbenchElementUserData } from '../../objectsUserdata';

const EPSILON = 1e-10; // Small number for floating point comparisons

/**
 * Performs a "fuzzy" floating point comparison
 * @param a - First number to compare
 * @param b - Second number to compare
 * @param epsilon - Tolerance for comparison (defaults to EPSILON)
 * @returns Whether the numbers are approximately equal
 */
const approximately = (a: number, b: number, epsilon = EPSILON): boolean => {
  return Math.abs(a - b) < epsilon;
};

/**
 * Checks if a value is greater than or approximately equal to another
 */
const greaterThanOrApproximatelyEqual = (
  a: number,
  b: number,
  epsilon = EPSILON
): boolean => {
  return a > b || approximately(a, b, epsilon);
};

/**
 * Checks if a value is less than or approximately equal to another
 */
const lessThanOrApproximatelyEqual = (
  a: number,
  b: number,
  epsilon = EPSILON
): boolean => {
  return a < b || approximately(a, b, epsilon);
};

/**
 * Represents the boundaries of an element or section
 */
interface Bounds {
  minX: number;
  maxX: number;
  minY: number;
  maxY: number;
}

/**
 * Calculates the bounds for an element based on its position and dimensions
 */
const calculateElementBounds = (
  position: { x: number; y: number },
  dimensions: { elementWidth: number; elementHeight: number }
): Bounds => {
  return {
    minX: position.x - dimensions.elementWidth / 2,
    maxX: position.x + dimensions.elementWidth / 2,
    minY: position.y - dimensions.elementHeight / 2,
    maxY: position.y + dimensions.elementHeight / 2,
  };
};

/**
 * Calculates the bounds for a section based on its data
 */
const calculateSectionBounds = (
  sectionData: ClientSideWorkbenchElementData
): Bounds => {
  const size = getElementSize(sectionData);
  return {
    minX: sectionData.x - size.width / 2,
    maxX: sectionData.x + size.width / 2,
    minY: sectionData.y - size.height / 2,
    maxY: sectionData.y + size.height / 2,
  };
};

/**
 * Checks if two bounds intersect, using fuzzy comparison for stability
 */
const doBoundsIntersect = (bounds1: Bounds, bounds2: Bounds): boolean => {
  return (
    lessThanOrApproximatelyEqual(bounds1.maxX, bounds2.maxX) &&
    greaterThanOrApproximatelyEqual(bounds1.minX, bounds2.minX) &&
    lessThanOrApproximatelyEqual(bounds1.maxY, bounds2.maxY) &&
    greaterThanOrApproximatelyEqual(bounds1.minY, bounds2.minY)
  );
};

/**
 * Retrieves the section elements that intersect with the given section data.
 * Uses fuzzy comparison to handle floating point precision issues.
 * @param sectionData - The section data containing x, y, width, and height.
 * @returns An array of section elements that intersect with the given section data.
 */
export const getSectionElements = (
  scene: Object3D,
  sectionData: ClientSideWorkbenchElementData
) => {
  const objects = filterChildByWorkbenchElementUserData(scene, () => true);
  const sectionBounds = calculateSectionBounds(sectionData);

  return objects.filter((el) => {
    if (el.userData.elementId === sectionData.id) {
      return false;
    }

    const elementBounds = calculateElementBounds(el.position, el.userData);

    return doBoundsIntersect(elementBounds, sectionBounds);
  });
};

import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { useDrawingMetadata } from '@vizcom/shared/data-access/graphql';
import {
  CloseIcon,
  CopyPromptIcon,
  FormattedDate,
  InfoIcon,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  Text,
  addToast,
} from '@vizcom/shared-ui-components';

import { ClientSideWorkbenchElementDrawing } from '../../../lib/clientState';
import {
  VersionTag,
  getPaletteVersion,
  useAvailablePalettes,
} from '../../modals/PaletteSelector/PaletteSelector';
import { VersionedPalette } from '../../modals/PaletteSelector/PaletteVersionsMenu';
import { CustomHtml } from '../../utils/CustomHtml';

const METADATA_WIDTH = 350;
const METADATA_HEIGHT = 135;
const MAX_CONTENT_LENGTH = 94;

export const DrawingMetadata = ({
  workbenchId,
  drawing,
  width,
  height,
  toggleMetadata,
}: {
  workbenchId: string;
  drawing: ClientSideWorkbenchElementDrawing;
  width: number;
  height: number;
  toggleMetadata: () => void;
}) => {
  const [showMore, setShowMore] = useState(false);
  const { data } = useDrawingMetadata(drawing.id);
  const availablePalettes = useAvailablePalettes(workbenchId);
  const theme = useTheme();

  if (!data?.prompts.nodes[0]) {
    return null;
  }

  const {
    publicPaletteId,
    customModelId,
    workbenchPaletteId,
    userPaletteId,
    text,
    imageInferenceType,
    sourceImageInfluence,
    creator,
  } = data.prompts.nodes[0] ?? {};

  const selectedPaletteId =
    publicPaletteId ?? customModelId ?? workbenchPaletteId ?? userPaletteId;

  const palette =
    availablePalettes.find((p) => p.value === selectedPaletteId) ||
    availablePalettes.find(
      (palette) =>
        palette.versions &&
        palette.versions.find((v) => v.value === selectedPaletteId)
    );

  const version =
    selectedPaletteId &&
    palette?.versions &&
    palette?.versions.length > 0 &&
    getPaletteVersion(palette as VersionedPalette, selectedPaletteId);

  return (
    <CustomHtml
      transform
      position={[0, -height / 2 - 1, 0.5]}
      style={{
        background: 'rgba(0, 0, 0, 0)',
        transform: `translateY(50%)`,
        width,
        height,
      }}
    >
      <Wrapper>
        <Heading>
          <Title>
            <Text type="sh1">Image Details</Text>
            <RichTooltip trigger="hover" placement="bottom">
              <RichTooltipTrigger>
                <InfoIcon $size="S" />
              </RichTooltipTrigger>
              <RichTooltipContent style={{ color: theme.deprecated.white }}>
                This is the latest prompt
                <br />
                associated with the image and
                <br />
                may not reflect its current state.
                <br />
                Open image in 2D studio to see the latest details.
              </RichTooltipContent>
            </RichTooltip>
          </Title>
          <CloseIconStyled onClick={() => toggleMetadata()} />
        </Heading>
        <Prompt>
          <PromptText $showMore={showMore}>
            <Text type="b1" color="bodyInverted">
              {text}
            </Text>
            {text.length > MAX_CONTENT_LENGTH && (
              <ShowMoreButton onClick={() => setShowMore(!showMore)}>
                {showMore ? 'Show less' : 'Show more'}
              </ShowMoreButton>
            )}
          </PromptText>
          <CopyIconStyled
            onClick={() => {
              navigator.clipboard.writeText(text);
              addToast('Prompt copied to clipboard');
            }}
          />
        </Prompt>
        <Section>
          <Detail>
            <Text type="b1" color="subtext">
              Mode
            </Text>
            <Text type="sh2" color="bodyInverted">
              {imageInferenceType === 'RENDER' ? 'Render' : 'Refine'}
            </Text>
          </Detail>
          <Detail>
            <Text type="b1" color="subtext">
              Drawing influence
            </Text>
            <Text type="sh2" color="bodyInverted">
              {(sourceImageInfluence ?? 0) * 100}%
            </Text>
          </Detail>
          <Detail>
            <Text type="b1" color="subtext">
              Palette
            </Text>
            <div>
              <ThumbnailWrapper>
                {palette?.thumbnailPath && (
                  <Thumbnail src={palette.thumbnailPath} alt={palette.name} />
                )}
              </ThumbnailWrapper>
              <Text type="sh2" color="bodyInverted">
                {palette?.name}
              </Text>
              {version && <VTag>v{version}</VTag>}
            </div>
          </Detail>
        </Section>
        <Text type="b1" color="subtext">
          {creator?.name && `${creator?.name}, `}
          <FormattedDate date={data?.createdAt} />
        </Text>
      </Wrapper>
    </CustomHtml>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;

  width: ${METADATA_WIDTH}px;
  min-height: ${METADATA_HEIGHT}px;
  padding: 10px;
  border-radius: ${({ theme: { borderRadius } }) =>
    `0 0 ${borderRadius.m} ${borderRadius.m}`};

  background: #ebebef; // this color isn't present in the theme
  box-shadow: 0px 4px 14px 2px rgba(0, 0, 0, 0.25);
  font-size: 12px;
`;

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.text.subtext};
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Section = styled.div`
  background: #f5f5f6;
  padding: 8px;
  border-radius: ${({ theme }) => theme.borderRadius.s};
  min-width: 0;
  display: flex;
  gap: 12px;
  flex-direction: column;
`;

const Prompt = styled(Section)`
  grid-area: 1 / 1 / 1 / 3;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 12px;
  gap: 8px;
  justify-content: space-between;

  > svg {
    height: 12px;
    width: 12px;
  }
`;

const PromptText = styled.div<{ $showMore: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > span {
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${({ $showMore }) => ($showMore ? 'unset' : 2)};
    line-clamp: ${({ $showMore }) => ($showMore ? 'unset' : 2)};
    word-break: break-word;
    overflow: hidden;
    width: 100%;
  }
`;

const Detail = styled(Section)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0px;

  > div {
    display: flex;
    gap: 4px;
    align-items: center;
  }
`;

const CopyIconStyled = styled(CopyPromptIcon)`
  cursor: pointer;
`;

const CloseIconStyled = styled(CloseIcon)`
  cursor: pointer;
  margin-left: 16px;
  color: ${({ theme }) => theme.text.subtext};
`;

const VTag = styled(VersionTag)`
  max-width: 20px;
  background: ${({ theme }) => theme.surface.pagePattern};
`;

const ShowMoreButton = styled.button`
  padding: 0;
  display: inline-block;
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.button.primary};
  cursor: pointer;
`;

const ThumbnailWrapper = styled.div`
  width: 24px;
  height: 24px;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.borderRadius.s};
  flex-shrink: 0;
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

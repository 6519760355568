import styled from 'styled-components';
import { Text } from '@vizcom/shared-ui-components';

export const Container = styled.div<{
  $isDragging: boolean;
  $isLocked: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  padding: ${({ theme }) => theme.spacing.s};
  padding-top: 32px;
  background: ${({ theme }) => theme.surface.primary};
  border-radius: 12px;
  position: ${({ $isLocked }) => ($isLocked ? 'relative' : 'fixed')};
  box-shadow: ${({ theme, $isDragging }) =>
    $isDragging
      ? '0 8px 32px rgba(0, 0, 0, 0.12)'
      : '0 4px 24px rgba(0, 0, 0, 0.08)'};
  z-index: 10000001;
  overflow: hidden;
  resize: ${({ $isLocked }) => ($isLocked ? 'none' : 'both')};
  width: ${({ $isLocked }) => ($isLocked ? '100%' : 'auto')};
  min-width: 240px;
  min-height: 300px;
  max-width: ${({ $isLocked }) => ($isLocked ? '100%' : '800px')};
  max-height: 800px;
  transform-origin: top left;
  will-change: ${({ $isDragging }) => ($isDragging ? 'transform' : 'auto')};
  touch-action: none;
  pointer-events: all;
  opacity: ${({ $isDragging }) => ($isDragging ? 0.92 : 1)};
  cursor: ${({ $isDragging, $isLocked }) =>
    $isDragging ? 'grabbing' : $isLocked ? 'default' : 'auto'};
  user-select: none;
  left: ${({ $isLocked }) => ($isLocked ? 'auto' : 'inherit')};
  top: ${({ $isLocked }) => ($isLocked ? 'auto' : 'inherit')};
  margin: ${({ $isLocked }) => ($isLocked ? '0' : 'initial')};
  transition: ${({ $isDragging }) =>
    $isDragging ? 'none' : 'box-shadow 0.2s ease, opacity 0.2s ease'};
  backdrop-filter: blur(8px);
  border: 2px solid transparent;
  animation: ${({ $isLocked }) =>
    !$isLocked ? 'unlockPulse 2s ease-in-out' : 'none'};

  @keyframes unlockPulse {
    0% {
      border-color: transparent;
    }
    50% {
      border-color: ${({ theme }) => `${theme.border.action}`};
    }
    100% {
      border-color: transparent;
    }
  }

  &:hover {
    box-shadow: ${({ theme, $isDragging }) =>
      $isDragging
        ? '0 8px 32px rgba(0, 0, 0, 0.12)'
        : '0 6px 28px rgba(0, 0, 0, 0.1)'};
  }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: ${({ theme }) => theme.spacing.m};
    height: ${({ theme }) => theme.spacing.m};
    cursor: nwse-resize;
    background: transparent;
    display: ${({ $isLocked }) => ($isLocked ? 'none' : 'block')};
  }
`;

export const DragHandle = styled.div<{ $isLocked: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  transition: background-color 0.2s ease;
  backdrop-filter: blur(8px);
  background: ${({ theme }) => `${theme.surface.primary}CC`};
  padding: 8px 12px;
  height: 40px;

  &:hover {
    background-color: ${({ theme }) => theme.surface.secondary}11;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ControlsRow = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
`;

export const ActionControls = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
  width: 100%;
`;

export const InfluenceControls = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

export const InfluenceControl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  padding: 0px 8px;
  border-radius: 10px;
  background: ${({ theme }) => theme.surface.secondary}11;
  box-sizing: border-box;
`;

export const InfluenceLabel = styled(Text)`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.text.body};
  opacity: 0.9;
  padding-left: 8px;
`;

export const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 12px;

  > div:first-child {
    flex: 1;
  }
`;

export const PreviewContainer = styled.div<{ $isLocked: boolean }>`
  position: relative;
  flex: 1;
  min-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 8px;
  }
`;

import { isNumber } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import {
  OrganizationSubscriptionData,
  useCreateOrganizationSubscriptionManagementPortal,
  OrganizationSubscriptionPlan,
} from '@vizcom/shared/data-access/graphql';
import { capitalize } from '@vizcom/shared/js-utils';
import { BillingInterval } from '@vizcom/shared/plans-limit';
import {
  Button,
  Chip,
  Divider,
  FormattedDate,
  InlineFlex,
  LoadingLogoInset,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  Surface,
  Text,
  WarningIcon,
  addToast,
  formatErrorMessage,
  IncomingPlanChangesMessage,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

import { SeatsIndicator } from './SeatsIndicator';

export const SubscriptionInfosCard = ({
  subscriptionData,
  organizationId,
  openSubscriptionUpdateModal = false,
}: {
  subscriptionData: OrganizationSubscriptionData;
  organizationId: string;
  openSubscriptionUpdateModal: boolean;
}) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const { subscription } = subscriptionData;

  const [res, createPortal] =
    useCreateOrganizationSubscriptionManagementPortal();

  const goingMonthly =
    subscription.nextPhaseSchedule?.interval &&
    subscription.billingInterval === BillingInterval.Year &&
    subscription.billingInterval !== subscription.nextPhaseSchedule.interval;

  const handleManageSubscription = async () => {
    const res = await createPortal({
      input: {
        organizationId,
      },
    });

    if (res.error || !res.data) {
      return addToast('Error while changing payment method', {
        secondaryText: formatErrorMessage(res.error),
        type: 'danger',
      });
    }

    window.location.assign(
      res.data.createOrganizationSubscriptionManagementPortal.url
    );
  };

  return (
    <Surface
      style={{ padding: 24, display: 'flex', gap: 10, flexDirection: 'column' }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Text block type="sh1">
            {capitalize(subscriptionData.subscriptionPlan)} Plan
          </Text>
          <SubscriptionStatusChip subscription={subscriptionData} />
        </div>
        {subscriptionData.subscriptionPlan !==
          OrganizationSubscriptionPlan.Free &&
          subscriptionData.subscriptionPlan !==
            OrganizationSubscriptionPlan.Edu &&
          !subscriptionData.manualSubscription && (
            <Button variant="secondary" onClick={handleManageSubscription}>
              Manage plan
            </Button>
          )}
      </div>

      {isNumber(subscriptionData.subscription.subscriptionBaseAmount) && (
        <InlineFlex $alignItems="baseline">
          <Text type="h1" style={{ marginRight: 5 }}>
            ${subscriptionData.subscription.subscriptionBaseAmount}
          </Text>
          <InlineFlex $gap={5}>
            {subscriptionData.subscription.billingInterval && (
              <Text color="subtext">
                /{subscriptionData.subscription.billingInterval}
              </Text>
            )}

            {goingMonthly && (
              <RichTooltip trigger="hover" placement="bottom">
                <RichTooltipTrigger>
                  <WarningIcon color={theme.surface.warning} />
                </RichTooltipTrigger>
                <RichTooltipContent $background="tertiary">
                  <IncomingPlanChangesMessage
                    organizationSubscription={subscriptionData}
                  />
                </RichTooltipContent>
              </RichTooltip>
            )}
          </InlineFlex>
        </InlineFlex>
      )}

      <Divider />

      <SeatsIndicator
        organizationSubscription={subscriptionData}
        isSubscriptionUpdateModalOpen={openSubscriptionUpdateModal}
        setIsSubscriptionUpdateModalOpen={(open) =>
          open
            ? navigate(
                paths.settings.organization.subscriptionUpdate(organizationId)
              )
            : navigate(paths.settings.organization.subscription(organizationId))
        }
      />
      <LoadingLogoInset active={res.fetching} />
    </Surface>
  );
};

const SubscriptionStatusChip = ({
  subscription,
}: {
  subscription: OrganizationSubscriptionData;
}) => {
  const isExpiring = subscription.subscription.cancelAtPeriodEnd;
  const currentPeriodEnd = subscription.subscription.currentPeriodEnd;
  const currentPeriodEndDate = new Date(
    currentPeriodEnd ? currentPeriodEnd * 1000 : 0
  );

  return isExpiring && currentPeriodEnd ? (
    <Chip variant="danger" style={{ marginLeft: 10 }}>
      EXPIRES{' '}
      <FormattedDate
        date={currentPeriodEndDate.toString()}
        format="MM/dd/yyyy"
      />
    </Chip>
  ) : subscription.subscription.status === 'past_due' ? (
    <Chip variant="warning" style={{ marginLeft: 10 }}>
      Past due
    </Chip>
  ) : (
    <Chip variant="primary" style={{ marginLeft: 10 }}>
      Active
    </Chip>
  );
};

import { GroupProps, useFrame } from '@react-three/fiber';
import { useRef } from 'react';
import { Group } from 'three';

import { vector3LikeToComponentsArray } from '../helpers';

// Similar to a <group> but reacts to the camera zoom to always keep the same size on screen
export const FixedSizeGroup = (
  props: GroupProps & {
    fixedSizeInYDirection?: boolean;
    fixedSizeInXDirection?: boolean;
    maxScale?: number;
  }
) => {
  const ref = useRef<Group>(null!);
  const lastZoomRef = useRef(null as null | number);

  const {
    scale,
    fixedSizeInYDirection = true,
    fixedSizeInXDirection = true,
    maxScale = Infinity,
    ...otherProps
  } = props;

  useFrame((s) => {
    const zoom = s.camera.zoom;
    if (zoom !== lastZoomRef.current) {
      lastZoomRef.current = zoom;
      const scaleComponents = vector3LikeToComponentsArray(scale ?? 1);
      ref.current.scale.set(
        Math.min(
          maxScale,
          fixedSizeInXDirection ? scaleComponents[0] / zoom : scaleComponents[0]
        ),
        Math.min(
          maxScale,
          fixedSizeInYDirection ? scaleComponents[1] / zoom : scaleComponents[1]
        ),
        scaleComponents[2]
      );
    }
  });

  return <group ref={ref} {...otherProps} />;
};

import { UserBasicDataFragment } from 'libs/shared/data-access/graphql/src/gql/graphql';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { getColorFromHash } from '@vizcom/shared-ui-components';

import cameraImage from './publicFileSharing/assets/camera.jpeg';
import carImage from './publicFileSharing/assets/car.jpeg';
import chairImage from './publicFileSharing/assets/chair.jpeg';
import chemexImage from './publicFileSharing/assets/chemex.jpeg';
import glassesImage from './publicFileSharing/assets/glasses.jpeg';
import hairdryerImage from './publicFileSharing/assets/hair-dryer.jpeg';
import handbagImage from './publicFileSharing/assets/handbag.jpeg';
import headphonesImage from './publicFileSharing/assets/headphones.jpeg';
import juicerImage from './publicFileSharing/assets/juicer.jpeg';
import lampImage from './publicFileSharing/assets/lamp.jpeg';
import shoeImage from './publicFileSharing/assets/shoe.jpeg';
import speakerImage from './publicFileSharing/assets/speaker.jpeg';
import telephoneImage from './publicFileSharing/assets/telephone.jpeg';
import toasterImage from './publicFileSharing/assets/toaster.jpeg';

export type MultiplayerDisplayConfig = {
  name: string;
  short: string;
  color: string;
  image?: string;
};

const ANONYMOUS_ADJECTIVES = [
  'Adventurous',
  'Brilliant',
  'Charming',
  'Dazzling',
  'Elegant',
  'Fierce',
  'Gentle',
  'Harmonious',
  'Inquisitive',
  'Joyful',
  'Luminous',
  'Majestic',
  'Nurturing',
  'Opulent',
  'Peaceful',
  'Radiant',
  'Serene',
  'Vibrant',
];

const ANONYMOUS_CONFIG: MultiplayerDisplayConfig[] = [
  {
    name: 'lamp',
    short: 'L',
    color: '#EBBF54',
    image: lampImage,
  },
  {
    name: 'chair',
    short: 'C',
    color: '#075B5B',
    image: chairImage,
  },
  {
    name: 'juicer',
    short: 'J',
    color: '#2096AF',
    image: juicerImage,
  },
  {
    name: 'coffee machine',
    short: 'CM',
    color: '#E53E80',
    image: chemexImage,
  },
  {
    name: 'camera',
    short: 'C',
    color: '#646FD8',
    image: cameraImage,
  },
  {
    name: 'telephone',
    short: 'T',
    color: '#7DAC71',
    image: telephoneImage,
  },
  {
    name: 'toaster',
    short: 'T',
    color: '#CA8C3A',
    image: toasterImage,
  },
  {
    name: 'hairdryer',
    short: 'H',
    color: '#E94733',
    image: hairdryerImage,
  },
  {
    name: 'headphones',
    short: 'H',
    color: '#4451BA',
    image: headphonesImage,
  },
  {
    name: 'shoe',
    short: 'S',
    color: '#5FB3CF',
    image: shoeImage,
  },
  {
    name: 'glasses',
    short: 'G',
    color: '#7A866E',
    image: glassesImage,
  },
  {
    name: 'speaker',
    short: 'S',
    color: '#168B9A',
    image: speakerImage,
  },
  {
    name: 'handbag',
    short: 'H',
    color: '#757A63',
    image: handbagImage,
  },
  {
    name: 'car',
    short: 'C',
    color: '#B44333',
    image: carImage,
  },
];

// Gets the userId or the userId from the clientId
export function getMultiplayerUserId(clientId: string): string {
  return clientId.split('/')[0];
}

export function useMultiplayerDisplayConfig(
  hash: number = 0,
  user?: UserBasicDataFragment | null
): MultiplayerDisplayConfig {
  const theme = useTheme();

  return useMemo(() => {
    if (!user) {
      const anonymousProfileIndex =
        hash % (ANONYMOUS_CONFIG.length * ANONYMOUS_ADJECTIVES.length);
      const config =
        ANONYMOUS_CONFIG[anonymousProfileIndex % ANONYMOUS_CONFIG.length];
      const adjective =
        ANONYMOUS_ADJECTIVES[
          Math.floor(anonymousProfileIndex / ANONYMOUS_CONFIG.length)
        ];

      return {
        ...config,
        name: `${adjective} ${config.name}`,
      };
    }

    const color = getColorFromHash(theme, hash);
    const name = user.name || user.email;

    return {
      name,
      color,
      short: name[0].toUpperCase(),
      image: undefined,
    };
  }, [hash, user, theme]);
}

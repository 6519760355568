import { PropsWithChildren, ReactNode } from 'react';
import { useTheme } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import {
  Button,
  ContextMenu,
  ContextMenuDivider,
  DuplicateIcon,
  MenuHorizontalIcon,
  Tooltip,
  TrashIcon,
  Toolbar,
  ToolbarDivider,
} from '@vizcom/shared-ui-components';

import { ClientSideWorkbenchElementData } from '../lib/clientState';
import { useWorkbenchSyncedState } from '../lib/useWorkbenchSyncedState';
import { useIsWorkbenchViewer } from '../lib/utils';
import { CustomHtml } from './utils/CustomHtml';
import { ViewportFlipGroup } from './utils/ViewportFlipGroup';
import { SharedContextMenuItems } from './workbenchContextMenu/contextMenuItemsPerType/Shared';

type WorkbenchElementExtraProps = PropsWithChildren<{
  workbenchId: string;
  element: ClientSideWorkbenchElementData;
  menuItems?: ReactNode;
  position: [number, number, number];
  pivot: number;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
}>;

export const WorkbenchElementExtra = ({
  workbenchId,
  children,
  element,
  menuItems,
  position,
  pivot,
  handleAction,
}: WorkbenchElementExtraProps) => {
  const isViewer = useIsWorkbenchViewer();

  return (
    <ViewportFlipGroup position={position} pivot={pivot}>
      {({ flipped }) => (
        <CustomHtml
          style={{
            pointerEvents: 'none',
          }}
        >
          <Toolbar
            position={flipped ? 'centered-below' : 'centered-above'}
            onPointerDown={(e) => e.stopPropagation()}
            onPointerEnter={(e) => e.stopPropagation()}
            onPointerLeave={(e) => e.stopPropagation()}
          >
            {children}

            {children && !isViewer && <ToolbarDivider />}

            {!isViewer && (
              <ContextMenu
                buttonProps={{
                  variant: 'secondary',
                  size: 'icon',
                }}
                items={
                  <>
                    {menuItems ? (
                      <>
                        {menuItems}
                        <ContextMenuDivider />
                      </>
                    ) : null}
                    <SharedContextMenuItems
                      workbenchId={workbenchId}
                      elements={[element]}
                      handleAction={handleAction}
                    />
                  </>
                }
              >
                <MenuHorizontalIcon />
              </ContextMenu>
            )}
          </Toolbar>
        </CustomHtml>
      )}
    </ViewportFlipGroup>
  );
};

type WorkbenchElementExtraDuplicateProps = {
  id: string;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
};

export const WorkbenchElementExtraDuplicate = ({
  id,
  handleAction,
}: WorkbenchElementExtraDuplicateProps) => {
  const theme = useTheme();
  return (
    <Tooltip tip="Duplicate" position="top">
      <Button
        size="icon"
        variant="tertiary"
        onClick={(e) => {
          e.stopPropagation();

          handleAction({
            type: 'duplicateElements',
            elementIds: [id],
            newElementIds: [uuidv4()],
          });
        }}
      >
        <DuplicateIcon
          style={{ color: theme.icon.primary, width: '24px', height: '24px' }}
        />
      </Button>
    </Tooltip>
  );
};

type WorkbenchElementExtraDeleteProps = {
  id: string;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
};

export const WorkbenchElementExtraDelete = ({
  id,
  handleAction,
}: WorkbenchElementExtraDeleteProps) => {
  const theme = useTheme();
  return (
    <Tooltip tip="Delete" position="top">
      <Button
        size="icon"
        variant="tertiary"
        onClick={(e) => {
          e.stopPropagation();
          handleAction({
            type: 'deleteElements',
            elementIds: [id],
          });
        }}
      >
        <TrashIcon
          style={{ color: theme.icon.primary, width: '24px', height: '24px' }}
        />
      </Button>
    </Tooltip>
  );
};

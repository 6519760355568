import { useState } from 'react';
import styled from 'styled-components';
import {
  BrushIcon,
  Button,
  CarretDownIcon,
  CarretUpIcon,
  Divider,
  PaintBucketIcon,
  Text,
  ToolbarButtonState,
} from '@vizcom/shared-ui-components';

import {
  useWorkbenchStudioState,
  WorkbenchStudioToolType,
} from '../../studioState';
import { ToolbarIcon } from '../ToolbarIcon';
import { ToolbarSlider } from '../ToolbarSlider';
import { BrushSettings } from './BrushSettings';

export const BrushMenu = () => {
  const [showSettings, setShowSettings] = useState(false);

  const { tool, setTool, getToolSettings } = useWorkbenchStudioState();
  const { toolSize, toolOpacity, setToolSize, setToolOpacity } =
    getToolSettings();

  return (
    <Container>
      <IconContainer>
        <ToolbarIcon
          $toolState={
            tool === WorkbenchStudioToolType.Brush
              ? ToolbarButtonState.ACTIVE
              : ToolbarButtonState.INACTIVE
          }
          onClick={() => setTool(WorkbenchStudioToolType.Brush)}
          icon={<BrushIcon />}
        />
        <ToolbarIcon
          $toolState={
            tool === WorkbenchStudioToolType.PaintBucket
              ? ToolbarButtonState.ACTIVE
              : ToolbarButtonState.INACTIVE
          }
          onClick={() => setTool(WorkbenchStudioToolType.PaintBucket)}
          icon={<PaintBucketIcon />}
        />
      </IconContainer>

      {tool === WorkbenchStudioToolType.Brush && (
        <>
          <Divider />

          <ToolbarSlider
            label="Size"
            style={{ flexGrow: 1 }}
            value={toolSize}
            setValue={setToolSize}
            min={1}
            max={250}
            step={1}
            unit="px"
            isLogScale
          />

          <ToolbarSlider
            label="Opacity"
            style={{ flexGrow: 1 }}
            value={toolOpacity}
            setValue={setToolOpacity}
            min={0}
            max={1}
            step={0.01}
            unit="%"
            multiplier={100}
          />

          <Divider />
          <MenuRow
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              setShowSettings((prev) => !prev);
            }}
          >
            <Text color="subtext" type="b1">
              Advanced settings
            </Text>
            <Button variant="tertiary" size="S">
              {showSettings ? (
                <CarretUpIcon $size="M" $color="secondary" />
              ) : (
                <CarretDownIcon $size="M" $color="secondary" />
              )}
            </Button>
          </MenuRow>
          {showSettings && <BrushSettings />}
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: auto;
  min-width: 176px;

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const IconContainer = styled.div`
  display: flex;
  gap: 4px;
  padding-bottom: 2px;
`;

const MenuRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

import { useState, useCallback } from 'react';

import { ResourceManager } from '../performance';
import { HistoryEntry } from './useRealtimeHistory';

/**
 * Error types that can occur during video export
 */
export type VideoExportErrorType = 'BROWSER_SUPPORT' | 'EXPORT_ERROR';

/**
 * Structure of video export errors
 */
export interface VideoExportError {
  message: string;
  type: VideoExportErrorType;
}

/**
 * Configuration options for video export
 */
export interface VideoExportOptions {
  /**
   * Frames per second for the exported video
   * @default 30
   */
  fps?: number;
  /**
   * Video bitrate in bits per second
   * @default 8000000 (8 Mbps)
   */
  videoBitsPerSecond?: number;
}

/**
 * Checks if the browser supports required video export capabilities
 * @returns boolean indicating whether video export is supported
 */
const hasVideoExportSupport = (): boolean => {
  const canvas = document.createElement('canvas');
  return !!(
    window.MediaRecorder &&
    typeof canvas.captureStream === 'function' &&
    MediaRecorder.isTypeSupported('video/webm;codecs=vp9')
  );
};

/**
 * Hook for exporting canvas history as video files.
 * Provides functionality to create WebM videos from a sequence of canvas states.
 *
 * @param resourceManager - Resource manager instance for canvas and blob management
 * @returns Object containing video export functions and state
 */
export const useVideoExport = (resourceManager: ResourceManager) => {
  const [isExporting, setIsExporting] = useState(false);
  const [exportProgress, setExportProgress] = useState(0);
  const [exportError, setExportError] = useState<VideoExportError | null>(null);

  /**
   * Creates a video from a sequence of history entries
   * @param history - Array of history entries to convert to video
   * @param width - Width of the video in pixels
   * @param height - Height of the video in pixels
   * @param fps - Frames per second (default: 30)
   * @returns Promise resolving to a video Blob or null if export fails
   */
  const createVideoFromHistory = useCallback(
    async (
      history: HistoryEntry[],
      width: number,
      height: number,
      fps: number = 30
    ): Promise<Blob | null> => {
      if (history.length === 0) {
        setExportError({
          message: 'No history entries to export',
          type: 'EXPORT_ERROR',
        });
        return null;
      }

      // Check browser support
      if (!hasVideoExportSupport()) {
        setExportError({
          message:
            'Your browser does not support video export. Please try Chrome, Edge, or Firefox.',
          type: 'BROWSER_SUPPORT',
        });
        return null;
      }

      setIsExporting(true);
      setExportProgress(0);
      setExportError(null);

      try {
        const canvas = resourceManager.acquireCanvas(width, height);
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          throw new Error('Could not get 2D context');
        }

        const chunks: Blob[] = [];
        const mediaRecorder = new MediaRecorder(canvas.captureStream(fps), {
          mimeType: 'video/webm;codecs=vp9',
          videoBitsPerSecond: 8000000, // 8 Mbps
        });

        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            chunks.push(event.data);
          }
        };

        const totalFrames = history.length;
        const frameInterval = 1000 / fps;

        return new Promise((resolve, reject) => {
          mediaRecorder.onstop = () => {
            const finalBlob = new Blob(chunks, { type: 'video/webm' });
            setIsExporting(false);
            setExportProgress(100);
            resourceManager.releaseCanvas(canvas);
            resolve(finalBlob);
          };

          mediaRecorder.onerror = (event) => {
            setExportError({
              message: 'Error during video export',
              type: 'EXPORT_ERROR',
            });
            setIsExporting(false);
            resourceManager.releaseCanvas(canvas);

            const error = new Error('Error during video export');
            error.name = 'VideoExportError';
            reject(error);
          };

          mediaRecorder.start();

          // Helper function to load an image and draw it to canvas
          const loadAndDrawImage = (entry: HistoryEntry): Promise<void> => {
            return new Promise((resolve, reject) => {
              const img = new Image();
              img.onload = () => {
                ctx.clearRect(0, 0, width, height);

                // Calculate scaling to fit the image while maintaining aspect ratio
                const scale = Math.min(width / img.width, height / img.height);

                // Calculate position to center the image
                const x = (width - img.width * scale) / 2;
                const y = (height - img.height * scale) / 2;

                // Draw the scaled and centered image
                ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
                resolve();
              };
              img.onerror = () => {
                reject(new Error('Failed to load frame image'));
              };
              img.src = entry.objectUrl;
            });
          };

          // Process frames sequentially with proper timing
          const processFrames = async () => {
            const startTime = performance.now();

            for (let i = 0; i < totalFrames; i++) {
              const targetTime = startTime + i * frameInterval;
              const currentTime = performance.now();
              const timeToWait = Math.max(0, targetTime - currentTime);

              if (timeToWait > 0) {
                await new Promise((resolve) => setTimeout(resolve, timeToWait));
              }

              try {
                await loadAndDrawImage(history[i]);
                setExportProgress((i / totalFrames) * 100);
              } catch (error) {
                setExportError({
                  message: 'Failed to load frame image',
                  type: 'EXPORT_ERROR',
                });
                mediaRecorder.stop();
                return;
              }
            }

            mediaRecorder.stop();
          };

          // Start processing frames
          processFrames().catch((error) => {
            setExportError({
              message: error.message || 'Error during video export',
              type: 'EXPORT_ERROR',
            });
            mediaRecorder.stop();
          });
        });
      } catch (error) {
        setExportError({
          message: error instanceof Error ? error.message : 'Export failed',
          type: 'EXPORT_ERROR',
        });
        setIsExporting(false);
        return null;
      }
    },
    [resourceManager]
  );

  return {
    createVideoFromHistory,
    isExporting,
    exportProgress,
    exportError,
    isSupported: hasVideoExportSupport(),
  };
};

import { useFrame } from '@react-three/fiber';
import { PropsWithChildren, useRef } from 'react';
import { Color, Material, Object3D } from 'three';
import { VizcomLogoSvgAsText } from '@vizcom/shared-ui-components';

import { isMeshBasicMaterial } from '../helpers';
import { StaticSvg } from './StaticSvg';

export const LoadingPlaceholder = (
  props: PropsWithChildren<{
    scale?: number | [number, number];
    backdropOpacity?: number;
    backdropColor?: Color | string;
  }>
) => {
  const logoRef = useRef<Object3D>(null!);
  useFrame(() => {
    logoRef.current.traverse((obj) => {
      const material = 'material' in obj && (obj.material as Material);
      if (isMeshBasicMaterial(material)) {
        material.transparent = true;
        material.opacity = Math.sin(Date.now() / 300) * 0.5 + 0.5;
      }
    });
  });

  const scale = Array.isArray(props.scale)
    ? ([...props.scale, 1] as any)
    : [props.scale, props.scale, 1];

  return (
    <group>
      <mesh scale={scale}>
        <planeGeometry args={[1, 1, 1, 1]} />
        <meshBasicMaterial
          color={props.backdropColor ?? 0xbbbbbb}
          transparent
          opacity={props.backdropOpacity ? 0.3 : 1}
        />
      </mesh>
      <StaticSvg
        ref={logoRef}
        svg={VizcomLogoSvgAsText}
        scale={[scale[0] / 100, scale[0] / 100, 1]}
        strokeMaterial={{ color: 0xffffff }}
      />
      {props.children}
    </group>
  );
};

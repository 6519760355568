import React from 'react';

import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../../lib/useDrawingSyncedState';
import { RealtimeWindowContainer } from './components/RealtimeWindowContainer';
import { RealtimeWindowContent } from './components/RealtimeWindowContent';

interface RealtimeWindowProps {
  prompt: string;
  drawing: Drawing2dStudio;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  setActiveLayerId: (id: string | undefined) => void;
  paletteInfluence: number;
  onPaletteInfluenceChange: (value: number) => void;
  sourceImageInfluence: number;
  onSourceImageInfluenceChange: (value: number) => void;
  mode: 'FAST' | 'MEDIUM' | 'SLOW';
  onModeChange: (mode: 'FAST' | 'MEDIUM' | 'SLOW') => void;
}

export const RealtimeWindow: React.FC<RealtimeWindowProps> = ({
  prompt,
  drawing,
  handleAction,
  setActiveLayerId,
  paletteInfluence,
  onPaletteInfluenceChange,
  sourceImageInfluence,
  onSourceImageInfluenceChange,
  mode,
  onModeChange,
}) => {
  return (
    <RealtimeWindowContainer
      prompt={prompt}
      drawing={drawing}
      handleAction={handleAction}
      setActiveLayerId={setActiveLayerId}
      paletteInfluence={paletteInfluence}
      onPaletteInfluenceChange={onPaletteInfluenceChange}
      sourceImageInfluence={sourceImageInfluence}
      onSourceImageInfluenceChange={onSourceImageInfluenceChange}
      mode={mode}
      onModeChange={onModeChange}
    >
      {(containerProps) => <RealtimeWindowContent {...containerProps} />}
    </RealtimeWindowContainer>
  );
};

import { Dispatch, SetStateAction, useMemo, MouseEvent, useRef } from 'react';
import styled, { useTheme } from 'styled-components';
import {
  CreatePromptStyleReferenceMode,
  ImageInferenceType,
  PromptData,
  publishTrackingEvent,
} from '@vizcom/shared/data-access/graphql';
import { PaywallEventName } from '@vizcom/shared/data-shape';
import { promptLegacyTypeToModeAndPalette } from '@vizcom/shared/inference-worker-queues';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import {
  FeatureFlagged,
  useFeatureFlag,
  useIsFree,
  useShouldDisplayOnboardingTour,
  usePaywallModalState,
  addToast,
  Divider,
  UpgradeWidget,
} from '@vizcom/shared-ui-components';

import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../../lib/useDrawingSyncedState';
import { useOnboardingMultiStep } from '../../utils/OnboardingMultiStep';
import { RealtimeWindow } from '../realtime/RealtimeWindow';
import { RealtimeDrawingSlider } from '../realtime/components/RealtimeDrawingSlider';
import { RealtimePaletteSlider } from '../realtime/components/RealtimePaletteSlider';
import { useSubscribeToSelectionApi } from '../selection/useSelectionApi';
import {
  WorkbenchStudioToolType,
  useWorkbenchStudioState,
} from '../studioState';
import { InferenceSettings } from '../useInference';
import { DrawingInfluenceSlider } from './DrawingInfluenceSlider';
import { FidelityAreaWrapper } from './FidelityArea';
import { GenerateButton } from './GenerateButton';
import { ImageInferenceTypeToggle } from './ImageInferenceTypeToggle';
import { MainPrompt } from './MainPrompt';
import { OutputsMenu } from './OutputsMenu';
import { PaletteSettings } from './PaletteSettings';
import { PromptInput } from './PromptInput';
import { StyleReferenceSettings } from './styleReference/StyleReferenceSettings';
import {
  MinimalInferenceSettings,
  useReferenceMode,
} from './utils/useReferenceMode';

type Props = {
  drawing: Drawing2dStudio;
  anyOutputLoading?: boolean;
  inferenceSettings: InferenceSettings & {
    imageInferenceType: ImageInferenceType;
  };
  selectedHistoryPrompt?: PromptData;
  isGeneratingPrompt: boolean;
  applyHistoryItem: (inferenceSettings: InferenceSettings) => void;
  setInferenceSettings: Dispatch<
    SetStateAction<
      InferenceSettings & { imageInferenceType: ImageInferenceType }
    >
  >;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  trigger: () => void;
  triggerAutoPrompt: () => void;
  cancelAutoPrompt: () => void;
  onExit: () => void;
  setActiveLayerId: (id: string | undefined) => void;
};

export const Create = ({
  drawing,
  anyOutputLoading,
  inferenceSettings,
  selectedHistoryPrompt,
  isGeneratingPrompt,
  applyHistoryItem,
  setInferenceSettings,
  handleAction,
  trigger,
  triggerAutoPrompt,
  cancelAutoPrompt,
  onExit,
  setActiveLayerId,
}: Props) => {
  const { currentStep } = useOnboardingMultiStep();
  const isFreePlan = useIsFree(drawing.id);
  const isHistoryPreviewActive = Boolean(selectedHistoryPrompt?.id);
  const theme = useTheme();
  const shouldDisplayTour = useShouldDisplayOnboardingTour();
  const hasActiveSelection = Boolean(
    useSubscribeToSelectionApi((state) => state.hasMask)
  );
  const paywallModalState = usePaywallModalState();

  const isRealtimeMode =
    inferenceSettings.imageInferenceType === ImageInferenceType.Realtime;
  const isColorTransferMode = Boolean(
    inferenceSettings.referenceMode === 'color' &&
      inferenceSettings.colorReference.color
  );

  const setStudioTool = useWorkbenchStudioState((s) => s.setTool);
  const activeToastId = useRef<string>();

  const shouldBlockColorTransfer =
    isColorTransferMode &&
    !hasActiveSelection &&
    inferenceSettings.imageInferenceType === ImageInferenceType.Render;

  const { isTryOnOrBackgroundReference } = useReferenceMode(
    inferenceSettings as MinimalInferenceSettings,
    setInferenceSettings
  );

  const { isTryOn, isBackground } = isTryOnOrBackgroundReference(
    inferenceSettings as MinimalInferenceSettings
  );

  const showColorTransferToast = () => {
    if (shouldBlockColorTransfer && !activeToastId.current) {
      activeToastId.current = addToast(
        'Select an area on the canvas before using color transfer, or remove the color reference',
        {
          duration: 5000,
          cta: {
            text: 'Select Area',
            action: () => {
              setStudioTool(WorkbenchStudioToolType.Lasso);
              activeToastId.current = undefined;
            },
          },
        }
      );
      setTimeout(() => {
        activeToastId.current = undefined;
      }, 5000);
      return true;
    }
    return false;
  };

  const handleGenerate = () => {
    if (shouldBlockColorTransfer) {
      showColorTransferToast();
      return;
    }
    trigger();
  };

  const {
    outputsCount,
    sourceImageInfluence,
    prompt,
    fidelity,
    paletteInfluence,
    publicPaletteId,
    customModelId,
    workbenchPaletteId,
    userPaletteId,
    imageInferenceType,
    negativePrompt,
    styleReference,
    referenceMode,
    colorReference,
    colorCoherence,
  } = useMemo(() => {
    if (!selectedHistoryPrompt?.id) return inferenceSettings;

    let imageInferenceType = selectedHistoryPrompt.imageInferenceType;
    let publicPaletteId = selectedHistoryPrompt.publicPaletteId;
    if (selectedHistoryPrompt.legacyType) {
      const legacyType = promptLegacyTypeToModeAndPalette(
        selectedHistoryPrompt.legacyType
      );
      imageInferenceType =
        legacyType.mode === 'render'
          ? ImageInferenceType.Render
          : ImageInferenceType.Refine;
      publicPaletteId = legacyType.publicPaletteId;
    }

    const savedSettings = {
      outputsCount: selectedHistoryPrompt.outputs.nodes.length > 1 ? 4 : 1,
      sourceImageInfluence: selectedHistoryPrompt.sourceImageInfluence,
      paletteInfluence: selectedHistoryPrompt.paletteInfluence,
      prompt: selectedHistoryPrompt.text,
      publicPaletteId,
      customModelId: selectedHistoryPrompt.customModelId,
      workbenchPaletteId: selectedHistoryPrompt.workbenchPaletteId,
      userPaletteId: selectedHistoryPrompt.userPaletteId,
      imageInferenceType,
      fidelity: inferenceSettings.fidelity,
      negativePrompt: inferenceSettings.negativePrompt,
      mode: inferenceSettings.mode,
      referenceMode: CreatePromptStyleReferenceMode.Image,
      styleReference: {
        [CreatePromptStyleReferenceMode.Image]: {
          strength: 1,
          styleReferenceId: null,
        },
        [CreatePromptStyleReferenceMode.Material]: {
          strength: 1,
          styleReferenceId: null,
        },
      },
      colorReference: inferenceSettings.colorReference,
      colorCoherence: inferenceSettings.colorCoherence,
    } as InferenceSettings;

    if (selectedHistoryPrompt.styleReferenceImagePath) {
      const styleReference = drawing?.workbench?.styleReferences?.nodes.find(
        (ref) => ref.imagePath === selectedHistoryPrompt.styleReferenceImagePath
      );
      if (styleReference) {
        savedSettings.referenceMode = CreatePromptStyleReferenceMode.Image;
        savedSettings.styleReference[
          savedSettings.referenceMode
        ].styleReferenceId = styleReference.id;
      }
    }

    return savedSettings;
  }, [selectedHistoryPrompt, inferenceSettings, drawing]);

  const onOutputsCountChange = (value: number) => {
    setInferenceSettings((prev) => ({
      ...prev,
      outputsCount: value,
    }));
  };

  const onSourceImageInfluenceChange = (sourceImageInfluence: number) => {
    setInferenceSettings((prev) => ({
      ...prev,
      sourceImageInfluence: sourceImageInfluence / 100.0,
    }));
  };

  const handleLocked = () => {
    trackEvent('Paywall', {
      action: 'Prompt Count',
      location: 'Prompt',
    });
    publishTrackingEvent({
      type: PaywallEventName.PAYWALL_DISPLAYED,
      data: {
        action: 'prompt-count',
        location: 'prompt',
      },
    });
    paywallModalState.trigger('freeToPro');
  };

  const applySettings = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    applyHistoryItem({
      ...inferenceSettings,
      outputsCount,
      sourceImageInfluence,
      paletteInfluence,
      prompt,
      publicPaletteId,
      customModelId,
      workbenchPaletteId,
      userPaletteId,
      imageInferenceType,
      fidelity,
      negativePrompt,
      styleReference: {
        ...inferenceSettings.styleReference,
        ...styleReference,
      },
      referenceMode,
    });
  };

  const { flagEnabled: fidelityFlagEnabled } = useFeatureFlag('FIDELITY');

  if (!drawing) {
    return null;
  }

  return (
    <Container>
      <ImageInferenceTypeToggle
        value={inferenceSettings.imageInferenceType}
        disabled={isHistoryPreviewActive}
        onChange={(value) => {
          setInferenceSettings((prev) => ({
            ...prev,
            imageInferenceType: value,
          }));
        }}
        isRenderOnly={isTryOn || isBackground}
        modesNotAllowedReason={
          isTryOn
            ? 'Disabled for Try On mode'
            : isBackground
            ? 'Disabled for Background mode'
            : undefined
        }
      />
      {isRealtimeMode && isFreePlan ? (
        <UpgradeWidgetWrapper>
          <UpgradeWidget
            variant="column"
            title="Unlock Live Render"
            description="Upgrade to Pro to access live features and take your designs to the next level"
            cta={{
              text: 'Upgrade',
              action: () => paywallModalState.trigger('freeToPro'),
            }}
          />
        </UpgradeWidgetWrapper>
      ) : (
        <ScrollableContent>
          <Field>
            <MainPrompt
              prompt={prompt}
              isGeneratingPrompt={isGeneratingPrompt}
              triggerAutoPrompt={triggerAutoPrompt}
              cancelAutoPrompt={cancelAutoPrompt}
              setInferenceSettings={setInferenceSettings}
              isHistoryPreviewActive={isHistoryPreviewActive}
              shouldDisplayTour={shouldDisplayTour}
              currentStep={currentStep}
              trigger={trigger}
            />
          </Field>

          {!isRealtimeMode && (
            <FeatureFlagged flag="NEGATIVE_PROMPT">
              <Field>
                <Header>Negative Prompt</Header>
                <PromptInput
                  disabled={isHistoryPreviewActive}
                  placeholder="What should be excluded from the image?"
                  value={negativePrompt}
                  onChange={(value) =>
                    setInferenceSettings((prev) => ({
                      ...prev,
                      negativePrompt: value,
                    }))
                  }
                  onEnter={trigger}
                />
              </Field>
            </FeatureFlagged>
          )}
          {!isRealtimeMode && (
            <>
              <Divider />
              <PaletteSettings
                drawing={drawing}
                historyPreviewActive={isHistoryPreviewActive}
                setInferenceSettings={setInferenceSettings}
                inferenceSettings={{
                  referenceMode,
                  styleReference,
                  colorReference,
                  publicPaletteId,
                  customModelId,
                  workbenchPaletteId,
                  userPaletteId,
                  imageInferenceType,
                  paletteInfluence,
                  colorCoherence,
                }}
                onExit={onExit}
              />
              {imageInferenceType === ImageInferenceType.Render &&
                !colorCoherence && (
                  <>
                    <Divider />
                    <StyleReferenceSettings
                      drawing={drawing}
                      historyPreviewActive={isHistoryPreviewActive}
                      setInferenceSettings={setInferenceSettings}
                      inferenceSettings={inferenceSettings}
                    />
                  </>
                )}
            </>
          )}

          <Divider />
          {fidelityFlagEnabled && !isRealtimeMode ? (
            <Field>
              <FidelityAreaWrapper
                fidelity={fidelity}
                setInferenceSettings={setInferenceSettings}
                sourceImageInfluence={sourceImageInfluence}
                isDisabled={isTryOn || isBackground || isHistoryPreviewActive}
              />
            </Field>
          ) : (
            !isRealtimeMode && (
              <Field>
                <Header>Drawing</Header>
                <DrawingInfluenceSlider
                  drawingInfluence={sourceImageInfluence}
                  onDrawingInfluenceChange={onSourceImageInfluenceChange}
                  theme={theme}
                  isDisabled={isTryOn || isBackground || isHistoryPreviewActive}
                />
              </Field>
            )
          )}

          {isRealtimeMode && (
            <>
              <RealtimeDrawingSlider
                sourceImageInfluence={sourceImageInfluence}
                onSourceImageInfluenceChange={onSourceImageInfluenceChange}
              />
              <RealtimePaletteSlider
                paletteInfluence={inferenceSettings.paletteInfluence}
                onPaletteInfluenceChange={(value) =>
                  setInferenceSettings((prev) => ({
                    ...prev,
                    paletteInfluence: value,
                  }))
                }
              />
            </>
          )}
        </ScrollableContent>
      )}

      {isRealtimeMode && !isFreePlan && (
        <RealtimeWindow
          prompt={prompt}
          drawing={drawing}
          handleAction={handleAction}
          setActiveLayerId={setActiveLayerId}
          paletteInfluence={inferenceSettings.paletteInfluence}
          onPaletteInfluenceChange={(value) =>
            setInferenceSettings((prev) => ({
              ...prev,
              paletteInfluence: value,
            }))
          }
          sourceImageInfluence={sourceImageInfluence}
          onSourceImageInfluenceChange={onSourceImageInfluenceChange}
          mode={inferenceSettings.mode}
          onModeChange={(mode) =>
            setInferenceSettings((prev) => ({
              ...prev,
              mode: mode,
            }))
          }
        />
      )}

      {!isRealtimeMode && (
        <GenerateWrapper>
          <OutputsMenu
            isFreePlan={isFreePlan}
            outputsCount={outputsCount}
            onOutputsCountChange={onOutputsCountChange}
            isHistoryPreviewActive={isHistoryPreviewActive}
            handleLocked={handleLocked}
          />
          <GenerateButton
            isHistoryPreviewActive={isHistoryPreviewActive}
            anyOutputLoading={anyOutputLoading}
            onGenerate={handleGenerate}
            applySettings={applySettings}
          />
        </GenerateWrapper>
      )}
    </Container>
  );
};

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const UpgradeWidgetWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
`;

export const ScrollableContent = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  height: 0;
  overflow-y: auto;

  ${({ theme }) => theme.scrollbar.dark}
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  padding: 12px 16px;
  z-index: 100;
`;

export const Header = styled.div`
  display: flex;
  font-weight: 600;
  align-items: center;
  font-size: 12px;
  color: #f9f9fa;

  > div {
    margin-left: auto;
    font-weight: 400;
  }
`;

const GenerateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  padding: 16px;
`;

import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { paths } from '@vizcom/shared-utils-paths';

import { Text } from '../../../../../../shared/ui/components/src';
import PlusIcon from './create_file_icon.svg?react';

const Container = styled(Link)`
  border-radius: ${({ theme }) => theme.borderRadius.l};
  color: ${({ theme }) => theme.text.subtext};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  border: 2px dashed ${({ theme }) => theme.surface.tertiary};
  cursor: pointer;
  height: 75%;
  width: 100%;

  transition: 0.2s all ease;
  :hover {
    background-color: ${({ theme }) => theme.surface.secondary};
  }
`;

export const CreateNewDrawingPlaceholder = (props: {
  folderId: string | undefined;
}) => {
  return (
    <Container
      to={paths.workbench.new(props.folderId!)}
      state={{ from: 'emptyFolderPlaceholder' }}
    >
      <PlusIcon />
      <Text type="button2" color="subtext" style={{ marginTop: 12 }}>
        Create new file
      </Text>
    </Container>
  );
};

import { RootState } from '@react-three/fiber';
import { memo, MutableRefObject } from 'react';
import styled from 'styled-components';
import { OrganizationSubscriptionPlan } from '@vizcom/shared/data-access/graphql';
import {
  AssetLibraryIcon,
  FeatureFlagged,
  OnboardingStepName,
  OnboardingTooltip,
  SectionIcon,
  SelectIcon,
  Text,
  TextIcon,
  Toolbar,
  ToolbarButton,
  ToolbarButtonState,
  ToolbarDivider,
  useCompleteOnboardingStep,
} from '@vizcom/shared-ui-components';

import { useWorkbenchSyncedState } from '../../lib/useWorkbenchSyncedState';
import { useIsWorkbenchViewer } from '../../lib/utils';
import { RedoButton } from '../RedoButton';
import { UndoButton } from '../UndoButton';
import { Hotkey } from '../studio/style';
import { WorkbenchInsertButton } from './InsertButton';
import {
  WorkbenchToolType,
  useWorkbenchToolState,
} from './WorkbenchToolContext';

interface WorkbenchToolbarProps {
  canUndo: boolean;
  canRedo: boolean;
  toolboxOpen: boolean;
  subscriptionPlan?: OrganizationSubscriptionPlan;
  setToolboxOpen: (open: boolean) => void;
  onOpenMobileUploadModal: () => void;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
  handleUndo: () => void;
  handleRedo: () => void;
  threeState: MutableRefObject<RootState>;
  handleSelectCreated: (id: string) => void;
  setOpenPaletteOnboardingModal: (open: boolean) => void;
}

export const WorkbenchToolbar = memo(
  ({
    canUndo,
    canRedo,
    toolboxOpen,
    subscriptionPlan,
    setToolboxOpen,
    onOpenMobileUploadModal,
    handleAction,
    handleUndo,
    handleRedo,
    threeState,
    handleSelectCreated,
    setOpenPaletteOnboardingModal,
  }: WorkbenchToolbarProps) => {
    const completeOnboardingStep = useCompleteOnboardingStep();
    const isViewer = useIsWorkbenchViewer();
    const { tool, setTool, toolsDisabled } = useWorkbenchToolState();

    if (isViewer) {
      return (
        <Toolbar position="top-center">
          <ToolbarButton
            icon={<SelectIcon />}
            tooltip="Select"
            state={ToolbarButtonState.ACTIVE}
          />

          <ToolbarDivider />

          <Text color="subtext" style={{ paddingRight: '8px' }}>
            View only
          </Text>
        </Toolbar>
      );
    }

    const handleToolboxOpen = (open: boolean) => {
      setToolboxOpen(open);
      if (open) completeOnboardingStep(OnboardingStepName.ClickInsert);
    };

    return (
      <Toolbar position="top-center">
        <OnboardingTooltip
          name={OnboardingStepName.ClickInsert}
          title="Click to insert a new element"
        >
          <AbsoluteButtonPlaceholder />
        </OnboardingTooltip>
        <WorkbenchInsertButton
          isMenuOpen={toolboxOpen}
          setIsMenuOpen={handleToolboxOpen}
          threeState={threeState}
          handleSelectCreated={handleSelectCreated}
          handleAction={handleAction}
          onOpenMobileUploadModal={onOpenMobileUploadModal}
          setOpenPaletteOnboardingModal={setOpenPaletteOnboardingModal}
          subscriptionPlan={subscriptionPlan}
        />

        <ToolbarDivider />

        <ToolbarButton
          state={
            tool === WorkbenchToolType.SELECT
              ? ToolbarButtonState.ACTIVE
              : ToolbarButtonState.INACTIVE
          }
          onClick={() => setTool(WorkbenchToolType.SELECT)}
          icon={<SelectIcon />}
          tooltip="Select"
        />

        <ToolbarButton
          state={
            tool === WorkbenchToolType.TEXT
              ? ToolbarButtonState.ACTIVE
              : ToolbarButtonState.INACTIVE
          }
          onClick={() => setTool(WorkbenchToolType.TEXT)}
          icon={<TextIcon />}
          tooltip={
            <>
              Text <Hotkey>T</Hotkey>
            </>
          }
          buttonProps={{
            disabled: toolsDisabled,
          }}
        />

        <FeatureFlagged flag="ASSETS_LIBRARY">
          <ToolbarButton
            state={
              tool === WorkbenchToolType.ASSET_LIBRARY
                ? ToolbarButtonState.ACTIVE
                : ToolbarButtonState.INACTIVE
            }
            onClick={() => setTool(WorkbenchToolType.ASSET_LIBRARY)}
            icon={<AssetLibraryIcon />}
            tooltip="Asset Library"
          />
        </FeatureFlagged>

        <ToolbarButton
          state={
            tool === WorkbenchToolType.SECTION
              ? ToolbarButtonState.ACTIVE
              : ToolbarButtonState.INACTIVE
          }
          onClick={() => setTool(WorkbenchToolType.SECTION)}
          icon={<SectionIcon />}
          tooltip="Section"
          buttonProps={{
            disabled: toolsDisabled,
          }}
        />

        <ToolbarDivider />

        <UndoButton canUndo={canUndo} handleUndo={handleUndo} />

        <RedoButton canRedo={canRedo} handleRedo={handleRedo} />
      </Toolbar>
    );
  }
);

const AbsoluteButtonPlaceholder = styled.div`
  position: absolute;
  height: 24px;
  width: 2px;
`;

import {
  CMD_KEY_PREFIX,
  ToolbarButton,
  ToolbarButtonState,
  UndoIcon,
} from '@vizcom/shared-ui-components';

import { Hotkey } from './studio/style';

export const UndoButton = ({
  canUndo,
  handleUndo,
}: {
  canUndo: boolean;
  handleUndo: () => void;
}) => {
  return (
    <ToolbarButton
      icon={<UndoIcon style={{ color: 'inherit' }} />}
      tooltip={
        canUndo ? (
          <>
            Undo <Hotkey>{CMD_KEY_PREFIX}Z</Hotkey>
          </>
        ) : (
          'Nothing to undo'
        )
      }
      state={
        canUndo ? ToolbarButtonState.INACTIVE : ToolbarButtonState.DISABLED
      }
      onClick={handleUndo}
    />
  );
};

import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  removeAccessToken,
  useCurrentUser,
} from '@vizcom/shared/data-access/graphql';
import { Button, Text, VizcomLogo } from '@vizcom/shared-ui-components';

export const OnboardingHeader = () => {
  const { data: currentUser } = useCurrentUser();

  const handleLogout = () => {
    removeAccessToken();
  };

  return (
    <Container>
      <Link to="/">
        <VizcomLogo style={{ color: 'white' }} />
      </Link>
      <div style={{ flex: 1 }} />
      <div>
        <Text block color="subtext">
          Logged in as:
        </Text>
        <Text block>{currentUser?.email}</Text>
      </div>
      <Button
        variant="tertiary"
        onClick={handleLogout}
        style={{ marginTop: 12 }}
      >
        Log out
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
  width: 100%;
`;

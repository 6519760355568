import { useEffect, useRef } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import {
  removeAccessToken,
  useAcceptOrganizationInvite,
  useCurrentUser,
} from '@vizcom/shared/data-access/graphql';
import {
  Button,
  FullPageDarkLoader,
  Layout,
  Text,
  addToast,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

export const AcceptOrganizationInvitePage = () => {
  const location = useLocation();
  const user = useCurrentUser();
  const { invitedOrganizationId } = useParams();
  const [acceptInviteRes, acceptInvite] = useAcceptOrganizationInvite();
  const startedAccepting = useRef(false);

  const getEmailFromJWT = () => {
    try {
      const token = location.hash.replace('#token=', '');
      const payload = token.split('.')[1];

      return JSON.parse(atob(payload)).email;
    } catch {
      return null;
    }
  };

  const invitationEmail = getEmailFromJWT();
  const { email: userEmail } = user.data || {};

  const handleLogout = () => {
    removeAccessToken();
  };

  useEffect(() => {
    if (startedAccepting.current) {
      return;
    }
    startedAccepting.current = true;
    acceptInvite({
      input: {
        targetOrganizationId: invitedOrganizationId,
      },
    }).then((res) => {
      if (res.error) {
        return addToast('Error while accepting invitation.', {
          type: 'danger',
          secondaryText: formatErrorMessage(res.error),
        });
      }

      addToast("Invitation accepted, you're now a member of this workspace");
    });
  }, [acceptInvite, invitedOrganizationId]);

  if (
    acceptInviteRes.data?.acceptOrganizationInvite?.organization?.globalTeam?.id
  ) {
    return (
      <Navigate
        to={paths.files.team(
          acceptInviteRes.data.acceptOrganizationInvite.organization.globalTeam
            .id
        )}
        replace
      />
    );
  }

  if (acceptInviteRes.fetching) {
    return (
      <Layout hideTeamInSidebar>
        <FullPageDarkLoader />
      </Layout>
    );
  }

  if (acceptInviteRes.error) {
    return (
      <Layout hideTeamInSidebar>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            height: '100%',
            textAlign: 'center',
          }}
        >
          <Text block style={{ maxWidth: 600 }} type="h2">
            There was an error with this workspace invite.
          </Text>
          <br />
          {userEmail &&
          invitationEmail &&
          userEmail.toLowerCase() !== invitationEmail.toLowerCase() ? (
            <>
              <Text block style={{ maxWidth: 600 }} type="h2">
                You are currently connected to Vizcom with {userEmail} and
                trying to accept an invitation to {invitationEmail}. Please
                logout before accepting the invitation.
              </Text>
              <Button
                variant="secondary"
                onClick={handleLogout}
                style={{ marginTop: 20 }}
              >
                Logout
              </Button>
            </>
          ) : (
            <Text block style={{ maxWidth: 600 }} type="h2">
              Please make sure the link is correct.
            </Text>
          )}
          <Text block style={{ marginTop: 20 }} color="error">
            {acceptInviteRes.error.graphQLErrors?.[0]?.message ||
              acceptInviteRes.error.message}
          </Text>
        </div>
      </Layout>
    );
  }

  return <Layout hideTeamInSidebar />;
};

import styled from 'styled-components';
import {
  LineIcon,
  ToolbarButtonState,
  ToolbarDivider,
  Checkbox,
  CloseIcon,
} from '@vizcom/shared-ui-components';

import { useWorkbenchStudioState } from '../../studioState';
import { ToolbarIcon } from '../ToolbarIcon';

type SymmetryToolbarProps = { onClose: () => void };

export const SymmetryToolbar = ({ onClose }: SymmetryToolbarProps) => {
  const { symmetry, setSymmetry } = useWorkbenchStudioState();

  return (
    <Container>
      <ToolbarIcon
        $toolState={
          symmetry.enabled && Math.abs(symmetry.rotation % Math.PI) === 0
            ? ToolbarButtonState.ACTIVE
            : ToolbarButtonState.INACTIVE
        }
        onClick={() => {
          setSymmetry({
            ...symmetry,
            enabled: true,
            origin: [0.5, 0.5],
            rotation: 0,
          });
        }}
        icon={<VerticalAxis />}
      />
      <ToolbarIcon
        $toolState={
          symmetry.enabled &&
          Math.abs(symmetry.rotation % Math.PI) === Math.PI * 0.5
            ? ToolbarButtonState.ACTIVE
            : ToolbarButtonState.INACTIVE
        }
        onClick={() => {
          setSymmetry({
            ...symmetry,
            enabled: true,
            origin: [0.5, 0.5],
            rotation: Math.PI * -0.5,
          });
        }}
        icon={<HorizontalAxis />}
      />
      <ToolbarDivider />
      <CheckboxContainer
        onClick={() =>
          setSymmetry({
            ...symmetry,
            allowCrossingAxis: !symmetry.allowCrossingAxis,
          })
        }
      >
        <span>Axis Clipping</span>
        <Checkbox
          checked={!symmetry.allowCrossingAxis}
          onClick={() =>
            setSymmetry({
              ...symmetry,
              allowCrossingAxis: !symmetry.allowCrossingAxis,
            })
          }
        />
      </CheckboxContainer>
      <ToolbarDivider />
      <ToolbarIcon
        $toolState={
          !symmetry.enabled
            ? ToolbarButtonState.ACTIVE
            : ToolbarButtonState.INACTIVE
        }
        onClick={() => {
          setSymmetry({
            ...symmetry,
            enabled: false,
          });
          onClose();
        }}
        icon={<CloseIcon />}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: 8px;
  color: ${({ theme }) => theme.text.body};
  cursor: pointer;
`;

const HorizontalAxis = styled(LineIcon)`
  transform: rotate(45deg);
`;

const VerticalAxis = styled(LineIcon)`
  transform: rotate(-45deg);
`;

import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Text, Button, useCrisp } from '@vizcom/shared-ui-components';

const FullPageContainer = styled.div`
  background-color: ${(p) => p.theme.surface.page};
  display: flex;
  flex-direction: column;
  padding: 4em;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  color: ${(p) => p.theme.text.body};
`;

const ErrorContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 600px;
  width: 100%;
`;

const ErrorMessage = styled(Text)`
  color: ${(p) => p.theme.surface.danger};
  background-color: ${(p) => p.theme.surface.secondary};
  padding: 16px;
  border-radius: ${(p) => p.theme.borderRadius.m};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
`;

interface ErrorPageProps {
  title: string;
  description: string;
  errorMessage: string;
}

export const MobileWorkbenchActionErrorPage = ({
  title,
  description,
  errorMessage,
}: ErrorPageProps) => {
  const crisp = useCrisp();

  return (
    <FullPageContainer>
      <ErrorContent>
        <Text block type="h2" color="link">
          {title}
        </Text>
        <Text block type="b1" color="subtext">
          {description}
        </Text>
        <ErrorMessage>{errorMessage}</ErrorMessage>
        <ButtonContainer>
          <Button variant="primary" as={Link} to="/">
            Return to dashboard
          </Button>
          <Text block color="subtext">
            Or
          </Text>
          <Button variant="secondary" onClick={() => crisp.openChat()}>
            Contact support
          </Button>
        </ButtonContainer>
      </ErrorContent>
    </FullPageContainer>
  );
};

import { useThree } from '@react-three/fiber';
import { useEffect, useRef } from 'react';
import { useTheme } from 'styled-components';
import {
  addToast,
  VizcomLogoAnimated,
  OnboardingTooltip,
  OnboardingStepName,
} from '@vizcom/shared-ui-components';

import { RawImage } from '../../../lib/RawImage';
import { ClientSideWorkbenchElementPlaceholder } from '../../../lib/clientState';
import { useWorkbenchSyncedState } from '../../../lib/useWorkbenchSyncedState';
import { useIsWorkbenchViewer } from '../../../lib/utils';
import {
  WorkbenchElementExtra,
  WorkbenchElementExtraDelete,
  WorkbenchElementExtraDuplicate,
} from '../../WorkbenchElementExtra';
import { getElementSize } from '../../helpers';
import { filterChildByWorkbenchElementUserData } from '../../objectsUserdata';
import { CustomHtml } from '../../utils/CustomHtml';
import { HtmlErrorPlaceholder } from '../../utils/HtmlErrorPlaceholder';
import { ZoomFallbackGroup } from '../../utils/ZoomFallbackGroup';
import { CreateDrawingPlaceholder } from './CreateDrawingPlaceholder';
import { CreateVideoPlaceholder } from './CreateVideoPlaceholder';
import { LoadingContainer, WorkbenchPlaceholderLoadingImage } from './style';

interface WorkbenchElementPlaceholderProps {
  workbenchId: string;
  element: ClientSideWorkbenchElementPlaceholder;
  isDragging: boolean;
  isResizing: boolean;
  singleFocused: boolean;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
  setEditingElementId: (id: string | null) => void;
}

export const WorkbenchElementPlaceholder = ({
  workbenchId,
  element,
  isDragging,
  isResizing,
  singleFocused,
  handleAction,
  setEditingElementId,
}: WorkbenchElementPlaceholderProps) => {
  const theme = useTheme();
  const scene = useThree((s) => s.scene);
  const { width, height } = getElementSize(element);
  const loadingMeshRef = useRef(false);
  const isViewer = useIsWorkbenchViewer();

  useEffect(() => {
    if (loadingMeshRef.current || !element.meshOutputPath) {
      return;
    }
    loadingMeshRef.current = true;
    handleAction({
      type: 'convertPlaceholderMeshOutput',
      elementId: element.id,
    });
  }, [element.meshOutputPath, element.id, handleAction]);

  useEffect(() => {
    if (!element.failureReason) return;

    addToast('Error generating image', {
      type: 'danger',
      secondaryText: element.failureReason,
    });
  }, [element.failureReason]);

  const fallback = (
    <mesh scale={[width, height, 1]}>
      <planeGeometry args={[1, 1, 1, 1]} />
      <meshBasicMaterial color="white" transparent />
    </mesh>
  );

  if (isResizing) {
    return fallback;
  }

  const allPlaceholders = filterChildByWorkbenchElementUserData(
    scene,
    (userData) => userData.elementTypename === 'WorkbenchElementPlaceholder'
  );
  // Only show the tooltip on the last placeholder element instead of all placeholder elements
  const showOnboardingTooltip =
    allPlaceholders.length > 0
      ? allPlaceholders[allPlaceholders.length - 1].userData.elementId ===
        element.id
      : true;

  const customPlaceholderType =
    element.type === 'drawing' || element.type === 'video';

  return (
    <>
      <ZoomFallbackGroup fallback={fallback}>
        <CustomHtml transform occlude visible={!isResizing}>
          <OnboardingTooltip
            name={OnboardingStepName.EnterSketch}
            previousStep={OnboardingStepName.InsertImage}
            title="Double click to begin sketching"
            show={showOnboardingTooltip}
          >
            <div
              style={{
                width,
                height,
                userSelect: 'none',
                background: '#fff',
                borderRadius: theme.borderRadius.m,
              }}
            >
              {element.type === 'drawing' && (
                <CreateDrawingPlaceholder
                  placeholder={element}
                  workbenchId={workbenchId}
                  handleAction={handleAction}
                  setEditingElementId={setEditingElementId}
                  singleFocused={singleFocused}
                />
              )}
              {element.type === 'video' && (
                <CreateVideoPlaceholder element={element} />
              )}
              {element.failureReason ? (
                <HtmlErrorPlaceholder />
              ) : (
                !customPlaceholderType && (
                  <WorkbenchPlaceholderLoading element={element} />
                )
              )}
            </div>
          </OnboardingTooltip>
        </CustomHtml>
      </ZoomFallbackGroup>
      {!isDragging && !isResizing && singleFocused && !isViewer && (
        <WorkbenchElementExtra
          workbenchId={workbenchId}
          element={element}
          handleAction={handleAction}
          position={[0, height / 2, 0]}
          pivot={element.y}
        >
          {!element.failureReason && (
            <WorkbenchElementExtraDuplicate
              id={element.id}
              handleAction={handleAction}
            />
          )}
          <WorkbenchElementExtraDelete
            id={element.id}
            handleAction={handleAction}
          />
        </WorkbenchElementExtra>
      )}
    </>
  );
};

const WorkbenchPlaceholderLoading = (props: {
  element: ClientSideWorkbenchElementPlaceholder;
}) => {
  const theme = useTheme();

  return (
    <>
      <LoadingContainer>
        <VizcomLogoAnimated
          style={{ width: 64, height: 64, zIndex: 10, position: 'relative' }}
          color={theme.icon.secondary}
        />
        {props.element.loadingImagePath && (
          <WorkbenchPlaceholderLoadingImage
            crossOrigin="anonymous"
            src={props.element.loadingImagePath}
          />
        )}
        {props.element.rawLoadingImage && (
          <WorkbenchPlaceholderLoadingImage
            as={RawImage}
            data={props.element.rawLoadingImage}
          />
        )}
      </LoadingContainer>
    </>
  );
};

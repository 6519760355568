import React from 'react';
import styled from 'styled-components';
import {
  Text,
  RangeInput,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  AutoplayVideo,
  NumberInput,
} from '@vizcom/shared-ui-components';

import drawingSliderInfo from './assets/tooltip_drawing_influence.mp4';

const HoverableHeaderSecondary = styled(Text)`
  color: ${({ theme }) => theme.text.subtext};
  font-size: 12px;
  font-weight: 500;
  cursor: help;
  transition: color 0.2s ease;

  &:hover {
    color: ${({ theme }) => theme.text.body};
  }
`;

const NumberInputContainer = styled.div`
  background-color: ${({ theme }) => theme.surface.secondary};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  width: 50px;
  transition: background-color 0.2s ease;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid ${({ theme }) => theme.border.primary};
  }
`;

const SliderContainer = styled.div<{ $isDisabled?: boolean }>`
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 16px;
  align-items: center;
  opacity: ${({ $isDisabled }) => ($isDisabled ? 0.5 : 1)};
`;

interface DrawingInfluenceSliderProps {
  drawingInfluence: number;
  onDrawingInfluenceChange: (value: number) => void;
  theme: any;
  isDisabled?: boolean;
}

export const DrawingInfluenceSlider: React.FC<DrawingInfluenceSliderProps> = ({
  drawingInfluence,
  onDrawingInfluenceChange,
  theme,
  isDisabled = false,
}) => {
  const displayValue = Math.floor(drawingInfluence * 100);

  return (
    <RichTooltip
      trigger="hover"
      placement="top"
      delay={{
        open: isDisabled ? 500 : 1500,
        close: 0,
      }}
      padding={16}
    >
      <RichTooltipTrigger>
        <SliderContainer $isDisabled={isDisabled}>
          <HoverableHeaderSecondary>Influence</HoverableHeaderSecondary>
          <RangeInput
            value={displayValue}
            min={0}
            max={100}
            step={5}
            onChange={(e) => onDrawingInfluenceChange(parseInt(e.target.value))}
            disabled={isDisabled}
          />
          <NumberInputContainer>
            <NumberInput
              value={displayValue}
              min={0}
              max={100}
              unit="%"
              setValue={(v) => onDrawingInfluenceChange(v)}
              dragArrows={false}
              disabled={isDisabled}
            />
          </NumberInputContainer>
        </SliderContainer>
      </RichTooltipTrigger>
      {isDisabled ? (
        <RichTooltipContent style={{ maxWidth: '250px' }}>
          <Text>Drawing Influence</Text>
          <Text
            type="b2"
            color="subtext"
            block
            style={{
              marginTop: '6px',
              marginBottom: '8px',
              lineHeight: '1.4',
            }}
          >
            Drawing influence adjustment is disabled in this mode
          </Text>
        </RichTooltipContent>
      ) : (
        <RichTooltipContent style={{ maxWidth: '201px' }}>
          <Text>Drawing Influence</Text>
          <Text
            type="b2"
            color="subtext"
            block
            style={{
              marginTop: '6px',
              marginBottom: '8px',
              lineHeight: '1.4',
            }}
          >
            Adjust how much the AI should consider your drawing when generating
            the image
          </Text>
          <div style={{ textAlign: 'right', marginBottom: '12px' }}>
            <a
              href="https://docs.vizcom.ai/drawing-influence"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                fontSize: '10px',
                color: theme.deprecated.primary.default,
                textDecoration: 'none',
              }}
            >
              Learn more
            </a>
          </div>
          <AutoplayVideo
            src={drawingSliderInfo}
            style={{
              width: '100%',
              height: 'auto',
              aspectRatio: '16 / 9',
              objectFit: 'cover',
              borderRadius: `calc(${theme.borderRadius.m} - 2px)`,
            }}
          />
        </RichTooltipContent>
      )}
    </RichTooltip>
  );
};

/**
 * FIXME Move to utils / split into files
 */
import { useThree, useStore } from '@react-three/fiber';
import {
  ForwardRefExoticComponent,
  PropsWithoutRef,
  RefAttributes,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Camera,
  DataTexture,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  OrthographicCamera,
  Scene,
  Vector3,
} from 'three';

import { filterExists } from '../../../../../shared/js-utils/src';
import { ClientSideWorkbenchElementData } from '../lib/clientState';
import { elementIsDrawing } from '../lib/utils';
import {
  filterChildByWorkbenchElementUserData,
  filterChildByWorkbenchObjectUserData,
  findNearestParentObjectWithWorkbenchElementUserData,
  WorkbenchElementContainerUserData,
} from './objectsUserdata';
import { MoveToEvent } from './utils/mapControls/mapControls';
import {
  getCameraBoundingBox,
  useMapControls,
} from './utils/mapControls/utils';

export const BOX_POINTS: [number, number][] = [
  [-1, 1],
  [1, 1],
  [1, -1],
  [-1, -1],
  [-1, 1],
];
export const Z_AXIS = new Vector3(0, 0, 1);
export const MAX_Z_POSITION = 1000000;

export const getElementObjectSize = (
  element: ClientSideWorkbenchElementData,
  objectRef: Object3D | null
) => {
  const parentWithUserData = objectRef
    ? findNearestParentObjectWithWorkbenchElementUserData(objectRef)
    : null;

  if (!parentWithUserData) {
    return getElementSize(element);
  }

  return {
    width:
      parentWithUserData.userData.resizeWidth ??
      parentWithUserData.userData.elementWidth,
    height:
      parentWithUserData.userData.resizeHeight ??
      parentWithUserData.userData.elementHeight,
  };
};

export const getElementSize = (element: ClientSideWorkbenchElementData) => {
  if (elementIsDrawing(element)) {
    return {
      width: element.drawingWidth * element.workbenchSizeRatio,
      height: element.drawingHeight * element.workbenchSizeRatio,
    };
  }

  return {
    width: element.width,
    height: element.height,
  };
};

export const getFileImageInfo = (file: File) => {
  return new Promise<{ width: number; height: number }>((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => {
        const image = new Image();
        image.height = 100;
        image.title = file.name;
        image.src = reader.result as string;

        image.addEventListener('load', () => {
          resolve({ width: image.naturalWidth, height: image.naturalHeight });
        });
        image.addEventListener('error', () => {
          reject(new Error('Invalid image'));
        });
      },
      false
    );

    reader.readAsDataURL(file);
  });
};

// get the range of z position (min/max) for the workbench elements in the current scene
export const getWorkbenchElementZPositionRange = (rootScene: Object3D) => {
  const workbenchObjects = filterChildByWorkbenchElementUserData(
    rootScene,
    () => true
  );

  const zIndexes = workbenchObjects
    .map(
      (object) =>
        (object.userData as WorkbenchElementContainerUserData).elementZIndex
    )
    .filter(filterExists);

  return [Math.min(...zIndexes), Math.max(...zIndexes)];
};

// z-index range used by CustomHtml to show elements below/above the canvas
export const DEFAULT_Z_INDEX_RANGE = [16777271, 0];

// some component can receive props in a few different format, for exemple "scale" of a mesh
// this is an helper to cast it to an array of 3 components
export const vector3LikeToComponentsArray = (
  src: number | Vector3 | readonly [x: number, y: number, z: number]
): [number, number, number] => {
  if (typeof src === 'object') {
    if ('isVector3' in src) {
      return [src.x, src.y, src.z];
    }
    return [src[0], src[1], src[2]];
  }
  return [src, src, src];
};

const v3 = new Vector3();
export const screenPositionToWorld = (
  [x, y]: [number, number],
  camera: OrthographicCamera
) => {
  v3.set(
    (x / window.innerWidth) * 2 - 1,
    -(y / window.innerHeight) * 2 + 1,
    (camera.near + camera.far) / (camera.near - camera.far)
  ).unproject(camera);
  return [v3.x, v3.y];
};

export const screenPositionToLocal = (
  position: [number, number],
  camera: OrthographicCamera,
  element?: Object3D
) => {
  const [worldX, worldY] = screenPositionToWorld(position, camera);
  v3.set(worldX, worldY, 0);
  if (element) {
    element.worldToLocal(v3);
  }
  return [v3.x, v3.y];
};

export const screenPositionToLayer = (
  position: [number, number],
  camera: OrthographicCamera,
  element: Object3D,
  [width, height]: [number, number]
): [number, number] => {
  const [localX, localY] = screenPositionToLocal(position, camera, element);
  return [(localX + 0.5) * width, (0.5 - localY) * height];
};

export const worldPositionToScreen = (
  [x, y]: [number, number],
  camera: OrthographicCamera
) => {
  v3.set(x, y, 1);
  v3.project(camera);
  return [
    ((v3.x + 1) / 2) * window.innerWidth,
    (-(v3.y - 1) / 2) * window.innerHeight,
  ];
};

export const localPositionToScreen = (
  position: [number, number],
  camera: OrthographicCamera,
  element?: Object3D
) => {
  v3.set(position[0], position[1], 1);
  if (element) {
    element.localToWorld(v3);
  }
  return worldPositionToScreen([v3.x, v3.y], camera);
};

export const layerPositionToScreen = (
  position: [number, number],
  camera: OrthographicCamera,
  element: Object3D,
  [width, height]: [number, number]
) => {
  return localPositionToScreen(
    [position[0] / width - 0.5, 0.5 - position[1] / height],
    camera,
    element
  );
};

// The worldToScreen, screenToWorld and their variant are complex and can be inefficient when applied repetitively.
// This function simplifies linear transform by computing the coefficients of a 3x2 matrix by evaluating the basis vectors.
// Similar to memoize, but for algebra.
export function simplifyTransform(
  transform: (x: number, y: number) => [number, number]
) {
  const [a, c] = transform(1, 0);
  const [b, d] = transform(0, 1);
  const [tx, ty] = transform(0, 0);
  // In the evaluations above, z is implicitely 1. We need to compensate a, b, c and d with tx and ty
  return (x: number, y: number) => [
    (a - tx) * x + (b - tx) * y + tx,
    (c - ty) * x + (d - ty) * y + ty,
  ];
}

// FROM: https://github.com/pmndrs/drei/blob/master/src/helpers/ts-utils.tsx#L12
/**
 * Utility type to declare the type of a `forwardRef` component so that the type is not "evaluated" in the declaration
 * file.
 */
export type ForwardRefComponent<P, T> = ForwardRefExoticComponent<
  PropsWithoutRef<P> & RefAttributes<T>
>;

export const isMeshBasicMaterial = (
  material: any
): material is MeshBasicMaterial => {
  return (
    material &&
    'isMeshBasicMaterial' in material &&
    material.isMeshBasicMaterial
  );
};

export const object3dIsMesh = (object: Object3D): object is Mesh<any, any> => {
  return 'isMesh' in object && Boolean(object.isMesh);
};

export const object3dIsMeshWithBasicMaterial = (
  object: Object3D
): object is Mesh<any, MeshBasicMaterial> => {
  return object3dIsMesh(object) && isMeshBasicMaterial(object.material);
};

export function useImageDataTexture(imageData: ImageData): DataTexture;
export function useImageDataTexture(imageData: undefined): null;
export function useImageDataTexture(
  imageData: ImageData | undefined
): DataTexture | null;
export function useImageDataTexture(imageData: ImageData | undefined) {
  const texture = useMemo(() => new DataTexture(), []);
  texture.flipY = true;
  if (imageData && texture.image !== imageData) {
    texture.image = imageData;
    texture.needsUpdate = true;
  }
  useEffect(() => () => texture.dispose(), [texture]);

  return imageData ? texture : null;
}

export const yFlipImageBuffer = (
  imageBuffer: Uint8ClampedArray,
  width: number
) => {
  const bytesPerRow = width * 4;
  const height = imageBuffer.length / bytesPerRow;
  // make a temp buffer to hold one row
  const temp = new Uint8ClampedArray(bytesPerRow);
  for (let y = 0; y < height / 2; ++y) {
    const topOffset = y * bytesPerRow;
    const bottomOffset = (height - y - 1) * bytesPerRow;

    // make copy of a row on the top half
    temp.set(imageBuffer.subarray(topOffset, topOffset + bytesPerRow));

    // copy a row from the bottom half to the top
    imageBuffer.copyWithin(topOffset, bottomOffset, bottomOffset + bytesPerRow);

    // copy the copy of the top half row to the bottom half
    imageBuffer.set(temp, bottomOffset);
  }
};

export const xFlipImageBuffer = (
  imageBuffer: Uint8ClampedArray,
  width: number
) => {
  const height = imageBuffer.length / 4 / width;
  const temp = [];
  //TODO verify width/2
  for (let i = 0; i < width / 2; i++) {
    for (let j = 0; j < height; j++) {
      const idLeft = 4 * (width * j + i);
      const idRight = 4 * (width * j + (width - i - 1));
      //const temp = imageBuffer.slice(idRight, idRight + 4);
      temp[0] = imageBuffer[idRight + 0];
      temp[1] = imageBuffer[idRight + 1];
      temp[2] = imageBuffer[idRight + 2];
      temp[3] = imageBuffer[idRight + 3];
      imageBuffer.copyWithin(idRight, idLeft, idLeft + 4);
      imageBuffer[idLeft + 0] = temp[0];
      imageBuffer[idLeft + 1] = temp[1];
      imageBuffer[idLeft + 2] = temp[2];
      imageBuffer[idLeft + 3] = temp[3];
    }
  }
};

// if the texture data is alpha premultiplied, we need to un-premultiply it before saving it as a png
export const unPreMultiplyAlpha = (data: Uint8ClampedArray) => {
  for (let i = 0; i < data.length; i += 4) {
    const alpha = data[i + 3];
    if (alpha === 0) {
      data[i] = 0;
      data[i + 1] = 0;
      data[i + 2] = 0;
    } else {
      data[i] = Math.round(data[i] / (alpha / 255));
      data[i + 1] = Math.round(data[i + 1] / (alpha / 255));
      data[i + 2] = Math.round(data[i + 2] / (alpha / 255));
    }
  }
};

export const useCameraZoom = () => {
  const initialCameraZoom = useThree((s) => s.camera.zoom);
  const [zoom, setZoom] = useState(initialCameraZoom);
  const controls = useMapControls();
  const store = useStore();

  useEffect(() => {
    if (!controls) return;

    const changeEvent = (e: MoveToEvent) => {
      setZoom(e.goal.zoom);
    };
    setZoom(store.getState().camera.zoom);
    controls.addEventListener('moveTo', changeEvent);

    return () => {
      controls.removeEventListener('moveTo', changeEvent);
    };
  }, [controls, store]);

  return zoom;
};

export type BoundingBox = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export const getElementsBoundingBox = (
  elements: ClientSideWorkbenchElementData[]
): BoundingBox => {
  let minX = Infinity;
  let minY = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;

  elements.forEach((element) => {
    const size = getElementSize(element);
    minX = Math.min(minX, element.x - size.width / 2);
    minY = Math.min(minY, element.y - size.height / 2);
    maxX = Math.max(maxX, element.x + size.width / 2);
    maxY = Math.max(maxY, element.y + size.height / 2);
  });

  const width = maxX - minX;
  const height = maxY - minY;
  return { x: minX + width / 2, y: minY + height / 2, width, height };
};

export const isDarkColor = (color: string) => {
  const c = color.substring(1);
  const rgb = parseInt(c, 16);
  // extract colors
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

  return luma < 128;
};

// NOTE Used to handle iPad-specific issues
//
//      userAgent note:
//        since iPadOS 13, iPads identify as desktop Macs (and only show desktop versions of websites),
//        to differentiate between iPads and Macs we need to check for touch support.
//        Alternative navigator.platform (deprecated) - https://developer.mozilla.org/en-US/docs/Web/API/Navigator/platform
//
//      source: https://developer.apple.com/forums/thread/653759
//
//      related iPad issues:
//        - https://github.com/pmndrs/react-three-fiber/issues/3309#issuecomment-2286357536
export const isIpad = (() => {
  if (typeof window === `undefined` || typeof navigator === `undefined`)
    return false;

  return /iPad|Macintosh/.test(navigator.userAgent) && 'ontouchend' in document;
})();

export const getBoundingBoxEdges = (boundingBox: BoundingBox) => ({
  left: boundingBox.x - boundingBox.width / 2,
  right: boundingBox.x + boundingBox.width / 2,
  top: boundingBox.y + boundingBox.height / 2,
  bottom: boundingBox.y - boundingBox.height / 2,
});

export const getVisibleWorkbenchElements = (camera: Camera, scene: Scene) => {
  const cameraVisibleSpace = getCameraBoundingBox(camera as OrthographicCamera);
  return filterChildByWorkbenchObjectUserData(
    scene,
    (userData) =>
      userData.workbenchObjectType === 'container' &&
      isWorkbenchElementVisible(userData, cameraVisibleSpace)
  );
};

const isWorkbenchElementVisible = (
  userData: WorkbenchElementContainerUserData,
  cameraVisibleSpace: {
    left: number;
    right: number;
    top: number;
    bottom: number;
  } | null
): boolean => {
  if (!cameraVisibleSpace) return true;
  return (
    cameraVisibleSpace.left < userData.elementX + userData.elementWidth / 2 &&
    cameraVisibleSpace.right > userData.elementX - userData.elementWidth / 2 &&
    cameraVisibleSpace.top > userData.elementY - userData.elementHeight / 2 &&
    cameraVisibleSpace.bottom < userData.elementY + userData.elementHeight / 2
  );
};

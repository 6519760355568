import styled, { useTheme } from 'styled-components';
import {
  imageToCanvas,
  applyMaskToImage,
  AddToSelectionIcon,
  ALT_KEY,
  CopyContentIcon,
  SHIFT_KEY,
  SubtractFromSelectionIcon,
  ToolbarButton,
  ToolbarButtonState,
  Button,
  CMD_KEY_PREFIX,
  InvertSelectionIcon,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  ToolbarDivider,
} from '@vizcom/shared-ui-components';

import {
  Drawing2dStudio,
  DrawingLayer,
  useDrawingSyncedState,
} from '../../../../lib/useDrawingSyncedState';
import {
  useSelectionApiStore,
  useSubscribeToSelectionApi,
} from '../../selection/useSelectionApi';
import { MaskOperation } from '../../selection/utils';
import {
  useWorkbenchStudioState,
  WorkbenchStudioToolType,
} from '../../studioState';
import { Hotkey } from '../../style';
import { addLayer } from '../../utils';
import { ToolbarSlider } from '../ToolbarSlider';
import { SelectionOffsetToolbar } from './SelectionOffsetToolbar';

type SelectionToolbarProps = {
  activeLayer: DrawingLayer | undefined;
  drawing: Drawing2dStudio;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  setActiveLayer: (id: string | undefined) => void;
};

export const SelectionToolbar = (props: SelectionToolbarProps) => {
  const theme = useTheme();

  const selectionApiStore = useSelectionApiStore();
  const { hasMask, offsetModeActivated } = useSubscribeToSelectionApi(
    (state) => ({
      hasMask: state.hasMask,
      offsetModeActivated: state.offsetModeActivated,
    })
  );
  const {
    tool,
    setTool,
    selectionSettings: {
      brushSettings: { toolSize, setToolSize },
      setOffset,
    },
  } = useWorkbenchStudioState();
  const { operation, setOperation } = useWorkbenchStudioState((s) => ({
    tool: s.tool,
    operation: s.selectionSettings.operation,
    setOperation: s.selectionSettings.setOperation,
  }));

  const drawingSize: [number, number] = [
    props.drawing.width,
    props.drawing.height,
  ];

  const copySelectionToNewLayer = async () => {
    const layer = props.activeLayer;

    if (!layer) {
      return;
    }
    const maskData = selectionApiStore.getState().getSelectionImage()?.data;
    if (!maskData) {
      return;
    }

    let imageData;

    if (layer.imagePath) {
      const { canvas, ctx } = await imageToCanvas(layer.imagePath);
      imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      applyMaskToImage(imageData.data, maskData);
    } else if (layer.fill.length === 7) {
      const colorValue = parseInt(layer.fill.slice(1), 16);
      const r = (colorValue >> 16) & 0xff;
      const g = (colorValue >> 8) & 0xff;
      const b = colorValue & 0xff;

      const bufferSize = drawingSize[0] * drawingSize[1] * 4;
      const pixels = new Uint8ClampedArray(bufferSize);
      for (let i = 0; i < bufferSize; i += 4) {
        pixels[i] = r;
        pixels[i + 1] = g;
        pixels[i + 2] = b;
        pixels[i + 3] = maskData[i];
      }
      imageData = new ImageData(pixels, drawingSize[0]);
    } else {
      return;
    }

    const id = addLayer({
      drawing: props.drawing,
      activeLayerId: layer.id,
      handleAction: props.handleAction,
      layerData: {
        name: `${layer.name} copy`,
        image: imageData,
      },
    });
    setTool(WorkbenchStudioToolType.Move);
    props.setActiveLayer(id);
    selectionApiStore.getState().deselectMask();
  };

  return (
    <>
      {!offsetModeActivated && (
        <>
          {tool === WorkbenchStudioToolType.BrushSelection && (
            <ToolbarSliderContainer>
              <ToolbarSlider
                value={toolSize}
                setValue={setToolSize}
                label="Size"
                min={1}
                max={250}
                step={1}
                unit="px"
                isLogScale
              />
              <ToolbarDivider />
            </ToolbarSliderContainer>
          )}
          <ToolbarButton
            state={
              operation === MaskOperation.Add
                ? ToolbarButtonState.ACTIVE
                : ToolbarButtonState.INACTIVE
            }
            icon={<AddToSelectionIcon />}
            onClick={() => {
              setOperation(
                operation === MaskOperation.Add
                  ? MaskOperation.Replace
                  : MaskOperation.Add
              );
            }}
            tooltip={
              <>
                Add to selection <Hotkey>{SHIFT_KEY}</Hotkey>
              </>
            }
            tooltipOptions={{ placement: 'bottom' }}
          />
          <ToolbarButton
            state={
              operation === MaskOperation.Remove
                ? ToolbarButtonState.ACTIVE
                : ToolbarButtonState.INACTIVE
            }
            icon={<SubtractFromSelectionIcon />}
            onClick={() => {
              setOperation(
                operation === MaskOperation.Remove
                  ? MaskOperation.Replace
                  : MaskOperation.Remove
              );
            }}
            tooltip={
              <>
                Remove from selection <Hotkey>{ALT_KEY}</Hotkey>
              </>
            }
            tooltipOptions={{ placement: 'bottom' }}
          />
          <ToolbarButton
            state={
              !hasMask
                ? ToolbarButtonState.DISABLED
                : ToolbarButtonState.INACTIVE
            }
            icon={<CopyContentIcon />}
            onClick={() => copySelectionToNewLayer()}
            tooltip="Copy selection to layer"
            tooltipOptions={{ placement: 'bottom' }}
          />

          <ToolbarButton
            icon={<InvertSelectionIcon />}
            onClick={() => selectionApiStore.getState().invertMask()}
            tooltip={
              <>
                Invert selection <Hotkey>{CMD_KEY_PREFIX}I</Hotkey>
              </>
            }
            tooltipOptions={{ placement: 'bottom' }}
          />
          <RichTooltip placement="bottom">
            <RichTooltipTrigger>
              <Button
                disabled={!hasMask}
                variant="secondary"
                onClick={() => {
                  setOffset(0);
                  selectionApiStore.getState().enterOffsetMode();
                }}
                style={{
                  fontSize: '12px',
                  padding: '0 18px',
                  height: '32px',
                }}
              >
                Offset
              </Button>
            </RichTooltipTrigger>
            <RichTooltipContent style={{ color: theme.deprecated.white }}>
              Offset
            </RichTooltipContent>
          </RichTooltip>
          <RichTooltip placement="bottom">
            <RichTooltipTrigger>
              <Button
                variant="secondary"
                onClick={() => selectionApiStore.getState().deselectMask()}
                style={{
                  fontSize: '12px',
                  padding: '0 18px',
                  height: '32px',
                }}
              >
                Deselect
              </Button>
            </RichTooltipTrigger>
            <RichTooltipContent style={{ color: theme.deprecated.white }}>
              Deselect <Hotkey>{CMD_KEY_PREFIX}D</Hotkey>
            </RichTooltipContent>
          </RichTooltip>
        </>
      )}
      {offsetModeActivated && <SelectionOffsetToolbar />}
    </>
  );
};

const ToolbarSliderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding-left: 4px;
`;

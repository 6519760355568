import { createCanvas } from './imageUtils';

export const getImageCoverSize = (
  sourceWidth: number,
  sourceHeight: number,
  targetWidth: number,
  targetHeight: number
) => {
  const sourceRatio = sourceWidth / sourceHeight;
  const targetRatio = targetWidth / targetHeight;

  let width = targetWidth;
  let height = targetHeight;

  if (sourceRatio > targetRatio) {
    height = targetWidth / sourceRatio;
  } else {
    width = targetHeight * sourceRatio;
  }

  return [width, height];
};

type PixelCountOrSize = number | { width: number; height: number };

const getResizedImageCanvas = async (
  blob: Blob,
  pixelCountOrSize: PixelCountOrSize
) => {
  const { image, unload } = await loadBlobToImage(blob);

  let width, height;

  if (typeof pixelCountOrSize === 'number') {
    const factor = pixelCountOrSize / (image.width * image.height);
    width = Math.round(image.width * Math.sqrt(factor));
    height = Math.round((image.height * width) / image.width);
  } else {
    width = pixelCountOrSize.width;
    height = pixelCountOrSize.height;
  }

  const { canvas, ctx } = createCanvas(width, height, {
    willReadFrequently: true,
  });

  ctx.imageSmoothingQuality = 'high';

  ctx.drawImage(
    image,
    0,
    0,
    image.width,
    image.height,
    0,
    0,
    canvas.width,
    canvas.height
  );

  unload();

  return { canvas, ctx };
};

export const loadBlobToImage = (blob: Blob) =>
  new Promise<{ image: HTMLImageElement; unload: () => void }>(
    (resolve, reject) => {
      const img = new Image();
      img.onerror = () => {
        URL.revokeObjectURL(img.src);
        reject(new Error('Invalid image file'));
      };
      img.onload = () => {
        resolve({ image: img, unload: () => URL.revokeObjectURL(img.src) });
      };
      img.src = URL.createObjectURL(blob);
    }
  );

export const canvasToBlob = (
  canvas: HTMLCanvasElement | OffscreenCanvas,
  type: string
) => {
  if (
    typeof HTMLCanvasElement !== 'undefined' &&
    canvas instanceof HTMLCanvasElement
  ) {
    return new Promise<Blob>((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          return reject(new Error('failed to create blob'));
        }
        resolve(blob);
      }, type);
    });
  }

  if (
    typeof OffscreenCanvas !== 'undefined' &&
    canvas instanceof OffscreenCanvas
  ) {
    return canvas.convertToBlob({
      type,
    });
  }

  throw new Error('unsupported canvas type passed to canvasToBlob');
};

type OutputImageFormat = 'image/png' | 'image/webp';

export const resizeImageToPixelCount = async (
  blob: Blob,
  pixelCount: PixelCountOrSize,
  type: OutputImageFormat = 'image/png'
) => {
  const { canvas } = await getResizedImageCanvas(blob, pixelCount);
  return {
    image: await canvasToBlob(canvas, type),
    width: canvas.width,
    height: canvas.height,
  };
};

export const resizeImageToPixelCountImageData = async (
  blob: Blob,
  pixelCount: PixelCountOrSize
) => {
  const { canvas, ctx } = await getResizedImageCanvas(blob, pixelCount);
  return {
    image: ctx.getImageData(0, 0, canvas.width, canvas.height),
    width: canvas.width,
    height: canvas.height,
  };
};

// return a new imageData of targetWidth by targetHeight which contains the image resized to cover the target size
export const resizeImageToCoverSize = async (
  blob: Blob,
  targetWidth: number,
  targetHeight: number
) => {
  const { image, unload } = await loadBlobToImage(blob);
  const { canvas, ctx } = createCanvas(targetWidth, targetHeight, {
    willReadFrequently: true,
  });
  const coverSize = getImageCoverSize(
    image.width,
    image.height,
    targetWidth,
    targetHeight
  );

  if (ctx === null) {
    throw new Error('failed to create ctx');
  }

  ctx.imageSmoothingQuality = 'high';
  ctx.drawImage(
    image,
    0,
    0,
    image.width,
    image.height,
    coverSize[0] < targetWidth ? (targetWidth - coverSize[0]) / 2 : 0,
    coverSize[1] < targetHeight ? (targetHeight - coverSize[1]) / 2 : 0,
    coverSize[0],
    coverSize[1]
  );

  unload();

  return ctx.getImageData(0, 0, canvas.width, canvas.height);
};

export const resizeImageToCoverSizeBlob = async (
  blob: Blob,
  targetWidth: number,
  targetHeight: number
) => {
  const res = await getResizedImageCanvas(blob, {
    width: targetWidth,
    height: targetHeight,
  });
  return new Promise<Blob>((resolve, reject) => {
    res.canvas.toBlob((blob) => {
      if (!blob) {
        return reject(new Error('failed to create blob'));
      }
      resolve(blob);
    });
  });
};

export function createImageElementWithSrc(
  src: string
): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.src = src;

    img.onload = () => resolve(img);
    img.onerror = (error) => reject(error);
  });
}

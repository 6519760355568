import { useThree } from '@react-three/fiber';
import { useRef, useState } from 'react';
import { Mesh, OrthographicCamera } from 'three';
import { clamp } from 'three/src/math/MathUtils';

import { simplifyTransform, layerPositionToScreen } from '../../../helpers';
import { HtmlOverlay } from '../../../utils/HtmlOverlay';
import { useSelectionApiStore } from '../../selection/useSelectionApi';
import { MaskOperation } from '../../selection/utils';
import { useWorkbenchStudioState } from '../../studioState';
import { BrushSegment } from '../../types';
import { BrushCursorPreview } from '../BrushEngine/BrushCursorPreview';
import { useBrushSegments } from '../BrushEngine/useBrushSegments';
import { EventMesh } from '../EventMesh';

function drawPolygon(
  ctx: CanvasRenderingContext2D,
  segments: BrushSegment[],
  lastSegment: BrushSegment
) {
  ctx.moveTo(lastSegment.startPosition[0], lastSegment.startPosition[1]);
  ctx.lineTo(lastSegment.endPosition[0], lastSegment.endPosition[1]);

  segments.forEach((segment) => {
    ctx.lineTo(segment.endPosition[0], segment.endPosition[1]);
  });
}

export const Lasso = ({ drawingSize }: { drawingSize: [number, number] }) => {
  const selectionApiStore = useSelectionApiStore();
  const operation = useWorkbenchStudioState(
    (s) => s.selectionSettings.operation
  );

  const eventPlaneRef = useRef<Mesh>(null!);

  const segmentsRef = useRef<BrushSegment[]>([]);
  const [svgPath, setSvgPath] = useState('');

  const camera = useThree((s) => s.camera);

  const { bind } = useBrushSegments({
    drawingSize,
    eventPlaneRef,
    onNewSegments(_, segments) {
      segmentsRef.current.push(...segments);
      const projectPoint = simplifyTransform(
        (x: number, y: number) =>
          layerPositionToScreen(
            [x, y],
            camera as OrthographicCamera,
            eventPlaneRef.current,
            drawingSize
          ) as [number, number]
      );

      //Build svg path
      const p = [];
      const proj0 = projectPoint(
        clamp(segmentsRef.current[0].startPosition[0], 0, drawingSize[0]),
        clamp(segmentsRef.current[0].startPosition[1], 0, drawingSize[1])
      );
      p.push('M', proj0[0], proj0[1]);
      segmentsRef.current.forEach((segment) => {
        const proj = projectPoint(
          clamp(segment.endPosition[0], 0, drawingSize[0]),
          clamp(segment.endPosition[1], 0, drawingSize[1])
        );
        p.push('L', proj[0], proj[1]);
      });
      setSvgPath(p.join(' '));
    },

    onStrokeEnd: (event) => {
      // draw mask on canvas (instead of only the segments)
      // if there's not enough segments, don't draw anything and return an empty mask
      // this will reset the mask state if the shift key is not pressed
      if (
        operation === MaskOperation.Replace &&
        segmentsRef.current.length <= 2
      ) {
        selectionApiStore.getState().deselectMask();
      } else if (segmentsRef.current.length >= 3) {
        selectionApiStore.getState().editSelectionCanvas((ctx) => {
          if (operation === MaskOperation.Replace) {
            ctx.fillStyle = '#000000';
            ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
          }

          if (
            !selectionApiStore.getState().hasMask ||
            operation === MaskOperation.Replace ||
            operation === MaskOperation.Add
          ) {
            ctx.fillStyle = '#ffffff';
          } else if (operation === MaskOperation.Remove) {
            ctx.fillStyle = '#000000';
          }

          ctx.beginPath();
          drawPolygon(ctx, segmentsRef.current, segmentsRef.current[0]);
          ctx.closePath();
          ctx.fill();
        });
      }

      segmentsRef.current = [];
      setSvgPath('');
    },
  });

  return (
    <>
      <EventMesh
        drawingSize={drawingSize}
        eventMeshProps={bind() as any}
        ref={eventPlaneRef}
      />
      <BrushCursorPreview
        drawingSize={drawingSize}
        toolSize={0}
        toolAspect={1}
        toolAngle={0}
        color={'#000000'}
      />
      <HtmlOverlay>
        {/* use svg to display the lasso at a resolution independant of the zoom */}
        <svg
          version="1.1"
          width="100%"
          height="100%"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            zIndex: '10000000',
          }}
        >
          <path
            d={svgPath}
            fill="none"
            stroke="#0000ff"
            strokeWidth="5"
            strokeLinecap="round"
            strokeDasharray="5,10"
            opacity="0.7"
          />
        </svg>
      </HtmlOverlay>
    </>
  );
};

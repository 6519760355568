import { useThree } from '@react-three/fiber';
import { WorkbenchContentFragment } from 'libs/shared/data-access/graphql/src/gql/graphql';
import { PropsWithChildren, useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import { filterExists } from '@vizcom/shared/js-utils';
import {
  ArrangeIcon,
  DownloadIcon,
  DuplicateIcon,
  ImageMetadataIcon,
  imageToBlob,
  PaletteIcon,
  SectionIcon,
  Toolbar,
  ToolbarButton,
  ToolbarButtonState,
  ToolbarDivider,
  useKeyboardShortcut,
} from '@vizcom/shared-ui-components';

import { ClientSideWorkbenchElementData } from '../lib/clientState';
import { downloadWorkbenchDrawings } from '../lib/downloadWorkbenchDrawings';
import { useWorkbenchElementSelectionState } from '../lib/elementSelectionState';
import { useWorkbenchSyncedState } from '../lib/useWorkbenchSyncedState';
import { elementIsDrawing, useIsWorkbenchViewer } from '../lib/utils';
import { useDrawingLocalState } from './elements/drawing/drawingLocalState';
import { limitSourceImages } from './elements/palette/helpers';
import {
  SECTION_DEFAULT_COLOR,
  SECTION_PADDING,
} from './elements/section/WorkbenchElementSection';
import { getSectionElements } from './elements/section/helpers';
import {
  MAX_Z_POSITION,
  getElementSize,
  getElementsBoundingBox,
  getWorkbenchElementZPositionRange,
} from './helpers';
import { masonryLayoutBestFit } from './utils/layoutHelpers';

type WorkbenchElementsExtraProps = PropsWithChildren<{
  workbench: WorkbenchContentFragment;
  elements: ClientSideWorkbenchElementData[];
  isDragging: boolean;
  flipped: boolean;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
}>;

export const WorkbenchElementsExtra = ({
  workbench,
  elements,
  isDragging,
  flipped,
  handleAction,
}: WorkbenchElementsExtraProps) => {
  const isViewer = useIsWorkbenchViewer();
  const scene = useThree((s) => s.scene);
  const setFocusedElementsId = useWorkbenchElementSelectionState(
    (state) => state.setFocusedElementsId
  );
  const drawingLocalState = useDrawingLocalState();

  const selectedDrawings = elements.filter(elementIsDrawing);

  const handleDuplicate = useCallback(() => {
    const sections = elements.filter(
      (e) => e.__typename === 'WorkbenchElementSection'
    );
    const sectionElements = sections.flatMap((section) =>
      getSectionElements(scene, section)
    );
    const allElementIdsSet = new Set([
      ...elements.map((e) => e.id),
      ...sectionElements.map((e) => e.userData.elementId),
    ]);
    const allElementIds = Array.from(allElementIdsSet);
    const newElementIds = allElementIds.map(() => uuid());

    handleAction({
      type: 'duplicateElements',
      elementIds: allElementIds,
      newElementIds,
    });
    setFocusedElementsId(newElementIds.join('/'));
  }, [elements, handleAction, scene, setFocusedElementsId]);

  const handleArrange = useCallback(async () => {
    handleAction({
      type: 'multiPosition',
      newElementData: getNewElementData(elements),
    });
  }, [elements, handleAction]);

  const handleCreateSection = useCallback(async () => {
    const { x, y, width, height } = getElementsBoundingBox(elements);
    const zRange = getWorkbenchElementZPositionRange(scene);
    const zIndex = isFinite(zRange[1]) ? zRange[1] + 1 : MAX_Z_POSITION / 2;

    const sectionId = uuid();
    handleAction({
      type: 'createElements',
      newElements: [
        {
          id: sectionId,
          updatedAt: '0',
          x: x,
          y: y,
          width: width + SECTION_PADDING,
          height: height + SECTION_PADDING,
          zIndex,
          __typename: 'WorkbenchElementSection',
          color: SECTION_DEFAULT_COLOR,
          title: '',
        },
      ],
    });

    setFocusedElementsId(sectionId);
  }, [elements, handleAction, scene, setFocusedElementsId]);

  const handleCreatePalette = useCallback(async () => {
    const { x, y, width, height } = getElementsBoundingBox(selectedDrawings);
    const workbenchElementPaletteId = uuid();

    const zRange = getWorkbenchElementZPositionRange(scene);
    const zIndex = isFinite(zRange[0]) ? zRange[0] - 1 : MAX_Z_POSITION / 4;

    const sourceDrawingNodes = (
      await Promise.all(
        limitSourceImages(selectedDrawings, 0).map(async (sourceImage) => {
          if (
            !sourceImage.thumbnailPath ||
            typeof sourceImage.thumbnailPath !== 'string'
          ) {
            return;
          }
          return {
            __typename: 'WorkbenchElementPaletteSourceImage' as const,
            id: sourceImage.id,
            imagePath: await imageToBlob(sourceImage.thumbnailPath),
            workbenchElementPaletteId,
          };
        })
      )
    ).filter(filterExists);

    handleAction({
      type: 'createElements',
      newElements: [
        {
          id: workbenchElementPaletteId,
          __typename: 'WorkbenchElementPalette',
          x: x + width + 30,
          y,
          zIndex,
          width: width + 20,
          height: height + 20,
          status: 'idle',
          tags: [],
          name: '',
          sourceImages: {
            nodes: sourceDrawingNodes,
          },
          usageCount: 0,
        },
      ],
    });

    const sourceDrawingIds = sourceDrawingNodes.map(({ id }) => id);

    handleAction({
      type: 'insertDrawingsToPalette',
      id: workbenchElementPaletteId,
      sourceDrawingIds,
    });

    setFocusedElementsId(workbenchElementPaletteId);
  }, [handleAction, scene, selectedDrawings, setFocusedElementsId]);

  const handleExportImages = async (e: React.MouseEvent<HTMLButtonElement>) => {
    await downloadWorkbenchDrawings(
      selectedDrawings,
      workbench.name,
      workbench.id
    );
    (e.target as HTMLButtonElement).blur();
  };

  useKeyboardShortcut(
    's',
    () => {
      handleCreateSection();
    },
    {
      shift: true,
    }
  );

  if (isDragging) {
    return null;
  }

  return (
    <Toolbar
      position={flipped ? 'centered-below' : 'centered-above'}
      onPointerDown={(e) => e.stopPropagation()}
    >
      <ToolbarButton
        onClick={handleArrange}
        state={
          isViewer ? ToolbarButtonState.DISABLED : ToolbarButtonState.INACTIVE
        }
        icon={<ArrangeIcon />}
        tooltip="Arrange"
      />
      <ToolbarDivider />

      {selectedDrawings.length === elements.length &&
        selectedDrawings.length > 0 && (
          <>
            <ToolbarButton
              onClick={handleCreatePalette}
              icon={<PaletteIcon />}
              tooltip="Create Palette"
              state={
                isViewer
                  ? ToolbarButtonState.DISABLED
                  : ToolbarButtonState.INACTIVE
              }
            />
            <ToolbarDivider />
          </>
        )}

      <ToolbarButton
        onClick={handleCreateSection}
        state={
          isViewer ? ToolbarButtonState.DISABLED : ToolbarButtonState.INACTIVE
        }
        icon={<SectionIcon />}
        tooltip="Section"
      />
      <ToolbarDivider />

      {selectedDrawings.length > 0 && (
        <>
          <ToolbarButton
            onClick={handleExportImages}
            icon={<DownloadIcon />}
            tooltip="Download Images"
          />
          <ToolbarDivider />
          <ToolbarButton
            onClick={() => {
              drawingLocalState.toggleMetadata(
                selectedDrawings.map(({ id }) => id)
              );
            }}
            icon={<ImageMetadataIcon />}
            tooltip="Show image details"
          />
          <ToolbarDivider />
        </>
      )}

      <ToolbarButton
        onClick={handleDuplicate}
        state={
          isViewer ? ToolbarButtonState.DISABLED : ToolbarButtonState.INACTIVE
        }
        icon={<DuplicateIcon />}
        tooltip="Duplicate"
      />
    </Toolbar>
  );
};

/**
 * Calculates new positions for a set of workbench elements.
 * @param elements - The workbench elements to position.
 * @returns The new positions for the elements.
 */
function getNewElementData(elements: ClientSideWorkbenchElementData[]) {
  const formattedElements = elements.map((e) => ({
    ...e,
    ...getElementSize(e),
  }));
  const columns = formattedElements.length > 8 ? 4 : 2;
  return masonryLayoutBestFit(formattedElements, columns);
}

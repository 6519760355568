import styled from 'styled-components';
import { CloseIcon, LineIcon, Text } from '@vizcom/shared-ui-components';

import { useWorkbenchStudioState } from '../../studioState';

export const SymmetrySettings = () => {
  const { symmetry, setSymmetry } = useWorkbenchStudioState();

  const onCheckboxClick = () => {
    setSymmetry({
      ...symmetry,
      allowCrossingAxis: !symmetry.allowCrossingAxis,
    });
  };

  return (
    <>
      <Axis
        $active={!symmetry.enabled}
        onClick={() => {
          setSymmetry({
            ...symmetry,
            enabled: false,
          });
        }}
      >
        <CloseIcon />
        <Text>Mirroring Off</Text>
      </Axis>
      <Axis
        $active={
          symmetry.enabled && Math.abs(symmetry.rotation % Math.PI) === 0
        }
        onClick={() => {
          setSymmetry({
            ...symmetry,
            enabled: true,
            origin: [0.5, 0.5],
            rotation: 0,
          });
        }}
      >
        <VerticalAxis />
        <div>Vertical Mirroring</div>
      </Axis>
      <Axis
        $active={
          symmetry.enabled &&
          Math.abs(symmetry.rotation % Math.PI) === Math.PI * 0.5
        }
        onClick={() => {
          setSymmetry({
            ...symmetry,
            enabled: true,
            origin: [0.5, 0.5],
            rotation: Math.PI * -0.5,
          });
        }}
      >
        <HorizontalAxis />
        <div>Horizontal Mirroring</div>
      </Axis>

      {symmetry.enabled && (
        <CheckboxLabel onClick={onCheckboxClick}>
          <Checkbox
            $checked={symmetry.allowCrossingAxis}
            onClick={onCheckboxClick}
          />
          <Text type="b3">Allow crossing symmetry axis</Text>
        </CheckboxLabel>
      )}
    </>
  );
};

const HorizontalAxis = styled(LineIcon)`
  transform: rotate(45deg);
`;

const VerticalAxis = styled(LineIcon)`
  transform: rotate(-45deg);
`;

const Axis = styled.button<{ $active?: boolean }>`
  border: 0;
  margin: 0;
  padding: 0.5rem 12px;
  background: ${({ $active, theme }) =>
    $active ? theme.surface.secondary : 'transparent'};
  cursor: pointer;
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  justify-content: center;
  gap: 12px;
  color: ${({ theme }) => theme.text.body};
  &:disabled {
    color: ${({ theme }) => theme.text.bodyDisabled};
    cursor: default;
  }
  text-align: left;
  transition: background-color 0.1s ease, color 0.1s ease;
  border-radius: ${({ theme }) => theme.borderRadius.m};

  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.surface.secondary};
  }
`;

const CheckboxLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 12px 0.25rem;
  color: ${({ theme }) => theme.text.subtext};
  font-weight: 600;
  gap: 12px;
  margin-top: 6px;
`;

const Checkbox = styled.div<{ $checked: boolean }>`
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid
    ${({ $checked, theme }) =>
      $checked
        ? theme.deprecated.primary.default
        : theme.deprecated.secondary.disabled};
  border-radius: ${({ theme }) => theme.borderRadius.s};
  color: ${({ theme }) => theme.text.body};
  ::after {
    content: '${({ $checked }) => ($checked ? '✔' : '')}';
    font-size: 0.5rem;
  }
`;

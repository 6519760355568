import { useOrganizationRefreshTrigger } from 'libs/web/features/workbench/src/lib/useOrganizationRefresher';
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  OrganizationSubscriptionData,
  OrganizationSubscriptionPlan,
  useOrganizationSubscription,
} from '@vizcom/shared/data-access/graphql';
import { billingIntervalWording } from '@vizcom/shared/plans-limit';
import {
  FullPageDarkLoader,
  Text,
  useSelectedOrganization,
  OrganizationPlansTable,
  FormattedDate,
  usePaywallModalState,
  addToast,
} from '@vizcom/shared-ui-components';

import {
  SettingsPageContainer,
  SettingsPageHeader,
  SettingsPageDivider,
} from '../components/SettingsPageLayout';
import { InviteUsersBanner } from './Banners/InviteUsersBanner';
import { OrganizationInvoices } from './OrganizationInvoices';
import { PaymentMethodInfosCard } from './PaymentMethodInfosCard';
import { PendingPaymentBanner } from './PendingPaymentBanner';
import { SubscriptionInfosCard } from './SubscriptionInfosCard';

export const OrganizationSubscriptionPage = ({
  openSubscriptionUpdateModal = false,
}: {
  openSubscriptionUpdateModal: boolean;
}) => {
  const { data: organization, loading } = useSelectedOrganization();
  const { data: organizationSubscription, fetching } =
    useOrganizationSubscription(organization?.id);
  const paywallModalState = usePaywallModalState();

  useWatchForSubscriptionUpdate();

  useEffect(() => {
    if (paywallModalState.isOpen) {
      paywallModalState.close();
    }
  }, [paywallModalState]);

  if (fetching || loading) return <FullPageDarkLoader />;

  if (
    !organization ||
    !organizationSubscription ||
    !organizationSubscription.subscription
  ) {
    return (
      <Text block style={{ textAlign: 'center', padding: '60px 10px' }}>
        There was an error fetching this page. Please check the link and retry.
      </Text>
    );
  }

  if (organization.currentUserRole !== 'ADMIN') {
    return (
      <Text block>
        You need to be an administrator of this workspace to access this page.
      </Text>
    );
  }

  const { manualSubscription } = organizationSubscription;

  return (
    <SettingsPageContainer>
      <SettingsPageHeader>
        <Text style={{ fontSize: 18, fontWeight: 600 }}>Plans & Billing</Text>
        <Text color="subtext" type="b1">
          Manage your workspace subscription plan
        </Text>
      </SettingsPageHeader>

      <SettingsPageDivider />

      {organizationSubscription.subscription.pendingUpdate ? (
        <PendingPaymentBanner subscriptionData={organizationSubscription} />
      ) : (
        <InviteUsersBanner organizationId={organization.id} />
      )}

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: 32,
          alignItems: 'stretch',
        }}
      >
        <SubscriptionInfosCard
          subscriptionData={organizationSubscription}
          organizationId={organization.id}
          openSubscriptionUpdateModal={openSubscriptionUpdateModal}
        />

        {!manualSubscription && (
          <PaymentMethodInfosCard
            subscriptionData={organizationSubscription}
            organizationId={organization.id}
          />
        )}
      </div>

      <SettingsPageDivider />

      {!manualSubscription && (
        <>
          <Text block type="sh2" style={{ marginBottom: 24 }}>
            Billing history
          </Text>

          <OrganizationInvoices organizationId={organization.id} />

          <SettingsPageDivider />
        </>
      )}

      <Text block type="sh2" style={{ marginBottom: 10 }}>
        Plans
      </Text>

      <SubscriptionPlanDescription
        organizationSubscription={organizationSubscription}
      />

      <div style={{ marginTop: '32px' }}>
        <OrganizationPlansTable
          organization={organization}
          subscription={organizationSubscription}
        />
      </div>
    </SettingsPageContainer>
  );
};

const SubscriptionPlanDescription = (props: {
  organizationSubscription: OrganizationSubscriptionData;
}) => {
  const { subscriptionPlan, paidSeatsCount, subscription, manualSubscription } =
    props.organizationSubscription;

  if (manualSubscription) return null;

  return (
    <>
      {subscriptionPlan === OrganizationSubscriptionPlan.Free && (
        <Text color="subtext">
          You are currently on the <Text color="body">Starter</Text> plan. Your
          workspace costs <Text color="body">$0 per month</Text>.{' '}
        </Text>
      )}

      {subscriptionPlan === OrganizationSubscriptionPlan.Edu && (
        <Text color="subtext">
          You are currently on the <Text color="body">Education</Text> plan.
          Your workspace costs <Text color="body">$0 per month</Text>.{' '}
        </Text>
      )}

      {subscription?.subscriptionBaseAmount && (
        <Text color="subtext" style={{ textWrap: 'pretty' }}>
          You are currently on the <Text color="body">{subscriptionPlan}</Text>{' '}
          plan. Your workspace of <Text color="body">{paidSeatsCount}</Text>{' '}
          editors costs{' '}
          <Text color="body">${subscription.subscriptionBaseAmount}</Text> per{' '}
          {subscription.billingInterval}
          {subscription.cancelAtPeriodEnd && (
            <Text color="body">
              , and will expires at the end of the period
            </Text>
          )}
          {subscription.nextPhaseSchedule &&
            subscription.nextPhaseSchedule.quantity === paidSeatsCount && (
              <Text color="body">
                , and will change to Professional{' '}
                {
                  billingIntervalWording[
                    subscription.nextPhaseSchedule.interval
                  ]
                }{' '}
                on{' '}
                <FormattedDate
                  date={subscription.nextPhaseSchedule.startDate * 1000}
                />
              </Text>
            )}
          {'. '}
        </Text>
      )}

      <Text color="subtext">
        If you need to speak with our sales team,{' '}
        <Text
          as={Link}
          to="https://www.vizcom.ai/contact"
          target="_blank"
          color="link"
          type="sh2"
        >
          contact us
        </Text>
        .
      </Text>
    </>
  );
};

const useWatchForSubscriptionUpdate = () => {
  const location = useLocation();
  const { triggerOrganizationRefresh } = useOrganizationRefreshTrigger();

  useEffect(() => {
    if (location.search.includes('success=true')) {
      addToast('Your subscription has been updated', { type: 'success' });
      triggerOrganizationRefresh();
    }
  }, [location.search, triggerOrganizationRefresh]);
};

import styled from 'styled-components';
import { Button } from '@vizcom/shared-ui-components';

export const WorkbenchMenuButton = styled(Button)`
  justify-content: flex-start;
  padding: 10px;

  :hover {
    background-color: ${({ theme }) => theme.button.secondaryHover};
  }
`;

import { useRef } from 'react';

import { eventTargetIsInput } from '../helpers';
import { useDocumentEventListener } from './useDocumentEventListener';

// return a ref that's true if the sepcific key is pressed down
export const useKeyPressedRef = (key: string) => {
  const ref = useRef(false);

  useDocumentEventListener('keydown', (e) => {
    if (e.key?.toLowerCase() === key.toLowerCase() && !eventTargetIsInput(e)) {
      ref.current = true;
    }
  });

  useDocumentEventListener('keyup', (e) => {
    // We need to check for Meta key as well because if you combine Meta
    // with another key and release the other key first, the keyup event for Meta only will be triggered.
    if (e.key?.toLowerCase() === key.toLowerCase() || e.key === 'Meta') {
      ref.current = false;
    }
  });

  return ref;
};

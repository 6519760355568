import { useState } from 'react';
import { StoreApi } from 'zustand';
import {
  useDocumentEventListener,
  useWindowEventListener,
  useKeyboardShortcut,
} from '@vizcom/shared-ui-components';

import { copyImageToClipboard } from '../../lib/drawingUtils';
import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../lib/useDrawingSyncedState';
import { useRegister2dStudioHotkeys } from '../hotkeys/hooks';
import { LayersCompositorApi } from './LayersCompositor/context';
import { SelectionApi } from './selection/useSelectionApi';
import { MaskOperation } from './selection/utils';
import {
  WorkbenchStudioToolType,
  isSelectionTool,
  useWorkbenchStudioState,
} from './studioState';
import {
  horizontalMirrorLayers,
  mergeDown,
  mergeSelected,
  verticalMirrorLayers,
} from './utils';

type Props = {
  readOnly: boolean;
  selectionApi: StoreApi<SelectionApi>;
  activeLayerId: string | undefined;
  drawing: Drawing2dStudio | undefined | null;
  layersCompositor: LayersCompositorApi;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  getCompositedImage: (() => ImageData) | undefined;
};

export const useImageStudioHotkeys = ({
  drawing,
  selectionApi,
  activeLayerId,
  layersCompositor,
  handleAction,
  getCompositedImage,
}: Props) => {
  const studioState = useWorkbenchStudioState();

  useRegister2dStudioHotkeys({ studioState });

  useKeyboardShortcut(
    'c',
    () => {
      if (getCompositedImage) {
        copyImageToClipboard(
          getCompositedImage(),
          selectionApi.getState().getSelectionImage()
        );
      }
    },
    {
      ctrl: true,
      shift: true,
    }
  );

  const [initialSelectionState, setInitialSelectionState] =
    useState<MaskOperation>(studioState.selectionSettings.operation);

  useDocumentEventListener('keydown', (e) => {
    if (isSelectionTool(studioState.tool)) {
      if (e.altKey) {
        setInitialSelectionState(studioState.selectionSettings.operation);
        studioState.selectionSettings.setOperation(MaskOperation.Remove);
      }
      if (e.shiftKey) {
        setInitialSelectionState(studioState.selectionSettings.operation);
        studioState.selectionSettings.setOperation(MaskOperation.Add);
      }
    } else if (e.altKey) {
      if (
        studioState.tool === WorkbenchStudioToolType.Brush ||
        studioState.tool === WorkbenchStudioToolType.PaintBucket ||
        studioState.tool === WorkbenchStudioToolType.Line ||
        studioState.tool === WorkbenchStudioToolType.Rectangle ||
        studioState.tool === WorkbenchStudioToolType.Ellipse
      ) {
        e.preventDefault();
        studioState.setCanvasColorPickerSession({
          currentColor: studioState.color,
          callback: (color) => studioState.setColor(color),
        });
      }
    }

    if (e.key === 's') {
      if (
        studioState.tool === WorkbenchStudioToolType.Brush ||
        studioState.tool === WorkbenchStudioToolType.RectangleSelection ||
        studioState.tool === WorkbenchStudioToolType.BrushSelection ||
        studioState.tool === WorkbenchStudioToolType.Eraser ||
        studioState.tool === WorkbenchStudioToolType.MagicEraser
      ) {
        studioState.setResizing(true);
      }
    }
  });

  useDocumentEventListener('keyup', (e) => {
    if (isSelectionTool(studioState.tool)) {
      if (e.key === 'Alt' || e.key === 'Shift') {
        studioState.selectionSettings.setOperation(initialSelectionState);
      }
    } else if (e.key === 'Alt') {
      e.preventDefault();
      studioState.setCanvasColorPickerSession(null);
    }

    if (e.key === 's') {
      studioState.setResizing(false);
    }
  });

  useKeyboardShortcut(
    'h',
    async () => {
      if (!activeLayerId || !drawing) return;

      if (activeLayerId.split('/').length > 1) {
        await mergeSelected(
          drawing,
          activeLayerId,
          layersCompositor,
          handleAction
        );
      } else {
        await mergeDown(drawing, activeLayerId, handleAction, layersCompositor);
      }
    },
    {
      ctrl: true,
      shift: true,
    }
  );

  useKeyboardShortcut(
    'h',
    async () => {
      if (!activeLayerId || !drawing) return;
      await horizontalMirrorLayers(drawing, activeLayerId, handleAction);
    },
    {
      shift: true,
    }
  );

  useKeyboardShortcut(
    'v',
    async () => {
      if (!activeLayerId || !drawing) return;
      await verticalMirrorLayers(drawing, activeLayerId, handleAction);
    },
    {
      shift: true,
    }
  );

  useWindowEventListener('blur', () => {
    studioState.setCanvasColorPickerSession(null);
    studioState.setResizing(false);
  });

  useDocumentEventListener('pointermove', (e) => {
    if (studioState.resizing && e.buttons === 1) {
      studioState
        .getToolSettings()
        .setToolSize(
          Math.max(1, studioState.getToolSettings().toolSize + e.movementX)
        );
    }
  });
};

import { PivotControls, CameraControls } from '@react-three/drei';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { Quaternion, Vector3, Matrix4, Group, Object3D } from 'three';
import { CompositeSceneFullData } from '@vizcom/shared/data-access/graphql';

import { useCompositeSceneSyncedState } from '../../../lib/useCompositeSceneSyncedState';
import { getRootElementForMesh } from '../../utils/getRootElementForMesh';
import { useCompositeSceneEditorContext } from './context';
import { getCompositeSceneNodeUUID } from './utils/compositeSceneNodeUUID';

const position = new Vector3();
const target = new Vector3();
const quaternion = new Quaternion();
const scale = new Vector3();
const worldTransform = new Matrix4();
const localTransform = new Matrix4();

const dragPosition = new Vector3();
const dragQuaternion = new Quaternion();
const dragScale = new Vector3();

export const Controls = ({
  compositeScene,
  handleAction,
  requestPreview,
  onControlsReady,
}: {
  compositeScene: CompositeSceneFullData;
  handleAction: ReturnType<typeof useCompositeSceneSyncedState>['handleAction'];
  requestPreview: () => void;
  onControlsReady: () => void;
}) => {
  /**
   * NOTE Ready state for when the initial transition from camera origin to compositeScene camera position
   *      is finished.
   */
  const [ready, setReady] = useState(false);

  const cameraControlsRef = useRef<CameraControls>(null);
  const pivotControlsRef = useRef<Group>(null);

  const theme = useTheme();
  const { selected, activeCamera } = useCompositeSceneEditorContext();

  const [dirty, setDirty] = useState(false);
  const selectedMatrix = useMemo<Matrix4>(() => {
    if (!selected) {
      return new Matrix4();
    }

    selected.getWorldPosition(position);
    selected.getWorldQuaternion(quaternion);

    // NOTE We use local scale for parented meshes - otherwise the controls may get too small
    return new Matrix4().compose(
      position,
      quaternion,
      new Vector3(1.0, 1.0, 1.0)
    );
  }, [selected]);

  const selectedElementRoot = useMemo(
    () => getRootElementForMesh(compositeScene, selected),
    [selected, compositeScene]
  );

  useEffect(() => {
    if (!pivotControlsRef.current) {
      return;
    }

    /**
     * NOTE Helper prop `isPivotControls` to check in pointer events if user is interacting with
     *      the controls - giving the controls priority over other elements in the scene.
     */
    pivotControlsRef.current.traverse((child) => {
      child.userData.isPivotControls = true;
    });
  }, [pivotControlsRef.current]);

  useEffect(() => {
    /**
     * NOTE Update position of selected element manually.
     *      PivotControls do not support `object` prop, delta matrix has to be applied by hand.
     */
    if (!selected) {
      return;
    }

    const autoUpdate = selected.matrixAutoUpdate;

    selected.updateWorldMatrix(true, true);
    selected.updateMatrix();
    selected.matrixAutoUpdate = false;

    return () => {
      if (!autoUpdate) {
        return;
      }

      selected.matrixAutoUpdate = true;
      selected.matrix.decompose(
        selected.position,
        selected.quaternion,
        selected.scale
      );
    };
  }, [selected]);

  useEffect(() => {
    if (!cameraControlsRef.current) {
      return;
    }

    cameraControlsRef.current.setPosition(-5.0, 2.5, -5.0, false);
    cameraControlsRef.current.setTarget(0.0, 0.0, 0.0, false);
  }, []);

  const moveCameraBackwards = () => {
    const cameraDirection = new Vector3();
    const { cameraPositionX, cameraPositionY, cameraPositionZ } =
      compositeScene;
    cameraControlsRef.current!.camera.getWorldDirection(cameraDirection);

    setTimeout(() => {
      handleAction({
        type: 'updateCompositeScene',
        cameraPositionX: cameraPositionX - cameraDirection.x,
        cameraPositionY: cameraPositionY - cameraDirection.y,
        cameraPositionZ: cameraPositionZ - cameraDirection.z,
      });
    }, 300);
  };

  const activeCameraRef = useRef(activeCamera);
  useEffect(() => {
    if (!activeCamera) {
      if (activeCameraRef.current) {
        moveCameraBackwards();

        activeCameraRef.current = null;
      }

      return;
    }

    const cameraNode = compositeScene.compositeSceneElements.nodes.find(
      (node) => node.id === activeCamera
    )?.meshes.root;

    if (!cameraNode) {
      return;
    }

    const mock = new Object3D();
    mock.position.set(
      cameraNode.position[0],
      cameraNode.position[1],
      cameraNode.position[2]
    );
    mock.quaternion.set(
      cameraNode.quaternion[0],
      cameraNode.quaternion[1],
      cameraNode.quaternion[2],
      cameraNode.quaternion[3]
    );
    mock.updateMatrix();

    const initialPosition = new Vector3();
    const initialDirection = new Vector3();

    mock.getWorldPosition(initialPosition);
    mock.getWorldDirection(initialDirection);

    const target = initialPosition
      .clone()
      .add(initialDirection.multiplyScalar(-2.0));

    handleAction({
      type: 'updateCompositeScene',
      cameraPositionX: initialPosition.x,
      cameraPositionY: initialPosition.y,
      cameraPositionZ: initialPosition.z,
      cameraTargetX: target.x,
      cameraTargetY: target.y,
      cameraTargetZ: target.z,
    });

    activeCameraRef.current = activeCamera;
  }, [activeCamera]);

  useEffect(() => {
    if (!cameraControlsRef.current) {
      return;
    }

    const { camera } = cameraControlsRef.current;

    const onCameraControlsUpdate = () => {
      requestPreview();
    };

    const onCameraControlsRest = () => {
      if (!camera || !cameraControlsRef.current) {
        return;
      }

      camera.getWorldPosition(position);
      cameraControlsRef.current.getTarget(target);

      handleAction({
        type: 'updateCompositeScene',
        cameraPositionX: position.x,
        cameraPositionY: position.y,
        cameraPositionZ: position.z,
        cameraTargetX: target.x,
        cameraTargetY: target.y,
        cameraTargetZ: target.z,
      });

      if (activeCamera) {
        const currentState = compositeScene.compositeSceneElements.nodes.find(
          (node) => node.id === activeCamera
        )?.meshes.root;

        if (!currentState) {
          return;
        }

        handleAction({
          type: 'updateCompositeSceneElement',
          id: activeCamera,
          meshes: {
            root: {
              ...(currentState || {}),
              position: [position.x, position.y, position.z],
              quaternion: [
                camera.quaternion.x,
                camera.quaternion.y,
                camera.quaternion.z,
                camera.quaternion.w,
              ],
              scale: [1.0, 1.0, 1.0],
            },
          },
        });
      }

      if (!ready) {
        setReady(true);
      }
    };

    const controlsEventListener = cameraControlsRef.current;

    controlsEventListener.addEventListener('update', onCameraControlsUpdate);
    controlsEventListener.addEventListener('rest', onCameraControlsRest);

    return () => {
      controlsEventListener.removeEventListener(
        'update',
        onCameraControlsUpdate
      );
      controlsEventListener.removeEventListener('rest', onCameraControlsRest);
    };
  }, [cameraControlsRef.current, handleAction, ready, requestPreview]);

  useEffect(() => {
    if (!cameraControlsRef.current) {
      return;
    }

    if (ready && cameraControlsRef.current.active) {
      // NOTE Discard remote updates while controls are active
      return;
    }

    cameraControlsRef.current.setPosition(
      compositeScene.cameraPositionX,
      compositeScene.cameraPositionY,
      compositeScene.cameraPositionZ,
      true
    );
    cameraControlsRef.current.setTarget(
      compositeScene.cameraTargetX,
      compositeScene.cameraTargetY,
      compositeScene.cameraTargetZ,
      true
    );
  }, [
    compositeScene.cameraPositionX,
    compositeScene.cameraPositionY,
    compositeScene.cameraPositionZ,
    compositeScene.cameraTargetX,
    compositeScene.cameraTargetY,
    compositeScene.cameraTargetZ,
    cameraControlsRef.current,
    ready,
  ]);

  useEffect(() => {
    if (ready && onControlsReady) {
      onControlsReady();
    }
  }, [ready, onControlsReady]);

  // NOTE Initial local scale transformation for nested objects - preserves scaling without continuous multiplication
  //      when pivot controls are active
  const [baseLocalScale, setBaseLocalScale] = useState(
    new Vector3(1.0, 1.0, 1.0)
  );

  useEffect(() => {
    if (!selected) return;

    setBaseLocalScale(selected.getWorldScale(new Vector3()));
  }, [selected]);

  const onPivotControlsDrag = (matrix: Matrix4) => {
    if (!selected) {
      return;
    }

    dragPosition.set(0.0, 0.0, 0.0);
    dragScale.copy(baseLocalScale);
    dragQuaternion.identity();

    localTransform.identity();
    localTransform.compose(dragPosition, dragQuaternion, dragScale);

    worldTransform.identity();
    worldTransform.multiplyMatrices(
      selected.parent!.matrixWorld.clone().invert(),
      matrix
    );
    worldTransform.multiply(localTransform);
    selected.matrix.copy(worldTransform);

    worldTransform.decompose(position, quaternion, scale);

    setDirty(true);
  };

  const onPivotControlsDragEnd = () => {
    if (!selected || !selectedElementRoot || !dirty) {
      return;
    }

    const selectedUuid = getCompositeSceneNodeUUID(selected);
    const currentState = selectedElementRoot.meshes[selectedUuid];
    const updatedState = {
      ...currentState,
      position: position.toArray(),
      quaternion: quaternion.toArray(),
      scale: scale.toArray(),
    };

    handleAction({
      type: 'updateCompositeSceneElement',
      id: selected.userData.rootId,
      meshes: {
        ...selectedElementRoot.meshes,
        [selectedUuid]: updatedState,
      },
    });

    setDirty(false);
  };

  return (
    <>
      <CameraControls
        makeDefault
        ref={cameraControlsRef}
        smoothTime={0.3}
        minDistance={1.0}
      />
      <group
        userData={{
          vizcomUI: true,
        }}
      >
        {selected && (
          <PivotControls
            ref={pivotControlsRef}
            depthTest={false}
            axisColors={[
              theme.deprecated.primary.default,
              theme.deprecated.primary.default,
              theme.deprecated.primary.default,
            ]}
            fixed
            scale={100.0}
            matrix={selectedMatrix}
            onDrag={onPivotControlsDrag}
            onDragEnd={onPivotControlsDragEnd}
          />
        )}
      </group>
    </>
  );
};

import styled from 'styled-components';
import { DropdownIconUrl } from '@vizcom/shared-ui-components';

export const MemberRoleSelect = styled.select`
  width: 100%;
  background-color: transparent;
  background-image: ${(p) => (p.disabled ? '' : `url(${DropdownIconUrl})`)};
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%, 0 0; /* position the dropdown arrow on the right */
  color: ${(p) => p.theme.text.body};
  outline: none;
  border: none;
  padding: 10px 30px 10px 16px;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  cursor: ${(p) => (p.disabled ? 'auto' : 'pointer')};
  appearance: none;

  :focus {
    box-shadow: 0 0 0 1px ${(p) => p.theme.deprecated.primary.default};
    background-color: ${(p) => p.theme.surface.secondary};
  }

  &:active,
  &:focus {
    color: ${(p) => p.theme.text.body};
    background-color: ${(p) => p.theme.surface.secondary};
  }
`;

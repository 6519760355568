import * as Comlink from 'comlink';

import { type Drawing2dStudio } from '../../../../lib/useDrawingSyncedState';

type TWorker = Comlink.Remote<typeof import('./exportLayersVideo.worker')>;

export async function runExportLayersVideo(
  drawing: Drawing2dStudio,
  freePlanMode: boolean,
  watermarkImage: ImageBitmap | null
): Promise<Blob> {
  const worker = new Worker(
    new URL('./exportLayersVideo.worker.ts', import.meta.url),
    { type: 'module' }
  );

  const workerWrap = Comlink.wrap<TWorker>(worker);

  return await workerWrap.exportLayersVideoWorker(
    drawing,
    freePlanMode,
    watermarkImage
  );
}

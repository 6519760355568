import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOrganizationAccessLogs } from '@vizcom/shared/data-access/graphql';
import { featureLimitForOrganizationSubscription } from '@vizcom/shared/plans-limit';
import {
  FormattedDate,
  LoadingLogo,
  ConnectedLegacyPaywallModal,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  TextInput,
  useSelectedOrganization,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

import { useDebouncedValue } from '../../../../../../../libs/shared/utils/hooks';
const ENTRIES_PER_PAGE = 10;

export const OrganizationAccess = () => {
  const navigate = useNavigate();
  const [offset, setOffset] = useState(0);
  const [debouncedFilter, value, setDebouncedFilter] = useDebouncedValue(
    '',
    500
  );

  const { data: organization, loading } = useSelectedOrganization();

  const { value: enabledBySubscriptionPlan, loading: loadingFeatureLimit } =
    featureLimitForOrganizationSubscription(organization, 'logs');

  const access = useOrganizationAccessLogs(
    enabledBySubscriptionPlan ? organization?.id : undefined,
    offset,
    debouncedFilter
  );

  const currentPage = Math.ceil((offset + 1) / ENTRIES_PER_PAGE);
  const totalPages = Math.ceil(access.count / ENTRIES_PER_PAGE);

  useEffect(() => {
    setOffset(0);
  }, [debouncedFilter]);

  const nextPage = () => {
    setOffset((o) => o + ENTRIES_PER_PAGE);
  };

  const prevPage = () => {
    setOffset((o) => o - ENTRIES_PER_PAGE);
  };

  if (access.fetching || loading || loadingFeatureLimit) {
    return <LoadingLogo />;
  }

  if (!enabledBySubscriptionPlan) {
    return (
      <ConnectedLegacyPaywallModal
        isOpen
        onClose={() =>
          navigate(paths.settings.organization.general(organization!.id))
        }
      />
    );
  }

  return (
    <Table
      cols={3}
      currentPage={currentPage}
      totalPages={totalPages}
      total={access.count}
      onNextPage={nextPage}
      onPrevPage={prevPage}
      filters={
        <div style={{ width: '400px' }}>
          <TextInput
            placeholder="Search by member email"
            type="text"
            name="filter-email"
            value={value || ''}
            onChange={(e) => setDebouncedFilter(e.target.value)}
          />
        </div>
      }
    >
      <TableHeader>
        <TableCell>Email</TableCell>
        <TableCell>Date</TableCell>
        <TableCell>IP Address</TableCell>
        <TableCell />
      </TableHeader>
      {access.data?.map((accessLog) => (
        <TableRow key={accessLog.eventDate}>
          <TableCell>{accessLog.userEmail}</TableCell>
          <TableCell>
            <FormattedDate date={accessLog.eventDate} />
          </TableCell>
          <TableCell>{accessLog.ipAddress}</TableCell>
          <TableCell />
        </TableRow>
      ))}
    </Table>
  );
};

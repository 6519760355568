import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Text } from '@vizcom/shared-ui-components';

import { ClientSideWorkbenchElementPlaceholder } from '../../../lib/clientState';
import { LoadingContainer, WorkbenchPlaceholderLoadingImage } from './style';

export const CreateVideoPlaceholder = ({
  element,
}: {
  element: ClientSideWorkbenchElementPlaceholder;
}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const secondsSinceCreation = Math.floor(
        (Date.now() - new Date(element.createdAt).getTime()) / 1000
      );
      const percentageCompleted = Math.min(
        (secondsSinceCreation / 300) * 100,
        100
      );
      setProgress(percentageCompleted);
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const getLoadingText = () => {
    if (progress > 75) {
      return 'Finalizing the details...';
    } else if (progress > 50) {
      return 'Tweaking the background...';
    } else if (progress > 25) {
      return 'Adjusting the lighting...';
    } else {
      return 'Creating your animation...';
    }
  };

  return (
    <>
      <LoadingContainer>
        {element.loadingImagePath && (
          <WorkbenchPlaceholderLoadingImage
            crossOrigin="anonymous"
            src={element.loadingImagePath}
          />
        )}
        <Content>
          <Text>{getLoadingText()}</Text>
          <Text style={{ textAlign: 'end' }}>{Math.round(progress)}%</Text>
          <ProgressBar>
            <Progress $progress={progress} />
          </ProgressBar>
        </Content>
      </LoadingContainer>
    </>
  );
};

const Content = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 12px;
  align-items: center;
  justify-content: center;
  width: 60%;
  position: relative;
  pointer-events: none;
`;

const ProgressBar = styled.div`
  position: relative;
  width: 100%;
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 2px;
  cursor: pointer;
  grid-column: 1 / -1;
  border-radius: ${({ theme }) => theme.borderRadius.l};
`;

const Progress = styled.div<{ $progress: number }>`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: ${({ $progress }) => $progress}%;
  background-color: ${({ theme }) => theme.surface.pageInverted};
  border-radius: ${({ theme }) => theme.borderRadius.l};
`;

import { Link, Navigate } from 'react-router-dom';
import {
  publishTrackingEvent,
  useGenerateRegisterToken,
} from '@vizcom/shared/data-access/graphql';
import {
  Button,
  Text,
  addToast,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

import { LogInWithGoogleButton } from '../LogInWithGoogleButton/LogInWithGoogleButton';
import {
  PageContainer,
  SectionContainer,
  FormContainer,
  AuthLinkContainer,
  Input,
  InputGroup,
  InputWrapper,
  ResetInputIcon,
} from '../styles';
import { useRegisterToken } from '../useRegisterToken';

interface SignupFormProps {
  email: string;
  onEmailReset: () => void;
}

export const SignupForm = ({ email, onEmailReset }: SignupFormProps) => {
  const [generateRegisterTokenRes, generateRegisterToken] =
    useGenerateRegisterToken();
  const registerToken = useRegisterToken();

  if (
    registerToken &&
    // user could have been invited to the org a long time ago and just now clicked the invite link
    // in the case the register token is expired, let the user go thought the normal email verification flow
    !registerToken.expired &&
    registerToken.body?.email === email
  ) {
    return <Navigate to={paths.auth.registerWithToken(registerToken.token)} />;
  }

  const handleGenerateRegisterToken = async () => {
    await generateRegisterToken({
      input: {
        email,
      },
    });

    if (generateRegisterTokenRes.error) {
      addToast(formatErrorMessage(generateRegisterTokenRes.error), {
        type: 'danger',
      });
      return;
    }

    publishTrackingEvent({
      type: 'SENT_SIGNUP_EMAIL',
      data: {
        email,
      },
    });
  };

  if (generateRegisterTokenRes.data?.generateRegisterToken.sentByEmail) {
    return (
      <PageContainer>
        <SectionContainer>
          <Text
            type="h1"
            block
            color="bodyInverted"
            style={{ textAlign: 'left' }}
          >
            Verify your email
          </Text>
          <Text type="b1" color="bodyInverted" style={{ textAlign: 'left' }}>
            We've sent you an email with a link that you'll need to click to
            verify your email.
          </Text>
        </SectionContainer>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <SectionContainer>
        <Text type="h1" color="bodyInverted" style={{ textAlign: 'left' }}>
          Get started
        </Text>
        <Text type="sh1" color="bodyInverted">
          You don't have an account yet. Please continue to create it now.
        </Text>
      </SectionContainer>

      <FormContainer as="div">
        <InputGroup>
          <Text type="sh2" color="bodyInverted">
            Email:
          </Text>
          <InputWrapper>
            <Input
              type="email"
              placeholder="Email"
              value={email}
              readOnly
              disabled
              autoFocus
            />
            <ResetInputIcon onClick={onEmailReset} />
          </InputWrapper>
        </InputGroup>

        <Button
          variant="secondary"
          disabled={generateRegisterTokenRes.fetching}
          $fullWidth
          style={{ marginTop: 40 }}
          onClick={handleGenerateRegisterToken}
        >
          {generateRegisterTokenRes.fetching
            ? 'Sending verification email...'
            : 'Create account'}
        </Button>
      </FormContainer>

      <LogInWithGoogleButton />

      <AuthLinkContainer>
        <Text
          type="b3"
          color="subtext"
          style={{ textAlign: 'center', marginTop: 8 }}
        >
          By clicking "Create account" or "Continue with Google", you agree to
          Vizcom's{' '}
          <Text
            type="b3"
            color="link"
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            as={Link}
            to="https://vizcom.ai/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms
          </Text>{' '}
          and{' '}
          <Text
            type="b3"
            color="link"
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            as={Link}
            to="https://vizcom.ai/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </Text>
        </Text>
      </AuthLinkContainer>
    </PageContainer>
  );
};

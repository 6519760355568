import { capitalize } from 'lodash';
import { TeamRole } from '@vizcom/shared/data-access/graphql';
import { Dropdown, Text } from '@vizcom/shared-ui-components';

import { MemberFiltersType, MemberOrderBy } from './memberFiltersType';

const BUTTON_SIZE = 'M';

export const MembersListFilters = (props: {
  filters: MemberFiltersType;
  setFilters: (filters: MemberFiltersType) => void;
}) => {
  return (
    <>
      {/* Role Filter */}
      <Dropdown
        buttonProps={{
          size: BUTTON_SIZE,
          style: {
            minWidth: 110,
          },
        }}
        options={[
          {
            data: {
              value: 'all',
              label: 'All roles',
            },
          },
          ...Object.entries(TeamRole).map(([key, value]) => ({
            data: { value, label: key },
          })),
        ]}
        OptionComponent={({ option }) => (
          <Text>{capitalize(option.label)}</Text>
        )}
        optionToValueMapper={(option) => option.value}
        setValue={(value) =>
          props.setFilters({
            ...props.filters,
            role: value === 'all' ? undefined : (value as TeamRole),
          })
        }
        value={props.filters.role ?? 'all'}
      >
        {props.filters.role ? capitalize(props.filters.role) : 'All roles'}
      </Dropdown>

      {/* Status Filter */}
      <Dropdown
        buttonProps={{
          size: BUTTON_SIZE,
          style: {
            minWidth: 115,
          },
        }}
        options={[
          {
            data: {
              value: 'all',
              label: 'All users',
            },
          },
          {
            data: {
              value: 'joined',
              label: 'Joined',
            },
          },
          {
            data: {
              value: 'pending',
              label: 'Pending',
            },
          },
        ]}
        OptionComponent={({ option }) => (
          <Text>{capitalize(option.label)}</Text>
        )}
        optionToValueMapper={(option) => option.value}
        setValue={(value) =>
          props.setFilters({
            ...props.filters,
            status:
              value === 'all' ? undefined : (value as 'joined' | 'pending'),
          })
        }
        value={props.filters.status ?? 'all'}
      >
        {props.filters.status ? capitalize(props.filters.status) : 'All users'}
      </Dropdown>

      {/* Order By */}
      <Dropdown
        buttonProps={{
          size: BUTTON_SIZE,
          style: {
            minWidth: 220,
          },
        }}
        options={Object.values(MemberOrderBy).map((value) => ({
          data: value,
        }))}
        OptionComponent={({ option }) => <Text>{capitalize(option)}</Text>}
        optionToValueMapper={(option) => option}
        setValue={(value) =>
          props.setFilters({
            ...props.filters,
            orderBy: value as MemberOrderBy,
          })
        }
        value={props.filters.orderBy}
      >
        {capitalize(props.filters.orderBy)}
      </Dropdown>
    </>
  );
};

import {
  CanvasProps,
  EventManager,
  Events,
  createEvents,
  RootState,
} from '@react-three/fiber';
import { OrthographicCamera } from 'three';
import { sleep } from '@vizcom/shared/js-utils';

import { screenPositionToLocal } from '../../helpers';
import { sortObjectsByHierarchicalRenderOrder } from '../threeRenderingOrder';

type DomEvent = PointerEvent | MouseEvent | WheelEvent;

export interface MultiTouchEventInfo {
  firstPointersWorldPositions: number[][];
  currentPointersWorldPositions: number[][];
  firstPointersScreenPositions: number[][];
  currentPointersScreenPositions: number[][];
  cameraAtStartOfMove: OrthographicCamera;
  memo: any;
}

export interface MultitouchEventManager extends EventManager<HTMLElement> {
  onMultiTouchMove: (
    callback: (multiTouchMoveInfo: MultiTouchEventInfo) => any
  ) => () => void;
}

export const createMultitouchThreejsEvents: CanvasProps['events'] = (store) => {
  const onMultiTouchMoveListeners = new Set<
    (multiTouchMoveInfo: MultiTouchEventInfo) => any
  >();
  let firstPointersWorldPositions: null | number[][] = null;
  let firstPointersScreenPositions: null | number[][] = null;
  const pointers = [] as {
    clientX: number;
    clientY: number;
    id: number;
  }[];
  // makes sure that as soon as we detect 2 touches for an event, we then ignore all other events until
  // all the touches have been released
  let currentInteractionIsDoubleTouch = false;
  let currentInteractionIsSingleTouchDetectionPromise =
    null as null | Promise<void>;
  let currentInteractionIsSingleTouch = false;
  // Make a copy of the camera when starting an interaction to then use with the raycaster and keep the same reference view
  let cameraAtStartOfMove = null as null | OrthographicCamera;
  const listenersMemo = new Map();

  const registerPointer = (e: PointerEvent) => {
    if (e.type === 'click') {
      // don't register anything for click events
      return;
    }
    const pointerInfo = {
      clientX: e.clientX,
      clientY: e.clientY,
      id: e.pointerId,
    };
    const pointerIndex = pointers.findIndex(({ id }) => e.pointerId === id);
    if (pointerIndex === -1) {
      // pointer is new
      if (e.type === 'pointerdown') {
        // only register the pointer if pointerdown and not hover
        pointers.push(pointerInfo);
      }
    } else {
      pointers[pointerIndex] = pointerInfo;
    }
  };
  const unregisterPointer = (e: PointerEvent) => {
    const pointerIndex = pointers.findIndex(({ id }) => e.pointerId === id);
    if (pointerIndex !== -1) {
      pointers.splice(pointerIndex, 1);
    }
    // reset move state when releasing at least one finget, this is useful if the user starts a pinch gesture
    // lift only one finger, and then pinch again with another finger
    cameraAtStartOfMove = null;
    firstPointersScreenPositions = null;
    firstPointersWorldPositions = null;
    listenersMemo.clear();
  };

  const handleMultitouchGesture = () => {
    const { camera } = store.getState();
    if (!cameraAtStartOfMove) {
      cameraAtStartOfMove = (camera as OrthographicCamera).clone();
    }
    const currentPointersScreenPositions = [
      [pointers[0].clientX, pointers[0].clientY],
      [pointers[1].clientX, pointers[1].clientY],
    ] as [number, number][];

    const currentPointersWorldPositions = [
      screenPositionToLocal(
        currentPointersScreenPositions[0],
        cameraAtStartOfMove
      ),
      screenPositionToLocal(
        currentPointersScreenPositions[1],
        cameraAtStartOfMove
      ),
    ];
    if (!firstPointersWorldPositions) {
      firstPointersWorldPositions = currentPointersWorldPositions;
      firstPointersScreenPositions = currentPointersScreenPositions;
      return;
    }
    onMultiTouchMoveListeners.forEach((listener) => {
      const listenerMemo = listenersMemo.get(listener);
      const newMemo = listener({
        firstPointersWorldPositions: firstPointersWorldPositions!,
        currentPointersWorldPositions,
        firstPointersScreenPositions: firstPointersScreenPositions!,
        currentPointersScreenPositions,
        cameraAtStartOfMove: cameraAtStartOfMove!,
        memo: listenerMemo,
      });
      listenersMemo.set(listener, newMemo);
    });
  };

  const { handlePointer } = createEvents(store);
  // adapted from: https://github.com/pmndrs/react-three-fiber/blob/master/packages/fiber/src/web/events.ts
  const touchEvents: MultitouchEventManager = {
    priority: 1,
    enabled: true,
    compute(event: DomEvent, state: RootState) {
      state.pointer.set(
        (event.clientX / state.size.width) * 2 - 1,
        -(event.clientY / state.size.height) * 2 + 1
      );
      state.raycaster.setFromCamera(state.pointer, state.camera);
    },
    connected: undefined,
    onMultiTouchMove: (callback) => {
      onMultiTouchMoveListeners.add(callback);
      return () => {
        onMultiTouchMoveListeners.delete(callback);
      };
    },
    filter(items) {
      // we are re-ordering the raycaster hits not by distance from the camera (default behavior)
      // but instead by our custom render order. This is done by overwriting the distance and setting it to the index
      // source: https://github.com/pmndrs/react-three-fiber/blob/master/packages/fiber/src/core/events.ts#L233
      const sortedItems = items
        .sort(sortObjectsByHierarchicalRenderOrder)
        .reverse()
        .map((item, i) => ({
          ...item,
          distance: i,
        }));
      return sortedItems;
    },
    handlers: Object.fromEntries(
      Object.keys(DOM_EVENTS).map((eventName) => {
        const nativeHandler = (event: DomEvent) => {
          try {
            return handlePointer(eventName)(event);
          } catch (e) {
            if (
              e instanceof Error &&
              (e.message.includes('setPointerCapture') ||
                e.message.includes('The object can not be found here'))
            ) {
              // threejs sometimes throw error because it cannot setPointerCapture on a pointer that doesn't exist (because it was already released because of the debounce here)
              // in this case, we ignore the error
              return;
            }
            throw e;
          }
        };
        const handler = async (e: DomEvent) => {
          if (!(e instanceof PointerEvent)) {
            nativeHandler(e);
            return;
          }
          if (e.pointerType === 'mouse') {
            nativeHandler(e);
            return;
          }
          if (e.pointerId === 1 && e.pointerType === 'pen') {
            // ipad pen hover, ignore it
            return;
          }
          if (
            e.type === 'pointerup' ||
            e.type === 'pointercancel' ||
            e.type === 'pointerleave' ||
            e.type === 'pointerout'
          ) {
            if (currentInteractionIsSingleTouchDetectionPromise) {
              await currentInteractionIsSingleTouchDetectionPromise;
            }
            // pointer was removed
            unregisterPointer(e);
            if (pointers.length === 0) {
              currentInteractionIsDoubleTouch = false;
              currentInteractionIsSingleTouch = false;
              currentInteractionIsSingleTouchDetectionPromise = null;
            }
            nativeHandler(e);
          } else {
            // either pointerdown or pointermove or click
            registerPointer(e);
            if (currentInteractionIsSingleTouch && pointers.length > 1) {
              // stop all event handling if the move was detected as single touch and then another cursor was detected
              e.stopPropagation();
              return;
            }

            if (pointers.length >= 2) {
              currentInteractionIsDoubleTouch = true;
            }
            if (currentInteractionIsDoubleTouch) {
              e.stopPropagation();
              if (pointers.length === 2) {
                handleMultitouchGesture();
              }
              return;
            }

            if (pointers.length === 0) {
              // move on hover, no actual touch, should run normal handler
              nativeHandler(e);
              return;
            }

            if (pointers.length === 1) {
              // only one pointer detected for now, can either be a single pointer interaction or
              // we are still waiting for the next pointer for it to be a panning/zoom interaction
              // in this case, wait 50ms and if there's still no pointer, trigger the action
              if (!currentInteractionIsSingleTouchDetectionPromise) {
                currentInteractionIsSingleTouchDetectionPromise = sleep(50);
              }
              // need to delay all event by this promise to make sure we are keeping the same order of events
              await currentInteractionIsSingleTouchDetectionPromise;
              if (pointers.length === 1) {
                currentInteractionIsSingleTouch = true;
                nativeHandler(e);
                return;
              }
            }
          }
        };
        return [eventName, handler];
      })
    ) as unknown as Events,
    update: () => {
      const { events, internal } = store.getState();
      if (internal.lastEvent?.current && events.handlers)
        events.handlers.onPointerMove(internal.lastEvent.current);
    },
    connect: (target: HTMLElement) => {
      target.style.touchAction = 'none';
      const { set, events } = store.getState();
      events.disconnect?.();
      set((state) => ({ events: { ...state.events, connected: target } }));
      Object.entries(events.handlers ?? []).forEach(([name, event]) => {
        const [eventName, passive] =
          DOM_EVENTS[name as keyof typeof DOM_EVENTS];
        target.addEventListener(eventName, event, { passive });
      });
    },
    disconnect: () => {
      const { set, events } = store.getState();
      if (events.connected) {
        Object.entries(events.handlers ?? []).forEach(([name, event]) => {
          if (events && events.connected instanceof HTMLElement) {
            const [eventName] = DOM_EVENTS[name as keyof typeof DOM_EVENTS];
            events.connected.removeEventListener(eventName, event);
          }
        });
        set((state) => ({ events: { ...state.events, connected: undefined } }));
      }
    },
  };

  return touchEvents;
};

const DOM_EVENTS = {
  onClick: ['click', false],
  onContextMenu: ['contextmenu', false],
  onDoubleClick: ['dblclick', false],
  onWheel: ['wheel', true],
  onPointerDown: ['pointerdown', true],
  onPointerUp: ['pointerup', true],
  onPointerLeave: ['pointerleave', true],
  onPointerMove: ['pointermove', true],
  onPointerCancel: ['pointercancel', true],
  onLostPointerCapture: ['lostpointercapture', true],
} as const; // from https://github.com/pmndrs/react-three-fiber/blob/master/packages/fiber/src/web/events.ts

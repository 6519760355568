import * as Sentry from '@sentry/react';
import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useUpdateWorkbench,
  useWorkbench,
} from '@vizcom/shared/data-access/graphql';
import {
  Button,
  Modal,
  ModalActions,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalTitle,
  TargetFolderSelector,
  Text,
  addToast,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

type MoveWorkbenchModalProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  workbenchId: string;
  onMoved?: () => void; // if nothing is passed, will redirect to new parent folder
};

export const MoveWorkbenchModal = (props: MoveWorkbenchModalProps) => {
  const { isOpen, setIsOpen, workbenchId, onMoved } = props;
  const navigate = useNavigate();
  const [updateWorkbenchRes, updateWorkbench] = useUpdateWorkbench();
  const workbenchRes = useWorkbench(props.workbenchId);
  const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);

  const loading = updateWorkbenchRes.fetching || workbenchRes.fetching;

  const handleMoveClick = async () => {
    if (!selectedFolderId) {
      return;
    }
    const workbenchRes = await updateWorkbench({
      id: workbenchId,
      patch: {
        folderId: selectedFolderId,
      },
    });
    if (workbenchRes.error) {
      addToast('Error while moving file', {
        type: 'danger',
        secondaryText: formatErrorMessage(workbenchRes.error),
      });

      Sentry.captureException(workbenchRes.error);
      return;
    }

    if (onMoved) {
      onMoved();
    } else {
      navigate(paths.files.folder(selectedFolderId));
    }
    setIsOpen(false);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const organizationId = workbenchRes.data?.folder?.organization?.id;

  return (
    <Modal
      style={{ minWidth: 430 }}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      loading={loading}
    >
      <ModalHeader>
        <ModalTitle>Move file {workbenchRes.data?.name}</ModalTitle>
        <ModalCloseButton />
      </ModalHeader>

      <ModalContent style={{ marginTop: 0 }}>
        {organizationId ? (
          <TargetFolderSelector
            organizationId={organizationId}
            selectedFolderId={selectedFolderId}
            setSelectedFolderId={setSelectedFolderId}
          />
        ) : (
          <Text>
            Error while duplicating file, please reload the page and try again.
          </Text>
        )}
      </ModalContent>

      <ModalActions>
        <Button variant="secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleMoveClick}
          disabled={!selectedFolderId || loading}
        >
          Move
        </Button>
      </ModalActions>
    </Modal>
  );
};

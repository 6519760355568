import { useLocation } from 'react-router-dom';

export const useRegisterToken = () => {
  const { hash } = useLocation();

  if (!hash.startsWith('#token=')) {
    return null;
  }

  const token = hash.slice('#token='.length);

  try {
    const body = JSON.parse(
      decodeURIComponent(window.atob(token.split('.')[1]))
    ) as {
      email: string;
      teamId?: string;
      exp?: number;
    } | null;
    if (!body || typeof body !== 'object') {
      return null;
    }

    return {
      token,
      body: body,
      expired: !!body.exp && body.exp < Date.now() / 1000,
    };
  } catch {
    return null;
  }
};

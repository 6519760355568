import React, { useCallback } from 'react';
import styled from 'styled-components';
import { RangeInput, NumberInput } from '@vizcom/shared-ui-components';

import { InfluenceControl, InfluenceLabel, SliderContainer } from '../style';

const NumberInputContainer = styled.div`
  background-color: ${({ theme }) => theme.surface.secondary};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  width: 50px;
  transition: background-color 0.2s ease;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid ${({ theme }) => theme.border.primary};
  }
`;

interface RealtimeDrawingSliderProps {
  sourceImageInfluence: number;
  onSourceImageInfluenceChange: (value: number) => void;
}

export const RealtimeDrawingSlider: React.FC<RealtimeDrawingSliderProps> = ({
  sourceImageInfluence,
  onSourceImageInfluenceChange,
}) => {
  // Convert 0-1 to 0-100 for display
  const displayValue = Math.round(sourceImageInfluence * 100);

  const handleSliderChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseFloat(e.target.value);
      onSourceImageInfluenceChange(value);
    },
    [onSourceImageInfluenceChange]
  );

  const handleNumberChange = useCallback(
    (v: number) => {
      onSourceImageInfluenceChange(v);
    },
    [onSourceImageInfluenceChange]
  );

  return (
    <InfluenceControl>
      <InfluenceLabel>Drawing Influence</InfluenceLabel>
      <SliderContainer>
        <RangeInput
          variant="primary"
          thickness="medium-thick"
          min={0}
          max={100}
          step={5}
          value={displayValue}
          onChange={handleSliderChange}
        />
        <NumberInputContainer>
          <NumberInput
            value={displayValue}
            min={0}
            max={100}
            unit="%"
            setValue={handleNumberChange}
            dragArrows={false}
          />
        </NumberInputContainer>
      </SliderContainer>
    </InfluenceControl>
  );
};

import { base62 } from 'mudder';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { sortByOrderKey } from '@vizcom/shared/js-utils';
import {
  SortableList,
  useWindowEventListener,
} from '@vizcom/shared-ui-components';

import { filterExists } from '../../../../../../shared/js-utils/src';
import {
  Drawing2dStudio,
  DrawingLayer,
  useDrawingSyncedState,
} from '../../lib/useDrawingSyncedState';
import { useIsWorkbenchViewer } from '../../lib/utils';
import { BackgroundLayer } from './BackgroundLayer';
import { Layer } from './Layer';
import { LayerGroup } from './LayerGroup';
import { ScreenShareProvider } from './ScreenShareContext';
import { MAX_LAYER_DEPTH } from './constants';
import { ActiveLayerChangeOperation } from './lib/useActiveLayer';

export const Layers = ({
  activeLayer,
  drawing,
  viewerVisibility,
  collapsedGroupStates,
  setCollapsedGroupStates,
  setActiveLayer,
  setViewerVisibility,
  handleAction,
  onCreateDrawingsFromImage,
}: {
  activeLayer: string | undefined;
  drawing: Drawing2dStudio;
  viewerVisibility: Record<string, boolean>;
  collapsedGroupStates: Record<string, boolean>;
  setCollapsedGroupStates: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  setActiveLayer: (
    id: string | undefined,
    operation?: ActiveLayerChangeOperation
  ) => void;
  setViewerVisibility: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  onCreateDrawingsFromImage: (
    previews: { preview: ArrayBuffer | Blob; name?: string }[],
    offset?: {
      x: number;
      y: number;
    }
  ) => void;
}) => {
  const contentRef = useRef<HTMLDivElement>(null!);
  const [editingLayer, setEditingLayer] = useState<string | null>(null);
  const [isTogglingMultiLayerVisibility, setTogglingMultiLayerVisibility] =
    useState<boolean | undefined>(undefined);
  const [storedVisibilities, setStoredVisibilities] = useState<Record<
    string,
    boolean
  > | null>(null);

  const isViewer = useIsWorkbenchViewer();
  const sortedLayers = sortByOrderKey(drawing.layers.nodes);

  // maintain and reference a local state of the layers order to prevent a flash when order changes
  const [layerOrder, setLayerOrder] = useState(sortedLayers.map((l) => l.id));
  const [parentIdOverrides, setParentIdOverrides] = useState<
    { id: string; parentId?: string }[] | null
  >(null);

  const isolateLayer = (id: string) => {
    if (!drawing) return;
    const layers = drawing?.layers.nodes.reduce((acc, layer) => {
      acc[layer.id] = layer;
      return acc;
    }, {} as Record<string, typeof drawing.layers.nodes[0]>);

    if (storedVisibilities) {
      if (!layers[id].visible) {
        handleAction?.({
          type: 'updateBulkLayers',
          layerUpdates: Object.values(layers).map((layer) => ({
            id: layer.id,
            visible: layer.id === id,
          })),
        });
        return;
      } else {
        handleAction?.({
          type: 'updateBulkLayers',
          layerUpdates: Object.entries(storedVisibilities).map(([k, v]) => ({
            id: k,
            visible: v,
          })),
        });
        setStoredVisibilities(null);
        return;
      }
    }

    const currentVisibilities = drawing?.layers.nodes.reduce((acc, layer) => {
      acc[layer.id] = layer.visible;
      return acc;
    }, {} as Record<string, boolean>);
    setStoredVisibilities(currentVisibilities);

    handleAction?.({
      type: 'updateBulkLayers',
      layerUpdates: drawing?.layers.nodes.map((layer) => ({
        id: layer.id,
        visible: layer.id === id,
      })),
    });
  };

  function layerIsNestedChild(id: string, selectedLayerIds: string[]) {
    if (selectedLayerIds.includes(id)) {
      return true;
    }

    const parent = drawing.layers.nodes.find((l) => l.id === id);

    if (!parent) {
      return false;
    }

    if (!parent.parentId) {
      return false;
    }

    return layerIsNestedChild(parent.parentId, selectedLayerIds);
  }

  const onChange = (
    newOrder: string[],
    nextItem: DrawingLayer | undefined,
    previousItem: DrawingLayer | undefined,
    parentId?: string
  ) => {
    if (!activeLayer) return;

    const selectedItemsWithoutChildren = activeLayer.split('/').filter((id) => {
      const layer = drawing.layers.nodes.find((l) => l.id === id);
      if (!layer) return false;
      if (!layer.parentId) return true;

      return !layerIsNestedChild(layer.parentId, activeLayer.split('/'));
    });

    const movedLayerCount = selectedItemsWithoutChildren.length;

    const orderKeys = previousItem?.orderKey
      ? base62.mudder(
          nextItem?.orderKey,
          previousItem?.orderKey,
          movedLayerCount
        )
      : base62.mudder(nextItem?.orderKey, undefined, movedLayerCount);
    const existingOrderKeysAtDepth = drawing.layers.nodes
      .filter((l) => l.parentId === parentId)
      .map((l) => l.orderKey);

    const movedLayers = selectedItemsWithoutChildren
      .map((id, i) => {
        let orderKey = orderKeys[i];

        if (existingOrderKeysAtDepth.includes(orderKeys[i])) {
          orderKey = base62.mudder(
            orderKeys[i],
            orderKeys[i + 1] ?? undefined
          )[0];
        }

        if (
          orderKey === drawing.layers.nodes.find((l) => l.id === id)?.orderKey
        ) {
          return null;
        }

        return {
          id,
          orderKey,
          parentId,
        };
      })
      .filter(filterExists);

    if (movedLayers.length === 0) return;

    handleAction?.({
      type: 'updateBulkLayers',
      layerUpdates: movedLayers,
    });

    setParentIdOverrides(movedLayers);
    setLayerOrder(newOrder);
  };

  const toggleCollapseGroup = (groupId: string, collapsed: boolean) => {
    setCollapsedGroupStates((prev) => ({
      ...prev,
      [groupId]: collapsed,
    }));

    // deselect children if group is collapsed
    if (collapsed) {
      const selectedLayerIds = activeLayer?.split('/') || [];

      const selectedWithoutChildren = selectedLayerIds.filter((selectedId) => {
        const layer = drawing.layers.nodes.find((l) => l.id === selectedId);
        if (!layer) return false;
        if (!layer.parentId) return true;

        return !layerIsNestedChild(selectedId, [groupId]);
      });

      // if the group is not selected, and one of its children was,
      // add the group to the selected layers
      if (
        selectedWithoutChildren.length !== selectedLayerIds.length &&
        !selectedWithoutChildren.includes(groupId)
      ) {
        selectedWithoutChildren.push(groupId);
      }

      setActiveLayer(selectedWithoutChildren.join('/'));
    }
  };

  useEffect(() => {
    setLayerOrder(sortedLayers.map((l) => l.id));
    setParentIdOverrides(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedLayers.map((l) => l.id).join(',')]);

  useWindowEventListener('keydown', (e) => {
    if (e.key === ' ' && !(e.target instanceof HTMLTextAreaElement)) {
      e.preventDefault();
    }
  });

  const orderedLayers = layerOrder
    .map((id) => drawing.layers.nodes.find((l) => l.id === id))
    .filter(filterExists)
    .map((layer) => {
      const parentIdOverride = parentIdOverrides?.find(
        (l) => l.id === layer.id
      );
      if (parentIdOverride) {
        return {
          ...layer,
          parentId: parentIdOverride.parentId,
          collapsed: collapsedGroupStates[layer.id],
        };
      }
      return {
        ...layer,
        collapsed: collapsedGroupStates[layer.id],
      };
    });

  return (
    <Frame>
      <ScreenShareProvider>
        <Content ref={contentRef}>
          <SortableList
            disableSorting={isViewer}
            disableDragging={isViewer}
            setOrder={setLayerOrder}
            activeLayerIds={activeLayer?.split('/') ?? []}
            items={orderedLayers}
            maxDepth={MAX_LAYER_DEPTH}
            onChange={onChange}
            setActiveLayer={setActiveLayer}
            renderItem={(item, depth, isLast, nextLayerIsGroup) => (
              <Layer
                drawing={drawing}
                layer={item}
                depth={depth}
                isLast={isLast}
                nextLayerIsGroup={nextLayerIsGroup}
                parentIsActive={Boolean(
                  item.parentId &&
                    layerIsNestedChild(
                      item.parentId,
                      activeLayer?.split('/') ?? []
                    )
                )}
                setTogglingMultiLayerVisibility={
                  setTogglingMultiLayerVisibility
                }
                isTogglingMultiLayerVisibility={isTogglingMultiLayerVisibility}
                isolateLayer={isolateLayer}
                activeLayer={activeLayer}
                setActiveLayer={setActiveLayer}
                editingLayer={editingLayer}
                setEditingLayer={setEditingLayer}
                handleAction={handleAction}
                viewerVisibility={viewerVisibility}
                setViewerVisibility={setViewerVisibility}
                onCreateDrawingsFromImage={onCreateDrawingsFromImage}
              />
            )}
            renderContainer={(
              item,
              depth,
              i,
              childCount,
              innerContent,
              isOver
            ) => (
              <LayerGroup
                drawing={drawing}
                layer={item}
                depth={depth}
                isLast={i === drawing.layers.nodes.length - 1}
                parentIsActive={Boolean(
                  item.parentId &&
                    layerIsNestedChild(
                      item.parentId,
                      activeLayer?.split('/') ?? []
                    )
                )}
                setTogglingMultiLayerVisibility={
                  setTogglingMultiLayerVisibility
                }
                isTogglingMultiLayerVisibility={isTogglingMultiLayerVisibility}
                isolateLayer={isolateLayer}
                activeLayer={activeLayer}
                setActiveLayer={setActiveLayer}
                editingLayer={editingLayer}
                setEditingLayer={setEditingLayer}
                handleAction={handleAction}
                viewerVisibility={viewerVisibility}
                setViewerVisibility={setViewerVisibility}
                onCreateDrawingsFromImage={onCreateDrawingsFromImage}
                childCount={childCount}
                innerContent={innerContent}
                hoveringOver={isOver}
                setCollapsed={toggleCollapseGroup}
              />
            )}
          />

          <BackgroundLayer
            drawing={drawing}
            viewerVisibility={viewerVisibility}
            setViewerVisibility={setViewerVisibility}
            setTogglingMultiLayerVisibility={setTogglingMultiLayerVisibility}
            isTogglingMultiLayerVisibility={isTogglingMultiLayerVisibility}
            handleAction={handleAction}
          />
        </Content>
      </ScreenShareProvider>
    </Frame>
  );
};

const Frame = styled.div`
  flex: 1 1 auto;
  display: flex;
  height: 0;
  width: 100%;
  /* following line prevents the scrollbar from overflowing
   * the bottom rounded corner of the panel */
  margin-bottom: 10px;
  padding: 8px;
  overflow-y: auto;

  color: ${({ theme }) => theme.text.body};
  border-radius: ${({ theme }) =>
    `0 0 ${theme.borderRadius.l} ${theme.borderRadius.l}`};

  ${({ theme }) => theme.scrollbar.dark}
`;

const Content = styled.div`
  width: 100%;
`;

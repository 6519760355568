import { useDeferredValue } from 'react';
import { useTheme } from 'styled-components';
import { DoubleSide } from 'three';

import { WorkbenchContentRenderingOrder } from '../../../WorkbenchContent';
import { useImageTextures } from '../../../lib/useImageTexture';
import { VizcomRenderingOrderEntry } from '../../utils/threeRenderingOrder';
import { PromptHistoryItem } from '../history/WorkbenchStudioHistory';
import { ActiveMask, MaskDisplayMode } from '../selection/ActiveMask';

export const InferencePreview = ({
  imagePath,
  visible,
  scale,
  selectedPromptHistoryItem,
}: {
  imagePath?: string | null;
  visible: boolean;
  scale: [number, number];
  selectedPromptHistoryItem: PromptHistoryItem;
}) => {
  const theme = useTheme();
  const path = imagePath || selectedPromptHistoryItem?.output?.imagePath;

  // make sure we still keep showing the previous image while switching to a new one and it's loading
  // this way we don't show a 1 frame flicker of the source drawing
  const deferredPath = useDeferredValue(path);
  const [image, maskImage] = useImageTextures([
    deferredPath,
    selectedPromptHistoryItem?.prompt.maskImagePath,
  ]);

  if (!image) {
    return null;
  }

  return (
    <group
      userData={{
        vizcomRenderingOrder: [
          {
            zIndex: WorkbenchContentRenderingOrder.indexOf('overlays'),
            escapeZIndexContext: true,
          } satisfies VizcomRenderingOrderEntry,
        ],
      }}
      visible={visible}
    >
      {maskImage && (
        <group
          userData={{
            vizcomRenderingOrder: [
              {
                zIndex: 2,
              } satisfies VizcomRenderingOrderEntry,
            ],
          }}
        >
          <ActiveMask
            drawingSize={scale}
            maskTexture={maskImage}
            mode={MaskDisplayMode.MARCHING_ANTS}
          />
        </group>
      )}

      <group
        scale={[scale[0], scale[1], 1]}
        userData={{
          vizcomRenderingOrder: [
            {
              zIndex: 1,
            } satisfies VizcomRenderingOrderEntry,
          ],
        }}
      >
        <mesh>
          <planeGeometry args={[1, 1, 1, 1]} />
          <meshBasicMaterial
            map={image}
            transparent
            depthTest={false}
            side={DoubleSide}
          />
        </mesh>
      </group>
      <group
        scale={[scale[0] + 4, scale[1] + 4, 1]}
        userData={{
          vizcomRenderingOrder: [
            {
              zIndex: 0,
            } satisfies VizcomRenderingOrderEntry,
          ],
        }}
      >
        <mesh>
          <planeGeometry args={[1, 1, 1, 1]} />
          <meshBasicMaterial
            depthTest={false}
            color={theme.deprecated.primary.default}
            side={DoubleSide}
          />
        </mesh>
      </group>
    </group>
  );
};

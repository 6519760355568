import { Navigate, Route, Routes } from 'react-router-dom';
import {
  LoadingLogo,
  Text,
  useSelectedOrganization,
} from '@vizcom/shared-ui-components';

import {
  SettingsPageContainer,
  SettingsPageDivider,
  SettingsPageHeader,
} from '../components/SettingsPageLayout';
import { DataPageTabs } from './DataPageTabs';
import { OrganizationAccess } from './OrganizationAccess';
import { OrganizationActivity } from './OrganizationActivity';

export const OrganizationDataPage = () => {
  const { data: organization, loading } = useSelectedOrganization();

  if (loading) return <LoadingLogo />;
  if (!organization) return <Navigate to="/" />;

  return (
    <SettingsPageContainer>
      <div
        style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 16 }}
      >
        <SettingsPageHeader>
          <Text style={{ fontSize: 18, fontWeight: 600 }}>Data</Text>
          <Text color="subtext" type="b1">
            View and export important data for your organization
          </Text>
        </SettingsPageHeader>
      </div>
      <SettingsPageDivider />

      <DataPageTabs />

      <Routes>
        <Route path="access" element={<OrganizationAccess />} />
        <Route path="activity" element={<OrganizationActivity />} />
        <Route path="*" element={<Navigate to="access" replace />} />
      </Routes>
    </SettingsPageContainer>
  );
};

import { Texture } from 'three';
import { useKeyboardShortcut } from '@vizcom/shared-ui-components';

import {
  Drawing2dStudio,
  DrawingLayer,
  useDrawingSyncedState,
} from '../../../lib/useDrawingSyncedState';
import { useEraseSelectedRegion } from '../lib/eraseSelectedRegion';
import { ActiveMask, MaskDisplayMode } from '../selection/ActiveMask';
import { SelectionOffset } from '../selection/SelectionOffset';
import {
  useSubscribeToSelectionApi,
  useSelectionApiStore,
} from '../selection/useSelectionApi';
import {
  WorkbenchStudioState,
  WorkbenchStudioToolType,
  isSelectionTool,
  isShapeTool,
} from '../studioState';
import { BrushRenderer } from './BrushEngine/BrushRenderer';
import { CanvasColorPicker } from './CanvasColorPicker';
import { Layer3dEditor } from './Layer3D/Layer3dEditor';
import { LayerPicker } from './LayerPicker';
import { LayerTransformControls } from './LayerTransform/LayerTransformControls';
import { LayerWarpControls } from './LayerTransform/LayerWarpControls';
import { MagicEraser } from './MagicEraser';
import { PaintBucketRenderer } from './PaintBucketRenderer';
import { ShapeRenderer } from './ShapeRenderer';
import { SymmetryAxis } from './SymmetryAxis';
import { AutoSelection } from './selectionTools/AutoSelection';
import { BrushSelection } from './selectionTools/BrushSelection';
import { Lasso } from './selectionTools/Lasso';
import { RectangleSelection } from './selectionTools/RectangleSelection';
import { BezierSelection } from './selectionTools/bezier/BezierSelection';

export const Tools = ({
  studioState,
  activeLayer,
  setActiveLayerId,
  handleAction,
  filterHistory,
  drawing,
  drawingSize,
  isInferenceRunning,
  getCompositedImage,
  layersTextures,
  activeLayerIndex,
}: {
  studioState: WorkbenchStudioState;
  activeLayer: DrawingLayer | undefined;
  setActiveLayerId: (id: string | undefined) => void;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  filterHistory: ReturnType<typeof useDrawingSyncedState>['filterHistory'];
  drawing: Drawing2dStudio;
  drawingSize: [number, number];
  isInferenceRunning: boolean;
  getCompositedImage: (() => ImageData) | undefined;
  layersTextures: Record<string, Texture | undefined>;
  activeLayerIndex: number;
}) => {
  const selectionApiStore = useSelectionApiStore();
  const layerZIndex = activeLayerIndex;

  const { eraseSelectedRegion } = useEraseSelectedRegion();
  const layerImage = activeLayer ? layersTextures[activeLayer.id] : undefined;

  useKeyboardShortcut(['Delete', 'Backspace'], () => {
    if (!selectionApiStore.getState().hasMask || !activeLayer) {
      return;
    }

    let imageToErase: Texture | string | undefined = layerImage;
    if (!imageToErase) {
      if (activeLayer.fill.length === 7) {
        imageToErase = activeLayer.fill;
      } else {
        return;
      }
    }
    const erasedImage = eraseSelectedRegion(
      imageToErase,
      selectionApiStore.getState().texture,
      drawingSize
    );

    handleAction({
      type: 'updateLayer',
      id: activeLayer.id,
      data: { image: erasedImage },
    });
  });

  const {
    selectionTexture,
    selectionOffsetModeActivated,
    selectionOffsetDistanceTexture,
  } = useSubscribeToSelectionApi((state) => ({
    selectionTexture: state.texture,
    selectionOffsetDistanceTexture: state.offsetDistanceTexture,
    selectionOffsetModeActivated: state.offsetModeActivated,
  }));

  return (
    <group>
      {(studioState.tool === WorkbenchStudioToolType.Move ||
        activeLayer?.metadata3D) && (
        <LayerPicker
          width={drawing.width}
          height={drawing.height}
          setActiveLayerId={setActiveLayerId}
        />
      )}
      {studioState.symmetry.enabled && (
        <SymmetryAxis
          drawingSize={drawingSize}
          handleAction={handleAction}
          isInferenceActive={isInferenceRunning}
        />
      )}
      <CanvasColorPicker width={drawing.width} height={drawing.height} />
      {!isInferenceRunning && (
        //Selection Tools
        <>
          {studioState.tool !== WorkbenchStudioToolType.Move &&
            studioState.tool !== WorkbenchStudioToolType.Transform &&
            studioState.tool !== WorkbenchStudioToolType.RectangleSelection &&
            studioState.tool !== WorkbenchStudioToolType.BrushSelection && (
              <>
                <ActiveMask
                  drawingSize={drawingSize}
                  maskTexture={selectionTexture}
                  mode={MaskDisplayMode.MARCHING_ANTS}
                />
              </>
            )}
          {isSelectionTool(studioState.tool) &&
            selectionOffsetModeActivated &&
            selectionOffsetDistanceTexture !== undefined && (
              <SelectionOffset
                drawingSize={drawingSize}
                distanceTexture={selectionOffsetDistanceTexture}
                distance={studioState.selectionSettings.offset}
              />
            )}
          {studioState.tool === WorkbenchStudioToolType.Lasso && (
            <Lasso drawingSize={drawingSize} />
          )}
          {studioState.tool === WorkbenchStudioToolType.RectangleSelection && (
            <RectangleSelection drawingSize={drawingSize} />
          )}
          {studioState.tool === WorkbenchStudioToolType.BrushSelection && (
            <BrushSelection drawingSize={drawingSize} />
          )}

          {studioState.tool === WorkbenchStudioToolType.AutoSelection && (
            <AutoSelection
              drawingSize={drawingSize}
              getCompositedImage={getCompositedImage}
            />
          )}
          {studioState.tool === WorkbenchStudioToolType.BezierSelection && (
            <BezierSelection drawingSize={drawingSize} />
          )}
        </>
      )}
      {activeLayer?.metadata3D && (
        <Layer3dEditor
          drawingSize={drawingSize}
          layer={activeLayer}
          active={!isSelectionTool(studioState.tool)}
          handleAction={handleAction}
          zIndex={layerZIndex}
        />
      )}
      {activeLayer && !activeLayer.metadata3D && !activeLayer.isGroup && (
        <>
          {(studioState.tool === WorkbenchStudioToolType.Brush ||
            studioState.tool === WorkbenchStudioToolType.Eraser) && (
            <BrushRenderer
              drawingSize={drawingSize}
              layer={activeLayer}
              layerImage={layerImage}
              handleAction={handleAction}
              zIndex={layerZIndex}
            />
          )}

          {studioState.tool === WorkbenchStudioToolType.MagicEraser && (
            <MagicEraser
              drawingSize={drawingSize}
              layer={activeLayer}
              layerImage={layerImage}
              handleAction={handleAction}
              zIndex={layerZIndex}
            />
          )}

          {isShapeTool(studioState.tool) && (
            <ShapeRenderer
              drawingSize={drawingSize}
              layer={activeLayer}
              layerImage={layerImage}
              handleAction={handleAction}
              zIndex={layerZIndex}
            />
          )}

          {studioState.tool === WorkbenchStudioToolType.PaintBucket && (
            <PaintBucketRenderer
              drawingSize={drawingSize}
              layer={activeLayer}
              layerImage={layerImage}
              handleAction={handleAction}
              zIndex={layerZIndex}
            />
          )}

          {studioState.tool === WorkbenchStudioToolType.Move && (
            <LayerTransformControls
              drawingSize={drawingSize}
              layer={activeLayer}
              layerImage={layerImage}
              handleAction={handleAction}
              filterHistory={filterHistory}
              zIndex={layerZIndex}
            />
          )}

          {studioState.tool === WorkbenchStudioToolType.Transform && (
            <LayerWarpControls
              drawingSize={drawingSize}
              layer={activeLayer}
              layerImage={layerImage}
              handleAction={handleAction}
              filterHistory={filterHistory}
              zIndex={layerZIndex}
            />
          )}
        </>
      )}
    </group>
  );
};

import { useFrame } from '@react-three/fiber';
import { ErrorBoundary } from '@sentry/react';
import Lottie from 'lottie-react';
import { Suspense, useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import {
  DownloadIcon,
  FileDropper,
  ImportImageIcon,
  RenameIcon,
  Text,
  ToolbarButton,
  addToast,
} from '@vizcom/shared-ui-components';

import { ClientSideWorkbenchElementPalette } from '../../../lib/clientState';
import { useWorkbenchSyncedState } from '../../../lib/useWorkbenchSyncedState';
import {
  WorkbenchElementExtra,
  WorkbenchElementExtraDuplicate,
} from '../../WorkbenchElementExtra';
import {
  getElementObjectSize,
  getElementSize,
  useCameraZoom,
} from '../../helpers';
import { CustomHtml } from '../../utils/CustomHtml';
import { DashedRoundedRectangle } from '../../utils/DashedRoundedRectangle';
import { RoundedPlaneGeometry } from '../../utils/RoundedPlaneGeometry';
import { WorkbenchElementPaletteContextMenuItems } from '../../workbenchContextMenu/contextMenuItemsPerType/PaletteContextMenuItems';
import { PaletteImages } from './PaletteImages';
import { PaletteName } from './PaletteName';
import { PaletteStatusButton } from './PaletteStatusButton';
import { PaletteUploadButton } from './PaletteUploadButton';
import dropAnimation from './drop_animation.json';
import {
  handleAddImagesToPalette,
  handleDownloadPaletteImages,
} from './helpers';

interface WorkbenchElementPaletteProps {
  workbenchId: string;
  element: ClientSideWorkbenchElementPalette;
  isDragging: boolean;
  isResizing: boolean;
  singleFocused: boolean;
  isDropTarget: boolean;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
}

export const WorkbenchElementPalette = ({
  workbenchId,
  element,
  singleFocused,
  isDragging,
  isResizing,
  isDropTarget,
  handleAction,
}: WorkbenchElementPaletteProps) => {
  const theme = useTheme();
  const zoom = useCameraZoom();
  const [editingName, setEditingName] = useState(false);
  const [paletteStatusWidth, setPaletteStatusWidth] = useState(0);
  const [showOverlay, setShowOverlay] = useState(isDropTarget);
  const ref = useRef(null);
  const lottieRef = useRef<{
    setDirection: (direction: 1 | -1) => void;
    setSpeed: (speed: number) => void;
    play: () => void;
  }>(null);

  const [{ width, height }, setSize] = useState(getElementSize(element));

  useEffect(() => {
    if (isDropTarget) {
      setShowOverlay(isDropTarget);
    } else {
      lottieRef.current?.setDirection(-1);
      lottieRef.current?.setSpeed(2);
      lottieRef.current?.play();
    }
  }, [isDropTarget]);

  useFrame(() => {
    if (!ref.current) {
      return;
    }

    const newSize = getElementObjectSize(element, ref.current);
    if (newSize.width !== width || newSize.height !== height) {
      setSize(newSize);
    }
  });

  useEffect(() => {
    if (!element.failureReason) {
      return;
    }
    addToast(`${element.name} Training failed. (${element.failureReason})`, {
      cta: {
        text: 'Retry',
        action: () => handleAction({ type: 'trainPalette', id: element.id }),
      },
    });
  }, [element.failureReason, element.id, element.name, handleAction]);

  const handleStatusTextSync = (troika: any, adornmentOffset?: number) => {
    const textWidth = Math.abs(
      troika.textRenderInfo.blockBounds[0] -
        troika.textRenderInfo.blockBounds[2]
    );
    setPaletteStatusWidth(textWidth + (adornmentOffset ?? 0));
  };

  const initialState = element.sourceImages.nodes.length === 0;
  const dashSize = Math.max(0.25, 3.4 - zoom);
  const fallback = (
    <>
      <mesh ref={ref}>
        <RoundedPlaneGeometry width={width} height={height} radius={6} />
        <meshBasicMaterial color="#FFF" transparent />
      </mesh>
      {!singleFocused && !initialState && (
        <DashedRoundedRectangle
          width={width}
          height={height}
          radius={6}
          color={theme.surface.palette}
          dashSize={dashSize}
          gapSize={
            element.status === 'ready' || element.status === 'training'
              ? 0
              : 0.5 * dashSize
          }
        />
      )}
    </>
  );

  const overlay = (
    <FileDropperContainer>
      <LottieWrapper>
        <Lottie
          lottieRef={lottieRef as any}
          animationData={dropAnimation}
          loop={false}
          rendererSettings={{
            preserveAspectRatio: 'xMaxYMid slice',
          }}
          onComplete={() => {
            if (!isDropTarget) {
              setShowOverlay(false);
            }
          }}
        />
      </LottieWrapper>
      <Text type="h3">Drop to add images</Text>
      <FileDropperOverlay />
    </FileDropperContainer>
  );

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setShowOverlay(true);
  };

  const hasSourceImages = element.sourceImages.nodes.length > 0;

  useEffect(() => {
    // reset the palette status width when there are no source images and the element status is 'idle'
    // we need this because onSync won't be called when the element status changes to 'idle'
    if (element.sourceImages.nodes.length === 0 && element.status === 'idle') {
      setPaletteStatusWidth(0);
    }
  }, [element.sourceImages.nodes, element.status]);

  return (
    <>
      <PaletteName
        /* Use a key prop to force re-render of PaletteName when paletteStatusWidth changes */
        key={`palette-${element.id}-${paletteStatusWidth}`}
        width={width}
        height={height}
        paletteStatusWidth={paletteStatusWidth}
        id={element.id}
        name={element.name}
        handleAction={handleAction}
        editingName={editingName}
        setEditingName={setEditingName}
      />
      <PaletteStatusButton
        width={width}
        height={height}
        element={element}
        handleAction={handleAction}
        paletteStatusWidth={paletteStatusWidth}
        handleStatusTextSync={handleStatusTextSync}
      />
      {fallback}

      {hasSourceImages && (
        <group
          userData={{
            vizcomRenderingOrder: [{ zIndex: 1 }],
          }}
        >
          <ErrorBoundary
            fallback={
              <CustomHtml transform>
                <Text color="error">Error while loading images</Text>
              </CustomHtml>
            }
          >
            <Suspense fallback={null}>
              <PaletteImages
                width={width}
                height={height}
                handleAction={handleAction}
                element={element}
              />
            </Suspense>
          </ErrorBoundary>
        </group>
      )}

      {element.status === 'idle' && (
        <CustomHtml
          transform
          occlude
          userData={{
            vizcomRenderingOrder: [
              {
                zIndex: showOverlay ? 2 : 0,
              },
            ],
          }}
        >
          <div
            style={{
              width,
              height,
              position: 'relative',
            }}
          >
            <FileDropperComponent
              as={FileDropper}
              multiple
              onFileSelected={(files) => {
                handleAddImagesToPalette(
                  element.id,
                  element.sourceImages.nodes.length,
                  handleAction,
                  files
                );
              }}
              onDragEnter={handleDragOver}
              onDragOver={handleDragOver}
              onDragLeave={() => setShowOverlay(false)}
              onDragExit={() => setShowOverlay(false)}
            >
              {showOverlay && overlay}
            </FileDropperComponent>
          </div>
        </CustomHtml>
      )}

      {element.status === 'idle' &&
        !showOverlay &&
        element.sourceImages.nodes.length === 0 && (
          <CustomHtml transform occlude>
            <PaletteUploadButton
              paletteData={element}
              handleAction={handleAction}
            />
          </CustomHtml>
        )}

      {!isDragging && !isResizing && singleFocused && (
        <WorkbenchElementExtra
          workbenchId={workbenchId}
          element={element}
          position={[0, height / 2 + 14, 0]}
          pivot={element.y}
          handleAction={handleAction}
          menuItems={
            <WorkbenchElementPaletteContextMenuItems
              handleAction={handleAction}
              element={element}
            />
          }
        >
          <ToolbarButton
            icon={<RenameIcon />}
            tooltip="Rename Palette"
            onClick={() => setEditingName(true)}
          />

          {element.status === 'idle' && (
            <ToolbarButton
              icon={<ImportImageIcon />}
              tooltip="Add images"
              onClick={(e) => {
                e.stopPropagation();
                handleAddImagesToPalette(
                  element.id,
                  element.sourceImages.nodes.length,
                  handleAction
                );
              }}
            />
          )}
          {element.sourceImages.nodes.length > 0 && (
            <ToolbarButton
              icon={<DownloadIcon />}
              tooltip="Download"
              onClick={(e) => {
                e.stopPropagation();
                handleDownloadPaletteImages(element);
              }}
            />
          )}
          {['training', 'ready'].includes(element.status) && (
            <WorkbenchElementExtraDuplicate
              id={element.id}
              handleAction={handleAction}
            />
          )}
        </WorkbenchElementExtra>
      )}
    </>
  );
};

const FileDropperComponent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FileDropperContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  color: ${({ theme }) => theme.surface.palette};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const FileDropperOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.surface.palette};
  opacity: 0.2;
`;

const LottieWrapper = styled.div`
  > div {
    width: 200px;
    height: 100px;
  }
`;

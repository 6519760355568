import { formatDuration, intervalToDuration } from 'date-fns';
import {
  TriggerWorkbenchElementAnimateMutation,
  urqlClient,
} from '@vizcom/shared/data-access/graphql';
import { RateLimitQuotaDetails } from '@vizcom/shared/data-shape';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import { addToast } from '@vizcom/shared-ui-components';

import { useNewlyCreatedElements } from '../../../components/elements/newlyCreatedElementsState';
import { SyncedActionType } from '../../SyncedAction';
import { ClientSideWorkbenchElementData } from '../../clientState';
import { elementById } from '../../utils';

export type AnimatePlaceholderInput = {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  zIndex: number;
};

export const TriggerAnimateAction: SyncedActionType<
  ClientSideWorkbenchElementData[],
  {
    type: 'triggerAnimate';
    elementId: string;
    // newly created placeholder which will then be replaced by a drawing element with the image result
    placeholder: AnimatePlaceholderInput;
  }
> = {
  type: 'triggerAnimate',
  optimisticUpdater: ({ payload }, elements) => {
    const sourceElement = elementById(elements, payload.elementId);
    if (sourceElement?.__typename === 'WorkbenchElementAnimate') {
      if (elementById(elements, payload.placeholder.id)) {
        // server already responded with this element, nothing to do locally
        return;
      }
      elements.push({
        __typename: 'WorkbenchElementPlaceholder',
        updatedAt: '0',
        createdAt: '0',
        id: payload.placeholder.id,
        type: 'inference',
        x: payload.placeholder.x,
        y: payload.placeholder.y,
        width: payload.placeholder.width,
        height: payload.placeholder.height,
        zIndex: payload.placeholder.zIndex,
      });
    }
  },
  remoteUpdater: async ({ payload }, workbenchId) => {
    const res = await urqlClient.mutation(
      TriggerWorkbenchElementAnimateMutation,
      {
        input: {
          id: payload.elementId,
          placeholder: {
            ...payload.placeholder,
            type: 'inference',
            workbenchId,
          },
        },
      }
    );
    if (res?.error) {
      if (
        (res.error.graphQLErrors?.[0].extensions?.exception as any)?.rateLimit
      ) {
        const rateLimitInfo = (
          res.error.graphQLErrors?.[0].extensions?.exception as any
        )?.rateLimit as RateLimitQuotaDetails;

        const duration = intervalToDuration({
          start: 0,
          end: rateLimitInfo.resetInMs,
        });
        const time = formatDuration(duration, { delimiter: ', ' });

        if (rateLimitInfo.code === 'monthly_video_generation_limit') {
          const message =
            rateLimitInfo.subscriptionPlan === 'enterprise'
              ? `Animation limit reached. Credits reset in ${time}. Please contact your workspace administrator for more usage.`
              : `Animation limit reached. Credits reset in ${time}. We're actively working on options to buy more usage.`;

          addToast(message, {
            type: 'danger',
          });
          return;
        } else {
          addToast(
            `You are generating too many animations in a short period of time. Please wait ${time} before trying again.`,
            {
              type: 'warning',
              duration: 5000,
            }
          );
          return;
        }
      } else if (
        (res.error.graphQLErrors?.[0]?.extensions?.exception as any)?.code ===
        'organization_forbidden_keyword'
      ) {
        addToast(
          `Your prompt contains a keyword forbidden by your workspace administrator. Please change it. If you believe it's a mistake, contact your workspace administrator.`,
          {
            type: 'danger',
          }
        );
        return;
      } else if (
        (res.error.graphQLErrors?.[0]?.extensions?.exception as any)?.code ===
        'trademarked_word'
      ) {
        addToast(
          `This prompt contains a trademarked word, if you think this is a mistake, contact us at support@vizcom.ai`,
          {
            type: 'danger',
          }
        );
        return;
      } else if (
        (res.error.graphQLErrors?.[0]?.extensions?.exception as any)?.code ===
        'offensive_word'
      ) {
        addToast(
          `This prompt contains offensive words, if you think this is a mistake, contact us at support@vizcom.ai`,
          {
            type: 'danger',
          }
        );
        return;
      }
      throw new Error(
        `Error using AI service, please retry. ${
          res.error.graphQLErrors[0]?.message ?? res.error.message
        }`
      );
    }
    trackEvent('Generate Video');
  },
  onAddedToQueue(action) {
    if (action.payload.placeholder) {
      useNewlyCreatedElements
        .getState()
        .registerNewlyCreatedElements([action.payload.placeholder]);
    }
  },
};

// This method start a file download for an image with a specified name
// Because of some problems with Chrome, it's not possible to set the name of the downloaded file
// in all cases reliably, so instead here we always transform the image to a blob by downloading it first

import { createCanvas } from './imageUtils';

// and then create a <a> link to the ObjectURL of the blob to have a reliable way to set the image name
export const downloadFile = async (
  source: Blob | ImageData | string,
  name: string,
  extension?: string
) => {
  let sourceImageUrl: string;
  if (source instanceof ImageData) {
    const { canvas, ctx } = createCanvas(source.width, source.height);
    ctx.putImageData(source, 0, 0);
    sourceImageUrl = await new Promise<string>((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Error while creating blob from canvas'));
          return;
        }
        resolve(URL.createObjectURL(blob));
      });
    });
  } else if (typeof source === 'string') {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    const loadPromise = new Promise<void>((resolve, reject) => {
      image.onerror = reject;
      image.onload = () => resolve();
    });
    image.src = source;
    await loadPromise;
    const { canvas, ctx } = createCanvas(image.width, image.height);
    ctx.drawImage(image, 0, 0);
    sourceImageUrl = await new Promise<string>((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Error while creating blob from canvas'));
          return;
        }
        resolve(URL.createObjectURL(blob));
      });
    });
  } else {
    // source is already a blob,
    sourceImageUrl = URL.createObjectURL(source);
  }
  const link = document.createElement('a');
  link.download = `${name.replace(/\.[^/.]+$/, '')}.${extension || 'png'}`;
  link.href = sourceImageUrl;
  link.click();
  URL.revokeObjectURL(sourceImageUrl);
};

export const downloadFileAtUrl = async (
  url: string,
  name: string,
  extension?: string
) => {
  // need to first fetch the file before actually downloading it to control the filename reliably on different browsers
  const file = await fetch(url).then((r) => r.blob());
  const link = document.createElement('a');
  link.download = `${name}.${extension || 'png'}`;
  link.href = URL.createObjectURL(file);
  link.click();
  URL.revokeObjectURL(link.href);
};

import { RoundedBox, Text } from '@react-three/drei';
import { GroupProps } from '@react-three/fiber';
import { PropsWithChildren, useCallback, useState } from 'react';
import { useTheme } from 'styled-components';

type TextWithBackgroundProps = GroupProps & {
  color: string;
  backgroundColor: string;
  padding: number | [number, number];
  fontWeight?: number;
};

export const TextWithBackground = (
  props: PropsWithChildren<TextWithBackgroundProps>
) => {
  const { color, backgroundColor, padding, children, fontWeight, ...rest } =
    props;
  const paddingX = typeof padding === 'number' ? padding : padding[0];
  const paddingY = typeof padding === 'number' ? padding : padding[1];
  const theme = useTheme();
  const [textSize, setTextSize] = useState([0, 0]);

  const handleTextSync = useCallback((troika: any) => {
    // once text is rendered, use this callback to get its size and correctly scale the background
    setTextSize([
      Math.abs(
        troika.textRenderInfo.blockBounds[0] -
          troika.textRenderInfo.blockBounds[2]
      ),
      Math.abs(
        troika.textRenderInfo.blockBounds[1] -
          troika.textRenderInfo.blockBounds[3]
      ),
    ]);
  }, []);

  return (
    <group {...rest}>
      <Text
        color={color}
        fontWeight={fontWeight || 400}
        fontSize={15}
        anchorX="left"
        anchorY="top"
        onSync={handleTextSync}
        userData={{
          vizcomRenderingOrder: [
            {
              zIndex: 2,
            },
          ],
        }}
      >
        {children}
      </Text>
      <RoundedBox
        args={[
          textSize[0] + (paddingX ?? 0),
          textSize[1] + (paddingY ?? 0),
          0.01,
        ]}
        radius={parseFloat(theme.borderRadius.m)}
        smoothness={10}
        creaseAngle={0.4}
        position={[textSize[0] / 2, -textSize[1] / 2, 0]}
        userData={{
          vizcomRenderingOrder: [
            {
              zIndex: 1,
            },
          ],
        }}
      >
        <meshBasicMaterial
          color={backgroundColor}
          depthTest={false}
          transparent
        />
      </RoundedBox>
    </group>
  );
};

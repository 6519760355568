import { useStore, useThree } from '@react-three/fiber';
import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { publishTrackingEvent } from '@vizcom/shared/data-access/graphql';
import { DrawingEventName } from '@vizcom/shared/data-shape';
import {
  CMD_KEY_PREFIX,
  ContextMenuDivider,
  ContextMenuItem,
  ContextMenuSuffix,
  addToast,
  useKeyboardShortcut,
} from '@vizcom/shared-ui-components';

import { ClientSideWorkbenchElementData } from '../../../lib/clientState';
import { useWorkbenchElementSelectionState } from '../../../lib/elementSelectionState';
import { useWorkbenchSyncedState } from '../../../lib/useWorkbenchSyncedState';
import {
  copyWorkbenchElementsToClipboard,
  copyLinkToSelection,
  pasteWorkbenchElementsFromClipboard,
} from '../../../lib/workbenchClipboardUtils';
import {
  SECTION_PADDING,
  SECTION_DEFAULT_COLOR,
} from '../../elements/section/WorkbenchElementSection';
import { getSectionElements } from '../../elements/section/helpers';
import {
  MAX_Z_POSITION,
  getElementsBoundingBox,
  getWorkbenchElementZPositionRange,
} from '../../helpers';
import { triggerDeleteElementsModal } from '../../modals/triggerDeleteElementsModal';

const ensureClipboardFeatureAvailable = () => {
  if (navigator.clipboard.readText === undefined) {
    addToast(
      `The feature is not supported. Please use Chrome or Edge to use this feature.`,
      {
        type: 'danger',
      }
    );

    return false;
  }

  return true;
};

export type SharedContextMenuItemsProps = {
  workbenchId: string;
  elements: ClientSideWorkbenchElementData[];
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
};

export const SharedContextMenuItems = ({
  workbenchId,
  elements,
  handleAction,
}: SharedContextMenuItemsProps) => {
  const { scene } = useThree();
  const store = useStore();
  const setFocusedElementsId = useWorkbenchElementSelectionState(
    (state) => state.setFocusedElementsId
  );

  const handleWrapInSection = () => {
    const { x, y, width, height } = getElementsBoundingBox(elements);
    const zRange = getWorkbenchElementZPositionRange(scene);
    const zIndex = isFinite(zRange[1]) ? zRange[1] + 1 : MAX_Z_POSITION / 2;

    const sectionId = uuidv4();
    handleAction({
      type: 'createElements',
      newElements: [
        {
          id: sectionId,
          updatedAt: '0',
          x: x,
          y: y,
          width: width + SECTION_PADDING,
          height: height + SECTION_PADDING,
          zIndex,
          __typename: 'WorkbenchElementSection',
          color: SECTION_DEFAULT_COLOR,
          title: '',
        },
      ],
    });

    setFocusedElementsId(sectionId);
  };

  const handleZIndexUp = () => {
    const zRange = getWorkbenchElementZPositionRange(scene);
    const zIndex = isFinite(zRange[1]) ? zRange[1] + 1 : MAX_Z_POSITION / 2;

    handleAction({
      type: 'multiPosition',
      newElementData: elements.map((e, index) => ({
        id: e.id,
        zIndex: zIndex + index,
      })),
    });
  };

  const handleZIndexDown = () => {
    const zRange = getWorkbenchElementZPositionRange(scene);
    const zIndex = isFinite(zRange[0]) ? zRange[0] - 1 : MAX_Z_POSITION / 2 - 1;

    handleAction({
      type: 'multiPosition',
      newElementData: elements.map((e, index) => ({
        id: e.id,
        zIndex: zIndex - index,
      })),
    });
  };

  const handleCopy = () => {
    if (!ensureClipboardFeatureAvailable()) {
      return;
    }

    copyWorkbenchElementsToClipboard(
      elements,
      elements.map((e) => e.id).join('/')
    );
  };

  const handlePaste = async () => {
    if (!ensureClipboardFeatureAvailable()) {
      return;
    }

    pasteWorkbenchElementsFromClipboard(
      await navigator.clipboard.readText(),
      handleAction,
      setFocusedElementsId,
      store.getState()
    );
  };

  const elementIds = elements.map((e) => e.id);

  const handleDuplicate = () => {
    const sections = elements.filter(
      (e) => e.__typename === 'WorkbenchElementSection'
    );
    const sectionElements = sections.flatMap((section) =>
      getSectionElements(scene, section)
    );
    const allElementIdsSet = new Set([
      ...elementIds,
      ...sectionElements.map((e) => e.userData.elementId),
    ]);
    const allElementIds = Array.from(allElementIdsSet);
    const newElementIds = allElementIds.map(() => uuidv4());

    handleAction({
      type: 'duplicateElements',
      elementIds: allElementIds,
      newElementIds,
    });
    setFocusedElementsId(newElementIds.join('/'));

    for (const e of elements) {
      if (e.__typename === 'Drawing') {
        publishTrackingEvent({
          type: DrawingEventName.DRAWING_DUPLICATED,
          data: {
            drawingId: e.id,
          },
        });
      }
    }
  };

  const handleDelete = useCallback(async () => {
    const sectionElements = elements.flatMap((deletedElement) =>
      getSectionElements(scene, deletedElement)
    );

    const elementsToDeleteIds = [
      ...elements.map((e) => e.id),
      ...sectionElements.map((e) => e.userData.elementId),
    ].filter((id, index, self) => self.indexOf(id) === index);

    if (!elementsToDeleteIds.length) return;

    try {
      await triggerDeleteElementsModal(elementsToDeleteIds);
    } catch {
      return;
    }

    for (const e of elements) {
      if (e.__typename === 'Drawing') {
        publishTrackingEvent({
          type: DrawingEventName.DRAWING_DELETED,
          data: {
            drawingId: e.id,
          },
        });
      }
    }

    handleAction({
      type: 'deleteElements',
      elementIds: elementsToDeleteIds,
    });
  }, [elements]);

  useKeyboardShortcut('[', handleZIndexDown);
  useKeyboardShortcut(']', handleZIndexUp);

  return (
    <>
      <ContextMenuItem onClick={handleWrapInSection}>
        Wrap in section
      </ContextMenuItem>
      <ContextMenuDivider />
      <ContextMenuItem onClick={handleZIndexUp}>
        Bring to front <ContextMenuSuffix>]</ContextMenuSuffix>
      </ContextMenuItem>
      <ContextMenuItem onClick={handleZIndexDown}>
        Send to back <ContextMenuSuffix>[</ContextMenuSuffix>
      </ContextMenuItem>
      <ContextMenuDivider />
      <ContextMenuItem
        onClick={() => copyLinkToSelection(workbenchId, elementIds)}
      >
        Copy link to selection{' '}
        <ContextMenuSuffix>{CMD_KEY_PREFIX}L</ContextMenuSuffix>
      </ContextMenuItem>
      <ContextMenuItem onClick={handleCopy}>
        Copy <ContextMenuSuffix>{CMD_KEY_PREFIX}C</ContextMenuSuffix>
      </ContextMenuItem>
      <ContextMenuItem onClick={handlePaste}>
        Paste <ContextMenuSuffix>{CMD_KEY_PREFIX}V</ContextMenuSuffix>
      </ContextMenuItem>
      <ContextMenuItem onClick={handleDuplicate}>
        Duplicate
        <ContextMenuSuffix>{CMD_KEY_PREFIX}D</ContextMenuSuffix>
      </ContextMenuItem>
      <ContextMenuDivider />
      <ContextMenuItem onClick={handleDelete}>
        Delete <ContextMenuSuffix>Del</ContextMenuSuffix>
      </ContextMenuItem>
    </>
  );
};

import React from 'react';
import styled from 'styled-components';

const PreviewWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => `${theme.surface.primary}1A`};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
`;

const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);

  &:not([src]) {
    opacity: 0;
  }
`;

interface HistoryEntry {
  objectUrl: string;
  seed: number;
  timestamp: number;
  blob: Blob;
}

interface RealtimePreviewProps {
  currentEntry: HistoryEntry | null;
}

export const RealtimePreview: React.FC<RealtimePreviewProps> = ({
  currentEntry,
}) => {
  return (
    <PreviewWrapper>
      {currentEntry && (
        <PreviewImage
          src={currentEntry.objectUrl}
          crossOrigin="anonymous"
          alt="Realtime preview"
        />
      )}
    </PreviewWrapper>
  );
};

import { ThreeEvent, useThree } from '@react-three/fiber';
import { useDrag } from '@use-gesture/react';
import { useContext } from 'react';
import * as THREE from 'three';

import {
  filterChildByWorkbenchElementUserData,
  WorkbenchObjectObject3D,
} from '../components/objectsUserdata';
import { useWorkbenchToolState } from '../components/toolbar/WorkbenchToolContext';
import { SnapGuide } from '../components/utils/Snapping';
import {
  useMapControls,
  handleScrollCanvas,
} from '../components/utils/mapControls/utils';
import { ClientSideWorkbenchElementData } from '../lib/clientState';
import { useWorkbenchElementSelectionState } from '../lib/elementSelectionState';
import {
  isDraggingContext,
  useIsWorkbenchViewer,
  floorPlane,
} from '../lib/utils';

interface UseElementTranslationProps {
  element: ClientSideWorkbenchElementData;
  isEditing: boolean;
  elementIsActive: boolean;
  setIsDragging: (value: boolean) => void;
  setSnapGuides: (guides: SnapGuide[]) => void;
  handleLastDrag: (
    allObjectsToMove: WorkbenchObjectObject3D[],
    onlyDrawings?: boolean,
    paletteDropTarget?: WorkbenchObjectObject3D
  ) => void;
  handleDragging: (
    allObjectsToMove: WorkbenchObjectObject3D[],
    delta: [number, number],
    snapElements: boolean,
    onlyDrawings?: boolean,
    paletteDropTarget?: WorkbenchObjectObject3D
  ) => void;
  setEditingElementId: (id: string) => void;
  getObjectsToMove: (focusedElementsId: string[]) => WorkbenchObjectObject3D[];
}

export const useElementTranslation = ({
  element,
  isEditing,
  elementIsActive,
  setIsDragging,
  setSnapGuides,
  handleLastDrag,
  handleDragging,
  setEditingElementId,
  getObjectsToMove,
}: UseElementTranslationProps) => {
  const scene = useThree((s) => s.scene);
  const camera = useThree((s) => s.camera);
  const controls = useMapControls();
  const isViewer = useIsWorkbenchViewer();
  const isDraggingRef = useContext(isDraggingContext);
  const { tool } = useWorkbenchToolState();

  const bind = useDrag<ThreeEvent<PointerEvent>>(
    ({ event, intentional, down, last, memo, tap }) => {
      if (isEditing || tool !== 'select') return;
      event.stopPropagation();

      const focusedElementsId = useWorkbenchElementSelectionState
        .getState()
        .focusedElementsId.split('/');
      const isCurrentElementFocused = focusedElementsId.includes(element.id);

      if (tap) {
        if (event.shiftKey) {
          if (isCurrentElementFocused) {
            useWorkbenchElementSelectionState
              .getState()
              .removeElementFromFocus(element.id);
            return;
          } else {
            useWorkbenchElementSelectionState.getState().addToFocus(element.id);
            return;
          }
        } else if (!isCurrentElementFocused) {
          useWorkbenchElementSelectionState
            .getState()
            .setFocusedElementsId(element.id);
          setEditingElementId('');
          return;
        }
      }

      if (elementIsActive || isViewer) return;

      const planeIntersectPoint = new THREE.Vector3();
      event.ray.intersectPlane(floorPlane, planeIntersectPoint);
      const initialPosition = memo?.initialPosition || [
        planeIntersectPoint.x,
        planeIntersectPoint.y,
      ];

      const xDelta = planeIntersectPoint.x - initialPosition[0];
      const yDelta = planeIntersectPoint.y - initialPosition[1];
      const direction =
        Math.abs(xDelta) > Math.abs(yDelta) ? 'horizontal' : 'vertical';
      const lockHorizontal = direction === 'horizontal' && event.shiftKey;
      const lockVertical = direction === 'vertical' && event.shiftKey;
      const delta: [number, number] = [
        lockVertical ? 0 : xDelta,
        lockHorizontal ? 0 : yDelta,
      ];

      if (intentional) {
        isDraggingRef.current = down;
        setIsDragging(true);

        if (!isCurrentElementFocused) {
          useWorkbenchElementSelectionState
            .getState()
            .setFocusedElementsId(element.id);
          return;
        }

        const allObjectsToMove = (
          element.__typename === 'WorkbenchElementSection'
            ? memo?.objectsToMove || getObjectsToMove(focusedElementsId)
            : getObjectsToMove(focusedElementsId)
        ) as WorkbenchObjectObject3D[];

        if (!allObjectsToMove.length) return memo;

        const workbenchElementsToMove = allObjectsToMove.filter(
          ({ userData }) =>
            userData.workbenchObjectType !== 'multi-focused-element-container'
        );
        const onlyDrawings = workbenchElementsToMove.every(
          (element) => element.userData.elementTypename === 'Drawing'
        );

        const mousePosition = new THREE.Vector2(event.point.x, event.point.y);

        const [paletteDropTarget] = filterChildByWorkbenchElementUserData(
          scene,
          (userData) =>
            userData.elementTypename === 'WorkbenchElementPalette' &&
            mousePosition.x >= userData.elementX - userData.elementWidth / 2 &&
            mousePosition.x <= userData.elementX + userData.elementWidth / 2 &&
            mousePosition.y >= userData.elementY - userData.elementHeight / 2 &&
            mousePosition.y <= userData.elementY + userData.elementHeight / 2
        );

        handleScrollCanvas(event, camera, controls);
        if (last) {
          handleLastDrag(allObjectsToMove, onlyDrawings, paletteDropTarget);
          setSnapGuides([]);
        } else {
          handleDragging(
            allObjectsToMove,
            delta,
            !event.ctrlKey,
            onlyDrawings,
            paletteDropTarget
          );
        }
      }

      return {
        initialPosition,
        objectsToMove:
          memo?.objectsToMove || getObjectsToMove(focusedElementsId),
      };
    },
    {
      threshold: 5,
      triggerAllEvents: true,
      pointer: { keys: false },
    }
  );

  return bind;
};

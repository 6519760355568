import { useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { useWorkbenchSyncedState } from '../../../lib/useWorkbenchSyncedState';
import { CustomHtml } from '../../utils/CustomHtml';
import { CustomText } from '../../utils/CustomText';
import { DashedRoundedRectangle } from '../../utils/DashedRoundedRectangle';
import { RoundedPlaneGeometry } from '../../utils/RoundedPlaneGeometry';

export const PALETTE_NAME_HEIGHT = 22;
export const PALETTE_NAME_WIDTH = 40;
const PALETTE_NAME_PADDING = 25;

interface NameDimensions {
  width: number;
  needsEllipses: boolean;
  availableWidth: number;
  xPosition: number;
}

export const PaletteName = ({
  id,
  name,
  width,
  height,
  paletteStatusWidth,
  handleAction,
  editingName,
  setEditingName,
}: {
  id: string;
  name: string;
  width: number;
  height: number;
  paletteStatusWidth: number;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
  editingName: boolean;
  setEditingName: (editingName: boolean) => void;
}) => {
  const theme = useTheme();
  const nameInputRef = useRef<HTMLDivElement>(null!);
  const [nameDimensions, setNameDimensions] = useState<NameDimensions>({
    width: 0,
    needsEllipses: false,
    availableWidth: 0,
    xPosition: 0,
  });

  const getAvailableWidth = () => {
    const statusPadding = paletteStatusWidth
      ? paletteStatusWidth + PALETTE_NAME_PADDING
      : 0;
    return width - statusPadding;
  };

  const calculateNameDimensions = (textRenderInfo: any) => {
    const textWidth = textRenderInfo.blockBounds[2] + 10;
    const availableWidth = getAvailableWidth();
    const needsEllipses = textWidth > availableWidth;
    const nameWidth = needsEllipses ? availableWidth : textWidth;
    const xPosition = width - nameWidth < 0 ? 0 : nameWidth / 2 + -width / 2;

    return {
      width: nameWidth,
      needsEllipses,
      availableWidth,
      xPosition,
    };
  };

  // Focus handling for name editing
  useEffect(() => {
    if (editingName && nameInputRef.current) {
      nameInputRef.current.innerText = name || '';
      setTimeout(() => nameInputRef.current?.focus(), 0);
    }
  }, [editingName, name]);

  const handleNameSync = (text: any) => {
    setNameDimensions(calculateNameDimensions(text._textRenderInfo));
  };

  const updatePaletteName = (newName: string) => {
    handleAction({
      type: 'updatePalette',
      elementId: id,
      name: newName.trim(),
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      updatePaletteName(e.currentTarget.innerText);
      setEditingName(false);
      e.preventDefault();
    }
  };

  const yPosition = height / 2 + 14;

  return (
    <group userData={{ cursor: 'text' }}>
      <mesh
        scale={[nameDimensions.width, PALETTE_NAME_HEIGHT, 1]}
        position={[
          nameDimensions.width / 2 - width / 2,
          height / 2 + PALETTE_NAME_HEIGHT / 2 + 3,
          0,
        ]}
        onDoubleClick={(e) => {
          e.stopPropagation();
          setEditingName(true);
        }}
        userData={{ vizcomRenderingOrder: [{ zIndex: 2 }] }}
      >
        <planeGeometry />
        <meshBasicMaterial transparent opacity={0} />
      </mesh>

      <CustomHtml position={[0, yPosition, 0]} transform>
        <div style={{ width }}>
          <NameInput
            ref={nameInputRef}
            contentEditable
            onFocus={(e) => window.getSelection()?.selectAllChildren(e.target)}
            onBlur={(e) => {
              updatePaletteName(e.target.innerText);
              setEditingName(false);
            }}
            onKeyDown={handleKeyDown}
            style={{
              display: editingName ? 'inline-flex' : 'none',
              width: getAvailableWidth(),
              color: theme.button.palette,
              background: '#FFFFFF',
            }}
          />
        </div>
      </CustomHtml>

      {!editingName && (
        <>
          {/* Background rectangle */}
          <group position={[nameDimensions.xPosition, yPosition, 0]}>
            <DashedRoundedRectangle
              width={Math.min(nameDimensions.width, width)}
              height={20}
              radius={parseFloat(theme.borderRadius.s)}
              color={theme.border.palette}
            />
            <mesh>
              <RoundedPlaneGeometry
                width={Math.min(nameDimensions.width, width)}
                height={20}
                radius={parseFloat(theme.borderRadius.s)}
              />
              <meshBasicMaterial color={'#FFFFFF'} transparent />
            </mesh>
          </group>

          <group userData={{ vizcomRenderingOrder: [{ zIndex: 1 }] }}>
            <CustomText
              color={theme.button.palette}
              position={[5 - width / 2, yPosition, 0]}
              fontSize={12}
              font={'Inter'}
              fontWeight={'semiBold'}
              anchorX={'left'}
              maxWidth={width - (nameDimensions.needsEllipses ? 15 : 0)}
              whiteSpace="nowrap"
              clipRect={[
                0,
                -10,
                nameDimensions.availableWidth -
                  (nameDimensions.needsEllipses ? 22 : 0),
                10,
              ]}
              content={name}
              onSync={handleNameSync}
            />

            {nameDimensions.needsEllipses && (
              <CustomText
                color={theme.button.palette}
                position={[
                  width / 2 -
                    15 -
                    (paletteStatusWidth
                      ? paletteStatusWidth + PALETTE_NAME_PADDING
                      : 0),
                  yPosition,
                  0,
                ]}
                fontSize={12}
                font={'Inter'}
                fontWeight={'semiBold'}
                anchorX={'left'}
                content={'...'}
              />
            )}
          </group>
        </>
      )}
    </group>
  );
};

const NameInput = styled.div`
  display: inline-flex;
  min-width: 20px;
  width: 100%;
  height: 26px;
  background: white;
  color: ${({ theme }) => theme.button.palette};
  border: 1px solid ${({ theme }) => theme.border.paletteActive};
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: ${({ theme }) => theme.borderRadius.m};
  font-size: 12px;
  letter-spacing: 0.1px;
  font-weight: 600;
  margin: 0;
  padding: 0 4px;
  white-space: nowrap;
  overflow: hidden;
  outline: none;
  align-items: center;
`;

import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { usePublishedChangelogs } from '@vizcom/shared/data-access/graphql';
import {
  FormattedDate,
  Markdown,
  Surface,
  Table,
  Text,
} from '@vizcom/shared-ui-components';

import {
  SettingsPageContainer,
  SettingsPageDivider,
  SettingsPageHeader,
} from '../components/SettingsPageLayout';

const ChangelogDateTag = styled.div<{
  isLatestChangelog?: boolean;
}>`
  position: absolute;
  top: 0px;
  right: 25px;
  background-color: ${(p) =>
    p.isLatestChangelog ? p.theme.button.primary : p.theme.button.secondary};
  border-radius: ${({ theme: { borderRadius } }) =>
    `0 0 ${borderRadius.m} ${borderRadius.m}`};
  padding: 8px;
  z-index: 1;
`;

const ChangelogTitle = styled.h1`
  font-size: 1.75rem;
  margin-bottom: 16px;
  padding-bottom: 8px;
  border-bottom: solid 1px ${(p) => p.theme.deprecated.white}22;
`;

export const ChangelogPage = () => {
  const changelogsPageSize = 10;
  const [changelogTablePage, setChangelogTablePage] = useState(0);
  const changelogs = usePublishedChangelogs({
    count: changelogsPageSize,
    offset: changelogTablePage * changelogsPageSize,
  });
  const totalPages = Math.ceil(changelogs.totalCount / changelogsPageSize);
  const theme = useTheme();

  const isLatestChangelog = (changelogIndex: number) =>
    changelogIndex === 0 && changelogTablePage === 0;

  return (
    <SettingsPageContainer>
      <SettingsPageHeader>
        <Text style={{ fontSize: 18, fontWeight: 600 }}>Latest Changes</Text>
      </SettingsPageHeader>

      <SettingsPageDivider />

      <Table
        cols={0}
        tableStyle={{ overflow: 'visible' }}
        onNextPage={() => {
          setChangelogTablePage((page) => Math.min(totalPages, page + 1));
        }}
        onPrevPage={() => {
          setChangelogTablePage((page) => Math.max(0, page - 1));
        }}
        totalPages={totalPages}
        currentPage={changelogTablePage + 1}
      >
        {(changelogs.data || []).map((item, index) => (
          <Surface
            key={item.id}
            style={{
              minHeight: 200,
              marginBottom: 16,
              color: theme.deprecated.white,
              border: isLatestChangelog(index)
                ? `solid 1px ${theme.deprecated.primary.default}`
                : `solid 1px ${theme.deprecated.secondary.default}`,
            }}
          >
            <ChangelogDateTag isLatestChangelog={isLatestChangelog(index)}>
              {isLatestChangelog(index) ? `latest | ` : ''}
              <FormattedDate date={item.publishedAt!} />
            </ChangelogDateTag>
            <ChangelogTitle>{item.title}</ChangelogTitle>
            <Markdown>{item.content}</Markdown>
          </Surface>
        ))}

        {(!changelogs.data || changelogs.fetching) && (
          <Surface
            loading
            style={{
              minHeight: 200,
            }}
          />
        )}
      </Table>
    </SettingsPageContainer>
  );
};

import styled from 'styled-components';
import { ToolbarButtonState, Button } from '@vizcom/shared-ui-components';

type ToolbarIconProps = {
  $toolState: ToolbarButtonState;
  icon: React.ReactNode;
  onClick: () => void;
};

export const ToolbarIcon = ({
  $toolState,
  icon,
  onClick,
}: ToolbarIconProps) => {
  return (
    <IconButton
      variant="secondary"
      size="icon"
      $toolState={$toolState}
      onClick={onClick}
    >
      {icon}
    </IconButton>
  );
};

const IconButton = styled(Button)<{
  $toolState: ToolbarButtonState;
}>`
  :not(:disabled) {
    ${({ $toolState, theme }) =>
      $toolState === ToolbarButtonState.ACTIVE
        ? `background-color: ${theme.deprecated.primary.default} !important;`
        : $toolState === ToolbarButtonState.BACKGROUND
        ? `background-color: ${theme.deprecated.primary.disabled} !important;`
        : ''}
  }
`;

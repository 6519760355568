import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  Button,
  ErrorBoundary,
  Text,
  useCrisp,
} from '@vizcom/shared-ui-components';

export const WorkbenchErrorBoundary = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <ErrorBoundary
      fallbackRender={({ error }) => <WorkbenchErrorPage error={error} />}
      logToSentry
    >
      {children}
    </ErrorBoundary>
  );
};

const OLD_BROWSER_ERROR_MESSAGES = ['findLastIndex', 'toSorted'];

const WorkbenchErrorPage = ({ error }: { error: any }) => {
  const crisp = useCrisp();

  const errorMessage = error?.message ?? 'Unknown error';
  const isOldBrowserError = OLD_BROWSER_ERROR_MESSAGES.some((message) =>
    errorMessage.includes(message)
  );

  return (
    <FullPageContainer>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
          margin: '24px 0',
          maxWidth: 600,
        }}
      >
        <Text block type="h2">
          There was an error
        </Text>
        {isOldBrowserError ? (
          <Text block type="b1">
            This problem seems linked to an outdated browser version. Please
            make sure you're using the last version of your browser (Chrome,
            Safari, Firefox) and your OS (Windows, MacOS, iOS, Android).
          </Text>
        ) : (
          <>
            <Text block type="b1">
              Please make sure you're using the last version of your browser
              (Chrome, Safari, Firefox) and your OS (Windows, MacOS, iOS,
              Android).
            </Text>
            <Text block type="b1">
              We've been notified of the issue and are working on fixing it.
            </Text>
          </>
        )}
        <Text block type="b1">
          If the error persists, please contact support and provide information
          on how to reproduce the issue.
        </Text>
      </div>
      <Text block color="error" style={{ margin: '24px 0', maxWidth: 450 }}>
        {errorMessage}
      </Text>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 12,
        }}
      >
        <Button variant="primary" as={Link} to={'/'}>
          Return to dashboard
        </Button>
        <Text block>Or</Text>
        <Button variant="secondary" onClick={() => crisp.openChat()}>
          Contact support
        </Button>
      </div>
    </FullPageContainer>
  );
};

const FullPageContainer = styled.div`
  background-color: ${(p) => p.theme.surface.primary};
  display: flex;
  flex-direction: column;
  padding: 4em;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

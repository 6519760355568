import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useTeam } from '@vizcom/shared/data-access/graphql';
import {
  LoadingErrorPage,
  LoadingLogo,
  NotFoundPage,
  useRegisterCurrentOrganization,
} from '@vizcom/shared-ui-components';

import { Folder } from '../Folder/Folder';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  overflow-y: auto;
`;

export const Team = () => {
  const { team_id } = useParams();

  const { data: team, error, fetching } = useTeam(team_id);
  useRegisterCurrentOrganization(team?.organization?.id);

  if (fetching) {
    return (
      <Wrapper
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <LoadingLogo />
      </Wrapper>
    );
  }

  if (error) {
    return (
      <LoadingErrorPage
        targetType="page"
        errorMessage={error.graphQLErrors?.[0]?.message ?? error.message}
      />
    );
  }

  if (team === null || team === undefined) {
    return <NotFoundPage targetType="page" />;
  }

  return <Folder folderId={team.rootFolder?.id} isTeamPage />;
};

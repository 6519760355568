import { omit } from 'lodash';
import {
  urqlClient,
  CreateLayerMutation,
} from '@vizcom/shared/data-access/graphql';
import {
  LayerMetadata3d,
  assertExists,
  assertUnreachable,
  genBottomOrderKey,
  genTopOrderKey,
} from '@vizcom/shared/js-utils';
import { imageDataToBlob } from '@vizcom/shared-ui-components';

import {
  SyncedActionPayloadFromType,
  SyncedActionType,
} from '../../SyncedAction';
import { Drawing2dStudio, DrawingLayer } from '../../useDrawingSyncedState';
import { UpdateBulkLayersAction } from './updateBulkLayers';
import { cachedLayerImagesByUrl } from './updateLayer';

// When we upload a mesh, we store the `Blob` in `meshPath` with the optimisticUpdater to show the mesh without waiting for the server response
// but when we receive the server response, `meshPath` gets replaced by the storage URL of the mesh
// this would cause a re-fetch of the mesh file, so we store a mapping of the `meshPath` URL -> Blob here to prevent that
// when needing to consume the mesh, we check if the URL is in this map and use the Blob instead
export const cachedLayerMeshByUrl = {} as Record<string, Blob>;

export type LayerPayload = {
  id: string;
  name: string;
  visible: boolean;
  opacity: number;
  blendMode: string;
  fill: string;
  orderKey: string;
  isGroup: boolean;
  parentId?: string | null;
  image?: ImageData | string | Blob | null;
  meshPath?: Blob | string | null;
  metadata3D?: LayerMetadata3d;
};

type AddLayerActionPayload = {
  type: 'addLayer';
  layer: Omit<LayerPayload, 'orderKey'> & // can either have an order key or a placement, not both
    (
      | { orderKey: string; placement?: never }
      | { orderKey?: never; placement: 'top' | 'bottom' }
    );
};

export const AddLayerAction: SyncedActionType<
  Drawing2dStudio,
  AddLayerActionPayload,
  {
    orderKey: string;
  }
> = {
  type: 'addLayer',
  optimisticUpdater: ({ payload, meta }, drawing) => {
    const existingLayer = drawing.layers.nodes.find(
      (l) => l.id === payload.layer.id
    );

    assertExists(meta.custom?.orderKey);

    if (!existingLayer) {
      const newLayer: typeof drawing.layers.nodes[0] = {
        ...omit(payload.layer, 'image', 'placement'),
        orderKey: meta.custom.orderKey,
        drawingId: drawing.id,
        updatedAt: '0',
        createdAt: '0',
        meshPath: payload.layer.meshPath,
        imagePath: payload.layer.image ?? undefined,
      };

      drawing.layers.nodes.push(newLayer);
    }
  },
  remoteUpdater: async ({ payload, meta }, drawingId) => {
    const image =
      payload.layer.image instanceof ImageData
        ? await imageDataToBlob(payload.layer.image)
        : payload.layer.image;

    assertExists(meta.custom?.orderKey);

    const res = await urqlClient.mutation(CreateLayerMutation, {
      input: {
        layer: {
          ...omit(
            payload.layer,
            'image',
            'createdAt',
            'updatedAt',
            'placement'
          ),
          orderKey: meta.custom.orderKey,
          drawingId,
          imagePath: image,
        },
      },
    });

    if (res.data?.createLayer?.layer?.imagePath && image instanceof Blob) {
      cachedLayerImagesByUrl[res.data?.createLayer?.layer?.imagePath] = image;
    }

    if (
      res.data?.createLayer?.layer?.meshPath &&
      payload.layer.meshPath instanceof Blob
    ) {
      cachedLayerMeshByUrl[res.data?.createLayer?.layer?.meshPath] =
        payload.layer.meshPath;
    }

    if (res?.error) {
      throw new Error(
        `Error while creating layer, please retry. ${
          res.error.graphQLErrors[0]?.message ?? res.error.message
        }`
      );
    }
  },
  undoConstructor: ({ payload }) => {
    const undoPayload: SyncedActionPayloadFromType<
      typeof UpdateBulkLayersAction
    > = {
      type: 'updateBulkLayers',
      deletedLayerIds: [payload.layer.id],
    };

    return undoPayload;
  },
  onRemoteUpdateError({ payload }, error, state) {
    if (
      error?.message?.includes('Drawing_layer_order_key_excl') &&
      payload.layer.placement
    ) {
      // another layer with the same order key already exists
      // in this case, we recompute the order key based on the placement
      return {
        newMeta: {
          custom: {
            orderKey: getOrderKeyForPlacement(
              payload.layer.placement,
              state.layers.nodes
            ),
          },
        },
      };
    }
  },
  metaConstructor: (payload, drawing) => ({
    custom: {
      orderKey: payload.layer.orderKey
        ? payload.layer.orderKey
        : getOrderKeyForPlacement(
            payload.layer.placement!,
            drawing.layers.nodes
          ),
    },
  }),
};

const getOrderKeyForPlacement = (
  placement: 'top' | 'bottom',
  layers: DrawingLayer[]
) =>
  placement === 'top'
    ? genTopOrderKey(layers)
    : placement === 'bottom'
    ? genBottomOrderKey(layers)
    : assertUnreachable(placement);

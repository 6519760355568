import styled, { useTheme } from 'styled-components';
import { ImageInferenceType } from '@vizcom/shared/data-access/graphql';
import {
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  Text,
  AutoplayVideo,
} from '@vizcom/shared-ui-components';

import refineVideo from './assets/tooltip_refine.mp4';
import renderVideo from './assets/tooltip_render.mp4';

type ImageInferenceTypeToggleProps = {
  value: ImageInferenceType;
  disabled: boolean;
  onChange: (value: ImageInferenceType) => void;
  isRenderOnly?: boolean;
  modesNotAllowedReason?: string;
};

export const ImageInferenceTypeToggle = ({
  value,
  disabled,
  onChange: _onChange,
  isRenderOnly,
  modesNotAllowedReason,
}: ImageInferenceTypeToggleProps) => {
  const theme = useTheme();

  const disabledMask = `linear-gradient( to bottom, ${theme.surface.primary} -50%, transparent 100% )`;

  const refineDisabled = isRenderOnly;
  const realtimeDisabled = isRenderOnly;

  const onChange = (type: ImageInferenceType) => {
    if (disabled) return;
    if (isRenderOnly && type !== ImageInferenceType.Render) return;
    _onChange(type);
  };

  return (
    // TODO: use standard HorizontalSwitch component
    <TogglesWrapper $enabled={!disabled}>
      <Toggles $enabled={!disabled}>
        <RichTooltip
          trigger="hover"
          placement="bottom"
          delay={{
            open: 1500,
            close: 0,
          }}
          padding={12}
        >
          <RichTooltipTrigger>
            <div
              onClick={() => onChange(ImageInferenceType.Render)}
              style={{
                color:
                  value === ImageInferenceType.Render
                    ? theme.text.body
                    : theme.text.subtext,
              }}
            >
              Render
            </div>
          </RichTooltipTrigger>
          <TooltipContent>
            <TooltipTitle type="b2">Render from sketch</TooltipTitle>
            <TooltipDescription type="b2" color="subtext">
              Create realistic renderings from a sketch or line drawing.
            </TooltipDescription>
            <div style={{ textAlign: 'right', marginBottom: '12px' }}>
              <a
                href="https://youtu.be/ZH_s91eEF1M?si=BkZb_xu_0HEJQFV4&t=71"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  fontSize: '10px',
                  color: theme.deprecated.primary.default,
                  textDecoration: 'none',
                }}
              >
                Learn more
              </a>
            </div>
            <TooltipVideo src={renderVideo} />
          </TooltipContent>
        </RichTooltip>
        <RichTooltip
          trigger="hover"
          placement="bottom"
          delay={{
            open: refineDisabled ? 300 : 1500,
            close: 0,
          }}
          padding={12}
        >
          <RichTooltipTrigger>
            <div
              onClick={() => onChange(ImageInferenceType.Refine)}
              style={{
                color:
                  value === ImageInferenceType.Refine
                    ? theme.text.body
                    : theme.text.subtext,
                maskImage: refineDisabled ? disabledMask : 'none',
                cursor: refineDisabled ? 'default' : 'pointer',
              }}
            >
              Refine
            </div>
          </RichTooltipTrigger>
          <TooltipContent>
            {modesNotAllowedReason ?? (
              <>
                <TooltipTitle type="b2">Refine existing image</TooltipTitle>
                <TooltipDescription type="b2" color="subtext">
                  Add detail to your renderings.
                </TooltipDescription>
                <div
                  style={{
                    textAlign: 'right',
                    marginTop: 12,
                    marginBottom: 12,
                  }}
                >
                  <a
                    href="https://youtu.be/ZH_s91eEF1M?si=B5r9MojgwU1XTtud&t=266"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      fontSize: '10px',
                      color: theme.deprecated.primary.default,
                      textDecoration: 'none',
                    }}
                  >
                    Learn more
                  </a>
                </div>
                <TooltipVideo src={refineVideo} />
              </>
            )}
          </TooltipContent>
        </RichTooltip>
        <RichTooltip
          trigger="hover"
          placement="bottom"
          delay={{
            open: realtimeDisabled ? 300 : 1500,
            close: 0,
          }}
          padding={12}
        >
          <RichTooltipTrigger>
            <div
              onClick={() => onChange(ImageInferenceType.Realtime)}
              style={{
                color:
                  value === ImageInferenceType.Realtime
                    ? theme.text.body
                    : theme.text.subtext,
                maskImage: realtimeDisabled ? disabledMask : 'none',
                cursor: realtimeDisabled ? 'default' : 'pointer',
              }}
            >
              Live
            </div>
          </RichTooltipTrigger>
          <TooltipContent>
            {modesNotAllowedReason ?? (
              <>
                <TooltipTitle type="b2">Live render</TooltipTitle>
                <TooltipDescription type="b2" color="subtext">
                  Get instant feedback as you draw with live rendering.
                </TooltipDescription>
              </>
            )}
          </TooltipContent>
        </RichTooltip>
        <Toggle $imageInferenceType={value} />
      </Toggles>
    </TogglesWrapper>
  );
};

const Toggle = styled.span<{
  $imageInferenceType: ImageInferenceType;
}>`
  position: absolute;
  left: ${({ $imageInferenceType }) => {
    switch ($imageInferenceType) {
      case ImageInferenceType.Render:
        return '2px';
      case ImageInferenceType.Refine:
        return 'calc(33.33% + 2px)';
      case ImageInferenceType.Realtime:
        return 'calc(66.66% + 2px)';
    }
  }};
  top: 2px;
  width: calc(33.33% - 4px);
  height: calc(100% - 4px);
  z-index: -1;

  border-radius: ${({ theme }) => `calc(${theme.borderRadius.m} - 2px)`};
  background-color: ${({ theme }) => theme.surface.tertiary};
  transition: 0.3s ease-out all;
`;

const TogglesWrapper = styled.div<{
  $enabled?: boolean;
}>`
  padding: 16px;
  pointer-events: ${({ $enabled }) => ($enabled ? 'all' : 'none')};
  mask-image: ${({ theme, $enabled }) =>
    $enabled
      ? 'none'
      : `linear-gradient( to bottom, ${theme.surface.primary} -50%, transparent 100% )`};

  border-bottom: 1px solid ${({ theme }) => theme.surface.tertiary};
`;

const Toggles = styled.div<{
  $enabled?: boolean;
}>`
  width: 100%;
  text-align: center;
  will-change: transform;
  transition: 0.3s ease all;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  background-color: ${({ theme }) => theme.surface.secondary};

  cursor: ${({ $enabled }) => ($enabled ? 'pointer' : 'default')};

  > div {
    display: inline-block;
    width: 33.33%;
    height: 32px;
    padding: 9px 0;
    position: relative;
    border: none;
    transition: 0.3s ease all;
    font-weight: 600;

    &:focus {
      outline: none;
    }
  }
`;

const TooltipContent = styled(RichTooltipContent)`
  color: ${({ theme }) => theme.deprecated.white};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  max-width: 240px;
  padding: 16px;
  background-color: ${({ theme }) => theme.surface.primary};
`;

const TooltipTitle = styled(Text)`
  margin-bottom: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TooltipDescription = styled(Text)`
  line-height: 1.5;
`;

const TooltipVideo = styled(AutoplayVideo)`
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  border-radius: ${({ theme }) => theme.borderRadius.m};
`;

import { MouseEventHandler, MutableRefObject, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { WorkbenchBasicData } from '@vizcom/shared/data-access/graphql';
import {
  ContextMenu,
  ContextMenuDivider,
  ContextMenuItem,
  copySharedLinkToClipboard,
  DuplicateWorkbenchModal,
  isPublicSharingEnabled,
  MenuHorizontalIcon,
  Text,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

import { RelativeDate } from '../../Dates';
import {
  SelectableItem,
  useSelectedItems,
} from '../../Folder/SelectedItemsProvider';
import { Content, Thumbnail, ThumbnailContainer, Wrapper } from './File.styled';
import { RenameModal } from './RenameModal';

export const MenuVerticalIcon = styled(MenuHorizontalIcon)`
  transform: rotate(90deg);
  opacity: 0.5;
`;

export const FileName = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 18px;
`;

const Meta = styled.div`
  display: flex;
  align-items: center;
  max-width: 180px;
  color: ${({ theme }) => theme.text.subtext};
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
`;

const Author = styled.div`
  display: inline-block;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Date = styled.div`
  display: inline-block;
  white-space: pre;
`;

const Delimiter = styled.span`
  margin: 0 0.2rem;
`;

type FileProps = {
  file: WorkbenchBasicData;
  index: number;
};

export const File = (props: FileProps) => {
  const { file } = props;
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);
  const {
    selectedItems,
    setSelectedItems,
    setMultipleDeleteModalOpen,
    setMultipleMoveModalOpen,
  } = useSelectedItems();

  const editorUrl = paths.workbench.file(file.id);

  const handleOpen = () => {
    navigate(editorUrl);
  };

  const handleOpenInNewTab = () => {
    window.open(editorUrl, '_blank');
  };

  const handleDeleteClick: MouseEventHandler = (e) => {
    e.preventDefault();
    if (!hasSelectedItems) {
      setSelectedItems([
        { type: 'workbench', id: file.id, index: props.index },
      ]);
    }
    setMultipleDeleteModalOpen(true);
  };

  const isSelected = !!selectedItems.find(({ id }) => id === file.id);
  const hasSelectedItems = selectedItems.length > 1;
  const publicSharingEnabled = isPublicSharingEnabled(file);

  return (
    <>
      <SelectableItem
        type="workbench"
        id={file.id}
        index={props.index}
        ref={containerRef}
      >
        <Wrapper $selected={isSelected} $logoBackground>
          <ThumbnailContainer>
            {file.thumbnail && (
              <Thumbnail crossOrigin="anonymous" src={file.thumbnail} />
            )}
          </ThumbnailContainer>

          <Content>
            <FileName type="sh1" color="body">
              {file.name}
            </FileName>
            <Meta>
              <Author>
                {file.lastUpdatedBy?.name ??
                  file.lastUpdatedBy?.email ??
                  'Edited'}
              </Author>
              <Delimiter>&#183;</Delimiter>
              <Date>
                <RelativeDate date={file.updatedAt} />
              </Date>
            </Meta>

            <ContextMenu
              buttonProps={{
                variant: 'bare',
                disabled: hasSelectedItems && !isSelected,
                size: 'icon',
                style: {
                  zIndex: 3,
                  gridArea: '1 / 2 / 3 / 3',
                },
              }}
              items={
                <>
                  {!hasSelectedItems && (
                    <>
                      <ContextMenuItem
                        as={Link}
                        to={editorUrl}
                        onClick={handleOpen}
                      >
                        Open
                      </ContextMenuItem>
                      <ContextMenuItem onClick={handleOpenInNewTab}>
                        Open in new tab
                      </ContextMenuItem>
                      {publicSharingEnabled && (
                        <ContextMenuItem
                          onClick={() => copySharedLinkToClipboard(file)}
                        >
                          Copy public link
                        </ContextMenuItem>
                      )}
                      <ContextMenuDivider />
                      <ContextMenuItem
                        onClick={() => {
                          setDuplicateModalOpen(true);
                        }}
                      >
                        Duplicate
                      </ContextMenuItem>
                    </>
                  )}
                  {file.isEditable && (
                    <>
                      <ContextMenuItem
                        onClick={() => {
                          if (!hasSelectedItems) {
                            setSelectedItems([
                              {
                                type: 'workbench',
                                id: file.id,
                                index: props.index,
                              },
                            ]);
                          }
                          setMultipleMoveModalOpen(true);
                        }}
                      >
                        Move
                      </ContextMenuItem>
                      {!hasSelectedItems && (
                        <ContextMenuItem
                          onClick={() => setRenameModalOpen(true)}
                        >
                          Rename
                        </ContextMenuItem>
                      )}
                      <ContextMenuDivider />
                      <ContextMenuItem onClick={handleDeleteClick}>
                        Delete
                      </ContextMenuItem>
                    </>
                  )}
                  {hasSelectedItems && !file.isEditable && (
                    <ContextMenuItem>No editorial permissions</ContextMenuItem>
                  )}
                </>
              }
              parentRef={containerRef as MutableRefObject<HTMLElement>}
            >
              <MenuVerticalIcon />
            </ContextMenu>
          </Content>
        </Wrapper>
      </SelectableItem>
      <DuplicateWorkbenchModal
        isOpen={duplicateModalOpen && !hasSelectedItems}
        setIsOpen={setDuplicateModalOpen}
        workbenchId={file.id}
      />
      <RenameModal
        isOpen={renameModalOpen}
        setIsOpen={setRenameModalOpen}
        workbenchId={file.id}
        previousName={file.name || ''}
      />
    </>
  );
};

import { useParams } from 'react-router-dom';
import { useWorkbenchContent } from '@vizcom/shared/data-access/graphql';

import { MobileWorkbenchActionErrorPage } from '../ErrorActionPage';
import { ImageUpload } from './ImageUploadElement';

export const MobileStudioImageUpload = () => {
  const { workbenchId, drawingId } = useParams();
  const { data: workbench, error: workbenchError } =
    useWorkbenchContent(workbenchId);

  // Error handling
  if (!workbenchId || !drawingId || workbenchError) {
    const errorMessage =
      'Invalid parameters: ' +
      (!workbenchId ? 'Missing workbench ID. ' : '') +
      (workbenchError ? 'Error fetching workbench content. ' : '');

    return (
      <MobileWorkbenchActionErrorPage
        title="Unable to Upload Image"
        description="We encountered an issue while trying to set up the image upload. This could be due to invalid parameters or a temporary system error. Please try again, and if the problem persists, contact our support team for assistance."
        errorMessage={errorMessage}
      />
    );
  }

  return (
    <ImageUpload
      workbenchId={workbenchId}
      drawingId={drawingId}
      workbenchName={workbench?.name ?? 'Untitled workbench'}
    />
  );
};

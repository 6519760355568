import { useCurrentUser } from '@vizcom/shared/data-access/graphql';

// this is a wildcard, enabling this flag enables all flags for this account
export const GLOBAL_ENABLE_FLAG = 'enable_all';

export const useFeatureFlag = (flag: string) => {
  const user = useCurrentUser();
  const flags = user?.data?.featureFlags || {};

  const flagEnabled = Boolean(flags[GLOBAL_ENABLE_FLAG] || flags[flag]);

  return { loading: user.fetching, flagEnabled };
};

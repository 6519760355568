import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { WorkbenchElementVideoData } from '@vizcom/shared/data-access/graphql';
import {
  Button,
  CopyPromptIcon,
  DownloadIcon,
  FloatingPanel,
  InlineFlex,
  Text,
  addToast,
  downloadFile,
  useKeyboardShortcut,
} from '@vizcom/shared-ui-components';

import { useWorkbenchToolState } from '../../toolbar/WorkbenchToolContext';
import { HtmlOverlay } from '../../utils/HtmlOverlay';
import { useMapControls } from '../../utils/mapControls/utils';
import { VideoControls } from './VideoControls';

export const FullscreenVideo = ({
  video,
  loop,
  fullscreen,
  currentTime,
  playing,
  setLoop,
  setFullscreen,
  setCurrentTime,
  setPlaying,
}: {
  video: WorkbenchElementVideoData;
  loop: boolean;
  fullscreen: boolean;
  currentTime: number;
  playing: boolean;
  setLoop: (loop: boolean) => void;
  setFullscreen: (fullscreen: boolean) => void;
  setCurrentTime: (currentTime: number) => void;
  setPlaying: (playing: boolean) => void;
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const controls = useMapControls();
  const { setToolsDisabled } = useWorkbenchToolState();

  const { name, prompt, sourceImagePath, videoPath } = video;

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = currentTime;
    }
  }, [videoRef.current]);

  useEffect(() => {
    controls.disable();
    setToolsDisabled(true);

    return () => {
      controls.enable();
      setToolsDisabled(false);
    };
  }, []);

  useKeyboardShortcut(
    'escape',
    () => {
      setFullscreen(false);
    },
    { capture: true }
  );

  return (
    <HtmlOverlay>
      <FullscreenContainer>
        <BlurredBackground />
        <Content>
          <VideoContainer>
            <InlineFlex
              $alignItems="center"
              $justifyContent="center"
              style={{ height: '80%' }}
            >
              <video
                ref={videoRef}
                autoPlay={playing}
                loop={loop}
                controls={false}
                onPause={() => setPlaying(false)}
                onPlay={() => setPlaying(true)}
                onTimeUpdate={() => {
                  setCurrentTime(videoRef.current?.currentTime ?? 0);
                }}
              >
                <source src={videoPath} type="video/mp4" />
              </video>
            </InlineFlex>
            <VideoControls
              showPlayPause
              videoRef={videoRef}
              currentTime={currentTime}
              loop={loop}
              setLoop={setLoop}
              fullscreen={fullscreen}
              setFullscreen={setFullscreen}
              playing={playing}
              setPlaying={setPlaying}
            />
          </VideoContainer>

          <VideoDetails>
            <DetailsHeader>
              <Text type="sh2">Details</Text>
            </DetailsHeader>

            <PromptContainer>
              <Text type="sh2">Prompt</Text>
              <Button
                size="icon"
                variant="secondary"
                onClick={() => {
                  if (!prompt) return;
                  navigator.clipboard.writeText(prompt);
                  addToast('Prompt copied to clipboard');
                }}
              >
                <CopyPromptIcon />
              </Button>
              <PromptContent>
                <Text>{prompt}</Text>
              </PromptContent>
            </PromptContainer>

            <VideoMeta>
              {videoRef.current && (
                <>
                  <Text type="sh2" color="subtext">
                    Format
                  </Text>
                  <Text>.mp4</Text>

                  <Text type="sh2" color="subtext">
                    Size
                  </Text>
                  <Text>
                    {videoRef.current.videoWidth +
                      ' x ' +
                      videoRef.current.videoHeight}
                  </Text>
                </>
              )}
              {sourceImagePath && (
                <>
                  <Text type="sh2" color="subtext">
                    Source image
                  </Text>
                  <Text>
                    <Button
                      size="icon"
                      variant="secondary"
                      onClick={() => downloadFile(sourceImagePath, name)}
                    >
                      <DownloadIcon />
                    </Button>
                  </Text>
                </>
              )}

              {sourceImagePath && (
                <SourceImageContainer>
                  <img src={sourceImagePath} alt={name} />
                </SourceImageContainer>
              )}
            </VideoMeta>
          </VideoDetails>
        </Content>
      </FullscreenContainer>
    </HtmlOverlay>
  );
};

const FullscreenContainer = styled.div`
  position: absolute;
  top: 28px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all;
`;

const BlurredBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
`;

const VideoContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.borderRadius.l};
  padding: 0 100px;
  align-items: center;
  justify-content: center;

  video {
    max-width: 100%;
    max-height: 100%;
    border-radius: ${({ theme }) => theme.borderRadius.l};
    box-shadow: 0px 4px 46.1px 0px rgba(0, 0, 0, 0.25);
  }
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 250px;
`;

const VideoDetails = styled(FloatingPanel)`
  right: 0;
  width: 250px;
  top: 80px;
  bottom: 80px;
  position: absolute;
`;

const DetailsHeader = styled.div`
  padding: 14px;
  border-bottom: 1px solid ${({ theme }) => theme.border.primary};
`;

const PromptContainer = styled.div`
  padding: 14px;
  border-bottom: 1px solid ${({ theme }) => theme.border.primary};
  display: grid;
  grid-template-columns: 1fr 24px;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
`;

const PromptContent = styled.div`
  padding: 16px;
  background: ${({ theme }) => theme.surface.secondary};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  grid-column: 1 / 3;
  min-height: 100px;
`;

const VideoMeta = styled.div`
  padding: 14px;
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr max-content;
  grid-template-rows: repeat(3, max-content);
  overflow-y: auto;
  max-height: calc(100% - 1rem);
  border-radius: ${({ theme }) => theme.borderRadius.m};

  span {
    display: flex;
    align-items: center;
  }

  span:nth-child(even) {
    justify-self: end;
  }
`;

const SourceImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.surface.secondary};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  grid-column: 1 / 3;
  padding: 8px;
  max-height: 100px;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;

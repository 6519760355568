import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  overflow-y: scroll;
  user-select: none;
`;

export const MaterialPreviewItem = styled.div<{
  $active?: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px 4px;
  cursor: pointer;
  background-color: ${({ theme, $active }) =>
    $active ? `${theme.surface.secondary}` : 'transparent'};
  z-index: 2;

  ${({ theme, $active }) =>
    !$active
      ? `
  &:hover {
    background-color: ${theme.surface.secondary};
    border-radius: ${theme.borderRadius.s};
  }
  `
      : ''}
`;

export const MaterialIcon = styled.div<{
  $size?: number;
  $fallbackColor?: string;
}>`
  flex-shrink: 0;
  display: inline-block;
  width: ${({ $size }) => $size ?? 24}px;
  height: ${({ $size }) => $size ?? 24}px;
  background-color: #${({ $fallbackColor }) => $fallbackColor ?? '333333'}33;
  border-radius: 50%;
  overflow: hidden;
`;

import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * A hook that returns a state and a setter function that is debounced.
 * Only the setter is debounced - the state value updates immediately when the debounce period ends.
 * If the setter is called multiple times within the delay period, only the last call will take effect.
 *
 * @param initialValue The initial state value
 * @param delay The debounce delay in milliseconds
 * @returns A tuple of [state, debouncedSetter]
 */
export const useDebouncedStateSetter = <T>(
  initialValue: T,
  delay: number
): [T, (value: T) => void] => {
  const [state, setState] = useState(initialValue);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const debouncedSetState = useCallback(
    (value: T) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        setState(value);
      }, delay);
    },
    [delay]
  );

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return [state, debouncedSetState];
};

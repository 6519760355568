import { ReactNode, useEffect, useRef } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import {
  getAccessToken,
  getSharingSecret,
  removeAccessToken,
  useCurrentUser,
} from '@vizcom/shared/data-access/graphql';
import { LoadingLogo } from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';
import { MobileRedirect } from '@vizcom/web/features/mobile-redirect';

const REDIRECT_AFTER_LOGIN_URL_STORAGE_KEY = 'vizcom_redirect_after_login_url';

const isSmallScreen = window.innerWidth < 480;

export const LoggedInAuthGuard = (props: {
  children: ReactNode;
  acceptSharingSecret?: boolean;
  isMobileAccessible?: boolean;
}) => {
  const currentUser = useCurrentUser();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const sharingSecret = getSharingSecret();

  const hasRedirectedRef = useRef(false);
  useEffect(() => {
    if (import.meta.env.VITE_IGNORE_ALL_DOMAIN_REDIRECTIONS) {
      // used in preview enviornments to avoid redirections to beta.vizcom.ai
      return;
    }
    // redirect the user to its dedicated subdomain if needed
    // when this happens, we need to clean the access token to make sure we don't block the user in an endless loop because he won't be able
    // to logout on the current domain
    if (currentUser.data?.appRootUrl && !hasRedirectedRef.current) {
      if (window.location.origin !== currentUser.data.appRootUrl) {
        hasRedirectedRef.current = true; // avoid calling this twice, while the page is navigating to another domain but still is unloaded
        const accessToken = getAccessToken();
        removeAccessToken();
        window.location.replace(
          `${currentUser.data.appRootUrl}/auth#access_token=${accessToken}`
          // pass the access token here to not have to re-login on the new domain
          // this is useful when migrating existing org to a new custom subdomain
        );
      }
    }
  }, [currentUser]);

  useEffect(() => {
    const redirectAfterLogin = sessionStorage.getItem(
      REDIRECT_AFTER_LOGIN_URL_STORAGE_KEY
    );

    if (!currentUser.fetching && currentUser.data && redirectAfterLogin) {
      sessionStorage.removeItem(REDIRECT_AFTER_LOGIN_URL_STORAGE_KEY);
      navigate(redirectAfterLogin, { replace: true });
    }
  }, [currentUser, navigate]);

  if (currentUser.fetching) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          backgroundColor: theme.surface.modal,
        }}
      >
        <LoadingLogo />
      </div>
    );
  }

  if (!currentUser.data?.id && !(props.acceptSharingSecret && sharingSecret)) {
    sessionStorage.setItem(
      REDIRECT_AFTER_LOGIN_URL_STORAGE_KEY,
      location.pathname + location.search + location.hash
    );
    return (
      <Navigate
        to={{ pathname: paths.auth.home(), hash: location.hash }}
        replace
      />
    );
  }

  if (!props.isMobileAccessible && isSmallScreen) {
    return <MobileRedirect />;
  }

  return <>{props.children}</>;
};

export const LoggedOutAuthGuard = (props: { children: ReactNode }) => {
  const theme = useTheme();
  const currentUser = useCurrentUser();
  const navigate = useNavigate();

  useEffect(() => {
    // This needs to be in an effect and not return <Navigate> because the render method should be pure
    // and here we need to mutation the sessionStorage
    if (!currentUser.fetching && currentUser.data?.id) {
      const redirectAfterLogin = sessionStorage.getItem(
        REDIRECT_AFTER_LOGIN_URL_STORAGE_KEY
      );
      if (redirectAfterLogin) {
        sessionStorage.removeItem(REDIRECT_AFTER_LOGIN_URL_STORAGE_KEY);
        navigate(redirectAfterLogin, { replace: true });
      } else {
        navigate(paths.files.dashboard(), { replace: true });
      }
    }
  }, [currentUser, navigate]);

  if (currentUser.fetching) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          backgroundColor: theme.surface.modal,
        }}
      >
        <LoadingLogo />
      </div>
    );
  }

  if (currentUser.data?.id) {
    return null;
  }

  return <>{props.children}</>;
};

export const AdminAuthGuard = (props: { children: ReactNode }) => {
  const theme = useTheme();
  const currentUser = useCurrentUser();

  if (currentUser.fetching) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          backgroundColor: theme.surface.modal,
        }}
      >
        <LoadingLogo />
      </div>
    );
  }

  if (!currentUser.data?.id || currentUser.data.isAdmin !== true) {
    return <Navigate to={paths.auth.home()} replace />;
  }

  if (isSmallScreen) {
    return <MobileRedirect />;
  }

  return <>{props.children}</>;
};

export const EduAdminAuthGuard = (props: { children: ReactNode }) => {
  const theme = useTheme();
  const currentUser = useCurrentUser();

  if (currentUser.fetching) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          backgroundColor: theme.surface.modal,
        }}
      >
        <LoadingLogo />
      </div>
    );
  }

  if (!currentUser.data?.id || currentUser.data.isEduAdmin !== true) {
    return <Navigate to={paths.auth.home()} replace />;
  }

  if (isSmallScreen) {
    return <MobileRedirect />;
  }

  return <>{props.children}</>;
};

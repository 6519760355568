import { isEqual, without } from 'lodash';
import { useState } from 'react';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  OrganizationInvitesData,
  OrganizationMembersData,
  TeamRole,
  useOrganizationMembers,
  useOrganizationSubscription,
  useOrganizationTeamsUsers,
} from '@vizcom/shared/data-access/graphql';
import { assertUnreachable } from '@vizcom/shared/js-utils';
import {
  Button,
  Table,
  TableHeader,
  Text,
  Surface,
  useSelectedOrganization,
  FullPageDarkLoader,
  Tooltip,
  TableCell,
  InfoIcon,
  downloadCSV,
  TextInput,
  Checkbox,
  SelectionCell,
  bottomScreenTunnel,
  CheckIcon,
} from '@vizcom/shared-ui-components';
import { useDebouncedValue } from '@vizcom/shared-utils-hooks';
import { paths } from '@vizcom/shared-utils-paths';

import { SeatsIndicator } from '../OrganizationSubscription/SeatsIndicator';
import {
  SettingsPageContainer,
  SettingsPageDivider,
  SettingsPageHeader,
} from '../components/SettingsPageLayout';
import { useCanEditOrganization } from '../useCanEditOrganization';
import { InviteMembersModal } from './InviteMembersModal';
import { MembersListFilters } from './MembersListFilters';
import { OrganizationMemberRow } from './OrganizationMemberRow';
import { TableSelectionToolbar } from './TableSelectionToolbar';
import { InvitedMemberRow } from './invitedMemberRow';
import { MemberFiltersType, MemberOrderBy } from './memberFiltersType';

export const MEMBERS_TABLE_SIZING = {
  totalColumns: 16,
  name: 4,
  dateAdded: 3,
  lastActive: 3,
  teams: 3,
  role: 3,
};

export type OrganizationMember = OrganizationMembersData['members']['edges'][0];
export type OrganizationInvite =
  OrganizationInvitesData['organizationInvites']['nodes'][0];
export type OrganizationInviteOrMember =
  | OrganizationInvite
  | OrganizationMember;

export const isElemMember = (
  elem: OrganizationMember | OrganizationInvite
): elem is OrganizationMember => {
  return 'node' in elem;
};

export const isElemInvite = (
  elem: OrganizationMember | OrganizationInvite
): elem is OrganizationInvite => {
  return 'email' in elem;
};

export const getEmailFromInviteOrMember = (
  inviteOrMember: OrganizationInviteOrMember
) => {
  return isElemMember(inviteOrMember)
    ? inviteOrMember.node.email
    : inviteOrMember.email;
};

const defaultFilters: MemberFiltersType = {
  orderBy: MemberOrderBy.createdAtAsc,
  role: undefined,
  status: undefined,
};

export const OrganizationMembers = (props: {
  openInviteMembersModal: boolean;
  openSubscriptionUpdateModal: boolean;
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { data: organization, loading } = useSelectedOrganization();

  const inviteRedirectKey = 'invite-redirect';
  const onSubscriptionUpdate =
    props.openSubscriptionUpdateModal &&
    new URLSearchParams(location.search).get(inviteRedirectKey) === 'true'
      ? () =>
          navigate(paths.settings.organization.membersInvite(organization!.id))
      : undefined;

  const canEdit = useCanEditOrganization(organization?.id);
  const { data, fetching } = useOrganizationMembers(organization?.id);
  const { data: organizationTeamsUsers } = useOrganizationTeamsUsers(
    organization?.id
  );
  const { data: orgSubscription, fetching: fetchingOrgSubscription } =
    useOrganizationSubscription(organization?.id);

  const [filters, setFilters] = useState<MemberFiltersType>(defaultFilters);
  const [selection, setSelection] = useState<string[]>([]);

  const [debouncedSearchText, searchText, setSearchText] = useDebouncedValue(
    '',
    500
  );

  const includeJoined = !(filters.status === 'pending');
  const includeInvited = !(filters.status === 'joined');

  const mixedInvitesAndMembers = [
    ...((includeJoined && data?.members.edges) || []),
    ...((includeInvited && data?.organizationInvites?.nodes) || []),
  ];

  const filteredInvitesOrMembers = mixedInvitesAndMembers
    .filter((inviteOrMember) => {
      const searchActive = debouncedSearchText !== '';
      const isMember = isElemMember(inviteOrMember);

      const filterRole = inviteOrMember.role === filters.role;

      const email = isMember ? inviteOrMember.node.email : inviteOrMember.email;
      const name = isMember
        ? inviteOrMember.node.name?.toLowerCase()
        : undefined;

      const lowerCaseSearch = debouncedSearchText.toLowerCase();
      const filterSearchEmail = searchActive && email.includes(lowerCaseSearch);
      const filterSearchName =
        searchActive && isMember && name?.includes(lowerCaseSearch);

      return (
        (filters.role ? filterRole : true) &&
        (searchActive ? filterSearchEmail || filterSearchName : true)
      );
    })
    .sort((a, b) => {
      const aIsMember = isElemMember(a);
      const bIsMember = isElemMember(b);

      const { createdAt: aCreatedAt, email: aEmail } = aIsMember ? a.node : a;
      const { createdAt: bCreatedAt, email: bEmail } = bIsMember ? b.node : b;

      const undefinedLastActive = '2000-01-01'; // Make sure undefined is sorted last
      const aLastActiveTimestamp = new Date(
        (aIsMember && a.node.lastActive) || undefinedLastActive
      ).getTime();
      const bLastActiveTimestamp = new Date(
        (bIsMember && b.node.lastActive) || undefinedLastActive
      ).getTime();

      switch (filters.orderBy) {
        case MemberOrderBy.createdAtAsc:
          return aCreatedAt.localeCompare(bCreatedAt);
        case MemberOrderBy.createdAtDesc:
          return bCreatedAt.localeCompare(aCreatedAt);
        case MemberOrderBy.emailAsc:
          return aEmail.localeCompare(bEmail);
        case MemberOrderBy.emailDesc:
          return bEmail.localeCompare(aEmail);
        case MemberOrderBy.lastActiveAsc:
          return aLastActiveTimestamp - bLastActiveTimestamp;
        case MemberOrderBy.lastActiveDesc:
          return bLastActiveTimestamp - aLastActiveTimestamp;
        default:
          assertUnreachable(filters.orderBy);
      }
    });

  const filteredSelection = selection.filter((email) =>
    filteredInvitesOrMembers.some(
      (inviteOrMember) => getEmailFromInviteOrMember(inviteOrMember) === email
    )
  );

  const filtersAreEmpty =
    isEqual(filters, defaultFilters) && debouncedSearchText === '';

  const resetFilters = () => {
    setFilters(defaultFilters);
    setSearchText('');
  };

  const handleExportButtonClick = () => {
    const nonNullMembersData = filteredInvitesOrMembers.map(
      (inviteOrMember) => {
        const isMember = isElemMember(inviteOrMember);
        const role = inviteOrMember.role.toString();

        return isMember
          ? {
              role,
              name: inviteOrMember.node.name || '',
              dateAdded: inviteOrMember.node.createdAt,
              lastActive: inviteOrMember.node.lastActive,
              email: inviteOrMember.node.email,
              status: 'joined',
            }
          : {
              role,
              name: inviteOrMember.email,
              dateAdded: inviteOrMember.createdAt,
              lastActive: '',
              email: inviteOrMember.email,
              status: 'pending',
            };
      }
    );

    downloadCSV(nonNullMembersData, 'Members');
  };

  if (fetching || loading || fetchingOrgSubscription || !orgSubscription) {
    return <FullPageDarkLoader />;
  }

  if (!organization) {
    return <Navigate to="/" replace />;
  }

  const getRoleCountFromSource = (source: TeamRole[]) => ({
    [TeamRole.Admin]: source.filter((r) => r === TeamRole.Admin).length,
    [TeamRole.Editor]: source.filter((r) => r === TeamRole.Editor).length,
    [TeamRole.Viewer]: source.filter((r) => r === TeamRole.Viewer).length,
  });

  const membersSources = data?.members.edges.map((e) => e.role) ?? [];
  const roleCountFromMembers = getRoleCountFromSource(membersSources);

  const allSelected =
    Object.values(filteredSelection).length === filteredInvitesOrMembers.length;

  const onRowSelectionChange = (inviteOrMember: OrganizationInviteOrMember) => {
    const currentEmail = getEmailFromInviteOrMember(inviteOrMember);
    const isSelected = selection.includes(currentEmail);

    isSelected
      ? setSelection(without(selection, currentEmail))
      : setSelection([...selection, currentEmail]);
  };

  return (
    <SettingsPageContainer>
      {/* Header */}
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <SettingsPageHeader>
          <Text style={{ fontSize: 18, fontWeight: 600 }}>Members</Text>
          <Text color="subtext" type="b1">
            Manage who has access to this workspace
          </Text>
        </SettingsPageHeader>
        <div>
          {canEdit ? (
            <Button
              variant="primary"
              as={Link}
              to={paths.settings.organization.membersInvite(organization!.id)}
              replace={true}
            >
              Invite members
            </Button>
          ) : (
            <Tooltip tip="Only workspace admins can invite new members">
              <Button variant="primary" disabled>
                Invite members
              </Button>
            </Tooltip>
          )}
        </div>
      </div>

      <SettingsPageDivider />

      <MemberRoleStatContainer>
        <MemberRoleStat>
          <Text
            block
            style={{
              fontSize: 24,
              lineHeight: '17px',
              fontWeight: 600,
              marginBottom: 16,
            }}
          >
            {roleCountFromMembers[TeamRole.Admin]}
          </Text>
          <Text block>
            Admin{roleCountFromMembers[TeamRole.Admin] > 1 ? 's' : ''}
            <Tooltip
              tip="Have full edit access to content in this workspace and can manage all workspace settings"
              position="bottom"
            >
              <InfoIcon
                style={{
                  marginLeft: 4,
                  verticalAlign: 'middle',
                  width: 10,
                  height: 10,
                }}
              />
            </Tooltip>
          </Text>
        </MemberRoleStat>
        <MemberRoleStat>
          <Text
            block
            style={{
              fontSize: 24,
              lineHeight: '17px',
              fontWeight: 600,
              marginBottom: 16,
            }}
          >
            {roleCountFromMembers[TeamRole.Editor]}
          </Text>
          <Text block>
            Editor{roleCountFromMembers[TeamRole.Editor] > 1 ? 's' : ''}
            <Tooltip
              tip="Have full edit access to content in this workspace"
              position="bottom"
            >
              <InfoIcon
                style={{
                  marginLeft: 4,
                  verticalAlign: 'middle',
                  width: 10,
                  height: 10,
                }}
              />
            </Tooltip>
          </Text>
        </MemberRoleStat>
        <MemberRoleStat>
          <Text
            block
            style={{
              fontSize: 24,
              lineHeight: '17px',
              fontWeight: 600,
              marginBottom: 16,
            }}
          >
            {roleCountFromMembers[TeamRole.Viewer]}
          </Text>
          <Text block>
            Viewer{roleCountFromMembers[TeamRole.Viewer] > 1 ? 's' : ''}
            <Tooltip
              tip="Have view-only access to content in this workspace"
              position="bottom"
            >
              <InfoIcon
                style={{
                  marginLeft: 4,
                  verticalAlign: 'middle',
                  width: 10,
                  height: 10,
                }}
              />
            </Tooltip>
          </Text>
        </MemberRoleStat>
      </MemberRoleStatContainer>

      <SeatsIndicator
        organizationSubscription={orgSubscription}
        isSubscriptionUpdateModalOpen={props.openSubscriptionUpdateModal}
        setIsSubscriptionUpdateModalOpen={(open) =>
          open
            ? navigate(
                paths.settings.organization.membersSubscriptionUpdate(
                  organization!.id
                )
              )
            : navigate(paths.settings.organization.members(organization!.id))
        }
        onSubscriptionUpdate={onSubscriptionUpdate}
      />

      <Text block type="sh2" style={{ marginTop: 15 }}>
        Members
      </Text>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 16,
          marginTop: 16,
          width: '100%',
        }}
      >
        {/* Search Input */}
        <div
          style={{
            width: 'fit-content',
          }}
        >
          <TextInput
            style={{
              minWidth: 200,
              padding: '8px 16px',
            }}
            $background="secondary"
            placeholder="Search members..."
            type="text"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>

        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 8,
          }}
        >
          <Button
            variant="secondary"
            onClick={handleExportButtonClick}
            size="M"
          >
            CSV Export
          </Button>
          <MembersListFilters filters={filters} setFilters={setFilters} />{' '}
        </div>
      </div>

      {/* Table  */}
      <Table cols={MEMBERS_TABLE_SIZING.totalColumns} addSelectionColumn={true}>
        <TableHeader>
          <SelectionCell>
            <Checkbox
              checked={allSelected}
              onClick={() => {
                allSelected
                  ? setSelection([])
                  : setSelection(
                      filteredInvitesOrMembers.map(getEmailFromInviteOrMember)
                    );
              }}
            >
              <CheckIcon />
            </Checkbox>
          </SelectionCell>
          <TableCell size={MEMBERS_TABLE_SIZING.name}>Name</TableCell>
          <TableCell size={MEMBERS_TABLE_SIZING.dateAdded}>
            Date added
          </TableCell>
          <TableCell size={MEMBERS_TABLE_SIZING.lastActive}>
            Last active
          </TableCell>
          <TableCell size={MEMBERS_TABLE_SIZING.teams}>Teams</TableCell>
          <TableCell size={MEMBERS_TABLE_SIZING.role}>Role</TableCell>
        </TableHeader>

        {filteredInvitesOrMembers.map((inviteOrMember) => {
          return isElemMember(inviteOrMember) ? (
            <OrganizationMemberRow
              key={inviteOrMember.node.id}
              memberEdge={inviteOrMember}
              organization={organization}
              canEdit={canEdit}
              selected={selection.includes(inviteOrMember.node.email)}
              teams={organizationTeamsUsers}
              onSelectedChange={() => onRowSelectionChange(inviteOrMember)}
            />
          ) : (
            <InvitedMemberRow
              key={inviteOrMember.email}
              invite={inviteOrMember}
              organization={organization}
              canEdit={canEdit}
              selected={selection.includes(inviteOrMember.email)}
              teams={organizationTeamsUsers}
              onSelectedChange={() => onRowSelectionChange(inviteOrMember)}
            />
          );
        })}

        {/* Empty state */}
        {filteredInvitesOrMembers.length === 0 && (
          <div
            style={{
              padding: '30px',
              display: 'flex',
              gridColumn: '11 span',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: 16,
            }}
          >
            <Text type="sh1" style={{ width: '100%', textAlign: 'center' }}>
              No members or invitations found
            </Text>
            <div style={{ display: 'flex', gap: 10 }}>
              <Button
                variant="primary"
                size="S"
                as={Link}
                to={paths.settings.organization.membersInvite(organization.id)}
              >
                Invite members
              </Button>
              {!filtersAreEmpty && (
                <Button variant="secondary" size="S" onClick={resetFilters}>
                  Reset filters
                </Button>
              )}
            </div>
          </div>
        )}
      </Table>

      <InviteMembersModal
        organization={orgSubscription}
        inviteRedirectKey={inviteRedirectKey}
        openInviteMembersModal={props.openInviteMembersModal}
      />

      <bottomScreenTunnel.In>
        <TableSelectionToolbar
          data={filteredInvitesOrMembers}
          selection={filteredSelection}
          organizationId={organization.id}
          onResetSelection={() => setSelection([])}
        />
      </bottomScreenTunnel.In>
    </SettingsPageContainer>
  );
};

const MemberRoleStatContainer = styled(Surface)`
  display: flex;
  justify-content: space-around;
  padding: 16px;
  margin: 40px 0;
`;

const MemberRoleStat = styled.div`
  padding: 8px;
`;

import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import { useResetPasswordAndLogin } from '@vizcom/shared/data-access/graphql';
import { Button, Text } from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

import {
  ErrorMessage,
  SuccessMessage,
  Input,
  SectionContainer,
  FormContainer,
  InputGroup,
  PageContainer,
  AuthLinkContainer,
} from '../styles';
import { usePasswordValidation } from '../usePasswordValidation';

type FormData = {
  password: string;
  passwordTwo: string;
};

export const ResetPasswordPage = () => {
  const { token } = useParams<{ token: string }>();
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setError,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      password: '',
      passwordTwo: '',
    },
  });
  const [res, resetPassword] = useResetPasswordAndLogin();

  const { passwordChecklist, valid } = usePasswordValidation(
    watch('password'),
    watch('passwordTwo')
  );

  const onSubmit = async (data: FormData) => {
    const { password } = data;

    if (!token) {
      setError('password', {
        type: 'manual',
        message: 'Token is required',
      });
      return;
    }
    if (!valid) {
      setError('password', {
        type: 'manual',
        message: 'Password does not meet all requirements',
      });
      return;
    }
    const resetRes = await resetPassword(token, password);
    if (resetRes.error) {
      setError('password', {
        type: 'server',
        message:
          resetRes.error.graphQLErrors?.[0]?.message || resetRes.error.message,
      });
    }
  };

  if (!token) {
    return (
      <PageContainer>
        <ErrorMessage>Token is required</ErrorMessage>
        <Link to={paths.auth.home()}>Go to Login</Link>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      {res.data?.resetPassword.user ? (
        <SuccessMessage>
          <Text
            type="h1"
            block
            color="bodyInverted"
            style={{ textAlign: 'left', marginBottom: '24px' }}
          >
            Success!
          </Text>
          <Text
            type="sh1"
            color="bodyInverted"
            style={{ textAlign: 'left', marginTop: '24px' }}
          >
            Your password has been reset.{' '}
            <Link to={paths.files.dashboard()}>Go to dashboard.</Link>
          </Text>
        </SuccessMessage>
      ) : (
        <>
          <SectionContainer>
            <Text type="h1" block color="bodyInverted">
              Reset Password
            </Text>
            <Text type="sh1" color="bodyInverted" style={{ textAlign: 'left' }}>
              Enter your new password.
            </Text>
          </SectionContainer>
          <FormContainer onSubmit={handleSubmit(onSubmit)}>
            <InputGroup>
              <Text
                type="sh2"
                color="bodyInverted"
                style={{ textAlign: 'left' }}
              >
                Password:
              </Text>
              <Input
                type="password"
                {...register('password', { required: 'Password is required' })}
                placeholder="Password"
                required
              />
            </InputGroup>
            {errors.password && (
              <ErrorMessage>{errors.password.message}</ErrorMessage>
            )}
            <InputGroup style={{ marginTop: 24, marginBottom: 8 }}>
              <Text
                type="sh2"
                color="bodyInverted"
                style={{ textAlign: 'left' }}
              >
                Confirm Password:
              </Text>
              <Input
                type="password"
                {...register('passwordTwo', {
                  required: 'Confirm password is required',
                })}
                placeholder="Confirm Password"
                required
              />
            </InputGroup>
            {errors.passwordTwo && (
              <ErrorMessage>{errors.passwordTwo.message}</ErrorMessage>
            )}
            {res.error && (
              <ErrorMessage>
                {res.error?.graphQLErrors[0]?.message || 'Unknown error'}
              </ErrorMessage>
            )}
            {isDirty && passwordChecklist}
            <Button
              variant="secondary"
              disabled={res.fetching || !valid}
              $fullWidth
              style={{
                marginTop: '40px',
              }}
            >
              Submit
            </Button>
          </FormContainer>

          <AuthLinkContainer>
            <Text
              type="b1"
              color="link"
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                marginTop: '24px',
              }}
              as={Link}
              to="/auth"
            >
              Back to login
            </Text>
          </AuthLinkContainer>
        </>
      )}
    </PageContainer>
  );
};

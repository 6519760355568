import {
  OrganizationTeamsUsers,
  useDeleteTeamInvite,
  useDeleteUsersOnTeam,
  useInviteToTeam,
} from '@vizcom/shared/data-access/graphql';
import {
  ComboBoxInput,
  addToast,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';

import {
  OrganizationInviteOrMember,
  getEmailFromInviteOrMember,
} from './OrganizationMembers';

interface UserTeamsComboBoxProps {
  orgTeams: OrganizationTeamsUsers;
  user: OrganizationInviteOrMember;
}

export const UserTeamsComboBox = ({
  orgTeams,
  user,
}: UserTeamsComboBoxProps) => {
  const [, inviteToTeam] = useInviteToTeam();
  const [, deleteTeamInvite] = useDeleteTeamInvite();
  const [, deleteUsersOnTeam] = useDeleteUsersOnTeam();

  const userEmail = getEmailFromInviteOrMember(user);

  const allTeams = [...(orgTeams?.teams.nodes ?? [])];

  const handleInviteToTeam = async (teamId: string) => {
    const teamFound = allTeams.find((team) => team.id === teamId);

    if (!teamFound) {
      return addToast('You cannot invite a user from a non-existent team.', {
        type: 'danger',
      });
    }

    const res = await inviteToTeam({
      input: {
        emails: [userEmail],
        teamId,
      },
    });

    if (res.error) {
      return addToast(
        `An error occurred while inviting ${userEmail} to ${teamFound.name}`,
        {
          type: 'danger',
          secondaryText: formatErrorMessage(res.error),
        }
      );
    }

    return addToast(`${userEmail} has been invited to ${teamFound.name}`);
  };

  const handleInviteRemoveFromTeam = async (teamId: string) => {
    const teamFound = allTeams.find((team) => team.id === teamId);

    if (!teamFound) {
      return addToast('You cannot remove a user from a non-existent team.', {
        type: 'danger',
      });
    }

    const userFoundIsMember = teamFound.members.nodes.find(
      (member) => member.email === userEmail
    );

    const res = userFoundIsMember
      ? await deleteUsersOnTeam({
          input: {
            teamId,
            userId: userFoundIsMember.id,
          },
        })
      : await deleteTeamInvite({
          input: {
            teamId,
            email: userEmail,
          },
        });

    if (res.error) {
      return addToast(
        `An error occurred while removing ${userEmail} from ${teamFound.name}: ${res.error.message}`,
        { type: 'danger' }
      );
    }

    return addToast(`${userEmail} has been removed from ${teamFound.name}`);
  };

  const userTeamsIds = allTeams
    .filter(
      (team) =>
        team.invites.nodes.find((invite) => invite.email === userEmail) ||
        team.members.nodes.find((member) => member.email === userEmail)
    )
    .map((team) => team.id);

  const comboBoxTeamsOptions = allTeams
    .filter((team) => !team.globalInOrganization)
    .map((team) => ({
      value: team.id,
      label: team.name,
    }));

  return (
    <ComboBoxInput
      data={comboBoxTeamsOptions}
      selection={userTeamsIds}
      onSelectElement={handleInviteToTeam}
      onRemoveElement={handleInviteRemoveFromTeam}
      placeholder="Search a team..."
      description="Select a team to invite this member"
      expandable={true}
      widthExpanded={245}
      compact={true}
    />
  );
};

import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import {
  UserClientStateKeys,
  useCurrentUserClientStateByKey,
  useDuplicateWorkbench,
  useFetchOrCreateUserOrganizationDraftFolder,
  useSetCurrentUserClientState,
} from '@vizcom/shared/data-access/graphql';
import { PALETTE_ONBOARDING_TEMPLATE } from '@vizcom/shared/data-shape';
import {
  Button,
  DotsPagination,
  LeftPanelBody,
  LeftPanelContainer,
  MultiStepsModal,
  PanelDescription,
  PanelTitle,
  RightPanelContainer,
  SplitContainer,
  SplitedModalView,
  useMultiStepsModalContext,
  Checkbox,
  addToast,
  formatErrorMessage,
  useSelectedOrganization,
  PlayIcon,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

import mediaStep01 from '../../assets/images/introducing_palettes.gif';
import mediaStep02 from '../../assets/images/palette_curation.png';
import mediaStep03 from '../../assets/videos/using_palette.mp4';

export const PaletteOnboardingModal = ({
  open,
  setIsOpen,
}: {
  open: boolean;
  setIsOpen: (open: boolean) => void;
}) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const onboardingStatus =
    useCurrentUserClientStateByKey(UserClientStateKeys.OnboardingStatus) || {};
  const paletteModalStatus = onboardingStatus['palette_onboarding'];
  const [, updateState] = useSetCurrentUserClientState();
  const { data: selectedOrganization } = useSelectedOrganization();
  const { data: draftFolder } = useFetchOrCreateUserOrganizationDraftFolder(
    selectedOrganization?.id
  );
  const [duplicateWorkbenchRes, duplicateWorkbench] = useDuplicateWorkbench();

  const handleClose = async (value?: boolean) => {
    if (value) return setIsOpen(true);

    setIsOpen(false);

    await updateState({
      input: {
        key: UserClientStateKeys.OnboardingStatus,
        value: {
          ...onboardingStatus,
          palette_onboarding: true,
        },
      },
    });
  };

  useEffect(() => {
    setCurrentStepIndex(0);
  }, [paletteModalStatus]);

  const handleFinalStep = async () => {
    if (!draftFolder?.id || duplicateWorkbenchRes.fetching) {
      return;
    }

    const res = await duplicateWorkbench({
      input: {
        id: PALETTE_ONBOARDING_TEMPLATE.id,
        targetFolderId: draftFolder.id,
        name: PALETTE_ONBOARDING_TEMPLATE.name,
      },
    });

    if (res.error) {
      return addToast('Error while creating palette onboarding template', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }

    if (res.data?.duplicateWorkbench.workbench.id) {
      window.open(
        paths.workbench.file(res.data?.duplicateWorkbench.workbench.id),
        '_blank'
      );
    }
    handleClose();
  };

  return (
    <MultiStepsModal
      isOpen={open}
      setIsOpen={handleClose}
      currentStepIndex={currentStepIndex}
      setCurrentStepIndex={setCurrentStepIndex}
      style={{ maxWidth: '828px' }}
    >
      <SplitedModalView
        position={0}
        title="Introducing Palettes"
        description="Palettes enable you to generate images in a style curated by you"
        image={mediaStep01}
      />

      <SplitedModalView
        position={1}
        title="Curation"
        description="Curate between 3-30 images that reflect your particular art style, design language, a particular visual style."
        image={mediaStep02}
      />

      <SplitedModalView
        position={2}
        title="Using a Palette"
        description="Your palette can be selected from the style selector in workbench or 2d studio to render your sketches"
        video={mediaStep03}
      />

      <CustomFinalStep onButtonClicked={handleFinalStep} />
    </MultiStepsModal>
  );
};

const CustomFinalStep = ({
  onButtonClicked,
}: {
  onButtonClicked: () => void;
}) => {
  const theme = useTheme();
  const { changeStep } = useMultiStepsModalContext();
  const [agreedToTerms, setAgreedToTerms] = useState(true);

  return (
    <SplitContainer>
      {/* LEFT */}
      <LeftPanelContainer>
        <LeftPanelBody>
          <PanelTitle>Learn more</PanelTitle>
          <PanelDescription>
            For a full video walkthrough and additional information on Palettes,
            please{' '}
            <Link
              to="https://docs.vizcom.ai/render-styles"
              target="_blank"
              rel="noopener noreferrer"
            >
              refer here
            </Link>
            <br />
            <br />A new file will open to guide you through creating a Palette.
          </PanelDescription>
          <div>
            <TermsContainer>
              <Checkbox
                checked={agreedToTerms}
                onClick={() => setAgreedToTerms((prev) => !prev)}
              />
              <div>
                By checking, you accept{' '}
                <Link
                  to="https://www.vizcom.ai/terms-of-service"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Vizcom’s terms and conditions
                </Link>{' '}
                regarding Palette training.
              </div>
            </TermsContainer>
            <Button
              variant="primary"
              $fullWidth
              onClick={onButtonClicked}
              disabled={!agreedToTerms}
            >
              Try Palettes
            </Button>
          </div>
        </LeftPanelBody>

        <DotsPagination
          currentStepIndex={3}
          changeStep={changeStep}
          stepsCount={4}
        />
      </LeftPanelContainer>

      {/* RIGHT */}
      <RightPanelContainer>
        <div
          style={{
            background: theme.surface.primary,
            padding: '71px 63px',
            height: '100%',
          }}
        >
          <VideoThumbnailContainer
            onClick={() => {
              window.open(
                'https://www.youtube.com/watch?v=fr5sYJCUOc8',
                '_blank'
              );
            }}
          >
            <StyledImage
              src={`https://img.youtube.com/vi/${'fr5sYJCUOc8'}/maxresdefault.jpg`}
            />
            <PlayIcon $size="XXL" style={{ position: 'absolute' }} />
          </VideoThumbnailContainer>
        </div>
      </RightPanelContainer>
    </SplitContainer>
  );
};

const TermsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;

  font-size: 10px;
  font-weight: 500;
  line-height: normal;
  color: ${({ theme }) => theme.text.subtext};

  a {
    text-decoration: underline;
  }
`;

const VideoThumbnailContainer = styled.div`
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  svg,
  img {
    transition: transform 0.2s ease-in-out;
  }

  &:hover {
    img {
      border: 1px solid ${({ theme }) => theme.border.highlight};
    }

    svg {
      transform: scale(1.2);
    }
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${({ theme }) => theme.borderRadius.l};
  transition: border 0.2s ease-in-out;
`;

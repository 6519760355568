import {
  urqlClient,
  UpdateWorkbenchElementMixMutation,
} from '@vizcom/shared/data-access/graphql';
import { addToast, formatErrorMessage } from '@vizcom/shared-ui-components';

import { MixSourceDrawing } from '../../../components/elements/mix/WorkbenchElementMix';
import { getMixElementHeight } from '../../../components/utils/getContentSize';
import {
  SyncedActionType,
  SyncedActionPayloadFromType,
} from '../../SyncedAction';
import { ClientSideWorkbenchElementData } from '../../clientState';
import { elementById } from '../../utils';

export const UpdateMixAction: SyncedActionType<
  ClientSideWorkbenchElementData[],
  {
    type: 'updateMix';
    elementId: string;
    sourceDrawings: MixSourceDrawing[];
    height?: number;
  },
  {
    height: number;
  }
> = {
  type: 'updateMix',
  optimisticUpdater: ({ payload, meta }, elements) => {
    const sourceElement = elementById(elements, payload.elementId);
    if (sourceElement?.__typename === 'WorkbenchElementMix') {
      sourceElement.sourceDrawings = payload.sourceDrawings;
      sourceElement.height = meta.custom!.height;
    }
  },
  remoteUpdater: async ({ payload, meta }) => {
    const res = await urqlClient.mutation(UpdateWorkbenchElementMixMutation, {
      input: {
        id: payload.elementId,
        patch: {
          sourceDrawings: payload.sourceDrawings,
          height: meta.custom!.height,
        },
      },
    });
    if (res?.error) {
      addToast(`Error while updating element, please retry`, {
        secondaryText: formatErrorMessage(res.error),
        type: 'danger',
      });
    }
  },
  metaConstructor: (payload, elements) => {
    const sourceElement = elementById(elements, payload.elementId);

    if (!sourceElement || sourceElement.__typename !== 'WorkbenchElementMix') {
      return {
        delay: 500,
        debounceId: payload.elementId,
      };
    }

    const oldContentHeight = getMixElementHeight(sourceElement.sourceDrawings);
    const contentHeight = getMixElementHeight(payload.sourceDrawings);
    return {
      delay: 500,
      debounceId: payload.elementId,
      custom: {
        height:
          sourceElement.height -
          (oldContentHeight - contentHeight) *
            (sourceElement.height / oldContentHeight),
      },
    };
  },
  undoConstructor: ({ payload }, elements) => {
    const element = elementById(elements, payload.elementId);
    if (!element || element.__typename !== 'WorkbenchElementMix') {
      return;
    }

    const undoPayload: SyncedActionPayloadFromType<typeof UpdateMixAction> = {
      type: 'updateMix',
      elementId: payload.elementId,
      sourceDrawings: element.sourceDrawings,
      height: element.height,
    };

    return undoPayload;
  },
};

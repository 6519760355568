import { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { PerspectiveCamera } from 'three';
import { CompositeSceneFullData } from '@vizcom/shared/data-access/graphql';
import { RangeInput } from '@vizcom/shared-ui-components';

import { useCompositeSceneSyncedState } from '../../../lib/useCompositeSceneSyncedState';
import { useCompositeSceneEditorContext } from '../compositeSceneEditor/context';
import { CustomInputRow } from '../compositeSceneMenu/CustomInputRow';
import { Container } from './style';

const tempCamera = new PerspectiveCamera();

const minFocalLength = 5.0;
const maxFocalLength = 120.0;

export const CameraConfiguration = ({
  compositeScene,
  handleAction,
}: {
  compositeScene: CompositeSceneFullData;
  handleAction: ReturnType<typeof useCompositeSceneSyncedState>['handleAction'];
}) => {
  const { activeCamera } = useCompositeSceneEditorContext();
  const theme = useTheme();

  const fov = useMemo(() => {
    if (activeCamera) {
      const node = compositeScene.compositeSceneElements.nodes.find(
        (node) => node.id === activeCamera
      )?.meshes.root;

      if (!node) {
        return;
      }

      return node.cameraFov ?? 50.0;
    } else {
      return compositeScene.cameraFov ?? 50.0;
    }
  }, [activeCamera, compositeScene]);
  const focalLengthApproximate = useMemo(() => {
    tempCamera.fov = fov;

    return tempCamera.getFocalLength();
  }, [fov]);

  return (
    <Container>
      <CustomInputRow
        before={<span style={{ color: theme.text.subtext }}>Focal Length</span>}
        after={
          <span style={{ color: theme.text.body }}>
            {focalLengthApproximate.toFixed(0)}mm
          </span>
        }
      >
        <RangeInput
          variant="tertiary"
          min={minFocalLength}
          max={maxFocalLength}
          step={1.0}
          value={maxFocalLength + minFocalLength - fov}
          onChange={(e) => {
            const cameraFov =
              maxFocalLength + minFocalLength - parseFloat(e.target.value);

            if (activeCamera) {
              const node = compositeScene.compositeSceneElements.nodes.find(
                (node) => node.id === activeCamera
              )?.meshes.root;

              if (!node) {
                return;
              }

              handleAction({
                type: 'updateCompositeSceneElement',
                id: activeCamera,
                meshes: {
                  root: {
                    ...node,
                    cameraFov,
                  },
                },
              });
            } else {
              handleAction({
                type: 'updateCompositeScene',
                cameraFov,
              });
            }
          }}
        />
      </CustomInputRow>
    </Container>
  );
};

import { Middleware, computePosition } from '@floating-ui/react';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { CreatePromptStyleReferenceMode } from '@vizcom/shared/data-access/graphql';
import { isEnumValue } from '@vizcom/shared/js-utils';
import {
  CheckIcon,
  CloseIcon,
  InfoIcon,
  Menu,
  MenuItem,
  NumberInput,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  Text,
} from '@vizcom/shared-ui-components';

import { Drawing2dStudio } from '../../../../lib/useDrawingSyncedState';
import { VideoTooltip } from '../../Toolbar/VideoTooltip';
import { InferenceSettings } from '../../useInference';
import checkeredThumbnail from '../assets/checkeredThumbnail.png';
import referenceImageSliderInfo from '../assets/tooltip_reference_image_x480.mp4';
import {
  MinimalInferenceSettings,
  useReferenceMode,
} from '../utils/useReferenceMode';
import { StyleReferenceModal, referenceModeLabel } from './StyleReferenceModal';

interface StyleReferenceSettingsProps {
  drawing: Drawing2dStudio;
  historyPreviewActive: boolean;
  setInferenceSettings: Dispatch<SetStateAction<InferenceSettings>>;
  inferenceSettings: InferenceSettings;
}

export const StyleReferenceSettings = ({
  drawing,
  historyPreviewActive,
  setInferenceSettings,
  inferenceSettings,
}: StyleReferenceSettingsProps) => {
  const theme = useTheme();
  const parentContainerRef = useRef<HTMLDivElement>(null);
  const [isNumberInputDragging, setIsNumberInputDragging] = useState(false);

  // Use the custom hook for reference mode management
  const referenceMode = useReferenceMode(
    inferenceSettings as MinimalInferenceSettings,
    setInferenceSettings
  );

  const isReferenceStrengthDisabled =
    referenceMode.isReferenceImageStrengthDisabled(
      inferenceSettings as MinimalInferenceSettings,
      historyPreviewActive
    );

  /**
   * Get the style reference settings for the current reference mode
   */
  const getStyleReferenceSettings = () => {
    if (referenceMode.isImageReferenceMode(inferenceSettings.referenceMode)) {
      return inferenceSettings.styleReference[
        inferenceSettings.referenceMode as CreatePromptStyleReferenceMode
      ];
    }
    return null;
  };

  const styleReferenceSettings = getStyleReferenceSettings();

  // Simplified conditions using the hook's utility functions
  const showReferenceImageSelector = referenceMode.isImageReferenceMode(
    inferenceSettings.referenceMode
  );
  const showColorSelector = referenceMode.isColorReferenceMode(
    inferenceSettings.referenceMode
  );

  const activeImageReference = styleReferenceSettings?.styleReferenceId
    ? drawing.workbench?.styleReferences.nodes.find(
        (n) => n.id === styleReferenceSettings?.styleReferenceId
      )
    : undefined;

  // used to use parentContainerRef as the position reference for the tooltips menu in this component
  const reparentTooltipReference: Middleware = {
    name: 'reparent',
    fn: async (state) => {
      const position = await computePosition(
        parentContainerRef.current ?? state.elements.reference,
        state.elements.floating,
        {
          placement: 'left',
        }
      );

      return {
        ...state,
        ...position,
      };
    },
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        padding: 16,
        width: '100%',
        pointerEvents: historyPreviewActive ? 'none' : 'all',
        opacity: historyPreviewActive ? 0.5 : 1,
      }}
      ref={parentContainerRef}
    >
      <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
        <Text type="sh2">Reference</Text>
        <RichTooltip trigger="hover" placement="left" padding={16}>
          <RichTooltipTrigger>
            <StyledInfoIcon $size="S" />
          </RichTooltipTrigger>
          <RichTooltipContent style={{ maxWidth: '200px' }}>
            <VideoTooltip
              title="Reference Image"
              description="Use reference images as style guides to influence the look and feel of your renders."
              learnMoreUrl="https://youtu.be/bUF0CDeUT2E?si=0-GULYgo8IPa24Bb&t=78"
              videoSrc={referenceImageSliderInfo}
            />
          </RichTooltipContent>
        </RichTooltip>
      </div>

      <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
        <Menu
          placement="right-start"
          offset={28}
          renderLabel={(props, interactionProps) => (
            <Text
              type="b1"
              color="subtext"
              style={{ cursor: 'pointer' }}
              {...interactionProps}
            >
              {referenceModeLabel[inferenceSettings.referenceMode]}
            </Text>
          )}
        >
          <MenuItem
            prependLabel={
              <StyledCheckIcon
                $visible={
                  inferenceSettings.referenceMode ===
                  CreatePromptStyleReferenceMode.Image
                }
              />
            }
            label={
              <ModeSelectorMenuItemLabel>
                <Text type="b2" block>
                  Transfer a style
                </Text>
                <Text type="b2" block color="subtext">
                  Mix images with your canvas
                </Text>
              </ModeSelectorMenuItemLabel>
            }
            onClick={() => {
              referenceMode.setReferenceMode(
                CreatePromptStyleReferenceMode.Image
              );
            }}
            closeOnClick={false}
          />
          <MenuItem
            prependLabel={
              <StyledCheckIcon
                $visible={
                  inferenceSettings.referenceMode ===
                  CreatePromptStyleReferenceMode.Material
                }
              />
            }
            label={
              <ModeSelectorMenuItemLabel>
                <Text type="b2" block>
                  Apply materials
                </Text>
                <Text type="b2" block color="subtext">
                  Use images as material swatches
                </Text>
              </ModeSelectorMenuItemLabel>
            }
            onClick={() => {
              referenceMode.setReferenceMode(
                CreatePromptStyleReferenceMode.Material
              );
            }}
            closeOnClick={false}
          />
          <MenuItem
            prependLabel={
              <StyledCheckIcon
                $visible={inferenceSettings.referenceMode === 'color'}
              />
            }
            label={
              <ModeSelectorMenuItemLabel>
                <Text type="b2" block>
                  Color
                </Text>
                <Text type="b2" block color="subtext">
                  Apply a specific color
                </Text>
              </ModeSelectorMenuItemLabel>
            }
            onClick={() => {
              referenceMode.setReferenceMode('color');
            }}
            closeOnClick={false}
          />
          <MenuItem
            prependLabel={
              <StyledCheckIcon
                $visible={
                  inferenceSettings.referenceMode ===
                  CreatePromptStyleReferenceMode.TryOn
                }
              />
            }
            label={
              <ModeSelectorMenuItemLabel>
                <Text type="b2" block>
                  Try On
                </Text>
                <Text type="b2" block color="subtext">
                  Apply a garment to your model
                </Text>
              </ModeSelectorMenuItemLabel>
            }
            onClick={() => {
              referenceMode.setReferenceMode(
                CreatePromptStyleReferenceMode.TryOn
              );
            }}
            closeOnClick={false}
          />
          <MenuItem
            prependLabel={
              <StyledCheckIcon
                $visible={
                  inferenceSettings.referenceMode ===
                  CreatePromptStyleReferenceMode.Background
                }
              />
            }
            label={
              <ModeSelectorMenuItemLabel>
                <Text type="b2" block>
                  Environment
                </Text>
                <Text type="b2" block color="subtext">
                  Set a background environment
                </Text>
              </ModeSelectorMenuItemLabel>
            }
            onClick={() => {
              referenceMode.setReferenceMode(
                CreatePromptStyleReferenceMode.Background
              );
            }}
            closeOnClick={false}
          />
        </Menu>

        <RichTooltip
          noParentIntegration
          padding={4}
          trigger="click"
          placement="left"
          displayArrow={false}
          customMiddlewares={[reparentTooltipReference]}
          isOpen={historyPreviewActive ? false : undefined}
        >
          <RichTooltipTrigger>
            <div style={{ flex: 1, width: 0 }}>
              {activeImageReference && showReferenceImageSelector ? (
                <ChipContainer>
                  <ThumbnailWrapper>
                    <Thumbnail
                      src={activeImageReference.imagePath}
                      alt={activeImageReference.imageName || 'Reference'}
                    />
                  </ThumbnailWrapper>
                  <FileName>
                    {activeImageReference.imageName || 'Image'}
                  </FileName>
                  <CloseIconWrapper>
                    <CloseIconStyled
                      onClick={() => referenceMode.clearReference()}
                    />
                  </CloseIconWrapper>
                </ChipContainer>
              ) : showColorSelector &&
                inferenceSettings.colorReference.color ? (
                <ChipContainer>
                  <SelectedColorPreview
                    $color={inferenceSettings.colorReference.color}
                  />
                  <FileName>{inferenceSettings.colorReference.color}</FileName>
                  <CloseIconWrapper>
                    <CloseIconStyled
                      onClick={() => referenceMode.setColorReference(null)}
                    />
                  </CloseIconWrapper>
                </ChipContainer>
              ) : (
                <ChipContainer>
                  <CheckeredImage src={checkeredThumbnail} alt="Add" />
                  <span>Add...</span>
                </ChipContainer>
              )}
            </div>
          </RichTooltipTrigger>
          <RichTooltipContent
            style={{
              padding: 0,
              borderRadius: theme.borderRadius.l,
              overflow: 'hidden',
            }}
            renderContainer={(props, { close }) => (
              <StyleReferenceModal
                drawing={drawing}
                setInferenceSettings={setInferenceSettings}
                inferenceSettings={inferenceSettings}
                onClose={close}
                {...props}
              />
            )}
          />
        </RichTooltip>
        {activeImageReference &&
          isEnumValue(
            inferenceSettings.referenceMode,
            CreatePromptStyleReferenceMode
          ) &&
          inferenceSettings.referenceMode !==
            CreatePromptStyleReferenceMode.Background &&
          inferenceSettings.referenceMode !==
            CreatePromptStyleReferenceMode.TryOn && (
            <NumberInputWrapper
              style={{
                pointerEvents: isReferenceStrengthDisabled ? 'none' : 'all',
                opacity: isReferenceStrengthDisabled ? 0.5 : 1,
              }}
            >
              <RichTooltip
                noParentIntegration
                placement="top"
                trigger="hover"
                delay={{
                  open: 500,
                  close: 0,
                }}
                padding={8}
                isOpen={isNumberInputDragging ? false : undefined}
              >
                <RichTooltipTrigger>
                  <div>
                    <NumberInput
                      value={
                        inferenceSettings.styleReference[
                          inferenceSettings.referenceMode
                        ].strength * 100
                      }
                      min={0}
                      max={100}
                      step={5}
                      unit="%"
                      disabled={isReferenceStrengthDisabled}
                      setValue={(value: number) => {
                        const normalizedStrength =
                          (Math.round(value / 5) * 5) / 100;
                        referenceMode.setReferenceStrength(
                          normalizedStrength,
                          inferenceSettings.referenceMode as CreatePromptStyleReferenceMode
                        );
                      }}
                      onChange={(value: number) => {
                        const normalizedStrength =
                          (Math.round(value / 5) * 5) / 100;
                        referenceMode.setReferenceStrength(
                          normalizedStrength,
                          inferenceSettings.referenceMode as CreatePromptStyleReferenceMode
                        );
                      }}
                      dragArrows={false}
                      enableGestureSlider
                      onDragStateChange={setIsNumberInputDragging}
                    />
                  </div>
                </RichTooltipTrigger>
                <RichTooltipContent>
                  <Text>
                    {isReferenceStrengthDisabled
                      ? 'Reference image strength is fixed'
                      : 'Adjust Strength of Reference Image'}
                  </Text>
                </RichTooltipContent>
              </RichTooltip>
            </NumberInputWrapper>
          )}
      </div>
    </div>
  );
};

const StyledInfoIcon = styled(InfoIcon)`
  color: ${(p) => p.theme.icon.secondary};

  &:hover {
    color: ${({ theme }) => theme.icon.primary};
  }
`;

const StyledCheckIcon = styled(CheckIcon)<{ $visible: boolean }>`
  visibility: ${(p) => (p.$visible ? 'visible' : 'hidden')};
`;

const ChipContainer = styled.div`
  display: flex;
  height: 32px;
  padding: 4px 2px 4px 0px;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  border: 1px solid transparent;
  background: ${({ theme }) => theme.surface.secondary};
  cursor: pointer;
  overflow: hidden;
  &:hover {
    border: 1px solid ${({ theme }) => theme.deprecated.secondary.hover};
  }
`;

const ThumbnailWrapper = styled.div`
  width: 24px;
  height: 24px;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.borderRadius.s};
  flex-shrink: 0;
  margin-left: 4px;
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const FileName = styled.span`
  font-size: 12px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  margin-right: 8px;
  margin-left: 8px;
`;

const NumberInputWrapper = styled.div`
  width: 50px;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  flex-shrink: 0;
  background: ${({ theme }) => theme.surface.secondary};
  border: 1px solid transparent;
  height: 32px;
  &:hover {
    border: 1px solid ${({ theme }) => theme.deprecated.secondary.hover};
  }
`;

const CheckeredImage = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  margin-left: 4px;
`;

const CloseIconWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: auto;
  width: 16px;
`;

const CloseIconStyled = styled(CloseIcon)`
  width: 16px;
  height: 16px;
  fill: ${({ theme }) => theme.icon.secondary};
  flex-shrink: 0;
  cursor: pointer;
`;

const ModeSelectorMenuItemLabel = styled.div`
  grid-column: 2;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SelectedColorPreview = styled.div<{ $color: string }>`
  background-color: ${({ $color }) => $color};
  width: 24px;
  height: 24px;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.borderRadius.s};
  flex-shrink: 0;
  margin-left: 4px;
`;

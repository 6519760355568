import { captureException } from '@sentry/react';
import produce from 'immer';
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { assertExists, sleep } from '@vizcom/shared/js-utils';

import { useComplexSyncExternalStore } from '../../../../../shared/ui/components/src';
import { isIpad } from '../components/helpers';
import { SyncQueueAction, SyncedActionBasePayloadType } from './SyncedAction';
import { SyncQueue } from './syncQueue';

const SYNC_QUEUE_DEBUG_LOCALSTORAGE_KEY = 'vizcom:sync_queue_debug';
const SYNC_QUEUE_ARTIFICIAL_LATENCY_LOCALSTORAGE_KEY =
  'vizcom:sync_queue_artificial_delay';

const SYNC_QUEUE_REMOTE_UDAPTER_ERROR_RETRY_COUNT = 2;
const SYNC_QUEUE_REMOTE_UDAPTER_ERROR_RETRY_DELAY = 2000; // wait 2s after an error before retrying

const NON_CRITICAL_ERRORS = [
  'no values were updated in collection', // in some race-conditions, the user can try to update an element that was already deleted
];

export type SyncQueueItem<
  TPayload extends SyncedActionBasePayloadType,
  TState
> = {
  id: string; // this is different from the debounced id, every action has an id that is set by the sync queue synchronizer and used internally
  action: SyncQueueAction<TPayload>;
  syncQueue: SyncQueue<TState, TPayload>;
  sideEffects?: {
    syncQueueName: string;
    optimisticUpdater: (state: any) => void;
  }[];
};

export class SyncQueueSynchronizer {
  constructor() {
    if (localStorage.getItem(SYNC_QUEUE_DEBUG_LOCALSTORAGE_KEY)) {
      console.info(
        `Sync queue: debug logging enabled, to disable: localStorage.removeItem('${SYNC_QUEUE_DEBUG_LOCALSTORAGE_KEY}')`
      );
    }
    if (localStorage.getItem(SYNC_QUEUE_ARTIFICIAL_LATENCY_LOCALSTORAGE_KEY)) {
      console.info(
        `Sync queue: Artificial delay enabled, to disable: localStorage.removeItem('${SYNC_QUEUE_ARTIFICIAL_LATENCY_LOCALSTORAGE_KEY}')`
      );
    }
  }

  private queue: SyncQueueItem<any, any>[] = [];

  private consuming = false;
  public errorMessage: null | string = null;
  public retrying = false;
  public lastRemoteUpdaterExecutedAt: null | number = null;
  private delayWakeup = null as null | {
    actionId: string | undefined;
    wakeup: () => void;
  };
  private currentlyRunningQueueItemId = undefined as undefined | string;

  private listeners: (() => void)[] = [];
  listen = (listener: () => void) => {
    this.listeners.push(listener);
    return () => {
      this.listeners = this.listeners.filter((l) => l !== listener);
    };
  };
  private triggerListeners() {
    if (localStorage.getItem(SYNC_QUEUE_DEBUG_LOCALSTORAGE_KEY)) {
      console.groupCollapsed(`Sync queue state: Pending: ${this.queue.length}`);
      console.groupCollapsed(
        `Queue: ${this.queue.length === 0 ? '(empty)' : ''}`
      );
      this.queue.forEach((item) => {
        console.log(
          `Type: ${item.action.payload.type}:`,
          item.action.meta,
          isIpad
            ? 'omited because logging ImageData cause the iOS device to hang'
            : item.action.payload
        );
      });
      console.groupEnd();
      console.groupEnd();
    }
    this.listeners.forEach((l) => l());
  }

  isEmptyAndIdle() {
    return this.queue.length === 0 && !this.consuming;
  }

  resetErrorAndRetry() {
    this.retrying = true;
    setTimeout(() => {
      // starting consumer on next event loop to prevent race-conditions
      this._consumerLoop();
    });
    this.triggerListeners();
  }

  // returns the canceled action if it was canceled, null if not
  // an action cannot be canceled if:
  // - it's not in queue anymore
  // - it already has been started
  // - it's not the last in the queue, potentially other actions depend on it and we cannot safely cancel it
  cancel(id: string) {
    const queueItemIndex = this.queue.findLastIndex(
      // check from the end of the queue as we want to cancel the last action with the same id
      // there could be an action before this that is already in progress and cannot be canceled
      (item) =>
        item.action.meta.debounceId === id && this.itemCanBeCanceled(item)
    );
    if (queueItemIndex === -1) {
      return null;
    }
    const queueItem = this.queue[queueItemIndex];
    this.queue = produce(this.queue, (draft) => {
      draft.splice(queueItemIndex, 1);
    });
    if (this.delayWakeup?.actionId === id) {
      this.delayWakeup.wakeup();
    }
    return queueItem.action;
  }

  private itemCanBeCanceled = (item: SyncQueueItem<any, any>) => {
    const index = this.queue.indexOf(item);
    if (index === -1) {
      return false;
    }
    if (this.currentlyRunningQueueItemId === item.id) {
      // item currently running, cannot be canceled because a network call could be in flight
      return false;
    }
    if (
      !item.action.meta?.pure &&
      this.queue.slice(index + 1).some((item) => !item.action.meta?.pure)
    ) {
      // this action is not pure, and there's another action after it that is not pure, so it cannot be canceled without potential side effects
      // for example: we cannot cancel an action to create a layer if there's another action to update the layer image after it
      return false;
    }
    return true;
  };

  getQueue = () => {
    return this.queue;
  };

  push<TPayload extends SyncedActionBasePayloadType, TState>(
    queueItem: Omit<SyncQueueItem<TPayload, TState>, 'id'>
  ) {
    this.queue = produce(this.queue, (draft) => {
      draft.push({
        id: uuidv4(),
        ...queueItem,
      });
    });

    setTimeout(() => {
      // starting consumer on next event loop to prevent race-conditions
      this._consumerLoop();
    });
    this.triggerListeners();
  }

  private executeRemoteUpdater = async (queueItemId: string) => {
    let errorCount = 0;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      // getting it back again here because it could have been modified by the last "onRemoteUpdateError" if there was an error before
      const queueItem = this.queue.find((item) => item.id === queueItemId);
      assertExists(queueItem);
      try {
        await queueItem.syncQueue.executeRemoteUpdater(queueItem.action);
        return;
      } catch (e: any) {
        if (
          'message' in e &&
          NON_CRITICAL_ERRORS.some((message) =>
            e.message?.toLowerCase?.().includes(message)
          )
        ) {
          return;
        }
        errorCount++;
        const errorHandlerOutput = queueItem.syncQueue.onRemoteUpdateError(
          queueItem.action,
          e
        );
        const newMeta = errorHandlerOutput?.newMeta;
        if (newMeta) {
          this.queue = produce(this.queue, (draft) => {
            const index = draft.findIndex((item) => item.id === queueItem.id);
            draft[index].action.meta = {
              ...draft[index].action.meta,
              ...newMeta,
            };
          });
        }
        if (errorCount > SYNC_QUEUE_REMOTE_UDAPTER_ERROR_RETRY_COUNT) {
          captureException(e);
          throw e;
        }
        await sleep(SYNC_QUEUE_REMOTE_UDAPTER_ERROR_RETRY_DELAY);
      }
    }
  };

  private async _consumerLoop() {
    if (this.consuming || (this.errorMessage && !this.retrying)) {
      return;
    }
    this.consuming = true;
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const queueItem = this.queue[0];
      if (!queueItem) {
        break;
      }
      const meta = queueItem.action.meta;

      if (meta?.delay && this.itemCanBeCanceled(queueItem)) {
        // if action has a delay but cannot be canceled, don't need to wait for the debounce to finish
        const wakeupPromise = new Promise<void>((resolve) => {
          this.delayWakeup = {
            actionId: meta?.debounceId,
            wakeup: resolve,
          };
        });
        await Promise.race([sleep(meta.delay), wakeupPromise]);
        this.delayWakeup = null;
        // verifying that the action is still the first in the queue, if not, it means that it has been canceled and should be ignored
        if (queueItem.id !== this.queue[0].id) {
          continue;
        }
      }
      this.currentlyRunningQueueItemId = queueItem.id;
      if (
        localStorage.getItem(SYNC_QUEUE_ARTIFICIAL_LATENCY_LOCALSTORAGE_KEY)
      ) {
        await sleep(2000);
      }
      try {
        await this.executeRemoteUpdater(queueItem.id);
        this.lastRemoteUpdaterExecutedAt = Date.now();
        this.retrying = false;
        this.errorMessage = null;
      } catch (error: any) {
        this.retrying = false;
        this.errorMessage = error.message;
        this.consuming = false;
        this.triggerListeners();
        return;
      }
      // Force the other side effects to run before removing the action from the queue
      // This is required for example to let URQL update the cache before re-rendering
      await sleep(0);
      this.currentlyRunningQueueItemId = undefined;

      this.queue = produce(this.queue, (draft) => {
        draft.shift();
      });
      this.triggerListeners();
    }
    this.consuming = false;
  }
}

export const useSyncQueueSynchronizer = (deps: any[] = []) => {
  const syncQueueSynchronizer = useMemo(
    () => new SyncQueueSynchronizer(),
    deps
  );

  useComplexSyncExternalStore(syncQueueSynchronizer.listen, () => ({
    queue: syncQueueSynchronizer.getQueue,
    errorMessage: syncQueueSynchronizer.errorMessage,
    retrying: syncQueueSynchronizer.retrying,
  }));

  return syncQueueSynchronizer;
};

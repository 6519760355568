import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { v4 as uuid } from 'uuid';
import {
  useMeshForDrawingId,
  usePublicResourcesMesh,
} from '@vizcom/shared/data-access/graphql';
import {
  Button,
  PlusIcon,
  Text,
  browseForFiles,
  VizcomLogoAnimated,
  LoadingLogoInset,
  useKeyboardShortcut,
  Import3DModelIcon,
  ImportImageIcon,
  RichTooltip,
  RichTooltipTrigger,
  RichTooltipContent,
  ToolbarButton,
  ToolbarButtonState,
  MobileUploadIcon,
  Divider,
} from '@vizcom/shared-ui-components';

import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../../lib/useDrawingSyncedState';
import { importBlobsToLayers } from '../WorkbenchStudioFileDropper';
import { DEFAULT_LAYER_3D_CAMERA_VIEW } from '../tools/Layer3D/types';

export const InsertButton = ({
  drawing,
  handleAction,
  setShowOverlay,
  activeLayerId,
  setActiveLayerId,
  onOpenMobileUploadModal,
}: {
  drawing: Drawing2dStudio;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  setShowOverlay: React.Dispatch<React.SetStateAction<boolean>>;
  activeLayerId: string | undefined;
  setActiveLayerId: (id: string | undefined) => void;
  onOpenMobileUploadModal: () => void;
}) => {
  const theme = useTheme();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [loadingInsertMesh, setLoadingInsertMesh] = useState(false);

  const handleUploadFile = async () => {
    const files = await browseForFiles({
      accept: 'image/*',
    });
    if (!files) {
      return;
    }
    setShowOverlay(true);
    importBlobsToLayers(
      files,
      drawing,
      handleAction,
      activeLayerId,
      setActiveLayerId
    );
    setShowOverlay(false);
  };

  const handleUpload3dModel = async () => {
    const files = await browseForFiles({
      accept: '.fbx,.glb,.gltf,.obj,.stl',
    });
    if (!files) {
      return;
    }
    setShowOverlay(true);
    setLoadingInsertMesh(true);
    importBlobsToLayers(
      files,
      drawing,
      handleAction,
      activeLayerId,
      setActiveLayerId
    );
    setShowOverlay(false);
    setLoadingInsertMesh(false);
  };

  const handleOpenMobileUploadModal = () => {
    onOpenMobileUploadModal();
    setIsMenuOpen(false);
  };

  useKeyboardShortcut('i', handleUploadFile);
  useKeyboardShortcut('p', handleUpload3dModel);
  useKeyboardShortcut('/', handleOpenMobileUploadModal);

  return (
    <RichTooltip
      trigger="click"
      onOpenChange={setIsMenuOpen}
      isOpen={isMenuOpen}
      displayArrow={false}
      padding={12}
    >
      <RichTooltipTrigger>
        <ToolbarButton
          icon={<PlusIcon />}
          tooltip="Insert"
          state={
            isMenuOpen ? ToolbarButtonState.ACTIVE : ToolbarButtonState.INACTIVE
          }
        />
      </RichTooltipTrigger>
      <RichTooltipContent
        closeOnClick
        style={{
          maxHeight: 440,
          overflow: 'hidden',
          padding: 0,
          borderRadius: theme.borderRadius.m,
        }}
      >
        <InsertActionButtonContainer>
          <InsertActionButton variant="tertiary" onClick={handleUploadFile}>
            <ImportImageIcon />
            <Text
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              Upload an image <Text color="subtext">I</Text>
            </Text>
          </InsertActionButton>
          <InsertActionButton
            variant="tertiary"
            onClick={handleOpenMobileUploadModal}
          >
            <MobileUploadIcon />
            <Text
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              Upload from phone <Text color="subtext">/</Text>
            </Text>
          </InsertActionButton>

          <InsertActionButton
            variant="tertiary"
            onClick={handleUpload3dModel}
            disabled={loadingInsertMesh}
          >
            <Import3DModelIcon />
            <Text
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              Upload a 3D model <Text color="subtext">P</Text>
            </Text>
            <LoadingLogoInset active={loadingInsertMesh} />
          </InsertActionButton>
        </InsertActionButtonContainer>
        <Divider />
        <InsertMeshesSection
          drawing={drawing}
          handleAction={handleAction}
          setActiveLayerId={setActiveLayerId}
        />
      </RichTooltipContent>
    </RichTooltip>
  );
};

const InsertMeshesSection = (props: {
  drawing: Drawing2dStudio;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  setActiveLayerId: (id: string | undefined) => void;
}) => {
  const { data: meshes, fetching } = useMeshForDrawingId(props.drawing.id);
  const { data: publicMeshes, fetching: fetchingPublicMeshes } =
    usePublicResourcesMesh();

  const handleAddMesh = (meshId: string) => {
    const id = uuid();
    props.handleAction({
      type: 'addLayer',
      layer: {
        id: id,
        name: `3D model`,
        visible: true,
        opacity: 1,
        blendMode: 'normal',
        fill: '',
        isGroup: false,
        placement: 'top',
        metadata3D: {
          mesh: meshId,
          view: DEFAULT_LAYER_3D_CAMERA_VIEW,
        },
      },
    });
    props.setActiveLayerId(id);
  };

  if (fetching || fetchingPublicMeshes) {
    return (
      <LoadingContainer>
        <VizcomLogoAnimated />
      </LoadingContainer>
    );
  }

  return (
    <MeshesContainer>
      {meshes.map((mesh) => (
        <MeshButton
          variant="secondary"
          key={mesh.id}
          onClick={() => handleAddMesh(mesh.id)}
        >
          <img
            draggable={false}
            crossOrigin="anonymous"
            src={mesh.thumbnailPath}
            alt={mesh.name}
          />
        </MeshButton>
      ))}
      {publicMeshes.map((mesh) => (
        <MeshButton
          variant="secondary"
          key={mesh.id}
          onClick={() => handleAddMesh(mesh.id)}
        >
          <img
            draggable={false}
            crossOrigin="anonymous"
            src={mesh.thumbnailPath}
            alt={mesh.name}
          />
        </MeshButton>
      ))}
    </MeshesContainer>
  );
};

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const InsertActionButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  padding: 8px 8px 12px;
  gap: 12px;
`;

const InsertActionButton = styled(Button)`
  display: flex;
  gap: 7px;
  text-align: left;
  align-items: center;
  padding: 8px 16px;

  &:not(:disabled):hover {
    background-color: ${({ theme }) => theme.surface.secondary};
  }
`;

const MeshesContainer = styled.div`
  max-height: 300px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  overflow-y: auto;
  padding: 20px 8px 8px;
  padding-right: 4px;
  scrollbar-gutter: stable;
  ${({ theme }) => theme.scrollbar.light}
`;

const MeshButton = styled(Button)`
  width: 108px;
  height: 112px;
  border-radius: ${({ theme }) => theme.borderRadius.l};
  background-color: ${({ theme }) => theme.surface.secondary};
`;

import { useFrame } from '@react-three/fiber';
import { Suspense, useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { WorkbenchElementVideoData } from '@vizcom/shared/data-access/graphql';
import {
  DownloadIcon,
  ToolbarButton,
  VideoPauseIcon,
  VideoPlayIcon,
  downloadFileAtUrl,
} from '@vizcom/shared-ui-components';

import { useWorkbenchSyncedState } from '../../../lib/useWorkbenchSyncedState';
import { WorkbenchElementExtra } from '../../WorkbenchElementExtra';
import { getElementSize } from '../../helpers';
import { CustomHtml } from '../../utils/CustomHtml';
import { FullscreenVideo } from './FullscreenVideo';
import { VideoControls } from './VideoControls';

const PlayIcon = styled(VideoPlayIcon)`
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 64px;
  height: 64px;
  fill: white;
`;

const PauseIcon = styled(VideoPauseIcon)`
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 64px;
  height: 64px;
  fill: white;
`;

interface WorkbenchElementVideoProps {
  workbenchId: string;
  element: WorkbenchElementVideoData;
  isDragging: boolean;
  singleFocused: boolean;
  isResizing: boolean;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
}

type ZoomThresholds = 'full' | 'min' | 'none';

export const WorkbenchElementVideo = ({
  workbenchId,
  element,
  isDragging,
  singleFocused,
  isResizing,
  handleAction,
}: WorkbenchElementVideoProps) => {
  const [showControls, setShowControls] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [loop, setLoop] = useState(true);
  const [fullscreen, setFullscreen] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [videoReady, setVideoReady] = useState(false);
  const [zoomThreshold, setZoomThreshold] = useState<ZoomThresholds>('none');
  const [hovering, setHovering] = useState(false);

  const { height, width } = getElementSize(element);
  const theme = useTheme();
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleDownload = () => {
    downloadFileAtUrl(element.videoPath, element.name, 'mp4');
  };

  useEffect(() => {
    setShowControls(singleFocused);
  }, [singleFocused]);

  useEffect(() => {
    if (hovering || !loop) return;

    const timeout = setTimeout(() => {
      setShowControls(false);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [hovering, loop]);

  // hide parts of the UI as the user zooms out
  // full: hide all controls
  // min: hide controls bar
  // none: show all controls
  useFrame((state) => {
    const zoom = state.camera.zoom;
    const aspect = [width * zoom, height * zoom];
    const cameraAspect = [state.size.width, state.size.height];

    const minOverThreshold =
      aspect[0] < cameraAspect[0] / 10 || aspect[1] < cameraAspect[1] / 10;
    const fullOverThreshold =
      aspect[0] < cameraAspect[0] / 20 || aspect[1] < cameraAspect[1] / 20;

    if (minOverThreshold && !fullOverThreshold && zoomThreshold !== 'min') {
      setZoomThreshold('min');
    } else if (fullOverThreshold && zoomThreshold !== 'full') {
      setZoomThreshold('full');
    } else if (
      !minOverThreshold &&
      !fullOverThreshold &&
      zoomThreshold !== 'none'
    ) {
      setZoomThreshold('none');
    }
  });

  useEffect(() => {
    if (videoRef.current && !fullscreen) {
      videoRef.current.currentTime = currentTime;
    }
  }, [fullscreen, videoRef.current]);

  if (isResizing) {
    return (
      <mesh position={[0, 0, 0]} scale={[element.width, element.height, 1]}>
        <planeGeometry args={[1, 1, 1, 1]} />
        <meshBasicMaterial color={theme.surface.primary} transparent />
      </mesh>
    );
  }

  if (fullscreen) {
    return (
      <FullscreenVideo
        video={element}
        loop={loop}
        setLoop={setLoop}
        fullscreen={fullscreen}
        setFullscreen={setFullscreen}
        currentTime={currentTime}
        setCurrentTime={setCurrentTime}
        playing={playing}
        setPlaying={setPlaying}
      />
    );
  }

  return (
    <>
      <Suspense fallback={null}>
        <group
          userData={{
            workbenchObjectType: 'container',
            fixedAspectRatio: true,
          }}
          onClick={() => {
            setShowControls(true);
          }}
        >
          <CustomHtml transform occlude>
            <div
              style={{
                width: element.width,
                height: element.height,
                userSelect: 'none',
                backgroundColor: 'black',
              }}
              onPointerEnter={() => {
                setHovering(true);
                setShowControls(true);
              }}
              onPointerLeave={() => {
                setHovering(false);
                !singleFocused && setShowControls(false);
              }}
            >
              <video
                ref={videoRef}
                autoPlay={playing}
                loop={loop}
                muted
                style={{ width: '100%', height: '100%' }}
                controls={false}
                onPause={() => setPlaying(false)}
                onPlay={() => setPlaying(true)}
                onCanPlay={() => {
                  setVideoReady(true);
                }}
                onTimeUpdate={() => {
                  setCurrentTime(videoRef.current?.currentTime ?? 0);
                }}
              >
                <source src={element.videoPath} type="video/mp4" />
              </video>
              {!playing && zoomThreshold !== 'full' && (
                <PlayIcon
                  style={{ width: '20%', height: '20%' }}
                  onClick={() => {
                    videoRef.current?.play();
                    setPlaying(true);
                  }}
                />
              )}
              {playing && showControls && zoomThreshold !== 'full' && (
                <PauseIcon
                  style={{ width: '20%', height: '20%' }}
                  onClick={() => {
                    videoRef.current?.pause();
                    setPlaying(false);
                  }}
                />
              )}
              {showControls && videoReady && zoomThreshold === 'none' && (
                <VideoControls
                  videoRef={videoRef}
                  currentTime={currentTime}
                  loop={loop}
                  setLoop={setLoop}
                  fullscreen={fullscreen}
                  playing={playing}
                  setPlaying={setPlaying}
                  setFullscreen={(f) => {
                    setFullscreen(f);
                    setShowControls(false);
                  }}
                />
              )}
            </div>
          </CustomHtml>
        </group>
      </Suspense>

      {!isDragging && !isResizing && singleFocused && (
        <WorkbenchElementExtra
          workbenchId={workbenchId}
          element={element}
          handleAction={handleAction}
          position={[0, height / 2, 0]}
          pivot={element.y}
        >
          <ToolbarButton
            icon={<DownloadIcon />}
            tooltip="Download"
            onClick={handleDownload}
          />
        </WorkbenchElementExtra>
      )}
    </>
  );
};

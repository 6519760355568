import { VariablesOf } from '@graphql-typed-document-node/core';
import {
  urqlClient,
  UpdateWorkbenchElementAnimateMutation,
} from '@vizcom/shared/data-access/graphql';

import {
  SyncedActionPayloadFromType,
  SyncedActionType,
} from '../../SyncedAction';
import { ClientSideWorkbenchElementData } from '../../clientState';
import { elementById } from '../../utils';

export const UpdateAnimateAction: SyncedActionType<
  ClientSideWorkbenchElementData[],
  {
    type: 'updateAnimate';
    elementId: string;
    prompt?: string;
    sourceDrawingId?: string | null;
  }
> = {
  type: 'updateAnimate',
  optimisticUpdater: ({ payload }, elements) => {
    const sourceElement = elementById(elements, payload.elementId);
    if (sourceElement?.__typename === 'WorkbenchElementAnimate') {
      sourceElement.prompt = payload.prompt ?? sourceElement.prompt;
      sourceElement.sourceDrawingId =
        payload.sourceDrawingId !== undefined
          ? payload.sourceDrawingId
          : sourceElement.sourceDrawingId;
    }
  },
  remoteUpdater: async ({ payload }) => {
    const mutationVariables: VariablesOf<
      typeof UpdateWorkbenchElementAnimateMutation
    > = {
      input: {
        id: payload.elementId,
        patch: {
          prompt: payload.prompt,
          sourceDrawingId: payload.sourceDrawingId,
        },
      },
    };

    const res = await urqlClient.mutation(
      UpdateWorkbenchElementAnimateMutation,
      mutationVariables
    );

    if (res?.error) {
      throw new Error(
        `Error while updating element, please retry. ${
          res.error.graphQLErrors[0]?.message ?? res.error.message
        }`
      );
    }
  },
  metaConstructor: (payload) => ({
    delay: 500,
    debounceId: payload.elementId,
  }),
  undoConstructor: ({ payload }, elements) => {
    const element = elementById(elements, payload.elementId);
    if (!element || element.__typename !== 'WorkbenchElementImg2Img') {
      return;
    }

    const undoPayload: SyncedActionPayloadFromType<typeof UpdateAnimateAction> =
      {
        type: 'updateAnimate',
        elementId: payload.elementId,
        prompt: element.prompt,
        sourceDrawingId: element.sourceDrawingId,
      };

    return undoPayload;
  },
};

import { Navigate } from 'react-router-dom';
import {
  FeatureFlagged,
  LoadingLogo,
  Text,
  useSelectedOrganization,
} from '@vizcom/shared-ui-components';

import {
  SettingsPageContainer,
  SettingsPageDivider,
  SettingsPageHeader,
} from '../components/SettingsPageLayout';
import { PromptDenyListSettings } from './PromptDenyListSettings';
import { PublicSharingSettings } from './PublicSharingSettings';

export const OrganizationSecurityPage = () => {
  const { data: organization, loading } = useSelectedOrganization();

  if (loading) return <LoadingLogo />;
  if (!organization) return <Navigate to="/" />;

  return (
    <SettingsPageContainer>
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <SettingsPageHeader>
          <Text style={{ fontSize: 18, fontWeight: 600 }}>Security</Text>
          <Text color="subtext" type="b1">
            Manage your organization's security settings
          </Text>
        </SettingsPageHeader>
      </div>
      <SettingsPageDivider />
      <PublicSharingSettings organization={organization} />
      <FeatureFlagged flag="PROMPT_DENY_LIST">
        <PromptDenyListSettings organization={organization} />
      </FeatureFlagged>
    </SettingsPageContainer>
  );
};

import { partition } from 'lodash';
import styled, { keyframes } from 'styled-components';
import {
  useDeleteOrganizationMembersOrInvite,
  useResendOrganizationInvite,
} from '@vizcom/shared/data-access/graphql';
import {
  Button,
  Loader,
  Text,
  addToast,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';

import {
  getEmailFromInviteOrMember,
  isElemInvite,
  isElemMember,
  type OrganizationInviteOrMember,
} from './OrganizationMembers';

interface TableSelectionToolbarProps {
  selection: string[];
  organizationId: string;
  onResetSelection: () => void;
  data: OrganizationInviteOrMember[];
}

export const TableSelectionToolbar = ({
  data,
  selection,
  organizationId,
  onResetSelection,
}: TableSelectionToolbarProps) => {
  const [deleteRes, deleteMembersOrInvites] =
    useDeleteOrganizationMembersOrInvite();
  const [resendRes, resendOrganizationInvite] = useResendOrganizationInvite();

  const selectionCount = selection.length;
  const plural = selectionCount > 1 ? 's' : '';
  const display = selectionCount > 0;

  const someInvitesSelected = selection.some((email) => {
    const elem = data.find(
      (elem) => getEmailFromInviteOrMember(elem) === email
    );
    return elem && isElemInvite(elem);
  });

  const handleCopyEmails = () => {
    navigator.clipboard.writeText(selection.join(','));
    onResetSelection();
    addToast('Your selection has been copied to the clipboard');
  };

  const handleDeleteUsers = async () => {
    const [members, invites] = partition(
      data.filter((elem) =>
        selection.includes(getEmailFromInviteOrMember(elem))
      ),
      isElemMember
    );
    const usersIds = members.map((member) => member.node.id);
    const invitesEmails = invites.map((invite) => invite.email);

    // Sleep for 1 second to show the loader

    const response = await deleteMembersOrInvites({
      input: {
        organizationId,
        usersIds,
        invitesEmails,
      },
    });

    if (response.error) {
      return addToast('Error deleting invitations or members', {
        type: 'danger',
        secondaryText: formatErrorMessage(response.error),
      });
    }

    addToast('The chosen invitations and members have been deleted.');
    onResetSelection();
  };

  const handleResendInvites = async () => {
    const invitesEmails = data
      .filter(isElemInvite)
      .filter((elem) => selection.includes(elem.email))
      .map((invite) => invite.email);

    const response = await resendOrganizationInvite({
      input: {
        organizationId,
        invitesEmails,
        usersIds: [],
      },
    });

    if (response.error) {
      return addToast('Error resending invitations', {
        secondaryText: formatErrorMessage(response.error),
        type: 'danger',
      });
    }

    addToast('The  invitations have been resent.');
    onResetSelection();
  };

  return (
    <StyledTableSelectionToolbar $display={display}>
      <Text
        style={{
          marginRight: 80,
        }}
      >
        {selectionCount} selected element{plural}
      </Text>

      <ButtonsContainer>
        <Button variant="secondary" size="S" onClick={handleCopyEmails}>
          Copy Emails
        </Button>

        {someInvitesSelected && (
          <Button
            variant="secondary"
            size="S"
            onClick={handleResendInvites}
            disabled={resendRes.fetching}
          >
            {resendRes.fetching ? (
              <Loader
                size={{
                  width: 15,
                  height: 15,
                }}
              />
            ) : (
              'Resend invites'
            )}
          </Button>
        )}

        <Button
          variant="danger"
          size="S"
          onClick={handleDeleteUsers}
          disabled={deleteRes.fetching}
        >
          {deleteRes.fetching ? (
            <Loader
              size={{
                width: 15,
                height: 15,
              }}
            />
          ) : (
            'Remove users'
          )}
        </Button>
      </ButtonsContainer>
    </StyledTableSelectionToolbar>
  );
};

const toolbarEnter = keyframes`
  from {
    opacity: 0;
    transform: scale(0.95) translateY(10px);
  }
  to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
`;

const StyledTableSelectionToolbar = styled.div<{
  $display: boolean;
}>`
  animation: ${toolbarEnter} 200ms ease;

  display: ${({ $display }) => ($display ? 'flex' : 'none')};

  margin: auto;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  padding: 10px 15px;
  background-color: ${({ theme }) => theme.surface.secondary};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  pointer-events: all;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 7px;
`;

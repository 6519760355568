import { ReactNode, useLayoutEffect, useRef, useState } from 'react';

type InfiniteScrollPageProps = {
  children: ReactNode;
  loadMore: () => void;
  hasNextPage?: boolean;
};

export const InfiniteScrollPage = ({
  children,
  hasNextPage,
  loadMore,
}: InfiniteScrollPageProps) => {
  const [nextPageLoaded, setNextPageLoaded] = useState(false);
  const observerTarget = useRef<HTMLDivElement>(null);

  // using a layout effect here instead of a useEffect to make sure we attach the IntersectionObserver before committing the layout
  // this prevent a race-condition if the user scrolls to the bottom of the page before we attach the intersection observer
  useLayoutEffect(() => {
    if (nextPageLoaded) return;

    const target = observerTarget.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        const { isIntersecting } = entry;

        if (isIntersecting && hasNextPage) {
          loadMore();
          setNextPageLoaded(true);
        }
      },
      { threshold: 1 }
    );

    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, [observerTarget, nextPageLoaded, hasNextPage, loadMore]);

  return (
    <>
      {children}
      {!nextPageLoaded && hasNextPage && (
        <div
          style={{
            // We want to make sure the observer target isn't shuffled as part of a grid
            gridColumn: '1 / -1',
            marginBottom: '40px',
          }}
          ref={observerTarget}
        />
      )}
    </>
  );
};

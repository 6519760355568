import Lottie from 'lottie-react';
import { useState } from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import {
  addToast,
  resizeImageToCoverSize,
  Text,
  ToastIndicator,
  useDocumentEventListener,
  useStableCallback,
} from '@vizcom/shared-ui-components';

import {
  filterExists,
  getLayerOrderKeys,
} from '../../../../../../shared/js-utils/src';
import dropAnimation from '../../assets/animations/drop_animation.json';
import { LayerPayload } from '../../lib/actions/drawing/addLayer';
import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../lib/useDrawingSyncedState';
import {
  checkImportedMeshFileSize,
  createObject3dThumbnail,
  exportObject3d,
  is3DModelFile,
  loadObject3DFromBlob,
} from '../utils/meshHelpers';
import {
  DEFAULT_LAYER_3D_CAMERA_DISTANCE,
  DEFAULT_LAYER_3D_CAMERA_VIEW,
} from './tools/Layer3D/types';

export async function importBlobsToLayers(
  files: Blob[],
  drawing: Drawing2dStudio,
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'],
  activeLayerId?: string,
  setActiveLayerId?: (id: string | undefined) => void
) {
  const activeLayer = drawing.layers.nodes.find(
    (layer) => layer.id === activeLayerId?.split('/')[0]
  );
  const orderKeys = getLayerOrderKeys(
    [...drawing.layers.nodes],
    activeLayer?.id,
    files.length
  );
  const layers: LayerPayload[] = (
    await Promise.all(
      files.map(async (file, i) => {
        const id = uuid();
        try {
          if (file.type.includes('image')) {
            const image = await resizeImageToCoverSize(
              file,
              drawing.width,
              drawing.height
            );
            return {
              id,
              name: file.name,
              visible: true,
              opacity: 1,
              blendMode: 'normal',
              fill: '',
              isGroup: false,
              image,
              orderKey: orderKeys[i],
              parentId: activeLayer?.parentId,
            };
          } else if (is3DModelFile(file.name)) {
            checkImportedMeshFileSize(file);

            const layerId = uuid();
            const mesh = await loadObject3DFromBlob(file);
            const gltfBinary = (await exportObject3d(mesh)).result;
            const blob = new Blob([gltfBinary]);
            const thumbnail = await createObject3dThumbnail(
              mesh,
              drawing.width,
              drawing.height,
              [
                DEFAULT_LAYER_3D_CAMERA_DISTANCE,
                DEFAULT_LAYER_3D_CAMERA_VIEW.phi,
                DEFAULT_LAYER_3D_CAMERA_VIEW.theta,
              ]
            );

            return {
              id: layerId,
              name: file.name,
              visible: true,
              opacity: 1,
              blendMode: 'normal',
              fill: '',
              isGroup: false,
              orderKey: orderKeys[i],
              metadata3D: {
                view: DEFAULT_LAYER_3D_CAMERA_VIEW,
              },
              image: thumbnail,
              meshPath: blob,
            };
          }

          throw new Error(
            'Cannot detect type of file, it should be either an image or a 3D model. Accepted 3d file formats: .fbx, .glb, .gltf, .obj, .stl.'
          );
        } catch (e) {
          const message = e instanceof Error ? e.message : '';
          addToast(`Cannot import file "${file.name}": ${message}.`, {
            type: 'danger',
          });
        }
      })
    )
  ).filter(filterExists);

  if (layers.length === 0) {
    return [];
  }

  handleAction({
    type: 'updateBulkLayers',
    newLayers: layers,
  });

  setActiveLayerId?.(layers[layers.length - 1].id);
  return layers;
}

export const WorkbenchStudioFileDropper = ({
  drawing,
  activeLayerId,
  hasPrompt,
  handleAction,
  setActiveLayerId,
  triggerAutoPrompt,
}: {
  drawing: Drawing2dStudio;
  activeLayerId: string | undefined;
  hasPrompt: boolean;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  setActiveLayerId: (id: string | undefined) => void;
  triggerAutoPrompt: (delay: number | undefined) => Promise<void>;
}) => {
  const [isDroppingFiles, setIsDroppingFiles] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleImportFiles = useStableCallback(async (files: File[]) => {
    setLoading(true);
    await importBlobsToLayers(
      files,
      drawing,
      handleAction,
      activeLayerId,
      setActiveLayerId
    );
    setLoading(false);
    setIsDroppingFiles(false);

    if (!hasPrompt) {
      triggerAutoPrompt(1000);
    }
  });

  useDocumentEventListener('paste', (event) => {
    const items = event.clipboardData?.items;
    if (!items) return;

    const files = [...items]
      .filter((item) => item.kind === 'file')
      .map((item) => item.getAsFile())
      .filter(filterExists);
    if (files.length) {
      event.preventDefault();
      event.stopPropagation();
      handleImportFiles(files);
    }
  });

  useDocumentEventListener('dragover', (e) => {
    e.preventDefault(); // needed for drop event to fire
    setIsDroppingFiles(true);
  });
  useDocumentEventListener('dragleave', () => {
    setIsDroppingFiles(false);
  });
  useDocumentEventListener(
    'drop',
    () => {
      setIsDroppingFiles(false);
    },
    { capture: true } // capturing here to also receive the drop event even if another element catches it
  );
  useDocumentEventListener('drop', (e) => {
    // this event will be triggered if no other element catches the drop event
    e.stopPropagation();
    e.preventDefault();
    const files = e.dataTransfer?.items
      ? [...e.dataTransfer.items]
          .map((item) => {
            if (item.kind === 'file') {
              const file = item.getAsFile();
              return file;
            }
          })
          .filter(filterExists)
      : e.dataTransfer?.files
      ? [...e.dataTransfer.files]
      : [];
    handleImportFiles(files);
  });

  return (
    <>
      <Container
        $active={isDroppingFiles}
        onClick={() => setIsDroppingFiles(false)} // if for any reason we missed the dragleave or drop event, let the user click anywhere to reset the state
      >
        {isDroppingFiles && (
          <Lottie animationData={dropAnimation} loop={false} />
        )}
        <Text type="h1" style={{ marginBottom: '20px' }}>
          Drop your files
        </Text>
        <Text type="sh1">Add images or 3d models to the canvas</Text>
      </Container>
      {loading && <ToastIndicator variant="loading" text="Importing files" />}
    </>
  );
};

const Container = styled.div<{ $active: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: ${({ $active }) => ($active ? 1 : 0)};
  visibility: ${({ $active }) => ($active ? 'visible' : 'hidden')};
  z-index: 10000000;
  transition: 0.5s opacity ease;
  background-color: rgba(76, 76, 239, 0.35);
  backdrop-filter: blur(1px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

import { Line, Plane } from '@react-three/drei';
import { ThreeEvent, useFrame, useThree } from '@react-three/fiber';
import { useDrag } from '@use-gesture/react';
import { useRef } from 'react';
import * as THREE from 'three';
import { damp } from 'three/src/math/MathUtils';
import { Line2 } from 'three-stdlib';
import { v4 as uuidv4 } from 'uuid';

import { WorkbenchContentRenderingOrder } from '../WorkbenchContent';
import { useWorkbenchElementSelectionState } from '../lib/elementSelectionState';
import { useWorkbenchSyncedState } from '../lib/useWorkbenchSyncedState';
import { floorPlane } from '../lib/utils';
import { SECTION_DEFAULT_COLOR } from './elements/section/WorkbenchElementSection';
import {
  BOX_POINTS,
  MAX_Z_POSITION,
  getWorkbenchElementZPositionRange,
} from './helpers';
import { filterChildByWorkbenchElementUserData } from './objectsUserdata';
import {
  WorkbenchToolType,
  useWorkbenchToolState,
} from './toolbar/WorkbenchToolContext';
import { getElementMinimumSize } from './utils/getContentSize';
import { getElementDefaultSize } from './utils/getElementDefaultSize';
import { VizcomRenderingOrderEntry } from './utils/threeRenderingOrder';

const planeIntersectPoint = new THREE.Vector3();

const AREA_OPACITY = 0.1;
const AREA_COLOR = 0x7070f2;

export type Area = [[number, number], [number, number]];

const createTextElement = (
  scene: THREE.Scene,
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'],
  setEditingElementId: (id: string | null) => void,
  x: number,
  y: number,
  width?: number,
  height?: number
) => {
  const zRange = getWorkbenchElementZPositionRange(scene);
  const zIndex = isFinite(zRange[1]) ? zRange[1] + 1 : MAX_Z_POSITION / 2;
  const size = getElementDefaultSize('WorkbenchElementText');
  const elementId = uuidv4();
  const textElement = {
    __typename: 'WorkbenchElementText' as const,
    updatedAt: '0',
    id: elementId,
    x,
    y,
    width: width ?? size.x,
    height: height ?? size.y,
    zIndex,
    angle: 0,
    content: '',
    contentHeight: height ?? size.y,
    lockWidth: !!width,
    style: {},
    link: '',
  };
  const minSize = getElementMinimumSize(textElement);

  handleAction({
    type: 'createElements',
    newElements: [
      {
        ...textElement,
        width: Math.max(textElement.width, minSize[0]),
        height: Math.max(textElement.height, minSize[1]),
      },
    ],
  });
  useWorkbenchElementSelectionState.getState().setFocusedElementsId(elementId);
  setEditingElementId(elementId);
};

const createSectionElement = (
  scene: THREE.Scene,
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'],
  x: number,
  y: number,
  width?: number,
  height?: number
) => {
  const zRange = getWorkbenchElementZPositionRange(scene);
  const zIndex = isFinite(zRange[1]) ? zRange[1] + 1 : MAX_Z_POSITION / 2;
  const size = getElementDefaultSize('WorkbenchElementSection');

  const sectionElement = {
    __typename: 'WorkbenchElementSection' as const,
    updatedAt: '0',
    id: uuidv4(),
    x,
    y,
    zIndex,
    width: width ?? size.x,
    height: height ?? size.y,
    color: SECTION_DEFAULT_COLOR,
    title: '',
  };
  const minSize = getElementMinimumSize(sectionElement);

  handleAction({
    type: 'createElements',
    newElements: [
      {
        ...sectionElement,
        width: Math.max(sectionElement.width, minSize[0]),
        height: Math.max(sectionElement.height, minSize[1]),
      },
    ],
  });
};

export const getBounds = (area: Area): Area => {
  const xBounds = [
    Math.min(area[0][0], area[1][0]),
    Math.max(area[0][0], area[1][0]),
  ] as [number, number];
  const yBounds = [
    Math.min(area[0][1], area[1][1]),
    Math.max(area[0][1], area[1][1]),
  ] as [number, number];
  return [xBounds, yBounds];
};

const getMatchingObjects = (
  scene: THREE.Scene,
  xBounds: number[],
  yBounds: number[],
  isSectionTool: boolean
) => {
  const objects = filterChildByWorkbenchElementUserData(scene, () => true);

  return objects.filter((element) => {
    const xStart = element.position.x - element.userData.elementWidth / 2;
    const xEnd = element.position.x + element.userData.elementWidth / 2;
    const yStart = element.position.y - element.userData.elementHeight / 2;
    const yEnd = element.position.y + element.userData.elementHeight / 2;

    if (
      isSectionTool ||
      element.userData.elementTypename === 'WorkbenchElementSection'
    ) {
      return (
        xEnd <= xBounds[1] &&
        xStart >= xBounds[0] &&
        yEnd <= yBounds[1] &&
        yStart >= yBounds[0]
      );
    }

    return (
      xEnd >= xBounds[0] &&
      xStart <= xBounds[1] &&
      yEnd >= yBounds[0] &&
      yStart <= yBounds[1]
    );
  });
};

const updateFocusedElements = (
  scene: THREE.Scene,
  area: Area,
  isSectionTool: boolean
) => {
  const [xBounds, yBounds] = getBounds(area);
  const matchingObjects = getMatchingObjects(
    scene,
    xBounds,
    yBounds,
    isSectionTool
  );

  const focusedElementId = matchingObjects
    .map((object) => object.userData.elementId)
    .join('/');

  useWorkbenchElementSelectionState
    .getState()
    .setFocusedElementsId(focusedElementId);
};

export const getArea = (point: THREE.Vector3, area: Area | null): Area => {
  return area
    ? [area[0], [point.x, point.y]]
    : [
        [point.x, point.y],
        [point.x, point.y],
      ];
};

export const AreaSelector = (props: {
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
  setEditingElementId: (id: string | null) => void;
}) => {
  const { tool, setTool } = useWorkbenchToolState();
  const scene = useThree((s) => s.scene);
  const area = useRef<null | Area>(null);
  const areaPlaneRef = useRef<THREE.Mesh>(null!);
  const outlineRef = useRef<Line2>(null!);

  const handleLastDrag = () => {
    if (area.current) {
      const width = Math.abs(area.current[0][0] - area.current[1][0]);
      const height = Math.abs(area.current[0][1] - area.current[1][1]);
      const x = (area.current[0][0] + area.current[1][0]) / 2;
      const y = (area.current[0][1] + area.current[1][1]) / 2;

      if (tool === 'section') {
        createSectionElement(scene, props.handleAction, x, y, width, height);
      } else if (tool === 'text') {
        createTextElement(
          scene,
          props.handleAction,
          props.setEditingElementId,
          x,
          y,
          width,
          height
        );
      }
    }

    setTool(WorkbenchToolType.SELECT);
    area.current = null;
  };

  const bind = useDrag<ThreeEvent<PointerEvent>>(
    ({ event, active, last, distance, tap, intentional }) => {
      event.ray.intersectPlane(floorPlane, planeIntersectPoint);

      if ((last || tap) && distance[0] + distance[1] < 2) {
        useWorkbenchElementSelectionState.getState().setFocusedElementsId('');
      }

      if (tool === 'text' && tap) {
        createTextElement(
          scene,
          props.handleAction,
          props.setEditingElementId,
          planeIntersectPoint.x,
          planeIntersectPoint.y
        );
        setTool(WorkbenchToolType.SELECT);
        return;
      }

      if (tool === 'section' && tap) {
        createSectionElement(
          scene,
          props.handleAction,
          planeIntersectPoint.x,
          planeIntersectPoint.y
        );
        setTool(WorkbenchToolType.SELECT);
        return;
      }

      if (!intentional) {
        return;
      }

      switch (tool) {
        case 'text':
          handleTextTool({ active, last });
          break;
        case 'section':
          handleSectionTool({ active, last });
          break;
        default:
          handleSelectTool({ active, last });
      }
    },
    {
      threshold: 5,
      triggerAllEvents: true,
      pointer: {
        keys: false,
      },
    }
  );

  const handleTextTool = ({
    active,
    last,
  }: {
    active: boolean;
    last: boolean;
  }) => {
    if (active) {
      area.current = getArea(planeIntersectPoint, area.current);
      // Set the height of the text element to the default height
      area.current[1][1] =
        area.current[0][1] + getElementDefaultSize('WorkbenchElementText').y;
    }

    if (last) {
      handleLastDrag();
    }
  };

  const handleSectionTool = ({
    active,
    last,
  }: {
    active: boolean;
    last: boolean;
  }) => {
    if (active) {
      area.current = getArea(planeIntersectPoint, area.current);
      updateFocusedElements(scene, area.current!, true);
    }

    if (last) {
      handleLastDrag();
    }
  };

  const handleSelectTool = ({
    active,
    last,
  }: {
    active: boolean;
    last: boolean;
  }) => {
    if (active) {
      area.current = getArea(planeIntersectPoint, area.current);
      updateFocusedElements(scene, area.current!, false);
    }

    if (last) {
      handleLastDrag();
    }
  };

  useFrame((s, dt) => {
    if (area.current) {
      const [x1, y1] = area.current[0];
      const [x2, y2] = area.current[1];

      outlineRef.current.material.opacity = 1;

      const centerX = (x1 + x2) / 2;
      const centerY = (y1 + y2) / 2;
      const scaleX = Math.abs(x1 - x2);
      const scaleY = Math.abs(y1 - y2);

      // Set position and scale for the area plane and outline
      areaPlaneRef.current.position.set(centerX, centerY, 0);
      areaPlaneRef.current.scale.set(scaleX, scaleY, 1);
      outlineRef.current.position.set(centerX, centerY, 0);
      outlineRef.current.scale.set(scaleX / 2, scaleY / 2, 1);

      if (tool === 'section') {
        const material = areaPlaneRef.current
          .material as THREE.MeshBasicMaterial;
        material.opacity = 0.2;
        material.color.set(SECTION_DEFAULT_COLOR);

        outlineRef.current.material.color.set(SECTION_DEFAULT_COLOR);
        outlineRef.current.material.linewidth = 3;
      } else {
        // For other tools, revert to default appearance
        (areaPlaneRef.current.material as THREE.MeshBasicMaterial).opacity =
          AREA_OPACITY;
        (areaPlaneRef.current.material as THREE.MeshBasicMaterial).color.set(
          AREA_COLOR
        );
        outlineRef.current.material.color.set(AREA_COLOR);
        outlineRef.current.material.linewidth = 1.5;
      }
    } else {
      const material = areaPlaneRef.current.material as THREE.MeshBasicMaterial;
      if (material.opacity === 0) return;

      const opacity = damp(material.opacity, 0, 10, dt);

      material.opacity = opacity;
      outlineRef.current.material.opacity = opacity;
      if (opacity <= 0.01) {
        material.opacity = 0;
        outlineRef.current.material.opacity = 0;
      }
    }
  });

  return (
    <>
      <Plane
        args={[5000000, 5000000]}
        {...(bind() as any)}
        material-transparent={true}
        material-opacity={0}
      />
      <group
        userData={{
          vizcomRenderingOrder: [
            {
              zIndex: WorkbenchContentRenderingOrder.indexOf('actions'),
            } satisfies VizcomRenderingOrderEntry,
          ],
        }}
      >
        <mesh ref={areaPlaneRef}>
          <planeGeometry args={[1, 1]} />
          <meshBasicMaterial color={AREA_COLOR} transparent />
        </mesh>
        <Line
          ref={outlineRef}
          points={BOX_POINTS}
          color={AREA_COLOR}
          lineWidth={1.5}
          transparent
        />
      </group>
    </>
  );
};

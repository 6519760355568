import { useTheme } from 'styled-components';
import { CompositeSceneFullData } from '@vizcom/shared/data-access/graphql';
import { Dropdown, Text } from '@vizcom/shared-ui-components';

import { useCompositeSceneSyncedState } from '../../../lib/useCompositeSceneSyncedState';
import {
  EnvironmentPresets,
  toEnvironmentPreset,
} from '../compositeSceneEditor/utils/environments';
import { CustomInputRow } from '../compositeSceneMenu/CustomInputRow';
import { BooleanInput } from '../ui/BooleanInput/BooleanInput';
import { Container } from './style';

export const EnvironmentConfiguration = ({
  compositeScene,
  handleAction,
}: {
  compositeScene: CompositeSceneFullData;
  handleAction: ReturnType<typeof useCompositeSceneSyncedState>['handleAction'];
}) => {
  const theme = useTheme();
  const backgroundOptions = [
    ...Object.keys(EnvironmentPresets).map((key) => ({
      data: {
        label: key,
        value: toEnvironmentPreset(key),
      },
    })),
  ];
  const sceneEnvironment = backgroundOptions.find(
    (match) => match.data.value === compositeScene.sceneEnvironmentMapUrl
  );

  return (
    <Container>
      <CustomInputRow
        before={<span style={{ color: theme.text.subtext }}>Lighting</span>}
      >
        <Dropdown
          options={backgroundOptions}
          value={compositeScene.sceneEnvironmentMapUrl || 'Default'}
          variant="tertiary"
          setValue={(value) => {
            handleAction({
              type: 'updateCompositeScene',
              sceneEnvironmentMapUrl: value,
            });
          }}
          OptionComponent={({ option }) => <Text>{option.label}</Text>}
          optionToValueMapper={(option) => option.value}
          buttonProps={{
            style: {
              width: '100%',
              backgroundColor: theme.surface.secondary,
              fontSize: 12,
            },
          }}
        >
          {sceneEnvironment?.data.label || 'Default'}
        </Dropdown>
      </CustomInputRow>
      <CustomInputRow
        before={<span style={{ color: theme.text.subtext }}>Ground plane</span>}
      >
        <BooleanInput
          value={compositeScene.sceneEnvironmentGroundPlane}
          onChange={(value) => {
            handleAction({
              type: 'updateCompositeScene',
              sceneEnvironmentGroundPlane: value,
            });
          }}
          variant="tertiary"
        />
      </CustomInputRow>
    </Container>
  );
};

import { ThreeEvent, useStore } from '@react-three/fiber';
import { useEffect, useMemo, useRef } from 'react';
import { Color, Mesh, OrthographicCamera, Texture } from 'three';

import { useStableCallback } from '../../../../../../../shared/ui/components/src';
import {
  DrawingLayer,
  useDrawingSyncedState,
} from '../../../lib/useDrawingSyncedState';
import { screenPositionToLayer } from '../../helpers';
import { LayerContent } from '../LayersCompositor/LayerContent';
import { useBeforeActiveLayerChange } from '../lib/useActiveLayer';
import { useCanvasTexture } from '../lib/useCanvasTexture';
import {
  useSelectionApiStore,
  useSubscribeToSelectionApi,
} from '../selection/useSelectionApi';
import { useWorkbenchStudioState } from '../studioState';
import { BrushCursorPreview } from './BrushEngine/BrushCursorPreview';
import {
  BrushTextureRenderer,
  BrushTextureRendererRef,
} from './BrushEngine/BrushTextureRenderer';
import { EventMesh } from './EventMesh';

const auxColor = new Color();

const neighbors = [
  [-1, -1],
  [0, -1],
  [1, -1],
  [-1, 0],
  [1, 0],
  [-1, 1],
  [0, 1],
  [1, 1],
];
const numNeighbors = neighbors.length;

const delta = (color: Uint8ClampedArray, target: Float32Array) => {
  // This function is used to compare an unmultipled color (color)
  // and a premultiplied color (target)
  // by returning the max of their rgb and alpha squared euclidean distance
  const colorA = color[3] / 0xff;
  return Math.max(
    ((color[0] / 0xff) * colorA - target[0]) ** 2 +
      ((color[1] / 0xff) * colorA - target[1]) ** 2 +
      ((color[2] / 0xff) * colorA - target[2]) ** 2,
    (colorA - target[3]) ** 2
  );
};

interface PaintBucketRendererProps {
  drawingSize: [number, number];
  layer: DrawingLayer;
  layerImage: Texture | undefined;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  zIndex: number;
}

export const PaintBucketRenderer = ({
  drawingSize,
  layer,
  layerImage,
  handleAction,
  zIndex,
}: PaintBucketRendererProps) => {
  const selectionApiStore = useSelectionApiStore();
  const store = useStore();
  const eventPlaneRef = useRef<Mesh>(null!);
  const brushTextureRendererRef = useRef<BrushTextureRendererRef>(null!);
  const { ctx, canvasTexture } = useCanvasTexture(drawingSize);

  const layerFill =
    layer.fill && layer.fill.match(/^#([0-9a-f]{6})$/i)
      ? layer.fill
      : undefined;

  const { color } = useWorkbenchStudioState();
  const abgr = useMemo(() => {
    auxColor.set(color);
    return (
      (0xff << 24) |
      ((Math.round(auxColor.b * 255) & 0xff) << 16) |
      ((Math.round(auxColor.g * 255) & 0xff) << 8) |
      (Math.round(auxColor.r * 255) & 0xff)
    );
  }, [color]);

  const map = useMemo(
    () => new Uint8Array(drawingSize[0] * drawingSize[1]),
    [drawingSize[0], drawingSize[1]]
  );
  const output = useMemo(() => {
    const image = new ImageData(drawingSize[0], drawingSize[1]);
    return {
      buffer: new Uint32Array(image.data.buffer),
      image,
    };
  }, [drawingSize[0], drawingSize[1]]);

  const processingStartedAt = useRef<number | null>(null);
  const cancelProcessing = () => (processingStartedAt.current = null);
  useEffect(
    () => () => {
      // Stop ongoing processing (if any) when this component gets out of scope
      cancelProcessing();
    },
    []
  );

  useBeforeActiveLayerChange(() => {
    cancelProcessing();
  });

  const resetPaintBucketOnNextRenderRef = useRef(false);
  if (resetPaintBucketOnNextRenderRef.current) {
    ctx.clearRect(0, 0, drawingSize[0], drawingSize[1]);
    canvasTexture.needsUpdate = true;
    resetPaintBucketOnNextRenderRef.current = false;
  }

  const pointerdown = useStableCallback(
    async (event: ThreeEvent<PointerEvent>) => {
      if (processingStartedAt.current !== null || event.buttons !== 1) {
        return;
      }

      let selectionPixels: ImageData | undefined;
      if (selectionApiStore.getState().hasMask) {
        selectionPixels = selectionApiStore.getState().getSelectionImage();
      }

      if (!layerImage?.image && !selectionPixels) {
        handleAction({
          type: 'updateLayer',
          id: layer.id,
          data: { fill: color },
        });
        return;
      }

      const pointer = screenPositionToLayer(
        [event.clientX, event.clientY],
        store.getState().camera as OrthographicCamera,
        eventPlaneRef.current,
        drawingSize
      );

      const point = {
        x: Math.floor(pointer[0]),
        y: Math.floor(pointer[1]),
      };

      let pixels: ImageData;
      const image =
        layerImage?.image || new ImageData(drawingSize[0], drawingSize[1]);
      if (image instanceof ImageData) {
        pixels = image;
      } else {
        ctx.drawImage(image, 0, 0);
        pixels = ctx.getImageData(0, 0, drawingSize[0], drawingSize[1]);
      }

      const pixelIndex = (x: number, y: number) => y * drawingSize[0] + x;

      const targetIndex = pixelIndex(point.x, point.y);
      map.fill(0);
      map[targetIndex] = 1;
      output.buffer.fill(0);
      output.buffer[targetIndex] = abgr;

      const ti = targetIndex * 4;
      const target = pixels.data.slice(ti, ti + 4);
      const premultipliedTarget = new Float32Array([
        (target[0] / 255) * (target[3] / 255),
        (target[1] / 255) * (target[3] / 255),
        (target[2] / 255) * (target[3] / 255),
        target[3] / 255,
      ]);

      // Start the processing queue at the target pixel
      let queue: [number, number][] = [[point.x, point.y]];

      const process = () => {
        const nqueue: [number, number][] = [];
        // Iterate through the current queue
        for (let i = 0, l = queue.length; i < l; i++) {
          const [x, y] = queue[i];
          // Check all the pixel neighbors
          // and add them to the next queue
          // if they are a similar color to the target pixel
          for (let n = 0; n < numNeighbors; n++) {
            let [nx, ny] = neighbors[n];
            nx += x;
            ny += y;
            if (
              nx < 0 ||
              nx >= drawingSize[0] ||
              ny < 0 ||
              ny >= drawingSize[1] ||
              (selectionApiStore.getState().hasMask &&
                selectionPixels!.data[4 * pixelIndex(nx, ny)] === 0)
            ) {
              // Neighbor is out of bounds
              continue;
            }
            const neighborIndex = pixelIndex(nx, ny);
            if (map[neighborIndex] !== 0) {
              // Neighbor has already been visited
              // in a previous iteration
              continue;
            }
            // Mark this neighbor pixel as visited
            // so it doesn't get checked in following iterations
            map[neighborIndex] = 1;
            // Fill it up
            output.buffer[neighborIndex] = abgr;
            // Check if this neighbor pixel is similar to the target color
            const ni = neighborIndex * 4;
            const neighbor = pixels.data.subarray(ni, ni + 4);
            if (
              neighbor.every((c, i) => target[i] === c) ||
              delta(neighbor, premultipliedTarget) < 0.1
            ) {
              // If it is, add it to the next queue so it's neighbors
              // get checked in the next iteration
              nqueue.push([nx, ny]);
            }
          }
        }
        // Swap the current queue for the next one
        queue = nqueue;
      };

      // processingStartedAt can be set to null when this component gets out of scope,
      // for example when changing the tool/layer while the fill is still processing.
      // This allows to stop the processing and avoids rendering into deallocated textures.
      const startTime = Date.now();
      processingStartedAt.current = startTime;
      // console.time('process');
      while (queue.length) {
        // This double loop processes the fill over time
        // to avoid hanging the main thread for too long.
        // The outer loop runs until there's nothing more in the queue to be processed.
        await new Promise((resolve) => {
          const time = performance.now();
          // The inner loop runs until 40ms has passed at the end of each queue iteration,
          // and/or there's nothing more in the queue to be processed.
          // This allows react to keep updating at moderate 25fps rate.
          // It also checks if the component has gone out of scope or the layer has changed (processingStartedAt === null)
          // and bails out if it has.
          while (
            processingStartedAt.current === startTime &&
            performance.now() - time < 40 &&
            queue.length
          ) {
            process();
          }
          setTimeout(resolve, 0);
        });
        // This component has gone out of scope (processingStartedAt has been set to null),
        // stop all processing and bail out.
        if (processingStartedAt.current !== startTime) {
          return;
        }
      }
      // console.timeEnd('process');
      processingStartedAt.current = null;

      ctx.putImageData(output.image, 0, 0);
      canvasTexture.needsUpdate = true;

      handleAction({
        type: 'updateLayer',
        id: layer.id,
        data: {
          image: brushTextureRendererRef.current.exportTexture(),
        },
      });
      resetPaintBucketOnNextRenderRef.current = true;
    }
  );

  const selectionTexture = useSubscribeToSelectionApi(
    (state) => state.texture_treatEmptyMaskAsFull
  );

  return (
    <>
      <BrushCursorPreview
        drawingSize={drawingSize}
        toolSize={0}
        toolAspect={1}
        toolAngle={0}
        color={color}
      />
      <LayerContent
        id={layer.id}
        opacity={layer.opacity}
        visible={layer.visible}
        blendMode={layer.blendMode}
        zIndex={zIndex}
        type={'vizcom:toolLayerContent'}
      >
        <BrushTextureRenderer
          size={drawingSize}
          layerFill={layerFill}
          layerTexture={layerImage}
          brushTexture={canvasTexture}
          maskTexture={selectionTexture}
          brushOpacity={1}
          ref={brushTextureRendererRef}
        />
      </LayerContent>
      <EventMesh
        drawingSize={drawingSize}
        eventMeshProps={{ onPointerDown: pointerdown }}
        ref={eventPlaneRef}
      />
    </>
  );
};

import styled from 'styled-components';

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: white;
  position: relative;
  pointer-events: none;
`;

export const WorkbenchPlaceholderLoadingImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: brightness(0.8) blur(5px);
`;

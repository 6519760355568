import { useState } from 'react';
import { useTheme } from 'styled-components';
import {
  BrushIcon,
  PaintBucketIcon,
  SettingsIcon,
  ToolbarButtonState,
  ToolbarDivider,
  ToolbarMenuButton,
} from '@vizcom/shared-ui-components';

import {
  useWorkbenchStudioState,
  WorkbenchStudioToolType,
} from '../../studioState';
import { ToolbarIcon } from '../ToolbarIcon';
import { ToolbarSlider } from '../ToolbarSlider';
import { BrushSettings } from './BrushSettings';

interface SecondaryBrushToolbarProps {
  isHideSliders: boolean;
}

export const SecondaryBrushToolbar = ({
  isHideSliders,
}: SecondaryBrushToolbarProps) => {
  const [showSettings, setShowSettings] = useState(false);

  const theme = useTheme();
  const { tool, setTool, getToolSettings } = useWorkbenchStudioState();
  const { toolOpacity, toolSize, setToolOpacity, setToolSize } =
    getToolSettings();

  return (
    <>
      <ToolbarIcon
        $toolState={
          tool === WorkbenchStudioToolType.Brush
            ? ToolbarButtonState.ACTIVE
            : ToolbarButtonState.INACTIVE
        }
        onClick={() => setTool(WorkbenchStudioToolType.Brush)}
        icon={<BrushIcon />}
      />
      <ToolbarIcon
        $toolState={
          tool === WorkbenchStudioToolType.PaintBucket
            ? ToolbarButtonState.ACTIVE
            : ToolbarButtonState.INACTIVE
        }
        onClick={() => setTool(WorkbenchStudioToolType.PaintBucket)}
        icon={<PaintBucketIcon />}
      />

      <ToolbarDivider />

      <ToolbarSlider
        isHideSliders={isHideSliders}
        label="Size"
        value={toolSize}
        setValue={setToolSize}
        min={1}
        max={250}
        step={1}
        unit="px"
        isLogScale
        disabled={tool !== WorkbenchStudioToolType.Brush}
      />

      <ToolbarSlider
        isHideSliders={isHideSliders}
        label="Opacity"
        value={toolOpacity}
        setValue={setToolOpacity}
        min={0}
        max={1}
        step={0.01}
        unit="%"
        multiplier={100}
        disabled={tool !== WorkbenchStudioToolType.Brush}
      />

      <ToolbarDivider />

      <ToolbarMenuButton
        disabled={tool !== WorkbenchStudioToolType.Brush}
        icon={<SettingsIcon />}
        menuStyle={{
          padding: 8,
          backgroundColor: theme.surface.primary,
          borderRadius: theme.borderRadius.l,
          marginTop: -4,
        }}
        menuOptions={{ manualOpen: true }}
        menu={
          <BrushSettings
            onClose={() => setShowSettings(false)}
            title="Settings"
          />
        }
        tooltip="Settings"
        state={
          showSettings ? ToolbarButtonState.ACTIVE : ToolbarButtonState.INACTIVE
        }
        disabledWhenInactive
        onClick={() => setShowSettings(!showSettings)}
      />
    </>
  );
};

import { useSpring, animated } from '@react-spring/three';
import {
  CursorIconRaw,
  useStringHashNumber,
} from '@vizcom/shared-ui-components';

import { WorkbenchContentRenderingOrder } from '../../WorkbenchContent';
import { MultiplayerPresence } from '../../lib/useWorkbenchMultiplayer';
import { FixedSizeGroup } from '../utils/FixedSizeGroup';
import { StaticSvg } from '../utils/StaticSvg';
import { TextWithBackground } from '../utils/TextWithBackground';
import { VizcomRenderingOrderEntry } from '../utils/threeRenderingOrder';
import {
  useMultiplayerDisplayConfig,
  getMultiplayerUserId,
} from './multiplayerDisplayConfig';

export const MultiplayerPresenceCursor = (props: {
  presence: MultiplayerPresence;
  editingElementId: string | null;
}) => {
  const { presence, editingElementId } = props;

  const userId = getMultiplayerUserId(presence.id);
  const hash = useStringHashNumber(userId);
  const { position } = useSpring({
    position: [presence.cursor?.x ?? 0, presence.cursor?.y ?? 0],
  });
  const displayConfig = useMultiplayerDisplayConfig(hash, presence.user);

  if (!presence.cursor || !presence.id) return null;
  if (editingElementId !== presence.editingElementId) return null;

  return (
    <animated.group
      position={position.to((x, y) => [x, y, 0])}
      userData={{
        vizcomRenderingOrder: [
          {
            zIndex: WorkbenchContentRenderingOrder.indexOf('overlays'),
          } satisfies VizcomRenderingOrderEntry,
        ],
      }}
    >
      <FixedSizeGroup>
        <StaticSvg
          fillMaterial={{ color: displayConfig.color }}
          svg={CursorIconRaw}
        />
        <TextWithBackground
          position={[15, -15, 0]}
          padding={[18, 10]}
          color="white"
          backgroundColor={displayConfig.color}
          fontWeight={500}
        >
          {displayConfig.name}
        </TextWithBackground>
      </FixedSizeGroup>
    </animated.group>
  );
};

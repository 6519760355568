import { useSpring } from '@react-spring/three';
import { useFrame } from '@react-three/fiber';
import { useEffect, useRef } from 'react';
import { Vector3 } from 'three';

import {
  MultiplayerPresence,
  useWorkbenchMultiplayer,
} from '../../lib/useWorkbenchMultiplayer';
import { floorPlane } from '../../lib/utils';
import {
  useMapControls,
  useOnMapControlsMove,
} from '../utils/mapControls/utils';
import { MultiplayerPresenceCursor } from './MultiplayerPresenceCursors';

const v3 = new Vector3();

export const MultiplayerCursorsSynchronizer = (props: {
  handleCursorMove: ReturnType<
    typeof useWorkbenchMultiplayer
  >['handleCursorMove'];
  handleCameraMove: ReturnType<
    typeof useWorkbenchMultiplayer
  >['handleCameraMove'];
  selectedPresence: MultiplayerPresence | undefined;
  multiplayerPresences: MultiplayerPresence[];
  setSelectedPresenceId: React.Dispatch<React.SetStateAction<string | null>>;
  editingElementId: string | null;
  setEditingElementId: (id: string | null) => void;
}) => {
  const {
    selectedPresence,
    editingElementId,
    setEditingElementId,
    handleCameraMove,
    handleCursorMove,
    multiplayerPresences,
    setSelectedPresenceId,
  } = props;

  const lastPosition = useRef({
    cursor: { x: 0, y: 0 },
    camera: { x: 0, y: 0, zoom: 0 },
  });
  useFrame((s) => {
    // Send camera and cursor state to server if changed
    if (
      dist(lastPosition.current.camera.x, s.camera.position.x) > 1 ||
      dist(lastPosition.current.camera.y, s.camera.position.y) > 1 ||
      dist(lastPosition.current.camera.zoom, s.camera.zoom) > 0.01
    ) {
      handleCameraMove(s.camera.position.x, s.camera.position.y, s.camera.zoom);
      lastPosition.current.camera = {
        x: s.camera.position.x,
        y: s.camera.position.y,
        zoom: s.camera.zoom,
      };
    }

    s.raycaster.ray.intersectPlane(floorPlane, v3);
    if (
      dist(lastPosition.current.cursor.x, v3.x) > 5 ||
      dist(lastPosition.current.cursor.y, v3.y) > 5
    ) {
      handleCursorMove(v3.x, v3.y);
      lastPosition.current.cursor = {
        x: v3.x,
        y: v3.y,
      };
    }
  });

  const controls = useMapControls();
  useOnMapControlsMove((e) => {
    if (e.type !== 'moveTo' || !selectedPresence || e.params.controlled) {
      return;
    }

    // when user manually move the camera, stop following the selected presence
    setSelectedPresenceId(null);
  });

  useEffect(() => {
    // follow selected presence in/out of 2D studio
    if (
      selectedPresence &&
      selectedPresence.editingElementId !== editingElementId
    ) {
      setEditingElementId(selectedPresence.editingElementId);
    }
  }, [editingElementId, setEditingElementId, selectedPresence]);

  const selectedPresenceCamera = useSpring({
    x: selectedPresence?.camera?.x,
    y: selectedPresence?.camera?.y,
    zoom: selectedPresence?.camera?.zoom,
  });
  useFrame(() => {
    // follow selected presence
    if (
      selectedPresence &&
      selectedPresenceCamera.x &&
      selectedPresenceCamera.y &&
      selectedPresenceCamera.zoom &&
      selectedPresence.editingElementId === editingElementId
    ) {
      controls.moveTo({
        x: selectedPresenceCamera.x.get(),
        y: selectedPresenceCamera.y.get(),
        zoom: selectedPresenceCamera.zoom.get(),
        skipAnimation: true,
        controlled: true,
      });
    }
  });

  return (
    <>
      {multiplayerPresences.map((presence) => (
        <MultiplayerPresenceCursor
          key={presence.id}
          presence={presence}
          editingElementId={editingElementId}
        />
      ))}
    </>
  );
};

const dist = (a: number, b: number) => Math.abs(a - b);

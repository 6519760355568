import { PropsWithChildren } from 'react';
import styled from 'styled-components';

export const SettingsPageHeader = styled.div`
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
`;
export const SettingsPageDivider = styled.div`
  background-color: ${(p) => p.theme.surface.tertiary};
  width: 100%;
  height: 1px;
  margin: 24px 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  position: relative;
  min-height: 100%;
  padding-bottom: 50px;
`;

const Content = styled.div`
  max-width: 840px;
  width: 100%;
`;

export const SettingsPageContainer = ({ children }: PropsWithChildren) => {
  return (
    <Container>
      <Content>{children}</Content>
    </Container>
  );
};

import { shaderMaterial } from '@react-three/drei';
import { extend, useThree } from '@react-three/fiber';
import { useCallback, useRef } from 'react';
import { CSSProperties } from 'styled-components';
import {
  Color,
  ColorRepresentation,
  Group,
  Mesh,
  OrthographicCamera,
  ShaderMaterial,
} from 'three';
import { useDocumentEventListener } from '@vizcom/shared-ui-components';

import { rotate, sdCircle, sdCross, sdEllipse } from '../../../../lib/glsl';
import { screenPositionToLocal } from '../../../helpers';
import { useWorkbenchStudioState } from '../../studioState';
import { EventMesh } from '../EventMesh';

export const BrushCursorPreview = ({
  drawingSize,
  toolSize,
  toolAspect,
  toolAngle,
  color,
}: {
  drawingSize: [number, number];
  toolSize: number;
  toolAspect: number;
  toolAngle: number;
  color: string;
}) => {
  const brushCursorPreviewRef = useRef<Mesh | null>(null);
  const groupRef = useRef<Group | null>(null);
  const studioState = useWorkbenchStudioState();
  const camera = useThree((state) => state.camera as OrthographicCamera);

  useDocumentEventListener('pointermove', (e) => {
    if (studioState.resizing) return;
    const position = screenPositionToLocal(
      [e.clientX, e.clientY],
      camera,
      groupRef.current!
    );
    if (brushCursorPreviewRef.current) {
      brushCursorPreviewRef.current.position.set(position[0], position[1], 1);
    }
  });

  const pointerEnter = useCallback(() => {
    if (brushCursorPreviewRef.current) {
      brushCursorPreviewRef.current.visible = true;
    }
  }, []);
  const pointerLeave = useCallback(() => {
    if (brushCursorPreviewRef.current) {
      brushCursorPreviewRef.current.visible = false;
    }
  }, []);

  let cursor: CSSProperties['cursor'] | null = null;
  if (studioState.resizing) {
    cursor = 'ew-resize';
  } else {
    // currently drawing, cursor shouldn't show because we show the brush preview instead
    cursor = 'none';
  }

  return (
    <group ref={groupRef}>
      <EventMesh
        drawingSize={drawingSize}
        eventMeshProps={{
          userData: {
            cursor: cursor,
          },
          renderOrder: 1,
          onPointerEnter: pointerEnter,
          onPointerLeave: pointerLeave,
        }}
      />
      <mesh
        ref={brushCursorPreviewRef}
        onBeforeRender={(_r, _s, camera, _g, material) => {
          const { zoom } = camera as OrthographicCamera;
          const { uniforms } = material as ShaderMaterial;
          uniforms.scale.value = 1 / zoom;
          brushCursorPreviewRef.current!.rotation.z = camera.rotation.z;
        }}
        visible={
          !studioState.rotating &&
          !studioState.panning &&
          !studioState.canvasColorPickerSession
        }
      >
        <planeGeometry args={[2, 2]} />
        <brushCursorPreviewMaterial
          transparent
          depthTest={false}
          angle={(toolAngle * Math.PI) / 180}
          aspect={toolAspect}
          color={color}
          size={toolSize}
        />
      </mesh>
    </group>
  );
};

const BrushCursorPreviewMaterialImpl = shaderMaterial(
  {
    angle: 0,
    aspect: 1,
    color: new Color(),
    scale: 1,
    size: 1,
  },
  /* glsl */ `
uniform float angle;
uniform float scale;
uniform float size;
flat varying float vRadius;
flat varying int vShape;
varying vec2 vUv;

${rotate}

void main() {
  float rotation = -angle;
  vRadius = (size / scale) * 0.5;
  if (vRadius <= 3.0) {
    rotation = 0.0;
    vShape = 1;
    vRadius = 10.0;
  }
  vUv = position.xy * (vRadius + 2.0);
  gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(rotate(vUv * scale, rotation), 0, 1.0);
}`,
  /* glsl */ `
uniform float aspect;
uniform vec3 color;
flat varying float vRadius;
flat varying int vShape;
varying vec2 vUv;

${sdCircle}
${sdCross}
${sdEllipse}

void main() {
  float dist;
  if (vShape == 0) {
    if (aspect == 1.0) {
      dist = sdCircle(vUv, vRadius);
    } else {
      dist = sdEllipse(vUv, vRadius * vec2(1.0, aspect));
    }
    dist = abs(dist + 1.0) - 1.0;
  } else {
    dist = sdCross(vUv, vRadius * vec2(0.5, 0.05), 0.0);
  }

  vec3 col = mix(
    color,
    mix(vec3(0.0), vec3(1.0), smoothstep(-1.0, 1.0, dist)),
    smoothstep(-2.0, 2.0, dist)
  );
  float alpha = 1.0 - smoothstep(-2.0, 2.0, dist);
  gl_FragColor = clamp(vec4(col, alpha), 0.0, 1.0);

}
`
);

extend({
  BrushCursorPreviewMaterial: BrushCursorPreviewMaterialImpl,
});

type BrushCursorPreviewMaterialType =
  JSX.IntrinsicElements['shaderMaterial'] & {
    angle: number;
    aspect: number;
    color: ColorRepresentation;
    size: number;
  };

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      brushCursorPreviewMaterial: BrushCursorPreviewMaterialType;
    }
  }
}

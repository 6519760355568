import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const CarouselContainer = styled.div`
  position: relative;
  width: 50vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;

  @media (max-width: 767px) {
    display: none;
  }
`;

const Slide = styled.div`
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.l};
  position: relative;
  background-size: cover;
  background-position: center;
  transition: background-image 0.5s ease-in-out;

  // Gradient overlay
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.7),
      transparent
    ); // Adjust the gradient as needed
    border-radius: ${({ theme }) => theme.borderRadius.l};
  }

  // Ensure content is above the gradient overlay
  > * {
    position: relative;
    z-index: 1;
  }
`;

const Title = styled.h2`
  color: white;
  font-size: 16px;
  font-weight: 700;
  position: absolute;
  bottom: 48px;
  left: 24px;
`;

const Subtitle = styled.p`
  color: white;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  bottom: 32px;
  left: 24px;
`;

const FollowButton = styled.button`
  color: white;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  bottom: 32px;
  right: 24px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 55px;
  padding: 4px 12px;
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  &:after {
    content: '➔';
    display: block;
    font-size: 12px;
    transform: translateY(0px);
  }
`;

const IndicatorContainer = styled.div`
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
`;

const Indicator = styled.div`
  width: 100px;
  height: 8px;
  background: rgba(255, 255, 255, 0.3); // Inactive indicator color
  border-radius: ${({ theme }) => theme.borderRadius.m};
  cursor: pointer;
  transition: background-color 0.3s;

  &.active {
    background: white; // Active indicator color
  }
`;

export interface Media {
  src: string;
  type: 'image' | 'video';
  title: string;
  description: string;
  link: string;
}

interface CarouselProps {
  data: Media[];
}

export const Carousel: React.FC<CarouselProps> = ({ data }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const preloadImages = () => {
    data.forEach((item) => {
      const img = new Image();
      img.src = item.src;
    });
  };

  const setMedia = (index: number) => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    setCurrentIndex(index);
  };

  useEffect(() => {
    // Preload images on mount
    preloadImages();

    intervalRef.current = setInterval(() => {
      const nextIndex = (currentIndex + 1) % data.length;
      setMedia(nextIndex);
    }, 5000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [currentIndex, data.length]);

  return (
    <CarouselContainer>
      {data.length > 0 && (
        <Slide style={{ backgroundImage: `url(${data[currentIndex].src})` }}>
          <Title>{data[currentIndex].title}</Title>
          <Subtitle>{data[currentIndex].description}</Subtitle>
          <FollowButton
            onClick={() => window.open(data[currentIndex].link, '_blank')}
          >
            Follow
          </FollowButton>
        </Slide>
      )}
      <IndicatorContainer>
        {data.map((_, index) => (
          <Indicator
            key={index}
            className={currentIndex === index ? 'active' : ''}
            onClick={() => setMedia(index)}
          />
        ))}
      </IndicatorContainer>
    </CarouselContainer>
  );
};

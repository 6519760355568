import { useMemo } from 'react';
import {
  CaretDownIconRotatable,
  CheckIcon,
  DefaultOptionComponent,
  InlineFlex,
  OptionButtonContainer,
  OptionComponentBase,
  Select,
  SelectedIndicator,
  Text,
  UpgradeIcon,
} from '@vizcom/shared-ui-components';

export const OutputsMenu = ({
  isFreePlan,
  outputsCount,
  onOutputsCountChange,
  isHistoryPreviewActive,
  handleLocked,
}: {
  isFreePlan: boolean;
  outputsCount: number;
  onOutputsCountChange: (value: number) => void;
  isHistoryPreviewActive: boolean;
  handleLocked: () => void;
}) => {
  const onClick4 = useMemo(() => {
    if (isFreePlan) {
      return handleLocked;
    }

    return () => onOutputsCountChange(4);
  }, [isFreePlan, onOutputsCountChange, handleLocked]);

  return (
    <Select
      onSelectElement={(value) =>
        value === '1'
          ? onOutputsCountChange(1)
          : value === '4'
          ? onClick4()
          : null
      }
      selectedOptionIndex={outputsCount === 1 ? 0 : 1}
      customSelectedTriggerContent={({ isOpen }) => (
        <OptionButtonContainer $active={false} style={{ height: '28px' }}>
          <Text type="b1" tabularNums>
            {outputsCount}
          </Text>
          <CaretDownIconRotatable $angle={isOpen ? 180 : 0} />
        </OptionButtonContainer>
      )}
      style={{ flex: 0 }}
      disabled={isHistoryPreviewActive}
    >
      <DefaultOptionComponent
        value="1"
        label="1"
        disabled={isHistoryPreviewActive}
      />
      <OptionComponentBase
        value="4"
        label="4"
        disabled={isHistoryPreviewActive}
      >
        {({ isSelected }) => (
          <InlineFlex $gap={8} style={{ width: 'fit-content' }}>
            <SelectedIndicator $active={isSelected}>
              <CheckIcon $color="action" />
            </SelectedIndicator>
            <Text>4</Text>
            {isFreePlan && <UpgradeIcon />}
          </InlineFlex>
        )}
      </OptionComponentBase>
    </Select>
  );
};

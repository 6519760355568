import { useGLTF } from '@react-three/drei';
import { useEffect, useMemo, useState } from 'react';
import { Material, Mesh, MeshBasicMaterial, Object3D } from 'three';
import { CompositeSceneFullData } from '@vizcom/shared/data-access/graphql';

import { useCompositeSceneSyncedState } from '../../../lib/useCompositeSceneSyncedState';
import { useCompositeSceneEditorContext } from '../compositeSceneEditor/context';
import { getCompositeSceneNodeUUID } from '../compositeSceneEditor/utils/compositeSceneNodeUUID';
import { MissingLabel } from '../compositeSceneMenu/style';
import { MaterialConfiguration } from './MaterialConfiguration';
import { MaterialPreviewIcon } from './MaterialPreviewIcon';
import { Container, MaterialPreviewItem } from './style';

export const MaterialsList = ({
  handleAction,
  compositeScene,
}: {
  handleAction: ReturnType<typeof useCompositeSceneSyncedState>['handleAction'];
  compositeScene: CompositeSceneFullData;
}) => {
  const { selected } = useCompositeSceneEditorContext();
  const [selectedMaterial, setSelectedMaterial] = useState<Material | null>(
    null
  );

  /**
   * NOTE When switching between model nodes, UX feels better if we keep configurator window open
   *      but switch to the first available material of the new node
   */
  const [firstAvailableMaterial, setFirstAvailableMaterial] =
    useState<Material | null>(null);

  const selectedModelUrl = useMemo(() => {
    if (!selected || !compositeScene) {
      return;
    }

    const { rootId } = selected.userData;
    const rootNode = compositeScene.compositeSceneElements.nodes.find(
      (match) => match.id === rootId
    );

    if (!rootNode) {
      return;
    }

    return rootNode.modelPath;
  }, [selected, compositeScene]);
  const [gltf] = useGLTF(selectedModelUrl ? [selectedModelUrl] : []);
  const materialsList = useMemo<JSX.Element | null>(() => {
    if (!gltf || !gltf.scene || !selected) {
      return null;
    }

    const materials: Record<string, Material> = {};
    let selectedNode: Object3D;

    gltf.scene.traverse((object) => {
      if (selectedNode) {
        return;
      }

      if (
        getCompositeSceneNodeUUID(object) ===
        getCompositeSceneNodeUUID(selected)
      ) {
        selectedNode = object;
      }
    });

    if (!selectedNode!) {
      setFirstAvailableMaterial(null);

      return null;
    }

    const mesh = selectedNode as Mesh;

    // TODO In the future this should most likely selectedNode.traverse and pick nested materials as well
    //      Currently backend allows overriding only a single material per mesh, not modifying yet
    if (mesh.isMesh && mesh.material) {
      (mesh.material instanceof Array
        ? mesh.material
        : [mesh.material]
      ).forEach((material) => {
        materials[getCompositeSceneNodeUUID(material)] = material;
      });
    }

    if (!Object.keys(materials).length) {
      setFirstAvailableMaterial(null);

      return null;
    }

    setFirstAvailableMaterial(materials[Object.keys(materials)[0]]);

    return (
      <>
        {Object.entries(materials).map(([uuid, material]) => {
          return (
            <MaterialPreviewItem
              key={uuid}
              onClick={() => setSelectedMaterial(material)}
            >
              <MaterialPreviewIcon
                material={material}
                $fallbackColor={
                  (material as MeshBasicMaterial).color
                    ? (material as MeshBasicMaterial).color.getHexString()
                    : undefined
                }
              />
              {material.name || <MissingLabel>(Unnamed)</MissingLabel>}
            </MaterialPreviewItem>
          );
        })}
      </>
    );
  }, [gltf, selected, compositeScene]);

  useEffect(() => {
    if (!selectedMaterial) {
      return;
    }

    setSelectedMaterial(firstAvailableMaterial);
  }, [selected]);

  useEffect(() => {
    if (!selected) {
      setSelectedMaterial(null);
      setFirstAvailableMaterial(null);
    }
  }, [selected]);

  return (
    <>
      {selected && (
        <Container>
          {materialsList || <MissingLabel>(No materials)</MissingLabel>}
        </Container>
      )}
      {selectedMaterial && (
        <MaterialConfiguration
          key={selectedMaterial.uuid}
          material={selectedMaterial}
          compositeScene={compositeScene}
          handleAction={handleAction}
        />
      )}
    </>
  );
};

import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

export const Label = styled.div<{ $disabled?: boolean }>`
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'text')};
  color: ${({ theme }) => theme.deprecated.white};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid transparent;
  margin-right: auto;
  max-width: 106px;
`;

export const Input = styled.input`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  appearance: none;
  padding: 0px 4px;
  border-radius: ${({ theme }) => theme.borderRadius.s};
  margin-left: -4px;

  &:focus {
    border: 1px solid ${({ theme }) => theme.deprecated.primary.default};
  }
`;

type NameProps = {
  disabled?: boolean;
  onBlur?: () => void;
  onChange: (value: string) => void;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  value: string;
};

export const LayerName = ({
  disabled,
  onBlur,
  onChange,
  value,
  isEditing,
  setIsEditing,
}: NameProps) => {
  const inputRef = useRef<HTMLInputElement>(null!);
  const [cursor, setCursor] = useState<number | null>(null);

  const handleBlur = useCallback(() => {
    setIsEditing(false);
    onChange(inputRef.current.value);
    onBlur?.();
  }, [onBlur, onChange, setIsEditing]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCursor(event.target.selectionStart);
    },
    [onChange]
  );
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      event.stopPropagation();

      if (!event.repeat && event.key?.toLowerCase() === 'enter') {
        (event.target as HTMLInputElement).blur();
      }
    },
    []
  );
  const handlePointerDown = useCallback(
    (event: React.PointerEvent<HTMLInputElement>) => event.stopPropagation(),
    []
  );

  useEffect(() => {
    if (isEditing) {
      inputRef.current.value = value;
      inputRef.current.select();
    }
  }, [isEditing, value]);

  useEffect(() => {
    const input = inputRef.current;
    if (input) input.setSelectionRange(cursor, cursor);
  }, [inputRef, cursor, value]);

  if (isEditing) {
    return (
      <Input
        ref={inputRef}
        type="text"
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onPointerDown={handlePointerDown}
        autoFocus
      />
    );
  }

  return (
    <Label
      $disabled={disabled}
      onDoubleClick={() => !disabled && setIsEditing(true)}
    >
      {value || 'Untitled'}
    </Label>
  );
};

import { ComponentType } from '@react-spring/web';
import { RootState } from '@react-three/fiber';
import { useDrag } from '@use-gesture/react';
import { memo, MutableRefObject, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled, { useTheme } from 'styled-components';
import { OrthographicCamera } from 'three';
import { OrganizationSubscriptionPlan } from '@vizcom/shared/plans-limit';
import {
  Button,
  Divider,
  FeatureFlagged,
  Import3DModelIcon,
  ImportImageIcon,
  MobileUploadIcon,
  OnboardingStepName,
  OnboardingTooltip,
  PlusIcon,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  SubscriptionGated,
  Text,
  ToolbarButton,
  ToolbarButtonState,
  useCompleteOnboardingStep,
  useFeatureFlag,
  useKeyboardShortcut,
  useOnboardingStatus,
} from '@vizcom/shared-ui-components';

import { useWorkbenchSyncedState } from '../../lib/useWorkbenchSyncedState';
import { createCompositeScene } from '../createCompositeScene';
import {
  getWorkbenchElementZPositionRange,
  MAX_Z_POSITION,
  screenPositionToWorld,
} from '../helpers';
import { handleImportFilesWithSceneContext } from '../utils/importFiles';
import { elementCreators } from './elementCreatorButtons';

export const WorkbenchInsertButton = memo(
  ({
    isMenuOpen,
    subscriptionPlan,
    setIsMenuOpen,
    threeState,
    handleSelectCreated,
    handleAction,
    onOpenMobileUploadModal,
    setOpenPaletteOnboardingModal,
  }: {
    isMenuOpen: boolean;
    subscriptionPlan?: OrganizationSubscriptionPlan;
    setIsMenuOpen: (open: boolean) => void;
    threeState: MutableRefObject<RootState>;
    handleSelectCreated: (id: string) => void;
    handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
    onOpenMobileUploadModal: () => void;
    setOpenPaletteOnboardingModal: (open: boolean) => void;
  }) => {
    const theme = useTheme();
    const onboardingTooltipRef = useRef<HTMLDivElement>(null);

    const [draggingPlaceholder, setDraggingPlaceholder] = useState<null | {
      Button: ComponentType;
      x: number;
      y: number;
      width: number;
      height: number;
    }>(null);

    const completeOnboardingStep = useCompleteOnboardingStep();
    const { flagEnabled: is3dStudioEnabled } =
      useFeatureFlag('COMPOSITE_SCENE');

    const paletteModalStatus = useOnboardingStatus('palette_onboarding');
    const openPaletteModal = () => setOpenPaletteOnboardingModal(true);

    const bind = useDrag(
      ({ event, down, last, currentTarget, args, tap }) => {
        const clientX = 'clientX' in event ? event.clientX : 0;
        const clientY = 'clientY' in event ? event.clientY : 0;

        const creator = args[0] as typeof elementCreators[0];
        if (down) {
          setDraggingPlaceholder({
            Button: creator.Button,
            x: clientX,
            y: clientY,
            width: (currentTarget as HTMLDivElement).offsetWidth,
            height: (currentTarget as HTMLDivElement).offsetHeight,
          });
        } else {
          setDraggingPlaceholder(null);
        }

        const position = screenPositionToWorld(
          [clientX, clientY],
          threeState.current.camera as OrthographicCamera
        );

        const x = tap ? threeState.current.camera.position.x : position[0];
        const y = tap ? threeState.current.camera.position.y : position[1];
        if (last || tap) {
          const zRange = getWorkbenchElementZPositionRange(
            threeState.current.scene
          );

          const zIndex = isFinite(zRange[1])
            ? zRange[1] + 1
            : MAX_Z_POSITION / 2;

          const elementId = creator.action({
            x,
            y,
            zIndex,
            handleAction,
            completeOnboardingStep,
            paletteModalStatus,
            openPaletteModal,
          });

          handleSelectCreated(elementId);
          setIsMenuOpen(false);
          setDraggingPlaceholder(null);
        }
      },
      {
        preventDefault: true,
        filterTaps: true,
      }
    );

    const handleOpenMobileUploadModal = () => {
      onOpenMobileUploadModal();
      setIsMenuOpen(false);
    };

    const handleUploadFile = async () => {
      await handleImportFilesWithSceneContext({
        scene: threeState.current.scene,
        camera: threeState.current.camera,
        handleAction,
      });
      setIsMenuOpen(false);
    };

    const handleUpload3dModel = () => {
      if (!is3dStudioEnabled) return;
      createCompositeScene({
        scene: threeState.current.scene,
        camera: threeState.current.camera,
        handleAction,
      });
    };

    useKeyboardShortcut('i', handleUploadFile);
    useKeyboardShortcut('p', handleUpload3dModel);
    useKeyboardShortcut('/', handleOpenMobileUploadModal);

    const onboardingTooltipHeightPx =
      onboardingTooltipRef.current?.clientHeight ??
      245 + 50 + (is3dStudioEnabled ? 50 : 0);
    const onboardingTooltipWidthPx =
      onboardingTooltipRef.current?.clientWidth ?? 240;

    return (
      <Container>
        <RichTooltip
          trigger="click"
          onOpenChange={setIsMenuOpen}
          isOpen={isMenuOpen}
          displayArrow={false}
          padding={12}
        >
          <RichTooltipTrigger>
            <ToolbarButton
              icon={<PlusIcon />}
              tooltip="Insert"
              state={
                isMenuOpen
                  ? ToolbarButtonState.ACTIVE
                  : ToolbarButtonState.INACTIVE
              }
            />
          </RichTooltipTrigger>
          <RichTooltipContent
            ref={onboardingTooltipRef}
            closeOnClick
            style={{
              overflow: 'hidden',
              padding: 0,
              borderRadius: theme.borderRadius.l,
            }}
          >
            <InsertActionButtonContainer>
              <InsertActionButton variant="tertiary" onClick={handleUploadFile}>
                <ImportImageIcon />
                <Text
                  style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  Upload an image <Text color="subtext">I</Text>
                </Text>
              </InsertActionButton>
              <InsertActionButton
                variant="tertiary"
                onClick={onOpenMobileUploadModal}
              >
                <MobileUploadIcon />
                <Text
                  style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  Upload from phone <Text color="subtext">/</Text>
                </Text>
              </InsertActionButton>

              <FeatureFlagged flag="COMPOSITE_SCENE">
                <InsertActionButton
                  variant="tertiary"
                  onClick={handleUpload3dModel}
                >
                  <Import3DModelIcon />
                  <Text
                    style={{
                      flex: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    Add a 3D model <Text color="subtext">P</Text>
                  </Text>
                </InsertActionButton>
              </FeatureFlagged>
            </InsertActionButtonContainer>
            <Divider />
            <GridContainer>
              {elementCreators.map((creator, i) =>
                creator.featureFlag ? (
                  <SubscriptionGated
                    key={creator.Button.name}
                    organizationSubscription={subscriptionPlan}
                    allowedSubscriptions={creator.allowedSubscriptionPlans}
                    flagOverride={creator.subscriptionPlanOverride}
                  >
                    <FeatureFlagged key={i} flag={creator.featureFlag}>
                      <creator.Button
                        key={i}
                        toolboxOpen={isMenuOpen}
                        {...bind(creator)}
                      />
                    </FeatureFlagged>
                  </SubscriptionGated>
                ) : (
                  <SubscriptionGated
                    key={creator.Button.name}
                    organizationSubscription={subscriptionPlan}
                    allowedSubscriptions={creator.allowedSubscriptionPlans}
                    flagOverride={creator.subscriptionPlanOverride}
                  >
                    <creator.Button
                      key={i}
                      toolboxOpen={isMenuOpen}
                      {...bind(creator)}
                    />
                  </SubscriptionGated>
                )
              )}
            </GridContainer>
          </RichTooltipContent>
        </RichTooltip>
        {draggingPlaceholder &&
          createPortal(
            <DraggingIndicator
              style={{
                left: draggingPlaceholder.x,
                top: draggingPlaceholder.y,
                width: draggingPlaceholder.width,
                height: draggingPlaceholder.height,
              }}
            >
              {<draggingPlaceholder.Button />}
            </DraggingIndicator>,
            document.body
          )}
        <OnboardingTooltip
          name={OnboardingStepName.InsertImage}
          previousStep={OnboardingStepName.ClickInsert}
          title="Drag the image block onto the canvas."
          show={isMenuOpen}
        >
          <OnboardingTooltipHelper
            style={{
              position: 'absolute',
              // Note: this positions tooltip under the "sketch" element; consider determining dynamically
              left: -onboardingTooltipWidthPx / 4,
              top: (onboardingTooltipHeightPx * 3) / 4,
            }}
          />
        </OnboardingTooltip>
      </Container>
    );
  }
);

const Container = styled.div`
  position: relative;
`;

const OnboardingTooltipHelper = styled.div`
  position: absolute;
`;

const InsertActionButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  padding: 8px;
  gap: 4px;
`;

const InsertActionButton = styled(Button)`
  display: flex;
  gap: 7px;
  text-align: left;
  align-items: center;
  padding: 8px;

  &:not(:disabled):hover {
    background-color: ${({ theme }) => theme.surface.secondary};
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  overflow-y: auto;
  padding: 8px;
  position: relative;
`;

const DraggingIndicator = styled.div`
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
  z-index: 100000000;
`;

// provides a set of reusable handles for the transform controls
// A handle has a visible Shape and a hidden hit area

import { GroupProps } from '@react-three/fiber';
import { ColorRepresentation } from 'three';

import {
  OutlinedCircle,
  Circle,
  Square,
  OutlinedRoundedSquare,
  Segment,
} from './Shapes';

export const HANDLE_SIZE = 15;
export const HIT_AREA_MARGIN = 20;

//Abstract handle with a circle shape and hit area
type CircleHandleProps = {
  Shape: typeof Circle | typeof OutlinedCircle;
  color?: ColorRepresentation;
  size?: number;
  handleMargin?: number;
} & GroupProps;

function CircleHandle({
  Shape,
  color,
  size,
  handleMargin,
  ...groupProps
}: CircleHandleProps) {
  const radius = size || HANDLE_SIZE;
  const handleSize = handleMargin || HIT_AREA_MARGIN;
  return (
    <group {...groupProps}>
      <Shape radius={radius} color={color} />
      <Circle radius={radius + handleSize} opacity={0} />
    </group>
  );
}

//Concrete circle handles

export const SolidCircleHandle = (props: Omit<CircleHandleProps, 'Shape'>) => (
  <CircleHandle Shape={Circle} {...props} />
);

export const OutlinedCircleHandle = (
  props: Omit<CircleHandleProps, 'Shape'>
) => <CircleHandle Shape={OutlinedCircle} {...props} />;

//Abstract handle with a square shape and hit area
type SquareHandleProps = {
  Shape: typeof Square | typeof OutlinedRoundedSquare;
  color?: ColorRepresentation;
  size?: number;
  handleMargin?: number;
} & GroupProps;

function SquareHandle({
  Shape,
  color,
  size,
  handleMargin,
  ...groupProps
}: SquareHandleProps) {
  const radius = size || HANDLE_SIZE;
  const handleSize = handleMargin || HIT_AREA_MARGIN;
  return (
    <group {...groupProps}>
      <Shape size={radius} color={color} />
      <Square size={radius + handleSize} opacity={0} depthWrite={false} />
    </group>
  );
}

//Concrete square handles

export const SolidSquareHandle = (props: Omit<SquareHandleProps, 'Shape'>) => (
  <SquareHandle Shape={Square} {...props} />
);

export const OutlinedSquareHandle = (
  props: Omit<SquareHandleProps, 'Shape'>
) => <SquareHandle Shape={OutlinedRoundedSquare} {...props} />;

//Segment

type Point = [number, number];
export function SegmentHandle({
  from,
  to,
  thickness,
  color,
  ...groupProps
}: {
  from: Point;
  to: Point;
  thickness: number;
  color?: ColorRepresentation;
} & GroupProps) {
  return (
    <group {...groupProps}>
      <Segment
        from={from}
        to={to}
        thickness={thickness}
        color={color}
        depthWrite={false}
      />
      <Segment
        from={from}
        to={to}
        thickness={thickness + HIT_AREA_MARGIN}
        opacity={0}
        depthWrite={false}
      />
    </group>
  );
}

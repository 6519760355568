import { Navigate } from 'react-router-dom';
import {
  FullPageDarkLoader,
  Text,
  useSelectedOrganization,
} from '@vizcom/shared-ui-components';

import { OrganizationDeleteSetting } from './components/OrganizationDelete';
import { OrganizationLogoSetting } from './components/OrganizationLogo';
import { OrganizationNameSetting } from './components/OrganizationName';
import {
  SettingsPageContainer,
  SettingsPageDivider,
  SettingsPageHeader,
} from './components/SettingsPageLayout';

export const OrganizationGeneralSettings = () => {
  const { data: organization, loading } = useSelectedOrganization();

  if (loading) {
    return <FullPageDarkLoader />;
  }
  if (!organization) {
    return <Navigate to="/" />;
  }

  return (
    <SettingsPageContainer>
      <SettingsPageHeader>
        <Text style={{ fontSize: 18, fontWeight: 600 }}>General</Text>
        <Text color="subtext" type="b1">
          Manage your workspace settings
        </Text>
      </SettingsPageHeader>
      <SettingsPageDivider />

      <OrganizationNameSetting organization={organization} />

      <SettingsPageDivider />

      <OrganizationLogoSetting organization={organization} />

      <OrganizationDeleteSetting organization={organization} />
    </SettingsPageContainer>
  );
};

import {
  Convert2dTo3dIcon,
  ToolbarMenuButton,
  ToolbarButtonState,
} from '@vizcom/shared-ui-components';

import tooltip_2d_3d from '../../../assets/videos/tooltip_2d_3d_x480.mp4';
import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../../lib/useDrawingSyncedState';
import { Generate3dLayerMenu } from './Generate3dLayerMenu';
import { VideoTooltip } from './VideoTooltip';

interface To3DButtonProps {
  drawing: Drawing2dStudio;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  setActiveLayerId: (id: string | undefined) => void;
  isMenuOpen: boolean;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
  activeLayerId?: string;
  getCompositedImage: () => ImageData;
  quadTopology: boolean;
  setQuadTopology: (value: boolean) => void;
}

export const To3DButton = ({
  drawing,
  handleAction,
  setActiveLayerId,
  isMenuOpen,
  setIsMenuOpen,
  disabled,
  activeLayerId,
  getCompositedImage,
  quadTopology,
  setQuadTopology,
}: To3DButtonProps) => {
  return (
    <ToolbarMenuButton
      icon={<Convert2dTo3dIcon />}
      state={
        disabled ? ToolbarButtonState.DISABLED : ToolbarButtonState.INACTIVE
      }
      tooltip={
        disabled ? (
          'Select a 2D layer to use this tool'
        ) : (
          <VideoTooltip
            title="Generate 3D"
            description="Transform your 2D rendering into a dynamic 3D model with a single click."
            learnMoreUrl="https://youtu.be/bUF0CDeUT2E?si=cMEpIpiFMk3RQklA&t=217"
            videoSrc={tooltip_2d_3d}
          />
        )
      }
      menu={
        <Generate3dLayerMenu
          drawing={drawing}
          activeLayerId={activeLayerId}
          handleAction={handleAction}
          setActiveLayerId={setActiveLayerId}
          onButtonClicked={() => setIsMenuOpen(false)}
          getCompositedImage={getCompositedImage}
          quadTopology={quadTopology}
          setQuadTopology={setQuadTopology}
        />
      }
      menuTooltip="2D to 3D Tools"
      controlledMenuState={[isMenuOpen, setIsMenuOpen]}
    />
  );
};

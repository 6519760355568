import React, { useState } from 'react';
import styled from 'styled-components';
import {
  BrushIcon,
  Modal,
  ModalHeader,
  ModalTitle,
  Text,
  TextInput,
  WorkbenchIcon,
} from '@vizcom/shared-ui-components';

type WelcomeModalProps = {
  onExit: (name: string, startInStudio: boolean) => void;
};

export const WelcomeModal = ({ onExit }: WelcomeModalProps) => {
  const [name, setName] = useState('Untitled');

  const handleStartInStudio = () => {
    onExit(name, true);
  };

  return (
    <Modal
      isOpen={true}
      setIsOpen={handleStartInStudio}
      style={{ width: '521px' }}
    >
      <ModalHeader>
        <ModalTitle>
          <Text type="h1">Welcome To Vizcom</Text>
        </ModalTitle>
      </ModalHeader>

      <StyledTextInput value={name} onChange={(e) => setName(e.target.value)} />

      <OptionsContainer>
        <OptionContainer
          onClick={handleStartInStudio}
          $hoverColor="#368CE4"
          $iconHoverColor="#1B72CA"
        >
          <StyledIconContainer $color="#368ce4">
            <BrushIcon />
          </StyledIconContainer>
          <Text type="h4">Start in Studio</Text>
          <Text type="sh2" color="subtext">
            Sketch and render
          </Text>
        </OptionContainer>
        <OptionContainer
          onClick={() => onExit(name, false)}
          $hoverColor="#7F4AEF"
          $iconHoverColor="#5013D3"
        >
          <StyledIconContainer $color="#7F4aef">
            <WorkbenchIcon />
          </StyledIconContainer>
          <Text type="h4">Start in Workbench</Text>
          <Text type="sh2" color="subtext">
            Explore and collaborate
          </Text>
        </OptionContainer>
      </OptionsContainer>
    </Modal>
  );
};

const StyledTextInput = styled(TextInput)`
  margin: 4px 0 24px;
  background: ${({ theme }) => theme.surface.tertiary};
  padding: 9px 15px;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
`;

const StyledIconContainer = styled.div<{ $color: string }>`
  background: ${({ $color }) => $color};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  box-shadow: 0px 2px 4px 0px #0000007a;
  padding: 10.5px 12px;
  color: white;
`;

const OptionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
`;

const OptionContainer = styled.div<{
  $hoverColor: string;
  $iconHoverColor: string;
}>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  gap: 8px;
  border: 1px solid ${({ theme }) => theme.border.primary};
  border-radius: ${({ theme }) => theme.borderRadius.l};
  padding: 25px 0;

  &:hover {
    background-color: ${(p) => p.$hoverColor};

    ${Text}:last-child {
      color: ${({ theme }) => theme.text.body};
    }

    ${StyledIconContainer} {
      background: ${({ $iconHoverColor }) => $iconHoverColor};
      box-shadow: none;
    }
  }
`;

import { useThree } from '@react-three/fiber';
import { v4 as uuidv4 } from 'uuid';
import {
  ImageInferenceType,
  useCurrentUser,
  useDrawingMetadata,
} from '@vizcom/shared/data-access/graphql';
import { PublicPalette } from '@vizcom/shared/inference-worker-queues';
import {
  SparklesIcon,
  Button,
  DownloadIcon,
  EditIcon,
  FeatureFlagged,
  downloadFile,
  GLOBAL_ENABLE_FLAG,
  ImageMetadataIcon,
  ToolbarButton,
  ToolbarButtonState,
  ToolbarDivider,
  ToolbarMenuButton,
  ContextMenuItem,
  imageToBlob,
} from '@vizcom/shared-ui-components';

import { ClientSideWorkbenchElementDrawing } from '../../../lib/clientState';
import { useWorkbenchElementSelectionState } from '../../../lib/elementSelectionState';
import { useWorkbenchSyncedState } from '../../../lib/useWorkbenchSyncedState';
import {
  GENERATED_IMAGES_MARGIN,
  useIsWorkbenchViewer,
} from '../../../lib/utils';
import { WorkbenchElementExtra } from '../../WorkbenchElementExtra';
import {
  getElementSize,
  getWorkbenchElementZPositionRange,
} from '../../helpers';
import { findFirstFreeSlotInScene } from '../../utils/freeSlotFinders';
import { getElementDefaultSize } from '../../utils/getElementDefaultSize';
import { DrawingContextMenuItems } from '../../workbenchContextMenu/contextMenuItemsPerType/DrawingContextMenuItems';

type Props = {
  element: ClientSideWorkbenchElementDrawing;
  workbenchId: string;
  setEditingElementId: (id: string | null) => void;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
  toggleMetadata: () => void;
};

export const WorkbenchElementDrawingExtra = ({
  element,
  workbenchId,
  setEditingElementId,
  handleAction,
  toggleMetadata,
}: Props) => {
  const isViewer = useIsWorkbenchViewer();
  const scene = useThree((s) => s.scene);
  const { width, height } = getElementSize(element);
  const { data: drawingMetadata } = useDrawingMetadata(element.id);

  const user = useCurrentUser();
  const shouldHaveAiMenu =
    user.data?.featureFlags['IMG2MESH'] ||
    user.data?.featureFlags[GLOBAL_ENABLE_FLAG] ||
    user.data?.featureFlags['WORKBENCH_MULTI_VIEW'];

  const handleDownload = async () => {
    if (element.thumbnailPath) {
      const blob = await imageToBlob(element.thumbnailPath, {
        convertToContentType: 'image/png',
      });
      downloadFile(blob, element.name || 'drawing');
    }
  };
  const handleCreateImg2ImgElementFromDrawing = () => {
    const img2imgElementId = uuidv4();
    const size = getElementDefaultSize('WorkbenchElementImg2Img');

    const {
      text,
      sourceImageInfluence,
      publicPaletteId,
      customModelId,
      workbenchPaletteId,
      userPaletteId,
    } = drawingMetadata?.prompts.nodes[0] || {};

    handleAction({
      type: 'createElements',
      newElements: [
        {
          __typename: 'WorkbenchElementImg2Img',
          updatedAt: '0',
          id: img2imgElementId,
          width: size.x,
          height: size.y,
          x: element.x + width / 2 + width / 2 + 40,
          y: element.y,
          zIndex: element.zIndex,
          sourceDrawingId: element.id,
          prompt: text || '',
          sourceImageInfluences: sourceImageInfluence
            ? [sourceImageInfluence]
            : [1],
          publicPaletteId:
            publicPaletteId ||
            (!customModelId && !userPaletteId && !workbenchPaletteId
              ? PublicPalette.generalV2
              : null),
          customModelId: customModelId || null,
          workbenchPaletteId: workbenchPaletteId || null,
          userPaletteId: userPaletteId || null,
          imageInferenceType: ImageInferenceType.Render,
        },
      ],
    });
    useWorkbenchElementSelectionState
      .getState()
      .setFocusedElementsId(img2imgElementId);
    setTimeout(() => {
      const elementInputField = document.querySelector(
        `[data-workbench-id="${img2imgElementId}"] [data-workbench-role="promptInput"]`
      );
      if (elementInputField instanceof HTMLInputElement) {
        elementInputField.focus();
      }
      // TODO: better handle this to not use a timeout and instead have a reliable way of detecting the element has been added to the DOM
    }, 50);
  };

  const handleCreateMultiViews = () => {
    handleAction({
      type: 'createMultiViewsFromDrawing',
      drawingId: element.id,
    });
  };

  const handleCreateMeshFromDrawing = () => {
    const drawingSize = getElementSize(element);
    const width = 300;
    const height = 300;
    const position = findFirstFreeSlotInScene(scene, {
      firstSlotX:
        element.x + drawingSize.width / 2 + width / 2 + GENERATED_IMAGES_MARGIN,
      firstSlotY: element.y,
      slotWidth: width + GENERATED_IMAGES_MARGIN,
      slotHeight: height + GENERATED_IMAGES_MARGIN,
      maxElementPerLine: 4,
    });
    const zRange = getWorkbenchElementZPositionRange(scene);

    handleAction({
      type: 'createWorkbenchMeshFromDrawingAction',
      drawingId: element.id,
      placeholderId: uuidv4(),
      x: position[0],
      y: position[1],
      zIndex: isFinite(zRange[1]) ? zRange[1] + 1 : element.zIndex + 1,
      width,
      height,
    });
  };

  const extra = isViewer ? (
    <>
      <Button
        variant="primary"
        size="M"
        onClick={() => setEditingElementId(element.id)}
      >
        View
      </Button>

      <ToolbarButton
        icon={<DownloadIcon />}
        tooltip="Download"
        onClick={handleDownload}
      />

      <ToolbarButton
        icon={<ImageMetadataIcon />}
        tooltip={
          !drawingMetadata?.prompts.nodes[0]
            ? 'No image details available'
            : 'Show image details'
        }
        onClick={() => toggleMetadata()}
        state={
          (!drawingMetadata?.prompts.nodes[0]
            ? 'disabled'
            : 'inactive') as ToolbarButtonState
        }
      />
    </>
  ) : (
    <>
      <Button
        variant="primary"
        size="M"
        onClick={() => setEditingElementId(element.id)}
        style={{
          display: 'flex',
          gap: '4px',
          alignItems: 'center',
        }}
      >
        <EditIcon /> Edit
      </Button>

      <ToolbarButton
        icon={<DownloadIcon />}
        tooltip="Download"
        onClick={handleDownload}
      />

      <ToolbarDivider />

      {shouldHaveAiMenu ? (
        <ToolbarMenuButton
          icon={<SparklesIcon />}
          tooltip="Create with AI"
          menu={
            <>
              <ContextMenuItem onClick={handleCreateImg2ImgElementFromDrawing}>
                Prompt
              </ContextMenuItem>
              <FeatureFlagged flag="IMG2MESH">
                <ContextMenuItem onClick={handleCreateMeshFromDrawing}>
                  3D model
                </ContextMenuItem>
              </FeatureFlagged>
              <FeatureFlagged flag="WORKBENCH_MULTI_VIEW">
                <ContextMenuItem onClick={handleCreateMultiViews}>
                  Multiviews
                </ContextMenuItem>
              </FeatureFlagged>
            </>
          }
        />
      ) : (
        <ToolbarButton
          icon={<SparklesIcon />}
          tooltip="Create with AI"
          onClick={handleCreateImg2ImgElementFromDrawing}
        />
      )}

      <ToolbarDivider />

      <ToolbarButton
        icon={<ImageMetadataIcon />}
        tooltip={
          !drawingMetadata?.prompts.nodes[0]
            ? 'No image details available'
            : 'Show image details'
        }
        onClick={() => toggleMetadata()}
        state={
          (!drawingMetadata?.prompts.nodes[0]
            ? 'disabled'
            : 'inactive') as ToolbarButtonState
        }
      />
    </>
  );

  return (
    <WorkbenchElementExtra
      workbenchId={workbenchId}
      element={element}
      position={[0, height / 2, 0]}
      pivot={element.y}
      handleAction={handleAction}
      menuItems={
        <DrawingContextMenuItems
          element={element}
          workbenchId={workbenchId}
          handleAction={handleAction}
        />
      }
    >
      {extra}
    </WorkbenchElementExtra>
  );
};

import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  useFetchOrCreateUserOrganizationDraftFolder,
  useDuplicateWorkbench,
  useWorkbenchesByFolderId,
  UserClientStateKeys,
  useCurrentUserClientStateByKey,
  useSetCurrentUserClientState,
} from '@vizcom/shared/data-access/graphql';
import { TEMPLATE_FOLDER_ID } from '@vizcom/shared/data-shape';
import {
  formatErrorMessage,
  addToast,
  Text,
  useSelectedOrganization,
  Button,
  CarretDownIcon,
  CarretUpIcon,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

import { TemplateFile } from '../Files/File/TemplateFile';

export const WorkbenchTemplates = () => {
  const savedState = useCurrentUserClientStateByKey(
    UserClientStateKeys.SavedState
  ) || { show_workbench_templates: true };
  const showWorkbenchTemplates = savedState['show_workbench_templates'];
  const [, updateState] = useSetCurrentUserClientState();
  const [contentHeight, setContentHeight] = useState<number | 'auto'>('auto');
  const contentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const { data, fetching } = useWorkbenchesByFolderId(
    TEMPLATE_FOLDER_ID,
    null,
    20
  );
  const { data: selectedOrganization } = useSelectedOrganization();
  const { data: draftFolder } = useFetchOrCreateUserOrganizationDraftFolder(
    selectedOrganization?.id
  );
  const [duplicateWorkbenchRes, duplicateWorkbench] = useDuplicateWorkbench();

  useEffect(() => {
    if (contentRef.current) {
      const height = contentRef.current.scrollHeight;
      setContentHeight(showWorkbenchTemplates ? height : 0);
    }
  }, [showWorkbenchTemplates, data]);

  const handleOpenTemplateFile = async (
    fileId: string,
    fileName: string,
    newTab?: boolean
  ) => {
    if (!draftFolder?.id || duplicateWorkbenchRes.fetching) {
      return;
    }

    const res = await duplicateWorkbench({
      input: {
        id: fileId,
        targetFolderId: draftFolder.id,
        name: fileName,
      },
    });

    if (res.error) {
      return addToast('Error while creating workbench template', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }

    const workbenchId = res.data?.duplicateWorkbench.workbench.id;
    if (workbenchId) {
      newTab
        ? window.open(paths.workbench.file(workbenchId), '_blank')
        : navigate(paths.workbench.file(workbenchId));
    }
  };

  return (
    <TemplatesContainer $open={showWorkbenchTemplates}>
      <TitleContainer>
        <Text block type="sh1">
          Get started with templates
        </Text>
        <Button
          variant="tertiary"
          onClick={async () =>
            await updateState({
              input: {
                key: UserClientStateKeys.SavedState,
                value: {
                  ...savedState,
                  show_workbench_templates: !showWorkbenchTemplates,
                },
              },
            })
          }
        >
          {showWorkbenchTemplates ? (
            <>
              <CarretUpIcon $size="L" color="default" />
              <Text type="b2">Hide Templates</Text>
            </>
          ) : (
            <>
              <CarretDownIcon $size="L" color="default" />
              <Text type="b2">Show Templates</Text>
            </>
          )}
        </Button>
      </TitleContainer>
      <AnimatedContent $height={contentHeight} ref={contentRef}>
        {!fetching && data?.length === 0 && (
          <Text block color="subtext">
            No template files
          </Text>
        )}

        <Container>
          <WorkbenchesContainer>
            {data?.map((workbench, index) => (
              <TemplateFile
                file={workbench}
                key={workbench.id}
                index={index}
                handleOpenTemplateFile={handleOpenTemplateFile}
              />
            ))}
          </WorkbenchesContainer>
        </Container>
      </AnimatedContent>
    </TemplatesContainer>
  );
};

const TemplatesContainer = styled.div<{ $open: boolean }>`
  border-radius: ${({ theme }) => theme.borderRadius.l};
  background: ${({ theme, $open }) =>
    $open ? theme.surface.primary : 'transparent'};
  padding: 8px;
  margin-bottom: 35px;
  transition: background-color 0.3s ease;
`;

const AnimatedContent = styled.div<{ $height: number | 'auto' }>`
  overflow: hidden;
  transition: height 0.3s ease;
  height: ${(props) =>
    typeof props.$height === 'number' ? `${props.$height}px` : props.$height};
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 ${({ theme }) => theme.spacing.s};
`;

const WorkbenchesContainer = styled.div`
  display: grid;
  grid-auto-columns: 180px;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  max-width: 100%;
  grid-auto-flow: column;
  padding: 8px 24px;
`;

const Container = styled.div`
  overflow-x: scroll;
  padding: 2px 0;
  margin-right: -16px;
  margin-left: -16px;

  ::-webkit-scrollbar {
    height: 4px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: ${({ theme }) => theme.borderRadius.s};
    background: ${({ theme }) => theme.text.subtext};
  }
`;

import { Banner, UpgradeIcon } from '@vizcom/shared-ui-components';

export const UpgradeEnterpriseBanner = () => {
  return (
    <Banner
      type="default"
      icon={<UpgradeIcon />}
      size="S"
      message="Need more editors?"
      secondaryMessage="Consider Enterprise plan"
      cta={{
        url: 'https://www.vizcom.ai/contact',
        target: '_blank',
        text: 'Contact sales',
      }}
    />
  );
};

import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import {
  addToast,
  browseForFiles,
  getDeviceFilePickerAcceptString,
  createCanvas,
  imageUrlToImageData,
  Text,
  resizeImageToPixelCount,
} from '@vizcom/shared-ui-components';

import { WORKBENCH_2D_STUDIO_IMAGE_MAX_PIXEL_COUNT } from '../../../constants';
import { useSyncQueueSynchronizer } from '../../../lib/SyncQueueSynchronizer';
import { useDrawingSyncedState } from '../../../lib/useDrawingSyncedState';
import { isDebugModeEnabled } from '../../compositeScene/compositeSceneEditor/utils/isDebugModeEnabled';
import { importBlobsToLayers } from '../../studio/WorkbenchStudioFileDropper';
import { MobileUploadPage } from '../MobileUploadPage';

interface ImageUploaderProps {
  workbenchId: string;
  drawingId: string;
  workbenchName: string;
}

export const ImageUpload = (props: ImageUploaderProps) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const syncQueueSynchronizer = useSyncQueueSynchronizer([props.workbenchId]);
  const { handleAction, drawing } = useDrawingSyncedState(
    props.drawingId,
    syncQueueSynchronizer
  );

  const debugMode = isDebugModeEnabled();

  const handleUploadFile = async () => {
    if (!drawing) {
      addToast('Drawing not found. Please try scanning again.', {
        type: 'danger',
      });
      return;
    }

    const accept = getDeviceFilePickerAcceptString({ imageOnly: true });
    const files = await browseForFiles({ accept });
    if (!files) {
      return;
    }
    setLoading(true);

    try {
      addToast('Files uploading to your workbench drawing');
      await importBlobsToLayers(files, drawing, handleAction);

      if (drawing.thumbnailPath && typeof drawing.thumbnailPath === 'string') {
        // Update thumbnail with new layer
        const newThumbnail = await updateThumbnailWithNewLayer(
          drawing.thumbnailPath,
          files[0]
        );

        handleAction({
          type: 'updateDrawingThumbnail',
          thumbnail: newThumbnail,
          final: true,
        });
      }
    } catch (error) {
      addToast('Error uploading files. Please try again.', {
        type: 'danger',
      });
      console.error('Upload error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MobileUploadPage
      title={`${props.workbenchName}${
        drawing?.name ? ` / ${drawing.name}` : ''
      }`}
      handleImportFiles={handleUploadFile}
      loading={loading}
    >
      {debugMode && drawing?.thumbnailPath && (
        <ImageContainer>
          <img
            src={drawing.thumbnailPath}
            alt={drawing.name || 'Drawing thumbnail'}
            style={{
              width: 'auto',
              height: 'auto',
              objectFit: 'contain',
              marginTop: '32px',
              borderRadius: theme.borderRadius.m,
            }}
          />
          <ThumbnailSubHeader>Current drawing</ThumbnailSubHeader>
        </ImageContainer>
      )}
    </MobileUploadPage>
  );
};

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const ThumbnailSubHeader = styled(Text)`
  font-size: 12px;
  color: ${(p) => p.theme.text.subtext};
`;

const updateThumbnailWithNewLayer = async (
  existingThumbnailUrl: string,
  newImageFile: File
) => {
  const thumbnailImageData = await imageUrlToImageData(existingThumbnailUrl);
  const { canvas, ctx } = createCanvas(
    thumbnailImageData.width,
    thumbnailImageData.height
  );

  ctx.putImageData(thumbnailImageData, 0, 0);

  const { image: resizedImage } = await resizeImageToPixelCount(
    newImageFile,
    WORKBENCH_2D_STUDIO_IMAGE_MAX_PIXEL_COUNT
  );

  const imageBitmap = await createImageBitmap(resizedImage);

  const scale = Math.min(
    canvas.width / imageBitmap.width,
    canvas.height / imageBitmap.height
  );
  const x = (canvas.width - imageBitmap.width * scale) / 2;
  const y = (canvas.height - imageBitmap.height * scale) / 2;

  ctx.globalAlpha = 1;
  ctx.drawImage(
    imageBitmap,
    x,
    y,
    imageBitmap.width * scale,
    imageBitmap.height * scale
  );

  return ctx.getImageData(0, 0, canvas.width, canvas.height);
};

import { UserPaletteDataFragment } from 'libs/shared/data-access/graphql/src/gql/graphql';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@vizcom/shared-ui-components';

import { CustomHtml } from '../../utils/CustomHtml';
import { EditPublishedPaletteModal } from './PublishPaletteModal';
import { useRemovePaletteFromLibrary } from './helpers';

export const PublishedPaletteDetails = ({
  palette,
  setShowDetails,
}: {
  palette: UserPaletteDataFragment;
  setShowDetails: (show: boolean) => void;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const { handleRemovePaletteFromLibrary } = useRemovePaletteFromLibrary(
    palette.id
  );

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setShowDetails(false);
    }
  };

  useEffect(() => {
    if (showEditModal) {
      document.removeEventListener('click', handleClickOutside);
    } else {
      document.addEventListener('click', handleClickOutside);
    }
    return () => document.removeEventListener('click', handleClickOutside);
  }, [showEditModal]);

  return (
    <CustomHtml position={[80, -10, 0]}>
      <PublishedDetailsContainer ref={ref}>
        <StyledButton
          variant="secondary"
          onClick={(e) => {
            e.stopPropagation();
            setShowEditModal(true);
          }}
        >
          Edit details
        </StyledButton>
        <StyledButton
          variant="secondary"
          onClick={(e) => {
            e.stopPropagation();
            handleRemovePaletteFromLibrary();
          }}
        >
          Remove from library...
        </StyledButton>
      </PublishedDetailsContainer>
      {showEditModal && (
        <EditPublishedPaletteModal
          onClose={() => {
            setShowEditModal(false);
            setShowDetails(false);
          }}
          element={palette}
        />
      )}
    </CustomHtml>
  );
};

const StyledButton = styled(Button)`
  justify-content: flex-start;
`;

const PublishedDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.theme.surface.primary};
  border-radius: ${(p) => p.theme.borderRadius.m};
  cursor: pointer;
  width: max-content;
  padding: ${(p) => p.theme.spacing.s};
`;

import { useMemo } from 'react';
import {
  EllipseIcon,
  LineIcon,
  RectangleIcon,
  ToolbarButtonState,
  ToolbarMenuButton,
} from '@vizcom/shared-ui-components';

import {
  useWorkbenchStudioState,
  WorkbenchTool,
  WorkbenchStudioToolType,
} from '../../studioState';
import { Hotkey } from '../../style';
import { ShapeSettings } from './ShapeSettings';

interface ShapeButtonProps {
  currentTool: WorkbenchStudioToolType;
  isMenuOpen: boolean;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
  onChangeTool: (tool: WorkbenchStudioToolType) => void;
}

export const SHAPE_TOOLS: WorkbenchTool[] = [
  {
    type: WorkbenchStudioToolType.Rectangle,
    icon: <RectangleIcon />,
    tooltip: 'Rectangle',
    hotkey: 'U',
  },
  {
    type: WorkbenchStudioToolType.Ellipse,
    icon: <EllipseIcon />,
    tooltip: 'Ellipse',
    hotkey: 'O',
  },
  {
    type: WorkbenchStudioToolType.Line,
    icon: <LineIcon />,
    tooltip: 'Line',
    hotkey: 'L',
  },
];

export const ShapeButton = ({
  currentTool,
  isMenuOpen,
  setIsMenuOpen,
  disabled,
  onChangeTool,
}: ShapeButtonProps) => {
  const studioState = useWorkbenchStudioState();
  const selectedShapeTool = useMemo(
    () =>
      SHAPE_TOOLS.find(({ type }) => type === studioState.tool) ||
      SHAPE_TOOLS[0],
    [studioState.tool]
  );
  const state = useMemo(
    () =>
      disabled
        ? ToolbarButtonState.DISABLED
        : SHAPE_TOOLS.some((tool) => tool.type === currentTool)
        ? ToolbarButtonState.ACTIVE
        : ToolbarButtonState.INACTIVE,
    [currentTool, disabled]
  );

  const onShapeToolSelect = () => {
    onChangeTool(selectedShapeTool.type);
  };

  return (
    <ToolbarMenuButton
      state={state}
      icon={selectedShapeTool.icon}
      tooltip={
        disabled ? (
          'Select a 2D layer to use this tool'
        ) : (
          <>
            {selectedShapeTool.tooltip}{' '}
            <Hotkey>{selectedShapeTool.hotkey}</Hotkey>
          </>
        )
      }
      onClick={() => !disabled && onShapeToolSelect()}
      menuTooltip="Shape Tools"
      showMenuIcon
      menu={
        <ShapeSettings
          onSelect={() => setIsMenuOpen(!isMenuOpen)}
          isUseCheckmarks
        />
      }
      controlledMenuState={[isMenuOpen, setIsMenuOpen]}
    />
  );
};

import styled, { useTheme } from 'styled-components';
import {
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  TextureSphereIcon,
  MeshSphereIcon,
} from '@vizcom/shared-ui-components';

type MaterialModeToggleProps = {
  materialMode: 'Mesh' | 'Texture';
  disabled: boolean;
  toggleMaterialMode: () => void;
};

export const MaterialModeToggle = ({
  materialMode,
  toggleMaterialMode,
  disabled,
}: MaterialModeToggleProps) => {
  const theme = useTheme();

  const handleToggleClick = () => {
    toggleMaterialMode();
  };

  return (
    <div
      style={{
        pointerEvents: disabled ? 'none' : 'all',
        maskImage: disabled
          ? 'linear-gradient( to bottom, rgba(21,21,23,1) -50%, transparent 100% )'
          : 'none',
      }}
    >
      {/* TODO: this component should be replaced with a HorizontalSwitcher */}
      <ToggleWrapper
        onClick={handleToggleClick}
        onPointerDown={(e) => e.stopPropagation()}
      >
        <RichTooltip trigger="hover" placement="top" {...theme.tooltip}>
          <RichTooltipTrigger>
            <div
              style={{
                color: materialMode === 'Texture' ? theme.text.body : 'inherit',
              }}
            >
              <TextureSphereIcon />
            </div>
          </RichTooltipTrigger>
          <RichTooltipContent
            $background="primary"
            style={{ color: theme.deprecated.white }}
          >
            <div>Texture</div>
          </RichTooltipContent>
        </RichTooltip>

        <RichTooltip trigger="hover" placement="top" {...theme.tooltip}>
          <RichTooltipTrigger>
            <div
              style={{
                color: materialMode === 'Mesh' ? theme.text.body : 'inherit',
              }}
            >
              <MeshSphereIcon />
            </div>
          </RichTooltipTrigger>
          <RichTooltipContent
            $background="primary"
            style={{ color: theme.deprecated.white }}
          >
            <div>Mesh</div>
          </RichTooltipContent>
        </RichTooltip>

        <Toggle $renderMode={materialMode === 'Texture' ? 'Texture' : 'Mesh'} />
      </ToggleWrapper>
    </div>
  );
};

export const ToggleWrapper = styled.div<{ $enabled?: boolean }>`
  display: flex;
  justify-content: space-evenly;
  width: 100px;
  will-change: transform;
  cursor: pointer;
  transition: 0.3s ease all;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  background-color: ${({ theme }) => theme.surface.secondary};

  > div {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: none;
    width: calc(50% - 8px);
    height: 32px;
    padding: 10px 0 10px 0;
    position: relative;
    border: none;
    transition: 0.2s ease all;
    font-weight: 600;

    &:focus {
      outline: none;
    }
  }
`;

export const Toggle = styled.span<{ $renderMode: 'Texture' | 'Mesh' }>`
  box-sizing: content-box;
  color: #151515;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  background-color: white;
  position: absolute;
  left: ${({ $renderMode }) =>
    $renderMode === 'Mesh' ? ['calc(50% - 4px)'] : '2px'};
  padding: ${({ $renderMode }) =>
    $renderMode === 'Mesh' ? '0 0 0 2px' : '0 2px 0 0'};
  top: 2px;
  width: 49%;
  height: calc(100% - 4px);
  z-index: -1;
  transition: 0.2s ease-out all;
  background-color: ${({ theme }) => theme.surface.tertiary};
`;

import * as Sentry from '@sentry/react';
import { Object3D } from 'three';
import { v4 as uuidv4 } from 'uuid';
import { drawingById, urqlClient } from '@vizcom/shared/data-access/graphql';
import { getLayerOrderKeys, sortByOrderKey } from '@vizcom/shared/js-utils';
import {
  addToast,
  applyMaskToImage,
  canvasToBlob,
  convertImageBlobFormat,
  downloadFile,
  imageToBlob,
  imageToCanvas,
} from '@vizcom/shared-ui-components';

import { getElementSize } from '../components/helpers';
import { findFirstFreeSlotInScene } from '../components/utils/freeSlotFinders';
import { masonryLayoutBestFit } from '../components/utils/layoutHelpers';
import { LayerPayload } from '../lib/actions/drawing/addLayer';
import { Drawing2dStudio } from '../lib/useDrawingSyncedState';
import { ClientSideWorkbenchElementData } from './clientState';
import { useWorkbenchSyncedState } from './useWorkbenchSyncedState';
import { GENERATED_IMAGES_MARGIN } from './utils';

export const handleCopyLayersToWorkbench = async (
  element: ClientSideWorkbenchElementData,
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'],
  scene: Object3D
) => {
  if (element.__typename !== 'Drawing') return;
  const { data } = await urqlClient.query(drawingById, {
    id: element.id,
  });
  const layers = data?.drawing?.layers.nodes ?? [];

  if (!layers.length) return;

  const columns = layers.length > 8 ? 4 : 2;

  const drawingSize = getElementSize(element);
  const sortedLayers = sortByOrderKey(
    layers as (typeof layers[0] & { orderKey: string })[]
  );

  // create a new drawing for each layer
  const newElements = sortedLayers
    .map((layer) => {
      return {
        layers: {
          nodes: [{ ...layer, visible: true, id: uuidv4(), parentId: null }],
        },
        thumbnailPath: layer.imagePath,
        id: uuidv4(),
        x: 0,
        y: 0,
        zIndex: 0,
        __typename: 'Drawing' as const,
        workbenchSizeRatio: element.workbenchSizeRatio,
        drawingHeight: element.drawingHeight,
        drawingWidth: element.drawingWidth,
        name: layer.name ?? '',
        updatedAt: '',
        backgroundColor: data?.drawing?.backgroundColor,
        backgroundVisible: data?.drawing?.backgroundVisible,
      };
    })
    .filter((el) => el.thumbnailPath);

  if (!newElements.length) return;

  const rows = Math.ceil(newElements.length / columns);
  const initialPosition = findFirstFreeSlotInScene(scene, {
    firstSlotX: element.x,
    firstSlotY: element.y,
    slotWidth: (columns / 2) * (drawingSize.width + GENERATED_IMAGES_MARGIN),
    slotHeight: rows * (drawingSize.height + GENERATED_IMAGES_MARGIN),
    maxElementPerLine: 4,
  });
  // position in a grid next to the drawing
  const positions = masonryLayoutBestFit(
    newElements.map(({ id }) => ({
      width: drawingSize.width,
      height: drawingSize.height,
      id,
      x: initialPosition[0],
      y: initialPosition[1],
    })),
    columns
  );

  handleAction({
    type: 'createElements',
    newElements: newElements.map((el, index) => {
      const { x, y } = positions[index];
      return {
        ...el,
        x,
        y,
        zIndex: element.zIndex + index + 1,
      };
    }),
  });

  return;
};

export const handleCopyDrawingImage = async (
  element: ClientSideWorkbenchElementData
) => {
  if (element.__typename !== 'Drawing') return;
  if (!element.thumbnailPath) return;

  return copyImageToClipboard(element.thumbnailPath);
};

export const copyImageToClipboard = async (
  imageData: ImageData | Blob | string | null | undefined,
  maskData?: ImageData
) => {
  if (!imageData) {
    return;
  }

  // Function to create the blob - this is async but not in a Promise constructor
  const createBlob = async (): Promise<Blob> => {
    let blob: Blob;
    if (maskData) {
      // If a mask is provided, we need to apply it to the image
      const { ctx, canvas } = await imageToCanvas(imageData);
      const maskedImageData = ctx.getImageData(
        0,
        0,
        canvas.width,
        canvas.height
      );
      applyMaskToImage(maskedImageData.data, maskData.data);
      ctx.putImageData(maskedImageData, 0, 0);
      blob = await canvasToBlob(canvas, 'image/png');
    } else if (imageData instanceof Blob) {
      blob = imageData;
    } else {
      blob = await imageToBlob(imageData);
    }

    blob = await convertImageBlobFormat(blob, 'image/png');
    return blob;
  };

  try {
    if (navigator.clipboard && 'write' in navigator.clipboard) {
      // Create a ClipboardItem with the blob promise
      // This is key for Safari compatibility - it allows Safari to handle the async operation
      const item = new ClipboardItem({
        'image/png': createBlob(), // Note: This is a Promise, not a Blob
      });

      // Write the ClipboardItem to the clipboard
      // This works in Safari because the async operation is handled by the browser
      await navigator.clipboard.write([item]);
      addToast('Image copied to clipboard');
    } else {
      throw new Error('Clipboard API not supported');
    }
  } catch (error) {
    console.error('Failed to copy image to clipboard:', error);
    Sentry.captureException(error);
    // Fallback mechanism for when clipboard operations fail
    try {
      // Provide user with a download option instead
      addToast(
        'Unable to copy to clipboard. Click here to download the image instead.',
        {
          cta: {
            text: 'Download image',
            action: async () => {
              const blob = await createBlob();
              downloadFile(blob, 'image', 'png');
            },
          },
        }
      );
    } catch (fallbackError) {
      Sentry.captureException(fallbackError);
      throw fallbackError;
    }
  }
};

const getGroupChildren = (
  groupId: string,
  drawing: Drawing2dStudio,
  existingGroupIdMap: Record<string, string> = {}
): string[] => {
  const children = drawing.layers.nodes.filter((l) => l.parentId === groupId);
  return children.reduce(
    (acc, child) => {
      if (child.isGroup) {
        const newGroupId = existingGroupIdMap[child.id] ?? uuidv4();
        existingGroupIdMap[child.id] = newGroupId;

        return [...acc, ...getGroupChildren(child.id, drawing)];
      }
      return [...acc, child.id];
    },
    children.map((c) => c.id)
  );
};

export const duplicateSelectedLayers = (
  selectedLayerIds: string[],
  drawing: Drawing2dStudio,
  handleAction: (action: any) => void
) => {
  if (selectedLayerIds.length === 0) return;

  const existingGroupIdMap = selectedLayerIds.reduce((acc, id) => {
    const layer = drawing.layers.nodes.find((l) => l.id === id);
    if (!layer) return acc;
    if (!layer.isGroup) return acc;

    acc[layer.id] = uuidv4();
    return acc;
  }, {} as Record<string, string>);

  const selectedGroupChildren = selectedLayerIds.reduce((acc, id) => {
    const layer = drawing.layers.nodes.find((l) => l.id === id);
    if (!layer) return acc;
    if (!layer.isGroup) return acc;

    const children = getGroupChildren(layer.id, drawing, existingGroupIdMap);

    return [...acc, ...children];
  }, [] as string[]);

  const newLayersCache = [] as Drawing2dStudio['layers']['nodes'];
  const newLayers = [
    ...new Set([...selectedLayerIds, ...selectedGroupChildren]),
  ]
    .map((id) => {
      const layer = drawing.layers.nodes.find((l) => l.id === id);
      if (!layer) return null;

      const orderKey = getLayerOrderKeys(
        [...drawing.layers.nodes, ...newLayersCache],
        layer.id
      )[0];

      const newLayer = {
        ...layer,
        id: existingGroupIdMap[layer.id] ?? uuidv4(),
        name: `${layer.name} copy`,
        image: layer.imagePath,
        parentId: layer.parentId
          ? existingGroupIdMap[layer.parentId] ?? layer.parentId
          : null,
        orderKey:
          layer.parentId && existingGroupIdMap[layer.parentId]
            ? layer.orderKey
            : orderKey,
      };

      newLayersCache.push(newLayer);
      return newLayer;
    })
    .filter(Boolean);

  handleAction({
    type: 'updateBulkLayers',
    newLayers: newLayers as LayerPayload[],
  });
};

import { animated, useSpring } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { TransitionStatus } from 'react-transition-group';
import styled, { css, useTheme } from 'styled-components';
import {
  useCurrentUserClientStateByKey,
  UserClientStateKeys,
  useSetCurrentUserClientState,
} from '@vizcom/shared/data-access/graphql';
import { boundNumber } from '@vizcom/shared/js-utils';
import {
  FeatureFlagged,
  FloatingPanel,
  InlineFlex,
  PlusIcon,
  ToolbarButton,
  useKeyboardShortcut,
} from '@vizcom/shared-ui-components';

import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../lib/useDrawingSyncedState';
import { useIsWorkbenchViewer } from '../../lib/utils';
import { AssetLibrary } from '../AssetLibrary/AssetLibrary';
import { Layers } from './Layers';
import {
  PanelSectionContainer,
  PanelSectionPicker,
} from './WorkbenchStudioCombinedUI';
import { PromptHistoryItem } from './history/WorkbenchStudioHistory';
import { ActiveLayerChangeOperation } from './lib/useActiveLayer';
import { Hotkey, Overlay } from './style';
import { Layout } from './types';
import { addGroup as addGroupImpl, addLayer as addLayerImpl } from './utils';

const UI_MIN_SIZE = 342;

type Props = {
  hidden: boolean;
  disabled: boolean;
  state: TransitionStatus;
  drawing: Drawing2dStudio;
  activeLayerId: string | undefined;
  historyOpen: boolean;
  viewerVisibility: Record<string, boolean>;
  uiRatio: number;
  collapsedGroupStates: Record<string, boolean>;
  setCollapsedGroupStates: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  setUiRatio: React.Dispatch<React.SetStateAction<number>>;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  setViewerVisibility: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  setActiveLayerId: (
    id: string | undefined,
    operation?: ActiveLayerChangeOperation
  ) => void;
  onCreateDrawingsFromImage: (
    previews: { preview: ArrayBuffer | Blob; name?: string }[],
    offset?: {
      x: number;
      y: number;
    }
  ) => void;
  setSelectedPromptHistoryItem: (item: PromptHistoryItem) => void;
};

export const WorkbenchStudioLayers = ({
  hidden,
  disabled,
  state,
  drawing,
  activeLayerId,
  historyOpen,
  viewerVisibility,
  uiRatio,
  collapsedGroupStates,
  setCollapsedGroupStates,
  setViewerVisibility,
  handleAction,
  setActiveLayerId,
  onCreateDrawingsFromImage,
  setUiRatio,
  setSelectedPromptHistoryItem,
}: Props) => {
  const theme = useTheme();
  const [spring, api] = useSpring(() => ({}));
  const isViewer = useIsWorkbenchViewer();
  const ref = useRef<HTMLDivElement>(null);
  const userPreferredLayout = useCurrentUserClientStateByKey(
    UserClientStateKeys.StudioLayout
  );
  const layout: Layout = isViewer
    ? 'default'
    : userPreferredLayout || 'default';
  const [, updateState] = useSetCurrentUserClientState();

  const addLayer = () =>
    addLayerImpl({
      drawing: drawing,
      activeLayerId: activeLayerId,
      handleAction: handleAction,
      anchorCollapsed: collapsedGroupStates[activeLayerId?.split('/')[0] || ''],
    });

  const addGroup = () =>
    addGroupImpl({
      drawing: drawing,
      activeLayerId: activeLayerId,
      handleAction: handleAction,
    });

  useKeyboardShortcut('n', () => {
    if (isViewer) return;
    const id = addLayer();
    setActiveLayerId(id);
  });

  useKeyboardShortcut(
    'g',
    () => {
      if (isViewer) return;
      const id = addGroup();
      if (id) setActiveLayerId(id);
    },
    {
      ctrl: true,
    }
  );

  useEffect(() => {
    if (isViewer) return;
    if (layout !== 'default') {
      ref.current!.style.bottom = '100px';
      return;
    }

    // slide up and down to make space for history panel
    if (historyOpen) {
      api.start({
        to: {
          bottom: 330,
        },
        config: { duration: 150 },
        delay: 200,
      });
    } else {
      api.start({
        to: {
          bottom: 100,
        },
        config: { duration: 150 },
        delay: 150,
      });
    }
  }, [historyOpen, layout]);

  useEffect(() => {
    const onWindowResize = () => {
      if (!ref.current) return;
      if (layout !== 'stacked') return;
      const newHeight = window.innerHeight * uiRatio - 101;
      ref.current.style.height = `${newHeight}px`;
    };

    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, [layout, uiRatio]);

  useLayoutEffect(() => {
    if (!ref.current) return;
    if (layout !== 'stacked') {
      ref.current.style.height = 'auto';
      return;
    }

    const newHeight = window.innerHeight * uiRatio - 101;
    ref.current.style.height = `${newHeight}px`;
  }, [uiRatio, layout]);

  const bindResize = useDrag(({ event, down, tap, last }) => {
    const clientY = 'clientY' in event ? event.clientY : 0;
    const minRatio = UI_MIN_SIZE / window.innerHeight;
    const newRatio = boundNumber(
      minRatio,
      1 - clientY / window.innerHeight,
      1 - minRatio
    );

    if (last) {
      updateState({
        input: {
          key: UserClientStateKeys.StudioRatio,
          value: newRatio,
        },
      });
    }

    if (tap || !down) {
      return;
    }

    setUiRatio(newRatio);
    const newHeight = window.innerHeight * newRatio - 101;
    ref.current!.style.height = `${newHeight}px`;
  });

  const [tab, setTab] = useState<'layers' | 'assets'>('layers');

  return (
    <>
      <StudioTabs
        $state={state}
        $disabled={disabled}
        $hidden={hidden}
        $layout={layout}
        style={spring}
        ref={ref}
      >
        <>
          <PanelSectionContainer>
            <InlineFlex $justifyContent="space-between">
              <InlineFlex $gap={16}>
                <PanelSectionPicker
                  $active={tab === 'layers'}
                  onClick={() => setTab('layers')}
                >
                  Layers
                </PanelSectionPicker>
                <FeatureFlagged flag="ASSETS_LIBRARY">
                  <PanelSectionPicker
                    $active={tab === 'assets'}
                    onClick={() => setTab('assets')}
                  >
                    Assets
                  </PanelSectionPicker>
                </FeatureFlagged>
              </InlineFlex>

              {tab === 'layers' && (
                <LayersHeaderButtons
                  isViewer={isViewer}
                  activeLayerId={activeLayerId}
                  addGroup={addGroup}
                  addLayer={addLayer}
                  setActiveLayerId={setActiveLayerId}
                />
              )}
            </InlineFlex>
          </PanelSectionContainer>

          {layout === 'stacked' && (
            <Resizer {...bindResize()}>
              <div />
            </Resizer>
          )}

          {disabled && (
            <Overlay
              style={{ borderRadius: theme.borderRadius.l }}
              onClick={() => setSelectedPromptHistoryItem(undefined)}
            />
          )}

          {drawing && tab === 'layers' && (
            <Layers
              drawing={drawing}
              handleAction={handleAction}
              activeLayer={activeLayerId}
              setActiveLayer={setActiveLayerId}
              viewerVisibility={viewerVisibility}
              setViewerVisibility={setViewerVisibility}
              onCreateDrawingsFromImage={onCreateDrawingsFromImage}
              collapsedGroupStates={collapsedGroupStates}
              setCollapsedGroupStates={setCollapsedGroupStates}
            />
          )}

          {drawing && tab === 'assets' && (
            <AssetLibrary workbenchId={drawing.workbenchId} />
          )}
        </>
      </StudioTabs>
    </>
  );
};

export const LayersHeaderButtons = ({
  isViewer,
  activeLayerId,
  addGroup,
  addLayer,
  setActiveLayerId,
}: {
  isViewer: boolean;
  activeLayerId: string | undefined;
  addGroup: () => string | undefined;
  addLayer: () => string;
  setActiveLayerId: (id: string) => void;
}) => {
  if (isViewer) return null;

  // New Group Button
  if (activeLayerId && activeLayerId?.split('/').length > 1) {
    return (
      <ToolbarButton
        buttonProps={{
          size: 's',
          style: {
            padding: '5px 12px',
            fontSize: '12px',
          },
        }}
        icon={<div>Group</div>}
        tooltip={
          <>
            New group <Hotkey>Ctrl + G</Hotkey>
          </>
        }
        tooltipOptions={{
          placement: 'right',
        }}
        onClick={(e) => {
          (e.target as HTMLButtonElement).blur();
          const id = addGroup();
          if (id) setActiveLayerId(id);
        }}
      />
    );
  }

  // New Layer Button
  return (
    <ToolbarButton
      icon={<PlusIcon />}
      tooltip={
        <>
          New layer <Hotkey>N</Hotkey>
        </>
      }
      tooltipOptions={{
        placement: 'right',
        padding: 15,
      }}
      onClick={(e) => {
        (e.target as HTMLButtonElement).blur();
        const id = addLayer();
        setActiveLayerId(id);
      }}
    />
  );
};

const Resizer = styled.div`
  position: absolute;
  cursor: ns-resize;
  align-self: center;
  top: 12px;
  margin-top: -32px;
  padding: 8px 0;

  > div {
    width: 222px;
    height: 4px;
    background-color: ${({ theme }) => theme.surface.tertiary};
    border-radius: ${({ theme }) => theme.borderRadius.l};
  }
`;

const StudioTabs = styled(animated(FloatingPanel))<{
  $state: TransitionStatus;
  $disabled: boolean;
  $hidden: boolean;
  $layout: Layout;
}>`
  position: absolute;
  z-index: 10000001;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  bottom: 100px;
  width: 250px;

  transition: transform 0.5s ease-in-out;
  opacity: ${({ $hidden }) => ($hidden ? 0 : 1)};

  ${({ theme, $layout, $state }) => {
    if ($layout === 'default') {
      return css`
        top: calc(1rem + 66px);
        left: 14px;
        animation: ${$state === 'entering'
            ? theme.animation.slideInFromLeft
            : 'none'}
          0.5s ease;
        transform: ${$state === 'entered' || $state === 'entering'
          ? 'translateX(0)'
          : 'translateX(calc(-100% - 14px))'};
      `;
    } else if ($layout === 'stacked') {
      return css`
        height: calc(calc(100% * 0.45) - 100px);
        right: 14px;
        animation: ${$state === 'entering'
            ? theme.animation.slideInFromRight
            : 'none'}
          0.5s ease;
        transform: ${$state === 'entered' || $state === 'entering'
          ? 'translateX(0)'
          : 'translateX(calc(100% + 14px))'};
      `;
    }
  }}

  pointer-events: ${({ $disabled, $hidden }) =>
    $disabled || $hidden ? 'none' : 'all'};
  * {
    pointer-events: ${({ $hidden }) => ($hidden ? 'none !important' : 'all')};
  }
`;

import { createPortal } from '@react-three/fiber';
import { PropsWithChildren } from 'react';

import { VizcomRenderingOrderEntry } from '../../utils/threeRenderingOrder';
import { BlendMode } from '../types';
import { useLayersCompositor } from './context';
import { CompositedLayerContentUserData } from './types';

export const LayerContent = ({
  children,
  id,
  opacity,
  blendMode,
  visible,
  zIndex,
  type,
}: PropsWithChildren<{
  id: string;
  opacity: number;
  blendMode: string;
  visible: boolean;
  zIndex: number;
  type: CompositedLayerContentUserData['type'];
}>) => {
  const layerCompositor = useLayersCompositor();

  const userData: CompositedLayerContentUserData = {
    type: type,
    id,
    opacity,
    blendMode: blendMode as BlendMode,
    visible,
    vizcomRenderingOrder: [
      {
        zIndex,
      } satisfies VizcomRenderingOrderEntry,
    ],
  };

  return createPortal(
    <group userData={userData}>{children}</group>,
    layerCompositor.scene
  );
};

import { useState } from 'react';
import { addToast, useStableCallback } from '@vizcom/shared-ui-components';

import { useSyncQueueSynchronizer } from '../../../lib/SyncQueueSynchronizer';
import { useWorkbenchSyncedState } from '../../../lib/useWorkbenchSyncedState';
import { MAX_Z_POSITION } from '../../helpers';
import { importFilesToWorkbench } from '../../utils/importFiles';
import { MobileUploadPage } from '../MobileUploadPage';

export const ImageUpload = (props: {
  workbenchId: string;
  workbenchName: string;
  targetPosition: [number, number];
}) => {
  const [loading, setLoading] = useState(false);

  const syncQueueSynchronizer = useSyncQueueSynchronizer([props.workbenchId]);
  const { handleAction, elements } = useWorkbenchSyncedState(
    props.workbenchId,
    syncQueueSynchronizer
  );

  const handleImportFiles = useStableCallback(async () => {
    try {
      setLoading(true);

      const zValue = Math.max(...elements.map((element) => element.zIndex));
      const maxZIndex = isFinite(zValue) ? zValue : MAX_Z_POSITION / 2;

      await importFilesToWorkbench({
        handleAction,
        maxZIndex,
        targetPosition: props.targetPosition,
      });

      addToast('Files uploading to your workbench');
    } catch (error) {
      console.error('Error uploading files:', error);
      addToast('Failed to upload files. Please try again.', {
        type: 'danger',
      });
    } finally {
      setLoading(false);
    }
  });

  return (
    <MobileUploadPage
      title={props.workbenchName}
      handleImportFiles={handleImportFiles}
      loading={loading}
    />
  );
};

import { useMemo } from 'react';
import styled from 'styled-components';
import { Color, Material, MeshPhysicalMaterial } from 'three';
import { CompositeSceneFullData } from '@vizcom/shared/data-access/graphql';
import { Text, Divider } from '@vizcom/shared-ui-components';

import { useCompositeSceneSyncedState } from '../../../lib/useCompositeSceneSyncedState';
import { useCompositeSceneEditorContext } from '../compositeSceneEditor/context';
import { getCompositeSceneNodeUUID } from '../compositeSceneEditor/utils/compositeSceneNodeUUID';
import {
  Container,
  Content,
  Field,
  Frame,
  HeaderPrimary,
  Section,
} from '../compositeSceneMenu/style';
import { MaterialPreviewIcon } from './MaterialPreviewIcon';
import { MaterialPropertyField } from './MaterialPropertyField';
import { MaterialOverrides, MaterialPropertyFieldType } from './types';

const color = new Color();

export const MaterialConfiguration = ({
  material: baseMaterial,
  compositeScene,
  handleAction,
}: {
  material: Material;
  compositeScene: CompositeSceneFullData;
  handleAction: ReturnType<typeof useCompositeSceneSyncedState>['handleAction'];
}) => {
  const { selected } = useCompositeSceneEditorContext();
  const rootNode = useMemo(() => {
    if (!selected || !compositeScene) {
      return null;
    }

    const { rootId } = selected.userData;
    const rootNode = compositeScene.compositeSceneElements.nodes.find(
      (match) => match.id === rootId
    );

    return rootNode;
  }, [selected, compositeScene]);
  const material = useMemo<MeshPhysicalMaterial | null>(() => {
    if (!compositeScene || !selected || !rootNode) {
      return null;
    }

    const material = baseMaterial.clone() as MeshPhysicalMaterial;
    const selectedNodeUUID = getCompositeSceneNodeUUID(selected);
    const materialOverrides = rootNode.meshes[selectedNodeUUID]?.material;

    if (!materialOverrides) {
      return material;
    }

    Object.entries(materialOverrides).forEach(([key, value]) => {
      // @ts-expect-error allow using a string key
      material[key] = value;
    });

    if (materialOverrides.color) {
      material.color = new Color(materialOverrides.color);
    }

    return material;
  }, [baseMaterial, compositeScene, selected, rootNode]);

  const updateMaterial = (materialOverrides: Partial<MaterialOverrides>) => {
    if (!selected || !rootNode) {
      return;
    }

    const selectedNodeUUID = getCompositeSceneNodeUUID(selected);
    const currentState = rootNode.meshes[selectedNodeUUID] ?? {};
    const updatedState = {
      ...currentState,
      material: {
        ...(currentState.material ?? {}),
        ...materialOverrides,
      },
    };

    handleAction({
      type: 'updateCompositeSceneElement',
      id: selected.userData.rootId,
      meshes: {
        ...rootNode.meshes,
        [selectedNodeUUID]: updatedState,
      },
    });
  };

  if (!material) {
    return null;
  }

  return (
    <Wrapper>
      <Container>
        <Frame $actions="top">
          <HeaderPrimary>
            <Text type="sh2">Edit Material</Text>
          </HeaderPrimary>
          <Divider />
          <Content>
            <Section>
              <CenterContent>
                <MaterialPreviewWrapper>
                  <MaterialPreviewIcon
                    material={material}
                    $size={72}
                    $fallbackColor={material.color.getHexString()}
                  />
                </MaterialPreviewWrapper>
              </CenterContent>
            </Section>
            <Section>
              <Field>
                <MaterialPropertyField
                  label="Color"
                  fieldType={MaterialPropertyFieldType.Color}
                  value={material.color.getHex()}
                  onChange={(value) => {
                    updateMaterial({
                      color: `#${color.setHex(value).getHexString()}`,
                    });
                  }}
                />
                <MaterialPropertyField
                  label="Metalness"
                  value={material.metalness}
                  onChange={(value) => updateMaterial({ metalness: value })}
                />
                <MaterialPropertyField
                  label="Roughness"
                  value={material.roughness}
                  onChange={(value) => updateMaterial({ roughness: value })}
                />
                <MaterialPropertyField
                  label="Opacity"
                  value={material.opacity}
                  onChange={(value) => {
                    updateMaterial({
                      opacity: value,
                      transparent: value < 1.0,
                    });
                  }}
                />
              </Field>
            </Section>
          </Content>
        </Frame>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  bottom: 0;
  left: 0;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  overflow: hidden;
  transform: translateX(calc(-100% - 4px)) translateY(-4px);
  z-index: 100;
`;

const MaterialPreviewWrapper = styled.div`
  position: relative;
  display: block;
  flex: 1;
  height: 120px;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  background-color: ${({ theme }) => theme.surface.secondary};

  & > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 1em;
  text-align: center;
`;

const CenterContent = ({ children }: React.PropsWithChildren) => {
  return <StyledContainer>{children}</StyledContainer>;
};

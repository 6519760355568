import { useStore } from '@react-three/fiber';
import { useMemo, useRef } from 'react';
import { MathUtils, Vector2 } from 'three';

import { useStableCallback } from '../../../../../../../../shared/ui/components/src';
import { symmetryTransform } from '../../lib/symmetry';
import { ToolSettings, Symmetry } from '../../studioState';
import { BrushSegment } from '../../types';
import {
  getCubicSplineSegmentCoefficients,
  evalCubicPoly,
} from './BrushSegmentCurve';
import { useStrokeRenderer } from './useStrokeRenderer';

const auxA = new Vector2();
const auxB = new Vector2();
const auxC = new Vector2();
const auxD = new Vector2();

export const useBrushStroke = ({
  drawingSize,
  color,
  symmetry,
  toolSettings,
}: {
  drawingSize: [number, number];
  color: string;
  symmetry: Symmetry;
  toolSettings: ToolSettings;
}) => {
  const store = useStore();
  const {
    toolAngle,
    toolAspect,
    toolHardness,
    toolSize,
    toolPressureSize,
    toolPressureOpacity,
  } = toolSettings;
  const angle = useMemo(() => MathUtils.degToRad(-toolAngle), [toolAngle]);
  const segmentsRef = useRef<BrushSegment[]>([]);
  const distanceToNextInstance = useRef<number>(0);

  const strokeRenderer = useStrokeRenderer(drawingSize);

  const getTexture = useStableCallback(() => strokeRenderer.getTexture());
  const getRenderTarget = useStableCallback(() =>
    strokeRenderer.getRenderTarget()
  );

  const addSegments = useStableCallback((segments, last) => {
    let i = Math.max(0, segmentsRef.current.length - 1);
    segmentsRef.current.push(...segments);

    if (!segmentsRef.current.length) return;

    const symmetryOrigin = new Vector2(
      symmetry.origin[0] * drawingSize[0],
      symmetry.origin[1] * drawingSize[1]
    );

    const symmetryDirection = new Vector2(
      Math.cos(Math.PI / 2 - symmetry.rotation),
      Math.sin(Math.PI / 2 - symmetry.rotation)
    );

    //`symmetryDirection` is used to determine the side of the symmetry axis we want to use when `allowCrossingAxis` is false.
    //Depending on the the side of the initial point, we invert the direction or not.
    const firstPoint = new Vector2(...segmentsRef.current[0].startPosition);
    if (firstPoint.clone().sub(symmetryOrigin).cross(symmetryDirection) < 0) {
      symmetryDirection.negate();
    }
    const negativeSymmetryDirection = symmetryDirection.clone().negate();

    while (segmentsRef.current[i]) {
      const segment = segmentsRef.current[i];
      const previousSegment = segmentsRef.current[i - 1];
      const nextSegment = segmentsRef.current[i + 1];
      if (!nextSegment && !last) {
        // do not use last segments if we don't have the next point yet, this is required to do the curve interpolation
        break;
      }

      const prev = auxA;
      const start = auxB;
      const end = auxC;
      const next = auxD;

      start.fromArray(segment.startPosition);
      end.fromArray(segment.endPosition);
      //A Catmull-Rom curve doesn't go through the first and last points.
      //We need to provide additional prev and next points when they are not defined.
      if (previousSegment?.startPosition) {
        prev.fromArray(previousSegment?.startPosition);
      } else {
        prev.subVectors(start, end).add(start);
      }
      if (nextSegment?.endPosition) {
        next.fromArray(nextSegment?.endPosition);
      } else {
        next.subVectors(end, start).add(end);
      }

      for (let s = 0; s < (symmetry.enabled ? 2 : 1); s++) {
        if (s === 1) {
          symmetryTransform(symmetry, drawingSize, start);
          symmetryTransform(symmetry, drawingSize, end);
          symmetryTransform(symmetry, drawingSize, prev);
          symmetryTransform(symmetry, drawingSize, next);
        }

        const { px, py } = getCubicSplineSegmentCoefficients(
          prev,
          start,
          end,
          next
        );

        const spacedRatios = [];
        const spacing =
          !toolPressureOpacity && toolHardness > 95
            ? 1
            : toolSize * 0.05 * toolAspect;

        //skip costly computations if the segment is a single point
        if (start.equals(end)) {
          spacedRatios.push(0);
          distanceToNextInstance.current = Math.max(
            1,
            spacing * (toolPressureSize ? segment.startPressure : 1.0)
          );
        } else {
          /*
          Compute the ratios for properly spaced divisions.
          On a cubic curve, equally spaced ratios don't return equally spaced points.
          It is not possible to directly compute these points. We find them by subdividing the curve.
          https://pomax.github.io/bezierinfo/#tracing

          This can't be part of the cubic curve code because we account for pen pressure.
          */
          const subdivisions = 100;
          const maxSubdivisions = last ? subdivisions + 1 : subdivisions; // include last subdivision, if at end
          let prevPoint = [evalCubicPoly(px, 0), evalCubicPoly(py, 0)];
          for (let i = 1; i < maxSubdivisions; i++) {
            const point = [
              evalCubicPoly(px, i / subdivisions),
              evalCubicPoly(py, i / subdivisions),
            ];
            const dx = point[0] - prevPoint[0];
            const dy = point[1] - prevPoint[1];
            const subdivisionLength = Math.sqrt(dx * dx + dy * dy);
            let remainingSubdivisionLength = subdivisionLength;
            //For loop rather than while to ensure maximum iterations.
            while (
              remainingSubdivisionLength > distanceToNextInstance.current
            ) {
              //Advance position
              remainingSubdivisionLength -= distanceToNextInstance.current;
              const subdivisionRatio =
                1 - remainingSubdivisionLength / subdivisionLength;
              const ratio = (i - 1 + subdivisionRatio) / subdivisions;
              spacedRatios.push(ratio);

              //Find where the next instance will be. Must be least 1px from current position.
              distanceToNextInstance.current = Math.max(
                1,
                spacing *
                  (toolPressureSize
                    ? MathUtils.lerp(
                        segment.startPressure,
                        segment.endPressure,
                        ratio
                      )
                    : 1.0)
              );
            }
            distanceToNextInstance.current -= remainingSubdivisionLength;
            prevPoint = point;
          }
        }

        strokeRenderer.render(
          toolAspect,
          color,
          spacedRatios,
          toolHardness / 100,
          1,
          {
            start: segment.startPressure,
            end: segment.endPressure,
          },
          px,
          py,
          angle + store.getState().camera.rotation.z,
          toolSize,
          symmetry.allowCrossingAxis || !symmetry.enabled, //if symmetry is disabled we don't want to limit the stroke
          symmetryOrigin,
          s === 0 ? symmetryDirection : negativeSymmetryDirection,
          toolPressureOpacity,
          toolPressureSize
        );
      }
      i++;
    }
  });

  const reset = useStableCallback(() => {
    strokeRenderer.clear();
    segmentsRef.current = [];
    distanceToNextInstance.current = 0;
  });

  return {
    getTexture,
    getRenderTarget,
    addSegments,
    reset,
    segmentsRef,
  };
};

import { omit } from 'lodash';
import {
  urqlClient,
  CreateLayerMutation,
  drawingById,
  UpdateDrawingMutation,
} from '@vizcom/shared/data-access/graphql';
import {
  assertExists,
  genTopOrderKey,
  Sortable,
} from '@vizcom/shared/js-utils';
import { imageDataToBlob } from '@vizcom/shared-ui-components';

import { SyncedActionType } from '../../SyncedAction';
import { ClientSideWorkbenchElementData } from '../../clientState';
import { Drawing2dStudio } from '../../useDrawingSyncedState';
import { elementById } from '../../utils';
import { LayerPayload } from '../drawing/addLayer';
import { cachedLayerImagesByUrl } from '../drawing/updateLayer';

export const AddLayerToDrawingAction: SyncedActionType<
  ClientSideWorkbenchElementData[],
  {
    type: 'addLayerToDrawing';
    drawingId: string;
    layer: Omit<LayerPayload, 'orderKey' | 'image'> & {
      image: ImageData;
    };
  }
> = {
  type: 'addLayerToDrawing',
  optimisticUpdater: ({ payload }, elements) => {
    const sourceElement = elementById(elements, payload.drawingId);
    if (sourceElement?.__typename === 'Drawing') {
      sourceElement.thumbnailPath = payload.layer.image;
    }
  },
  remoteUpdater: async ({ payload }) => {
    const imageBlob = await imageDataToBlob(payload.layer.image);

    // Fetch drawing to get order key
    const drawingRes = await urqlClient.query(drawingById, {
      id: payload.drawingId,
    });
    if (drawingRes.error) {
      throw new Error(
        `Error while fetching drawing, please retry. ${
          drawingRes.error.graphQLErrors[0]?.message ?? drawingRes.error.message
        }`
      );
    }
    const drawing = drawingRes.data?.drawing;
    assertExists(drawing, 'Drawing not found');

    const nodesWithOrderKeys = drawing.layers.nodes.filter(
      (n) => typeof n.orderKey === 'string'
    ) as Sortable[];
    const orderKey = genTopOrderKey(nodesWithOrderKeys);

    // Create layer
    const [layerRes] = await Promise.all([
      urqlClient.mutation(CreateLayerMutation, {
        input: {
          layer: {
            ...omit(payload.layer, 'image', 'createdAt', 'updatedAt'),
            drawingId: payload.drawingId,
            imagePath: imageBlob,
            orderKey,
          },
        },
      }),
      urqlClient.mutation(UpdateDrawingMutation, {
        id: payload.drawingId,
        patch: {
          thumbnailPath: imageBlob,
        },
      }),
    ]);

    // Cache image
    const imagePath = layerRes.data?.createLayer?.layer?.imagePath;
    if (imagePath) cachedLayerImagesByUrl[imagePath] = imageBlob;

    if (layerRes?.error) {
      throw new Error(
        `Error while creating layer, please retry. ${
          layerRes.error.graphQLErrors[0]?.message ?? layerRes.error.message
        }`
      );
    }
  },
  sideEffects: ({ payload }) => [
    {
      syncQueueName: 'drawing',
      optimisticUpdater: (drawing: Drawing2dStudio) => {
        if (drawing.id === payload.drawingId) {
          const orderKey = genTopOrderKey(drawing.layers.nodes);
          const existingLayer = drawing.layers.nodes.find(
            (l) => l.id === payload.layer.id
          );

          if (!existingLayer) {
            const newLayer = {
              ...omit(payload.layer, 'image', 'createdAt', 'updatedAt'),
              id: payload.layer.id,
              drawingId: payload.drawingId,
              orderKey,
              imagePath: payload.layer.image,
              meshPath: null,
              updatedAt: '0',
              createdAt: '0',
            };
            drawing.layers.nodes.push(newLayer);
          }
        }
      },
    },
  ],
};

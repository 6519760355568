import styled from 'styled-components';
import {
  Checkbox,
  Text,
  useCanDrawWithFinger,
  useHasStylus,
  MenuItem,
} from '@vizcom/shared-ui-components';

export const WorkbenchSettingsMenu = () => {
  const hasStylus = useHasStylus();
  const { canDrawWithFinger, setDrawWithFinger, wasDrawWithFingerUpdated } =
    useCanDrawWithFinger();
  const isFingerDrawingDisabled = !hasStylus && !wasDrawWithFingerUpdated;

  return (
    <MenuItem label="Preferences">
      <CheckboxAndLabel>
        <Checkbox
          checked={canDrawWithFinger}
          disabled={isFingerDrawingDisabled}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            setDrawWithFinger(!canDrawWithFinger);
          }}
        />
        <LabelContainer>
          <Text>Allow painting with finger</Text>
        </LabelContainer>
      </CheckboxAndLabel>
    </MenuItem>
  );
};

const CheckboxAndLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px 16px;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  user-select: none;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

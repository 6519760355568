import styled, { useTheme } from 'styled-components';
import {
  AutoplayVideo,
  Button,
  CarretDownIcon,
  Clickable,
  OnboardingStepName,
  OnboardingTourTooltipContent,
  RichTooltip,
  RichTooltipTrigger,
  Select,
  SparklesIcon,
  Text,
  useCompleteOnboardingStep,
  useOnboardingStatus,
} from '@vizcom/shared-ui-components';

import aiActionsEducationalVideo from '../../../assets/videos/ai-actions-educational-tooltip.mp4';
import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../../lib/useDrawingSyncedState';
import {
  StepContainer,
  LeftSection,
  RightSection,
} from '../../utils/OnboardingMultiStep';
import { EnhanceAction } from './EnhanceAction';
import { RemoveBackgroundAction } from './RemoveBackgroundAction';

export const AiActions = ({
  drawing,
  handleAction,
  getCompositedImage,
}: {
  drawing: Drawing2dStudio;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  getCompositedImage: (() => ImageData) | undefined;
}) => {
  const { borderRadius, spacing } = useTheme();

  const aiActionsOnboarded = useOnboardingStatus(OnboardingStepName.AiActions);
  const studioOnboarded = useOnboardingStatus(
    OnboardingStepName.BackInfiniteCanvas
  );

  const showOnboardingTooltip = !aiActionsOnboarded && studioOnboarded;

  const completeOnboardingStep = useCompleteOnboardingStep();
  const handleCompleteOnboarding = () => {
    completeOnboardingStep(OnboardingStepName.AiActions);
  };

  return (
    <RichTooltip isOpen={showOnboardingTooltip} placement="left" padding={88}>
      <Select
        onSelectElement={() => {}}
        selectedOptionIndex={-1}
        customSelectedTriggerContent={({ isOpen }) => (
          <RichTooltipTrigger>
            <Button size="icon" variant="bare" style={{ width: '46px' }}>
              <SparklesIcon color="primary" />
              <CarretDownIcon
                style={{
                  transform: isOpen ? 'rotate(180deg)' : 'none',
                  transition: 'transform 0.2s',
                }}
              />
            </Button>
          </RichTooltipTrigger>
        )}
        style={{ flex: 'unset' }}
      >
        <AiActionsContainer>
          <EnhanceAction
            index={0}
            handleAction={handleAction}
            drawing={drawing}
            getCompositedImage={getCompositedImage}
          />
          <RemoveBackgroundAction
            index={1}
            handleAction={handleAction}
            drawing={drawing}
            getCompositedImage={getCompositedImage}
          />
        </AiActionsContainer>
      </Select>
      <OnboardingTourTooltipContent>
        <StepContainer>
          <LeftSection>
            <AutoplayVideo
              src={aiActionsEducationalVideo}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: `calc(${borderRadius.l} - 2px) 0 0 calc(${borderRadius.l} - 2px)`,
              }}
            />
          </LeftSection>
          <RightSection>
            <Text type="sh2">Introducing: AI Actions</Text>
            <Text type="b1" style={{ margin: `${spacing.m} 0` }}>
              Enhance images and remove background from the new AI Actions
              button in the create panel.
            </Text>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                size="S"
                variant="primary"
                onClick={handleCompleteOnboarding}
              >
                Got it
              </Button>
            </div>
          </RightSection>
        </StepContainer>
      </OnboardingTourTooltipContent>
    </RichTooltip>
  );
};

export const AiActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

export const AiAction = styled(Clickable)`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;

  ${({ theme }) => theme.typography.b2}
`;

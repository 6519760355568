import { useMemo, useState } from 'react';
import { assertExists } from '@vizcom/shared/js-utils';
import { useKeyboardShortcut } from '@vizcom/shared-ui-components';

import { ImageStudioShortcuts, filterHotkeys } from './constants';
import { Hotkey, HotkeyCategoryName, HotkeysPropsDeps } from './types';

export const useRegisterHotkey = (hotkey: Hotkey, opts: HotkeysPropsDeps) => {
  const trigger = hotkey.trigger;
  assertExists(trigger, 'Trigger must be defined to register the hotkey');

  useKeyboardShortcut(
    trigger.combination,
    () => trigger.callback(opts),
    trigger.modifiers
  );
};

export const useRegister2dStudioHotkeys = (opts: HotkeysPropsDeps) => {
  useRegisterHotkey(ImageStudioShortcuts['2dStudio'].brush, opts);
  useRegisterHotkey(ImageStudioShortcuts['2dStudio'].paintBucket, opts);

  useRegisterHotkey(ImageStudioShortcuts['2dStudio'].moveTool, opts);
  useRegisterHotkey(ImageStudioShortcuts['2dStudio'].transformTool, opts);

  useRegisterHotkey(ImageStudioShortcuts['2dStudio'].boxShapeTool, opts);
  useRegisterHotkey(ImageStudioShortcuts['2dStudio'].eclipseShapeTool, opts);
  useRegisterHotkey(ImageStudioShortcuts['2dStudio'].lineShapeTool, opts);

  useRegisterHotkey(ImageStudioShortcuts['2dStudio'].increaseToolSize, opts);
  useRegisterHotkey(ImageStudioShortcuts['2dStudio'].decreaseToolSize, opts);

  useRegisterHotkey(ImageStudioShortcuts['2dStudio'].selectionTools, opts);

  useRegisterHotkey(ImageStudioShortcuts['2dStudio'].eraserTool, opts);
  useRegisterHotkey(ImageStudioShortcuts['2dStudio'].magicEraserTool, opts);
};

// shortcut management UI

export const useSearchShortcuts = () => {
  const [searchQuery, setSearchQuery] = useState('');

  const onNewSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchQuery(value);
  };

  return {
    searchQuery,
    onNewSearchQuery,
  };
};

export const useStudioHotkeyRow = (opts: {
  setHotkey: (value: Hotkey | null) => void;
}) => {
  const { setHotkey } = opts;

  const onMouseEnter = (hotkey: Hotkey) => {
    setHotkey(hotkey);
  };

  const onMouseLeave = () => {
    setHotkey(null);
  };

  return {
    onMouseEnter,
    onMouseLeave,
  };
};

export const useShortcuts = () => {
  const search = useSearchShortcuts();

  const filteredHotkeys = useMemo(
    () => filterHotkeys(ImageStudioShortcuts, search.searchQuery),
    [search.searchQuery]
  );

  return {
    getCategory: (category: HotkeyCategoryName) =>
      Object.values(filteredHotkeys[category]),
    search,
  };
};

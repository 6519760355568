import { useDeferredValue } from 'react';
import { Texture } from 'three';
import { v4 as uuid } from 'uuid';
import {
  assertExists,
  filterExists,
  genBottomOrderKey,
  genOrderKeys,
  genTopOrderKey,
  getLayerOrderKeys,
  sortByOrderKey,
  sortSingleDepthByOrderKey,
} from '@vizcom/shared/js-utils';
import {
  applyMaskToImage,
  imageToImageData,
} from '@vizcom/shared-ui-components';

import { LayerPayload } from '../../lib/actions/drawing/addLayer';
import { copyImageToClipboard } from '../../lib/drawingUtils';
import {
  Drawing2dStudio,
  DrawingLayer,
  useDrawingSyncedState,
} from '../../lib/useDrawingSyncedState';
import { useImageTextures } from '../../lib/useImageTexture';
import {
  useImageDataTexture,
  xFlipImageBuffer,
  yFlipImageBuffer,
} from '../helpers';
import { LayersCompositorApi } from './LayersCompositor/context';
import { MAX_LAYER_DEPTH } from './constants';

export const WORKBENCH_2D_STUDIO_ANIMATION_DURATION_MS = 1000;

export function swap(array: number[], moveIndex: number, toIndex: number) {
  const item = array[moveIndex];
  const length = array.length;
  const diff = moveIndex - toIndex;

  if (diff > 0) {
    return [
      ...array.slice(0, toIndex),
      item,
      ...array.slice(toIndex, moveIndex),
      ...array.slice(moveIndex + 1, length),
    ];
  } else if (diff < 0) {
    const targetIndex = toIndex + 1;
    return [
      ...array.slice(0, moveIndex),
      ...array.slice(moveIndex + 1, targetIndex),
      item,
      ...array.slice(targetIndex, length),
    ];
  }
  return array;
}

export const useLayerTexture = (layer: DrawingLayer) => {
  // When another client edit a layer, its URL will change, while loading this new image, we want to keep the old image
  // on the screen. To do this we use `useDeferredValue` to keep the old URL while the Suspense operation triggered by `useImageTextures`
  // finishes loading the new image. We only do this if `imagePath` is an URL or a Blob, if it's an ImageData, we always want to use the last version
  // to prevent any flashes of the old texture
  const deferredImagePath = useDeferredValue(layer.imagePath);

  const imageDataTexture = useImageDataTexture(
    layer.imagePath instanceof ImageData
      ? layer.imagePath
      : deferredImagePath instanceof ImageData
      ? deferredImagePath
      : undefined
  );

  const [_image] = useImageTextures(
    typeof deferredImagePath === 'string' || deferredImagePath instanceof Blob
      ? [deferredImagePath]
      : []
  );

  return (imageDataTexture ?? _image) as Texture | undefined;
};

export function imageDataIsBlank(imageData: ImageData) {
  for (let i = 0; i < imageData.data.length; i += 4) {
    if (
      imageData.data[i] !== 255 ||
      imageData.data[i + 1] !== 255 ||
      imageData.data[i + 2] !== 255
    ) {
      return false;
    }
  }
  return true;
}

export function imageDataIsTransparent(imageData: ImageData) {
  for (let i = 0; i < imageData.data.length; i += 4) {
    if (imageData.data[i + 3] !== 0) {
      return false;
    }
  }
  return true;
}

export function getNestedGroupVisibility(
  parentId: string | null | undefined,
  layers: Drawing2dStudio['layers']['nodes']
): boolean {
  if (!parentId) return false;
  const parent = layers.find((l) => l.id === parentId);
  if (!parent) return false;
  if (!parent.visible) return true;
  return getNestedGroupVisibility(parent.parentId, layers);
}

export const getParentVisibility = (
  drawing: Drawing2dStudio,
  layerId: string
): boolean => {
  const layer = drawing.layers.nodes.find((l) => l.id === layerId);
  if (!layer) return false;

  const parent = drawing.layers.nodes.find((l) => l.id === layer.parentId);
  if (!parent) return layer.visible;

  if (parent.visible === false) return false;

  return getParentVisibility(drawing, parent.id);
};

export function getHierarchicalParentIds(
  layerIds: string[],
  layers: Drawing2dStudio['layers']['nodes']
) {
  const parentIds = layerIds
    .map((id) => {
      const layer = layers.find((l) => l.id === id);
      if (!layer) return null;
      return layer.parentId;
    })
    .filter(filterExists);

  return new Set(
    layerIds.flatMap((id): string[] => {
      const layer = layers.find((l) => l.id === id);
      if (!layer || !layer.parentId) return [];
      return [
        layer.parentId,
        ...getHierarchicalParentIds([layer.parentId], layers),
      ];
    }, parentIds)
  );
}

export function layerIsNestedChild(
  id: string,
  ids: string[],
  layers: Drawing2dStudio['layers']['nodes']
) {
  if (ids.includes(id)) {
    return true;
  }

  const parent = layers.find((l) => l.id === id);

  if (!parent) {
    return false;
  }

  if (!parent.parentId) {
    return false;
  }

  return layerIsNestedChild(parent.parentId, ids, layers);
}

export function getLayerDepth(
  parentId: string | null | undefined,
  layers: Drawing2dStudio['layers']['nodes']
): number {
  if (!parentId) return 0;
  const parent = layers.find((l) => l.id === parentId);
  if (!parent) return 0;
  return 1 + getLayerDepth(parent.parentId, layers);
}

export const getGroupChildLayers = (
  drawing: Drawing2dStudio,
  groupId: string
) => {
  return drawing.layers.nodes.reduce((acc, layer) => {
    if (layer.parentId === groupId) {
      if (layer.isGroup) {
        acc.push(...getGroupChildLayers(drawing, layer.id));
      } else {
        acc.push(layer);
      }
    }
    return acc;
  }, [] as Drawing2dStudio['layers']['nodes']);
};

export function addLayer({
  drawing,
  activeLayerId,
  handleAction,
  layerData,
  anchorCollapsed,
}: {
  drawing: Drawing2dStudio;
  activeLayerId: string | undefined;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  layerData?: Partial<LayerPayload>;
  anchorCollapsed?: boolean;
}) {
  const id = uuid();
  const selectedIds = activeLayerId?.split('/');
  const anchorId =
    selectedIds && selectedIds.length > 1
      ? selectedIds[selectedIds.length - 1]
      : activeLayerId;
  const anchor = drawing.layers.nodes.find((l) => l.id === anchorId);

  const selectedGroupId =
    anchor?.isGroup && !anchorCollapsed ? anchorId : anchor?.parentId;

  const orderKey =
    anchor?.isGroup && !anchorCollapsed
      ? genTopOrderKey(
          drawing.layers.nodes
            .filter((l) => l.parentId === selectedGroupId)
            .map((l) => ({ orderKey: l.orderKey, id: l.id }))
        )
      : getLayerOrderKeys(drawing.layers.nodes, anchorId)[0];

  handleAction({
    type: 'addLayer',
    layer: {
      id: id,
      name: `Layer ${drawing.layers.nodes.length + 1}`,
      visible: true,
      opacity: 1,
      blendMode: 'normal',
      isGroup: false,
      fill: '',
      orderKey,
      parentId: selectedGroupId || null,
      ...layerData,
    },
  });

  return id;
}

export function addGroup({
  drawing,
  activeLayerId,
  handleAction,
  layerData,
}: {
  drawing: Drawing2dStudio;
  activeLayerId: string | undefined;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  layerData?: Partial<LayerPayload>;
}) {
  const id = uuid();
  const selectedIds = activeLayerId?.split('/');
  const anchorId =
    selectedIds && selectedIds.length > 1
      ? selectedIds[selectedIds.length - 1]
      : activeLayerId;
  const parentGroup = drawing.layers.nodes.find(
    (l) => l.id === selectedIds?.[0]
  )?.parentId;

  const parentDepth = parentGroup
    ? getLayerDepth(parentGroup, drawing.layers.nodes)
    : 0;

  if (parentDepth >= MAX_LAYER_DEPTH) {
    return;
  }

  const selectedWithoutNestedChildren = selectedIds?.filter((id) => {
    const layer = drawing.layers.nodes.find((l) => l.id === id);
    if (!layer) return false;
    if (!layer.parentId) return true;

    return !layerIsNestedChild(
      layer.parentId,
      selectedIds,
      drawing.layers.nodes
    );
  });

  const newOrderKeys = genOrderKeys(selectedWithoutNestedChildren?.length || 1);

  handleAction?.({
    type: 'updateBulkLayers',
    newLayers: [
      {
        id: id,
        name: `Group ${
          drawing?.layers.nodes.filter((layer) => layer.isGroup).length + 1
        }`,
        visible: true,
        opacity: 1,
        isGroup: true,
        blendMode: 'normal',
        fill: '',
        orderKey: getLayerOrderKeys(drawing.layers.nodes, anchorId)[0],
        parentId:
          parentGroup && selectedWithoutNestedChildren?.includes(parentGroup)
            ? null
            : parentGroup || null,
        ...layerData,
      },
    ],
    layerUpdates: selectedWithoutNestedChildren?.map((selectedId, i) => ({
      id: selectedId,
      parentId: id,
      orderKey: newOrderKeys[i],
    })),
  });

  return id;
}

export const moveLayersToTop = (
  activeLayer: string | undefined,
  drawing: Drawing2dStudio,
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction']
) => {
  if (!activeLayer) return;

  if (activeLayer.split('/').length > 1) {
    const layers = activeLayer.split('/').map((id) => {
      const layer = drawing.layers.nodes.find((l) => l.id === id);
      assertExists(layer, 'Could not find layer to move to top');
      return layer;
    });

    const newLayers = [] as {
      id: string;
      orderKey: string;
    }[];

    const movedLayers = sortSingleDepthByOrderKey(layers).map((layer, i) => {
      const siblings = drawing.layers.nodes.filter(
        (l) => l.parentId === layer.parentId
      );
      const newSiblings = drawing.layers.nodes.filter((l) =>
        newLayers.find((nl) => nl.id === l.id)
      );

      const orderKey = genTopOrderKey([
        ...siblings.map((l) => ({
          ...l,
          parentId: null,
        })),
        ...newSiblings,
      ]);
      const layerUpdate = {
        id: layer.id,
        orderKey: orderKey + i,
      };
      newLayers.push(layerUpdate);
      return layerUpdate;
    });

    handleAction({
      type: 'updateBulkLayers',
      layerUpdates: movedLayers,
    });

    return;
  }

  const layer = drawing.layers.nodes.find((l) => l.id === activeLayer);

  handleAction({
    type: 'updateLayer',
    id: activeLayer,
    data: {
      orderKey: genTopOrderKey(drawing.layers.nodes, layer?.parentId),
    },
  });
};

export const moveLayersToBottom = (
  activeLayer: string | undefined,
  drawing: Drawing2dStudio,
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction']
) => {
  if (!activeLayer) return;

  if (activeLayer.split('/').length > 1) {
    const layers = activeLayer.split('/').map((id) => {
      const layer = drawing.layers.nodes.find((l) => l.id === id);
      assertExists(layer, 'Could not find layer to move to bottom');
      return layer;
    });

    const newLayers = [] as {
      id: string;
      orderKey: string;
    }[];

    const movedLayers = sortSingleDepthByOrderKey(layers).map((layer, i) => {
      const siblings = drawing.layers.nodes.filter(
        (l) => l.parentId === layer.parentId
      );
      const newSiblings = drawing.layers.nodes.filter((l) =>
        newLayers.find((nl) => nl.id === l.id)
      );

      const orderKey = genBottomOrderKey([
        ...siblings.map((l) => ({
          ...l,
          parentId: null,
        })),
        ...newSiblings,
      ]);
      const layerUpdate = {
        id: layer.id,
        orderKey: orderKey + i,
      };
      newLayers.push(layerUpdate);
      return layerUpdate;
    });

    handleAction({
      type: 'updateBulkLayers',
      layerUpdates: movedLayers,
    });

    return;
  }

  const layer = drawing.layers.nodes.find((l) => l.id === activeLayer);

  handleAction({
    type: 'updateLayer',
    id: activeLayer,
    data: {
      orderKey: genBottomOrderKey(drawing.layers.nodes, layer?.parentId),
    },
  });
};

export const mergeDown = async (
  drawing: Drawing2dStudio,
  id: string,
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'],
  layersCompositor: LayersCompositorApi
) => {
  const sortedLayers = sortByOrderKey(drawing.layers.nodes);
  const currentLayerIndex = sortedLayers.findIndex((l) => l.id === id);
  const layer = sortedLayers[currentLayerIndex];
  const nextLayer = sortedLayers[currentLayerIndex + 1];

  assertExists(nextLayer, 'Could not find next layer to merge down');

  if (nextLayer.isGroup) return;
  if (nextLayer.parentId !== layer.parentId) return;

  handleAction({
    type: 'mergeLayers',
    targetLayerId: nextLayer.id,
    sourceLayersId: [layer.id],
    mergedImage: layersCompositor.getCompositedImage({
      onlyDisplayLayersIds: [nextLayer.id, layer.id],
      forceFullOpacityForLayersId: [nextLayer.id],
    }),
  });
};

export const mergeSelected = async (
  drawing: Drawing2dStudio,
  activeLayer: string | undefined,
  layersCompositor: LayersCompositorApi,
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction']
) => {
  const selectedLayers = activeLayer?.split('/');
  if (!selectedLayers) return;

  const sortedLayers = sortByOrderKey(drawing.layers.nodes)
    .filter((l) => selectedLayers.includes(l.id))
    .map((l) => ({
      ...l,
      parentId: null,
    }));

  const groupChildLayers = sortedLayers.reduce((acc, layer) => {
    if (layer.isGroup) {
      acc.push(...getGroupChildLayers(drawing, layer.id));
    }
    return acc;
  }, [] as Drawing2dStudio['layers']['nodes']);

  const target = sortedLayers.shift();

  assertExists(target, 'Could not find target layer to merge into');

  const sources = [...new Set([...sortedLayers, ...groupChildLayers])];

  handleAction({
    type: 'mergeLayers',
    targetLayerId: target.id,
    sourceLayersId: sources.map((l) => l.id),
    mergedImage: layersCompositor.getCompositedImage({
      onlyDisplayLayersIds: [...sources, target].map((l) => l.id),
      forceFullOpacityForLayersId: [target.id],
    }),
  });
};

const flipLayers = (
  flipFunction: (imageBuffer: Uint8ClampedArray, width: number) => void
) => {
  return async (
    drawing: Drawing2dStudio,
    activeLayer: string | undefined,
    handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction']
  ) => {
    const selectedLayers = activeLayer?.split('/');
    if (!selectedLayers) return;

    // Get all layers to flip, including group children (recursive)
    const layersToFlip = selectedLayers.reduce((acc, id) => {
      const layer = drawing.layers.nodes.find((l) => l.id === id);
      if (!layer) return acc;

      if (layer.isGroup) {
        const childLayers = getGroupChildLayers(drawing, layer.id);
        acc.push(...childLayers);
      } else {
        acc.push(layer);
      }
      return acc;
    }, [] as Drawing2dStudio['layers']['nodes']);

    // Flip layers
    for (const layer of layersToFlip) {
      if (!layer.imagePath || layer.metadata3D) {
        continue;
      }

      const imageData = await imageToImageData(layer.imagePath);
      flipFunction(imageData.data, drawing.width);

      if (imageData) {
        handleAction?.({
          type: 'updateLayer',
          id: layer.id,
          data: { image: imageData },
        });
      }
    }
  };
};

export const horizontalMirrorLayers = flipLayers(xFlipImageBuffer);
export const verticalMirrorLayers = flipLayers(yFlipImageBuffer);

export const cutSelection = async (
  layer: DrawingLayer,
  maskData: ImageData,
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction']
) => {
  if (!layer.imagePath || layer.metadata3D) {
    return;
  }
  const imageData = await imageToImageData(layer.imagePath);
  if (imageData) {
    await copyImageToClipboard(imageData, maskData);
    applyMaskToImage(imageData.data, maskData.data, true);

    handleAction?.({
      type: 'updateLayer',
      id: layer.id,
      data: { image: imageData },
    });
  }
};

export const getSelectedVisibleLayers = (
  drawing: Drawing2dStudio,
  selection: string[]
) => {
  return (
    selection
      .map((id) => drawing.layers.nodes.find((l) => l.id === id && l.visible))
      .filter(filterExists)
      .filter((layer) => layer.imagePath || layer.isGroup)
      .reduce((acc, layer) => {
        if (layer.isGroup) {
          const children = getGroupChildLayers(drawing, layer.id);

          acc.push(
            ...children.filter((child) => child.visible && child.imagePath)
          );
        } else {
          acc.push(layer);
        }
        return acc;
      }, [] as typeof drawing.layers.nodes)
      .filter(filterExists) || []
  );
};

import * as Sentry from '@sentry/react';
import { AutoPromptType } from 'libs/shared/data-access/graphql/src/gql/graphql';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  autoPrompt,
  publishTrackingEvent,
  useWorkbenchPalette,
} from '@vizcom/shared/data-access/graphql';
import { AutoPromptEventName } from '@vizcom/shared/data-shape';
import {
  OnboardingStepName,
  applyMaskToImage,
  imageDataToBlob,
  useCompleteOnboardingStep,
  useStableCallback,
} from '@vizcom/shared-ui-components';

import { imageDataIsBlank, imageDataIsTransparent } from './utils';

type Props = {
  workbenchPaletteId: string | null | undefined;
  inputType: AutoPromptType;
  getCompositeImage:
    | (() => Promise<ImageData | null>)
    | (() => ImageData | null);
  setPrompt: (prompt: string, targetId?: string) => void;
  getSelectionImage: () => ImageData | undefined;
};

export const useAutoPrompt = ({
  workbenchPaletteId,
  inputType,
  getCompositeImage,
  setPrompt,
  getSelectionImage,
}: Props) => {
  const [isGeneratingPrompt, setIsGeneratingPrompt] = useState(false);
  const abortControllerRef = useRef<AbortController | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const completeOnboardingStep = useCompleteOnboardingStep();

  const { data: palette } = useWorkbenchPalette(workbenchPaletteId);

  const cancelAutoPrompt = useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    setIsGeneratingPrompt(false);
  }, []);

  const generate = useStableCallback(async (targetId?: string) => {
    setIsGeneratingPrompt(true);

    const imageData = await getCompositeImage();
    const maskData = getSelectionImage();

    if (
      !imageData ||
      imageDataIsTransparent(imageData) ||
      imageDataIsBlank(imageData)
    ) {
      setIsGeneratingPrompt(false);
      return;
    }

    if (maskData) {
      const maskedImage = applyMaskToImage(imageData.data, maskData.data);

      if (maskedImage) {
        imageData.data.set(maskedImage);
      }
    }

    const compositeImage = await imageDataToBlob(imageData, {
      maxPixels: 512 * 512,
      format: 'image/jpg',
    });

    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    const tags = palette?.tags?.join(', ') ?? '';

    try {
      publishTrackingEvent({
        type: AutoPromptEventName.AUTOPROMPT_TRIGGERED,
        data: {},
      });
      const text = await autoPrompt(
        { data: compositeImage, inputType },
        {
          fetch: (input, init) => {
            init = init || {};
            init.signal = abortController.signal;
            return fetch(input, init);
          },
        }
      );

      setPrompt(text + ' ' + tags, targetId);

      publishTrackingEvent({
        type: AutoPromptEventName.AUTOPROMPT_SUCCESS,
        data: {
          prompt: text + ' ' + tags,
        },
      });
    } catch (e: any) {
      if (!abortController.signal.aborted) {
        console.error(e);
        Sentry.captureException(e);
      }
    } finally {
      setIsGeneratingPrompt(false);
      completeOnboardingStep(OnboardingStepName.NewDrawingAutoPrompt);
    }
  });

  const triggerAutoPrompt = useCallback(
    async (delay?: number, targetId?: string) => {
      if (delay) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
          generate(targetId);
        }, delay);
      } else {
        generate(targetId);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getCompositeImage, setPrompt, workbenchPaletteId]
  );

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return { triggerAutoPrompt, cancelAutoPrompt, isGeneratingPrompt };
};

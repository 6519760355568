import { memoize } from 'lodash';
import {
  ACESFilmicToneMapping,
  Box3,
  Group,
  Mesh,
  MeshStandardMaterial,
  Object3D,
  PerspectiveCamera,
  Scene,
  Texture,
  Vector3,
} from 'three';
import { assertUnreachable } from '@vizcom/shared/js-utils';

import ENV_PRESET_STUDIO from '../../assets/environments/studio_small_09_1k.hdr.jpg';
import { getImperativeSnapshot3D } from '../compositeScene/compositeSceneEditor/utils/getImperativeSnapshot3D';
// @ts-ignore

const MAX_IMPORTED_MESH_SIZE_BYTES = 50 * 1024 * 1024; // 50MB
export const checkImportedMeshFileSize = (file: Pick<File, 'size'>) => {
  if (file.size > MAX_IMPORTED_MESH_SIZE_BYTES) {
    throw new Error(
      `File too large, maximum allowed size is ${Math.floor(
        MAX_IMPORTED_MESH_SIZE_BYTES / 1024 / 1024
      )}MB.`
    );
  }
};

const lazyGltfLoader = memoize(() =>
  import('three/examples/jsm/loaders/GLTFLoader').then(
    (module) => new module.GLTFLoader()
  )
);
const lazyFbxLoader = memoize(() =>
  import('three/examples/jsm/loaders/FBXLoader').then(
    (module) => new module.FBXLoader()
  )
);
const lazyStlLoader = memoize(() =>
  import('three/examples/jsm/loaders/STLLoader').then(
    (module) => new module.STLLoader()
  )
);
const lazyObjLoader = memoize(() =>
  import('three/examples/jsm/loaders/OBJLoader').then(
    (module) => new module.OBJLoader()
  )
);

const lazyGltfExporter = memoize(() =>
  import('three/examples/jsm/exporters/GLTFExporter').then(
    (module) => new module.GLTFExporter()
  )
);
const lazyObjExporter = memoize(() =>
  import('three/examples/jsm/exporters/OBJExporter').then(
    (module) => new module.OBJExporter()
  )
);
const lazyStlExporter = memoize(() =>
  import('three/examples/jsm/exporters/STLExporter').then(
    (module) => new module.STLExporter()
  )
);
const lazyUsdzExporter = memoize(() =>
  import('three/examples/jsm/exporters/USDZExporter').then(
    (module) => new module.USDZExporter()
  )
);

export const is3DModelFile = (filename: string) => {
  const name = filename.toLowerCase();
  return (
    name.endsWith('.gltf') ||
    name.endsWith('.glb') ||
    name.endsWith('.fbx') ||
    name.endsWith('.obj') ||
    name.endsWith('.stl')
  );
};

function cleanInvalidTextures(object: Object3D) {
  object.traverse((child) => {
    if ((child as Mesh).isMesh) {
      const material = (child as Mesh).material;

      // Handle both single and array materials
      const materials = Array.isArray(material) ? material : [material];

      materials.forEach((mat) => {
        for (const key in mat) {
          const prop = (mat as unknown as any)[key] as Texture;
          if (prop && prop.isTexture) {
            const texture = prop;
            if (!texture.source || !texture.source.data) {
              // Set the texture to null if it is invalid
              (mat as unknown as any)[key] = null;
              mat.needsUpdate = true; // Ensure the material updates
            }
          }
        }
      });
    }
  });
}

export const loadObject3DFromBlob = async (file: Blob) => {
  const filename = file.name.toLowerCase();

  let object: Object3D | null = null;

  if (filename.endsWith('.gltf') || filename.endsWith('.glb')) {
    const loader = await lazyGltfLoader();
    const gltf = await loader.parseAsync(await file.arrayBuffer(), '');
    object = gltf.scene;
  } else if (filename.endsWith('.fbx')) {
    const loader = await lazyFbxLoader();
    object = loader.parse(await file.arrayBuffer(), '');
  } else if (filename.endsWith('.stl')) {
    const loader = await lazyStlLoader();
    const geometry = loader.parse(await file.arrayBuffer());
    const material = new MeshStandardMaterial({
      color: 0xcccccc,
      metalness: 0.75,
      roughness: 0.5,
    });

    object = new Group();
    object.add(new Mesh(geometry, material));
  } else if (filename.endsWith('.obj')) {
    const loader = await lazyObjLoader();
    object = loader.parse(await file.text());
  } else {
    throw new Error(
      'This model is not supported, use a .gltf, .glb, .fbx, .obj or .stl file.'
    );
  }

  cleanInvalidTextures(object);

  return object;
};

export const loadGltfFromUrl = async (url: string) => {
  const content = await fetch(url).then((res) => res.arrayBuffer());
  const loader = await lazyGltfLoader();
  const gltf = await loader.parseAsync(content, '');
  return gltf.scene;
};

const emptyVector3 = new Vector3();
const box3 = new Box3();
/**
 * NOTE Scale model to fit into 1x1x1 box
 *      Prevents super-scaled and micro-scaled models
 */
export const scaleObject3dToBox = (
  object: Object3D,
  boxSideSize = 1.0
): {
  center: Vector3;
} => {
  const meshSize = emptyVector3.set(0.0, 0.0, 0.0);

  box3.makeEmpty();
  box3.expandByObject(object, true);
  box3.getSize(meshSize);

  const maxSize = Math.max(meshSize.x, meshSize.y, meshSize.z);

  object.scale.multiplyScalar(boxSideSize / maxSize);

  // recenter mesh
  box3.makeEmpty();
  box3.expandByObject(object, true);
  box3.getCenter(object.position);
  object.position.multiplyScalar(-1.0);

  return {
    center: box3.getCenter(new Vector3()),
  };
};

export type ExportFormat = 'gltf' | 'glb' | 'obj' | 'stl' | 'usdz';

export const exportObject3d = async (
  object: Object3D,
  format: ExportFormat = 'glb'
): Promise<{
  result: ArrayBuffer | string;
  resultType: 'application/octet-stream' | 'text/plain';
}> => {
  let result: ArrayBuffer | string;
  let resultType: 'application/octet-stream' | 'text/plain';

  if (format === 'glb' || format === 'gltf') {
    const exporter = await lazyGltfExporter();

    result = (await exporter.parseAsync(object, {
      binary: format === 'glb',
    })) as ArrayBuffer;

    resultType = 'application/octet-stream';
  } else if (format === 'obj') {
    const exporter = await lazyObjExporter();

    result = exporter.parse(object);
    resultType = 'text/plain';
  } else if (format === 'stl') {
    const exporter = await lazyStlExporter();

    result = exporter.parse(object);
    resultType = 'text/plain';
  } else if (format === 'usdz') {
    const exporter = await lazyUsdzExporter();

    result = (await exporter.parseAsync(object)).buffer;
    resultType = 'application/octet-stream';
  } else {
    assertUnreachable(format);
  }

  return { result, resultType };
};

export const createObject3dThumbnail = async (
  object: Object3D,
  width: number,
  height: number,
  cameraSphericalCoordinates?: [number, number, number]
) => {
  const scene = new Scene();
  scene.add(object);

  const box = new Box3();
  const v3 = new Vector3();
  const camera = new PerspectiveCamera(40, width / height, 0.01, 10000);
  if (cameraSphericalCoordinates) {
    camera.position.setFromSphericalCoords(...cameraSphericalCoordinates);
  } else {
    box.setFromObject(object);
    box.getSize(v3);
    v3.multiplyScalar(2);
    camera.position.copy(v3);
  }
  box.setFromObject(object);
  box.getCenter(v3);
  camera.lookAt(v3);

  return getImperativeSnapshot3D(scene, camera, {
    environmentUrl: ENV_PRESET_STUDIO,
    width,
    height,
    type: 'png',
    toneMapping: ACESFilmicToneMapping,
  });
};

import { ThreeEvent, useThree } from '@react-three/fiber';
import { useDrag } from '@use-gesture/react';
import { WorkbenchContentFragment } from 'libs/shared/data-access/graphql/src/gql/graphql';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { OrthographicCamera, Vector2 } from 'three';
import {
  useCreateDrawingStyleReferences,
  useRemoveStyleReferences,
  useWorkbenchStyleReferences,
} from '@vizcom/shared/data-access/graphql';
import {
  addToast,
  ContextMenu,
  ContextMenuDivider,
  ContextMenuItem,
  eventTargetIsInput,
  formatErrorMessage,
  imageDataToBlob,
} from '@vizcom/shared-ui-components';

import { WorkbenchContentRenderingOrder } from '../WorkbenchContent';
import { ClientSideWorkbenchElementData } from '../lib/clientState';
import { downloadWorkbenchDrawings } from '../lib/downloadWorkbenchDrawings';
import { useWorkbenchSyncedState } from '../lib/useWorkbenchSyncedState';
import { elementIsDrawing } from '../lib/utils';
import {
  BoundingBox,
  getBoundingBoxEdges,
  getElementSize,
  getElementsBoundingBox,
  getVisibleWorkbenchElements,
  screenPositionToWorld,
} from './helpers';
import {
  filterChildByWorkbenchElementUserData,
  findChildByWorkbenchElementUserData,
} from './objectsUserdata';
import { HtmlOverlay } from './utils/HtmlOverlay';
import { ResizePositionRotationPresenter } from './utils/ResizePositionRotationPresenter';
import {
  areSnapGuidesEqual,
  findSnapGuides,
  SnapGuide,
} from './utils/Snapping';
import {
  getElementMinimumSize,
  getTextContentSize,
} from './utils/getContentSize';
import { SharedContextMenuItems } from './workbenchContextMenu/contextMenuItemsPerType/Shared';

export const ResizerControls = ({
  workbench,
  active,
  elements,
  handleAction,
  setIsResizing,
  setSnapGuides,
}: {
  workbench: WorkbenchContentFragment;
  elements: ClientSideWorkbenchElementData[];
  active: boolean;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
  setIsResizing: (value: boolean) => void;
  setSnapGuides: Dispatch<SetStateAction<SnapGuide[]>>;
}) => {
  const styleReferences = useWorkbenchStyleReferences(workbench.id);
  const [createStyleReferencesRef, createStyleReferences] =
    useCreateDrawingStyleReferences();
  const [removeStyleReferencesRef, removeStyleReferences] =
    useRemoveStyleReferences();
  const theme = useTheme();
  const boundingBox = getElementsBoundingBox(elements);
  const boundingBoxRef = useRef(JSON.stringify(boundingBox));
  const [contextMenuPosition, setContextMenuPosition] = useState<
    { x: number; y: number } | undefined
  >();
  const { bindScaleHandle, resetScale, scaledBoundingBox } = useScaleTransform(
    elements,
    boundingBox,
    setIsResizing,
    setSnapGuides,
    (newBoundingBox) => {
      setIsResizing(false);
      setSnapGuides([]);
      if (
        elements.length === 1 &&
        elements[0].__typename === 'WorkbenchElementText' &&
        newBoundingBox.width !== elements[0].width
      ) {
        const [element] = elements;

        const { newContentHeight } = getTextContentSize(
          {
            ...element,
            lockWidth: true,
            width: newBoundingBox.width,
          },
          newBoundingBox.height / element.contentHeight
        );

        handleAction({
          type: 'updateText',
          elementId: element.id,
          contentHeight: newContentHeight,
          height:
            newContentHeight * (newBoundingBox.height / element.contentHeight),
          width: newBoundingBox.width,
          lockWidth: true,
          x: newBoundingBox.x,
          y: newBoundingBox.y,
          final: true,
        });

        return;
      }
      handleAction({
        type: 'multiPosition',
        newElementData: elements.map((element) => {
          return calculateNewElementData(
            { ...element, ...getElementSize(element) },
            boundingBox,
            newBoundingBox
          );
        }),
      });
    }
  );

  if (boundingBoxRef.current !== JSON.stringify(boundingBox)) {
    boundingBoxRef.current = JSON.stringify(boundingBox);
    resetScale();
  }

  const resizerBoundingBox = scaledBoundingBox ?? boundingBox;

  // Hide the resizer if there is only one empty text element
  if (
    elements.length === 1 &&
    elements[0].__typename === 'WorkbenchElementText' &&
    elements[0].content.length === 0
  ) {
    return null;
  }

  const handleContextMenu = (e: ThreeEvent<MouseEvent>) => {
    if (
      contextMenuPosition ||
      elements.length <= 1 ||
      e.button !== 2 ||
      eventTargetIsInput(e.nativeEvent)
    ) {
      return;
    }

    e.stopPropagation();
    e.nativeEvent.preventDefault();

    setContextMenuPosition({
      x: e.clientX,
      y: e.clientY,
    });
  };

  const handleExportAsZip = () => {
    const drawings = elements.filter(elementIsDrawing);
    downloadWorkbenchDrawings(drawings, workbench.id, workbench.name);
  };

  const handleAddReferenceImages = async () => {
    const res = await createStyleReferences({
      input: {
        styleReferences: await Promise.all(
          elements.filter(elementIsDrawing).map(async (element) => ({
            workbenchId: workbench.id,
            imageName: element.name,
            imagePath:
              element.thumbnailPath instanceof ImageData
                ? await imageDataToBlob(element.thumbnailPath)
                : element.thumbnailPath,
          }))
        ),
      },
    });

    if (res.error) {
      return addToast('Error while creating style references.', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }

    addToast(
      'Added to reference image library; it is only available to use in this file'
    );
  };

  const handleRemoveReferenceImages = async () => {
    const selectedStyleReferences = styleReferences.data?.nodes.filter(
      (styleReference) =>
        elements
          .filter(elementIsDrawing)
          .some((element) => element.thumbnailPath === styleReference.imagePath)
    );

    if (!selectedStyleReferences) {
      addToast('No style references found for selected images.');
      return;
    }
    const res = await removeStyleReferences({
      input: {
        ids: selectedStyleReferences?.map(
          (styleReference) => styleReference.id
        ),
      },
    });

    if (res.error) {
      return addToast('Error while deleting style references.', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }

    addToast('Removed from reference image library');
  };

  return (
    <group
      visible={active}
      position={[resizerBoundingBox.x, resizerBoundingBox.y, 0]}
      userData={{
        workbenchObjectType: 'multi-focused-element-container',
        elementX: resizerBoundingBox.x,
        elementY: resizerBoundingBox.y,
        elementWidth: resizerBoundingBox.width,
        elementHeight: resizerBoundingBox.height,
        vizcomRenderingOrder: [
          {
            zIndex: WorkbenchContentRenderingOrder.indexOf('actions'),
            escapeZIndexContext: true,
          },
        ],
      }}
      onContextMenu={handleContextMenu}
    >
      <ResizePositionRotationPresenter
        active={active}
        width={resizerBoundingBox.width}
        height={resizerBoundingBox.height}
        resizeHandleMeshPropsGetter={bindScaleHandle as any}
        forceAspectRatio={
          elements.length > 1 ||
          (elements.length === 1 &&
            (elements[0].__typename === 'Drawing' ||
              elements[0].__typename === 'WorkbenchElementImg2Img' ||
              elements[0].__typename === 'WorkbenchElementAnimate' ||
              elements[0].__typename === 'Video' ||
              elements[0].__typename === 'WorkbenchElementMix'))
            ? { x: true, y: true }
            : elements.length === 1 &&
              elements[0].__typename === 'WorkbenchElementText'
            ? { x: false, y: true }
            : { x: false, y: false }
        }
        color={
          elements.length === 1 &&
          elements[0].__typename === 'WorkbenchElementPalette'
            ? theme.button.palette
            : undefined
        }
      />

      {/* Context menu */}
      <HtmlOverlay>
        <ContextMenu
          withButton={false}
          contextMenuPosition={contextMenuPosition}
          onOpenStateChange={(state) => {
            if (state === false) setContextMenuPosition(undefined);
          }}
          items={
            <>
              {!elements.find(
                (element) => element.__typename !== 'Drawing'
              ) && (
                <>
                  <ContextMenuItem onClick={handleExportAsZip}>
                    Download
                  </ContextMenuItem>
                  <ContextMenuItem
                    disabled={createStyleReferencesRef.fetching}
                    onClick={handleAddReferenceImages}
                  >
                    Add as reference images in 2D Studio
                  </ContextMenuItem>
                  <ContextMenuItem
                    disabled={removeStyleReferencesRef.fetching}
                    onClick={handleRemoveReferenceImages}
                  >
                    Remove reference images in 2D Studio
                  </ContextMenuItem>
                  <ContextMenuDivider />
                </>
              )}
              <SharedContextMenuItems
                workbenchId={workbench.id}
                elements={elements}
                handleAction={handleAction}
              />
            </>
          }
        />
      </HtmlOverlay>
    </group>
  );
};

interface UseScaleTransformDragMemo {
  initialPointerOffset: Vector2;
  initialPointerPosition: Vector2;
  dragDirection: [number, number];
  initialBoundingBox: BoundingBox;
  selectedObjects: ReturnType<typeof filterChildByWorkbenchElementUserData>;
  scaleFromCenter: boolean;
}

const v2 = new Vector2();
const useScaleTransform = (
  elements: ClientSideWorkbenchElementData[],
  initialBoundingBox: BoundingBox,
  setIsResizing: (value: boolean) => void,
  setSnapGuides: Dispatch<SetStateAction<SnapGuide[]>>,
  onResize: (newBoundingBox: BoundingBox) => void
) => {
  const scene = useThree((s) => s.scene);
  const camera = useThree((s) => s.camera as OrthographicCamera);
  const [scaledBoundingBox, setScaledBoundingBox] =
    useState(initialBoundingBox);

  const findVisibleSnapGuides = (pointerOffset: Vector2) => {
    const visibleWorkbenchElements = getVisibleWorkbenchElements(camera, scene);
    const boundingBoxEdges = getBoundingBoxEdges(scaledBoundingBox);

    return findSnapGuides(
      visibleWorkbenchElements
        .filter((el) => !elements.some((e) => e.id === el.userData.elementId))
        .map((el) => ({
          x: el.userData.elementX,
          y: el.userData.elementY,
          width: el.userData.elementWidth,
          height: el.userData.elementHeight,
        })),
      boundingBoxEdges,
      v2,
      pointerOffset
    );
  };

  const bindScaleHandle = useDrag<ThreeEvent<PointerEvent>>(
    (gesture): UseScaleTransformDragMemo => {
      gesture.event.stopPropagation();

      if (gesture.intentional) {
        setIsResizing(true);
      }
      const pointerPosition = screenPositionToWorld(
        [gesture.event.clientX, gesture.event.clientY],
        camera
      );

      v2.set(pointerPosition[0], pointerPosition[1]);

      const memo = gesture.memo as UseScaleTransformDragMemo;
      if (!memo) {
        const [xDir, yDir] = gesture.args as [number, number];
        const cornerDraggingPosition = new Vector2(
          initialBoundingBox.x + (initialBoundingBox.width / 2) * xDir,
          initialBoundingBox.y + (initialBoundingBox.height / 2) * yDir
        );
        return {
          initialPointerOffset: v2.clone().sub(cornerDraggingPosition),
          initialPointerPosition: v2.clone(),
          dragDirection: [xDir, yDir],
          initialBoundingBox,
          selectedObjects: filterChildByWorkbenchElementUserData(
            scene,
            ({ elementId }) => elements.some((e) => e.id === elementId)
          ),
          scaleFromCenter: gesture.event.altKey || gesture.event.metaKey,
        };
      }
      const forceAspectRatio =
        elements.length === 1
          ? elements[0].__typename === 'Drawing' ||
            (elements[0].__typename === 'WorkbenchElementText' &&
              memo.dragDirection[1] !== 0) ||
            elements[0].__typename === 'WorkbenchElementMix' ||
            elements[0].__typename === 'WorkbenchElementImg2Img' ||
            elements[0].__typename === 'WorkbenchElementAnimate' ||
            elements[0].__typename === 'Video'
          : true;

      if (!gesture.ctrlKey) {
        const { guides, snappedPosition } = findVisibleSnapGuides(
          memo.initialPointerOffset
        );

        // Update position with snapped coordinates
        if (snappedPosition) {
          v2.x = snappedPosition.x;
          v2.y = snappedPosition.y;
        }

        // Update snap guides display
        setSnapGuides((prevSnapGuides) =>
          areSnapGuidesEqual(guides, prevSnapGuides) ? prevSnapGuides : guides
        );
      } else {
        setSnapGuides((prev) => (prev.length === 0 ? prev : []));
      }

      const deltaX = v2.x - memo.initialPointerPosition.x;
      const deltaY = v2.y - memo.initialPointerPosition.y;

      const newBoundingBox = calculateNewBoundingBox(
        memo.initialBoundingBox,
        deltaX,
        deltaY,
        { x: memo.dragDirection[0], y: memo.dragDirection[1] },
        elements,
        forceAspectRatio,
        memo.scaleFromCenter
      );

      setScaledBoundingBox(newBoundingBox);

      // Update the positions of the selected objects in real-time
      memo.selectedObjects.forEach((object) => {
        const element = elements.find(
          (e) => e.id === object.userData.elementId
        );
        if (!element) return;

        const newElementData = calculateNewElementData(
          {
            __typename: object.userData.elementTypename,
            x: object.userData.elementX,
            y: object.userData.elementY,
            id: object.userData.elementId,
            width: object.userData.elementWidth,
            height: object.userData.elementHeight,
          },
          memo.initialBoundingBox,
          newBoundingBox
        );

        object.position.set(
          newElementData.x,
          newElementData.y,
          object.position.z
        );

        if (
          object.userData.elementTypename === 'WorkbenchElementPalette' ||
          object.userData.elementTypename === 'WorkbenchElementSection'
        ) {
          object.userData.resizeWidth = newElementData.width;
          object.userData.resizeHeight = newElementData.height;
        } else {
          object.scale.set(
            newElementData.width / object.userData.elementWidth,
            newElementData.height / object.userData.elementHeight,
            1
          );
        }

        if (object.userData.elementTypename === 'Drawing') {
          const metaData = findChildByWorkbenchElementUserData(
            object,
            (userData) => !!userData.notResizable
          );

          metaData?.scale.set(
            1 / (newElementData.width / object.userData.elementWidth),
            1 / (newElementData.height / object.userData.elementHeight),
            1
          );

          metaData?.position.set(
            metaData.position.x,
            (-(
              1 -
              1 / (newElementData.height / object.userData.elementHeight)
            ) *
              object.userData.elementHeight) /
              2,
            metaData.position.z
          );
        }

        // WorkbenchElementText edge case
        if (
          elements.length === 1 &&
          object.userData.elementTypename === 'WorkbenchElementText' &&
          memo.dragDirection[1] === 0
        ) {
          const text = findChildByWorkbenchElementUserData(
            object,
            (userData) => !!userData.fixedAspectRatio
          );

          // inverse the scale so we maintain the aspect ratio
          text?.scale.set(
            1 / (newElementData.width / object.userData.elementWidth),
            1,
            1
          );
        }
      });

      if (gesture.last) {
        onResize(newBoundingBox);
      }

      return memo;
    }
  );

  return {
    bindScaleHandle,
    scaledBoundingBox,
    resetScale: () => {
      // reset scale of the selected objects
      const selectedObjects = filterChildByWorkbenchElementUserData(
        scene,
        ({ elementId }) => elements.some((e) => e.id === elementId)
      );
      selectedObjects.forEach((object) => {
        object.scale.set(1, 1, 1);
        object.position.set(
          object.userData.elementX,
          object.userData.elementY,
          0
        );

        // WorkbenchElementText edge case
        if (object.userData.elementTypename === 'WorkbenchElementText') {
          const text = findChildByWorkbenchElementUserData(
            object,
            (userData) => !!userData.fixedAspectRatio
          );
          text?.scale.set(1, 1, 1);
        }

        // Drawing edge case
        if (object.userData.elementTypename === 'Drawing') {
          const metaData = findChildByWorkbenchElementUserData(
            object,
            (userData) => !!userData.notResizable
          );
          metaData?.scale.set(1, 1, 1);
          metaData?.position.set(0, 0, 0);
        }
      });
      setScaledBoundingBox(initialBoundingBox);
    },
  };
};

export function calculateNewBoundingBox(
  initialBox: BoundingBox,
  deltaX: number,
  deltaY: number,
  dragDirection: { x: number; y: number },
  elements: ClientSideWorkbenchElementData[],
  forceAspectRatio: boolean,
  scaleFromCenter = false
) {
  const { x, y, width, height } = initialBox;

  // Find the maximum scale factor that doesn't violate the minimum size constraint
  let maxScaleX = -Infinity;
  let maxScaleY = -Infinity;

  // Calculate the change based on drag direction
  const changeX = dragDirection.x * deltaX * (scaleFromCenter ? 2 : 1);
  const changeY = dragDirection.y * deltaY * (scaleFromCenter ? 2 : 1);

  let scaleX = (width + changeX) / width;
  let scaleY = (height + changeY) / height;

  if (forceAspectRatio) {
    if (dragDirection.x === 0) {
      scaleX = scaleY;
    } else if (dragDirection.y === 0) {
      scaleY = scaleX;
    } else {
      const maxScale = Math.max(scaleX, scaleY);
      scaleX = maxScale;
      scaleY = maxScale;
    }
  }

  elements.forEach((element) => {
    const currentSize = getElementSize(element);
    const [minWidth, minHeight] = getElementMinimumSize(
      element,
      currentSize.height * scaleY
    );
    maxScaleX = Math.max(maxScaleX, minWidth / currentSize.width);
    maxScaleY = Math.max(maxScaleY, minHeight / currentSize.height);
  });

  scaleX = Math.max(scaleX, maxScaleX);
  scaleY = Math.max(scaleY, maxScaleY);

  const newWidth = width * scaleX;
  const newHeight = height * scaleY;
  let newX = x;
  let newY = y;

  if (!scaleFromCenter) {
    const heightChange = (height - newHeight) / 2;
    const widthChange = (width - newWidth) / 2;

    newX = x - widthChange * dragDirection.x;
    newY = y - heightChange * dragDirection.y;
  }

  return { x: newX, y: newY, width: newWidth, height: newHeight };
}

export function calculateNewElementData(
  element: {
    __typename: string;
    x: number;
    y: number;
    width: number;
    height: number;
    id: string;
  },
  initialBox: BoundingBox,
  newBox: BoundingBox
) {
  const relativeX = (element.x - initialBox.x) / initialBox.width;
  const relativeY = (element.y - initialBox.y) / initialBox.height;

  const scaleX = newBox.width / initialBox.width;
  const scaleY = newBox.height / initialBox.height;

  const newWidth = element.width * scaleX;
  const newHeight = element.height * scaleY;

  const newX = newBox.x + relativeX * newBox.width;
  const newY = newBox.y + relativeY * newBox.height;

  return {
    x: newX,
    y: newY,
    id: element.id,
    width: newWidth,
    height: newHeight,
  };
}

import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import {
  useCurrentUserClientStateByKey,
  UserClientStateKeys,
} from '@vizcom/shared/data-access/graphql';
import {
  BlendIcon,
  Dropdown,
  InferenceStyleOption,
  UnhideIcon,
  OpacityIcon,
  RangeInput,
  HideIcon,
  useDocumentEventListener,
  useKeyboardShortcut,
  NumberInput,
  addToast,
  CMD_KEY_NAME,
  WideCarretDownIcon,
  Menu,
  ToolbarButton,
  Model3dIcon,
  Divider,
  MeatballIcon,
} from '@vizcom/shared-ui-components';

import { RawImage } from '../../lib/RawImage';
import { copyImageToClipboard } from '../../lib/drawingUtils';
import {
  Drawing2dStudio,
  DrawingLayer,
  useDrawingSyncedState,
} from '../../lib/useDrawingSyncedState';
import { useIsWorkbenchViewer } from '../../lib/utils';
import { LayerContextMenu } from './LayerContextMenu';
import { LayerName } from './LayerName';
import { ScreenShareAreaSelector } from './ScreenShareAreaSelector';
import { ActiveLayerChangeOperation } from './lib/useActiveLayer';
import { useSelectionApiStore } from './selection/useSelectionApi';
import { LayerMeatballWrapper, LoaderIcon } from './style';
import { BlendMode, Layout } from './types';
import { useScreenShare } from './useScreenShare';
import { cutSelection, getNestedGroupVisibility } from './utils';

const BlendModes = {
  [BlendMode.normal]: 'Normal',
  [BlendMode.multiply]: 'Multiply',
};

export const Layer = ({
  drawing,
  layer,
  depth,
  isLast,
  nextLayerIsGroup,
  parentIsActive,
  viewerVisibility,
  isTogglingMultiLayerVisibility,
  activeLayer,
  editingLayer,
  setViewerVisibility,
  setTogglingMultiLayerVisibility,
  isolateLayer,
  setActiveLayer,
  setEditingLayer,
  handleAction,
  onCreateDrawingsFromImage,
}: {
  drawing: Drawing2dStudio;
  layer: DrawingLayer;
  depth: number;
  isLast: boolean;
  nextLayerIsGroup: boolean;
  parentIsActive: boolean;
  viewerVisibility: Record<string, boolean>;
  editingLayer: string | null;
  isTogglingMultiLayerVisibility: boolean | undefined;
  activeLayer: string | undefined;
  setViewerVisibility: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  setTogglingMultiLayerVisibility: (v: boolean | undefined) => void;
  isolateLayer: (id: string) => void;
  setActiveLayer: (
    id: string | undefined,
    operation?: ActiveLayerChangeOperation
  ) => void;
  setEditingLayer: (id: string | null) => void;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  onCreateDrawingsFromImage: (
    previews: { preview: ArrayBuffer | Blob; name?: string }[],
    offset?: {
      x: number;
      y: number;
    }
  ) => void;
}) => {
  const theme = useTheme();
  const layout: Layout =
    useCurrentUserClientStateByKey(UserClientStateKeys.StudioLayout) ||
    'default';
  const [isEditingName, setIsEditingName] = useState(false);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const isViewer = useIsWorkbenchViewer();
  const selectionApiStore = useSelectionApiStore();
  const id = layer.id;
  const {
    startScreenShare,
    stopScreenShare,
    isSharing,
    setFrameCallback,
    isSelectingArea,
    firstFrame,
    handleAreaSelected,
  } = useScreenShare(id, drawing.width, drawing.height);

  useDocumentEventListener('pointerup', () => {
    setTogglingMultiLayerVisibility(undefined);
  });

  const toggleLayer = () => {
    handleAction({
      type: 'updateLayer',
      id,
      data: {
        visible: !layer.visible,
      },
    });
  };

  const openContextMenu = (open: boolean) => {
    if (!activeLayer?.split('/').includes(id) && open) {
      setActiveLayer(id);
    }
    setShowContextMenu(open);
  };

  const toggleVisibility = () => {
    setTogglingMultiLayerVisibility(!layer.visible);
  };

  const setVisible = (visible: boolean) => {
    handleAction({
      type: 'updateLayer',
      id,
      data: {
        visible,
      },
    });
  };

  const setLayerName = (name: string) => {
    handleAction({
      type: 'updateLayer',
      id,
      data: {
        name,
      },
    });
  };

  const setLayerBlendMode = (blendMode: BlendMode) => {
    handleAction({
      type: 'updateLayer',
      id,
      data: {
        blendMode,
      },
    });
  };

  const setLayerOpacity = (opacity: number) => {
    handleAction({
      type: 'updateLayer',
      id,
      data: {
        opacity,
      },
      keepCanceledDebounceActionInHistory: false,
    });
  };

  const handleCopyRawLayerImage = () => {
    if (!layer.imagePath) {
      addToast('This layer is empty');
    }
    copyImageToClipboard(
      layer.imagePath,
      selectionApiStore.getState().getSelectionImage()
    );
  };

  const handleCutRawLayerImage = () => {
    if (!layer.imagePath) {
      addToast('This layer is empty');
    }

    const selectionData = selectionApiStore.getState().getSelectionImage();
    if (selectionData) {
      cutSelection(layer, selectionData, handleAction);
    }
  };

  useKeyboardShortcut(
    'c',
    () => {
      if (activeLayer === id) {
        handleCopyRawLayerImage();
      }
    },
    {
      ctrl: true,
    }
  );

  useKeyboardShortcut(
    'x',
    () => {
      if (activeLayer === id) {
        handleCutRawLayerImage();
      }
    },
    {
      ctrl: true,
    }
  );

  const handleStartScreenShare = async () => {
    try {
      handleStopScreenShare(); // always stop if active on this layer
      const mediaStream = await startScreenShare();
      if (mediaStream) {
        const screenTrack = mediaStream.getVideoTracks()[0];

        if (screenTrack?.label) {
          handleAction({
            type: 'updateLayer',
            id: layer.id,
            data: {
              name: `Screen Share: ${screenTrack.label}`,
            },
          });
        }

        setFrameCallback((imageData: ImageData) => {
          handleAction({
            type: 'updateLayer',
            id: layer.id,
            data: { image: imageData },
          });
        });
      }
    } catch (error) {
      console.error('Screen share error:', error);
      addToast('Failed to start screen sharing', { type: 'danger' });
    }
  };

  const handleStopScreenShare = () => {
    stopScreenShare();
  };

  if (!layer) return null;

  const is3D = Boolean(layer.metadata3D);
  const isInHiddenGroup = getNestedGroupVisibility(
    layer.parentId,
    drawing.layers.nodes
  );
  const active =
    activeLayer === id || Boolean(activeLayer?.split('/').includes(id));

  return (
    <>
      {isSelectingArea && firstFrame && (
        <ScreenShareAreaSelector
          isOpen={isSelectingArea}
          onClose={() => {
            stopScreenShare();
          }}
          imageData={firstFrame}
          onAreaSelected={handleAreaSelected}
          drawingWidth={drawing.width}
          drawingHeight={drawing.height}
        />
      )}
      <LayerContainer
        $depth={depth}
        $is3D={is3D}
        $active={active}
        $parentIsActive={parentIsActive}
        className="hoverable"
        onClick={(e) => {
          e.stopPropagation();
          if (!drawing?.isEditable) return;
          if (e.shiftKey) {
            setActiveLayer(id, 'addTo');
            return;
          }
          if (e[CMD_KEY_NAME]) {
            if (activeLayer?.split('/').includes(id)) {
              setActiveLayer(id, 'remove');
            } else {
              setActiveLayer(id, 'add');
            }
            return;
          }
          setActiveLayer(id);
        }}
        onContextMenu={(e) => {
          if (!drawing?.isEditable) return;
          e.preventDefault();
          e.stopPropagation();
          openContextMenu(true);
        }}
      >
        <Content
          $depth={depth}
          $is3D={is3D}
          $active={active}
          $parentIsActive={parentIsActive}
        >
          <Actions>
            <button
              style={{
                touchAction: 'none',
                padding: '10px',
              }}
              onPointerDown={(e) => {
                if (isViewer) {
                  setViewerVisibility((prev) => ({
                    ...prev,
                    [id]: prev[id] !== undefined ? !prev[id] : !layer.visible,
                  }));
                  return;
                }
                e.stopPropagation();
                try {
                  (e.target as HTMLButtonElement).releasePointerCapture(
                    e.pointerId
                  );
                } catch {}

                e.preventDefault();
                if (e.altKey) {
                  isolateLayer(id);
                  return;
                }

                toggleVisibility();
                toggleLayer();
              }}
              onPointerEnter={() => {
                if (isTogglingMultiLayerVisibility !== undefined) {
                  setVisible(isTogglingMultiLayerVisibility);
                }
              }}
            >
              {viewerVisibility[id] ?? layer.visible ? (
                <HideIcon
                  style={{
                    pointerEvents: 'none',
                    color: isInHiddenGroup
                      ? theme.icon.secondary
                      : theme.icon.primary,
                  }}
                />
              ) : (
                <UnhideIcon
                  style={{
                    pointerEvents: 'none',
                    color: isInHiddenGroup
                      ? theme.icon.secondary
                      : theme.icon.primary,
                  }}
                />
              )}
            </button>
          </Actions>
          <ImageWrapper
            $active={active}
            $is3D={is3D}
            $parentIsActive={parentIsActive}
          >
            {is3D && <StyledCube />}
            <Image
              $width={drawing.width}
              $height={drawing.height}
              $is3D={is3D}
              style={
                layer.fill.length === 7 && !layer.imagePath
                  ? { background: layer.fill }
                  : undefined
              }
              onClick={() => {
                openContextMenu(!showContextMenu);
              }}
            >
              {layer.imagePath ? <RawImage data={layer.imagePath} /> : <div />}
            </Image>
            {layer.metadata3D?.generatedFrom2dTo3d && !layer.meshPath && (
              <LayerLoader $width={drawing.width} $height={drawing.height}>
                <div>
                  <LoaderIcon />
                </div>
              </LayerLoader>
            )}
          </ImageWrapper>
          <Meta>
            <LayerNameWrapper>
              <LayerName
                disabled={!drawing?.isEditable}
                onChange={(value) => setLayerName(value)}
                value={layer.name}
                isEditing={isEditingName}
                setIsEditing={setIsEditingName}
              />
              {isSharing && <LiveIndicator>LIVE</LiveIndicator>}
            </LayerNameWrapper>
            {drawing?.isEditable &&
              (isSharing ? (
                <DisconnectButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleStopScreenShare();
                  }}
                >
                  Disconnect
                </DisconnectButton>
              ) : (
                <Settings
                  $active={active}
                  $is3D={is3D}
                  $parentIsActive={parentIsActive}
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditingLayer(editingLayer === id ? '' : id);
                  }}
                >
                  <div>
                    {BlendModes[layer.blendMode as BlendMode].charAt(0)}
                  </div>
                  <div>{(layer.opacity * 100).toFixed(0)}%</div>
                  {depth < 2 && (
                    <StyledCarretDownIcon
                      height={12}
                      width={12}
                      $isOpen={editingLayer === id}
                    />
                  )}
                </Settings>
              ))}
          </Meta>
          <Menu
            hideArrow
            placement={layout === 'default' ? 'right' : 'left'}
            offset={layout === 'default' ? 20 : 230}
            renderLabel={(props, interactionProps) => (
              <ToolbarButton
                icon={
                  <LayerMeatballWrapper
                    $active={active}
                    $is3d={false}
                    $open={props.isOpen}
                  >
                    <MeatballIcon />
                  </LayerMeatballWrapper>
                }
                tooltip="More actions"
                tooltipOptions={{ placement: 'right', disabled: props.isOpen }}
                {...interactionProps}
                onClick={(e) => {
                  e.stopPropagation();
                  (interactionProps.onClick as React.MouseEventHandler)(e);
                }}
                buttonProps={{ tabIndex: -1, variant: 'tertiary' }}
              />
            )}
            open={showContextMenu}
            setIsOpen={openContextMenu}
            disableFocusManager
          >
            <LayerContextMenu
              drawing={drawing}
              layer={layer}
              isLast={isLast}
              nextLayerIsGroup={nextLayerIsGroup}
              activeLayer={activeLayer}
              setViewerVisibility={setViewerVisibility}
              setActiveLayer={setActiveLayer}
              handleAction={handleAction}
              onCreateDrawingsFromImage={onCreateDrawingsFromImage}
              setIsEditingName={setIsEditingName}
              setShowContextMenu={setShowContextMenu}
              handleScreenShareFromMenu={handleStartScreenShare}
            />
          </Menu>
        </Content>
        {editingLayer === id && (
          <>
            {!active && !parentIsActive && <Divider />}
            <Edit
              onPointerDown={(e) => {
                if (active) e.stopPropagation();
              }}
              onClick={(e) => {
                if (active) e.stopPropagation();
              }}
              $depth={depth}
            >
              <BlendingRow>
                <BlendIcon />
                <Dropdown
                  options={Object.entries(BlendModes).map(([value, name]) => ({
                    data: {
                      name,
                      value,
                    },
                  }))}
                  value={layer.blendMode}
                  setValue={(v) => setLayerBlendMode(v as BlendMode)}
                  buttonProps={{
                    style: {
                      backgroundColor: !active
                        ? theme.surface.secondary
                        : is3D
                        ? theme['3d'].disabled
                        : theme.surface.layer,
                    },
                  }}
                  optionToValueMapper={({ value }: { value: string }) => value}
                  OptionComponent={InferenceStyleOption}
                >
                  {BlendModes[layer.blendMode as BlendMode]}
                </Dropdown>
              </BlendingRow>
              <TransparencyRow>
                <OpacityIcon />
                <div style={{ position: 'relative' }}>
                  <TransparencyBackground
                    style={{
                      background:
                        'repeating-conic-gradient(#d7d7d7 0% 25%, #ffffff 0% 50%) 50% / 8px 8px',
                    }}
                  />
                  <TransparencyBackground
                    style={{
                      width: '101%',
                      backgroundImage:
                        'linear-gradient(-90deg, rgb(47, 47, 49) 10%, rgba(47, 47, 49, 0.2) 100%)',
                    }}
                  />

                  <RangeInput
                    thickness="medium-thick"
                    variant="transparent"
                    value={layer.opacity}
                    min={0}
                    max={1}
                    step={0.01}
                    onChange={({ target: { value } }) =>
                      setLayerOpacity(+value)
                    }
                    onMouseDown={(e) => e.stopPropagation()}
                  />
                </div>
                <Value
                  style={{
                    backgroundColor: !active
                      ? theme.surface.secondary
                      : is3D
                      ? theme['3d'].disabled
                      : theme.surface.layer,
                  }}
                >
                  <NumberInput
                    min={0}
                    max={100}
                    value={layer.opacity * 100}
                    valueStyle={{
                      width: '100%',
                    }}
                    unit="%"
                    unitStyle={{
                      paddingLeft: '5px',
                      color:
                        !active && !parentIsActive
                          ? theme.text.bodyDisabled
                          : is3D
                          ? theme['3d'].primary
                          : theme.text.subtextSecondary,
                    }}
                    setValue={(v) => setLayerOpacity(v / 100)}
                    dragArrows={false}
                    hoverColor={
                      !active && !parentIsActive
                        ? theme.text.bodyDisabled
                        : is3D
                        ? theme['3d'].primary
                        : theme.text.subtextSecondary
                    }
                  />
                </Value>
              </TransparencyRow>
            </Edit>
          </>
        )}
      </LayerContainer>
    </>
  );
};

const Content = styled.div<{
  $active: boolean;
  $depth: number;
  $is3D: boolean;
  $parentIsActive: boolean;
}>`
  display: grid;
  grid-template-columns: 32px 62px minmax(0, 1fr) 32px;
  padding: 8px 4px 8px ${({ $depth }) => $depth * 24}px;
  align-items: center;
  gap: 4px;
  user-select: none;
  min-height: 64px;
  width: 100%;
  border: 1px solid transparent;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  background: ${({ $active, $is3D, $parentIsActive, theme }) =>
    $active
      ? $is3D
        ? theme['3d'].disabled
        : theme.surface.layer
      : $parentIsActive
      ? theme.button.primaryDisabled
      : theme.surface.primary};
`;

const LayerContainer = styled.div<{
  $depth: number;
  $is3D: boolean;
  $active: boolean;
  $parentIsActive: boolean;
}>`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid transparent;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  background: ${({ theme, $is3D, $active, $parentIsActive }) =>
    $active
      ? $is3D
        ? theme['3d'].secondary
        : theme.surface.layerSecondary
      : $parentIsActive
      ? theme.button.primaryDisabled
      : theme.surface.primary};

  overflow: hidden;
  min-height: 64px;

  &:hover {
    border-color: ${({ $is3D, theme, $active, $depth }) =>
      !$active || $depth === 0
        ? 'transparent'
        : $is3D
        ? theme['3d'].primary
        : theme.deprecated.primary.default};
    background: ${({ theme, $active, $is3D, $parentIsActive }) =>
      $active
        ? $is3D
          ? theme['3d'].secondary
          : theme.surface.layerSecondary
        : $parentIsActive
        ? theme.button.primaryDisabled
        : theme.surface.primary};

    > ${Content} {
      border-color: ${({ $is3D, theme }) =>
        $is3D ? theme['3d'].primary : theme.deprecated.primary.default};
      background: ${({ theme, $active, $is3D, $parentIsActive }) =>
        $active
          ? $is3D
            ? theme['3d'].disabled
            : theme.surface.layer
          : $parentIsActive
          ? theme.button.primaryDisabled
          : theme.surface.secondary};
    }
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;

  button {
    border: 0;
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    align-items: center;
    background: transparent;
    cursor: pointer;
  }
`;

const ImageWrapper = styled.div<{
  $active: boolean;
  $is3D: boolean;
  $parentIsActive: boolean;
}>`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 10px 3px;
  background: ${({ theme, $active, $is3D, $parentIsActive }) =>
    !$active || $is3D
      ? $parentIsActive
        ? theme.surface.layer
        : theme.surface.secondary
      : theme.surface.layerSecondary};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  margin-right: 4px;
  width: 58px;
  height: 48px;
`;

const Image = styled.div<{ $width: number; $height: number; $is3D: boolean }>`
  background: ${({ $is3D }) =>
    $is3D
      ? 'none'
      : 'repeating-conic-gradient(#d7d7d7 0% 25%, #ffffff 0% 50%) 50% / 8px 8px'};
  overflow: hidden;
  cursor: pointer;
  align-items: center;
  display: flex;

  > div {
    width: ${({ $width }) => $width}px;
    height: ${({ $height }) => $height}px;
  }

  &,
  > img,
  > canvas {
    aspect-ratio: ${({ $width, $height }) => $width / $height};
    border-radius: ${({ theme }) => theme.borderRadius.s};
    max-width: 58px;
    max-height: 38px;
  }
  > img,
  > canvas {
    pointer-events: none;
    object-fit: contain;
    width: 100%;
  }
`;

const LayerLoader = styled.div<{ $width: number; $height: number }>`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    aspect-ratio: ${({ $width, $height }) => $width / $height};
    width: 100%;
    height: 100%;
    border-radius: ${({ theme }) => theme.borderRadius.m};
    backdrop-filter: blur(2px) brightness(80%);

    > svg {
      max-width: 100%;
      max-height: 100%;
    }
  }
`;

const Meta = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 6px;
  overflow: hidden;
`;

const LayerNameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Settings = styled.div<{
  $active: boolean;
  $is3D: boolean;
  $parentIsActive: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ theme, $active, $is3D, $parentIsActive }) =>
    !$active && !$parentIsActive
      ? theme.text.bodyDisabled
      : $is3D
      ? theme['3d'].primary
      : theme.text.subtextSecondary};
  width: 75px;
  cursor: pointer;
`;

const Edit = styled.div<{ $depth: number }>`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 1rem;
  width: 100%;
  border-radius: ${({ theme }) =>
    `0 0 ${theme.borderRadius.m} ${theme.borderRadius.m}`};
  padding-left: ${({ $depth }) => $depth * 24}px;
`;

const BlendingRow = styled.div`
  display: flex;
  padding: 0px 2px;
  gap: 8px;
  align-items: center;

  > * {
    flex-grow: 1;
  }

  > svg {
    flex-grow: 0;
    flex-shrink: 0;
    width: 32px;
  }
`;

const TransparencyRow = styled.div`
  display: grid;
  grid-template-columns: 32px 1fr 50px;
  padding: 0px 2px;
  gap: 8px;
  align-items: center;

  > svg {
    flex-grow: 0;
    flex-shrink: 0;
    width: 32px;
  }
`;

const Value = styled.div`
  margin-left: auto;
  color: ${({ theme }) => theme.text.body};
  font-weight: 400;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  width: 50px;
`;

const StyledCarretDownIcon = styled(WideCarretDownIcon)<{ $isOpen: boolean }>`
  transform: ${({ $isOpen }) => ($isOpen ? 'rotate(180deg)' : 'none')};
  transition: transform 0.2s;
`;

const StyledCube = styled(Model3dIcon)`
  color: ${({ theme }) => theme['3d'].primary};
  transform: rotate(30deg);
  position: absolute;
  width: 12px;
  height: 12px;
  top: 4px;
  left: 4px;
`;

const TransparencyBackground = styled.div`
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;

  border-radius: ${({ theme }) => theme.borderRadius.m};
  height: 16px;
  width: 100%;
`;

const DisconnectButton = styled.div`
  color: ${({ theme }) => theme.text.primaryDisabled};
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
`;

const LiveIndicator = styled.div`
  border-radius: 12px;
  background-color: ${({ theme }) => theme.button.palette};
  color: ${({ theme }) => theme.text.primary};
  font-size: 10px;
  padding: 4px 6px;
`;

import styled, { CSSProperties } from 'styled-components';
import {
  OrganizationSubscriptionPlan,
  useWorkbenchContent,
} from '@vizcom/shared/data-access/graphql';
import { usePaywallModalState } from '@vizcom/shared-ui-components';

export const CollaborationToolbarSubscriptionPlanIndicator = ({
  workbenchId,
}: {
  workbenchId: string;
}) => {
  const { data: workbench } = useWorkbenchContent(workbenchId);

  const subscriptionPlan = workbench?.folder?.organization?.subscriptionPlan;

  if (!subscriptionPlan) {
    return null;
  }

  const pillContent = SubscriptionPlanTypePillContent[subscriptionPlan];

  const handleClick = () => {
    if (subscriptionPlan === OrganizationSubscriptionPlan.Free) {
      usePaywallModalState.getState().trigger();
    }
  };

  return (
    <SubscriptionPlanPill
      style={pillContent.style}
      onClick={handleClick}
      isFree={subscriptionPlan === OrganizationSubscriptionPlan.Free}
    >
      {pillContent.text}
    </SubscriptionPlanPill>
  );
};

const SubscriptionPlanPill = styled.div<{ isFree: boolean }>`
  padding: 4px;
  border-radius: ${({ theme }) => theme.borderRadius.s};
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
  cursor: ${(props) => (props.isFree ? 'pointer' : 'default')};
`;

const SubscriptionPlanTypePillContent: Record<
  OrganizationSubscriptionPlan,
  { text: string; style: CSSProperties }
> = {
  [OrganizationSubscriptionPlan.Free]: {
    text: 'Free',
    style: {
      backgroundColor: '#E6E6E6F2',
      color: '#000000',
    },
  },
  [OrganizationSubscriptionPlan.Pro]: {
    text: 'Pro',
    style: {
      backgroundColor: '#1e1e60',
      color: '#7070f2',
    },
  },
  [OrganizationSubscriptionPlan.Organization]: {
    text: 'Org',
    style: {
      backgroundColor: '#FF3E8D33',
      color: '#FF3E8DE5',
    },
  },
  [OrganizationSubscriptionPlan.Enterprise]: {
    text: 'Ent',
    style: {
      backgroundColor: '#2D2D30E0',
      color: '#A5A5B1',
    },
  },
  [OrganizationSubscriptionPlan.Edu]: {
    text: 'Edu',
    style: {
      backgroundColor: '#81BB3733',
      color: ' #81BB37',
    },
  },
};

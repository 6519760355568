import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getSharingSecret,
  useCurrentUser,
  useWorkbenchContent,
} from '@vizcom/shared/data-access/graphql';
import {
  FullPageDarkLoader,
  isPublicSharingEnabled,
  LoadingErrorPage,
  NotFoundPage,
  useSelectedOrganization,
} from '@vizcom/shared-ui-components';

import { WorkbenchContent } from './WorkbenchContent';
import { WorkbenchErrorBoundary } from './components/WorkbenchErrorBoundary';
import { isViewerContext } from './lib/utils';

export const WorkbenchPage = () => {
  const navigate = useNavigate();
  const { workbenchId, drawingId } = useParams();
  const { data: currentUser } = useCurrentUser();
  const { fetching, data: workbench, error } = useWorkbenchContent(workbenchId);
  const { data: selectedOrganization } = useSelectedOrganization(); // required to make sure we update the currently selected organization everywhere else when navigating directly to a workbench

  const sharingSecretUrl = getSharingSecret();

  useEffect(() => {
    if (!workbench) return;

    const sharingEnabled = isPublicSharingEnabled(workbench);
    const userHaveOrganizationAccess = !!selectedOrganization;

    // Add the sharing secret to the URL if the workbench is public and the user is logged in
    if (currentUser && sharingEnabled && !sharingSecretUrl) {
      navigate(
        `${location.pathname}#sharing_secret=${workbench.sharingSecret}`,
        {
          replace: true,
        }
      );
    }

    // Remove the sharing secret from the URL if sharing is disabled
    if (
      currentUser &&
      !sharingEnabled &&
      sharingSecretUrl &&
      userHaveOrganizationAccess
    ) {
      navigate(location.pathname, {
        replace: true,
      });
    }
  }, [
    sharingSecretUrl,
    drawingId,
    currentUser,
    workbench,
    navigate,
    selectedOrganization,
  ]);

  if (fetching) {
    return <FullPageDarkLoader />;
  }

  if (error) {
    return (
      <LoadingErrorPage
        targetType="file"
        errorMessage={error.graphQLErrors?.[0]?.message ?? error.message}
      />
    );
  }

  if (workbench === null || workbench === undefined) {
    return <NotFoundPage targetType="file" />;
  }

  return (
    <isViewerContext.Provider value={!workbench.isEditable}>
      <WorkbenchErrorBoundary>
        <WorkbenchContent workbench={workbench} drawingId={drawingId || null} />
      </WorkbenchErrorBoundary>
    </isViewerContext.Provider>
  );
};

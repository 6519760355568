import { useTheme } from 'styled-components';
import { CompositeSceneFullData } from '@vizcom/shared/data-access/graphql';
import { capitalize } from '@vizcom/shared/js-utils';
import { Dropdown, Text } from '@vizcom/shared-ui-components';

import { useCompositeSceneSyncedState } from '../../../lib/useCompositeSceneSyncedState';
import { CustomInputRow } from '../compositeSceneMenu/CustomInputRow';
import { ColorInput } from '../ui/ColorInput/ColorInput';
import { Container } from './style';

type CompositeSceneSizeOption = 'landscape' | 'portrait' | 'square';

const CompositeSceneSizeRatio = 1080 / 1920;

export const SceneConfiguration = ({
  compositeScene,
  handleAction,
}: {
  compositeScene: CompositeSceneFullData;
  handleAction: ReturnType<typeof useCompositeSceneSyncedState>['handleAction'];
}) => {
  const theme = useTheme();
  const compositeSceneSize: CompositeSceneSizeOption =
    compositeScene.width === compositeScene.height
      ? 'square'
      : compositeScene.height > compositeScene.width
      ? 'portrait'
      : 'landscape';

  const handleCompositeSceneSizeChange = (size: CompositeSceneSizeOption) => {
    const { width, height } = compositeScene;
    let updatedWidth: number;
    let updatedHeight: number;

    if (size === 'landscape') {
      updatedWidth = Math.max(width, height);
      updatedHeight = updatedWidth * CompositeSceneSizeRatio;
    } else if (size === 'portrait') {
      updatedHeight = Math.max(width, height);
      updatedWidth = updatedHeight * CompositeSceneSizeRatio;
    } else {
      updatedWidth = Math.max(width, height);
      updatedHeight = Math.max(width, height);
    }

    handleAction({
      type: 'updateCompositeScene',
      width: updatedWidth,
      height: updatedHeight,
    });
  };

  return (
    <Container>
      <CustomInputRow
        before={<span style={{ color: theme.text.subtext }}>Size</span>}
      >
        <Dropdown
          options={[
            {
              data: {
                label: 'Landscape',
                value: 'landscape',
              },
            },
            {
              data: {
                label: 'Portrait',
                value: 'portrait',
              },
            },
            {
              data: {
                label: 'Square',
                value: 'square',
              },
            },
          ]}
          value={compositeSceneSize}
          variant="tertiary"
          setValue={(value) =>
            handleCompositeSceneSizeChange(
              value.toLowerCase() as CompositeSceneSizeOption
            )
          }
          OptionComponent={({ option }) => <Text>{option.label}</Text>}
          optionToValueMapper={(option) => option.value}
          buttonProps={{
            style: {
              width: '100%',
              backgroundColor: theme.surface.secondary,
              fontSize: 12,
            },
          }}
        >
          {capitalize(compositeSceneSize)}
        </Dropdown>
      </CustomInputRow>
      <CustomInputRow
        before={<span style={{ color: theme.text.subtext }}>Background</span>}
      >
        <ColorInput
          withPreview
          value={compositeScene.sceneBackgroundColorHex}
          onChange={(hex) => {
            handleAction({
              type: 'updateCompositeScene',
              sceneBackgroundColorHex: hex ?? theme.surface.pagePattern,
            });
          }}
        />
      </CustomInputRow>
    </Container>
  );
};

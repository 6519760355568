import { memo, useMemo } from 'react';
import styled from 'styled-components';
import { Text, RangeInput } from '@vizcom/shared-ui-components';

export interface HistoryEntry {
  objectUrl: string;
  seed: number;
  timestamp: number;
  blob: Blob;
}

export interface RealtimeHistoryProps {
  history: HistoryEntry[];
  currentIndex: number;
  onSelect: (index: number) => void;
  onClear: () => void;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 0;
  user-select: none;
  padding: 4px 12px;
  border-radius: 6px;
  background: ${({ theme }) => `${theme.surface.primary}05`};
  transition: background 0.2s ease-out;
  width: 100%;

  &:hover {
    background: ${({ theme }) => `${theme.surface.primary}08`};
  }
`;

export const RealtimeHistory = memo(
  ({ history, currentIndex, onSelect, onClear }: RealtimeHistoryProps) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newIndex = Math.round(Number(e.target.value));
      onSelect(newIndex);
    };

    const maxValue = useMemo(
      () => Math.max(0, history.length - 1),
      [history.length]
    );

    if (history.length === 0) return null;

    return (
      <Container>
        <Text type="b2" color="subtext">
          History
        </Text>
        <RangeInput
          value={currentIndex}
          onChange={handleChange}
          min={0}
          max={maxValue}
          step={1}
          variant="primary"
        />
      </Container>
    );
  }
);

RealtimeHistory.displayName = 'RealtimeHistory';

import styled from 'styled-components';
import { MagicEraserIcon, EraserIcon } from '@vizcom/shared-ui-components';

import {
  WorkbenchStudioToolType,
  useWorkbenchStudioState,
} from '../../studioState';
import { ToolbarMenuOption } from '../ToolbarOption';

type EraseSettingsProps = {
  onSelect?: () => void;
  isUseCheckmarks?: boolean;
};

export const EraseSettings = ({
  onSelect,
  isUseCheckmarks,
}: EraseSettingsProps) => {
  const { tool, setTool } = useWorkbenchStudioState();

  const isEraserActive = tool === WorkbenchStudioToolType.Eraser;
  const isMagicEraserActive = tool === WorkbenchStudioToolType.MagicEraser;

  const isAnyActive = isEraserActive || isMagicEraserActive;

  return (
    <Container>
      <ToolbarMenuOption
        icon={<EraserIcon />}
        label="Eraser"
        shortcut="E"
        isSpaceForCheckmark={isUseCheckmarks && isAnyActive}
        active={isEraserActive}
        onClick={() => {
          setTool(WorkbenchStudioToolType.Eraser);
          onSelect?.();
        }}
      />
      <ToolbarMenuOption
        icon={<MagicEraserIcon />}
        label="Magic Eraser"
        shortcut="shift+E"
        isSpaceForCheckmark={isUseCheckmarks && isAnyActive}
        active={isMagicEraserActive}
        onClick={() => {
          setTool(WorkbenchStudioToolType.MagicEraser);
          onSelect?.();
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

import {
  Dropdown,
  InferenceStyleOption,
  Text,
} from '@vizcom/shared-ui-components';

type WorkbenchElementTextFontProps = {
  font: string;
  handleSetFontFamily: (font: string) => void;
  setEditing: () => void;
};

export const WorkbenchElementTextFont = ({
  font,
  handleSetFontFamily,
  setEditing,
}: WorkbenchElementTextFontProps) => {
  return (
    <Dropdown
      value={font ?? 'Inter'}
      setValue={handleSetFontFamily}
      onOpenStateChange={(open) => (open ? setEditing() : null)}
      optionToValueMapper={({ value }) => value}
      OptionComponent={InferenceStyleOption}
      options={['Inter', 'Montserrat', 'Open Sans', 'Roboto'].map((font) => ({
        data: { value: font, name: font },
      }))}
      buttonProps={{
        variant: 'secondary',
        style: {
          width: '125px',
          padding: '3px 8px',
          alignSelf: 'stretch',
        },
      }}
    >
      <div style={{ textAlign: 'left' }}>
        <Text type="b2">{font ?? 'Inter'}</Text>
      </div>
    </Dropdown>
  );
};

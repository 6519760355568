import { useState, useEffect, useRef } from 'react';
import styled, { useTheme } from 'styled-components';
import {
  publishTrackingEvent,
  useCurrentUser,
} from '@vizcom/shared/data-access/graphql';
import {
  Button,
  CloseIcon,
  Divider,
  Text,
  TextArea,
  ThumbsDownIcon,
  ThumbsUpIcon,
  Toolbar,
} from '@vizcom/shared-ui-components';

import { ClientSideWorkbenchElementData } from '../../../lib/clientState';

type ModalState = {
  displayed: boolean;
  id?: string;
};

interface PaletteFeedbackCtaProps {
  elements: ClientSideWorkbenchElementData[];
}

const PALETTE_FEEDBACK_TRIGGER_AFTER_N_USAGES = 5;

export const PaletteFeedbackCta = ({ elements }: PaletteFeedbackCtaProps) => {
  const theme = useTheme();
  const user = useCurrentUser();
  const [paletteFeedback, setPaletteFeedback] = useState<ModalState>({
    displayed: false,
  });
  const [liked, setLiked] = useState<boolean | null>(null);
  const [showTextArea, setShowTextArea] = useState(false);
  const [feedback, setFeedback] = useState('');

  const lastPaletteUsageCount = useRef(new Map<string, number>());
  useEffect(() => {
    // this component "listens" to the usage count of the palettes
    // when the usage count reaches a certain threshold, it triggers the feedback modal
    // if this component is not mounted while this threshold is reached, nothing happens
    elements.forEach((element) => {
      if (element.__typename === 'WorkbenchElementPalette') {
        const lastCount = lastPaletteUsageCount.current.get(element.id);
        lastPaletteUsageCount.current.set(element.id, element.usageCount);
        if (
          lastCount &&
          lastCount <= PALETTE_FEEDBACK_TRIGGER_AFTER_N_USAGES &&
          element.usageCount > PALETTE_FEEDBACK_TRIGGER_AFTER_N_USAGES &&
          !!user.data?.id &&
          !!element.createdBy &&
          user.data.id === element.createdBy // only show palette feedback modal to creator
        ) {
          setPaletteFeedback({
            displayed: true,
            id: element.id,
          });
        }
      }
    });
  }, [elements, user.data?.id]);

  const resetState = () => {
    setLiked(null);
    setShowTextArea(false);
    setFeedback('');
  };

  const handleClose = () => {
    setPaletteFeedback({ displayed: false });
    resetState();
  };

  const handleLike = (value: boolean) => {
    setLiked(value);
    setShowTextArea(true);

    if (!paletteFeedback.id) return;

    publishTrackingEvent({
      type: 'PALETTE_RATED',
      data: {
        paletteId: paletteFeedback.id,
        feedback: value ? 'positive' : 'negative',
      },
    });
  };

  const handleSubmit = () => {
    if (paletteFeedback.id) {
      publishTrackingEvent({
        type: 'PALETTE_FEEDBACK_SUBMITTED',
        data: {
          paletteId: paletteFeedback.id,
          feedback: liked ? 'positive' : 'negative',
          feedbackText: feedback,
        },
      });
    }

    setPaletteFeedback({ displayed: false });
    resetState();
  };

  if (!paletteFeedback.displayed) return null;

  return (
    <Toolbar position="bottom-left" style={{ height: 'auto', padding: 0 }}>
      <Container>
        <Header>
          <Text type="sh2" color="headings">
            What do you think of this palette?
          </Text>
          <Button onClick={handleClose} size="icon" variant="tertiary">
            <CloseIcon color="primary" />
          </Button>
        </Header>

        <ButtonGroup>
          <Button
            onClick={() => handleLike(true)}
            variant={liked !== null && liked ? 'primary' : 'secondary'}
          >
            <ThumbsUpIcon /> I like it
          </Button>
          <Button
            onClick={() => handleLike(false)}
            variant="secondary"
            style={{
              color:
                liked !== null && !liked ? theme.surface.danger : 'inherit',
            }}
          >
            <ThumbsDownIcon /> I don't like it
          </Button>
        </ButtonGroup>
        <Text color="subtext" block>
          Your feedback helps us improve the experience.
        </Text>
        {showTextArea && (
          <>
            <FeedbackSection>
              <Divider />
              <Text type="sh2" color="headings">
                {liked
                  ? 'Great! What did you like about the palette?'
                  : `Sorry to hear that. What didn't you like?`}
              </Text>
              <TextArea
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                placeholder="Tell us about your experience"
                rows={4}
                $resize
              />
              <Button variant="primary" onClick={handleSubmit}>
                Submit
              </Button>
            </FeedbackSection>
          </>
        )}
      </Container>
    </Toolbar>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 317px;
  background: ${({ theme }) => theme.surface.modal};
  border-radius: ${({ theme }) => theme.borderRadius.l};
  padding: ${({ theme }) => theme.spacing.m};
  color: ${({ theme }) => theme.text.body};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacing.m};
  margin-top: ${({ theme }) => theme.spacing.m};
  margin-bottom: ${({ theme }) => theme.spacing.m};
`;

const FeedbackSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.m};
  margin-top: ${({ theme }) => theme.spacing.m};
`;

import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PLANS_LIMITS } from '@vizcom/shared/plans-limit';
import {
  SidebarWrapper,
  SidebarItem,
  Button,
  CarretLeftIcon,
  Text,
  useSelectedOrganization,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

export const SettingsSidebar = () => {
  const { data: organization } = useSelectedOrganization();

  return (
    <SidebarWrapper isOpen={true} style={{ gap: 12 }}>
      <Header>
        <Button
          as={Link}
          to={paths.files.dashboard()}
          variant="secondary"
          size="icon"
        >
          <CarretLeftIcon style={{ color: 'white', height: 16, width: 16 }} />
        </Button>
        <Text style={{ fontSize: 18, fontWeight: 600 }}>Settings</Text>
      </Header>
      {organization && (
        <>
          <Text type="sh2" color="subtext">
            Workspace
          </Text>
          <div>
            <SidebarItem
              to={paths.settings.organization.general(organization.id)}
            >
              <Text type="sh1" color="body">
                General
              </Text>
            </SidebarItem>
            <SidebarItem
              to={paths.settings.organization.members(organization.id)}
            >
              <Text type="sh1" color="body">
                Members
              </Text>
            </SidebarItem>
            <SidebarItem
              to={paths.settings.organization.teams(organization.id)}
            >
              <Text type="sh1" color="body">
                Teams
              </Text>
            </SidebarItem>
            {organization.currentUserRole === 'ADMIN' && (
              <>
                <SidebarItem
                  to={paths.settings.organization.subscription(organization.id)}
                >
                  <Text type="sh1" color="body">
                    Plans &amp; Billing
                  </Text>
                </SidebarItem>

                {PLANS_LIMITS[organization.subscriptionPlan]
                  .securitySettings && (
                  <SidebarItem
                    to={paths.settings.organization.security(organization.id)}
                  >
                    <Text type="sh1" color="body">
                      Security
                    </Text>
                  </SidebarItem>
                )}

                {PLANS_LIMITS[organization.subscriptionPlan].logs && (
                  <SidebarItem
                    to={paths.settings.organization.data(organization.id)}
                  >
                    <Text type="sh1" color="body">
                      Data
                    </Text>
                  </SidebarItem>
                )}
              </>
            )}

            {(organization.currentUserRole === 'ADMIN' ||
              organization.currentUserRole === 'EDITOR') && (
              <SidebarItem
                to={paths.settings.organization.palette(organization.id)}
              >
                <Text type="sh1" color="body">
                  Palettes
                </Text>
              </SidebarItem>
            )}
          </div>
        </>
      )}
      <Text type="sh2" color="subtext">
        Account
      </Text>
      <div>
        <SidebarItem to={paths.settings.account.profile()}>
          <Text type="sh1" color="body">
            Profile
          </Text>
        </SidebarItem>
      </div>
      <Text type="sh2" color="subtext">
        App
      </Text>
      <div>
        <SidebarItem to={paths.settings.app.changelog()}>
          <Text type="sh1" color="body">
            Changelog
          </Text>
        </SidebarItem>
      </div>
    </SidebarWrapper>
  );
};

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 28px;
  margin-bottom: 50px;
`;

import { useColorArea } from '@react-aria/color';
import { useFocusRing } from '@react-aria/focus';
import { useColorAreaState, ColorAreaProps } from '@react-stately/color';
import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import {
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  Text,
} from '@vizcom/shared-ui-components';

import { InferenceSettings } from '../useInference';

const SIZE = 175;
const FOCUSED_THUMB_SIZE = 24;
const THUMB_SIZE = 20;

export const FidelityArea = ({
  setInfluence,
  setFidelity,
  ...rest
}: FidelityAreaProps) => {
  const { isDisabled } = rest;

  const inputXRef = React.useRef(null);
  const inputYRef = React.useRef(null);
  const containerRef = React.useRef(null);

  const state = useColorAreaState({
    ...rest,
    xChannel: 'red',
    yChannel: 'green',
    onChange: (value) => {
      const influence = Math.round((value.getChannelValue('red') / 255) * 100);
      const fidelity = Math.round((value.getChannelValue('green') / 255) * 100);
      setInfluence(influence);
      setFidelity(fidelity);
    },
  });

  const { colorAreaProps, xInputProps, yInputProps, thumbProps } = useColorArea(
    // @ts-expect-error - TS doesn't like the spread here
    { ...rest, inputXRef, inputYRef, containerRef },
    {
      ...state,
      xChannelStep: 12.75,
      yChannelStep: 12.75,
      xChannelPageStep: 25.5,
      yChannelPageStep: 25.5,
    }
  );

  const { focusProps, isFocusVisible } = useFocusRing();

  return (
    <ColorAreaContainer
      ref={containerRef}
      {...colorAreaProps}
      style={colorAreaProps.style}
    >
      <DashedLine />
      <ColorAreaContent />
      <Thumb
        {...thumbProps}
        style={thumbProps.style}
        $isFocusVisible={isFocusVisible}
        $isDisabled={!!isDisabled}
      >
        <input ref={inputXRef} {...xInputProps} {...focusProps} />
        <input ref={inputYRef} {...yInputProps} {...focusProps} />
      </Thumb>
    </ColorAreaContainer>
  );
};

export const FidelityAreaWrapper = ({
  fidelity,
  sourceImageInfluence,
  setInferenceSettings,
  isDisabled,
}: {
  fidelity: number;
  sourceImageInfluence: number;
  setInferenceSettings: Dispatch<SetStateAction<InferenceSettings>>;
  isDisabled?: boolean;
}) => {
  return (
    <RichTooltip
      trigger="hover"
      placement="top"
      delay={{
        open: isDisabled ? 500 : 1500,
        close: 0,
      }}
      padding={8}
    >
      <RichTooltipTrigger>
        <WrapperContainer $isDisabled={!!isDisabled}>
          <HeaderContainer>
            <Header>Drawing & Fidelity</Header>
          </HeaderContainer>
          <FidelityLabel $isDisabled={!!isDisabled}>
            <span>{Math.round(fidelity / 5) * 5}%</span> Fidelity
          </FidelityLabel>
          <InfluenceLabel $isDisabled={!!isDisabled}>
            <span>{Math.round(sourceImageInfluence * 100)}%</span> Influence
          </InfluenceLabel>
          <CenterContainer>
            <FidelityArea
              setFidelity={(value) =>
                setInferenceSettings((prev) => ({
                  ...prev,
                  fidelity: value,
                }))
              }
              setInfluence={(value) =>
                setInferenceSettings((prev) => ({
                  ...prev,
                  sourceImageInfluence: value,
                }))
              }
              isDisabled={isDisabled}
            />
          </CenterContainer>
        </WrapperContainer>
      </RichTooltipTrigger>
      {isDisabled && (
        <RichTooltipContent>
          <Text>
            Drawing influence and fidelity adjustments are disabled in this mode
          </Text>
        </RichTooltipContent>
      )}
    </RichTooltip>
  );
};

type FidelityAreaProps = {
  setInfluence: (value: number) => void;
  setFidelity: (value: number) => void;
} & ColorAreaProps;

const ColorAreaContainer = styled.div`
  background: ${({ theme }) => theme.surface.primary};
  width: ${SIZE}px;
  height: ${SIZE}px;
  border-radius: ${({ theme }) => theme.borderRadius.s};
`;

const DashedLine = styled.div`
  width: 100%;
  height: 1px;
  border: none;
  border-top: 1px dashed white;
  position: absolute;
  top: 50%;
`;

const ColorAreaContent = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.s};
  height: ${SIZE}px;
  width: ${SIZE}px;
`;

interface ThumbProps {
  $isFocusVisible: boolean;
  $isDisabled: boolean;
}

const Thumb = styled.div<ThumbProps>`
  background: white;
  border: 2px solid
    ${({ theme, $isDisabled }) =>
      $isDisabled ? theme.icon.secondary : theme.icon.primary};
  border-radius: 50%;
  box-shadow: 0 0 0 1px black, inset 0 0 0 1px black;
  box-sizing: border-box;
  height: ${({ $isFocusVisible }) =>
    $isFocusVisible ? FOCUSED_THUMB_SIZE : THUMB_SIZE}px;
  width: ${({ $isFocusVisible }) =>
    $isFocusVisible ? FOCUSED_THUMB_SIZE : THUMB_SIZE}px;
  transform: translate(-50%, -50%);
`;

const WrapperContainer = styled.div<{
  $isDisabled: boolean;
}>`
  position: relative;
  padding: 20px;
  pointer-events: ${({ $isDisabled }) => ($isDisabled ? 'none' : 'all')};
  opacity: ${({ $isDisabled }) => ($isDisabled ? 0.5 : 1)};
  mask-image: ${({ $isDisabled }) =>
    $isDisabled
      ? 'linear-gradient(to bottom, rgba(21,21,23,1) -50%, transparent 100%)'
      : 'none'};
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
`;

const Header = styled.div`
  display: flex;
  font-weight: 600;
  align-items: center;
  font-size: 12px;
  color: #f9f9fa;
`;

const FidelityLabel = styled.div<{ $isDisabled?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
  transform: rotate(-90deg) translate(-100%);
`;

const InfluenceLabel = styled.div<{ $isDisabled?: boolean }>`
  position: absolute;
  bottom: 0;
  right: 0;
`;

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
`;

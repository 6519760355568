import { useCallback } from 'react';
import {
  useCreateUserProfile,
  useCurrentUser,
} from '@vizcom/shared/data-access/graphql';
import { addToast, formatErrorMessage } from '@vizcom/shared-ui-components';

import { UserProfileSurvey } from './UserProfileSurvey';

export const ConnectedUserProfileSurvey = () => {
  const { data } = useCurrentUser();
  const [, createUserProfile] = useCreateUserProfile();
  const onSubmitProfile = useCallback(
    async (selectedOptions: { [key: number]: string[] }) => {
      const res = await createUserProfile({
        input: {
          userProfile: {
            industry: selectedOptions[0][0],
            usage: selectedOptions[1],
            companySize: selectedOptions[2][0],
          },
        },
      });

      if (res.error) {
        return addToast(
          'Your answers could not be saved, please try again later',
          {
            type: 'danger',
            secondaryText: formatErrorMessage(res.error),
          }
        );
      }

      return addToast('Thank you for your input!', {
        secondaryText: "We'll continue to improve your experience",
      });
    },
    [createUserProfile]
  );

  if (data?.isProfileCompleted) return null;

  return <UserProfileSurvey onSubmit={onSubmitProfile} />;
};

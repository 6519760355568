import { hexToRgba } from 'libs/shared/ui/components/src/lib/ColorPicker/utils/convert';
import { useState } from 'react';
import styled from 'styled-components';
import {
  AssetColorData,
  deleteAssetColor,
  LibraryType,
  updateAssetColor,
  useAssetColor,
  useCreateAssetColor,
  useWorkbenchFoldersIds,
} from '@vizcom/shared/data-access/graphql';
import {
  addToast,
  BlockStack,
  Button,
  CloseIcon,
  ColorPicker,
  ContextMenu,
  ContextMenuItem,
  Divider,
  formatErrorMessage,
  InlineFlex,
  MeatballIcon,
  Menu,
  RelativeDate,
  Text,
  TextInput,
  UserAvatar,
} from '@vizcom/shared-ui-components';

import { useWorkbenchStudioState } from '../../studio/studioState';
import { SaveColorButton } from '../SaveColorButton';
import { assetColorDataToHex } from '../assetLibraryHelpers';
import { triggerCreateConfirmationModal } from './triggerCreateConfirmationModal';

export enum PanelViewMode {
  Edit = 'edit',
  View = 'view',
  Add = 'add',
}

type AssetColorMenuProps = {
  isOpen: boolean;
  workbenchId: string;
  libraryType: LibraryType;
  panelViewMode: PanelViewMode;
  selectedColor: AssetColorData | null;
  setIsColorPanelOpen: (isOpen: boolean) => void;
  setPanelViewMode: (panelViewMode: PanelViewMode) => void;
};

export const AssetColorMenu = ({
  isOpen,
  workbenchId,
  libraryType,
  panelViewMode,
  selectedColor,
  setIsColorPanelOpen,
  setPanelViewMode,
}: AssetColorMenuProps) => {
  const onClickPlusIcon = () => {
    setIsColorPanelOpen(true);
    setPanelViewMode(PanelViewMode.Add);
  };

  return (
    <Menu
      hideArrow
      open={isOpen}
      placement="right-end"
      offset={30}
      renderLabel={(props, interactionProps) => (
        <div {...interactionProps}>
          <SaveColorButton onClick={onClickPlusIcon} title="Add color" />
        </div>
      )}
    >
      {panelViewMode === PanelViewMode.View && selectedColor ? (
        <ViewableColorPanel
          setShowMenuPanel={setIsColorPanelOpen}
          workbenchId={workbenchId}
          libraryType={libraryType}
          panelViewMode={panelViewMode}
          setPanelViewMode={setPanelViewMode}
          selectedColor={selectedColor}
        />
      ) : (
        <EditableColorPanel
          setShowMenuPanel={setIsColorPanelOpen}
          workbenchId={workbenchId}
          libraryType={libraryType}
          panelViewMode={panelViewMode}
          selectedColor={selectedColor}
        />
      )}
    </Menu>
  );
};

type ColorPanelProps = {
  workbenchId: string;
  libraryType: LibraryType;
  panelViewMode: PanelViewMode;
  selectedColor: AssetColorData | null;
  setShowMenuPanel: (showMenuPanel: boolean) => void;
};

const EditableColorPanel = ({
  workbenchId,
  libraryType,
  panelViewMode,
  selectedColor,
  setShowMenuPanel,
}: ColorPanelProps) => {
  const hexSelectedColor = assetColorDataToHex(selectedColor, '#000000');

  const studioState = useWorkbenchStudioState();
  const createAssetColor = useCreateAssetColor(libraryType, workbenchId);
  const { data: workbench } = useWorkbenchFoldersIds(workbenchId);

  const [color, setColor] = useState(hexSelectedColor);
  const [colorName, setColorName] = useState<string>(selectedColor?.name || '');

  if (!workbench) return null;

  const onSaveColor = async () => {
    return panelViewMode === PanelViewMode.Edit ? updateColor() : createColor();
  };

  const createColor = async () => {
    const { r, g, b } = hexToRgba(color);
    const name = colorName !== '' ? colorName : color.toUpperCase();

    const payload = {
      name,
      red: r,
      green: g,
      blue: b,
    };

    try {
      setShowMenuPanel(false);
      await triggerCreateConfirmationModal(libraryType, workbench.orgName);
    } catch {
      // User clicked cancel
      return;
    }

    const res = await createAssetColor(payload);

    if (res.error) {
      return addToast('Failed to save color', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }

    addToast('New color saved', { type: 'success' });
    setShowMenuPanel(false);
  };

  const updateColor = async () => {
    if (!selectedColor) return;

    const { r, g, b } = hexToRgba(color);
    const name = colorName !== '' ? colorName : color.toUpperCase();

    const payload = {
      id: selectedColor.id,
      name,
      red: r,
      green: g,
      blue: b,
    };

    try {
      setShowMenuPanel(false);
      await triggerCreateConfirmationModal(libraryType, workbench.orgName);
    } catch {
      // User clicked cancel
      return;
    }

    const res = await updateAssetColor(libraryType, payload);

    if (res.error) {
      return addToast('Failed to update color', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }

    addToast('Color updated');
    setShowMenuPanel(false);
  };

  const onEyeDropperClicked = () => {
    studioState.setCanvasColorPickerSession({
      currentColor: studioState.color,
      callback: (color) => {
        setColor(color);
      },
    });
  };

  return (
    <Panel>
      <InlineFlex $justifyContent="space-between">
        <Text type="sh2" block>
          {panelViewMode === 'edit' ? 'Edit Color' : 'Save Color'}
        </Text>
        <CloseIcon
          style={{ cursor: 'pointer' }}
          onClick={() => setShowMenuPanel(false)}
        />
      </InlineFlex>
      <TextInput
        $background="secondary"
        placeholder="Color name"
        value={colorName}
        required
        autoFocus
        onChange={(e) => setColorName(e.target.value)}
      />
      <ColorPicker
        color={color}
        onChange={setColor}
        onEyeDropperClicked={onEyeDropperClicked}
      />
      <Divider />
      <InlineFlex>
        <Button
          style={{ flex: 1 }}
          variant="secondary"
          onClick={() => setShowMenuPanel(false)}
        >
          Cancel
        </Button>
        <Button style={{ flex: 1 }} variant="primary" onClick={onSaveColor}>
          Save
        </Button>
      </InlineFlex>
    </Panel>
  );
};

const ViewableColorPanel = ({
  libraryType,
  selectedColor,
  setShowMenuPanel,
  setPanelViewMode,
  workbenchId,
}: ColorPanelProps & {
  selectedColor: AssetColorData;
  setPanelViewMode: (panelViewMode: PanelViewMode) => void;
}) => {
  const studioState = useWorkbenchStudioState();

  const createAssetColor = useCreateAssetColor(libraryType, workbenchId);
  const { data: colorAsset } = useAssetColor(libraryType, selectedColor.id);

  if (!colorAsset) return null;

  const hexSelectedColor = assetColorDataToHex(selectedColor);

  const onUseAsset = () => {
    studioState.setColor(hexSelectedColor);
    setShowMenuPanel(false);
  };

  const createdBy =
    colorAsset.userByCreatedBy?.name ||
    colorAsset.userByCreatedBy?.email ||
    'Unknown';

  const onEditColor = () => {
    setPanelViewMode(PanelViewMode.Edit);
  };

  const onDeleteColor = async () => {
    const res = await deleteAssetColor(libraryType, selectedColor.id);

    if (res.error) {
      return addToast('Failed to delete color', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }

    addToast('Color deleted', {
      type: 'success',
      secondaryText: 'Color deleted successfully',
      duration: 5000,
      cta: {
        text: 'Undo',
        action: async () => {
          await createAssetColor({
            name: selectedColor.name,
            red: selectedColor.red,
            green: selectedColor.green,
            blue: selectedColor.blue,
          });
        },
      },
    });

    setShowMenuPanel(false);
  };

  const contextMenuItems = (
    <BlockStack $gap={0}>
      <ContextMenuItem onClick={onEditColor}>Edit</ContextMenuItem>
      <ContextMenuItem onClick={onDeleteColor}>Delete</ContextMenuItem>
    </BlockStack>
  );

  return (
    <Panel>
      <InlineFlex $justifyContent="space-between">
        <Text type="sh2" block>
          Details
        </Text>
        <InlineFlex $gap={12} style={{ width: 'fit-content' }}>
          <ContextMenu style={{ minWidth: '90px' }} items={contextMenuItems}>
            <MeatballIcon style={{ cursor: 'pointer' }} />
          </ContextMenu>
          <CloseIcon
            style={{ cursor: 'pointer' }}
            onClick={() => setShowMenuPanel(false)}
          />
        </InlineFlex>
      </InlineFlex>
      <BlockStack $gap={12}>
        <ColorPreviewContainer>
          <ColorPreview color={hexSelectedColor} />
        </ColorPreviewContainer>

        {/* Name */}
        <InlineFlex $gap={8}>
          <Text type="b1" color="subtext" style={{ width: '40px' }}>
            Name
          </Text>
          <Text type="b1" block>
            {selectedColor.name}
          </Text>
        </InlineFlex>

        {/* Hex */}
        <InlineFlex $gap={8}>
          <Text type="b1" color="subtext" style={{ width: '40px' }}>
            Hex
          </Text>
          <Text type="b1" block>
            {hexSelectedColor.toUpperCase()}
          </Text>
        </InlineFlex>

        {/* Created by */}
        <InlineFlex $gap={8}>
          <UserAvatar
            name={createdBy}
            userId={colorAsset.userByCreatedBy?.id || ''}
            size="medium"
          />
          <BlockStack $gap={2}>
            <Text type="b1" color="subtext" block>
              {createdBy}
            </Text>
            <Text type="b1" color="subtext" block>
              <RelativeDate date={colorAsset.createdAt} />
            </Text>
          </BlockStack>
        </InlineFlex>

        <Button variant="primary" onClick={onUseAsset}>
          Use asset
        </Button>
      </BlockStack>
    </Panel>
  );
};

const Panel = styled.div`
  width: 255px;
  padding: 17px;
  background-color: ${({ theme }) => theme.surface.primary};
  border-radius: ${({ theme }) => theme.borderRadius.l};
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ColorPreviewContainer = styled(InlineFlex)`
  background-color: ${({ theme }) => theme.surface.secondary};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  padding-block: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ColorPreview = styled.div<{ color: string }>`
  width: 72px;
  height: 72px;
  background-color: ${({ color }) => color};
  border-radius: ${({ theme }) => theme.borderRadius.m};
`;

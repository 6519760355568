import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import {
  Button,
  MeatballIcon,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
} from '@vizcom/shared-ui-components';

import { EditPublishedPaletteModal } from '../../elements/palette/PublishPaletteModal';
import { useRemovePaletteFromLibrary } from '../../elements/palette/helpers';
import { Palette } from './PaletteSelector';

export const MyLibraryPaletteMenu = ({ palette }: { palette: Palette }) => {
  const theme = useTheme();
  const [showPublishedPaletteModal, setShowPublishedPaletteModal] =
    useState(false);
  const { handleRemovePaletteFromLibrary } = useRemovePaletteFromLibrary(
    palette.id
  );

  return (
    <>
      <RichTooltip trigger="click" noParentIntegration displayArrow={false}>
        <RichTooltipTrigger>
          <div
            style={{
              marginLeft: 'auto',
              display: 'block',
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <MeatballIcon />
          </div>
        </RichTooltipTrigger>
        <RichTooltipContent
          $background="tertiary"
          style={{
            padding: '8px',
            borderRadius: theme.borderRadius.l,
          }}
        >
          <Button
            variant="tertiary"
            onClick={(e) => {
              e.stopPropagation();
              setShowPublishedPaletteModal(true);
            }}
          >
            Edit details
          </Button>
          <Button
            variant="tertiary"
            onClick={(e) => {
              e.stopPropagation();
              handleRemovePaletteFromLibrary();
            }}
          >
            Remove...
          </Button>
        </RichTooltipContent>
      </RichTooltip>
      {showPublishedPaletteModal && (
        <EditPublishedPaletteModal
          onClose={() => setShowPublishedPaletteModal(false)}
          element={palette}
        />
      )}
    </>
  );
};

import * as Sentry from '@sentry/react';
import { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import {
  meshesByIdsQuery,
  publishTrackingEvent,
  urqlClient,
  useUpscaleImage,
} from '@vizcom/shared/data-access/graphql';
import { StudioEventName } from '@vizcom/shared/data-shape';
import { filterExists } from '@vizcom/shared/js-utils';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import {
  addToast,
  downloadFile,
  createImageElementWithSrc,
  urlToBlob,
  zipFiles,
  FileToZip,
  imageDataToBlob,
  formatErrorMessage,
  imageToBlob,
  usePaywallModalState,
} from '@vizcom/shared-ui-components';

import { Drawing2dStudio } from '../../../lib/useDrawingSyncedState';
import { useLayersCompositor } from '../LayersCompositor/context';
import { runExportLayersVideo } from '../Toolbar/export/exportLayersVideo';
import { getLayeredPSD } from '../Toolbar/export/getLayeredPSD';

const VIZCOM_SVG_LOGO_COLOR = '#011414';
const VIZCOM_SVG_LOGO = `<svg width="195" height="34" viewBox="0 0 195 34" fill="${VIZCOM_SVG_LOGO_COLOR}" xmlns="http://www.w3.org/2000/svg">...</svg>`;

export const useExportUtils = (
  drawing: Drawing2dStudio,
  isFreePlan: boolean
) => {
  const [isExporting, setExporting] = useState(false);
  const upscaleImage = useUpscaleImage();
  const { trigger } = usePaywallModalState();
  const layersCompositor = useLayersCompositor();

  const handleExportToVideoGenerator = useCallback(
    (blockFreePlan: boolean) => async () => {
      if (blockFreePlan && isFreePlan) {
        trigger('freeToPro');
        return;
      }

      // Show loading toast
      const loadingToastId = addToast('Exporting video...', {
        type: 'loading',
      });

      try {
        if (typeof VideoEncoder === 'undefined') {
          throw new Error(
            'Your browser does not meet all the requirements to export the video. Please use a recent version of Chrome.'
          );
        }

        const videoName =
          drawing.name || drawing.workbench?.name || 'export-vizcom-layers';

        const base64Logo = window.btoa(VIZCOM_SVG_LOGO);
        const image = await createImageElementWithSrc(
          `data:image/svg+xml;base64,${base64Logo}`
        );
        const watermarkImage = await createImageBitmap(image);

        const blob = await runExportLayersVideo(
          drawing,
          isFreePlan,
          watermarkImage
        );

        downloadFile(blob, videoName, 'mp4');

        // Dismiss loading toast
        toast.dismiss(loadingToastId);

        // Show success toast
        addToast('Your video is ready. Please check your downloads.');

        trackEvent('Video Export', { type: 'exportLayersVideo' });
        publishTrackingEvent({
          type: StudioEventName.EXPORT,
          data: {
            exportType: 'VIDEO',
          },
        });
      } catch (error) {
        // Dismiss loading toast
        toast.dismiss(loadingToastId);

        // Capture the error with Sentry
        Sentry.captureException(error, {
          extra: {
            drawingId: drawing.id,
            workbenchId: drawing.workbench?.id,
          },
        });

        // Format and show error toast
        const message = formatErrorMessage(
          error,
          'An error occurred while exporting the video'
        );

        addToast(message, { type: 'danger' });
      } finally {
        setExporting(false);
      }
    },
    [drawing, isFreePlan, trigger]
  );

  const handleExportToPngGenerator = useCallback(
    (upscale: boolean) => async () => {
      if (upscale && isFreePlan) {
        trigger('freeToPro');
        return;
      }

      setExporting(true);

      try {
        let compositeImage: Blob | string = await imageDataToBlob(
          layersCompositor.getCompositedImage()
        );
        if (upscale) {
          compositeImage = await upscaleImage(drawing.id, compositeImage);
        }
        const imageName =
          drawing.name || drawing.workbench?.name || 'export-vizcom-layers';
        downloadFile(compositeImage, imageName, 'png');

        addToast('Your image is ready. Please check your downloads.');
        trackEvent('Image Export', { type: 'exportLayersImage' });
        publishTrackingEvent({
          type: StudioEventName.EXPORT,
          data: {
            exportType: 'PNG',
            upscale,
          },
        });
      } catch (error) {
        const message = formatErrorMessage(
          error,
          'An error occurred while exporting the image'
        );

        addToast(message, { type: 'danger' });
      }

      setExporting(false);
    },
    [drawing, isFreePlan, trigger, upscaleImage, layersCompositor]
  );

  const exportPSD = useCallback(async () => {
    setExporting(true);

    try {
      const layeredPSD = await getLayeredPSD(drawing);
      const psdName =
        drawing.name || drawing.workbench?.name || 'export-vizcom-layers';

      downloadFile(layeredPSD, psdName, 'psd');

      addToast('Your PSD is ready. Please check your downloads.');
      trackEvent('PSD Export', { type: 'exportLayersPSD' });
      publishTrackingEvent({
        type: StudioEventName.EXPORT,
        data: {
          exportType: 'PSD',
        },
      });
    } catch (error) {
      const message = formatErrorMessage(
        error,
        'An error occurred while exporting the PSD'
      );

      addToast(message, { type: 'danger' });
    }

    setExporting(false);
  }, [drawing]);

  const exportAllModels = useCallback(async () => {
    if (isFreePlan) {
      trigger('freeToPro');
      return;
    }

    setExporting(true);

    try {
      const meshes = [] as {
        name: string;
        url: string;
        isObjectUrl: boolean;
      }[];
      const externalMeshesId = drawing.layers.nodes
        .map((layer) => layer.metadata3D?.mesh)
        .filter(filterExists);
      if (externalMeshesId.length) {
        const res = await urqlClient.query(meshesByIdsQuery, {
          ids: externalMeshesId,
        });
        if (res.error || !res.data?.meshes) {
          addToast('An error occurred while exporting the models', {
            type: 'danger',
          });
          setExporting(false);
          return;
        }
        meshes.push(
          ...res.data.meshes.nodes.map((mesh) => ({
            name: mesh.name,
            url: mesh.path,
            isObjectUrl: false,
          }))
        );
      }

      const internalMeshes = drawing.layers.nodes
        .map((layer) => {
          if (layer.meshPath) {
            const url =
              layer.meshPath instanceof Blob
                ? URL.createObjectURL(layer.meshPath)
                : layer.meshPath;

            return {
              name: layer.name,
              isObjectUrl: layer.meshPath instanceof Blob,
              url,
            };
          }
          return null;
        })
        .filter(filterExists);
      meshes.push(...internalMeshes);

      if (meshes.length === 0) {
        addToast('No models to export');
        return;
      }

      const files: FileToZip[] = await Promise.all(
        meshes.map(async (mesh, index) => ({
          name: `${mesh.name}-${index}.glb`,
          data: await urlToBlob(mesh.url),
        }))
      );

      const zipBlob = await zipFiles(files);
      const zipName =
        drawing.name || drawing.workbench?.name || 'export-vizcom-models';

      downloadFile(zipBlob, `${zipName}-models-export`, 'zip');
      addToast('Your models are ready. Please check your downloads.');

      internalMeshes.forEach((mesh) => {
        if (mesh.isObjectUrl) {
          URL.revokeObjectURL(mesh.url);
        }
      });

      trackEvent('3D Export', { type: 'exportLayers3D' });
      publishTrackingEvent({
        type: StudioEventName.EXPORT,
        data: {
          exportType: '3D',
        },
      });
    } finally {
      setExporting(false);
    }
  }, [drawing, isFreePlan, trigger]);

  const exportAll2DLayers = useCallback(async () => {
    setExporting(true);

    try {
      const layers = drawing.layers.nodes.filter((layer) => layer.imagePath);
      const zipName =
        drawing.name || drawing.workbench?.name || 'export-vizcom-layers';
      await exportLayersAsZip(layers, zipName);
    } finally {
      setExporting(false);
    }
  }, [drawing]);

  return {
    isExporting,
    handleExportToVideo: handleExportToVideoGenerator(false),
    handleExportToWatermarkFreeVideo: handleExportToVideoGenerator(true),
    handleExportToPng: handleExportToPngGenerator(false),
    handleExportToUpscaledPng: handleExportToPngGenerator(true),
    exportPSD,
    exportAllModels,
    exportAll2DLayers,
  };
};

export const exportLayersAsZip = async (
  layers: Drawing2dStudio['layers']['nodes'],
  zipName: string
): Promise<void> => {
  if (layers.length === 0) {
    addToast('No layers to export');
    return;
  }

  const processedLayers = [];

  // Process layers
  for (const layer of layers) {
    // Handle 2D layers
    if (layer.imagePath && !layer.meshPath && !layer.metadata3D?.mesh) {
      processedLayers.push({
        name: layer.name,
        url: await imageToBlob(layer.imagePath),
        isObjectUrl: false,
        is3D: false,
      });
      continue;
    }

    // Handle direct mesh paths
    if (layer.meshPath) {
      processedLayers.push({
        name: layer.name,
        url:
          layer.meshPath instanceof Blob
            ? URL.createObjectURL(layer.meshPath)
            : layer.meshPath,
        isObjectUrl: layer.meshPath instanceof Blob,
        is3D: true,
      });
    }

    // Handle mesh metadata
    if (layer.metadata3D?.mesh) {
      try {
        const res = await urqlClient.query(meshesByIdsQuery, {
          ids: layer.metadata3D.mesh,
        });

        if (!res.data?.meshes) {
          throw new Error('Failed to fetch mesh data');
        }

        processedLayers.push(
          ...res.data.meshes.nodes.map((mesh) => ({
            name: mesh.name,
            url: mesh.path,
            isObjectUrl: false,
            is3D: true,
          }))
        );
      } catch (error) {
        addToast('An error occurred while exporting the models', {
          type: 'danger',
        });
        return;
      }
    }
  }

  try {
    // Convert layers to zip files
    const files: FileToZip[] = await Promise.all(
      processedLayers.map(async (layer, index) => ({
        name: `${layer.name}-${index}.${layer.is3D ? 'glb' : 'png'}`,
        data:
          layer.url instanceof Blob ? layer.url : await urlToBlob(layer.url),
      }))
    );

    const zipBlob = await zipFiles(files);
    downloadFile(zipBlob, `${zipName}-layers-export`, 'zip');

    addToast('Your layers are ready. Please check your downloads.');

    // Track events
    trackEvent('2D Export', { type: 'exportLayers2D' });
    publishTrackingEvent({
      type: StudioEventName.EXPORT,
      data: { exportType: 'ZIP' },
    });
  } catch (error) {
    addToast('An error occurred while creating the zip file', {
      type: 'danger',
    });
  }
};

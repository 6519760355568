import { Navigate, Route, Routes } from 'react-router-dom';
import { LoggedInAuthGuard } from '@vizcom/auth/ui';

import { AcceptOrganizationInvitePage } from './AcceptOrganizationInvitePage';
import { CreateOrganizationPage } from './CreateOrganizationPage/createOrganizationPage';

export const OrganizationRoutes = () => {
  return (
    <LoggedInAuthGuard>
      <Routes>
        <Route path="create/*" element={<CreateOrganizationPage />} />
        {/*
          The parameter in this route needs to be something else than organizationId
          else `useSelectedOrganization` will try to load it while loading the invitation which will cause a race condition
         */}
        <Route
          path=":invitedOrganizationId/acceptinvite"
          element={<AcceptOrganizationInvitePage />}
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </LoggedInAuthGuard>
  );
};

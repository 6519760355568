import { MeshProps } from '@react-three/fiber';
import { forwardRef } from 'react';
import { DoubleSide, Mesh } from 'three';

interface Props {
  drawingSize: [number, number];
  eventMeshProps: MeshProps;
  userData?: {
    cursor?: string;
  };
}

export const EventMesh = forwardRef<Mesh, Props>(
  ({ drawingSize, eventMeshProps, userData }: Props, ref) => {
    return (
      <>
        <mesh
          ref={ref}
          scale={[drawingSize[0], drawingSize[1], 1]}
          userData={userData}
        >
          <planeGeometry args={[1, 1, 1, 1]} />
          <meshBasicMaterial transparent opacity={0} side={DoubleSide} />
        </mesh>

        <mesh
          scale={[drawingSize[0] * 20, drawingSize[1] * 20, 1]}
          {...eventMeshProps}
        >
          <planeGeometry args={[1, 1, 1, 1]} />
          <meshBasicMaterial transparent opacity={0} side={DoubleSide} />
        </mesh>
      </>
    );
  }
);

import {
  OrganizationData,
  useRequestDeleteOrganizationConfirmationToken,
} from '@vizcom/shared/data-access/graphql';
import {
  Button,
  Text,
  addToast,
  formatErrorMessage,
  useCrisp,
} from '@vizcom/shared-ui-components';

import { SettingsPageDivider } from './SettingsPageLayout';

export const OrganizationDeleteSetting = ({
  organization,
}: {
  organization: OrganizationData;
}) => {
  const crisp = useCrisp();
  const [{ fetching, data }, requestDeleteOrganizationConfirmationToken] =
    useRequestDeleteOrganizationConfirmationToken();

  const handleContactSupport = () => {
    crisp.openChat();
  };

  if (organization.currentUserRole !== 'ADMIN') {
    return null;
  }

  const handleDelete = async () => {
    const res = await requestDeleteOrganizationConfirmationToken({
      input: {
        organizationId: organization.id,
      },
    });
    if (res.error) {
      return addToast('Error while deleting workspace', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }

    addToast('Organization deletion request sent', {
      secondaryText:
        'An email has been sent to your email address with a link to confirm the deletion of this workspace.',
    });
  };

  return (
    <>
      <SettingsPageDivider />
      <div style={{ position: 'relative' }}>
        <Text block type="sh1" style={{ marginBottom: 16 }}>
          Delete workspace
        </Text>

        <Text
          block
          type="b1"
          color="subtext"
          style={{ marginBottom: 16, maxWidth: 540 }}
        >
          Deleting a workspace will permanently delete all of its data,
          including all files and folders. This action is irreversible.
        </Text>

        {organization.currentUserRole !== 'ADMIN' ? (
          <Text color="subtext">Only an admin can do this.</Text>
        ) : organization.manualSubscription ? (
          <Button type="button" variant="danger" onClick={handleContactSupport}>
            Contact support
          </Button>
        ) : (
          <Button
            type="button"
            variant="danger"
            onClick={handleDelete}
            disabled={fetching || !!data}
          >
            Delete "{organization.name}"
          </Button>
        )}
      </div>
    </>
  );
};

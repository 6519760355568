export const DEFAULT_GAP = 11;
const ROW_GAP = 25;
/**
 * This function calculates a masonry layout for a set of elements.
 * Instead of simply placing each element in the shortest column,
 * it attempts to find the best fit for each element based on its height.
 *
 * @param elements - The elements to position.
 * @param columns - The number of columns in the layout.
 * @param columnGap - The gap between columns.
 * @param rowGap - The gap between rows.
 * @returns The positions for the elements.
 */
export function masonryLayoutBestFit(
  elements: {
    id: string;
    width: number;
    height: number;
    x: number;
    y: number;
  }[],
  columns: number,
  columnGap: number = DEFAULT_GAP,
  rowGap: number = ROW_GAP
) {
  // Calculate the minimum x and y coordinates of all elements
  const { minX, maxY } = elements.reduce(
    (box, el) => ({
      minX: Math.min(box.minX, el.x - el.width / 2),
      maxY: Math.max(box.maxY, el.y + el.height / 2),
    }),
    { minX: Infinity, maxY: -Infinity }
  );

  // Initialize arrays to keep track of column widths and heights
  const columnWidths = new Array(columns).fill(0);
  const columnHeights = new Array(columns).fill(0);
  const newData: {
    id: string;
    x: number;
    y: number;
    width: number;
    height: number;
  }[] = [];
  // Sort elements by width largest to smallest
  const sortedElements = [...elements].sort((a, b) => b.width - a.width);

  // Place each element in the best fitting column
  for (const element of sortedElements) {
    let bestColumnIndex = 0;
    let bestY = Infinity;

    // Find the column with the lowest height
    for (let i = 0; i < columns; i++) {
      const y = columnHeights[i];
      if (y < bestY) {
        bestY = y;
        bestColumnIndex = i;
      }
    }

    // Calculate the x position based on the chosen column
    const x =
      minX +
      columnWidths.slice(0, bestColumnIndex).reduce((a, b) => a + b, 0) +
      bestColumnIndex * columnGap;
    const y = maxY - bestY;

    // Add the new element position to the result array
    newData.push({
      id: element.id,
      x: x + element.width / 2,
      y: y - element.height / 2,
      width: element.width,
      height: element.height,
    });

    // Update the height and width of the chosen column
    columnHeights[bestColumnIndex] += element.height + rowGap;
    columnWidths[bestColumnIndex] = Math.max(
      columnWidths[bestColumnIndex],
      element.width
    );
  }

  return newData;
}

/**
 * Calculates the grid layout for a set of elements within a container.
 *
 * @param elements - An array of elements with their dimensions and aspect ratios.
 * @param containerWidth - The width of the container.
 * @param containerHeight - The height of the container.
 * @param gap - The gap between elements in the grid (default: 10).
 * @returns An array of objects representing the layout of each element in the grid.
 */
export function calculateGridLayout(
  elements: {
    id: string;
    width: number;
    height: number;
    aspectRatio: number;
  }[],
  containerWidth: number,
  containerHeight: number,
  gap: number = DEFAULT_GAP
) {
  // Initialize variables
  let columns = 1;

  let layout = [];
  // Loop until we find a layout that fits all elements
  while (layout.length < elements.length) {
    // Reset layout array
    layout = [];
    // Calculate the width of each column including the gaps
    const columnWidth = (containerWidth - gap * (columns + 1)) / columns;

    // Initialize an array to store the height of each column
    const columnHeights = new Array(columns).fill(0);

    // Initialize a flag to check if all elements fit in the current layout
    let allFit = true;

    // Loop through each element
    for (const el of elements) {
      // Calculate the scaled height based on the aspect ratio and column width
      const scaledHeight = columnWidth / el.aspectRatio;

      // Find the shortest column
      const shortestColumnIndex = columnHeights.indexOf(
        Math.min(...columnHeights)
      );

      // Calculate the x and y coordinates for the element
      const x = gap + shortestColumnIndex * (columnWidth + gap);
      const y = gap + columnHeights[shortestColumnIndex];

      // Check if the element fits within the container height
      if (y + scaledHeight > containerHeight - gap * 2) {
        // If the element doesn't fit, set the flag to false and break the loop
        allFit = false;
        break;
      }

      // Add the element's position and dimensions to the temporary layout array
      layout.push({
        id: el.id,
        x: x + columnWidth / 2,
        y: y + scaledHeight / 2,
        width: columnWidth,
        height: scaledHeight,
      });

      // Update the height of the shortest column
      columnHeights[shortestColumnIndex] += scaledHeight + gap;
    }

    // If all elements fit in the current layout, return the layout
    if (allFit) {
      return layout;
    }

    // If not all elements fit, increment the number of columns and repeat the process
    columns++;
  }
  return layout;
}

import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { OrganizationSubscriptionPlan } from '@vizcom/shared/plans-limit';
import { Banner, Button, TeamIcon, Text } from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

interface CreateOrganizationSuccessPageProps {
  organizationGlobalTeamId: string;
  subscriptionPlan: OrganizationSubscriptionPlan;
}

export const CreateOrganizationSuccessPage = ({
  organizationGlobalTeamId,
  subscriptionPlan,
}: CreateOrganizationSuccessPageProps) => {
  return (
    <Container>
      {subscriptionPlan.toUpperCase() === OrganizationSubscriptionPlan.Edu && (
        <Banner
          style={{ textAlign: 'left' }}
          icon={<TeamIcon width={30} />}
          message="You joined the Vizcom Education program."
          secondaryMessage="You benefit from free access to the pro Vizcom features."
          type="default"
        />
      )}

      <Text block type="h1">
        You're all set
      </Text>
      <Text>
        We sent you an email to help you get started. It contains helpful video
        tutorials and links to download our Vizcom desktop app.
      </Text>

      <Text block>
        You can update workspace roles on the members page after setting up.
      </Text>

      <Button
        variant="primary"
        as={Link}
        to={paths.files.team(organizationGlobalTeamId)}
      >
        Continue to Workspace
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  gap: 32px;
  text-align: center;
`;

import { SelectIcon, WarpIcon } from '@vizcom/shared-ui-components';

import {
  WorkbenchStudioToolType,
  useWorkbenchStudioState,
} from '../../studioState';
import { ToolbarMenuOption } from '../ToolbarOption';

type TransformSettingsProps = {
  onSelect?: () => void;
  isShowCheckmarks?: boolean;
};

export const TransformSettings = ({
  onSelect,
  isShowCheckmarks,
}: TransformSettingsProps) => {
  const { tool, setTool } = useWorkbenchStudioState();

  const isMoveActive = tool === WorkbenchStudioToolType.Move;
  const isTransformActive = tool === WorkbenchStudioToolType.Transform;
  const isAnyActive = isMoveActive || isTransformActive;

  return (
    <>
      <ToolbarMenuOption
        icon={<SelectIcon />}
        label="Move"
        shortcut="M"
        isSpaceForCheckmark={isShowCheckmarks && isAnyActive}
        active={isMoveActive}
        onClick={() => {
          setTool(WorkbenchStudioToolType.Move);
          onSelect?.();
        }}
      />
      <ToolbarMenuOption
        icon={<WarpIcon />}
        label="Transform"
        shortcut="T"
        isSpaceForCheckmark={isShowCheckmarks && isAnyActive}
        active={isTransformActive}
        onClick={() => {
          setTool(WorkbenchStudioToolType.Transform);
          onSelect?.();
        }}
      />
    </>
  );
};

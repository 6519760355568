// FROM https://raw.githubusercontent.com/pmndrs/drei/master/src/web/Html.tsx
// Modified to fix a bug with the occlusion and orthographic camera

import { useContextBridge } from '@react-three/drei';
import {
  ReactThreeFiber,
  useFrame,
  useThree,
  context,
} from '@react-three/fiber';
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import {
  UNSAFE_LocationContext,
  UNSAFE_NavigationContext,
} from 'react-router-dom';
import { useRecoilBridgeAcrossReactRoots_UNSTABLE } from 'recoil';
import { ThemeContext } from 'styled-components';
import {
  Vector3,
  Group,
  Object3D,
  Matrix4,
  Camera,
  OrthographicCamera,
  DoubleSide,
  Mesh,
  NoBlending,
} from 'three';
import { Context as UrqlContext } from 'urql';
import { Assign } from 'utility-types';

import { isDraggingContext, isViewerContext } from '../../lib/utils';
import { DEFAULT_Z_INDEX_RANGE } from '../helpers';
import { SelectionApiContext } from '../studio/selection/useSelectionApi';
import { sortObjectsByHierarchicalRenderOrder } from './threeRenderingOrder';

const v1 = new Vector3();

// When adding HTML elements, we need to keep track of them and their associated threejs objects
// to know which z-index to assign to them in order for the DOM elements to be rendered in the same order as their associated threejs objects
// this is done by keeping track of every HTML element, ordering their threejs object with the sortObjectsByHierarchicalRenderOrder method
// and then assigning their z-index based on their order in this array
const customHtmlElementsStore = new Map<
  HTMLElement,
  { object: Object3D; aboveCanvas: boolean }
>();
const refreshCustomHtmlElementsZIndex = () => {
  const orderedElements = Array.from(customHtmlElementsStore.entries()).sort(
    ([_, a], [_2, b]) => sortObjectsByHierarchicalRenderOrder(a, b)
  );

  orderedElements.forEach(([element, object], index) => {
    element.style.zIndex = `${
      index + Math.floor(object.aboveCanvas ? DEFAULT_Z_INDEX_RANGE[0] / 2 : 0)
    }`;
  });
};

function calculatePosition(
  el: Object3D,
  camera: Camera,
  size: { width: number; height: number }
) {
  const objectPos = v1.setFromMatrixPosition(el.matrixWorld);
  objectPos.project(camera);
  const widthHalf = size.width / 2;
  const heightHalf = size.height / 2;
  return [
    objectPos.x * widthHalf + widthHalf,
    -(objectPos.y * heightHalf) + heightHalf,
  ];
}

const epsilon = (value: number) => (Math.abs(value) < 1e-10 ? 0 : value);

function getCSSMatrix(matrix: Matrix4, multipliers: number[], prepend = '') {
  let matrix3d = 'matrix3d(';
  for (let i = 0; i !== 16; i++) {
    matrix3d +=
      epsilon(multipliers[i] * matrix.elements[i]) + (i !== 15 ? ',' : ')');
  }
  return prepend + matrix3d;
}

const getCameraCSSMatrix = ((multipliers: number[]) => {
  return (matrix: Matrix4) => getCSSMatrix(matrix, multipliers);
})([1, -1, 1, 1, 1, -1, 1, 1, 1, -1, 1, 1, 1, -1, 1, 1]);

const getObjectCSSMatrix = ((scaleMultipliers: (n: number) => number[]) => {
  return (matrix: Matrix4, factor: number) =>
    getCSSMatrix(matrix, scaleMultipliers(factor), 'translate(-50%,-50%)');
})((f: number) => [
  1 / f,
  1 / f,
  1 / f,
  1,
  -1 / f,
  -1 / f,
  -1 / f,
  -1,
  1 / f,
  1 / f,
  1 / f,
  1,
  1,
  1,
  1,
  1,
]);

export interface CustomHtmlProps
  extends Omit<
    Assign<
      React.HTMLAttributes<HTMLDivElement>,
      ReactThreeFiber.Object3DNode<Group, typeof Group>
    >,
    'ref'
  > {
  transform?: boolean;
  as?: string;

  // This is a reference to the object3D that has its scale changed while the user is resizing,
  // this is used both to check if the CustomHtml should rerender and
  // to constrain the matrix to ensure the element is not stretched
  parentScale?: Object3D | null;

  // Occlusion based off work by Jerome Etienne and James Baicoianu
  // https://www.youtube.com/watch?v=ScZcUEDGjJI
  // as well as Joe Pea in CodePen: https://codepen.io/trusktr/pen/RjzKJx
  occlude?: boolean;
  scaleOcclusionGeometry?: boolean; // Scale occlusion geometry to match the size of the HTML element, geometry should have a size of 1x1
  geometry?: React.ReactNode; // Geometry for occlusion plane
}

export const CustomHtml = React.forwardRef(
  (
    {
      children,
      style,
      className,
      transform = false,
      occlude,
      geometry,
      parentScale,
      as = 'div',
      scaleOcclusionGeometry = true,
      ...props
    }: CustomHtmlProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const { gl, camera, scene, size, events, viewport } = useThree();

    // VIZCOM MODIFIED: inject these context in the HTML element
    const RecoilBridge = useRecoilBridgeAcrossReactRoots_UNSTABLE();
    const ContextBridge = useContextBridge(
      ThemeContext,
      UNSAFE_LocationContext,
      UNSAFE_NavigationContext,
      isDraggingContext,
      isViewerContext,
      UrqlContext,
      SelectionApiContext,
      context // allow using useThree inside the html component
    );

    const [el] = React.useState(() => document.createElement(as));
    const root = React.useRef<ReactDOM.Root>();
    const group = React.useRef<Group>(null!);
    const oldZoom = React.useRef(0);
    const oldPosition = React.useRef([0, 0]);
    const oldCameraPosition = React.useRef([0, 0]);
    const transformOuterRef = React.useRef<HTMLDivElement>(null!);
    const transformInnerRef = React.useRef<HTMLDivElement>(null!);
    const isRendered = React.useRef(false);

    // Append to the connected element, which makes HTML work with views
    const target = (events.connected ||
      gl.domElement.parentNode) as HTMLElement;

    const occlusionMeshRef = React.useRef<Mesh>(null!);
    const isMeshSizeSet = React.useRef<boolean>(false);

    React.useLayoutEffect(() => {
      const el = gl.domElement as HTMLCanvasElement;

      el.style.zIndex = `${Math.floor(DEFAULT_Z_INDEX_RANGE[0] / 2)}`;
      el.style.position = 'absolute';
      el.style.pointerEvents = 'none';
    }, [gl.domElement]);

    React.useLayoutEffect(() => {
      if (group.current) {
        const currentRoot = (root.current = ReactDOM.createRoot(el));
        scene.updateMatrixWorld();
        if (transform) {
          el.style.cssText = `position:absolute;top:0;left:0;pointer-events:none;overflow:hidden;`;
        } else {
          const vec = calculatePosition(group.current, camera, size);
          el.style.cssText = `position:absolute;top:0;left:0;transform:translate3d(${vec[0]}px,${vec[1]}px,0);transform-origin:0 0;`;
        }
        if (target) {
          target.appendChild(el);
        }
        customHtmlElementsStore.set(el, {
          object: group.current,
          aboveCanvas: !occlude,
        });
        return () => {
          if (target) target.removeChild(el);
          currentRoot.unmount();
          customHtmlElementsStore.delete(el);
        };
      }
    }, [target, transform, el]);

    const styles: React.CSSProperties = React.useMemo(() => {
      if (transform) {
        return {
          position: 'absolute',
          top: 0,
          left: 0,
          width: size.width,
          height: size.height,
          transformStyle: 'preserve-3d',
          pointerEvents: 'none',
        };
      } else {
        return {
          position: 'absolute',
          // transform: center ? 'translate3d(-50%,-50%,0)' : 'none',
          ...style,
        };
      }
    }, [style, size, transform]);

    React.useLayoutEffect(() => {
      isMeshSizeSet.current = false;

      if (transform) {
        root.current?.render(
          <RecoilBridge>
            <ContextBridge>
              <div ref={transformOuterRef} style={styles}>
                <div
                  ref={transformInnerRef}
                  style={{ position: 'absolute', pointerEvents: 'auto' }}
                >
                  <div
                    ref={ref}
                    className={className}
                    style={style}
                    children={children}
                  />
                </div>
              </div>
            </ContextBridge>
          </RecoilBridge>
        );
      } else {
        root.current?.render(
          <RecoilBridge>
            <ContextBridge>
              <div
                ref={ref}
                style={styles}
                className={className}
                children={children}
              />
            </ContextBridge>
          </RecoilBridge>
        );
      }
    });

    useFrame((gl) => {
      if (group.current) {
        camera.updateMatrixWorld();
        group.current.updateWorldMatrix(true, false);
        const vec = calculatePosition(group.current, camera, size);

        // on each frame, refresh the zIndex of all html elements
        // this needs to be done every frame because the other meshs could have changed their render order
        // and so we need to update the zIndex of this element
        customHtmlElementsStore.set(el, {
          object: group.current,
          aboveCanvas: !occlude,
        });
        refreshCustomHtmlElementsZIndex();

        if (
          (Math.abs(oldZoom.current - camera.zoom) > 0.001 ||
            Math.abs(oldPosition.current[0] - vec[0]) > 0.001 ||
            Math.abs(oldPosition.current[1] - vec[1]) > 0.001 ||
            Math.abs(oldCameraPosition.current[0] - camera.position.x) >
              0.001 ||
            Math.abs(oldCameraPosition.current[1] - camera.position.y) >
              0.001) &&
          (transform ? isRendered.current : true)
        ) {
          el.style.display = 'block';

          if (transform) {
            const [widthHalf, heightHalf] = [size.width / 2, size.height / 2];
            const fov = camera.projectionMatrix.elements[5] * heightHalf;
            const { isOrthographicCamera, top, left, bottom, right } =
              camera as OrthographicCamera;
            const cameraMatrix = getCameraCSSMatrix(camera.matrixWorldInverse);
            const cameraTransform = isOrthographicCamera
              ? `scale(${fov})translate(${epsilon(
                  -(right + left) / 2
                )}px,${epsilon((top + bottom) / 2)}px)`
              : `translateZ(${fov}px)`;
            const matrix = group.current.matrixWorld;
            el.style.width = size.width + 'px';
            el.style.height = size.height + 'px';
            el.style.perspective = isOrthographicCamera ? '' : `${fov}px`;

            if (transformOuterRef.current && transformInnerRef.current) {
              transformOuterRef.current.style.transform = `${cameraTransform}${cameraMatrix}translate(${widthHalf}px,${heightHalf}px)`;
              transformInnerRef.current.style.transform = getObjectCSSMatrix(
                matrix,
                1
              );
            }
          } else {
            el.style.transform = `translate3d(${vec[0]}px,${vec[1]}px,0)`;
          }
          oldPosition.current = vec;
          oldZoom.current = camera.zoom;
          oldCameraPosition.current = [camera.position.x, camera.position.y];
        }

        if (
          !isRendered.current &&
          transformOuterRef.current &&
          transformInnerRef.current
        ) {
          isRendered.current = true;
        }
      }

      if (!scaleOcclusionGeometry) {
        occlusionMeshRef.current.scale.set(1, 1, 1);
      } else if (occlusionMeshRef.current && !isMeshSizeSet.current) {
        if (transform) {
          if (transformOuterRef.current) {
            const el = transformOuterRef.current.children[0];

            if (el?.clientWidth && el?.clientHeight) {
              // NOTE: Guillaume: This was manually modified to simplify this case and fix a problem with othographic camera
              occlusionMeshRef.current.scale.set(
                el.clientWidth - 1, // remove 1px from the occlusion mesh to avoid 1px line artifacts
                el.clientHeight - 1,
                1
              );

              isMeshSizeSet.current = true;
            }
          }
        } else {
          const ele = el.children[0];

          if (ele?.clientWidth && ele?.clientHeight) {
            const ratio = 1 / viewport.factor;
            const w = ele.clientWidth * ratio;
            const h = ele.clientHeight * ratio;

            occlusionMeshRef.current.scale.set(w, h, 1);

            isMeshSizeSet.current = true;
          }

          occlusionMeshRef.current.lookAt(gl.camera.position);
        }
      }
    });

    const shaders = React.useMemo(
      () => ({
        vertexShader: !transform
          ? /* glsl */ `
          /*
            This shader is from the THREE's SpriteMaterial.
            We need to turn the backing plane into a Sprite
            (make it always face the camera) if "transfrom"
            is false.
          */
          #include <common>

          void main() {
            vec2 center = vec2(0., 1.);
            float rotation = 0.0;

            // This is somewhat arbitrary, but it seems to work well
            // Need to figure out how to derive this dynamically if it even matters
            float size = 0.03;

            vec4 mvPosition = modelViewMatrix * vec4( 0.0, 0.0, 0.0, 1.0 );
            vec2 scale;
            scale.x = length( vec3( modelMatrix[ 0 ].x, modelMatrix[ 0 ].y, modelMatrix[ 0 ].z ) );
            scale.y = length( vec3( modelMatrix[ 1 ].x, modelMatrix[ 1 ].y, modelMatrix[ 1 ].z ) );

            bool isPerspective = isPerspectiveMatrix( projectionMatrix );
            if ( isPerspective ) scale *= - mvPosition.z;

            vec2 alignedPosition = ( position.xy - ( center - vec2( 0.5 ) ) ) * scale * size;
            vec2 rotatedPosition;
            rotatedPosition.x = cos( rotation ) * alignedPosition.x - sin( rotation ) * alignedPosition.y;
            rotatedPosition.y = sin( rotation ) * alignedPosition.x + cos( rotation ) * alignedPosition.y;
            mvPosition.xy += rotatedPosition;

            gl_Position = projectionMatrix * mvPosition;
          }
      `
          : undefined,
        fragmentShader: /* glsl */ `
        void main() {
          gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);
        }
      `,
      }),
      [transform]
    );

    return (
      <group {...props} ref={group}>
        {occlude && (
          <mesh ref={occlusionMeshRef}>
            {geometry || <planeGeometry />}

            {/* This is used when the occlusion is active, in this mode, we want to make a "hole"
               where the html element is, to let it appear through the canvas
               We use this shader material to set the pixel color to fully transparent */}
            <shaderMaterial
              side={DoubleSide}
              vertexShader={shaders.vertexShader}
              fragmentShader={shaders.fragmentShader}
              transparent
              blending={NoBlending}
            />
            {/* <meshBasicMaterial color="red" transparent /> */}
          </mesh>
        )}
      </group>
    );
  }
);

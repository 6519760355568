import styled from 'styled-components';
import {
  Divider,
  ExportActionButton,
  Text,
  UpgradeIcon,
  useIsFree,
} from '@vizcom/shared-ui-components';

import { Drawing2dStudio } from '../../../../lib/useDrawingSyncedState';
import { useExportUtils } from '../../lib/useExportUtils';

export const StudioExportButtons = (props: { drawing: Drawing2dStudio }) => {
  const isFreePlan = useIsFree(props.drawing.id);

  const {
    isExporting,
    handleExportToVideo,
    handleExportToWatermarkFreeVideo,
    handleExportToPng,
    handleExportToUpscaledPng,
    exportPSD,
    exportAllModels,
    exportAll2DLayers,
  } = useExportUtils(props.drawing, isFreePlan);

  return (
    <InsertActionButtonContainer>
      <ExportActionButton
        text="Image • 1x"
        size={`${props.drawing.width} x ${props.drawing.height}`}
        onClick={handleExportToPng}
        disabled={isExporting}
      />
      <ExportActionButton
        text={
          <>
            <Text>Image • 2x</Text>
            {isFreePlan && <UpgradeIcon />}
          </>
        }
        size={`${props.drawing.width * 2} x ${props.drawing.height * 2}`}
        onClick={handleExportToUpscaledPng}
        disabled={isExporting}
      />
      <Divider />
      <ExportActionButton
        text={
          <>
            <Text>
              Video • <Text color="link">Layer time-lapse</Text>
            </Text>
          </>
        }
        size={`${props.drawing.width} x ${props.drawing.height}`}
        onClick={handleExportToVideo}
        disabled={isExporting}
      />
      {isFreePlan && (
        <ExportActionButton
          text={
            <>
              <Text>
                Video • <Text color="link">No Vizcom watermark</Text>
              </Text>
              <UpgradeIcon />
            </>
          }
          size={`${props.drawing.width} x ${props.drawing.height}`}
          onClick={handleExportToWatermarkFreeVideo}
          disabled={isExporting}
        />
      )}
      <Divider />
      <ExportActionButton
        text="PSD • 1x"
        size={`${props.drawing.width} x ${props.drawing.height}`}
        onClick={exportPSD}
        disabled={isExporting}
      />
      <Divider />
      <ExportActionButton
        text={
          <>
            <Text>2D • All layers</Text>
          </>
        }
        size="ZIP"
        onClick={exportAll2DLayers}
        disabled={isExporting}
      />
      <ExportActionButton
        text={
          <>
            <Text>3D • All models</Text>
            {isFreePlan && <UpgradeIcon />}
          </>
        }
        size="ZIP"
        onClick={exportAllModels}
        disabled={isExporting}
      />
    </InsertActionButtonContainer>
  );
};

const InsertActionButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: stretch;
`;

import { PropsWithChildren, createContext, useContext, useState } from 'react';
import * as THREE from 'three';

import { AIPreviewMode } from './types';

type CompositeSceneEditorState = {
  selected: THREE.Object3D | null;
  setSelected: (mesh: THREE.Object3D | null) => void;

  hovered: THREE.Object3D | null;
  setHovered: (mesh: THREE.Object3D | null) => void;

  enableDnD: boolean;
  setEnableDnD: (enabled: boolean) => void;

  aiPreviewMode: AIPreviewMode;
  setAIPreviewMode: (aiPreviewMode: AIPreviewMode) => void;

  activeCamera: string | null;
  setActiveCamera: (activeCameraId: string | null) => void;

  /**
   * NOTE We're using simple-dropzone (https://github.com/donmccurdy/simple-dropzone) for model uploads.
   *      Simple-dropzone automatically triggers the validation and upload flow on input element "change"
   *      event. To trigger upload manually, without accessing simple-dropzone directly, simply click the
   *      uploadInputRef whenever it's present:
   *
   *      ```
   *      const { uploadInputRef } = useCompositeSceneEditorContext();
   *
   *      if (uploadInputRef) {
   *        uploadInputRef.click();
   *      }
   *      ```
   *
   *      This will validate model format, convert the model to GLTF, and upload it to the backend.
   *
   */
  uploadInputRef: HTMLInputElement | null;
  setUploadInputRef: (input: HTMLInputElement) => void;
};

const CompositeSceneEditorContext =
  createContext<CompositeSceneEditorState | null>(null);

export const useCompositeSceneEditorContext = (): CompositeSceneEditorState => {
  const context = useContext(CompositeSceneEditorContext);

  if (!context) {
    throw new Error('useCompositeSceneEditorContext used without context');
  }

  return context;
};

export const CompositeSceneEditorContextProvider = (
  props: PropsWithChildren
) => {
  const [selected, setSelected] = useState<THREE.Object3D | null>(null);
  const [hovered, setHovered] = useState<THREE.Object3D | null>(null);
  const [enableDnD, setEnableDnD] = useState<boolean>(false);
  const [aiPreviewMode, setAIPreviewMode] = useState<AIPreviewMode>({
    mode: 'docked',
    splitScreenRatio: 0.5,
  });
  const [uploadInputRef, setUploadInputRef] = useState<HTMLInputElement | null>(
    null
  );
  const [activeCamera, setActiveCamera] = useState<string | null>(null);

  return (
    <CompositeSceneEditorContext.Provider
      value={{
        selected,
        setSelected,
        hovered,
        setHovered,
        enableDnD,
        setEnableDnD,
        aiPreviewMode,
        setAIPreviewMode,
        uploadInputRef,
        setUploadInputRef,
        activeCamera,
        setActiveCamera,
      }}
    >
      {props.children}
    </CompositeSceneEditorContext.Provider>
  );
};

import { useState } from 'react';
import { useWorkbench } from '@vizcom/shared/data-access/graphql';
import { ExportActionButton } from '@vizcom/shared-ui-components';

import {
  ClientSideWorkbenchElementData,
  ClientSideWorkbenchElementDrawing,
} from '../../lib/clientState';
import { downloadWorkbenchDrawings } from '../../lib/downloadWorkbenchDrawings';
import { useWorkbenchElementSelectionState } from '../../lib/elementSelectionState';
import { elementIsDrawing } from '../../lib/utils';

export const WorkbenchExportImagesButton = (props: {
  elements: ClientSideWorkbenchElementData[];
  workbenchId: string;
}) => {
  const [isExporting, setIsExporting] = useState(false);
  const { data: workbench } = useWorkbench(props.workbenchId);

  const { focusedElementsId } = useWorkbenchElementSelectionState();

  const focusedElementsIds = focusedElementsId.split('/').filter((id) => id);
  const allDrawings = props.elements.filter(elementIsDrawing);
  const focusedDrawings = allDrawings.filter((element) =>
    focusedElementsIds.some((id) => id === element.id)
  ) as ClientSideWorkbenchElementDrawing[];

  const isDrawingsSelected = focusedDrawings.length > 0;
  const isMultipleDrawingsSelected = focusedDrawings.length > 1;

  const handleExportImages = async (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsExporting(true);
    await downloadWorkbenchDrawings(
      isDrawingsSelected ? focusedDrawings : allDrawings,
      workbench?.name ?? '',
      props.workbenchId
    );
    (e.target as HTMLButtonElement).blur();
    setIsExporting(false);
  };

  const text = `Export ${
    isDrawingsSelected
      ? `selected image${
          isMultipleDrawingsSelected ? `s (${focusedDrawings.length})` : ''
        }`
      : 'all images'
  }`;

  return (
    <ExportActionButton
      text={text}
      size="ZIP"
      onClick={handleExportImages}
      disabled={isExporting}
      style={{ marginTop: 4 }}
    />
  );
};

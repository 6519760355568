import { useState } from 'react';
import { LayerMetadata3d } from '@vizcom/shared/js-utils';
import { NumberInput, RangeInput, Text } from '@vizcom/shared-ui-components';

import { LayerData } from '../../../../lib/actions/drawing/updateLayer';
import {
  Drawing2dStudio,
  DrawingLayer,
} from '../../../../lib/useDrawingSyncedState';
import { HeaderSecondary } from '../../style';

export const Layer3dDropShadowSettings = ({
  layer,
  updateLayer,
  onDropShadowOpacityChange,
}: {
  layer: DrawingLayer;
  updateLayer: (
    update?: Partial<LayerData>,
    updateLayerThumbnail?: boolean
  ) => void;
  onDropShadowOpacityChange: (opacity: number) => void;
}) => {
  const metadata3d = layer.metadata3D as LayerMetadata3d;
  const [dropShadowOpacity, setDropShadowOpacity] = useState<number>(
    metadata3d?.view?.dropShadowOpacity ?? 1.0
  );

  const handleUpdate = (dropShadowOpacity: number) => {
    const metadata3DUpdate: Partial<LayerMetadata3d> = {
      ...layer.metadata3D,
      view: {
        ...(layer.metadata3D?.view ?? {}),
        dropShadowOpacity,
      },
    };

    requestAnimationFrame(() => updateLayer({ metadata3D: metadata3DUpdate }));
  };

  return (
    <>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto 1fr auto',
          gap: '16px',
          alignItems: 'center',
          padding: '0 8px',
          width: 240,
        }}
      >
        <HeaderSecondary>Shadow Strength</HeaderSecondary>
        <RangeInput
          value={dropShadowOpacity * 100.0}
          min={0}
          max={100}
          step={5}
          onChange={(e) => {
            setDropShadowOpacity(parseFloat(e.target.value) / 100.0);
            onDropShadowOpacityChange(parseFloat(e.target.value) / 100.0);
          }}
          onMouseUp={() => {
            handleUpdate(dropShadowOpacity);
          }}
        />
        <Text>
          <NumberInput
            value={dropShadowOpacity * 100.0}
            min={0}
            max={100}
            unit="%"
            setValue={(v) => {
              handleUpdate(v / 100.0);
            }}
            dragArrows={false}
          />
        </Text>
      </div>
    </>
  );
};

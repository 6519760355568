import { MeshBasicMaterialProps, MeshProps } from '@react-three/fiber';
import { CustomBlending, DoubleSide, OneFactor } from 'three';

import { useLayersCompositor } from './context';

export const CompositedLayersMesh = (props: {
  meshProps?: MeshProps;
  materialProps?: MeshBasicMaterialProps;
  width: number;
  height: number;
}) => {
  const compositor = useLayersCompositor();

  return (
    <mesh scale={[props.width, props.height, 1]} {...props.meshProps}>
      <planeGeometry args={[1, 1, 1, 1]} />
      <meshBasicMaterial
        transparent
        side={DoubleSide}
        map={compositor.fbo.texture}
        blending={CustomBlending}
        // texture is alpha premultiplied, we change the blending src factor to 1 because we don't need to multiply it again by the alpha (the default behavior)
        blendSrc={OneFactor}
        {...props.materialProps}
      />
    </mesh>
  );
};

import { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  useCancelPrompt,
  PromptData,
  publishTrackingEvent,
} from '@vizcom/shared/data-access/graphql';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import {
  UnhideIcon,
  RegenerateIcon,
  HideIcon,
  DownloadIcon,
  LoadingLogoInset,
  downloadFile,
  CloseIcon,
  Button,
  useIsFree,
  useKeyboardShortcut,
  TooltipNotificationName,
  useTooltipNotificationState,
  bottomScreenTunnel,
  imageToBlob,
  usePaywallModalState,
  ToolbarButton,
  ToolbarButtonState,
  ToolbarDivider,
  Toolbar,
  TooltipNotification,
} from '@vizcom/shared-ui-components';

import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../../lib/useDrawingSyncedState';
import { Overlay, LoaderIcon } from '../style';
import { addLayer } from '../utils';

type Props = {
  drawing: Drawing2dStudio;
  selectedPrompt?: PromptData | null;
  previewHidden: boolean;
  selectedPromptId: string | undefined;
  selectedOutputId: string | undefined;
  activeLayerId?: string;
  setSelectedOutputId: (id: string | undefined) => void;
  setSelectedPromptId: (id: string | undefined) => void;
  setPreviewHidden: (hidden: boolean) => void;
  trigger: () => void;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  setActiveLayerId: (id: string) => void;
};

export const InferenceTray = ({
  drawing,
  selectedPrompt,
  previewHidden,
  selectedPromptId,
  selectedOutputId,
  activeLayerId,
  setSelectedOutputId,
  setSelectedPromptId,
  setPreviewHidden,
  trigger,
  handleAction,
  setActiveLayerId,
}: Props) => {
  const isFreePlan = useIsFree(drawing.id);
  const [, cancelPrompt] = useCancelPrompt();
  const [addedPromptIds, setAddedPromptIds] = useState<string[]>([]);
  const [order, setOrder] = useState<string[]>([]);
  const [addedLayerIds, setAddedLayerIds] = useState<string[]>([]);
  const tooltipState = useTooltipNotificationState();

  const selectedPromptOutputs =
    selectedPrompt?.id === selectedPromptId
      ? selectedPrompt?.outputs.nodes.sort((a, b) => {
          const indexA = order.indexOf(a.id);
          const indexB = order.indexOf(b.id);
          const posA = indexA === -1 ? order.length : indexA;
          const posB = indexB === -1 ? order.length : indexB;

          return posA - posB;
        })
      : undefined;

  const selectedOutput = selectedPromptOutputs?.find(
    (p) => p.id === selectedOutputId
  );

  useKeyboardShortcut('ArrowLeft', () => {
    if (selectedOutputId && selectedPromptOutputs) {
      const index = selectedPromptOutputs.findIndex(
        (p) => p.id === selectedOutputId
      );
      if (index !== undefined && index > 0) {
        setSelectedOutputId(selectedPromptOutputs[index - 1].id);
      }
    }
  });
  useKeyboardShortcut('ArrowRight', () => {
    if (selectedOutputId && selectedPromptOutputs) {
      const index = selectedPromptOutputs?.findIndex(
        (p) => p.id === selectedOutputId
      );
      if (index !== undefined && index < selectedPromptOutputs.length - 1) {
        setSelectedOutputId(selectedPromptOutputs[index + 1].id);
      }
    }
  });

  // selectedPromptOutputs are created in a one order but the order the images come back
  // is arbitrary. We reorder them and present them to the user based on the order they
  // get the images back.
  useEffect(() => {
    if (selectedPromptOutputs) {
      const newOrder = [...order];
      selectedPromptOutputs.forEach((p) => {
        if (p.imagePath && !newOrder.includes(p.id)) {
          newOrder.push(p.id);
        }
      });
      setOrder(newOrder);

      if (newOrder.length && !selectedOutputId) {
        setSelectedOutputId(newOrder[0]);
      }
    } else {
      setOrder([]);
    }
  }, [selectedPromptOutputs]);

  useEffect(() => {
    setPreviewHidden(false);
    // preview should be displayed again when clicking on another image
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOutputId]);

  useEffect(() => {
    setAddedPromptIds([]);
    setSelectedOutputId(undefined);
  }, [selectedPromptId, setSelectedOutputId]);

  const handleAddAsLayer = async () => {
    if (!selectedOutput || !selectedOutput.imagePath) {
      return;
    }

    setAddedPromptIds((prev) => [...prev, selectedOutput.id]);

    const id = addLayer({
      drawing,
      handleAction,
      activeLayerId,
      layerData: {
        name:
          selectedPrompt?.text || `Layer ${drawing.layers.nodes.length + 1}`,
        image: selectedOutput.imagePath,
        visible: false,
      },
    });
    setActiveLayerId(id);

    setAddedLayerIds((prev) => [...prev, id]);

    trackEvent('Image Added to Canvas', {
      publicPalette: selectedPrompt?.publicPaletteId,
    });

    publishTrackingEvent({
      type: 'ADD_TO_LAYER',
      data: {
        publicPaletteId: selectedPrompt?.publicPaletteId || undefined,
      },
    });
  };

  const handleCancel = () => {
    if (!selectedPromptId) {
      return;
    }
    if (selectedPromptId !== 'loading') {
      // user cancelled before receiving the inference id, cannot cancel it
      cancelPrompt({
        input: {
          promptId: selectedPromptId,
        },
      });
    }

    setSelectedPromptId(undefined);
    setSelectedOutputId(undefined);

    // Mark the tooltip as dismissed when user clicks the X
    tooltipState.setDismissed(TooltipNotificationName.ExitInferenceTray);

    if (addedLayerIds.length) {
      addedLayerIds.forEach((id, i) => {
        handleAction?.({
          type: 'updateLayer',
          id: id,
          data: {
            visible: true,
          },
        });

        if (i === addedLayerIds.length - 1) {
          setActiveLayerId(id);
        }
      });

      handleAction?.({
        type: 'updateBulkLayers',
        layerUpdates: drawing.layers.nodes
          .filter((l) => addedLayerIds.includes(l.id))
          .map((l) => ({
            id: l.id,
            visible: true,
          })),
      });
      setAddedLayerIds([]);
    }

    trackEvent('Cancel Inference');
    publishTrackingEvent({
      type: 'CANCEL_INFERENCE',
      data: {
        promptId: selectedPromptId,
      },
    });
  };

  const handleSelect = (id: string) => {
    setSelectedOutputId(id);
  };

  const handleToggle = () => {
    setPreviewHidden(!previewHidden);
  };

  const handleDownload = async () => {
    const output = selectedPromptOutputs?.find(
      (p) => p.id === selectedOutputId
    );

    if (!output || !output.imagePath) {
      return;
    }

    const name = drawing?.workbench?.name || 'unnamed file';
    downloadFile(
      await imageToBlob(output.imagePath, {
        convertToContentType: 'image/png',
      }),
      name
    );
    trackEvent('Image Export', { type: 'inferenceTrayDownload' });
    publishTrackingEvent({
      type: 'EXPORT_IMAGE',
      data: {
        promptId: selectedPromptId,
        imagePath: output.imagePath,
      },
    });
  };

  const loading =
    selectedPromptId === 'loading' ||
    !selectedOutput ||
    !selectedPromptOutputs?.some((p) => p.imagePath);

  const imagesLoaded = Boolean(
    selectedPrompt &&
      !loading &&
      selectedPromptOutputs?.some((p) => p.imagePath)
  );

  if (!selectedPromptId) {
    return null;
  }

  return (
    <>
      {loading && (
        <Overlay>
          <LoaderIcon />
        </Overlay>
      )}
      <bottomScreenTunnel.In>
        <Toolbar position="bottom-center">
          {selectedPrompt && (
            <>
              <ToolbarButton
                state={
                  loading
                    ? ToolbarButtonState.DISABLED
                    : ToolbarButtonState.INACTIVE
                }
                onClick={handleToggle}
                icon={previewHidden ? <UnhideIcon /> : <HideIcon />}
                tooltip={
                  !previewHidden
                    ? 'Show generated image'
                    : 'Show original canvas'
                }
              />
              <ToolbarButton
                state={
                  loading
                    ? ToolbarButtonState.DISABLED
                    : ToolbarButtonState.INACTIVE
                }
                onClick={trigger}
                icon={<RegenerateIcon />}
                tooltip="Regenerate"
              />

              <Outputs
                $hasOutputs={
                  !!selectedPromptOutputs && selectedPromptOutputs.length > 1
                }
              >
                {selectedPromptOutputs && selectedPromptOutputs.length > 1 && (
                  <>
                    <ToolbarDivider />
                    {selectedPromptOutputs.map(
                      ({ id, imagePath, failureReason }) => (
                        <Output
                          key={id}
                          $aspect={drawing.width / drawing.height}
                          $current={selectedOutputId === id}
                          onClick={() => handleSelect(id)}
                        >
                          <LoadingLogoInset
                            active={!imagePath && !failureReason}
                            style={{ width: 32, height: 32 }}
                          />
                          {imagePath && (
                            <img crossOrigin="anonymous" src={imagePath} />
                          )}
                        </Output>
                      )
                    )}
                  </>
                )}
              </Outputs>

              <Outputs
                $hasOutputs={!!selectedPromptOutputs?.length && isFreePlan}
              >
                {selectedPromptOutputs &&
                  selectedPromptOutputs.length === 1 &&
                  isFreePlan && (
                    <>
                      <Output
                        $aspect={drawing.width / drawing.height}
                        $current={true}
                      >
                        <LoadingLogoInset
                          active={
                            !selectedPromptOutputs[0].imagePath &&
                            !selectedPromptOutputs[0].failureReason
                          }
                          style={{ width: 32, height: 32 }}
                        />
                        {selectedPromptOutputs[0].imagePath && (
                          <img
                            crossOrigin="anonymous"
                            src={selectedPromptOutputs[0].imagePath}
                          />
                        )}
                      </Output>
                      {Array.from({ length: 3 }).map((_, i) => (
                        <Output
                          key={i}
                          $aspect={drawing.width / drawing.height}
                          $current={false}
                          $blur={true}
                          onClick={() =>
                            usePaywallModalState.getState().trigger()
                          }
                        >
                          {selectedPromptOutputs[0].imagePath && (
                            <img
                              crossOrigin="anonymous"
                              src={selectedPromptOutputs[0].imagePath}
                            />
                          )}
                        </Output>
                      ))}
                    </>
                  )}
              </Outputs>

              <ToolbarDivider />

              <ToolbarButton
                state={
                  loading
                    ? ToolbarButtonState.DISABLED
                    : ToolbarButtonState.INACTIVE
                }
                onClick={handleDownload}
                icon={<DownloadIcon />}
                tooltip="Download"
              />
            </>
          )}
          <Button
            variant="primary"
            size="M"
            disabled={
              loading ||
              !selectedOutputId ||
              addedPromptIds.includes(selectedOutputId)
            }
            onClick={handleAddAsLayer}
          >
            {selectedOutputId && addedPromptIds.includes(selectedOutputId)
              ? 'Added'
              : 'Add'}
          </Button>

          <ToolbarDivider />

          <TooltipNotification
            name={TooltipNotificationName.ExitInferenceTray}
            autoTriggerAfter={30_000}
            startTimerCondition={imagesLoaded}
            message="Exit generation preview"
          >
            <Button variant="secondary" size="icon" onClick={handleCancel}>
              <CloseIcon />
            </Button>
          </TooltipNotification>
        </Toolbar>
      </bottomScreenTunnel.In>
    </>
  );
};

export const Outputs = styled.div<{ $hasOutputs: boolean }>`
  display: inline-flex;
  background: ${({ theme }) => theme.surface.primary};
  color: ${({ theme }) => theme.text.body};
  gap: 0.5rem;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  max-width: ${({ $hasOutputs }) => ($hasOutputs ? '305px' : '0px')};
  position: ${({ $hasOutputs }) => ($hasOutputs ? 'relative' : 'absolute')};
  transition: max-width 0.5s ease;
`;

export const Output = styled.div<{
  $aspect: number;
  $current: boolean;
  $blur?: boolean;
}>`
  background: ${({ theme }) => theme.surface.secondary};
  cursor: pointer;
  position: relative;
  height: 38px;
  min-width: 38px;
  max-width: 64px;
  padding: 2px;
  align-items: center;
  display: flex;
  border-radius: ${({ theme }) => theme.borderRadius.s};
  overflow: hidden;
  box-shadow: 0 0 0 2px
    ${({ $current, theme }) =>
      $current ? `${theme.deprecated.primary.default}` : 'transparent'};
  transition: box-shadow 0.2s ease;
  user-select: none;

  &,
  > img {
    aspect-ratio: ${({ $aspect }) => $aspect};
    filter: ${({ $blur }) => ($blur ? 'blur(6px)' : 'none')};
  }
  > img {
    border-radius: ${({ theme }) => theme.borderRadius.s};
    pointer-events: none;
    object-fit: contain;
    width: 100%;
    max-width: 60px;
    max-height: 34px;
  }
`;

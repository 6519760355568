import { useEffect } from 'react';

import { getLocalStorage, useLocalStorage } from './useLocalStorage';

const HAS_STYLUS_LOCALSTORAGE_KEY = 'vizcom:has_stylus';

export const hasStylusFlag = () =>
  getLocalStorage(HAS_STYLUS_LOCALSTORAGE_KEY, false);

// Return true if we received a stylus event at least once on this device
export const useHasStylus = () => {
  const [hasStylus, setHasStylus] = useLocalStorage(
    HAS_STYLUS_LOCALSTORAGE_KEY,
    false
  );

  useEffect(() => {
    if (hasStylus) {
      return;
    }
    const touchListener = (e: TouchEvent) => {
      const touch = e.touches[0];
      if (touch && 'touchType' in touch && touch.touchType === 'stylus') {
        setHasStylus(true);
        document.removeEventListener('pointerdown', pointerListener);
        document.removeEventListener('touchstart', touchListener);
      }
    };

    const pointerListener = (e: PointerEvent) => {
      if (e.pointerType === 'pen') {
        setHasStylus(true);
        document.removeEventListener('touchstart', touchListener);
        document.removeEventListener('pointerdown', pointerListener);
      }
    };

    document.addEventListener('touchstart', touchListener);
    document.addEventListener('pointerdown', pointerListener);

    return () => {
      document.removeEventListener('touchstart', touchListener);
      document.removeEventListener('pointerdown', pointerListener);
    };
  }, [hasStylus, setHasStylus]);

  return hasStylus;
};

import styled, { css } from 'styled-components';

import noThumbnailImg from './icon-no-thumbnail.svg?url';

const WrapperHoverStyles = css`
  ::after {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, transparent 70%);
    opacity: 1;
  }
`;

export const WrapperSelectedStyles = css`
  outline: 2px solid ${({ theme }) => theme.border.action};
  outline-offset: 3px;

  ::after {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, transparent 70%),
      ${({ theme }) => theme.button.primaryHover}33 !important;
    opacity: 1;
  }
`;

export const Wrapper = styled.div<{
  $logoBackground?: boolean;
  $selected?: boolean;
}>`
  cursor: pointer;
  overflow: hidden;

  border-radius: ${(p) => p.theme.borderRadius.l};
  color: ${(p) => p.theme.text.body};
  outline-color: transparent;

  transition: outline-color 0.2s ease, background-color 0.2s ease;

  ::after {
    transition: opacity 0.2s ease;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, transparent 70%);
    opacity: 0;
    z-index: 2;
  }

  :hover,
  .hover {
    ${WrapperHoverStyles}
  }

  ${({ $selected }) => $selected && WrapperSelectedStyles}

  position: relative;
  ${(p) =>
    p.$logoBackground &&
    `
    background-image: url(${noThumbnailImg});
    background-repeat: no-repeat;
    background-position: 50% 45%;
    background-color: ${p.theme.surface.tertiary};
  `}
`;

export const ThumbnailContainer = styled.div`
  border-radius: ${({ theme: { borderRadius } }) =>
    `${borderRadius.l} ${borderRadius.l} 0 0`};
  overflow: hidden;
  aspect-ratio: 16/9;
  display: flex;
  img {
    transition: 0.15s ease transform;
    pointer-events: none;
    object-fit: contain;
  }

  ${Wrapper}:hover & img, .hover & img {
    transform: scale(1.2);
  }
`;

export const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.surface.page};
`;

export const Content = styled.div`
  padding: 17px 17px;

  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;

  gap: 4px;

  border-radius: ${({ theme: { borderRadius } }) =>
    `0 0 ${borderRadius.l} ${borderRadius.l}`};
  background-color: ${({ theme }) => theme.surface.secondary};
`;

import { ThreeEvent } from '@react-three/fiber';
import { useDrag } from '@use-gesture/react';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { useStore } from 'zustand';
import { CMD_KEY_NAME } from '@vizcom/shared-ui-components';

import { useSelectionApiStore } from '../../../selection/useSelectionApi';
import { LINE_DRAG_MULTIPLIER, moveCounterControlPoint } from './util';

type Props = {
  selectedPoints: string[] | null;
  setSelectedPoints: Dispatch<SetStateAction<string[] | null>>;
  resetControls: (id: string) => void;
  dragControls: (
    id: string,
    event: ThreeEvent<PointerEvent>,
    newPoint?: boolean
  ) => void;
  draggingLineRef: MutableRefObject<[number, number] | null>;
  drawSelection: () => void;
  pointToLocal: (x: number, y: number) => number[];
};

export const useBindPoint = ({
  selectedPoints,
  draggingLineRef,
  setSelectedPoints,
  resetControls,
  dragControls,
  drawSelection,
  pointToLocal,
}: Props) => {
  const selectionApiStore = useSelectionApiStore();
  const penState = useStore(selectionApiStore, (state) => state.penState);
  const { bezierPoints, closed } = penState;

  return useDrag<ThreeEvent<PointerEvent>>(
    ({ event, args, first, last, memo, movement }) => {
      event.stopPropagation();

      const id = args[0].id as string;

      if (memo?.unselected) {
        return {
          unselected: memo.unselected,
        };
      }

      if (selectedPoints?.includes(id) && event.shiftKey && first) {
        setSelectedPoints((points) => {
          return (points || []).filter((p) => p !== id);
        });

        return {
          unselected: id,
        };
      }

      if (!selectedPoints?.includes(id)) {
        setSelectedPoints((points) => {
          if (!points) return [id];

          if (!points.includes(id)) {
            if (event.shiftKey) {
              return [...points, id];
            } else {
              return [id];
            }
          }

          if (points.includes(id) && event.shiftKey) {
            return points.filter((p) => p !== id);
          }

          return points;
        });
      }

      const point = pointToLocal(event.clientX, event.clientY);
      const x = point[0];
      const y = point[1];

      const index = selectionApiStore
        .getState()
        .penState.bezierPoints.findIndex((p) => p.id === id);

      // we just deleted a point; if the user continues to drag, move the control points
      // of the adjacent points instead of the deleted point
      if (memo?.deleted !== undefined) {
        const hasMovedEnough =
          Math.abs(movement[0]) > 5 || Math.abs(movement[1]) > 5;

        if (!hasMovedEnough) {
          return {
            deleted: memo.deleted,
            hasMovedEnough: false,
            x: memo.x,
            y: memo.y,
          };
        }

        const previousIndex =
          memo.deleted === 0
            ? selectionApiStore.getState().penState.bezierPoints.length - 1
            : memo.deleted - 1;
        const nextIndex =
          memo.deleted ===
          selectionApiStore.getState().penState.bezierPoints.length
            ? 0
            : memo.deleted;
        draggingLineRef.current = [previousIndex, nextIndex];

        let innerControlLength = memo?.innerControlLength;
        let outerControlLength = memo?.outerControlLength;

        selectionApiStore.getState().setPenState((state) => {
          const newPoints = [...state.bezierPoints];
          const prevPoint = newPoints[previousIndex];
          const nextPoint = newPoints[nextIndex];

          if (memo?.hasMovedEnough === false) {
            const midPointX = (prevPoint.x + nextPoint.x) / 2;
            const midPointY = (prevPoint.y + nextPoint.y) / 2;

            const vectorToDeletedX = memo.x - midPointX;
            const vectorToDeletedY = memo.y - midPointY;

            prevPoint.outerControl.x =
              prevPoint.x + LINE_DRAG_MULTIPLIER * vectorToDeletedX;
            prevPoint.outerControl.y =
              prevPoint.y + LINE_DRAG_MULTIPLIER * vectorToDeletedY;

            nextPoint.innerControl.x =
              nextPoint.x + LINE_DRAG_MULTIPLIER * vectorToDeletedX;
            nextPoint.innerControl.y =
              nextPoint.y + LINE_DRAG_MULTIPLIER * vectorToDeletedY;
          }

          prevPoint.outerControl.x += (x - memo.x) * LINE_DRAG_MULTIPLIER;
          prevPoint.outerControl.y += (y - memo.y) * LINE_DRAG_MULTIPLIER;

          const innerControlVectorX = prevPoint.innerControl.x - prevPoint.x;
          const innerControlVectorY = prevPoint.innerControl.y - prevPoint.y;
          innerControlLength =
            innerControlLength ||
            Math.sqrt(
              innerControlVectorX * innerControlVectorX +
                innerControlVectorY * innerControlVectorY
            );

          if (!prevPoint.singleControlLock) {
            moveCounterControlPoint(
              prevPoint,
              prevPoint.outerControl,
              prevPoint.innerControl,
              innerControlLength
            );
          }

          nextPoint.innerControl.x += (x - memo.x) * LINE_DRAG_MULTIPLIER;
          nextPoint.innerControl.y += (y - memo.y) * LINE_DRAG_MULTIPLIER;

          const outerControlVectorX = nextPoint.outerControl.x - nextPoint.x;
          const outerControlVectorY = nextPoint.outerControl.y - nextPoint.y;
          outerControlLength =
            outerControlLength ||
            Math.sqrt(
              outerControlVectorX * outerControlVectorX +
                outerControlVectorY * outerControlVectorY
            );

          if (!nextPoint.singleControlLock) {
            moveCounterControlPoint(
              nextPoint,
              nextPoint.innerControl,
              nextPoint.outerControl,
              outerControlLength
            );
          }

          return {
            ...state,
            bezierPoints: newPoints,
            innerControlLength,
            outerControlLength,
          };
        });

        if (last) {
          draggingLineRef.current = null;
          drawSelection();
        }

        return {
          deleted: memo.deleted,
          hasMovedEnough: true,
          x,
          y,
        };
      }

      // If the user holds alt while dragging a point
      // move its control points instead
      if (event.altKey || memo?.movingControls) {
        if (first) {
          resetControls(id);
        }

        const bezierPoint = selectionApiStore
          .getState()
          .penState.bezierPoints.find((p) => p.id === id);
        const hasMovedEnough =
          (bezierPoint?.innerControl.initialized &&
            bezierPoint?.outerControl.initialized) ||
          Math.abs(movement[0]) > 5 ||
          Math.abs(movement[1]) > 5;

        if (hasMovedEnough) {
          dragControls(id, event);
        }

        if (last) {
          drawSelection();
        }

        return {
          movingControls: true,
        };
      }

      // If the user is holding ctrl or cmd, move the point
      if (event[CMD_KEY_NAME] || memo?.dragging) {
        const offset = {
          x: x - selectionApiStore.getState().penState.bezierPoints[index].x,
          y: y - selectionApiStore.getState().penState.bezierPoints[index].y,
        };

        selectionApiStore.getState().setPenState((state) => {
          const newPoints = [...state.bezierPoints];
          const selected = selectedPoints
            ? newPoints.filter((p) => selectedPoints.includes(p.id))
            : [newPoints[index]];

          selected.forEach((point) => {
            point.x += offset.x;
            point.y += offset.y;
            point.innerControl.x += offset.x;
            point.innerControl.y += offset.y;
            point.outerControl.x += offset.x;
            point.outerControl.y += offset.y;
          });

          return {
            ...state,
            bezierPoints: newPoints,
          };
        });

        if (last) {
          drawSelection();
        }

        return {
          dragging: true,
        };
      }

      // if the user is selecting the first point and the selection is not closed
      // and there are more than 2 points, close the selection
      if (
        index === 0 &&
        !event.shiftKey &&
        !closed &&
        selectionApiStore.getState().penState.bezierPoints.length > 1
      ) {
        selectionApiStore.getState().setPenState((state) => ({
          ...state,
          closed: true,
        }));
        return {
          movingControls: true,
        };
      }

      // no modifier keys, delete the point if we arent the last point
      if ((!closed && index === bezierPoints.length - 1) || index === -1) {
        return;
      }

      selectionApiStore.getState().setPenState((prev) => {
        const newPoints = [...prev.bezierPoints];
        const point = newPoints[index];

        let closed = prev.closed;
        if (selectionApiStore.getState().penState.bezierPoints.length <= 3) {
          closed = false;
        }

        const prevPoint =
          newPoints[index - 1] || newPoints[newPoints.length - 1];
        const nextPoint = newPoints[index + 1] || newPoints[0];

        // if the deleted point has adjacent points, move the control points of the adjacent points
        // to bring the line to the cursor
        if (nextPoint && prevPoint) {
          const midPointX = (prevPoint.x + nextPoint.x) / 2;
          const midPointY = (prevPoint.y + nextPoint.y) / 2;

          const vectorToDeletedX = point.x - midPointX;
          const vectorToDeletedY = point.y - midPointY;

          if (prevPoint.outerControl.initialized) {
            prevPoint.outerControl.x =
              prevPoint.x + LINE_DRAG_MULTIPLIER * vectorToDeletedX;
            prevPoint.outerControl.y =
              prevPoint.y + LINE_DRAG_MULTIPLIER * vectorToDeletedY;
          }

          if (nextPoint.innerControl.initialized) {
            nextPoint.innerControl.x =
              nextPoint.x + LINE_DRAG_MULTIPLIER * vectorToDeletedX;
            nextPoint.innerControl.y =
              nextPoint.y + LINE_DRAG_MULTIPLIER * vectorToDeletedY;
          }
        }

        newPoints.splice(index, 1);

        return {
          ...prev,
          closed,
          bezierPoints: newPoints,
        };
      });

      drawSelection();

      return {
        deleted: index,
        x: bezierPoints[index].x,
        y: bezierPoints[index].y,
      };
    },
    {
      pointer: {
        capture: false,
      },
    }
  );
};

import { useTheme } from 'styled-components';
import { getColorFromHash } from '@vizcom/shared-ui-components';

import { useStringHashNumber } from './useStringHashNumber';

export const usePlayerColor = (val: string | undefined | null) => {
  const theme = useTheme();
  const hashColor = useStringHashNumber(val || '');

  return getColorFromHash(theme, hashColor);
};

import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import {
  OrganizationData,
  useUpdateOrganization,
} from '@vizcom/shared/data-access/graphql';
import {
  addToast,
  Button,
  formatErrorMessage,
  LoadingLogoInset,
  Text,
} from '@vizcom/shared-ui-components';

type FormData = {
  promptDenylist: string;
};

export const PromptDenyListSettings = ({
  organization,
}: {
  organization: OrganizationData;
}) => {
  const [updateOrganizationRes, updateOrganization] = useUpdateOrganization();

  const { register, handleSubmit, formState, reset } = useForm<FormData>({
    values: {
      promptDenylist: organization.promptDenylist ?? '',
    },
  });

  const handleUpdateDenyList = async (data: FormData) => {
    const res = await updateOrganization({
      input: {
        id: organization.id,
        patch: {
          promptDenylist: data.promptDenylist
            .trim()
            .replace(/\n/g, ',')
            .split(',')
            .filter(Boolean)
            .join(','),
        },
      },
    });

    if (res.error) {
      return addToast('Error while updating forbidden keyword list', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }
    reset();
    return addToast(`Forbidden keywords list updated`);
  };

  return (
    <div
      style={{
        padding: '0px 10px',
      }}
    >
      <LoadingLogoInset active={updateOrganizationRes.fetching} />

      <Text block type="sh1" style={{ marginBottom: 16 }}>
        Forbidden prompt keywords
      </Text>

      <Text block type="b1" color="subtext" style={{ marginBottom: 16 }}>
        List keywords that cannot be used in prompts in any file in your
        workspace. Separated by commas.
      </Text>

      <form
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          gap: 8,
          flexDirection: 'column',
          marginBottom: 16,
        }}
        onSubmit={handleSubmit(handleUpdateDenyList)}
      >
        <DenylistTextarea {...register('promptDenylist')} />
        <Button variant="primary" type="submit" disabled={!formState.isDirty}>
          Save
        </Button>
      </form>
    </div>
  );
};

const DenylistTextarea = styled.textarea`
  width: 100%;
  background-color: ${(p) => p.theme.surface.secondary};
  color: ${(p) => p.theme.deprecated.white};
  border: 1px solid ${(p) => p.theme.surface.tertiary};
  min-height: 200px;
  resize: vertical;
  border-radius: ${(p) => p.theme.borderRadius.m};
  padding: 12px;
`;

import { v4 as uuidv4 } from 'uuid';
import {
  CreateWorkbenchElementsMutation,
  ImageInferenceType,
  UserClientStateKeys,
  urqlClient,
  useCurrentUserClientStateByKey,
} from '@vizcom/shared/data-access/graphql';
import { PublicPalette } from '@vizcom/shared/inference-worker-queues';
import {
  Text,
  addToast,
  ImageEditIcon,
  browseForFile,
  resizeImageToPixelCount,
  useCompleteOnboardingStep,
  OnboardingStepName,
} from '@vizcom/shared-ui-components';

import { WORKBENCH_2D_STUDIO_IMAGE_MAX_PIXEL_COUNT } from '../../../constants';
import { ClientSideWorkbenchElementPlaceholder } from '../../../lib/clientState';
import { useWorkbenchSyncedState } from '../../../lib/useWorkbenchSyncedState';
import { useIsWorkbenchViewer } from '../../../lib/utils';
import { getFileImageInfo } from '../../helpers';
import { getElementDefaultSize } from '../../utils/getElementDefaultSize';
import {
  InitialWorkbenchElement,
  InitialWorkbenchElementButton,
  InitialWorkbenchElementButtonContainer,
  InitialWorkbenchElementText,
} from '../style';

export const CreateDrawingPlaceholder = (props: {
  workbenchId: string;
  placeholder: ClientSideWorkbenchElementPlaceholder;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
  setEditingElementId: (id: string | null) => void;
  singleFocused: boolean;
}) => {
  const { workbenchId, placeholder, setEditingElementId } = props;
  const isViewer = useIsWorkbenchViewer();
  const completeOnboardingStep = useCompleteOnboardingStep();
  const onboardingStatus =
    useCurrentUserClientStateByKey(UserClientStateKeys.OnboardingStatus) || {};

  const handleDoubleClick = async () => {
    if (isViewer) return;

    await urqlClient.mutation(CreateWorkbenchElementsMutation, {
      createDrawingsInput: [
        {
          id: placeholder.id,
          workbenchId,
          width: 1920,
          height: 1080,
          image: null,
          backgroundColor: '#ffffff',
          backgroundVisible: true,
        },
      ],
      createWorkbenchElementsPlaceholderInput: [],
      createWorkbenchElementsImg2ImgInput: [],
      createWorkbenchElementsTextInput: [],
      createWorkbenchElementsMixInput: [],
      createWorkbenchElementsSectionInput: [],
      createWorkbenchElementsPaletteInput: [],
      createCompositeScenesInput: [],
      createVideosInput: [],
      createAnimatesInput: [],
    });

    if (!onboardingStatus[OnboardingStepName.EnterSketch]) {
      const promptSize = getElementDefaultSize('WorkbenchElementImg2Img');

      await urqlClient.mutation(CreateWorkbenchElementsMutation, {
        createWorkbenchElementsImg2ImgInput: [
          {
            id: uuidv4(),
            x: placeholder.x + 450,
            y: placeholder.y,
            width: promptSize.x,
            height: promptSize.y,
            workbenchId,
            zIndex: placeholder.zIndex + 1,
            prompt: '',
            publicPaletteId: PublicPalette.generalV2,
            sourceImageInfluences: [1],
            imageInferenceType: ImageInferenceType.Render,
            sourceDrawingId: placeholder.id,
          },
        ],
        createDrawingsInput: [],
        createWorkbenchElementsPlaceholderInput: [],
        createWorkbenchElementsTextInput: [],
        createWorkbenchElementsMixInput: [],
        createWorkbenchElementsSectionInput: [],
        createWorkbenchElementsPaletteInput: [],
        createCompositeScenesInput: [],
        createVideosInput: [],
        createAnimatesInput: [],
      });
    }

    completeOnboardingStep(OnboardingStepName.EnterSketch);

    setEditingElementId(placeholder.id);
  };

  const handleUploadPhoto = async () => {
    const file = await browseForFile({
      accept: 'image/*',
    });

    try {
      await getFileImageInfo(file);
    } catch (e) {
      addToast(
        `Cannot import file "${file.name}", please check it's a valid image`,
        {
          type: 'danger',
        }
      );
      throw e;
    }

    const { width, height, image } = await resizeImageToPixelCount(
      file,
      WORKBENCH_2D_STUDIO_IMAGE_MAX_PIXEL_COUNT
    );

    await urqlClient.mutation(CreateWorkbenchElementsMutation, {
      createDrawingsInput: [
        {
          id: placeholder.id,
          workbenchId,
          width,
          height,
          image: image,
          backgroundColor: '#ffffff',
          backgroundVisible: true,
        },
      ],
      createWorkbenchElementsPlaceholderInput: [],
      createWorkbenchElementsImg2ImgInput: [],
      createWorkbenchElementsTextInput: [],
      createWorkbenchElementsMixInput: [],
      createWorkbenchElementsSectionInput: [],
      createWorkbenchElementsPaletteInput: [],
      createCompositeScenesInput: [],
      createVideosInput: [],
      createAnimatesInput: [],
    });

    completeOnboardingStep(OnboardingStepName.EnterSketch);

    setEditingElementId(placeholder.id);
  };

  return (
    <InitialWorkbenchElement onDoubleClick={handleDoubleClick}>
      <InitialWorkbenchElementButtonContainer>
        <InitialWorkbenchElementButton
          variant="primary"
          onClick={handleDoubleClick}
        >
          <ImageEditIcon style={{ width: '22px', height: '22px' }} />
          Sketch
        </InitialWorkbenchElementButton>
      </InitialWorkbenchElementButtonContainer>
      <InitialWorkbenchElementText type="b3" color="subtext">
        or
        <Text
          type="b3"
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          color="subtext"
          onClick={handleUploadPhoto}
        >
          {' '}
          upload a photo{' '}
        </Text>
        to get started
      </InitialWorkbenchElementText>
    </InitialWorkbenchElement>
  );
};

import {
  SymmetryIcon,
  ToolbarButtonState,
  ToolbarMenuButton,
} from '@vizcom/shared-ui-components';

import {
  isSymmetryCompatibleTool,
  useWorkbenchStudioState,
  isSelectionTool,
} from '../../studioState';
import { SymmetryToolbar } from './SymmetryToolbar';

interface SymmetryButtonProps {
  isMenuOpen: boolean;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
}

export const SymmetryButton = ({
  isMenuOpen,
  setIsMenuOpen,
  disabled: propDisabled,
}: SymmetryButtonProps) => {
  const studioState = useWorkbenchStudioState();
  const toolDisabled = !isSymmetryCompatibleTool(studioState.tool);
  const isSelectionToolActive = isSelectionTool(studioState.tool);

  const state =
    propDisabled || toolDisabled
      ? ToolbarButtonState.DISABLED
      : studioState.symmetry.enabled
      ? ToolbarButtonState.SECONDARY_ACTIVE
      : ToolbarButtonState.INACTIVE;

  const tooltipText =
    isSelectionToolActive && toolDisabled
      ? 'Tool not available with selection'
      : propDisabled
      ? 'Select a 2D layer to use this tool'
      : 'Symmetry';

  return (
    <ToolbarMenuButton
      state={state}
      icon={<SymmetryIcon style={{ color: 'inherit' }} />}
      tooltip={tooltipText}
      onClick={() =>
        !propDisabled &&
        !toolDisabled &&
        studioState.setSymmetry({
          ...studioState.symmetry,
          enabled: true,
        })
      }
      // NOTE: we want the toolbar to behave as a temporary tooltip (i.e. not persist after user clicks away)
      menu={<SymmetryToolbar onClose={() => setIsMenuOpen(false)} />}
      menuStyle={{
        display: 'flex',
        flexDirection: 'row',
        gap: 8,
        padding: 4,
        borderRadius: 12, // custom radius to compliment the toolbar buttons
      }}
      menuTooltip="Symmetry Tools"
      controlledMenuState={[isMenuOpen, setIsMenuOpen]}
    />
  );
};

import { Line } from '@react-three/drei';
import { forwardRef } from 'react';
import { useTheme } from 'styled-components';
import { Line2, LineSegments2 } from 'three-stdlib';
import { usePlayerColor } from '@vizcom/shared-ui-components';

import { MultiplayerPresence } from '../../lib/useWorkbenchMultiplayer';
import { BOX_POINTS } from '../helpers';

const LINE_WIDTH = 1.5;

interface FocusIndicatorProps {
  active: boolean;
  multiplayerSelected?: MultiplayerPresence;
  lineWidthMultiplier?: number;
  height: number;
  width: number;
}

export const FocusIndicator = forwardRef<
  Line2 | LineSegments2,
  FocusIndicatorProps
>((props, ref) => {
  const { active, multiplayerSelected, height, width, lineWidthMultiplier } =
    props;
  const theme = useTheme();
  const playerColor = usePlayerColor(multiplayerSelected?.userId);

  let color = theme.deprecated.primary.default;
  if (multiplayerSelected && !active) {
    color = playerColor;
  }

  const lineWidth = multiplayerSelected
    ? LINE_WIDTH * 2
    : LINE_WIDTH * (lineWidthMultiplier ?? 1);

  return (
    <group>
      <Line
        ref={ref}
        scale={[width / 2, height / 2, 1]}
        points={BOX_POINTS}
        color={color}
        lineWidth={lineWidth}
        transparent
        opacity={active || multiplayerSelected ? 1 : 0}
      />
    </group>
  );
});

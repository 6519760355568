import {
  OrganizationSubscriptionData,
  useCreateOrganizationSubscriptionManagementPortal,
} from '@vizcom/shared/data-access/graphql';
import { capitalize } from '@vizcom/shared/js-utils';
import {
  addToast,
  AmexIcon,
  BlockStack,
  Button,
  DinersIcon,
  DiscoverIcon,
  EmailIcon,
  formatErrorMessage,
  GenericIcon,
  InlineFlex,
  JCBIcon,
  LoadingLogoInset,
  MaestroIcon,
  MasterCardIcon,
  PaypalIcon,
  Surface,
  Text,
  UnionpayIcon,
  VisaIcon,
} from '@vizcom/shared-ui-components';

export const PaymentMethodInfosCard = ({
  subscriptionData,
  organizationId,
}: {
  subscriptionData: OrganizationSubscriptionData;
  organizationId: string;
}) => {
  const { subscription } = subscriptionData;

  const [res, createPortal] =
    useCreateOrganizationSubscriptionManagementPortal();

  const handleChangePaymentMethod = async () => {
    const res = await createPortal({
      input: {
        organizationId,
      },
    });
    if (res.error || !res.data) {
      return addToast('Error while changing payment method', {
        secondaryText: formatErrorMessage(res.error),
        type: 'danger',
      });
    }
    window.location.assign(
      res.data.createOrganizationSubscriptionManagementPortal.url
    );
  };

  return (
    <Surface style={{ padding: 24 }}>
      <InlineFlex $justifyContent="space-between">
        <Text style={{ marginBottom: 24 }} block type="sh1">
          Payment method
        </Text>
      </InlineFlex>
      {subscription.paymentMethodType === 'card' &&
        subscription.paymentMethodCardBrand && (
          <InlineFlex $justifyContent="space-between">
            <InlineFlex $gap={18}>
              <div style={{ width: '60px' }}>
                <CreditCardLogo type={subscription.paymentMethodCardBrand} />
              </div>
              <BlockStack $gap={4}>
                {/* Card Info */}
                <Text block>
                  {capitalize(subscription.paymentMethodCardBrand)}
                  {subscription.paymentMethodCardLast4 && (
                    <> ending in {subscription.paymentMethodCardLast4}</>
                  )}
                </Text>

                {/* Card Expires Info */}
                {subscription.paymentMethodCardExpires && (
                  <Text block color="subtext">
                    Expires {subscription.paymentMethodCardExpires}
                  </Text>
                )}

                {/* Billing Email Info */}
                <InlineFlex>
                  <EmailIcon />
                  <Text color="subtext">{subscription.billingEmail}</Text>
                </InlineFlex>
              </BlockStack>
            </InlineFlex>
            <Button
              size="M"
              variant="secondary"
              onClick={handleChangePaymentMethod}
            >
              Edit
            </Button>
          </InlineFlex>
        )}
      {!subscription.paymentMethodType && (
        <div>
          <Text block>No payment method stored for this workspace</Text>
        </div>
      )}
      <LoadingLogoInset active={res.fetching} />
    </Surface>
  );
};

const CreditCardLogo = ({ type }: { type?: string }) => {
  switch (type) {
    case 'amex':
      return <AmexIcon />;
    case 'diners':
      return <DinersIcon />;
    case 'discover':
      return <DiscoverIcon />;
    case 'jcb':
      return <JCBIcon />;
    case 'maestro':
      return <MaestroIcon />;
    case 'mastercard':
      return <MasterCardIcon />;
    case 'paypal':
      return <PaypalIcon />;
    case 'unionpay':
      return <UnionpayIcon />;
    case 'visa':
      return <VisaIcon />;
    default:
      return <GenericIcon />;
  }
};

import * as Sentry from '@sentry/react';

import { getBrowserCapabilities } from './utils/browserUtils';

/**
 * Unified resource management system
 */
export class ResourceManager {
  private canvasPools: Map<string, HTMLCanvasElement[]> = new Map();
  private objectUrls: Set<string> = new Set();
  private disposables: Set<{ dispose: () => void }> = new Set();
  private maxPoolSize: number;
  private browserCaps = getBrowserCapabilities();

  constructor(maxPoolSize = 10) {
    this.maxPoolSize = Math.min(
      maxPoolSize,
      this.browserCaps.maxCanvasPoolSize
    );
  }

  /**
   * Get or create a canvas with specified dimensions
   */
  acquireCanvas(width: number, height: number): HTMLCanvasElement {
    const key = `${width}x${height}`;
    if (!this.canvasPools.has(key)) {
      this.canvasPools.set(key, []);
    }
    const pool = this.canvasPools.get(key)!;

    let canvas: HTMLCanvasElement;
    if (pool.length > 0) {
      canvas = pool.pop()!;
    } else {
      canvas = document.createElement('canvas');

      // Apply dimension limits if needed
      const maxDim = this.browserCaps.maxCanvasDimension;
      if (width > maxDim || height > maxDim) {
        const scale = maxDim / Math.max(width, height);
        canvas.width = Math.floor(width * scale);
        canvas.height = Math.floor(height * scale);
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      if (this.browserCaps.isSafari) {
        // Apply Safari-specific optimizations
        canvas.style.transform = 'translateZ(0)';
        canvas.style.backfaceVisibility = 'hidden';
      }
    }

    // Get optimized context based on browser capabilities
    const ctx = canvas.getContext('2d', {
      alpha: true,
      willReadFrequently: false,
      desynchronized: this.browserCaps.supportsDesynchronized,
    });

    if (ctx) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.setTransform(1, 0, 0, 1, 0, 0);
      ctx.globalAlpha = 1;
      ctx.globalCompositeOperation = 'source-over';

      if (this.browserCaps.isSafari) {
        // Optimize image rendering quality for Safari
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = this.browserCaps.isIOS ? 'medium' : 'high';
      }
    }

    return canvas;
  }

  /**
   * Return a canvas to the pool
   */
  releaseCanvas(canvas: HTMLCanvasElement): void {
    // For Safari, be more aggressive with cleanup
    if (this.browserCaps.isSafari) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        // @ts-ignore - Force clear internal buffers
        if (ctx.reset) ctx.reset();
      }
      return; // Don't pool canvases in Safari to prevent memory leaks
    }

    const key = `${canvas.width}x${canvas.height}`;
    if (!this.canvasPools.has(key)) {
      this.canvasPools.set(key, []);
    }
    const pool = this.canvasPools.get(key)!;

    if (pool.length < this.maxPoolSize) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.setTransform(1, 0, 0, 1, 0, 0);
        ctx.globalAlpha = 1;
        ctx.globalCompositeOperation = 'source-over';
      }
      pool.push(canvas);
    }
  }

  /**
   * Create and track an object URL
   */
  createObjectURL(blob: Blob): string {
    const url = URL.createObjectURL(blob);
    this.objectUrls.add(url);
    return url;
  }

  /**
   * Remove and revoke an object URL
   */
  revokeObjectURL(url: string): void {
    if (this.objectUrls.has(url)) {
      URL.revokeObjectURL(url);
      this.objectUrls.delete(url);
    }
  }

  /**
   * Track a disposable resource
   */
  trackDisposable<T extends { dispose: () => void }>(resource: T): T {
    this.disposables.add(resource);
    return resource;
  }

  /**
   * Dispose all resources
   */
  dispose(): void {
    // Clear canvas pools
    this.canvasPools.clear();

    // Revoke all object URLs
    this.objectUrls.forEach((url) => URL.revokeObjectURL(url));
    this.objectUrls.clear();

    // Dispose all tracked resources
    this.disposables.forEach((resource) => {
      try {
        resource.dispose();
      } catch (error) {
        Sentry.captureException(error, {
          tags: { context: 'resource_disposal' },
          extra: { message: 'Error disposing resource' },
        });
      }
    });
    this.disposables.clear();
  }
}

/**
 * Task scheduler for managing update operations with simple FIFO queue
 */
export class UpdateQueue {
  private queue: (() => Promise<void>)[] = [];
  private isProcessing = false;
  private errorHandler?: (error: Error) => void;
  private taskFailureCallback?: (taskIndex: number, error: Error) => void;

  constructor(options?: {
    onError?: (error: Error) => void;
    onTaskFailure?: (taskIndex: number, error: Error) => void;
  }) {
    this.errorHandler = options?.onError;
    this.taskFailureCallback = options?.onTaskFailure;
  }

  /**
   * Add a task to the queue
   */
  enqueue(task: () => Promise<void>): void {
    this.queue.push(task);
    this.processQueue();
  }

  /**
   * Process queued tasks in FIFO order
   */
  private async processQueue(): Promise<void> {
    if (this.isProcessing || this.queue.length === 0) return;

    this.isProcessing = true;

    try {
      let taskIndex = 0;
      while (this.queue.length > 0) {
        const task = this.queue.shift();
        if (task) {
          try {
            await task();
          } catch (error) {
            // Handle individual task failure
            const taskError =
              error instanceof Error ? error : new Error(String(error));
            this.taskFailureCallback?.(taskIndex, taskError);

            // Optionally bubble up the error through the error handler
            this.errorHandler?.(taskError);
          }
        }
        taskIndex++;
      }
    } catch (error) {
      // Handle queue-level processing errors
      const queueError =
        error instanceof Error ? error : new Error(String(error));
      Sentry.captureException(queueError, {
        tags: { context: 'queue_processing' },
        extra: { message: 'Critical error in queue processing' },
      });
      if (this.errorHandler) {
        this.errorHandler(queueError as Error);
      }
    } finally {
      this.isProcessing = false;

      // If there are new tasks that were added while processing, start processing them
      if (this.queue.length > 0) {
        this.processQueue();
      }
    }
  }

  /**
   * Clear all pending tasks
   */
  clear(): void {
    this.queue = [];
  }
}

/**
 * Warm up WebGL context
 */
export const warmupWebGL = async (
  resourceManager: ResourceManager
): Promise<void> => {
  const canvas = resourceManager.acquireCanvas(1, 1);
  try {
    const gl = canvas.getContext('webgl', {
      alpha: true,
      antialias: false,
      depth: false,
      stencil: false,
      preserveDrawingBuffer: false,
    });

    if (!gl) return;

    // Create and bind a dummy texture
    const texture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.RGBA,
      1,
      1,
      0,
      gl.RGBA,
      gl.UNSIGNED_BYTE,
      new Uint8Array([0, 0, 0, 255])
    );

    // Force a draw call
    gl.clear(gl.COLOR_BUFFER_BIT);
    gl.flush();

    // Clean up
    gl.deleteTexture(texture);
  } finally {
    resourceManager.releaseCanvas(canvas);
  }
};

import { MutableRefObject } from 'react';
import { useRegisterVizcomExternalClientApi } from '@vizcom/external-client-api';
import { sortByOrderKey } from '@vizcom/shared/js-utils';

import {
  imageDataToBlob,
  imageToBlob,
} from '../../../../../../../shared/ui/components/src';
import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../../lib/useDrawingSyncedState';
import { LayersCompositorApi } from '../LayersCompositor/context';
import { importBlobsToLayers } from '../WorkbenchStudioFileDropper';

export const useRegisterDrawingExternalApi = (
  drawing: Drawing2dStudio | null | undefined,
  layerCompositor: MutableRefObject<LayersCompositorApi>,
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction']
) => {
  useRegisterVizcomExternalClientApi('getCurrentDrawing', () => {
    if (!drawing) {
      return { loading: false, data: null };
    }
    return {
      loading: false,
      data: {
        id: drawing.id,
        name: drawing.name,
        layers: sortByOrderKey(drawing.layers.nodes).map((layer) => ({
          id: layer.id,
          name: layer.name,
        })),
      },
    };
  });

  useRegisterVizcomExternalClientApi(
    'getCurrentDrawingLayerImageAsBlob',
    async (layerId) => {
      if (!drawing) {
        throw new Error('no drawing open');
      }
      const layer = drawing.layers.nodes.find((layer) => layer.id === layerId);
      if (!layer) {
        throw new Error(`layer not found`);
      }
      if (!layer.imagePath) {
        throw new Error('layer is empty');
      }

      const image = await imageToBlob(layer.imagePath, {
        convertToContentType: 'image/png',
      });

      return { image };
    }
  );

  useRegisterVizcomExternalClientApi(
    'getCurrentDrawingImageAsBlob',
    async () => {
      if (!drawing) {
        throw new Error('no drawing open');
      }
      if (!layerCompositor.current) {
        throw new Error('drawing is not ready yet');
      }
      const imageData = await layerCompositor.current.getCompositedImageAsync();
      const image = await imageDataToBlob(imageData, {
        format: 'image/png',
      });
      return { image };
    }
  );

  useRegisterVizcomExternalClientApi(
    'importBlobsAsNewLayerInCurrentDrawing',
    async (data) => {
      if (!drawing) {
        throw new Error('no drawing open');
      }
      const newLayers = await importBlobsToLayers(data, drawing, handleAction);
      return newLayers.map((layer) => ({
        id: layer.id,
        name: layer.name,
      }));
    }
  );
};

import { useFrame } from '@react-three/fiber';
import { useMemo, useRef } from 'react';
import { useTheme } from 'styled-components';
import { Clock, DoubleSide, Mesh, MeshBasicMaterial } from 'three';

const loaderSegments = 32;

export const CompositeSceneElementLoader = ({ radius }: { radius: number }) => {
  const theme = useTheme();
  const segmentsRef = useRef<Array<Mesh | null>>([]);
  const clock = useMemo(() => new Clock(), []);

  useFrame(() => {
    if (!segmentsRef.current) {
      return;
    }

    const time = clock.getElapsedTime();

    segmentsRef.current.forEach((segment, index) => {
      if (!segment) {
        return;
      }

      const offset = Math.max(
        0.0,
        Math.sin(index * ((Math.PI * 2.0) / loaderSegments) + time * 2.0) * 0.1
      );

      (segment.material as MeshBasicMaterial).opacity = Math.max(
        0.5,
        offset / 0.1
      );
    });
  });

  return (
    <group userData={{ vizcomUI: true }}>
      {Array(loaderSegments)
        .fill(0)
        .map((_, index) => {
          return (
            <mesh
              rotation={[
                -Math.PI / 2.0,
                0.0,
                (index * (Math.PI * 2.0)) / loaderSegments,
              ]}
              key={index}
              ref={(element) => (segmentsRef.current[index] = element)}
            >
              <ringGeometry
                args={[
                  radius - 0.05,
                  radius,
                  32,
                  1,
                  0.0,
                  (Math.PI * 2.0) / loaderSegments,
                ]}
              />
              <meshBasicMaterial
                color={theme.deprecated.primary.default}
                side={DoubleSide}
                transparent
              />
            </mesh>
          );
        })}
    </group>
  );
};

import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
} from 'react';

type ScreenShareContextValue = {
  activeLayerId: string | null;
  activeStopFn: (() => void) | null;
  setScreenShare: (layerId: string | null, stopFn: (() => void) | null) => void;
};

const ScreenShareContext = createContext<ScreenShareContextValue>({
  activeLayerId: null,
  activeStopFn: null,
  setScreenShare: () => {
    /* no-op default */
  },
});

export function ScreenShareProvider({ children }: { children: ReactNode }) {
  const [activeLayerId, setActiveLayerId] = useState<string | null>(null);
  const [activeStopFn, setActiveStopFn] = useState<(() => void) | null>(null);

  const setScreenShare = useCallback(
    (layerId: string | null, stopFn: (() => void) | null) => {
      setActiveLayerId(layerId);
      setActiveStopFn(() => stopFn);
    },
    []
  );

  return (
    <ScreenShareContext.Provider
      value={{ activeLayerId, activeStopFn, setScreenShare }}
    >
      {children}
    </ScreenShareContext.Provider>
  );
}

export function useScreenShareContext() {
  return useContext(ScreenShareContext);
}

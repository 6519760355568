import { ConnectedFreeToProModal } from './FreeToProModal';
import { ConnectedLegacyPaywallModal } from './LegacyPaywallModal';
import { usePaywallModalState } from './paywallModalState';
export const GlobalPaywallModal = () => {
  const { isOpen, close, variant } = usePaywallModalState();

  return variant === 'freeToPro' ? (
    <ConnectedFreeToProModal isOpen={isOpen} onClose={close} />
  ) : (
    <ConnectedLegacyPaywallModal isOpen={isOpen} onClose={close} />
  );
};

import { GroupProps, useFrame, useThree } from '@react-three/fiber';
import { useEffect, useRef, useState } from 'react';
import { Group, Vector3 } from 'three';

const VIEWPORT_FLIP_THRESHOLD = 0.45;

type GroupWithPositionProp = Omit<GroupProps, 'position' | 'children'> & {
  position: [number, number, number];
  pivot: number;
  children: (props: { flipped: boolean }) => React.ReactNode;
};

// Similar to a <group> but reacts to move above or below the pivot based on the camera position and viewport
export const ViewportFlipGroup = ({
  pivot,
  children,
  ...rest
}: GroupWithPositionProp) => {
  const [flipped, setFlipped] = useState(false);
  const [initialised, setInitialised] = useState(false);

  const groupRef = useRef<Group>(null!);
  const { camera } = useThree();

  const pivotPositionRef = useRef(new Vector3(0, pivot, 0));
  const worldPosition = useRef(new Vector3());

  useEffect(() => {
    pivotPositionRef.current.set(0, pivot, 0);
  }, [pivot]);

  useFrame(() => {
    if (!groupRef.current) return;

    worldPosition.current.copy(pivotPositionRef.current).project(camera);

    if (worldPosition.current.y > VIEWPORT_FLIP_THRESHOLD) {
      if (groupRef.current.position.y > 0) {
        groupRef.current.position.setY(-Math.abs(rest.position[1]));
        setFlipped(true);
      }
    } else {
      if (groupRef.current.position.y < 0) {
        groupRef.current.position.setY(Math.abs(rest.position[1]));
        setFlipped(false);
      }
    }

    if (!initialised) {
      setInitialised(true);
    }
  });

  return (
    <group ref={groupRef} {...rest}>
      {initialised && children({ flipped })}
    </group>
  );
};

import { base64ToArrayBuffer } from './base64';
import { downloadFileAtUrl } from './downloadImage';

export const downloadModel = async (
  base64String: string,
  fileName: string,
  format: string,
  mimeType: string
) => {
  const buffer = await base64ToArrayBuffer(base64String);
  const url = URL.createObjectURL(new Blob([buffer], { type: mimeType }));
  downloadFileAtUrl(url, fileName, format);
};

import { ErrorIconRaw } from '@vizcom/shared-ui-components';

import { StaticSvg } from './StaticSvg';

export const ErrorPlaceholder = ({
  scale,
  color,
}: {
  scale?: number | [number, number];
  color?: string;
}) => {
  const meshScale = Array.isArray(scale) ? ([...scale, 1] as any) : scale;
  const svgScale =
    (Array.isArray(scale) ? Math.min(scale[0], scale[1]) : scale || 1) * 0.05;

  return (
    <group>
      <mesh scale={meshScale}>
        <planeGeometry args={[1, 1, 1, 1]} />
        <meshBasicMaterial color={0xbbbbbb} />
      </mesh>
      <StaticSvg
        svg={ErrorIconRaw}
        fillMaterial={{ color }}
        scale={[svgScale, svgScale, 1]}
      />
    </group>
  );
};

import styled from 'styled-components';
import { Text } from '@vizcom/shared-ui-components';

export const DeprecatedElectronPage = () => {
  return (
    <Container>
      <Text block type="h2" style={{ maxWidth: 600, textAlign: 'center' }}>
        This version of the app is too old. Please go to http://vizcom.ai/ to
        download the latest version of the app. All your files and settings will
        be preserved.
      </Text>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${(p) => p.theme.surface.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  min-width: 100vw;
`;

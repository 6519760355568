import {
  MutableRefObject,
  useRef,
  useState,
  PointerEvent,
  useEffect,
} from 'react';
import styled from 'styled-components';
import {
  Button,
  FullscreenIcon,
  InlineFlex,
  LoopIcon,
  PauseIcon,
  PlayIcon,
} from '@vizcom/shared-ui-components';

export const VideoControls = ({
  videoRef,
  currentTime,
  loop,
  fullscreen,
  showPlayPause,
  playing,
  setPlaying,
  setLoop,
  setFullscreen,
}: {
  videoRef: MutableRefObject<HTMLVideoElement | null>;
  currentTime: number;
  loop: boolean;
  fullscreen: boolean;
  showPlayPause?: boolean;
  playing: boolean;
  setPlaying: (playing: boolean) => void;
  setLoop: (loop: boolean) => void;
  setFullscreen: (fullscreen: boolean) => void;
}) => {
  const progressBarRef = useRef<HTMLDivElement | null>(null);
  const [isScrubbing, setIsScrubbing] = useState(false);
  const [duration, setDuration] = useState(0);

  const handleScrub = (clientX: number) => {
    if (!videoRef.current || !progressBarRef.current) return;

    const rect = progressBarRef.current.getBoundingClientRect();
    const xPos = clientX - rect.left;
    const clamped = Math.max(0, Math.min(xPos, rect.width));
    const ratio = clamped / rect.width;
    videoRef.current.currentTime = ratio * videoRef.current.duration;
  };

  useEffect(() => {
    if (videoRef.current?.duration) {
      setDuration(videoRef.current.duration);

      return;
    }

    videoRef.current?.addEventListener('loadedmetadata', () => {
      setDuration(videoRef.current?.duration ? videoRef.current.duration : 0);
    });
  }, [videoRef.current]);

  if (!videoRef.current) {
    return null;
  }

  const durationToTime = new Date(duration * 1000)
    .toISOString()
    .substring(14, 19);
  const progress = duration ? (currentTime / duration) * 100 : 0;
  const currentTimeToTime = new Date(currentTime * 1000)
    .toISOString()
    .substring(14, 19);

  const handlePointerDown = (e: PointerEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsScrubbing(true);
    e.currentTarget.setPointerCapture(e.pointerId);
    handleScrub(e.clientX);
  };

  const handlePointerMove = (e: PointerEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (isScrubbing) {
      handleScrub(e.clientX);
    }
  };

  const handlePointerUp = (e: PointerEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsScrubbing(false);
    e.currentTarget.releasePointerCapture(e.pointerId);
  };

  return (
    <ControlsContainer $showPlayPause={showPlayPause}>
      <ControlsBackground />
      {showPlayPause && !playing && (
        <Button
          size="icon"
          onClick={() => {
            videoRef.current?.play();
            setPlaying(true);
          }}
        >
          <PlayIcon />
        </Button>
      )}
      {showPlayPause && playing && (
        <Button
          size="icon"
          onClick={() => {
            videoRef.current?.pause();
            setPlaying(false);
          }}
        >
          <PauseIcon />
        </Button>
      )}
      <InlineFlex>
        {currentTimeToTime} / {durationToTime}
      </InlineFlex>
      <ProgressBar>
        <ProgressInteraction
          ref={progressBarRef}
          onPointerDown={handlePointerDown}
          onPointerMove={handlePointerMove}
          onPointerUp={handlePointerUp}
          onDragStart={(e) => e.preventDefault()}
        />
        <Progress $progress={progress} />
        <Thumb $progress={progress} />
      </ProgressBar>
      <LoopButton
        $loop={loop}
        size="icon"
        onClick={() => setLoop(!loop)}
        variant="secondary"
      >
        <LoopIcon />
      </LoopButton>
      <Button onClick={() => setFullscreen(!fullscreen)} size="icon">
        <FullscreenIcon />
      </Button>
    </ControlsContainer>
  );
};

const ControlsContainer = styled.div<{ $showPlayPause?: boolean }>`
  position: absolute;
  z-index: 1;
  bottom: 8px;
  left: 14px;
  right: 14px;
  justify-content: center;
  padding: 0 8px;
  width: calc(100% - 28px);
  height: 40px;
  color: ${({ theme }) => theme.deprecated.white};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  display: grid;
  grid-template-columns: ${({ $showPlayPause }) =>
    $showPlayPause
      ? '32px max-content 1fr 32px 32px'
      : 'max-content 1fr 32px 32px'};
  gap: 8px;
  justify-content: center;
  align-items: center;
  pointer-events: all;
`;

const ControlsBackground = styled.div`
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: ${({ theme }) => theme.surface.primary};
  opacity: 0.8;
  border-radius: ${({ theme }) => theme.borderRadius.m};
`;

const ProgressBar = styled.div`
  position: relative;
  width: 100%;
  height: 4px;
  background-color: ${({ theme }) => theme.icon.secondary};
  border-radius: 2px;
  cursor: pointer;
`;

const Progress = styled.div<{ $progress: number }>`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: ${({ $progress }) => $progress}%;
  background-color: ${({ theme }) => theme.icon.primary};
  border-radius: 2px;
`;

const ProgressInteraction = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  height: 40px;
  transform: translateY(-50%);
  z-index: 1;
`;

const Thumb = styled.div<{ $progress: number }>`
  position: absolute;
  top: 50%;
  left: ${({ $progress }) => $progress}%;
  transform: translate(-50%, -50%);
  width: 14px;
  height: 14px;
  border: 2px solid ${({ theme }) => theme.surface.primary};
  background-color: ${({ theme }) => theme.icon.primary};
  border-radius: 50%;
`;

const LoopButton = styled(Button)<{ $loop: boolean }>`
  background-color: ${({ theme, $loop }) =>
    $loop ? theme.button.secondaryHover : theme.button.secondary};

  &:focus {
    background-color: ${({ theme, $loop }) =>
      $loop ? theme.button.secondaryHover : theme.button.secondary};
  }
`;

import { useMemo } from 'react';
import {
  BrushSelectionIcon,
  RectangleSelectionIcon,
  LassoIcon,
  BezierCurvesIcon,
  AutoSelectionIcon,
  ToolbarButtonState,
  ToolbarMenuButton,
} from '@vizcom/shared-ui-components';

import tooltip_bezier_selection from '../../../../assets/videos/tooltip_bezier_selection_x480.mp4';
import tooltip_brush_selection from '../../../../assets/videos/tooltip_brush_selection_x480.mp4';
import tooltip_lasso_selection from '../../../../assets/videos/tooltip_lasso_selection_x480.mp4';
import { useSubscribeToSelectionApi } from '../../selection/useSelectionApi';
import {
  WorkbenchStudioToolType,
  WorkbenchTool,
  useWorkbenchStudioState,
} from '../../studioState';
import { Hotkey } from '../../style';
import { SelectionSettings } from '../Selection/SelectionSettings';
import { VideoTooltip } from '../VideoTooltip';

interface SelectionButtonProps {
  currentTool: WorkbenchStudioToolType;
  isMenuOpen: boolean;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SELECTION_TOOLS: WorkbenchTool[] = [
  {
    type: WorkbenchStudioToolType.Lasso,
    icon: <LassoIcon />,
    hotkey: 'G',
    tooltip: {
      title: 'Lasso selection',
      description:
        'Freely draw a selection area to isolate and regenerate specific parts of your image.',
      videoSrc: tooltip_lasso_selection,
    },
  },
  {
    type: WorkbenchStudioToolType.RectangleSelection,
    icon: <RectangleSelectionIcon />,
    hotkey: 'G',
    tooltip: 'Rectangle selection',
  },
  {
    type: WorkbenchStudioToolType.BrushSelection,
    icon: <BrushSelectionIcon />,
    hotkey: 'G',
    tooltip: {
      title: 'Brush selection',
      description:
        'Paint over areas to create a selection mask for targeted edits or regeneration.',
      videoSrc: tooltip_brush_selection,
    },
  },
  {
    type: WorkbenchStudioToolType.BezierSelection,
    icon: <BezierCurvesIcon />,
    hotkey: 'G',
    tooltip: {
      title: 'Bezier selection',
      description:
        'Create precise selections with smooth, curved paths using Bézier controls and regenerate specific areas within the selection.',
      videoSrc: tooltip_bezier_selection,
    },
  },
  {
    type: WorkbenchStudioToolType.AutoSelection,
    icon: <AutoSelectionIcon />,
    tooltip: 'Auto selection',
    hotkey: 'G',
  },
];

export const SelectionButton = ({
  currentTool,
  isMenuOpen,
  setIsMenuOpen,
}: SelectionButtonProps) => {
  const studioState = useWorkbenchStudioState();
  const hasSelectionMask = useSubscribeToSelectionApi((state) => state.hasMask);
  const selectedTool = useMemo(
    () =>
      SELECTION_TOOLS.find(
        ({ type }) => type === studioState.selectionSettings.lastSelectionTool
      ) || SELECTION_TOOLS[0],
    [studioState.selectionSettings.lastSelectionTool]
  );
  const state = useMemo(
    () =>
      SELECTION_TOOLS.some((tool) => tool.type === currentTool)
        ? ToolbarButtonState.ACTIVE
        : hasSelectionMask
        ? ToolbarButtonState.SECONDARY_ACTIVE
        : ToolbarButtonState.INACTIVE,
    [currentTool, hasSelectionMask]
  );

  return (
    <ToolbarMenuButton
      state={state}
      icon={selectedTool.icon}
      tooltip={
        typeof selectedTool.tooltip === 'string' ? (
          <>
            {selectedTool.tooltip} <Hotkey>{selectedTool.hotkey}</Hotkey>
          </>
        ) : (
          <VideoTooltip
            title={
              <>
                {selectedTool.tooltip.title}{' '}
                <Hotkey>{selectedTool.hotkey}</Hotkey>
              </>
            }
            description={selectedTool.tooltip?.description}
            videoSrc={selectedTool.tooltip?.videoSrc || ''}
          />
        )
      }
      onClick={() => studioState.setTool(selectedTool.type)}
      menu={
        <SelectionSettings
          onSelectFromMenu={() => setIsMenuOpen(!setIsMenuOpen)}
          isUseCheckmarks
        />
      }
      menuTooltip="Selection Tools"
      controlledMenuState={[isMenuOpen, setIsMenuOpen]}
      showMenuIcon
    />
  );
};

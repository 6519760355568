import { noop } from 'lodash';
import styled, { css } from 'styled-components';
import {
  Button,
  DotsPagination,
  Modal,
  Text,
} from '@vizcom/shared-ui-components';

import SelectionCircle from './assets/selection-circle.svg?react';

interface StepProps {
  title: string;
  description: string;
  options: {
    id: string;
    label: string;
    image: JSX.Element;
  }[];
  selectedOptions: { [key: number]: string[] };
  maxSelectedOptions?: number;
  toggleSelectedOption: (option: string, maxSelectedOptions?: number) => void;
  currentStep: number;
  totalSteps: number;
  onChangeStep: (index: number) => void;
  onSubmit?: () => void;
  isOpen: boolean;
}

export const Step = ({
  title,
  description,
  options,
  selectedOptions,
  maxSelectedOptions = 1,
  toggleSelectedOption,
  currentStep,
  totalSteps,
  onChangeStep,
  onSubmit,
  isOpen,
}: StepProps) => (
  <Modal isOpen={isOpen} setIsOpen={noop} style={modalStyle}>
    <Header>
      <Text type="h1">{title}</Text>
      <Text type="sh1" color="subtext">
        {description}
      </Text>
    </Header>
    <GridSelect
      options={options}
      selectedOptions={selectedOptions}
      maxSelectedOptions={maxSelectedOptions}
      currentStep={currentStep}
      toggleSelectedOption={toggleSelectedOption}
    />
    <Footer>
      <PaginationWrapper>
        <DotsPagination
          currentStepIndex={currentStep}
          stepsCount={totalSteps}
        />
      </PaginationWrapper>
      <Navigation>
        {currentStep > 0 && (
          <Button
            variant="tertiary"
            onClick={() => onChangeStep(currentStep - 1)}
          >
            Back
          </Button>
        )}
        <Button
          variant="primary"
          onClick={
            currentStep === totalSteps - 1 && onSubmit
              ? () => onSubmit()
              : () => onChangeStep(currentStep + 1)
          }
          disabled={selectedOptions[currentStep].length === 0}
        >
          {currentStep === totalSteps - 1 ? 'Done' : 'Next'}
        </Button>
      </Navigation>
    </Footer>
  </Modal>
);

const GridSelect = ({
  options,
  selectedOptions = [],
  maxSelectedOptions,
  currentStep,
  toggleSelectedOption,
}: Pick<
  StepProps,
  | 'options'
  | 'selectedOptions'
  | 'maxSelectedOptions'
  | 'currentStep'
  | 'toggleSelectedOption'
>) => {
  return (
    <OptionsWrapper $variant={options.length}>
      {options.map((option) => (
        <OptionButton
          key={option.id}
          $selected={selectedOptions[currentStep].includes(option.id)}
          onClick={() => toggleSelectedOption(option.id, maxSelectedOptions)}
        >
          <StyledSelectionCircle
            $selected={selectedOptions[currentStep].includes(option.id)}
          />
          {option.image}
          <OptionLabel type="sh1">{option.label}</OptionLabel>
        </OptionButton>
      ))}
    </OptionsWrapper>
  );
};

const modalStyle = {
  display: 'flex',
  gap: '32px',
  flexDirection: 'column',
  width: '600px',
} as const;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const OptionButton = styled.div<{ $selected: boolean }>`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
  color: ${({ theme }) => theme.text.subtext};

  border: 1px solid ${({ theme }) => theme.border.primary};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  background-color: transparent;

  transition: border-color 0.3s ease, color 0.3s ease,
    background-color 0.3s ease;
  svg:last-of-type {
    position: relative;
    transition: rotate 0.3s ease;
  }

  :hover {
    border-color: transparent;
    color: ${({ theme }) => theme.text.body};
    background-color: ${({ theme }) => theme.surface.tertiary};

    svg:last-of-type {
      rotate: 15deg;
    }
  }

  ${({ $selected }) =>
    $selected &&
    css`
      border-color: transparent;
      color: ${({ theme }) => theme.text.body};
      background-color: transparent !important;

      svg:last-of-type {
        rotate: 0deg !important;
      }
    `}
`;

const OptionsWrapper = styled.div<{ $variant: number }>`
  display: grid;
  grid-template-columns: repeat(
    ${({ $variant }) => ($variant === 9 ? 3 : 2)},
    1fr
  );
  gap: ${({ $variant }) => ($variant === 9 ? '12px' : '24px')};

  ${OptionButton} {
    gap: ${({ $variant }) => ($variant === 9 ? 8 : 19)}px;
    padding: ${({ $variant }) => ($variant === 9 ? '6px 0' : '20px 0 9px')};
  }
`;

const StyledSelectionCircle = styled(SelectionCircle)<{ $selected: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;

  color: ${({ theme, $selected }) =>
    $selected ? theme.border.action : 'transparent'};

  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  animation: ${({ $selected }) =>
    $selected ? 'draw-circle 0.5s ease reverse forwards' : ''};

  @keyframes draw-circle {
    to {
      stroke-dashoffset: 1000;
    }
  }
`;

const OptionLabel = styled(Text)`
  position: relative;
`;

const Footer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
`;

const PaginationWrapper = styled.div`
  grid-area: 1 / 2 / 2 / 3;

  display: flex;
  justify-content: center;
`;

const Navigation = styled.div`
  grid-area: 1 / 3 / 2 / 4;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 8px;
`;

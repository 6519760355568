import styled, { useTheme } from 'styled-components';
import { LayerMetadata3d } from '@vizcom/shared/js-utils';
import {
  Layer3dLightIcon,
  FeatureFlagged,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  Layer3dDropShadowIcon,
  NumberInput,
  Text,
  Toolbar,
  ToolbarMenuButton,
  ToolbarButtonState,
  ToolbarDivider,
} from '@vizcom/shared-ui-components';

import { LayerData } from '../../../../lib/actions/drawing/updateLayer';
import { DrawingLayer } from '../../../../lib/useDrawingSyncedState';
import { Layer3dLightSettings } from './Layer3dLightSettings';
import { Layer3dDropShadowSettings } from './Layer3dShadowDropShadowSettings';
import { MaterialModeToggle } from './MaterialToggle';
import { ControlsMode } from './types';

export function Layer3DMenu({
  controlsMode,
  setControlsMode,
  isOrbiting,
  active,
  materialMode,
  toggleRenderMode,
  setEnvironmentIntensity,
  setDropShadowOpacity,
  focalLength,
  setTempFocalLength,
  setIsFocusing,
  updateLayer,
  layer,
}: {
  controlsMode: ControlsMode;
  setControlsMode: (controlMode: ControlsMode) => void;
  isOrbiting: boolean;
  active: boolean;
  materialMode: 'Texture' | 'Mesh';
  toggleRenderMode: () => void;
  focalLength: number;
  setTempFocalLength: (tempFocalLength: number | null) => void;
  setEnvironmentIntensity: (environmentIntensity: number) => void;
  setIsFocusing: (isFocusing: boolean) => void;
  setDropShadowOpacity: (dropShadowOpacity: number) => void;
  updateLayer: (
    update?: Partial<LayerData>,
    updateLayerThumbnail?: boolean
  ) => void;
  layer: DrawingLayer;
}) {
  const theme = useTheme();

  return (
    <Layer3DToolbar
      position="centered-above"
      $active={!isOrbiting && active}
      onPointerDown={(e) => e.stopPropagation()}
    >
      <MaterialModeToggle
        materialMode={materialMode}
        toggleMaterialMode={() => {
          toggleRenderMode();
        }}
        disabled={isOrbiting || !active}
      />
      <RichTooltip trigger="hover" placement="top" {...theme.tooltip}>
        <RichTooltipTrigger>
          <NumberInput
            value={focalLength}
            min={10}
            max={200}
            unit="mm"
            onChange={(value) => {
              setTempFocalLength(
                Math.round(Math.min(200, Math.max(10, value)))
              );
              setIsFocusing(true);
            }}
            setValue={(value) => {
              const metadata3DUpdate: Partial<LayerMetadata3d> = {
                ...layer.metadata3D,
                view: {
                  ...(layer.metadata3D?.view ?? {}),
                  focalLength: Math.round(Math.min(200, Math.max(10, value))),
                },
              };

              // if this is being set and the onChange event was never called
              // (i.e. the user directly set the value)
              // first set the temp value to the new value so that
              // the scene renders with the new value and the thumbnail
              // is taken with the new perspective
              setTempFocalLength(
                Math.round(Math.min(200, Math.max(10, value)))
              );
              setTimeout(() => {
                updateLayer({ metadata3D: metadata3DUpdate });
                setTempFocalLength(null);
                setIsFocusing(false);
              }, 0);
            }}
            dragArrows={true}
            enableGestureSlider
          />
        </RichTooltipTrigger>
        <RichTooltipContent>
          <Text>Focal length</Text>
        </RichTooltipContent>
      </RichTooltip>
      <FeatureFlagged flag="2DSTUDIO_3DLAYERS_LIGHT_CONTROLS">
        <ToolbarDivider />
        <ToolbarMenuButton
          state={
            isOrbiting
              ? ToolbarButtonState.DISABLED
              : controlsMode === ControlsMode.Light
              ? ToolbarButtonState.ACTIVE
              : ToolbarButtonState.INACTIVE
          }
          onClick={() => {
            setControlsMode(
              controlsMode !== ControlsMode.Light
                ? ControlsMode.Light
                : ControlsMode.Orbit
            );
          }}
          icon={<Layer3dLightIcon />}
          tooltip="Light"
          menu={
            <Layer3dLightSettings
              layer={layer}
              updateLayer={updateLayer}
              onEnvironmentIntensityChange={setEnvironmentIntensity}
            />
          }
          menuStyle={{ padding: '4px' }}
        />

        <ToolbarMenuButton
          state={
            isOrbiting
              ? ToolbarButtonState.DISABLED
              : controlsMode === ControlsMode.Shadow
              ? ToolbarButtonState.ACTIVE
              : ToolbarButtonState.INACTIVE
          }
          onClick={() => {
            setControlsMode(
              controlsMode !== ControlsMode.Shadow
                ? ControlsMode.Shadow
                : ControlsMode.Orbit
            );
          }}
          icon={<Layer3dDropShadowIcon />}
          tooltip="Shadow"
          menu={
            <Layer3dDropShadowSettings
              updateLayer={updateLayer}
              layer={layer}
              onDropShadowOpacityChange={setDropShadowOpacity}
            />
          }
          menuStyle={{ padding: '4px' }}
        />
      </FeatureFlagged>
    </Layer3DToolbar>
  );
}

const Layer3DToolbar = styled(Toolbar)<{ $active: boolean }>`
  opacity: ${({ $active }) => ($active ? 1 : 0)};
  transition: opacity 0.2s ease;
`;

import styled, { useTheme } from 'styled-components';
import {
  ToolbarButton,
  Button,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  ToolbarDivider,
  CloseIcon,
} from '@vizcom/shared-ui-components';

import {} from '../../../../lib/useDrawingSyncedState';
import { useSelectionApiStore } from '../../selection/useSelectionApi';
import { useWorkbenchStudioState } from '../../studioState';
import { ToolbarSlider } from '../ToolbarSlider';

export const SelectionOffsetToolbar = () => {
  const theme = useTheme();
  const selectionApiStore = useSelectionApiStore();
  const {
    selectionSettings: { offset, setOffset },
  } = useWorkbenchStudioState();

  return (
    <>
      <ToolbarSliderContainer>
        <ToolbarSlider
          value={offset}
          setValue={setOffset}
          label="Offset"
          min={-48}
          max={48}
          step={1}
          unit="px"
          relativeCenter={0}
          sliderWidth={120}
        />
        <ToolbarDivider />
      </ToolbarSliderContainer>

      <RichTooltip placement="bottom">
        <RichTooltipTrigger>
          <Button
            variant="primary"
            onClick={() => selectionApiStore.getState().applyOffset(offset)}
            style={{
              fontSize: '12px',
              padding: '0 18px',
              height: '32px',
            }}
          >
            Apply
          </Button>
        </RichTooltipTrigger>
        <RichTooltipContent style={{ color: theme.deprecated.white }}>
          Apply
        </RichTooltipContent>
      </RichTooltip>

      <ToolbarButton
        icon={<CloseIcon />}
        onClick={() => selectionApiStore.getState().exitOffsetMode()}
        tooltip="exit"
        tooltipOptions={{ placement: 'bottom' }}
      />
    </>
  );
};

const ToolbarSliderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding-left: 4px;
`;

import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import {
  useInviteToTeam,
  useTeam,
  useTeamMembers,
} from '@vizcom/shared/data-access/graphql';
import {
  Button,
  EmailMultiInput,
  LoadingLogoInset,
  Modal,
  ModalActions,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalTitle,
  Text,
  addToast,
  formatErrorMessage,
  useLastNonNullValue,
} from '@vizcom/shared-ui-components';

interface AddMembersModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  teamId: string | undefined;
  autocompleteOptions: string[];
}

export const AddMembersModal = (props: AddMembersModalProps) => {
  const { open, setOpen, autocompleteOptions } = props;
  const teamId = useLastNonNullValue(props.teamId);
  const { data: team, fetching } = useTeam(teamId);
  const { data: teamMembers, fetching: fetchingTeamMembers } =
    useTeamMembers(teamId);
  const [inviteToTeamRes, inviteToTeam] = useInviteToTeam();

  const [inviteEmailsValue, setInviteEmailsValue] = useState([] as string[]);

  if (!teamId) {
    return null;
  }
  if (fetching || fetchingTeamMembers) {
    return <LoadingLogoInset />;
  }
  if (!team) {
    return <Navigate to={'/'} replace />;
  }

  const alreadyInvited =
    teamMembers?.invites.nodes.map((invite) => invite.email) || [];
  const autocompleteOptionsWithoutInvites = autocompleteOptions.filter(
    (email) => !alreadyInvited.includes(email)
  );

  const handleInviteToTeam: React.FormEventHandler<HTMLFormElement> = async (
    e
  ) => {
    e.preventDefault();
    if (inviteEmailsValue.length === 0) {
      return addToast('You need to add emails to send the invites');
    }

    const res = await inviteToTeam({
      input: {
        emails: inviteEmailsValue,
        teamId,
      },
    });

    if (res.error) {
      return addToast('Error while inviting users', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }

    addToast('Invites sent!');
    setInviteEmailsValue([]);
    setOpen(false);
  };

  return (
    <Modal
      isOpen={open}
      setIsOpen={setOpen}
      loading={inviteToTeamRes.fetching}
      style={{
        maxWidth: 700,
        width: '90vw',
      }}
    >
      <ModalHeader>
        <ModalTitle>Invite members to {team.name}</ModalTitle>
        <ModalCloseButton />
      </ModalHeader>
      <form onSubmit={handleInviteToTeam}>
        <ModalContent>
          <Text block style={{ marginBottom: 12 }}>
            Users that are not yet members of the workspace will also be invited
            to it.
          </Text>

          <EmailMultiInput
            emails={inviteEmailsValue}
            setEmails={setInviteEmailsValue}
            autoCompleteOptions={autocompleteOptionsWithoutInvites}
          />
        </ModalContent>
        <ModalActions>
          <Button
            variant="tertiary"
            type="button"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Invite
          </Button>
        </ModalActions>
      </form>
    </Modal>
  );
};

import { Object3D } from 'three';

import { ClientSideWorkbenchElementData } from '../../lib/clientState';
import { getElementSize } from '../helpers';
import { filterChildByWorkbenchElementUserData } from '../objectsUserdata';

interface FreeSlotFinderOptions {
  firstSlotX: number;
  firstSlotY: number;
  slotWidth: number;
  slotHeight: number;
  maxElementPerLine: number;
}

const FREE_SLOT_DETECTION_THRESHOLD = 5;

/**
 * Finds the first free slot in a scene.
 *
 * @param scene - The scene to search for free slots in.
 * @param options - The options to customize the search behavior.
 * @returns The first free slot found in the scene.
 */
export const findFirstFreeSlotInScene = (
  scene: Object3D,
  options: FreeSlotFinderOptions
) => {
  const children = filterChildByWorkbenchElementUserData(scene, () => true);
  const elements = children.map((object) => ({
    x: object.userData.elementX,
    y: object.userData.elementY,
    width: object.userData.elementWidth,
    height: object.userData.elementHeight,
  }));
  return findFirstFreeSlotFromElements(elements, options);
};

/**
 * Finds the first free slot from a collection of elements.
 *
 * @param elements - The collection of elements to search for free slots.
 * @param options - The options for finding a free slot.
 * @returns The first free slot found in the element collection.
 */
export const findFirstFreeSlotInElementCollection = (
  elements: ClientSideWorkbenchElementData[],
  options: FreeSlotFinderOptions
) => {
  const elementData = elements.map((element) => {
    const size = getElementSize(element);
    return {
      x: element.x,
      y: element.y,
      width: size.width,
      height: size.height,
    };
  });
  return findFirstFreeSlotFromElements(elementData, options);
};

/**
 * Finds the first free slot from a list of elements.
 *
 * @param elements - The list of elements to check for occupied positions.
 * @param options - The options for finding the free slot.
 * @returns The coordinates of the first free slot.
 */
export const findFirstFreeSlotFromElements = (
  elements: {
    x: number;
    y: number;
    width: number;
    height: number;
  }[],
  options: FreeSlotFinderOptions
) => {
  const targetPosition = [options.firstSlotX, options.firstSlotY];
  // give up after testing 1000 positions
  for (let i = 1; i < 1000; i++) {
    const isPositionOccupied = elements.some((element) => {
      const dx = targetPosition[0] - element.x;
      const dy = targetPosition[1] - element.y;
      const distance = Math.sqrt(dx * dx + dy * dy);

      // Check if the new element would overlap with the existing element
      const wouldOverlap =
        Math.abs(dx) < element.width / 2 + options.slotWidth / 2 &&
        Math.abs(dy) < element.height / 2 + options.slotHeight / 2;

      return distance < FREE_SLOT_DETECTION_THRESHOLD || wouldOverlap;
    });

    if (!isPositionOccupied) {
      return targetPosition;
    }

    if (i % options.maxElementPerLine === 0) {
      targetPosition[0] = options.firstSlotX;
      targetPosition[1] -= options.slotHeight;
    } else {
      targetPosition[0] += options.slotWidth;
    }
  }
  return [options.firstSlotX, options.firstSlotY];
};

import {
  UpdateMultiWorkbenchElementsMutation,
  publishTrackingEvent,
  urqlClient,
} from '@vizcom/shared/data-access/graphql';
import { filterExists } from '@vizcom/shared/js-utils';
import { trackEvent } from '@vizcom/shared-data-access-analytics';

import { getElementSize } from '../../../components/helpers';
import {
  SyncedActionPayloadFromType,
  SyncedActionType,
} from '../../SyncedAction';
import { ClientSideWorkbenchElementData } from '../../clientState';
import { elementById, elementIsDrawing } from '../../utils';

export type NewElementData = {
  id: string;
  x?: number;
  y?: number;
  zIndex?: number;
  width?: number;
  height?: number;
};

export const MultiUpdateElementsAction: SyncedActionType<
  ClientSideWorkbenchElementData[],
  {
    type: 'multiPosition';
    newElementData: NewElementData[];
  },
  {
    elements: ClientSideWorkbenchElementData[];
  }
> = {
  type: 'multiPosition',
  optimisticUpdater: ({ payload }, elements) => {
    payload.newElementData.forEach(({ id, x, y, zIndex, width, height }) => {
      const element = elementById(elements, id);
      if (!element) return;
      if (elementIsDrawing(element)) {
        element.workbenchSizeRatio =
          (width ?? getElementSize(element).width) / element.drawingWidth;
      } else if ('width' in element && 'height' in element) {
        element.width = width ?? element.width;
        element.height = height ?? element.height;
      }
      element.x = x ?? element.x;
      element.y = y ?? element.y;
      element.zIndex = zIndex ?? element.zIndex;
    });
  },
  remoteUpdater: async ({ payload, meta }, workbenchId) => {
    const res = await urqlClient.mutation(
      UpdateMultiWorkbenchElementsMutation,
      {
        input: {
          newElementData: payload.newElementData
            .map(({ id, x, y, zIndex, width, height }) => {
              const element = meta.custom?.elements.find((el) => el.id === id);
              if (!element) {
                // element could have been deleted before executing the multiPosition operation
                // in this case ignore it
                return null;
              }
              if (elementIsDrawing(element)) {
                return {
                  id,
                  x,
                  y,
                  zIndex,
                  workbenchSizeRatio:
                    width === undefined || height === undefined
                      ? undefined
                      : width / element.drawingWidth,
                };
              }
              return {
                id,
                x,
                y,
                zIndex,
                width,
                height,
              };
            })
            .filter(filterExists),
        },
      }
    );

    if (res?.error) {
      throw new Error(
        `Error while updating elements, please retry. ${
          res.error.graphQLErrors[0]?.message ?? res.error.message
        }`
      );
    }

    trackEvent('Update Elements');
    publishTrackingEvent({
      type: 'UPDATE_ELEMENTS',
      data: {
        workbenchId,
        elementIds: payload.newElementData.map((el) => el.id),
      },
    });
  },
  metaConstructor: (payload, elements) => ({
    custom: {
      elements: payload.newElementData
        .map(({ id }) => elementById(elements, id))
        .filter(filterExists),
    },
  }),
  undoConstructor: ({ payload }, elements) => {
    const undoPayload: SyncedActionPayloadFromType<
      typeof MultiUpdateElementsAction
    > = {
      ...payload,
      newElementData: payload.newElementData
        .map(({ id, height, width, x, y, zIndex }) => {
          const element = elementById(elements, id);
          if (!element) {
            return;
          }
          const patch = {
            id: element.id,
          } as NewElementData;
          if (height !== undefined && width !== undefined) {
            Object.assign(patch, getElementSize(element));
          }
          if (x !== undefined) {
            patch.x = element.x;
          }
          if (y !== undefined) {
            patch.y = element.y;
          }
          if (zIndex !== undefined) {
            patch.zIndex = element.zIndex;
          }
          return patch;
        })
        .filter(filterExists),
    };
    return undoPayload;
  },
};

import { Line } from '@react-three/drei';
import { sortBy } from 'lodash';
import { Fragment } from 'react';
import { useTheme } from 'styled-components';
import { filterExists } from '@vizcom/shared/js-utils';

import { WorkbenchContentRenderingOrder } from '../../WorkbenchContent';
import { BoundingBox } from '../helpers';
import { DEFAULT_GAP } from './layoutHelpers';

type GuideType =
  | 'vertical'
  | 'verticalLeft'
  | 'verticalRight'
  | 'horizontal'
  | 'horizontalTop'
  | 'horizontalBottom';

export type SnapGuide = {
  type: GuideType;
  position: number;
  start: number;
  end: number;
  startMarker: number;
  endMarker: number;
};

/**
 * Finds the snap guides for aligning a bounding box to rectangles.
 *
 * @param rectangles - The list of rectangles to consider for aligment.
 * @param boundingBox - The bounding box to align.
 * @returns An object containing the snap guides, as well as the bestSnapX and bestSnapY values for snapping elements.
 */
export function findSnapGuides(
  rectangles: BoundingBox[],
  boundingBox: {
    left: number;
    right: number;
    top: number;
    bottom: number;
  },
  cursorPosition?: { x: number; y: number },
  initialOffset?: { x: number; y: number }
) {
  // Initialize the bestGuide object to store the best snap guides for each type
  const bestGuide = {
    vertical: null as SnapGuide | null,
    verticalLeft: null as SnapGuide | null,
    verticalRight: null as SnapGuide | null,
    horizontal: null as SnapGuide | null,
    horizontalTop: null as SnapGuide | null,
    horizontalBottom: null as SnapGuide | null,
  };

  // Calculate the center coordinates of the bounding box
  const boundingBoxCenterX = (boundingBox.left + boundingBox.right) / 2;
  const boundingBoxCenterY = (boundingBox.top + boundingBox.bottom) / 2;

  // Sort elements from closest to furthest from the center of the bounding box
  sortBy(rectangles, (rect) =>
    Math.hypot(rect.x - boundingBoxCenterX, rect.y - boundingBoxCenterY)
  ).forEach((rect) => {
    // Calculate the left and right edges of the element
    const elementLeft = rect.x - rect.width / 2;
    const elementRight = rect.x + rect.width / 2;

    // Calculate the top and bottom edges of the element
    const elementTop = rect.y + rect.height / 2;
    const elementBottom = rect.y - rect.height / 2;

    // Calculate the center coordinates of the element
    const elementCenterX = rect.x;
    const elementCenterY = rect.y;

    // Check if the element is above or below the bounding box
    const isAbove = elementCenterY > boundingBox.top;

    // Calculate the properties for the vertical alignment line
    const verticalLineProperties = {
      start: isAbove
        ? Math.min(boundingBox.bottom, elementBottom)
        : Math.max(boundingBox.top, elementTop),
      end: isAbove
        ? Math.max(elementTop, boundingBox.top)
        : Math.min(elementBottom, boundingBox.bottom),
      startMarker: isAbove ? elementBottom : elementTop,
      endMarker: isAbove ? elementTop : elementBottom,
    };

    // Check if the element is to the left or right of the bounding box
    const isLeft = elementCenterX < boundingBox.left;

    // Calculate the properties for the horizontal alignment line
    const horizontalLineProperties = {
      start: isLeft
        ? Math.max(boundingBox.right, elementRight)
        : Math.min(boundingBox.left, elementLeft),
      end: isLeft
        ? Math.min(elementLeft, boundingBox.left)
        : Math.max(elementRight, boundingBox.right),
      startMarker: isLeft ? elementRight : elementLeft,
      endMarker: isLeft ? elementLeft : elementRight,
    };

    // Check and update vertical alignments
    updateBestGuide(
      'verticalLeft',
      boundingBox.left,
      elementLeft,
      verticalLineProperties
    );
    updateBestGuide(
      'verticalLeft',
      boundingBox.left,
      elementRight,
      verticalLineProperties
    );
    updateBestGuide(
      'verticalRight',
      boundingBox.right,
      elementLeft,
      verticalLineProperties
    );
    updateBestGuide(
      'verticalRight',
      boundingBox.right,
      elementRight,
      verticalLineProperties
    );
    updateBestGuide(
      'vertical',
      boundingBoxCenterX,
      elementCenterX,
      verticalLineProperties
    );

    // Check and update horizontal alignments
    updateBestGuide(
      'horizontalTop',
      boundingBox.top,
      elementTop,
      horizontalLineProperties
    );
    updateBestGuide(
      'horizontalTop',
      boundingBox.top,
      elementBottom,
      horizontalLineProperties
    );
    updateBestGuide(
      'horizontalBottom',
      boundingBox.bottom,
      elementBottom,
      horizontalLineProperties
    );
    updateBestGuide(
      'horizontalBottom',
      boundingBox.bottom,
      elementTop,
      horizontalLineProperties
    );
    updateBestGuide(
      'horizontal',
      boundingBoxCenterY,
      elementCenterY,
      horizontalLineProperties
    );
  });

  // Get all valid snap guides
  const guides = Object.values(bestGuide).filter(filterExists);

  // If no cursor position provided, just return the guides
  if (!cursorPosition) {
    return { guides, snappedPosition: null };
  }

  // Handle cursor snapping
  const offset = initialOffset || { x: 0, y: 0 };
  const snappedPosition = { ...cursorPosition };
  let thresholdX = DEFAULT_GAP;
  let thresholdY = DEFAULT_GAP;
  let activeGuides: SnapGuide[] = [];

  guides.forEach((guide) => {
    const diff = Math.abs(
      (guide.type === 'vertical' ? cursorPosition.x : cursorPosition.y) -
        offset[guide.type === 'vertical' ? 'x' : 'y'] -
        guide.position
    );

    if (guide.type === 'vertical' && diff < thresholdX) {
      thresholdX = diff;
      snappedPosition.x = guide.position + offset.x;
      activeGuides = activeGuides.filter((g) => g.type !== 'vertical');
      activeGuides.push(guide);
    }

    if (guide.type === 'horizontal' && diff < thresholdY) {
      thresholdY = diff;
      snappedPosition.y = guide.position + offset.y;
      activeGuides = activeGuides.filter((g) => g.type !== 'horizontal');
      activeGuides.push(guide);
    }
  });

  return {
    guides: activeGuides,
    snappedPosition,
  };

  /**
   * Updates the bestGuide object with the closest snap guide for the given type.
   *
   * @param side - The type of the snap guide (vertical, verticalLeft, verticalRight, horizontal, horizontalTop, horizontalBottom).
   * @param boxPosition - The position of the bounding box edge.
   * @param elementPosition - The position of the element edge.
   * @param lineProperties - The properties for the alignment line.
   */
  function updateBestGuide(
    side:
      | 'vertical'
      | 'verticalLeft'
      | 'verticalRight'
      | 'horizontal'
      | 'horizontalTop'
      | 'horizontalBottom',
    boxPosition: number,
    elementPosition: number,
    lineProperties: {
      start: number;
      end: number;
      startMarker: number;
      endMarker: number;
    }
  ) {
    // Calculate the distance between the boxPosition and elementPosition
    const distance = Math.abs(boxPosition - elementPosition);

    // Check if the element is within the default gap
    if (distance < DEFAULT_GAP) {
      // Create a new snap guide object
      const newGuide = {
        type: (side.startsWith('vertical')
          ? 'vertical'
          : 'horizontal') as SnapGuide['type'],
        position: elementPosition,
        ...lineProperties,
      };

      // Check if the new snap guide is closer than the existing snap guide for the given type
      if (
        bestGuide[side] === null ||
        distance < Math.abs(boxPosition - bestGuide[side]!.position)
      ) {
        // Update the bestGuide object with the new snap guide
        bestGuide[side] = newGuide;
      }
    }
  }
}

/**
 * Finds the best snap positions for X and Y coordinates based on the given snap guides.
 *
 * @param guides - The snap guides.
 * @param boundingBoxCenter - The center coordinates of the bounding box.
 * @returns An object containing the bestSnapX and bestSnapY values.
 */
export function getBestSnapXY(
  guides: SnapGuide[],
  boundingBoxCenter: { x: number; y: number }
) {
  let bestSnapX = null;
  let bestSnapY = null;

  // Find the closest snap position for X coordinate
  bestSnapX = guides
    .filter((guide) => guide.type.startsWith('vertical'))
    .reduce((acc, guide) => {
      if (acc === null) return guide.position;
      return Math.abs(guide.position - boundingBoxCenter.x) <
        Math.abs(acc - boundingBoxCenter.x)
        ? guide.position
        : acc;
    }, null as number | null);

  // Find the closest snap position for Y coordinate
  bestSnapY = guides
    .filter((guide) => guide.type.startsWith('horizontal'))
    .reduce((acc, guide) => {
      if (acc === null) return guide.position;
      return Math.abs(guide.position - boundingBoxCenter.y) <
        Math.abs(acc - boundingBoxCenter.y)
        ? guide.position
        : acc;
    }, null as number | null);

  return { bestSnapX, bestSnapY };
}

export function getSnapOffset(
  bestSnapX: number | null,
  bestSnapY: number | null,
  boundingBox: { left: number; right: number; top: number; bottom: number }
) {
  let snapOffsetX = 0;
  let snapOffsetY = 0;

  const boundingBoxCenterX = (boundingBox.left + boundingBox.right) / 2;
  const boundingBoxCenterY = (boundingBox.top + boundingBox.bottom) / 2;

  if (bestSnapX !== null) {
    if (
      Math.abs(bestSnapX - boundingBoxCenterX) <
        Math.abs(bestSnapX - boundingBox.left) &&
      Math.abs(bestSnapX - boundingBoxCenterX) <
        Math.abs(bestSnapX - boundingBox.right)
    ) {
      snapOffsetX = bestSnapX - boundingBoxCenterX;
    } else if (
      Math.abs(bestSnapX - boundingBox.left) <
      Math.abs(bestSnapX - boundingBox.right)
    ) {
      snapOffsetX = bestSnapX - boundingBox.left;
    } else {
      snapOffsetX = bestSnapX - boundingBox.right;
    }
  }

  if (bestSnapY !== null) {
    if (
      Math.abs(bestSnapY - boundingBoxCenterY) <
        Math.abs(bestSnapY - boundingBox.top) &&
      Math.abs(bestSnapY - boundingBoxCenterY) <
        Math.abs(bestSnapY - boundingBox.bottom)
    ) {
      snapOffsetY = bestSnapY - boundingBoxCenterY;
    } else if (
      Math.abs(bestSnapY - boundingBox.top) <
      Math.abs(bestSnapY - boundingBox.bottom)
    ) {
      snapOffsetY = bestSnapY - boundingBox.top;
    } else {
      snapOffsetY = bestSnapY - boundingBox.bottom;
    }
  }

  return [snapOffsetX, snapOffsetY];
}

export function SnapGuideLines({ snapGuides }: { snapGuides: SnapGuide[] }) {
  const theme = useTheme();
  const markerSize = 3;

  const createXMarker = (position: [number, number, number]) => {
    const [x, y, z] = position;
    return (
      <>
        <Line
          points={[
            [x - markerSize, y - markerSize, z],
            [x + markerSize, y + markerSize, z],
          ]}
          color={theme.text.error}
          lineWidth={2}
          transparent
        />
        <Line
          points={[
            [x - markerSize, y + markerSize, z],
            [x + markerSize, y - markerSize, z],
          ]}
          color={theme.text.error}
          lineWidth={2}
          transparent
        />
      </>
    );
  };

  return (
    <group
      userData={{
        vizcomRenderingOrder: [
          {
            zIndex: WorkbenchContentRenderingOrder.indexOf('actions'),
            escapeZIndexContext: true,
          },
        ],
      }}
    >
      {snapGuides.map((guide) => {
        return (
          <Fragment
            key={`${guide.position}-${guide.start}-${guide.end}-${guide.type}`}
          >
            <group>
              <Line
                points={
                  guide.type === 'vertical'
                    ? [
                        [guide.position, guide.start, 0],
                        [guide.position, guide.end, 0],
                      ]
                    : [
                        [guide.start, guide.position, 0],
                        [guide.end, guide.position, 0],
                      ]
                }
                color={theme.text.error}
                lineWidth={1}
                transparent
              />

              {createXMarker(
                guide.type === 'vertical'
                  ? [guide.position, guide.startMarker, 0]
                  : [guide.startMarker, guide.position, 0]
              )}
              {createXMarker(
                guide.type === 'vertical'
                  ? [guide.position, guide.endMarker, 0]
                  : [guide.endMarker, guide.position, 0]
              )}
            </group>
          </Fragment>
        );
      })}
    </group>
  );
}

export const areSnapGuidesEqual = (
  guides1: SnapGuide[],
  guides2: SnapGuide[]
) => {
  if (guides1.length !== guides2.length) return false;
  return guides1.every((guide, index) =>
    checkSnapGuideEquality(guide, guides2[index])
  );
};

const checkSnapGuideEquality = (guide1: SnapGuide, guide2: SnapGuide) =>
  guide1.position === guide2.position && guide1.type === guide2.type;

import { useFrame, useThree } from '@react-three/fiber';
import { useEffect, useRef } from 'react';
import { OrthographicCamera } from 'three';

import { useMapControls } from './mapControls/utils';

const IGNORE_TAG_NAMES = [
  'INPUT',
  'TEXTAREA',
  'SELECT',
  'BUTTON',
  'A',
  'OPTION',
  'OPTGROUP',
  'TBODY',
  'THEAD',
  'TFOOT',
  'TABLE',
  'TR',
  'TD',
  'TH',
  'DL',
  'DT',
  'DD',
  'OL',
  'UL',
  'LI',
  'DATALIST',
];

interface UseArrowKeyPanningProps {
  panSpeed?: number;
}

export const useArrowKeyPanning = ({
  panSpeed = 2,
}: UseArrowKeyPanningProps = {}) => {
  const controls = useMapControls();
  const panVelocity = useRef({ x: 0, y: 0 });
  const { camera } = useThree();

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // Ignore if focus is on an input or contentEditable element
      if (
        e.target instanceof HTMLElement &&
        (e.target.isContentEditable ||
          IGNORE_TAG_NAMES.includes(e.target.tagName))
      ) {
        return;
      }

      if (e.repeat) {
        // Ignore auto-repeated keydown events
        return;
      }

      // Prevent default scrolling behavior for arrow keys
      if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
        e.preventDefault();
        e.stopPropagation();
      }

      switch (e.key) {
        case 'ArrowUp':
          panVelocity.current.y = 1;
          break;
        case 'ArrowDown':
          panVelocity.current.y = -1;
          break;
        case 'ArrowLeft':
          panVelocity.current.x = -1;
          break;
        case 'ArrowRight':
          panVelocity.current.x = 1;
          break;
        default:
          break;
      }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (
        e.target instanceof HTMLElement &&
        (e.target.isContentEditable ||
          IGNORE_TAG_NAMES.includes(e.target.tagName))
      ) {
        return;
      }

      if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
        e.preventDefault();
        e.stopPropagation();
      }

      switch (e.key) {
        case 'ArrowUp':
        case 'ArrowDown':
          panVelocity.current.y = 0;
          break;
        case 'ArrowLeft':
        case 'ArrowRight':
          panVelocity.current.x = 0;
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown, { capture: true });
    window.addEventListener('keyup', handleKeyUp, { capture: true });

    return () => {
      window.removeEventListener('keydown', handleKeyDown, { capture: true });
      window.removeEventListener('keyup', handleKeyUp, { capture: true });
    };
  }, []);

  useFrame((state, delta) => {
    if (!controls) return;

    // Ensure the camera is an OrthographicCamera
    if (!(camera instanceof OrthographicCamera)) return;

    // Adjust pan steps based on current zoom to maintain consistent panning speed
    const zoom = camera.zoom;
    const adjustedPanSpeed = panSpeed;

    const panStepX = (panVelocity.current.x * adjustedPanSpeed * delta) / zoom;
    const panStepY = (panVelocity.current.y * adjustedPanSpeed * delta) / zoom;

    if (panStepX !== 0 || panStepY !== 0) {
      controls.moveTo({
        x: panStepX,
        y: panStepY,
        relative: true,
        skipAnimation: true,
        controlled: true,
      });
    }
  });
};

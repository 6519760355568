import { useThree } from '@react-three/fiber';
import { MouseEventHandler, useContext, useRef } from 'react';
import styled from 'styled-components';
import { Group } from 'three';
import { v4 as uuidv4 } from 'uuid';
import { WorkbenchElementMixData } from '@vizcom/shared/data-access/graphql';
import {
  Button,
  Text,
  addToast,
  TextInput,
} from '@vizcom/shared-ui-components';

import { useWorkbenchSyncedState } from '../../../lib/useWorkbenchSyncedState';
import { GENERATED_IMAGES_MARGIN, isDraggingContext } from '../../../lib/utils';
import { WorkbenchElementExtra } from '../../WorkbenchElementExtra';
import { WorkbenchElementDragConnector } from '../../elementConnector/workbenchElementDragConnector';
import {
  getElementSize,
  getWorkbenchElementZPositionRange,
} from '../../helpers';
import { findNearestParentObjectWithWorkbenchElementUserData } from '../../objectsUserdata';
import { CustomHtml } from '../../utils/CustomHtml';
import { RoundedPlaneGeometry } from '../../utils/RoundedPlaneGeometry';
import { findFirstFreeSlotInScene } from '../../utils/freeSlotFinders';
import { getMixElementHeight } from '../../utils/getContentSize';
import { MixSlider, DisabledMixSlider } from './MixSlider';

interface WorkbenchElementMixProps {
  workbenchId: string;
  element: WorkbenchElementMixData;
  isDragging: boolean;
  isResizing: boolean;
  singleFocused: boolean;
  showSingleFocusedControls: boolean;
  sourceDrawingsThumbnails: Record<string, SourceDrawingData> | undefined;
  selectedSourceId: string | null;
  setSelectedSourceId: (sourceId: string | null) => void;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
}

export interface MixSourceDrawing {
  sourceDrawingId: string;
  weight: number;
}

export type SourceDrawingData = {
  thumbnail: string | null | undefined;
  height: number;
  width: number;
};

export const WorkbenchElementMix = ({
  workbenchId,
  element,
  singleFocused,
  isDragging,
  isResizing,
  showSingleFocusedControls,
  sourceDrawingsThumbnails,
  setSelectedSourceId,
  handleAction,
}: WorkbenchElementMixProps) => {
  const scene = useThree((s) => s.scene);
  const isDraggingRef = useContext(isDraggingContext);
  const ref = useRef<Group>(null);

  const { width, height } = getElementSize(element);
  const contentHeight = getMixElementHeight(element.sourceDrawings);
  const contentScale = height / contentHeight;

  const hasDrawings =
    element.sourceDrawings && element.sourceDrawings.length > 0;

  const handleSubmit: MouseEventHandler = async (e) => {
    if (isDraggingRef.current) {
      return;
    }

    if (!hasDrawings || element.sourceDrawings.length < 2) {
      return addToast('Connect two images to mix');
    }

    e.preventDefault();
    const width =
      sourceDrawingsThumbnails?.[element.sourceDrawings[0].sourceDrawingId]
        .width || 300;
    const height =
      sourceDrawingsThumbnails?.[element.sourceDrawings[0].sourceDrawingId]
        .height || 300;
    const position = findFirstFreeSlotInScene(scene, {
      firstSlotX:
        element.x + element.width / 2 + width / 2 + GENERATED_IMAGES_MARGIN,
      firstSlotY: element.y,
      slotWidth: width + GENERATED_IMAGES_MARGIN,
      slotHeight: height + GENERATED_IMAGES_MARGIN,
      maxElementPerLine: 4,
    });
    const zRange = getWorkbenchElementZPositionRange(scene);

    handleAction({
      type: 'triggerMix',
      elementId: element.id,
      placeholderId: uuidv4(),
      sourceDrawings: element.sourceDrawings,
      x: position[0],
      y: position[1],
      width: Math.trunc(width),
      height: Math.trunc(height),
      zIndex: isFinite(zRange[1]) ? zRange[1] + 1 : element.zIndex + 1,
    });
  };

  const deleteDrawing = (drawingId: string) => {
    const sourceDrawings = [...element.sourceDrawings];

    const newDrawings = sourceDrawings.filter(
      (source) => source.sourceDrawingId !== drawingId
    );
    handleAction({
      type: 'updateMix',
      elementId: element.id,
      sourceDrawings: newDrawings,
    });
  };
  const setWeight = (weight: number, drawingId: string) => {
    const sourceDrawings: MixSourceDrawing[] = JSON.parse(
      JSON.stringify(element.sourceDrawings)
    );

    const sourceIndex = sourceDrawings.findIndex(
      (obj) => obj.sourceDrawingId === drawingId
    );
    sourceDrawings[sourceIndex].weight = weight;
    handleAction({
      type: 'updateMix',
      elementId: element.id,
      sourceDrawings,
    });
  };

  return (
    <>
      {showSingleFocusedControls && !isResizing && (
        <WorkbenchElementDragConnector
          element={element}
          handleAction={handleAction}
          disabled={element.sourceDrawings.length === 5}
        />
      )}
      <group ref={ref}>
        <CustomHtml
          transform
          occlude
          scaleOcclusionGeometry={false}
          scale={[contentScale, contentScale, 1]}
          parentScale={
            ref.current
              ? findNearestParentObjectWithWorkbenchElementUserData(ref.current)
              : undefined
          }
          geometry={
            <RoundedPlaneGeometry
              radius={16}
              width={width / contentScale}
              height={contentHeight}
            />
          }
        >
          <div
            style={{
              width: width / contentScale,
              height: contentHeight,
              userSelect: 'none',
            }}
          >
            <Mix>
              <MixList>
                {hasDrawings ? (
                  <>
                    {element.sourceDrawings.map((source: MixSourceDrawing) => (
                      <MixSlider
                        key={source.sourceDrawingId}
                        source={source}
                        image={
                          sourceDrawingsThumbnails?.[source.sourceDrawingId]
                            ?.thumbnail
                        }
                        setWeight={setWeight}
                        deleteDrawing={deleteDrawing}
                        setSelectedSourceId={setSelectedSourceId}
                      />
                    ))}
                    {element.sourceDrawings.length < 5 && <DisabledMixSlider />}
                  </>
                ) : (
                  <>
                    <EmptyText>Connect 2-5 images to Mix</EmptyText>
                    <DisabledMixSlider />
                  </>
                )}
                <Button onClick={handleSubmit} variant="primary">
                  <span>Mix Images</span>
                </Button>
              </MixList>
            </Mix>
          </div>
        </CustomHtml>
      </group>
      {!isDragging && singleFocused && !isResizing && (
        <WorkbenchElementExtra
          workbenchId={workbenchId}
          element={element}
          position={[0, height / 2, 0]}
          pivot={element.y}
          handleAction={handleAction}
        />
      )}
    </>
  );
};

const Mix = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 24px;
  gap: 8px;
  background: ${({ theme }) => theme.surface.primary};
  border-radius: ${(p) => p.theme.borderRadius.m};
  color: ${({ theme }) => theme.text.body};
  ${TextInput} {
    flex: 1;
  }
`;

const MixList = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 6px;
  overflow-y: auto;
  justify-content: space-between;
`;

const EmptyText = styled(Text)`
  font-size: 18px;
  color: ${({ theme }) => theme.text.subtext};
`;

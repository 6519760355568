import * as Sentry from '@sentry/react';
import { useCallback, useMemo, useRef } from 'react';

import { ResourceManager } from '../performance';
import {
  InferenceService,
  InferenceMetadata,
} from '../services/inferenceService';

export const REALTIME_PRESETS = {
  FAST: {
    minUpdateInterval: 4, // ~240fps
    maxRequestsPerMinute: 2400,
    maxConcurrentRequests: 24,
  },
  MEDIUM: {
    minUpdateInterval: 8, // ~120fps
    maxRequestsPerMinute: 1200,
    maxConcurrentRequests: 12,
  },
  SLOW: {
    minUpdateInterval: 500, // 2fps
    maxRequestsPerMinute: 60, // 1 request per second
    maxConcurrentRequests: 1,
  },
} as const;

export type RealtimeMode = keyof typeof REALTIME_PRESETS;

export interface RealtimeEngineConfig {
  minUpdateInterval: number;
  maxRequestsPerMinute: number;
  maxConcurrentRequests: number;
}

interface EngineState {
  current: 'idle' | 'loading' | 'error';
  inFlightCount: number;
}

/**
 * Hook for managing realtime inference requests with automatic retries,
 * rate limiting, and error handling.
 *
 * @param resourceManager - Resource manager instance for handling canvas and blobs
 * @returns Object containing inference functions and state
 */
export const useRealtimeEngine = (
  resourceManager: ResourceManager,
  mode: RealtimeMode = 'FAST'
) => {
  const inferenceService = useMemo(
    () => new InferenceService(resourceManager),
    [resourceManager]
  );

  const config = useMemo(() => REALTIME_PRESETS[mode], [mode]);
  const lastRequestTime = useRef<number>(0);
  const state = useRef<EngineState>({ current: 'idle', inFlightCount: 0 });

  const makeInferenceCall = useCallback(
    async (
      compositeImage: ImageData,
      metadata: InferenceMetadata
    ): Promise<Blob | null> => {
      const now = Date.now();
      if (now - lastRequestTime.current < config.minUpdateInterval) {
        return null;
      }

      // Check if we've hit the concurrency limit
      if (state.current.inFlightCount >= config.maxConcurrentRequests) {
        return null;
      }

      lastRequestTime.current = now;

      try {
        // Update state to reflect new in-flight request
        state.current = {
          current: 'loading',
          inFlightCount: state.current.inFlightCount + 1,
        };

        const result = await inferenceService.makeInferenceRequest(
          compositeImage,
          metadata
        );

        // Update state to reflect completed request
        state.current = {
          current: 'idle',
          inFlightCount: Math.max(0, state.current.inFlightCount - 1),
        };

        return result;
      } catch (error) {
        // Update state to reflect error and decrement in-flight count
        state.current = {
          current: 'error',
          inFlightCount: Math.max(0, state.current.inFlightCount - 1),
        };

        Sentry.captureException(error, {
          tags: { context: 'inference' },
          extra: { message: 'Inference error' },
        });
        throw error;
      }
    },
    [inferenceService, config]
  );

  const resetState = useCallback(() => {
    lastRequestTime.current = 0;
    state.current = { current: 'idle', inFlightCount: 0 };
  }, []);

  return {
    makeInferenceCall,
    resetState,
    state,
    error: null,
  };
};

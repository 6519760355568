import { PublicPalette } from '@vizcom/shared/inference-worker-queues';

import IconCarExterior from './icon-carExterior.png';
import IconCarInterior from './icon-carInterior.png';
import IconCarShading from './icon-carShading.png';
import IconCyberCel from './icon-cybercel.png';
import IconDesignSketch from './icon-designSketch.png';
import IconGeneral from './icon-general.png';
import IconLineArt from './icon-lineart.png';
import IconPastel from './icon-pastel.png';
import IconProductRender from './icon-productRender.png';
import IconSurfaceSculpt from './icon-surfaceSculpt.png';
import IconTechnicolor from './icon-technicolor.png';
import IconVolume from './icon-volume.png';
import PublicPaletteThumbnail from './palette.png';

export { PublicPaletteThumbnail };

export const PALETTE_DISPLAY_ORDER = [
  'Essentials',
  'Stylized',
  'Automotive',
  'Local palettes',
];

export enum PaletteCategory {
  Essentials = 'Essentials',
  Stylized = 'Stylized',
  Automotive = 'Automotive',
  Local = 'Local palettes',
  MY_LIBRARY = 'My Library',
}

export const PUBLIC_PALETTES_DETAILS = () => [
  {
    id: 'general',
    name: 'Vizcom General',
    desc: 'Stunning photorealism that puts the focus on your product.',
    category: PaletteCategory.Essentials,
    value: PublicPalette.general,
    thumbnailPath: IconGeneral,
    versions: [{ value: PublicPalette.generalV2, version: 2 }],
  },
  {
    id: 'sculpt',
    name: 'Surface Sculpt',
    desc: 'Three-dimensional look with a emphasis on depth and form.',
    category: PaletteCategory.Essentials,
    value: PublicPalette.surfaceSculpt,
    thumbnailPath: IconSurfaceSculpt,
  },
  {
    id: 'volume',
    name: 'Volume Render',
    desc: 'Three-dimensional look with a emphasis on depth and form.',
    category: PaletteCategory.Essentials,
    value: PublicPalette.volume,
    thumbnailPath: IconVolume,
    versions: [{ value: PublicPalette.volume_v2, version: 2 }],
  },
  {
    id: 'interior',
    name: 'Interior',
    desc: 'Detailed renderings emphasizing texture, lighting, and space in automotive interiors',
    category: PaletteCategory.Automotive,
    value: PublicPalette.carInterior,
    thumbnailPath: IconCarInterior,
    versions: [{ value: PublicPalette.carInterior_v2, version: 2 }],
  },
  {
    id: 'exterior',
    name: 'Exterior',
    desc: 'Vivid car design renders with a emphasis on shadows, and reflections.',
    category: PaletteCategory.Automotive,
    value: PublicPalette.carExterior,
    thumbnailPath: IconCarExterior,
    versions: [{ value: PublicPalette.carExterior_v2, version: 2 }],
  },
  {
    id: 'cybercel',
    name: 'Cyber Cel',
    desc: 'Vibrant flat colors with strong outlines and high-contrast',
    category: PaletteCategory.Stylized,
    value: PublicPalette.cybercel,
    thumbnailPath: IconCyberCel,
    versions: [{ value: PublicPalette.cybercel_v2, version: 2 }],
  },
  {
    id: 'technicolor',
    name: 'Technicolor',
    desc: 'A futuristic style with warm inviting colors',
    category: PaletteCategory.Stylized,
    value: PublicPalette.technicolor,
    thumbnailPath: IconTechnicolor,
    versions: [{ value: PublicPalette.technicolor_v2, version: 2 }],
  },
  {
    id: 'pastel',
    name: 'Pastel',
    desc: 'Gentle and soothing color palette, inspired by the soft hues of pastel colors.',
    category: PaletteCategory.Stylized,
    value: PublicPalette.pastel,
    thumbnailPath: IconPastel,
  },
  {
    id: 'pdSketchColor',
    name: 'Design Sketch',
    desc: '',
    category: PaletteCategory.Stylized,
    value: PublicPalette.pdSketchColor_v2,
    thumbnailPath: IconDesignSketch,
  },
  {
    id: 'lineart',
    name: 'Line Art',
    desc: '',
    category: PaletteCategory.Stylized,
    value: PublicPalette.lineart_v2,
    thumbnailPath: IconLineArt,
  },
  {
    id: 'realisticProduct',
    name: 'Realistic Product',
    desc: '',
    category: PaletteCategory.Essentials,
    value: PublicPalette.realisticProduct_v2,
    thumbnailPath: IconProductRender,
  },
  {
    id: 'carShading',
    name: 'Car Shading',
    desc: '',
    category: PaletteCategory.Automotive,
    value: PublicPalette.carShading_v2,
    thumbnailPath: IconCarShading,
  },
];

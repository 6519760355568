import { WorkbenchBasicDataFragment } from 'libs/shared/data-access/graphql/src/gql/graphql';
import styled, { useTheme } from 'styled-components';
import {
  useOrganization,
  useTeamMemberCount,
  useWorkbench,
} from '@vizcom/shared/data-access/graphql';
import {
  Text,
  TeamAvatar,
  FileIcon,
  Divider,
} from '@vizcom/shared-ui-components';

import { useIsWorkbenchViewer } from '../../../lib/utils';
import { CopySharedLinkButton } from './CopySharedLinkButton';
import { FileSharingSelectComponent } from './FileSharingSelectComponent';
import { OrganizationFileSharingWarning } from './OrganizationFileSharingWarning';
import { ExportFloatingContainer } from './PublicFileSharing';

const FileIconSquare = styled.div`
  padding: 6px 6px 6px 5px;
  display: flex;
  background-color: ${(p) => p.theme.surface.tertiary};
  border-radius: ${({ theme }) => theme.borderRadius.s};
`;

const TeamSharingInfos = (props: { workbench: WorkbenchBasicDataFragment }) => {
  const { workbench } = props;

  const { data: org } = useOrganization(workbench.folder?.organization?.id);
  const { data: team } = useTeamMemberCount(workbench.team?.id);

  if (!team) return null;

  const countMembers =
    team.globalInOrganization && org
      ? org?.members.totalCount
      : team.members.totalCount;

  return (
    <FlexSpaced style={{ padding: '8px' }}>
      <TeamAvatar teamId={team.id} name={team.name} size="medium" />
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        }}
      >
        <Text block type="b2">
          {team.name}
        </Text>
        <Text block color="subtext">
          {countMembers} member{countMembers > 1 ? 's' : ''}
        </Text>
      </div>
      <Text type="b1" color="body">
        Can access
      </Text>
    </FlexSpaced>
  );
};

const MyFilesSharingInfos = ({
  sharingEnabled,
}: {
  sharingEnabled: boolean;
}) => {
  const color = useTheme();

  return (
    <FlexSpaced style={{ padding: '0px 8px 8px 8px' }}>
      <FileIconSquare>
        <FileIcon height={12} width={12} color={color.icon.primary} />
      </FileIconSquare>
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          padding: '10px 0px 10px 0px',
        }}
      >
        <Text block type="b2">
          This file is located in My files
        </Text>
        {sharingEnabled && (
          <Text block color="subtext">
            Others can still access this file through link sharing
          </Text>
        )}
      </div>
    </FlexSpaced>
  );
};

export const LinkSharingTabContent = ({
  workbenchId,
}: {
  workbenchId: string;
}) => {
  const isViewer = useIsWorkbenchViewer();
  const { data: workbench } = useWorkbench(workbenchId);

  if (!workbench) return null;

  const publicSharingGloballyEnabled = Boolean(
    workbench.folder?.organization?.publicSharing
  );

  return (
    <ExportFloatingContainer>
      {!isViewer ? (
        <>
          {workbench.team && <TeamSharingInfos workbench={workbench} />}
          {!workbench.team && (
            <MyFilesSharingInfos
              sharingEnabled={
                workbench.publicSharingEnabled && publicSharingGloballyEnabled
              }
            />
          )}
          <Divider />
          {publicSharingGloballyEnabled ? (
            <FlexSpaced>
              <FileSharingSelectComponent workbench={workbench} />
              <CopySharedLinkButton
                workbench={workbench}
                disabled={!workbench.publicSharingEnabled}
              />
            </FlexSpaced>
          ) : (
            <OrganizationFileSharingWarning />
          )}
        </>
      ) : (
        <CopySharedLinkButton
          workbench={workbench}
          disabled={!workbench.publicSharingEnabled}
          $fullWidth
          variant="primary"
        />
      )}
    </ExportFloatingContainer>
  );
};

export const FlexSpaced = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
`;

import { ThreeEvent, useThree } from '@react-three/fiber';
import { useDrag } from '@use-gesture/react';
import { useState } from 'react';
import { Group, OrthographicCamera } from 'three';

import { screenPositionToLocal } from '../../../helpers';

export const useLayerRotationTransform = (
  onGestureEnd: (rotation: number) => void,
  resizerGroupRef: React.MutableRefObject<Group>
) => {
  const camera = useThree((s) => s.camera as OrthographicCamera);
  const [rotationTransform, setRotationTransform] = useState<number>(0);

  const bindRotationHandle = useDrag<ThreeEvent<PointerEvent>>((gesture) => {
    if (gesture.tap) {
      return;
    }
    gesture.event.stopPropagation();
    if (gesture.last) {
      onGestureEnd(rotationTransform);
      return;
    }
    const pointerPosition = screenPositionToLocal(
      [gesture.event.nativeEvent.clientX, gesture.event.nativeEvent.clientY],
      camera,
      resizerGroupRef.current
    );
    const pointerAngleFromCenter = Math.atan2(
      pointerPosition[0],
      pointerPosition[1]
    );

    if (!gesture.memo) {
      return {
        initialAngle: pointerAngleFromCenter,
        initialRotation: rotationTransform,
      };
    }
    const { initialAngle, initialRotation } = gesture.memo;

    const newAngle = initialRotation + initialAngle - pointerAngleFromCenter;

    const snapAngle = gesture.event.shiftKey
      ? Math.round(newAngle / (Math.PI / 4)) * (Math.PI / 4)
      : newAngle;

    setRotationTransform(snapAngle);

    return { initialAngle, initialRotation };
  });

  return {
    bindRotationHandle,
    rotationTransform,
    resetRotationTransform: () => setRotationTransform(0),
  };
};

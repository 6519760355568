import { animated, useSpring } from '@react-spring/web';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { TransitionStatus } from 'react-transition-group';
import styled, { useTheme } from 'styled-components';
import {
  UserClientStateKeys,
  useCurrentUserClientStateByKey,
} from '@vizcom/shared/data-access/graphql';
import {
  FeatureFlagged,
  FloatingPanel,
  InlineFlex,
  Text,
  useKeyboardShortcut,
} from '@vizcom/shared-ui-components';

import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../lib/useDrawingSyncedState';
import { useIsWorkbenchViewer } from '../../lib/utils';
import { AssetLibrary } from '../AssetLibrary/AssetLibrary';
import { Layers } from './Layers';
import { WorkbenchStudioCreate } from './WorkbenchStudioCreate';
import {
  LayersHeaderButtons,
  WorkbenchStudioLayers,
} from './WorkbenchStudioLayers';
import { AiActions } from './ai-actions/AiActions';
import { Create } from './create/Create';
import { PromptHistoryItem } from './history/WorkbenchStudioHistory';
import { ActiveLayerChangeOperation } from './lib/useActiveLayer';
import { Overlay } from './style';
import { Layout } from './types';
import { InferenceSettings } from './useInference';
import { addGroup as addGroupImpl, addLayer as addLayerImpl } from './utils';

type Props = {
  hidden: boolean;
  state: TransitionStatus;
  drawing: Drawing2dStudio;
  anyOutputLoading?: boolean;
  inferenceSettings: InferenceSettings;
  selectedPromptHistoryItem: PromptHistoryItem;
  uiRatio: number;
  activeLayerId: string | undefined;
  viewerVisibility: Record<string, boolean>;
  layersDisabled: boolean;
  isGeneratingPrompt: boolean;
  historyOpen: boolean;
  collapsedGroupStates: Record<string, boolean>;
  setCollapsedGroupStates: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  setIsPrompting: (isPrompting: boolean) => void;
  setUiRatio: Dispatch<SetStateAction<number>>;
  setViewerVisibility: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  setActiveLayerId: (
    id: string | undefined,
    operation?: ActiveLayerChangeOperation
  ) => void;
  onCreateDrawingsFromImage: (
    previews: { preview: ArrayBuffer | Blob; name?: string }[],
    offset?: {
      x: number;
      y: number;
    }
  ) => void;
  applyHistoryItem: (inferenceSettings: InferenceSettings) => void;
  setInferenceSettings: Dispatch<SetStateAction<InferenceSettings>>;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  trigger: () => void;
  triggerAutoPrompt: () => void;
  setSelectedPromptHistoryItem: (item: PromptHistoryItem) => void;
  getCompositedImage: (() => ImageData) | undefined;
  cancelAutoPrompt: () => void;
  onExit: () => void;
};

const WorkbenchStudioCombinedUIImpl = (props: Props) => {
  const theme = useTheme();
  const [spring, api] = useSpring(() => ({}));
  const isViewer = useIsWorkbenchViewer();
  const ref = useRef<HTMLDivElement>(null);
  const [tab, setTab] = useState<'create' | 'layers' | 'assets'>('create');
  const userPreferredLayout = useCurrentUserClientStateByKey(
    UserClientStateKeys.StudioLayout
  );
  const layout: Layout = isViewer
    ? 'default'
    : userPreferredLayout || 'default';

  const addLayer = () =>
    addLayerImpl({
      drawing: props.drawing,
      activeLayerId: props.activeLayerId,
      handleAction: props.handleAction,
      anchorCollapsed:
        props.collapsedGroupStates[props.activeLayerId?.split('/')[0] || ''],
    });

  const addGroup = () =>
    addGroupImpl({
      drawing: props.drawing,
      activeLayerId: props.activeLayerId,
      handleAction: props.handleAction,
    });

  useEffect(() => {
    if (!props.selectedPromptHistoryItem?.output.id) return;

    // flash the create tab when a new history item is selected
    api.start({
      from: {
        boxShadow: `0 0 10px 6px rgba(76, 76, 239, 1)`,
      },
      to: {
        boxShadow: `0 0 10px 6px rgba(76, 76, 239, 0)`,
      },
      config: { duration: 700 },
    });
  }, [props.selectedPromptHistoryItem?.output.id]);

  useEffect(() => {
    if (!ref.current) return;
    if (layout !== 'stacked') {
      ref.current.style.height = 'auto';
      return;
    }

    const newHeight = window.innerHeight * (1 - props.uiRatio) - 100;
    ref.current.style.height = `${newHeight}px`;
  }, [props.uiRatio, layout]);

  useKeyboardShortcut('n', () => {
    if (isViewer) return;
    const id = addLayer();
    props.setActiveLayerId(id);
  });

  useKeyboardShortcut(
    'g',
    () => {
      if (isViewer) return;
      const id = addGroup();
      if (id) props.setActiveLayerId(id);
    },
    {
      ctrl: true,
    }
  );

  if (isViewer) {
    return null;
  }

  return (
    <StudioTabs
      ref={ref}
      $state={props.state}
      style={spring}
      $hidden={props.hidden}
    >
      <PanelSectionContainer>
        <InlineFlex $justifyContent="space-between">
          <InlineFlex $gap={16}>
            <PanelSectionPicker
              $active={tab === 'create'}
              onClick={() => setTab('create')}
            >
              Create
            </PanelSectionPicker>

            <PanelSectionPicker
              $active={tab === 'layers'}
              onClick={() => setTab('layers')}
            >
              Layers
            </PanelSectionPicker>

            <FeatureFlagged flag="ASSETS_LIBRARY">
              <PanelSectionPicker
                $active={tab === 'assets'}
                onClick={() => setTab('assets')}
              >
                Assets
              </PanelSectionPicker>
            </FeatureFlagged>
          </InlineFlex>

          {tab === 'create' && (
            <AiActions
              drawing={props.drawing}
              handleAction={props.handleAction}
              getCompositedImage={props.getCompositedImage}
            />
          )}

          {tab === 'layers' && (
            <LayersHeaderButtons
              isViewer={isViewer}
              activeLayerId={props.activeLayerId}
              addGroup={addGroup}
              addLayer={addLayer}
              setActiveLayerId={props.setActiveLayerId}
            />
          )}
        </InlineFlex>
      </PanelSectionContainer>

      {tab === 'create' && (
        <Create
          drawing={props.drawing}
          anyOutputLoading={props.anyOutputLoading}
          handleAction={props.handleAction}
          inferenceSettings={props.inferenceSettings}
          setInferenceSettings={props.setInferenceSettings}
          trigger={props.trigger}
          triggerAutoPrompt={props.triggerAutoPrompt}
          selectedHistoryPrompt={props.selectedPromptHistoryItem?.prompt}
          applyHistoryItem={props.applyHistoryItem}
          isGeneratingPrompt={props.isGeneratingPrompt}
          cancelAutoPrompt={props.cancelAutoPrompt}
          onExit={props.onExit}
          setActiveLayerId={props.setActiveLayerId}
        />
      )}

      {tab === 'layers' && (
        <>
          {props.layersDisabled && (
            <Overlay
              style={{ borderRadius: theme.borderRadius.l }}
              onClick={() => props.setSelectedPromptHistoryItem(undefined)}
            />
          )}
          <Layers
            collapsedGroupStates={props.collapsedGroupStates}
            setCollapsedGroupStates={props.setCollapsedGroupStates}
            drawing={props.drawing}
            handleAction={props.handleAction}
            activeLayer={props.activeLayerId}
            setActiveLayer={props.setActiveLayerId}
            viewerVisibility={props.viewerVisibility}
            setViewerVisibility={props.setViewerVisibility}
            onCreateDrawingsFromImage={props.onCreateDrawingsFromImage}
          />
        </>
      )}

      {tab === 'assets' && (
        <AssetLibrary workbenchId={props.drawing.workbenchId} />
      )}
    </StudioTabs>
  );
};

// TODO: Combine this into a single component with the implementation above
// All layouts need access to the same collapsible layer group state
export const WorkbenchStudioCombinedUI = (
  props: Omit<Props, 'collapsedGroupStates' | 'setCollapsedGroupStates'>
) => {
  const isViewer = useIsWorkbenchViewer();
  const userPreferredLayout = useCurrentUserClientStateByKey(
    UserClientStateKeys.StudioLayout
  );
  const layout: Layout = isViewer
    ? 'default'
    : userPreferredLayout || 'default';

  const {
    drawing,
    anyOutputLoading,
    inferenceSettings,
    isGeneratingPrompt,
    selectedPromptHistoryItem,
    uiRatio,
    activeLayerId,
    viewerVisibility,
    hidden,
    layersDisabled,
    historyOpen,
    state,
    onExit,
    setIsPrompting,
    setUiRatio,
    setInferenceSettings,
    handleAction,
    trigger,
    triggerAutoPrompt,
    cancelAutoPrompt,
    applyHistoryItem,
    setActiveLayerId,
    setViewerVisibility,
    onCreateDrawingsFromImage,
    setSelectedPromptHistoryItem,
    getCompositedImage,
  } = props;
  const [collapsedGroupStates, setCollapsedGroupStates] = useState<
    Record<string, boolean>
  >(
    drawing.layers.nodes
      .filter((layer) => layer.isGroup)
      .reduce((acc, layer) => {
        acc[layer.id] = true;
        return acc;
      }, {} as Record<string, boolean>)
  );

  return layout === 'classic' ? (
    <WorkbenchStudioCombinedUIImpl
      hidden={hidden}
      drawing={drawing}
      state={state}
      anyOutputLoading={anyOutputLoading}
      inferenceSettings={inferenceSettings}
      isGeneratingPrompt={isGeneratingPrompt}
      selectedPromptHistoryItem={selectedPromptHistoryItem}
      uiRatio={uiRatio}
      activeLayerId={activeLayerId}
      viewerVisibility={viewerVisibility}
      layersDisabled={layersDisabled}
      historyOpen={historyOpen}
      collapsedGroupStates={collapsedGroupStates}
      setInferenceSettings={setInferenceSettings}
      handleAction={handleAction}
      trigger={trigger}
      triggerAutoPrompt={triggerAutoPrompt}
      cancelAutoPrompt={cancelAutoPrompt}
      applyHistoryItem={applyHistoryItem}
      setActiveLayerId={setActiveLayerId}
      setViewerVisibility={setViewerVisibility}
      onCreateDrawingsFromImage={onCreateDrawingsFromImage}
      setSelectedPromptHistoryItem={setSelectedPromptHistoryItem}
      setIsPrompting={setIsPrompting}
      setUiRatio={setUiRatio}
      setCollapsedGroupStates={setCollapsedGroupStates}
      getCompositedImage={getCompositedImage}
      onExit={onExit}
    />
  ) : (
    <>
      <WorkbenchStudioLayers
        hidden={hidden}
        disabled={layersDisabled}
        historyOpen={historyOpen}
        drawing={drawing}
        state={state}
        activeLayerId={activeLayerId}
        viewerVisibility={viewerVisibility}
        uiRatio={uiRatio}
        handleAction={handleAction}
        setActiveLayerId={setActiveLayerId}
        setViewerVisibility={setViewerVisibility}
        onCreateDrawingsFromImage={onCreateDrawingsFromImage}
        setUiRatio={setUiRatio}
        setSelectedPromptHistoryItem={setSelectedPromptHistoryItem}
        collapsedGroupStates={collapsedGroupStates}
        setCollapsedGroupStates={setCollapsedGroupStates}
      />
      <WorkbenchStudioCreate
        hidden={hidden}
        drawing={drawing}
        state={props.state}
        anyOutputLoading={anyOutputLoading}
        inferenceSettings={inferenceSettings}
        setInferenceSettings={setInferenceSettings}
        handleAction={handleAction}
        trigger={trigger}
        triggerAutoPrompt={triggerAutoPrompt}
        isGeneratingPrompt={isGeneratingPrompt}
        cancelAutoPrompt={cancelAutoPrompt}
        selectedPromptHistoryItem={selectedPromptHistoryItem}
        applyHistoryItem={applyHistoryItem}
        uiRatio={uiRatio}
        setIsPrompting={setIsPrompting}
        onExit={onExit}
        setActiveLayerId={setActiveLayerId}
        getCompositedImage={getCompositedImage}
      />
    </>
  );
};

const StudioTabs = styled(animated(FloatingPanel))<{
  $state: TransitionStatus;
  $hidden: boolean;
}>`
  position: absolute;
  z-index: 10000001;
  top: calc(1rem + 66px);
  right: 14px;
  bottom: 100px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 250px;

  opacity: ${({ $hidden }) => ($hidden ? 0 : 1)};
  transform: ${({ $state }) =>
    $state === 'entered' || $state === 'entering'
      ? 'translateX(0)'
      : 'translateX(calc(100% + 14px))'};
  animation: ${({ theme, $state }) =>
      $state === 'entering' ? theme.animation.slideInFromRight : 'none'}
    0.5s ease;
  transition: transform 0.5s ease-in-out;

  pointer-events: ${({ $hidden }) => ($hidden ? 'none' : 'all')};
  * {
    pointer-events: ${({ $hidden }) => ($hidden ? 'none !important' : 'all')};
  }
`;

export const PanelSectionContainer = styled.div`
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ theme }) => theme.text.body};
  width: 100%;
  padding: 12px 14px 0px;
  border-bottom: 2px solid ${(p) => p.theme.surface.tertiary};
  gap: 16px;
`;

export const PanelSectionPicker = styled(Text)<{ $active?: boolean }>`
  cursor: pointer;
  position: relative;
  padding: 12px 0;
  font-weight: 600;
  color: ${({ $active, theme }) =>
    $active ? theme.icon.primary : theme.icon.secondary};

  &:after {
    cursor: auto;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 2px;
    background-color: ${(p) =>
      p.$active ? p.theme.deprecated.white : 'transparent'};
    bottom: -2px;
  }
`;

import { useState } from 'react';
import styled from 'styled-components';
import {
  NewFeatureButton,
  NewFeatureName,
  OnboardingTourTooltipContent,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  Text,
  WorkbenchIcon,
} from '@vizcom/shared-ui-components';

import educationalTooltipVideo04 from '../../../assets/videos/educational-tooltip-04.mp4';
import exit2dStudioVideo from '../../../assets/videos/exit-2d-studio.mp4';
import { OnboardingStep } from '../../utils/OnboardingMultiStep';
import { Hotkey } from '../style';
import { VideoTooltip } from './VideoTooltip';

interface WorkbenchButtonProps {
  isIconOnly: boolean;
  shouldDisplayTour: boolean;
  currentStep: number;
  onExit: () => void;
}

const WorkbenchTrigger = ({
  isIconOnly,
  onExit,
  setIsOpen,
}: {
  isIconOnly: boolean;
  onExit: () => void;
  setIsOpen: (isOpen: boolean) => void;
}) => {
  return (
    <RichTooltipTrigger>
      <NewFeatureButton
        featureName={NewFeatureName.Exit2DStudioToWorkbench}
        onClick={() => {
          setIsOpen(false);
          onExit();
        }}
        variant="secondary"
      >
        <WorkbenchIcon />
        {!isIconOnly && <Text>Workbench</Text>}
      </NewFeatureButton>
    </RichTooltipTrigger>
  );
};

export const WorkbenchButton = ({
  isIconOnly,
  shouldDisplayTour,
  currentStep,
  onExit,
}: WorkbenchButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return shouldDisplayTour && currentStep === 4 ? (
    <RichTooltip isOpen={true} placement="bottom-end" padding={88}>
      <WorkbenchTrigger
        isIconOnly={isIconOnly}
        onExit={onExit}
        setIsOpen={setIsOpen}
      />
      <OnboardingTourTooltipContent>
        <OnboardingStep
          video={educationalTooltipVideo04}
          title="Return to Workbench"
          content="Return to the infinite canvas to explore ideas and collaborate with others."
        />
      </OnboardingTourTooltipContent>
    </RichTooltip>
  ) : (
    <RichTooltip
      trigger="hover"
      padding={11}
      placement="bottom"
      delay={{
        open: 1500,
        close: 0,
      }}
      isOpen={isOpen}
      onOpenChange={setIsOpen}
    >
      <WorkbenchTrigger
        isIconOnly={window.innerWidth < 1000}
        onExit={onExit}
        setIsOpen={setIsOpen}
      />
      <TooltipContent $background="primary">
        <VideoTooltip
          title={
            <>
              Enter Workbench
              <Hotkey>⇧ W</Hotkey>
            </>
          }
          description="Your infinite canvas to explore ideas and collaborate with others."
          videoSrc={exit2dStudioVideo}
        />
      </TooltipContent>
    </RichTooltip>
  );
};

// TODO: see if we can get rid of this
const TooltipContent = styled(RichTooltipContent)`
  color: ${({ theme }) => theme.deprecated.white};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  max-width: 240px;
  padding: 16px;
`;

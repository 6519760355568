import { Mesh, Object3D, Object3DEventMap } from 'three';
import { v4 as uuidv4 } from 'uuid';
import {
  urqlClient,
  ConvertPlaceholderToCompositeSceneMutation,
} from '@vizcom/shared/data-access/graphql';

import { setCompositeSceneNodeUUID } from '../../../components/compositeScene/compositeSceneEditor/utils/compositeSceneNodeUUID';
import {
  createObject3dThumbnail,
  exportObject3d,
  loadGltfFromUrl,
  scaleObject3dToBox,
} from '../../../components/utils/meshHelpers';
import { SyncedActionType } from '../../SyncedAction';
import { ClientSideWorkbenchElementData } from '../../clientState';
import { elementById } from '../../utils';

// when generating a model from an image with the img2mesh action, the resulting model needs to be parsed and converted by the client
// we do it here and send the result to the server
export const ConvertPlaceholderMeshOutputAction: SyncedActionType<
  ClientSideWorkbenchElementData[],
  {
    type: 'convertPlaceholderMeshOutput';
    elementId: string;
  },
  {
    meshOutputPath: string;
  }
> = {
  type: 'convertPlaceholderMeshOutput',
  optimisticUpdater: ({ payload }, elements) => {
    const placeholder = elementById(elements, payload.elementId);
    if (
      !placeholder ||
      placeholder.__typename !== 'WorkbenchElementPlaceholder'
    ) {
      return;
    }
    placeholder.meshOutputPath = null;
  },
  remoteUpdater: async ({ payload, meta }) => {
    if (!meta.custom?.meshOutputPath) {
      return;
    }
    const mesh = await loadGltfFromUrl(meta.custom.meshOutputPath);
    mesh.traverse((child: Object3D<Object3DEventMap>) => {
      setCompositeSceneNodeUUID(child, uuidv4());

      const childAsMesh = child as Mesh;

      if (childAsMesh.material instanceof Array) {
        childAsMesh.material.forEach((material) => {
          setCompositeSceneNodeUUID(material, uuidv4());
        });
      } else if (childAsMesh.material) {
        setCompositeSceneNodeUUID(childAsMesh.material, uuidv4());
      }
    });

    scaleObject3dToBox(mesh);

    const { result: gltfBinary } = await exportObject3d(mesh);
    const thumbnail = await createObject3dThumbnail(mesh, 512, 512);

    const res = await urqlClient.mutation(
      ConvertPlaceholderToCompositeSceneMutation,
      {
        input: {
          id: payload.elementId,
          thumbnail: thumbnail,
          mesh: new Blob([gltfBinary]),
        },
      }
    );

    if (res?.error) {
      throw new Error(
        `Error while creating element, please retry. ${
          res.error.graphQLErrors[0]?.message ?? res.error.message
        }`
      );
    }
  },
  metaConstructor: (payload, elements) => {
    const placeholder = elementById(elements, payload.elementId);
    if (
      !placeholder ||
      placeholder.__typename !== 'WorkbenchElementPlaceholder' ||
      !placeholder.meshOutputPath
    ) {
      return {};
    }
    return {
      custom: {
        meshOutputPath: placeholder.meshOutputPath,
      },
    };
  },
};

import {
  urqlClient,
  UpdateDrawingMutation,
} from '@vizcom/shared/data-access/graphql';

import { SyncedActionType } from '../../SyncedAction';
import { ClientSideWorkbenchElementData } from '../../clientState';
import { elementById } from '../../utils';

export const UpdateDrawingAction: SyncedActionType<
  ClientSideWorkbenchElementData[],
  {
    type: 'updateDrawing';
    drawingId: string;
    name: string;
  }
> = {
  type: 'updateDrawing',
  optimisticUpdater: ({ payload }, elements) => {
    const sourceElement = elementById(elements, payload.drawingId);
    if (sourceElement?.__typename === 'Drawing') {
      sourceElement.name = payload.name;
    }
  },
  remoteUpdater: async ({ payload }) => {
    await urqlClient.mutation(UpdateDrawingMutation, {
      id: payload.drawingId,
      patch: {
        name: payload.name,
      },
    });
  },
  undoConstructor: ({ payload }, elements) => {
    const element = elementById(elements, payload.drawingId);
    if (element?.__typename === 'Drawing') {
      return {
        type: 'updateDrawing',
        drawingId: payload.drawingId,
        name: element.name,
      };
    }
  },
};

import styled from 'styled-components';
import { LoaderIcon as LoaderIconBase } from '@vizcom/shared-ui-components';

export const HeaderSecondary = styled.div`
  display: flex;
  font-weight: 400;
  align-items: center;
  font-size: 12px;
  color: ${({ theme }) => theme.text.subtext};
`;

export const HoverableHeaderSecondary = styled(HeaderSecondary)`
  &:hover {
    color: ${({ theme }) => theme.text.body};
  }
`;

export const SidebarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;

  border-bottom: 1px solid ${({ theme }) => theme.surface.tertiary};

  ${({ theme }) => theme.typography.h4}
`;

export const SidebarHeaderContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.75);
  z-index: 100000000;
`;

export const LoaderIcon = styled(LoaderIconBase)`
  color: ${({ theme }) => theme.text.body};
  width: 5rem;
  height: 5rem;
`;

export const Hotkey = styled.span`
  margin-left: 8px;
  color: ${({ theme }) => theme.border.tertiary};
`;

export const LayerMeatballWrapper = styled.div<{
  $active: boolean;
  $is3d: boolean;
  $open: boolean;
}>`
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  color: ${({ theme, $open, $active, $is3d }) =>
    $open
      ? theme.text.body
      : $active
      ? $is3d
        ? theme['3d'].primary
        : theme.text.subtextSecondary
      : theme.icon.secondary};

  &:hover {
    color: ${({ theme }) => theme.text.body};
  }
`;

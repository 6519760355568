import { Navigate, Route, Routes } from 'react-router-dom';
import { LoggedInAuthGuard } from '@vizcom/auth/ui';
import { Layout } from '@vizcom/shared-ui-components';

import { ChangelogPage } from './App/ChangelogPage';
import { ConfirmDeleteAccountPage } from './ConfirmDeleteAccountPage';
import { OrganizationConfirmDelete } from './OrganizationConfirmDelete/OrganizationConfirmDelete';
import { OrganizationDataPage } from './OrganizationData/OrganizationDataPage';
import { OrganizationGeneralSettings } from './OrganizationGeneralSettings';
import { OrganizationMembers } from './OrganizationMembers/OrganizationMembers';
import { OrganizationSecurityPage } from './OrganizationSecurity/OrganizationSecurityPage';
import { OrganizationSubscriptionPage } from './OrganizationSubscription/OrganizationSubscriptionPage';
import { OrganizationTeams } from './OrganizationTeams/OrganizationTeams';
import { PaletteSettingsPage } from './Palette/PaletteSettingsPage';
import { ProfileSettingsPage } from './ProfileSettings/ProfileSettings';
import { SettingsSidebar } from './components/SettingsSidebar';

export const SettingsPage = () => {
  return (
    <LoggedInAuthGuard>
      <Layout sidebar={<SettingsSidebar />}>
        <Routes>
          <Route
            path="organization/:organizationId/general"
            element={<OrganizationGeneralSettings />}
          />
          <Route
            path="organization/:organizationId/members"
            element={
              <OrganizationMembers
                openInviteMembersModal={false}
                openSubscriptionUpdateModal={false}
              />
            }
          />
          <Route
            path="organization/:organizationId/members/invite"
            element={
              <OrganizationMembers
                openInviteMembersModal={true}
                openSubscriptionUpdateModal={false}
              />
            }
          />
          <Route
            path="organization/:organizationId/members/subscription/update"
            element={
              <OrganizationMembers
                openInviteMembersModal={false}
                openSubscriptionUpdateModal={true}
              />
            }
          />
          <Route
            path="organization/:organizationId/confirm_delete"
            element={<OrganizationConfirmDelete />}
          />
          <Route
            path="organization/:organizationId/teams"
            element={<OrganizationTeams />}
          />
          <Route
            path="organization/:organizationId/teams/create"
            element={<OrganizationTeams createTeamOpen />}
          />
          <Route
            path="organization/:organizationId/teams/:teamId/settings"
            element={<OrganizationTeams openSettingsForSelectedTeam />}
          />
          <Route
            path="organization/:organizationId/teams/:teamId/invite"
            element={<OrganizationTeams openAddMembersForSelectedTeam />}
          />
          <Route
            path="organization/:organizationId/teams/:teamId/join"
            element={<OrganizationTeams autoJoinSelectedTeam />}
          />
          <Route
            path="organization/:organizationId/subscription"
            element={
              <OrganizationSubscriptionPage
                openSubscriptionUpdateModal={false}
              />
            }
          />
          <Route
            path="organization/:organizationId/subscription/update"
            element={
              <OrganizationSubscriptionPage
                openSubscriptionUpdateModal={true}
              />
            }
          />
          <Route
            path="organization/:organizationId/palette"
            element={<PaletteSettingsPage />}
          />
          <Route
            path="organization/:organizationId/security"
            element={<OrganizationSecurityPage />}
          />
          <Route
            path="organization/:organizationId/data/*"
            element={<OrganizationDataPage />}
          />

          <Route path="account/profile" element={<ProfileSettingsPage />} />
          <Route
            path="account/confirm_delete"
            element={<ConfirmDeleteAccountPage />}
          />

          <Route path="app/changelog" element={<ChangelogPage />} />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Layout>
    </LoggedInAuthGuard>
  );
};

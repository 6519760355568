import { isNumber } from 'lodash';
import { Link, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';
import {
  OrganizationSubscriptionData,
  useOrganizationSeatsUsageQuery,
} from '@vizcom/shared/data-access/graphql';
import {
  ProgressBar,
  Text,
  FormattedDate,
  Button,
  WarningIcon,
  InlineFlex,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

import { SubscriptionUpdateModal } from './SubscriptionUpdateModal';

export const SeatsIndicator = ({
  organizationSubscription,
  isSubscriptionUpdateModalOpen,
  setIsSubscriptionUpdateModalOpen,
  onSubscriptionUpdate,
}: {
  organizationSubscription: OrganizationSubscriptionData;
  isSubscriptionUpdateModalOpen: boolean;
  setIsSubscriptionUpdateModalOpen: (isOpen: boolean) => void;
  onSubscriptionUpdate?: () => void;
}) => {
  const { subscription, manualSubscription, paidSeatsCount, id } =
    organizationSubscription;

  const theme = useTheme();
  const location = useLocation();

  const { data, fetching } = useOrganizationSeatsUsageQuery(id);

  // Manually managed organizations with a paid_seats_count of 0 are considered to have an unlimited seat count. No need to display SeatsIndicator
  const unlimitedSeatsCount = manualSubscription && paidSeatsCount === 0;

  if (fetching || !data?.seatsUsage || !subscription || unlimitedSeatsCount) {
    return null;
  }

  const { seatsUsage } = data;

  const showSeatsManagementButton =
    !organizationSubscription.manualSubscription &&
    organizationSubscription.subscriptionPlan === 'PRO';

  return (
    <div>
      <InlineFlex $justifyContent="space-between">
        <div>
          <Text color="subtext">
            {seatsUsage.takenEditorsSeatsCount}/{seatsUsage.editorsSeatsCount}{' '}
            seat
            {subscription.seats > 1 ? 's' : ''} used
            {isNumber(subscription.nextInvoiceAmount) &&
              subscription.nextBillingDate &&
              Math.abs(
                subscription.nextInvoiceAmount -
                  (subscription.subscriptionBaseAmount || 0)
              ) > 1 && (
                <>
                  - next invoice{' '}
                  <FormattedDate date={subscription.nextBillingDate} />
                </>
              )}
          </Text>{' '}
          {!location.pathname.includes('members') && (
            <Link
              to={paths.settings.organization.members(
                organizationSubscription.id
              )}
            >
              <Text color="link" type="b1">
                Manage members
              </Text>
            </Link>
          )}
        </div>

        {showSeatsManagementButton && (
          <Button
            variant="secondary"
            onClick={() => setIsSubscriptionUpdateModalOpen(true)}
          >
            Manage seats
          </Button>
        )}
      </InlineFlex>

      <ProgressBar
        value={seatsUsage.takenEditorsSeatsCount}
        max={seatsUsage.editorsSeatsCount}
        reverse={true}
      />

      {subscription.nextPhaseSchedule && (
        <InlineFlex $gap={1} style={{ marginTop: 8 }}>
          <WarningIcon
            color={theme.surface.warning}
            style={{ marginRight: 5 }}
          />
          <Text color="subtext" type="b1">
            {subscription.nextPhaseSchedule?.quantity} seat
            {subscription.nextPhaseSchedule?.quantity > 1
              ? 's'
              : ''} starting{' '}
            <FormattedDate
              date={new Date(
                (subscription.nextPhaseSchedule?.startDate as number) * 1000
              ).toISOString()}
            />
          </Text>
        </InlineFlex>
      )}

      {isSubscriptionUpdateModalOpen && (
        <SubscriptionUpdateModal
          isModalOpen={isSubscriptionUpdateModalOpen}
          setIsModalOpen={setIsSubscriptionUpdateModalOpen}
          organizationSubscription={organizationSubscription}
          seatsUsage={seatsUsage}
          onSubscriptionUpdate={onSubscriptionUpdate}
        />
      )}
    </div>
  );
};

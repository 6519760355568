/*
  Based on https://github.com/dy/bitmap-sdf/blob/b861e311cdc74e8729f36ac60bf7c50b34a4927d/index.js
  For more explanations, read the part "The Classic EDT" here: https://acko.net/blog/subpixel-distance-transform/
*/

import { Direction } from '@dnd-kit/core/dist/types';

const INF = 1e20;

export function calcSDF(src: ImageData, options: { channel: number }) {
  const channel = options.channel || 0;

  const imgData = src;
  const w = src.width;
  const h = src.height;

  const size = Math.max(w, h);
  const pixelsCount = w * h;

  //convert int data to floats
  const data = new Float32Array(pixelsCount);

  for (let i = 0; i < pixelsCount; i++) {
    data[i] = imgData.data[i * 4 + channel] / 255;
  }

  // temporary arrays for the distance transform
  const gridOuter = new Float32Array(pixelsCount);
  const gridInner = new Float32Array(pixelsCount);
  const f = new Float32Array(size);
  const d = new Float32Array(size);
  const z = new Float32Array(size + 1);
  const v = new Float32Array(size);

  for (let i = 0; i < pixelsCount; i++) {
    const a = data[i];
    gridOuter[i] =
      a === 1 ? 0 : a === 0 ? INF : Math.pow(Math.max(0, 0.5 - a), 2);
    gridInner[i] =
      a === 1 ? INF : a === 0 ? 0 : Math.pow(Math.max(0, a - 0.5), 2);
  }

  euclideanDistanceTransform2d(gridOuter, w, h, f, d, v, z);
  euclideanDistanceTransform2d(gridInner, w, h, f, d, v, z);

  const dist = new Float32Array(pixelsCount);

  for (let i = 0; i < pixelsCount; i++) {
    const x = i % w;
    const y = Math.floor(i / w);
    dist[w * (h - y) + x] = gridOuter[i] - gridInner[i];
  }

  return dist;
}

// 2D Euclidean distance transform by Felzenszwalb & Huttenlocher https://cs.brown.edu/~pff/dt/
function euclideanDistanceTransform2d(
  data: Float32Array,
  width: number,
  height: number,
  f: Float32Array,
  d: Float32Array,
  v: Float32Array,
  z: Float32Array
) {
  for (let x = 0; x < width; x++) {
    for (let y = 0; y < height; y++) {
      f[y] = data[y * width + x];
    }
    euclideanDistanceTransform1d(f, d, v, z, height);
    for (let y = 0; y < height; y++) {
      data[y * width + x] = d[y];
    }
  }
  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      f[x] = data[y * width + x];
    }
    euclideanDistanceTransform1d(f, d, v, z, width);
    for (let x = 0; x < width; x++) {
      data[y * width + x] = Math.sqrt(d[x]);
    }
  }
}

// 1D squared distance transform
function euclideanDistanceTransform1d(
  f: Float32Array,
  d: Float32Array,
  v: Float32Array,
  z: Float32Array,
  n: number
) {
  v[0] = 0;
  z[0] = -INF;
  z[1] = +INF;

  for (let q = 1, k = 0; q < n; q++) {
    let s = (f[q] + q * q - (f[v[k]] + v[k] * v[k])) / (2 * q - 2 * v[k]);
    while (s <= z[k]) {
      k--;
      s = (f[q] + q * q - (f[v[k]] + v[k] * v[k])) / (2 * q - 2 * v[k]);
    }
    k++;
    v[k] = q;
    z[k] = s;
    z[k + 1] = +INF;
  }

  for (let q = 0, k = 0; q < n; q++) {
    while (z[k + 1] < q) k++;
    d[q] = (q - v[k]) * (q - v[k]) + f[v[k]];
  }
}

function calcSDF2(src: ImageData) {
  const distances = [];
  const directionsBuffers = {
    top: [],
    current: [],
  };
  const w = src.width;
  const h = src.height;
  const getDirs = (buffer: [number, number][], x: number, y: number) => {
    const dirs = [];
    for (let i = 0; i < buffer.length; i++) {
      dirs.push([buffer[i][0] + x, buffer[i][1] + y]);
    }
    return dirs;
  };
  for (let j = 0; j < h; j++) {
    for (let i = 0; i < w; i++) {
      directionsBuffers.top = directionsBuffers.current;
      directionsBuffers.current.length = 0;
      const id = i + j * w;
      const vecs = [
        ...getDirs(directionsBuffers.top[i - 1], 1, 1),
        ...getDirs(directionsBuffers.current[i], 0, 1),
        ...getDirs(directionsBuffers.current[i + 1], -1, 1),
        ...getDirs(directionsBuffers.current[i - 1], 1, 0),
      ];
      const bests: [[number, number][], number] = [
        [],
        Number.POSITIVE_INFINITY,
      ];
      vecs.forEach(([x, y]) => {
        const d = x * x + y * y;
        if (bests[0] && d < bests[1]) {
          bests[0][0] = [x, y];
          bests[0].length = 0;
          bests[1] = d;
        } else if (d === bests[1]) {
          bests[0].push([x, y]);
        }
      });
      distances[id] = bests[0];
    }
  }
}

import styled from 'styled-components';
import {
  InlineFlex,
  Text,
  VizcomLogoAnimated,
} from '@vizcom/shared-ui-components';

import { ViewMode } from '../ViewModeSwitcher';

type ColorPillProps = {
  red: number;
  green: number;
  blue: number;
  view: ViewMode;
  name: string;
  onClick?: () => void;
};

export const ColorPill = ({
  red,
  green,
  blue,
  view,
  name,
  onClick,
}: ColorPillProps) => {
  if (view === ViewMode.List) {
    return (
      <ColorLineContainer onClick={onClick}>
        <ColorLinePillStyle $red={red} $green={green} $blue={blue} />
        <Text ellipsis title={name}>
          {name}
        </Text>
      </ColorLineContainer>
    );
  }

  return (
    <ColorIconPillStyle
      $red={red}
      $green={green}
      $blue={blue}
      onClick={onClick}
    />
  );
};

export const ColorPillLoading = ({ view }: { view: ViewMode }) => {
  if (view === ViewMode.List) {
    return (
      <ColorLineContainer>
        <ColorLinePillStyle $red={30} $green={30} $blue={30}>
          <VizcomLogoAnimated />
        </ColorLinePillStyle>
        <Text ellipsis title="Loading">
          Loading...
        </Text>
      </ColorLineContainer>
    );
  }

  return (
    <ColorIconPillStyle $red={30} $green={30} $blue={30}>
      <VizcomLogoAnimated />
    </ColorIconPillStyle>
  );
};

const ColorLineContainer = styled(InlineFlex)`
  padding: 5px;
  background-color: ${({ theme }) => theme.surface.secondary};
  border-radius: ${({ theme }) => theme.borderRadius.s};
`;

const ColorLinePillStyle = styled.div<{
  $red: number;
  $green: number;
  $blue: number;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borderRadius.s};
  min-width: 25px;
  min-height: 25px;
  background-color: rgb(
    ${({ $red }) => $red},
    ${({ $green }) => $green},
    ${({ $blue }) => $blue}
  );
`;

const ColorIconPillStyle = styled.div<{
  $red: number;
  $green: number;
  $blue: number;
}>`
  width: 40px;
  height: 40px;
  background-color: rgb(
    ${({ $red }) => $red},
    ${({ $green }) => $green},
    ${({ $blue }) => $blue}
  );
  border-radius: ${({ theme }) => theme.borderRadius.s};
  box-shadow: 0 0 0 2px ${({ theme }) => theme.surface.tertiary};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`;

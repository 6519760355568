import { useRef, useState } from 'react';
import { Mesh } from 'three';
import { useStore } from 'zustand';

import { useCanvasTexture } from '../../lib/useCanvasTexture';
import { ActiveMask, MaskDisplayMode } from '../../selection/ActiveMask';
import { useSelectionApiStore } from '../../selection/useSelectionApi';
import { MaskOperation } from '../../selection/utils';
import { useWorkbenchStudioState } from '../../studioState';
import { BrushSegment } from '../../types';
import { BrushCursorPreview } from '../BrushEngine/BrushCursorPreview';
import { useBrushSegments } from '../BrushEngine/useBrushSegments';
import { EventMesh } from '../EventMesh';

function drawPolygon(
  ctx: CanvasRenderingContext2D,
  segments: BrushSegment[],
  lastSegment: BrushSegment
) {
  ctx.moveTo(lastSegment.startPosition[0], lastSegment.startPosition[1]);
  ctx.lineTo(lastSegment.endPosition[0], lastSegment.endPosition[1]);

  segments.forEach((segment) => {
    ctx.lineTo(segment.endPosition[0], segment.endPosition[1]);
  });
}

export const BrushSelection = ({
  drawingSize,
}: {
  drawingSize: [number, number];
}) => {
  const selectionApiStore = useSelectionApiStore();
  const { operation, toolSize, isPrompting } = useWorkbenchStudioState((s) => ({
    operation: s.selectionSettings.operation,
    toolSize: s.selectionSettings.brushSettings.toolSize,
    isPrompting: s.isPrompting,
  }));

  const eventPlaneRef = useRef<Mesh>(null!);
  const { ctx, canvasTexture } = useCanvasTexture(drawingSize);
  const [isDrawing, setIsDrawing] = useState(false);

  ctx.fillStyle = '0x000000';
  ctx.lineWidth = toolSize;
  ctx.lineJoin = ctx.lineCap = 'round';

  const segmentsRef = useRef<BrushSegment[]>([]);

  const { bind } = useBrushSegments({
    drawingSize,
    eventPlaneRef,
    onNewSegments(_, segments) {
      setIsDrawing(true);
      ctx.clearRect(0, 0, drawingSize[0], drawingSize[1]);
      segmentsRef.current.push(...segments);

      if (
        segmentsRef.current.length > 1 ||
        operation !== MaskOperation.Replace
      ) {
        ctx.save();
        if (operation === MaskOperation.Replace) {
          ctx.fillStyle = '#000000';
          ctx.fillRect(0, 0, drawingSize[0], drawingSize[1]);
        } else {
          ctx.drawImage(selectionApiStore.getState().canvas, 0, 0);
        }

        if (
          !selectionApiStore.getState().hasMask ||
          operation === MaskOperation.Replace ||
          operation === MaskOperation.Add
        ) {
          ctx.strokeStyle = '#ffffff';
        } else if (operation === MaskOperation.Remove) {
          ctx.strokeStyle = '#000000';
        }

        ctx.beginPath();
        drawPolygon(ctx, segmentsRef.current, segmentsRef.current[0]);
        ctx.stroke();
        const firstPoint = segmentsRef.current[0];
        const lastPoint = segmentsRef.current[segmentsRef.current.length - 1];
        if (
          Math.hypot(
            firstPoint.startPosition[0] - lastPoint.endPosition[0],
            firstPoint.startPosition[1] - lastPoint.endPosition[1]
          ) < toolSize
        ) {
          ctx.fillStyle = ctx.strokeStyle;
          ctx.closePath();
          ctx.fill();
        }
        ctx.restore();
      } else {
        ctx.clearRect(0, 0, drawingSize[0], drawingSize[1]);
      }
      canvasTexture.needsUpdate = true;
    },
    onStrokeEnd: (event) => {
      setIsDrawing(false);
      // draw mask on canvas (instead of only the segments)
      // if there's not enough segments, don't draw anything and return an empty mask
      // this will reset the mask state if the shift key is not pressed
      if (
        operation === MaskOperation.Replace &&
        segmentsRef.current.length <= 2
      ) {
        selectionApiStore.getState().deselectMask();
      } else {
        //ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        const brushCanvas = ctx.canvas;
        //canvasTexture.needsUpdate = true;
        selectionApiStore.getState().editSelectionCanvas((ctx) => {
          ctx.clearRect(0, 0, drawingSize[0], drawingSize[1]);
          ctx.drawImage(brushCanvas, 0, 0);
        });
      }

      segmentsRef.current = [];
    },
  });

  const selectionTexture = useStore(selectionApiStore, (s) => s.texture);

  return (
    <>
      <BrushCursorPreview
        drawingSize={drawingSize}
        toolSize={toolSize}
        toolAspect={1}
        toolAngle={0}
        color={'#000000'}
      />
      <ActiveMask
        drawingSize={drawingSize}
        maskTexture={isDrawing ? canvasTexture : selectionTexture}
        mode={
          isPrompting ? MaskDisplayMode.MARCHING_ANTS : MaskDisplayMode.FILL
        }
      />

      <EventMesh
        drawingSize={drawingSize}
        eventMeshProps={bind() as any}
        ref={eventPlaneRef}
      />
    </>
  );
};

import { useState } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useMatch,
  useNavigate,
} from 'react-router-dom';
import {
  useCreateOrganization,
  useCreateOrganizationInvite,
  useCurrentUser,
  usePendingInvitedOrganizations,
  useWebConfig,
} from '@vizcom/shared/data-access/graphql';
import {
  LoadingLogoInset,
  addToast,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

import { OnboardingContainer } from '../../onboarding/OnboardingContainer';
import { OnboardingHeader } from '../../onboarding/OnboardingHeader';
import { OnboardingStepIndicator } from '../../onboarding/OnboardingStepIndicator';
import { CreateOrganizationBlockedPage } from './CreateOrganizationBlockedPage';
import { CreateOrganizationJoinExistingPage } from './CreateOrganizationJoinExistingPage';
import { CreateOrganizationNameInputPage } from './CreateOrganizationNameInputPage';
import { CreateOrganizationSuccessPage } from './CreateOrganizationSuccessPage';

export const CreateOrganizationPage = () => {
  const { fetching: fetchingWebConfig } = useWebConfig();
  const { data: currentUser } = useCurrentUser();
  const [createOrganizationRes, createOrganization] = useCreateOrganization();
  const {
    data: pendingInvitedOrganizations,
    fetching: fetchingPendingInvites,
  } = usePendingInvitedOrganizations();
  const [createOrganizationInvitesRes, createOrganizationInvite] =
    useCreateOrganizationInvite();
  const navigate = useNavigate();
  const routesState = [
    useMatch(paths.organization.create() + '/name'),
    useMatch(paths.organization.create() + '/success'),
  ];

  const currentStep = routesState.findIndex((active) => active) + 1;

  const [name, setName] = useState(
    currentUser?.name ? `${currentUser.name}'s Workspace` : ''
  );
  const [inviteEmails, setInviteEmails] = useState<string[]>([]);

  if (fetchingWebConfig || fetchingPendingInvites) {
    return (
      <OnboardingContainer>
        <LoadingLogoInset />
      </OnboardingContainer>
    );
  }

  if (currentUser?.canCreateOrganization === false) {
    return (
      <OnboardingContainer>
        <OnboardingHeader />
        <CreateOrganizationBlockedPage />
      </OnboardingContainer>
    );
  }

  const handleCreateOrganization = async () => {
    if (!name) {
      navigate(paths.organization.create() + '/name');
      return;
    }
    const res = await createOrganization({
      input: {
        name,
      },
    });
    if (res.error || !res.data?.createOrganization?.organization) {
      return addToast('Error while creating organization', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }
    for (const email of inviteEmails) {
      const inviteRes = await createOrganizationInvite({
        input: {
          organizationInvite: {
            organizationId: res.data.createOrganization.organization.id,
            email,
          },
        },
      });
      if (inviteRes.error) {
        addToast(`Error while inviting ${email}:`, {
          type: 'danger',
          secondaryText: formatErrorMessage(inviteRes.error),
        });
      }
    }
    navigate(paths.organization.create() + '/success');
    setName('');
    setInviteEmails([]);
  };

  const org = createOrganizationRes.data?.createOrganization?.organization;
  const createOrganizationGlobalTeamId = org?.globalTeam?.id;
  const orgPlan = org?.subscriptionPlan;

  return (
    <OnboardingContainer>
      <OnboardingHeader />

      <LoadingLogoInset
        active={
          createOrganizationRes.fetching ||
          createOrganizationInvitesRes.fetching
        }
      />

      <Routes>
        <Route
          path="name"
          element={
            <CreateOrganizationNameInputPage
              name={name}
              onSetName={setName}
              onSubmit={handleCreateOrganization}
            />
          }
        />
        <Route
          path="success"
          element={
            createOrganizationGlobalTeamId &&
            orgPlan && (
              <CreateOrganizationSuccessPage
                subscriptionPlan={orgPlan}
                organizationGlobalTeamId={createOrganizationGlobalTeamId}
              />
            )
          }
        />
        <Route
          path="join_existing"
          element={<CreateOrganizationJoinExistingPage />}
        />
        <Route
          path="*"
          element={
            <Navigate
              to={
                pendingInvitedOrganizations &&
                pendingInvitedOrganizations.length > 0
                  ? 'join_existing'
                  : 'name'
              }
              replace
            />
          }
        />
      </Routes>

      <OnboardingStepIndicator count={3} currentStep={currentStep} />
    </OnboardingContainer>
  );
};

import { ThreeEvent, useThree } from '@react-three/fiber';
import { useDrag } from '@use-gesture/react';
import { useState } from 'react';
import { Group, OrthographicCamera, Vector3 } from 'three';

import { screenPositionToWorld } from '../../../helpers';

const v3 = new Vector3();

export type LayerTranslation = [number, number];

const defaultTranslation: LayerTranslation = [0, 0];

export const useLayerTranslationTransform = (
  onGestureUpdate: (translationTransform: LayerTranslation) => void,
  onGestureEnd: (translationTransform: LayerTranslation) => void,
  resizerGroupRef: React.MutableRefObject<Group>
) => {
  const camera = useThree((s) => s.camera as OrthographicCamera);
  const [translationTransform, setTranslationTransform] =
    useState<LayerTranslation>(defaultTranslation);

  const bindTranslationHandle = useDrag<ThreeEvent<PointerEvent>>((gesture) => {
    if (gesture.tap) {
      return;
    }
    if (gesture.last) {
      onGestureEnd(translationTransform);
      return;
    }

    const pointerPosition = screenPositionToWorld(
      [gesture.event.nativeEvent.clientX, gesture.event.nativeEvent.clientY],
      camera
    );
    if (!gesture.memo) {
      return {
        initialPointerPosition: pointerPosition,
        initialTranslation: [...translationTransform],
      };
    }

    const { initialPointerPosition, initialTranslation } = gesture.memo as {
      initialTranslation: LayerTranslation;
      initialPointerPosition: [number, number];
    };

    resizerGroupRef.current.getWorldScale(v3);
    const deltaX = (pointerPosition[0] - initialPointerPosition[0]) / v3.x;
    const deltaY = (pointerPosition[1] - initialPointerPosition[1]) / v3.y;
    const newTranslationTransform: LayerTranslation = [
      deltaX + initialTranslation[0],
      deltaY + initialTranslation[1],
    ];
    setTranslationTransform(newTranslationTransform);
    onGestureUpdate(newTranslationTransform);
    return {
      initialPointerPosition,
      initialTranslation,
    };
  });

  return {
    bindTranslationHandle,
    translationTransform,
    resetTranslationTransform: () =>
      setTranslationTransform(defaultTranslation),
  };
};

import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px 40px 16px;
`;

export const Header = styled.div`
  z-index: 5;

  position: sticky;
  top: 0;

  padding: 27px 0;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  grid-template-areas:
    'title actions'
    'links actions';
  margin-bottom: 30px;
  justify-content: space-between;
  align-items: center;

  background: ${(p) => p.theme.surface.pageInverted};
`;

export const NewFileButton = styled.button`
  border: none;
  padding: 12px 20px;
  margin-left: 10px;

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;

  background-color: ${({ theme }) => theme.deprecated.primary.default};
  color: ${({ theme }) => theme.text.body};

  :hover {
    cursor: pointer;
  }

  :disabled {
    cursor: not-allowed;
    background-color: rgba(117, 117, 117, 0.3);
    color: ${({ theme }) => theme.text.secondaryDisabled};
  }
`;

export const Error = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 6rem 0;
`;

export const GridWrapper = styled.div`
  height: 100%;
`;

export const Grid = styled.div`
  padding: 24px 0;
  margin-bottom: 30px;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(255px, 1fr));
  grid-gap: 24px;
`;

export const FullWidthGridItem = styled.div`
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
`;

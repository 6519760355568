import { useStore, useThree } from '@react-three/fiber';
import { LayerDataFragment } from 'libs/shared/data-access/graphql/src/gql/graphql';
import { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { filterExists } from '@vizcom/shared/js-utils';
import {
  addToast,
  eventTargetIsInput,
  useDocumentEventListener,
  useKeyboardShortcut,
} from '@vizcom/shared-ui-components';

import { ClientSideWorkbenchElementData } from '../lib/clientState';
import { handleCopyDrawingImage } from '../lib/drawingUtils';
import { useWorkbenchElementSelectionState } from '../lib/elementSelectionState';
import { useWorkbenchSyncedState } from '../lib/useWorkbenchSyncedState';
import { elementById, useIsWorkbenchViewer } from '../lib/utils';
import {
  copyWorkbenchElementsToClipboard,
  copyLinkToSelection,
  pasteWorkbenchElementsFromClipboard,
  workbenchElementClipboardDataFromString,
} from '../lib/workbenchClipboardUtils';
import { getSectionElements } from './elements/section/helpers';
import {
  WorkbenchToolType,
  useWorkbenchToolState,
} from './toolbar/WorkbenchToolContext';
import { useArrowKeyPanning } from './utils/useArrowKeyPanning';

export type WorkbenchElementData = ClientSideWorkbenchElementData & {
  layersOrder?: string[];
  layers?: { nodes: LayerDataFragment[] };
  image?: Blob;
  type?: string;
};

interface WorkbenchKeyboardShortcutsProps {
  workbenchId: string;
  elements: ClientSideWorkbenchElementData[];
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
  undoAction: () => void;
  redoAction: () => void;
}

export const WorkbenchKeyboardShortcuts: FC<
  WorkbenchKeyboardShortcutsProps
> = ({ workbenchId, elements, handleAction, undoAction, redoAction }) => {
  const { scene } = useThree();
  const store = useStore();
  const isViewer = useIsWorkbenchViewer();
  const { setTool } = useWorkbenchToolState();
  useArrowKeyPanning({
    panSpeed: 1000, // pixels per second
  });

  useKeyboardShortcut(
    'escape',
    () => {
      if (isViewer) return;
      setTool(WorkbenchToolType.SELECT);
    },
    {
      capture: true, // needed to capture the event before it propagates
    }
  );

  useKeyboardShortcut('t', () => {
    if (isViewer) return;

    setTool(WorkbenchToolType.TEXT);
  });

  useDocumentEventListener('copy', (e) => {
    if (eventTargetIsInput(e)) {
      return;
    }

    const focusedElementsId =
      useWorkbenchElementSelectionState.getState().focusedElementsId;

    const data = focusedElementsId
      .split('/')
      .filter(Boolean)
      .map((id) => elementById(elements, id))
      .filter(filterExists);

    if (!data.length) {
      return false;
    }
    e.stopPropagation();
    e.preventDefault();
    copyWorkbenchElementsToClipboard(elements, focusedElementsId);
  });

  useDocumentEventListener('paste', async (event) => {
    if (isViewer) return;

    const data = event.clipboardData?.getData('text/plain');

    if (workbenchElementClipboardDataFromString(data)) {
      event.preventDefault();
      event.stopPropagation();
      await pasteWorkbenchElementsFromClipboard(
        data!,
        handleAction,
        useWorkbenchElementSelectionState.getState().setFocusedElementsId,
        store.getState()
      );
    }
  });

  useKeyboardShortcut(
    'd',
    (e) => {
      e.preventDefault();

      if (isViewer) return;

      const elementIds = useWorkbenchElementSelectionState
        .getState()
        .focusedElementsId.split('/')
        .filter(Boolean);

      const sections = elements.filter(
        (e) =>
          e.__typename === 'WorkbenchElementSection' &&
          elementIds.includes(e.id)
      );
      const sectionElements = sections.flatMap((section) =>
        getSectionElements(scene, section)
      );
      const allElementIdsSet = new Set([
        ...elementIds,
        ...sectionElements.map((e) => e.userData.elementId),
      ]);
      const allElementIds = Array.from(allElementIdsSet);
      const newElementIds = allElementIds.map(() => uuidv4());

      handleAction({
        type: 'duplicateElements',
        elementIds: allElementIds,
        newElementIds,
      });

      useWorkbenchElementSelectionState
        .getState()
        .setFocusedElementsId(newElementIds.join('/'));
    },
    { ctrl: true }
  );

  useKeyboardShortcut(
    'c',
    (e) => {
      e.preventDefault();

      const focusedElementIds = useWorkbenchElementSelectionState
        .getState()
        .focusedElementsId.split('/')
        .filter(Boolean);

      const drawings = elements.filter(
        (element) =>
          element.__typename === 'Drawing' &&
          focusedElementIds.includes(element.id)
      );

      if (drawings.length > 1) {
        addToast('Only one image can be copied at a time', {
          type: 'danger',
        });
        return;
      }

      if (drawings.length === 0) return;
      handleCopyDrawingImage(drawings[0]);
    },
    { shift: true, ctrl: true }
  );

  useKeyboardShortcut('z', undoAction, {
    ctrl: true,
  });

  useKeyboardShortcut('z', redoAction, {
    ctrl: true,
    shift: true,
  });

  useKeyboardShortcut(
    'l',
    (e) => {
      e.preventDefault();

      const focusedElementIds = useWorkbenchElementSelectionState
        .getState()
        .focusedElementsId.split('/')
        .filter(Boolean);

      if (!focusedElementIds.length) {
        addToast('Select an item to copy its link.', { type: 'warning' });
        return;
      }

      const linkableElementIds = elements
        .filter((el) => focusedElementIds.includes(el.id))
        .map((el) => el.id);

      if (!linkableElementIds.length) {
        addToast(
          'Unable to copy link to a palette source image, select the palette instead.',
          { type: 'warning' }
        );
        return;
      }

      copyLinkToSelection(workbenchId, linkableElementIds);
    },
    { ctrl: true }
  );

  return null;
};

import { ReactNode } from 'react';
import { OrganizationSubscriptionPlan } from '@vizcom/shared/data-access/graphql';

import { FeatureFlagged } from './featureFlags/FeatureFlagged';

export const SubscriptionGated = ({
  allowedSubscriptions,
  organizationSubscription,
  flagOverride,
  children,
}: {
  allowedSubscriptions?: OrganizationSubscriptionPlan[];
  organizationSubscription?: OrganizationSubscriptionPlan;
  flagOverride?: string;
  children: ReactNode;
}) => {
  if (!allowedSubscriptions || !organizationSubscription) {
    return <>{children}</>;
  }

  if (allowedSubscriptions.includes(organizationSubscription)) {
    return <>{children}</>;
  } else if (flagOverride) {
    return <FeatureFlagged flag={flagOverride}>{children}</FeatureFlagged>;
  }

  return null;
};

import styled from 'styled-components';
import { useCurrentUser } from '@vizcom/shared/data-access/graphql';

import {
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  Text,
  useStringHashNumber,
} from '../../../../../../shared/ui/components/src';
import { type MultiplayerPresence } from '../../lib/useWorkbenchMultiplayer';
import {
  useMultiplayerDisplayConfig,
  getMultiplayerUserId,
} from './multiplayerDisplayConfig';

export const MultiplayerPresenceIndicator = (props: {
  presence: MultiplayerPresence;
  selected: boolean;
  onSelect: () => void;
}) => {
  const { presence, selected, onSelect } = props;

  const userId = getMultiplayerUserId(presence.id);
  const hash = useStringHashNumber(userId);
  const displayConfig = useMultiplayerDisplayConfig(hash, presence.user);

  if (!presence.user && !presence.id) {
    return null;
  }

  return (
    <RichTooltip trigger="hover" placement="bottom" padding={11}>
      <RichTooltipTrigger>
        <MultiplayerPresenceIndicatorName
          style={{
            backgroundColor: displayConfig.color,
          }}
          $selected={selected}
          onClick={onSelect}
        >
          {!presence.user && <AnonymousAvatarImage src={displayConfig.image} />}
          {displayConfig.short}
        </MultiplayerPresenceIndicatorName>
      </RichTooltipTrigger>
      <RichTooltipContent>
        <Text>{displayConfig.name}</Text>
      </RichTooltipContent>
    </RichTooltip>
  );
};

export const OwnPresenceIndicator = ({
  selfPresence,
}: {
  selfPresence: MultiplayerPresence;
}) => {
  const { data: currentUser } = useCurrentUser();
  const userId = getMultiplayerUserId(selfPresence.id);
  const hash = useStringHashNumber(userId);
  const displayConfig = useMultiplayerDisplayConfig(hash, currentUser);

  return (
    <RichTooltip trigger="hover" placement="bottom" padding={11}>
      <RichTooltipTrigger>
        <MultiplayerPresenceIndicatorName
          style={{
            backgroundColor: displayConfig.color,
            cursor: 'auto',
          }}
          $selected={false}
        >
          {!selfPresence.userId && (
            <AnonymousAvatarImage src={displayConfig.image} />
          )}
          {displayConfig.short}
        </MultiplayerPresenceIndicatorName>
      </RichTooltipTrigger>
      <RichTooltipContent>
        <Text>You ({displayConfig.name})</Text>
      </RichTooltipContent>
    </RichTooltip>
  );
};

export const AnonymousAvatarImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: center;
`;

export const MultiplayerPresenceIndicatorName = styled.div<{
  $selected: boolean;
}>`
  position: relative;
  color: white;
  border-radius: ${({ theme }) => theme.borderRadius.round};
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s box-shadow ease;
  user-select: none;
  ${(p) => p.$selected && 'box-shadow: 0 0 0 2px white;'}
`;

/**
 * Browser detection and optimization utilities
 */

interface BrowserCapabilities {
  isSafari: boolean;
  isIOS: boolean;
  isIPad: boolean;
  supportsDesynchronized: boolean;
  maxCanvasPoolSize: number;
  maxCanvasDimension: number;
  preferredFPS: number;
  maxConcurrentRequests: number;
  requestsPerMinute: number;
}

/**
 * Get browser capabilities and recommended optimization settings
 * Centralizes all browser detection logic in one place
 */
export const getBrowserCapabilities = (): BrowserCapabilities => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const isIPad =
    /iPad|Macintosh/.test(navigator.userAgent) && 'ontouchend' in document;

  return {
    isSafari,
    isIOS,
    isIPad,
    // Safari has issues with desynchronized contexts
    supportsDesynchronized: !isSafari,
    // Reduce pool size for Safari to prevent memory pressure
    maxCanvasPoolSize: isSafari ? (isIOS ? 3 : 5) : 10,
    // iOS has stricter canvas size limits
    maxCanvasDimension: isIOS ? 2048 : 4096,
    // Reduce FPS for Safari/iOS for better stability
    preferredFPS: isIOS ? 20 : isSafari ? 30 : 60,
    // Limit concurrent requests more aggressively on mobile
    maxConcurrentRequests: isIOS ? 1 : isSafari ? 2 : 3,
    // More conservative rate limiting for mobile
    requestsPerMinute: isIOS ? 200 : isSafari ? 300 : 600,
  };
};

/**
 * Get optimized canvas context options based on browser capabilities
 */
export const getOptimizedCanvasContext = (
  canvas: HTMLCanvasElement
): CanvasRenderingContext2D | null => {
  const { isSafari, isIOS, supportsDesynchronized } = getBrowserCapabilities();

  const ctx = canvas.getContext('2d', {
    alpha: true,
    willReadFrequently: false,
    desynchronized: supportsDesynchronized,
    latencyHint: isSafari && !isIOS ? 'low-latency' : 'default',
  }) as CanvasRenderingContext2D | null;

  if (ctx && isSafari) {
    // Apply Safari-specific optimizations
    canvas.style.transform = 'translateZ(0)';
    canvas.style.backfaceVisibility = 'hidden';

    if (isIOS) {
      // Optimize canvas size for iOS
      const scale = window.devicePixelRatio || 1;
      const displayWidth = Math.floor(canvas.clientWidth * scale);
      const displayHeight = Math.floor(canvas.clientHeight * scale);

      if (canvas.width !== displayWidth || canvas.height !== displayHeight) {
        canvas.width = displayWidth;
        canvas.height = displayHeight;
      }
    }
  }

  return ctx;
};

/**
 * Get optimized inference request config based on browser capabilities
 */
export const getOptimizedInferenceConfig = (baseConfig: any) => {
  const {
    isIOS,
    isSafari,
    preferredFPS,
    maxConcurrentRequests,
    requestsPerMinute,
  } = getBrowserCapabilities();

  if (isSafari) {
    return {
      ...baseConfig,
      minUpdateInterval: Math.floor(1000 / preferredFPS),
      maxConcurrentRequests,
      maxRequestsPerMinute: requestsPerMinute,
      retryDelay: isIOS ? 500 : 350,
    };
  }

  return baseConfig;
};

import styled from 'styled-components';
import { BlockStack, Text } from '@vizcom/shared-ui-components';

export const EmptyColorsState = () => {
  return (
    <EmptyStateContainer $gap={0}>
      <Text color="body" type="sh2">
        Create Colors
      </Text>
      <Text color="bodyDisabled" type="b1">
        Add your favorite colors
      </Text>
    </EmptyStateContainer>
  );
};

const EmptyStateContainer = styled(BlockStack)`
  text-align: center;
  padding: 15px;
  background-color: ${({ theme }) => theme.surface.secondary};
  border-radius: ${({ theme }) => theme.borderRadius.s};
  width: 100%;
  grid-column: 1 / -1;
  border: 1px dashed ${({ theme }) => theme.surface.tertiary};
`;

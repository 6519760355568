import { useState } from 'react';
import styled from 'styled-components';
import {
  EraserIcon,
  MagicEraserIcon,
  Button,
  CarretDownIcon,
  CarretUpIcon,
  Divider,
  Text,
  ToolbarButtonState,
} from '@vizcom/shared-ui-components';

import {
  useWorkbenchStudioState,
  WorkbenchStudioToolType,
} from '../../studioState';
import { BrushSettings } from '../Brush/BrushSettings';
import { ToolbarIcon } from '../ToolbarIcon';
import { ToolbarSlider } from '../ToolbarSlider';

export const EraserMenu = () => {
  const [showSettings, setShowSettings] = useState(false);

  const { tool, setTool, getToolSettings } = useWorkbenchStudioState();
  const { toolSize, toolOpacity, setToolSize, setToolOpacity } =
    getToolSettings();

  return (
    <Container>
      <IconContainer>
        <ToolbarIcon
          $toolState={
            tool === WorkbenchStudioToolType.Eraser
              ? ToolbarButtonState.ACTIVE
              : ToolbarButtonState.INACTIVE
          }
          onClick={() => setTool(WorkbenchStudioToolType.Eraser)}
          icon={<EraserIcon />}
        />
        <ToolbarIcon
          $toolState={
            tool === WorkbenchStudioToolType.MagicEraser
              ? ToolbarButtonState.ACTIVE
              : ToolbarButtonState.INACTIVE
          }
          onClick={() => setTool(WorkbenchStudioToolType.MagicEraser)}
          icon={<MagicEraserIcon />}
        />
      </IconContainer>

      <Divider />

      <ToolbarSlider
        label="Size"
        style={{ flexGrow: 1 }}
        value={toolSize}
        setValue={setToolSize}
        min={1}
        max={250}
        step={1}
        unit="px"
        isLogScale
      />

      {tool === WorkbenchStudioToolType.Eraser && (
        <ToolbarSlider
          label="Opacity"
          style={{ flexGrow: 1 }}
          value={toolOpacity}
          setValue={setToolOpacity}
          min={0}
          max={1}
          step={0.01}
          unit="%"
          multiplier={100}
        />
      )}

      <Divider />
      <MenuRow
        style={{ cursor: 'pointer' }}
        onClick={(e) => {
          e.stopPropagation();
          setShowSettings((prev) => !prev);
        }}
      >
        <Text color="subtext" type="b1">
          Advanced settings
        </Text>
        <Button variant="tertiary" size="S">
          {showSettings ? (
            <CarretUpIcon $size="M" $color="secondary" />
          ) : (
            <CarretDownIcon $size="M" $color="secondary" />
          )}
        </Button>
      </MenuRow>
      {showSettings && (
        <BrushSettings
          disableHardness={tool === WorkbenchStudioToolType.MagicEraser}
          disableOpacity={tool === WorkbenchStudioToolType.MagicEraser}
          disableStabilizer={tool === WorkbenchStudioToolType.MagicEraser}
          disableAspect={tool === WorkbenchStudioToolType.MagicEraser}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: auto;
  min-width: 176px;

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const IconContainer = styled.div`
  display: flex;
  gap: 4px;
  padding-bottom: 2px;
`;

const MenuRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

import styled, { useTheme } from 'styled-components';
import {
  Button,
  MobileUploadIcon,
  ToastIndicator,
} from '@vizcom/shared-ui-components';

import { MobileWorkbenchFileMenu } from './MobileWorkbenchFileMenu';

interface MobileUploadPageProps {
  title: string;
  loading: boolean;
  handleImportFiles: () => void;
  children?: React.ReactNode;
}

export const MobileUploadPage = ({
  title,
  loading,
  handleImportFiles,
  children,
}: MobileUploadPageProps) => {
  const theme = useTheme();

  return (
    <FullPageContainer>
      <MobileWorkbenchFileMenu title={title} />

      <UploadButton variant="primary" onClick={handleImportFiles}>
        <MobileUploadIcon $size="XL" style={{ color: theme.icon.secondary }} />
        Upload
      </UploadButton>

      {children}

      {loading && <ToastIndicator variant="loading" text="Importing files" />}
    </FullPageContainer>
  );
};

const FullPageContainer = styled.div`
  background-color: ${(p) => p.theme.surface.page};
  display: flex;
  flex-direction: column;
  padding: 64px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  color: ${(p) => p.theme.text.body};
  font-size: 14px;

  position: relative;
  background-image: radial-gradient(
    circle at 2px 2px,
    #e4e3e3 2px,
    transparent 2px
  );
  background-size: 25px 25px;
`;

const UploadButton = styled(Button)`
  width: 145px;
  height: 134px;
  border-radius: ${({ theme }) => theme.borderRadius.l};
  border: 1px solid ${(p) => p.theme.border.palette}; // TODO: this border color isn't standard
  background-color: ${(p) => p.theme.surface.page};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  color: ${(p) => p.theme.text.bodyDisabled};

  &:hover {
    background-color: ${(p) => p.theme.surface.pagePattern};
  }
`;

import { useEffect, useRef } from 'react';
import toast from 'react-hot-toast';

import { ToastProps, addToast } from './Toast';

export const ToastIndicator = ({
  text,
  ...options
}: { duration?: number } & Omit<ToastProps, 'onDismiss'>) => {
  const toastIdRef = useRef<string>();

  useEffect(() => {
    toastIdRef.current = addToast(text, {
      ...options,
      id: toastIdRef.current,
      type: options.variant,
    });
  }, [text, options]);

  useEffect(() => () => toast.dismiss(toastIdRef.current), []);

  return null;
};

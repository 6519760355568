import { useStore, useThree } from '@react-three/fiber';
import { v4 as uuidv4 } from 'uuid';
import {
  CMD_KEY_PREFIX,
  MenuDivider,
  MenuItem,
  Text,
  addToast,
} from '@vizcom/shared-ui-components';

import { ClientSideWorkbenchElementData } from '../../lib/clientState';
import { useWorkbenchElementSelectionState } from '../../lib/elementSelectionState';
import { useWorkbenchSyncedState } from '../../lib/useWorkbenchSyncedState';
import { useIsWorkbenchViewer } from '../../lib/utils';
import {
  copyWorkbenchElementsToClipboard,
  copyLinkToSelection,
  pasteWorkbenchElementsFromClipboard,
} from '../../lib/workbenchClipboardUtils';
import { getSectionElements } from '../elements/section/helpers';
import { triggerDeleteElementsModal } from '../modals/triggerDeleteElementsModal';

interface WorkbenchMenuEditItemProps {
  workbenchId: string;
  canUndo: boolean;
  undoAction: () => any;
  canRedo: boolean;
  redoAction: () => any;
  elements: ClientSideWorkbenchElementData[];
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
}

export const WorkbenchMenuEditItem = ({
  workbenchId,
  canRedo,
  canUndo,
  redoAction,
  undoAction,
  elements,
  handleAction,
}: WorkbenchMenuEditItemProps) => {
  const { scene } = useThree();
  const store = useStore();
  const isViewer = useIsWorkbenchViewer();
  const focusedElementIds = useWorkbenchElementSelectionState((state) =>
    state.focusedElementsId.split('/').filter(Boolean)
  );
  const hasFocusedElements = focusedElementIds.length > 0;

  return (
    <MenuItem label="Edit">
      <MenuItem
        label="Undo"
        disabled={!canUndo || !isViewer}
        onClick={undoAction}
        appendLabel={<Text color="subtext">{CMD_KEY_PREFIX}Z</Text>}
      />
      <MenuItem
        label="Redo"
        disabled={!canRedo}
        onClick={redoAction}
        appendLabel={<Text color="subtext">{CMD_KEY_PREFIX}⇧Z</Text>}
      />
      <MenuDivider />
      <MenuItem
        label="Copy link to selection"
        disabled={!hasFocusedElements}
        onClick={() => {
          const linkableElementIds = elements
            .filter((el) => focusedElementIds.includes(el.id))
            .map((el) => el.id);

          if (!linkableElementIds.length) {
            addToast(
              'Unable to copy link to a palette source image, select the palette instead.',
              { type: 'warning' }
            );
            return;
          }

          copyLinkToSelection(workbenchId, linkableElementIds);
        }}
        appendLabel={<Text color="subtext">{CMD_KEY_PREFIX}L</Text>}
      />
      <MenuItem
        label="Copy"
        disabled={!hasFocusedElements}
        onClick={() =>
          copyWorkbenchElementsToClipboard(
            elements,
            useWorkbenchElementSelectionState.getState().focusedElementsId
          )
        }
        appendLabel={<Text color="subtext">{CMD_KEY_PREFIX}C</Text>}
      />
      <MenuItem
        disabled={isViewer}
        label="Paste"
        onClick={async () => {
          if (navigator.clipboard.readText === undefined) {
            return addToast(
              `The feature is not supported. Please use Chrome or Edge to use this feature.`,
              {
                type: 'danger',
              }
            );
          }

          const data = await navigator.clipboard.readText();
          pasteWorkbenchElementsFromClipboard(
            data,
            handleAction,
            useWorkbenchElementSelectionState.getState().setFocusedElementsId,
            store.getState()
          );
        }}
        appendLabel={<Text color="subtext">{CMD_KEY_PREFIX}V</Text>}
      />
      <MenuItem
        label="Duplicate"
        appendLabel={<Text color="subtext">{CMD_KEY_PREFIX}D</Text>}
        disabled={!hasFocusedElements || isViewer}
        onClick={() => {
          const elementIds = useWorkbenchElementSelectionState
            .getState()
            .focusedElementsId.split('/')
            .filter(Boolean);
          const sections = elements.filter(
            (e) =>
              e.__typename === 'WorkbenchElementSection' &&
              elementIds.includes(e.id)
          );
          const sectionElements = sections.flatMap((section) =>
            getSectionElements(scene, section)
          );
          const allElementIdsSet = new Set([
            ...elementIds,
            ...sectionElements.map((e) => e.userData.elementId),
          ]);
          const allElementIds = Array.from(allElementIdsSet);
          const newElementIds = allElementIds.map(() => uuidv4());

          handleAction({
            type: 'duplicateElements',
            elementIds: allElementIds,
            newElementIds,
          });

          useWorkbenchElementSelectionState
            .getState()
            .setFocusedElementsId(newElementIds.join('/'));
        }}
      />
      <MenuDivider />
      <MenuItem
        label="Delete"
        disabled={!hasFocusedElements || isViewer}
        appendLabel={<Text color="subtext">DEL</Text>}
        onClick={async () => {
          const elementIds = useWorkbenchElementSelectionState
            .getState()
            .focusedElementsId.split('/')
            .filter((id) => id);
          const deletedElements = elements.filter((e) =>
            elementIds.includes(e.id)
          );
          const sectionElements = deletedElements.flatMap((deletedElement) =>
            getSectionElements(scene, deletedElement)
          );

          const elementsToDeleteIds = [
            ...deletedElements.map((e) => e.id),
            ...sectionElements.map((e) => e.userData.elementId),
          ].filter((id, index, self) => self.indexOf(id) === index);

          if (!elementsToDeleteIds.length) return;

          try {
            await triggerDeleteElementsModal(elementsToDeleteIds);
          } catch {
            return;
          }

          handleAction({
            type: 'deleteElements',
            elementIds: elementsToDeleteIds,
          });
        }}
      />
    </MenuItem>
  );
};

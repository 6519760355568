import { ComponentProps, ComponentType } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { PublicPalette } from '@vizcom/shared/inference-worker-queues';
import {
  AnimateToolbarIcon,
  MixToolbarIcon,
  PaletteToolbarIcon,
  PromptToolbarIcon,
  SketchToolbarIcon,
  Text,
  OnboardingStepName,
} from '@vizcom/shared-ui-components';

import {
  ImageInferenceType,
  OrganizationSubscriptionPlan,
} from '../../../../../../shared/data-access/graphql/src';
import { useWorkbenchSyncedState } from '../../lib/useWorkbenchSyncedState';
import { getElementDefaultSize } from '../utils/getElementDefaultSize';

export const ToolboxButton = styled.div`
  position: relative !important;
  background-color: ${(p) => p.theme.surface.secondary};
  border-radius: ${(p) => p.theme.borderRadius.m};
  padding: 14px;
  width: 108px;
  height: 100px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  cursor: grab;
  transition: 0.1s background-color ease, 0.1s box-shadow ease,
    0.5s opacity ease;
  touch-action: none;
  user-select: none;
  &:hover {
    box-shadow: inset 0 0 0 1px ${(p) => p.theme.icon.primary};
    background-color: ${(p) => p.theme.surface.tertiary};
  }
`;

const StyledChip = styled.div`
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 4px;
  background: ${({ theme }) => theme.chip.lightPrimary.background};
  color: ${({ theme }) => theme.chip.lightPrimary.text};
  font-size: 8px;
  font-weight: 700;
  padding: 2px;
`;

const CreateImageButton = (props: ComponentProps<typeof ToolboxButton>) => (
  <ToolboxButton {...props}>
    <SketchToolbarIcon $size="XL" />
    <Text block type="sh1">
      Sketch
    </Text>
  </ToolboxButton>
);

const CreateImg2ImgButton = (props: ComponentProps<typeof ToolboxButton>) => (
  <ToolboxButton {...props}>
    <PromptToolbarIcon $size="XL" />
    <Text block type="sh1">
      Prompt
    </Text>
  </ToolboxButton>
);

const CreateMixButton = (props: ComponentProps<typeof ToolboxButton>) => (
  <ToolboxButton {...props}>
    <MixToolbarIcon $size="XL" />
    <Text block type="sh1">
      Mix
    </Text>
  </ToolboxButton>
);

const CreatePaletteButton = (props: ComponentProps<typeof ToolboxButton>) => (
  <ToolboxButton {...props}>
    <PaletteToolbarIcon $size="XL" style={{ marginBottom: '5px' }} />
    <Text type="sh1">Palette</Text>
  </ToolboxButton>
);

const CreateAnimateButton = (props: ComponentProps<typeof ToolboxButton>) => (
  <ToolboxButton {...props}>
    <AnimateToolbarIcon $size="XL" />
    <Text block type="sh1">
      Animate
    </Text>
    <StyledChip>BETA</StyledChip>
  </ToolboxButton>
);

export const elementCreators: {
  featureFlag?: string;
  allowedSubscriptionPlans?: OrganizationSubscriptionPlan[];
  subscriptionPlanOverride?: string;
  Button: ComponentType<{
    toolboxOpen?: boolean;
  }>;
  action: (props: {
    x: number;
    y: number;
    zIndex: number;
    handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
    completeOnboardingStep?: (step: OnboardingStepName) => void;
    paletteModalStatus?: boolean;
    openPaletteModal?: () => void;
  }) => string;
}[] = [
  {
    Button: CreateImageButton,
    action: ({ x, y, zIndex, handleAction, completeOnboardingStep }) => {
      const size = getElementDefaultSize('WorkbenchElementPlaceholder');
      const elementId = uuidv4();

      if (completeOnboardingStep) {
        completeOnboardingStep(OnboardingStepName.InsertImage);
      }

      handleAction({
        type: 'createElements',
        newElements: [
          {
            __typename: 'WorkbenchElementPlaceholder',
            updatedAt: '0',
            createdAt: '0',
            id: elementId,
            width: size.x,
            height: size.y,
            type: 'drawing',
            x,
            y,
            zIndex,
          },
        ],
      });

      return elementId;
    },
  },
  {
    Button: CreateImg2ImgButton,
    action: ({ x, y, zIndex, handleAction }) => {
      const size = getElementDefaultSize('WorkbenchElementImg2Img');
      const elementId = uuidv4();

      handleAction({
        type: 'createElements',
        newElements: [
          {
            __typename: 'WorkbenchElementImg2Img',
            updatedAt: '0',
            id: elementId,
            width: size.x,
            height: size.y,
            x,
            y,
            zIndex,
            prompt: '',
            sourceImageInfluences: [1],
            imageInferenceType: ImageInferenceType.Render,
            publicPaletteId: PublicPalette.generalV2,
          },
        ],
      });
      return elementId;
    },
  },
  {
    Button: CreatePaletteButton,
    action: ({
      handleAction,
      paletteModalStatus,
      openPaletteModal,
      x,
      y,
      zIndex,
    }) => {
      const elementId = uuidv4();
      const size = getElementDefaultSize('WorkbenchElementPalette');

      handleAction({
        type: 'createElements',
        newElements: [
          {
            __typename: 'WorkbenchElementPalette',
            id: elementId,
            width: size.x,
            height: size.y,
            name: '',
            status: 'idle',
            tags: [],
            sourceImages: {
              nodes: [],
            },
            usageCount: 0,
            x,
            y,
            zIndex,
          },
        ],
      });

      if (!paletteModalStatus && openPaletteModal) {
        openPaletteModal();
      }
      return elementId;
    },
  },
  {
    Button: CreateMixButton,
    action: ({ x, y, zIndex, handleAction }) => {
      const elementId = uuidv4();
      const size = getElementDefaultSize('WorkbenchElementMix');

      handleAction({
        type: 'createElements',
        newElements: [
          {
            __typename: 'WorkbenchElementMix',
            updatedAt: '0',
            id: elementId,
            width: size.x,
            height: size.y,
            x,
            y,
            zIndex,
            sourceDrawings: [],
          },
        ],
      });
      return elementId;
    },
  },
  {
    Button: CreateAnimateButton,
    action: ({ x, y, zIndex, handleAction }) => {
      const elementId = uuidv4();
      const size = getElementDefaultSize('WorkbenchElementAnimate');

      handleAction({
        type: 'createElements',
        newElements: [
          {
            __typename: 'WorkbenchElementAnimate',
            updatedAt: '0',
            id: elementId,
            width: size.x,
            height: size.y,
            x,
            y,
            zIndex,
            prompt: '',
            sourceDrawingId: null,
          },
        ],
      });
      return elementId;
    },
  },
];

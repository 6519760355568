import styled from 'styled-components';
import { ContentPasteIcon, Divider, Text } from '@vizcom/shared-ui-components';

import { ToolbarMenuOption } from '../ToolbarOption';
import { TransformSettings } from './TransformSettings';

type Props = {
  handlePaste: (event: React.MouseEvent) => void;
};

export const TransformMenu = ({ handlePaste }: Props) => {
  return (
    <Container>
      <Text style={{ padding: 8 }} type="sh2">
        Transform
      </Text>
      <TransformSettings />
      <Divider />
      <ToolbarMenuOption
        icon={<ContentPasteIcon />}
        label="Paste"
        onClick={handlePaste}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 176px;
`;

import { RootState } from '@react-three/fiber';
import { useEffect, useRef } from 'react';
import toast from 'react-hot-toast';
import QRCode from 'react-qr-code';
import styled, { useTheme } from 'styled-components';
import {
  Modal,
  ModalTitle,
  Text,
  addToast,
  LinkIcon as _LinkIcon,
  CloseIcon,
  Button,
  useFeatureFlag,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

import {
  ClientSideWorkbenchElementData,
  ClientSideWorkbenchElementDrawing,
} from '../../lib/clientState';
import { useWorkbenchSyncedState } from '../../lib/useWorkbenchSyncedState';
import { cleanupSketch } from '../utils/cleanupSketch';

interface ScanWithPhoneModalProps {
  isOpen: boolean;
  onClose: () => void;
  workbenchId: string;
  drawingId: string | null;
  threeState: React.MutableRefObject<RootState>;
  elements: ClientSideWorkbenchElementData[];
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
}

const generateUploadUrl = (
  workbenchId: string,
  drawingId: string | null,
  targetPosition: [number, number]
): string => {
  const path = drawingId
    ? paths.mobileWorkbench.uploadToStudio(workbenchId, drawingId)
    : `${paths.mobileWorkbench.uploadToWorkbench(workbenchId)}?target=${
        targetPosition[0]
      },${targetPosition[1]}`;

  return window.location.origin + path;
};

export const ScanWithPhoneModal = ({
  isOpen,
  onClose,
  workbenchId,
  drawingId,
  threeState,
  elements,
  handleAction,
}: ScanWithPhoneModalProps) => {
  const theme = useTheme();
  const previousDrawingsRef = useRef<Set<string>>(new Set());

  const { flagEnabled: isWorkbenchCleanupSketchEnabled } = useFeatureFlag(
    'WORKBENCH_CLEANUP_SKETCH'
  );

  const handleCleanUpSketch = (sketchId: string) => {
    const drawing = elements.find(
      (element): element is ClientSideWorkbenchElementDrawing =>
        element.__typename === 'Drawing' && element.id === sketchId
    );
    if (!drawing) {
      addToast('Drawing not found', { type: 'danger' });
      return;
    }
    cleanupSketch(handleAction, drawing);
  };

  const showSketchCleanupToast = (newDrawingId: string) =>
    toast(
      (t) => (
        <div
          style={{
            display: 'flex',
            gap: '16px',
            alignItems: 'center',
          }}
        >
          Is this a sketch?
          <Button
            style={{
              backgroundColor: theme.surface.secondary,
              color: theme.text.body,
            }}
            variant="secondary"
            onClick={() => {
              handleCleanUpSketch(newDrawingId);
              toast.dismiss(t.id);
            }}
          >
            Clean up sketch
          </Button>
          <Button
            variant="bare"
            size="M"
            style={{
              padding: 6,
              paddingBottom: 4,
            }}
            onClick={() => toast.dismiss(t.id)}
          >
            <CloseIcon />
          </Button>
        </div>
      ),
      {
        duration: 15000,
        style: {
          backgroundColor: theme.surface.pageInverted,
          color: theme.text.body,
          borderRadius: theme.borderRadius.l,
          height: '52px',
          margin: 0,
        },
      }
    );

  useEffect(() => {
    if (!isOpen) return;

    const currentDrawings = new Set(
      elements
        .filter((element) => element.__typename === 'Drawing')
        .map((element) => element.id)
    );

    if (previousDrawingsRef.current.size === 0) {
      previousDrawingsRef.current = currentDrawings;
      return;
    }

    currentDrawings.forEach((drawingId) => {
      if (!previousDrawingsRef.current.has(drawingId)) {
        onClose();
        if (isWorkbenchCleanupSketchEnabled) showSketchCleanupToast(drawingId);
      }
    });

    previousDrawingsRef.current = currentDrawings;
  }, [elements, isOpen]);

  if (!threeState.current) return null;

  const { position } = threeState.current.camera;
  const targetPosition: [number, number] = [
    Number(position.x.toFixed(2)),
    Number(position.y.toFixed(2)),
  ];

  const copyToClipboard = async (url: string): Promise<void> => {
    try {
      await navigator.clipboard.writeText(url);
      addToast(
        'URL copied to clipboard. Open on your phone to upload images.',
        { duration: 3000 }
      );
    } catch (err) {
      console.error('Failed to copy URL: ', err);
      addToast('Failed to copy URL. Please try again.', {
        type: 'danger',
        duration: 3000,
      });
    }
  };

  const url = generateUploadUrl(workbenchId, drawingId, targetPosition);

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={onClose}
      style={{
        maxWidth: '320px',
        padding: '16px',
        backgroundColor: theme.surface.primary,
      }}
    >
      <Content>
        <TitleContainer>
          <ModalTitle style={{ color: theme.text.body }}>
            Upload from phone
          </ModalTitle>
          <StyledCloseIcon onClick={onClose} />
        </TitleContainer>
        <Text block>
          Scan the QR code to take a picture or upload an image from your phone
        </Text>
        <QRCodeContainer>
          <QRCodeBackground>
            <QRCode value={url} size={98} />
          </QRCodeBackground>
        </QRCodeContainer>
        <LinkContainer>
          <QRLink href={url} target="_blank">
            {url}
          </QRLink>
          <LinkIcon onClick={() => copyToClipboard(url)} />
        </LinkContainer>
      </Content>
    </Modal>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const QRCodeContainer = styled.div`
  height: 148px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.surface.secondary};
  border-radius: ${({ theme }) => theme.borderRadius.l};
`;

// Always white background for QR code
const QRCodeBackground = styled.div`
  padding: 8px;
  background-color: white;
  border-radius: ${({ theme }) => theme.borderRadius.m};
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  background-color: ${({ theme }) => theme.surface.secondary};
  border-radius: ${({ theme }) => theme.borderRadius.m};
`;

const QRLink = styled.a`
  color: ${(p) => p.theme.text.body};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 230px;
`;

const LinkIcon = styled(_LinkIcon)`
  color: ${(p) => p.theme.text.body};
  cursor: pointer;
  transition: color 0.1s ease;

  &:hover {
    color: ${(p) => p.theme.text.subtext};
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  color: ${(p) => p.theme.text.body};
  cursor: pointer;
  transition: color 0.1s ease;
  &:hover {
    color: ${(p) => p.theme.text.subtext};
  }
`;

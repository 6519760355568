import { useNavigate } from 'react-router-dom';
import {
  OrganizationData,
  OrganizationSubscriptionPlan,
  OrganizationTeamsUsers,
  TeamRole,
  useCurrentUser,
  useDeleteUserOrganization,
  useUpdateUserOrganization,
} from '@vizcom/shared/data-access/graphql';
import { capitalize } from '@vizcom/shared/js-utils';
import {
  TableRow,
  TableCell,
  UserAvatar,
  ContextMenu,
  ContextMenuItem,
  MenuHorizontalIcon,
  Text,
  LoadingLogoInset,
  triggerConfirmModal,
  FormattedDate,
  ContextMenuDivider,
  Checkbox,
  SelectionCell,
  RelativeDate,
  ActionsCell,
  addToast,
  formatErrorMessage,
  useCrisp,
  ConfirmationModalComponent,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

import {
  MEMBERS_TABLE_SIZING,
  type OrganizationMember,
} from './OrganizationMembers';
import { UserTeamsComboBox } from './UserTeamsComboBox';
import {
  shouldDisplayLimitationErrorToast,
  showLimitationErrorToast,
} from './limitationToastMessage';
import { MemberRoleSelect } from './styles';

export const OrganizationMemberRow = (props: {
  memberEdge: OrganizationMember;
  organization: OrganizationData;
  canEdit: boolean;
  selected: boolean;
  teams: OrganizationTeamsUsers;
  onSelectedChange: () => void;
}) => {
  const crisp = useCrisp();
  const { memberEdge, organization, teams, canEdit } = props;
  const member = memberEdge.node;

  const { data: currentUser } = useCurrentUser();
  const navigate = useNavigate();
  const isCurrentUser = currentUser?.id === member.id;

  const [updateUserOrganizationRes, updateUserOrganization] =
    useUpdateUserOrganization();
  const [deleteUserOrganizationRes, deleteUserOrganization] =
    useDeleteUserOrganization();

  const handleChangeRole = async (userId: string, role: string) => {
    const res = await updateUserOrganization({
      input: {
        organizationId: organization.id,
        userId: userId,
        patch: {
          role: role as any,
        },
      },
    });

    if (res.error) {
      if (shouldDisplayLimitationErrorToast(res.error)) {
        return showLimitationErrorToast(
          res.error,
          organization.id,
          navigate,
          crisp.openChat
        );
      }

      return addToast('Error changing role', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }

    return addToast('Member role changed');
  };

  const handleRemoveFromOrganization = async (
    userId: string,
    userEmail: string
  ) => {
    try {
      await triggerConfirmModal({
        body: (onConfirm, onCancel) => (
          <ConfirmationModalComponent
            onConfirm={onConfirm}
            variant="danger"
            onCancel={onCancel}
            title={
              isCurrentUser
                ? 'Are you sure you want to quit this workspace?'
                : `Remove member: ${userEmail}?`
            }
            description={
              isCurrentUser
                ? "You won't be able to join again without being invited back"
                : `This member will be removed from the workspace. You can invite them back at any time.`
            }
          />
        ),
      });
    } catch {
      // User clicked cancel
      return;
    }

    const res = await deleteUserOrganization({
      input: {
        organizationId: organization.id,
        userId: userId,
      },
    });

    if (res.error) {
      return addToast('Error while removing member', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }

    if (isCurrentUser) return navigate('/');

    if (
      memberEdge.role !== TeamRole.Viewer &&
      organization.subscriptionPlan !== OrganizationSubscriptionPlan.Free &&
      !organization.manualSubscription
    ) {
      addToast('Member removed', {
        duration: 5000,
        secondaryText: "You can remove a seat if you don't need it anymore.",
        cta: {
          text: 'Remove seat',
          url: paths.settings.organization.membersSubscriptionUpdate(
            organization.id
          ),
        },
      });
    } else {
      addToast('Member removed', {
        secondaryText:
          "This individual won't be able to join the workspace anymore.",
      });
    }
  };

  return (
    <TableRow>
      <SelectionCell>
        <Checkbox checked={props.selected} onClick={props.onSelectedChange} />
      </SelectionCell>

      <TableCell
        size={MEMBERS_TABLE_SIZING.name}
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          gap: 10,
          justifyContent: 'flex-start',
        }}
      >
        <UserAvatar userId={member.id} name={member.name || member.email} />
        <div
          style={{
            flex: 1,
            flexBasis: 0,
            overflow: 'hidden',
          }}
        >
          <Text block type="b2" ellipsis title={member.name || '-'}>
            {member.name || '-'}
          </Text>
          <Text
            block
            type="button3"
            color="subtext"
            ellipsis
            title={member.email}
          >
            {member.email}
          </Text>
        </div>
      </TableCell>

      <TableCell size={MEMBERS_TABLE_SIZING.dateAdded}>
        <FormattedDate date={member.createdAt} />
      </TableCell>
      <TableCell size={MEMBERS_TABLE_SIZING.lastActive}>
        {member.lastActive && <RelativeDate date={member.lastActive} />}
        {!member.lastActive && '-'}
      </TableCell>
      <TableCell size={MEMBERS_TABLE_SIZING.teams}>
        <UserTeamsComboBox orgTeams={teams} user={memberEdge} />
      </TableCell>
      <TableCell size={MEMBERS_TABLE_SIZING.role}>
        {canEdit && !isCurrentUser ? (
          <MemberRoleSelect
            value={memberEdge.role}
            onChange={(e) => handleChangeRole(member.id, e.target.value)}
          >
            <option value="ADMIN">Admin</option>
            <option value="EDITOR">Editor</option>
            <option value="VIEWER">Viewer</option>
          </MemberRoleSelect>
        ) : (
          capitalize(memberEdge.role)
        )}
      </TableCell>
      <ActionsCell>
        {isCurrentUser ? (
          <ContextMenu
            buttonProps={{
              variant: 'tertiary',
              size: 'icon',
            }}
            items={
              <ContextMenuItem
                onClick={() =>
                  handleRemoveFromOrganization(member.id, member.email)
                }
              >
                Quit workspace
              </ContextMenuItem>
            }
          >
            <MenuHorizontalIcon />
          </ContextMenu>
        ) : canEdit ? (
          <ContextMenu
            buttonProps={{
              variant: 'tertiary',
              size: 'icon',
            }}
            items={
              <>
                <ContextMenuItem
                  onClick={() => {
                    navigator.clipboard.writeText(member.email);
                    addToast('Email copied to clipboard');
                  }}
                >
                  Copy Email
                </ContextMenuItem>

                <ContextMenuDivider />
                <ContextMenuItem
                  onClick={() =>
                    handleRemoveFromOrganization(member.id, member.email)
                  }
                >
                  Remove from workspace
                </ContextMenuItem>
              </>
            }
          >
            <MenuHorizontalIcon />
          </ContextMenu>
        ) : null}
      </ActionsCell>
      <LoadingLogoInset
        active={
          updateUserOrganizationRes.fetching ||
          deleteUserOrganizationRes.fetching
        }
      />
    </TableRow>
  );
};

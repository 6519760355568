import { StatsGl, Environment, Grid, SoftShadows } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { PropsWithChildren, useEffect, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { PerspectiveCamera } from 'three';
import { CompositeSceneFullData } from '@vizcom/shared/data-access/graphql';

import { Postprocessing } from './Postprocessing';
import { useCompositeSceneEditorContext } from './context';
import { useImperativeSceneConfig } from './hooks/useImperativeSceneConfig';
import {
  EnvironmentPresets,
  getEnvironmentPresetLighting,
} from './utils/environments';
import { isDebugModeEnabled } from './utils/isDebugModeEnabled';

export const Scene = ({
  children,
  compositeScene,
}: PropsWithChildren<{
  compositeScene: CompositeSceneFullData;
}>) => {
  const debugMode = isDebugModeEnabled();
  const { camera } = useThree();
  const { activeCamera } = useCompositeSceneEditorContext();
  const theme = useTheme();
  const isSceneEmpty = compositeScene.compositeSceneElements.nodes.length === 0;

  const { environment } = useImperativeSceneConfig({
    compositeScene,
  });
  const environmentLighting = useMemo(
    () =>
      getEnvironmentPresetLighting(
        (compositeScene.sceneEnvironmentMapUrl ??
          '') as keyof typeof EnvironmentPresets,
        compositeScene.sceneEnvironmentGroundPlane
      ),
    [
      compositeScene.sceneEnvironmentMapUrl,
      compositeScene.sceneEnvironmentGroundPlane,
    ]
  );

  useEffect(() => {
    const perspectiveCamera = camera as PerspectiveCamera;

    let fov: number;

    if (activeCamera) {
      const node = compositeScene.compositeSceneElements.nodes.find(
        (node) => node.id === activeCamera
      )?.meshes.root;

      if (!node) {
        return;
      }

      fov = node.cameraFov ?? 50.0;
    } else {
      fov = compositeScene.cameraFov ?? 50.0;
    }

    perspectiveCamera.fov = fov;
    perspectiveCamera.far = 5000.0;

    perspectiveCamera.updateProjectionMatrix();
  }, [camera, compositeScene, activeCamera]);

  return (
    <>
      <color
        attach="background"
        args={[compositeScene.sceneBackgroundColorHex || theme.surface.page]}
      />
      <group>
        {environment && <Environment key={1} files={environment} />}
        {environmentLighting}
        <SoftShadows samples={10} />
      </group>
      <group
        name="composite-scene-root"
        rotation={[0.0, compositeScene.sceneEnvironmentAngle || 0.0, 0.0]}
      >
        {children}
        <Grid
          position={[0.0, 0.0, 0.0]}
          args={[500.0, 500.0, 500.0]}
          cellSize={0.2}
          cellColor={compositeScene.sceneBackgroundColorHex || 0xffffff}
          cellThickness={1.0}
          sectionSize={2.0}
          sectionColor={compositeScene.sceneBackgroundColorHex || 0xfefefe}
          fadeDistance={30.0}
          followCamera
          userData={{
            vizcomUI: true,
          }}
        />
        {isSceneEmpty && (
          <mesh
            rotation={[-Math.PI / 2.0, 0.0, 0.0]}
            position={[0.0, 10.0, 0.0]}
            castShadow={false}
          >
            <sphereGeometry args={[1.0, 64, 64]} />
            <meshStandardMaterial opacity={0.0} transparent />
          </mesh>
        )}
        {compositeScene.sceneEnvironmentGroundPlane && (
          <mesh receiveShadow position={[0, -0.52, 0]}>
            <boxGeometry args={[500.0, 1.0, 500.0, 1.0, 1.0, 1.0]} />
            <shadowMaterial opacity={0.5} transparent />
          </mesh>
        )}
      </group>
      <Postprocessing />
      {debugMode && <StatsGl />}
    </>
  );
};

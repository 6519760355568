import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  LinkIcon,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  TextInput,
  Text,
  ToolbarButton,
  ToolbarButtonState,
} from '@vizcom/shared-ui-components';

type WorkbenchElementTextLinkProps = {
  link: string;
  setLink: (link: string) => void;
  handleSetTextDecoration: (textDecoration: 'none') => void;
  textDecoration: string;
};

export const WorkbenchElementTextLink = ({
  link,
  setLink,
  handleSetTextDecoration,
  textDecoration,
}: WorkbenchElementTextLinkProps) => {
  const [open, setOpen] = useState(false);
  const [linkInput, setLinkInput] = useState(link);

  useEffect(() => {
    if (link.length && textDecoration !== 'none') {
      handleSetTextDecoration('none');
    }
  }, [link.length, textDecoration, handleSetTextDecoration]);

  return (
    <RichTooltip isOpen={open} trigger="click" padding={8}>
      <RichTooltipTrigger>
        <ToolbarButton
          state={
            link.length
              ? ToolbarButtonState.BACKGROUND
              : ToolbarButtonState.INACTIVE
          }
          icon={<LinkIcon />}
          tooltip="Link"
          onClick={() => setOpen(true)}
        />
      </RichTooltipTrigger>
      <RichTooltipContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          padding: 0,
        }}
      >
        <TextInput
          placeholder="Type or paste URL"
          value={linkInput}
          onChange={(e) => setLinkInput(e.target.value)}
          onClick={(e) => e.stopPropagation()}
          onBlur={() => {
            setLink(linkInput);
            setOpen(false);
          }}
          onKeyDown={(e) => {
            if (e.key !== 'Enter') {
              return;
            }
            setLink(linkInput);
            setOpen(false);
          }}
          autoFocus
        />
      </RichTooltipContent>
    </RichTooltip>
  );
};

type WorkbenchElementTextLinkDetailsProps = {
  link: string;
};

export const WorkbenchElementTextLinkDetails = ({
  link,
}: WorkbenchElementTextLinkDetailsProps) => {
  return (
    <LinkDetailsContainer onPointerDown={(e) => e.stopPropagation()}>
      <LinkContainer>
        <IconContainer>
          <LinkIcon />
        </IconContainer>
        <Text
          type="b2"
          color="body"
          as={Link}
          to={link}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            maxWidth: '162px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {link}
        </Text>
      </LinkContainer>
    </LinkDetailsContainer>
  );
};

const LinkDetailsContainer = styled.div`
  transform: translate3d(-50%, calc(-30px + 100%), 0);
  display: flex;
  height: 30px;
  background: ${({ theme }) => theme.surface.secondary};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25),
    0px 0.5px 1px 0px rgba(180, 180, 180, 0.25) inset;
`;

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px;
`;

const IconContainer = styled.div`
  color: ${({ theme }) => theme.text.subtext};
  padding-right: 8px;
`;

import { useDrag } from '@use-gesture/react';
import { useState } from 'react';
import { Euler, Object3D, Scene } from 'three';
import { LayerMetadata3d } from '@vizcom/shared/js-utils';

import { LayerData } from '../../../../lib/actions/drawing/updateLayer';
import {
  Drawing2dStudio,
  DrawingLayer,
} from '../../../../lib/useDrawingSyncedState';

const rotateSpeed = Math.PI * 0.002;

export function useLightingControls(
  metadata3d: LayerMetadata3d,
  targetObject: Object3D,
  layer: DrawingLayer,
  updateLayer: (
    update?: Partial<LayerData>,
    updateLayerThumbnail?: boolean
  ) => void,
  setIsOrbiting: (isOrbiting: boolean) => void
) {
  const [environmentRotationTransform, setEnvironmentRotationTransform] =
    useState(0);

  const bind = useDrag<PointerEvent>((gesture) => {
    gesture.event.stopPropagation();
    const pointerPosition = gesture.event.clientX;

    if (!gesture.memo) {
      setEnvironmentRotationTransform(0);
      return {
        initialPointerPosition: pointerPosition,
      };
    }

    const { initialPointerPosition } = gesture.memo;

    const delta = rotateSpeed * (pointerPosition - initialPointerPosition);
    setEnvironmentRotationTransform(delta);

    const newEnvironmentRotation =
      ((metadata3d.view?.environmentRotation ?? 0) + delta) % (2 * Math.PI);
    (targetObject.parent as Scene).environmentRotation.copy(
      new Euler(0.0, newEnvironmentRotation, 0.0)
    );

    if (gesture.last) {
      updateLayer({
        metadata3D: {
          ...layer.metadata3D,
          view: {
            ...(layer.metadata3D?.view ?? {}),
            environmentRotation: newEnvironmentRotation,
          },
        },
      });
      setEnvironmentRotationTransform(0);
      setIsOrbiting(false);
      return;
    }
    setIsOrbiting(true);

    return {
      initialPointerPosition,
    };
  });

  return {
    bind,
    environmentRotationTransform,
  };
}

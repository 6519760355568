import {
  Dropdown,
  InferenceStyleOption,
  Text,
} from '@vizcom/shared-ui-components';

type WorkbenchElementTextSizeProps = {
  size: number;
  handleSetTextSize: (size: number) => void;
};

const TEXT_SIZE_OPTIONS = [
  {
    name: 'Small',
    value: 1,
  },
  {
    name: 'Medium',
    value: 1.5,
  },
  {
    name: 'Large',
    value: 2.5,
  },
];

export const WorkbenchElementTextSize = ({
  size,
  handleSetTextSize,
}: WorkbenchElementTextSizeProps) => {
  const value = size.toFixed(1);
  return (
    <Dropdown
      value={value}
      setValue={(size) => handleSetTextSize(Number(size))}
      optionToValueMapper={({ value }) => Number(value).toFixed(1)}
      OptionComponent={InferenceStyleOption}
      options={TEXT_SIZE_OPTIONS.map(({ name, value }) => ({
        data: { value: value.toString(), name },
      }))}
      buttonProps={{
        variant: 'secondary',
        style: {
          width: '90px',
          padding: '3px 8px',
          alignSelf: 'stretch',
        },
      }}
    >
      <div style={{ textAlign: 'left' }}>
        <Text type="b2">
          {TEXT_SIZE_OPTIONS.find((option) => option.value.toFixed(1) === value)
            ?.name ?? 'Custom'}
        </Text>
      </div>
    </Dropdown>
  );
};

import { create as _create } from 'zustand';
import type { StateCreator } from 'zustand';

import { MaskOperation } from './selection/utils';

export enum WorkbenchStudioToolType {
  Move,
  Transform,
  Brush,
  Eraser,
  MagicEraser,
  Rectangle,
  Ellipse,
  Line,
  BezierSelection,
  Lasso,
  RectangleSelection,
  BrushSelection,
  AutoSelection,
  PaintBucket,
}

export interface WorkbenchVideoTooltip {
  title: React.ReactNode;
  description: string;
  videoSrc: string;
  learnMoreUrl?: string;
}

export interface WorkbenchTool {
  type: WorkbenchStudioToolType;
  icon: React.ReactNode;
  tooltip: string | WorkbenchVideoTooltip;
  hotkey?: string;
}

const SHAPE_TOOLS = [
  WorkbenchStudioToolType.Rectangle,
  WorkbenchStudioToolType.Ellipse,
  WorkbenchStudioToolType.Line,
];
export type ShapeTool = typeof SHAPE_TOOLS[number];
export const isShapeTool = (tool: WorkbenchStudioToolType): tool is ShapeTool =>
  SHAPE_TOOLS.includes(tool);

const TRANSFORM_TOOLS = [
  WorkbenchStudioToolType.Move,
  WorkbenchStudioToolType.Transform,
];
export type TransformTool = typeof TRANSFORM_TOOLS[number];
export const isTransformTool = (
  tool: WorkbenchStudioToolType
): tool is TransformTool => TRANSFORM_TOOLS.includes(tool);

const PAINTING_TOOLS = [
  WorkbenchStudioToolType.Brush,
  WorkbenchStudioToolType.PaintBucket,
  WorkbenchStudioToolType.Eraser,
  WorkbenchStudioToolType.MagicEraser,
  ...SHAPE_TOOLS,
];
export type PaintingTool = typeof PAINTING_TOOLS[number];
export const isPaintingTool = (
  tool: WorkbenchStudioToolType
): tool is PaintingTool => PAINTING_TOOLS.includes(tool);

const SELECTION_TOOLS = [
  WorkbenchStudioToolType.BezierSelection,
  WorkbenchStudioToolType.Lasso,
  WorkbenchStudioToolType.RectangleSelection,
  WorkbenchStudioToolType.BrushSelection,
  WorkbenchStudioToolType.AutoSelection,
];
export type SelectionTool = typeof SELECTION_TOOLS[number];
export const isSelectionTool = (
  tool: WorkbenchStudioToolType
): tool is SelectionTool => SELECTION_TOOLS.includes(tool);

const LAYER3D_TOOLS = [WorkbenchStudioToolType.Move, ...SELECTION_TOOLS];
export type Layer3DTool = typeof LAYER3D_TOOLS[number];
export const isLayer3dTool = (
  tool: WorkbenchStudioToolType
): tool is Layer3DTool => LAYER3D_TOOLS.includes(tool);

const SYMMETRY_COMPATIBLE_TOOLS = [
  WorkbenchStudioToolType.Brush,
  WorkbenchStudioToolType.Eraser,
  WorkbenchStudioToolType.MagicEraser,
  ...SHAPE_TOOLS,
];
export type SymmetryCompatibleTool = typeof SYMMETRY_COMPATIBLE_TOOLS[number];
export const isSymmetryCompatibleTool = (
  tool: WorkbenchStudioToolType
): tool is SymmetryCompatibleTool => SYMMETRY_COMPATIBLE_TOOLS.includes(tool);

export interface ToolSettings {
  toolSize: number;
  setToolSize: (size: number) => void;

  toolOpacity: number;
  setToolOpacity: (opacity: number) => void;

  toolAngle: number;
  setToolAngle: (angle: number) => void;

  toolHardness: number;
  setToolHardness: (hardness: number) => void;

  toolAspect: number;
  setToolAspect: (aspect: number) => void;

  toolPressureOpacity: boolean;
  setToolPressureOpacity: (toolPressureOpacity: boolean) => void;

  toolPressureSize: boolean;
  setToolPressureSize: (toolPressureSize: boolean) => void;

  toolStabilizer: number;
  setToolStabilizer: (toolStabilizer: number) => void;
}

export interface ShapeSettings {
  size: number;
  setSize: (size: number) => void;

  opacity: number;
  setOpacity: (opacity: number) => void;

  fill: boolean;
  setFill: (fill: boolean) => void;
}

export interface Symmetry {
  enabled: boolean;
  allowCrossingAxis: boolean;
  origin: [number, number];
  rotation: number;
}

export interface SelectionSettings {
  operation: MaskOperation;
  setOperation: (operation: MaskOperation) => void;
  lastSelectionTool: SelectionTool;
  setlastSelectionTool: (tool: SelectionTool) => void;

  brushSettings: ToolSettings;

  offset: number;
  setOffset: (offset: number) => void;
}

export type ColorPickerSessionOptions = {
  currentColor: string;
  callback: (color: string) => void;
};

export interface WorkbenchStudioState {
  tool: WorkbenchStudioToolType;
  setTool: (tool: WorkbenchStudioToolType) => void;

  flipped: boolean;
  setFlipped: (flipped: boolean) => void;

  canvasColorPickerSession: ColorPickerSessionOptions | null;
  setCanvasColorPickerSession: (
    options: ColorPickerSessionOptions | null
  ) => void;

  color: string;
  setColor: (color: string) => void;

  resizing: boolean;
  setResizing: (resizing: boolean) => void;

  panning: boolean;
  setPanning: (panning: boolean) => void;

  rotating: boolean;
  setRotating: (rotating: boolean) => void;

  symmetry: Symmetry;
  setSymmetry: (symmetry: Symmetry) => void;

  brushSettings: ToolSettings;
  eraserSettings: ToolSettings;
  magicEraserSettings: ToolSettings;
  shapeSettings: ShapeSettings;
  selectionSettings: SelectionSettings;

  isPrompting: boolean;
  setIsPrompting: (isPrompoting: boolean) => void;

  getToolSettings: () => ToolSettings;

  realtimePreviewSeed: number;
  setRealtimePreviewSeed: (seed: number) => void;
  realtimePreviewSeedLocked: boolean;
  setRealtimePreviewSeedLocked: (locked: boolean) => void;
}

let storeResetFn = () => {};

export const resetStudioToolState = () => {
  storeResetFn();
};

const create = (<T extends WorkbenchStudioState>() => {
  return (stateCreator: StateCreator<T>) => {
    const store = _create(stateCreator);
    const initialState = store.getState();
    storeResetFn = () => {
      store.setState(initialState, true);
    };
    return store;
  };
}) as typeof _create;

export const useWorkbenchStudioState = create<WorkbenchStudioState>()(
  (set, get) => ({
    tool: WorkbenchStudioToolType.Brush,
    setTool: (tool) => {
      if (isSelectionTool(tool)) {
        if (!isSelectionTool(get().tool)) {
          set((state) => ({
            tool: tool,
            selectionSettings: {
              ...state.selectionSettings,
              lastSelectionTool: tool as SelectionTool,
              operation: MaskOperation.Replace,
            },
          }));
        } else {
          set((state) => ({
            tool: tool,
            selectionSettings: {
              ...state.selectionSettings,
              lastSelectionTool: tool as SelectionTool,
            },
          }));
        }
      } else {
        set({ tool });
      }
    },

    flipped: false,
    setFlipped: (flipped) => set({ flipped }),

    color: '#000000',
    setColor: (color) => set({ color }),

    canvasColorPickerSession: null,
    setCanvasColorPickerSession: (options: ColorPickerSessionOptions | null) =>
      set({ canvasColorPickerSession: options }),

    resizing: false,
    setResizing: (resizing) => set({ resizing }),

    panning: false,
    setPanning: (panning) => set({ panning }),

    rotating: false,
    setRotating: (rotating) => set({ rotating }),

    getToolSettings: () => {
      const state = get();
      if (isSelectionTool(state.tool)) {
        return state.selectionSettings.brushSettings;
      } else if (state.tool === WorkbenchStudioToolType.Eraser) {
        return state.eraserSettings;
      } else if (state.tool === WorkbenchStudioToolType.MagicEraser) {
        return state.magicEraserSettings;
      } else {
        return state.brushSettings;
      }
    },

    isPrompting: false,
    setIsPrompting: (isPrompting: boolean) => set({ isPrompting }),

    symmetry: {
      enabled: false,
      allowCrossingAxis: true,
      origin: [0.5, 0.5],
      rotation: 0,
    },
    setSymmetry: (symmetry: Symmetry) => set({ symmetry }),

    brushSettings: {
      toolSize: 4,
      setToolSize: (toolSize) =>
        set((state) => ({
          brushSettings: { ...state.brushSettings, toolSize },
        })),

      toolOpacity: 1,
      setToolOpacity: (toolOpacity) =>
        set((state) => ({
          brushSettings: { ...state.brushSettings, toolOpacity },
        })),

      toolAngle: 0,
      setToolAngle: (toolAngle) =>
        set((state) => ({
          brushSettings: { ...state.brushSettings, toolAngle },
        })),

      toolAspect: 1,
      setToolAspect: (toolAspect) =>
        set((state) => ({
          brushSettings: { ...state.brushSettings, toolAspect },
        })),

      toolHardness: 100,
      setToolHardness: (toolHardness) =>
        set((state) => ({
          brushSettings: { ...state.brushSettings, toolHardness },
        })),

      toolPressureOpacity: true,
      setToolPressureOpacity: (toolPressureOpacity) =>
        set((state) => ({
          brushSettings: { ...state.brushSettings, toolPressureOpacity },
        })),

      toolPressureSize: true,
      setToolPressureSize: (toolPressureSize) =>
        set((state) => ({
          brushSettings: { ...state.brushSettings, toolPressureSize },
        })),

      toolStabilizer: 1,
      setToolStabilizer: (toolStabilizer) =>
        set((state) => ({
          brushSettings: { ...state.brushSettings, toolStabilizer },
        })),
    },

    eraserSettings: {
      toolSize: 10,
      setToolSize: (toolSize) =>
        set((state) => ({
          eraserSettings: { ...state.eraserSettings, toolSize },
        })),

      toolOpacity: 1,
      setToolOpacity: (toolOpacity) =>
        set((state) => ({
          eraserSettings: { ...state.eraserSettings, toolOpacity },
        })),

      toolAngle: 0,
      setToolAngle: (toolAngle) =>
        set((state) => ({
          eraserSettings: { ...state.eraserSettings, toolAngle },
        })),

      toolAspect: 1,
      setToolAspect: (toolAspect) =>
        set((state) => ({
          eraserSettings: { ...state.eraserSettings, toolAspect },
        })),

      toolHardness: 100,
      setToolHardness: (toolHardness) =>
        set((state) => ({
          eraserSettings: { ...state.eraserSettings, toolHardness },
        })),

      toolPressureOpacity: false,
      setToolPressureOpacity: (toolPressureOpacity) =>
        set((state) => ({
          eraserSettings: { ...state.eraserSettings, toolPressureOpacity },
        })),

      toolPressureSize: false,
      setToolPressureSize: (toolPressureSize) =>
        set((state) => ({
          eraserSettings: { ...state.eraserSettings, toolPressureSize },
        })),

      toolStabilizer: 1,
      setToolStabilizer: (toolStabilizer) =>
        set((state) => ({
          eraserSettings: { ...state.eraserSettings, toolStabilizer },
        })),
    },

    magicEraserSettings: {
      toolSize: 100,
      setToolSize: (toolSize) =>
        set((state) => ({
          magicEraserSettings: { ...state.magicEraserSettings, toolSize },
        })),

      toolOpacity: 1,
      setToolOpacity: (toolOpacity) =>
        set((state) => ({
          magicEraserSettings: { ...state.magicEraserSettings, toolOpacity },
        })),

      toolAngle: 0,
      setToolAngle: (toolAngle) =>
        set((state) => ({
          magicEraserSettings: { ...state.magicEraserSettings, toolAngle },
        })),

      toolAspect: 1,
      setToolAspect: (toolAspect) =>
        set((state) => ({
          magicEraserSettings: { ...state.magicEraserSettings, toolAspect },
        })),

      toolHardness: 100,
      setToolHardness: (toolHardness) =>
        set((state) => ({
          magicEraserSettings: { ...state.magicEraserSettings, toolHardness },
        })),

      toolPressureOpacity: false,
      setToolPressureOpacity: (toolPressureOpacity) =>
        set((state) => ({
          magicEraserSettings: {
            ...state.magicEraserSettings,
            toolPressureOpacity,
          },
        })),

      toolPressureSize: false,
      setToolPressureSize: (toolPressureSize) =>
        set((state) => ({
          magicEraserSettings: {
            ...state.magicEraserSettings,
            toolPressureSize,
          },
        })),

      toolStabilizer: 1,
      setToolStabilizer: (toolStabilizer) =>
        set((state) => ({
          magicEraserSettings: { ...state.magicEraserSettings, toolStabilizer },
        })),
    },

    shapeSettings: {
      size: 8,
      setSize: (size) =>
        set((state) => ({ shapeSettings: { ...state.shapeSettings, size } })),

      opacity: 1,
      setOpacity: (opacity) =>
        set((state) => ({
          shapeSettings: { ...state.shapeSettings, opacity },
        })),

      fill: false,
      setFill: (fill) =>
        set((state) => ({ shapeSettings: { ...state.shapeSettings, fill } })),
    },

    realtimePreviewSeed: Math.floor(Math.random() * 1000000),
    setRealtimePreviewSeed: (seed: number) =>
      set(() => ({ realtimePreviewSeed: seed })),
    realtimePreviewSeedLocked: false,
    setRealtimePreviewSeedLocked: (locked: boolean) =>
      set(() => ({ realtimePreviewSeedLocked: locked })),

    selectionSettings: {
      operation: MaskOperation.Replace,
      setOperation: (operation) =>
        set((state) => ({
          selectionSettings: { ...state.selectionSettings, operation },
        })),

      lastSelectionTool: WorkbenchStudioToolType.Lasso,
      setlastSelectionTool: (tool: SelectionTool) =>
        set((state) => ({
          selectionSettings: {
            ...state.selectionSettings,
            lastSelectionTool: tool,
          },
        })),

      brushSettings: {
        toolSize: 40,
        setToolSize: (toolSize) =>
          set((state) => ({
            selectionSettings: {
              ...state.selectionSettings,
              brushSettings: {
                ...state.selectionSettings.brushSettings,
                toolSize,
              },
            },
          })),

        toolOpacity: 1,
        setToolOpacity: (toolOpacity) =>
          set((state) => ({
            selectionSettings: {
              ...state.selectionSettings,
              brushSettings: {
                ...state.selectionSettings.brushSettings,
                toolOpacity,
              },
            },
          })),

        toolAngle: 0,
        setToolAngle: (toolAngle) =>
          set((state) => ({
            selectionSettings: {
              ...state.selectionSettings,
              brushSettings: {
                ...state.selectionSettings.brushSettings,
                toolAngle,
              },
            },
          })),

        toolAspect: 1,
        setToolAspect: (toolAspect) =>
          set((state) => ({
            selectionSettings: {
              ...state.selectionSettings,
              brushSettings: {
                ...state.selectionSettings.brushSettings,
                toolAspect,
              },
            },
          })),

        toolHardness: 100,
        setToolHardness: (toolHardness) =>
          set((state) => ({
            selectionSettings: {
              ...state.selectionSettings,
              brushSettings: {
                ...state.selectionSettings.brushSettings,
                toolHardness,
              },
            },
          })),

        toolPressureOpacity: false,
        setToolPressureOpacity: (toolPressureOpacity) =>
          set((state) => ({
            selectionSettings: {
              ...state.selectionSettings,
              brushSettings: {
                ...state.selectionSettings.brushSettings,
                toolPressureOpacity,
              },
            },
          })),

        toolPressureSize: false,
        setToolPressureSize: (toolPressureSize) =>
          set((state) => ({
            selectionSettings: {
              ...state.selectionSettings,
              brushSettings: {
                ...state.selectionSettings.brushSettings,
                toolPressureSize,
              },
            },
          })),

        toolStabilizer: 1,
        setToolStabilizer: (toolStabilizer) =>
          set((state) => ({
            selectionSettings: {
              ...state.selectionSettings,
              brushSettings: {
                ...state.selectionSettings.brushSettings,
                toolStabilizer,
              },
            },
          })),
      },
      offset: 0,
      setOffset: (offset) =>
        set((state) => ({
          selectionSettings: {
            ...state.selectionSettings,
            offset,
          },
        })),
    },
  })
);

import {
  getAccessToken,
  ImageInferenceType,
} from '@vizcom/shared/data-access/graphql';

import { ResourceManager } from '../performance';
import { createOptimizedBlob } from '../utils/canvasUtils';

const MODAL_ENDPOINT = location.host.includes('app.vizcom.ai')
  ? 'https://realtime-376da508.modal.vizcom.ai/'
  : 'https://realtime-e6bc3535.modal.vizcom.ai/';

/**
 * Strongly-typed metadata for inference requests
 */
export interface InferenceMetadata {
  prompt: string;
  seed: number;
  influence: number;
  paletteInfluence: number;
  steps?: number;
  width?: number;
  height?: number;
  timestamp?: number;
  inferenceType: ImageInferenceType;
  [key: string]: unknown; // Allow for future extensibility while maintaining type safety
}

export class InferenceService {
  constructor(private resourceManager: ResourceManager) {}

  async makeInferenceRequest(
    compositeImage: ImageData,
    metadata: InferenceMetadata
  ): Promise<Blob> {
    // Validate required fields
    if (metadata.seed == null || metadata.influence == null) {
      throw new Error('Missing required fields: seed or influence');
    }

    const formData = new FormData();
    const blob = await createOptimizedBlob(
      compositeImage,
      this.resourceManager,
      { useWebGL: false }
    );
    formData.append('image', blob, 'image.webp');

    // Ensure all metadata fields are properly formatted
    const requestMetadata: InferenceMetadata = {
      ...metadata,
      timestamp: Date.now(),
      width: compositeImage.width,
      height: compositeImage.height,
      paletteInfluence: Number(metadata.paletteInfluence),
      influence: Number(metadata.influence),
      seed: Number(metadata.seed),
      steps: metadata.steps ?? 1,
      inferenceType: metadata.inferenceType ?? ImageInferenceType.Realtime,
    };

    formData.append('metadata', JSON.stringify(requestMetadata));

    const accessToken = await getAccessToken();
    if (!accessToken) {
      throw new Error('No access token available');
    }

    const queryParams = new URLSearchParams({
      prompt: metadata.prompt || '',
      influence: metadata.influence.toString(),
      steps: '1',
      seed: metadata.seed.toString(),
      paletteInfluence: metadata.paletteInfluence.toString(),
      inferenceType: metadata.inferenceType,
    }).toString();

    const response = await fetch(`${MODAL_ENDPOINT}?${queryParams}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'image/*',
      },
      body: formData,
      credentials: 'omit',
      mode: 'cors',
    });

    if (!response.ok) {
      const errorMessage =
        response.status === 422
          ? 'Invalid request parameters'
          : response.status === 401
          ? 'Unauthorized - please log in again'
          : response.status === 429
          ? 'Too many requests'
          : `Server error (${response.status})`;

      throw new Error(errorMessage);
    }

    const result = await response.blob();
    if (!result.size) {
      throw new Error('Received empty response from server');
    }

    return result;
  }
}

import { rgbaToHex } from 'libs/shared/ui/components/src/lib/ColorPicker/utils/convert';
import { AssetColorData } from '@vizcom/shared/data-access/graphql';

export const assetColorDataToHex = (
  color?: AssetColorData | null,
  defaultHex: string = '#000000'
): string => {
  if (!color) return defaultHex;

  return rgbaToHex({
    r: color.red,
    g: color.green,
    b: color.blue,
    a: 1,
  });
};

import styled from 'styled-components';
import { useWebConfig } from '@vizcom/shared/data-access/graphql';

import { LoginAnchor } from '../LoginAnchor';
import googleLogo from '../assets/google-logo.png';

export const LogInWithGoogleButton = () => {
  const { data } = useWebConfig();

  if (!data?.loginWithGoogle) {
    return null;
  }

  return (
    <GoogleButton
      rel="noopener noreferrer"
      href={
        (import.meta.env.VITE_API_BASE_URL_OVERIDE ?? `/api/v1`) +
        '/auth/google'
      }
    >
      <img
        style={{ width: '12px', height: '12px' }}
        src={googleLogo}
        alt="Google Logo"
      />

      <span>Continue with Google</span>
    </GoogleButton>
  );
};

const GoogleButton = styled(LoginAnchor)`
  border: 1px solid ${({ theme }) => theme.border.tertiary};
  background-color: #ffffff;
  color: #000000;
`;

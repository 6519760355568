import { useFBO } from '@react-three/drei';
import {
  MeshBasicMaterialProps,
  createPortal,
  useFrame,
  useThree,
} from '@react-three/fiber';
import {
  PropsWithChildren,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from 'react';
import {
  AddEquation,
  Camera,
  CustomBlending,
  DoubleSide,
  NearestFilter,
  OneFactor,
  OneMinusSrcAlphaFactor,
  OrthographicCamera,
  Scene,
  ToneMapping,
  UnsignedByteType,
  WebGLRenderer,
} from 'three';

import { WORKBENCH_CONTENT_RENDERING_ORDER } from '../../../constants';
import { object3dIsMesh, yFlipImageBuffer } from '../../helpers';

export interface LayerTextureRendererRef {
  exportTexture: () => ImageData;
}

export const LayerTextureRenderer = forwardRef<
  LayerTextureRendererRef,
  PropsWithChildren<{
    width: number;
    height: number;
    materialProps?: MeshBasicMaterialProps;
    camera?: Camera;
    toneMapping?: ToneMapping;
    multisampled?: boolean;
  }>
>(
  (
    {
      width,
      height,
      children,
      materialProps,
      camera,
      toneMapping,
      multisampled,
    },
    ref
  ) => {
    const gl = useThree((s) => s.gl);
    const fbo = useFBO(width, height, {
      samples: multisampled ? gl.capabilities.maxSamples : 0,
      type: UnsignedByteType,
      minFilter: NearestFilter,
      magFilter: NearestFilter,
    });

    const scene = useMemo(() => new Scene(), []);

    const defaultCamera = useMemo(() => {
      const defaultCamera = new OrthographicCamera(
        (width / 2) * -1,
        width / 2,
        height / 2,
        (height / 2) * -1,
        0.1,
        1000
      );
      defaultCamera.position.z = 10;
      return defaultCamera;
    }, [width, height]);

    const render = (gl: WebGLRenderer) => {
      // Inspired from https://github.com/pmndrs/drei/blob/master/src/core/RenderTexture.tsx
      const oldAutoClear = gl.autoClear;
      gl.autoClear = true;
      gl.setRenderTarget(fbo);
      const oldToneMapping = gl.toneMapping;
      if (toneMapping) {
        gl.toneMapping = toneMapping;
      }
      scene.traverse((child) => {
        if (object3dIsMesh(child)) {
          child.material.premultipliedAlpha = false;
          // Blending could be set manually, for example: the eraser tool
          if (child.material.blending !== CustomBlending) {
            // fix blending with transparent background
            child.material.blending = CustomBlending;
            // output color = (sourceColor * 1) + (destinationColor * (1 - sourceAlpha))
            // output alpha = (sourceAlpha * 1) + (destinationAlpha * 1) (maxing out at 1)
            child.material.blendEquation = AddEquation;
            child.material.blendSrc = OneFactor;
            child.material.blendDst = OneMinusSrcAlphaFactor;
            child.material.blendSrcAlpha = OneFactor;
            child.material.blendDstAlpha = OneFactor;
          }
        }
      });

      gl.render(scene, camera ?? defaultCamera);
      gl.setRenderTarget(null);
      gl.autoClear = oldAutoClear;
      gl.toneMapping = oldToneMapping;
    };

    useImperativeHandle(
      ref,
      () => ({
        exportTexture: () => {
          render(gl);

          const imageData = new ImageData(width, height); // we want exportTexture to be synchronous to simplify the rest of the logic of the brush handling
          // to do so, we read the pixels and store them in an image that then get asynchrounously converted to a png blob
          // just before sending to the server
          gl.readRenderTargetPixels(fbo, 0, 0, width, height, imageData.data);
          // flip the image upside down because readRenderTargetPixels read pixels from the bottom left corner
          yFlipImageBuffer(imageData.data, width);
          return imageData;
        },
      }),
      [width, height, gl, fbo]
    );

    useFrame(
      (state) => render(state.gl),
      WORKBENCH_CONTENT_RENDERING_ORDER.indexOf('individualLayerTexture')
    );

    return (
      <>
        <mesh scale={[width, height, 1]}>
          <planeGeometry args={[1, 1, 1, 1]} />
          <meshBasicMaterial
            transparent
            side={DoubleSide}
            map={fbo.texture}
            {...materialProps}
          />
        </mesh>
        {createPortal(<>{children}</>, scene)}
      </>
    );
  }
);

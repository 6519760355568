import { useCallback, useMemo, useState } from 'react';

import { Intro } from './Intro';
import { Step } from './Step';
import Size1 from './assets/1-person.svg?react';
import Size3 from './assets/11-30-people.svg?react';
import Size2 from './assets/2-10-people.svg?react';
import Size4 from './assets/30-plus-people.svg?react';
import ThreeD from './assets/3d.svg?react';
import Architecture from './assets/architecture.svg?react';
import Automotive from './assets/automotive.svg?react';
import ColorMaterial from './assets/color-material.svg?react';
import Education from './assets/education.svg?react';
import Enhance from './assets/enhance.svg?react';
import Entertainment from './assets/entertainment.svg?react';
import Fashion from './assets/fashion.svg?react';
import Footwear from './assets/footwear.svg?react';
import Furniture from './assets/furniture.svg?react';
import HomeGoods from './assets/home-goods.svg?react';
import Ideation from './assets/ideation.svg?react';
import IndustrialDesign from './assets/industrial-design.svg?react';
import NewTools from './assets/new-tools.svg?react';
import Other from './assets/other.svg?react';
import Palettes from './assets/palettes.svg?react';
import Render from './assets/render.svg?react';
import Visualization from './assets/visualization.svg?react';

export const UserProfileSurvey = ({
  onSubmit: onSubmitFromProps,
}: {
  onSubmit: (selectedOptions: { [key: number]: string[] }) => void;
}) => {
  const {
    currentStep,
    selectedOptions,
    isOpen,
    toggleSelectedOption,
    onChangeStep,
    setIsOpen,
  } = useUserProfileSurvey();

  const onSubmit = useCallback(async () => {
    setIsOpen(false);
    onSubmitFromProps(selectedOptions);
  }, [onSubmitFromProps, setIsOpen, selectedOptions]);

  return currentStep === -1 ? (
    <Intro onNext={() => onChangeStep(0)} isOpen={isOpen} />
  ) : (
    <Step
      {...steps[currentStep]}
      selectedOptions={selectedOptions}
      toggleSelectedOption={toggleSelectedOption}
      currentStep={currentStep}
      totalSteps={steps.length}
      onChangeStep={onChangeStep}
      onSubmit={onSubmit}
      isOpen={isOpen}
    />
  );
};

export const useUserProfileSurvey = () => {
  const defaultState = useMemo(
    () => ({
      currentStep: -1,
      selectedOptions: { 0: [], 1: [], 2: [] },
      isOpen: true,
    }),
    []
  );
  const [{ currentStep, selectedOptions, isOpen }, setState] = useState<{
    currentStep: number;
    selectedOptions: { [key: number]: string[] };
    isOpen: boolean;
  }>(defaultState);

  const toggleSelectedOption = useCallback(
    (selectedOption: string, maxSelectedOptions = 1) => {
      setState(({ currentStep, ...currState }) => ({
        ...currState,
        currentStep,
        selectedOptions: {
          ...selectedOptions,
          [currentStep]: selectedOptions[currentStep].includes(selectedOption)
            ? selectedOptions[currentStep].filter(
                (option) => option !== selectedOption
              )
            : [...selectedOptions[currentStep], selectedOption].slice(
                -maxSelectedOptions
              ),
        },
      }));
    },
    [selectedOptions]
  );

  const setIsOpen = useCallback(
    (isOpen: boolean) => {
      setState((currState) => ({
        // reset state when closing the modal
        ...(isOpen ? currState : defaultState),
        isOpen,
      }));
    },
    [defaultState]
  );

  const onChangeStep = useCallback((index: number) => {
    setState((currState) => ({ ...currState, currentStep: index }));
  }, []);

  return {
    currentStep,
    selectedOptions,
    isOpen,
    toggleSelectedOption,
    onChangeStep,
    setIsOpen,
  };
};

export const steps = [
  /* Industry step */
  {
    title: 'What industry are you part of?',
    description:
      'Your industry helps us tailor tutorials and resources to your needs.',
    options: [
      {
        id: 'idustrial-design',
        label: 'Industrial Design',
        image: <IndustrialDesign />,
      },
      {
        id: 'automotive',
        label: 'Automotive',
        image: <Automotive />,
      },
      {
        id: 'architecture',
        label: 'Architecture',
        image: <Architecture />,
      },
      {
        id: 'footwear',
        label: 'Footwear',
        image: <Footwear />,
      },
      {
        id: 'fashion',
        label: 'Fashion & Apparel',
        image: <Fashion />,
      },
      {
        id: 'furniture',
        label: 'Furniture',
        image: <Furniture />,
      },
      {
        id: 'home-goods',
        label: 'Home Goods',
        image: <HomeGoods />,
      },
      {
        id: 'entertainment',
        label: 'Entertainment & Gaming',
        image: <Entertainment />,
      },
      {
        id: 'other',
        label: 'Other',
        image: <Other />,
      },
    ],
  },

  /* Usage step */
  {
    title: 'How do you want to use Vizcom?',
    description:
      'Selecting one or more options helps us provide templates tailored to your goals.',
    maxSelectedOptions: 9,
    options: [
      {
        id: 'new-tools',
        label: 'Exploring new tools',
        image: <NewTools />,
      },
      {
        id: 'render',
        label: 'Render Sketches',
        image: <Render />,
      },
      {
        id: 'palettes',
        label: 'Custom AI Models',
        image: <Palettes />,
      },
      {
        id: '3d',
        label: 'Generate 3D',
        image: <ThreeD />,
      },
      {
        id: 'ideation',
        label: 'Ideation',
        image: <Ideation />,
      },
      {
        id: 'color-material',
        label: 'Exploring Color & Material',
        image: <ColorMaterial />,
      },
      {
        id: 'visualization',
        label: 'Product Visualization',
        image: <Visualization />,
      },
      {
        id: 'enhance',
        label: 'Enhance',
        image: <Enhance />,
      },
      {
        id: 'education',
        label: 'For Education',
        image: <Education />,
      },
    ],
  },

  /* Team size step */
  {
    title: 'How big is your team?',
    description:
      'Knowing your team size helps us provide the right support for your team.',
    options: [
      {
        id: '1-person',
        label: "It's just me",
        image: <Size1 />,
      },
      {
        id: '2-10-people',
        label: '2 - 10 people',
        image: <Size2 />,
      },
      {
        id: '11-31-people',
        label: '11 - 30 people',
        image: <Size3 />,
      },
      {
        id: '30-plus-people',
        label: '30+ people',
        image: <Size4 />,
      },
    ],
  },
];

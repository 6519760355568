import {
  SelectIcon,
  ToolbarButton,
  ToolbarButtonState,
  ToolbarMenuButton,
  WarpIcon,
} from '@vizcom/shared-ui-components';

import {
  useWorkbenchStudioState,
  WorkbenchTool,
  WorkbenchStudioToolType,
} from '../../studioState';
import { Hotkey } from '../../style';
import { TransformSettings } from './TransformSettings';

interface TransformButtonProps {
  isLayer3d: boolean;
  currentTool: WorkbenchStudioToolType;
  isMenuOpen: boolean;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onChangeTool: (tool: WorkbenchStudioToolType) => void;
}

export const TRANSFORM_TOOLS: WorkbenchTool[] = [
  {
    type: WorkbenchStudioToolType.Move,
    icon: <SelectIcon />,
    tooltip: 'Move',
    hotkey: 'M',
  },
  {
    type: WorkbenchStudioToolType.Transform,
    icon: <WarpIcon />,
    tooltip: 'Skew',
    hotkey: 'T',
  },
];

export const TransformButton = ({
  isLayer3d,
  currentTool,
  isMenuOpen,
  setIsMenuOpen,
  onChangeTool,
}: TransformButtonProps) => {
  const studioState = useWorkbenchStudioState();
  const selectedTransformTool =
    TRANSFORM_TOOLS.find(({ type }) => type === studioState.tool) ||
    TRANSFORM_TOOLS[0];
  const state = TRANSFORM_TOOLS.some((tool) => tool.type === currentTool)
    ? ToolbarButtonState.ACTIVE
    : ToolbarButtonState.INACTIVE;
  const transformProps = {
    state,
    icon: selectedTransformTool.icon,
    tooltip: (
      <>
        {selectedTransformTool.tooltip}{' '}
        <Hotkey>{selectedTransformTool.hotkey}</Hotkey>
      </>
    ),
    menuTooltip: 'Move Tools',
  };

  return isLayer3d ? (
    <ToolbarButton
      {...transformProps}
      onClick={() => studioState.setTool(WorkbenchStudioToolType.Move)}
    />
  ) : (
    <ToolbarMenuButton
      {...transformProps}
      onClick={() => onChangeTool(selectedTransformTool.type)}
      menu={
        <TransformSettings
          onSelect={() => setIsMenuOpen(!isMenuOpen)}
          isShowCheckmarks
        />
      }
      showMenuIcon
      controlledMenuState={[isMenuOpen, setIsMenuOpen]}
    />
  );
};

import { Navigate } from 'react-router-dom';
import {
  useDeleteTeamInvite,
  useDeleteUsersOnTeam,
  useTeamMembers,
} from '@vizcom/shared/data-access/graphql';
import { capitalize } from '@vizcom/shared/js-utils';
import {
  ContextMenu,
  ContextMenuItem,
  InfoIcon,
  LoadingLogoInset,
  MenuHorizontalIcon,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  Text,
  Tooltip,
  UserAvatar,
  addToast,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';

import { useCanEditOrganization } from '../useCanEditOrganization';

export const TeamMembersList = (props: {
  teamId: string;
  searchFilter: string;
}) => {
  const { data: team, fetching } = useTeamMembers(props.teamId);
  const canEdit = useCanEditOrganization(team?.organization?.id);
  const [, deleteUsersOnTeam] = useDeleteUsersOnTeam();
  const [, deleteTeamInvite] = useDeleteTeamInvite();

  const handleRemoveUserFromTeam = async (userId: string) => {
    const res = await deleteUsersOnTeam({
      input: {
        teamId: props.teamId,
        userId: userId,
      },
    });
    if (res.error) {
      return addToast('Error while removing user from team', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }
    addToast(`User removed from team`);
  };

  const members = (team?.usersOnTeamsByTeamId.nodes ?? []).filter((member) => {
    return (
      member.user?.name?.includes(props.searchFilter) ||
      member.user?.email.includes(props.searchFilter)
    );
  });

  const invites = (team?.invites.nodes ?? []).filter((invite) => {
    return invite.email.includes(props.searchFilter);
  });

  const handleRemoveInvite = async (inviteEmail: string) => {
    const res = await deleteTeamInvite({
      input: {
        teamId: props.teamId,
        email: inviteEmail,
      },
    });
    if (res.error) {
      return addToast('Error while deleting invite', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }
    addToast(`Invite deleted`);
  };

  if (fetching) {
    return <LoadingLogoInset />;
  }
  if (!team) {
    return <Navigate to={'/'} replace />;
  }

  if (
    !team.globalInOrganization &&
    team.usersOnTeamsByTeamId.totalCount === 0 &&
    team.invites.totalCount === 0
  ) {
    return <Text color="subtext">No members yet</Text>;
  }

  return (
    <Table cols={2}>
      <TableHeader>
        <TableCell style={{ paddingLeft: 0 }}>
          <Text color="subtext">Name</Text>
        </TableCell>
        <TableCell>
          <Text color="subtext">
            <Tooltip tip="Role type is set at the workspace level. Manage roles in Settings > Members">
              Role
              <InfoIcon style={{ marginLeft: 12 }} />
            </Tooltip>
          </Text>
        </TableCell>
        <TableCell />
      </TableHeader>
      {members.map(
        ({ userRoleInOrganization, user }) =>
          user && (
            <TableRow key={user.id}>
              <TableCell
                style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  paddingLeft: 0,
                  gap: 12,
                }}
              >
                <UserAvatar name={user.name ?? user.email} userId={user.id} />
                <div style={{ flex: 1, flexBasis: 0, overflow: 'hidden' }}>
                  <Text block>{user.name ?? '-'}</Text>
                  <Text block color="subtext">
                    {user.email}
                  </Text>
                </div>
              </TableCell>
              <TableCell>{capitalize(userRoleInOrganization ?? '')}</TableCell>
              <TableCell style={{ paddingRight: 0 }}>
                {canEdit && (
                  <ContextMenu
                    buttonProps={{
                      variant: 'tertiary',
                      size: 'icon',
                    }}
                    items={
                      <ContextMenuItem
                        onClick={() => handleRemoveUserFromTeam(user.id)}
                      >
                        Remove from team
                      </ContextMenuItem>
                    }
                  >
                    <MenuHorizontalIcon />
                  </ContextMenu>
                )}
              </TableCell>
            </TableRow>
          )
      )}
      {invites.map((invite) => (
        <TableRow key={invite.email}>
          <TableCell
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-start',
              paddingLeft: 0,
              gap: 12,
            }}
          >
            <UserAvatar name={invite.email} userId={invite.email} />
            <div style={{ flex: 1 }}>
              <Text block>{invite.email}</Text>
              <Text block color="subtext">
                Invited
              </Text>
            </div>
          </TableCell>
          <TableCell>-</TableCell>
          <TableCell>
            {canEdit && (
              <ContextMenu
                buttonProps={{
                  variant: 'tertiary',
                  size: 'icon',
                }}
                items={
                  <ContextMenuItem
                    onClick={() => handleRemoveInvite(invite.email)}
                  >
                    Remove invite
                  </ContextMenuItem>
                }
              >
                <MenuHorizontalIcon />
              </ContextMenu>
            )}
          </TableCell>
        </TableRow>
      ))}
    </Table>
  );
};

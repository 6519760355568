import { useGLTF } from '@react-three/drei';
import { Spinner } from 'libs/shared/ui/components/src/lib/Loader/Loader.styled';
import {
  KeyboardEvent,
  useMemo,
  useEffect,
  useRef,
  useState,
  SetStateAction,
  Dispatch,
  MouseEvent,
} from 'react';
import styled, { useTheme } from 'styled-components';
import { Object3D } from 'three';
import { CompositeSceneElement } from '@vizcom/shared/data-access/graphql';
import {
  CarretDownIcon,
  UnhideIcon,
  MeatballIcon,
  Model3dIcon,
  HideIcon,
  Menu,
  ToolbarButton,
  MenuItem,
} from '@vizcom/shared-ui-components';

import { useCompositeSceneSyncedState } from '../../../lib/useCompositeSceneSyncedState';
import { exportObject3d, ExportFormat } from '../../utils/meshHelpers';
import { useCompositeSceneEditorContext } from '../compositeSceneEditor/context';
import { getCompositeSceneNodeUUID } from '../compositeSceneEditor/utils/compositeSceneNodeUUID';
import { Field, MissingLabel } from '../compositeSceneMenu/style';
import {
  Actions,
  ModelName,
  StructureTreeItem,
  StructureTreeWrapper,
} from './style';

const SimpleExportLabel = ({ format }: { format: string }) => {
  return (
    <>
      <span style={{ opacity: 0.5 }}>Export to </span>
      <span>{format}</span>
    </>
  );
};

const TreeNode = ({
  setSelected,
  setHovered,
  object,
  depthLevel,
  expanded,
  setExpanded,
  isActive,
  onKeyDown,
  meshState,
  updateElementVisible,
}: {
  setSelected: (mesh: Object3D | null) => void;
  setHovered: (mesh: Object3D | null) => void;
  object: Object3D;
  depthLevel: number;
  expanded: boolean;
  setExpanded: Dispatch<SetStateAction<boolean>>;
  isActive: boolean;
  onKeyDown: (event: KeyboardEvent<HTMLDivElement>) => void;
  meshState: {
    deleted?: boolean;
  };
  updateElementVisible: (element: Object3D, visible: boolean) => void;
}) => {
  const theme = useTheme();
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [isExporting, setIsExporting] = useState<ExportFormat | null>(null);

  const exportNode = async (
    event: MouseEvent<HTMLButtonElement>,
    node: Object3D,
    format: ExportFormat
  ) => {
    event.preventDefault();
    event.stopPropagation();

    if (isExporting) {
      return;
    }

    setIsExporting(format);

    const model = await exportObject3d(node, format);
    const blob = new Blob([model.result], { type: model.resultType });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `model.${format}`;

    a.click();

    URL.revokeObjectURL(url);

    setShowContextMenu(false);
    setIsExporting(null);
  };

  return (
    <StructureTreeItem
      tabIndex={0}
      onClick={() => {
        if (!isActive) {
          setSelected(object as Object3D);
          setExpanded(true);
        } else if (depthLevel === 0) {
          setExpanded((value) => !value);
        }
      }}
      onPointerEnter={() => {
        setHovered(object as Object3D);
      }}
      onPointerLeave={() => {
        setHovered(null);
      }}
      onKeyDown={onKeyDown}
      $active={isActive}
      $depthLevel={depthLevel}
    >
      <CarretDownIcon
        style={{
          color: isActive ? theme.icon.primaryInverted : theme.icon.secondary,
          opacity: !object.children.length ? 0.0 : 1.0,
          transform: expanded ? 'rotate(0deg)' : 'rotate(-90deg)',
        }}
      />
      {meshState.deleted ? (
        <UnhideIcon
          style={{
            color: isActive ? theme.icon.primaryInverted : theme.icon.secondary,
            fill: isActive ? theme.icon.primaryInverted : theme.icon.secondary,
          }}
          onClick={(event) => {
            event.stopPropagation();

            updateElementVisible(object, true);
          }}
        />
      ) : (
        <HideIcon
          style={{
            color: isActive ? theme.icon.primaryInverted : theme.icon.secondary,
            fill: isActive ? theme.icon.primaryInverted : theme.icon.secondary,
          }}
          onClick={(event) => {
            event.stopPropagation();

            updateElementVisible(object, false);
          }}
        />
      )}
      <Model3dIcon
        style={{
          color: isActive ? theme.icon.primaryInverted : theme.icon.secondary,
        }}
      />
      <ModelName>
        {object.name || <MissingLabel>(Unnamed)</MissingLabel>}
      </ModelName>
      {depthLevel === 0 && (
        <Actions
          $active={isActive}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Menu
            placement="left-start"
            renderLabel={(props, interactionProps) => (
              <ToolbarButton
                icon={<MeatballIcon />}
                tooltip="More actions"
                {...interactionProps}
                onClick={(e) => {
                  e.stopPropagation();
                  (interactionProps.onClick as React.MouseEventHandler)(e);
                }}
                buttonProps={{ tabIndex: -1 }}
              />
            )}
            open={showContextMenu}
            setIsOpen={setShowContextMenu}
          >
            <MenuItem
              prependLabel={
                isExporting === 'gltf' ? (
                  <Spinner size={{ width: 16, height: 16 }} />
                ) : (
                  <Model3dIcon />
                )
              }
              label={<SimpleExportLabel format="GLTF" />}
              onClick={(event) => exportNode(event, object, 'gltf')}
            />
            <MenuItem
              prependLabel={
                isExporting === 'glb' ? (
                  <Spinner size={{ width: 16, height: 16 }} />
                ) : (
                  <Model3dIcon />
                )
              }
              label={<SimpleExportLabel format="GLB" />}
              onClick={(event) => exportNode(event, object, 'glb')}
            />
            <MenuItem
              prependLabel={
                isExporting === 'obj' ? (
                  <Spinner size={{ width: 16, height: 16 }} />
                ) : (
                  <Model3dIcon />
                )
              }
              label={<SimpleExportLabel format="OBJ" />}
              onClick={(event) => exportNode(event, object, 'obj')}
            />
            <MenuItem
              prependLabel={
                isExporting === 'stl' ? (
                  <Spinner size={{ width: 16, height: 16 }} />
                ) : (
                  <Model3dIcon />
                )
              }
              label={<SimpleExportLabel format="STL" />}
              onClick={(event) => exportNode(event, object, 'stl')}
            />
            <MenuItem
              prependLabel={
                isExporting === 'usdz' ? (
                  <Spinner size={{ width: 16, height: 16 }} />
                ) : (
                  <Model3dIcon />
                )
              }
              label={<SimpleExportLabel format="USDZ" />}
              onClick={(event) => exportNode(event, object, 'usdz')}
            />
          </Menu>
        </Actions>
      )}
    </StructureTreeItem>
  );
};

export const SceneStructureNode = ({
  compositeSceneElement,
  handleAction,
}: {
  compositeSceneElement: CompositeSceneElement;
  handleAction: ReturnType<typeof useCompositeSceneSyncedState>['handleAction'];
}) => {
  const [expanded, setExpanded] = useState(false);
  const structureTreeRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const { selected, setSelected, setHovered } =
    useCompositeSceneEditorContext();
  const [gltf] = useGLTF(
    compositeSceneElement.modelPath ? [compositeSceneElement.modelPath] : []
  );
  const updateElementVisible = (element: Object3D, visible: boolean) => {
    const uuid = getCompositeSceneNodeUUID(element);
    const currentState = compositeSceneElement.meshes[uuid] || {};
    const updatedState = {
      ...currentState,
      deleted: !visible,
    };

    handleAction({
      type: 'updateCompositeSceneElement',
      id: element.userData.rootId,
      meshes: {
        ...compositeSceneElement.meshes,
        [uuid]: updatedState,
      },
    });
  };
  const onKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (selected && ['Backspace', 'Delete'].includes(event.key)) {
      updateElementVisible(selected, false);
    }
  };

  const structureTree: JSX.Element | null = useMemo(() => {
    if (!gltf?.scene) {
      return null;
    }

    const renderStructureNode = (
      object: Object3D,
      depthLevel = 0,
      {
        recursive = true,
      }: {
        recursive?: boolean;
      }
    ): JSX.Element | null => {
      const children = recursive
        ? (object.children || []).map((child: Object3D) =>
            renderStructureNode(child, depthLevel + 1, { recursive: true })
          )
        : null;
      const isActive = selected?.uuid === object.uuid;
      const uuid = getCompositeSceneNodeUUID(object);
      const meshState = compositeSceneElement.meshes[uuid] || {};

      return (
        <div key={object.uuid} attr-objectuuid={object.uuid as string}>
          <TreeNode
            setSelected={setSelected}
            setHovered={setHovered}
            object={object}
            depthLevel={depthLevel}
            expanded={expanded}
            setExpanded={setExpanded}
            isActive={isActive}
            onKeyDown={onKeyDown}
            meshState={meshState}
            updateElementVisible={updateElementVisible}
          />
          {children}
        </div>
      );
    };

    if (expanded) {
      return (
        <>
          {gltf.scene.children.map(
            (child: Object3D) =>
              renderStructureNode(child, 0, { recursive: true }),
            { recursive: true }
          )}
        </>
      );
    } else {
      return (
        <>
          {renderStructureNode(gltf.scene.children[0], 0, { recursive: false })}
        </>
      );
    }
  }, [gltf, gltf?.scene, selected, theme, compositeSceneElement, expanded]);

  useEffect(() => {
    if (!selected || !expanded || !structureTreeRef.current) {
      return;
    }

    const selectedNode = structureTreeRef.current.querySelector(
      `[attr-objectuuid="${selected.uuid}"]`
    );

    if (!selectedNode) {
      return;
    }

    selectedNode.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest',
    });
  }, [selected, expanded, structureTreeRef]);

  if (!compositeSceneElement.modelPath || compositeSceneElement.basicShape) {
    return null;
  }

  return (
    <NodeField $expanded={expanded}>
      <StructureTreeWrapper ref={structureTreeRef}>
        {structureTree}
      </StructureTreeWrapper>
    </NodeField>
  );
};

const NodeField = styled(Field)<{
  $expanded: boolean;
}>`
  padding: 16px 16px 0px 16px;
  max-height: ${({ $expanded }) => ($expanded ? '226px' : 'auto')};
`;

import {
  RectangleSelectionIcon,
  BrushSelectionIcon,
  AutoSelectionIcon,
  LassoIcon,
  BezierCurvesIcon,
} from '@vizcom/shared-ui-components';

import {
  WorkbenchStudioToolType,
  useWorkbenchStudioState,
} from '../../studioState';
import { ToolbarMenuOption } from '../ToolbarOption';

type SelectionSettingsProps = {
  onSelectFromMenu: () => void;
  isUseCheckmarks?: boolean;
};

export const SelectionSettings = ({
  onSelectFromMenu,
  isUseCheckmarks,
}: SelectionSettingsProps) => {
  const { tool, setTool } = useWorkbenchStudioState();

  const isAnyActive = [
    WorkbenchStudioToolType.Lasso,
    WorkbenchStudioToolType.RectangleSelection,
    WorkbenchStudioToolType.BrushSelection,
    WorkbenchStudioToolType.BezierSelection,
    WorkbenchStudioToolType.AutoSelection,
  ].some((t) => t === tool);

  return (
    <>
      <ToolbarMenuOption
        icon={<LassoIcon />}
        label="Lasso Selection"
        shortcut="G"
        active={tool === WorkbenchStudioToolType.Lasso}
        isSpaceForCheckmark={isUseCheckmarks && isAnyActive}
        onClick={() => {
          setTool(WorkbenchStudioToolType.Lasso);
          onSelectFromMenu();
        }}
      />
      <ToolbarMenuOption
        icon={<RectangleSelectionIcon />}
        label="Rectangle Selection"
        shortcut="G"
        active={tool === WorkbenchStudioToolType.RectangleSelection}
        isSpaceForCheckmark={isUseCheckmarks && isAnyActive}
        onClick={() => {
          setTool(WorkbenchStudioToolType.RectangleSelection);
          onSelectFromMenu();
        }}
      />
      <ToolbarMenuOption
        icon={<BrushSelectionIcon />}
        label="Brush Selection"
        shortcut="G"
        active={tool === WorkbenchStudioToolType.BrushSelection}
        isSpaceForCheckmark={isUseCheckmarks && isAnyActive}
        onClick={() => {
          setTool(WorkbenchStudioToolType.BrushSelection);
          onSelectFromMenu();
        }}
      />
      <ToolbarMenuOption
        icon={<BezierCurvesIcon />}
        label="Bezier Selection"
        shortcut="G"
        active={tool === WorkbenchStudioToolType.BezierSelection}
        isSpaceForCheckmark={isUseCheckmarks && isAnyActive}
        onClick={() => {
          setTool(WorkbenchStudioToolType.BezierSelection);
          onSelectFromMenu();
        }}
      />
      <ToolbarMenuOption
        icon={<AutoSelectionIcon />}
        label="Auto Selection"
        shortcut="G"
        active={tool === WorkbenchStudioToolType.AutoSelection}
        isSpaceForCheckmark={isUseCheckmarks && isAnyActive}
        onClick={() => {
          setTool(WorkbenchStudioToolType.AutoSelection);
          onSelectFromMenu();
        }}
      />
    </>
  );
};

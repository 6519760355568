import { createContext, useContext, useEffect, useMemo } from 'react';
import { StoreApi, createStore, useStore } from 'zustand';
import {
  assertExists,
  assertUnreachable,
  sortByOrderKey,
} from '@vizcom/shared/js-utils';

import { useLastValue } from '../../../../../../../shared/ui/components/src';
import { Drawing2dStudio } from '../../../lib/useDrawingSyncedState';
import { useIsWorkbenchViewer } from '../../../lib/utils';
import { getParentVisibility } from '../utils';

export type ActiveLayerChangeOperation = 'set' | 'add' | 'addTo' | 'remove';

export interface ActiveLayerStateStore {
  activeLayerId: string | undefined;

  setActiveLayerId: (
    id: string | undefined,
    // 'set' sets the active layer to the given id
    // 'add' adds the given id to the active layer id
    // 'addTo' adds the given id to the active layer id and all layers between the active layer and the given id
    operation?: ActiveLayerChangeOperation
  ) => void;
  selectionOrigin: string | undefined;
  beforeActiveLayerChange: (() => void)[];
}

// Auto select first visible layer if none are selected or if the selected layer
// doesn't exist anymore (because it was deleted)
export const useCreateActiveLayerStore = (
  drawing: Drawing2dStudio | undefined | null
) => {
  const drawingRef = useLastValue(drawing);
  const isViewer = useIsWorkbenchViewer();
  const isViewerRef = useLastValue(isViewer);

  const store = useMemo(
    () =>
      createStore<ActiveLayerStateStore>((set, get) => ({
        beforeActiveLayerChange: [],
        activeLayerId: undefined,
        setActiveLayerId: (id, operation = 'set') => {
          if (!drawingRef.current || isViewerRef.current) {
            return;
          }
          get().beforeActiveLayerChange.forEach((callback) => callback());
          if (operation === 'addTo') {
            let newActiveLayerId = get().activeLayerId;
            const selectionOrigin = get().selectionOrigin;
            if (selectionOrigin === undefined) {
              set({ selectionOrigin: get().activeLayerId });
            } else {
              newActiveLayerId = selectionOrigin.split('/')[0];
              set({
                selectionOrigin: selectionOrigin.split('/')[0],
              });
            }

            const sorted = sortByOrderKey(drawingRef.current.layers.nodes);
            const index = sorted.findIndex(
              (layer) => layer.id === newActiveLayerId
            );
            const newIndex = sorted.findIndex((layer) => layer.id === id);

            let included = sorted.slice(index + 1, newIndex + 1);
            if (newIndex < index) {
              included = sorted.slice(newIndex, index);
            }

            included.forEach((layer) => {
              newActiveLayerId += `/${layer.id}`;
            });

            if (!newActiveLayerId) {
              set({ activeLayerId: undefined });
              return;
            }

            const ordered = newActiveLayerId
              .split('/')
              .sort(
                (a, b) =>
                  sorted.findIndex((layer) => layer.id === a) -
                  sorted.findIndex((layer) => layer.id === b)
              );

            set({ activeLayerId: ordered.join('/') });
            return;
          } else if (operation === 'add') {
            const ordered = sortByOrderKey(drawingRef.current.layers.nodes);
            const ids = [...(get().activeLayerId?.split('/') || []), id];

            const orderdIds = ids.sort(
              (a, b) =>
                ordered.findIndex((layer) => layer.id === a) -
                ordered.findIndex((layer) => layer.id === b)
            );

            set({ activeLayerId: orderdIds.join('/') });
            return;
          } else if (operation === 'set') {
            set({ selectionOrigin: undefined });

            if (!id) {
              set({ activeLayerId: undefined });
              return;
            }

            const ids = id.split('/');
            const order = sortByOrderKey(drawingRef.current.layers.nodes);
            const sortedIds = ids.sort(
              (a, b) =>
                order.findIndex((layer) => layer.id === a) -
                order.findIndex((layer) => layer.id === b)
            );
            set({ activeLayerId: sortedIds.join('/') });
          } else if (operation === 'remove') {
            if (!id) return;

            const ids = get().activeLayerId?.split('/') || [];
            const index = ids.indexOf(id);
            if (index !== -1) {
              ids.splice(index, 1);
              set({ activeLayerId: ids.join('/') });
            }
          } else {
            assertUnreachable(operation);
          }

          set({ activeLayerId: id });
        },
        selectionOrigin: undefined,
      })),
    [drawingRef, isViewerRef]
  );

  const storeState = useStore(store);

  useEffect(() => {
    if (!drawing) {
      return;
    }
    if (!storeState.activeLayerId) {
      // if no active layer id, auto-select the first visible layer if there's one
      const firstVisibleLayerId = sortByOrderKey(drawing.layers.nodes).find(
        (layer) => getParentVisibility(drawing, layer.id)
      )?.id;
      if (firstVisibleLayerId) {
        storeState.setActiveLayerId(firstVisibleLayerId, 'set');
      }
    }

    // check that the layer still exists, could have been deleted by the user and `storeState.activeLayerId` would be invalid
    const existingActiveLayerIds =
      (storeState.activeLayerId?.split('/') || [])
        .filter((id) => drawing.layers.nodes.some((layer) => layer.id === id))
        .join('/') || undefined;
    if (existingActiveLayerIds !== storeState.activeLayerId) {
      storeState.setActiveLayerId(existingActiveLayerIds, 'set');
    }
  }, [drawing, storeState]);

  return store;
};

const activeLayerContext =
  createContext<null | StoreApi<ActiveLayerStateStore>>(null);
export const ActiveLayerContextProvider = activeLayerContext.Provider;

export const useActiveLayer = () => {
  const store = useContext(activeLayerContext);
  assertExists(
    store,
    'useActiveLayer must be used inside ActiveLayerContextProvider'
  );
  const state = useStore(store);
  return state;
};

export const useBeforeActiveLayerChange = (callback: () => void) => {
  const store = useContext(activeLayerContext);
  assertExists(
    store,
    'useBeforeActiveLayerChange must be used inside ActiveLayerContextProvider'
  );
  const lastValue = useLastValue(callback);

  useEffect(() => {
    const eventListener = () => {
      lastValue.current();
    };

    store.getState().beforeActiveLayerChange.push(eventListener);
    return () => {
      store.setState({
        beforeActiveLayerChange: store
          .getState()
          .beforeActiveLayerChange.filter((cb) => cb !== eventListener),
      });
    };
  }, [lastValue, store]);
};

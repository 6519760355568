import { LibraryType } from '@vizcom/shared/data-access/graphql';
import {
  triggerConfirmModal,
  ConfirmationModalComponent,
} from '@vizcom/shared-ui-components';

export const triggerCreateConfirmationModal = async (
  libraryType: LibraryType,
  orgName: string | undefined
) => {
  if (libraryType !== LibraryType.Organization) return;

  await triggerConfirmModal({
    body: (onConfirm, onCancel) => (
      <ConfirmationModalComponent
        onConfirm={onConfirm}
        variant="primary"
        onCancel={onCancel}
        title={`Add to ${orgName || 'your organization'} color library?`}
        description="By adding this asset to your workspace, it will be available for your entire team to use across all files in the workspace."
      />
    ),
  });
};

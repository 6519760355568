import styled from 'styled-components';
import { CloseIcon } from '@vizcom/shared-ui-components';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;

  color: ${({ theme }) => theme.text.bodyInverted};
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 40px;
  position: relative;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;
export const AuthLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Divider = styled.div`
  width: 90%;
  height: 1px;
  background-color: #e0e0e0;
  margin: 20px 1rem 8px 1rem;
  opacity: 0.2;
`;

export const Input = styled.input`
  width: 100%;
  height: 40px;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  border: 0.5px solid ${({ theme }) => theme.border.tertiary};
  background-color: ${({ theme }) => theme.surface.page};
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  outline: none;
  transition: all 0.2s ease-in-out;
  margin: 8px 0px 0px 0px;
  padding: 10px 16px;
`;

export const ErrorMessage = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.text.error};
  opacity: 0.6;
  margin: 0;
  padding: 0;
  margin-top: 8px;
`;

export const SuccessMessage = styled.h2`
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  margin: 0;
  padding: 0;
  margin-top: 16px;
  opacity: 0.6;
`;

export const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const RequirementList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  list-style: none;
  padding: 0;
  margin-top: 8px;
`;

export const RequirementItem = styled.li<{ valid: boolean }>`
  color: ${(props) => (props.valid ? 'green' : 'red')};
  margin-bottom: 4px; // Spacing between list items
  &:before {
    content: '${(props) => (props.valid ? '✓' : '✗')}';
    margin-right: 8px;
  }
`;
export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledButtonContainer = styled.div`
  width: 100%;
  margin-top: 40px;
`;

export const ResetInputIcon = styled(CloseIcon)`
  position: absolute;
  top: 60%;
  right: 8px;
  transform: translateY(-50%);
  cursor: pointer;
`;

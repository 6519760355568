import styled from 'styled-components';
import {
  RectangleIcon,
  EllipseIcon,
  LineIcon,
} from '@vizcom/shared-ui-components';

import {
  WorkbenchStudioToolType,
  useWorkbenchStudioState,
} from '../../studioState';
import { ToolbarMenuOption } from '../ToolbarOption';

type ShapeSettingsProps = {
  onSelect?: () => void;
  isUseCheckmarks?: boolean;
};

export const ShapeSettings = ({
  onSelect,
  isUseCheckmarks,
}: ShapeSettingsProps) => {
  const { tool, setTool } = useWorkbenchStudioState();

  const isRectangleActive = tool === WorkbenchStudioToolType.Rectangle;
  const isEllipseActive = tool === WorkbenchStudioToolType.Ellipse;
  const isLineActive = tool === WorkbenchStudioToolType.Line;
  const isAnyActive = isRectangleActive || isEllipseActive || isLineActive;

  return (
    <Container>
      <ToolbarMenuOption
        icon={<RectangleIcon />}
        label="Rectangle"
        shortcut="U"
        isSpaceForCheckmark={isUseCheckmarks && isAnyActive}
        active={isRectangleActive}
        onClick={() => {
          setTool(WorkbenchStudioToolType.Rectangle);
          onSelect?.();
        }}
      />
      <ToolbarMenuOption
        icon={<EllipseIcon />}
        label="Ellipse"
        shortcut="O"
        isSpaceForCheckmark={isUseCheckmarks && isAnyActive}
        active={isEllipseActive}
        onClick={() => {
          setTool(WorkbenchStudioToolType.Ellipse);
          onSelect?.();
        }}
      />
      <ToolbarMenuOption
        icon={<LineIcon />}
        label="Line"
        shortcut="L"
        isSpaceForCheckmark={isUseCheckmarks && isAnyActive}
        active={isLineActive}
        onClick={() => {
          setTool(WorkbenchStudioToolType.Line);
          onSelect?.();
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

import {
  Dropdown,
  LockIcon,
  WorldIcon,
  Surface,
  Text,
} from '@vizcom/shared-ui-components';

const OPTIONS = [
  {
    data: {
      label: 'All Workspace',
      value: 'public',
      icon: <WorldIcon $color="primary" />,
      description:
        'This team is visible to all members of the workspace. Anyone can search for and join without an invitation.',
    },
  },
  {
    data: {
      label: 'Invite Only',
      value: 'private',
      icon: <LockIcon $color="primary" />,
      description:
        'This team is hidden from general view. Joining is restricted to Admins and users who receive a direct invitation.',
    },
  },
];

export const TeamVisibilityPicker = (props: {
  publicInOrganization: boolean;
  setPublicInOrganization: (value: boolean) => void;
  disabled?: boolean;
}) => {
  const { publicInOrganization, setPublicInOrganization, disabled } = props;

  if (disabled) {
    return (
      <Surface color="e0">
        <TeamVisibilityPickerOption
          option={publicInOrganization ? OPTIONS[0].data : OPTIONS[1].data}
        />
      </Surface>
    );
  }

  return (
    <Dropdown
      options={OPTIONS}
      value={publicInOrganization ? 'public' : 'private'}
      setValue={(option) => setPublicInOrganization(option === 'public')}
      OptionComponent={TeamVisibilityPickerOption}
      optionToValueMapper={(option) => option.value}
      buttonProps={{
        style: {
          width: '100%',
        },
      }}
    >
      <TeamVisibilityPickerOption
        option={publicInOrganization ? OPTIONS[0].data : OPTIONS[1].data}
      />
    </Dropdown>
  );
};

const TeamVisibilityPickerOption = (props: {
  option: typeof OPTIONS[0]['data'];
}) => {
  return (
    <div style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
      {props.option.icon}
      <div style={{ textAlign: 'left' }}>
        <Text type="sh2" block style={{ marginBottom: 8 }}>
          {props.option.label}
        </Text>
        <Text color="subtext" block>
          {props.option.description}
        </Text>
      </div>
    </div>
  );
};

import { useTheme } from 'styled-components';
import { ImportImageIcon } from '@vizcom/shared-ui-components';

import { ClientSideWorkbenchElementPalette } from '../../../lib/clientState';
import { useWorkbenchSyncedState } from '../../../lib/useWorkbenchSyncedState';
import {
  InitialWorkbenchElement,
  InitialWorkbenchElementText,
  InitialWorkbenchElementButton,
  InitialWorkbenchElementButtonContainer,
} from '../style';
import { handleAddImagesToPalette } from './helpers';

export const PaletteUploadButton = ({
  paletteData,
  handleAction,
}: {
  paletteData: ClientSideWorkbenchElementPalette;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
}) => {
  const theme = useTheme();
  return (
    <InitialWorkbenchElement>
      <InitialWorkbenchElementButtonContainer>
        <InitialWorkbenchElementButton
          variant="bare"
          size="M"
          onPointerDown={(e) => {
            e.stopPropagation();
            handleAddImagesToPalette(
              paletteData.id,
              paletteData.sourceImages.nodes.length,
              handleAction
            );
          }}
          style={{
            backgroundColor: theme.button.palette,
          }}
        >
          <ImportImageIcon style={{ width: '22px', height: '22px' }} />
          Upload
        </InitialWorkbenchElementButton>
      </InitialWorkbenchElementButtonContainer>
      <InitialWorkbenchElementText type="b3" color="subtext">
        or drag in photos to get started
      </InitialWorkbenchElementText>
    </InitialWorkbenchElement>
  );
};

import { UserPaletteDataFragment } from 'libs/shared/data-access/graphql/src/gql/graphql';
import { Fragment, ReactNode, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import {
  useLayerCountByDrawingIds,
  useUserPalette,
} from '@vizcom/shared/data-access/graphql';
import { filterExists } from '@vizcom/shared/js-utils';
import {
  Button,
  LoadingLogoInset,
  Modal,
  Text,
  useSelectedOrganization,
} from '@vizcom/shared-ui-components';

import {
  ClientSideWorkbenchElementData,
  ClientSideWorkbenchElementPalette,
} from '../../lib/clientState';
import { elementIsPalette } from '../../lib/utils';

type ModalContent = {
  title: string;
  content: ReactNode;
} | null;

type ModalState = {
  displayed: boolean;
  elements?: ClientSideWorkbenchElementData[];
  resolve?: (value?: unknown) => void;
  reject?: (reason?: any) => void;
};

let _triggerDeleteElementsModal:
  | null
  | ((
      deletedElementIds: string[],
      resolve: (value?: unknown) => void,
      reject: (reason?: any) => void
    ) => void) = null;
export const triggerDeleteElementsModalImpl = (
  deletedElementIds: string[],
  resolve: (value?: unknown) => void,
  reject: (reason?: any) => void
) => {
  _triggerDeleteElementsModal?.(deletedElementIds, resolve, reject);
};

export const triggerDeleteElementsModal = (deletedElementIds: string[]) => {
  return new Promise((resolve, reject) => {
    triggerDeleteElementsModalImpl(deletedElementIds, resolve, reject);
  });
};

export const DeleteElementsModal = ({
  elements,
  handleUndo,
}: {
  elements: ClientSideWorkbenchElementData[];
  handleUndo: () => void;
}) => {
  const [modal, setModal] = useState<ModalState>({
    displayed: false,
  });
  const theme = useTheme();

  const drawings =
    modal.elements?.filter((el) => el.__typename === 'Drawing') || [];
  const drawingIds = drawings.map((d) => d.id);
  const { data: layerData, fetching } = useLayerCountByDrawingIds(drawingIds);
  const { data: selectedOrganization } = useSelectedOrganization();
  const { data: userPalettes } = useUserPalette(selectedOrganization?.id);

  useEffect(() => {
    _triggerDeleteElementsModal = (deletedElementIds, resolve, reject) => {
      const deletedElements = elements.filter((el) =>
        deletedElementIds.includes(el.id)
      );
      setModal({
        displayed: true,
        elements: deletedElements,
        resolve,
        reject,
      });
    };
    return () => {
      _triggerDeleteElementsModal = null;
    };
  }, [elements]);

  const handleSetIsOpen = (open: boolean) => {
    if (!open) {
      modal.reject?.();
      setModal({ displayed: false });
    }
  };

  const confirmDelete = () => {
    modal.resolve?.();
    setModal({ displayed: false });
    toast(
      (t) => (
        <div
          style={{
            display: 'flex',
            gap: '16px',
            alignItems: 'center',
          }}
        >
          <div>Selection deleted</div>
          <Button
            variant="secondary"
            onClick={() => {
              handleUndo();
              toast.dismiss(t.id);
            }}
          >
            Undo
          </Button>
        </div>
      ),
      {
        duration: 5000,
        style: {
          backgroundColor: theme.surface.primary,
          color: theme.deprecated.white,
          borderRadius: theme.borderRadius.l,
          padding: '16px',
          height: '52px',
          margin: 0,
        },
      }
    );
  };

  if (fetching) {
    return (
      <Modal
        style={{
          backgroundColor: theme.surface.primary,
          padding: '16px',
          minWidth: '320px',
          width: '320px',
        }}
        isOpen={modal.displayed}
        setIsOpen={handleSetIsOpen}
      >
        <Container>
          <LoadingLogoInset />
        </Container>
      </Modal>
    );
  }

  if (
    (!layerData && drawings?.length) ||
    (!userPalettes && modal.elements?.some(elementIsPalette))
  ) {
    return (
      <Modal
        style={{
          backgroundColor: theme.surface.primary,
          padding: '16px',
          minWidth: '320px',
          width: '320px',
        }}
        isOpen={modal.displayed}
        setIsOpen={handleSetIsOpen}
      >
        <Container>
          <Title>
            There was a problem with your request, please try again.
          </Title>
          <Buttons>
            <Button
              type="button"
              variant="secondary"
              onClick={modal.reject}
              data-close-modal
            >
              Close
            </Button>
          </Buttons>
        </Container>
      </Modal>
    );
  }

  const modalContent = getModalContentByElementTypes(
    modal.elements ?? [],
    userPalettes,
    layerData
  );

  if (!modalContent) {
    modal.resolve?.();
    return null;
  }

  return (
    <Modal
      style={{
        backgroundColor: theme.surface.primary,
        padding: '16px',
        minWidth: '320px',
        width: '320px',
      }}
      isOpen={modal.displayed}
      setIsOpen={handleSetIsOpen}
      initialFocus
    >
      <Container>
        <Title>{modalContent.title}</Title>
        <Content>{modalContent.content}</Content>
        <Buttons>
          <Button
            type="button"
            variant="secondary"
            onClick={modal.reject}
            data-close-modal
          >
            Cancel
          </Button>
          <Button
            type="button"
            variant="danger"
            onClick={confirmDelete}
            data-close-modal
            data-autofocus="true"
          >
            Delete
          </Button>
        </Buttons>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  background-color: ${(p) => p.theme.surface.primary};
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Title = styled.div`
  color: ${(p) => p.theme.text.body};
  font-weight: 600;
`;

const Content = styled.div`
  color: ${(p) => p.theme.text.subtext};
`;

const Buttons = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 8px;
`;

const getDrawingContent = (count: number, layerData: any) => {
  const layerCount =
    layerData?.reduce(
      (acc: number, d: any) => acc + (d.layers.totalCount || 0),
      0
    ) || count;
  return (
    <>
      {count} image block{count > 1 ? 's' : ''} and {layerCount} individual
      layer{layerCount > 1 ? 's' : ''}
    </>
  );
};

const getCompositeSceneContent = (count: number) => {
  return (
    <>
      {count} composite scene{count > 1 ? 's' : ''}
    </>
  );
};

const getWorkbenchElementPaletteContent = (
  userPalettes: UserPaletteDataFragment[],
  palettes: ClientSideWorkbenchElementPalette[]
) => {
  const publishedPalettes = palettes.filter((palette) =>
    userPalettes.some(
      (userPalette) => userPalette.sourcePaletteId === palette.id
    )
  );
  const trainedPalettes = palettes.filter(
    (palette) =>
      palette.status === 'ready' && !publishedPalettes.includes(palette)
  );
  const trainingPalettes = palettes.filter(
    (palette) => palette.status === 'training'
  );
  const unTrainedWithSourcePalettes = palettes.filter(
    (palette) =>
      palette.status === 'idle' && palette.sourceImages.nodes.length > 0
  );

  const publishedCount = publishedPalettes.length;
  const trainedCount = trainedPalettes.length;
  const trainingCount = trainingPalettes.length;
  const untrainedCount = unTrainedWithSourcePalettes.length;

  const getPluralizedText = (count: number, singular: string) =>
    `${count} ${singular}${count === 1 ? '' : 's'}`;

  const publishedText = getPluralizedText(publishedCount, 'published palette');
  const trainedText = getPluralizedText(trainedCount, 'trained palette');
  const trainingText = getPluralizedText(trainingCount, 'training palette');
  const untrainedText = `${untrainedCount} untrained ${
    untrainedCount === 1 ? 'palette' : 'palettes'
  } with source image(s)`;

  const publishedInfoText = publishedCount > 0 && (
    <p>
      <br />
      Deleting the source {publishedCount === 1 ? 'palette' : 'palettes'} will
      not remove {publishedCount === 1 ? 'it' : 'them'} from the library{' '}
      {publishedCount === 1 ? "it's" : "they've"} been published to. Other users
      who saved {publishedCount === 1 ? 'this palette' : 'these palettes'} in
      their personal library will still have access.{' '}
      <LearnMoreLink
        type="sh2"
        as={Link}
        to="https://docs.vizcom.ai/make-your-own-palette"
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn more
      </LearnMoreLink>
    </p>
  );

  const getCombinedText = () => {
    const texts = [];
    if (trainedCount > 0) texts.push(trainedText);
    if (trainingCount > 0) texts.push(trainingText);
    if (untrainedCount > 0) texts.push(untrainedText);
    if (publishedCount > 0) texts.push(publishedText);

    if (texts.length === 1) return texts[0] + '.';

    const lastItem = texts.pop();
    return `${texts.join(', ')}, and ${lastItem}.`;
  };

  if (
    trainedCount === 0 &&
    trainingCount === 0 &&
    untrainedCount === 0 &&
    publishedCount === 0
  ) {
    return null;
  }

  return (
    <>
      {getCombinedText()}
      {publishedInfoText}
    </>
  );
};

const LearnMoreLink = styled(Text)`
  color: ${(p) => p.theme.text.link};
  cursor: pointer;
`;

export const getModalContentByElementTypes = (
  elements: ClientSideWorkbenchElementData[],
  userPalettes: UserPaletteDataFragment[],
  layerData: any
): ModalContent => {
  const elementCounts = elements.reduce((acc, el) => {
    acc[el.__typename] = (acc[el.__typename] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  const title = 'Are you sure you want to delete this selection?';

  const contentItems = Object.entries(elementCounts)
    .map(([type, count]) => {
      switch (type) {
        case 'Drawing':
          return getDrawingContent(count, layerData);
        case 'CompositeScene':
          return getCompositeSceneContent(count);
        case 'WorkbenchElementPalette':
          return getWorkbenchElementPaletteContent(
            userPalettes,
            elements.filter(elementIsPalette)
          );
        default:
          return null;
      }
    })
    .filter(filterExists);

  if (contentItems.length === 0) {
    return null;
  }

  const content = (
    <p>
      This selection includes{' '}
      {contentItems.map((item, index) => (
        <Fragment key={index}>
          {index > 0 && index === contentItems.length - 1 && ' and '}
          {item}
          {index < contentItems.length - 1 &&
            index !== contentItems.length - 2 &&
            ', '}
        </Fragment>
      ))}
    </p>
  );

  return { title, content };
};

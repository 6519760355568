import { Fragment } from 'react';
import styled from 'styled-components';
import {
  ChevronDownDoubleIcon,
  Divider,
  ToolbarMenuButton,
} from '@vizcom/shared-ui-components';

import { IconWithWidth } from './WorkbenchStudioToolbar';

type OverflowMenuProps = {
  overflowItems: IconWithWidth[];
  isMenuOpen: boolean;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const OverflowMenu = ({
  overflowItems,
  isMenuOpen,
  setIsMenuOpen,
}: OverflowMenuProps) => {
  return (
    <ToolbarMenuButton
      icon={<ChevronDownDoubleIcon />}
      tooltip="More tools"
      menu={
        <Container>
          {overflowItems.map((item, i, arr) =>
            item.toolbarOptions ? (
              <Fragment key={i}>
                <Section>{item.toolbarOptions}</Section>
                {i !== arr.length - 1 && <Divider key={`divider-${i}`} />}
              </Fragment>
            ) : null
          )}
        </Container>
      }
      controlledMenuState={[isMenuOpen, setIsMenuOpen]}
    />
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 200px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

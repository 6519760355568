import {
  DragEventHandler,
  PropsWithChildren,
  useCallback,
  useState,
} from 'react';

import { browseForFile, browseForFiles } from './helpers';
import { useLastValue } from './hooks/useLastValue';

interface CommonProps extends React.HTMLAttributes<HTMLDivElement> {
  browseForFileOnClick?: boolean;
}

interface SingleFileDropperProps extends CommonProps {
  onFileSelected: (file: File) => void;
  multiple?: false;
}

interface MultipleFileDropperProps extends CommonProps {
  onFileSelected: (files: File[]) => void;
  multiple: true;
}

type FileDropperProps = (SingleFileDropperProps | MultipleFileDropperProps) & {
  overlay?: React.ReactNode;
};

export const FileDropper = (
  props: PropsWithChildren<
    React.HTMLAttributes<HTMLDivElement> & FileDropperProps
  >
) => {
  const { browseForFileOnClick, onFileSelected, multiple, ...rest } = props;
  const lastOnFileSelected = useLastValue(props.onFileSelected);
  const [isDragging, setIsDragging] = useState(false);

  const handleDragEnter = useCallback<DragEventHandler<HTMLDivElement>>((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);
  const handleDragOver = useCallback<DragEventHandler<HTMLDivElement>>((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);
  const handleDrop = useCallback<DragEventHandler<HTMLDivElement>>(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      const files = e.dataTransfer?.files;

      if (!files || files.length === 0) return;

      if ('multiple' in props && props.multiple) {
        (lastOnFileSelected.current as (files: File[]) => void)(
          Array.from(files)
        );
      } else {
        (lastOnFileSelected.current as (file: File) => void)(files[0]);
      }

      setIsDragging(false);
    },
    [lastOnFileSelected]
  );
  const handleClick = async () => {
    if (props.browseForFileOnClick) {
      if ('multiple' in props && props.multiple) {
        const files = await browseForFiles();
        (lastOnFileSelected.current as (files: File[]) => void)(files);
      } else {
        const file = await browseForFile();
        (lastOnFileSelected.current as (file: File) => void)(file);
      }
    }
  };

  return (
    <div
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={() => setIsDragging(false)}
      onDragExit={() => setIsDragging(false)}
      onDrop={handleDrop}
      onClick={handleClick}
      {...rest}
    >
      {isDragging ? props.overlay : null}
      {(!isDragging || !props.overlay) && props.children}
    </div>
  );
};

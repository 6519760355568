import {
  CMD_KEY_PREFIX,
  ToolbarButton,
  ToolbarButtonState,
  RedoIcon,
  SHIFT_KEY_PREFIX,
} from '@vizcom/shared-ui-components';

import { Hotkey } from './studio/style';

export const RedoButton = ({
  canRedo,
  handleRedo,
}: {
  canRedo: boolean;
  handleRedo: () => void;
}) => {
  return (
    <ToolbarButton
      icon={<RedoIcon style={{ color: 'inherit' }} />}
      tooltip={
        canRedo ? (
          <>
            Redo{' '}
            <Hotkey>
              {CMD_KEY_PREFIX}
              {SHIFT_KEY_PREFIX}Z
            </Hotkey>
          </>
        ) : (
          'Nothing to redo'
        )
      }
      state={
        canRedo ? ToolbarButtonState.INACTIVE : ToolbarButtonState.DISABLED
      }
      onClick={handleRedo}
    />
  );
};

import { useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import {
  useUpdateFolder,
  useUpdateWorkbench,
} from '@vizcom/shared/data-access/graphql';
import {
  Button,
  IS_IPAD,
  Modal,
  ModalActions,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalTitle,
  TextInput,
} from '@vizcom/shared-ui-components';

type RenameModalInputs = {
  name: string;
};

type RenameModalProps = {
  isOpen: boolean;
  setIsOpen: (val: boolean) => any;
  previousName: string;
} & ({ workbenchId: string } | { folderId: string });

export const RenameModal = (props: RenameModalProps) => {
  const [res, updateWorkbench] = useUpdateWorkbench();
  const [resFolder, updateFolder] = useUpdateFolder();

  const { register, handleSubmit, reset } = useForm<RenameModalInputs>({
    values: {
      name: props.previousName ?? '',
    },
  });

  useEffect(() => {
    if (props.isOpen) {
      reset();
    }
  }, [props.isOpen, reset]);

  const loading = res.fetching || resFolder.fetching;

  const handleCancel = () => {
    props.setIsOpen(false);
  };
  const onSubmit: SubmitHandler<RenameModalInputs> = async ({ name }, e) => {
    e?.preventDefault();
    e?.stopPropagation();
    if (!name) {
      return;
    }
    if ('workbenchId' in props) {
      await updateWorkbench({
        id: props.workbenchId,
        patch: {
          name,
        },
      });
    } else if ('folderId' in props) {
      await updateFolder({
        input: {
          id: props.folderId,
          patch: {
            name,
          },
        },
      });
    }

    props.setIsOpen(false);
  };

  return (
    <Modal
      style={{ minWidth: 400 }}
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      placement={IS_IPAD ? 'flex-start' : 'center'}
    >
      <ModalHeader>
        <ModalTitle>
          Rename {'folderId' in props ? 'folder' : 'file'}
        </ModalTitle>
        <ModalCloseButton />
      </ModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          <TextInput
            required
            type="text"
            autoFocus
            $background="secondary"
            {...register('name')}
          />
        </ModalContent>
        <ModalActions>
          <Button variant="secondary" type="button" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" disabled={loading}>
            Rename
          </Button>
        </ModalActions>
      </form>
    </Modal>
  );
};

import React from 'react';
import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import {
  OrganizationSubscriptionPlan,
  useCurrentUserClientStateByKey,
  UserClientStateKeys,
  useSetCurrentUserClientState,
  useUserPalette,
} from '@vizcom/shared/data-access/graphql';
import {
  RichTooltip,
  RichTooltipTrigger,
  OnboardingTourTooltipContent,
  AutoplayVideo,
  Button,
  Text,
  useSelectedOrganization,
} from '@vizcom/shared-ui-components';

import publishPaletteOnboardingVideo from '../../../assets/videos/publish_palette.mp4';
import { ClientSideWorkbenchElementData } from '../../../lib/clientState';
import { CustomHtml } from '../../utils/CustomHtml';
import {
  StepContainer,
  LeftSection,
  RightSection,
} from '../../utils/OnboardingMultiStep';
import { PALETTE_NAME_HEIGHT } from './PaletteName';

export const PublishPaletteOnboardingTooltip = ({
  elements,
}: {
  elements: ClientSideWorkbenchElementData[];
}) => {
  const { borderRadius } = useTheme();
  const onboardingStatus =
    useCurrentUserClientStateByKey(UserClientStateKeys.OnboardingStatus) || {};
  const shouldDisplayOnboardingTooltip = !onboardingStatus['publish_palette'];
  const [, updateState] = useSetCurrentUserClientState();

  const { data: selectedOrganization } = useSelectedOrganization();
  const isEnterpriseOrganization =
    selectedOrganization?.subscriptionPlan ===
    OrganizationSubscriptionPlan.Enterprise;
  const { data: palettes } = useUserPalette(selectedOrganization?.id);

  // Find the first ready palette that is not published in the current organization
  const nonPublishedPalette =
    isEnterpriseOrganization &&
    elements.find(
      (el) =>
        el.__typename === 'WorkbenchElementPalette' &&
        el.status === 'ready' &&
        !palettes?.find((p) => p.sourcePaletteId === el.id)
    );

  if (
    !shouldDisplayOnboardingTooltip ||
    !nonPublishedPalette ||
    nonPublishedPalette.__typename !== 'WorkbenchElementPalette'
  ) {
    return null;
  }

  return (
    <CustomHtml
      position={[
        nonPublishedPalette.x + nonPublishedPalette.width / 2 - 50,
        nonPublishedPalette.y +
          nonPublishedPalette.height / 2 +
          PALETTE_NAME_HEIGHT / 2,
        nonPublishedPalette.zIndex,
      ]}
      transform
    >
      <RichTooltip isOpen={true} placement="right" padding={88}>
        <RichTooltipTrigger>
          <EmptyTrigger>.</EmptyTrigger>
        </RichTooltipTrigger>
        <OnboardingTourTooltipContent>
          <StepContainer>
            <LeftSection>
              <AutoplayVideo
                src={publishPaletteOnboardingVideo}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: `calc(${borderRadius.l} - 2px) 0 0 calc(${borderRadius.l} - 2px)`,
                }}
              />
            </LeftSection>
            <RightSection>
              <Text type="sh2">Publish Palette</Text>
              <Text type="b1">
                Access your Palette across a workspace files by publishing it to
                your personal library.
              </Text>
              <Footer>
                <Button
                  variant="secondary"
                  size="M"
                  as={Link}
                  to="https://docs.vizcom.ai/make-your-own-palette"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn More
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    updateState({
                      input: {
                        key: UserClientStateKeys.OnboardingStatus,
                        value: {
                          ...onboardingStatus,
                          publish_palette: true,
                        },
                      },
                    });
                  }}
                >
                  Got it
                </Button>
              </Footer>
            </RightSection>
          </StepContainer>
        </OnboardingTourTooltipContent>
      </RichTooltip>
    </CustomHtml>
  );
};

const EmptyTrigger = styled.div`
  opacity: 0;
  height: 14px;
  width: 70px;
  margin-top: -6px;
  margin-right: -20px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 4px;
`;

import { useThree } from '@react-three/fiber';
import { useEffect } from 'react';
import { OrthographicCamera } from 'three';
import { filterExists } from '@vizcom/shared/js-utils';
import {
  addToast,
  dismissToast,
  useLastValue,
} from '@vizcom/shared-ui-components';

import { ClientSideWorkbenchElementData } from '../../lib/clientState';
import { getElementsBoundingBox } from '../helpers';
import {
  getCameraBoundingBox,
  useMapControls,
} from '../utils/mapControls/utils';
import { useNewlyCreatedElements } from './newlyCreatedElementsState';

export const NewlyCreatedElementsToastIndicator = ({
  elements,
}: {
  elements: ClientSideWorkbenchElementData[];
}) => {
  const camera = useThree((s) => s.camera as OrthographicCamera);
  const mapControls = useMapControls();

  const elementsRef = useLastValue(elements);

  useEffect(() => {
    let toastId = undefined as undefined | string;

    const interval = setInterval(() => {
      // check every 100ms if there are any newly created elements, and if those are outside of the camera view, show a toast

      const cameraBoundingBox = getCameraBoundingBox(camera);
      const elements = Object.keys(useNewlyCreatedElements.getState().elements)
        .map((id) => elementsRef.current.find((e) => e.id === id))
        .filter(filterExists);

      const elementsOutsideViewport = elements.filter(
        (el) =>
          el.x < cameraBoundingBox.left ||
          el.x > cameraBoundingBox.right ||
          el.y < cameraBoundingBox.bottom ||
          el.y > cameraBoundingBox.top
      );

      if (elementsOutsideViewport.length) {
        toastId = addToast(
          `${elementsOutsideViewport.length} new image${
            elementsOutsideViewport.length > 1 ? 's' : ''
          } created outside of the viewport`,
          {
            id: toastId,
            cta: {
              text: 'Jump to',
              action: () => {
                const boundingBox = getElementsBoundingBox(
                  elementsOutsideViewport
                );
                const zoomForWidth =
                  (camera.right - camera.left) / boundingBox.width;
                const zoomForHeight =
                  (camera.top - camera.bottom) / boundingBox.height;
                const zoom = Math.min(zoomForWidth, zoomForHeight) * 0.8;

                mapControls.moveTo({
                  x: boundingBox.x,
                  y: boundingBox.y,
                  zoom,
                });
                useNewlyCreatedElements.setState(() => ({ elements: {} }));
              },
            },
          }
        );
      } else if (toastId) {
        dismissToast(toastId);
        toastId = undefined;
      }
    }, 100);

    return () => {
      clearInterval(interval);
      if (toastId) {
        dismissToast(toastId);
      }
    };
  }, [camera, mapControls, elementsRef]);

  return null;
};

import { Line } from '@react-three/drei';
import { useMemo } from 'react';
import { EllipseCurve, Vector2 } from 'three';

export const DashedRoundedRectangle = ({
  width,
  height,
  radius,
  color,
  gapSize = 0.5,
  dashScale = 0.08,
  dashSize = 3,
  lineWidth = 2,
  cornerSegments = 8,
}: {
  width: number;
  height: number;
  radius: number;
  color: string;
  gapSize?: number;
  dashScale?: number;
  dashSize?: number;
  lineWidth?: number;
  cornerSegments?: number;
}) => {
  const points = useMemo(() => {
    const x = -width / 2;
    const y = -height / 2;

    const createCornerPoints = (
      centerX: number,
      centerY: number,
      startAngle: number,
      endAngle: number
    ) => {
      const curve = new EllipseCurve(
        centerX,
        centerY,
        radius,
        radius,
        startAngle,
        endAngle,
        false,
        0
      );
      return curve.getPoints(cornerSegments);
    };

    const solidPoints = [
      // top left corner
      createCornerPoints(x + radius, y + height - radius, Math.PI / 2, Math.PI),
      // top right corner
      createCornerPoints(
        x + width - radius,
        y + height - radius,
        0,
        Math.PI / 2
      ),
      // bottom right corner
      createCornerPoints(x + width - radius, y + radius, (Math.PI * 3) / 2, 0),
      // bottom left corner
      createCornerPoints(x + radius, y + radius, Math.PI, (Math.PI * 3) / 2),
    ];

    const dashedPoints = [
      // top
      [
        new Vector2(x + radius, y + height),
        new Vector2(x + width / 2, y + height),
      ],
      [
        new Vector2(x + width - radius, y + height),
        new Vector2(x + width / 2, y + height),
      ],

      // right
      [
        new Vector2(x + width, y + height - radius),
        new Vector2(x + width, y + height / 2),
      ],
      [
        new Vector2(x + width, y + radius),
        new Vector2(x + width, y + height / 2),
      ],

      // bottom
      [new Vector2(x + width - radius, y), new Vector2(x + width / 2, y)],
      [new Vector2(x + radius, y), new Vector2(x + width / 2, y)],

      // left
      [new Vector2(x, y + radius), new Vector2(x, y + height / 2)],
      [new Vector2(x, y + height - radius), new Vector2(x, y + height / 2)],
    ];

    return {
      solidPoints,
      dashedPoints,
    };
  }, [width, height, radius, cornerSegments]);

  return (
    <>
      {points.solidPoints.map((point, i) => (
        <Line
          key={i}
          points={point}
          color={color}
          lineWidth={lineWidth}
          transparent
        />
      ))}
      {points.dashedPoints.map((point, i) => (
        <Line
          key={i}
          points={point}
          color={color}
          lineWidth={lineWidth}
          dashed
          dashScale={dashScale}
          dashSize={dashSize}
          gapSize={gapSize}
          transparent
        />
      ))}
    </>
  );
};

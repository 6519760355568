import {
  DndContext,
  useSensor,
  useSensors,
  PointerSensor,
} from '@dnd-kit/core';
import React, {
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useState,
} from 'react';
import {
  useCurrentUserClientStateByKey,
  UserClientStateKeys,
  ImageInferenceType,
} from '@vizcom/shared/data-access/graphql';
import { ErrorBoundary } from '@vizcom/shared-ui-components';

import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../../../lib/useDrawingSyncedState';
import { useLayersCompositor } from '../../LayersCompositor/context';
import { useWorkbenchStudioState } from '../../studioState';
import { useRealtimeEngine } from '../hooks/useRealtimeEngine';
import { useRealtimeHistory } from '../hooks/useRealtimeHistory';
import { useVideoExport } from '../hooks/useVideoExport';
import { useWindowControls } from '../hooks/useWindowControls';
import { ResourceManager } from '../performance';
import { compareCanvasStates } from '../utils/canvasUtils';
import { RealtimeWindowContentProps } from './RealtimeWindowContent';

interface RealtimeWindowContainerProps {
  prompt: string;
  drawing: Drawing2dStudio;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  setActiveLayerId: (id: string | undefined) => void;
  paletteInfluence: number;
  onPaletteInfluenceChange: (value: number) => void;
  sourceImageInfluence: number;
  onSourceImageInfluenceChange: (value: number) => void;
  mode: 'FAST' | 'MEDIUM' | 'SLOW';
  onModeChange: (mode: 'FAST' | 'MEDIUM' | 'SLOW') => void;
  children: (props: RealtimeWindowContentProps) => React.ReactNode;
}

export const RealtimeWindowContainer: React.FC<
  RealtimeWindowContainerProps
> = ({
  prompt,
  drawing,
  handleAction,
  setActiveLayerId,
  paletteInfluence,
  onPaletteInfluenceChange,
  sourceImageInfluence,
  onSourceImageInfluenceChange,
  mode,
  onModeChange,
  children,
}) => {
  const resourceManager = useMemo(() => new ResourceManager(), []);
  const containerRef = useRef<HTMLDivElement>(null);
  const layout =
    useCurrentUserClientStateByKey(UserClientStateKeys.StudioLayout) ||
    'default';
  const [windowPosition, setWindowPosition] = useState({
    x: 14,
    y: window.innerHeight - 450,
  });

  const {
    position,
    size,
    isLocked,
    lock,
    unlockAtCurrentPosition,
    handleDragEnd,
  } = useWindowControls({
    initialLocked: true,
    initialPosition: windowPosition,
    initialSize: { width: 250, height: 200 },
  });

  const unlock = useCallback(() => {
    containerRef.current && unlockAtCurrentPosition(containerRef.current);
  }, [unlockAtCurrentPosition]);

  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 8 } })
  );

  const {
    realtimePreviewSeed,
    realtimePreviewSeedLocked,
    setRealtimePreviewSeed,
  } = useWorkbenchStudioState((state) => ({
    realtimePreviewSeed: state.realtimePreviewSeed,
    realtimePreviewSeedLocked: state.realtimePreviewSeedLocked,
    setRealtimePreviewSeed: state.setRealtimePreviewSeed,
  }));

  const layersCompositor = useLayersCompositor();
  const influenceRef = useRef(sourceImageInfluence);
  const paletteInfluenceRef = useRef(paletteInfluence);

  // Update refs whenever influence props change
  useEffect(() => {
    influenceRef.current = sourceImageInfluence;
  }, [sourceImageInfluence]);

  useEffect(() => {
    paletteInfluenceRef.current = paletteInfluence;
  }, [paletteInfluence]);

  const { makeInferenceCall } = useRealtimeEngine(resourceManager, mode);
  const {
    history: realtimeHistory,
    historyIndex: realtimeHistoryIndex,
    addHistoryEntry,
    navigateHistory,
    clearHistory,
    getCurrentEntry,
  } = useRealtimeHistory(resourceManager);
  const { createVideoFromHistory, isExporting: videoExportIsExporting } =
    useVideoExport(resourceManager);

  useEffect(() => {
    let isActive = true;
    let animationFrameId: number | null = null;
    let lastCanvasState: ImageData | null = null;
    let lastInfluence = influenceRef.current;
    let lastPaletteInfluence = paletteInfluenceRef.current;

    const update = () => {
      if (!isActive) return;

      const compositeImage = layersCompositor.getCompositedImage();
      if (!compositeImage) {
        animationFrameId = requestAnimationFrame(update);
        return;
      }

      // Compare canvas states and influence to see if we need to make a new inference call
      const hasCanvasChanged =
        !lastCanvasState ||
        !compareCanvasStates(lastCanvasState, compositeImage);

      const hasInfluenceChanged =
        lastInfluence !== influenceRef.current ||
        lastPaletteInfluence !== paletteInfluenceRef.current;

      if (hasCanvasChanged || hasInfluenceChanged) {
        lastCanvasState = compositeImage;
        lastInfluence = influenceRef.current;
        lastPaletteInfluence = paletteInfluenceRef.current;
        // Fire the inference call without awaiting
        makeInferenceCall(compositeImage, {
          prompt: prompt || '',
          influence: influenceRef.current,
          seed: realtimePreviewSeed,
          width: compositeImage.width,
          height: compositeImage.height,
          paletteInfluence: paletteInfluenceRef.current,
          inferenceType: ImageInferenceType.Realtime,
        })
          .then((result) => {
            if (result && isActive) {
              addHistoryEntry(result, realtimePreviewSeed);
            }
          })
          .catch((error) => {
            console.error('Inference error:', error);
            // If we hit an error, we'll let the engine's error handling take care of it
            // The engine will automatically pause requests if too many errors occur
          });
      }

      // Schedule next update
      if (isActive) {
        animationFrameId = requestAnimationFrame(update);
      }
    };

    update();
    return () => {
      isActive = false;
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [
    makeInferenceCall,
    prompt,
    realtimePreviewSeed,
    layersCompositor,
    addHistoryEntry,
  ]);

  useEffect(() => {
    return () => resourceManager.dispose();
  }, [resourceManager]);

  return (
    <ErrorBoundary>
      <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
        {children({
          ref: containerRef,
          position,
          size,
          isLocked,
          onLockChange: (locked: boolean) => (locked ? lock() : unlock()),
          realtimeHistory,
          realtimeHistoryIndex,
          videoExportIsExporting,
          handleInferenceUpdate: async () => {
            if (!realtimePreviewSeedLocked) {
              setRealtimePreviewSeed(Math.floor(Math.random() * 1000000));
            }
            const compositeImage = layersCompositor.getCompositedImage();
            if (!compositeImage) return;

            const result = await makeInferenceCall(compositeImage, {
              prompt: prompt || '',
              influence: influenceRef.current,
              seed: realtimePreviewSeed,
              width: compositeImage.width,
              height: compositeImage.height,
              paletteInfluence: paletteInfluenceRef.current,
              inferenceType: ImageInferenceType.Realtime,
            });

            if (result) {
              addHistoryEntry(result, realtimePreviewSeed);
            }
          },
          navigateHistory,
          clearHistory,
          getCurrentEntry,
          createVideoFromHistory: (
            width: number,
            height: number,
            fps?: number
          ) => createVideoFromHistory(realtimeHistory, width, height, fps),

          drawing,
          handleAction,
          setActiveLayerId,
          layout,
          paletteInfluence,
          onPaletteInfluenceChange,
          sourceImageInfluence,
          onSourceImageInfluenceChange,
          mode,
          onModeChange,
        })}
      </DndContext>
    </ErrorBoundary>
  );
};

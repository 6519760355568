import {
  BoldIcon,
  StrikethroughIcon,
  ToolbarButton,
  ToolbarButtonState,
  UnderlineIcon,
} from '@vizcom/shared-ui-components';

type WorkbenchElementTextDecorationProps = {
  textDecoration: string;
  fontWeight: number;
  setIsBold: (isBold: boolean) => void;
  handleSetTextDecoration: (
    textDecoration: 'underline' | 'line-through' | 'none'
  ) => void;
};

export const WorkbenchElementTextDecoration = ({
  textDecoration,
  fontWeight,
  setIsBold,
  handleSetTextDecoration,
}: WorkbenchElementTextDecorationProps) => {
  return (
    <>
      <ToolbarButton
        state={
          fontWeight === 600
            ? ToolbarButtonState.BACKGROUND
            : ToolbarButtonState.INACTIVE
        }
        icon={<BoldIcon />}
        tooltip="Bold"
        onClick={() => setIsBold(fontWeight !== 600)}
      />
      <ToolbarButton
        state={
          textDecoration === 'underline'
            ? ToolbarButtonState.BACKGROUND
            : ToolbarButtonState.INACTIVE
        }
        icon={<UnderlineIcon />}
        tooltip="Underline"
        onClick={() =>
          handleSetTextDecoration(
            textDecoration === 'underline' ? 'none' : 'underline'
          )
        }
      />
      <ToolbarButton
        state={
          textDecoration === 'line-through'
            ? ToolbarButtonState.BACKGROUND
            : ToolbarButtonState.INACTIVE
        }
        icon={<StrikethroughIcon />}
        tooltip="Strikethrough"
        onClick={() =>
          handleSetTextDecoration(
            textDecoration === 'line-through' ? 'none' : 'line-through'
          )
        }
      />
    </>
  );
};

import { omit } from 'lodash';
import {
  urqlClient,
  UpdateLayerMutation,
} from '@vizcom/shared/data-access/graphql';
import { LayerMetadata3d } from '@vizcom/shared/js-utils';
import { imageDataToBlob } from '@vizcom/shared-ui-components';

import {
  SyncedActionPayloadFromType,
  SyncedActionType,
} from '../../SyncedAction';
import { Drawing2dStudio } from '../../useDrawingSyncedState';

// when we send a new layer image to the server, it will respond with the URL of the updated image
// then urql will update the layer in the cache with this new URL, which will trigger a request to get this image
// but we already have the image in memory (because we just uploaded it), so we store it here to prevent the request and instead use the blob
export const cachedLayerImagesByUrl = {} as Record<string, Blob>;

export type LayerData = {
  name?: string;
  visible?: boolean;
  opacity?: number;
  blendMode?: string;
  fill?: string;
  image?: ImageData | string | Blob | null;
  metadata3D?: LayerMetadata3d;
  orderKey?: string;
};

export const UpdateLayerAction: SyncedActionType<
  Drawing2dStudio,
  {
    type: 'updateLayer';
    id: string;
    data: LayerData;
    keepCanceledDebounceActionInHistory?: boolean;
  }
> = {
  type: 'updateLayer',
  optimisticUpdater: ({ payload }, drawing) => {
    const existingLayer = drawing.layers.nodes.find((l) => l.id === payload.id);
    if (!existingLayer) {
      return;
    }

    existingLayer.name = payload.data.name ?? existingLayer.name;
    existingLayer.visible = payload.data.visible ?? existingLayer.visible;
    existingLayer.opacity = payload.data.opacity ?? existingLayer.opacity;
    existingLayer.blendMode = payload.data.blendMode ?? existingLayer.blendMode;
    existingLayer.fill = payload.data.fill ?? existingLayer.fill;
    existingLayer.metadata3D =
      payload.data.metadata3D ?? existingLayer.metadata3D;
    existingLayer.orderKey = payload.data.orderKey ?? existingLayer.orderKey;
    existingLayer.imagePath =
      payload.data.image !== undefined // image was passed as input, if it's null it means the layer should revert to an empty layer
        ? payload.data.image ?? undefined
        : existingLayer.imagePath;
  },
  remoteUpdater: async ({ payload }) => {
    const image =
      payload.data.image instanceof ImageData
        ? await imageDataToBlob(payload.data.image)
        : payload.data.image;
    const res = await urqlClient.mutation(UpdateLayerMutation, {
      id: payload.id,
      patch: {
        ...omit(payload.data, 'image'),
        imagePath: image,
      },
    });

    if (res.data?.updateLayer?.layer?.imagePath && image instanceof Blob) {
      cachedLayerImagesByUrl[res.data?.updateLayer?.layer?.imagePath] = image;
    }

    if (res?.error) {
      throw new Error(
        `Error while updating layer, please retry. ${
          res.error.graphQLErrors[0]?.message ?? res.error.message
        }`
      );
    }
  },
  undoConstructor: ({ payload }, drawing) => {
    const oldLayer = drawing.layers.nodes.find((l) => l.id === payload.id) as
      | typeof drawing['layers']['nodes'][0]
      | undefined;
    if (!oldLayer) {
      return;
    }

    const data = (Object.keys(payload.data) as Array<keyof LayerData>).reduce(
      (acc, key) => {
        if (key === 'image') {
          acc.image = oldLayer.imagePath || null;
          return acc;
        }

        acc[key] = oldLayer[key];
        return acc;
      },
      {} as LayerData
    );

    const undoPayload: SyncedActionPayloadFromType<typeof UpdateLayerAction> = {
      type: 'updateLayer',
      id: payload.id,
      data,
    };

    return undoPayload;
  },
  metaConstructor: (payload) => ({
    debounceId: `updateLayer-${payload.id}`,
    keepCanceledDebounceActionInHistory:
      payload.keepCanceledDebounceActionInHistory ?? true,
  }),
  actionMerger: (previousAction, nextAction) => {
    if (previousAction.payload.id !== nextAction.payload.id) {
      throw new Error(
        'Trying to merge two updateLayer actions with different ids'
      );
    }

    return {
      meta: nextAction.meta,
      payload: {
        type: nextAction.payload.type,
        id: nextAction.payload.id,
        data: {
          ...previousAction.payload.data,
          ...nextAction.payload.data,
        },
      },
    };
  },

  compact: {
    // imageData created when drawing on a layer are very heavy, when storing them in the undo queue, this can lead to a lot of memory usage and OOM errors
    // to fix this, we asynchronously compact them to a PNG blob, and replace the payload in the history queue
    canBeCompacted: (payload) => payload.data.image instanceof ImageData,
    async compactPayload(payload) {
      if (!(payload.data.image instanceof ImageData)) {
        throw new Error(
          'Try to compact a updateLayer action that cannot be compacted'
        );
      }
      const compactedImage = await imageDataToBlob(payload.data.image);
      return {
        ...payload,
        data: {
          ...payload.data,
          image: compactedImage,
        },
      };
    },
  },
};

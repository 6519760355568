import { MutableRefObject, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { WorkbenchBasicData } from '@vizcom/shared/data-access/graphql';
import {
  Button,
  ContextMenu,
  ContextMenuItem,
  InlineFlex,
  MenuHorizontalIcon,
  Text,
} from '@vizcom/shared-ui-components';
import { useClickOutside } from '@vizcom/shared-utils-hooks';

import { Thumbnail, Wrapper } from './File.styled';

type FileProps = {
  file: WorkbenchBasicData;
  index: number;
  handleOpenTemplateFile: (id: string, name: string, newTab?: boolean) => void;
};

export const TemplateFile = (props: FileProps) => {
  const theme = useTheme();
  const { file, handleOpenTemplateFile } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const [isActive, setIsActive] = useState(false);

  useClickOutside(containerRef, () => setIsActive(false));

  return (
    <TemplateFileContainer ref={containerRef}>
      <StyledWrapper
        $logoBackground={!file.thumbnail}
        onDoubleClick={() => handleOpenTemplateFile(file.id, file.name)}
        onClick={() => setIsActive(!isActive)}
        $selected={isActive}
      >
        <ThumbnailContainer>
          {file.thumbnail && (
            <Thumbnail crossOrigin="anonymous" src={file.thumbnail} />
          )}
          <ButtonWrapper $isActive={isActive}>
            <Button
              variant="primary"
              size="S"
              onClick={() => handleOpenTemplateFile(file.id, file.name)}
            >
              <Text type="b1" color="body">
                Use template
              </Text>
            </Button>
          </ButtonWrapper>
        </ThumbnailContainer>
        <InlineFlex
          $alignItems="center"
          $justifyContent="space-between"
          style={{
            backgroundColor: theme.surface.secondary,
            paddingInline: `calc(${theme.spacing.m} / 2)`,
            paddingBlock: `calc(${theme.spacing.m} / 2)`,
          }}
        >
          <Text type="b1" color="body">
            {file.name}
          </Text>

          <ContextMenu
            buttonProps={{
              variant: 'bare',
              size: 'icon',
              style: {
                color: theme.icon.secondary,
                zIndex: 3,
              },
            }}
            items={
              <>
                <ContextMenuItem
                  onClick={() => handleOpenTemplateFile(file.id, file.name)}
                >
                  Open
                </ContextMenuItem>
                <ContextMenuItem
                  onClick={() =>
                    handleOpenTemplateFile(file.id, file.name, true)
                  }
                >
                  Open in new tab
                </ContextMenuItem>
              </>
            }
            parentRef={containerRef as MutableRefObject<HTMLElement>}
          >
            <MenuHorizontalIcon />
          </ContextMenu>
        </InlineFlex>
      </StyledWrapper>
    </TemplateFileContainer>
  );
};

const TemplateFileContainer = styled.div`
  background: ${({ theme }) => theme.surface.primary};
`;

const ButtonWrapper = styled.div<{ $isActive: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  opacity: ${({ $isActive }) => ($isActive ? 1 : 0)};
  transition: opacity 0.1s ease;
`;

const ThumbnailContainer = styled.div`
  position: relative;
  border-radius: ${(p) => p.theme.borderRadius.l}
    ${(p) => p.theme.borderRadius.l} 0 0;
  overflow: hidden;
  width: 100%;
  height: 100px;
  display: flex;
  img {
    transition: 0.15s ease transform;
    pointer-events: none;
    object-fit: contain;
  }

  ${Wrapper}:hover & img, .hover & img {
    transform: scale(1.2);
    opacity: 0.2;
  }
`;

const StyledWrapper = styled(Wrapper)`
  &:hover {
    ${ButtonWrapper} {
      opacity: 1;
    }
  }
`;

import { useState } from 'react';
import { useTheme } from 'styled-components';
import {
  ToolbarButtonState,
  ToolbarDivider,
  ToolbarMenuButton,
  SettingsIcon,
  EraserIcon,
  MagicEraserIcon,
} from '@vizcom/shared-ui-components';

import {
  useWorkbenchStudioState,
  WorkbenchStudioToolType,
} from '../../studioState';
import { BrushSettings } from '../Brush/BrushSettings';
import { ToolbarIcon } from '../ToolbarIcon';
import { ToolbarSlider } from '../ToolbarSlider';

interface SecondaryEraserToolbarProps {
  isHideSliders: boolean;
}

export const SecondaryEraserToolbar = ({
  isHideSliders,
}: SecondaryEraserToolbarProps) => {
  const [showSettings, setShowSettings] = useState(false);

  const theme = useTheme();
  const { tool, setTool, getToolSettings } = useWorkbenchStudioState();
  const { toolOpacity, toolSize, setToolOpacity, setToolSize } =
    getToolSettings();

  return (
    <>
      <ToolbarIcon
        $toolState={
          tool === WorkbenchStudioToolType.Eraser
            ? ToolbarButtonState.ACTIVE
            : ToolbarButtonState.INACTIVE
        }
        onClick={() => setTool(WorkbenchStudioToolType.Eraser)}
        icon={<EraserIcon />}
      />
      <ToolbarIcon
        $toolState={
          tool === WorkbenchStudioToolType.MagicEraser
            ? ToolbarButtonState.ACTIVE
            : ToolbarButtonState.INACTIVE
        }
        onClick={() => setTool(WorkbenchStudioToolType.MagicEraser)}
        icon={<MagicEraserIcon />}
      />

      <ToolbarDivider />

      <ToolbarSlider
        isHideSliders={isHideSliders}
        label="Size"
        value={toolSize}
        setValue={setToolSize}
        min={1}
        max={250}
        step={1}
        unit="px"
        isLogScale
      />

      {tool === WorkbenchStudioToolType.Eraser && (
        <ToolbarSlider
          isHideSliders={isHideSliders}
          label="Opacity"
          value={toolOpacity}
          setValue={setToolOpacity}
          min={0}
          max={1}
          step={0.01}
          unit="%"
          multiplier={100}
        />
      )}

      <ToolbarDivider />

      <ToolbarMenuButton
        icon={<SettingsIcon />}
        menuStyle={{
          padding: 8,
          backgroundColor: theme.surface.primary,
          borderRadius: theme.borderRadius.l,
          marginTop: -4,
        }}
        menuOptions={{ manualOpen: true }}
        menu={
          <BrushSettings
            onClose={() => setShowSettings(false)}
            title="Settings"
            disableHardness={tool === WorkbenchStudioToolType.MagicEraser}
            disableOpacity={tool === WorkbenchStudioToolType.MagicEraser}
            disableStabilizer={tool === WorkbenchStudioToolType.MagicEraser}
            disableAspect={tool === WorkbenchStudioToolType.MagicEraser}
          />
        }
        tooltip="Settings"
        disabledWhenInactive
        state={
          showSettings ? ToolbarButtonState.ACTIVE : ToolbarButtonState.INACTIVE
        }
        onClick={() => setShowSettings(!showSettings)}
      />
    </>
  );
};

import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { CombinedError } from 'urql';
import {
  createPrompt,
  usePrompt,
  ImageInferenceType,
  CreatePromptStyleReferenceMode,
  publishTrackingEvent,
} from '@vizcom/shared/data-access/graphql';
import { RateLimitQuotaDetails } from '@vizcom/shared/data-shape';
import {
  PublicPalette,
  promptLegacyTypeToModeAndPalette,
} from '@vizcom/shared/inference-worker-queues';
import { PLANS_LIMITS } from '@vizcom/shared/plans-limit';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import {
  Text,
  imageDataToBlob,
  triggerDirectModal,
} from '@vizcom/shared-ui-components';

import { isEnumValue } from '../../../../../../shared/js-utils/src';
import { Drawing2dStudio } from '../../lib/useDrawingSyncedState';
import { LayersCompositorApi } from './LayersCompositor/context';
import { promptContainsArtistName } from './constants';

export interface InferenceSettings {
  outputsCount: number;
  sourceImageInfluence: number;
  colorCoherence: boolean;

  prompt: string;
  fidelity: number;
  paletteInfluence: number;
  publicPaletteId?: string | null;
  customModelId?: string | null;
  workbenchPaletteId?: string | null;
  userPaletteId?: string | null;
  imageInferenceType: ImageInferenceType;
  negativePrompt: string;
  referenceMode: CreatePromptStyleReferenceMode | 'color';
  mode: 'FAST' | 'MEDIUM' | 'SLOW';
  styleReference: {
    [CreatePromptStyleReferenceMode.Image]: {
      strength: number;
      styleReferenceId: string | null;
    };
    [CreatePromptStyleReferenceMode.Background]: {
      styleReferenceId: string | null;
      strength: number;
    };
    [CreatePromptStyleReferenceMode.Material]: {
      strength: number;
      styleReferenceId: string | null;
    };
    [CreatePromptStyleReferenceMode.Precise]: {
      strength: number;
      styleReferenceId: string | null;
      maskZoom: boolean;
    };
    [CreatePromptStyleReferenceMode.TryOn]: {
      strength: number;
      styleReferenceId: string | null;
    };
  };
  colorReference: {
    color: string | null;
  };
}

type Props = {
  drawing?: Drawing2dStudio | null;
  selectedPromptId: string | undefined;
  setSelectedPromptId: Dispatch<SetStateAction<string | undefined>>;
  getCompositeImage: LayersCompositorApi['getCompositedImage'];
  getSelectionImage: () => ImageData | undefined;
};

const defaultInferenceSettings: InferenceSettings = {
  imageInferenceType: ImageInferenceType.Render,
  outputsCount: 1,
  publicPaletteId: PublicPalette.generalV2,
  customModelId: null,
  workbenchPaletteId: null,
  userPaletteId: null,

  sourceImageInfluence: 1,
  paletteInfluence: 1.0,
  fidelity: 0.5,
  colorCoherence: false,
  mode: 'FAST',

  prompt: '',
  negativePrompt: '',

  referenceMode: CreatePromptStyleReferenceMode.Material,
  styleReference: {
    [CreatePromptStyleReferenceMode.Image]: {
      strength: 1,
      styleReferenceId: null,
    },
    [CreatePromptStyleReferenceMode.Background]: {
      strength: 1,
      styleReferenceId: null,
    },
    [CreatePromptStyleReferenceMode.Material]: {
      strength: 1,
      styleReferenceId: null,
    },
    [CreatePromptStyleReferenceMode.Precise]: {
      strength: 1,
      styleReferenceId: null,
      maskZoom: false,
    },
    [CreatePromptStyleReferenceMode.TryOn]: {
      strength: 1,
      styleReferenceId: null,
    },
  },
  colorReference: {
    color: null,
  },
};

export const useInference = ({
  drawing,
  getCompositeImage,
  getSelectionImage,
  selectedPromptId,
  setSelectedPromptId,
}: Props) => {
  const maxOutputsCount =
    PLANS_LIMITS[
      drawing?.workbench?.folder?.organization?.subscriptionPlan ?? 'FREE'
    ].multiParallelInference ?? 1;

  const [_inferenceSettings, setInferenceSettings] =
    useState<InferenceSettings>({
      ...defaultInferenceSettings,
      outputsCount: maxOutputsCount,
    });

  // filter out style reference references that are not available anymore
  const inferenceSettings = useMemo(
    () =>
      ({
        ..._inferenceSettings,
        styleReference: {
          [CreatePromptStyleReferenceMode.Image]: {
            ..._inferenceSettings.styleReference[
              CreatePromptStyleReferenceMode.Image
            ],
            styleReferenceId: styleReferenceExistsInDrawing(
              drawing,
              _inferenceSettings.styleReference[
                CreatePromptStyleReferenceMode.Image
              ].styleReferenceId
            )
              ? _inferenceSettings.styleReference[
                  CreatePromptStyleReferenceMode.Image
                ].styleReferenceId
              : null,
          },
          [CreatePromptStyleReferenceMode.Background]: {
            ..._inferenceSettings.styleReference[
              CreatePromptStyleReferenceMode.Background
            ],
            styleReferenceId: styleReferenceExistsInDrawing(
              drawing,
              _inferenceSettings.styleReference[
                CreatePromptStyleReferenceMode.Background
              ].styleReferenceId
            )
              ? _inferenceSettings.styleReference[
                  CreatePromptStyleReferenceMode.Background
                ].styleReferenceId
              : null,
          },
          [CreatePromptStyleReferenceMode.Material]: {
            ..._inferenceSettings.styleReference[
              CreatePromptStyleReferenceMode.Material
            ],
            styleReferenceId: styleReferenceExistsInDrawing(
              drawing,
              _inferenceSettings.styleReference[
                CreatePromptStyleReferenceMode.Material
              ].styleReferenceId
            )
              ? _inferenceSettings.styleReference[
                  CreatePromptStyleReferenceMode.Material
                ].styleReferenceId
              : null,
          },
          [CreatePromptStyleReferenceMode.Precise]: {
            ..._inferenceSettings.styleReference[
              CreatePromptStyleReferenceMode.Precise
            ],
            styleReferenceId: styleReferenceExistsInDrawing(
              drawing,
              _inferenceSettings.styleReference[
                CreatePromptStyleReferenceMode.Precise
              ].styleReferenceId
            )
              ? _inferenceSettings.styleReference[
                  CreatePromptStyleReferenceMode.Precise
                ].styleReferenceId
              : null,
          },
          [CreatePromptStyleReferenceMode.TryOn]: {
            ..._inferenceSettings.styleReference[
              CreatePromptStyleReferenceMode.TryOn
            ],
            styleReferenceId: styleReferenceExistsInDrawing(
              drawing,
              _inferenceSettings.styleReference[
                CreatePromptStyleReferenceMode.TryOn
              ].styleReferenceId
            )
              ? _inferenceSettings.styleReference[
                  CreatePromptStyleReferenceMode.TryOn
                ].styleReferenceId
              : null,
          },
        },
      } as typeof _inferenceSettings),
    [_inferenceSettings, drawing]
  );

  useEffect(() => {
    if (drawing) {
      const prompt = drawing.prompts.nodes[0];
      if (!prompt) {
        setInferenceSettings({
          ...defaultInferenceSettings,
          outputsCount: maxOutputsCount,
        });
      } else {
        let imageInferenceType = prompt.imageInferenceType;
        let publicPaletteId = prompt.publicPaletteId;
        if (prompt.legacyType) {
          const convertedLegacyType = promptLegacyTypeToModeAndPalette(
            prompt.legacyType
          );
          imageInferenceType =
            convertedLegacyType.mode.toUpperCase() as ImageInferenceType;
          publicPaletteId = convertedLegacyType.publicPaletteId;
        }
        const sourceImageInfluence = prompt.sourceImageInfluence ?? 0.5;

        if (
          !publicPaletteId &&
          !prompt.customModelId &&
          !prompt.workbenchPaletteId &&
          !prompt.userPaletteId
        ) {
          // this can happen if the customModel or workbenchPalette has been deleted, in this case we rollback to the default palette
          publicPaletteId = PublicPalette.generalV2;
        }

        const styleReference = drawing?.workbench?.styleReferences?.nodes.find(
          (ref) => ref.imagePath === prompt.styleReferenceImagePath
        );

        setInferenceSettings({
          imageInferenceType,
          outputsCount: maxOutputsCount,
          sourceImageInfluence:
            sourceImageInfluence > 1 // legacy sourceImageInfluence were from 0 to 100, normalizing it here
              ? sourceImageInfluence / 100
              : sourceImageInfluence,
          prompt: prompt.text || '',
          fidelity: defaultInferenceSettings.fidelity,
          paletteInfluence: prompt.paletteInfluence,
          mode: defaultInferenceSettings.mode,

          negativePrompt: '',

          publicPaletteId,
          customModelId: prompt.customModelId,
          workbenchPaletteId: prompt.workbenchPaletteId,
          userPaletteId: prompt.userPaletteId,

          referenceMode: CreatePromptStyleReferenceMode.Image,
          styleReference: {
            [CreatePromptStyleReferenceMode.Image]: {
              strength: 1,
              styleReferenceId: styleReference?.id ?? null,
            },
            [CreatePromptStyleReferenceMode.Background]: {
              strength: 1,
              styleReferenceId: styleReference?.id ?? null,
            },
            [CreatePromptStyleReferenceMode.Material]: {
              strength: 1,
              styleReferenceId: styleReference?.id ?? null,
            },
            [CreatePromptStyleReferenceMode.Precise]: {
              strength: 1,
              styleReferenceId: styleReference?.id ?? null,
              maskZoom: false,
            },
            [CreatePromptStyleReferenceMode.TryOn]: {
              strength: 1,
              styleReferenceId: styleReference?.id ?? null,
            },
          },
          colorReference: {
            color: defaultInferenceSettings.colorReference.color,
          },
          colorCoherence: defaultInferenceSettings.colorCoherence,
        });
      }
    }
  }, [drawing?.id]);

  const selectedPrompt = usePrompt(selectedPromptId);
  const selectedPromptOutputs =
    selectedPrompt.data?.id === selectedPromptId
      ? selectedPrompt.data?.outputs.nodes
      : undefined;

  const anyOutputLoading =
    !!selectedPromptId &&
    selectedPrompt.data?.outputs.nodes.some(
      (output) => !output.imagePath && !output.failureReason
    );

  const failureReason = selectedPromptOutputs?.[0]?.failureReason;

  useEffect(() => {
    if (failureReason) {
      setSelectedPromptId(undefined);
      if (failureReason.includes('timeout')) {
        triggerDirectModal({
          title: 'Over capacity',
          content: (
            <>
              <Text block>
                Vizcom is at capacity right now. Please retry later.
              </Text>
              <Text block>
                If you haven't yet, you can upgrade your Workspace to Vizcom
                Professional to get priority access to our service.
              </Text>
            </>
          ),
        });
      } else {
        triggerDirectModal({
          title: "Couldn't generate your image",
          content: (
            <>
              <Text block>There was an unexpected error.</Text>
              <Text block>Please try again in a few moments.</Text>
            </>
          ),
        });
      }
    }
  }, [selectedPromptId, setSelectedPromptId, failureReason]);

  const trigger = useCallback(async () => {
    if (!drawing) {
      return;
    }

    if (promptContainsArtistName(inferenceSettings.prompt)) {
      return;
    }

    // Skip backend call for realtime mode since it uses its own inference endpoint
    if (inferenceSettings.imageInferenceType === 'REALTIME') {
      return;
    }

    const selectionImage = getSelectionImage();
    setSelectedPromptId('loading');
    const [composite, mask] = await Promise.all([
      imageDataToBlob(getCompositeImage()),
      selectionImage && imageDataToBlob(selectionImage),
    ]);
    const imageStyleReference =
      inferenceSettings.imageInferenceType === ImageInferenceType.Render &&
      isEnumValue(
        inferenceSettings.referenceMode,
        CreatePromptStyleReferenceMode
      )
        ? inferenceSettings.styleReference[inferenceSettings.referenceMode]
        : null;

    try {
      const inferenceOutput = await createPrompt({
        data: composite,
        mask,
        prompt: inferenceSettings.prompt || '',
        sourceImageInfluence: inferenceSettings.sourceImageInfluence,
        fidelity: inferenceSettings.fidelity,
        paletteInfluence: inferenceSettings.paletteInfluence,
        colorCoherence: inferenceSettings.colorCoherence,
        drawingId: drawing.id,
        imageInferenceType:
          inferenceSettings.imageInferenceType as ImageInferenceType, // Cast to remove REALTIME
        outputsCount: inferenceSettings.outputsCount,
        publicPaletteId: inferenceSettings.publicPaletteId,
        customModelId: inferenceSettings.customModelId,
        workbenchPaletteId: inferenceSettings.workbenchPaletteId,
        userPaletteId: inferenceSettings.userPaletteId,
        negativePrompt: inferenceSettings.negativePrompt,
        styleReference: imageStyleReference?.styleReferenceId
          ? {
              strength: imageStyleReference.strength,
              styleReferenceId: imageStyleReference.styleReferenceId,
              mode: inferenceSettings.referenceMode as CreatePromptStyleReferenceMode,
              maskZoom:
                inferenceSettings.styleReference[
                  CreatePromptStyleReferenceMode.Precise
                ].maskZoom,
            }
          : undefined,
        colorReference:
          inferenceSettings.referenceMode === 'color' &&
          inferenceSettings.colorReference.color
            ? {
                color: inferenceSettings.colorReference.color,
              }
            : undefined,

        // only for Vizcom admin, overwrite the workflow from ComfyUI, used to test different settings live
        workflow: localStorage.getItem('vizcom:2dstudio:customWorkflow'),
        magicPrompt:
          localStorage.getItem('vizcom:2dstudio:magicPrompt') === 'true',
      });
      setSelectedPromptId((selectedId) => {
        if (selectedId === undefined) {
          // prompt was cancelled before `createPrompt` finished, should not set the inference output id
          return undefined;
        }
        return inferenceOutput.id;
      });
    } catch (e) {
      if (
        e instanceof CombinedError &&
        (e.graphQLErrors[0]?.extensions?.exception as any)?.code ===
          'offensive_word'
      ) {
        triggerDirectModal({
          title: 'Your prompt contains offensive words',
          content: (
            <>
              <Text block>
                Your prompt contains offensive words. Please change it.
              </Text>
              <Text block>
                If you believe it's a mistake, contact us (support@vizcom.ai)
              </Text>
            </>
          ),
        });
      } else if (
        e instanceof CombinedError &&
        (e.graphQLErrors[0]?.extensions?.exception as any)?.code ===
          'trademarked_word'
      ) {
        triggerDirectModal({
          title: 'Your prompt contains a trademarked source',
          content: (
            <>
              <Text block>
                Your prompt contains a trademarked source. Please change it.
              </Text>
              <Text block>
                If you believe it's a mistake, contact us (support@vizcom.ai)
              </Text>
            </>
          ),
        });
      } else if (
        e instanceof CombinedError &&
        (e.graphQLErrors[0]?.extensions?.exception as any)?.code ===
          'organization_forbidden_keyword'
      ) {
        triggerDirectModal({
          title: 'Your prompt contains a forbidden word',
          content: (
            <>
              <Text block>
                Your prompt contains a keyword forbidden by your workspace
                administrator. Please change it.
              </Text>
              <Text block>
                If you believe it's a mistake, contact your workspace
                administrator.
              </Text>
            </>
          ),
        });
      } else if (
        e instanceof CombinedError &&
        (e.graphQLErrors[0]?.extensions?.exception as any)?.rateLimit
      ) {
        const rateLimitInfo = (e.graphQLErrors[0]?.extensions?.exception as any)
          ?.rateLimit as RateLimitQuotaDetails;
        triggerDirectModal({
          title: 'You have been generating too many images',
          content: (
            <Text block>
              Please wait {(rateLimitInfo.resetInMs / 1000).toFixed(0)} seconds
              before trying again.
            </Text>
          ),
        });
      } else {
        triggerDirectModal({
          title: "Couldn't generate your image",
          content: (
            <>
              <Text block>There was an unexpected error.</Text>
              <Text block>Please try again in a few moments.</Text>
            </>
          ),
        });
      }
      setSelectedPromptId(undefined);
    }

    trackEvent('Generate Image', {
      imageInferenceType: inferenceSettings.imageInferenceType,
      publicPaletteId: inferenceSettings.publicPaletteId,
      customModelId: inferenceSettings.customModelId,
      workbenchPaletteId: inferenceSettings.workbenchPaletteId,
      userPaletteId: inferenceSettings.userPaletteId,
      outputsCount: inferenceSettings.outputsCount,
      sourceImageInfluence: inferenceSettings.sourceImageInfluence,
      prompt: inferenceSettings.prompt,
      styleReferenceEnabled: !!imageStyleReference,
    });

    publishTrackingEvent({
      type: 'GENERATE_IMAGE',
      data: {
        imageInferenceType: inferenceSettings.imageInferenceType || undefined,
        publicPaletteId: inferenceSettings.publicPaletteId || undefined,
        customModelId: inferenceSettings.customModelId || undefined,
        workbenchPaletteId: inferenceSettings.workbenchPaletteId || undefined,
        userPaletteId: inferenceSettings.userPaletteId || undefined,
        outputsCount: inferenceSettings.outputsCount,
        sourceImageInfluence: inferenceSettings.sourceImageInfluence,
        prompt: inferenceSettings.prompt,
        styleReferenceEnabled: !!imageStyleReference,
        source: 'STUDIO',
        paletteInfluence: inferenceSettings.paletteInfluence,
      },
    });
  }, [
    drawing,
    getCompositeImage,
    inferenceSettings,
    getSelectionImage,
    setSelectedPromptId,
  ]);

  return {
    anyOutputLoading,
    selectedPromptId,
    inferenceSettings,
    selectedPrompt: selectedPrompt.data,
    setSelectedPromptId: (id?: string) => setSelectedPromptId(id),
    setInferenceSettings,
    trigger,
  };
};

const styleReferenceExistsInDrawing = (
  drawing: Drawing2dStudio | null | undefined,
  id: string | null
) => {
  if (!drawing || !id) {
    return false;
  }
  return (
    drawing.workbench?.styleReferences.nodes.some((s) => s.id === id) || false
  );
};

import {
  RichTooltip,
  RichTooltipTrigger,
  Button,
  PlusIcon,
  RichTooltipContent,
} from '@vizcom/shared-ui-components';

type SaveColorButtonProps = {
  onClick: () => void;
  title: string;
};

export const SaveColorButton = ({ onClick, title }: SaveColorButtonProps) => {
  return (
    <RichTooltip trigger="hover" noParentIntegration>
      <RichTooltipTrigger>
        <Button variant="bare" size="icon" onClick={onClick}>
          <PlusIcon />
        </Button>
      </RichTooltipTrigger>
      <RichTooltipContent>{title}</RichTooltipContent>
    </RichTooltip>
  );
};

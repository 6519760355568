import { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  RecentWorkbenchOrdering,
  TeamRole,
  useFetchOrCreateUserOrganizationDraftFolder,
  useRecentWorkbenchesOfOrganization,
} from '@vizcom/shared/data-access/graphql';
import {
  Button,
  LoadingLogo,
  Text,
  Tooltip,
  useLocalStorage,
  useSelectedOrganization,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

import { Error, Header, Wrapper } from '../Files/Files.styled';
import { CreateFolderModal } from '../Folder/CreateFolderModal';
import { HeaderTitle } from '../Folder/HeaderTitle';
import { AllFilesSection } from './AllFilesSection';
import { RecentlyViewedWorkbenches } from './RecentlyViewedWorkbenches';
import { WorkbenchTemplates } from './WorkbenchTemplates';

export const RecentFilesPage = () => {
  // Persist the ordering preference in local storage
  const [orderBy, setOrderBy] = useLocalStorage(
    `vizcom:recent_page_order_by`,
    RecentWorkbenchOrdering.UpdatedAt
  );

  const [isOpen, setIsOpen] = useState(false);

  const { data: organization, loading } = useSelectedOrganization();
  const { data: draftFolder } = useFetchOrCreateUserOrganizationDraftFolder(
    organization?.id
  );
  const { data: workbenches, fetching } = useRecentWorkbenchesOfOrganization(
    organization?.id,
    orderBy,
    []
  );

  // Determine user permissions and content creation ability
  const isAdmin = organization?.currentUserRole === TeamRole.Admin;
  const isEditor = organization?.currentUserRole !== TeamRole.Viewer;
  const isDraftFolderLoaded = draftFolder !== undefined;

  // Memoized action buttons based on user permissions
  const actionButtons = useMemo(() => {
    if (!isEditor) {
      return (
        <>
          <Tooltip
            position="left"
            tip="As a viewer you can't create new folders."
          >
            <DisabledNewFolderButton variant="secondary" size="M">
              New folder
            </DisabledNewFolderButton>
          </Tooltip>
          <Tooltip
            position="left"
            tip="As a viewer you can't create new files."
          >
            <DisabledNewFileButton disabled variant="primary" size="M">
              New file
            </DisabledNewFileButton>
          </Tooltip>
        </>
      );
    }

    if (!isDraftFolderLoaded) {
      return (
        <>
          <DisabledNewFolderButton variant="secondary" size="M">
            New folder
          </DisabledNewFolderButton>
          <DisabledNewFileButton disabled variant="primary" size="M">
            New file
          </DisabledNewFileButton>
        </>
      );
    }

    return (
      <>
        <NewFolderButton
          variant="secondary"
          size="M"
          onClick={() => setIsOpen(true)}
        >
          New folder
        </NewFolderButton>
        <NewFileButton
          as={Link}
          to={paths.workbench.new(draftFolder?.id ?? '')}
          state={{ from: 'recentFilesHeader' }}
          variant="primary"
          size="M"
        >
          New file
        </NewFileButton>
      </>
    );
  }, [isEditor, isDraftFolderLoaded, draftFolder?.id]);

  if (loading) {
    return (
      <Wrapper>
        <LoadingLogo />
      </Wrapper>
    );
  }

  if (!organization) {
    return (
      <Wrapper>
        <Error>
          <Text>There was an error getting this page. Please retry.</Text>
          <Button variant="primary" as={Link} to={paths.files.dashboard()}>
            Go back
          </Button>
        </Error>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Header>
        <TitleContainer>
          <HeaderTitle name={'Home'} />
        </TitleContainer>
        <HeaderActionContainer>
          {isAdmin && (
            <Button
              as={Link}
              to={paths.settings.organization.membersInvite(organization.id)}
              variant="secondary"
              size="M"
            >
              Invite Members
            </Button>
          )}
          {actionButtons}
        </HeaderActionContainer>
      </Header>

      <WorkbenchTemplates />
      <RecentlyViewedWorkbenches organizationId={organization.id} />

      <AllFilesSection
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        fetching={fetching}
        loading={loading}
        workbenches={workbenches ?? []}
      />

      <CreateFolderModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        folderId={draftFolder?.id ?? ''}
        redirect={true}
      />
    </Wrapper>
  );
};

const TitleContainer = styled.div`
  grid-area: title / links / links / links;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const HeaderActionContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const NewFileButton = styled(Button)``;

const NewFolderButton = styled(Button)``;

const DisabledNewFolderButton = styled(Button)``;

const DisabledNewFileButton = styled(Button)``;

import { captureException } from '@sentry/react';
import { publishTrackingEvent } from '@vizcom/shared/data-access/graphql';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import {
  addToast,
  dismissToast,
  FileToZip,
  imageToBlob,
  zipFiles,
  downloadFile,
} from '@vizcom/shared-ui-components';

import { ClientSideWorkbenchElementDrawing } from './clientState';

export const downloadWorkbenchDrawings = async (
  drawings: ClientSideWorkbenchElementDrawing[],
  workbenchName: string,
  workbenchId: string
): Promise<void> => {
  if (drawings.length === 0) {
    addToast('Please select at least one image to export', {
      type: 'danger',
    });
    return;
  }

  let loadingToastId: string | undefined;
  if (drawings.length > 5) {
    loadingToastId = addToast(
      `Preparing ${drawings.length} images for download...`,
      {
        type: 'loading',
      }
    );
  }

  try {
    const files: FileToZip[] = await Promise.all(
      drawings
        .map(async (element, index) => {
          if (!element.thumbnailPath) return null;

          return {
            name: `${workbenchName}-${index}.png`,
            data: await imageToBlob(element.thumbnailPath, {
              convertToContentType: 'image/png',
            }),
          };
        })
        .filter(Boolean) as Promise<FileToZip>[]
    );

    if (loadingToastId) {
      addToast(`Compressing ${files.length} images...`, {
        type: 'loading',
        id: loadingToastId,
      });
    }

    const zipFile = await zipFiles(files);

    downloadFile(zipFile, workbenchName, 'zip');

    if (loadingToastId) {
      dismissToast(loadingToastId);
      addToast(`Successfully downloaded ${files.length} images`, {
        type: 'success',
      });
    }

    trackEvent('Image Export', { type: 'exportZipImages' });

    publishTrackingEvent({
      type: 'ZIP_EXPORT',
      data: {
        workbenchId: workbenchId,
        countFiles: files.length,
      },
    });
  } catch (error) {
    captureException(error);
    addToast('An error happened exporting drawing files', {
      type: 'danger',
    });
  } finally {
    if (loadingToastId) {
      dismissToast(loadingToastId);
    }
  }
};

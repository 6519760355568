import { StatsGl } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { AutoPromptType } from 'libs/shared/data-access/graphql/src/gql/graphql';
import { Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { Transition, TransitionStatus } from 'react-transition-group';
import { useTheme } from 'styled-components';
import { DoubleSide, OrthographicCamera, ShaderMaterial } from 'three';
import { useStore } from 'zustand';
import {
  UserClientStateKeys,
  useCurrentUserClientStateByKey,
  useSetCurrentUserClientState,
} from '@vizcom/shared/data-access/graphql';
import { sortByOrderKey } from '@vizcom/shared/js-utils';
import {
  addToast,
  Button,
  formatErrorMessage,
  FullPageDarkLoader,
  FullscreenExitIcon,
  FullscreenIcon,
  LoadingLogoInset,
  OnboardingStepName,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  ToastIndicator,
  TooltipNotificationName,
  useCompleteOnboardingStep,
  useHasStylus,
  useInitDrawWithFinger,
  useKeyboardShortcut,
  useLastNonNullValue,
  useStableCallback,
  useTooltipNotificationState,
} from '@vizcom/shared-ui-components';

import { WorkbenchContentRenderingOrder } from '../../WorkbenchContent';
import { SyncQueueSynchronizer } from '../../lib/SyncQueueSynchronizer';
import { ClientSideWorkbenchElementDrawing } from '../../lib/clientState';
import { useDrawingSyncedState } from '../../lib/useDrawingSyncedState';
import { MultiplayerPresence } from '../../lib/useWorkbenchMultiplayer';
import { WorkbenchCollaborationToolbar } from '../WorkbenchCollaborationToolbar/WorkbenchCollaborationToolbar';
import { PublicFileSharingButton } from '../WorkbenchCollaborationToolbar/publicFileSharing/PublicFileSharing';
import { WorkbenchHelpMenu } from '../WorkbenchHelpMenu';
import { WorkbenchViewportControls } from '../WorkbenchViewportControls';
import { getElementSize } from '../helpers';
import { WorkbenchElementContainerUserData } from '../objectsUserdata';
import { CustomImage } from '../utils/CustomImage';
import { GlobalCursor } from '../utils/GlobalCursor';
import { HtmlOverlay } from '../utils/HtmlOverlay';
import { LoadingPlaceholder } from '../utils/LoadingPlaceholder';
import { OnboardingMultiStepProvider } from '../utils/OnboardingMultiStep';
import {
  getCameraLimitsFromActiveDrawing,
  useMapControls,
} from '../utils/mapControls/utils';
import { VizcomRenderingOrderEntry } from '../utils/threeRenderingOrder';
import { FlippableGroup } from './FlippableGroup';
import { CompositeLayer } from './LayersCompositor/CompositeLayer';
import { CompositedLayersMesh } from './LayersCompositor/CompositedLayersMesh';
import { LayersCompositor } from './LayersCompositor/LayersCompositor';
import { LayersCompositorApi } from './LayersCompositor/context';
import { useLayersTextures } from './LayersCompositor/useLayersTextures';
import { NewDrawingModal } from './NewDrawingModal';
import { WorkbenchStudioContextMenu } from './Toolbar/WorkbenchStudioContextMenu';
import { WorkbenchStudioToolbar } from './Toolbar/WorkbenchStudioToolbar';
import { WorkbenchStudioCombinedUI } from './WorkbenchStudioCombinedUI';
import { WorkbenchStudioDebug } from './WorkbenchStudioDebug';
import {
  importBlobsToLayers,
  WorkbenchStudioFileDropper,
} from './WorkbenchStudioFileDropper';
import { WorkbenchStudioMenu } from './WorkbenchStudioMenu/WorkbenchStudioMenu';
import { WorkbenchStudioCamera } from './camera/WorkbenchStudioCamera';
import { WorkbenchStudioCameraGestures } from './camera/WorkbenchStudioCameraGestures';
import { InferencePreview } from './create/InferencePreview';
import { InferenceTray } from './create/InferenceTray';
import {
  PromptHistoryItem,
  WorkbenchStudioHistory,
} from './history/WorkbenchStudioHistory';
import {
  ActiveLayerContextProvider,
  useCreateActiveLayerStore,
} from './lib/useActiveLayer';
import { useIsDebugMode } from './lib/useIsDebugMode';
import { useRegisterDrawingExternalApi } from './lib/useRegisterDrawingExternalApi';
import {
  useSelectionApi,
  SelectionApiContextProvider,
} from './selection/useSelectionApi';
import { useSelectionShortcuts } from './selection/useSelectionHotkeys';
import {
  WorkbenchStudioToolType,
  isPaintingTool,
  isSelectionTool,
  useWorkbenchStudioState,
} from './studioState';
import { Tools } from './tools/Tools';
import { Layout } from './types';
import { useAutoPrompt } from './useAutoPrompt';
import { useImageStudioHotkeys } from './useImageStudioHotkeys';
import { InferenceSettings, useInference } from './useInference';
import {
  WORKBENCH_2D_STUDIO_ANIMATION_DURATION_MS,
  getParentVisibility,
} from './utils';

// The UI is 250px + 14px margin from the viewport edge
const HALF_UI_WIDTH = 132;

// This component is used as a wrapper over WorkbenchImageStudioContent
// to load it lazily on the first drawing selected and animate it in/out correctly
// This makes sure WorkbenchImageStudioContent is always passed a drawing as props
export const WorkbenchImageStudio = (props: {
  activeElement: ClientSideWorkbenchElementDrawing | undefined;
  syncQueueSynchronizer: SyncQueueSynchronizer;
  onExit: () => void;
  selfPresence: MultiplayerPresence | null;
  selectedPresence: MultiplayerPresence | undefined;
  setSelectedPresenceId: React.Dispatch<React.SetStateAction<string | null>>;
  multiplayerPresences: MultiplayerPresence[];
  onCreateDrawingsFromImage: (
    elementId: string,
    previews: { preview: ArrayBuffer | Blob; name?: string }[],
    offset?: {
      x: number;
      y: number;
    }
  ) => void;
  onOpenMobileUploadModal: () => void;
}) => {
  const lastActiveElement = useLastNonNullValue(props.activeElement);

  if (!lastActiveElement) {
    return null;
  }

  return (
    <Transition
      in={!!props.activeElement}
      timeout={WORKBENCH_2D_STUDIO_ANIMATION_DURATION_MS}
      appear
      unmountOnExit
    >
      {(state) => (
        <WorkbenchImageStudioContent
          drawing={lastActiveElement}
          state={state}
          syncQueueSynchronizer={props.syncQueueSynchronizer}
          key={lastActiveElement.id}
          _onExit={props.onExit}
          selfPresence={props.selfPresence}
          selectedPresence={props.selectedPresence}
          setSelectedPresenceId={props.setSelectedPresenceId}
          multiplayerPresences={props.multiplayerPresences}
          onCreateDrawingsFromImage={(previews, offset) =>
            props.onCreateDrawingsFromImage(
              lastActiveElement.id,
              previews,
              offset
            )
          }
          onOpenMobileUploadModal={props.onOpenMobileUploadModal}
        />
      )}
    </Transition>
  );
};

const WorkbenchImageStudioContent = (props: {
  drawing: ClientSideWorkbenchElementDrawing;
  syncQueueSynchronizer: SyncQueueSynchronizer;
  state: TransitionStatus;
  selfPresence: MultiplayerPresence | null;
  selectedPresence: MultiplayerPresence | undefined;
  setSelectedPresenceId: React.Dispatch<React.SetStateAction<string | null>>;
  multiplayerPresences: MultiplayerPresence[];
  _onExit: () => void;
  onCreateDrawingsFromImage: (
    previews: { preview: ArrayBuffer | Blob; name?: string }[],
    offset?: {
      x: number;
      y: number;
    }
  ) => void;
  onOpenMobileUploadModal: () => void;
}) => {
  const [, updateState] = useSetCurrentUserClientState();
  const studioState = useWorkbenchStudioState();
  const debugMode = useIsDebugMode();
  const [showOverlay, setShowOverlay] = useState(false);

  useEffect(() => {
    // when switching drawing in 2D studio, we reset the selection tool back to lasso
    studioState.selectionSettings.setlastSelectionTool(
      WorkbenchStudioToolType.Lasso
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.drawing?.id]);

  const layersCompositorRef = useRef<LayersCompositorApi>(null!);

  const [viewerVisibility, setViewerVisibility] = useState<
    Record<string, boolean>
  >({});

  const savedUiRatio: number =
    useCurrentUserClientStateByKey(UserClientStateKeys.StudioRatio) || 0.45;
  const layout: Layout =
    useCurrentUserClientStateByKey(UserClientStateKeys.StudioLayout) ||
    'default';
  const [uiRatio, setUiRatio] = useState(savedUiRatio);

  const { connected } = useThree((s) => s.events);

  const theme = useTheme();

  const {
    handleAction: _handleAction,
    filterHistory,
    drawing,
    undoAction,
    redoAction,
    canUndo,
    canRedo,
    hasUnsavedChanges,
  } = useDrawingSyncedState(props.drawing.id, props.syncQueueSynchronizer);

  const activeLayerStore = useCreateActiveLayerStore(drawing);
  const { activeLayerId, setActiveLayerId } = useStore(activeLayerStore);

  const [previewHidden, setPreviewHidden] = useState(false);
  const [selectedPromptId, setSelectedPromptId] = useState<string | undefined>(
    undefined
  );
  const [selectedOutputId, setSelectedOutputId] = useState<string | undefined>(
    undefined
  );
  const [historyOpen, setHistoryOpen] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [selectedPromptHistoryItem, setSelectedPromptHistoryItem] =
    useState<PromptHistoryItem>(undefined);

  const handleAction: typeof _handleAction = useCallback(
    (action, meta) => {
      const payload = _handleAction(action, meta);

      if (
        payload &&
        payload?.type !== 'updateDrawingThumbnail' &&
        payload?.type !== 'mutateLocalState'
      ) {
        _handleAction({
          type: 'updateDrawingThumbnail',
          thumbnail: layersCompositorRef.current.getCompositedImage,
        });
      }
      return payload;
    },
    [_handleAction]
  );
  useRegisterDrawingExternalApi(drawing, layersCompositorRef, handleAction);

  const handleInitiateDrawing = useStableCallback((autoPromptDelay: number) => {
    triggerAutoPrompt(autoPromptDelay);
  });

  const selectionApi = useSelectionApi(
    drawing ? [drawing.width, drawing.height] : [0, 0],
    handleAction
  );

  const {
    trigger,
    anyOutputLoading,
    selectedPrompt,
    inferenceSettings,
    setInferenceSettings,
  } = useInference({
    drawing,
    selectedPromptId,
    setSelectedPromptId,
    getCompositeImage: layersCompositorRef.current?.getCompositedImage,
    getSelectionImage: () => selectionApi.getState().getSelectionImage(),
  });

  const { triggerAutoPrompt, cancelAutoPrompt, isGeneratingPrompt } =
    useAutoPrompt({
      workbenchPaletteId: inferenceSettings.workbenchPaletteId,
      inputType: AutoPromptType.Image,
      getCompositeImage: layersCompositorRef.current?.getCompositedImage,
      setPrompt: (text) =>
        setInferenceSettings((settings) => ({
          ...settings,
          prompt: text,
        })),
      getSelectionImage: () => selectionApi.getState().getSelectionImage(),
    });

  const { width, height } = getElementSize(props.drawing);
  const userData: WorkbenchElementContainerUserData = {
    workbenchObjectType: 'container',
    elementId: props.drawing.id,
    elementTypename: 'Drawing',
    elementWidth: width,
    elementHeight: height,
    elementX: props.drawing.x,
    elementY: props.drawing.y,
    elementZIndex: props.drawing.zIndex,
    multiFocused: false,
    cursor: 'auto',
    singleFocused: true,
    vizcomRenderingOrder: [
      {
        zIndex: 0,
        escapeZIndexContext: true,
      },
    ],
  };

  useKeyboardShortcut('z', undoAction, { ctrl: true });
  useKeyboardShortcut('z', redoAction, { ctrl: true, shift: true });
  useKeyboardShortcut(['Delete', 'Backspace'], () => {
    if (
      (selectionApi.getState().hasMask &&
        activeLayerId?.split('/').length === 1) ||
      activeLayerId === undefined
    ) {
      return false;
    }

    handleAction({
      type: 'updateBulkLayers',
      deletedLayerIds: activeLayerId?.split('/'),
    });
  });
  useSelectionShortcuts(selectionApi);

  const completeOnboardingStep = useCompleteOnboardingStep();
  const onboardingStatus =
    useCurrentUserClientStateByKey(UserClientStateKeys.OnboardingStatus) || {};

  const exitStudioMode = () => {
    if (!onboardingStatus['workbench-tour-back-infinite-canvas']) {
      completeOnboardingStep(OnboardingStepName.BackInfiniteCanvas);
    }

    if (hasUnsavedChanges) {
      const compositedImage = layersCompositorRef.current.getCompositedImage();
      // if there's some unsaved changes, export the drawing thumbnail here, before exiting
      // studio mode and losing the canvas context
      _handleAction({
        type: 'updateDrawingThumbnail',
        thumbnail: compositedImage,
        final: true,
      });
    }

    props._onExit();
  };

  useImageStudioHotkeys({
    readOnly: !drawing?.isEditable,
    getCompositedImage: layersCompositorRef.current?.getCompositedImage,
    selectionApi,
    activeLayerId,
    drawing,
    handleAction,
    layersCompositor: layersCompositorRef.current,
  });

  const {
    textures: layersTextures,
    areAllTexturesLoaded: areAllTexturesLoaded,
  } = useLayersTextures(drawing?.layers.nodes ?? []);

  const applyHistoryItem = (inferenceSettings: InferenceSettings) => {
    if (selectedPromptHistoryItem?.prompt.maskImagePath) {
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = selectedPromptHistoryItem.prompt.maskImagePath;
      img.onload = () => {
        selectionApi.getState().setSelectionImage(img);
      };
    }
    setInferenceSettings(inferenceSettings);
    setSelectedPromptHistoryItem(undefined);
  };

  useEffect(() => {
    if (
      selectionApi.getState().offsetModeActivated &&
      !isSelectionTool(studioState.tool)
    ) {
      selectionApi.getState().exitOffsetMode();
    }
  }, [studioState.tool, selectionApi]);

  const controls = useMapControls();
  const camera = useThree((s) => s.camera as OrthographicCamera);

  const hasStylus = useHasStylus();
  useInitDrawWithFinger({ hasStylus });

  const loadingFallback = (
    <>
      <CustomImage
        scale={[props.drawing.drawingWidth, props.drawing.drawingHeight]}
        url={props.drawing.thumbnailPath}
      />
    </>
  );

  if (!drawing) {
    // loading state
    return (
      <group
        position={[props.drawing.x, props.drawing.y, 0]}
        userData={userData}
        scale={[
          props.drawing.workbenchSizeRatio,
          props.drawing.workbenchSizeRatio,
          1,
        ]}
      >
        {loadingFallback}
      </group>
    );
  }

  const drawingSize: [number, number] = [drawing.width, drawing.height];

  const cameraLimits = getCameraLimitsFromActiveDrawing(props.drawing, camera);
  const onFitToScreen = () => {
    const zoomForWidth =
      (camera.right - camera.left) / (cameraLimits.xMax - cameraLimits.xMin);
    const zoomForHeight =
      (camera.top - camera.bottom) / (cameraLimits.yMax - cameraLimits.yMin);
    const zoomTarget = Math.min(zoomForWidth, zoomForHeight);

    controls.moveTo({
      x: layout === 'default' ? 0 : HALF_UI_WIDTH / zoomTarget,
      y: 0,
      zoom: zoomTarget,
      controlled: true,
      skipAnimation: true,
    });
  };

  const showActiveLayer =
    selectedPromptId === undefined &&
    !selectedPromptHistoryItem &&
    props.state === 'entered';
  const selectedLayer = drawing?.layers.nodes.find(
    (l) => l.id === activeLayerId
  );
  const activeLayer =
    showActiveLayer && selectedLayer
      ? {
          ...selectedLayer,
          visible:
            getParentVisibility(drawing, selectedLayer.id) &&
            selectedLayer.visible,
        }
      : undefined;

  const shouldShowHiddenLayerToast = () => {
    if (
      props.state !== 'entered' ||
      !activeLayer ||
      selectedOutputId ||
      selectedPromptHistoryItem
    ) {
      return false;
    }

    if (isPaintingTool(studioState.tool)) {
      return !activeLayer.visible;
    }
  };

  const handlePaste = async (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    try {
      const items = await navigator.clipboard.read();
      const data = [...items].filter((item) =>
        item.types.some((type) => type.includes('image'))
      );

      if (!data || data.length === 0) {
        return;
      }

      const files = [];
      for (const item of data) {
        const blob = await item.getType(item.types[0]);
        files.push(
          new File([blob], 'pasted-image.png', { type: item.types[0] })
        );
      }

      if (files.length > 0) {
        await importBlobsToLayers(
          files,
          drawing,
          handleAction,
          activeLayerId,
          setActiveLayerId
        );
      }
    } catch (e) {
      addToast('Failed to paste image', {
        type: 'danger',
        secondaryText: formatErrorMessage(e),
      });
    }
  };

  const shouldShowMultiSelectionToast =
    activeLayerId && activeLayerId.split('/').length > 1;

  const shouldShowGroupSelectedToast = activeLayer?.isGroup;

  const hiddenHierarchicalParentId = (layerId: string): string | undefined => {
    const layer = drawing.layers.nodes.find((l) => l.id === layerId);

    if (!layer) return undefined;
    if (!layer.visible) return layer.id;
    if (!layer.parentId) return undefined;

    return hiddenHierarchicalParentId(layer.parentId);
  };

  const shouldShowInHiddenGroupToast =
    !activeLayer?.isGroup &&
    activeLayer?.parentId &&
    hiddenHierarchicalParentId(activeLayer?.parentId);

  const handleShowInferenceTrayExitButtonTooltip = () => {
    const inferenceModeActive = selectedPromptId !== undefined;
    if (!inferenceModeActive) return;

    useTooltipNotificationState
      .getState()
      .setDisplay(TooltipNotificationName.ExitInferenceTray, true);
  };

  const handleEndTour = () => {
    updateState({
      input: {
        key: UserClientStateKeys.OnboardingStatus,
        value: {
          ...onboardingStatus,
          v_1_1: true,
        },
      },
    });
  };

  const layersWithParentVisibility = drawing.layers.nodes.map((layer) => {
    const topLevelParentVisible = getParentVisibility(drawing, layer.id);

    return {
      ...layer,
      visible: topLevelParentVisible && layer.visible,
    };
  });

  const visibleSortedLayers = sortByOrderKey(layersWithParentVisibility)
    .reverse()
    .filter((layer) => {
      return (
        !layer.imagePath ||
        Object.prototype.hasOwnProperty.call(layersTextures, layer.id)
      );
    });

  return (
    <ActiveLayerContextProvider value={activeLayerStore}>
      <SelectionApiContextProvider value={selectionApi}>
        <WorkbenchStudioCamera
          state={props.state}
          cameraLimits={cameraLimits}
          drawingPosition={[props.drawing.x, props.drawing.y]}
          drawingWorkbenchSizeRatio={props.drawing.workbenchSizeRatio}
          offsetX={layout === 'default' ? 0 : HALF_UI_WIDTH}
        />
        {(props.state === 'entering' || props.state === 'entered') && (
          <>
            <WorkbenchViewportControls
              collapsed={fullscreen}
              cameraLimits={cameraLimits}
              onFitToScreen={onFitToScreen}
              prependControls={
                <RichTooltip trigger="hover" padding={11}>
                  <RichTooltipTrigger>
                    <Button
                      onClick={() => setFullscreen((f) => !f)}
                      size="icon"
                      variant="tertiary"
                    >
                      {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                    </Button>
                  </RichTooltipTrigger>
                  <RichTooltipContent style={{ color: theme.deprecated.white }}>
                    {fullscreen ? 'Exit Fullscreen' : 'Fullscreen'}
                  </RichTooltipContent>
                </RichTooltip>
              }
              appendControls={
                <WorkbenchHelpMenu context="Drawing" fullscreen={fullscreen} />
              }
            />
            <WorkbenchStudioCameraGestures cameraLimits={cameraLimits} />
          </>
        )}
        <GlobalCursor activeLayer={activeLayer} drawingSize={drawingSize} />

        <group
          userData={userData}
          visible={props.state === 'entering' || props.state === 'entered'}
        >
          <FlippableGroup>
            <group
              userData={{
                vizcomRenderingOrder: [
                  {
                    zIndex: WorkbenchContentRenderingOrder.indexOf('actions'),
                    escapeZIndexContext: true,
                  } satisfies VizcomRenderingOrderEntry,
                ],
              }}
            ></group>
            {/* Using two groups, parent one for the roate, child for the flip to not isolate the different rotation and correctly composite them */}
            <LayersCompositor
              drawing={drawing}
              ref={layersCompositorRef}
              layersOrder={[
                ...sortByOrderKey(drawing.layers.nodes).map((l) => l.id),
              ].reverse()}
            >
              {shouldShowHiddenLayerToast() && (
                <ToastIndicator text="This layer is hidden, make it visible to edit it" />
              )}
              {shouldShowMultiSelectionToast && (
                <ToastIndicator text="Select a single layer to edit it" />
              )}
              {shouldShowGroupSelectedToast && (
                <ToastIndicator text="Groups cannot be edited" />
              )}
              {shouldShowInHiddenGroupToast && (
                <ToastIndicator
                  text={
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 16,
                      }}
                    >
                      Layer in hidden group
                      <Button size="S" variant="secondary">
                        Unhide Group
                      </Button>
                    </div>
                  }
                />
              )}

              <group
                userData={{
                  vizcomRenderingOrder: [
                    {
                      zIndex:
                        WorkbenchContentRenderingOrder.indexOf('elements'),
                      escapeZIndexContext: true,
                    } satisfies VizcomRenderingOrderEntry,
                  ],
                }}
              >
                {areAllTexturesLoaded
                  ? visibleSortedLayers.map((layer, index) => (
                      <CompositeLayer
                        layer={layer}
                        texture={layersTextures[layer.id]}
                        key={layer.id}
                        active={layer.id === activeLayer?.id}
                        drawingSize={drawingSize}
                        handleAction={handleAction}
                        filterHistory={filterHistory}
                        visible={viewerVisibility[layer.id] ?? layer.visible}
                        zIndex={index}
                      />
                    ))
                  : loadingFallback}
              </group>

              <CompositedLayersMesh
                width={drawing.width}
                height={drawing.height}
                meshProps={{
                  renderOrder: 1,
                }}
              />

              <mesh
                userData={{
                  vizcomRenderingOrder: [
                    {
                      zIndex:
                        WorkbenchContentRenderingOrder.indexOf('background'),
                      escapeZIndexContext: true,
                    } satisfies VizcomRenderingOrderEntry,
                  ],
                }}
                onDoubleClick={exitStudioMode}
              >
                <planeGeometry
                  args={[drawing.width * 100, drawing.height * 100, 1, 1]}
                />
                <meshBasicMaterial transparent opacity={0} side={DoubleSide} />
              </mesh>

              <mesh
                userData={{
                  vizcomRenderingOrder: [
                    {
                      zIndex:
                        WorkbenchContentRenderingOrder.indexOf('background'),
                      escapeZIndexContext: true,
                    } satisfies VizcomRenderingOrderEntry,
                  ],
                }}
                onDoubleClick={(e) => e.stopPropagation()}
                onBeforeRender={(_r, _s, camera, _g, material) => {
                  const { zoom } = camera as OrthographicCamera;
                  const { uniforms } = material as ShaderMaterial;
                  uniforms.checkboardSize.value = 8 / zoom;
                }}
                visible={!drawing.backgroundVisible}
              >
                <planeGeometry args={[drawing.width, drawing.height, 1, 1]} />
                <checkboardMaterial
                  scale={[drawing.width, drawing.height]}
                  side={DoubleSide}
                />
              </mesh>

              <Suspense fallback={<LoadingPlaceholder />}>
                <InferencePreview
                  imagePath={
                    selectedPrompt?.outputs.nodes.find(
                      (output) => output.id === selectedOutputId
                    )?.imagePath
                  }
                  visible={!previewHidden && selectedPromptId !== 'loading'}
                  scale={[drawing.width, drawing.height]}
                  selectedPromptHistoryItem={selectedPromptHistoryItem}
                />
              </Suspense>

              <Tools
                studioState={studioState}
                activeLayer={activeLayer}
                setActiveLayerId={setActiveLayerId}
                handleAction={handleAction}
                filterHistory={filterHistory}
                drawing={drawing}
                drawingSize={drawingSize}
                isInferenceRunning={selectedPromptId !== undefined}
                getCompositedImage={
                  layersCompositorRef.current?.getCompositedImage
                }
                layersTextures={layersTextures}
                activeLayerIndex={visibleSortedLayers.findIndex(
                  (layer) => layer.id === activeLayerId
                )}
              />
              <HtmlOverlay>
                {!drawing.initiated && drawing.isEditable && (
                  <NewDrawingModal
                    drawing={drawing}
                    handleAction={handleAction}
                    handleInitiateDrawing={handleInitiateDrawing}
                  />
                )}

                <div
                  style={{
                    position: 'absolute',
                    top: '0px',
                    left: '0px',
                    width: '100%',
                    height: '100%',
                    zIndex: '10000001',
                  }}
                >
                  <LoadingLogoInset
                    active={!areAllTexturesLoaded && props.state === 'entered'}
                  />
                </div>
                <OnboardingMultiStepProvider
                  active={drawing.initiated}
                  totalSteps={4}
                  onLastStep={handleEndTour}
                >
                  <WorkbenchStudioToolbar
                    drawing={drawing}
                    disabled={
                      Boolean(selectedPromptId) ||
                      Boolean(selectedPromptHistoryItem)
                    }
                    isInferenceRunning={selectedPromptId !== undefined}
                    handleAction={handleAction}
                    state={props.state}
                    activeLayer={activeLayer}
                    activeLayerId={activeLayerId}
                    setActiveLayerId={setActiveLayerId}
                    handleUndo={undoAction}
                    handleRedo={redoAction}
                    onExit={exitStudioMode}
                    canUndo={canUndo}
                    canRedo={canRedo}
                    setSelectedPromptHistoryItem={setSelectedPromptHistoryItem}
                    onClick={handleShowInferenceTrayExitButtonTooltip}
                    onOpenMobileUploadModal={props.onOpenMobileUploadModal}
                    getCompositedImage={
                      layersCompositorRef.current?.getCompositedImage
                    }
                  />
                  {connected && (
                    <WorkbenchStudioContextMenu
                      target={connected}
                      handlePaste={handlePaste}
                    />
                  )}
                  <div onClick={handleShowInferenceTrayExitButtonTooltip}>
                    <WorkbenchStudioCombinedUI
                      hidden={fullscreen}
                      drawing={drawing}
                      state={props.state}
                      anyOutputLoading={anyOutputLoading}
                      inferenceSettings={inferenceSettings}
                      setInferenceSettings={setInferenceSettings}
                      handleAction={handleAction}
                      trigger={trigger}
                      triggerAutoPrompt={triggerAutoPrompt}
                      isGeneratingPrompt={isGeneratingPrompt}
                      cancelAutoPrompt={cancelAutoPrompt}
                      selectedPromptHistoryItem={selectedPromptHistoryItem}
                      applyHistoryItem={applyHistoryItem}
                      uiRatio={uiRatio}
                      historyOpen={historyOpen}
                      setIsPrompting={studioState.setIsPrompting}
                      setUiRatio={setUiRatio}
                      activeLayerId={activeLayerId}
                      setActiveLayerId={setActiveLayerId}
                      viewerVisibility={viewerVisibility}
                      setViewerVisibility={setViewerVisibility}
                      onExit={exitStudioMode}
                      onCreateDrawingsFromImage={
                        props.onCreateDrawingsFromImage
                      }
                      layersDisabled={
                        Boolean(selectedPromptId) ||
                        Boolean(selectedPromptHistoryItem) ||
                        studioState.tool ===
                          WorkbenchStudioToolType.AutoSelection
                      }
                      setSelectedPromptHistoryItem={
                        setSelectedPromptHistoryItem
                      }
                      getCompositedImage={
                        layersCompositorRef.current?.getCompositedImage
                      }
                    />
                  </div>
                  <WorkbenchStudioHistory
                    disabled={selectedPromptId !== undefined}
                    drawing={drawing}
                    open={historyOpen}
                    state={props.state}
                    setOpen={setHistoryOpen}
                    handleAction={handleAction}
                    selectedPromptHistoryItem={selectedPromptHistoryItem}
                    setSelectedPromptHistoryItem={setSelectedPromptHistoryItem}
                    setActiveLayerId={setActiveLayerId}
                  />
                  <InferenceTray
                    drawing={drawing}
                    selectedPrompt={selectedPrompt}
                    trigger={trigger}
                    handleAction={handleAction}
                    previewHidden={previewHidden}
                    setPreviewHidden={setPreviewHidden}
                    selectedPromptId={selectedPromptId}
                    setSelectedPromptId={setSelectedPromptId}
                    selectedOutputId={selectedOutputId}
                    setSelectedOutputId={setSelectedOutputId}
                    activeLayerId={activeLayerId}
                    setActiveLayerId={setActiveLayerId}
                  />
                  {drawing.initiated && drawing.isEditable && (
                    <WorkbenchStudioFileDropper
                      handleAction={handleAction}
                      drawing={drawing}
                      activeLayerId={activeLayer?.id}
                      setActiveLayerId={setActiveLayerId}
                      triggerAutoPrompt={triggerAutoPrompt}
                      hasPrompt={inferenceSettings.prompt.trim().length > 0}
                    />
                  )}
                  <WorkbenchStudioDebug />
                  <WorkbenchStudioMenu
                    drawing={drawing}
                    handleAction={handleAction}
                    setShowOverlay={setShowOverlay}
                    setActiveLayerId={setActiveLayerId}
                    activeLayerId={activeLayerId}
                    canUndo={canUndo}
                    canRedo={canRedo}
                    undoAction={undoAction}
                    redoAction={redoAction}
                    handlePaste={handlePaste}
                  />
                  {showOverlay && <FullPageDarkLoader />}
                </OnboardingMultiStepProvider>
              </HtmlOverlay>
              {debugMode && <StatsGl />}
              <WorkbenchCollaborationToolbar
                selfPresence={props.selfPresence}
                workbenchId={drawing.workbenchId}
                selectedPresence={props.selectedPresence}
                setSelectedPresenceId={props.setSelectedPresenceId}
                multiplayerPresences={props.multiplayerPresences}
              >
                <PublicFileSharingButton
                  workbenchId={drawing.workbenchId}
                  drawing={drawing}
                  elements={[]}
                />
              </WorkbenchCollaborationToolbar>
            </LayersCompositor>
          </FlippableGroup>
        </group>
      </SelectionApiContextProvider>
    </ActiveLayerContextProvider>
  );
};

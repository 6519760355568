import { Canvas } from '@react-three/fiber';
import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
  publishTrackingEvent,
  useCreateWorkbenchElementsPalette,
} from '@vizcom/shared/data-access/graphql';
import { LoadingLogo } from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

import { BackgroundLayer } from './components/BackgroundLayer';
import { MAX_Z_POSITION } from './components/helpers';
import { findFirstFreeSlotFromElements } from './components/utils/freeSlotFinders';
import { getElementDefaultSize } from './components/utils/getElementDefaultSize';
import { workbenchCameraPositionStorageKey } from './constants';
import { useSyncQueueSynchronizer } from './lib/SyncQueueSynchronizer';
import { useWorkbenchSyncedState } from './lib/useWorkbenchSyncedState';

export const NewPalettePage = () => {
  const { workbenchId } = useParams<{ workbenchId: string }>();
  const initialized = useRef(false);
  const syncQueueSynchronizer = useSyncQueueSynchronizer([workbenchId]);

  const { elements } = useWorkbenchSyncedState(
    workbenchId ?? '',
    syncQueueSynchronizer
  );
  const navigate = useNavigate();
  const [, createPalette] = useCreateWorkbenchElementsPalette();
  const [isLoading, setIsLoading] = useState(false);

  const handleCreatePalette = async () => {
    if (isLoading || !workbenchId) {
      return;
    }
    setIsLoading(true);
    const size = getElementDefaultSize('WorkbenchElementPalette');
    const pos = localStorage.getItem(
      workbenchCameraPositionStorageKey(workbenchId)
    );
    const position = findFirstFreeSlotFromElements(
      elements.map((element) => ({
        ...element,
        width: size.x,
        height: size.y,
      })),
      {
        firstSlotX: pos ? JSON.parse(pos).x : 0,
        firstSlotY: pos ? JSON.parse(pos).y : 0,
        slotWidth: size.x,
        slotHeight: size.y,
        maxElementPerLine: 1,
      }
    );
    // const zRange = getWorkbenchElementZPositionRange(scene);

    const res = await createPalette({
      input: [
        {
          id: uuidv4(),
          width: size.x,
          height: size.y,
          name: '',
          workbenchId,
          status: 'idle',
          tags: [],
          x: position[0],
          y: position[1],
          zIndex: MAX_Z_POSITION / 2,
        },
      ],
    });

    if (res.data?.createWorkbenchElementsPalette.palettes.length) {
      publishTrackingEvent({
        type: 'CREATE_ELEMENTS',
        data: {
          workbenchId,
          elementIds: res.data?.createWorkbenchElementsPalette.palettes.map(
            (palette) => palette.id
          ),
        },
      });
      navigate(paths.workbench.file(workbenchId), {
        replace: true,
      });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (isLoading || !workbenchId) {
      return;
    }
    if (!initialized.current) {
      initialized.current = true;

      handleCreatePalette();
    }
  }, []);

  if (!workbenchId) {
    return <></>;
  }

  if (isLoading) {
    return <LoadingLogo />;
  }

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
      }}
    >
      <Canvas>
        <BackgroundLayer />
      </Canvas>
    </div>
  );
};

import { v4 as uuid } from 'uuid';
import {
  CreateMultiViewsFromDrawingMutation,
  publishTrackingEvent,
  urqlClient,
} from '@vizcom/shared/data-access/graphql';
import { assertExists } from '@vizcom/shared/js-utils';
import { trackEvent } from '@vizcom/shared-data-access-analytics';

import { getElementSize } from '../../../components/helpers';
import { SyncedActionType } from '../../SyncedAction';
import { ClientSideWorkbenchElementData } from '../../clientState';
import { elementById } from '../../utils';

const MULTIVIEW_PLACEHOLDER_COUNT = 6;

export const CreateMultiViewsFromDrawingAction: SyncedActionType<
  ClientSideWorkbenchElementData[],
  {
    type: 'createMultiViewsFromDrawing';
    drawingId: string;
  },
  {
    placeholders: {
      id: string;
      x: number;
      y: number;
      width: number;
      height: number;
      zIndex: number;
      loadingImagePath: string | null | undefined;
    }[];
  }
> = {
  type: 'createMultiViewsFromDrawing',
  optimisticUpdater: ({ payload, meta }, elements) => {
    meta.custom?.placeholders.forEach((placeholder) => {
      if (elementById(elements, placeholder.id)) {
        return;
      }
      elements.push({
        __typename: 'WorkbenchElementPlaceholder',
        type: 'inference',
        id: placeholder.id,
        updatedAt: '0',
        createdAt: '0',
        x: placeholder.x,
        y: placeholder.y,
        width: placeholder.width,
        height: placeholder.height,
        zIndex: placeholder.zIndex,
        loadingImagePath: placeholder.loadingImagePath,
      });
    });
  },
  remoteUpdater: async ({ payload, meta }, workbenchId) => {
    assertExists(meta.custom);
    const res = await urqlClient.mutation(CreateMultiViewsFromDrawingMutation, {
      input: {
        drawingId: payload.drawingId,
        placeholders: meta.custom.placeholders.map((placeholder) => ({
          id: placeholder.id,
          x: placeholder.x,
          y: placeholder.y,
          width: placeholder.width,
          height: placeholder.height,
          zIndex: placeholder.zIndex,
        })),
      },
    });

    if (res?.error) {
      throw new Error(
        `Error while creating multi view, please retry. ${
          res.error.graphQLErrors[0]?.message ?? res.error.message
        }`
      );
    }
    trackEvent('Create Multi view');
    publishTrackingEvent({
      type: 'CREATE_MULTI_VIEW',
      data: {
        workbenchId,
      },
    });
  },
  // TODO: integrate undo
  metaConstructor: (payload, state) => {
    const sourceDrawing = elementById(state, payload.drawingId);
    if (!sourceDrawing || sourceDrawing.__typename !== 'Drawing') {
      throw new Error('No source drawing');
    }
    const maxZPosition = Math.max(...state.map((element) => element.zIndex));

    const size = getElementSize(sourceDrawing);
    const placeholderSize = size.height;

    return {
      custom: {
        placeholders: new Array(MULTIVIEW_PLACEHOLDER_COUNT)
          .fill(0)
          .map((_, i) => {
            return {
              id: uuid(),
              x:
                sourceDrawing.x +
                size.width / 2 +
                placeholderSize / 2 +
                10 +
                (i % 3) * (placeholderSize + 10),
              y: sourceDrawing.y - Math.floor(i / 3) * (placeholderSize + 10),
              width: placeholderSize,
              height: placeholderSize,
              zIndex: maxZPosition + 1 + i,
              loadingImagePath:
                typeof sourceDrawing.thumbnailPath === 'string'
                  ? sourceDrawing.thumbnailPath
                  : undefined,
            };
          }),
      },
    };
  },
};

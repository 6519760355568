import styled, { useTheme } from 'styled-components';
import { Text, WarningIcon } from '@vizcom/shared-ui-components';

export const OrganizationFileSharingWarning = () => {
  const theme = useTheme();

  return (
    <Container>
      <WarningIcon height={18} width={18} color={theme.surface.warning} />
      <div>
        <Text block type="b1" color="body">
          Public file sharing disabled
        </Text>
        <Text block type="b1" color="subtext">
          Contact admin to enable public file sharing.
        </Text>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 15px 10px;
`;

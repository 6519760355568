import { noop } from 'lodash';
import styled from 'styled-components';
import {
  Button,
  Modal,
  Text,
  VizcomLogoFilled,
} from '@vizcom/shared-ui-components';

interface IntroProps {
  onNext: () => void;
  isOpen: boolean;
}

export const Intro = ({ onNext, isOpen }: IntroProps) => (
  <Modal isOpen={isOpen} setIsOpen={noop} style={modalStyle}>
    <StyledVizcomLogo />
    <TextWrapper>
      <StyledH1 type="h1">Let's get to know each other</StyledH1>
      <StyledSh1 type="sh1" color="subtext">
        Answering the following questions will help us improve your experience
        with Vizcom
      </StyledSh1>
    </TextWrapper>
    <Button variant="primary" onClick={onNext}>
      Get started
    </Button>
  </Modal>
);

const modalStyle = {
  display: 'flex',
  gap: '40px',
  flexDirection: 'column',
  alignItems: 'center',
  width: '422px',
  padding: '76px 36px',
} as const;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const StyledVizcomLogo = styled(VizcomLogoFilled)`
  width: 48px;
  height: auto;
`;

const StyledH1 = styled(Text)`
  max-width: 75%;
  text-align: center;
`;

const StyledSh1 = styled(Text)`
  display: block;
  text-align: center;
`;

import * as THREE from 'three';
import { v4 as uuidv4 } from 'uuid';

import { useWorkbenchSyncedState } from '../lib/useWorkbenchSyncedState';
import { getWorkbenchElementZPositionRange, MAX_Z_POSITION } from './helpers';
import { getElementDefaultSize } from './utils/getElementDefaultSize';

export const createCompositeScene = ({
  camera,
  scene,
  handleAction,
}: {
  scene: THREE.Object3D;
  camera: THREE.Object3D;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
}) => {
  const size = getElementDefaultSize('CompositeScene');
  const elementId = uuidv4();

  const zRange = getWorkbenchElementZPositionRange(scene);
  const zIndex = isFinite(zRange[1]) ? zRange[1] + 1 : MAX_Z_POSITION / 2;
  const targetPosition = [camera.position.x, camera.position.y];

  handleAction({
    type: 'createElements',
    newElements: [
      {
        __typename: 'CompositeScene',
        id: elementId,
        width: size.x,
        height: size.y,
        x: targetPosition[0],
        y: targetPosition[1],
        zIndex,
      },
    ],
  });
};

import { useThree } from '@react-three/fiber';
import { memo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  addToast,
  ContextMenu,
  eventTargetIsInput,
} from '@vizcom/shared-ui-components';

import { WorkbenchContentRenderingOrder } from '../WorkbenchContent';
import { ClientSideWorkbenchElementData } from '../lib/clientState';
import { useWorkbenchElementSelectionState } from '../lib/elementSelectionState';
import { useElementTranslation } from '../lib/useElementTranslation';
import { MultiplayerPresence } from '../lib/useWorkbenchMultiplayer';
import { useWorkbenchSyncedState } from '../lib/useWorkbenchSyncedState';
import {
  elementIsDrawing,
  elementIsPalette,
  elementIsSection,
  elementIsText,
  useIsWorkbenchViewer,
} from '../lib/utils';
import { WorkbenchElementConnections } from './WorkbenchElementConnections';
import { WorkbenchElementAnimate } from './elements/animate/WorkbenchElementAnimate';
import { WorkbenchElementCompositeScene } from './elements/compositeScene/WorkbenchCompositeScene';
import { WorkbenchElementDrawing } from './elements/drawing/WorkbenchElementDrawing';
import { WorkbenchElementImg2Img } from './elements/img2img/WorkbenchElementImg2Img';
import {
  SourceDrawingData,
  WorkbenchElementMix,
} from './elements/mix/WorkbenchElementMix';
import { WorkbenchElementPalette } from './elements/palette/WorkbenchElementPalette';
import { limitSourceImages } from './elements/palette/helpers';
import { WorkbenchElementPlaceholder } from './elements/placeholder/WorkbenchElementPlaceholder';
import { WorkbenchElementSection } from './elements/section/WorkbenchElementSection';
import { WorkbenchElementText } from './elements/text/WorkbenchElementText';
import { WorkbenchElementVideo } from './elements/video/WorkbenchElementVideo';
import { getElementSize, getVisibleWorkbenchElements } from './helpers';
import {
  WorkbenchElementContainerUserData,
  WorkbenchObjectObject3D,
  filterChildByWorkbenchObjectUserData,
  getBoundingBoxFromWorkbenchObjects,
} from './objectsUserdata';
import { useWorkbenchToolState } from './toolbar/WorkbenchToolContext';
import { FocusIndicator } from './utils/FocusIndicator';
import { HtmlOverlay } from './utils/HtmlOverlay';
import {
  findSnapGuides,
  getBestSnapXY,
  getSnapOffset,
  SnapGuide,
} from './utils/Snapping';
import { VizcomRenderingOrderUserData } from './utils/threeRenderingOrder';
import { WorkbenchContextMenuByElementType } from './workbenchContextMenu/WorkbenchContextMenuByElementType';

export const WorkbenchElement = memo(
  (props: {
    element: ClientSideWorkbenchElementData;
    workbenchId: string;
    thumbnailDrawingId?: string | null;
    isEditing: boolean;
    multiplayerPresences: MultiplayerPresence[];
    elementIsActive: boolean;
    isResizing: boolean;
    isDragging: boolean;
    sourceDrawingsThumbnails: Record<string, SourceDrawingData> | undefined;
    focused: boolean;
    singleFocused: boolean;
    isDropTarget: boolean;
    handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
    setEditingElementId: (id: string | null) => void;
    setIsDragging: (value: boolean) => void;
    setIsDropTarget: (value: string | null) => void;
    setSnapGuides: (snapGuides: SnapGuide[]) => void;
    getDrawingImageData: (drawingId: string) => Promise<ImageData | null>;
    getParentRenderingOrder: (
      element: ClientSideWorkbenchElementData
    ) => VizcomRenderingOrderUserData;
  }) => {
    const scene = useThree((s) => s.scene);
    const camera = useThree((s) => s.camera);
    const isViewer = useIsWorkbenchViewer();
    const { tool } = useWorkbenchToolState();
    const {
      element,
      multiplayerPresences,
      elementIsActive,
      isResizing,
      isDragging,
      isDropTarget,
      setIsDragging,
      setIsDropTarget,
      setSnapGuides,
      isEditing,
      focused,
      singleFocused,
      getParentRenderingOrder,
    } = props;
    const [_isHovered, setIsHovered] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState<{
      x: number;
      y: number;
    }>();
    const [selectedSourceId, setSelectedSourceId] = useState<string | null>(
      null
    );

    const { width, height } = getElementSize(element);

    const multiplayerSelected = multiplayerPresences.find((presence) =>
      presence.focusedElementId?.includes(element.id)
    );
    const showSingleFocusedControls =
      !elementIsActive &&
      !isViewer &&
      !isEditing &&
      !elementIsSection(element) &&
      props.singleFocused;

    const parentRenderOrder = getParentRenderingOrder(element);
    // This is used for when connecting elements together, to know which element is hovered and should be connected
    const userData: WorkbenchElementContainerUserData = {
      workbenchObjectType: 'container',
      elementId: element.id,
      elementTypename: element.__typename,
      elementWidth: width,
      elementHeight: height,
      elementX: element.x,
      elementY: element.y,
      elementZIndex: element.zIndex,
      multiFocused: props.focused,
      singleFocused: props.singleFocused,
      cursor: 'auto',
      paletteSourceImageCount: elementIsPalette(element)
        ? element.sourceImages.nodes.length
        : undefined,
      paletteStatus: elementIsPalette(element) ? element.status : undefined,
      drawingName: elementIsDrawing(element) ? element.name : undefined,
      drawingThumbnailPath: elementIsDrawing(element)
        ? element.thumbnailPath
        : undefined,
      vizcomRenderingOrder: [
        ...parentRenderOrder,
        { zIndex: element.zIndex, conflictId: element.id },
      ],
    };

    const setFocusedElementsId =
      useWorkbenchElementSelectionState.getState().setFocusedElementsId;

    const handleLastDrag = (
      allObjectsToMove: WorkbenchObjectObject3D[],
      onlyDrawings?: boolean,
      paletteDropTarget?: WorkbenchObjectObject3D
    ) => {
      setIsDropTarget(null);
      setIsDragging(false);
      setSnapGuides([]);

      const workbenchElementsToMove = allObjectsToMove.filter(
        ({ userData }) =>
          userData.workbenchObjectType !== 'multi-focused-element-container'
      );
      if (onlyDrawings && paletteDropTarget) {
        if (paletteDropTarget.userData.paletteStatus === 'idle') {
          const sourceDrawingIds = limitSourceImages(
            workbenchElementsToMove,
            paletteDropTarget.userData.paletteSourceImageCount || 0
          ).map((element) => element.userData.elementId);

          if (sourceDrawingIds.length) {
            props.handleAction({
              type: 'insertDrawingsToPalette',
              id: paletteDropTarget.userData.elementId,
              sourceDrawingIds,
            });
          }
        } else {
          addToast('Trained palette locked. Duplicate to edit', {
            cta: {
              text: 'Duplicate',
              action: () =>
                props.handleAction({
                  type: 'duplicateElements',
                  elementIds: [paletteDropTarget.userData.elementId],
                  newElementIds: [uuidv4()],
                }),
            },
          });
        }
        // reset position of all elements
        allObjectsToMove.forEach((child) => {
          child.position.set(
            child.userData.elementX,
            child.userData.elementY,
            0
          );
        });
        return;
      }

      props.handleAction({
        type: 'multiPosition',
        newElementData: workbenchElementsToMove.map((element) => ({
          id: element.userData.elementId,
          x: element.position.x,
          y: element.position.y,
        })),
      });
      // we intentionally don't revert the position back on the group element
      // to let React do it on the next render loop when the client state has been updated correctly
      // this is required to prevent a one-frame flash of the old position
    };

    const handleDragging = (
      allObjectsToMove: WorkbenchObjectObject3D[],
      delta: [number, number],
      snapElements: boolean,
      onlyDrawings?: boolean,
      paletteDropTarget?: WorkbenchObjectObject3D
    ) => {
      const visibleWorkbenchElements = getVisibleWorkbenchElements(
        camera,
        scene
      );

      const workbenchElementsToMove = allObjectsToMove.filter(
        ({ userData }) =>
          userData.workbenchObjectType !== 'multi-focused-element-container'
      );
      const boundingBox = getBoundingBoxFromWorkbenchObjects(
        workbenchElementsToMove
      );
      boundingBox.left += delta[0];
      boundingBox.right += delta[0];
      boundingBox.top += delta[1];
      boundingBox.bottom += delta[1];
      // handle snapping
      let snapOffset = [0, 0];
      if (snapElements) {
        const { guides } = findSnapGuides(
          visibleWorkbenchElements
            .filter((el) => !workbenchElementsToMove.includes(el))
            .map((el) => ({
              x: el.userData.elementX,
              y: el.userData.elementY,
              width: el.userData.elementWidth,
              height: el.userData.elementHeight,
            })),
          boundingBox
        );
        setSnapGuides(guides);

        const boundingBoxCenterX = (boundingBox.left + boundingBox.right) / 2;
        const boundingBoxCenterY = (boundingBox.top + boundingBox.bottom) / 2;

        const { bestSnapX, bestSnapY } = getBestSnapXY(guides, {
          x: boundingBoxCenterX,
          y: boundingBoxCenterY,
        });
        snapOffset = getSnapOffset(bestSnapX, bestSnapY, boundingBox);
      } else {
        setSnapGuides([]);
      }

      // Apply movement to all elements
      allObjectsToMove.forEach((child) => {
        const newX = child.userData.elementX + delta[0] + snapOffset[0];
        const newY = child.userData.elementY + delta[1] + snapOffset[1];
        child.position.set(newX, newY, 0);
      });

      // Handle palette hover effect
      if (onlyDrawings && paletteDropTarget) {
        userData.cursor =
          paletteDropTarget.userData.paletteStatus === 'idle'
            ? 'copy'
            : 'not-allowed';
      }

      const dropTarget =
        onlyDrawings && paletteDropTarget
          ? paletteDropTarget.userData.elementId
          : null;

      setIsDropTarget(dropTarget);
    };

    const bind = useElementTranslation({
      element,
      isEditing,
      elementIsActive,
      setIsDragging,
      setSnapGuides,
      handleLastDrag,
      handleDragging,
      setEditingElementId: props.setEditingElementId,
      getObjectsToMove: (focusedElementsId) => {
        if (tool !== 'select' || elementIsSection(element)) {
          return [];
        }
        return filterChildByWorkbenchObjectUserData(
          scene,
          (userData) =>
            userData.workbenchObjectType ===
              'multi-focused-element-container' ||
            focusedElementsId.includes(userData.elementId)
        );
      },
    });

    const isHovered = _isHovered && !focused;

    return (
      <>
        <group
          userData={{
            vizcomRenderingOrder: [...parentRenderOrder, { zIndex: 1 }],
          }}
        >
          <WorkbenchElementConnections
            element={element}
            focused={focused}
            selectedSourceId={selectedSourceId}
            handleAction={props.handleAction}
          />
        </group>

        <group
          position={[element.x, element.y, 0]}
          userData={userData}
          onPointerEnter={(e) => {
            if (!isViewer && !isEditing && !isDragging && !isResizing) {
              e.stopPropagation();
              setIsHovered(true);
            }
          }}
          onPointerLeave={(e) => {
            e.stopPropagation();
            setIsHovered(false);
          }}
          onContextMenu={(e) => {
            if (contextMenuPosition || e.button !== 2 || isViewer) {
              return;
            }

            const { clientX, clientY } = e;

            if (eventTargetIsInput(e.nativeEvent)) {
              return;
            }

            e.stopPropagation();
            e.nativeEvent.preventDefault();

            setFocusedElementsId(element.id);

            setContextMenuPosition({
              x: clientX,
              y: clientY,
            });
          }}
        >
          <group
            userData={{
              vizcomRenderingOrder: [
                {
                  zIndex: WorkbenchContentRenderingOrder.indexOf('actions'),
                  escapeZIndexContext: true,
                },
              ],
            }}
          >
            <FocusIndicator
              multiplayerSelected={multiplayerSelected}
              active={(focused || isHovered) && !isDragging && !isResizing}
              lineWidthMultiplier={isHovered ? 2 : 1}
              height={height}
              width={width}
            />
          </group>
          <group
            {...(bind() as any)}
            onDoubleClick={(e) => {
              if (isViewer) return;
              e.stopPropagation();
              if (elementIsText(element) || elementIsDrawing(element)) {
                props.setEditingElementId(element.id);
              }
            }}
          >
            {element.__typename === 'Drawing' && (
              <WorkbenchElementDrawing
                workbenchId={props.workbenchId}
                element={element}
                isHovered={isHovered}
                handleAction={props.handleAction}
                singleFocused={singleFocused}
                focused={focused}
                setEditingElementId={props.setEditingElementId}
                isDragging={isDragging}
                isResizing={isResizing}
                isThumbnail={element.id === props.thumbnailDrawingId}
                showSingleFocusedControls={showSingleFocusedControls}
              />
            )}
            {element.__typename === 'Video' && (
              <WorkbenchElementVideo
                workbenchId={props.workbenchId}
                element={element}
                isDragging={isDragging}
                isResizing={isResizing}
                singleFocused={singleFocused}
                handleAction={props.handleAction}
              />
            )}
            {element.__typename === 'WorkbenchElementImg2Img' && (
              <WorkbenchElementImg2Img
                workbenchId={props.workbenchId}
                element={element}
                handleAction={props.handleAction}
                singleFocused={singleFocused}
                focused={focused}
                isDragging={isDragging}
                isResizing={isResizing}
                showSingleFocusedControls={showSingleFocusedControls}
                getDrawingImageData={props.getDrawingImageData}
              />
            )}
            {element.__typename === 'WorkbenchElementAnimate' && (
              <WorkbenchElementAnimate
                workbenchId={props.workbenchId}
                element={element}
                handleAction={props.handleAction}
                singleFocused={singleFocused}
                focused={focused}
                isDragging={isDragging}
                isResizing={isResizing}
                showSingleFocusedControls={showSingleFocusedControls}
                getDrawingImageData={props.getDrawingImageData}
              />
            )}
            {element.__typename === 'WorkbenchElementText' && (
              <WorkbenchElementText
                workbenchId={props.workbenchId}
                element={element}
                handleAction={props.handleAction}
                singleFocused={singleFocused}
                isEditing={isEditing}
                setEditingElementId={props.setEditingElementId}
                isDragging={isDragging}
                isResizing={isResizing}
              />
            )}
            {element.__typename === 'WorkbenchElementPlaceholder' && (
              <WorkbenchElementPlaceholder
                workbenchId={props.workbenchId}
                element={element}
                handleAction={props.handleAction}
                singleFocused={singleFocused}
                setEditingElementId={props.setEditingElementId}
                isDragging={isDragging}
                isResizing={isResizing}
              />
            )}
            {element.__typename === 'CompositeScene' && (
              <WorkbenchElementCompositeScene
                workbenchId={props.workbenchId}
                element={element}
                handleAction={props.handleAction}
                singleFocused={singleFocused}
                setEditingElementId={props.setEditingElementId}
                isDragging={isDragging}
                isResizing={isResizing}
              />
            )}
            {element.__typename === 'WorkbenchElementPalette' && (
              <WorkbenchElementPalette
                workbenchId={props.workbenchId}
                element={element}
                singleFocused={singleFocused}
                isDragging={isDragging}
                isResizing={isResizing}
                isDropTarget={isDropTarget}
                handleAction={props.handleAction}
              />
            )}
            {element.__typename === 'WorkbenchElementMix' && (
              <WorkbenchElementMix
                workbenchId={props.workbenchId}
                element={element}
                singleFocused={singleFocused}
                isDragging={isDragging}
                isResizing={isResizing}
                showSingleFocusedControls={showSingleFocusedControls}
                sourceDrawingsThumbnails={props.sourceDrawingsThumbnails}
                selectedSourceId={selectedSourceId}
                setSelectedSourceId={setSelectedSourceId}
                handleAction={props.handleAction}
              />
            )}
            {element.__typename === 'WorkbenchElementSection' && (
              <WorkbenchElementSection
                workbenchId={props.workbenchId}
                element={element}
                singleFocused={singleFocused}
                isDragging={isDragging}
                isResizing={isResizing}
                elementIsActive={elementIsActive}
                handleAction={props.handleAction}
                setEditingElementId={props.setEditingElementId}
                setIsDragging={setIsDragging}
                setSnapGuides={setSnapGuides}
              />
            )}

            {singleFocused && (
              <HtmlOverlay>
                <ContextMenu
                  withButton={false}
                  contextMenuPosition={contextMenuPosition}
                  onOpenStateChange={(state) => {
                    if (state === false) {
                      setContextMenuPosition(undefined);
                    }
                  }}
                  items={
                    <WorkbenchContextMenuByElementType
                      element={element}
                      workbenchId={props.workbenchId}
                      handleAction={props.handleAction}
                    />
                  }
                />
              </HtmlOverlay>
            )}
          </group>
        </group>
      </>
    );
  }
);

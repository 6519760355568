import { OrganizationSubscriptionData } from '@vizcom/shared/data-access/graphql';
import { Banner, CTA, WarningIcon } from '@vizcom/shared-ui-components';

export const PendingPaymentBanner = (props: {
  subscriptionData: OrganizationSubscriptionData;
}) => {
  const { subscriptionData } = props;

  if (!subscriptionData.subscription.pendingUpdate) return null;

  const message = 'There’s an issue with your payment.';
  const secondary =
    'Validate your payment method to keep using Vizcom Professional';
  const cta: CTA = {
    text: 'Manage Payment',
    url: subscriptionData.subscription.pendingUpdate.invoiceUrl,
    target: '_blank',
  };

  return (
    <Banner
      icon={<WarningIcon width={15} height={15} />}
      type="danger"
      message={message}
      secondaryMessage={secondary}
      cta={cta}
      style={{ marginBottom: 24 }}
    />
  );
};

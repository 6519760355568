import { useState, useCallback, useRef, useEffect } from 'react';

import { useScreenShareContext } from './ScreenShareContext';

interface SelectionRect {
  x: number;
  y: number;
  width: number;
  height: number;
}

export function useScreenShare(
  layerId: string,
  drawingWidth?: number,
  drawingHeight?: number
) {
  const { activeLayerId, activeStopFn, setScreenShare } =
    useScreenShareContext();

  const [stream, setStream] = useState<MediaStream | null>(null);
  const [isSharing, setIsSharing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [frameRate] = useState(24);
  const streamRef = useRef<MediaStream | null>(null);
  const frameCallbackRef = useRef<((imageData: ImageData) => void) | null>(
    null
  );
  const lastFrameTimeRef = useRef<number>(0);
  const frameIntervalRef = useRef<number>(1000 / frameRate);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [selectionArea, setSelectionArea] = useState<SelectionRect | null>(
    null
  );
  const [firstFrame, setFirstFrame] = useState<ImageData | null>(null);
  const [isSelectingArea, setIsSelectingArea] = useState(false);
  const animationRef = useRef<number | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isPageVisible = useRef<boolean>(true);
  const captureControllerRef = useRef<any>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const animationFrameRef = useRef<number | null>(null);

  const setFrameCallback = useCallback(
    (callback: (imageData: ImageData) => void) => {
      frameCallbackRef.current = callback;
    },
    []
  );

  // Add visibility change detection
  useEffect(() => {
    const handleVisibilityChange = () => {
      isPageVisible.current = document.visibilityState === 'visible';

      // If we're sharing and page becomes visible again, ensure we're capturing
      if (isPageVisible.current && isSharing && !animationRef.current) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = null;
        }
        captureFrame();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isSharing]);

  const stopScreenShare = useCallback(() => {
    // Only stop if this layer is actually the one currently sharing
    if (activeLayerId === layerId) {
      setScreenShare(null, null);
    }
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => {
        track.stop();
      });
      streamRef.current = null;
      setStream(null);
      setIsSharing(false);
      setError(null);
      frameCallbackRef.current = null;
      if (videoRef.current) {
        videoRef.current.srcObject = null;
        videoRef.current = null;
      }
      setSelectionArea(null);
      setFirstFrame(null);
    }

    // Clear any pending animation/timeout
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
      animationRef.current = null;
    }
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    // Reset the capture controller
    captureControllerRef.current = null;
  }, [activeLayerId, layerId, setScreenShare]);

  const startScreenShare = useCallback(async () => {
    try {
      // If there's already another layer sharing, stop it first
      if (activeLayerId && activeLayerId !== layerId && activeStopFn) {
        activeStopFn();
      }

      stopScreenShare();

      // Create a CaptureController if the API is available
      const CaptureController = (window as any).CaptureController;
      if (CaptureController) {
        captureControllerRef.current = new CaptureController();
      }

      // Prepare display media options
      const displayMediaOptions: any = {
        video: {
          frameRate: frameRate,
          displaySurface: 'monitor',
          width: { ideal: 1920 },
          height: { ideal: 1080 },
        },
        audio: false,
      };

      // Add controller to options if available
      if (captureControllerRef.current) {
        displayMediaOptions.controller = captureControllerRef.current;
      }

      const mediaStream = await navigator.mediaDevices.getDisplayMedia(
        displayMediaOptions
      );

      // Apply Conditional Focus if supported
      if (captureControllerRef.current) {
        const [track] = mediaStream.getVideoTracks();
        const displaySurface = track.getSettings().displaySurface;

        // Only apply no-focus-change for browser tabs or windows, not for monitors
        if (displaySurface === 'browser' || displaySurface === 'window') {
          try {
            captureControllerRef.current.setFocusBehavior('no-focus-change');
          } catch (e) {
            console.warn('Failed to set focus behavior:', e);
          }
        }
      }

      mediaStream.getVideoTracks()[0].onended = () => {
        stopScreenShare();
      };

      // Capture first frame for selection
      const video = document.createElement('video');
      video.srcObject = mediaStream;
      await video.play();

      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext('2d');

      if (ctx) {
        ctx.drawImage(video, 0, 0);
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        setFirstFrame(imageData);
        setIsSelectingArea(true);
      }

      streamRef.current = mediaStream;

      // Mark this layer as the active screen share
      setScreenShare(layerId, stopScreenShare);

      return mediaStream;
    } catch (err) {
      const errorMessage =
        err instanceof Error ? err.message : 'Failed to start screen sharing';
      console.error('Screen share error:', err);
      setError(errorMessage);
      stopScreenShare();
      return null;
    }
  }, [
    frameRate,
    stopScreenShare,
    layerId,
    activeLayerId,
    activeStopFn,
    setScreenShare,
  ]);

  // Modified captureFrame function
  const captureFrame = useCallback(() => {
    if (
      !isSharing ||
      !videoRef.current ||
      !canvasRef.current ||
      !selectionArea
    ) {
      return;
    }

    const video = videoRef.current;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    if (!ctx) return;

    const now = performance.now();

    // Process frame if enough time has passed
    if (now - lastFrameTimeRef.current >= frameIntervalRef.current) {
      if (video.readyState === video.HAVE_ENOUGH_DATA) {
        // Use the canvas that's already being updated with proper aspect ratio
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

        if (frameCallbackRef.current) {
          frameCallbackRef.current(imageData);
        }
      }
      lastFrameTimeRef.current = now;
    }

    // Schedule next frame capture
    if (isPageVisible.current) {
      // Use requestAnimationFrame when page is visible for optimal performance
      animationRef.current = requestAnimationFrame(captureFrame);
    } else {
      // Use setTimeout as a fallback when page is not visible
      // This will continue to capture at a slower rate but won't stop completely
      timeoutRef.current = setTimeout(captureFrame, frameIntervalRef.current);
    }
  }, [isSharing, selectionArea]);

  const updateCanvas = useCallback(() => {
    if (!videoRef.current || !canvasRef.current || !stream) return;

    const video = videoRef.current;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d', { alpha: true });

    if (!ctx) return;

    // Use drawing dimensions if provided, otherwise fallback to 1920x1080
    const canvasWidth = drawingWidth || 1920;
    const canvasHeight = drawingHeight || 1080;

    // Always set canvas to the drawing dimensions
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    // Clear the canvas to ensure transparency
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (selectionArea) {
      // Calculate source dimensions from the video
      const sourceX = selectionArea.x;
      const sourceY = selectionArea.y;
      const sourceWidth = selectionArea.width;
      const sourceHeight = selectionArea.height;

      // Calculate the aspect ratio of the source
      const sourceAspectRatio = sourceWidth / sourceHeight;

      // Calculate the aspect ratio of the canvas
      const canvasAspectRatio = canvasWidth / canvasHeight;

      // Calculate dimensions to fit the content within the canvas
      const scaleByWidth = canvasWidth / sourceWidth;
      const scaleByHeight = canvasHeight / sourceHeight;

      // Use the smaller scale factor to ensure content fits within canvas
      const scaleFactor = Math.min(scaleByWidth, scaleByHeight);

      // Calculate the destination dimensions
      const destWidth = sourceWidth * scaleFactor;
      const destHeight = sourceHeight * scaleFactor;

      // Center the content on the canvas
      const destX = (canvasWidth - destWidth) / 2;
      const destY = (canvasHeight - destHeight) / 2;

      // Draw the selected portion of the video onto the canvas
      ctx.drawImage(
        video,
        sourceX,
        sourceY,
        sourceWidth,
        sourceHeight,
        destX,
        destY,
        destWidth,
        destHeight
      );
    } else {
      // Draw the entire video
      const videoAspectRatio = video.videoWidth / video.videoHeight;
      const canvasAspectRatio = canvas.width / canvas.height;

      // Calculate dimensions to fit the content within the canvas
      const scaleByWidth = canvas.width / video.videoWidth;
      const scaleByHeight = canvas.height / video.videoHeight;

      // Use the smaller scale factor to ensure content fits within canvas
      const scaleFactor = Math.min(scaleByWidth, scaleByHeight);

      // Calculate the destination dimensions
      const destWidth = video.videoWidth * scaleFactor;
      const destHeight = video.videoHeight * scaleFactor;

      // Center the content on the canvas
      const destX = (canvas.width - destWidth) / 2;
      const destY = (canvas.height - destHeight) / 2;

      ctx.drawImage(
        video,
        0,
        0,
        video.videoWidth,
        video.videoHeight,
        destX,
        destY,
        destWidth,
        destHeight
      );
    }

    animationFrameRef.current = requestAnimationFrame(updateCanvas);
  }, [stream, selectionArea, drawingWidth, drawingHeight]);

  useEffect(() => {
    if (!stream || !frameCallbackRef.current || !selectionArea) return;

    if (!videoRef.current) {
      videoRef.current = document.createElement('video');
      videoRef.current.muted = true;
    }

    if (!canvasRef.current) {
      canvasRef.current = document.createElement('canvas');
      // Use drawing dimensions if provided, otherwise fallback to 1920x1080
      canvasRef.current.width = drawingWidth || 1920;
      canvasRef.current.height = drawingHeight || 1080;
    }

    const video = videoRef.current;
    video.srcObject = stream;
    video.play().catch(console.error);

    video.onloadedmetadata = () => {
      if (!videoRef.current) return;
      setIsSharing(true);
      // Start frame capture and canvas updates
      captureFrame();
      updateCanvas();
    };

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
        animationRef.current = null;
      }
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
        animationFrameRef.current = null;
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      video.pause();
      video.srcObject = null;
      if (videoRef.current) {
        videoRef.current = null;
      }
    };
  }, [
    stream,
    selectionArea,
    captureFrame,
    updateCanvas,
    drawingWidth,
    drawingHeight,
  ]);

  const handleAreaSelected = useCallback((selection: SelectionRect) => {
    setSelectionArea(selection);
    setIsSelectingArea(false);

    if (streamRef.current) {
      setStream(streamRef.current);
      setIsSharing(true);
      setError(null);
    }
  }, []);

  return {
    stream,
    isSharing,
    error,
    startScreenShare,
    stopScreenShare,
    setFrameCallback,
    isSelectingArea,
    firstFrame,
    handleAreaSelected,
    updateCanvas,
  };
}

import { WorkbenchBasicDataFragment } from 'libs/shared/data-access/graphql/src/gql/graphql';
import React from 'react';
import styled from 'styled-components';
import { useUpdateWorkbench } from '@vizcom/shared/data-access/graphql';
import {
  addToast,
  CheckIcon,
  CustomSelectTriggerContentProps,
  DropdownIcon,
  formatErrorMessage,
  Select,
  Text,
  triggerConfirmModal,
  ConfirmationModalComponent,
  WorldIcon,
  AccessOffIcon,
  InlineFlex,
  BlockStack,
  OptionComponentBase,
} from '@vizcom/shared-ui-components';

const LabelText = styled(Text)<{ $restricted: boolean }>`
  color: ${(p) => (p.$restricted ? p.theme.text.error : p.theme.text.body)};
`;

type FileSharingOptionConfig = {
  label: string;
  description: string;
  value: boolean;
  icon: React.ReactNode;
};

const FILE_SHARING_OPTIONS: FileSharingOptionConfig[] = [
  {
    label: 'Anyone with link',
    description: 'Anyone with the link can access the file',
    value: true,
    icon: <WorldIcon $color="secondary" />,
  },
  {
    label: 'Link sharing off',
    description: 'Team members will still be able to access the file',
    value: false,
    icon: <AccessOffIcon $color="error" />,
  },
];

export const FileSharingSelectComponent = ({
  workbench,
}: {
  workbench: WorkbenchBasicDataFragment;
}) => {
  const [, updateWorkbench] = useUpdateWorkbench();

  const onSelectSharingMode = async (value: string | null) => {
    const newPublicSharingEnabled = Boolean(value === 'true');

    if (newPublicSharingEnabled === false) {
      try {
        await triggerConfirmModal({
          body: (onConfirm, onCancel) => (
            <ConfirmationModalComponent
              onConfirm={onConfirm}
              variant="warning"
              onCancel={onCancel}
              title="Are you sure you want to disable link sharing?"
              description="The link to this file will no longer be valid if it has already been shared, however it can be restored by admins and editors at any time."
            />
          ),
        });
      } catch {
        // User cancelled disabling link sharing
        return;
      }
    }

    const res = await updateWorkbench({
      id: workbench.id,
      patch: {
        publicSharingEnabled: newPublicSharingEnabled,
      },
    });

    if (res.error) {
      return addToast('Error while updating link sharing', {
        secondaryText: formatErrorMessage(res.error),
        type: 'danger',
      });
    }

    return addToast(
      `Link sharing ${newPublicSharingEnabled ? 'enabled' : 'disabled'}`
    );
  };

  const selectedIndex = workbench.publicSharingEnabled ? 0 : 1;

  return (
    <Select
      onSelectElement={onSelectSharingMode}
      customSelectedTriggerContent={CustomSelectedTriggerContent}
      selectedOptionIndex={selectedIndex}
    >
      {FILE_SHARING_OPTIONS.map((config, index) => (
        <OptionComponentBase
          key={index}
          value={String(config.value)}
          label={config.label}
        >
          {({ isSelected }) => (
            <>
              <InlineFlex $gap={8}>
                {config.icon}
                <BlockStack $gap={4}>
                  <LabelText block type="sh2" $restricted={!config.value}>
                    {config.label}
                  </LabelText>
                  <Text block color="subtext" type="b1">
                    {config.description}
                  </Text>
                </BlockStack>
              </InlineFlex>
              {isSelected && <CheckIcon $color="primary" />}
            </>
          )}
        </OptionComponentBase>
      ))}
    </Select>
  );
};

const CustomSelectedTriggerContent = (
  props: CustomSelectTriggerContentProps
) => {
  const { selectedIndex } = props;

  if (selectedIndex === null) {
    return (
      <Text block type="b1">
        Select sharing mode
      </Text>
    );
  }

  const selectedConfig = FILE_SHARING_OPTIONS[selectedIndex];

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ display: 'flex', gap: 6, alignItems: 'center' }}>
        {selectedConfig.icon}
        <Text block={true} type="b1">
          {selectedConfig.label}
        </Text>
      </div>
      <DropdownIcon style={{ float: 'right' }} />
    </div>
  );
};

import styled from 'styled-components';
import { useRecentlyViewedWorkbenchesOfOrganization } from '@vizcom/shared/data-access/graphql';
import { Text } from '@vizcom/shared-ui-components';

import { File } from '../Files/File/File';
import { SelectedItemsProvider } from '../Folder/SelectedItemsProvider';

export const RecentlyViewedWorkbenches = (props: {
  organizationId: string;
}) => {
  const { data, fetching } = useRecentlyViewedWorkbenchesOfOrganization(
    props.organizationId
  );

  return (
    <>
      <Text block type="h2" style={{ marginBottom: 35 }}>
        Recent
      </Text>

      {!fetching && data?.length === 0 && (
        <Text block color="subtext">
          No recent files
        </Text>
      )}

      <Container>
        <WorkbenchesContainer>
          <SelectedItemsProvider
            getItems={() =>
              data?.map((workbench, index) => ({
                id: workbench.id,
                type: 'workbench',
                index,
              })) ?? []
            }
          >
            {data?.map((workbench, index) => (
              <File file={workbench} key={workbench.id} index={index} />
            ))}
          </SelectedItemsProvider>
        </WorkbenchesContainer>
      </Container>
    </>
  );
};

const WorkbenchesContainer = styled.div`
  display: grid;
  grid-auto-columns: 250px;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  max-width: 100%;
  grid-auto-flow: column;
  padding: 4px 16px;
`;

const Container = styled.div`
  overflow-x: scroll;
  padding: 10px 0;
  margin-right: -16px;
  margin-left: -16px;

  ::-webkit-scrollbar {
    height: 4px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: ${({ theme }) => theme.borderRadius.s};
    background: ${({ theme }) => theme.text.subtext};
  }
`;

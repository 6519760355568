import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { CreatePromptStyleReferenceMode } from '@vizcom/shared/data-access/graphql';
import {
  Divider,
  Text,
  HorizontalSwitcher,
  CloseIcon,
  useFeatureFlag,
  Select,
  DefaultOptionComponent,
} from '@vizcom/shared-ui-components';

import { Drawing2dStudio } from '../../../../lib/useDrawingSyncedState';
import { InferenceSettings } from '../../useInference';
import { StyleReferenceModalColorSettings } from './StyleReferenceModalColorSettings';
import { StyleReferenceModalImageSettings } from './StyleReferenceModalImageSettings';

type StyleReferenceModalProps = {
  drawing: Drawing2dStudio;
  setInferenceSettings: Dispatch<SetStateAction<InferenceSettings>>;
  inferenceSettings: InferenceSettings;
  onClose: () => void;
};

export const referenceModeLabel = {
  color: 'Color',
  [CreatePromptStyleReferenceMode.Background]: 'Environment',
  [CreatePromptStyleReferenceMode.TryOn]: 'Try On',
  [CreatePromptStyleReferenceMode.Image]: 'Style',
  [CreatePromptStyleReferenceMode.Material]: 'Material',
  [CreatePromptStyleReferenceMode.Precise]: 'Precise Color',
} as Record<InferenceSettings['referenceMode'], string>;

type HorizontalSwitcherOption = {
  value: InferenceSettings['referenceMode'];
  label: string;
};

const TOO_MANY_OPTIONS_THRESHOLD = 5;

export const StyleReferenceModal = ({
  drawing,
  setInferenceSettings,
  inferenceSettings,
  onClose,
}: StyleReferenceModalProps) => {
  const { flagEnabled: preciseFlagEnabled } = useFeatureFlag(
    '2DSTUDIO_PRECISE_TRANSFER'
  );

  const horizontalSwitcherOptions = [
    {
      value: CreatePromptStyleReferenceMode.Image,
      label: referenceModeLabel[CreatePromptStyleReferenceMode.Image],
    },
    {
      value: CreatePromptStyleReferenceMode.Material,
      label: referenceModeLabel[CreatePromptStyleReferenceMode.Material],
    },
    preciseFlagEnabled && {
      value: CreatePromptStyleReferenceMode.Precise,
      label: referenceModeLabel[CreatePromptStyleReferenceMode.Precise],
    },
    {
      value: 'color',
      label: referenceModeLabel['color'],
    },
    {
      value: CreatePromptStyleReferenceMode.TryOn,
      label: referenceModeLabel[CreatePromptStyleReferenceMode.TryOn],
    },
    {
      value: CreatePromptStyleReferenceMode.Background,
      label: referenceModeLabel[CreatePromptStyleReferenceMode.Background],
    },
  ].filter(Boolean) as HorizontalSwitcherOption[];

  const isUseDropdownForOptions =
    horizontalSwitcherOptions.length >= TOO_MANY_OPTIONS_THRESHOLD;

  const selectedOptionIndex = horizontalSwitcherOptions.findIndex(
    (option) => option.value === inferenceSettings.referenceMode
  );

  const verticalDropdown = (
    <Select
      selectedOptionIndex={selectedOptionIndex}
      onSelectElement={(_, index) => {
        const newReferenceMode = horizontalSwitcherOptions[index].value;
        setInferenceSettings((prev) => ({
          ...prev,
          referenceMode: newReferenceMode,
          // Clear any existing color reference when switching modes
          colorReference: {
            ...prev.colorReference,
            color:
              newReferenceMode === 'color' ? prev.colorReference.color : null,
          },
          // Clear color coherence when switching to a reference mode that disables it
          colorCoherence:
            newReferenceMode === 'color' ||
            newReferenceMode === CreatePromptStyleReferenceMode.TryOn ||
            newReferenceMode === CreatePromptStyleReferenceMode.Background
              ? false
              : prev.colorCoherence,
        }));
      }}
      defaultLabel={horizontalSwitcherOptions[selectedOptionIndex]?.label}
      customSelectedTriggerContent={() => (
        <CustomReferenceDropdownTrigger>
          Reference: {horizontalSwitcherOptions[selectedOptionIndex]?.label}
        </CustomReferenceDropdownTrigger>
      )}
    >
      {horizontalSwitcherOptions.map((option) => (
        <DefaultOptionComponent
          key={option.value}
          value={option.value}
          label={option.label}
        />
      ))}
    </Select>
  );

  const horizontalSwitcher = (
    <HorizontalSwitcher<InferenceSettings['referenceMode']>
      value={inferenceSettings.referenceMode}
      onChange={(value) => {
        setInferenceSettings((prev) => ({
          ...prev,
          referenceMode: value,
          // Clear any existing color reference when switching modes
          colorReference: {
            ...prev.colorReference,
            color: value === 'color' ? prev.colorReference.color : null,
          },
          // Clear color coherence when switching to a reference mode that disables it
          colorCoherence:
            value === 'color' ||
            value === CreatePromptStyleReferenceMode.TryOn ||
            value === CreatePromptStyleReferenceMode.Background
              ? false
              : prev.colorCoherence,
        }));
      }}
      options={horizontalSwitcherOptions}
    />
  );

  return (
    <PopoverContainer>
      <Header>
        <Text type="sh2" block>
          Reference
        </Text>
        <CloseIcon style={{ cursor: 'pointer' }} onClick={onClose} />
      </Header>

      <Divider />

      {isUseDropdownForOptions ? verticalDropdown : horizontalSwitcher}

      {inferenceSettings.referenceMode ===
        CreatePromptStyleReferenceMode.Image && (
        <StyleReferenceModalImageSettings
          drawing={drawing}
          inferenceSettings={inferenceSettings}
          setInferenceSettings={setInferenceSettings}
          onClose={onClose}
        />
      )}
      {inferenceSettings.referenceMode ===
        CreatePromptStyleReferenceMode.Material && (
        <StyleReferenceModalImageSettings
          drawing={drawing}
          inferenceSettings={inferenceSettings}
          setInferenceSettings={setInferenceSettings}
          onClose={onClose}
        />
      )}
      {inferenceSettings.referenceMode ===
        CreatePromptStyleReferenceMode.Precise && (
        <StyleReferenceModalImageSettings
          drawing={drawing}
          inferenceSettings={inferenceSettings}
          setInferenceSettings={setInferenceSettings}
          onClose={onClose}
        />
      )}
      {inferenceSettings.referenceMode === 'color' && (
        <StyleReferenceModalColorSettings
          drawing={drawing}
          inferenceSettings={inferenceSettings}
          setInferenceSettings={setInferenceSettings}
          onClose={onClose}
        />
      )}
      {inferenceSettings.referenceMode ===
        CreatePromptStyleReferenceMode.TryOn && (
        <StyleReferenceModalImageSettings
          drawing={drawing}
          inferenceSettings={inferenceSettings}
          setInferenceSettings={setInferenceSettings}
          isHideOptionsContainer={true}
          onClose={onClose}
        />
      )}
      {inferenceSettings.referenceMode ===
        CreatePromptStyleReferenceMode.Background && (
        <StyleReferenceModalImageSettings
          drawing={drawing}
          inferenceSettings={inferenceSettings}
          setInferenceSettings={setInferenceSettings}
          isHideOptionsContainer={true}
          onClose={onClose}
        />
      )}
    </PopoverContainer>
  );
};

const PopoverContainer = styled.div`
  width: 258px;

  background-color: ${(p) => p.theme.surface.primary};
  color: #fff;
  border-radius: ${({ theme }) => theme.borderRadius.l};
  overflow: hidden;

  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CustomReferenceDropdownTrigger = styled.div`
  padding: 8px 16px;
`;

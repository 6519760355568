/* This hook is used in the Workbench and the Studio to
 * make mask the paywall and make pro features available
 * as soon as the user upgrades in a different tab
 */
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  organization,
  urqlClient,
  useWorkbench,
} from '@vizcom/shared/data-access/graphql';
import { usePaywallModalState } from '@vizcom/shared-ui-components';

const ORGANIZATION_INVALIDATION_CHANNEL = 'organizationInvalidation';
const INVALIDATE_MESSAGE = 'invalidateCurrent';

export const useOrganizationRefreshListener = () => {
  const workbenchId = useParams<{ workbenchId: string }>().workbenchId;
  const organizationId = useWorkbench(workbenchId, { pause: !workbenchId })
    ?.data?.folder?.organization?.id;
  const { close } = usePaywallModalState();

  useEffect(() => {
    // Initialize the BroadcastChannel to listen for organization invalidation events
    const channel = new BroadcastChannel(ORGANIZATION_INVALIDATION_CHANNEL);

    // Define the handler for incoming messages
    const handleMessage = async (event: MessageEvent) => {
      // Check if the message is the expected "invalidate" type
      if (event.data === INVALIDATE_MESSAGE && organizationId) {
        try {
          // Use query with network-only policy to force a fresh request
          await urqlClient
            .query(
              organization,
              { id: organizationId },
              {
                requestPolicy: 'network-only',
              }
            )
            .toPromise();

          // Close the paywall modal if it's open
          close();
        } catch (error) {
          console.error('Error refreshing organization data:', error);
        }
      }
    };

    // Register the event listener
    channel.addEventListener('message', handleMessage);

    // Clean up the channel and event listener when the component unmounts
    return () => {
      channel.removeEventListener('message', handleMessage);
      channel.close();
    };
  }, [organizationId, close]);
};

export const useOrganizationRefreshTrigger = () => {
  const triggerOrganizationRefresh = useCallback(() => {
    const channel = new BroadcastChannel(ORGANIZATION_INVALIDATION_CHANNEL);
    channel.postMessage(INVALIDATE_MESSAGE);
  }, []);

  return { triggerOrganizationRefresh };
};

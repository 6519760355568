import { useOrganizationSeatsUsageQuery } from '@vizcom/shared/data-access/graphql';
import { Banner, CTA, TeamIcon } from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

export const InviteUsersBanner = (props: { organizationId: string }) => {
  const { data, fetching } = useOrganizationSeatsUsageQuery(
    props.organizationId
  );

  if (
    fetching ||
    !data?.seatsUsage ||
    data.seatsUsage.editorsSeatsCountLeft <= 0
  )
    return null;

  const message = `You have ${data.seatsUsage.editorsSeatsCountLeft} unused seats.`;
  const secondary = 'You can manage these seats for members of your workspace';
  const cta: CTA = {
    text: 'Manage Members',
    url: paths.settings.organization.members(props.organizationId),
  };

  return (
    <Banner
      icon={<TeamIcon />}
      type="primary"
      message={message}
      secondaryMessage={secondary}
      cta={cta}
      style={{ marginBottom: 24 }}
    />
  );
};

import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Link, Navigate } from 'react-router-dom';
import {
  OrganizationSubscriptionPlan,
  useTeamMembers,
  useUpdateTeam,
} from '@vizcom/shared/data-access/graphql';
import { PLANS_LIMITS } from '@vizcom/shared/plans-limit';
import {
  ArchiveIcon,
  Button,
  LoadingLogoInset,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalContentDivider,
  ModalHeader,
  Surface,
  TeamAvatar,
  Text,
  TextInput,
  WorldIcon,
  addToast,
  formatErrorMessage,
  useLastNonNullValue,
} from '@vizcom/shared-ui-components';
import { useDebouncedValue } from '@vizcom/shared-utils-hooks';
import { paths } from '@vizcom/shared-utils-paths';

import { useCanEditOrganization } from '../useCanEditOrganization';
import { ConfirmArchiveTeamModal } from './ConfirmArchiveTeamModal';
import { TeamMembersList } from './TeamMembersList';
import { TeamVisibilityPicker } from './TeamVisibilityPicker';

type TeamNameInputs = {
  name: string;
};

interface TeamSettingsModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  teamId: string | undefined;
}

export const TeamSettingsModal = (props: TeamSettingsModalProps) => {
  const teamId = useLastNonNullValue(props.teamId);
  const [, updateTeam] = useUpdateTeam();
  const { data: team, fetching } = useTeamMembers(teamId);
  const canEdit = useCanEditOrganization(team?.organization?.id);
  const [showArchiveTeamModal, setShowArchiveTeamModal] = useState(false);
  const [debouncedSearchText, searchText, setSearchText] = useDebouncedValue(
    '',
    200
  );

  const { register, handleSubmit, formState } = useForm<TeamNameInputs>({
    values: {
      name: team?.name || '',
    },
  });

  if (!teamId) {
    return null;
  }
  if (fetching) {
    return <LoadingLogoInset />;
  }
  if (!team) {
    return <Navigate to={'/'} replace />;
  }

  const handleChangeName: SubmitHandler<TeamNameInputs> = async (data) => {
    const res = await updateTeam({
      input: {
        id: team.id,
        patch: data,
      },
    });
    if (res.error) {
      return addToast('Error while changing team name', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }
    addToast(`Name successfully changed`);
  };

  const handleChangePublicInOrganization = async (
    publicInOrganization: boolean
  ) => {
    const res = await updateTeam({
      input: {
        id: team.id,
        patch: {
          publicInOrganization,
        },
      },
    });
    if (res.error) {
      return addToast('Error while changing team visibility', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }
    addToast(`Team visibility successfully changed`);
  };

  const handleChangeArchivedState = async (archived: boolean) => {
    const res = await updateTeam({
      input: {
        id: team.id,
        patch: {
          archived,
        },
      },
    });
    if (res.error) {
      return addToast('Error while changing team archival state', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }
    addToast(archived ? `Team archived` : `Team un-archived`);
  };

  return (
    <Modal
      isOpen={props.open}
      setIsOpen={props.setOpen}
      style={{
        maxWidth: 700,
        width: '90vw',
      }}
    >
      <ModalHeader>
        <TeamAvatar name={team.name} size="large" teamId={team.id} />
        <div
          style={{ flex: 1, marginLeft: 12, flexBasis: 0, overflow: 'hidden' }}
        >
          <Text
            block
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {team.name}
          </Text>
          {team.globalInOrganization ? (
            <Text color="subtext" block>
              Available to everyone in the workspace
            </Text>
          ) : (
            <Text color="subtext" block>
              {team.usersOnTeamsByTeamId.totalCount} member
              {team.usersOnTeamsByTeamId.totalCount > 1 ? 's' : ''}
            </Text>
          )}
        </div>
        <ModalCloseButton />
      </ModalHeader>
      <ModalContentDivider />
      <ModalContent>
        <Text block type="sh2" style={{ marginBottom: 16 }}>
          Team name
        </Text>
        <form
          style={{ display: 'flex', gap: 12 }}
          onSubmit={handleSubmit(handleChangeName)}
        >
          <TextInput {...register('name')} required disabled={!canEdit} />
          {canEdit && (
            <Button
              variant="primary"
              type="submit"
              disabled={!formState.isDirty}
            >
              Save
            </Button>
          )}
        </form>
        <ModalContentDivider style={{ marginTop: 24 }} />
        <Text block type="sh2" style={{ marginBottom: 16, marginTop: 24 }}>
          Visibility
        </Text>
        {team.globalInOrganization ? (
          <Surface
            color="e2"
            style={{ display: 'flex', alignItems: 'center', gap: 16 }}
          >
            <WorldIcon />
            <div style={{ flex: 1 }}>
              <Text block type="sh2" style={{ marginBottom: 8 }}>
                Everyone in workspace
              </Text>
              <Text block>
                All workspace members have access to this team and members
                cannot be added or removed. This team is the perfect place to
                store files that everybody needs access to.
              </Text>
            </div>
          </Surface>
        ) : (
          <TeamVisibilityPicker
            publicInOrganization={team.publicInOrganization}
            setPublicInOrganization={handleChangePublicInOrganization}
            disabled={!canEdit}
          />
        )}
        {PLANS_LIMITS[
          team.organization?.subscriptionPlan ??
            OrganizationSubscriptionPlan.Free
        ].teamArchival && (
          <>
            {team.archived ? (
              <Button
                onClick={() => handleChangeArchivedState(false)}
                variant="secondary"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'left',
                  gap: 16,
                  marginTop: 24,
                  width: '100%',
                }}
                disabled={!canEdit}
              >
                <ArchiveIcon />
                <div style={{ flex: 1 }}>
                  <Text block type="sh2" style={{ marginBottom: 8 }}>
                    Unarchive team
                  </Text>
                  <Text block type="b1" color="subtext">
                    Restoring this team will add it to the sidebar for all
                    members that were previously added. Content is editable and
                    new files can be created.
                  </Text>
                </div>
              </Button>
            ) : (
              <Button
                onClick={() => setShowArchiveTeamModal(true)}
                variant="danger"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'left',
                  gap: 16,
                  marginTop: 24,
                  width: '100%',
                }}
                disabled={!canEdit}
              >
                <ArchiveIcon />
                <div style={{ flex: 1 }}>
                  <Text block type="sh2" style={{ marginBottom: 8 }}>
                    Archive team
                  </Text>
                  <Text block type="b1">
                    Removes edit access and hides it in the sidebar.
                  </Text>
                </div>
              </Button>
            )}
            {team.archived && (
              <Text
                block
                style={{
                  marginTop: 12,
                  textDecoration: 'underline',
                  textAlign: 'right',
                }}
              >
                <Link to={paths.files.team(team.id)}>View files</Link>
              </Text>
            )}
          </>
        )}
        <ModalContentDivider style={{ marginTop: 24 }} />

        <Text block type="sh2" style={{ marginTop: 24 }}>
          Members
        </Text>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            marginBottom: 16,
          }}
        >
          <TextInput
            style={{
              maxWidth: 200,
              marginTop: 16,
              padding: '8px 16px',
            }}
            $background="primary"
            placeholder="Search members..."
            type="text"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />

          {canEdit && !team.globalInOrganization && (
            <Button
              variant="primary"
              size="M"
              as={Link}
              to={paths.settings.organization.teamInvite(
                team.organization!.id,
                team.id
              )}
            >
              Invite members
            </Button>
          )}
        </div>
        {team.globalInOrganization ? (
          <Text>All workspace members are member of this team</Text>
        ) : (
          <TeamMembersList teamId={teamId} searchFilter={debouncedSearchText} />
        )}
      </ModalContent>
      <ConfirmArchiveTeamModal
        team={team}
        isOpen={showArchiveTeamModal}
        setIsOpen={setShowArchiveTeamModal}
      />
    </Modal>
  );
};

import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  OrganizationActionLogsData,
  useOrganizationActivityLogs,
} from '@vizcom/shared/data-access/graphql';
import { featureLimitForOrganizationSubscription } from '@vizcom/shared/plans-limit';
import {
  LoadingLogo,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  FormattedDate,
  useSelectedOrganization,
  PaywallModal,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

const ENTRIES_PER_PAGE = 10;

const humanizeTargetName = (log: OrganizationActionLogsData) => {
  if (log.targetTable === 'Drawing') {
    return '-';
  }
  if (log.targetTable === 'workbench') {
    return <Link to={paths.workbench.file(log.targetId)}>File</Link>;
  }
  if (log.targetTable === 'folder') {
    return <Link to={paths.files.folder(log.targetId)}>Folder</Link>;
  }
  if (log.targetTable === 'organization_invite') {
    return log.metadata?.email ?? '-';
  }
  if (log.targetTable === 'UsersOnTeam') {
    return 'Team';
  }
  if (log.targetTable === 'organization') {
    if (log.metadata?.new_name) {
      return <>New name: {log.metadata?.new_name}</>;
    }
    return '-';
  }

  return log.targetTable;
};

const humanizeActionName = (log: OrganizationActionLogsData) => {
  if (
    log.targetTable === 'organization_invite' &&
    log.userEmail === log.metadata?.email
  ) {
    return 'Accepted invite';
  }
  return log.actionType;
};

export const OrganizationActivity = () => {
  const navigate = useNavigate();
  const [offset, setOffset] = useState(0);
  const { data: organization, loading } = useSelectedOrganization();
  const { value: enabledBySubscriptionPlan, loading: loadingFeatureLimit } =
    featureLimitForOrganizationSubscription(organization, 'logs');

  const activity = useOrganizationActivityLogs(
    enabledBySubscriptionPlan ? organization?.id : undefined,
    offset
  );

  const currentPage = Math.ceil((offset + 1) / ENTRIES_PER_PAGE);
  const totalPages = Math.ceil(activity.count / ENTRIES_PER_PAGE);

  const nextPage = () => {
    setOffset((o) => o + ENTRIES_PER_PAGE);
  };

  const prevPage = () => {
    setOffset((o) => o - ENTRIES_PER_PAGE);
  };

  if (activity.fetching || loading || loadingFeatureLimit) {
    return <LoadingLogo />;
  }

  if (!enabledBySubscriptionPlan) {
    return (
      <PaywallModal
        isOpen
        onClose={() =>
          navigate(paths.settings.organization.general(organization!.id))
        }
      />
    );
  }

  return (
    <Table
      cols={3}
      currentPage={currentPage}
      totalPages={totalPages}
      total={activity.count}
      onNextPage={nextPage}
      onPrevPage={prevPage}
    >
      <TableHeader>
        <TableCell>Email</TableCell>
        <TableCell>Date</TableCell>
        <TableCell>Action</TableCell>
        <TableCell>Target</TableCell>
      </TableHeader>
      {activity.data?.map((activityLog) => (
        <TableRow key={activityLog.eventDate}>
          <TableCell>{activityLog.userEmail}</TableCell>
          <TableCell>
            <FormattedDate date={activityLog.eventDate} />
          </TableCell>
          <TableCell>{humanizeActionName(activityLog)}</TableCell>
          <TableCell>{humanizeTargetName(activityLog)}</TableCell>
        </TableRow>
      ))}
    </Table>
  );
};

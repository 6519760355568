import {
  urqlClient,
  CompositeSceneFullData,
  DeleteCompositeSceneElementMutation,
} from '@vizcom/shared/data-access/graphql';

import { SyncedActionType } from '../../SyncedAction';

export const DeleteCompositeSceneElementAction: SyncedActionType<
  CompositeSceneFullData,
  {
    type: 'deleteCompositeSceneElement';
    id: string;
  }
> = {
  type: 'deleteCompositeSceneElement',
  optimisticUpdater: ({ payload }, scene) => {
    const elementIndex = scene.compositeSceneElements.nodes.findIndex(
      (el) => el.id === payload.id
    );
    if (elementIndex !== -1) {
      scene.compositeSceneElements.nodes.splice(elementIndex, 1);
    }
  },
  remoteUpdater: async ({ payload }) => {
    const res = await urqlClient.mutation(DeleteCompositeSceneElementMutation, {
      input: {
        id: payload.id,
      },
    });

    if (res?.error) {
      throw new Error(
        `Error while deleting element, please retry. ${
          res.error.graphQLErrors[0]?.message ?? res.error.message
        }`
      );
    }
  },
};

import styled from 'styled-components';
import { Button, Text } from '@vizcom/shared-ui-components';

export const Drawing = styled.div`
  position: relative;
  height: 100%;
  background: repeating-conic-gradient(#d7d7d7 0% 25%, #ffffff 0% 50%) 50% / 8px
    8px;
  > img {
    width: 100%;
    object-fit: contain;
    pointer-events: none;
  }
`;

export const PlaceHolder = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.deprecated.white};
  color: ${({ theme }) => theme.text.subtext};
`;

export const Img2Img = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  background: ${({ theme }) => theme.surface.primary};
  color: ${({ theme }) => theme.text.body};
  border-radius: ${({ theme }) => theme.borderRadius.m};
`;

export const TextInput = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 30px;
  min-width: 30px;
  display: inline-block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 24px;
  color: ${({ theme }) => theme.surface.primary};
  outline: none;
  overflow: hidden;
  background-color: transparent;
  appearance: auto;
  resize: none;
  border: none;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  line-height: 1.3;
  letter-spacing: 0.5px;

  :empty::before {
    content: attr(placeholder);
    color: ${({ theme }) => theme.text.subtext};
  }
`;

export const ColorSwatch = styled.div`
  height: 16px;
  width: 16px;
  outline: none;
  -webkit-appearance: none;
  background: none;
  border: 1px solid white;
  border-radius: 50%;
`;

export const Input = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  gap: 8px;
`;

export const InputOption = styled.button<{ $selected?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border: none;
  outline: none;
  background: ${({ theme, $selected }) =>
    $selected ? theme.surface.tertiary : 'none'};
  color: ${({ theme }) => theme.text.subtext};
  cursor: pointer;
  padding: 0;

  border-radius: ${({ theme }) => theme.borderRadius.s};
  color: #fff;

  &:hover {
    background: ${({ theme }) => theme.surface.tertiary};
  }
`;

export const Label = styled.div`
  color: ${({ theme }) => theme.text.subtext};
`;

export const ExtraPopup = styled.div<{ $top: number; $right: number }>`
  position: absolute;
  padding: 4px;
  top: ${({ $top }) => $top}px;
  right: ${({ $right }) => $right}px;
  background: ${({ theme }) => theme.surface.primary};
  border-radius: ${({ theme }) => theme.borderRadius.l};
  z-index: 1;
`;

export const InitialWorkbenchElement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const InitialWorkbenchElementButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InitialWorkbenchElementButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 16px;
  font-size: 17px;
  font-weight: 600;
  height: 48px;
`;

export const InitialWorkbenchElementText = styled(Text)`
  margin-top: 8px;
  max-width: 90px;
  text-align: center;
`;

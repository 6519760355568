import { Svg } from '@react-three/drei';
import { ThreeEvent } from '@react-three/fiber';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { CompositeSceneData } from '@vizcom/shared/data-access/graphql';
import {
  Button,
  EditIcon,
  Model3dIconUrl,
  Tooltip,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

import { useWorkbenchSyncedState } from '../../../lib/useWorkbenchSyncedState';
import { useIsWorkbenchViewer } from '../../../lib/utils';
import { WorkbenchElementExtra } from '../../WorkbenchElementExtra';
import { getElementSize } from '../../helpers';
import { CustomImage } from '../../utils/CustomImage';

interface WorkbenchElementDrawingProps {
  workbenchId: string;
  element: CompositeSceneData;
  isDragging: boolean;
  isResizing: boolean;
  singleFocused: boolean;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
  setEditingElementId: (id: string | null) => void;
}

export const WorkbenchElementCompositeScene = ({
  workbenchId,
  element,
  singleFocused,
  isDragging,
  isResizing,
  handleAction,
  setEditingElementId,
}: WorkbenchElementDrawingProps) => {
  const theme = useTheme();
  const isViewer = useIsWorkbenchViewer();
  const { width, height } = getElementSize(element);
  const editorPath = paths.workbench.compositeSceneEditor(
    workbenchId,
    element.id
  );

  const handleDoubleClickOnImage = (e: ThreeEvent<MouseEvent>) => {
    if (e.ctrlKey) {
      window.open(editorPath, '_blank');
    } else {
      setEditingElementId(element.id);
    }
  };

  if (isResizing) {
    return (
      <mesh scale={[width, height, 1]}>
        <planeGeometry />
        <meshBasicMaterial
          color={element.sceneBackgroundColorHex ?? theme.surface.tertiary}
        />
      </mesh>
    );
  }

  return (
    <>
      {element.thumbnailPath ? (
        <CustomImage
          onDoubleClick={handleDoubleClickOnImage}
          scale={[width, height]}
          url={element.thumbnailPath}
        />
      ) : (
        <>
          <CustomImage
            onDoubleClick={handleDoubleClickOnImage}
            scale={[width, height]}
            url={null}
          />
          <Svg src={Model3dIconUrl} position={[-32, 32, 0]} scale={4} />
        </>
      )}
      {!isDragging && singleFocused && !isViewer && !isResizing && (
        <WorkbenchElementExtra
          workbenchId={workbenchId}
          element={element}
          handleAction={handleAction}
          position={[0, height / 2, 0]}
          pivot={element.y}
        >
          <Tooltip tip="Edit" position="top">
            <Button as={Link} to={editorPath} variant="tertiary" size="icon">
              <EditIcon />
            </Button>
          </Tooltip>
        </WorkbenchElementExtra>
      )}
    </>
  );
};

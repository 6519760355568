import { shaderMaterial } from '@react-three/drei';
import { extend, useFrame } from '@react-three/fiber';
import { useRef } from 'react';
import { DoubleSide, ShaderMaterial, Texture } from 'three';

export const SelectionOffset = ({
  distanceTexture,
  distance,
  drawingSize,
}: {
  distanceTexture: Texture;
  distance: number;
  drawingSize: [number, number];
}) => {
  const matRef = useRef<ShaderMaterial>(null!);
  useFrame(() => {
    if (matRef.current) {
      matRef.current.uniforms.u_time.value = (performance.now() / 1000) % 1000;
    }
  });
  return (
    <mesh scale={[drawingSize[0], drawingSize[1], 1]}>
      <planeGeometry args={[1, 1, 1, 1]} />
      <selectionOffsetMaterial
        ref={matRef}
        key={SelectionOffsetMaterialImpl.key}
        transparent
        side={DoubleSide}
        u_distanceTexture={distanceTexture}
        u_distance={distance}
        u_drawingSize={[drawingSize[0], drawingSize[1]]}
        u_resolution={[window.innerWidth, window.innerHeight]}
        u_time={0}
      />
    </mesh>
  );
};

const SelectionOffsetMaterialImpl = shaderMaterial(
  {
    u_distanceTexture: null,
    u_distance: 0,
    u_time: 0,
    u_drawingSize: [0, 0],
    u_resolution: [0, 0],
  },
  /* glsl */ `
  varying vec2 v_uv;

  void main() {
    gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(position, 1.);
    v_uv = uv;
  }
`,
  /* glsl */ `
  precision highp float;

  varying vec2 v_uv;

  uniform sampler2D u_distanceTexture;
  uniform float u_distance;
  uniform float u_time;
  uniform vec2 u_drawingSize;
  uniform vec2 u_resolution;

  uniform mat4 projectionMatrix;
  uniform mat4 modelMatrix;

  float sampleDistance(vec2 uv) {
    return step(texture(u_distanceTexture, uv).r, u_distance);
  }
  vec3 blue = vec3(0.3, 0.3, 0.93);
  vec3 white = vec3(1.0, 1.0, 1.0);

  void main() {

    mat4 m = projectionMatrix * modelMatrix;

    float thickness = 1.5;
    float threshold = 0.5;
    vec3 delta = vec3(thickness / (m * vec4(u_drawingSize, 0.0, 0.0)).xy, 0.0);

    // Edge detection. Exterior of texture is considered black.
    float left = v_uv.x - 2.0 * delta.x <= 0.0 ? 0.0 : sampleDistance(v_uv - delta.xz);
    float right = v_uv.x + 2.0 * delta.x >= 1.0 ? 0.0 : sampleDistance(v_uv + delta.xz);
    float top = v_uv.y - 2.0 * delta.y <= 0.0 ? 0.0 : sampleDistance(v_uv - delta.zy);
    float bottom = v_uv.y + 2.0 * delta.y >= 1.0 ? 0.0 : sampleDistance(v_uv + delta.zy);
    float minValue = min(min(right, left), min(top, bottom));
    float maxValue = max(max(right, left), max(top, bottom));

    if (minValue < threshold && maxValue >= threshold) {
      //dash pattern for the line.
      float dotSize = 25.0;
      float speed = 35.0;
      float dashPattern = step(mod(gl_FragCoord.x + gl_FragCoord.y - u_time * speed, dotSize) / dotSize, 0.5);
      gl_FragColor = vec4(vec3(dashPattern), 1.0);
    } else {
      // Transparent color for the rest.
      gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);
    }
    gl_FragColor = gl_FragColor + vec4(blue, 0.2 * sampleDistance(v_uv) * (1.0 - gl_FragColor.a));

    //uncomment to see distance texture
    //gl_FragColor = vec4(vec3(abs(texture(u_distanceTexture, v_uv).r)/255.0), 1.0);
  }
  `
);
extend({ SelectionOffsetMaterial: SelectionOffsetMaterialImpl });

type SelectionOffsetMaterialType = JSX.IntrinsicElements['shaderMaterial'] & {
  u_distanceTexture: Texture;
  u_distance: number;
  u_time: number;
  u_drawingSize: [number, number];
  u_resolution: [number, number];
  u_darkenNonSelected?: boolean;
};

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      selectionOffsetMaterial: SelectionOffsetMaterialType;
    }
  }
}

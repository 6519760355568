import { useState } from 'react';
import styled from 'styled-components';
import {
  ColorPicker,
  ColorWheelIcon,
  ToolbarButton,
} from '@vizcom/shared-ui-components';

import { textColors } from '../../constants';
import { ColorSwatch, ExtraPopup, InputOption } from './style';

type WorkbenchElementColorProps = {
  color: string;
  handleChangeColor: (newColor: string) => void;
  // TODO: get rid of this param once we switch from ExtraPopup to RichTooltip
  $right?: number;
};

export const WorkbenchElementColor = ({
  color,
  handleChangeColor,
  $right = 0,
}: WorkbenchElementColorProps) => {
  const [isSelectingColor, setIsSelectingColor] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);

  return (
    <>
      <ToolbarButton
        icon={<ColorSwatch style={{ background: color ?? '#000000' }} />}
        tooltip="Text color"
        onClick={() => {
          setIsSelectingColor(!isSelectingColor);
          setShowColorPicker(false);
        }}
      />

      {isSelectingColor && (
        <ExtraPopup $top={-75} $right={$right}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(7, 1fr)',
            }}
          >
            {textColors.map((c) => (
              <InputOption key={c} onClick={() => handleChangeColor(c)}>
                <ColorSwatch style={{ background: c }} />
              </InputOption>
            ))}
            <InputOption
              onClick={() => {
                setShowColorPicker(true);
                setIsSelectingColor(false);
              }}
            >
              <ColorWheelIcon />
            </InputOption>
          </div>
        </ExtraPopup>
      )}

      {showColorPicker && (
        <ExtraPopup
          $top={-235}
          $right={$right}
          style={{
            padding: 0,
          }}
        >
          <StyledColorPicker
            color={color ?? '#000000'}
            onChange={(c) => handleChangeColor(c)}
          />
        </ExtraPopup>
      )}
    </>
  );
};

const StyledColorPicker = styled(ColorPicker)`
  padding: 0.75rem;
  & .saturation {
    width: 220px;
    height: 140px;
  }
`;

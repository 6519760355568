import { useMemo } from 'react';
import {
  EraserIcon,
  MagicEraserIcon,
  ToolbarButtonState,
  ToolbarMenuButton,
} from '@vizcom/shared-ui-components';

import {
  useWorkbenchStudioState,
  WorkbenchTool,
  WorkbenchStudioToolType,
} from '../../studioState';
import { Hotkey } from '../../style';
import { EraseSettings } from './EraseSettings';

interface EraserButtonProps {
  currentTool: WorkbenchStudioToolType;
  disabled?: boolean;
  isMenuOpen: boolean;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onChangeTool: (tool: WorkbenchStudioToolType) => void;
}

export const ERASER_TOOLS: WorkbenchTool[] = [
  {
    type: WorkbenchStudioToolType.Eraser,
    icon: <EraserIcon />,
    tooltip: 'Eraser',
    hotkey: 'E',
  },
  {
    type: WorkbenchStudioToolType.MagicEraser,
    icon: <MagicEraserIcon />,
    tooltip: 'Magic Eraser',
    hotkey: 'shift+E',
  },
];

export const EraserButton = ({
  currentTool,
  disabled,
  isMenuOpen,
  setIsMenuOpen,
  onChangeTool,
}: EraserButtonProps) => {
  const studioState = useWorkbenchStudioState();
  const selectedEraserTool = useMemo(
    () =>
      ERASER_TOOLS.find(({ type }) => type === studioState.tool) ||
      ERASER_TOOLS[0],
    [studioState.tool]
  );
  const state = useMemo(
    () =>
      disabled
        ? ToolbarButtonState.DISABLED
        : ERASER_TOOLS.some((tool) => tool.type === currentTool)
        ? ToolbarButtonState.ACTIVE
        : ToolbarButtonState.INACTIVE,
    [currentTool, disabled]
  );

  return (
    <ToolbarMenuButton
      icon={selectedEraserTool.icon}
      tooltip={
        disabled ? (
          'Select a 2D layer to use this tool'
        ) : (
          <>
            {selectedEraserTool.tooltip}{' '}
            <Hotkey>{selectedEraserTool.hotkey}</Hotkey>
          </>
        )
      }
      onClick={() => onChangeTool(selectedEraserTool.type)}
      state={state}
      showMenuIcon
      menu={
        <EraseSettings
          onSelect={() => setIsMenuOpen(!isMenuOpen)}
          isUseCheckmarks
        />
      }
      controlledMenuState={[isMenuOpen, setIsMenuOpen]}
    />
  );
};

import { Text as DreiText } from '@react-three/drei';
import React, { ReactElement, ReactNode, Suspense, useState } from 'react';
import { DefaultTheme, useTheme } from 'styled-components';
import { filterExists } from '@vizcom/shared/js-utils';

import { RoundedPlaneGeometry } from './RoundedPlaneGeometry';
import InterBold from './fonts/Inter-Bold.ttf';
import InterMedium from './fonts/Inter-Medium.ttf';
import InterSemiBold from './fonts/Inter-SemiBold.ttf';
import MontserratBold from './fonts/Montserrat-Bold.ttf';
import MontserratMedium from './fonts/Montserrat-Medium.ttf';
import OpenSansBold from './fonts/OpenSans-Bold.ttf';
import OpenSansMedium from './fonts/OpenSans-Medium.ttf';
import RobotoBold from './fonts/Roboto-Bold.ttf';
import RobotoMedium from './fonts/Roboto-Medium.ttf';
import { VizcomRenderingOrderEntry } from './threeRenderingOrder';

export const FONTS = {
  Inter: {
    medium: InterMedium,
    bold: InterBold,
    semiBold: InterSemiBold,
  },
  Roboto: {
    medium: RobotoMedium,
    bold: RobotoBold,
  },
  Montserrat: {
    medium: MontserratMedium,
    bold: MontserratBold,
  },
  'Open Sans': {
    medium: OpenSansMedium,
    bold: OpenSansBold,
  },
};

type FontFamilies = keyof typeof FONTS;
type FontWeights<F extends FontFamilies> = keyof typeof FONTS[F];

type DreiTextProps = Parameters<typeof DreiText>[0];

type Adornment = {
  svg: ReactNode;
  width: number;
};

type CustomTextProps<F extends FontFamilies> = Omit<
  DreiTextProps,
  'font' | 'children' | 'position' | 'scale'
> & {
  content: string;
  font: F;
  fontWeight: FontWeights<F>;
  textDecoration?: 'underline' | 'line-through' | 'none';
  radius?: keyof DefaultTheme['borderRadius'];
  backgroundColor?: string;
  paddingX?: number;
  paddingY?: number;
  startAdornment?: Adornment;
  endAdornment?: Adornment;
  position?: [number, number, number];
  scale?: [number, number, number];
  onSync?: (troika: any) => void;
};

// Custom Text element implementing the fonts we're supporting in the app + underline and line-through text decoration
export const CustomText = React.forwardRef(
  (
    {
      content,
      font,
      fontWeight,
      position,
      textDecoration,
      visible,
      backgroundColor,
      radius = 's',
      paddingX,
      paddingY,
      startAdornment,
      endAdornment,
      scale,
      onSync,
      anchorX,
      ...rest
    },
    ref: React.Ref<any>
  ) => {
    const [textTroikaInstance, setTextTroikaInstance] = useState<any>(null);
    const [textSize, setTextSize] = useState([0, 0]);
    const fontUrl = FONTS[font][fontWeight] as string;

    const theme = useTheme();

    const elementPosition = position ?? [0, 0, 0];

    let textAlignedAnchorX = anchorX ?? 'left';
    const scaleX = scale ? scale[0] : 1;
    if (rest.maxWidth) {
      if (rest.textAlign === 'left') {
        textAlignedAnchorX = 'left';
        elementPosition[0] -= (rest.maxWidth / 2) * scaleX;
      } else if (rest.textAlign === 'right') {
        textAlignedAnchorX = 'right';
        elementPosition[0] += (rest.maxWidth / 2) * scaleX;
      }
    }

    const totalWidth =
      textSize[0] +
      (paddingX ?? 0) +
      (startAdornment ? startAdornment.width : 0) +
      (endAdornment ? endAdornment.width : 0);

    return (
      <Suspense fallback={null}>
        <group
          position={elementPosition}
          visible={visible}
          scale={scale}
          userData={{
            vizcomRenderingOrder: [
              {
                zIndex: 0,
              } satisfies VizcomRenderingOrderEntry,
            ],
          }}
        >
          {backgroundColor && textTroikaInstance && (
            <group
              position={[endAdornment ? endAdornment.width / 2 : 0, 0, 0]}
              userData={{
                vizcomRenderingOrder: [
                  {
                    zIndex: 0,
                  } satisfies VizcomRenderingOrderEntry,
                ],
              }}
            >
              <mesh
                position={[
                  textAlignedAnchorX === 'left'
                    ? textSize[0] / 2
                    : textAlignedAnchorX === 'right'
                    ? -textSize[0] / 2
                    : 0,
                  rest.anchorY === 'top'
                    ? -textSize[1] / 2
                    : rest.anchorY === 'bottom'
                    ? textSize[1] / 2
                    : 0,
                  0,
                ]}
              >
                <RoundedPlaneGeometry
                  width={totalWidth + (paddingX ?? 0)}
                  height={textSize[1] + (paddingY ?? 0)}
                  radius={parseFloat(theme.borderRadius[radius])}
                />
                <meshBasicMaterial color={backgroundColor} transparent />
              </mesh>
            </group>
          )}
          <group
            userData={{
              vizcomRenderingOrder: [
                {
                  zIndex: 1,
                } satisfies VizcomRenderingOrderEntry,
              ],
            }}
          >
            {startAdornment && (
              <group
                position={[
                  -startAdornment.width / 2 + (paddingX ?? 0) / 2,
                  0,
                  0,
                ]}
              >
                {startAdornment.svg}
              </group>
            )}
            <DreiText
              position={[startAdornment ? startAdornment.width / 2 : 0, 0, 0]}
              ref={ref}
              font={fontUrl}
              {...rest}
              anchorX={textAlignedAnchorX}
              onSync={(troika) => {
                setTextTroikaInstance(troika);
                setTextSize([
                  Math.abs(
                    troika.textRenderInfo.blockBounds[0] -
                      troika.textRenderInfo.blockBounds[2]
                  ),
                  Math.abs(
                    troika.textRenderInfo.blockBounds[1] -
                      troika.textRenderInfo.blockBounds[3]
                  ),
                ]);
                if (onSync) {
                  onSync(troika);
                }
              }}
            >
              {content}
            </DreiText>
            {endAdornment && (
              <group
                position={[
                  totalWidth - endAdornment.width - (paddingX ?? 0) / 2,
                  0,
                  0,
                ]}
              >
                {endAdornment.svg}
              </group>
            )}
          </group>
          <group
            userData={{
              vizcomRenderingOrder: [
                {
                  zIndex: 2,
                } satisfies VizcomRenderingOrderEntry,
              ],
            }}
          >
            {textDecoration &&
              textDecoration !== 'none' &&
              textTroikaInstance && (
                <TextDecoration
                  type={textDecoration}
                  color={rest.color}
                  troikaInstance={textTroikaInstance._textRenderInfo}
                  content={content}
                />
              )}
          </group>
        </group>
      </Suspense>
    );
  }
) as <T extends FontFamilies>(props: CustomTextProps<T>) => ReactElement; // this cast is required to use generics with forwardRef
// source: https://stackoverflow.com/a/58473012

// Troika text doesn't provide a way to add text decoration, so we need to draw it manually
// we do this by using the getSelectionRects function from troika and drawing a plane at the position of the text decoration
// because this troika function is used for carret display, it will also show a text decoration for line break characters, so we need to filter those out
const TextDecoration = (props: {
  color: DreiTextProps['color'];
  troikaInstance: any;
  content: string;
  type: 'underline' | 'line-through';
}) => {
  // need to remove line break from the text content before calling getSelectionRects else we also get the rects for \n
  const filledLinesCharacterIndices = getTextLineCharacterIndices(
    props.content
  );
  const rects = filledLinesCharacterIndices
    .flatMap(([start, end]) =>
      getSelectionRects(props.troikaInstance, start, end)
    )
    .filter(filterExists);

  return (
    <>
      {rects.map((rect, i) => (
        <mesh
          key={i}
          position={[
            (rect.left + rect.right) / 2,
            props.type === 'line-through'
              ? (rect.bottom + rect.top) / 2
              : rect.bottom + 2,
            0,
          ]}
          scale={[rect.right - rect.left, 2, 1]}
        >
          <planeGeometry args={[1, 1]} />
          <meshBasicMaterial color={props.color || 'black'} transparent />
        </mesh>
      ))}
    </>
  );
};

const getTextLineCharacterIndices = (text: string) => {
  let characterCount = 0;
  return text
    .split('\n')
    .map((line) => {
      const startCharacterCount = characterCount;
      characterCount += line.length + 1;
      return [startCharacterCount, characterCount - 1];
    })
    .filter(([start, end]) => start !== end);
};

/**
 * From: https://github.com/protectwise/troika/blob/main/packages/troika-three-text/src/selectionUtils.js
 * Given start and end character indexes, return a list of rectangles covering all the
 * characters within that selection.
 * @param {TroikaTextRenderInfo} textRenderInfo
 * @param {number} start - index of the first char in the selection
 * @param {number} end - index of the first char after the selection
 * @return {Array<{left, top, right, bottom}> | null}
 */
export function getSelectionRects(
  textRenderInfo: any,
  start: number,
  end: number
): Array<{ left: number; top: number; right: number; bottom: number }> | null {
  const rects: Array<{
    left: number;
    top: number;
    right: number;
    bottom: number;
  }> = [];
  if (textRenderInfo) {
    const { caretPositions } = textRenderInfo;

    // Normalize
    if (end < start) {
      const s = start;
      start = end;
      end = s;
    }
    start = Math.max(start, 0);
    end = Math.min(end, caretPositions.length + 1);

    // Build list of rects, expanding the current rect for all characters in a run and starting
    // a new rect whenever reaching a new line or a new bidi direction
    let currentRect: {
      left: number;
      top: number;
      right: number;
      bottom: number;
    } | null = null;
    for (let i = start; i < end; i++) {
      const x1 = caretPositions[i * 4];
      const x2 = caretPositions[i * 4 + 1];
      const left = Math.min(x1, x2);
      const right = Math.max(x1, x2);
      const bottom = caretPositions[i * 4 + 2];
      const top = caretPositions[i * 4 + 3];
      if (
        !currentRect ||
        bottom !== currentRect.bottom ||
        top !== currentRect.top ||
        left > currentRect.right ||
        right < currentRect.left
      ) {
        currentRect = {
          left: Infinity,
          right: -Infinity,
          bottom,
          top,
        };
        rects.push(currentRect);
      }
      currentRect.left = Math.min(left, currentRect.left);
      currentRect.right = Math.max(right, currentRect.right);
    }

    // Merge any overlapping rects, e.g. those formed by adjacent bidi runs
    rects.sort((a, b) => b.bottom - a.bottom || a.left - b.left);
    for (let i = rects.length - 1; i-- > 0; ) {
      const rectA = rects[i];
      const rectB = rects[i + 1];
      if (
        rectA.bottom === rectB.bottom &&
        rectA.top === rectB.top &&
        rectA.left <= rectB.right &&
        rectA.right >= rectB.left
      ) {
        rectB.left = Math.min(rectB.left, rectA.left);
        rectB.right = Math.max(rectB.right, rectA.right);
        rects.splice(i, 1);
      }
    }
  }
  return rects;
}

import { useStore } from '@react-three/fiber';
import { useNavigate } from 'react-router-dom';
import { useWorkbenchContent } from '@vizcom/shared/data-access/graphql';
import { MenuDivider, MenuItem } from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

import { ClientSideWorkbenchElementData } from '../../lib/clientState';
import { downloadWorkbenchDrawings } from '../../lib/downloadWorkbenchDrawings';
import { useWorkbenchSyncedState } from '../../lib/useWorkbenchSyncedState';
import { elementIsDrawing } from '../../lib/utils';
import { handleImportFilesWithSceneContext } from '../utils/importFiles';

interface WorkbenchMenuFileItemProps {
  setIsEditingName: (isEditing: boolean) => void;
  setDuplicateModalOpen: (isOpen: boolean) => void;
  setMoveModalOpen: (isOpen: boolean) => void;
  workbenchId: string;
  elements: ClientSideWorkbenchElementData[];
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
}

export const WorkbenchMenuFileItem = ({
  setIsEditingName,
  workbenchId,
  setDuplicateModalOpen,
  setMoveModalOpen,
  elements,
  handleAction,
}: WorkbenchMenuFileItemProps) => {
  const store = useStore();
  const navigate = useNavigate();
  const { data } = useWorkbenchContent(workbenchId);

  const handleExportImages = async () => {
    const allDrawings = elements.filter(elementIsDrawing);
    await downloadWorkbenchDrawings(allDrawings, data?.name ?? '', workbenchId);
  };

  return (
    <>
      <MenuItem label="File">
        <MenuItem
          label="New file"
          onClick={() =>
            navigate(paths.workbench.new(data?.folderId || ''), {
              state: {
                from: 'workbenchMenu',
              },
            })
          }
        />
        <MenuItem
          label="Duplicate file"
          onClick={() => {
            setDuplicateModalOpen(true);
          }}
        />
        <MenuItem
          label="Move to"
          onClick={() => {
            setMoveModalOpen(true);
          }}
        />
        <MenuItem
          label="Rename"
          // In fact, setIsEditingName, by focusing the input, will close the menu.
          // We don't need to close the parent here, because it would cause a focus on the Vizcom trigger that would not focus the input.
          closeParentOnClick={false}
          onClick={() => {
            setIsEditingName(true);
          }}
        />
        <MenuDivider />
        <MenuItem
          label="Open / Import"
          onClick={() =>
            handleImportFilesWithSceneContext({
              scene: store.getState().scene,
              camera: store.getState().camera,
              handleAction,
            })
          }
        />
        <MenuItem label="Export as .ZIP" onClick={handleExportImages} />
      </MenuItem>
    </>
  );
};

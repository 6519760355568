import React, { useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { useWorkbenchSyncedState } from '../../../lib/useWorkbenchSyncedState';
import { isDarkColor } from '../../helpers';
import { CustomHtml } from '../../utils/CustomHtml';
import { CustomText } from '../../utils/CustomText';
import { RoundedPlaneGeometry } from '../../utils/RoundedPlaneGeometry';

export const SECTION_TITLE_HEIGHT = 22;

interface TitleDimensions {
  width: number;
  needsEllipses: boolean;
  xPosition: number;
}

export const SectionTitle = ({
  id,
  title,
  width,
  height,
  handleAction,
  editingTitle,
  setEditingTitle,
  color,
}: {
  id: string;
  title: string;
  width: number;
  height: number;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
  editingTitle: boolean;
  setEditingTitle: (editingTitle: boolean) => void;
  color: string;
}) => {
  const theme = useTheme();
  const titleInputRef = useRef<HTMLDivElement>(null!);
  const [titleDimensions, setTitleDimensions] = useState<TitleDimensions>({
    width: 0,
    needsEllipses: false,
    xPosition: 0,
  });

  const titleColor = isDarkColor(color) ? '#FFFFFF' : '#000000';
  const yPosition = height / 2 + 14;

  const calculateTitleDimensions = (textRenderInfo: any): TitleDimensions => {
    const textWidth = textRenderInfo.blockBounds[2] + 10;
    const needsEllipses = textWidth > width;
    const xPosition = width - textWidth < 0 ? 0 : textWidth / 2 + -width / 2;

    return {
      width: textWidth,
      needsEllipses,
      xPosition,
    };
  };

  // Focus handling for title editing
  useEffect(() => {
    if (editingTitle && titleInputRef.current) {
      titleInputRef.current.innerText = title || '';
      setTimeout(() => titleInputRef.current?.focus(), 0);
    }
  }, [editingTitle, title]);

  const handleTitleSync = (text: any) => {
    setTitleDimensions(calculateTitleDimensions(text._textRenderInfo));
  };

  const updateSectionTitle = (newTitle: string) => {
    handleAction({
      type: 'updateSection',
      elementId: id,
      title: newTitle.trim(),
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      updateSectionTitle(e.currentTarget.innerText);
      setEditingTitle(false);
      e.preventDefault();
    }
  };

  return (
    <group userData={{ cursor: 'move' }}>
      <mesh
        scale={[width, SECTION_TITLE_HEIGHT, 1]}
        position={[0, height / 2 + SECTION_TITLE_HEIGHT / 2, 0]}
        onDoubleClick={(e) => {
          e.stopPropagation();
          setEditingTitle(true);
        }}
        userData={{ vizcomRenderingOrder: [{ zIndex: 1 }] }}
      >
        <planeGeometry />
        <meshBasicMaterial transparent opacity={0} />
      </mesh>

      <CustomHtml position={[0, yPosition, 0]} transform>
        <div style={{ width }}>
          <TitleInput
            ref={titleInputRef}
            contentEditable
            onFocus={(e) => window.getSelection()?.selectAllChildren(e.target)}
            onBlur={(e) => {
              updateSectionTitle(e.target.innerText);
              setEditingTitle(false);
            }}
            onKeyDown={handleKeyDown}
            style={{
              display: editingTitle ? 'inline-flex' : 'none',
              width,
              color: titleColor,
              background: color,
            }}
          />
        </div>
      </CustomHtml>

      {!editingTitle && (
        <>
          <mesh position={[titleDimensions.xPosition, yPosition, 0]}>
            <RoundedPlaneGeometry
              width={Math.min(titleDimensions.width, width)}
              height={20}
              radius={parseFloat(theme.borderRadius.s)}
            />
            <meshBasicMaterial color={color} transparent />
          </mesh>

          <CustomText
            color={titleColor}
            position={[5 - width / 2, yPosition, 0]}
            fontSize={12}
            font={'Inter'}
            fontWeight={'semiBold'}
            anchorX={'left'}
            maxWidth={width - (titleDimensions.needsEllipses ? 15 : 0)}
            whiteSpace="nowrap"
            clipRect={[
              0,
              -10,
              width - (titleDimensions.needsEllipses ? 22 : 0),
              10,
            ]}
            content={title}
            onSync={handleTitleSync}
          />

          {titleDimensions.needsEllipses && (
            <CustomText
              color={titleColor}
              position={[width / 2 - 15, yPosition, 0]}
              fontSize={12}
              font={'Inter'}
              fontWeight={'semiBold'}
              anchorX={'left'}
              content={'...'}
            />
          )}
        </>
      )}
    </group>
  );
};

const TitleInput = styled.div`
  display: inline-flex;
  min-width: 20px;
  width: 100%;
  height: 26px;
  background: white;
  border: 1px solid ${({ theme }) => theme.border.primary};
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: ${({ theme }) => theme.borderRadius.m};
  font-size: 12px;
  letter-spacing: 0.1px;
  font-weight: 600;
  margin: 0;
  padding: 0 4px;
  white-space: nowrap;
  overflow: hidden;
  outline: none;
  align-items: center;
`;

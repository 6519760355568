import { animated } from '@react-spring/web';
import { TransitionStatus } from 'react-transition-group';
import styled from 'styled-components';
import {
  CloseIcon,
  FloatingPanel,
  InlineFlex,
  Text,
} from '@vizcom/shared-ui-components';

import {
  useWorkbenchToolState,
  WorkbenchToolType,
} from '../toolbar/WorkbenchToolContext';
import { HtmlOverlay } from '../utils/HtmlOverlay';
import { AssetLibrary } from './AssetLibrary';

export const WorkbenchAssetLibrary = ({
  workbenchId,
}: {
  workbenchId: string;
}) => {
  const { tool, setTool } = useWorkbenchToolState();
  const panelOpen = tool === WorkbenchToolType.ASSET_LIBRARY;

  return (
    <HtmlOverlay>
      <Panel $state={panelOpen ? 'entered' : 'exited'} $hidden={!panelOpen}>
        <InlineFlex
          $justifyContent="space-between"
          style={{ padding: '20px 15px 5px' }}
        >
          <Text type="sh2">Assets</Text>
          <CloseIcon
            style={{ cursor: 'pointer' }}
            onClick={() => setTool(WorkbenchToolType.SELECT)}
          />
        </InlineFlex>
        <AssetLibrary workbenchId={workbenchId} />
      </Panel>
    </HtmlOverlay>
  );
};

const Panel = styled(animated(FloatingPanel))<{
  $state: TransitionStatus;
  $hidden: boolean;
}>`
  /* Positioning */
  position: absolute;
  z-index: 10000001;
  top: calc(1rem + 66px);
  left: 14px;
  bottom: 100px;

  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 250px;

  /* Animation */
  transition: transform 0.2s ease, opacity 0.2s ease;
  opacity: ${({ $hidden }) => ($hidden ? 0 : 1)};
  transform: ${({ $state }) =>
    ['entered', 'entering'].includes($state)
      ? 'translateX(0)'
      : 'translateX(calc(-100% - 14px))'};

  /* Interaction */
  pointer-events: ${({ $hidden }) => ($hidden ? 'none' : 'all')};
  * {
    pointer-events: ${({ $hidden }) => ($hidden ? 'none !important' : 'all')};
  }
`;

import { omit } from 'lodash';
import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import {
  UserClientStateKeys,
  useCurrentUserClientStateByKey,
} from '@vizcom/shared/data-access/graphql';
import {
  CMD_KEY_NAME,
  FilledCaretDownIcon,
  HideIcon,
  MeatballIcon,
  Menu,
  ToolbarButton,
  UnhideIcon,
  useDocumentEventListener,
} from '@vizcom/shared-ui-components';

import {
  Drawing2dStudio,
  DrawingLayer,
  useDrawingSyncedState,
} from '../../lib/useDrawingSyncedState';
import { useIsWorkbenchViewer } from '../../lib/utils';
import { LayerContextMenu } from './LayerContextMenu';
import { LayerName } from './LayerName';
import { ActiveLayerChangeOperation } from './lib/useActiveLayer';
import { LayerMeatballWrapper } from './style';
import { Layout } from './types';
import { getNestedGroupVisibility } from './utils';

export const LayerGroup = ({
  drawing,
  layer,
  depth,
  isLast,
  viewerVisibility,
  isTogglingMultiLayerVisibility,
  activeLayer,
  childCount,
  innerContent,
  hoveringOver,
  parentIsActive,
  setCollapsed,
  setViewerVisibility,
  setTogglingMultiLayerVisibility,
  isolateLayer,
  setActiveLayer,
  handleAction,
  onCreateDrawingsFromImage,
}: {
  drawing: Drawing2dStudio;
  layer: DrawingLayer & { collapsed?: boolean };
  depth: number;
  isLast: boolean;
  viewerVisibility: Record<string, boolean>;
  childCount: number;
  innerContent: React.ReactNode;
  isTogglingMultiLayerVisibility: boolean | undefined;
  activeLayer: string | undefined;
  editingLayer: string | null;
  hoveringOver: boolean;
  parentIsActive: boolean;
  setCollapsed: (groupId: string, collapsed: boolean) => void;
  setViewerVisibility: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  setTogglingMultiLayerVisibility: (v: boolean | undefined) => void;
  isolateLayer: (id: string) => void;
  setActiveLayer: (
    id: string | undefined,
    operation?: ActiveLayerChangeOperation
  ) => void;
  setEditingLayer: (id: string | null) => void;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  onCreateDrawingsFromImage: (
    previews: { preview: ArrayBuffer | Blob; name?: string }[],
    offset?: {
      x: number;
      y: number;
    }
  ) => void;
}) => {
  const layout: Layout =
    useCurrentUserClientStateByKey(UserClientStateKeys.StudioLayout) ||
    'default';
  const [isEditingName, setIsEditingName] = useState(false);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const isViewer = useIsWorkbenchViewer();
  const theme = useTheme();

  const id = layer.id;

  useDocumentEventListener('pointerup', () => {
    setTogglingMultiLayerVisibility(undefined);
  });

  const toggleLayer = () => {
    handleAction({
      type: 'updateLayer',
      id,
      data: {
        visible: !layer.visible,
      },
    });
  };

  const openContextMenu = (open: boolean) => {
    if (!activeLayer?.split('/').includes(id) && open) {
      setActiveLayer(id);
    }
    setShowContextMenu(open);
  };

  const toggleVisibility = () => {
    setTogglingMultiLayerVisibility(!layer.visible);
  };

  const setVisible = (visible: boolean) => {
    handleAction({
      type: 'updateLayer',
      id,
      data: {
        visible,
      },
    });
  };

  const setLayerName = (name: string) => {
    handleAction({
      type: 'updateLayer',
      id,
      data: {
        name,
      },
    });
  };

  if (!layer) return null;

  const isInHiddenGroup = getNestedGroupVisibility(
    layer.parentId,
    drawing.layers.nodes
  );
  const active =
    activeLayer === id || Boolean(activeLayer?.split('/').includes(id));

  return (
    <LayerGroupContainer
      className="hoverable"
      onClick={(e) => {
        e.stopPropagation();
        if (!drawing?.isEditable) return;
        if (e.shiftKey) {
          setActiveLayer(id, 'addTo');
          return;
        }
        if (e[CMD_KEY_NAME]) {
          if (activeLayer?.split('/').includes(id)) {
            setActiveLayer(id, 'remove');
          } else {
            setActiveLayer(id, 'add');
          }
          return;
        }
        setActiveLayer(id);
      }}
      $active={active}
      onContextMenu={(e) => {
        if (!drawing?.isEditable) return;

        e.preventDefault();
        e.stopPropagation();

        setShowContextMenu(true);
      }}
    >
      <Content
        $active={active}
        $hoveringOver={hoveringOver}
        $depth={depth}
        $parentIsActive={parentIsActive}
      >
        <Actions>
          <button
            style={{
              touchAction: 'none',
            }}
            onClick={(e) => {
              e.stopPropagation();
              setCollapsed(layer.id, !layer.collapsed);
            }}
          >
            <FilledCaretDownIcon
              width={24}
              style={{
                fill: theme.icon.primary,
                transform: layer.collapsed ? 'rotate(-90deg)' : 'rotate(0deg)',
              }}
            />
          </button>
          <button
            style={{
              touchAction: 'none',
              padding: '4px',
            }}
            onPointerDown={(e) => {
              if (isViewer) {
                setViewerVisibility((prev) => ({
                  ...prev,
                  [id]: prev[id] !== undefined ? !prev[id] : !layer.visible,
                }));
                return;
              }
              e.stopPropagation();
              try {
                (e.target as HTMLButtonElement).releasePointerCapture(
                  e.pointerId
                );
              } catch {}

              e.preventDefault();
              if (e.altKey) {
                isolateLayer(id);
                return;
              }

              toggleVisibility();
              toggleLayer();
            }}
            onPointerEnter={() => {
              if (isTogglingMultiLayerVisibility !== undefined) {
                setVisible(isTogglingMultiLayerVisibility);
              }
            }}
          >
            {viewerVisibility[id] ?? layer.visible ? (
              <HideIcon
                style={{
                  pointerEvents: 'none',
                  color: isInHiddenGroup
                    ? theme.icon.secondary
                    : theme.icon.primary,
                }}
              />
            ) : (
              <UnhideIcon
                style={{
                  pointerEvents: 'none',
                  color: isInHiddenGroup
                    ? theme.icon.secondary
                    : theme.icon.primary,
                }}
              />
            )}
          </button>
        </Actions>
        <Meta>
          <LayerName
            disabled={!drawing?.isEditable}
            onChange={(value) => setLayerName(value)}
            value={layer.name}
            isEditing={isEditingName}
            setIsEditing={setIsEditingName}
          />
        </Meta>
        <ChildCount>{childCount}</ChildCount>
        <Menu
          hideArrow
          placement={layout === 'default' ? 'right' : 'left'}
          offset={layout === 'default' ? 20 : 230}
          renderLabel={(props, interactionProps) => (
            <ToolbarButton
              icon={
                <LayerMeatballWrapper
                  $active={active}
                  $is3d={false}
                  $open={props.isOpen}
                >
                  <MeatballIcon />
                </LayerMeatballWrapper>
              }
              tooltip="More actions"
              tooltipOptions={{ placement: 'right', disabled: props.isOpen }}
              {...interactionProps}
              onClick={(e) => {
                e.stopPropagation();
                (interactionProps.onClick as React.MouseEventHandler)(e);
              }}
              buttonProps={{ tabIndex: -1, variant: 'tertiary' }}
            />
          )}
          open={showContextMenu}
          setIsOpen={openContextMenu}
          disableFocusManager // do not let menu handle focus change to make sure focus isn't lost when clicking on "rename layer" action
        >
          <LayerContextMenu
            drawing={drawing}
            layer={omit(layer, 'collapsed')}
            isLast={isLast}
            activeLayer={activeLayer}
            setViewerVisibility={setViewerVisibility}
            setActiveLayer={setActiveLayer}
            handleAction={handleAction}
            onCreateDrawingsFromImage={onCreateDrawingsFromImage}
            setIsEditingName={setIsEditingName}
            setShowContextMenu={setShowContextMenu}
          />
        </Menu>
      </Content>
      <InnerContent $active={active}>{innerContent}</InnerContent>
    </LayerGroupContainer>
  );
};

const Content = styled.div<{
  $active: boolean;
  $hoveringOver: boolean;
  $depth: number;
  $parentIsActive: boolean;
}>`
  display: grid;
  position: relative;
  z-index: 1;
  width: 100%;
  grid-template-columns: 46px minmax(0, 1fr) max-content 32px;
  padding: 4px;
  padding-left: ${({ $depth }) => $depth * 24 + 4}px;
  align-items: center;
  gap: 4px;
  row-gap: 0px;
  user-select: none;
  min-height: 40px;
  border-radius: 4px;
  border: 1px solid transparent;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  background: ${({ $active, $parentIsActive, theme }) =>
    $active
      ? theme.surface.layer
      : $parentIsActive
      ? theme.button.primaryDisabled
      : theme.surface.primary};

  &:hover {
    border-color: ${({ theme }) => theme.deprecated.primary.default};
    background: ${({ theme, $active, $parentIsActive }) =>
      $active
        ? theme.surface.layer
        : $parentIsActive
        ? theme.button.primaryDisabled
        : theme.surface.secondary};
  }
`;

const LayerGroupContainer = styled.div<{ $active: boolean }>`
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
  min-height: 40px;
`;

const InnerContent = styled.div<{ $active?: boolean }>`
  background: ${({ theme, $active }) =>
    $active ? theme.button.primaryDisabled : 'none'};
  padding-top: 6px;
  margin-top: -6px;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;

  button {
    border: 0;
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    align-items: center;
    background: transparent;
    cursor: pointer;
  }
`;

const Meta = styled.div`
  display: flex;
  flex: 1;
  margin-left: 6px;
  overflow: hidden;
`;

const ChildCount = styled.div`
  color: ${({ theme }) => theme.text.subtextSecondary};
  margin-right: 2px;
  padding: 4px;
`;

import { useRef, useCallback, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';

const Container = styled.div`
  align-self: start;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.surface.tertiary};
  border-radius: ${({ theme }) => theme.borderRadius.s};

  width: 100%;
  height: 100%;

  > canvas {
    width: 100px;
    height: 100px;
  }
`;

type BrushTransformInputProps = {
  aspect: number;
  setAspect: (aspect: number) => void;
  rotation: number;
  setRotation: (rotation: number) => void;
};

export const BrushTransformInput = ({
  aspect,
  setAspect,
  rotation,
  setRotation,
}: BrushTransformInputProps) => {
  const theme = useTheme();
  const dom: React.MutableRefObject<HTMLCanvasElement> = useRef(null!);
  const state: React.MutableRefObject<{
    aspect: number;
    rotation: number;
    handlers: { operation: 'aspect' | 'rotation'; x: number; y: number }[];
    dragging: {
      x: number;
      y: number;
      operation: 'aspect' | 'rotation';
      value: number;
    };
    pointer: number;
    isCapturing: boolean;
  }> = useRef({
    aspect: 0,
    rotation: 0,
    handlers: [],
    dragging: { x: 0, y: 0, operation: 'aspect', value: 0 },
    pointer: -1,
    isCapturing: false,
  });

  useEffect(() => {
    const renderer: HTMLCanvasElement = dom.current;
    const ctx: CanvasRenderingContext2D = renderer.getContext('2d')!;
    const rect: DOMRect = renderer.getBoundingClientRect();
    const radians = (rotation * Math.PI) / 180;
    renderer.width = rect.width * devicePixelRatio;
    renderer.height = rect.height * devicePixelRatio;
    ctx.translate(renderer.width * 0.5, renderer.height * 0.5);
    ctx.rotate(radians);
    ctx.scale(devicePixelRatio, devicePixelRatio);
    ctx.lineWidth = 1 / devicePixelRatio;

    const w = (rect.width - 18) * 0.5;
    const h = (rect.height - 18) * aspect * 0.5;

    ctx.strokeStyle = theme.icon.secondary;
    ctx.lineWidth = 1.5;
    ctx.beginPath();
    ctx.moveTo(-w, 0);
    ctx.lineTo(w, 0);
    ctx.moveTo(0, -h);
    ctx.lineTo(0, h);
    ctx.stroke();
    ctx.beginPath();
    ctx.ellipse(0, 0, w, h, 0, 0, Math.PI * 2);
    ctx.stroke();

    ctx.fillStyle = theme.icon.primary;
    ctx.strokeStyle = theme.surface.secondary;
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.arc(0, -h, 6, 0, Math.PI * 2);
    ctx.fill();
    ctx.stroke();
    ctx.beginPath();
    ctx.arc(0, h, 6, 0, Math.PI * 2);
    ctx.fill();
    ctx.stroke();

    ctx.fillStyle = theme.icon.primary;
    ctx.beginPath();
    ctx.moveTo(w + 2, -5);
    ctx.lineTo(w + 10, 0);
    ctx.lineTo(w + 2, 5);
    ctx.fill();

    state.current.aspect = aspect;
    state.current.rotation = radians;
    state.current.handlers = [
      { operation: 'aspect', x: 0, y: h },
      { operation: 'aspect', x: 0, y: -h },
      { operation: 'rotation', x: w + 5, y: 0 },
    ];
  }, [aspect, rotation, theme]);

  const transformPointer = useCallback(
    (p: { x: number; y: number }, rotate = true) => {
      const renderer: HTMLCanvasElement = dom.current;
      const rect: DOMRect = renderer.getBoundingClientRect();
      const tp = {
        x: p.x - rect.x - rect.width * 0.5,
        y: p.y - rect.y - rect.height * 0.5,
      };
      if (!rotate) {
        return tp;
      }
      const cos = Math.cos(-state.current.rotation);
      const sin = Math.sin(-state.current.rotation);
      return {
        x: tp.x * cos - tp.y * sin,
        y: tp.y * cos + tp.x * sin,
      };
    },
    []
  );

  const getHandler = useCallback(
    (p: { x: number; y: number }, isDragging = false) => {
      const hitRadius = isDragging ? 50 : 12;
      return state.current.handlers.find(
        (h) => Math.sqrt((h.x - p.x) ** 2 + (h.y - p.y) ** 2) < hitRadius
      );
    },
    []
  );

  const pointerdown: React.PointerEventHandler<HTMLCanvasElement> = useCallback(
    (e) => {
      if (state.current.pointer !== -1) {
        return;
      }

      const p = transformPointer({ x: e.clientX, y: e.clientY });
      const handler = getHandler(p);

      if (handler) {
        try {
          dom.current!.setPointerCapture(e.pointerId);
          state.current.isCapturing = true;
          state.current.pointer = e.pointerId;
          state.current.dragging.x = p.x;
          state.current.dragging.y = p.y;
          state.current.dragging.operation = handler.operation;
          state.current.dragging.value = state.current[handler.operation];

          e.preventDefault();
        } catch (err) {
          console.warn('Failed to capture pointer:', err);
        }
      }
    },
    [getHandler, transformPointer]
  );

  const pointermove: React.PointerEventHandler<HTMLCanvasElement> = useCallback(
    (e) => {
      if (!state.current.isCapturing) {
        const p = transformPointer({ x: e.clientX, y: e.clientY });
        dom.current!.style.cursor = getHandler(p) ? 'pointer' : 'default';
        return;
      }

      if (state.current.pointer !== e.pointerId) {
        return;
      }

      e.preventDefault();

      const p = transformPointer(
        { x: e.clientX, y: e.clientY },
        state.current.dragging.operation === 'aspect'
      );

      if (state.current.dragging.operation === 'aspect') {
        const newAspect = Math.min(
          Math.max(
            (p.y * state.current.dragging.value) / state.current.dragging.y,
            0.01
          ),
          1
        );

        if (Math.abs(newAspect - aspect) > 0.001) {
          setAspect(newAspect);
        }
      } else {
        const newRotation = (Math.atan2(p.y, p.x) * 180) / Math.PI;

        if (Math.abs(newRotation - rotation) > 0.1) {
          setRotation(newRotation);
        }
      }
    },
    [getHandler, setAspect, setRotation, transformPointer, aspect, rotation]
  );

  const pointerup: React.PointerEventHandler<HTMLCanvasElement> = useCallback(
    (e) => {
      if (state.current.pointer === e.pointerId) {
        try {
          if (state.current.isCapturing) {
            dom.current.releasePointerCapture(e.pointerId);
          }
        } catch (err) {
          console.warn('Failed to release pointer:', err);
        } finally {
          state.current.pointer = -1;
          state.current.isCapturing = false;
          const p = transformPointer({ x: e.clientX, y: e.clientY });
          dom.current!.style.cursor = getHandler(p) ? 'pointer' : 'default';
        }
      }
    },
    [transformPointer, getHandler]
  );

  return (
    <Container>
      <canvas
        ref={dom}
        onPointerDown={pointerdown}
        onPointerMove={pointermove}
        onPointerUp={pointerup}
        onPointerCancel={pointerup}
        onLostPointerCapture={pointerup}
        style={{
          touchAction: 'none',
          userSelect: 'none',
        }}
      />
    </Container>
  );
};

import { ThreeEvent, useThree } from '@react-three/fiber';
import { useDrag } from '@use-gesture/react';
import { useState } from 'react';
import { Group, OrthographicCamera } from 'three';

import { screenPositionToWorld } from '../../../helpers';
import { useWorkbenchStudioState } from '../../studioState';
import { LayerTranslation } from './useLayerTranslationTransform';

export type LayerScale = [number, number];

const defaultScale: LayerScale = [1, 1];
const defaultTranslation: LayerTranslation = [0, 0];

export const useLayerResizeTransform = (
  onGestureEnd: (scale: LayerScale, translation: LayerTranslation) => void,
  resizerGroupRef: React.MutableRefObject<Group>,
  layerWidth: number,
  layerHeight: number,
  rotation: number = 0,
  {
    forceAspectRatio = false,
    minSize = undefined,
  }: {
    forceAspectRatio?: boolean;
    minSize?: number;
  } = {}
) => {
  const camera = useThree((s) => s.camera as OrthographicCamera);
  const [scaleTransform, setScaleTransform] =
    useState<LayerScale>(defaultScale);

  const [translationTransform, setTranslationTransform] =
    useState<LayerTranslation>(defaultTranslation);

  const yFlipSign = useWorkbenchStudioState().flipped ? -1 : 1;

  const bindResizeHandle = useDrag<ThreeEvent<PointerEvent>>((gesture) => {
    gesture.event.stopPropagation();

    if (gesture.tap) {
      return;
    }
    if (gesture.last) {
      onGestureEnd(scaleTransform, translationTransform);
      return;
    }

    const [xDir, yDir] = gesture.args as LayerScale;
    const pointerPosition = screenPositionToWorld(
      [gesture.event.nativeEvent.clientX, gesture.event.nativeEvent.clientY],
      camera
    );

    if (!gesture.memo) {
      return {
        initialSize: [
          scaleTransform[0] * layerWidth,
          scaleTransform[1] * layerHeight,
        ],
        initialPointerPosition: pointerPosition,
      };
    }

    const {
      initialSize: [initialWidth, initialHeight],
      initialPointerPosition,
    } = gesture.memo as {
      initialSize: LayerScale;
      initialPointerPosition: LayerScale;
    };

    const dx = yFlipSign * (pointerPosition[0] - initialPointerPosition[0]);
    const dy = pointerPosition[1] - initialPointerPosition[1];

    const ca = Math.cos(-rotation);
    const sa = Math.sin(-rotation);
    const rotatedDx = ca * dx - sa * dy;
    const rotatedDy = sa * dx + ca * dy;

    let deltaX = rotatedDx * xDir;
    let deltaY = rotatedDy * yDir;

    if (!gesture.event.nativeEvent.shiftKey || forceAspectRatio) {
      if (xDir) {
        deltaY = (deltaX / initialWidth) * initialHeight;
      } else {
        deltaX = (deltaY / initialHeight) * initialWidth;
      }
    }
    const resizeFromCenter = gesture.event.nativeEvent.altKey;
    const resizeFactor = resizeFromCenter ? 2 : 1;
    const newSize = [
      initialWidth + deltaX * resizeFactor,
      initialHeight + deltaY * resizeFactor,
    ];

    if (minSize !== undefined) {
      if (newSize[0] < minSize || newSize[1] < minSize) {
        const r = minSize / Math.min(newSize[0], newSize[1]);
        newSize[0] *= r;
        newSize[1] *= r;
      }
    }

    if (!resizeFromCenter) {
      //Compute how much the opposite corner or edge is moved in centered mode,...
      const translation: LayerTranslation = [
        (-xDir * (newSize[0] - initialWidth)) / 2,
        (-yDir * (newSize[1] - initialHeight)) / 2,
      ];
      //...accounting for the rotation.
      const ca = Math.cos(rotation);
      const sa = Math.sin(rotation);
      const rt = [
        translation[0] * ca - translation[1] * sa,
        translation[0] * sa + translation[1] * ca,
      ];
      //Apply the opposite translation.
      setTranslationTransform([-rt[0], -rt[1]]);
    }
    setScaleTransform([newSize[0] / layerWidth, newSize[1] / layerHeight]);

    return {
      initialSize: [initialWidth, initialHeight],
      initialPointerPosition,
    };
  });

  return {
    bindResizeHandle,
    scaleTransform,
    translationTransform,
    resetResizeTransform: () => {
      setScaleTransform(defaultScale);
      setTranslationTransform(defaultTranslation);
    },
  };
};

import { Children, PropsWithChildren } from 'react';
import { filterExists } from '@vizcom/shared/js-utils';
import { ContextMenuDivider } from '@vizcom/shared-ui-components';

import { ClientSideWorkbenchElementData } from '../../lib/clientState';
import { useWorkbenchSyncedState } from '../../lib/useWorkbenchSyncedState';
import { DrawingContextMenuItems } from './contextMenuItemsPerType/DrawingContextMenuItems';
import { WorkbenchElementPaletteContextMenuItems } from './contextMenuItemsPerType/PaletteContextMenuItems';
import { WorkbenchElementSectionContextMenuItems } from './contextMenuItemsPerType/SectionContextMenuItems';
import { SharedContextMenuItems } from './contextMenuItemsPerType/Shared';

export type ContextMenuItemsProps = {
  element: ClientSideWorkbenchElementData;
  workbenchId: string;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
};

const ContextMenuItems = ({
  element,
  workbenchId,
  children,
  handleAction,
}: PropsWithChildren<ContextMenuItemsProps>) => {
  return (
    <>
      {Children.count(
        (Array.isArray(children) ? children : [children])?.filter(filterExists)
      ) > 0 && (
        <>
          {children}
          <ContextMenuDivider />
        </>
      )}
      <SharedContextMenuItems
        workbenchId={workbenchId}
        elements={[element]}
        handleAction={handleAction}
      />
    </>
  );
};

export const WorkbenchContextMenuByElementType = ({
  element,
  workbenchId,
  handleAction,
}: ContextMenuItemsProps) => {
  const commonProps = {
    element,
    workbenchId,
    handleAction,
  };

  // NOTE Add remaining element types and their custom context menus below
  return (
    <ContextMenuItems {...commonProps}>
      {element.__typename === 'Drawing' ? (
        <DrawingContextMenuItems {...commonProps} element={element} />
      ) : null}
      {element.__typename === 'WorkbenchElementPalette' ? (
        <WorkbenchElementPaletteContextMenuItems
          {...commonProps}
          element={element}
        />
      ) : null}
      {element.__typename === 'WorkbenchElementSection' ? (
        <WorkbenchElementSectionContextMenuItems
          {...commonProps}
          element={element}
        />
      ) : null}
    </ContextMenuItems>
  );
};

import { AutoPromptType } from 'libs/shared/data-access/graphql/src/gql/graphql';
import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import {
  ThumbnailIconRaw,
  imageUrlToImageData,
} from '@vizcom/shared-ui-components';

import { ClientSideWorkbenchElementDrawing } from '../../../lib/clientState';
import { useWorkbenchSyncedState } from '../../../lib/useWorkbenchSyncedState';
import { useIsElementFilteredOut } from '../../WorkbenchFilters/WorkbenchFiltersContext';
import { WorkbenchElementDragConnector } from '../../elementConnector/workbenchElementDragConnector';
import { getElementSize } from '../../helpers';
import { useAutoPrompt } from '../../studio/useAutoPrompt';
import { CustomHtml } from '../../utils/CustomHtml';
import { CustomImage } from '../../utils/CustomImage';
import { CustomText } from '../../utils/CustomText';
import { StaticSvg } from '../../utils/StaticSvg';
import { DrawingMetadata } from './DrawingMetadata';
import { WorkbenchElementDrawingExtra } from './WorkbenchElementDrawingExtra';
import { useDrawingLocalState } from './drawingLocalState';

const DRAWING_NAME_WIDTH = 115;
const DRAWING_NAME_HEIGHT = 22;

interface WorkbenchElementDrawingProps {
  element: ClientSideWorkbenchElementDrawing;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
  singleFocused: boolean;
  focused: boolean;
  isHovered: boolean;
  setEditingElementId: (id: string | null) => void;
  isDragging: boolean;
  isResizing: boolean;
  workbenchId: string;
  isThumbnail: boolean;
  showSingleFocusedControls: boolean;
}

export const WorkbenchElementDrawing = ({
  element,
  handleAction,
  singleFocused,
  focused,
  isHovered,
  setEditingElementId,
  isDragging,
  isResizing,
  isThumbnail,
  workbenchId,
  showSingleFocusedControls,
}: WorkbenchElementDrawingProps) => {
  const isFilteredOut = useIsElementFilteredOut(element);
  const theme = useTheme();
  const { width, height } = getElementSize(element);
  const [editingName, setEditingName] = useState(false);
  const toggleMetadata = useDrawingLocalState((state) => state.toggleMetadata);
  const drawingMetadataOpen = useDrawingLocalState((state) =>
    state.metadataOpen.includes(element.id)
  );

  const { triggerAutoPrompt } = useAutoPrompt({
    workbenchPaletteId: null,
    inputType: AutoPromptType.Image,
    getCompositeImage: async () => {
      if (!element.id || !element.thumbnailPath) {
        return null;
      }

      if (element.thumbnailPath instanceof ImageData) {
        return element.thumbnailPath;
      }

      return await imageUrlToImageData(element.thumbnailPath);
    },
    setPrompt: (text, id) => {
      if (!id) {
        return;
      }

      handleAction({
        type: 'updateAiImg2Img',
        elementId: id,
        prompt: text,
      });
    },
    getSelectionImage: () => undefined,
  });

  const handleDoubleClickOnImage = () => {
    setEditingElementId(element.id);
  };

  const updateDrawingName = (name: string) => {
    handleAction({
      type: 'updateDrawing',
      drawingId: element.id,
      name,
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      updateDrawingName(e.currentTarget.innerText.replace('\n', ''));
      setEditingName(false);
      e.preventDefault();
    }
  };

  return (
    <>
      {showSingleFocusedControls && (
        <WorkbenchElementDragConnector
          element={element}
          handleAction={handleAction}
          triggerAutoPrompt={triggerAutoPrompt}
        />
      )}
      <group>
        <CustomImage
          onDoubleClick={handleDoubleClickOnImage}
          scale={[width, height]}
          url={element.thumbnailPath}
          opacity={isFilteredOut ? 0.2 : 1}
        />
      </group>

      {isThumbnail && (
        <StaticSvg
          svg={ThumbnailIconRaw}
          strokeMaterial={{ color: theme.deprecated.primary.default }}
          anchorX="left"
          anchorY="middle"
          position={[-width / 2, height / 2 + 13, 0]}
        />
      )}

      {(focused || singleFocused) && (
        <group
          position={[-width / 2 + (isThumbnail ? 14 : 0), height / 2 + 14, 0]}
        >
          {editingName ? (
            <CustomHtml transform position={[DRAWING_NAME_WIDTH / 2, 0, 0]}>
              <div
                style={{
                  width: DRAWING_NAME_WIDTH,
                  height: DRAWING_NAME_HEIGHT,
                }}
              >
                <NameInput
                  contentEditable
                  ref={(el) => {
                    if (el) {
                      el.focus();
                      el.innerText = element.name || '';
                      window.getSelection()?.selectAllChildren(el);
                    }
                  }}
                  onBlur={(e) => {
                    updateDrawingName(e.target.innerText.replace('\n', ''));
                    setEditingName(false);
                  }}
                  onKeyDown={handleKeyDown}
                />
              </div>
            </CustomHtml>
          ) : (
            <CustomText
              color={
                singleFocused || isHovered
                  ? theme.deprecated.primary.default
                  : theme.text.subtext
              }
              fontSize={12}
              font={'Inter'}
              fontWeight={'semiBold'}
              anchorX={'left'}
              maxWidth={width}
              whiteSpace="nowrap"
              clipRect={[0, -10, DRAWING_NAME_WIDTH, 10]}
              onDoubleClick={(e) => {
                e.stopPropagation();
                setEditingName(true);
              }}
              content={element.name?.replace('\n', '') || 'Untitled'}
              visible={!editingName}
              userData={{ cursor: 'text' }}
            />
          )}
        </group>
      )}

      {/* <DrawingContentLayersIndicator
        drawing={element}
        position={[width / 2, height / 2 + 2, 0]}
      /> */}

      {!isDragging && !isResizing && singleFocused && (
        <WorkbenchElementDrawingExtra
          element={element}
          workbenchId={workbenchId}
          handleAction={handleAction}
          toggleMetadata={() => {
            toggleMetadata([element.id]);
          }}
          setEditingElementId={setEditingElementId}
        />
      )}

      {drawingMetadataOpen && (
        <group
          userData={{
            workbenchObjectType: 'container',
            notResizable: true,
          }}
        >
          <DrawingMetadata
            workbenchId={workbenchId}
            drawing={element}
            width={width}
            height={height}
            toggleMetadata={() => {
              toggleMetadata([element.id]);
            }}
          />
        </group>
      )}
    </>
  );
};

const NameInput = styled.div`
  display: inline-flex;
  min-width: 20px;
  max-width: ${DRAWING_NAME_WIDTH}px;
  height: 26px;
  background: white;
  color: ${({ theme }) => theme.deprecated.primary.default};
  border: 2px solid ${({ theme }) => theme.deprecated.primary.default};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  font-size: 14px;
  letter-spacing: 0.1px;
  font-weight: 500;
  margin: 0;
  padding: 0 4px;
  white-space: nowrap;
  overflow: hidden;
  outline: none;
  align-items: center;
`;

import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import {
  OnboardingTourTooltipContent,
  RichTooltip,
  RichTooltipTrigger,
} from '@vizcom/shared-ui-components';

import educationalTooltipVideo02 from '../../../assets/videos/educational-tooltip-02.mp4';
import { OnboardingStep } from '../../utils/OnboardingMultiStep';
import { InferenceSettings } from '../useInference';
import { Header } from './Create';
import { DescribeButton } from './DescribeButton';
import { PromptInput } from './PromptInput';

export const MainPrompt = ({
  prompt,
  isGeneratingPrompt,
  triggerAutoPrompt,
  cancelAutoPrompt,
  setInferenceSettings,
  isHistoryPreviewActive,
  shouldDisplayTour,
  currentStep,
  trigger,
}: {
  prompt: string;
  isGeneratingPrompt: boolean;
  triggerAutoPrompt: () => void;
  cancelAutoPrompt: () => void;
  setInferenceSettings: Dispatch<SetStateAction<InferenceSettings>>;
  isHistoryPreviewActive: boolean;
  shouldDisplayTour: boolean;
  currentStep: number;
  trigger: () => void;
}) => {
  return (
    <>
      <Wrapper>
        <RichTooltip
          isOpen={shouldDisplayTour && currentStep === 2}
          placement="left"
          padding={88}
        >
          <RichTooltipTrigger>
            <Header>Prompt</Header>
          </RichTooltipTrigger>
          <OnboardingTourTooltipContent>
            <OnboardingStep
              video={educationalTooltipVideo02}
              title="Prompt"
              content="Describe your designs form, materials, and background, or have Vizcom do it for you."
            />
          </OnboardingTourTooltipContent>
        </RichTooltip>
        <DescribeButton
          showButton={!isHistoryPreviewActive}
          isGeneratingPrompt={isGeneratingPrompt}
          triggerAutoPrompt={triggerAutoPrompt}
          cancelAutoPrompt={cancelAutoPrompt}
        />
      </Wrapper>
      <PromptInput
        placeholder={
          !prompt.length && isGeneratingPrompt
            ? 'Describing the canvas'
            : 'What are you creating?'
        }
        value={prompt}
        disabled={isHistoryPreviewActive}
        onChange={(v) =>
          setInferenceSettings((prev) => ({ ...prev, prompt: v }))
        }
        onEnter={trigger}
        onFocus={() => {
          cancelAutoPrompt();
        }}
      />
    </>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: centerflex;
  justify-content: space-between;
`;

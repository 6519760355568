import { ThreeEvent } from '@react-three/fiber';
import { useDrag } from '@use-gesture/react';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { CubicBezierCurve, Vector2 } from 'three';
import { filterExists } from '@vizcom/shared/js-utils';
import { CMD_KEY_NAME } from '@vizcom/shared-ui-components';

import { useSelectionApiStore } from '../../../selection/useSelectionApi';
import {
  getParametricValue,
  moveCounterControlPoint,
  LINE_DRAG_MULTIPLIER,
} from './util';

type Props = {
  selectedPoints: string[] | null;
  setSelectedPoints: Dispatch<SetStateAction<string[] | null>>;
  addBezierPoint: (x: number, y: number, index?: number) => void;
  dragControls: (
    id: string,
    event: ThreeEvent<PointerEvent>,
    newPoint?: boolean
  ) => void;
  draggingLineRef: MutableRefObject<[number, number] | null>;
  drawSelection: () => void;
  pointToLocal: (x: number, y: number) => number[];
};

export const useBindLine = ({
  selectedPoints,
  draggingLineRef,
  setSelectedPoints,
  addBezierPoint,
  dragControls,
  drawSelection,
  pointToLocal,
}: Props) => {
  const selectionApiStore = useSelectionApiStore();

  return useDrag<ThreeEvent<PointerEvent>>(
    ({ event, memo, args, last, movement }) => {
      event.stopPropagation();
      const i = args[0].i as number;

      const point = pointToLocal(event.clientX, event.clientY);
      const x = point[0];
      const y = point[1];

      // Holding control/command grabs the line
      // if we have a memo, we're already dragging the line
      if (memo?.x || event[CMD_KEY_NAME]) {
        if (!memo?.x) {
          return {
            x,
            y,
          };
        }

        const previousIndex =
          i === 0
            ? selectionApiStore.getState().penState.bezierPoints.length - 1
            : i - 1;
        const nextIndex =
          i === selectionApiStore.getState().penState.bezierPoints.length
            ? 0
            : i;
        draggingLineRef.current = [previousIndex, nextIndex];

        const previousPoint =
          selectionApiStore.getState().penState.bezierPoints[previousIndex];
        const nextPoint =
          selectionApiStore.getState().penState.bezierPoints[nextIndex];

        let curveT = memo?.curveT;
        if (!curveT) {
          const curve = new CubicBezierCurve(
            new Vector2(previousPoint.x, previousPoint.y),
            new Vector2(
              previousPoint.outerControl.x,
              previousPoint.outerControl.y
            ),
            new Vector2(nextPoint.innerControl.x, nextPoint.innerControl.y),
            new Vector2(nextPoint.x, nextPoint.y)
          );
          const targetPoint = new Vector2(x, y);
          curveT = getParametricValue(curve, targetPoint);
        }

        let innerControlLength = memo?.innerControlLength;
        let outerControlLength = memo?.outerControlLength;

        selectionApiStore.getState().setPenState((state) => {
          const newPoints = [...state.bezierPoints];
          const nextPoint = newPoints[nextIndex];
          const previousPoint = newPoints[previousIndex];

          const selected = selectedPoints
            ?.map((id) => newPoints.find((p) => p.id === id))
            .filter(filterExists);
          const deltaX = x - memo.x;
          const deltaY = y - memo.y;

          // multiple points are selected, move all of them
          if (
            selected &&
            selected?.length > 1 &&
            selected.some(
              (p) => p.id === nextPoint.id || p.id === previousPoint.id
            )
          ) {
            selected.forEach((point) => {
              point.x += deltaX;
              point.y += deltaY;

              point.innerControl.x += deltaX;
              point.innerControl.y += deltaY;

              point.outerControl.x += deltaX;
              point.outerControl.y += deltaY;
            });

            return {
              ...state,
              bezierPoints: newPoints,
            };
          }

          // multiple points are selected, but none of them are adjacent to the line
          // we're dragging, so clear the selection
          if (
            selected &&
            !selected.some(
              (p) => p.id === nextPoint.id || p.id === previousPoint.id
            )
          ) {
            setSelectedPoints([]);
          }

          // neither of the adjacent controls are initialized, so we move the entire line
          if (
            !nextPoint.outerControl.initialized &&
            !previousPoint.innerControl.initialized
          ) {
            previousPoint.x += deltaX;
            previousPoint.y += deltaY;
            previousPoint.outerControl.x += deltaX;
            previousPoint.outerControl.y += deltaY;
            previousPoint.innerControl.x += deltaX;
            previousPoint.innerControl.y += deltaY;

            nextPoint.x += deltaX;
            nextPoint.y += deltaY;
            nextPoint.outerControl.x += deltaX;
            nextPoint.outerControl.y += deltaY;
            nextPoint.innerControl.x += deltaX;
            nextPoint.innerControl.y += deltaY;

            return {
              ...state,
              bezierPoints: newPoints,
            };
          }

          // the user has selected the middle 50% of the line,
          // drag the adjacent control points, and move the counter control points
          // if their positions are not locked
          if (curveT >= 0.25 && curveT <= 0.75) {
            const innerControlVectorX =
              previousPoint.innerControl.x - previousPoint.x;
            const innerControlVectorY =
              previousPoint.innerControl.y - previousPoint.y;
            innerControlLength =
              innerControlLength ||
              Math.sqrt(
                innerControlVectorX * innerControlVectorX +
                  innerControlVectorY * innerControlVectorY
              );

            previousPoint.outerControl.x += deltaX * LINE_DRAG_MULTIPLIER;
            previousPoint.outerControl.y += deltaY * LINE_DRAG_MULTIPLIER;

            if (!previousPoint.singleControlLock) {
              moveCounterControlPoint(
                previousPoint,
                previousPoint.outerControl,
                previousPoint.innerControl,
                innerControlLength
              );
            }

            const outerControlVectorX =
              previousPoint.outerControl.x - previousPoint.x;
            const outerControlVectorY =
              previousPoint.outerControl.y - previousPoint.y;
            outerControlLength =
              outerControlLength ||
              Math.sqrt(
                outerControlVectorX * outerControlVectorX +
                  outerControlVectorY * outerControlVectorY
              );

            nextPoint.innerControl.x += deltaX * LINE_DRAG_MULTIPLIER;
            nextPoint.innerControl.y += deltaY * LINE_DRAG_MULTIPLIER;

            if (!nextPoint.singleControlLock) {
              moveCounterControlPoint(
                nextPoint,
                nextPoint.innerControl,
                nextPoint.outerControl,
                outerControlLength
              );
            }

            return {
              ...state,
              bezierPoints: newPoints,
            };
          }

          // the user has selected the first 25% of the line,
          // move the previous point and its control points inversely
          // from how far the user has selected from the point
          if (curveT < 0.25) {
            const ratio = curveT / 0.25;

            previousPoint.x += deltaX * (1 - ratio);
            previousPoint.y += deltaY * (1 - ratio);

            const innerControlVectorX =
              previousPoint.innerControl.x - previousPoint.x;
            const innerControlVectorY =
              previousPoint.innerControl.y - previousPoint.y;
            innerControlLength =
              innerControlLength ||
              Math.sqrt(
                innerControlVectorX * innerControlVectorX +
                  innerControlVectorY * innerControlVectorY
              );

            previousPoint.outerControl.x += deltaX * ratio * 2;
            previousPoint.outerControl.y += deltaY * ratio * 2;

            if (!previousPoint.singleControlLock) {
              moveCounterControlPoint(
                previousPoint,
                previousPoint.outerControl,
                previousPoint.innerControl,
                innerControlLength
              );
            }

            return {
              ...state,
              bezierPoints: newPoints,
            };
          }

          // the user has selected the last 25% of the line,
          // move the next point and its control points inversely
          // from how far the user has selected from the point
          if (curveT > 0.75) {
            const ratio = (1 - curveT) / 0.25;

            nextPoint.x += deltaX * (1 - ratio);
            nextPoint.y += deltaY * (1 - ratio);

            const outerControlVectorX =
              previousPoint.outerControl.x - previousPoint.x;
            const outerControlVectorY =
              previousPoint.outerControl.y - previousPoint.y;
            outerControlLength =
              outerControlLength ||
              Math.sqrt(
                outerControlVectorX * outerControlVectorX +
                  outerControlVectorY * outerControlVectorY
              );

            nextPoint.innerControl.x += deltaX * ratio * 2;
            nextPoint.innerControl.y += deltaY * ratio * 2;

            if (!nextPoint.singleControlLock) {
              moveCounterControlPoint(
                nextPoint,
                nextPoint.innerControl,
                nextPoint.outerControl,
                outerControlLength
              );
            }

            return {
              ...state,
              bezierPoints: newPoints,
            };
          }

          return {
            ...state,
            bezierPoints: newPoints,
          };
        });

        if (last) {
          draggingLineRef.current = null;
          drawSelection();
        }

        return {
          x,
          y,
          curveT,
          innerControlLength,
          outerControlLength,
        };
      }

      // we've created a new point, and we're dragging it
      if (memo?.id) {
        const bezierPoint = selectionApiStore
          .getState()
          .penState.bezierPoints.find((p) => p.id === memo.id);
        const hasMovedEnough =
          (bezierPoint?.innerControl.initialized &&
            bezierPoint?.outerControl.initialized) ||
          Math.abs(movement[0]) > 5 ||
          Math.abs(movement[1]) > 5;

        if (hasMovedEnough) {
          dragControls(memo.id, event);
        }

        if (last) {
          drawSelection();
        }

        return {
          id: memo.id,
        };
      }

      const id = addBezierPoint(x, y, i);

      if (last) {
        drawSelection();
      }

      return {
        id,
      };
    },
    {
      pointer: {
        capture: false,
      },
    }
  );
};

import { useState } from 'react';
import styled from 'styled-components';
import {
  OnboardingStepName,
  OnboardingTooltip,
  Spinner,
  useCompleteOnboardingStep,
} from '@vizcom/shared-ui-components';

export const DescribeButton = ({
  isGeneratingPrompt,
  showButton = true,
  triggerAutoPrompt,
  cancelAutoPrompt,
}: {
  isGeneratingPrompt: boolean;
  showButton?: boolean;
  triggerAutoPrompt: () => void;
  cancelAutoPrompt: () => void;
}) => {
  const [showCancelAutoPrompt, setShowCancelAutoPrompt] = useState(false);
  const [cancelTimeout, setCancelTimeout] = useState<
    NodeJS.Timeout | undefined
  >();
  const completeOnboardingStep = useCompleteOnboardingStep();

  return (
    <>
      {!isGeneratingPrompt && showButton && (
        <OnboardingTooltip
          previousStep={OnboardingStepName.NewDrawingAutoPrompt}
          name={OnboardingStepName.AutoPrompt}
          durationVisible={3000}
          title={
            <span>
              Click to generate a prompt
              <br />
              from canvas content
            </span>
          }
        >
          <AutoPrompt
            onClick={() => {
              triggerAutoPrompt();
              completeOnboardingStep(OnboardingStepName.AutoPrompt);
            }}
          >
            Describe
          </AutoPrompt>
        </OnboardingTooltip>
      )}
      {isGeneratingPrompt && !showCancelAutoPrompt && (
        <div
          onPointerEnter={() => {
            const timeout = setTimeout(
              () => setShowCancelAutoPrompt(true),
              300
            );
            setCancelTimeout(timeout);
          }}
          onPointerLeave={() => {
            clearTimeout(cancelTimeout);
            setCancelTimeout(undefined);
          }}
          style={{
            marginRight: '20px',
          }}
        >
          <Spinner
            size={{
              width: 14,
              height: 14,
            }}
          />
        </div>
      )}
      {isGeneratingPrompt && showCancelAutoPrompt && (
        <AutoPrompt
          onPointerOut={() => setShowCancelAutoPrompt(false)}
          onClick={() => {
            setShowCancelAutoPrompt(false);
            cancelAutoPrompt();
          }}
        >
          Cancel?
        </AutoPrompt>
      )}
    </>
  );
};

const AutoPrompt = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.text.link};

  &:hover {
    color: ${({ theme }) => theme.text.linkHover};
  }
`;

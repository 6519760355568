import { RemoveIcon, Button } from '@vizcom/shared-ui-components';

import {
  InitialWorkbenchElement,
  InitialWorkbenchElementButtonContainer,
  InitialWorkbenchElementText,
} from '../elements/style';

export const HtmlErrorPlaceholder = () => {
  return (
    <InitialWorkbenchElement>
      <InitialWorkbenchElementButtonContainer>
        <Button variant="danger" size="icon">
          <RemoveIcon style={{ width: '22px', height: '22px' }} />
        </Button>
      </InitialWorkbenchElementButtonContainer>
      <InitialWorkbenchElementText
        type="b3"
        color="subtext"
        style={{ maxWidth: '120px' }}
      >
        Image failed to generate
      </InitialWorkbenchElementText>
    </InitialWorkbenchElement>
  );
};

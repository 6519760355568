export const browseForFile = (
  opts: {
    accept?: string;
  } = {}
) => {
  return new Promise<File>((resolve, reject) => {
    const loader = document.createElement('input');
    loader.type = 'file';
    if (opts.accept) {
      loader.accept = opts.accept;
    }
    loader.onchange = () => {
      const { files } = loader;
      const file = files && files[0];
      if (!file) {
        reject();
      } else {
        resolve(file);
      }
      // fix for safari
      document.body.removeChild(loader);
    };
    loader.onabort = () => {
      reject();
    };
    // fix for safari
    document.body.appendChild(loader);
    loader.click();
  });
};

export const browseForFiles = (
  opts: {
    accept?: string;
    capture?: string;
  } = {}
) => {
  return new Promise<File[]>((resolve, reject) => {
    const loader = document.createElement('input');
    loader.type = 'file';
    loader.multiple = true;
    if (opts.accept) {
      loader.accept = opts.accept;
    }
    if (opts.capture) {
      loader.setAttribute('capture', opts.capture);
    }
    loader.onchange = () => {
      const { files } = loader;
      resolve(Array.from(files as any));
      // fix for safari
      document.body.removeChild(loader);
    };
    loader.onabort = () => {
      reject();
    };
    // fix for safari
    document.body.appendChild(loader);
    loader.click();
  });
};

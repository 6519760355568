import styled from 'styled-components';
import {
  publishTrackingEvent,
  useCurrentUserClientStateByKey,
  UserClientStateKeys,
  useSetCurrentUserClientState,
} from '@vizcom/shared/data-access/graphql';
import { StudioEventName } from '@vizcom/shared/data-shape';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import { CheckIcon, MenuItem } from '@vizcom/shared-ui-components';

import { Layout } from '../types';

export const WorkbenchStudioMenuLayoutItem = () => {
  const [, updateState] = useSetCurrentUserClientState();
  const layout: Layout =
    useCurrentUserClientStateByKey(UserClientStateKeys.StudioLayout) ||
    'default';

  const setLayout = (layout: Layout) => {
    updateState({
      input: {
        key: UserClientStateKeys.StudioLayout,
        value: layout,
      },
    });
    trackEvent('2D Studio Layout Changed', { layout });
    publishTrackingEvent({
      type: StudioEventName.LAYOUT_CHANGED,
      data: {
        layout,
      },
    });
  };

  return (
    <MenuItem label="Layout">
      <MenuItem
        prependLabel={<StyledCheckmark $visible={layout === 'default'} />}
        label="Default"
        onClick={() => setLayout('default')}
      />
      <MenuItem
        prependLabel={<StyledCheckmark $visible={layout === 'classic'} />}
        label="Classic"
        onClick={() => setLayout('classic')}
      />
      <MenuItem
        prependLabel={<StyledCheckmark $visible={layout === 'stacked'} />}
        label="Stacked"
        onClick={() => setLayout('stacked')}
      />
    </MenuItem>
  );
};

const StyledCheckmark = styled(CheckIcon)<{ $visible: boolean }>`
  visibility: ${(p) => (p.$visible ? 'visible' : 'hidden')};
`;

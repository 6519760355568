// This API is used by some customers who built custom workflowws on top of Vizcom
// they are integrating the webapp in a Electron app and then interacting with it through this API
// This API is client only, not accessible directly from a server, but only useful for automation purposes

import { clone } from 'lodash';
import { useEffect } from 'react';
import { useStableCallback } from '@vizcom/shared-ui-components';

import { VizcomExternalClientApi, defaultExternalClientApi } from './types';

const api = clone(defaultExternalClientApi);

export const registerVizcomExternalClientApi = () => {
  if (typeof window !== 'undefined') {
    // @ts-ignore
    window.vizcomAPI = api;
  }
};

export const useRegisterVizcomExternalClientApi = <
  T extends keyof VizcomExternalClientApi
>(
  method: T,
  callback: VizcomExternalClientApi[T]
) => {
  const stableCallback = useStableCallback(callback);

  useEffect(() => {
    if (api[method] !== defaultExternalClientApi[method]) {
      throw new Error(
        'This method was already registered, make sure you are not calling useRegisterVizcomExternalClientApi twice'
      );
    }
    api[method] = stableCallback;
    return () => {
      api[method] = defaultExternalClientApi[method];
    };
  }, [method, stableCallback]);
};

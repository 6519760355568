import { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { useDocumentEventListener } from '@vizcom/shared-ui-components';

import {
  WorkbenchStudioToolType,
  useWorkbenchStudioState,
} from '../studioState';
import { BrushMenu } from './Brush/BrushMenu';
import { EraserMenu } from './Eraser/EraserMenu';
import { ShapeMenu } from './Shape/ShapeMenu';
import { SLIDER_ID } from './ToolbarSlider';
import { TransformMenu } from './Transform/TransformMenu';

type Props = {
  target: HTMLElement;
  handlePaste: (event: React.MouseEvent) => void;
};

export const WorkbenchStudioContextMenu = ({ target, handlePaste }: Props) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const studioState = useWorkbenchStudioState();

  useEffect(() => {
    const onContextMenu = (e: MouseEvent) => {
      e.preventDefault();
      if (!menuRef.current) return;

      let x = e.x;
      let y = e.y;

      // Check if the menu contains a slider
      const sliderElement = menuRef.current.querySelector(`#${SLIDER_ID}`);
      if (sliderElement) {
        const containerRect = menuRef.current.getBoundingClientRect();
        const sliderRect = sliderElement.getBoundingClientRect();
        x -= menuRef.current.clientWidth / 2;
        const relativeSliderPositionY =
          containerRect.top - sliderRect.top - sliderRect.height / 2;
        y += relativeSliderPositionY;
      }

      // Ensure the menu stays within viewport bounds
      x = Math.min(x, window.innerWidth - menuRef.current.clientWidth);
      y = Math.min(y, window.innerHeight - menuRef.current.clientHeight);
      x = Math.max(x, 0);
      y = Math.max(y, 0);

      menuRef.current.style.visibility = 'visible';
      menuRef.current.style.transform = `translate(${x}px, ${y}px)`;
    };

    const onEscape = (e: KeyboardEvent) => {
      if (!menuRef.current) return;
      if (
        e.key === 'Escape' &&
        // escape is also the key to reset the view, hijack it only when the menu is open
        menuRef.current.style.visibility === 'visible'
      ) {
        e.preventDefault();
        e.stopPropagation();
        menuRef.current.style.visibility = 'hidden';
      }
    };

    target.addEventListener('contextmenu', onContextMenu);
    target.addEventListener('keydown', onEscape);

    return () => {
      target.removeEventListener('contextmenu', onContextMenu);
      target.removeEventListener('keydown', onEscape);
    };
  }, [target]);

  useDocumentEventListener('click', (e) => {
    if (!menuRef.current || menuRef.current.contains(e.target as Node)) return;
    menuRef.current.style.visibility = 'hidden';
  });

  const menu = useMemo(() => {
    switch (studioState.tool) {
      case WorkbenchStudioToolType.Move:
      case WorkbenchStudioToolType.Transform:
        return <TransformMenu handlePaste={handlePaste} />;
      case WorkbenchStudioToolType.Brush:
        return <BrushMenu />;
      case WorkbenchStudioToolType.Eraser:
      case WorkbenchStudioToolType.MagicEraser:
        return <EraserMenu />;
      case WorkbenchStudioToolType.Line:
      case WorkbenchStudioToolType.Rectangle:
      case WorkbenchStudioToolType.Ellipse:
        return <ShapeMenu />;
    }
  }, [studioState.tool, handlePaste]);

  if (!menu) return null;

  return <Container ref={menuRef}>{menu}</Container>;
};

const Container = styled.div`
  position: fixed;
  z-index: 100000001;
  visibility: hidden;
  pointer-events: all;
  padding: 8px;
  border-radius: ${({ theme }) => theme.borderRadius.l};
  gap: 0.5rem;
  background: ${({ theme }) => theme.surface.primary};
`;

import { useEffect } from 'react';
import {
  AssetColorData,
  deleteAssetColor,
  LibraryType,
  useAssetColors,
  useCreateAssetColor,
} from '@vizcom/shared/data-access/graphql';
import {
  addToast,
  BlockStack,
  ContextMenu,
  ContextMenuItem,
  formatErrorMessage,
  InfiniteScrollPage,
} from '@vizcom/shared-ui-components';

import { useAccordion } from './Accordion';
import { type Cursors } from './AssetLibrary';
import { ViewMode } from './ViewModeSwitcher';
import { PanelViewMode } from './colors/AddAssetColorMenu';
import { ColorPill, ColorPillLoading } from './colors/ColorPill';
import { EmptyColorsState } from './colors/EmptyColorsState';

type ColorsResultPageProps = {
  view: ViewMode;
  workbenchId: string;
  cursor: string | null;
  libraryType: LibraryType;
  setColorsCursors: React.Dispatch<React.SetStateAction<Cursors>>;
  searchText: string;
  cursorIndex: number;
  onColorSelected: (color: AssetColorData, action: PanelViewMode) => void;
};

export const ColorsResultPage = ({
  view,
  workbenchId,
  cursor,
  libraryType,
  setColorsCursors,
  searchText,
  cursorIndex,
  onColorSelected,
}: ColorsResultPageProps) => {
  const createAssetColor = useCreateAssetColor(libraryType, workbenchId);
  const { setDisabled } = useAccordion();

  const { data, pageInfo, fetching } = useAssetColors(
    libraryType,
    workbenchId,
    cursor
  );

  const { hasNextPage, endCursor } = pageInfo || {};

  const fetchMore = () => {
    if (hasNextPage && endCursor) {
      setColorsCursors((cursors) => [...cursors, endCursor]);
    }
  };

  const colors = data.filter(
    (color) =>
      color.name.toLowerCase().includes(searchText.toLowerCase()) ||
      color.red.toString() === searchText ||
      color.green.toString() === searchText ||
      color.blue.toString() === searchText
  );

  useEffect(() => {
    setDisabled(colors.length < 5 && !fetching);
  }, [colors, fetching, setDisabled]);

  if (colors.length === 0 && !fetching && cursorIndex === 0) {
    return <EmptyColorsState />;
  }

  const onEditColor = (color: AssetColorData) => {
    onColorSelected(color, PanelViewMode.Edit);
  };

  const onViewColor = (color: AssetColorData) => {
    onColorSelected(color, PanelViewMode.View);
  };

  const onDeleteColor = async (color: AssetColorData) => {
    const res = await deleteAssetColor(libraryType, color.id);

    if (res.error) {
      return addToast('Failed to delete color', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }

    addToast('Color deleted', {
      duration: 3000,
      type: 'success',
      secondaryText: 'Color deleted successfully',
      cta: {
        text: 'Undo',
        action: async () => {
          await createAssetColor({
            name: color.name,
            red: color.red,
            green: color.green,
            blue: color.blue,
          });
        },
      },
    });
  };

  return (
    <InfiniteScrollPage loadMore={fetchMore} hasNextPage={hasNextPage}>
      {colors.map((color) => {
        const contextMenuItems = (
          <BlockStack $gap={0}>
            <ContextMenuItem onClick={() => onViewColor(color)}>
              View
            </ContextMenuItem>
            <ContextMenuItem onClick={() => onEditColor(color)}>
              Edit
            </ContextMenuItem>
            <ContextMenuItem onClick={() => onDeleteColor(color)}>
              Delete
            </ContextMenuItem>
          </BlockStack>
        );

        return (
          <ContextMenu
            key={color.id}
            openOnRightClick
            style={{ minWidth: '80px' }}
            items={contextMenuItems}
          >
            <ColorPill
              red={color.red}
              green={color.green}
              blue={color.blue}
              view={view}
              name={color.name}
              onClick={() => onViewColor(color)}
            />
          </ContextMenu>
        );
      })}

      {fetching && <ColorPillLoading view={view} />}
    </InfiniteScrollPage>
  );
};

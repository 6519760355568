import { WorkbenchElementTextData } from '@vizcom/shared/data-access/graphql';

import { ClientSideWorkbenchElementData } from '../../lib/clientState';
import { MixSourceDrawing } from '../elements/mix/WorkbenchElementMix';

export const MIN_SIZE = 10;
export const MIN_PLACEHOLDER_SIZE = [180, 180];
export const MIN_IMG_2_IMG_SIZE = [320, 180];
export const MIN_IMG_2_MESH_SIZE = [140, 105];
export const MIN_PALETTE_SIZE = [180, 180];
export const MIN_COMPOSITE_SCENE_SIZE = [25, 25];
export const MIN_DRAWING_SIZE = [30, 30];
export const MIN_TEXT_SIZE = [30, 30];
export const MIN_MIX_SIZE = [320, 180];
export const MIN_SECTION_SIZE = [100, 100];
export const MIX_ENTRY_HEIGHT = 56;
export const MIX_PADDING = 108;

export const getMixElementHeight = (sourceDrawings: MixSourceDrawing[]) => {
  if (!sourceDrawings.length) return MIN_MIX_SIZE[1];

  const sourcesCount =
    sourceDrawings.length < 5
      ? sourceDrawings.length + 1
      : sourceDrawings.length;
  return sourcesCount * MIX_ENTRY_HEIGHT + MIX_PADDING;
};

export const getElementMinimumSize = (
  element: ClientSideWorkbenchElementData,
  // newHeight is used for elements that have dynamic miniumum size based on their content
  // for resizing contexts
  newHeight?: number
) => {
  if (element.__typename === 'WorkbenchElementImg2Img') {
    const minWidth = Math.max(
      MIN_IMG_2_IMG_SIZE[0],
      MIN_IMG_2_IMG_SIZE[0] *
        ((newHeight ?? element.height) / MIN_IMG_2_IMG_SIZE[1])
    );

    return [minWidth, MIN_IMG_2_IMG_SIZE[1]];
  }

  if (element.__typename === 'WorkbenchElementPlaceholder') {
    return MIN_PLACEHOLDER_SIZE;
  }

  if (element.__typename === 'WorkbenchElementPalette') {
    return MIN_PALETTE_SIZE;
  }

  if (element.__typename === 'CompositeScene') {
    return MIN_COMPOSITE_SCENE_SIZE;
  }

  if (element.__typename === 'Drawing') {
    return MIN_DRAWING_SIZE;
  }

  if (element.__typename === 'WorkbenchElementText') {
    return MIN_TEXT_SIZE;
  }

  if (element.__typename === 'WorkbenchElementSection') {
    return MIN_SECTION_SIZE;
  }

  if (element.__typename === 'WorkbenchElementMix') {
    const height = getMixElementHeight(element.sourceDrawings);
    const minWidth = Math.max(
      MIN_MIX_SIZE[0],
      MIN_MIX_SIZE[0] * ((newHeight ?? element.height) / height)
    );

    return [minWidth, height];
  }

  return [MIN_SIZE, MIN_SIZE];
};

export const getContentSize = (el: HTMLElement | null | undefined) => {
  if (!el)
    return {
      newContentHeight: MIN_SIZE,
      newContentWidth: MIN_SIZE,
    };

  const parent = el.parentNode as HTMLDivElement;
  const currentHeight = el.style.height;

  el.style.height = '';
  parent.style.height = '';

  const newContentHeight = Math.max(el.scrollHeight, MIN_SIZE);
  const newContentWidth = Math.max(el.scrollWidth, MIN_SIZE);

  el.style.height = currentHeight;

  return { newContentHeight, newContentWidth };
};

export const getTextContentSize = (
  element: WorkbenchElementTextData,
  scale: number
) => {
  const el = document.createElement('div');

  el.innerHTML = element.content;
  el.style.fontSize = element.style?.fontSize || '24px';
  el.style.fontWeight = element.style?.fontWeight || 'inherit';
  el.style.textAlign = element.style?.textAlign || 'inherit';
  el.style.whiteSpace = 'pre-wrap';
  el.style.overflowWrap = 'break-word';
  el.style.position = 'absolute';
  el.style.top = '0px';
  el.style.visibility = 'hidden';
  el.style.width = element.lockWidth ? `${element.width / scale}px` : '';
  el.style.fontFamily = element.style?.fontFamily ?? 'Inter';
  el.style.lineHeight = '1.3';
  el.style.letterSpacing = '0.5px';
  el.style.transform = `scale(${scale})`;

  document.body.appendChild(el);

  const { newContentHeight, newContentWidth } = getContentSize(el);

  el.remove();

  return { newContentHeight, newContentWidth };
};

import { Navigate, Route, Routes } from 'react-router-dom';
import { Layout } from '@vizcom/shared-ui-components';

import { PocBanner } from './components/Banners/PocBanner';
import { DraftsPage } from './components/Drafts/DraftsPage';
import { Folder } from './components/Folder/Folder';
import { RecentFilesPage } from './components/RecentFilesPage/RecentFilesPage';
import { Team } from './components/Team/Team';
import { ConnectedUserProfileSurvey } from './components/UserProfileSurvey/ConnectedUserProfileSurvey';

export const Dashboard = () => {
  return (
    <Layout>
      <PocBanner />
      <Routes>
        <Route path="team/:team_id" element={<Team />} />
        <Route
          path="organization/:organizationId/drafts"
          element={<DraftsPage />}
        />
        <Route path="folder/:folderId" element={<Folder />} />
        <Route path=":organizationId/recent" element={<RecentFilesPage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>

      <ConnectedUserProfileSurvey />
    </Layout>
  );
};

import { ephemeralJobMailboxId } from 'libs/shared/data-access/graphql/src/subscriptions/ephemeralJobs/ephemeralJobMailboxId';
import { v4 as uuidv4 } from 'uuid';
import {
  TriggerDrawingCleanupMutation,
  urqlClient,
  waitForEphemeralJobResult,
} from '@vizcom/shared/data-access/graphql';
import { RateLimitQuotaDetails } from '@vizcom/shared/data-shape';
import { assertExists } from '@vizcom/shared/js-utils';
import {
  addToast,
  dismissToast,
  formatErrorMessage,
  imageToBlob,
  imageUrlToImageData,
} from '@vizcom/shared-ui-components';

import { ClientSideWorkbenchElementDrawing } from '../../lib/clientState';
import { useWorkbenchSyncedState } from '../../lib/useWorkbenchSyncedState';

export const cleanupSketch = async (
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'],
  drawing?: ClientSideWorkbenchElementDrawing
) => {
  const loadingToastId = addToast('Cleaning up sketch...', {
    type: 'loading',
  });

  try {
    assertExists(drawing, 'Drawing not found');
    assertExists(drawing.thumbnailPath, 'Drawing has no thumbnail');

    const thumbnailBlob = await imageToBlob(drawing.thumbnailPath);

    const jobId = uuidv4();
    const res = await urqlClient.mutation(TriggerDrawingCleanupMutation, {
      input: {
        jobId,
        image: thumbnailBlob,
        mailboxId: ephemeralJobMailboxId,
      },
    });

    if (res?.error) {
      if (
        (res.error.graphQLErrors?.[0].extensions?.exception as any)?.rateLimit
      ) {
        const rateLimitInfo = (
          res.error.graphQLErrors?.[0].extensions?.exception as any
        )?.rateLimit as RateLimitQuotaDetails;
        addToast(
          `You have been generating too many sketches, please wait ${(
            rateLimitInfo.resetInMs / 1000
          ).toFixed(0)}s before trying again.`,
          {
            type: 'danger',
          }
        );
      } else {
        addToast(`Error using AI service, please retry.`, {
          secondaryText: formatErrorMessage(res.error),
          type: 'danger',
        });
      }
      return;
    }

    const result = await waitForEphemeralJobResult<'backgroundRemover'>(
      jobId,
      30 * 1000
    );
    const imageData = await imageUrlToImageData(
      `data:image/png;base64,${result.image}`
    );

    handleAction({
      type: 'addLayerToDrawing',
      drawingId: drawing.id,
      layer: {
        id: uuidv4(),
        name: 'Cleaned up sketch',
        visible: true,
        opacity: 1,
        blendMode: 'normal',
        fill: '#ffffff',
        isGroup: false,
        image: imageData,
      },
    });

    addToast('Sketch cleaned up successfully');
  } catch (error) {
    addToast(`Error cleaning up sketch, please retry.`, {
      secondaryText: formatErrorMessage(error),
      type: 'danger',
    });
    console.error(error);
  } finally {
    dismissToast(loadingToastId);
  }
};

import { OutputFormat as QuadExportFormat } from 'libs/shared/data-access/graphql/src/gql/graphql';
import { useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import {
  SetCurrentUserClientStateMutation,
  UserClientStateKeys,
  meshByIdQuery,
  meshConvert,
  publishTrackingEvent,
  urqlClient,
  useCurrentUserClientStateByKey,
} from '@vizcom/shared/data-access/graphql';
import { StudioEventName } from '@vizcom/shared/data-shape';
import { LayerMetadata3d } from '@vizcom/shared/js-utils';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import {
  MenuItem,
  addToast,
  downloadFile,
  formatErrorMessage,
  usePaywallModalState,
  Text,
  Checkbox,
  InlineFlex,
  dismissToast,
  downloadModel,
} from '@vizcom/shared-ui-components';

import { DrawingLayer } from '../../lib/useDrawingSyncedState';
import {
  ExportFormat,
  exportObject3d,
  loadGltfFromUrl,
} from '../utils/meshHelpers';

const FREE_SUBSCRIPTION_MAX_3D_LAYER_EXPORTS = 3;

type Layer3dExportItemProps = {
  exportFormat: string;
  handleExportLayer3D: (args: { exportFormat: string }) => void;
  isPendingExport: string | null;
};

const Layer3dExportItem = (props: Layer3dExportItemProps) => {
  const { exportFormat, handleExportLayer3D, isPendingExport } = props;

  return (
    <MenuItem
      label={
        isPendingExport === exportFormat ? (
          <ExportLabelPrefix>Exporting...</ExportLabelPrefix>
        ) : (
          <>
            .<span>{exportFormat.toLowerCase()}</span>
          </>
        )
      }
      onClick={() => handleExportLayer3D({ exportFormat })}
      closeOnClick={false}
    />
  );
};

export const Layer3dMeshExportMenu = ({
  onExportEnded,
  layer,
  isFreePlan,
}: {
  onExportEnded: () => void;
  layer: DrawingLayer;
  isFreePlan: boolean;
}) => {
  const [isPendingExport, setIsPendingExport] = useState<string | null>(null);
  const [quadTopology, setQuadTopology] = useState(!!layer.quadMeshPath);

  const layer3dExportCount =
    useCurrentUserClientStateByKey(UserClientStateKeys.Layer_3DExportCount) ??
    0;
  const metadata3D = layer.metadata3D as LayerMetadata3d | undefined;

  const handleExportLayer3DQuad = async ({
    exportFormat,
  }: {
    exportFormat: string;
  }) => {
    if (!layer.quadMeshPath) return;

    if (metadata3D && isFreePlan) {
      if (layer3dExportCount >= FREE_SUBSCRIPTION_MAX_3D_LAYER_EXPORTS) {
        addToast(
          `You already exported ${FREE_SUBSCRIPTION_MAX_3D_LAYER_EXPORTS} 3D models. Please upgrade to a paid plan to export more.`,
          {
            type: 'danger',
          }
        );
        usePaywallModalState.getState().trigger();

        onExportEnded();

        return;
      }

      await urqlClient.mutation(SetCurrentUserClientStateMutation, {
        input: {
          key: UserClientStateKeys.Layer_3DExportCount,
          value: layer3dExportCount + 1,
        },
      });
    }

    setIsPendingExport(exportFormat);

    const toastId = uuidv4();
    try {
      addToast(
        `Exporting ${
          layer.name
        } with quad topology as .${exportFormat.toLowerCase()}`,
        {
          id: toastId,
          type: 'loading',
        }
      );

      const res = await meshConvert(
        layer.drawingId,
        layer.quadMeshPath,
        exportFormat as QuadExportFormat
      );

      dismissToast(toastId);

      if (exportFormat === 'OBJ') {
        downloadModel(res.mesh, layer.name, 'zip', 'application/zip');
        return;
      }

      downloadModel(
        res.mesh,
        layer.name,
        exportFormat.toLowerCase(),
        'application/octet-stream'
      );
    } catch (error) {
      const rateLimitInfo = (error as any).graphQLErrors?.[0].extensions
        ?.exception as any;
      if (rateLimitInfo?.rateLimit) {
        addToast(
          `You have been exporting too many quad 3D models, please wait ${(
            rateLimitInfo.rateLimit.resetInMs / 1000
          ).toFixed(0)}s before trying again.`,
          {
            type: 'danger',
          }
        );
      }

      dismissToast(toastId);
      addToast('Model export failed. Please try again.', {
        duration: 3000,
        type: 'danger',
      });
    }

    publishTrackingEvent({
      type: StudioEventName.EXPORT,
      data: {
        exportType: '3D',
      },
    });
    trackEvent('3D Export', { type: 'exportLayers3D', quad: true });

    setIsPendingExport(null);
    onExportEnded();
  };

  const handleExportLayer3D = async ({
    exportFormat,
  }: {
    exportFormat: string;
  }) => {
    if (metadata3D && isFreePlan) {
      if (layer3dExportCount >= FREE_SUBSCRIPTION_MAX_3D_LAYER_EXPORTS) {
        addToast(
          `You already exported ${FREE_SUBSCRIPTION_MAX_3D_LAYER_EXPORTS} 3D models. Please upgrade to a paid plan to export more.`,
          {
            type: 'danger',
          }
        );
        usePaywallModalState.getState().trigger();

        onExportEnded();

        return;
      }

      await urqlClient.mutation(SetCurrentUserClientStateMutation, {
        input: {
          key: UserClientStateKeys.Layer_3DExportCount,
          value: layer3dExportCount + 1,
        },
      });
    }

    setIsPendingExport(exportFormat);

    let downloadPath: string;

    if (layer.meshPath) {
      downloadPath =
        layer.meshPath instanceof Blob
          ? URL.createObjectURL(layer.meshPath)
          : layer.meshPath;
    } else if (metadata3D?.mesh) {
      const res = await urqlClient
        .query(meshByIdQuery, {
          id: metadata3D.mesh,
        })
        .toPromise();

      if (res.error || !res.data?.mesh?.path) {
        addToast(`Error while exporting 3D model`, {
          secondaryText: formatErrorMessage(res.error),
          type: 'danger',
        });

        setIsPendingExport(null);
        onExportEnded();

        return;
      }

      downloadPath = res.data.mesh.path;
    } else {
      addToast(`Error while exporting 3D model: unable to download the model`, {
        type: 'danger',
      });

      setIsPendingExport(null);
      onExportEnded();

      return;
    }

    const gltf = await loadGltfFromUrl(downloadPath);
    const model = await exportObject3d(gltf, exportFormat as ExportFormat);
    const blob = new Blob([model.result], { type: model.resultType });

    downloadFile(blob, layer.name, exportFormat);

    if (layer.meshPath instanceof Blob) {
      URL.revokeObjectURL(downloadPath);
    }

    publishTrackingEvent({
      type: StudioEventName.EXPORT,
      data: {
        exportType: '3D',
      },
    });
    trackEvent('3D Export', { type: 'exportLayers3D', quad: false });

    setIsPendingExport(null);
    onExportEnded();
  };

  return (
    <>
      {isPendingExport && <ExportPendingOverlay />}
      <Text style={{ padding: '8px' }} color="subtext">
        File type
      </Text>

      {layer.quadMeshPath && (
        <MenuItem
          label={
            <InlineFlex>
              <Checkbox
                checked={quadTopology}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  setQuadTopology(!quadTopology);
                }}
              />
              <Text color="subtext">Export with quad topology</Text>
            </InlineFlex>
          }
          closeOnClick={false}
          onClick={() => setQuadTopology(!quadTopology)}
        />
      )}

      {!quadTopology &&
        ['glb', 'obj', 'stl', 'usdz'].map((exportFormat) => (
          <Layer3dExportItem
            key={exportFormat}
            exportFormat={exportFormat}
            isPendingExport={isPendingExport}
            handleExportLayer3D={handleExportLayer3D}
          />
        ))}

      {quadTopology &&
        ['FBX', 'OBJ', 'USDZ'].map((exportFormat) => (
          <Layer3dExportItem
            key={exportFormat}
            exportFormat={exportFormat}
            isPendingExport={isPendingExport}
            handleExportLayer3D={handleExportLayer3DQuad}
          />
        ))}
    </>
  );
};

const ExportLabelPrefix = styled.span`
  color: ${({ theme }) => theme.text.subtext};
`;

const ExportPendingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

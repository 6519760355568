import { DragEndEvent } from '@dnd-kit/core';
import { useCallback, useEffect, useReducer } from 'react';

export interface Position {
  x: number;
  y: number;
}

export interface Size {
  width: number;
  height: number;
}

export interface UseWindowControlsProps {
  initialPosition?: Position;
  initialSize?: Size;
  minWidth?: number;
  minHeight?: number;
  maxWidth?: number;
  maxHeight?: number;
  initialLocked?: boolean;
  draggableId?: string;
}

// Define default values
const DEFAULT_MIN_WIDTH = 250;
const DEFAULT_MIN_HEIGHT = 300;
const DEFAULT_MAX_WIDTH = 800;
const DEFAULT_MAX_HEIGHT = 800;
const DEFAULT_DRAGGABLE_ID = 'realtime-window';

// SSR-safe default position
const getDefaultPosition = (): Position => ({
  x: 14,
  y: typeof window !== 'undefined' ? window.innerHeight - 450 : 450,
});

// State management with reducer
interface State {
  position: Position;
  size: Size;
  isLocked: boolean;
}

type Action =
  | { type: 'SET_POSITION'; payload: Position }
  | { type: 'SET_SIZE'; payload: Size }
  | { type: 'LOCK'; payload?: Position }
  | { type: 'UNLOCK' }
  | { type: 'UNLOCK_AT_POSITION'; payload: Position };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_POSITION':
      return { ...state, position: action.payload };
    case 'SET_SIZE':
      return { ...state, size: action.payload };
    case 'LOCK':
      return {
        ...state,
        isLocked: true,
        position: action.payload || state.position,
      };
    case 'UNLOCK':
      return { ...state, isLocked: false };
    case 'UNLOCK_AT_POSITION':
      return {
        ...state,
        isLocked: false,
        position: action.payload,
      };
    default:
      return state;
  }
};

export const useWindowControls = (props: UseWindowControlsProps = {}) => {
  const {
    initialPosition,
    initialSize = { width: 250, height: 350 },
    minWidth = DEFAULT_MIN_WIDTH,
    minHeight = DEFAULT_MIN_HEIGHT,
    maxWidth = DEFAULT_MAX_WIDTH,
    maxHeight = DEFAULT_MAX_HEIGHT,
    initialLocked = true,
    draggableId = DEFAULT_DRAGGABLE_ID,
  } = props;

  // Initialize state with reducer
  const [state, dispatch] = useReducer(reducer, {
    position: initialPosition || getDefaultPosition(),
    size: initialSize,
    isLocked: initialLocked,
  });

  // Window resize handler for locked state
  useEffect(() => {
    if (state.isLocked) {
      const handleWindowResize = () => {
        // Keep current position when window resizes
        dispatch({ type: 'LOCK' });
      };

      if (typeof window !== 'undefined') {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
      }
    }
  }, [state.isLocked]);

  // Handle drag end events
  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      if (event.active.id !== draggableId || state.isLocked) {
        return;
      }

      const { delta } = event;
      const newPosition = {
        x: state.position.x + delta.x,
        y: state.position.y + delta.y,
      };

      dispatch({ type: 'SET_POSITION', payload: newPosition });
    },
    [draggableId, state.position, state.isLocked]
  );

  // Handle size changes with clamping
  const setSize = useCallback(
    (width: number, height: number) => {
      const clampedWidth = Math.min(Math.max(width, minWidth), maxWidth);
      const clampedHeight = Math.min(Math.max(height, minHeight), maxHeight);
      dispatch({
        type: 'SET_SIZE',
        payload: { width: clampedWidth, height: clampedHeight },
      });
    },
    [minWidth, maxWidth, minHeight, maxHeight]
  );

  // Action creators for external use
  const lock = useCallback(() => dispatch({ type: 'LOCK' }), []);

  const unlock = useCallback(() => dispatch({ type: 'UNLOCK' }), []);

  const unlockAtCurrentPosition = useCallback((containerEl: HTMLElement) => {
    // Use requestAnimationFrame to ensure layout is updated
    requestAnimationFrame(() => {
      // Get the element's rect relative to the viewport
      const rect = containerEl.getBoundingClientRect();

      // Get any scroll offsets
      const scrollX = window.scrollX || window.pageXOffset;
      const scrollY = window.scrollY || window.pageYOffset;

      // Get the parent's rect if it exists (to account for relative positioning)
      const parentRect = containerEl.offsetParent?.getBoundingClientRect() || {
        left: 0,
        top: 0,
      };

      // Calculate the absolute position
      const absoluteX = rect.left + scrollX - parentRect.left;
      const absoluteY = rect.top + scrollY - parentRect.top;

      // Validate the position is within viewport bounds
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      // Calculate bounds to keep at least 100px of the window visible
      const minVisiblePx = 100;
      const maxX = viewportWidth - minVisiblePx;
      const maxY = viewportHeight - minVisiblePx;

      // Ensure the position keeps the window visible
      const x = Math.max(minVisiblePx - rect.width, Math.min(absoluteX, maxX));
      const y = Math.max(minVisiblePx - rect.height, Math.min(absoluteY, maxY));

      dispatch({
        type: 'UNLOCK_AT_POSITION',
        payload: { x, y },
      });
    });
  }, []);

  return {
    position: state.position,
    size: state.size,
    isLocked: state.isLocked,
    lock,
    unlock,
    unlockAtCurrentPosition,
    setSize,
    handleDragEnd,
  };
};

import { type Placement } from '@floating-ui/react';
import { ReactNode, useEffect, useState } from 'react';
import {
  UserClientStateKeys,
  useCurrentUserClientStateByKey,
  useSetCurrentUserClientState,
} from '@vizcom/shared/data-access/graphql';
import {
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  useStableCallback,
} from '@vizcom/shared-ui-components';

export const Delay = ({
  children,
  delay,
  startTimer = true,
  durationVisible,
  onDurationVisibleEnds = () => {},
}: {
  children: ReactNode;
  delay: number;
  startTimer?: boolean;
  durationVisible?: number;
  onDurationVisibleEnds?: () => void;
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const stableCallback = useStableCallback(onDurationVisibleEnds);

  useEffect(() => {
    if (!startTimer) return;

    const showTimer = setTimeout(() => {
      setIsVisible(true);
    }, delay);

    const hideTimer = durationVisible
      ? setTimeout(() => {
          setIsVisible(false);
          stableCallback();
        }, delay + durationVisible)
      : undefined;

    return () => {
      clearTimeout(showTimer);
      if (hideTimer) clearTimeout(hideTimer);
    };
  }, [delay, startTimer, durationVisible, stableCallback]);

  return <>{isVisible && children}</>;
};

export enum OnboardingStepName {
  ClickInsert = 'workbench-tour-click-insert',
  InsertImage = 'workbench-tour-insert-image',
  EnterSketch = 'workbench-tour-enter-sketch',
  BackInfiniteCanvas = 'workbench-tour-back-infinite-canvas',
  AnyQuestions = 'workbench-tour-any-questions',
  NewDrawingAutoPrompt = 'workbench-tour-new-drawing-auto-prompt',
  AutoPrompt = 'workbench-tour-auto-prompt',
  AiActions = 'workbench-tour-ai-actions',
}

export const useShouldDisplayOnboardingTooltip = (
  name: OnboardingStepName,
  previousStep?: OnboardingStepName
) => {
  const onboardingStatus =
    useCurrentUserClientStateByKey(UserClientStateKeys.OnboardingStatus) || {};

  if (!onboardingStatus['v_1_1_workbench']) return false;

  const isCurrentStepCompleted = onboardingStatus[name] === true;
  const isPreviousStepCompleted = previousStep
    ? onboardingStatus[previousStep] === true
    : true;

  return isPreviousStepCompleted && !isCurrentStepCompleted;
};

export const useOnboardingStatus = (key: string) => {
  const onboardingStatus =
    useCurrentUserClientStateByKey(UserClientStateKeys.OnboardingStatus) || {};

  return onboardingStatus[key];
};

export const useCompleteOnboardingStep = () => {
  const onboardingStatus =
    useCurrentUserClientStateByKey(UserClientStateKeys.OnboardingStatus) || {};
  const [, updateState] = useSetCurrentUserClientState();

  return async (name: OnboardingStepName) => {
    if (onboardingStatus[name]) return;

    await updateState({
      input: {
        key: UserClientStateKeys.OnboardingStatus,
        value: {
          ...onboardingStatus,
          [name]: true,
        },
      },
    });
  };
};

export const useResetOnboardingStatus = () => {
  const [, updateState] = useSetCurrentUserClientState();

  return async () => {
    await updateState({
      input: {
        key: UserClientStateKeys.OnboardingStatus,
        value: {},
      },
    });
  };
};

export const OnboardingTooltip = ({
  children,
  name,
  title,
  previousStep,
  show = true,
  placement = 'bottom-start',
  durationVisible,
}: {
  children: ReactNode;
  name: OnboardingStepName;
  title: string | ReactNode;
  previousStep?: OnboardingStepName;
  show?: boolean;
  placement?: Placement;
  durationVisible?: number;
}) => {
  const completeOnboardingStep = useCompleteOnboardingStep();
  const shouldDisplayOnboardingTooltip = useShouldDisplayOnboardingTooltip(
    name,
    previousStep
  );

  const manualOpen = show && shouldDisplayOnboardingTooltip;

  useEffect(() => {
    if (durationVisible && manualOpen) {
      setTimeout(() => {
        completeOnboardingStep(name);
      }, durationVisible);
    }
  }, [durationVisible, manualOpen, completeOnboardingStep, name]);

  return (
    <RichTooltip placement={placement} manualOpen={manualOpen}>
      <RichTooltipTrigger>{children}</RichTooltipTrigger>
      <Delay
        delay={1000}
        startTimer={manualOpen}
        durationVisible={durationVisible}
      >
        <RichTooltipContent style={{ color: 'white' }} $background="primary">
          {title}
        </RichTooltipContent>
      </Delay>
    </RichTooltip>
  );
};

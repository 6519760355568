import { OrganizationSubscriptionPlan } from './plans-types';

interface PlanFeaturesLimits {
  maxAllowedEditorsCount: number;
  includedEditorsCount: number;
  multiParallelInference: number;
  logs: boolean;
  securitySettings: boolean;
  teamCreation: boolean;
  highDefinitionExport: boolean;
  teamArchival: boolean;
  highQuality3dGenerationLimitPerMonth: number;
  paletteSettings: boolean;
  startingPaletteStorage: number;
  paletteStoragePerEditor: number;
  batchGenerate: boolean;
  videoGenerationLimitPerMonth: number;
}

/*
 * Temporary function to limit the number of editors to 5 for new PRO plan only.
 * Once the 1 seat limitation migration is done, we can remove this function.
 * TODO: Remove this function after January 2026.
 */
export const getMaxAllowedEditorsCount = (
  currentCount: number,
  plan: OrganizationSubscriptionPlan
) => {
  const MAX_PRO_EDITORS_COUNT = 5;

  return currentCount <= MAX_PRO_EDITORS_COUNT &&
    plan === OrganizationSubscriptionPlan.Pro
    ? MAX_PRO_EDITORS_COUNT
    : PLANS_LIMITS[plan].maxAllowedEditorsCount;
};

export const PLANS_LIMITS: Record<
  OrganizationSubscriptionPlan,
  PlanFeaturesLimits
> = {
  FREE: {
    logs: false,
    securitySettings: false,
    maxAllowedEditorsCount: 1,
    includedEditorsCount: 1,
    highDefinitionExport: false,
    multiParallelInference: 1,
    teamCreation: false,
    teamArchival: false,
    highQuality3dGenerationLimitPerMonth: 0,
    paletteSettings: false,
    paletteStoragePerEditor: 0,
    startingPaletteStorage: 0,
    batchGenerate: false,
    videoGenerationLimitPerMonth: 2,
  },
  // PRO changes should be reflected in EDU as well.
  PRO: {
    logs: false,
    securitySettings: false,
    maxAllowedEditorsCount: Infinity,
    includedEditorsCount: 0,
    highDefinitionExport: true,
    multiParallelInference: 4,
    teamCreation: false,
    teamArchival: false,
    highQuality3dGenerationLimitPerMonth: 50,
    paletteSettings: true,
    paletteStoragePerEditor: 5,
    startingPaletteStorage: 20,
    batchGenerate: false,
    videoGenerationLimitPerMonth: 10,
  },
  ORGANIZATION: {
    logs: true,
    securitySettings: true,
    maxAllowedEditorsCount: Infinity,
    includedEditorsCount: 0,
    highDefinitionExport: true,
    multiParallelInference: 4,
    teamCreation: true,
    teamArchival: true,
    highQuality3dGenerationLimitPerMonth: 150,
    paletteSettings: true,
    paletteStoragePerEditor: 5,
    startingPaletteStorage: 20,
    batchGenerate: false,
    videoGenerationLimitPerMonth: 50,
  },
  ENTERPRISE: {
    logs: true,
    securitySettings: true,
    maxAllowedEditorsCount: Infinity,
    includedEditorsCount: 0,
    highDefinitionExport: true,
    multiParallelInference: 4,
    teamCreation: true,
    teamArchival: true,
    highQuality3dGenerationLimitPerMonth: 150,
    paletteSettings: true,
    paletteStoragePerEditor: 5,
    startingPaletteStorage: 50,
    batchGenerate: true,
    videoGenerationLimitPerMonth: 1000,
  },
  EDU: {
    logs: false,
    securitySettings: false,
    maxAllowedEditorsCount: 100, // Specific for EDU
    includedEditorsCount: 100, // Specific for EDU
    highDefinitionExport: true,
    multiParallelInference: 4,
    teamCreation: true,
    teamArchival: true,
    highQuality3dGenerationLimitPerMonth: 50,
    paletteSettings: true,
    paletteStoragePerEditor: 5,
    startingPaletteStorage: 20,
    batchGenerate: false,
    videoGenerationLimitPerMonth: 10,
  },
};

export const featureLimitForOrganizationSubscription = function <
  Feature extends keyof PlanFeaturesLimits
>(
  organization:
    | { subscriptionPlan: OrganizationSubscriptionPlan }
    | null
    | undefined,
  feature: Feature
):
  | { value: PlanFeaturesLimits[Feature]; loading: false }
  | { value: undefined; loading: true } {
  if (organization === null || organization === undefined) {
    return { value: undefined, loading: true };
  }
  return {
    value: PLANS_LIMITS[organization.subscriptionPlan][feature],
    loading: false,
  };
};

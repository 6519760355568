import { EXTERNAL_API_METHOD_DOCUMENTATION } from './documentation';

export interface VizcomExternalClientApiWorkbench {
  id: string;
  name: string;
}

export interface VizcomExternalClientApiDrawingLayer {
  id: string;
  name: string;
}

export interface VizcomExternalClientApiDrawing {
  id: string;
  name: string;
  layers: VizcomExternalClientApiDrawingLayer[];
}

export const defaultExternalClientApi = {
  userIsLoggedIn: () => ({ loading: false, loggedIn: false }),
  getCurrentWorkbench: () => ({
    loading: false,
    data: null as VizcomExternalClientApiWorkbench | null,
  }),
  getCurrentDrawing: () => ({
    loading: false,
    data: null as VizcomExternalClientApiDrawing | null,
  }),
  getCurrentDrawingLayerImageAsBlob: async (
    layerId: string
  ): Promise<{ image: Blob }> => {
    throw new Error('no drawing open');
  },
  getCurrentDrawingImageAsBlob: async (): Promise<{ image: Blob }> => {
    throw new Error('no drawing open');
  },
  importBlobsAsNewLayerInCurrentDrawing: async (
    blobs: Blob[]
  ): Promise<VizcomExternalClientApiDrawingLayer[]> => {
    throw new Error('no drawing open');
  },
  documentation: () => EXTERNAL_API_METHOD_DOCUMENTATION,
};

export type VizcomExternalClientApi = typeof defaultExternalClientApi;

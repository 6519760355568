import { Object3D } from 'three';

export interface VizcomRenderingOrderEntry {
  escapeZIndexContext?: boolean;
  // if true do not go through the ancestors to get the zIndex context, instead stop at this element
  // and consider it as if it was a root object in the scene
  zIndex: number;
  // lower zIndex values will be rendered first
  conflictId?: string;
  // in case of zIndex being equals, the conflictIds will be compared with `localeCompare`
}

export type VizcomRenderingOrderUserData = VizcomRenderingOrderEntry[];

export const getHierarchicalRenderOrder = (a: Object3D) => {
  const hierarchicalOrder: VizcomRenderingOrderEntry[] = [];

  if (a.userData?.vizcomRenderingOrder) {
    if (!Array.isArray(a.userData.vizcomRenderingOrder)) {
      throw new Error('vizcomRenderingOrder should be an array');
    }
    const vizcomRenderingOrder = [...a.userData.vizcomRenderingOrder].reverse();

    hierarchicalOrder.push(...vizcomRenderingOrder);
    if (
      a.userData.vizcomRenderingOrder.some(
        (order: VizcomRenderingOrderEntry) => order.escapeZIndexContext
      )
    ) {
      return hierarchicalOrder;
    }
  }

  let stopTraversing = false;
  a.traverseAncestors((ancestor) => {
    if (stopTraversing || ('isScene' in ancestor && ancestor.isScene)) {
      return;
    }

    if (ancestor.userData?.vizcomRenderingOrder) {
      const vizcomRenderingOrder = [
        ...ancestor.userData.vizcomRenderingOrder,
      ].reverse();
      hierarchicalOrder.push(...vizcomRenderingOrder);
      if (
        vizcomRenderingOrder.some(
          (order: VizcomRenderingOrderEntry) => order.escapeZIndexContext
        )
      ) {
        stopTraversing = true;
      }
    }
  });

  return hierarchicalOrder.reverse();
};
// This function is used to render objects based on their renderOrder + ancestors renderOrder
// This is different from the default sorting function of three.js which only takes the direct group parent renderOrder into account
// and not the full hierarchy
// More info here:
// https://github.com/mrdoob/three.js/issues/15561#issuecomment-531715127
// https://discourse.threejs.org/t/help-understanding-group-renderorder-and-mesh-renderorder/10790/2

// It starts from the top-most ancestor and compares the renderOrder entries of each ancestor until a difference
// is found, then it returns the comparison result

// For example, if we have a graph like this:
// Group A (renderOrder: [{zIndex: 1}])
//  - Group AA (no-renderOrder)
//    - Mesh AAA (renderOrder: [{zIndex: 10}])
// Group B (renderOrder: [{zIndex: 2}])
//   - Mesh BA (renderOrder: [{zIndex: 0}])
// We would render Mesh AAA first because Group A has a lower renderOrder then Group B even if Mesh AAA has a higher renderOrder than Mesh BA

// Section example:
// Section A (renderOrder: [{zIndex: 10}])
// Element A (renderOrder: [{zIndex: 1}])
// Element B (renderOrder: [{zIndex: 2}])
// If Element A is within Section A, we would render Element A first because Element A has a higher combined renderOrder than Section A
// Because Element A's renderOrder would be [10, 1] and Section A's renderOrder would be [10]

export const sortObjectsByHierarchicalRenderOrder = (
  a: { object: Object3D },
  b: { object: Object3D }
) => {
  const aHierarchicalOrder = getHierarchicalRenderOrder(a.object);
  const bHierarchicalOrder = getHierarchicalRenderOrder(b.object);

  for (
    let i = 0;
    i < Math.max(aHierarchicalOrder.length, bHierarchicalOrder.length);
    i++
  ) {
    const orderA: VizcomRenderingOrderEntry = aHierarchicalOrder[i] ?? {
      zIndex: 0,
    };
    const orderB: VizcomRenderingOrderEntry = bHierarchicalOrder[i] ?? {
      zIndex: 0,
    };

    if (orderA.zIndex !== orderB.zIndex) {
      return orderA.zIndex - orderB.zIndex;
    }

    if (
      orderA.conflictId &&
      orderB.conflictId &&
      orderA.conflictId !== orderB.conflictId
    ) {
      return orderA.conflictId.localeCompare(orderB.conflictId);
    }
  }

  return a.object.id - b.object.id;
};

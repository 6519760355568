import { useCallback, useRef } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import {
  removeBackground,
  publishTrackingEvent,
} from '@vizcom/shared/data-access/graphql';
import {
  addToast,
  useSelectContext,
  imageUrlToImageData,
  dismissToast,
  Text,
  Button,
  ThumbsUpIcon,
  ThumbsDownIcon,
  imageToBlob,
  useIsFree,
  usePaywallModalState,
} from '@vizcom/shared-ui-components';

import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../../lib/useDrawingSyncedState';
import { AiAction } from './AiActions';

const performRemoveBackground = async (
  drawingId: string,
  image: Blob | string
) => {
  const res = await removeBackground(drawingId, image);
  const base64Image = res.image;
  return await imageUrlToImageData(`data:image/png;base64,${base64Image}`);
};

export const RemoveBackgroundAction = ({
  drawing,
  index,
  handleAction,
  getCompositedImage,
}: {
  drawing: Drawing2dStudio;
  index: number;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  getCompositedImage: (() => ImageData) | undefined;
}) => {
  const { handleSelect } = useSelectContext();
  const isFreePlan = useIsFree(drawing.id);
  const { trigger } = usePaywallModalState();

  const originalLayerVisibilityStates = useRef<
    { id: string; visible: boolean }[]
  >([]);

  // Create a ref for the handleRemoveBackground function to avoid the dependency cycle
  const handleRemoveBackgroundRef =
    useRef<(previousLayerId?: string, isRetry?: boolean) => Promise<void>>();

  const handleRestoreLayerVisibility = useCallback(() => {
    handleAction({
      type: 'updateBulkLayers',
      layerUpdates: originalLayerVisibilityStates.current.map((state) => ({
        id: state.id,
        visible: state.visible,
      })),
    });
  }, [handleAction]);

  const showRemoveBackgroundFeedback = useCallback(
    (layerId: string) => {
      const feedbackToastId = uuidv4();
      addToast(
        <RemoveBackgroundUserFeedback
          toastId={feedbackToastId}
          handleApprove={() => handleRestoreLayerVisibility()}
          handleRetry={() => {
            handleRestoreLayerVisibility();
            // Use the ref to call the current function implementation
            return (
              handleRemoveBackgroundRef.current?.(layerId, true) ||
              Promise.resolve()
            );
          }}
          drawingId={drawing.id}
        />,
        {
          id: feedbackToastId,
          duration: 10000,
        }
      );
    },
    [drawing.id, handleRestoreLayerVisibility]
  );

  const handleRemoveBackground = useCallback(
    async (previousLayerId?: string, isRetry = false) => {
      const toastId = uuidv4();
      const toastMessage = isRetry
        ? 'Retry removing background.'
        : 'Removing background. The result will appear in the layers tab.';

      addToast(toastMessage, {
        id: toastId,
        type: 'loading',
      });

      if (previousLayerId) {
        handleAction({
          type: 'updateBulkLayers',
          deletedLayerIds: [previousLayerId],
        });
      }

      try {
        const imageData = await performRemoveBackground(
          drawing.id,
          getCompositedImage
            ? await imageToBlob(getCompositedImage())
            : (drawing.thumbnailPath as string)
        );

        const newLayerId = uuidv4();
        handleAction({
          type: 'addLayer',
          layer: {
            id: newLayerId,
            image: imageData,
            name: 'Removed Background',
            visible: true,
            opacity: 1,
            blendMode: 'normal',
            isGroup: false,
            fill: '',
            meshPath: null,
            parentId: null,
            placement: 'top',
          },
        });

        if (!isRetry) {
          originalLayerVisibilityStates.current = drawing.layers.nodes.map(
            (layer) => ({
              id: layer.id,
              visible: layer.visible,
            })
          );
        }

        // Hide all other layers
        handleAction({
          type: 'updateBulkLayers',
          layerUpdates: drawing.layers.nodes
            .filter((layer) => layer.id !== newLayerId)
            .map((layer) => ({ id: layer.id, visible: false })),
        });

        dismissToast(toastId);
        showRemoveBackgroundFeedback(newLayerId);
      } catch (error) {
        dismissToast(toastId);
        addToast('Remove background failed. Please try again.', {
          duration: 3000,
          type: 'danger',
        });
      }
    },
    [
      drawing.id,
      drawing.thumbnailPath,
      drawing.layers.nodes,
      getCompositedImage,
      handleAction,
      showRemoveBackgroundFeedback,
    ]
  );

  // Update the ref with the latest implementation
  // This ensures the ref always points to the most current version of the function
  // with all its dependencies up to date
  useRef(() => {
    handleRemoveBackgroundRef.current = handleRemoveBackground;
  }).current();

  const handleInitialRemoveBackground = useCallback(() => {
    // if (isFreePlan) {
    //   trigger('freeToPro');
    //   return;
    // }

    handleSelect(index);
    handleRemoveBackground();
    // }, [handleSelect, index, handleRemoveBackground, isFreePlan, trigger]);
  }, [handleSelect, index, handleRemoveBackground]);

  return (
    <AiAction onClick={handleInitialRemoveBackground}>
      Remove background
      {/* {isFreePlan && <UpgradeIcon />} */}
    </AiAction>
  );
};

const RemoveBackgroundUserFeedback = ({
  toastId,
  handleApprove,
  handleRetry,
  drawingId,
}: {
  toastId: string;
  handleApprove: () => void;
  handleRetry: () => Promise<void>;
  drawingId: string;
}) => {
  const handleFeedback = (feedback: 'approve' | 'retry') => {
    publishTrackingEvent({
      type: 'REMOVE_BACKGROUND_FEEDBACK',
      data: { drawingId, feedback },
    });
    dismissToast(toastId);
    if (feedback === 'approve') return handleApprove();
    if (feedback === 'retry') handleRetry();
  };

  return (
    <FeedbackContainer>
      <Text type="sh2">Background removed</Text>
      <VerticalDivider />
      <IconContainer>
        <Button
          size="S"
          variant="primary"
          onClick={() => handleFeedback('approve')}
        >
          <ThumbsUpIcon /> Approve
        </Button>
        <Button
          size="S"
          variant="tertiary"
          onClick={() => handleFeedback('retry')}
        >
          <ThumbsDownIcon /> Retry
        </Button>
      </IconContainer>
      <VerticalDivider />
    </FeedbackContainer>
  );
};

const FeedbackContainer = styled.div`
  width: 100%;
  display: inline-flex;
  gap: 14px;
  align-items: center;
  justify-content: center;
`;

const VerticalDivider = styled.div`
  width: 1px;
  height: 30px;
  background: ${({ theme }) => theme.surface.tertiary};
`;

const IconContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacing.s};
`;

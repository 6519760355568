import { VizcomLogoAnimated } from 'libs/shared/ui/components/src/lib/Logo/LoadingLogo';
import { useMemo, useState } from 'react';
import { Link, useParams, Navigate } from 'react-router-dom';
import { useClient } from 'urql';
import {
  useFolder,
  useTeam,
  useWorkbenchesByFolderId,
  workbenchesByFolderId,
} from '@vizcom/shared/data-access/graphql';
import {
  Button,
  InfiniteScrollPage,
  Text,
  useSelectedOrganization,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

import { CreateNewDrawingPlaceholder } from '../Files/CreateNewDrawingPlaceholder';
import { File } from '../Files/File/File';
import {
  Error,
  FullWidthGridItem,
  Grid,
  GridWrapper,
  Header,
  Wrapper,
} from '../Files/Files.styled';
import { Loading } from '../Loading/Loading';
import { Breadcrumbs } from './BreadCrumbs';
import { FolderItem } from './FolderItem';
import { HeaderActions } from './HeaderActions';
import { HeaderTitle } from './HeaderTitle';
import {
  SelectableItemType,
  SelectedItemsProvider,
} from './SelectedItemsProvider';

const WORKBENCHES_PER_PAGE = 30;

interface FolderFilesGridProps {
  children: React.ReactNode;
}

const FolderFilesGrid = ({ children }: FolderFilesGridProps) => (
  <GridWrapper>
    <Grid>{children}</Grid>
  </GridWrapper>
);

const WorkbenchResultsPage = ({
  pageIndex,
  folderId,
  cursor,
  setNextPageCursor,
  folderLength,
}: {
  pageIndex: number;
  folderId: string;
  cursor: string | null;
  setNextPageCursor: (cursor: string) => void;
  folderLength: number;
}) => {
  const { data, error, fetching, pageInfo } = useWorkbenchesByFolderId(
    folderId,
    cursor,
    WORKBENCHES_PER_PAGE
  );

  if (fetching) {
    return (
      <div
        style={{
          gridColumn: '1 / -1',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <VizcomLogoAnimated />;
      </div>
    );
  }

  if (error) {
    return (
      <FullWidthGridItem>
        <Error>
          <Text>There was an error loading files, please retry.</Text>
        </Error>
      </FullWidthGridItem>
    );
  }

  return (
    <InfiniteScrollPage
      loadMore={() => setNextPageCursor(pageInfo?.endCursor)}
      hasNextPage={pageInfo?.hasNextPage}
    >
      {data?.map((workbench, index) => (
        <File
          key={workbench.id}
          file={workbench}
          index={index + folderLength + WORKBENCHES_PER_PAGE * pageIndex}
        />
      ))}
    </InfiniteScrollPage>
  );
};

export const Folder = (props: {
  folderId?: string;
  isTeamPage?: boolean;
  isDraftsPage?: boolean;
  links?: React.ReactNode;
}) => {
  const { isTeamPage, links, isDraftsPage } = props;
  const params = useParams();
  const folderId = props.folderId || params.folderId;
  useSelectedOrganization(); // used to register the current organization from the folder, this needs to be called here to have access to the params of this router

  const [pageCursors, setPageCursors] = useState<Array<string | null>>([null]);
  const { data: folder, error, fetching } = useFolder(folderId);
  const { data: team } = useTeam(folder?.rootTeam?.id);
  const client = useClient();

  const breadcrumbs = useMemo(() => {
    if (!folder) return [];
    return [
      folder.rootTeam && (
        <Link to={paths.files.team(folder.rootTeam.id)}>
          {folder.rootTeam?.name}
        </Link>
      ),
      ...folder.breadcrumbs.nodes
        .filter(
          (parent) => parent.parentId || parent.rootFolderOfDraftOrganizationId
        )
        .map((parent) =>
          parent.rootFolderOfDraftOrganizationId ? (
            <Link
              key={parent.id}
              to={paths.files.organizationDraftFolder(
                parent.rootFolderOfDraftOrganizationId
              )}
            >
              My files
            </Link>
          ) : (
            <Link key={parent.id} to={paths.files.folder(parent.id)}>
              {parent.name}
            </Link>
          )
        ),
    ].filter(Boolean);
  }, [folder]);

  if (fetching) {
    return (
      <Wrapper>
        <Loading />
      </Wrapper>
    );
  }

  if (error || !folder) {
    return (
      <Wrapper>
        <Error>
          <Text>There was an error getting this page. Please retry.</Text>
          <Button variant="primary" as={Link} to={paths.files.dashboard()}>
            Go back
          </Button>
        </Error>
      </Wrapper>
    );
  }

  // Redirecting to team page if accessing the root folder of a team directly
  if (!isTeamPage && folder?.rootTeam && !folder.parentId) {
    // if accessing directly the root folder of a team, we redirect the client to the team page instead
    return <Navigate to={paths.files.team(folder.rootTeam.id)} replace />;
  }

  // Redirecting to drafts page if accessing the root folder of a draft organization directly
  if (!isDraftsPage && folder.rootFolderOfDraftOrganizationId) {
    return (
      <Navigate
        to={paths.files.organizationDraftFolder(
          folder.rootFolderOfDraftOrganizationId
        )}
        replace
      />
    );
  }

  const workbenches = pageCursors.map((cursor, index) => (
    <WorkbenchResultsPage
      key={index}
      pageIndex={index}
      folderId={folder.id}
      cursor={cursor}
      setNextPageCursor={(cursor) =>
        setPageCursors((cursors) => [...cursors, cursor])
      }
      folderLength={folder.childFolders.nodes.length}
    />
  ));

  const getSelectableItems = () => [
    ...folder.childFolders.nodes.map((child, index) => ({
      id: child.id,
      type: 'folder' as SelectableItemType,
      index,
    })),
    ...pageCursors.flatMap((cursor, pageIndex) => {
      if (!folder) {
        return [];
      }
      const queryData = client.readQuery(workbenchesByFolderId, {
        id: folder.id,
        cursor,
        count: WORKBENCHES_PER_PAGE,
      });
      if (queryData?.data) {
        return (
          queryData.data.workbenches?.nodes.map((workbench, index) => ({
            id: workbench.id,
            type: 'workbench' as SelectableItemType,
            index:
              folder.childFolders.nodes.length +
              index +
              pageIndex * WORKBENCHES_PER_PAGE,
          })) || []
        );
      }
      return [];
    }),
  ];

  return (
    <Wrapper>
      <Header>
        <div
          style={{
            gridArea: links ? 'title' : 'title / links / links / links',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <HeaderTitle
            isTeamPage={isTeamPage}
            name={
              isDraftsPage
                ? 'My files'
                : isTeamPage && folder.rootTeam
                ? folder.rootTeam.name
                : folder.name
            }
          />
          {!isTeamPage && !isDraftsPage && (
            <Breadcrumbs breadcrumbs={breadcrumbs} name={folder.name} />
          )}
        </div>
        <HeaderActions
          isTeamPage={isTeamPage}
          newPath={paths.workbench.new(folder.id)}
          team={team || undefined}
          folder={folder}
          isDraftsPage={isDraftsPage}
        />
      </Header>

      <SelectedItemsProvider getItems={getSelectableItems}>
        {folder.childFolders.nodes.length !== 0 && (
          <>
            <Text type="h2">Folders</Text>
            <FolderFilesGrid>
              {folder.childFolders.nodes.map((folder, index) => (
                <FolderItem key={folder.id} folder={folder} index={index} />
              ))}
            </FolderFilesGrid>
          </>
        )}

        <Text type="h2">Files</Text>
        <FolderFilesGrid>
          {folder.workbenches.totalCount === 0 &&
            (folder.isEditable ? (
              <CreateNewDrawingPlaceholder folderId={folder.id} />
            ) : (
              <Text type="sh2" color="subtext" style={{ marginTop: 24 }}>
                No files yet
              </Text>
            ))}
          {workbenches}
        </FolderFilesGrid>
      </SelectedItemsProvider>
    </Wrapper>
  );
};

import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import {
  VizcomLogo,
  Button,
  MenuHorizontalIcon,
  Text,
  RichTooltipContent,
  RichTooltipTrigger,
  ContextMenuItem,
  RichTooltip,
  useCrisp,
  ToolbarDivider,
} from '@vizcom/shared-ui-components';

export const MobileWorkbenchFileMenu = ({ title }: { title: string }) => {
  const theme = useTheme();

  return (
    <FileMenu
      style={{
        right: 14,
        width: 'unset',
        padding: 8,
        background: theme.surface.secondary,
        zIndex: 1,
      }}
    >
      <LogoContainer>
        <VizcomLogo width={32} height={32} style={{ padding: 8 }} />
        <ToolbarDivider />
      </LogoContainer>
      <HeaderContainer>
        <Text type="sh1">{title}</Text>
      </HeaderContainer>
      <MobileMenu />
    </FileMenu>
  );
};

const FileMenu = styled.div`
  position: absolute;
  top: 1rem;
  left: 14px;
  color: ${({ theme }) => theme.text.body};
  z-index: 10000000;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  gap: 1rem;
  padding: 4px 8px;
  border-radius: ${({ theme }) => theme.borderRadius.l};
  background: ${({ theme }) => theme.surface.primary};
  grid-template-areas: 'logo name loader';
  width: 250px;
  height: 48px;
  pointer-events: all;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const HeaderContainer = styled.div`
  justify-self: center;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

const MobileMenu = () => {
  const theme = useTheme();
  const crisp = useCrisp();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <RichTooltip
      trigger="click"
      displayArrow={false}
      isOpen={isMenuOpen}
      onOpenChange={setIsMenuOpen}
    >
      <RichTooltipTrigger>
        <Button variant="tertiary">
          <MenuHorizontalIcon />
        </Button>
      </RichTooltipTrigger>
      <RichTooltipContent
        style={{
          padding: 8,
          marginTop: 16,
          borderRadius: theme.borderRadius.l,
          width: 176,
        }}
      >
        <LinksContainer>
          <LinkItem href="https://www.youtube.com/channel/UCU-XOa74z1aGdZRyvRBQ6UQ/videos">
            Tutorials
          </LinkItem>
          <LinkItem href="https://www.vizcom.ai/terms-of-service">
            Legal summary
          </LinkItem>
          <LinkContainer
            onClick={() => {
              crisp.openChat();
              setIsMenuOpen(false);
            }}
          >
            Contact support
          </LinkContainer>
          <LinkItem href="https://forms.gle/LJZzUfHDWwiZeHBW6">
            Send feedback
          </LinkItem>
        </LinksContainer>
      </RichTooltipContent>
    </RichTooltip>
  );
};

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const LinkItem = ({
  href,
  children,
}: {
  href: string;
  children: React.ReactNode;
}) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();
  };

  return (
    <LinkContainer>
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleClick}
        style={{ display: 'block', width: '100%', height: '100%' }}
      >
        {children}
      </a>
    </LinkContainer>
  );
};

const LinkContainer = styled(ContextMenuItem)`
  padding: 10px 8px;
  margin: 0;
`;

import { useThree } from '@react-three/fiber';
import { createUseGesture, pinchAction, wheelAction } from '@use-gesture/react';
import { OrthographicCamera, Vector2 } from 'three';
import { logStep } from '@vizcom/shared/js-utils';
import { CMD_KEY_NAME } from '@vizcom/shared-ui-components';

import { screenPositionToWorld } from '../../helpers';
import { useMapControls } from './utils';
import { CameraLimits, normalizeWheel } from './utils';

const useGesture = createUseGesture([wheelAction, pinchAction]);

const detectIsTouchpad = function (event: WheelEvent) {
  // windows wheel events are based on "lines", some are integers, some are floats
  // meaning the next condition will be false for windows mouse
  // line based scrolling will be a much higher number than pixel based scrolling
  if (Math.abs(event.deltaY) > 30) {
    return false;
  }

  // from: https://stackoverflow.com/a/74597327
  if (event.deltaY && !Number.isInteger(event.deltaY)) {
    return false;
  }

  return true;
};

export const useWorkbenchWheelGesture = ({
  getCameraLimits,
}: {
  getCameraLimits: () => CameraLimits;
}) => {
  const eventDomElement = useThree((s) => s.events.connected);
  const camera = useThree((s) => s.camera as OrthographicCamera);
  const controls = useMapControls();

  useGesture(
    {
      onWheel: (gesture) => {
        const { event, active, memo } = gesture;

        if (!active) {
          return;
        }

        // on a touchpad (laptop) we want the scroll event to be a pan action (because it has pinch to zoom)
        // but with a mouse, we want the scroll event to zoom
        // we do this detection only once per gesture, allowing separate devices to have different behavior
        const touchpad = memo?.isTouchPad ?? detectIsTouchpad(event);

        event.preventDefault();
        const delta = normalizeWheel(event);
        const limits = getCameraLimits();
        if (!touchpad || event[CMD_KEY_NAME]) {
          // zoom in/out centered on the pointer position
          const position = screenPositionToWorld(
            [gesture.event.clientX, gesture.event.clientY],
            camera
          );

          const zoomTarget = logStep(
            camera.zoom,
            event.ctrlKey || event.metaKey ? delta.z : delta.y / 5000,
            limits.zoomMin,
            limits.zoomMax
          );

          const zoomFactor = camera.zoom / zoomTarget;
          const targetX =
            (camera.position.x - position[0]) * zoomFactor + position[0];
          const targetY =
            (camera.position.y - position[1]) * zoomFactor + position[1];

          controls.moveTo({
            x: targetX,
            y: targetY,
            zoom: zoomTarget,
            skipAnimation: true,
            limits,
          });
        } else {
          const position = new Vector2(delta.x, delta.y);
          position.rotateAround(new Vector2(0, 0), -camera.rotation.z);
          position.divideScalar(camera.zoom);

          controls.moveTo({
            x: position.x * -1,
            y: position.y,
            relative: true,
            skipAnimation: true,
            limits: getCameraLimits(),
          });
        }

        return {
          isTouchPad: touchpad,
        };
      },
    },
    {
      target: eventDomElement,
      eventOptions: { passive: false },
    }
  );
};

import styled, { useTheme } from 'styled-components';
import {
  Button,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  Tabs,
  Tab,
} from '@vizcom/shared-ui-components';

import { ClientSideWorkbenchElementData } from '../../../lib/clientState';
import { Drawing2dStudio } from '../../../lib/useDrawingSyncedState';
import { StudioExportButtons } from '../../studio/Toolbar/export/StudioExportButtons';
import { WorkbenchExportImagesButton } from '../ExportFiles';
import { LinkSharingTabContent } from './LinkSharingTab';

export const PublicFileSharingButton = ({
  workbenchId,
  drawing,
  elements,
}: {
  workbenchId: string;
  drawing?: Drawing2dStudio;
  elements: ClientSideWorkbenchElementData[];
}) => {
  const theme = useTheme();

  return (
    <RichTooltip trigger="click" displayArrow={false} padding={12}>
      <RichTooltipTrigger>
        <Button variant="primary">Share</Button>
      </RichTooltipTrigger>

      <RichTooltipContent
        style={{
          overflow: 'hidden',
          padding: 8,
          borderRadius: theme.borderRadius.m,
        }}
      >
        <Tabs style={{ gap: 4 }}>
          {drawing && (
            <Tab key="export" title="Export">
              <ExportFloatingContainer>
                <StudioExportButtons drawing={drawing} />
              </ExportFloatingContainer>
            </Tab>
          )}

          <Tab key="share" title="Share">
            <LinkSharingTabContent workbenchId={workbenchId} />
          </Tab>

          {!drawing && (
            <Tab key="batch-export" title="Export">
              <ExportFloatingContainer>
                <WorkbenchExportImagesButton
                  elements={elements}
                  workbenchId={workbenchId}
                />
              </ExportFloatingContainer>
            </Tab>
          )}
        </Tabs>
      </RichTooltipContent>
    </RichTooltip>
  );
};

export const ExportFloatingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 334px;
`;

import { GroupProps, ThreeEvent } from '@react-three/fiber';
import { UserPaletteDataFragment } from 'libs/shared/data-access/graphql/src/gql/graphql';
import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import {
  OrganizationSubscriptionPlan,
  useUserPalette,
} from '@vizcom/shared/data-access/graphql';
import {
  Button,
  WideCarretDownIconRaw,
  CheckCircleIconRaw,
  CloseIconOrangeRaw,
  ErrorIconRaw,
  Modal,
  PlayCircleIconRaw,
  PublishIconRaw,
  RetryIconRaw,
  useSelectedOrganization,
  RichTooltipContent,
  RichTooltip,
  RichTooltipTrigger,
  Text,
} from '@vizcom/shared-ui-components';

import { ClientSideWorkbenchElementPalette } from '../../../lib/clientState';
import { useWorkbenchSyncedState } from '../../../lib/useWorkbenchSyncedState';
import { CustomHtml } from '../../utils/CustomHtml';
import { CustomText } from '../../utils/CustomText';
import { StaticSvg } from '../../utils/StaticSvg';
import { AnimatedLoadingSvg } from './AnimatedLoadingSvg';
import { PublishPaletteModal } from './PublishPaletteModal';
import { PublishedPaletteDetails } from './PublishedPaletteDetails';

interface StatusButtonProps {
  element: ClientSideWorkbenchElementPalette;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
  width: number;
  height: number;
  paletteStatusWidth: number;
  handleStatusTextSync: (troika: any, adornmentOffset?: number) => void;
}

const IdleStatusButton: React.FC<StatusButtonProps> = ({
  element,
  handleAction,
  width,
  height,
  paletteStatusWidth,
  handleStatusTextSync,
}) => {
  const theme = useTheme();
  const handleClick = (e: ThreeEvent<MouseEvent>) => {
    e.stopPropagation();
    handleAction({ type: 'trainPalette', id: element.id });
  };

  return (
    <StatusButtonWrapper
      width={width}
      height={height}
      paletteStatusWidth={paletteStatusWidth}
      onClick={handleClick}
    >
      <CustomText
        color="#FFF"
        outlineColor="#000"
        fontSize={12}
        font="Inter"
        fontWeight="semiBold"
        anchorX="left"
        whiteSpace="nowrap"
        content="Train"
        backgroundColor={theme.button.palette}
        radius="s"
        paddingX={10}
        paddingY={5}
        startAdornment={{
          svg: (
            <StaticSvg
              svg={PlayCircleIconRaw}
              fillMaterial={{ color: 0xffffff }}
            />
          ),
          width: 18,
        }}
        onSync={(t) => handleStatusTextSync(t, 20)}
      />
    </StatusButtonWrapper>
  );
};

const TrainingStatusButton: React.FC<StatusButtonProps> = ({
  element,
  handleAction,
  width,
  height,
  paletteStatusWidth,
  handleStatusTextSync,
}) => {
  const [showCancel, setShowCancel] = useState(false);
  const [showEstimate, setShowEstimate] = useState(false);
  const [confirmCancelTraining, setConfirmCancelTraining] = useState(false);
  const theme = useTheme();

  const handleClick = (e: ThreeEvent<MouseEvent>) => {
    e.stopPropagation();
    setConfirmCancelTraining(true);
    setShowCancel(false);
  };

  const getTrainingStatusMessage = () => {
    const remainingTrainingMs = element.estimatedTrainingEndTimestamp
      ? new Date(element.estimatedTrainingEndTimestamp).getTime() - Date.now()
      : 0;

    const minutesRemaining = Math.floor(remainingTrainingMs / (1000 * 60));

    if (minutesRemaining < 1) {
      return 'Almost ready!';
    }

    // Short tier (< 8 minutes)
    if (minutesRemaining <= 8) {
      const shortMessages = [
        'Your palette will be ready in a few moments',
        'Almost there! Grab a quick coffee while we work on this',
        "We're processing your palette – won't be long",
      ];
      return shortMessages[Math.floor(Math.random() * shortMessages.length)];
    }

    // Medium tier (< 25 minutes)
    if (minutesRemaining <= 25) {
      const mediumMessages = [
        'Your palette will be ready shortly – perfect time for a quick break',
        'Processing your palette – check back in a little while',
        "This might take a brief while – we'll notify you when it's done",
      ];
      return mediumMessages[Math.floor(Math.random() * mediumMessages.length)];
    }

    // Long tier (> 25 minutes)
    const longMessages = [
      'This is a complex palette – check back in about an hour',
      'Taking some extra time to perfect your palette – feel free to work on other things',
      "Your palette needs some time to process – we'll let you know when it's ready",
    ];
    return longMessages[Math.floor(Math.random() * longMessages.length)];
  };

  return (
    <>
      <StatusButtonWrapper
        width={width}
        height={height}
        paletteStatusWidth={paletteStatusWidth}
        onPointerOver={() => setShowEstimate(true)}
        onPointerLeave={() => setShowEstimate(false)}
      >
        {showCancel ? (
          <CustomHtml transform position={[paletteStatusWidth - 17, 4, 0]}>
            <RichTooltip isOpen={true} placement="top">
              <RichTooltipTrigger>
                <EmptyTrigger>.</EmptyTrigger>
              </RichTooltipTrigger>
              <RichTooltipContent>
                <Text type="b2">Cancel Training</Text>
              </RichTooltipContent>
            </RichTooltip>
          </CustomHtml>
        ) : showEstimate ? (
          <CustomHtml transform position={[-4, 4, 0]}>
            <RichTooltip isOpen={true} placement="top">
              <RichTooltipTrigger>
                <EmptyTrigger>.</EmptyTrigger>
              </RichTooltipTrigger>
              <RichTooltipContent>
                <Text type="b2">{getTrainingStatusMessage()}</Text>
              </RichTooltipContent>
            </RichTooltip>
          </CustomHtml>
        ) : null}
        <CustomText
          color={theme.button.palette}
          outlineColor="#000"
          fontSize={12}
          font="Inter"
          fontWeight="semiBold"
          anchorX="left"
          whiteSpace="nowrap"
          content="Training"
          backgroundColor={theme.surface.paletteDisabled}
          radius="s"
          paddingX={10}
          paddingY={5}
          startAdornment={{
            svg: <AnimatedLoadingSvg />,
            width: 18,
          }}
          endAdornment={{
            svg: (
              <group
                onPointerOver={() => setShowCancel(true)}
                onPointerLeave={() => setShowCancel(false)}
                onClick={handleClick}
                userData={{
                  cursor: 'pointer',
                }}
              >
                <mesh>
                  <planeGeometry args={[18, 18]} />
                  <meshBasicMaterial color={0xffffff} transparent opacity={0} />
                </mesh>
                <StaticSvg
                  svg={CloseIconOrangeRaw}
                  fillMaterial={{ color: 0xff5106 }}
                />
              </group>
            ),
            width: 18,
          }}
          onSync={(t) => handleStatusTextSync(t, 40)}
        />
      </StatusButtonWrapper>
      {confirmCancelTraining && (
        <CustomHtml>
          <Modal
            isOpen={true}
            setIsOpen={() => setConfirmCancelTraining(false)}
            style={{
              backgroundColor: theme.surface.primary,
              padding: '16px',
              minWidth: '320px',
              width: '320px',
            }}
          >
            <Container>
              <Title>Are you sure you'd like to stop palette training?</Title>
              <Content>This action can't be undone.</Content>
              <Buttons>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => setConfirmCancelTraining(false)}
                >
                  Return
                </Button>
                <Button
                  type="button"
                  variant="danger"
                  onClick={() => {
                    handleAction({
                      type: 'cancelPaletteTraining',
                      id: element.id,
                    });
                    setConfirmCancelTraining(false);
                  }}
                >
                  Stop Training
                </Button>
              </Buttons>
            </Container>
          </Modal>
        </CustomHtml>
      )}
    </>
  );
};

const PublishStatusButton: React.FC<StatusButtonProps> = ({
  element,
  width,
  height,
  paletteStatusWidth,
  handleStatusTextSync,
}) => {
  const theme = useTheme();
  const [showConfirm, setShowConfirm] = useState(false);

  const handleClick = (e: ThreeEvent<MouseEvent>) => {
    e.stopPropagation();
    setShowConfirm(true);
  };

  return (
    <>
      <StatusButtonWrapper
        width={width}
        height={height}
        paletteStatusWidth={paletteStatusWidth}
        onClick={handleClick}
      >
        <CustomText
          color="#FFF"
          outlineColor="#000"
          fontSize={12}
          font="Inter"
          fontWeight="semiBold"
          anchorX="left"
          whiteSpace="nowrap"
          content="Publish"
          backgroundColor={theme.deprecated.primary.default}
          radius="s"
          paddingX={10}
          paddingY={5}
          startAdornment={{
            svg: (
              <StaticSvg
                svg={PublishIconRaw}
                fillMaterial={{ color: 0xffffff }}
              />
            ),
            width: 18,
          }}
          onSync={(t) => handleStatusTextSync(t, 20)}
        />
      </StatusButtonWrapper>
      {showConfirm && (
        <CustomHtml>
          <PublishPaletteModal
            onClose={() => setShowConfirm(false)}
            element={element}
          />
        </CustomHtml>
      )}
    </>
  );
};

const PublishedStatusButton: React.FC<
  StatusButtonProps & { publishedPalette: UserPaletteDataFragment }
> = ({
  width,
  height,
  publishedPalette,
  paletteStatusWidth,
  handleStatusTextSync,
}) => {
  const theme = useTheme();
  const [showDetails, setShowDetails] = useState(false);

  return (
    <StatusButtonWrapper
      width={width}
      height={height}
      paletteStatusWidth={paletteStatusWidth}
      onClick={() => setShowDetails(true)}
    >
      <CustomText
        color="#FFF"
        outlineColor="#000"
        fontSize={12}
        font="Inter"
        fontWeight="semiBold"
        anchorX="left"
        whiteSpace="nowrap"
        content="Published"
        backgroundColor={theme.surface.success}
        radius="s"
        paddingX={10}
        paddingY={5}
        startAdornment={{
          svg: (
            <StaticSvg
              svg={CheckCircleIconRaw}
              fillMaterial={{ color: 0xffffff }}
            />
          ),
          width: 18,
        }}
        endAdornment={{
          svg: (
            <StaticSvg
              svg={WideCarretDownIconRaw}
              fillMaterial={{ color: 0xffffff }}
            />
          ),
          width: 18,
        }}
        onSync={(t) => handleStatusTextSync(t, 40)}
      />

      {showDetails && (
        <PublishedPaletteDetails
          setShowDetails={setShowDetails}
          palette={publishedPalette}
        />
      )}
    </StatusButtonWrapper>
  );
};

const CompletedStatusButton: React.FC<StatusButtonProps> = ({
  width,
  height,
  paletteStatusWidth,
  handleStatusTextSync,
}) => {
  const theme = useTheme();

  return (
    <StatusButtonWrapper
      width={width}
      height={height}
      paletteStatusWidth={paletteStatusWidth}
    >
      <CustomText
        color="#FFF"
        outlineColor="#000"
        fontSize={12}
        font="Inter"
        fontWeight="semiBold"
        anchorX="left"
        whiteSpace="nowrap"
        content="Completed"
        backgroundColor={theme.surface.success}
        radius="s"
        paddingX={10}
        paddingY={5}
        startAdornment={{
          svg: (
            <StaticSvg
              svg={CheckCircleIconRaw}
              fillMaterial={{ color: 0xffffff }}
            />
          ),
          width: 18,
        }}
        onSync={(t) => handleStatusTextSync(t, 20)}
      />
    </StatusButtonWrapper>
  );
};

const FailedStatusButton: React.FC<StatusButtonProps> = ({
  element,
  handleAction,
  width,
  height,
  paletteStatusWidth,
  handleStatusTextSync,
}) => {
  const theme = useTheme();
  const [showRetry, setShowRetry] = useState(false);

  const handleClick = (e: ThreeEvent<MouseEvent>) => {
    e.stopPropagation();
    handleAction({ type: 'trainPalette', id: element.id });
  };

  return (
    <StatusButtonWrapper
      width={width}
      height={height}
      paletteStatusWidth={paletteStatusWidth}
      onClick={handleClick}
      onPointerOver={() => setShowRetry(true)}
      onPointerLeave={() => setShowRetry(false)}
    >
      {showRetry && (
        <CustomHtml transform position={[paletteStatusWidth - 17, 4, 0]}>
          <RichTooltip isOpen={true} placement="top">
            <RichTooltipTrigger>
              <EmptyTrigger>.</EmptyTrigger>
            </RichTooltipTrigger>
            <RichTooltipContent>
              <Text type="b2">Retry Training</Text>
            </RichTooltipContent>
          </RichTooltip>
        </CustomHtml>
      )}
      <CustomText
        color="#FFF"
        outlineColor="#000"
        fontSize={12}
        font="Inter"
        fontWeight="semiBold"
        anchorX="left"
        whiteSpace="nowrap"
        content="Failed"
        backgroundColor={theme.surface.danger}
        radius="s"
        paddingX={10}
        paddingY={5}
        startAdornment={{
          svg: (
            <StaticSvg svg={ErrorIconRaw} fillMaterial={{ color: 0xffffff }} />
          ),
          width: 18,
        }}
        endAdornment={{
          svg: (
            <StaticSvg svg={RetryIconRaw} fillMaterial={{ color: 0xffffff }} />
          ),
          width: 18,
        }}
        onSync={(t) => handleStatusTextSync(t, 40)}
      />
    </StatusButtonWrapper>
  );
};

const StatusButtonWrapper: React.FC<
  GroupProps & {
    width: number;
    height: number;
    paletteStatusWidth: number;
    children: React.ReactNode;
  }
> = ({ width, height, paletteStatusWidth, children, ...props }) => (
  <group
    userData={props.onClick ? { cursor: 'pointer' } : {}}
    position={[width / 2 - paletteStatusWidth, height / 2 + 14, 0]}
    {...props}
  >
    {children}
  </group>
);

export const PaletteStatusButton: React.FC<StatusButtonProps> = (props) => {
  const { data: selectedOrganization } = useSelectedOrganization();
  const isEnterpriseUser =
    selectedOrganization?.subscriptionPlan ===
    OrganizationSubscriptionPlan.Enterprise;

  const { data: palettes } = useUserPalette(selectedOrganization?.id);

  if (!props.element.sourceImages?.nodes?.length) {
    return null;
  }

  // check if user palette is published
  const userPalette = palettes?.find(
    (palette) => palette.sourcePaletteId === props.element.id
  );

  if (userPalette) {
    return <PublishedStatusButton {...props} publishedPalette={userPalette} />;
  }

  switch (props.element.status) {
    case 'idle':
      return <IdleStatusButton {...props} />;
    case 'training':
      return <TrainingStatusButton {...props} />;
    case 'ready':
      return isEnterpriseUser ? (
        <PublishStatusButton {...props} />
      ) : (
        <CompletedStatusButton {...props} />
      );
    case 'failed':
      return <FailedStatusButton {...props} />;
    default:
      return null;
  }
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Title = styled.div`
  color: ${(p) => p.theme.text.body};
  font-weight: 600;
`;

const Content = styled.div`
  color: ${(p) => p.theme.text.subtext};
`;

const Buttons = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 16px;
`;

const EmptyTrigger = styled.div`
  opacity: 0;
`;

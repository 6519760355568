import { lowerCase } from 'lodash';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { CombinedError } from 'urql';
import {
  OrganizationSubscriptionData,
  TeamRole,
  useCreateOrganizationInvite,
  useOrganizationSeatsUsageQuery,
} from '@vizcom/shared/data-access/graphql';
import {
  ModalHeader,
  ModalTitle,
  ModalCloseButton,
  ModalContent,
  EmailMultiInput,
  Banner,
  WarningIcon,
  ModalActions,
  Button,
  Modal,
  addToast,
  formatErrorMessage,
  getCombinedErrorCode,
  useCrisp,
  Text,
  Select,
  CheckIcon,
  SelectedIndicator,
  InlineFlex,
  CarretDownIcon,
  shouldShowSeatsSettings,
  OptionComponentBase,
  OptionButtonContainer,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

import { UpgradeEnterpriseBanner } from '../OrganizationSubscription/Banners/UpgradeEnterpriseBanner';
import {
  shouldDisplayLimitationErrorToast,
  showLimitationErrorToast,
} from './limitationToastMessage';

const roleUseSeat = (role: TeamRole) =>
  role === TeamRole.Editor || role === TeamRole.Admin;

export const InviteMembersModal = (props: {
  organization: OrganizationSubscriptionData;
  openInviteMembersModal: boolean;
  inviteRedirectKey: string;
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const crisp = useCrisp();

  const { organization, inviteRedirectKey } = props;

  const { manualSubscription, paidSeatsCount } = organization;

  const showSeatsSettings = shouldShowSeatsSettings(organization);
  const [createInviteRes, createInvite] = useCreateOrganizationInvite();
  const { data: seatsUsageData, fetching: fetchingSeatsUsage } =
    useOrganizationSeatsUsageQuery(organization.id);

  const [inviteEmailsValue, setInviteEmailsValue] = useState([] as string[]);
  const [inviteRole, setInviteRole] = useState<TeamRole>(TeamRole.Viewer);

  if (fetchingSeatsUsage || !seatsUsageData?.seatsUsage) {
    return null;
  }

  const unlimitedSeats = manualSubscription && paidSeatsCount === 0;
  const { editorsSeatsCountLeft, planLimitationsReached } =
    seatsUsageData.seatsUsage;
  const seatsLimitationWarning =
    !unlimitedSeats &&
    editorsSeatsCountLeft <= 0 &&
    (inviteRole === TeamRole.Editor || inviteRole === TeamRole.Admin);

  const handleConfirm = async () => {
    const erroredEmails = [];
    const successfulEmails = [];

    let limitationReachedError: CombinedError | null = null;

    for (const email of inviteEmailsValue) {
      // Could be improved to only use one mutation for everything instead of a loop here
      const res = await createInvite({
        input: {
          organizationInvite: {
            email,
            organizationId: organization.id,
            role: inviteRole,
          },
        },
      });

      if (res.error) {
        const errorCode = getCombinedErrorCode(res.error);

        if (errorCode === 'NUNIQ') {
          addToast(`${email} already has been invited to this workspace`, {
            type: 'danger',
          });
        } else if (shouldDisplayLimitationErrorToast(res.error)) {
          limitationReachedError = res.error;
        } else {
          addToast(`Error while inviting ${email}`, {
            secondaryText: formatErrorMessage(res.error),
            type: 'danger',
          });
        }

        erroredEmails.push(email);
      } else {
        successfulEmails.push(email);
      }
    }

    setInviteEmailsValue(erroredEmails);

    if (erroredEmails.length === 0) {
      navigate(paths.settings.organization.members(organization.id), {
        replace: true,
      });

      if (successfulEmails.length > 0) {
        // if tried to send an invite to an existing member, we shouldn't show anything here
        addToast('Invites sent');
      }
    }

    if (limitationReachedError) {
      showLimitationErrorToast(
        limitationReachedError,
        organization.id,
        navigate,
        crisp.openChat,
        new URLSearchParams({ [inviteRedirectKey]: 'true' })
      );
    }
  };

  return (
    <Modal
      isOpen={props.openInviteMembersModal}
      setIsOpen={(open) => {
        if (!open) {
          navigate(paths.settings.organization.members(organization.id), {
            replace: true,
          });
        }
      }}
    >
      <ModalHeader>
        <ModalTitle>Invite members</ModalTitle>
        <ModalCloseButton />
      </ModalHeader>
      <ModalContent>
        <EmailMultiInput
          emails={inviteEmailsValue}
          setEmails={setInviteEmailsValue}
          style={{
            width: ' 400px',
            maxHeight: '400px',
          }}
        />

        <Select
          onSelectElement={(value) => setInviteRole(value as TeamRole)}
          selectedOptionIndex={2}
          customSelectedTriggerContent={({ selectedIndex, isOpen }) => (
            <OptionButtonContainer $active={false} style={{ width: '100%' }}>
              <Text type="b1">
                Invite members as{' '}
                <Text color="body">
                  {lowerCase(Object.keys(TeamRole)[selectedIndex!])}
                </Text>
              </Text>
              <CarretDownIcon
                style={{
                  transform: isOpen ? 'rotate(180deg)' : 'none',
                  transition: 'transform 0.2s',
                }}
              />
            </OptionButtonContainer>
          )}
          style={{ marginTop: 16 }}
        >
          {Object.entries(TeamRole).map(([label, value]) => (
            <InviteOptionComponent
              key={label}
              label={label}
              value={value}
              editorsSeatsCountLeft={editorsSeatsCountLeft}
              unlimitedSeats={unlimitedSeats}
            />
          ))}
        </Select>
      </ModalContent>

      {seatsLimitationWarning && showSeatsSettings && (
        <Banner
          type="default"
          size="S"
          icon={<WarningIcon color={theme.text.error} />}
          message="Not enough seats available"
          cta={{
            text: planLimitationsReached ? 'Change plan' : 'Purchase seats',
            action: () =>
              planLimitationsReached
                ? navigate(
                    paths.settings.organization.subscription(organization.id)
                  )
                : navigate(
                    paths.settings.organization.membersSubscriptionUpdate(
                      organization.id
                    )
                  ),
          }}
        />
      )}

      {seatsLimitationWarning && !showSeatsSettings && (
        <UpgradeEnterpriseBanner />
      )}

      <ModalActions>
        <Button
          variant="secondary"
          as={Link}
          to={paths.settings.organization.members(organization.id)}
          replace={true}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleConfirm}
          disabled={createInviteRes.fetching || seatsLimitationWarning}
        >
          Invite
        </Button>
      </ModalActions>
    </Modal>
  );
};

const InviteOptionComponent = ({
  value,
  label,
  editorsSeatsCountLeft,
  unlimitedSeats,
}: {
  value: TeamRole;
  label: string;
  editorsSeatsCountLeft: number;
  unlimitedSeats: boolean;
}) => {
  const textColor = editorsSeatsCountLeft === 0 ? 'warning' : 'primary';
  const displaySeatsLeft =
    roleUseSeat(value) &&
    !unlimitedSeats &&
    Number.isInteger(editorsSeatsCountLeft);

  return (
    <OptionComponentBase value={value} label={label}>
      {({ isSelected }) => (
        <InlineFlex $justifyContent="space-between">
          <InlineFlex style={{ width: 'fit-content' }}>
            <SelectedIndicator $active={isSelected}>
              <CheckIcon $color="action" />
            </SelectedIndicator>

            <Text color="subtext">
              Invite members as <Text color="body">{lowerCase(label)}s</Text>
            </Text>
          </InlineFlex>

          {displaySeatsLeft && (
            <Text
              type="b1"
              color={textColor === 'primary' ? 'primaryDisabled' : textColor}
            >
              {editorsSeatsCountLeft} seat
              {+editorsSeatsCountLeft > 1 ? 's' : ''} left
            </Text>
          )}
        </InlineFlex>
      )}
    </OptionComponentBase>
  );
};

import styled from 'styled-components';
import { ToolbarMenuButton } from '@vizcom/shared-ui-components';

import { ColorLibraryPicker } from '../../AssetLibrary/ColorPicker';
interface BrushColorButtonProps {
  color: string;
  setColor: (color: string) => void;
  onOpenCanvasColorPicker: () => void;
  workbenchId: string;
}

export const BrushColorButton = (props: BrushColorButtonProps) => {
  return (
    <ToolbarMenuButton
      icon={
        <ColorIndicatorWrapper>
          <ColorIndicator style={{ backgroundColor: props.color }} />
        </ColorIndicatorWrapper>
      }
      tooltip="Brush color"
      menu={
        <div style={{ padding: 8, width: 255 }}>
          <ColorLibraryPicker
            color={props.color}
            onChange={props.setColor}
            onEyeDropperClicked={props.onOpenCanvasColorPicker}
            workbenchId={props.workbenchId}
          />
        </div>
      }
      buttonProps={{
        style: { padding: '6px 6px' },
      }}
    />
  );
};

const ColorIndicatorWrapper = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: conic-gradient(
    from 0deg,
    red,
    orange,
    yellow,
    green,
    blue,
    indigo,
    violet,
    red
  );
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ColorIndicator = styled.div`
  width: 14px;
  height: 14px;
  border: 1px solid transparent;
  border-radius: 50%;
  box-shadow: 0 0 0 1px ${(p) => p.theme.icon.primary};
`;

import { format } from 'date-fns';
import { DowngradeFeedbackInput } from 'libs/shared/data-access/graphql/src/gql/graphql';
import { BillingInterval } from 'libs/shared/plans-limit/src/lib/plans-types';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import {
  OrganizationSeatsUsageData,
  OrganizationSubscriptionData,
  OrganizationSubscriptionPlan,
  usePreviewSeatsChange,
  useSubscribeToPlan,
} from '@vizcom/shared/data-access/graphql';
import { stripeTimestampToDate } from '@vizcom/shared/js-utils';
import {
  billingIntervalWording,
  getMaxAllowedEditorsCount,
  getMonthlyPricePerEditor,
  isBillingIntervalValid,
  isPlanValid,
  PLANS_LIMITS,
} from '@vizcom/shared/plans-limit';
import {
  Text,
  Button,
  Chip,
  ModalHeader,
  ModalCloseButton,
  InlineFlex,
  ModalContent,
  ModalActions,
  NumberButtonInput,
  BlockStack,
  ArrowRightIcon,
  formatErrorMessage,
  addToast,
  FormattedDate,
  Spinner,
  ToastIndicator,
  triggerConfirmModal,
  ConfirmationModalComponent,
  MultiStepsModal,
  TextArea,
  Checkbox,
  useCrisp,
  shouldShowSeatsSettings,
} from '@vizcom/shared-ui-components';
import { useDebouncedValue } from '@vizcom/shared-utils-hooks';

import { UpgradeEnterpriseBanner } from './Banners/UpgradeEnterpriseBanner';

export const SubscriptionUpdateModal = ({
  isModalOpen,
  setIsModalOpen,
  organizationSubscription,
  seatsUsage,
  onSubscriptionUpdate,
}: {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  organizationSubscription: OrganizationSubscriptionData;
  seatsUsage: OrganizationSeatsUsageData;
  onSubscriptionUpdate?: () => void;
}) => {
  const crisp = useCrisp();
  const location = useLocation();

  const [subscribeToPlanRes, subscribeToPlan] = useSubscribeToPlan();
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const { subscription, subscriptionPlan, paidSeatsCount } =
    organizationSubscription;
  const { currentPeriodEnd } = subscription;

  const searchParams = new URLSearchParams(location.search);
  const searchPlan = searchParams.get('plan');
  const searchBillingInterval = searchParams.get('interval');

  const targetPlan = isPlanValid(searchPlan) ? searchPlan : subscriptionPlan;
  const targetInterval = isBillingIntervalValid(searchBillingInterval)
    ? searchBillingInterval
    : subscription.billingInterval || BillingInterval.Year;

  const downgradeToFree = targetPlan === OrganizationSubscriptionPlan.Free;
  const changingPlan = targetPlan && targetPlan !== subscriptionPlan;
  const changingInterval =
    targetInterval && targetInterval !== subscription.billingInterval;
  const goingMonthly =
    changingInterval && targetInterval === BillingInterval.Month;
  const goingYearly =
    changingInterval && targetInterval === BillingInterval.Year;
  const intervalWillChange =
    subscription.nextPhaseSchedule?.interval &&
    subscription.nextPhaseSchedule?.interval !== targetInterval;

  const [debouncedSelectedSeats, , setSelectedSeats] = useDebouncedValue(
    downgradeToFree ? 2 : paidSeatsCount || 1,
    100
  );

  const { data: previewSeatsChange, fetching: previewDueTodayFetching } =
    usePreviewSeatsChange({
      organizationId: organizationSubscription.id,
      paidSeatsCount: debouncedSelectedSeats,
      plan: targetPlan,
      interval: targetInterval,
    });

  const removingSeats = debouncedSelectedSeats < paidSeatsCount;
  const changingSeats = debouncedSelectedSeats !== paidSeatsCount;
  const currentPeriodEndDate = currentPeriodEnd
    ? stripeTimestampToDate(currentPeriodEnd)
    : null;

  const previewDueToday =
    !previewDueTodayFetching && previewSeatsChange
      ? (previewSeatsChange.amountRemainingInCents / 100).toFixed(2)
      : null;
  const couponAmount = Math.abs(
    previewSeatsChange?.startingBalanceInCents || 0
  );
  const showSeatsSettings = shouldShowSeatsSettings(organizationSubscription);

  const noDueToday =
    (goingMonthly && subscriptionPlan !== OrganizationSubscriptionPlan.Free) ||
    downgradeToFree ||
    (removingSeats && !goingYearly);

  const handleCheckout = async (downgradeFeedback?: DowngradeFeedbackInput) => {
    // Confirmation modal for canceling the schedule to change the monthly subscription
    if (
      !changingInterval &&
      intervalWillChange &&
      searchBillingInterval === BillingInterval.Year
    ) {
      const confirmed = await triggerConfirmSubscriptionUpdateModal(
        stripeTimestampToDate(subscription.currentPeriodEnd!)
      );
      if (!confirmed) return;
    }

    // Display the feedback modal if the user is downgrading to Free
    if (downgradeToFree && currentStepIndex === 0) {
      return setCurrentStepIndex(1);
    }

    const res = await subscribeToPlan({
      input: {
        organizationId: organizationSubscription.id,
        paidSeatsCount: debouncedSelectedSeats,
        plan: targetPlan,
        interval: targetInterval,
        downgradeFeedback,
      },
    });

    if (res.error || !res.data) {
      return addToast('Error while changing seats count', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }

    if (res.data.subscribeToPlan?.url) {
      window.location.assign(res.data.subscribeToPlan.url);
      return;
    }

    addToast('Seats count changed successfully', {
      id: 'update-plan',
    });

    setIsModalOpen(false);

    onSubscriptionUpdate?.();
  };

  return (
    <MultiStepsModal
      isOpen={isModalOpen}
      setIsOpen={setIsModalOpen}
      currentStepIndex={currentStepIndex}
      setCurrentStepIndex={setCurrentStepIndex}
    >
      {/* STEP 01 */}
      <ModalContainer>
        <ModalHeader>
          <InlineFlex $gap={10}>
            <Text type="sh2">Update Subscription</Text>
            <InlineFlex $gap={1} style={{ width: 'fit-content' }}>
              <CurrentPlanChip orgSubscription={organizationSubscription} />
              {(changingPlan || changingInterval) && (
                <>
                  <ArrowRightIcon width={15} fill="#FFF" />
                  <NextPlanChip plan={targetPlan} interval={targetInterval} />
                </>
              )}
            </InlineFlex>
          </InlineFlex>
          <ModalCloseButton />
        </ModalHeader>
        <ModalContent style={{ padding: 0, maxWidth: 500 }}>
          <BlockStack $gap={10}>
            {downgradeToFree && seatsUsage.takenEditorsSeatsCount > 2 && (
              <Text color="subtext" type="b1" style={{ marginBottom: 10 }}>
                The free plan comes with{' '}
                {PLANS_LIMITS.FREE.includedEditorsCount} paid editors and you
                currently have {seatsUsage.takenEditorsSeatsCount}. If you
                choose to maintain all {seatsUsage.takenEditorsSeatsCount} users
                then{' '}
                {seatsUsage.takenEditorsSeatsCount -
                  PLANS_LIMITS.FREE.includedEditorsCount}{' '}
                editor(s) will become viewers.
              </Text>
            )}

            {!downgradeToFree && showSeatsSettings && (
              <NumberSelector
                label="Total organization editors"
                value={debouncedSelectedSeats}
                setValue={setSelectedSeats}
                min={1}
                max={getMaxAllowedEditorsCount(
                  organizationSubscription.paidSeatsCount,
                  organizationSubscription.subscriptionPlan
                )}
              />
            )}

            <InvoicePreview
              organizationSubscription={organizationSubscription}
              selectedSeatsCount={debouncedSelectedSeats}
              seatsUsage={seatsUsage}
              targetPlan={targetPlan}
              billingInterval={targetInterval}
            />

            {(!showSeatsSettings ||
              debouncedSelectedSeats ===
                getMaxAllowedEditorsCount(
                  organizationSubscription.paidSeatsCount,
                  organizationSubscription.subscriptionPlan
                )) && <UpgradeEnterpriseBanner />}

            {noDueToday && (
              <BlockStack $gap={4} style={{ paddingInline: 10 }}>
                <InlineFlex $justifyContent="space-between">
                  <Text type="sh2">Due today</Text>
                  <Text color="subtext">$0</Text>
                </InlineFlex>
                <BlockStack $gap={8}>
                  <Text
                    color="subtext"
                    style={{ textWrap: 'pretty', maxWidth: 300 }}
                  >
                    {targetPlan} plan
                    {targetInterval === BillingInterval.Month &&
                    targetPlan !== OrganizationSubscriptionPlan.Free
                      ? ' billed monthly'
                      : ''}{' '}
                    including {debouncedSelectedSeats} seat
                    {debouncedSelectedSeats > 1 ? 's' : ''} starting{' '}
                    {currentPeriodEndDate && (
                      <FormattedDate
                        date={currentPeriodEndDate.toDateString()}
                        format="MMMM d, yyyy"
                      />
                    )}
                  </Text>
                </BlockStack>
              </BlockStack>
            )}
            {!noDueToday && (
              <BlockStack $gap={2} style={{ paddingInline: 10 }}>
                <InlineFlex
                  $justifyContent="space-between"
                  style={{ overflow: 'hidden' }}
                >
                  <Text type="sh2">Due today</Text>
                  <div>
                    {previewDueTodayFetching && (
                      <Spinner size={{ height: 10, width: 10 }} />
                    )}
                    {previewDueToday && (
                      <Text color="subtext">${previewDueToday}</Text>
                    )}
                  </div>
                </InlineFlex>

                {couponAmount > 0 && (
                  <Text block color="subtext">
                    Using your credit of ${(couponAmount / 100).toFixed(2)}
                  </Text>
                )}
              </BlockStack>
            )}
          </BlockStack>
        </ModalContent>
        <ModalActions>
          <Button
            variant="secondary"
            size="S"
            onClick={() => setIsModalOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            size="S"
            onClick={() => handleCheckout()}
            disabled={
              subscribeToPlanRes.fetching ||
              previewDueTodayFetching ||
              (!changingSeats &&
                !subscription.nextPhaseSchedule &&
                !changingPlan &&
                !changingInterval)
            }
          >
            {removingSeats ? 'Confirm' : 'Checkout'}
          </Button>
        </ModalActions>
        {subscribeToPlanRes.fetching && (
          <ToastIndicator variant="loading" text="We are updating your plan" />
        )}
      </ModalContainer>

      {/* STEP 02: Asking why downgrading */}
      <FeedbackModal
        setIsModalOpen={setIsModalOpen}
        handleCheckout={handleCheckout}
      />
    </MultiStepsModal>
  );
};

const FeedbackModal = ({
  setIsModalOpen,
  handleCheckout,
}: {
  setIsModalOpen: (isOpen: boolean) => void;
  handleCheckout: (downgradeFeedback?: DowngradeFeedbackInput) => void;
}) => {
  const [notGettingValue, setNotGettingValue] = useState(false);
  const [anotherProduct, setAnotherProduct] = useState(false);
  const [dontNeedIt, setDontNeedIt] = useState(false);
  const [tooComplex, setTooComplex] = useState(false);
  const [other, setOther] = useState(false);
  const [otherReason, setOtherReason] = useState('');

  const reasons = [
    notGettingValue ? 'NOT_GETTING_VALUE' : undefined,
    anotherProduct ? 'USE_ANOTHER_PRODUCT' : undefined,
    dontNeedIt ? 'DONT_NEED_IT' : undefined,
    tooComplex ? 'TOO_COMPLEX' : undefined,
    other ? 'OTHER' : undefined,
  ].filter(Boolean) as DowngradeFeedbackInput['reasons'];

  const feedback: DowngradeFeedbackInput = {
    reasons,
    comment: otherReason,
  };

  return (
    <ModalContainer>
      <Text type="sh1">
        We would love to know why you chose to downgrade your plan?
      </Text>

      <BlockStack $gap={10} style={{ marginTop: 20 }}>
        {/* NOT_GETTING_VALUE  */}
        <InlineFlex $gap={10}>
          <Checkbox
            checked={notGettingValue}
            onClick={() => setNotGettingValue(!notGettingValue)}
          />
          <Text>I'm not getting much value</Text>
        </InlineFlex>

        {/* USE_ANOTHER_PRODUCT  */}
        <InlineFlex $gap={10}>
          <Checkbox
            checked={anotherProduct}
            onClick={() => setAnotherProduct(!anotherProduct)}
          />
          <Text>I'm using another product</Text>
        </InlineFlex>

        {/* DONT_NEED_IT */}
        <InlineFlex $gap={10}>
          <Checkbox
            checked={dontNeedIt}
            onClick={() => setDontNeedIt(!dontNeedIt)}
          />
          <Text>I don't need it anymore</Text>
        </InlineFlex>

        {/* TOO_COMPLEX */}
        <InlineFlex $gap={10}>
          <Checkbox
            checked={tooComplex}
            onClick={() => setTooComplex(!tooComplex)}
          />
          <Text>Product is too complex to use</Text>
        </InlineFlex>

        {/* Other */}
        <InlineFlex $gap={10}>
          <Checkbox checked={other} onClick={() => setOther(!other)} />
          <Text>Other</Text>
        </InlineFlex>

        {other && (
          <BlockStack $gap={10}>
            <TextArea
              $background="tertiary"
              placeholder="Please specify..."
              value={otherReason}
              onChange={(e) => setOtherReason(e.target.value)}
              style={{
                resize: 'vertical',
                minHeight: 100,
              }}
            />
          </BlockStack>
        )}
      </BlockStack>

      <ModalActions>
        <Button
          variant="secondary"
          size="S"
          onClick={() => setIsModalOpen(false)}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          size="S"
          onClick={() => handleCheckout(feedback)}
        >
          Confirm
        </Button>
      </ModalActions>
    </ModalContainer>
  );
};

export const ModalContainer = styled.div`
  padding: 20px;
  width: 500px;
`;

const NumberSelector = ({
  label,
  value,
  setValue,
  min,
  max,
}: {
  label: string;
  value: number;
  setValue: (value: number) => void;
  min?: number;
  max?: number;
}) => {
  const theme = useTheme();

  return (
    <InlineFlex
      $gap={10}
      $justifyContent="space-between"
      style={{
        backgroundColor: theme.surface.tertiary,
        padding: '8px 10px',
        borderRadius: theme.borderRadius.m,
      }}
    >
      <Text color="subtext">{label}</Text>
      <NumberButtonInput
        value={value}
        setValue={setValue}
        min={min}
        max={max}
      />
    </InlineFlex>
  );
};

const InvoicePreview = ({
  organizationSubscription,
  selectedSeatsCount,
  seatsUsage,
  targetPlan,
  billingInterval,
}: {
  organizationSubscription: OrganizationSubscriptionData;
  selectedSeatsCount: number;
  seatsUsage: OrganizationSeatsUsageData;
  targetPlan: OrganizationSubscriptionPlan;
  billingInterval?: BillingInterval;
}) => {
  const { subscription } = organizationSubscription;
  const showSeatsSettings = shouldShowSeatsSettings(organizationSubscription);

  return (
    <InvoicePreviewContainer>
      <InlineFlex $gap={15}>
        <InvoicePreviewPricingDetails
          title="Current billing"
          seats={seatsUsage.editorsSeatsCount}
          plan={organizationSubscription.subscriptionPlan}
          interval={subscription.billingInterval || undefined}
          showSeatsSettings={showSeatsSettings}
        />

        <ArrowRightIcon width={20} height={20} />

        <InvoicePreviewPricingDetails
          title="Next billing"
          seats={selectedSeatsCount}
          plan={targetPlan}
          interval={billingInterval}
          showSeatsSettings={showSeatsSettings}
        />
      </InlineFlex>
    </InvoicePreviewContainer>
  );
};

const InvoicePreviewPricingDetails = ({
  title,
  seats,
  interval,
  plan,
  showSeatsSettings,
}: {
  title: string;
  seats: number;
  interval?: BillingInterval;
  plan: OrganizationSubscriptionPlan;
  showSeatsSettings: boolean;
}) => {
  const monthlyPricePerEditor = getMonthlyPricePerEditor(plan, interval);
  const monthlyPrice = monthlyPricePerEditor * seats;
  const recurrentPricing =
    monthlyPrice * (interval === BillingInterval.Year ? 12 : 1);

  return (
    <BlockStack $gap={3} style={{ flex: 1 }}>
      <InlineFlex $justifyContent="space-between">
        <Text>{title}</Text>
        <Text color="body" type="sh2">
          ${recurrentPricing}
        </Text>
      </InlineFlex>
      <Text color="subtext">
        {seats} x editor{seats > 1 ? 's' : ''}
      </Text>

      <Text color="subtext">
        ${monthlyPricePerEditor}{' '}
        {plan !== OrganizationSubscriptionPlan.Free
          ? `/month${showSeatsSettings ? '/seat' : ''}`
          : ''}
        {interval === BillingInterval.Year &&
          plan !== OrganizationSubscriptionPlan.Free &&
          ', billed annually'}
      </Text>
    </BlockStack>
  );
};

const CurrentPlanChip = ({
  orgSubscription,
}: {
  orgSubscription: OrganizationSubscriptionData;
}) => {
  const { subscription, subscriptionPlan } = orgSubscription;

  return (
    <Chip
      variant="default"
      style={{ textTransform: 'uppercase', fontSize: 10 }}
    >
      {subscriptionPlan}
      {subscription.billingInterval
        ? ` ${billingIntervalWording[subscription.billingInterval]}`
        : ''}
    </Chip>
  );
};

const NextPlanChip = ({
  plan,
  interval,
}: {
  plan?: OrganizationSubscriptionPlan;
  interval?: BillingInterval;
}) => {
  return (
    <Chip
      variant="default"
      style={{ textTransform: 'uppercase', fontSize: 10 }}
    >
      {plan}
      {interval && plan !== OrganizationSubscriptionPlan.Free
        ? ` ${billingIntervalWording[interval]}`
        : ''}
    </Chip>
  );
};

const triggerConfirmSubscriptionUpdateModal = async (
  date: Date
): Promise<boolean> => {
  try {
    const description = `Your workspace will stay on the current Professional Annually plan, which renews on ${format(
      date,
      'PPP'
    )}.`;

    await triggerConfirmModal({
      body: (onConfirm, onCancel) => (
        <ConfirmationModalComponent
          onConfirm={onConfirm}
          onCancel={onCancel}
          title="Change the billing period?"
          description={description}
        />
      ),
    });

    return true;
  } catch (error) {
    return false;
  }
};

const InvoicePreviewContainer = styled.div`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.deprecated.secondary.disabled};
  padding: 10px;

  border-radius: ${({ theme }) => theme.borderRadius.m};
`;

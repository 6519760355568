import { useStore, useThree } from '@react-three/fiber';
import { useEffect } from 'react';
import { useStableCallback } from '@vizcom/shared-ui-components';

import { MultitouchEventManager } from './multitouchThreejsEvents';
import { CameraLimits, useMapControls } from './utils';

export const useWorkbenchTouchGestures = ({
  getCameraLimits,
  enableTwoFingerRotation,
}: {
  getCameraLimits: () => CameraLimits;
  enableTwoFingerRotation?: boolean;
}) => {
  const store = useStore();
  const events = useThree((s) => s.events) as MultitouchEventManager;
  const lastGetCameraLimits = useStableCallback(getCameraLimits);
  const controls = useMapControls();

  useEffect(() => {
    return events.onMultiTouchMove(
      ({
        currentPointersScreenPositions: [screenA, screenB],
        firstPointersWorldPositions: [worldA, worldB],
        cameraAtStartOfMove,
      }) => {
        /*
        we want to find the camera position so that worldA is at screenA and worldB is at screenB
        We must find camera so that:
        ```
        worldA = screenToWorld(screenA, camera)
        worldB = screenToWorld(screenB, camera)
        ```

       The rest of the code finds zzoom, rotation and position by inverting this version of `screenPositionToWorld`:
        ```
        const screenPositionToWorld = (
          [x, y]: [number, number],
          camera: OrthographicCamera
        ) => {
          const v = [
              ((1 - (x / window.innerWidth) * 2) * camera.left) / camera.zoom,
              ((1 - (y / window.innerHeight) * 2) * camera.top) / camera.zoom,
            ];
            const ca = Math.cos(camera.rotation.z);
            const sa = Math.sin(camera.rotation.z);
            return [
              v[0] * ca - v[1] * sa + camera.position.x,
              v[0] * sa + v[1] * ca + camera.position.y,
            ];
          }
          ```
        */
        const normalize = (x: number, y: number) => [
          (1 - (2 * x) / window.innerWidth) * cameraAtStartOfMove.left,
          (1 - (2 * y) / window.innerHeight) * cameraAtStartOfMove.top,
        ];

        const na = normalize(screenA[0], screenA[1]);
        const nb = normalize(screenB[0], screenB[1]);

        const deltaWorld = [worldA[0] - worldB[0], worldA[1] - worldB[1]];
        const deltaNormalized = [na[0] - nb[0], na[1] - nb[1]];

        //prevent further divisions by zero
        if (deltaNormalized[0] === 0 && deltaNormalized[1] === 0) {
          return;
        }

        const d =
          deltaNormalized[0] * deltaNormalized[0] +
          deltaNormalized[1] * deltaNormalized[1];

        // `ca` and `sa` are `cos(rotation)/zoom` and `sin(rotation)/zoom`
        const ca =
          (deltaNormalized[0] * deltaWorld[0] +
            deltaNormalized[1] * deltaWorld[1]) /
          d;
        const sa =
          (deltaNormalized[0] * deltaWorld[1] -
            deltaNormalized[1] * deltaWorld[0]) /
          d;

        controls.moveTo({
          x: worldA[0] - (na[0] * ca - na[1] * sa),
          y: worldA[1] - (na[0] * sa + na[1] * ca),
          rotation: enableTwoFingerRotation ? Math.atan2(sa, ca) : 0,
          zoom: 1 / Math.hypot(ca, sa),
          limits: lastGetCameraLimits(),
          skipAnimation: true,
        });
      }
    );
  }, [store, events, lastGetCameraLimits, enableTwoFingerRotation, controls]);
};

import { MathUtils } from 'three';
import { lerp, smoothstep } from 'three/src/math/MathUtils';

export const stabilizePoint = (
  point: {
    stabilizedScreenPosition: [number, number];
    stabilizedPressure: number;
  },
  previous: {
    stabilizedScreenPosition: [number, number];
    stabilizedPressure: number;
  }[],
  maxPoints: number
) => {
  if (maxPoints === 0) {
    return;
  }

  const { length } = previous;
  // Iterate through previous points going backwards
  for (let i = 1; i <= Math.min(length, maxPoints); i++) {
    const other = previous[length - i];
    // Lerp the point position into the previous point.
    // Descreasing the interpolation factor on every iteration.
    // (Each previous point influence descreases as it gets further down the chain)
    const t = 1 - i / (maxPoints + 1);
    point.stabilizedScreenPosition[0] = MathUtils.lerp(
      point.stabilizedScreenPosition[0],
      other.stabilizedScreenPosition[0],
      t
    );
    point.stabilizedScreenPosition[1] = MathUtils.lerp(
      point.stabilizedScreenPosition[1],
      other.stabilizedScreenPosition[1],
      t
    );
  }
};

/*
On iPad the `pressure` for the first points is incorrect.
This can affect a random number of points, generally between 1 and 5
The first points have a fixed value, then the value moves toward a correct one.
`correctValuesCount` is the number of points at the end of the list that we expect to have correct pressure
*/
export const stabilizeInitialPressure = (
  previousPoints: { pressure: number; stabilizedPressure: number }[],
  correctValuesCount: number = 4
) => {
  if (previousPoints.length < 2 * correctValuesCount) {
    throw new Error(
      `not enough points to perform stabilization. Expected:${
        2 * correctValuesCount
      }; Received:${previousPoints.length}`
    );
  }
  const mean =
    previousPoints
      .slice(-correctValuesCount)
      .reduce((t, p) => t + p.pressure, 0) / correctValuesCount;
  previousPoints.forEach((p, i) => {
    //Lerp points toward the mean, decreasing influence as the list advances.
    p.stabilizedPressure = lerp(
      mean,
      p.pressure,
      smoothstep(i / (previousPoints.length - 1), 0, 1)
    );
  });
};

import { createPortal } from '@react-three/fiber';
import {
  useLayoutEffect,
  useRef,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { DoubleSide, Group, Texture, Vector2, Matrix3 } from 'three';
import { v4 as uuidv4 } from 'uuid';
import { useLastValue, useStableCallback } from '@vizcom/shared-ui-components';

import { SyncedActionPayloadFromType } from '../../../../lib/SyncedAction';
import { MutateLocalStateAction } from '../../../../lib/actions/drawing/mutateLocalStateAction';
import {
  DrawingLayer,
  useDrawingSyncedState,
} from '../../../../lib/useDrawingSyncedState';
import { TransformEnveloppeHelper } from '../../../utils/TransformEnveloppeHelper';
import { VizcomRenderingOrderEntry } from '../../../utils/threeRenderingOrder';
import { LayerContent } from '../../LayersCompositor/LayerContent';
import { useBeforeActiveLayerChange } from '../../lib/useActiveLayer';
import { ActiveMask, MaskDisplayMode } from '../../selection/ActiveMask';
import {
  useOnBeforeSelectionChange,
  useSelectionApiStore,
  useSubscribeToSelectionApi,
} from '../../selection/useSelectionApi';
import {
  LayerTextureRenderer,
  LayerTextureRendererRef,
} from '../LayerTextureRenderer';
import { initializeTransformTextures, useSelectionTransform } from './helpers';
import {
  LayerTranslation,
  useLayerTranslationTransform,
} from './useLayerTranslationTransform';
import {
  initialWarpPointDirections,
  useLayerWarpTransform,
} from './useLayerWarpTransform';
import { WarpGeometry, WarpPoint } from './warp';

interface LayerTransformProps {
  drawingSize: [number, number];
  layer: DrawingLayer;
  layerImage: Texture | undefined;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  filterHistory: ReturnType<typeof useDrawingSyncedState>['filterHistory'];
  zIndex: number;
}

export const LayerWarpControls = ({
  drawingSize,
  layer,
  layerImage,
  handleAction,
  filterHistory,
  zIndex,
}: LayerTransformProps) => {
  const layerTextureRendererRef = useRef<LayerTextureRendererRef>(null!);
  const resizerGroupRef = useRef<Group>(null!);
  const lastLayerImageRef = useRef(layerImage?.image);

  const selectionApiStore = useSelectionApiStore();
  const selectionTexture_treatEmptyMaskAsFull = useSubscribeToSelectionApi(
    (state) => state.texture_treatEmptyMaskAsFull
  );
  const selectionTextureVersion = useSubscribeToSelectionApi(
    (state) => state.version
  );

  const { scene, selectionFBO, selectionToImageData } =
    useSelectionTransform(drawingSize);

  const croppedTexture = useMemo(
    () =>
      initializeTransformTextures(
        layerImage,
        selectionApiStore.getState().getSelectionImage(),
        selectionApiStore.getState().texture,
        drawingSize
      ),
    [layerImage, drawingSize, selectionTextureVersion, selectionApiStore]
  );

  const updateLayerImage = useCallback(
    (undoGroupId?: string) => {
      if (!layerTextureRendererRef.current) {
        return;
      }

      handleAction(
        {
          type: 'updateLayer',
          id: layer.id,
          data: {
            image: layerTextureRendererRef.current.exportTexture(),
          },
        },
        { undoGroupId }
      );
      resetBaseTransform();
    },
    [handleAction, layer.id]
  );

  useBeforeActiveLayerChange(() => {
    if (hasLayerChanged()) {
      updateLayerImage();
    }
  });

  //These are the transforms applied to the layer at rest.
  //These values are then composed with the transfom from the controls.
  const [baseTranslation, setBaseTranslation] = useState<[number, number]>([
    0, 0,
  ]);
  const [baseWarpPointsTransforms, setBaseWarpPointsTransforms] = useState<
    WarpPoint[]
  >(initialWarpPointDirections.map(() => [0, 0]));

  const hasLayerChanged = useStableCallback(() => {
    return (
      baseTranslation[0] !== 0 ||
      baseTranslation[1] !== 0 ||
      baseWarpPointsTransforms.some((p) => {
        return p[0] !== 0 || p[1] !== 0;
      })
    );
  });

  const resetBaseTransform = useStableCallback(() => {
    setBaseTranslation([0, 0]);
    setBaseWarpPointsTransforms(
      initialWarpPointDirections.map(() => [0, 0]) as WarpPoint[]
    );
  });

  // expose a stable ref to the last value of the transform state, used when generating the undo/redo actions
  const transformStateRef = useLastValue({
    baseTranslation,
    baseWarpPointsTransforms,
  });

  const getMutateLocalStateAction = useStableCallback(
    (editionFunction: () => void) => {
      const snapshot = {
        translation: [0, 0] as [number, number],
        warpPoints: [] as WarpPoint[],
      };

      const action = {
        type: 'mutateLocalState',
        actionId: `warp_${uuidv4()}`,
        onExecute: () => {
          snapshot.translation = transformStateRef.current.baseTranslation;
          snapshot.warpPoints =
            transformStateRef.current.baseWarpPointsTransforms;
          editionFunction();
        },
        undoConstructor: () =>
          getMutateLocalStateAction!(() => {
            setBaseTranslation(snapshot.translation);
            setBaseWarpPointsTransforms(snapshot.warpPoints);
          }),
      } as SyncedActionPayloadFromType<typeof MutateLocalStateAction>;
      return action;
    }
  );

  const cleanupLocalHistory = () => {
    filterHistory((action) =>
      Boolean(
        action.payload.type === 'mutateLocalState' &&
          action.payload.actionId?.startsWith('warp_')
      )
    );
  };

  const {
    bindTranslationHandle,
    resetTranslationTransform,
    translationTransform,
  } = useLayerTranslationTransform(
    () => {},
    useStableCallback((translation: [number, number]) => {
      handleAction(
        getMutateLocalStateAction!(() => {
          setBaseTranslation([
            baseTranslation[0] + translation[0],
            baseTranslation[1] + translation[1],
          ]);
          resetTranslationTransform();
        })
      );
    }),
    resizerGroupRef
  );

  const { bindWarpHandle, resetWarp, warpPointsTransforms } =
    useLayerWarpTransform(
      baseWarpPointsTransforms,
      useStableCallback((warpPointsTransforms: WarpPoint[]) => {
        handleAction(
          getMutateLocalStateAction!(() => {
            setBaseWarpPointsTransforms([...warpPointsTransforms]);
            resetWarp();
          })
        );
      }),
      resizerGroupRef
    );

  // using `useLayoutEffect` instead of `useEffect` to make sure we can export the texture before LayerTextureRenderer is unmounted
  // and we lose the reference to the updated texture
  useLayoutEffect(() => {
    return () => {
      if (hasLayerChanged()) {
        cleanupLocalHistory();
        const undoGroupId = uuidv4();
        updateLayerImage(undoGroupId);
        selectionApiStore.getState().editSelectionCanvas((ctx) => {
          ctx.putImageData(selectionToImageData(), 0, 0);
        }, undoGroupId);
      }
    };
  }, [layer.id]); // commit update when unmounting or when layer id changes

  useOnBeforeSelectionChange(() => {
    if (hasLayerChanged()) {
      cleanupLocalHistory();
      updateLayerImage();
    }
  });

  useEffect(() => {
    resetWarp();
    resetTranslationTransform();
  }, [selectionTextureVersion]);

  if (lastLayerImageRef.current !== layerImage?.image) {
    // when layer image changes, reset the transform
    // this let us keep the transforms while the new image is being passed through handleAction
    // and only reset the transform when the new image is ready
    // this synchronize both actions to prevent flickering
    lastLayerImageRef.current = layerImage?.image;
    resetWarp();
    resetTranslationTransform();
    resetBaseTransform();
  }

  if (!croppedTexture?.texture) {
    return null;
  }
  const textureMatrix = new Matrix3().setUvTransform(
    croppedTexture?.texture.offset?.x || 0,
    croppedTexture?.texture.offset.y || 0,
    croppedTexture?.texture.repeat.x || 1,
    croppedTexture?.texture.repeat.y || 1,
    0,
    0,
    0
  );

  const computedTransform = {
    translation: [
      croppedTexture.boundingBox.centerDeltaX +
        baseTranslation[0] +
        translationTransform[0],
      croppedTexture.boundingBox.centerDeltaY +
        baseTranslation[1] +
        translationTransform[1],
    ] as LayerTranslation,
    warpPoints: initialWarpPointDirections.map((p, i) => [
      (p[0] * (croppedTexture?.boundingBox.width ?? 0)) / 2 +
        baseWarpPointsTransforms[i][0] +
        warpPointsTransforms[i][0],
      (p[1] * (croppedTexture?.boundingBox.height ?? 0)) / 2 +
        baseWarpPointsTransforms[i][1] +
        warpPointsTransforms[i][1],
    ]) as WarpPoint[],
  };
  const warpPointsAsVectors = computedTransform.warpPoints.map(
    (p) => new Vector2(p[0], p[1])
  );

  return (
    <>
      <group
        ref={resizerGroupRef}
        position={[
          computedTransform.translation[0],
          computedTransform.translation[1],
          0,
        ]}
        userData={{
          vizcomRenderingOrder: [
            {
              zIndex: 3,
            } satisfies VizcomRenderingOrderEntry,
          ],
        }}
      >
        <TransformEnveloppeHelper
          points={computedTransform.warpPoints}
          warpHandleMeshPropsGetter={bindWarpHandle as any}
          moveHandleMeshProps={bindTranslationHandle() as any}
        />
      </group>
      <LayerContent
        id={layer.id}
        opacity={layer.opacity}
        blendMode={layer.blendMode}
        visible={layer.visible}
        zIndex={zIndex}
        type={'vizcom:toolLayerContent'}
      >
        <LayerTextureRenderer
          width={drawingSize[0]}
          height={drawingSize[1]}
          ref={layerTextureRendererRef}
        >
          {croppedTexture?.alphaMap && (
            <mesh
              userData={{
                vizcomRenderingOrder: [
                  {
                    zIndex: 2,
                  } satisfies VizcomRenderingOrderEntry,
                ],
              }}
            >
              <planeGeometry args={[drawingSize[0], drawingSize[1]]} />
              <maskedWarpMaterial
                u_points={warpPointsAsVectors}
                u_textureMatrix={textureMatrix}
                u_map={layerImage!}
                u_mask={selectionTexture_treatEmptyMaskAsFull}
                u_translation={computedTransform.translation}
                side={DoubleSide}
                transparent
                depthTest={false}
              />
            </mesh>
          )}
          {!croppedTexture?.alphaMap && (
            <group
              userData={{
                vizcomRenderingOrder: [
                  {
                    zIndex: 2,
                  } satisfies VizcomRenderingOrderEntry,
                ],
              }}
              position={[
                computedTransform.translation[0],
                computedTransform.translation[1],
                0,
              ]}
            >
              <mesh>
                <WarpGeometry points={computedTransform.warpPoints} />
                <warpMaterial
                  u_points={warpPointsAsVectors}
                  u_textureMatrix={textureMatrix}
                  u_map={croppedTexture?.texture}
                  side={DoubleSide}
                  transparent
                  depthTest={false}
                />
              </mesh>
            </group>
          )}
        </LayerTextureRenderer>
      </LayerContent>

      {croppedTexture.alphaMap && (
        <>
          {/*render transformed selection*/}
          {createPortal(
            <>
              <group
                position={[
                  computedTransform.translation[0],
                  computedTransform.translation[1],
                  0,
                ]}
              >
                <mesh
                  userData={{
                    vizcomRenderingOrder: [
                      {
                        zIndex: 2,
                      } satisfies VizcomRenderingOrderEntry,
                    ],
                  }}
                >
                  <WarpGeometry points={computedTransform.warpPoints} />
                  <warpMaterial
                    u_points={warpPointsAsVectors}
                    u_textureMatrix={textureMatrix}
                    u_map={
                      croppedTexture?.alphaMap ||
                      selectionTexture_treatEmptyMaskAsFull
                    }
                    side={DoubleSide}
                  />
                </mesh>
              </group>
            </>,
            scene
          )}
          {/*display transformed selection*/}
          <ActiveMask
            drawingSize={drawingSize}
            maskTexture={selectionFBO.texture}
            mode={MaskDisplayMode.MARCHING_ANTS}
          />
        </>
      )}
    </>
  );
};

import { RootState } from '@react-three/fiber';
import { MutableRefObject, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import {
  Button,
  DownloadIcon,
  ToolbarDivider,
  Tooltip,
  downloadFile,
} from '@vizcom/shared-ui-components';

import { GENERATED_IMAGES_MARGIN } from '../../../lib/utils';
import { CompositeSceneAIHistory } from '../compositeSceneAIHistory/CompositeSceneAIHistory';
import { ToolbarContainer, Toolbar, LoadingIndicator } from './style';

export const CompositeSceneAIToolbar = ({
  loading,
  renders,
  threeStateRef,
  setActiveRenders,
  onAddToWorkbench,
}: {
  loading: boolean;
  renders: (ArrayBuffer | null)[];
  threeStateRef: MutableRefObject<RootState | null>;
  setActiveRenders: (renders: (ArrayBuffer | null)[]) => void;
  onAddToWorkbench: (
    previews: { preview: ArrayBuffer | Blob; name?: string }[],
    offset?: {
      x: number;
      y: number;
    }
  ) => void;
}) => {
  const theme = useTheme();
  const [blockAddingToWorkbench, setBlockAddingToWorkbench] = useState(false);
  const validRenders = renders.filter(
    (render) => render !== null
  ) as ArrayBuffer[];

  useEffect(() => {
    setBlockAddingToWorkbench(false);
  }, [renders]);

  const onAddRendersToWorkbench = async () => {
    if (!validRenders.length || !threeStateRef.current) {
      return;
    }

    const { gl } = threeStateRef.current;
    let frameArrayBuffer: ArrayBuffer | null = null;

    await new Promise<void>((resolve) => {
      gl.domElement.toBlob(async (blob) => {
        if (!blob) {
          return resolve();
        }

        frameArrayBuffer = await blob?.arrayBuffer();

        resolve();
      });
    });

    if (frameArrayBuffer) {
      onAddToWorkbench([{ preview: frameArrayBuffer }]);

      setTimeout(() => {
        validRenders.forEach((render, index) => {
          onAddToWorkbench([{ preview: render }], {
            x: GENERATED_IMAGES_MARGIN * index,
            y: -GENERATED_IMAGES_MARGIN,
          });
        });
      }, 300);
    } else {
      validRenders.forEach((render) => {
        onAddToWorkbench([{ preview: render }]);
      });
    }
  };

  const onDownload = () => {
    if (!validRenders.length || !threeStateRef.current) {
      return;
    }

    const { gl } = threeStateRef.current;
    const frame = gl.domElement.toDataURL('image/jpg');

    downloadFile(frame, 'scene.download');

    if (validRenders.length === 1) {
      downloadFile(new Blob([validRenders[0]]), 'ai.download');
    } else {
      validRenders.forEach((render, index) => {
        downloadFile(new Blob([render]), `ai-${index + 1}.download`);
      });
    }
  };

  return (
    <ToolbarContainer>
      <Toolbar>
        <CompositeSceneAIHistory
          renders={renders}
          setActiveRenders={setActiveRenders}
        />
        <ToolbarDivider />
        <LoadingIndicator $active={loading} />
        <ToolbarDivider />
        <Tooltip tip="Download" position="top">
          <Button
            variant="tertiary"
            size="icon"
            onClick={onDownload}
            disabled={!validRenders.length}
          >
            <DownloadIcon />
          </Button>
        </Tooltip>
        <Tooltip tip="Add to Workbench" position="top">
          <Button
            disabled={blockAddingToWorkbench || !validRenders.length}
            variant="primary"
            size="icon"
            onClick={() => {
              onAddRendersToWorkbench();
              setBlockAddingToWorkbench(true);
            }}
            style={
              blockAddingToWorkbench
                ? {
                    backgroundColor: theme.deprecated.tertiary.disabled,
                    color: theme.deprecated.tertiary.default,
                    width: 64,
                  }
                : {
                    backgroundColor: theme.deprecated.tertiary.default,
                    color: theme.button.secondary,
                    width: 64,
                  }
            }
          >
            {blockAddingToWorkbench
              ? 'Added'
              : ['Add', renders.length].filter(Boolean).join(' ')}
          </Button>
        </Tooltip>
      </Toolbar>
    </ToolbarContainer>
  );
};

import { WorkbenchBasicDataFragment } from 'libs/shared/data-access/graphql/src/gql/graphql';
import { useState } from 'react';
import { publishTrackingEvent } from '@vizcom/shared/data-access/graphql';
import { WorkbenchSharingName } from '@vizcom/shared/data-shape';
import {
  addToast,
  Button,
  CheckIcon,
  LinkIcon,
  Text,
} from '@vizcom/shared-ui-components';

type CopySharedLinkButtonProps = {
  workbench: WorkbenchBasicDataFragment;
  disabled?: boolean;
  $fullWidth?: boolean;
  variant?: 'secondary' | 'primary';
};

export function CopySharedLinkButton({
  workbench,
  disabled = false,
  $fullWidth,
  variant = 'secondary',
}: CopySharedLinkButtonProps) {
  const [linkCopied, setLinkCopied] = useState(false);

  const handleCopyLink = async () => {
    const displayDuration = 3_000;

    await navigator.clipboard.writeText(`${location.href}`);

    addToast('Your link has been copied to your clipboard', {
      duration: displayDuration,
    });

    setLinkCopied(true);
    setTimeout(() => {
      setLinkCopied(false);
    }, displayDuration);

    publishTrackingEvent({
      type: WorkbenchSharingName.SHARING_LINK_COPIED,
      data: {
        workbenchId: workbench.id,
      },
    });
  };

  return (
    <Button
      variant={variant}
      size="L"
      onClick={handleCopyLink}
      disabled={disabled || linkCopied}
      $fullWidth={$fullWidth}
    >
      {linkCopied ? (
        <CheckIcon />
      ) : (
        <LinkIcon color={disabled ? 'subtext' : 'body'} />
      )}
      <Text type="b1">{linkCopied ? 'Link copied!' : 'Copy link'}</Text>
    </Button>
  );
}

import { createContext, useContext, useEffect, useMemo } from 'react';
import {
  CanvasTexture,
  DataTexture,
  FloatType,
  NearestFilter,
  Texture,
  RedFormat,
} from 'three';
import { createStore, useStore } from 'zustand';
import { createCanvas, useStableCallback } from '@vizcom/shared-ui-components';

import { SyncedActionPayloadFromType } from '../../../lib/SyncedAction';
import { MutateLocalStateAction } from '../../../lib/actions/drawing/mutateLocalStateAction';
import { useDrawingSyncedState } from '../../../lib/useDrawingSyncedState';
import { calcSDF } from '../lib/bitmapSDF';

export type ControlPoint = {
  x: number;
  y: number;
  inner: boolean;
  initialized?: boolean;
};

export type BezierPoint = {
  x: number;
  y: number;
  id: string;
  singleControlLock?: boolean;
  innerControl: ControlPoint;
  outerControl: ControlPoint;
};

type PenState = {
  bezierPoints: BezierPoint[];
  closed: boolean;
};

export interface SelectionApi {
  version: number;
  hasMask: boolean;

  editSelectionCanvas: (
    editionFunction: (ctx: CanvasRenderingContext2D) => void,
    undoGroupId?: string
  ) => void;
  setSelectionImage: (selectionImage: CanvasImageSource) => void;

  invertMask: () => void;
  deselectMask: () => void;
  selectAll: () => void;

  canvas: HTMLCanvasElement;
  texture: Texture;
  texture_treatEmptyMaskAsFull: Texture;

  penState: PenState;
  setPenState: (state: PenState | ((prev: PenState) => PenState)) => void;
  lastPenState: PenState;
  setLastPenState: (state: PenState) => void;

  getSelectionImage: () => ImageData | undefined;
  onBeforeSelectionChange: (listener: () => void) => () => void; // call this listener before the selection changes, returns the function to unsubscribe

  offsetModeActivated: boolean;
  offsetDistanceTexture: Texture | undefined;
  enterOffsetMode: () => void;
  exitOffsetMode: () => void;
  applyOffset: (offset: number) => void;
}

//This texture can be used in place of the selection texture when
//the selection is emtpy but we want the tools to work on the whole canvas
const whiteTexture = new Texture();
whiteTexture.image = new ImageData(1, 1);
whiteTexture.image.data[0] = 255;
whiteTexture.needsUpdate = true;

const checkCanvasSelectionIsEmpty = (ctx: CanvasRenderingContext2D) => {
  const pixels = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
  for (let i = 0; i < pixels.data.length; i += 4) {
    if (pixels.data[i] !== 0) {
      return false;
    }
  }
  return true;
};

// Provides an interface to manipluate the selection through a canvas
// This returns a zustand store that can be used to manipulate the selection or to subscribe to it
// We use a zustand store to remove unecessary re-renders and to let other components that references the selection to be updated only when the selection changes
// This should only be called once per drawing, at the top-level and then exposed to other children components through useSelectionApiStore and useSubscribeToSelectionApi
export const useSelectionApi = (
  drawingSize: [number, number],
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction']
) => {
  const handleActionRef = useStableCallback(handleAction);

  const store = useMemo(
    () =>
      createStore<SelectionApi>((set, get) => {
        const { canvas, ctx } = createCanvas(1, 1);
        const canvasTexture = new CanvasTexture(canvas);
        canvasTexture.minFilter = canvasTexture.magFilter = NearestFilter;

        const onBeforeSelectionChange = new Set<() => void>();

        // return the mutate local state action marker that will be used to model this selection edition
        // it won't actually change anything in the drawing itself as the whole selection state is still managed in this hook
        // but will construct the "undo" action that can be used to set the selection canvas back to its previous saved state
        const getMutateLocalStateAction = (
          editionFunction: () => void,
          penState?: {
            previous?: PenState;
            current?: PenState;
          }
        ) => {
          const { canvas: snapshotCanvas, ctx: snapshotCtx } = createCanvas(
            canvas.width,
            canvas.height
          );
          snapshotCtx.save();
          snapshotCtx.fillStyle = 'black';
          snapshotCtx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
          snapshotCtx.restore();

          const currentPenState = penState?.current || get().penState;

          const action = {
            type: 'mutateLocalState',
            onExecute: () => {
              onBeforeSelectionChange.forEach((listener) => listener());
              snapshotCtx.drawImage(canvas, 0, 0);
              ctx.save();
              editionFunction();
              ctx.restore();
              get().texture.needsUpdate = true;
              const hasMask = !checkCanvasSelectionIsEmpty(ctx);
              get().exitOffsetMode();

              set({
                version: get().version + 1,
                hasMask,
                texture_treatEmptyMaskAsFull: hasMask
                  ? get().texture
                  : whiteTexture,
                penState: {
                  bezierPoints: currentPenState.bezierPoints.map((p) => ({
                    ...p,
                    innerControl: { ...p.innerControl },
                    outerControl: { ...p.outerControl },
                  })),
                  closed: currentPenState.closed,
                },
                lastPenState: currentPenState,
              });
            },
            undoConstructor: () =>
              getMutateLocalStateAction(
                () => {
                  ctx.drawImage(snapshotCanvas, 0, 0);
                },
                {
                  previous: currentPenState,
                  current: get().lastPenState,
                }
              ),
          } as SyncedActionPayloadFromType<typeof MutateLocalStateAction>;
          return action;
        };

        return {
          version: 0,
          hasMask: false,
          canvas,
          editSelectionCanvas: (editionFunction, undoGroupId) => {
            handleActionRef(
              getMutateLocalStateAction(() => editionFunction(ctx)),
              {
                undoGroupId,
              }
            );
          },

          getSelectionImage: () => {
            if (!get().hasMask) {
              return undefined;
            }
            return ctx.getImageData(0, 0, canvas.width, canvas.height);
          },

          setSelectionImage: (selectionImage) => {
            handleActionRef(
              getMutateLocalStateAction(() => {
                ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
                ctx.drawImage(selectionImage, 0, 0);
              })
            );
          },

          invertMask: () => {
            handleActionRef(
              getMutateLocalStateAction(() => {
                ctx.globalCompositeOperation = 'difference';
                ctx.fillStyle = 'white';
                ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
              })
            );
          },
          selectAll: () => {
            handleActionRef(
              getMutateLocalStateAction(() => {
                ctx.fillStyle = 'white';
                ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
              })
            );
          },
          deselectMask: () => {
            handleActionRef(
              getMutateLocalStateAction(
                () => {
                  ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
                },
                {
                  previous: get().lastPenState,
                  current: {
                    bezierPoints: [],
                    closed: false,
                  },
                }
              )
            );
          },

          texture_treatEmptyMaskAsFull: whiteTexture,
          texture: canvasTexture,

          onBeforeSelectionChange: (listener) => {
            onBeforeSelectionChange.add(listener);
            return () => {
              onBeforeSelectionChange.delete(listener);
            };
          },

          penState: {
            bezierPoints: [],
            closed: false,
          },
          setPenState: (state) => {
            set((prev) => {
              const newState =
                typeof state === 'function' ? state(prev.penState) : state;
              return {
                ...prev,
                penState: newState,
              };
            });
          },

          lastPenState: {
            bezierPoints: [],
            closed: false,
          },
          setLastPenState: (state) => {
            set({ lastPenState: state });
          },

          offsetModeActivated: false,
          offsetDistanceTexture: undefined,
          enterOffsetMode: () => {
            const sdf = calcSDF(
              ctx.getImageData(0, 0, canvas.width, canvas.height),
              {
                channel: 0,
              }
            );
            const distanceTexture = new DataTexture(
              sdf,
              canvas.width,
              canvas.height,
              RedFormat,
              FloatType
            );
            distanceTexture.needsUpdate = true;
            set({
              offsetModeActivated: true,
              offsetDistanceTexture: distanceTexture,
            });
          },
          exitOffsetMode: () => {
            const { offsetDistanceTexture } = get();

            if (offsetDistanceTexture) {
              offsetDistanceTexture.dispose();
            }
            set({
              offsetModeActivated: false,
              offsetDistanceTexture: undefined,
            });
          },
          applyOffset: (offset: number) => {
            const { offsetDistanceTexture, exitOffsetMode } = get();
            if (!offsetDistanceTexture) {
              exitOffsetMode();
              return;
            }

            const sdf = offsetDistanceTexture.source.data.data;

            handleActionRef(
              getMutateLocalStateAction(() => {
                const imageData = ctx.createImageData(
                  canvas.width,
                  canvas.height
                );
                const data = imageData.data;
                for (let i = 0; i < data.length; i += 4) {
                  const x = (i / 4) % canvas.width;
                  const y = Math.floor(i / 4 / canvas.width);
                  const value =
                    sdf[x + (canvas.height - y) * canvas.width] < offset
                      ? 255
                      : 0;
                  data[i] = value;
                  data[i + 1] = value;
                  data[i + 2] = value;
                  data[i + 3] = 255;
                }
                ctx.putImageData(imageData, 0, 0);
              })
            );
          },
        };
      }),
    [handleActionRef]
  );

  const canvas = useStore(store, (state) => state.canvas);
  const canvasTexture = useStore(store, (state) => state.texture);
  useEffect(() => {
    return () => {
      if (canvasTexture) {
        canvasTexture.dispose();
      }
    };
  }, [canvasTexture]);

  useEffect(() => {
    if (canvas.width !== drawingSize[0] || canvas.height !== drawingSize[1]) {
      canvas.width = drawingSize[0];
      canvas.height = drawingSize[1];
      // need to recreate a canvas texture when updating the canvas size
      const newCanvasTexture = new CanvasTexture(canvas);
      newCanvasTexture.minFilter = newCanvasTexture.magFilter = NearestFilter;
      store.setState({ texture: newCanvasTexture });
    }
  }, [drawingSize[0], drawingSize[1], canvas, store]);

  return store;
};

export const SelectionApiContext = createContext<ReturnType<
  typeof useSelectionApi
> | null>(null);
export const SelectionApiContextProvider = SelectionApiContext.Provider;

export const useSelectionApiStore = () => {
  const selectionApiStore = useContext(SelectionApiContext);
  if (!selectionApiStore) {
    throw new Error(
      'useSelectionApiStore must be used within a SelectionApiProvider'
    );
  }
  return selectionApiStore;
};

export function useSubscribeToSelectionApi<T>(
  selector: (selectionApi: SelectionApi) => T
): T {
  const selectionApiStore = useSelectionApiStore();
  return useStore(selectionApiStore, selector);
}

export const useOnBeforeSelectionChange = (listener: () => void): void => {
  const selectionApiStore = useSelectionApiStore();
  const stableListener = useStableCallback(listener);
  const onBeforeSelectionChange = useStore(
    selectionApiStore,
    (state) => state.onBeforeSelectionChange
  );

  useEffect(
    () => onBeforeSelectionChange(stableListener),
    [stableListener, onBeforeSelectionChange]
  );
};

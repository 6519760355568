import styled from 'styled-components';
import {
  Button,
  Checkbox,
  CloseIcon,
  Divider,
  HorizontalSwitcher,
  Text,
} from '@vizcom/shared-ui-components';

import { useWorkbenchStudioState } from '../../studioState';
import { ToolbarSlider } from '../ToolbarSlider';
import { BrushSizePreview } from './BrushSizePreview';
import { BrushTransformInput } from './BrushTransformInput';

type BrushSettingsProps = {
  title?: string;
  onClose?: () => void;
  disableHardness?: boolean;
  disableOpacity?: boolean;
  disableStabilizer?: boolean;
  disableAspect?: boolean;
};

export const BrushSettings = ({
  title,
  onClose,
  disableHardness = false,
  disableOpacity = false,
  disableStabilizer = false,
  disableAspect = false,
}: BrushSettingsProps) => {
  const { getToolSettings } = useWorkbenchStudioState();
  const {
    toolHardness,
    toolAspect,
    toolAngle,
    toolPressureSize,
    toolPressureOpacity,
    toolStabilizer,
    toolSize,
    toolOpacity,
    setToolAspect,
    setToolHardness,
    setToolAngle,
    setToolPressureSize,
    setToolPressureOpacity,
    setToolStabilizer,
  } = getToolSettings();

  return (
    <Content>
      {title && (
        <>
          <BodyRow>
            <Text type="sh2">{title}</Text>
            {onClose && (
              <Button
                variant="secondary"
                size="icon"
                onClick={onClose}
                style={{ width: '24px', height: '24px', padding: '4px' }}
              >
                <CloseIcon $size="M" $color="primary" />
              </Button>
            )}
          </BodyRow>
          <Divider />
        </>
      )}
      <Body>
        {!disableStabilizer && (
          <BodyRow>
            <Text type="b2" color="subtext">
              Stabilizer
            </Text>
            <HorizontalSwitcher
              value={toolStabilizer}
              options={[0, 1, 2, 3].map((i) => ({
                label: <Text type="button3">{`${i}x`}</Text>,
                value: i,
              }))}
              onChange={(v) => setToolStabilizer(+v)}
              style={{ minHeight: 24, width: 'unset', flexGrow: 0 }}
              optionStyle={{ height: 24, width: 24 }}
            />
          </BodyRow>
        )}
        {!disableHardness && (
          <BodyRow>
            <Text type="b2" color="subtext">
              Hardness
            </Text>
            <ToolbarSlider
              value={toolHardness}
              setValue={setToolHardness}
              min={1}
              max={100}
              unit="%"
              sliderWidth={56}
            />
          </BodyRow>
        )}
        <BodyRow>
          <Text type="b2" color="subtext">
            Use pressure for size
          </Text>
          <Checkbox
            checked={toolPressureSize}
            onClick={() => setToolPressureSize(!toolPressureSize)}
          />
        </BodyRow>
        <BodyRow>
          <Text type="b2" color="subtext">
            Use pressure for opacity
          </Text>
          <Checkbox
            checked={toolPressureOpacity}
            onClick={() => setToolPressureOpacity(!toolPressureOpacity)}
          />
        </BodyRow>

        <GizmoContainer>
          <BrushSizePreview
            size={toolSize}
            opacity={disableOpacity ? 1 : toolOpacity}
            hardness={disableHardness ? 100 : toolHardness}
            aspect={disableAspect ? 1 : toolAspect}
            angle={disableAspect ? 0 : toolAngle}
          />
          {!disableAspect && (
            <div
              style={{
                position: 'absolute',
                top: '0px',
                left: '0px',
                width: '100%',
                height: '100%',
              }}
            >
              <BrushTransformInput
                aspect={toolAspect}
                setAspect={setToolAspect}
                rotation={toolAngle}
                setRotation={setToolAngle}
              />
            </div>
          )}
        </GizmoContainer>
      </Body>
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const BodyRow = styled.div`
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
`;

const GizmoContainer = styled.div`
  position: relative;
  width: 176px;
  height: 100px;

  border-radius: ${({ theme }) => theme.borderRadius.m};
  overflow: hidden;
`;

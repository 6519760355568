import { MutableRefObject, useEffect, useRef, useState } from 'react';

export const useResizeObserver = <T extends HTMLElement = HTMLElement>(): [
  MutableRefObject<T | null>,
  number
] => {
  const [width, setWidth] = useState(0);
  const ref = useRef<T>(null);

  useEffect(() => {
    if (!ref.current) return;

    const observer = new ResizeObserver(([entry]) => {
      if (!entry) return;
      setWidth(entry.contentRect.width);
    });

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return [ref, width];
};

import { ReactNode } from 'react';
import styled from 'styled-components';
import { CheckIcon } from '@vizcom/shared-ui-components';

type ToolbarOptionProps = {
  icon: ReactNode;
  label: string;
  shortcut?: string;
  active?: boolean;
  isSpaceForCheckmark?: boolean;
  onClick: (event: React.MouseEvent) => void;
};

export const ToolbarMenuOption = ({
  icon,
  label,
  shortcut,
  active,
  isSpaceForCheckmark = false,
  onClick,
}: ToolbarOptionProps) => {
  return (
    <StyledOption $active={active} onClick={onClick}>
      {isSpaceForCheckmark && <CheckIcon style={{ opacity: active ? 1 : 0 }} />}
      {icon}
      <TextRow>
        <div>{label}</div>
        {shortcut && <div>{shortcut}</div>}
      </TextRow>
    </StyledOption>
  );
};

const StyledOption = styled.button<{ $active?: boolean }>`
  border: 0;
  margin: 0;
  padding: 8px 12px;
  background: ${({ $active, theme }) =>
    $active ? theme.surface.secondary : 'transparent'};
  cursor: pointer;
  display: flex;
  gap: 16px;
  align-items: center;
  color: ${({ theme }) => theme.text.body};
  transition: background-color 0.1s ease, color 0.1s ease;
  border-radius: ${({ theme }) => theme.borderRadius.m};

  &:disabled {
    color: ${({ theme }) => theme.text.bodyDisabled};
    cursor: default;
  }

  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.surface.secondary};
  }
`;

const TextRow = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 36px;
`;

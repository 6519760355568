import { createUseGesture, dragAction, wheelAction } from '@use-gesture/react';
import { groupBy } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import {
  useCustomModels,
  useUserPalette,
  useWorkbenchPalettes,
} from '@vizcom/shared/data-access/graphql';
import { filterExists } from '@vizcom/shared/js-utils';
import {
  InfoIcon,
  PUBLIC_PALETTES_DETAILS,
  PALETTE_DISPLAY_ORDER,
  Text,
  useSelectedOrganization,
  Dropdown,
  PaletteCategory,
  PlusIcon,
  RichTooltip,
  RichTooltipTrigger,
  RichTooltipContent,
  useLocalStorage,
  PublicPaletteThumbnail,
  MeatballIcon,
  Divider,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

import { LocalPaletteOptions } from './LocalPaletteOptions';
import { MyLibraryPaletteMenu } from './MyLibraryPaletteMenu';
import { PaletteVersionsMenu, VersionedPalette } from './PaletteVersionsMenu';

export const VERSION_SELECTIONS_KEY = 'vizcom:palette_versions';

export type Palette = {
  id: string;
  name: string;
  desc: string;
  type:
    | 'publicPaletteId'
    | 'customModelId'
    | 'workbenchPaletteId'
    | 'userPaletteId';
  value: string;
  category: PaletteCategory;
  thumbnailPath: string | null | undefined;
  workbenchId?: string;
  versions?: { value: string; version: number }[];
  tags?: string[];
  workbenchElement?: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
};

export const getPaletteVersion = (
  palette: VersionedPalette,
  selectedPaletteId: string
) => {
  let version = 1;
  if (palette.value === selectedPaletteId) {
    return version;
  }

  for (const v of palette.versions) {
    version++;
    if (v.value === selectedPaletteId) {
      return version;
    }
  }

  return undefined;
};

export const useAvailablePalettes = (workbenchId?: string) => {
  const { data } = useSelectedOrganization();
  const customModels = useCustomModels(data?.id);
  const { data: palettes } = useWorkbenchPalettes(workbenchId);
  const { data: userPalettes } = useUserPalette(data?.id);

  return [
    ...PUBLIC_PALETTES_DETAILS().map(
      (palette): Palette => ({
        type: 'publicPaletteId',
        ...palette,
      })
    ),
    ...(customModels.data?.nodes?.map(
      (customModel): Palette => ({
        id: customModel.id,
        name: customModel.name,
        value: customModel.id,
        desc: 'Custom',
        thumbnailPath: customModel.thumbnailPath,
        type: 'customModelId',
        category: PaletteCategory.MY_LIBRARY,
      })
    ) ?? []),
    ...(palettes?.nodes
      .filter((palette) => palette.status === 'ready')
      .map(
        (palette): Palette => ({
          id: palette.id,
          name: palette.name,
          value: palette.id,
          desc: 'Custom',
          thumbnailPath: palette.thumbnailPath ?? PublicPaletteThumbnail,
          type: 'workbenchPaletteId',
          category: PaletteCategory.Local,
          tags: palette.tags.filter(filterExists),
          workbenchId,
          workbenchElement: {
            x: palette.x,
            y: palette.y,
            width: palette.width,
            height: palette.height,
          },
        })
      ) ?? []),
    ...(userPalettes.map(
      (palette): Palette => ({
        id: palette.id,
        name: palette.name,
        value: palette.id,
        desc: 'Custom',
        thumbnailPath: palette.thumbnailPath ?? PublicPaletteThumbnail,
        type: 'userPaletteId',
        category: PaletteCategory.MY_LIBRARY,
        tags: palette.tags.filter(filterExists),
      })
    ) ?? []),
  ];
};

const useGesture = createUseGesture([wheelAction, dragAction]);

type Props = {
  size: 'small' | 'large';
  workbenchId?: string;
  availablePalettes: Palette[];
  selectedPaletteId: string;
  handleSelectPalette: (paletteId: string, type: string) => void;
  onExit?: () => void;
  in2DStudio?: boolean;
};

export const PaletteSelector = ({
  size,
  workbenchId,
  availablePalettes,
  selectedPaletteId,
  handleSelectPalette: _handleSelectPalette,
  onExit,
  in2DStudio,
}: Props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { data } = useSelectedOrganization();
  const ref = useRef<HTMLDivElement>(null);
  const [categoryFilter, setCategoryFilter] = useState<string>('All libraries');
  const [versionSelections, setVersionSelections] = useLocalStorage(
    VERSION_SELECTIONS_KEY,
    {} as Record<string, number>
  );

  const showFilter = useMemo(
    () =>
      availablePalettes.some(
        (palette) =>
          palette.type === 'workbenchPaletteId' ||
          palette.type === 'customModelId' ||
          palette.type === 'userPaletteId'
      ),
    [availablePalettes]
  );

  const selectedPalette = useMemo(
    () =>
      availablePalettes.find(
        (palette) =>
          palette.value === selectedPaletteId ||
          (
            palette.versions &&
            palette.versions.find((v) => v.value === selectedPaletteId)
          )?.value
      ),
    [availablePalettes, selectedPaletteId]
  );

  const handleCreatePalette = useCallback(() => {
    if (!workbenchId) {
      return;
    }

    navigate(paths.workbench.newPalette(workbenchId));
  }, [navigate, workbenchId]);

  const handleSelectPalette = useCallback(
    (id: string, version: number, paletteValue: string, type: string) => {
      setVersionSelections({
        ...versionSelections,
        [id]: version,
      });

      _handleSelectPalette(paletteValue, type);
    },
    [versionSelections, _handleSelectPalette, setVersionSelections]
  );

  useEffect(() => {
    // scroll to the selected palette
    if (ref.current) {
      const selectedPaletteElement = ref.current.querySelector(
        `[data-palette-id="${selectedPaletteId}"]`
      ) as HTMLButtonElement | null;

      if (selectedPaletteElement) {
        ref.current.scrollTop =
          selectedPaletteElement.offsetTop - ref.current.clientHeight / 2;
      }
    }
  }, [selectedPaletteId]);

  useGesture(
    {
      onWheel: (state) => {
        const { event } = state;
        event.preventDefault();
        event.stopPropagation();

        if (ref.current) {
          ref.current.scrollTop += state.delta[1];
        }
      },
      onDrag: (state) => {
        const { event } = state;
        event.preventDefault();
        event.stopPropagation();

        if (ref.current) {
          ref.current.scrollTop += state.delta[1];
        }
      },
    },
    {
      eventOptions: {
        passive: false,
      },
      target: ref,
    }
  );

  const groupedPalettes = useMemo(
    () =>
      Object.entries({
        ...groupBy(availablePalettes, 'category'),
        ...(!availablePalettes.some(
          (palette) => palette.type === 'workbenchPaletteId'
        )
          ? { [PaletteCategory.Local]: [] as Palette[] }
          : {}),
      })
        .sort((a, b) => {
          const indexA = PALETTE_DISPLAY_ORDER.indexOf(a[0]);
          const indexB = PALETTE_DISPLAY_ORDER.indexOf(b[0]);

          const effectiveIndexA = indexA === -1 ? Infinity : indexA;
          const effectiveIndexB = indexB === -1 ? Infinity : indexB;

          return effectiveIndexA - effectiveIndexB;
        })
        .filter(([category]) => {
          if (categoryFilter === 'Vizcom') {
            return ['Essentials', 'Stylized', 'Automotive'].includes(category);
          }

          if (categoryFilter === 'Local palettes') {
            return category === 'Local palettes';
          }

          if (categoryFilter === 'My library') {
            return category === 'My Library';
          }

          if (categoryFilter === data?.name) {
            return category === data?.name;
          }
          return true;
        }),
    [availablePalettes, categoryFilter, data]
  );
  const filterCategories = useMemo(
    () => [
      { data: { value: 'All libraries', label: 'All libraries' } },
      { data: { value: 'Vizcom', label: 'Vizcom' } },
      {
        data: {
          value: 'Local palettes',
          label: 'Local palettes',
        },
      },
      ...(availablePalettes.some((palette) => palette.type === 'customModelId')
        ? [
            {
              data: {
                value: data?.name || 'Workspace',
                label: data?.name || 'Workspace',
              },
            },
          ]
        : []),
      ...(availablePalettes.some((palette) => palette.type === 'userPaletteId')
        ? [
            {
              data: {
                value: 'My library',
                label: 'My library',
              },
            },
          ]
        : []),
    ],
    [availablePalettes, data]
  );

  return (
    <Container ref={ref} $size={size}>
      <TitleContainer $size={size}>
        {size === 'large' && (
          <>
            <Title>
              Palettes
              <a target="_blank" href="https://docs.vizcom.ai/render-styles">
                <InfoIcon $color="secondary" $size="S" />
              </a>
            </Title>
            <Divider />
          </>
        )}
        {showFilter && (
          <div
            style={{
              display: 'flex',
              gap: '12px',
              flexDirection: 'column',
            }}
          >
            <Dropdown
              options={filterCategories}
              value={categoryFilter}
              setValue={(value) => setCategoryFilter(value)}
              OptionComponent={({ option }) => <Text>{option.label}</Text>}
              optionToValueMapper={(option) => option.label}
              buttonProps={{
                style: {
                  padding: '8px 12px',
                  width: '100%',
                },
              }}
            >
              <span
                style={{
                  fontSize: 12,
                }}
              >
                {categoryFilter}
              </span>
            </Dropdown>
            <Divider />
          </div>
        )}
      </TitleContainer>
      <div
        style={{
          marginTop:
            showFilter && size === 'large'
              ? '108px'
              : !showFilter && size === 'small'
              ? 0
              : '48px',
        }}
      >
        {groupedPalettes.map(([category, palettes]) => (
          <div key={category}>
            <Text color="subtext">{category}</Text>
            <PalettesContainer>
              {palettes.map((palette) => (
                <PaletteButton
                  $selected={palette.name === selectedPalette?.name}
                  key={palette.value}
                  data-palette-id={palette.id}
                  onClick={(e) => {
                    if (!palette.versions || !palette.versions.length) {
                      _handleSelectPalette(palette.value, palette.type);
                      return;
                    }

                    // Ignore clicks on the palette version button and its descendants
                    if ((e.target as HTMLDivElement).closest('button')) {
                      return;
                    }

                    const version =
                      versionSelections[palette.id] ??
                      palette.versions.length + 1;

                    const id =
                      version === 1
                        ? palette.value
                        : palette.versions[version - 2].value;

                    handleSelectPalette(palette.id, version, id, palette.type);
                  }}
                >
                  {palette.thumbnailPath && (
                    <PaletteThumbnail
                      crossOrigin="anonymous"
                      alt={palette.name}
                      src={palette.thumbnailPath}
                    />
                  )}
                  <span>{palette.name}</span>
                  {palette.versions && palette.versions.length > 0 && (
                    <VersionTag>
                      v
                      {getPaletteVersion(
                        palette as VersionedPalette,
                        selectedPaletteId
                      ) ??
                        versionSelections[palette.id] ??
                        palette.versions.length + 1}
                    </VersionTag>
                  )}
                  {palette.category === 'Local palettes' && (
                    <RichTooltip
                      noParentIntegration
                      trigger="click"
                      displayArrow={false}
                    >
                      <RichTooltipTrigger>
                        <StyledMeatballIcon />
                      </RichTooltipTrigger>
                      <RichTooltipContent
                        $background="tertiary"
                        style={{
                          padding: '8px',
                          borderRadius: theme.borderRadius.l,
                        }}
                      >
                        <LocalPaletteOptions
                          palette={palette}
                          in2DStudio={in2DStudio}
                          onExit={onExit}
                        />
                      </RichTooltipContent>
                    </RichTooltip>
                  )}
                  {palette.versions && Boolean(palette.versions.length) && (
                    <PaletteVersionsMenu
                      selectedPaletteId={selectedPaletteId}
                      palette={palette as VersionedPalette}
                      handleSelectPalette={handleSelectPalette}
                    />
                  )}
                  {palette.category === PaletteCategory.MY_LIBRARY && (
                    <MyLibraryPaletteMenu palette={palette} />
                  )}
                </PaletteButton>
              ))}

              {!palettes.length && (
                <PaletteButton onClick={handleCreatePalette}>
                  <PlusIconContainer>
                    <PlusIcon />
                  </PlusIconContainer>
                  Create a palette
                </PaletteButton>
              )}
            </PalettesContainer>
          </div>
        ))}
      </div>
    </Container>
  );
};

const Container = styled.div<{ $size: 'small' | 'large' }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: ${(p) => (p.$size === 'small' ? '252px;' : '416px')};
  width: ${(p) => (p.$size === 'small' ? '215px;;' : '252px')};
  padding: 12px 12px 0px;
  overflow-y: scroll;

  background-color: ${({ theme }) => theme.surface.primary};

  pointer-events: all;
  ${({ theme }) => theme.scrollbar.dark}
`;

const TitleContainer = styled.div<{ $size: 'small' | 'large' }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: fixed;
  padding-top: 12px;
  margin-top: -12px;
  background-color: ${(p) => p.theme.surface.primary};
  width: ${(p) => (p.$size === 'small' ? '191px' : '228px')};
`;

const Title = styled.div`
  display: flex;
  font-weight: 600;
  align-items: center;
  gap: 8px;
  color: ${(p) => p.theme.text.body};

  a {
    margin-top: 3px;
  }
`;

const PalettesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin: 16px 0px;
`;

const PaletteButton = styled.div<{ $selected?: boolean }>`
  display: flex;
  gap: 8px;
  width: 100%;
  padding: 6px;
  align-items: center;

  border-radius: ${({ theme }) => theme.borderRadius.m};
  background-color: ${(p) =>
    p.$selected ? p.theme.surface.secondary : 'transparent'};
  font-size: 12px;

  :hover {
    background-color: ${(p) => p.theme.surface.tertiary};
    cursor: pointer;
  }
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const PaletteThumbnail = styled.img`
  width: 32px;
  height: 32px;
  border-radius: calc(${({ theme }) => theme.borderRadius.m} - 2px);
`;

const StyledMeatballIcon = styled(MeatballIcon)`
  flex: none;
  margin: 0 4px 0 auto;
`;

const PlusIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(p) => p.theme.surface.tertiary};
  border-radius: calc(${({ theme }) => theme.borderRadius.m} - 2px);
  width: 32px;
  height: 32px;
`;

export const VersionTag = styled.div`
  background-color: ${(p) => p.theme.surface.tertiary};
  color: ${(p) => p.theme.text.subtext};
  font-size: 12px;
  min-width: 20px;
  text-align: center;
  border-radius: ${({ theme }) => theme.borderRadius.s};
  padding: 2px;
  margin-right: 8px;
`;

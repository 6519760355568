import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Vector2, WebGLRenderer } from 'three';

import { StrokeRenderer } from '../../tools/BrushEngine/useStrokeRenderer';

type BrushSizePreviewProps = {
  size: number;
  opacity: number;
  hardness: number;
  aspect: number;
  angle: number;
};

const PreviewContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.surface.tertiary};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  padding: 0.5rem;
  width: 100%;
  height: 100%;
  position: relative;
`;

const previewSize: [number, number] = [176, 100];

//we need a single point in the middle
const zeroCoefficientsX = { c0: 0.5 * previewSize[0], c1: 0, c2: 0, c3: 0 };
const zeroCoefficientsY = { c0: 0.5 * previewSize[1], c1: 0, c2: 0, c3: 0 };

export const BrushSizePreview: React.FC<BrushSizePreviewProps> = ({
  size,
  opacity,
  hardness,
  aspect,
  angle,
}) => {
  const previewCanvasRef = useRef<HTMLCanvasElement>(null!);
  const strokeRenderer = useRef<StrokeRenderer>(null!);

  //The `useStrokeRenderer` hook requires a r3f canvas.
  //Apparently r3f canvases don't work well in a HTMLOverlay context, so we're directly using a WebGLRenderer and a standard canvas
  useEffect(() => {
    if (previewCanvasRef.current) {
      const gl = new WebGLRenderer({ canvas: previewCanvasRef.current });
      strokeRenderer.current = new StrokeRenderer(gl, previewSize, false);
      return () => {
        gl.dispose();
        gl.forceContextLoss();
        strokeRenderer.current?.dispose();
      };
    }
  }, []);

  useEffect(() => {
    if (strokeRenderer.current) {
      strokeRenderer.current.clear();
      strokeRenderer.current.render(
        aspect,
        '#ffffff',
        [0],
        hardness / 100,
        opacity,
        { start: 1, end: 1 },
        zeroCoefficientsX,
        zeroCoefficientsY,
        (-angle * Math.PI) / 180,
        size,
        false,
        new Vector2(0, 0),
        new Vector2(0, 1),
        false,
        false
      );
    }
  }, [aspect, hardness, angle, size, strokeRenderer, opacity]);

  return (
    <PreviewContainer>
      <canvas
        ref={previewCanvasRef}
        width={previewSize[0]}
        height={previewSize[1]}
      />
    </PreviewContainer>
  );
};

import { useState } from 'react';
import { useTheme } from 'styled-components';
import {
  AlignRightIcon,
  AlignCenterIcon,
  AlignLeftIcon,
  ToolbarButton,
} from '@vizcom/shared-ui-components';

import { ExtraPopup, Input, InputOption } from '../../style';

const alignmentToIcon = {
  left: <AlignLeftIcon />,
  center: <AlignCenterIcon />,
  right: <AlignRightIcon />,
};

type WorkbenchElementTextAlignment = {
  textAlign: 'left' | 'center' | 'right';
  handleSetTextAlign: (textAlign: 'left' | 'center' | 'right') => void;
};

export const WorkbenchElementTextAlignment = ({
  textAlign,
  handleSetTextAlign,
}: WorkbenchElementTextAlignment) => {
  const [isSelectingAlignment, setIsSelectingAlignment] = useState(false);
  const theme = useTheme();

  return (
    <>
      <ToolbarButton
        icon={alignmentToIcon[textAlign]}
        tooltip="Alignment"
        onClick={() => setIsSelectingAlignment(!isSelectingAlignment)}
      />
      {isSelectingAlignment && (
        <ExtraPopup $top={-45} $right={14}>
          <Input>
            <InputOption>
              <AlignLeftIcon
                onClick={() => handleSetTextAlign('left')}
                style={{ color: theme.icon.primary }}
              />
            </InputOption>
            <InputOption>
              <AlignCenterIcon
                onClick={() => handleSetTextAlign('center')}
                style={{ color: theme.icon.primary }}
              />
            </InputOption>
            <InputOption>
              <AlignRightIcon
                onClick={() => handleSetTextAlign('right')}
                style={{ color: theme.icon.primary }}
              />
            </InputOption>
          </Input>
        </ExtraPopup>
      )}
    </>
  );
};

import styled from 'styled-components';

type BooleanInputProps = {
  label?: string;
  value: boolean;
  onChange: (value: boolean) => void;
  variant?: 'primary' | 'secondary' | 'tertiary';
  size?: 'S' | 'M';
};

export const BooleanInput = ({
  label,
  value,
  onChange,
  variant = 'primary',
  size = 'M',
}: BooleanInputProps) => {
  return (
    <InputWrapper>
      {!!label && <InputLabel>{label}</InputLabel>}
      <InputFieldWrapper
        style={{ gridColumn: label ? '4 / 13' : '1 / 13' }}
        $size={size}
      >
        <InputFieldValue
          $active={value}
          $variant={variant}
          onClick={() => onChange(!value)}
        >
          <InputFieldDot $active={value} />
        </InputFieldValue>
      </InputFieldWrapper>
    </InputWrapper>
  );
};

const InputWrapper = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 8px;
  width: 100%;
  align-items: center;
`;

const InputLabel = styled.div`
  grid-column: 1 / 4;
`;

const InputFieldWrapper = styled.div<{
  $size: 'S' | 'M';
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  justify-content: flex-end;
  align-items: center;
  padding: ${({ $size }) => ($size === 'S' ? 0 : 8)} 0;
`;

const InputFieldValue = styled.div<{
  $variant: 'primary' | 'secondary' | 'tertiary';
  $active: boolean;
}>`
  position: relative;
  display: inline-block;
  width: 32px;
  height: 16px;
  border-radius: ${({ theme }) => theme.borderRadius.l};
  background-color: ${({ theme, $variant, $active }) =>
    $active ? theme.deprecated[$variant].default : theme.surface.secondary};
  cursor: pointer;
`;

const InputFieldDot = styled.div<{
  $active: boolean;
}>`
  position: absolute;
  top: 2px;
  left: 2px;
  width: 12px;
  height: 12px;
  border-radius: ${({ theme }) => theme.borderRadius.s};
  background-color: ${({ theme, $active }) =>
    $active ? theme.surface.secondary : theme.surface.tertiary};
  transform: translate(${({ $active }) => ($active ? '16px' : '0px')});
  transition: transform 0.2s;
  pointer-events: none;
`;

import { MouseEvent } from 'react';
import styled from 'styled-components';
import { Button } from '@vizcom/shared-ui-components';

interface GenerateButtonProps {
  isHistoryPreviewActive: boolean;
  anyOutputLoading?: boolean;
  onGenerate: () => void;
  applySettings: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const GenerateButton = ({
  isHistoryPreviewActive,
  anyOutputLoading = false,
  onGenerate,
  applySettings,
}: GenerateButtonProps) => {
  return (
    <>
      {isHistoryPreviewActive ? (
        <StyledButton
          variant="secondary"
          disabled={anyOutputLoading}
          onClick={applySettings}
        >
          Apply Settings
        </StyledButton>
      ) : (
        <StyledButton
          variant="primary"
          disabled={anyOutputLoading}
          onClick={onGenerate}
        >
          {anyOutputLoading ? 'Generating...' : 'Generate'}
        </StyledButton>
      )}
    </>
  );
};

const StyledButton = styled(Button)`
  flex: 1;
`;

import { omit } from 'lodash';
import {
  urqlClient,
  UpdateDrawingLayersMutation,
} from '@vizcom/shared/data-access/graphql';
import { LayerMetadata3d, filterExists } from '@vizcom/shared/js-utils';
import { imageDataToBlob } from '@vizcom/shared-ui-components';

import { layerIsNestedChild } from '../../../components/studio/utils';
import {
  SyncedActionPayloadFromType,
  SyncedActionType,
} from '../../SyncedAction';
import { Drawing2dStudio } from '../../useDrawingSyncedState';
import { LayerPayload } from './addLayer';
import { cachedLayerImagesByUrl } from './updateLayer';

export type LayerPatchPayLoad = {
  id: string;
  name?: string;
  visible?: boolean;
  opacity?: number;
  blendMode?: string;
  fill?: string;
  metadata3D?: LayerMetadata3d;
  orderKey?: string | null;
  parentId?: string | null;
};

export const UpdateBulkLayersAction: SyncedActionType<
  Drawing2dStudio,
  {
    type: 'updateBulkLayers';
    layerUpdates?: LayerPatchPayLoad[];
    newLayers?: LayerPayload[];
    deletedLayerIds?: string[];
  }
> = {
  type: 'updateBulkLayers',
  optimisticUpdater: ({ payload }, drawing) => {
    payload.layerUpdates?.forEach((layer) => {
      const existingLayer = drawing.layers.nodes.find((l) => l.id === layer.id);
      if (existingLayer) {
        existingLayer.name = layer.name ?? existingLayer.name;
        existingLayer.visible = layer.visible ?? existingLayer.visible;
        existingLayer.opacity = layer.opacity ?? existingLayer.opacity;
        existingLayer.blendMode = layer.blendMode ?? existingLayer.blendMode;
        existingLayer.fill = layer.fill ?? existingLayer.fill;
        existingLayer.metadata3D = layer.metadata3D ?? existingLayer.metadata3D;
        existingLayer.orderKey = layer.orderKey ?? existingLayer.orderKey;
        existingLayer.parentId =
          layer.parentId === undefined
            ? existingLayer.parentId
            : layer.parentId;
      }
    });

    payload.newLayers?.forEach((layer) => {
      const existingLayer = drawing.layers.nodes.find((l) => l.id === layer.id);
      if (existingLayer) {
        return;
      }

      const newLayer: typeof drawing.layers.nodes[0] = {
        ...omit(layer, 'image'),
        drawingId: drawing.id,
        updatedAt: '0',
        createdAt: '0',
        meshPath: layer.meshPath,
        imagePath: layer.image ?? undefined,
      };

      drawing.layers.nodes.push(newLayer);
    });

    drawing.layers.nodes = drawing.layers.nodes.filter(
      (l) =>
        !payload.deletedLayerIds?.includes(l.id) &&
        !payload.deletedLayerIds?.includes(l.parentId ?? '')
    );
  },
  remoteUpdater: async ({ payload }, drawingId) => {
    const newLayers = payload.newLayers
      ? await Promise.all(
          payload.newLayers?.map(async (layer) => ({
            ...omit(layer, 'image', 'createdAt', 'updatedAt'),
            drawingId,
            imagePath:
              layer.image instanceof ImageData
                ? await imageDataToBlob(layer.image)
                : layer.image,
          }))
        )
      : undefined;

    const res = await urqlClient.mutation(UpdateDrawingLayersMutation, {
      input: {
        id: drawingId,
        layerUpdates: payload.layerUpdates,
        newLayers,
        deletedLayerIds: payload.deletedLayerIds?.filter(Boolean),
      },
    });

    res.data?.updateDrawingLayers.drawing.layers.nodes.forEach((layer) => {
      const payloadData = newLayers?.find((l) => l.id === layer.id);
      if (!payloadData) return;

      if (layer.imagePath && payloadData.imagePath instanceof Blob) {
        cachedLayerImagesByUrl[layer.imagePath] = payloadData.imagePath;
      }

      if (layer.meshPath && payloadData.meshPath instanceof Blob) {
        cachedLayerImagesByUrl[layer.meshPath] = payloadData.meshPath;
      }
    });

    if (res?.error) {
      throw new Error(
        `Error while updating drawing, please retry. ${
          res.error.graphQLErrors[0]?.message ?? res.error.message
        }`
      );
    }
  },
  actionMerger: (a, b) => {
    const aLayerUpdates = a.payload.layerUpdates || [];
    const bLayerUpdates = b.payload.layerUpdates || [];
    const finalLayerUpdates: typeof aLayerUpdates = [];

    aLayerUpdates.forEach((layer) => {
      finalLayerUpdates.push({
        ...layer,
      });
    });

    bLayerUpdates.forEach((layer) => {
      const existingLayer = finalLayerUpdates.find((l) => l.id === layer.id);
      const existingLayerIndex = finalLayerUpdates.findIndex(
        (l) => l.id === layer.id
      );

      if (existingLayer) {
        finalLayerUpdates[existingLayerIndex] = {
          ...existingLayer,
          ...layer,
        };
      } else {
        finalLayerUpdates.push({
          ...layer,
        });
      }
    });

    return {
      ...b,
      payload: {
        ...a.payload,
        ...b.payload,
        layerUpdates: finalLayerUpdates,
        deletedLayerIds: [
          ...(a.payload.deletedLayerIds || []),
          ...(b.payload.deletedLayerIds || []),
        ],
        newLayers: [
          ...(a.payload.newLayers || []),
          ...(b.payload.newLayers || []),
        ],
      },
    };
  },
  undoConstructor: ({ payload }, drawing) => {
    const existingLayers = drawing.layers.nodes.filter(
      (l) =>
        payload.layerUpdates?.find((layer) => layer.id === l.id) !== undefined
    );

    const layerUpdates = payload.layerUpdates
      ?.map((update) => {
        const existingLayer = existingLayers.find((l) => l.id === update.id);
        if (existingLayer) {
          const updates = Object.keys(update) as (keyof LayerPatchPayLoad)[];
          const layerUpdate = updates.reduce((acc, key) => {
            (acc[key] as string) = existingLayer[key];
            return acc;
          }, {} as LayerPatchPayLoad);

          return layerUpdate;
        }
        return undefined;
      })
      .filter(filterExists);

    const deletedLayers = drawing.layers.nodes
      .map((l) => {
        // the layer was deleted
        if (payload.deletedLayerIds?.includes(l.id)) {
          return { ...l, image: l.imagePath };
        }

        // the layer was updated, don't delete it
        if (payload.layerUpdates?.find((lu) => lu.id === l.id)) {
          return undefined;
        }

        const ancestorWasDeleted = layerIsNestedChild(
          l.id,
          payload.deletedLayerIds || [],
          drawing.layers.nodes
        );

        const ancestorWasUpdated = layerIsNestedChild(
          l.id,
          payload.layerUpdates?.map((lu) => lu.id) || [],
          drawing.layers.nodes
        );

        const parentWasDeleted = l.parentId
          ? payload.deletedLayerIds?.includes(l.parentId)
          : false;

        // we are a child of a deleted group, we have not been updated with a new parent,
        if (parentWasDeleted) {
          return { ...l, image: l.imagePath };
        }

        // we are a child of a deleted group, we have not been updated with a new parent,
        // and another ancestor has not been updated with a new parent - delete this layer
        if (ancestorWasDeleted && !ancestorWasUpdated) {
          return { ...l, image: l.imagePath };
          // An ancestor was deleted but another was updated,
          // we should not delete this layer
        } else if (ancestorWasDeleted && ancestorWasUpdated) {
          return undefined;
        }

        return undefined;
      })
      .filter(filterExists);

    const undoPayload: SyncedActionPayloadFromType<
      typeof UpdateBulkLayersAction
    > = {
      type: 'updateBulkLayers',
      layerUpdates,
      deletedLayerIds: payload.newLayers?.map((l) => l.id),
      newLayers: deletedLayers,
    };

    return undoPayload;
  },
};

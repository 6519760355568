import { upperCase } from 'lodash';
import {
  LibraryType,
  UseOrganizationReturnData,
} from '@vizcom/shared/data-access/graphql';
import { OrganizationSubscriptionPlan } from '@vizcom/shared/plans-limit';
import {
  Select,
  InlineFlex,
  SelectedIndicator,
  LockIcon,
  Text,
  CaretDownIconRotatable,
  OptionButtonContainer,
  OptionComponentBase,
  DefaultOptionComponent,
} from '@vizcom/shared-ui-components';

import { type Cursors } from './AssetLibrary';

export const SelectLibraryType = ({
  organization,
  selectedLibraryType,
  setSelectedLibraryType,
  setColorsCursors,
}: {
  organization: NonNullable<UseOrganizationReturnData>;
  selectedLibraryType: LibraryType;
  setSelectedLibraryType: React.Dispatch<React.SetStateAction<LibraryType>>;
  setColorsCursors: React.Dispatch<React.SetStateAction<Cursors>>;
}) => {
  const wording = {
    [LibraryType.Workbench]: 'Workbench',
    [LibraryType.Personal]: 'My Library',
    [LibraryType.Organization]: 'Workspace',
  };

  const handleSelect = (value: LibraryType) => {
    setSelectedLibraryType(value);
    setColorsCursors([null]);
  };

  return (
    <Select
      onSelectElement={(value) => handleSelect(value as LibraryType)}
      selectedOptionIndex={Object.values(LibraryType).indexOf(
        selectedLibraryType
      )}
      customSelectedTriggerContent={({ isOpen }) => (
        <OptionButtonContainer
          $active={false}
          style={{ width: '100%', height: 27 }}
        >
          <Text type="b1">{wording[selectedLibraryType]}</Text>
          <CaretDownIconRotatable $angle={isOpen ? 180 : 0} />
        </OptionButtonContainer>
      )}
    >
      <DefaultOptionComponent
        value={LibraryType.Workbench}
        label={wording[LibraryType.Workbench]}
      />
      <DefaultOptionComponent
        value={LibraryType.Personal}
        label={wording[LibraryType.Personal]}
      />
      <EnterpriseLibraryOption organization={organization} />
    </Select>
  );
};

const EnterpriseLibraryOption = ({
  organization,
}: {
  organization: NonNullable<UseOrganizationReturnData>;
}) => {
  const { subscriptionPlan, name } = organization;

  if (upperCase(subscriptionPlan) === OrganizationSubscriptionPlan.Enterprise) {
    return (
      <DefaultOptionComponent
        value={LibraryType.Organization}
        label={`${name} Library`}
      />
    );
  }

  return (
    <OptionComponentBase
      disabled={true}
      value={LibraryType.Organization}
      label="Workspace Library"
    >
      <InlineFlex $gap={2} style={{ width: 'fit-content', cursor: 'default' }}>
        <SelectedIndicator $active={true}>
          <LockIcon $color="disabled" />
        </SelectedIndicator>
        <Text color="bodyDisabled">Workspace Library</Text>
      </InlineFlex>
    </OptionComponentBase>
  );
};

import { useThree } from '@react-three/fiber';
import { AutoPromptType } from 'libs/shared/data-access/graphql/src/gql/graphql';
import {
  ChangeEventHandler,
  MouseEventHandler,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import styled, { useTheme } from 'styled-components';
import { Group } from 'three';
import { v4 as uuidv4 } from 'uuid';
import { WorkbenchElementAnimateData } from '@vizcom/shared/data-access/graphql';
import {
  Button,
  TextArea,
  TextInput,
  addToast,
} from '@vizcom/shared-ui-components';

import { useWorkbenchSyncedState } from '../../../lib/useWorkbenchSyncedState';
import {
  GENERATED_IMAGES_MARGIN,
  isDraggingContext,
  useIsWorkbenchViewer,
} from '../../../lib/utils';
import { WorkbenchElementExtra } from '../../WorkbenchElementExtra';
import { WorkbenchElementDragConnector } from '../../elementConnector/workbenchElementDragConnector';
import {
  getElementSize,
  getWorkbenchElementZPositionRange,
} from '../../helpers';
import {
  filterChildByWorkbenchElementUserData,
  findNearestParentObjectWithWorkbenchElementUserData,
} from '../../objectsUserdata';
import { promptContainsArtistName } from '../../studio/constants';
import { DescribeButton } from '../../studio/create/DescribeButton';
import { useAutoPrompt } from '../../studio/useAutoPrompt';
import { CustomHtml } from '../../utils/CustomHtml';
import { RoundedPlaneGeometry } from '../../utils/RoundedPlaneGeometry';
import { ZoomFallbackGroup } from '../../utils/ZoomFallbackGroup';
import { findFirstFreeSlotFromElements } from '../../utils/freeSlotFinders';
import { MIN_IMG_2_IMG_SIZE } from '../../utils/getContentSize';

interface WorkbenchElementAnimateProps {
  workbenchId: string;
  element: WorkbenchElementAnimateData;
  focused: boolean;
  singleFocused: boolean;
  isDragging: boolean;
  isResizing: boolean;
  showSingleFocusedControls: boolean;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
  getDrawingImageData: (drawingId: string) => Promise<ImageData | null>;
}

export const WorkbenchElementAnimate = ({
  workbenchId,
  element,
  singleFocused,
  isDragging,
  isResizing,
  showSingleFocusedControls,
  handleAction,
  getDrawingImageData,
}: WorkbenchElementAnimateProps) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const scene = useThree((s) => s.scene);
  const isDraggingRef = useContext(isDraggingContext);
  const theme = useTheme();
  const isViewer = useIsWorkbenchViewer();
  const [cursor, setCursor] = useState<[number | null, number | null] | null>(
    null
  );
  const ref = useRef<Group>(null);

  const { triggerAutoPrompt, cancelAutoPrompt, isGeneratingPrompt } =
    useAutoPrompt({
      workbenchPaletteId: null,
      inputType: AutoPromptType.Video,
      getCompositeImage: async () => {
        if (!element.sourceDrawingId) {
          return null;
        }

        return await getDrawingImageData(element.sourceDrawingId);
      },
      setPrompt: (text) => {
        handleAction({
          type: 'updateAnimate',
          elementId: element.id,
          prompt: text,
        });
      },
      getSelectionImage: () => undefined,
    });

  useLayoutEffect(() => {
    if (inputRef.current) {
      const cursor1 = cursor?.[0] ?? inputRef.current.selectionStart;
      const cursor2 = cursor?.[1] ?? inputRef.current.selectionEnd;

      [inputRef.current.selectionStart, inputRef.current.selectionEnd] = [
        cursor1,
        cursor2,
      ];

      inputRef.current.value = element.prompt;
    }
  }, [element.prompt, cursor]);

  const handlePromptChange: ChangeEventHandler<HTMLTextAreaElement> = ({
    target: { value, selectionStart, selectionEnd },
  }) => {
    setCursor([selectionStart, selectionEnd]);
    handleAction({
      type: 'updateAnimate',
      elementId: element.id,
      prompt: value,
    });
  };

  const handleSubmit: MouseEventHandler = (e) => {
    if (isDraggingRef.current) {
      return;
    }
    e.preventDefault();
    if (!element.sourceDrawingId) {
      addToast('You need to connect this block to an image first', {
        type: 'danger',
      });
      return;
    }

    if (promptContainsArtistName(element.prompt)) {
      return;
    }

    handleAction((elements) => {
      const target = elements.find((el) => el.id === element.sourceDrawingId);
      if (target?.__typename !== 'Drawing') {
        return;
      }
      const width = target.drawingWidth * target.workbenchSizeRatio;
      const height = target.drawingHeight * target.workbenchSizeRatio;

      const children = filterChildByWorkbenchElementUserData(scene, () => true);
      const els = children.map((object) => ({
        x: object.userData.elementX,
        y: object.userData.elementY,
        width: object.userData.elementWidth,
        height: object.userData.elementHeight,
      }));

      const opts = {
        firstSlotX:
          element.x + element.width / 2 + width / 2 + GENERATED_IMAGES_MARGIN,
        firstSlotY: element.y,
        slotWidth: width + GENERATED_IMAGES_MARGIN,
        slotHeight: height + GENERATED_IMAGES_MARGIN,
        maxElementPerLine: 4,
      };

      const zRange = getWorkbenchElementZPositionRange(scene);
      const pos = findFirstFreeSlotFromElements(els, opts);

      return {
        type: 'triggerAnimate',
        elementId: element.id,
        placeholder: {
          id: uuidv4(),
          x: pos[0],
          y: pos[1],
          width,
          height,
          zIndex: isFinite(zRange[1]) ? zRange[1] + 1 : element.zIndex + 1,
        },
      };
    });
  };

  const { width, height } = getElementSize(element);
  const contentScale = height / MIN_IMG_2_IMG_SIZE[1];

  const fallback = (
    <mesh position={[0, 0, 0]}>
      <RoundedPlaneGeometry width={width} height={height} radius={7.5} />
      <meshBasicMaterial color={theme.surface.primary} transparent />
    </mesh>
  );

  return (
    <>
      {showSingleFocusedControls && !isResizing && (
        <WorkbenchElementDragConnector
          element={element}
          handleAction={handleAction}
          triggerAutoPrompt={triggerAutoPrompt}
        />
      )}

      <ZoomFallbackGroup fallback={fallback}>
        <group ref={ref}>
          <CustomHtml
            transform
            occlude
            scaleOcclusionGeometry={false}
            scale={[contentScale, contentScale, 1]}
            parentScale={
              ref.current
                ? findNearestParentObjectWithWorkbenchElementUserData(
                    ref.current
                  )
                : undefined
            }
            geometry={
              <RoundedPlaneGeometry
                radius={16}
                width={width / contentScale}
                height={MIN_IMG_2_IMG_SIZE[1]}
              />
            }
          >
            <div
              style={{
                width: width / contentScale,
                height: MIN_IMG_2_IMG_SIZE[1],
                userSelect: 'none',
              }}
            >
              <Animate data-workbench-id={element.id}>
                <Title>
                  <div>
                    <span>Animate</span>
                    <BetaBadge>BETA</BetaBadge>
                  </div>

                  <DescribeButton
                    showButton={!!element.sourceDrawingId}
                    isGeneratingPrompt={isGeneratingPrompt}
                    triggerAutoPrompt={triggerAutoPrompt}
                    cancelAutoPrompt={cancelAutoPrompt}
                  />
                </Title>
                <AnimateTextArea
                  ref={inputRef}
                  defaultValue={element.prompt}
                  onChange={handlePromptChange}
                  // workbench-role is used to programatically focus this field when creating a new Animate element
                  data-workbench-role="promptInput"
                  onKeyDown={(e) => e.stopPropagation()}
                  // prevent using the cursor to select text from dragging the element itself, if already focused
                  onPointerMove={(e) => {
                    const target = e.target as HTMLTextAreaElement;
                    if (singleFocused && !isDragging) {
                      e.stopPropagation();
                    } else if (isDragging) {
                      target.blur();
                    }
                  }}
                  placeholder="What are you creating?"
                  disabled={isViewer}
                  $isDragging={isDragging}
                  onFocus={() => {
                    cancelAutoPrompt();
                  }}
                />
                <GenerateButton
                  onClick={(e) => {
                    if (!isDragging) {
                      handleSubmit(e);
                    }
                  }}
                  variant="secondary"
                  size="M"
                  $isDragging={isDragging}
                >
                  <span>Animate</span>
                </GenerateButton>
              </Animate>
            </div>
          </CustomHtml>
        </group>
      </ZoomFallbackGroup>

      {!isDragging && singleFocused && !isViewer && !isResizing && (
        <WorkbenchElementExtra
          workbenchId={workbenchId}
          element={element}
          handleAction={handleAction}
          position={[0, height / 2, 0]}
          pivot={element.y}
        />
      )}
    </>
  );
};

const Animate = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 16px;
  gap: 10px;
  background: ${({ theme }) => theme.surface.primary};
  border-radius: ${(p) => p.theme.borderRadius.m};
  color: ${({ theme }) => theme.text.body};
  cursor: move;

  ${TextInput} {
    flex: 1;
  }
`;

const AnimateTextArea = styled(TextArea)<{ $isDragging: boolean }>`
  cursor: ${(p) => (p.$isDragging ? 'move' : 'text')};
  height: 100%;
`;

const GenerateButton = styled(Button)<{ $isDragging: boolean }>`
  cursor: ${(p) => (p.$isDragging ? 'move' : 'pointer')};
  background: ${({ theme }) => theme.surface.pagePattern};
  color: ${({ theme }) => theme.surface.tertiary};

  &:hover {
    background: ${({ theme }) => theme.border.tertiary};
  }
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;

  > div {
    font-weight: 400;
  }
`;

const BetaBadge = styled.span`
  color: ${({ theme }) => theme.text.subtext};
  font-size: 8px;
  vertical-align: super;
  font-weight: 700;
  border-radius: 12px;
  letter-spacing: 0.8px;
  padding-left: 4px;
`;

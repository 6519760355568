import { useCallback, useRef } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import {
  enhanceImage,
  publishTrackingEvent,
} from '@vizcom/shared/data-access/graphql';
import {
  addToast,
  useSelectContext,
  imageUrlToImageData,
  dismissToast,
  Text,
  Button,
  ThumbsUpIcon,
  ThumbsDownIcon,
  imageToBlob,
  useIsFree,
  usePaywallModalState,
} from '@vizcom/shared-ui-components';

import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../../lib/useDrawingSyncedState';
import { AiAction } from './AiActions';

// Helper function to handle the enhancement process
const performEnhancement = async (
  drawingId: string,
  image: Blob | string,
  options = { isRetry: false }
) => {
  const res = await enhanceImage(drawingId, image, options);
  return await imageUrlToImageData(`data:image/png;base64,${res.image}`);
};

export const EnhanceAction = ({
  drawing,
  index,
  handleAction,
  getCompositedImage,
}: {
  drawing: Drawing2dStudio;
  index: number;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  getCompositedImage: (() => ImageData) | undefined;
}) => {
  const { handleSelect } = useSelectContext();
  const isFreePlan = useIsFree(drawing.id);
  const { trigger } = usePaywallModalState();

  // Create a ref for the handleEnhancement function to avoid the dependency cycle
  const handleEnhancementRef =
    useRef<(previousLayerId?: string, isRetry?: boolean) => Promise<void>>();

  const showEnhancementFeedback = useCallback(
    (layerId: string) => {
      const feedbackToastId = uuidv4();
      addToast(
        <EnhanceUserFeedback
          toastId={feedbackToastId}
          handleRetry={() =>
            handleEnhancementRef.current?.(layerId, true) || Promise.resolve()
          }
          drawingId={drawing.id}
        />,
        {
          id: feedbackToastId,
        }
      );
    },
    [drawing.id]
  );

  const handleEnhancement = useCallback(
    async (previousLayerId?: string, isRetry = false) => {
      const toastId = uuidv4();
      const toastMessage = isRetry
        ? 'Retry enhancing the canvas.'
        : 'Enhancing the canvas. The result will appear in the layers tab.';

      addToast(toastMessage, {
        id: toastId,
        type: 'loading',
      });

      if (previousLayerId) {
        handleAction({
          type: 'updateBulkLayers',
          deletedLayerIds: [previousLayerId],
        });
      }

      try {
        const imageData = await performEnhancement(
          drawing.id,
          getCompositedImage
            ? await imageToBlob(getCompositedImage())
            : (drawing.thumbnailPath as string),
          { isRetry }
        );

        const id = uuidv4();
        handleAction({
          type: 'addLayer',
          layer: {
            id,
            image: imageData,
            name: 'Enhanced Image',
            visible: true,
            opacity: 1,
            blendMode: 'normal',
            isGroup: false,
            fill: '',
            meshPath: null,
            parentId: null,
            placement: 'top',
          },
        });

        dismissToast(toastId);
        showEnhancementFeedback(id);
      } catch (error) {
        dismissToast(toastId);
        addToast('Enhancement failed. Please try again.', {
          duration: 3000,
          type: 'danger',
        });
      }
    },
    [
      drawing.id,
      drawing.thumbnailPath,
      getCompositedImage,
      handleAction,
      showEnhancementFeedback,
    ]
  );

  // Update the ref with the latest implementation
  // This ensures the ref always points to the most current version of the function
  // with all its dependencies up to date
  useRef(() => {
    handleEnhancementRef.current = handleEnhancement;
  }).current();

  const handleInitialEnhance = useCallback(() => {
    // if (isFreePlan) {
    //   trigger('freeToPro');
    //   return;
    // }

    handleSelect(index);
    handleEnhancement();
    // }, [handleSelect, index, handleEnhancement, isFreePlan, trigger]);
  }, [handleSelect, index, handleEnhancement]);

  return (
    <AiAction onClick={handleInitialEnhance}>
      Enhance
      {/* {isFreePlan && <UpgradeIcon />} */}
    </AiAction>
  );
};

const EnhanceUserFeedback = ({
  toastId,
  handleRetry,
  drawingId,
}: {
  toastId: string;
  handleRetry: () => Promise<void>;
  drawingId: string;
}) => {
  const handleFeedback = (feedback: 'approve' | 'retry') => {
    publishTrackingEvent({
      type: 'ENHANCE_FEEDBACK',
      data: { drawingId, feedback },
    });
    dismissToast(toastId);
    if (feedback === 'retry') handleRetry();
  };

  return (
    <FeedbackContainer>
      <Text type="sh2">Canvas enhanced</Text>
      <VerticalDivider />
      <IconContainer>
        <Button
          size="S"
          variant="primary"
          onClick={() => handleFeedback('approve')}
        >
          <ThumbsUpIcon /> Approve
        </Button>
        <Button
          size="S"
          variant="tertiary"
          onClick={() => handleFeedback('retry')}
        >
          <ThumbsDownIcon /> Retry
        </Button>
      </IconContainer>
      <VerticalDivider />
    </FeedbackContainer>
  );
};

const FeedbackContainer = styled.div`
  width: 100%;
  display: inline-flex;
  gap: 14px;
  align-items: center;
  justify-content: center;
`;

const VerticalDivider = styled.div`
  width: 1px;
  height: 30px;
  background: ${({ theme }) => theme.surface.tertiary};
`;

const IconContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spacing.s};
`;

import { useWorkbenchPointerGesture } from '../../utils/mapControls/pointerEvents';
import { useWorkbenchTouchGestures } from '../../utils/mapControls/touchEvents';
import { CameraLimits } from '../../utils/mapControls/utils';
import { useWorkbenchWheelGesture } from '../../utils/mapControls/wheelEvents';
import { useWorkbenchStudioPointerRotationGesture } from '../lib/studioPointerEvents';

export const WorkbenchStudioCameraGestures = ({
  cameraLimits,
}: {
  cameraLimits: CameraLimits;
}) => {
  useWorkbenchWheelGesture({
    getCameraLimits: () => cameraLimits,
  });
  useWorkbenchPointerGesture({
    getCameraLimits: () => cameraLimits,
  });
  useWorkbenchTouchGestures({
    getCameraLimits: () => cameraLimits,
    enableTwoFingerRotation: true,
  });

  useWorkbenchStudioPointerRotationGesture();

  return null;
};

import { useMemo, useState } from 'react';
import {
  BrushIcon,
  PaintBucketIcon,
  ToolbarButton,
  ToolbarButtonState,
} from '@vizcom/shared-ui-components';

import {
  useWorkbenchStudioState,
  WorkbenchTool,
  WorkbenchStudioToolType,
} from '../../studioState';
import { Hotkey } from '../../style';

interface BrushButtonProps {
  currentTool: WorkbenchStudioToolType;
  disabled?: boolean;
  onChangeTool: (tool: WorkbenchStudioToolType) => void;
}

export const BRUSH_TOOLS: WorkbenchTool[] = [
  {
    type: WorkbenchStudioToolType.Brush,
    icon: <BrushIcon />,
    tooltip: 'Brush',
    hotkey: 'B',
  },
  {
    type: WorkbenchStudioToolType.PaintBucket,
    icon: <PaintBucketIcon />,
    tooltip: 'Paint Bucket',
    hotkey: '',
  },
] as const;

export const BrushButton = ({
  currentTool,
  disabled,
  onChangeTool,
}: BrushButtonProps) => {
  const studioState = useWorkbenchStudioState();
  const isTooltipOpenState = useState(false);
  const selectedBrushTool = useMemo(
    () =>
      BRUSH_TOOLS.find(({ type }) => type === studioState.tool) ||
      BRUSH_TOOLS[0],
    [studioState.tool]
  );
  const state = useMemo(
    () =>
      disabled
        ? ToolbarButtonState.DISABLED
        : BRUSH_TOOLS.some((tool) => tool.type === currentTool)
        ? ToolbarButtonState.ACTIVE
        : ToolbarButtonState.INACTIVE,
    [currentTool, disabled]
  );

  return (
    <ToolbarButton
      icon={selectedBrushTool.icon}
      tooltip={
        disabled ? (
          'Select a 2D layer to use this tool'
        ) : (
          <>
            {selectedBrushTool.tooltip}{' '}
            <Hotkey>{selectedBrushTool.hotkey}</Hotkey>
          </>
        )
      }
      tooltipOptions={{
        trigger: state === ToolbarButtonState.ACTIVE ? 'none' : 'hover',
        controlledOpenState: isTooltipOpenState,
      }}
      state={state}
      onClick={() => {
        onChangeTool(selectedBrushTool.type);
        isTooltipOpenState[1](false);
      }}
    />
  );
};

import { Text } from '@vizcom/shared-ui-components';

interface BreadcrumbProps {
  breadcrumbs: React.ReactNode[];
  name: string;
}

export const Breadcrumbs = ({ breadcrumbs, name }: BreadcrumbProps) => {
  return (
    <div style={{ marginBottom: 20 }}>
      {breadcrumbs.map((breadcrumb, i) => (
        <Text color="subtext" type="b1" key={i}>
          {breadcrumb}
          <span>{' / '}</span>
        </Text>
      ))}
      <Text type="b1" color="body">
        {name}
      </Text>
    </div>
  );
};

import { useFrame, useThree } from '@react-three/fiber';
import { useRef, useState } from 'react';
import { WorkbenchElementSectionData } from '@vizcom/shared/data-access/graphql';

import { textColors } from '../../../constants';
import { useElementTranslation } from '../../../lib/useElementTranslation';
import { useWorkbenchSyncedState } from '../../../lib/useWorkbenchSyncedState';
import { WorkbenchElementExtra } from '../../WorkbenchElementExtra';
import {
  getElementObjectSize,
  getElementSize,
  getVisibleWorkbenchElements,
} from '../../helpers';
import {
  filterChildByWorkbenchObjectUserData,
  getBoundingBoxFromWorkbenchObjects,
  WorkbenchObjectObject3D,
} from '../../objectsUserdata';
import { DashedRoundedRectangle } from '../../utils/DashedRoundedRectangle';
import { RoundedPlaneGeometry } from '../../utils/RoundedPlaneGeometry';
import {
  findSnapGuides,
  getBestSnapXY,
  getSnapOffset,
  SnapGuide,
} from '../../utils/Snapping';
import { WorkbenchElementSectionContextMenuItems } from '../../workbenchContextMenu/contextMenuItemsPerType/SectionContextMenuItems';
import { WorkbenchElementColor } from '../WorkbenchElementColor';
import { SectionTitle } from './SectionTitle';
import { WorkbenchElementSectionAddImages } from './extra/WorkbenchElementSectionAddImages';
import { getSectionElements } from './helpers';

interface WorkbenchElementSectionProps {
  workbenchId: string;
  element: WorkbenchElementSectionData;
  isDragging: boolean;
  isResizing: boolean;
  singleFocused: boolean;
  elementIsActive: boolean;
  setIsDragging: (value: boolean) => void;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
  setEditingElementId: (id: string) => void;
  setSnapGuides: (guides: SnapGuide[]) => void;
}

export const SECTION_PADDING = 50;
export const SECTION_DEFAULT_COLOR = textColors[7];
export const WORKBENCH_ELEMENT_SECTION_PLACEHOLDER = 'Add section';

export const WorkbenchElementSection = ({
  workbenchId,
  element,
  isDragging,
  isResizing,
  elementIsActive,
  singleFocused,
  setIsDragging,
  handleAction,
  setEditingElementId,
  setSnapGuides,
}: WorkbenchElementSectionProps) => {
  const scene = useThree((state) => state.scene);
  const camera = useThree((state) => state.camera);
  const ref = useRef(null);
  const [{ width, height }, setSize] = useState(getElementSize(element));
  const [editingTitle, setEditingTitle] = useState(false);

  const color = element.color || SECTION_DEFAULT_COLOR;

  const handleChangeColor = (color: string) => {
    handleAction({
      type: 'updateSection',
      elementId: element.id,
      color,
    });
  };

  useFrame(() => {
    if (!ref.current) {
      return;
    }

    const newSize = getElementObjectSize(element, ref.current);
    if (newSize.width !== width || newSize.height !== height) {
      setSize(newSize);
    }
  });

  const bind = useElementTranslation({
    element,
    isEditing: false,
    elementIsActive,
    setIsDragging,
    setSnapGuides,
    handleLastDrag: (allObjectsToMove: WorkbenchObjectObject3D[]) => {
      setIsDragging(false);
      setSnapGuides([]);

      const workbenchElementsToMove = allObjectsToMove.filter(
        ({ userData }) =>
          userData.workbenchObjectType !== 'multi-focused-element-container'
      );

      handleAction({
        type: 'multiPosition',
        newElementData: workbenchElementsToMove.map((element) => ({
          id: element.userData.elementId,
          x: element.position.x,
          y: element.position.y,
        })),
      });
    },
    handleDragging: (
      allObjectsToMove: WorkbenchObjectObject3D[],
      delta: [number, number],
      snapElements: boolean
    ) => {
      let snapOffset = [0, 0];
      if (snapElements) {
        const visibleWorkbenchElements = getVisibleWorkbenchElements(
          camera,
          scene
        );
        const workbenchElementsToMove = allObjectsToMove.filter(
          ({ userData }) =>
            userData.workbenchObjectType !== 'multi-focused-element-container'
        );
        const boundingBox = getBoundingBoxFromWorkbenchObjects(
          workbenchElementsToMove
        );
        boundingBox.left += delta[0];
        boundingBox.right += delta[0];
        boundingBox.top += delta[1];
        boundingBox.bottom += delta[1];

        const { guides } = findSnapGuides(
          visibleWorkbenchElements
            .filter((el) => !workbenchElementsToMove.includes(el))
            .map((el) => ({
              x: el.userData.elementX,
              y: el.userData.elementY,
              width: el.userData.elementWidth,
              height: el.userData.elementHeight,
            })),
          boundingBox
        );
        setSnapGuides(guides);

        const boundingBoxCenterX = (boundingBox.left + boundingBox.right) / 2;
        const boundingBoxCenterY = (boundingBox.top + boundingBox.bottom) / 2;

        const { bestSnapX, bestSnapY } = getBestSnapXY(guides, {
          x: boundingBoxCenterX,
          y: boundingBoxCenterY,
        });
        snapOffset = getSnapOffset(bestSnapX, bestSnapY, boundingBox);
      } else {
        setSnapGuides([]);
      }

      allObjectsToMove.forEach((child) => {
        child.position.set(
          child.userData.elementX + delta[0] + snapOffset[0],
          child.userData.elementY + delta[1] + snapOffset[1],
          0
        );
      });
    },
    setEditingElementId,
    getObjectsToMove: (focusedElementsId) => {
      const otherFocusedElements = filterChildByWorkbenchObjectUserData(
        scene,
        (userData) =>
          userData.workbenchObjectType !== 'multi-focused-element-container' &&
          focusedElementsId.includes(userData.elementId)
      );

      const otherSectionElements = otherFocusedElements.filter(
        (el) => el.userData.elementTypename === 'WorkbenchElementSection'
      );

      const sectionChildrenIds = [
        ...otherSectionElements.flatMap((section) =>
          getSectionElements(scene, {
            __typename: 'WorkbenchElementSection',
            id: section.userData.elementId,
            height: section.userData.elementHeight,
            width: section.userData.elementWidth,
            x: section.userData.elementX,
            y: section.userData.elementY,
            zIndex: section.userData.elementZIndex,
            title: '',
            color: '',
            updatedAt: '',
          })
        ),
        ...getSectionElements(scene, element),
      ].map((el) => el.userData.elementId);

      return filterChildByWorkbenchObjectUserData(
        scene,
        (userData) =>
          userData.workbenchObjectType === 'multi-focused-element-container' ||
          sectionChildrenIds.includes(userData.elementId) ||
          userData.elementId === element.id ||
          focusedElementsId.includes(userData.elementId)
      );
    },
  });

  const fallback = (
    <>
      <mesh
        ref={ref}
        userData={{
          vizcomRenderingOrder: [
            {
              zIndex: 1,
            },
          ],
        }}
      >
        <RoundedPlaneGeometry width={width} height={height} radius={6} />
        <meshBasicMaterial color={color} opacity={0.2} transparent />
      </mesh>
      <mesh>
        <RoundedPlaneGeometry width={width} height={height} radius={6} />
        <meshBasicMaterial color="#FFF" transparent />
      </mesh>
      <DashedRoundedRectangle
        width={width}
        height={height}
        radius={6}
        color={color}
        lineWidth={3}
        gapSize={0}
      />
    </>
  );

  return (
    <>
      <group {...(bind() as any)}>
        <SectionTitle
          width={width}
          height={height}
          id={element.id}
          title={element.title}
          handleAction={handleAction}
          editingTitle={editingTitle}
          setEditingTitle={setEditingTitle}
          color={color}
        />
        {fallback}
      </group>
      {!isDragging && !isResizing && singleFocused && (
        <WorkbenchElementExtra
          workbenchId={workbenchId}
          element={element}
          handleAction={handleAction}
          position={[0, height / 2 + 14, 0]}
          pivot={element.y}
          menuItems={
            <WorkbenchElementSectionContextMenuItems
              element={element}
              handleAction={handleAction}
            />
          }
        >
          <WorkbenchElementColor
            color={element.color}
            handleChangeColor={handleChangeColor}
          />
          <WorkbenchElementSectionAddImages
            sectionData={element}
            handleAction={handleAction}
          />
        </WorkbenchElementExtra>
      )}
    </>
  );
};

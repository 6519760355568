import { StudioEventName } from 'libs/shared/data-shape/src/lib/tracking/StudioTrackingEventSchema';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { v4 as uuid } from 'uuid';
import {
  publishTrackingEvent,
  ImageTo3dQualityType,
  useCurrentUserClientStateByKey,
  UserClientStateKeys,
} from '@vizcom/shared/data-access/graphql';
import {
  assertExists,
  filterExists,
  getLayerOrderKeys,
} from '@vizcom/shared/js-utils';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import {
  MenuDivider,
  downloadFile,
  imageUrlToBlob,
  imageDataToBlob,
  addToast,
  imageToBlob,
  usePaywallModalState,
  MenuItem,
  CMD_KEY_PREFIX,
  SHIFT_KEY,
  FeatureFlagged,
  Text,
  useIsFree,
  UpgradeIcon,
  InlineFlex,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

import {
  copyImageToClipboard,
  duplicateSelectedLayers,
} from '../../lib/drawingUtils';
import {
  Drawing2dStudio,
  DrawingLayer,
  useDrawingSyncedState,
} from '../../lib/useDrawingSyncedState';
import { useIsWorkbenchViewer } from '../../lib/utils';
import { Layer3dMeshExportMenu } from './Layer3dMeshExportMenu';
import { useLayersCompositor } from './LayersCompositor/context';
import { useScreenShareContext } from './ScreenShareContext';
import { ActiveLayerChangeOperation } from './lib/useActiveLayer';
import { exportLayersAsZip } from './lib/useExportUtils';
import { useSelectionApiStore } from './selection/useSelectionApi';
import { Hotkey } from './style';
import { Layout } from './types';
import {
  addGroup,
  cutSelection,
  getGroupChildLayers,
  getHierarchicalParentIds,
  getSelectedVisibleLayers,
  horizontalMirrorLayers,
  mergeDown,
  mergeSelected,
  moveLayersToBottom,
  moveLayersToTop,
  verticalMirrorLayers,
} from './utils';

export const LayerContextMenu = ({
  drawing,
  layer,
  isLast,
  nextLayerIsGroup,
  activeLayer,
  setViewerVisibility,
  setActiveLayer,
  handleAction,
  onCreateDrawingsFromImage,
  setIsEditingName,
  setShowContextMenu,
  handleScreenShareFromMenu,
}: {
  drawing: Drawing2dStudio;
  layer: DrawingLayer;
  isLast: boolean;
  nextLayerIsGroup?: boolean;
  horizontalMargin?: number;
  setViewerVisibility: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  activeLayer: string | undefined;
  setActiveLayer: (
    id: string | undefined,
    operation?: ActiveLayerChangeOperation
  ) => void;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  onCreateDrawingsFromImage: (
    previews: { preview: ArrayBuffer | Blob; name?: string }[],
    offset?: {
      x: number;
      y: number;
    }
  ) => void;
  setIsEditingName: React.Dispatch<React.SetStateAction<boolean>>;
  setShowContextMenu: React.Dispatch<React.SetStateAction<boolean>>;
  handleScreenShareFromMenu?: () => void;
}) => {
  const layersCompositor = useLayersCompositor();
  const isViewer = useIsWorkbenchViewer();
  const selectionApiStore = useSelectionApiStore();
  const navigate = useNavigate();
  const theme = useTheme();
  const layout: Layout =
    useCurrentUserClientStateByKey(UserClientStateKeys.StudioLayout) ||
    'default';
  const id = layer.id;
  const isFreePlan = useIsFree(drawing.id);

  const { activeLayerId: globalActiveShareLayerId } = useScreenShareContext();

  const validMultiviewLayers = getSelectedVisibleLayers(
    drawing,
    activeLayer?.split('/') || []
  );
  const validChildMultiviewLayers = getGroupChildLayers(drawing, id).filter(
    (l) => l.visible && l.imagePath
  );

  const exportLayer = async () => {
    if (layer.imagePath) {
      downloadFile(
        await imageToBlob(layer.imagePath, {
          convertToContentType: 'image/png',
        }),
        layer.name,
        'png'
      );
      trackEvent('Image Export', { type: 'exportLayersImage' });
      publishTrackingEvent({
        type: StudioEventName.EXPORT,
        data: {
          exportType: 'PNG',
        },
      });
    }
  };

  const renameLayer: React.MouseEventHandler = () => {
    setIsEditingName(true);
  };

  const removeLayer = () => {
    if (activeLayer && activeLayer.split('/').includes(id)) {
      handleAction({
        type: 'updateBulkLayers',
        deletedLayerIds: activeLayer.split('/'),
      });
    } else {
      handleAction({
        type: 'updateBulkLayers',
        deletedLayerIds: [layer.id],
      });
    }
  };

  const duplicateLayer = async (id: string) => {
    const newLayer = {
      ...layer,
      id: uuid(),
      name: `${layer.name} copy`,
      image: layer.imagePath,
      orderKey: getLayerOrderKeys(drawing.layers.nodes, id)[0],
    };
    handleAction({
      type: 'addLayer',
      layer: newLayer,
    });
  };

  const duplicateMultipleLayers = () => {
    const selectedLayers = activeLayer?.split('/');
    if (!selectedLayers) return;
    duplicateSelectedLayers(selectedLayers, drawing, handleAction);
  };

  const exportSelectedLayers = async () => {
    const selectedLayers = activeLayer?.split('/');
    if (!selectedLayers) return;

    let layers = selectedLayers
      .map((id) => drawing.layers.nodes.find((l) => l.id === id))
      .filter(filterExists);

    const groupChildLayers = layers.reduce((acc, layer) => {
      if (layer.isGroup) {
        acc.push(...getGroupChildLayers(drawing, layer.id));
      }
      return acc;
    }, [] as Drawing2dStudio['layers']['nodes']);

    layers = [...new Set([...layers, ...groupChildLayers])];

    if (isFreePlan) {
      const filteredLayers = layers.filter(
        (layer) => !layer.meshPath && !layer.metadata3D?.mesh
      );

      if (filteredLayers.length !== layers.length) {
        addToast(
          'Some layers are 3D models and cannot be exported in the free plan',
          {
            type: 'warning',
            cta: {
              text: 'Upgrade now',
              action: () => {
                assertExists(drawing.workbench?.folder?.organization?.id);
                navigate(
                  paths.settings.organization.subscription(
                    drawing.workbench?.folder?.organization?.id
                  )
                );
              },
            },
          }
        );
        layers = filteredLayers;
      }
    }

    await exportLayersAsZip(layers, drawing.name || 'export-vizcom-layers');
  };

  const exportGroup = async () => {
    const groupChildLayers = getGroupChildLayers(drawing, id);
    await exportLayersAsZip(groupChildLayers, layer.name);
  };

  const handleGenerateMeshFromLayer = () => {
    if (!layer.imagePath) {
      return addToast(
        'Your layer is not ready to be converted to a 3D model. Please add an image to your layer first.',
        {
          type: 'danger',
        }
      );
    }

    const id = uuid();
    handleAction({
      type: 'createLayer3dFromDrawing',
      id,
      name: `${layer.name} - 3D`,
      qualityType: ImageTo3dQualityType.Basic,
      sourceImages: [layer.imagePath],
    });
    setActiveLayer(id);
  };

  const handleDuplicateMultipleInWorkbench = async () => {
    const selectedLayers = activeLayer?.split('/');
    if (!selectedLayers) return;

    const sourceImagesAndNames = await Promise.all(
      selectedLayers.map(async (id) => {
        const selectedLayer = drawing.layers.nodes.find((l) => l.id === id);
        if (!selectedLayer?.imagePath) {
          return null;
        }

        const imageBlob =
          selectedLayer.imagePath instanceof ImageData
            ? await imageDataToBlob(selectedLayer.imagePath)
            : typeof selectedLayer.imagePath === 'string'
            ? await imageUrlToBlob(selectedLayer.imagePath)
            : selectedLayer.imagePath;

        return {
          image: imageBlob,
          name: selectedLayer.name,
        };
      })
    );

    onCreateDrawingsFromImage(
      sourceImagesAndNames.filter(filterExists).map(({ image, name }) => ({
        preview: image,
        name,
      }))
    );

    addToast('These layers have been duplicated in workbench');
  };

  const handleDuplicateInWorkbench = async () => {
    if (!layer.imagePath) {
      return addToast(
        `This layer cannot be duplicated in workbench because it's empty`
      );
    }

    const sourceImage =
      layer.imagePath instanceof ImageData
        ? await imageDataToBlob(layer.imagePath)
        : typeof layer.imagePath === 'string'
        ? await imageUrlToBlob(layer.imagePath)
        : layer.imagePath;
    onCreateDrawingsFromImage(
      [{ preview: sourceImage, name: layer.name }],
      undefined
    );
    addToast('This layer has been duplicated in workbench');
  };

  const handleCopyRawLayerImage = () => {
    addToast(
      layer.imagePath ? 'Layer copied to clipboard' : 'This layer is empty'
    );
    copyImageToClipboard(
      layer.imagePath,
      selectionApiStore.getState().getSelectionImage()
    );
  };

  const handleCutRawLayerImage = () => {
    if (!layer.imagePath) {
      addToast('This layer is empty');
      return;
    }

    const selectionData = selectionApiStore.getState().getSelectionImage();
    if (selectionData) {
      cutSelection(layer, selectionData, handleAction);
    }
  };

  const toggleLayerVisibility = () => {
    handleAction({
      type: 'updateLayer',
      id,
      data: {
        visible: !layer.visible,
      },
    });
  };

  const toggleMultipleLayerVisibility = () => {
    const layerUpdates = activeLayer
      ?.split('/')
      .map((id) => {
        const layer = drawing.layers.nodes.find((l) => l.id === id);
        return layer
          ? {
              id: layer.id,
              visible: !layer.visible,
            }
          : null;
      })
      .filter(filterExists);

    if (!layerUpdates || layerUpdates.length === 0) return;

    handleAction({
      type: 'updateBulkLayers',
      layerUpdates,
    });
  };

  const toggleIsolateLayerVisibility = () => {
    const selectedLayerIds = activeLayer?.split('/');
    if (!selectedLayerIds) return;

    const hierarchicalParents = getHierarchicalParentIds(
      selectedLayerIds,
      drawing.layers.nodes
    );

    const layers = drawing.layers.nodes.filter(
      (l) => ![...selectedLayerIds, ...hierarchicalParents]?.includes(l.id)
    );

    const moreVisible =
      layers.filter((l) => l.visible).length >
      layers.filter((l) => !l.visible).length;

    handleAction({
      type: 'updateBulkLayers',
      layerUpdates: layers.map((l) => ({
        id: l.id,
        visible: !moreVisible,
      })),
    });
  };

  const handleHorizontalMirrorLayers = () => {
    horizontalMirrorLayers(drawing, activeLayer, handleAction);
  };

  const handleVerticalMirrorLayers = () => {
    verticalMirrorLayers(drawing, activeLayer, handleAction);
  };

  const ViewerContextMenu = (
    <>
      <MenuItem
        label="Show/hide layer"
        onClick={() => {
          setViewerVisibility((prev) => ({
            ...prev,
            [id]: prev[id] !== undefined ? !prev[id] : !layer.visible,
          }));
        }}
      />
      <MenuItem
        label="Export"
        onClick={() => {
          exportLayer();
        }}
      />
    </>
  );

  const LayerContextMenu = (
    <>
      <MenuItem label="Rename" onClick={renameLayer} />
      <MenuItem
        label={!layer.metadata3D ? 'Copy' : 'Copy image'}
        onClick={handleCopyRawLayerImage}
        appendLabel={<Hotkey>{CMD_KEY_PREFIX}C</Hotkey>}
      />
      <MenuItem
        label={!layer.metadata3D ? 'Cut' : 'Cut image'}
        onClick={handleCutRawLayerImage}
        appendLabel={<Hotkey>{CMD_KEY_PREFIX}X</Hotkey>}
        disabled={!selectionApiStore.getState().hasMask}
      />
      <MenuItem
        label="Duplicate"
        onClick={() => {
          duplicateLayer(id);
        }}
      />
      {!layer.metadata3D && (
        <MenuItem
          label="Duplicate in workbench"
          onClick={handleDuplicateInWorkbench}
        />
      )}
      {!isLast && !nextLayerIsGroup && !layer.metadata3D && (
        <MenuItem
          label="Merge down"
          onClick={async () => {
            await mergeDown(drawing, layer.id, handleAction, layersCompositor);
          }}
          appendLabel={
            <Hotkey>
              {CMD_KEY_PREFIX}
              {SHIFT_KEY}H
            </Hotkey>
          }
        />
      )}
      <MenuItem
        label="Create group with layer"
        onClick={() => {
          const id = addGroup({
            drawing,
            activeLayerId: layer.id,
            handleAction,
          });
          if (id) setActiveLayer(id);
        }}
        appendLabel={<Hotkey>{CMD_KEY_PREFIX}G</Hotkey>}
      />
      <MenuDivider />
      {!layer.metadata3D && (
        <FeatureFlagged flag="LIVE_LAYER_SHARING">
          {handleScreenShareFromMenu && (
            <>
              <MenuItem
                label="Start Live Layer"
                onClick={() => {
                  handleScreenShareFromMenu();
                  setShowContextMenu(false);
                }}
                // Disable if there's an active share on a different layer
                disabled={
                  globalActiveShareLayerId !== null &&
                  globalActiveShareLayerId !== layer.id
                }
              />
              <MenuDivider />
            </>
          )}
        </FeatureFlagged>
      )}
      {!layer.metadata3D && (
        <>
          <MenuItem
            label="Horizontal Mirror"
            onClick={() => {
              handleHorizontalMirrorLayers();
            }}
            appendLabel={<Hotkey>{SHIFT_KEY}H</Hotkey>}
          />
          <MenuItem
            label="Vertical Mirror"
            onClick={() => {
              handleVerticalMirrorLayers();
            }}
            appendLabel={<Hotkey>{SHIFT_KEY}V</Hotkey>}
          />
          <MenuDivider />
        </>
      )}
      <MenuItem
        label="Send to front"
        onClick={() => moveLayersToTop(activeLayer, drawing, handleAction)}
      />
      <MenuItem
        label="Send to back"
        onClick={() => moveLayersToBottom(activeLayer, drawing, handleAction)}
      />
      <MenuDivider />
      <MenuItem label="Show/hide" onClick={toggleLayerVisibility} />
      <MenuItem
        label="Show/hide all other layers"
        onClick={toggleIsolateLayerVisibility}
      />
      <MenuDivider />
      {!layer.metadata3D && (
        <>
          <MenuItem label="Generate 3D" onClick={handleGenerateMeshFromLayer} />
          <MenuDivider />
        </>
      )}
      {layer.metadata3D ? (
        <MenuItem
          hideArrow
          offset={10}
          placement={layout === 'default' ? 'right-start' : 'left-start'}
          floatingStyleOverrides={{
            minWidth: '120px',
          }}
          label={
            <LabelWrapper>Export {isFreePlan && <UpgradeIcon />}</LabelWrapper>
          }
          onClick={() => {
            if (isFreePlan) {
              usePaywallModalState.getState().trigger('freeToPro');
            }
          }}
        >
          {!isFreePlan && (
            <Layer3dMeshExportMenu
              onExportEnded={() => setShowContextMenu(false)}
              layer={layer}
              isFreePlan={isFreePlan}
            />
          )}
        </MenuItem>
      ) : (
        <MenuItem label="Export" onClick={exportLayer} />
      )}
      <MenuItem
        label={<span style={{ color: theme.surface.danger }}>Delete</span>}
        onClick={() => {
          removeLayer();
        }}
        appendLabel={<Hotkey>Del</Hotkey>}
      />
    </>
  );

  const MultiSelectionContextMenu = (
    <>
      <MenuItem label="Duplicate" onClick={duplicateMultipleLayers} />
      {!layer.metadata3D && (
        <MenuItem
          label="Duplicate in workbench"
          onClick={handleDuplicateMultipleInWorkbench}
        />
      )}

      <MenuDivider />

      {!layer.metadata3D && (
        <>
          <MenuItem
            label="Horizontal Mirror"
            onClick={() => {
              handleHorizontalMirrorLayers();
            }}
            appendLabel={<Hotkey>{SHIFT_KEY}H</Hotkey>}
          />
          <MenuItem
            label="Vertical Mirror"
            onClick={() => {
              handleVerticalMirrorLayers();
            }}
            appendLabel={<Hotkey>{SHIFT_KEY}V</Hotkey>}
          />
        </>
      )}

      <MenuDivider />

      <MenuItem
        label="Group selected"
        onClick={() => {
          const id = addGroup({
            drawing,
            activeLayerId: activeLayer,
            handleAction,
          });
          if (id) setActiveLayer(id);
        }}
        appendLabel={<Hotkey>{CMD_KEY_PREFIX}G</Hotkey>}
      />
      <MenuDivider />

      <MenuItem
        label="Merge"
        onClick={async () => {
          await mergeSelected(
            drawing,
            activeLayer,
            layersCompositor,
            handleAction
          );
        }}
      />

      <MenuDivider />

      <MenuItem
        label="Send to front"
        onClick={() => moveLayersToTop(activeLayer, drawing, handleAction)}
      />
      <MenuItem
        label="Send to back"
        onClick={() => moveLayersToBottom(activeLayer, drawing, handleAction)}
      />

      <MenuDivider />

      <MenuItem label="Show/hide" onClick={toggleMultipleLayerVisibility} />
      <MenuItem
        label="Show/hide all other layers"
        onClick={toggleIsolateLayerVisibility}
      />

      <MenuDivider />

      <>
        <MenuItem
          label="Generate 3D from multiple views"
          disabled={
            validMultiviewLayers.length < 2 || validMultiviewLayers.length > 5
          }
          appendLabel={
            validMultiviewLayers.length < 2 ||
            validMultiviewLayers.length > 5 ? (
              <Text color="dangerLink">2-5 layers</Text>
            ) : (
              ''
            )
          }
          onClick={() => {
            const id = uuid();
            handleAction({
              type: 'createLayer3dFromDrawing',
              id,
              name: `${layer.name} - 3D Multiview`,
              qualityType: ImageTo3dQualityType.MultiView_1,
              sourceImages: validMultiviewLayers.map((l) => l.imagePath),
            });
            setActiveLayer(id);
          }}
        />
        <MenuDivider />
      </>

      <MenuItem
        label="Export"
        onClick={exportSelectedLayers}
        appendLabel={<Hotkey>.zip</Hotkey>}
      />
      <MenuItem
        label={<span style={{ color: theme.surface.danger }}>Delete</span>}
        onClick={removeLayer}
        appendLabel={<Hotkey>Del</Hotkey>}
      />
    </>
  );

  const GroupContextMenu = (
    <>
      <MenuItem label="Rename" onClick={renameLayer} />
      <MenuItem
        label="Duplicate"
        onClick={() => {
          duplicateMultipleLayers();
        }}
      />
      <MenuDivider />
      <MenuItem
        label="Horizontal Mirror"
        onClick={() => {
          handleHorizontalMirrorLayers();
        }}
        appendLabel={<Hotkey>{SHIFT_KEY}H</Hotkey>}
      />
      <MenuItem
        label="Vertical Mirror"
        onClick={() => {
          handleVerticalMirrorLayers();
        }}
        appendLabel={<Hotkey>{SHIFT_KEY}V</Hotkey>}
      />
      <MenuDivider />
      <MenuItem
        label="Send to front"
        onClick={() => moveLayersToTop(activeLayer, drawing, handleAction)}
      />
      <MenuItem
        label="Send to back"
        onClick={() => moveLayersToBottom(activeLayer, drawing, handleAction)}
      />
      <MenuDivider />
      <MenuItem label="Show/hide" onClick={toggleLayerVisibility} />
      <MenuItem
        label="Show/hide all other layers"
        onClick={toggleIsolateLayerVisibility}
      />
      <MenuDivider />
      <>
        <MenuItem
          label="Generate 3D from multiple views"
          disabled={
            validChildMultiviewLayers.length < 2 ||
            validChildMultiviewLayers.length > 5
          }
          appendLabel={
            validChildMultiviewLayers.length < 2 ||
            validChildMultiviewLayers.length > 5 ? (
              <Text color="dangerLink">2-5 layers</Text>
            ) : (
              ''
            )
          }
          onClick={() => {
            const id = uuid();
            handleAction({
              type: 'createLayer3dFromDrawing',
              id,
              name: `${layer.name} - 3D Multiview`,
              qualityType: ImageTo3dQualityType.MultiView_1,
              sourceImages: validChildMultiviewLayers.map((l) => l.imagePath),
            });
            setActiveLayer(id);
          }}
        />
        <MenuDivider />
      </>
      <MenuItem
        label={'Export'}
        onClick={() => {
          exportGroup();
        }}
        appendLabel={<Hotkey>.zip</Hotkey>}
      />
      <MenuItem
        label={<span style={{ color: theme.surface.danger }}>Delete</span>}
        onClick={() => {
          removeLayer();
        }}
        appendLabel={<Hotkey>Del</Hotkey>}
      />
    </>
  );

  const getTooltipMenuContent = () => {
    if (isViewer) {
      return ViewerContextMenu;
    } else if (activeLayer !== id && activeLayer?.split('/').includes(id)) {
      return MultiSelectionContextMenu;
    } else if (layer.isGroup) {
      return GroupContextMenu;
    } else {
      return LayerContextMenu;
    }
  };

  return (
    <div
      style={{
        all: 'inherit',
        display: 'contents',
      }}
      onPointerDown={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      {getTooltipMenuContent()}
    </div>
  );
};

const LabelWrapper = styled(InlineFlex)`
  align-items: center;
  gap: 8px;
`;

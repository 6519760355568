import { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  UserClientStateKeys,
  useCurrentUserClientStateByKey,
  useSetCurrentUserClientState,
} from '@vizcom/shared/data-access/graphql';
import {
  Button,
  DotsPagination,
  LeftPanelBody,
  LeftPanelContainer,
  MultiStepsModal,
  PanelDescription,
  PanelTitle,
  RightPanelContainer,
  SplitContainer,
  SplitedModalView,
  useMultiStepsModalContext,
} from '@vizcom/shared-ui-components';

import videoStep02 from '../../assets/videos/connect-to-create.mp4';
import videoStep01 from '../../assets/videos/vizcom-canvas.mp4';
import videoStep03 from '../../assets/videos/where-do-i-sketch.mp4';

const youtubePlaylistUrl =
  'https://www.youtube.com/watch?v=IHIV3w9DSik&list=PLVHis7W7S2-gGyKcjOeER0TQ99gzkgVQ8';

const getYoutubeThumbnail = (videoId: string) =>
  `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;

/**
 * CustomFinalStep
 */
const PlaylistContainer = styled.div`
  display: grid;
  align-content: center;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  padding: 30px;
  height: 100%;
`;

const PlaylistItem = styled.a`
  border-radius: ${({ theme }) => theme.borderRadius.m};
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  color: #242425;
  font-weight: 700;

  p {
    padding: 0px 8px 5px 10px;
  }

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.surface.page};
    transition: 0.3s;
  }
`;

const CustomFinalStep = ({
  onButtonClicked,
}: {
  onButtonClicked: () => void;
}) => {
  const { changeStep } = useMultiStepsModalContext();

  const playlistVideos = [
    {
      title: 'Introducing Vizcom 2D Studio',
      thumbnail: getYoutubeThumbnail('qYax7QPVZVw'),
      url: 'https://www.youtube.com/watch?v=qYax7QPVZVw',
    },
    {
      title: 'Vizcom: Main Features Overview',
      thumbnail: getYoutubeThumbnail('tAMp3IrwDwo'),
      url: 'https://www.youtube.com/watch?v=tAMp3IrwDwo',
    },
    {
      title: 'Vizcom Workbench Beta: A Quick Start Guide',
      thumbnail: getYoutubeThumbnail('TUbWIMnNVqA'),
      url: 'https://www.youtube.com/watch?v=TUbWIMnNVqA',
    },
    {
      title: 'How to control specific parts of a rendering',
      thumbnail: getYoutubeThumbnail('KlcrlXMGsJY'),
      url: 'https://www.youtube.com/watch?v=KlcrlXMGsJY',
    },
  ];

  return (
    <SplitContainer>
      {/* LEFT */}
      <LeftPanelContainer>
        <LeftPanelBody>
          <PanelTitle>Want to learn more?</PanelTitle>
          <PanelDescription>
            Check out this{' '}
            <a href={youtubePlaylistUrl} target="_blank">
              Youtube Playlist
            </a>{' '}
            with tutorials to get started using Vizcom. If you’re ready, let’s
            jump into the tool.
          </PanelDescription>
          <Button variant="primary" $fullWidth onClick={onButtonClicked}>
            Get started
          </Button>
        </LeftPanelBody>

        <DotsPagination
          currentStepIndex={3}
          changeStep={changeStep}
          stepsCount={4}
        />
      </LeftPanelContainer>

      {/* RIGHT */}
      <RightPanelContainer
        style={{
          backgroundColor: '#FFF',
        }}
      >
        <PlaylistContainer>
          {playlistVideos.map((video, index) => (
            <PlaylistItem href={video.url} target="_blank" key={index}>
              <img src={video.thumbnail} />
              <p>{video.title}</p>
            </PlaylistItem>
          ))}
        </PlaylistContainer>
      </RightPanelContainer>
    </SplitContainer>
  );
};
/** End of CustomFinalStep **/

export const WorkbenchOnboardingModal = () => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [onboardingModalOpen, setOnboardingModalOpen] = useState(false);
  const onboardingStatus =
    useCurrentUserClientStateByKey(UserClientStateKeys.OnboardingStatus) || {};
  const [, updateState] = useSetCurrentUserClientState();

  const workbenchModalStatus = onboardingStatus['v_1_1_workbench'];

  useEffect(() => {
    setOnboardingModalOpen(!workbenchModalStatus);
    setCurrentStepIndex(0);
  }, [workbenchModalStatus]);

  const setIsOpen = async (value: boolean) => {
    if (value) return setOnboardingModalOpen(true);

    setOnboardingModalOpen(false);

    await updateState({
      input: {
        key: UserClientStateKeys.OnboardingStatus,
        value: {
          ...onboardingStatus,
          v_1_1_workbench: true,
        },
      },
    });
  };

  return (
    <MultiStepsModal
      isOpen={onboardingModalOpen}
      setIsOpen={setIsOpen}
      currentStepIndex={currentStepIndex}
      setCurrentStepIndex={setCurrentStepIndex}
    >
      <SplitedModalView
        position={0}
        title="Welcome to Workbench"
        description="Your generations now live in this infinite canvas environment. This allows you to collaborate to generate new ideas."
        video={videoStep01}
      />

      <SplitedModalView
        position={1}
        title="Connect to create"
        description="Add images to the canvas through blocks. Link them to prompt blocks to generate content."
        video={videoStep02}
      />

      <SplitedModalView
        position={2}
        title="“Where do I sketch?”"
        description="Double click on an image to begin sketching. Click exit on the toolbar or double click off the image to return to workbench."
        video={videoStep03}
      />

      <CustomFinalStep onButtonClicked={() => setIsOpen(false)} />
    </MultiStepsModal>
  );
};

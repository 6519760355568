import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';

export enum WorkbenchToolType {
  SELECT = 'select',
  SECTION = 'section',
  TEXT = 'text',
  ASSET_LIBRARY = 'asset_library',
}

type WorkbenchToolContextValue = {
  tool: WorkbenchToolType;
  setTool: Dispatch<SetStateAction<WorkbenchToolType>>;
  toolsDisabled: boolean;
  setToolsDisabled: Dispatch<SetStateAction<boolean>>;
};

export const workbenchToolContext = createContext<
  WorkbenchToolContextValue | undefined
>(undefined);

export const WorkbenchToolProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [tool, setTool] = useState<WorkbenchToolType>(WorkbenchToolType.SELECT);
  const [toolsDisabled, setToolsDisabled] = useState(false);

  return (
    <workbenchToolContext.Provider
      value={{ tool, setTool, toolsDisabled, setToolsDisabled }}
    >
      {children}
    </workbenchToolContext.Provider>
  );
};

export const useWorkbenchToolState = () => {
  const state = useContext(workbenchToolContext);

  if (!state) {
    throw new Error('useWorkbenchToolState must be used within a Workbench');
  }
  return state;
};

import {
  ChangeEventHandler,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { TextArea } from '@vizcom/shared-ui-components';

type PromptInputProps = {
  placeholder?: string;
  value: string;
  disabled?: boolean;
  variant?: 'primary' | 'secondary' | 'tertiary';
  onChange: (value: string) => void;
  onEnter?: () => void;
  onClick?: () => void;
  onFocus?: () => void;
};

export const PromptInput = ({
  placeholder,
  value,
  disabled,
  variant,
  onChange,
  onEnter,
  onClick,
  onFocus,
}: PromptInputProps) => {
  const ref = useRef<HTMLTextAreaElement>(null);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (value !== ref.current?.value && !focused) {
      ref.current!.value = value;
    }
  }, [value]);

  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback(
    ({ target: { value } }) => {
      onChange(value);
    },
    [onChange]
  );
  const handleKeydown: KeyboardEventHandler<HTMLTextAreaElement> = useCallback(
    (e) => {
      e.stopPropagation();
      if (onEnter && !e.shiftKey && !e.repeat && e.code === 'Enter') {
        e.preventDefault();
        onEnter();
      }
    },
    [onEnter]
  );
  return (
    <TextArea
      ref={ref}
      disabled={disabled}
      spellCheck={false}
      placeholder={placeholder}
      onChange={handleChange}
      onKeyDown={handleKeydown}
      onFocus={() => {
        onFocus?.();
        setFocused(true);
      }}
      onBlur={() => setFocused(false)}
      onClick={onClick}
      $height="90px"
    />
  );
};

import {
  urqlClient,
  UpdateLayerMutation,
  DeleteLayerMutation,
  UpdateDrawingLayersMutation,
} from '@vizcom/shared/data-access/graphql';
import { imageDataToBlob } from '@vizcom/shared-ui-components';

import {
  SyncedActionPayloadFromType,
  SyncedActionType,
} from '../../SyncedAction';
import { Drawing2dStudio } from '../../useDrawingSyncedState';
import { UnmergeLayersAction } from './unMergeLayers';

export const MergeLayersAction: SyncedActionType<
  Drawing2dStudio,
  {
    type: 'mergeLayers';
    targetLayerId: string;
    sourceLayersId: string[];
    mergedImage: ImageData;
  }
> = {
  type: 'mergeLayers',
  optimisticUpdater: ({ payload }, drawing) => {
    const targetLayer = drawing.layers.nodes.find(
      (layer) => layer.id === payload.targetLayerId
    );
    if (!targetLayer) {
      return;
    }
    targetLayer.isGroup = false;
    targetLayer.imagePath = payload.mergedImage;
    drawing.layers.nodes = drawing.layers.nodes.filter(
      (layer) => !payload.sourceLayersId.includes(layer.id)
    );
  },
  remoteUpdater: async ({ payload, meta }, drawingId) => {
    const res = await urqlClient.mutation(UpdateDrawingLayersMutation, {
      input: {
        id: drawingId,
        layerUpdates: [
          {
            id: payload.targetLayerId,
            imagePath: await imageDataToBlob(payload.mergedImage),
            isGroup: false,
          },
        ],
        deletedLayerIds: payload.sourceLayersId,
      },
    });

    if (res?.error) {
      throw new Error(
        `Error while merging layers, please retry. ${
          res.error.graphQLErrors[0]?.message ?? res.error.message
        }`
      );
    }
  },
  undoConstructor: ({ payload }, drawing) => {
    const sourceLayers = drawing.layers.nodes
      .filter((layer) => payload.sourceLayersId.includes(layer.id))
      .map((layer) => ({ ...layer, anchor: payload.targetLayerId }));

    const targetLayer = drawing.layers.nodes.find(
      (layer) => layer.id === payload.targetLayerId
    );
    if (!targetLayer) {
      return;
    }

    const undoPayload: SyncedActionPayloadFromType<typeof UnmergeLayersAction> =
      {
        type: 'unmergeLayers',
        targetLayerId: payload.targetLayerId,
        targetWasGroup: targetLayer.isGroup,
        sourceLayers,
        mergedImage: payload.mergedImage,
        originalImage: targetLayer.imagePath,
      };

    return undoPayload;
  },
};

import { useEffect, useMemo, useState } from 'react';
import { TransitionStatus } from 'react-transition-group';
import styled, { css, keyframes, useTheme } from 'styled-components';
import { v4 as uuid } from 'uuid';
import {
  PromptData,
  PromptOutput,
  publishTrackingEvent,
  useCurrentUserClientStateByKey,
  UserClientStateKeys,
} from '@vizcom/shared/data-access/graphql';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import {
  Button,
  CarretDownIcon,
  DownloadIcon,
  HistoryIcon,
  downloadFile,
  useLastNonNullValue,
  ArrowLeftIcon,
  ArrowRightIcon,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  useKeyboardShortcut,
  imageToBlob,
  FloatingPanel,
  Text,
} from '@vizcom/shared-ui-components';

import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../../lib/useDrawingSyncedState';
import { useIsWorkbenchViewer } from '../../../lib/utils';
import { Overlay } from '../style';
import { Layout } from '../types';
import { StudioHistoryPage } from './StudioHistoryPage';

export type PromptHistoryItem =
  | {
      output: PromptOutput;
      prompt: PromptData;
    }
  | undefined;

type Props = {
  drawing: Drawing2dStudio;
  state: TransitionStatus;
  open: boolean;
  selectedPromptHistoryItem: PromptHistoryItem;
  disabled: boolean;
  setOpen: (open: boolean) => void;
  handleAction?: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  setSelectedPromptHistoryItem: (item: PromptHistoryItem) => void;
  setActiveLayerId: (id: string) => void;
};

export const WorkbenchStudioHistory = ({
  drawing,
  state,
  open,
  selectedPromptHistoryItem,
  disabled,
  setOpen,
  handleAction,
  setSelectedPromptHistoryItem,
  setActiveLayerId,
}: Props) => {
  const theme = useTheme();
  const isViewer = useIsWorkbenchViewer();
  const prevOpen = useLastNonNullValue(open);
  const [pageCursors, setPageCursors] = useState<Array<string | null>>([null]);
  const [prompts, setPrompts] = useState<Record<string, PromptData[]>>({});
  const layout: Layout =
    useCurrentUserClientStateByKey(UserClientStateKeys.StudioLayout) ||
    'default';

  const history = useMemo(
    () =>
      Object.values(prompts)
        .flat()
        .flatMap((prompt) => prompt?.outputs.nodes),
    [prompts]
  );

  useEffect(() => {
    if (open && history.length > 0 && !selectedPromptHistoryItem) {
      setSelectedPromptHistoryItem({
        output: history[0],
        prompt: Object.values(prompts)
          .flat()
          .find((p) => p.id === history[0].promptId)!,
      });
    }
  }, [open, history.length]);

  useEffect(() => {
    setOpen(false);
    setSelectedPromptHistoryItem(undefined);
  }, [layout]);

  useKeyboardShortcut('ArrowLeft', () => {
    if (!open || disabled) {
      return;
    }
    selectPreviousHistoryItem();
  });
  useKeyboardShortcut('ArrowRight', () => {
    if (!open || disabled) {
      return;
    }
    selectNextHistoryItem();
  });
  useKeyboardShortcut('Escape', () => {
    if (!open || disabled) {
      return;
    }
    setSelectedPromptHistoryItem(undefined);
  });

  const selectNextHistoryItem = () => {
    const nextIndex = history.findIndex(
      (item) => item.id === selectedPromptHistoryItem?.output.id
    );
    if (nextIndex === history.length - 1) return;

    const nextItem = !selectedPromptHistoryItem?.output.id
      ? history[0]
      : history[nextIndex + 1];
    if (!nextItem) return;

    setSelectedPromptHistoryItem({
      output: nextItem,
      prompt: Object.values(prompts)
        .flat()
        .find((p) => p.id === nextItem.promptId)!,
    });
  };

  const selectPreviousHistoryItem = () => {
    const previousIndex = history.findIndex(
      (item) => item.id === selectedPromptHistoryItem?.output.id
    );
    if (previousIndex === -1) return;

    const previousItem = history[previousIndex - 1];
    if (!previousItem) return;

    setSelectedPromptHistoryItem({
      output: previousItem,
      prompt: Object.values(prompts)
        .flat()
        .find((p) => p.id === previousItem.promptId)!,
    });
  };

  const addItem = () => {
    const id = uuid();
    handleAction?.({
      type: 'addLayer',
      layer: {
        id: id,
        name:
          selectedPromptHistoryItem?.prompt.text ||
          `Layer ${drawing?.layers.nodes.length + 1}`,
        visible: true,
        opacity: 1,
        blendMode: 'normal',
        fill: '',
        isGroup: false,
        image: selectedPromptHistoryItem?.output.imagePath,
        placement: 'top',
      },
    });
    setSelectedPromptHistoryItem(undefined);
    setActiveLayerId(id);
  };

  const handleDownload = async () => {
    if (!selectedPromptHistoryItem?.output.imagePath) return;

    const name = selectedPromptHistoryItem?.prompt.text || 'generated image';
    downloadFile(
      await imageToBlob(selectedPromptHistoryItem.output.imagePath, {
        convertToContentType: 'image/png',
      }),
      name
    );
    trackEvent('Image Export', { type: 'historyDownloadImage' });
    publishTrackingEvent({
      type: 'EXPORT_HISTORY_IMAGE',
      data: {
        promptId: selectedPromptHistoryItem.prompt.id,
        imagePaths: [selectedPromptHistoryItem.output.imagePath],
      },
    });
  };

  if (isViewer) {
    return null;
  }

  const historyPages =
    open &&
    pageCursors.map((cursor, index) => (
      <StudioHistoryPage
        key={index}
        selectedPromptHistoryItem={selectedPromptHistoryItem}
        setSelectedPromptHistoryItem={setSelectedPromptHistoryItem}
        drawingId={drawing.id}
        cursor={cursor}
        setNextPageCursor={(cursor) =>
          setPageCursors((cursors) => [...cursors, cursor])
        }
        updatePrompts={(cursor: string, prompts: PromptData[]) =>
          setPrompts((prev) => ({
            ...prev,
            [cursor]: prompts,
          }))
        }
      />
    ));

  const OpenCloseButton = () => {
    return (
      <HistoryButton
        onClick={() => {
          if (disabled) return;
          setOpen(!open);
          setSelectedPromptHistoryItem(undefined);
        }}
        variant="tertiary"
        size="L"
      >
        <HistoryButtonTextContainer>
          <HistoryIcon color={theme.text.body} />
          <Text type="b1">Generation History</Text>
        </HistoryButtonTextContainer>
        {open && <CarretDownIcon />}
      </HistoryButton>
    );
  };

  return (
    <Container $state={state}>
      <Content $open={open} $shouldAnimate={!!prevOpen}>
        {disabled && <Overlay style={{ borderRadius: theme.borderRadius.m }} />}

        <OpenCloseButton />

        <HistoryContainer>{historyPages}</HistoryContainer>

        {open && (
          <ActionBar
            $open={open}
            onClick={() => {
              if (!open && !disabled) {
                setOpen(true);
              }
            }}
            style={{ cursor: !open && !disabled ? 'pointer' : 'default' }}
          >
            <RichTooltip padding={11} placement="top">
              <RichTooltipTrigger>
                <Button
                  onClick={() => selectPreviousHistoryItem()}
                  variant="secondary"
                  size="icon"
                >
                  <ArrowLeftIcon
                    width={24}
                    height={24}
                    fill={theme.icon.primary}
                  />
                </Button>
              </RichTooltipTrigger>

              <RichTooltipContent>Previous</RichTooltipContent>
            </RichTooltip>

            <RichTooltip padding={11} placement="top">
              <RichTooltipTrigger>
                <Button
                  onClick={() => selectNextHistoryItem()}
                  variant="secondary"
                  size="icon"
                >
                  <ArrowRightIcon
                    width={24}
                    height={24}
                    fill={theme.icon.primary}
                  />
                </Button>
              </RichTooltipTrigger>
              <RichTooltipContent>Next</RichTooltipContent>
            </RichTooltip>

            <RichTooltip padding={11} placement="top">
              <RichTooltipTrigger>
                <Button
                  onClick={handleDownload}
                  variant="secondary"
                  size="icon"
                >
                  <DownloadIcon />
                </Button>
              </RichTooltipTrigger>
              <RichTooltipContent>Download</RichTooltipContent>
            </RichTooltip>

            <Button
              variant="primary"
              disabled={!selectedPromptHistoryItem}
              onClick={addItem}
            >
              Add
            </Button>
          </ActionBar>
        )}
      </Content>
    </Container>
  );
};

const ActionBar = styled.div<{ $open?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: absolute;
  bottom: 0;
  gap: 8px;
  bottom: 0;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  z-index: 2;
  width: 100%;
  overflow: hidden;
  min-width: 250px;
  background: ${({ theme }) => theme.surface.primary};
  height: ${({ $open }) => ($open ? 'auto' : '48px')};
  padding: ${({ $open }) => ($open ? '8px 14px' : '8px')};
  transition: padding 0.3s ease-in-out, background 0.2s ease-in-out;

  ${({ $open, theme }) =>
    !$open &&
    `
    justify-content: flex-start;

    button:not(:first-child) {
      display: none;
    }

    &:hover {
      background: ${theme.surface.secondary};
    }
  `}
`;

const HistoryButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  height: 48px;
  width: 100%;
  padding: 0 16px;

  &:hover {
    background: transparent !important;
  }
`;

const HistoryButtonTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
`;

const HistoryContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 8px;
  padding: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100%;

  mask-image: linear-gradient(
    to bottom,
    rgba(21, 21, 23, 1) 75%,
    transparent 100%
  );

  ${({ theme }) => theme.scrollbar.light}
`;

const Container = styled(FloatingPanel)<{ $state?: TransitionStatus }>`
  z-index: 10000000;
  position: absolute;
  bottom: 1rem;
  left: 14px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;

  pointer-events: all;

  transform: ${({ $state }) =>
    $state === 'entered' || $state === 'entering'
      ? 'translateX(0)'
      : 'translateX(calc(-100% - 14px))'};
  transition: transform 0.5s ease-in-out;

  animation: ${({ theme, $state }) =>
    $state === 'entering' ? theme.animation.slideInFromLeft : 'none'};
`;

const slideAndExpand = keyframes`
  0% {
    height: 48px;
  }
  100% {
    height: 306px;
  }
`;

const slideAndShrink = keyframes`
  0% {
    height: 306px;
  }
  100% {
    height: 48px;
  }
`;

const Content = styled.div<{ $open: boolean; $shouldAnimate: boolean }>`
  ${({ $open }) =>
    $open &&
    css`
      display: grid;
      grid-template-rows: auto 1fr 53px;
      grid-template-columns: 1fr;
      gap: 8px;
    `}
  overflow: hidden;
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius.l};
  width: 250px;
  height: ${({ $open, $shouldAnimate }) =>
    !$shouldAnimate ? ($open ? '306px' : '48px') : '48px'};
  animation: ${({ $open, $shouldAnimate }) =>
    $shouldAnimate
      ? $open
        ? css`
            ${slideAndExpand} 0.6s ease-in-out 1 forwards
          `
        : css`
            ${slideAndShrink} 0.4s ease-in-out 1 forwards
          `
      : 'none'};
`;

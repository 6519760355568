import { NavigateFunction } from 'react-router-dom';
import { CombinedError } from 'urql';
import {
  addToast,
  formatErrorMessage,
  getCombinedErrorCode,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

export function shouldDisplayLimitationErrorToast(error: CombinedError) {
  const errorCode = getCombinedErrorCode(error);

  return (
    errorCode === 'PAID_SEATS_LIMITATION_ERROR' ||
    errorCode === 'PLAN_SEATS_LIMITATION_ERROR' ||
    errorCode === 'MANUALLY_MANAGED_LIMITATION_ERROR'
  );
}

export const showLimitationErrorToast = (
  error: CombinedError,
  organizationId: string,
  navigate: NavigateFunction,
  openChat: () => void,
  searchParams: URLSearchParams = new URLSearchParams()
) => {
  const defaultErrorMessage = 'You do not have any editor seats available';
  const errorCode = getCombinedErrorCode(error);

  if (errorCode === 'MANUALLY_MANAGED_LIMITATION_ERROR') {
    addToast(formatErrorMessage(error, defaultErrorMessage), {
      duration: 15_000,
      type: 'warning',
      cta: {
        text: 'Contact support',
        action: () => openChat(),
      },
    });

    return;
  }

  const ctaText =
    errorCode === 'PLAN_SEATS_LIMITATION_ERROR'
      ? 'Upgrade workspace'
      : 'Add more seats';

  if (errorCode === 'PLAN_SEATS_LIMITATION_ERROR') {
    searchParams.append('plan', 'PRO');
  }

  const url =
    errorCode === 'PAID_SEATS_LIMITATION_ERROR'
      ? paths.settings.organization.membersSubscriptionUpdate(organizationId)
      : paths.settings.organization.subscription(organizationId);

  addToast(formatErrorMessage(error, defaultErrorMessage), {
    duration: 15_000,
    type: 'warning',
    cta: {
      text: ctaText,
      url: url.concat(searchParams.size ? `?${searchParams.toString()}` : ''),
    },
  });
};

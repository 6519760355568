import { animated, useSpring } from '@react-spring/web';
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';
import { TransitionStatus } from 'react-transition-group';
import styled from 'styled-components';
import {
  useCurrentUserClientStateByKey,
  UserClientStateKeys,
} from '@vizcom/shared/data-access/graphql';
import { FloatingPanel } from '@vizcom/shared-ui-components';

import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../lib/useDrawingSyncedState';
import { useIsWorkbenchViewer } from '../../lib/utils';
import { AiActions } from './ai-actions/AiActions';
import { Create } from './create/Create';
import { PromptHistoryItem } from './history/WorkbenchStudioHistory';
import { SidebarHeader, SidebarHeaderContent } from './style';
import { Layout } from './types';
import { InferenceSettings } from './useInference';

type Props = {
  hidden: boolean;
  state: TransitionStatus;
  drawing: Drawing2dStudio;
  anyOutputLoading?: boolean;
  inferenceSettings: InferenceSettings;
  selectedPromptHistoryItem: PromptHistoryItem;
  uiRatio: number;
  isGeneratingPrompt: boolean;
  applyHistoryItem: (inferenceSettings: InferenceSettings) => void;
  setInferenceSettings: Dispatch<SetStateAction<InferenceSettings>>;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  trigger: () => void;
  triggerAutoPrompt: () => void;
  setIsPrompting: (value: boolean) => void;
  cancelAutoPrompt: () => void;
  onExit: () => void;
  setActiveLayerId: (id: string | undefined) => void;
  getCompositedImage: (() => ImageData) | undefined;
};

export const WorkbenchStudioCreate = (props: Props) => {
  const [spring, api] = useSpring(() => ({}));
  const isViewer = useIsWorkbenchViewer();
  const ref = useRef<HTMLDivElement>(null);
  const userPreferredLayout = useCurrentUserClientStateByKey(
    UserClientStateKeys.StudioLayout
  );
  const layout: Layout = isViewer
    ? 'default'
    : userPreferredLayout || 'default';

  useEffect(() => {
    if (!props.selectedPromptHistoryItem?.output.id) return;

    // flash the create tab when a new history item is selected
    api.start({
      from: {
        boxShadow: `0 0 10px 6px rgba(76, 76, 239, 1)`,
      },
      to: {
        boxShadow: `0 0 10px 6px rgba(76, 76, 239, 0)`,
      },
      config: { duration: 700 },
    });
  }, [api, props.selectedPromptHistoryItem?.output.id]);

  useEffect(() => {
    const onWindowResize = () => {
      if (!ref.current) return;
      if (layout !== 'stacked') return;
      const newHeight = window.innerHeight * (1 - props.uiRatio) - 101;
      ref.current.style.height = `${newHeight}px`;
    };

    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, [layout, props.uiRatio]);

  useLayoutEffect(() => {
    if (!ref.current) return;
    if (layout !== 'stacked') {
      ref.current.style.height = 'auto';
      return;
    }

    const newHeight = window.innerHeight * (1 - props.uiRatio) - 101;
    ref.current.style.height = `${newHeight}px`;
  }, [props.uiRatio, layout]);

  if (isViewer) {
    return null;
  }

  return (
    <StudioTabs
      ref={ref}
      $state={props.state}
      style={spring}
      $hidden={props.hidden}
      $layout={layout}
      onFocus={() => props.setIsPrompting(true)}
      onBlur={() => props.setIsPrompting(false)}
    >
      <SidebarHeader>
        <SidebarHeaderContent>
          Create
          <AiActions
            drawing={props.drawing}
            handleAction={props.handleAction}
            getCompositedImage={props.getCompositedImage}
          />
        </SidebarHeaderContent>
      </SidebarHeader>
      <Create
        drawing={props.drawing}
        anyOutputLoading={props.anyOutputLoading}
        handleAction={props.handleAction}
        inferenceSettings={props.inferenceSettings}
        setInferenceSettings={props.setInferenceSettings}
        trigger={props.trigger}
        triggerAutoPrompt={props.triggerAutoPrompt}
        selectedHistoryPrompt={props.selectedPromptHistoryItem?.prompt}
        applyHistoryItem={props.applyHistoryItem}
        isGeneratingPrompt={props.isGeneratingPrompt}
        cancelAutoPrompt={props.cancelAutoPrompt}
        onExit={props.onExit}
        setActiveLayerId={props.setActiveLayerId}
      />
    </StudioTabs>
  );
};

const StudioTabs = styled(animated(FloatingPanel))<{
  $state: TransitionStatus;
  $hidden: boolean;
  $layout: Layout;
}>`
  position: absolute;
  z-index: 10000001;
  top: calc(1rem + 66px);
  right: 14px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 250px;

  opacity: ${({ $hidden }) => ($hidden ? 0 : 1)};

  transform: ${({ $state }) =>
    $state === 'entered' || $state === 'entering'
      ? 'translateX(0)'
      : 'translateX(calc(100% + 14px))'};
  animation: ${({ theme, $state }) =>
      $state === 'entering' ? theme.animation.slideInFromRight : 'none'}
    0.5s ease;
  transition: transform 0.5s ease-in-out;
  ${({ $layout }) => {
    if ($layout === 'default') {
      return `
        bottom: 100px;
      `;
    } else if ($layout === 'stacked') {
      return `
        height: calc(calc(100% * 0.55) - 100px);
      `;
    }
  }}

  pointer-events: ${({ $hidden }) => ($hidden ? 'none' : 'all')};
  * {
    pointer-events: ${({ $hidden }) => ($hidden ? 'none !important' : 'all')};
  }
`;

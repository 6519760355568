import { create } from 'zustand';

type DrawingLocalState = {
  metadataOpen: string[];
  toggleMetadata: (ids: string[]) => void;
};

export const useDrawingLocalState = create<DrawingLocalState>()((set, get) => ({
  metadataOpen: [],
  toggleMetadata: (ids) => {
    const state = get().metadataOpen;
    const newIds = ids.filter((id) => !state.includes(id));
    const exitingIds = ids.filter((id) => state.includes(id));

    if (newIds.length >= exitingIds.length) {
      set(() => ({
        metadataOpen: [...state, ...newIds],
      }));
    } else {
      set(() => ({
        metadataOpen: [...state.filter((id) => !exitingIds.includes(id))],
      }));
    }
  },
}));

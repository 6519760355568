import { useRerender } from 'libs/shared/ui/components/src/lib/hooks/useRerender';
import { useEffect, useRef } from 'react';
import { DataTexture, Texture } from 'three';

import { cachedLayerImagesByUrl } from '../../../lib/actions/drawing/updateLayer';
import { DrawingLayer } from '../../../lib/useDrawingSyncedState';
import { loadImageWithRetry } from '../../../lib/useImageTexture';
import { useThrottleQueue } from '../../../lib/useThrottleQueue';

/**
 * Asynchronously loads and creates a texture from a drawing layer's image data
 * Handles both ImageData and URL/Blob sources with caching support
 */
const getLayerImage = async (
  imagePath: string | Blob | null | undefined
): Promise<Texture | undefined> => {
  if (imagePath instanceof ImageData) {
    const texture = new DataTexture();
    texture.flipY = true;
    texture.image = imagePath;
    texture.needsUpdate = true;
    return texture;
  }
  if (typeof imagePath === 'string' || imagePath instanceof Blob) {
    let toLoad = imagePath;
    if (typeof imagePath === 'string') {
      const cached = cachedLayerImagesByUrl[imagePath];
      if (cached) {
        toLoad = cached;
      }
    }
    const texture = await loadImageWithRetry(toLoad);
    if (texture) {
      return texture;
    }
  } else if (imagePath === null || imagePath === undefined) {
    return undefined;
  }
};

/**
 * Handles Three.js texture lifecycle, caching, and cleanup for the drawing layers
 */
export const useLayersTextures = (layers: DrawingLayer[]) => {
  //Load layers one by one to prevent freeze when decoding the image and uploading to the GPU.
  const queue = useThrottleQueue(1);

  // Tracks the current image path for each layer to detect changes
  const managedImagePaths = useRef<Record<string, DrawingLayer['imagePath']>>(
    {}
  );

  // Stores the actual texture objects by layer ID
  const textures = useRef<Record<string, Texture>>({});

  const rerender = useRerender();

  // Cleanup: Remove textures for layers that no longer exist
  Object.keys(managedImagePaths.current)
    .filter((key) => !layers.find((layer) => layer.id === key))
    .forEach((key: string) => {
      if (textures.current[key]) {
        textures.current[key]?.dispose();
        textures.current = { ...textures.current };
        delete textures.current[key];
      }
      delete managedImagePaths.current[key];
    });

  /**
   * Helper function to update a layer's texture
   * Disposes old texture and updates the texture registry
   */
  const updateTexture = (layerId: string, texture: Texture | undefined) => {
    if (textures.current[layerId]) {
      textures.current[layerId]?.dispose();
    }
    if (texture !== undefined) {
      textures.current = {
        ...textures.current,
        [layerId]: texture,
      };
    } else {
      delete textures.current[layerId];
    }
  };

  // Process each layer and update textures as needed
  layers.forEach((layer) => {
    if (managedImagePaths.current[layer.id] === layer.imagePath) {
      return; // Skip if image hasn't changed
    }
    if (!layer.visible) {
      return; //Hidden layers are not loaded or updated.
    }
    managedImagePaths.current[layer.id] = layer.imagePath;
    if (layer.imagePath instanceof ImageData) {
      const texture = new DataTexture();
      texture.flipY = true;
      texture.image = layer.imagePath;
      texture.needsUpdate = true;

      updateTexture(layer.id, texture);
    } else {
      queue.push(() =>
        getLayerImage(layer.imagePath as string | Blob | undefined).then(
          (texture: Texture | undefined) => {
            //prevent race conditions when loading an already expired imagePath
            if (managedImagePaths.current[layer.id] === layer.imagePath) {
              updateTexture(layer.id, texture);
              rerender();
            } else {
              texture?.dispose();
            }
          }
        )
      );
    }
  });

  // Checks if all layers have their textures loaded
  // Note: Textures may be outdated while new versions are loading
  const areAllTexturesLoaded = Object.keys(managedImagePaths.current).every(
    (key) =>
      managedImagePaths.current[key] === undefined ||
      managedImagePaths.current[key] === null ||
      Object.prototype.hasOwnProperty.call(textures.current, key)
  );

  useEffect(
    () => () => {
      // dispose all textures and prevent memory leaks
      Object.values(textures.current).forEach((texture) => texture?.dispose());
      textures.current = {};
      // Ensure `managedImagePaths` is empty.
      // If there is still pending loads, this will prevent them from storing the texture when completed.
      managedImagePaths.current = {};
    },
    []
  );

  return {
    textures: textures.current,
    areAllTexturesLoaded,
  };
};

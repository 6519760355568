import styled from 'styled-components';
import { AutoplayVideo, Text } from '@vizcom/shared-ui-components';

import { WorkbenchVideoTooltip } from '../studioState';

export const VideoTooltip = ({
  title,
  description,
  videoSrc,
  learnMoreUrl,
}: WorkbenchVideoTooltip) => {
  return (
    <TooltipWrapper>
      <Text type="b2">{title}</Text>
      {description && (
        <TooltipDescription type="b2" color="subtext">
          {description}
        </TooltipDescription>
      )}
      {learnMoreUrl && (
        <TooltipLink
          href={learnMoreUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more
        </TooltipLink>
      )}
      {videoSrc && <TooltipVideo src={videoSrc} />}
    </TooltipWrapper>
  );
};

const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 240px;
  padding: 8px;
`;

const TooltipDescription = styled(Text)`
  line-height: 1.5; // TODO: should be a theme value
`;

const TooltipLink = styled.a`
  font-size: 10px; // TODO: should be a theme value
  text-decoration: none;
  color: ${({ theme }) => theme.deprecated.primary.default};
`;

const TooltipVideo = styled(AutoplayVideo)`
  width: 100%;
  height: auto;
  border-radius: calc(${({ theme }) => theme.borderRadius.m} - 2px);
`;

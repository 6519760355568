import { ThreeEvent, useThree } from '@react-three/fiber';
import { createUseGesture } from '@use-gesture/react';
import { useRef, useState } from 'react';
import { Mesh, OrthographicCamera } from 'three';
import { useStore } from 'zustand';

import { screenPositionToLayer } from '../../../helpers';
import { useCanvasTexture } from '../../lib/useCanvasTexture';
import { ActiveMask, MaskDisplayMode } from '../../selection/ActiveMask';
import { useSelectionApiStore } from '../../selection/useSelectionApi';
import { MaskOperation } from '../../selection/utils';
import { useWorkbenchStudioState } from '../../studioState';
import { BrushCursorPreview } from '../BrushEngine/BrushCursorPreview';
import { EventMesh } from '../EventMesh';

const useGesture = createUseGesture([]);

const MIN_SCREEN_DIST = 2;

export const RectangleSelection = ({
  drawingSize,
}: {
  drawingSize: [number, number];
}) => {
  const selectionApiStore = useSelectionApiStore();
  const { operation, toolSize } = useWorkbenchStudioState((s) => ({
    operation: s.selectionSettings.operation,
    toolSize: s.selectionSettings.brushSettings.toolSize,
    isPrompting: s.isPrompting,
  }));

  const eventPlaneRef = useRef<Mesh>(null!);
  const { ctx, canvasTexture } = useCanvasTexture(drawingSize);
  const [isDrawing, setIsDrawing] = useState(false);

  ctx.fillStyle = '0x000000';
  ctx.lineWidth = toolSize;
  ctx.lineJoin = ctx.lineCap = 'round';

  const initialScreenPositionRef = useRef<[number, number] | null>(null);

  const camera = useThree((s) => s.camera as OrthographicCamera);

  const bind = useGesture({
    onPointerDown: (state) => {
      const event = state.event as unknown as ThreeEvent<PointerEvent>;
      initialScreenPositionRef.current = [event.clientX, event.clientY];
    },

    onPointerUp: (state) => {
      if (initialScreenPositionRef.current === null) {
        return;
      }
      const event = state.event as unknown as ThreeEvent<PointerEvent>;
      const screenPosition: [number, number] = [event.clientX, event.clientY];

      //Clear selection if the pointer has not moved.
      const screenDist = Math.hypot(
        initialScreenPositionRef.current[0] - screenPosition[0],
        initialScreenPositionRef.current[1] - screenPosition[1]
      );
      if (operation === MaskOperation.Replace && screenDist < MIN_SCREEN_DIST) {
        selectionApiStore.getState().deselectMask();
      } else {
        const rectangleCanvas = ctx.canvas;
        selectionApiStore.getState().editSelectionCanvas((ctx) => {
          ctx.clearRect(0, 0, drawingSize[0], drawingSize[1]);
          ctx.drawImage(rectangleCanvas, 0, 0);
        });
      }

      initialScreenPositionRef.current = null;

      setIsDrawing(false);
    },

    onPointerMove: (state) => {
      if (initialScreenPositionRef.current === null) {
        return;
      }
      const event = state.event as unknown as ThreeEvent<PointerEvent>;
      const screenPosition: [number, number] = [event.clientX, event.clientY];

      setIsDrawing(true);
      ctx.clearRect(0, 0, drawingSize[0], drawingSize[1]);

      const screenDist = Math.hypot(
        initialScreenPositionRef.current[0] - screenPosition[0],
        initialScreenPositionRef.current[1] - screenPosition[1]
      );

      if (screenDist > MIN_SCREEN_DIST || operation !== MaskOperation.Replace) {
        ctx.save();
        if (operation === MaskOperation.Replace) {
          ctx.fillStyle = '#000000';
          ctx.fillRect(0, 0, drawingSize[0], drawingSize[1]);
        } else {
          ctx.drawImage(selectionApiStore.getState().canvas, 0, 0);
        }

        if (
          !selectionApiStore.getState().hasMask ||
          operation === MaskOperation.Replace ||
          operation === MaskOperation.Add
        ) {
          ctx.fillStyle = '#ffffff';
        } else if (operation === MaskOperation.Remove) {
          ctx.fillStyle = '#000000';
        }

        const initialPosition = screenPositionToLayer(
          initialScreenPositionRef.current,
          camera,
          eventPlaneRef.current,
          drawingSize
        );
        const currentPosition = screenPositionToLayer(
          screenPosition,
          camera,
          eventPlaneRef.current,
          drawingSize
        );
        ctx.fillRect(
          initialPosition[0],
          initialPosition[1],
          currentPosition[0] - initialPosition[0],
          currentPosition[1] - initialPosition[1]
        );
        ctx.restore();
      } else {
        ctx.clearRect(0, 0, drawingSize[0], drawingSize[1]);
      }
      canvasTexture.needsUpdate = true;
    },
  });

  const selectionTexture = useStore(selectionApiStore, (s) => s.texture);

  return (
    <>
      <BrushCursorPreview
        drawingSize={drawingSize}
        toolSize={0}
        toolAspect={1}
        toolAngle={0}
        color={'#000000'}
      />
      <ActiveMask
        drawingSize={drawingSize}
        maskTexture={isDrawing ? canvasTexture : selectionTexture}
        mode={MaskDisplayMode.MARCHING_ANTS}
      />

      <EventMesh
        drawingSize={drawingSize}
        eventMeshProps={bind() as any}
        ref={eventPlaneRef}
      />
    </>
  );
};

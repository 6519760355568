import { CSSProperties } from 'styled-components';
import {
  eventTargetIsInput,
  useDocumentEventListener,
} from '@vizcom/shared-ui-components';

import { DrawingLayer } from '../../lib/useDrawingSyncedState';
import { useWorkbenchStudioState, isPaintingTool } from '../studio/studioState';
import { EventMesh } from '../studio/tools/EventMesh';
import { ROTATION_CURSORS } from './ResizePositionRotationPresenter';

export const GlobalCursor = (props: {
  activeLayer: DrawingLayer | undefined;
  drawingSize: [number, number];
}) => {
  const studioState = useWorkbenchStudioState();

  useDocumentEventListener('keydown', (e) => {
    if (eventTargetIsInput(e)) {
      return;
    }
    if (e.key?.toLowerCase() === 'r') {
      studioState.setRotating(true);
    }
    if (e.key === ' ') {
      studioState.setPanning(true);
    }
  });

  useDocumentEventListener('keyup', (e) => {
    if (e.key?.toLowerCase() === 'r') {
      studioState.setRotating(false);
    }
    if (e.key === ' ') {
      studioState.setPanning(false);
    }
  });

  let cursor: CSSProperties['cursor'] | null = null;
  const canUseBrushOrEraser =
    props.activeLayer &&
    props.activeLayer.visible &&
    !props.activeLayer.isGroup;
  if (!canUseBrushOrEraser && isPaintingTool(studioState.tool)) {
    cursor = 'not-allowed';
  } else if (studioState.resizing) {
    cursor = 'ew-resize';
  } else if (studioState.rotating) {
    cursor = ROTATION_CURSORS['-1/1'];
  } else if (studioState.panning) {
    cursor = 'grabbing';
  } else {
    cursor = null;
  }

  return (
    <group>
      <EventMesh
        drawingSize={props.drawingSize}
        eventMeshProps={{
          userData: {
            globalCursor: cursor,
          },
          renderOrder: 11,
        }}
      />
    </group>
  );
};

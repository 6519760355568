import { useRef, useState } from 'react';
import styled from 'styled-components';
import {
  useCurrentUserClientStateByKey,
  UserClientStateKeys,
} from '@vizcom/shared/data-access/graphql';
import {
  Divider,
  useDocumentEventListener,
  useStableCallback,
  CloseIcon,
  Text,
  Menu,
  HideIcon,
  UnhideIcon,
  HexColorInput,
  ColorPicker,
} from '@vizcom/shared-ui-components';

import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../lib/useDrawingSyncedState';
import { useIsWorkbenchViewer } from '../../lib/utils';
import { useWorkbenchStudioState } from './studioState';
import { Layout } from './types';

const SETTINGS_CLASSNAME = 'background-settings';

export const BackgroundLayer = ({
  drawing,
  viewerVisibility,
  setViewerVisibility,
  setTogglingMultiLayerVisibility,
  isTogglingMultiLayerVisibility,
  handleAction,
}: {
  drawing: Drawing2dStudio;
  viewerVisibility: Record<string, boolean>;
  setViewerVisibility: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
  setTogglingMultiLayerVisibility: (v: boolean | undefined) => void;
  isTogglingMultiLayerVisibility: boolean | undefined;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
}) => {
  const layout: Layout =
    useCurrentUserClientStateByKey(UserClientStateKeys.StudioLayout) ||
    'default';
  const isViewer = useIsWorkbenchViewer();
  const [isColorMenuOpened, setIsColorMenuOpened] = useState(false);
  const [isEditingValue, setIsEditingValue] = useState(false);
  const studioState = useWorkbenchStudioState();
  const settingsRef = useRef<HTMLDivElement>(null);

  useDocumentEventListener('pointerup', () => {
    setTogglingMultiLayerVisibility(undefined);
  });

  const toggleLayer = () => {
    handleAction({
      type: 'updateDrawing',
      backgroundVisible: !drawing.backgroundVisible,
    });
  };

  const toggleVisibility = () => {
    setTogglingMultiLayerVisibility(!drawing.backgroundVisible);
  };

  const setVisible = (visible: boolean) => {
    handleAction({
      type: 'updateDrawing',
      backgroundVisible: visible,
    });
  };

  const setBackgroundColor = (color: string) => {
    handleAction({
      type: 'updateDrawing',
      backgroundColor: color,
    });
  };

  const handleEditValue = useStableCallback(() => {
    if (!isEditingValue) {
      setIsColorMenuOpened(true);
    }
    setIsEditingValue(true);
  });

  const menuOffset = layout === 'default' ? 155 : 55;

  return (
    <LayerContainer $active={false}>
      <Content>
        <Actions>
          <button
            style={{
              touchAction: 'none',
              padding: '10px',
            }}
            onPointerDown={(e) => {
              if (isViewer) {
                setViewerVisibility((prev) => ({
                  ...prev,
                  ['background']:
                    prev['background'] === undefined
                      ? !drawing.backgroundVisible
                      : !prev['background'],
                }));
                return;
              }
              e.stopPropagation();
              try {
                (e.target as HTMLButtonElement).releasePointerCapture(
                  e.pointerId
                );
              } catch {}

              e.preventDefault();

              toggleVisibility();
              toggleLayer();
            }}
            onPointerEnter={() => {
              if (isTogglingMultiLayerVisibility !== undefined) {
                setVisible(isTogglingMultiLayerVisibility);
              }
            }}
          >
            {viewerVisibility['background'] ?? drawing.backgroundVisible ? (
              <HideIcon style={{ pointerEvents: 'none' }} />
            ) : (
              <UnhideIcon style={{ pointerEvents: 'none' }} />
            )}
          </button>
        </Actions>

        <Menu
          placement={layout === 'default' ? 'right-start' : 'left-start'}
          offset={menuOffset}
          hideArrow={true}
          renderLabel={(props, interactionProps) => (
            <ImageWrapper {...interactionProps}>
              <Image
                $width={drawing.width}
                $height={drawing.height}
                $color={drawing.backgroundColor}
                $active={isColorMenuOpened}
              >
                <div style={{ height: drawing.height, width: drawing.width }} />
              </Image>
            </ImageWrapper>
          )}
          open={isColorMenuOpened}
          setIsOpen={setIsColorMenuOpened}
          dismissProps={{
            outsidePress: (e) =>
              !settingsRef.current?.contains(e.target as HTMLElement),
          }}
          disableFocusManager
        >
          <BackgroundColorMenu
            color={drawing.backgroundColor}
            setColor={setBackgroundColor}
            onOpenCanvasColorPicker={() => {
              studioState.setCanvasColorPickerSession({
                currentColor: drawing.backgroundColor,
                callback: (color) => {
                  setBackgroundColor(color);
                },
              });
            }}
            onCloseCanvasColorPicker={() => {
              studioState.setCanvasColorPickerSession(null);
            }}
            setShowContextMenu={setIsColorMenuOpened}
          />
        </Menu>

        <Meta>
          <Label>Background</Label>
          <Settings
            ref={settingsRef}
            className={SETTINGS_CLASSNAME}
            onClick={handleEditValue}
            style={{ width: '100%' }}
          >
            <BackgroundLayerItemColorInput
              color={drawing.backgroundColor}
              onChange={(c) => {
                setBackgroundColor('#' + c);
              }}
              onBlur={() => {
                setIsEditingValue(false);
              }}
            />
          </Settings>
        </Meta>
      </Content>
    </LayerContainer>
  );
};

interface BackgroundColorMenuProps {
  color: string;
  setColor: (color: string) => void;
  onOpenCanvasColorPicker: () => void;
  onCloseCanvasColorPicker: () => void;
  setShowContextMenu: (showContextMenu: boolean) => void;
}

function BackgroundColorMenu({
  setShowContextMenu,
  color,
  setColor,
  onOpenCanvasColorPicker,
  onCloseCanvasColorPicker,
}: BackgroundColorMenuProps) {
  const handleOnClose = useStableCallback(() => {
    onCloseCanvasColorPicker();
    setShowContextMenu(false);
  });

  return (
    <PopoverContainer>
      <Header>
        <Text type="sh2" block>
          Color
        </Text>
        <CloseIcon style={{ cursor: 'pointer' }} onClick={handleOnClose} />
      </Header>
      <Divider />
      <ColorPicker
        color={color}
        onChange={setColor}
        onEyeDropperClicked={onOpenCanvasColorPicker}
      />
    </PopoverContainer>
  );
}

const PopoverContainer = styled.div`
  width: 254px;
  padding: 17px;
  background-color: ${({ theme }) => theme.surface.primary};
  color: #fff;
  border-radius: ${({ theme }) => theme.borderRadius.l};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BackgroundLayerItemColorInput = styled(HexColorInput)`
  width: 100%;
  height: 20px;
  padding: 3px 5px;
  margin-left: -5px;

  border-radius: ${({ theme }) => theme.borderRadius.s};

  text-transform: uppercase;

  &:not(:focus) {
    background-color: transparent;
    border-color: transparent;
    color: ${({ theme }) => theme.text.subtext};
  }
`;

export const Label = styled.div`
  cursor: 'default';
  color: ${({ theme }) => theme.text.body};
  overflow: hidden;
  white-space: nowrap;
  border: 1px solid transparent;
  margin-right: auto;
  max-width: 106px;
`;

const LayerContainer = styled.div<{ $active: boolean }>`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid transparent;
  background: ${({ $active, theme }) =>
    $active ? theme.deprecated.primary.disabled : theme.surface.primary};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  overflow: hidden;
  min-height: 64px;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  min-height: 64px;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  button {
    border: 0;
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    align-items: center;
    background: transparent;
    cursor: pointer;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 10px 3px;
  margin-right: 4px;
  width: 58px;
  height: 58px;
`;

const Image = styled.div<{
  $width: number;
  $height: number;
  $color: string;
  $active: boolean;
}>`
  background: ${({ $color }) => $color};
  overflow: hidden;
  cursor: pointer;
  aspect-ratio: ${({ $width, $height }) => $width / $height};
  border-radius: ${({ theme }) => theme.borderRadius.s};
  max-width: 58px;
  max-height: 38px;
  border: ${({ $active }) => ($active ? 1 : 0)}px solid
    ${({ theme }) => theme.deprecated.primary.default};

  &:hover {
    border: 1px solid ${({ theme }) => theme.deprecated.primary.default};
  }
`;

const Settings = styled.div`
  display: flex;
  gap: 0.5rem;
  color: ${({ theme }) => theme.text.subtext};
  cursor: pointer;
  > div {
    display: flex;
  }
`;

const Meta = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 6px;
`;

import { Fragment, useEffect, useMemo, useState } from 'react';
import { TransitionStatus } from 'react-transition-group';
import styled, { useTheme } from 'styled-components';
import {
  FullPageDarkLoader,
  RichTooltip,
  RichTooltipTrigger,
  useKeyboardShortcut,
  SelectIcon,
  OnboardingTourTooltipContent,
  useShouldDisplayOnboardingTour,
  Toolbar,
  ToolbarDivider,
  ToolbarButton,
  ToolbarButtonState,
  Text,
  EraserIcon,
} from '@vizcom/shared-ui-components';
import {
  useDebouncedStateSetter,
  useResizeObserver,
} from '@vizcom/shared-utils-hooks';

import educationalTooltipVideo01 from '../../../assets/videos/educational-tooltip-01.mp4';
import {
  Drawing2dStudio,
  DrawingLayer,
  useDrawingSyncedState,
} from '../../../lib/useDrawingSyncedState';
import { useIsWorkbenchViewer } from '../../../lib/utils';
import { RedoButton } from '../../RedoButton';
import { UndoButton } from '../../UndoButton';
import {
  OnboardingStep,
  useOnboardingMultiStep,
} from '../../utils/OnboardingMultiStep';
import { PromptHistoryItem } from '../history/WorkbenchStudioHistory';
import {
  WorkbenchStudioToolType,
  isLayer3dTool,
  useWorkbenchStudioState,
} from '../studioState';
import { Overlay } from '../style';
import { BrushButton } from './Brush/BrushButton';
import { BrushColorButton } from './BrushColorButton';
import { EraserButton } from './Eraser/EraserButton';
import { Generate3dLayerMenu } from './Generate3dLayerMenu';
import { InsertButton } from './InsertButton';
import { SelectionButton } from './Selection/SelectionButton';
import { SelectionSettings } from './Selection/SelectionSettings';
import { ShapeButton } from './Shape/ShapeButton';
import { ShapeSettings } from './Shape/ShapeSettings';
import { SymmetryButton } from './Symmetry/SymmetryButton';
import { SymmetrySettings } from './Symmetry/SymmetrySettings';
import { To3DButton } from './To3DButton';
import { ToolbarMenuOption } from './ToolbarOption';
import { OverflowMenu } from './ToolbarOverflowButton';
import { TransformButton } from './Transform/TransformButton';
import { TransformSettings } from './Transform/TransformSettings';
import { WorkbenchButton } from './WorkbenchButton';
import { WorkbenchStudioToolbarSecondary } from './WorkbenchStudioToolbarSecondary';

interface WorkbenchStudioToolbarProps {
  disabled: boolean;
  drawing: Drawing2dStudio;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  state: TransitionStatus;
  activeLayer: DrawingLayer | undefined;
  activeLayerId: string | undefined;
  isInferenceRunning: boolean;
  setActiveLayerId: (id: string | undefined) => void;
  handleUndo: () => void;
  handleRedo: () => void;
  onExit: () => void;
  canRedo: boolean;
  canUndo: boolean;
  setSelectedPromptHistoryItem: (item: PromptHistoryItem) => void;
  onClick: () => void;
  onOpenMobileUploadModal: () => void;
  getCompositedImage: () => ImageData;
}

type MenuOpenStatus =
  | 'transform'
  | 'shape'
  | 'selection'
  | 'symmetry'
  | 'to3D'
  | 'overflow'
  | 'erase'
  | null;

export interface IconWithWidth {
  icon: React.ReactNode;
  toolbarOptions?: React.ReactNode;
  width: number;
}

const MENU_ICON_WIDTH = 32;
const MENU_ICON_WITH_CHEVRON_WIDTH = MENU_ICON_WIDTH + 14;
const ROW_GAP = 8;
const TOOLBAR_PADDING = 8;

const calculateWidth = (
  icons: IconWithWidth[],
  startWidth = 0,
  maximum = Infinity
): { width: number; numItems: number } => {
  return icons.reduce(
    (acc, item, index) => {
      const gapWidth = index === icons.length - 1 ? 0 : ROW_GAP;
      if (acc.width + item.width + gapWidth <= maximum) {
        return {
          width: acc.width + item.width + gapWidth,
          numItems: acc.numItems + 1,
        };
      }
      return acc;
    },
    { width: startWidth, numItems: 0 }
  );
};

export const WorkbenchStudioToolbar = (props: WorkbenchStudioToolbarProps) => {
  const [toolbarRef, toolbarWidth] = useResizeObserver<HTMLDivElement>();
  const [leftRef, leftRefWidth] = useResizeObserver<HTMLDivElement>();
  const [rightRef, rightRefWidth] = useResizeObserver<HTMLDivElement>();
  const [visibleItemsRef, visibleItemsWidth] =
    useResizeObserver<HTMLDivElement>();

  const theme = useTheme();
  const studioState = useWorkbenchStudioState();
  const shouldDisplayTour = useShouldDisplayOnboardingTour();
  const { currentStep } = useOnboardingMultiStep();
  const isLayer3d = props.activeLayer?.metadata3D;
  const isViewer = useIsWorkbenchViewer();

  const [showOverlay, setShowOverlay] = useState(false);
  // Note: since we use transitions, we want to debounce the state setter (with RichTooltip, sometimes multiple state changes happen simultaneously)
  const [hideSecondaryToolbar, setHideSecondaryToolbar] =
    useDebouncedStateSetter(false, 150);
  const [menuOpenStatus, setMenuOpenStatus_] = useState<MenuOpenStatus>(null);

  const [quadTopology, setQuadTopology] = useState(false);

  const setMenuOpenStatus = (newStatus: MenuOpenStatus) => {
    setMenuOpenStatus_(newStatus);
    setHideSecondaryToolbar(newStatus !== null);
  };

  useEffect(() => {
    setMenuOpenStatus(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studioState.tool]);

  const onChangeTool = (tool: WorkbenchStudioToolType) => {
    if (tool === studioState.tool) {
      setHideSecondaryToolbar(!hideSecondaryToolbar);
    }
    studioState.setTool(tool);
  };

  const currentTool =
    isLayer3d && !isLayer3dTool(studioState.tool)
      ? WorkbenchStudioToolType.Move
      : studioState.tool;

  useKeyboardShortcut('w', props.onExit, {
    shift: true,
  });

  const isDrawingDisabled = isLayer3d;

  const UNCOLLAPSIBLE_ICONS: IconWithWidth[] = [
    {
      icon:
        shouldDisplayTour && currentStep === 1 ? (
          <RichTooltip isOpen={true} placement="bottom-start" padding={88}>
            <RichTooltipTrigger>
              <BrushButton
                currentTool={currentTool}
                disabled={isDrawingDisabled}
                onChangeTool={onChangeTool}
              />
            </RichTooltipTrigger>
            <OnboardingTourTooltipContent>
              <OnboardingStep
                video={educationalTooltipVideo01}
                title="Sketch your design"
                content="Draw on the canvas, or upload a sketch using the insert button."
              />
            </OnboardingTourTooltipContent>
          </RichTooltip>
        ) : (
          <BrushButton
            currentTool={currentTool}
            disabled={isDrawingDisabled}
            onChangeTool={onChangeTool}
          />
        ),
      width: MENU_ICON_WIDTH,
    },
    {
      icon: (
        <BrushColorButton
          workbenchId={props.drawing.workbenchId}
          color={studioState.color}
          setColor={studioState.setColor}
          onOpenCanvasColorPicker={() =>
            studioState.setCanvasColorPickerSession({
              currentColor: studioState.color,
              callback: (color) => {
                studioState.setColor(color);
              },
            })
          }
        />
      ),
      width: MENU_ICON_WIDTH,
    },
  ];

  const COLLAPSIBLE_ICONS: IconWithWidth[] = [
    {
      icon: (
        <TransformButton
          currentTool={currentTool}
          isLayer3d={isLayer3d}
          isMenuOpen={menuOpenStatus === 'transform'}
          setIsMenuOpen={(isOpen: boolean | ((prev: boolean) => boolean)) => {
            setMenuOpenStatus(isOpen ? 'transform' : null);
          }}
          onChangeTool={onChangeTool}
        />
      ),
      toolbarOptions: (
        <TransformSettings onSelect={() => setMenuOpenStatus('transform')} />
      ),
      width: MENU_ICON_WITH_CHEVRON_WIDTH,
    },
    {
      icon: (
        <EraserButton
          currentTool={currentTool}
          disabled={isDrawingDisabled}
          onChangeTool={onChangeTool}
          isMenuOpen={menuOpenStatus === 'erase'}
          setIsMenuOpen={(isOpen: boolean | ((prev: boolean) => boolean)) => {
            setMenuOpenStatus(isOpen ? 'erase' : null);
          }}
        />
      ),
      toolbarOptions: (
        <ToolbarMenuOption
          icon={<EraserIcon />}
          label="Eraser"
          shortcut="E"
          onClick={() => onChangeTool(WorkbenchStudioToolType.Eraser)}
        />
      ),
      width: MENU_ICON_WITH_CHEVRON_WIDTH,
    },
    {
      icon: (
        <ShapeButton
          currentTool={currentTool}
          disabled={isDrawingDisabled}
          isMenuOpen={menuOpenStatus === 'shape'}
          setIsMenuOpen={(isOpen: boolean | ((prev: boolean) => boolean)) => {
            setMenuOpenStatus(isOpen ? 'shape' : null);
          }}
          onChangeTool={onChangeTool}
        />
      ),
      toolbarOptions: (
        <ShapeSettings onSelect={() => setMenuOpenStatus('shape')} />
      ),
      width: MENU_ICON_WITH_CHEVRON_WIDTH,
    },
    {
      icon: (
        <SelectionButton
          currentTool={currentTool}
          isMenuOpen={menuOpenStatus === 'selection'}
          setIsMenuOpen={(isOpen: boolean | ((prev: boolean) => boolean)) => {
            setMenuOpenStatus(isOpen ? 'selection' : null);
          }}
        />
      ),
      toolbarOptions: (
        <SelectionSettings
          onSelectFromMenu={() => setMenuOpenStatus('selection')}
        />
      ),
      width: MENU_ICON_WITH_CHEVRON_WIDTH,
    },
    {
      icon: (
        <SymmetryButton
          disabled={isDrawingDisabled}
          isMenuOpen={menuOpenStatus === 'symmetry'}
          setIsMenuOpen={(isOpen: boolean | ((prev: boolean) => boolean)) => {
            setMenuOpenStatus(isOpen ? 'symmetry' : null);
          }}
        />
      ),
      toolbarOptions: <SymmetrySettings />,
      width: MENU_ICON_WIDTH,
    },
    {
      icon: (
        <To3DButton
          drawing={props.drawing}
          handleAction={props.handleAction}
          activeLayerId={props.activeLayerId}
          setActiveLayerId={props.setActiveLayerId}
          disabled={isDrawingDisabled}
          isMenuOpen={menuOpenStatus === 'to3D'}
          setIsMenuOpen={(isOpen: boolean | ((prev: boolean) => boolean)) => {
            setMenuOpenStatus(isOpen ? 'to3D' : null);
          }}
          getCompositedImage={props.getCompositedImage}
          quadTopology={quadTopology}
          setQuadTopology={setQuadTopology}
        />
      ),
      toolbarOptions: (
        <Generate3dLayerMenu
          drawing={props.drawing}
          handleAction={props.handleAction}
          activeLayerId={props.activeLayerId}
          setActiveLayerId={props.setActiveLayerId}
          onButtonClicked={() => setMenuOpenStatus('to3D')}
          getCompositedImage={props.getCompositedImage}
          quadTopology={quadTopology}
          setQuadTopology={setQuadTopology}
        />
      ),
      width: MENU_ICON_WIDTH,
    },
  ];

  // Get style parameters for responsive toolbar
  const { maxToolbarWidth, minToolbarWidth, maxVisibleItems } = useMemo(() => {
    const { width: uncollapsibleWidth } = calculateWidth(UNCOLLAPSIBLE_ICONS);
    const { numItems: numVisibleItems } = calculateWidth(
      COLLAPSIBLE_ICONS,
      uncollapsibleWidth + ROW_GAP,
      visibleItemsWidth
    );

    const restOfToolbarWidth =
      leftRefWidth + rightRefWidth + ROW_GAP * 2 + TOOLBAR_PADDING * 2;

    const maxToolbarWidth =
      calculateWidth([...COLLAPSIBLE_ICONS, ...UNCOLLAPSIBLE_ICONS]).width +
      restOfToolbarWidth;

    const minToolbarWidth =
      uncollapsibleWidth + ROW_GAP + MENU_ICON_WIDTH + restOfToolbarWidth;

    const isFullyExpanded =
      toolbarWidth + TOOLBAR_PADDING * 2 >= maxToolbarWidth;
    const maxVisibleItems = isFullyExpanded ? Infinity : numVisibleItems; // Note: this solves issue with items starting in collapsed state

    return {
      maxToolbarWidth,
      minToolbarWidth,
      maxVisibleItems,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleItemsWidth, leftRefWidth, rightRefWidth, toolbarWidth]);

  const visibleItems = COLLAPSIBLE_ICONS.slice(0, maxVisibleItems);
  const overflowItems = COLLAPSIBLE_ICONS.slice(maxVisibleItems);

  const [transformButton, eraserButton, ...visibleRest] = visibleItems;
  const [brushButton, colorButton] = UNCOLLAPSIBLE_ICONS;

  if (isViewer) {
    return (
      <StudioToolbar
        $state={props.state}
        position="top-center"
        $maxWidth={270}
        $minWidth={minToolbarWidth}
        style={{ justifyContent: 'space-between' }}
      >
        <ToolbarButton
          icon={<SelectIcon />}
          tooltip="Select"
          state={ToolbarButtonState.ACTIVE}
        />

        <ToolbarDivider />

        <Text color="subtext" style={{ paddingRight: '8px' }}>
          View only
        </Text>

        <ToolbarDivider />

        <WorkbenchButton
          isIconOnly={false}
          onExit={props.onExit}
          shouldDisplayTour={shouldDisplayTour}
          currentStep={currentStep}
        />
      </StudioToolbar>
    );
  }

  return (
    <>
      <WorkbenchStudioToolbarSecondary
        hideSecondaryToolbar={
          isDrawingDisabled ||
          hideSecondaryToolbar ||
          ['exiting', 'exited'].includes(props.state)
        }
        isHideSliders={maxVisibleItems === 0}
        drawing={props.drawing}
        handleAction={props.handleAction}
        setActiveLayer={props.setActiveLayerId}
        activeLayer={props.activeLayer}
        isInferenceRunning={props.isInferenceRunning}
      />

      {showOverlay && <FullPageDarkLoader />}
      <StudioToolbar
        $state={props.state}
        position="top-center"
        onClick={props.onClick}
        $maxWidth={maxToolbarWidth}
        $minWidth={minToolbarWidth}
        ref={toolbarRef}
      >
        {props.disabled && (
          <Overlay
            style={{ borderRadius: theme.borderRadius.l }}
            onClick={() => {
              props.setSelectedPromptHistoryItem(undefined);
            }}
          />
        )}

        <LeftSection ref={leftRef}>
          <InsertButton
            drawing={props.drawing}
            handleAction={props.handleAction}
            setShowOverlay={setShowOverlay}
            activeLayerId={props.activeLayer?.id}
            setActiveLayerId={props.setActiveLayerId}
            onOpenMobileUploadModal={props.onOpenMobileUploadModal}
          />
          <ToolbarDivider />
        </LeftSection>

        <VisibleItemsContainer ref={visibleItemsRef}>
          {transformButton?.icon ?? null}
          {brushButton.icon}
          {eraserButton?.icon ?? null}
          {colorButton.icon}
          {visibleRest.map((item, index) => (
            <Fragment key={index}>{item.icon}</Fragment>
          ))}
        </VisibleItemsContainer>

        {overflowItems.length > 0 && (
          <OverflowMenu
            overflowItems={overflowItems}
            isMenuOpen={menuOpenStatus === 'overflow'}
            setIsMenuOpen={(isOpen: boolean | ((prev: boolean) => boolean)) => {
              setMenuOpenStatus(isOpen ? 'overflow' : null);
            }}
          />
        )}

        <RightSection ref={rightRef}>
          <ToolbarDivider />
          <UndoButton canUndo={props.canUndo} handleUndo={props.handleUndo} />
          <RedoButton canRedo={props.canRedo} handleRedo={props.handleRedo} />
          <ToolbarDivider />
          <WorkbenchButton
            isIconOnly={maxVisibleItems < 3}
            onExit={props.onExit}
            shouldDisplayTour={shouldDisplayTour}
            currentStep={currentStep}
          />
        </RightSection>
      </StudioToolbar>
    </>
  );
};

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StudioToolbar = styled(Toolbar)<{
  $state?: TransitionStatus;
  $maxWidth: number;
  $minWidth: number;
}>`
  z-index: 10000001;
  transform: ${({ $state }) =>
    $state === 'entered' || $state === 'entering'
      ? 'translateY(0)'
      : 'translateY(calc(-100% - 0.5rem))'};
  animation: ${({ theme, $state }) =>
      $state === 'entering' ? theme.animation.slideInFromTop : 'none'}
    0.5s ease;
  transition: transform 0.5s ease-in-out;
  translate: unset;

  left: 300px;
  right: 300px;
  max-width: ${({ $maxWidth }) => `min(calc(100% - 600px), ${$maxWidth}px)`};
  min-width: ${({ $minWidth }) => $minWidth}px;
  width: auto;

  margin: auto;
`;

const VisibleItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  min-width: 0;
  align-items: center;
  gap: 8px;
`;

import {
  urqlClient,
  UpdateDrawingMutation,
} from '@vizcom/shared/data-access/graphql';

import { SyncedActionType } from '../../SyncedAction';
import { Drawing2dStudio } from '../../useDrawingSyncedState';

export const UpdateDrawingAction: SyncedActionType<
  Drawing2dStudio,
  {
    type: 'updateDrawing';
    name?: string;
    backgroundColor?: string;
    backgroundVisible?: boolean;
  }
> = {
  type: 'updateDrawing',
  optimisticUpdater: ({ payload }, drawing) => {
    if (payload.name) {
      drawing.name = payload.name;
    }
    if (payload.backgroundColor) {
      drawing.backgroundColor = payload.backgroundColor;
    }
    if (payload.backgroundVisible !== undefined) {
      drawing.backgroundVisible = payload.backgroundVisible;
    }
  },
  remoteUpdater: async ({ payload }, drawingId) => {
    await urqlClient.mutation(UpdateDrawingMutation, {
      id: drawingId,
      patch: {
        name: payload.name,
        backgroundColor: payload.backgroundColor,
        backgroundVisible: payload.backgroundVisible,
      },
    });
  },
  metaConstructor() {
    return {
      debounceId: 'updateDrawing',
      delay: 1000,
    };
  },
  undoConstructor: ({ payload }, drawing) => {
    return {
      type: 'updateDrawing',
      name: payload.name ? drawing.name : undefined,
      backgroundColor: payload.backgroundColor
        ? drawing.backgroundColor
        : undefined,
      backgroundVisible: payload.backgroundVisible
        ? drawing.backgroundVisible
        : undefined,
    };
  },
};

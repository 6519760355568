import { useMemo } from 'react';
import { Transition, TransitionStatus } from 'react-transition-group';
import styled, { css } from 'styled-components';
import { Button, FloatingPanel, Toolbar } from '@vizcom/shared-ui-components';

import {
  Drawing2dStudio,
  DrawingLayer,
  useDrawingSyncedState,
} from '../../../lib/useDrawingSyncedState';
import { useSelectionApiStore } from '../selection/useSelectionApi';
import {
  useWorkbenchStudioState,
  WorkbenchStudioToolType,
} from '../studioState';
import { WORKBENCH_2D_STUDIO_ANIMATION_DURATION_MS } from '../utils';
import { SecondaryBrushToolbar } from './Brush/BrushToolbar';
import { SecondaryEraserToolbar } from './Eraser/EraserToolbar';
import { SelectionToolbar } from './Selection/SelectionToolbar';
import { ShapeToolbar } from './Shape/ShapeToolbar';

type SecondaryToolbarProps = {
  hideSecondaryToolbar: boolean;
  isHideSliders: boolean;
  activeLayer: DrawingLayer | undefined;
  drawing: Drawing2dStudio;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  setActiveLayer: (id: string | undefined) => void;
  isInferenceRunning: boolean;
};

export const WorkbenchStudioToolbarSecondary = ({
  hideSecondaryToolbar,
  isHideSliders,
  activeLayer,
  drawing,
  handleAction,
  setActiveLayer,
  isInferenceRunning,
}: SecondaryToolbarProps) => {
  const { tool } = useWorkbenchStudioState();
  const selectionApiStore = useSelectionApiStore();

  const toolbarContents = useMemo(() => {
    switch (tool as WorkbenchStudioToolType) {
      // - TODO: Add transform toolbar once "Warp" / "Distort" / "Perspective Warp"
      // functionality is implemented under the "Transform" tool
      case WorkbenchStudioToolType.Brush:
      case WorkbenchStudioToolType.PaintBucket:
        return <SecondaryBrushToolbar isHideSliders={isHideSliders} />;
      case WorkbenchStudioToolType.Eraser:
      case WorkbenchStudioToolType.MagicEraser:
        return <SecondaryEraserToolbar isHideSliders={isHideSliders} />;
      case WorkbenchStudioToolType.Rectangle:
      case WorkbenchStudioToolType.Ellipse:
      case WorkbenchStudioToolType.Line:
        return <ShapeToolbar isHideSliders={isHideSliders} />;
      case WorkbenchStudioToolType.Lasso:
      case WorkbenchStudioToolType.RectangleSelection:
      case WorkbenchStudioToolType.BrushSelection:
      case WorkbenchStudioToolType.BezierSelection:
      case WorkbenchStudioToolType.AutoSelection:
        if (isInferenceRunning) {
          return null;
        }

        return (
          <SelectionToolbar
            activeLayer={activeLayer}
            drawing={drawing}
            handleAction={handleAction}
            setActiveLayer={setActiveLayer}
          />
        );
      default:
        return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tool, isInferenceRunning, isHideSliders]);

  const hasSelectionMask = selectionApiStore.getState().hasMask;
  const isToolWhereDeselectIsPossible = [
    WorkbenchStudioToolType.Brush,
    WorkbenchStudioToolType.PaintBucket,
    WorkbenchStudioToolType.Eraser,
    WorkbenchStudioToolType.MagicEraser,
  ].includes(tool as WorkbenchStudioToolType);
  const isShowDeselectButton =
    isToolWhereDeselectIsPossible && hasSelectionMask;

  return (
    <Transition
      in={!!toolbarContents && !hideSecondaryToolbar}
      timeout={WORKBENCH_2D_STUDIO_ANIMATION_DURATION_MS}
      appear
      unmountOnExit
    >
      {(state) => (
        <StudioSecondaryToolbar
          position="top-center"
          $state={state}
          isTransparentBg={isShowDeselectButton}
        >
          {isShowDeselectButton ? (
            <>
              <InlineToolbar>{toolbarContents}</InlineToolbar>
              <InlineToolbar>
                <Button
                  variant="secondary"
                  onClick={() => selectionApiStore.getState().deselectMask()}
                  style={{
                    fontSize: '12px',
                    padding: '0 18px',
                    height: '32px',
                  }}
                >
                  Deselect
                </Button>
              </InlineToolbar>
            </>
          ) : (
            toolbarContents
          )}
        </StudioSecondaryToolbar>
      )}
    </Transition>
  );
};

const StudioSecondaryToolbar = styled(Toolbar)<{
  $state?: TransitionStatus;
  isTransparentBg?: boolean; // Visually, this makes the toolbar act like a wrapper containing smaller toolbars, while keeping positioning behavior the same
}>`
  z-index: 10000001;
  top: 68px;
  height: 40px;
  min-width: 100px;

  ${({ isTransparentBg }) =>
    isTransparentBg
      ? css`
          background-color: transparent;
          box-shadow: none;
          gap: 4px;
        `
      : css`
          border-radius: 12px;
          padding: 4px;
        `}

  opacity: ${({ $state }) =>
    $state === 'entered' || $state === 'entering' ? 1 : 0};
  transform: translateY(calc(-100% - 0.5rem));
  transform: ${({ $state }) =>
    $state === 'entered' || $state === 'entering'
      ? 'translateY(0)'
      : 'translateY(calc(-100% - 0.5rem))'};
  transition: transform 0.125s ease-in-out, opacity 0.125s ease-in-out;
`;

const InlineToolbar = styled(FloatingPanel)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  border-radius: 12px; // needs to be 4px more than 'borderRadius.m' (unique value)
  padding: 4px;
`;

import { useState, useCallback, useEffect, useRef } from 'react';

import { ResourceManager } from '../performance';

/**
 * Get the maximum history limit based on browser capabilities
 */
const getHistoryLimit = () => {
  return 50; // Fixed limit of 50 entries for all browsers
};

/**
 * Represents a single entry in the realtime history
 */
export interface HistoryEntry {
  /**
   * Object URL for the entry's image data
   */
  objectUrl: string;
  /**
   * Random seed used to generate this entry
   */
  seed: number;
  /**
   * Raw blob data for the entry
   */
  blob: Blob;
  /**
   * Timestamp of when the entry was created
   */
  timestamp: number;
}

/**
 * Hook for managing a history of realtime inference results.
 * Provides functionality for adding, navigating, and clearing history entries
 * while managing object URLs and resource cleanup.
 *
 * @param resourceManager - Resource manager instance for handling object URLs
 * @returns Object containing history state and management functions
 */
export const useRealtimeHistory = (resourceManager: ResourceManager) => {
  const [history, setHistory] = useState<HistoryEntry[]>([]);
  const [historyIndex, setHistoryIndex] = useState<number>(0);
  const urlsToCleanup = useRef<Set<string>>(new Set());
  const historyLimit = useRef(getHistoryLimit());

  // Cleanup URLs on unmount
  useEffect(() => {
    const urls = urlsToCleanup.current;
    return () => {
      urls.forEach((url) => {
        resourceManager.revokeObjectURL(url);
      });
    };
  }, [resourceManager]);

  const cleanupOldEntries = useCallback(
    (entries: HistoryEntry[]) => {
      const limit = historyLimit.current;
      if (entries.length > limit) {
        const entriesToRemove = entries.slice(0, entries.length - limit);
        entriesToRemove.forEach((entry) => {
          resourceManager.revokeObjectURL(entry.objectUrl);
          urlsToCleanup.current.delete(entry.objectUrl);
        });
        return entries.slice(-limit);
      }
      return entries;
    },
    [resourceManager]
  );

  /**
   * Adds a new entry to the history
   * @param blob - Blob data for the new entry
   * @param seed - Random seed used to generate the entry
   */
  const addHistoryEntry = useCallback(
    (blob: Blob, seed: number) => {
      const objectUrl = resourceManager.createObjectURL(blob);
      urlsToCleanup.current.add(objectUrl);
      const newEntry = { objectUrl, seed, blob, timestamp: Date.now() };

      setHistory((prev) => {
        const newHistory = cleanupOldEntries([...prev, newEntry]);
        return newHistory;
      });

      // Always move to the latest entry when adding new ones
      setHistoryIndex((prev) => history.length);
    },
    [resourceManager, cleanupOldEntries, history.length]
  );

  /**
   * Navigates to a specific history entry
   * @param index - Index in history to navigate to
   * @returns The selected history entry, or null if invalid index
   */
  const navigateHistory = useCallback(
    (index: number): HistoryEntry | null => {
      if (index >= 0 && index < history.length) {
        setHistoryIndex(index);
        return history[index];
      }
      return null;
    },
    [history]
  );

  /**
   * Clears all history entries and releases associated resources
   */
  const clearHistory = useCallback(() => {
    history.forEach((entry) => {
      resourceManager.revokeObjectURL(entry.objectUrl);
      urlsToCleanup.current.delete(entry.objectUrl);
    });
    setHistory([]);
    setHistoryIndex(0);
  }, [history, resourceManager]);

  /**
   * Gets the current history entry
   * @returns The current history entry, or null if history is empty
   */
  const getCurrentEntry = useCallback((): HistoryEntry | null => {
    if (history.length === 0) return null;
    return history[historyIndex] || history[history.length - 1];
  }, [history, historyIndex]);

  return {
    history,
    historyIndex,
    addHistoryEntry,
    navigateHistory,
    clearHistory,
    getCurrentEntry,
  };
};

import { LoggedInAuthGuard } from '@vizcom/auth/ui';

import { Dashboard } from './dashboard';

export const Files = () => {
  return (
    <LoggedInAuthGuard>
      <Dashboard />
    </LoggedInAuthGuard>
  );
};

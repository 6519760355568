import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Button, Modal } from '@vizcom/shared-ui-components';

interface SelectionRect {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface Handle {
  cursor: string;
  position: string;
}

interface ScreenShareAreaSelectorProps {
  isOpen: boolean;
  onClose: () => void;
  imageData: ImageData;
  onAreaSelected: (selection: SelectionRect) => void;
  drawingWidth: number;
  drawingHeight: number;
}

export const ScreenShareAreaSelector: React.FC<
  ScreenShareAreaSelectorProps
> = ({
  isOpen,
  onClose,
  imageData,
  onAreaSelected,
  drawingWidth,
  drawingHeight,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [selection, setSelection] = useState<SelectionRect | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState<{ x: number; y: number } | null>(
    null
  );
  const [activeHandle, setActiveHandle] = useState<string | null>(null);
  const [maintainAspectRatio, setMaintainAspectRatio] = useState(true);
  const [currentAspectRatio, setCurrentAspectRatio] = useState(
    drawingWidth / drawingHeight
  );
  const aspectRatioRef = useRef(currentAspectRatio);

  useEffect(() => {
    aspectRatioRef.current = currentAspectRatio;
  }, [currentAspectRatio]);

  useEffect(() => {
    if (!canvasRef.current || !imageData) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    canvas.width = imageData.width;
    canvas.height = imageData.height;
    ctx.putImageData(imageData, 0, 0);

    const maxWidth = imageData.width * 0.8;
    const maxHeight = imageData.height * 0.8;

    let selectionWidth, selectionHeight;
    const initialAspectRatio = drawingWidth / drawingHeight;

    if (maxWidth / initialAspectRatio <= maxHeight) {
      selectionWidth = maxWidth;
      selectionHeight = selectionWidth / initialAspectRatio;
    } else {
      selectionHeight = maxHeight;
      selectionWidth = selectionHeight * initialAspectRatio;
    }

    setCurrentAspectRatio(initialAspectRatio);

    setSelection({
      x: (imageData.width - selectionWidth) / 2,
      y: (imageData.height - selectionHeight) / 2,
      width: selectionWidth,
      height: selectionHeight,
    });
  }, [imageData, drawingWidth, drawingHeight]);

  const drawOverlay = () => {
    if (!canvasRef.current || !selection) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.putImageData(imageData, 0, 0);

    ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';

    ctx.fillRect(0, 0, canvas.width, selection.y);
    ctx.fillRect(
      0,
      selection.y + selection.height,
      canvas.width,
      canvas.height - (selection.y + selection.height)
    );
    ctx.fillRect(0, selection.y, selection.x, selection.height);
    ctx.fillRect(
      selection.x + selection.width,
      selection.y,
      canvas.width - (selection.x + selection.width),
      selection.height
    );

    ctx.setLineDash([5, 5]);
    ctx.strokeStyle = '#8B5CF6';
    ctx.lineWidth = 2;
    ctx.strokeRect(selection.x, selection.y, selection.width, selection.height);

    const handleSize = 10;
    ctx.setLineDash([]);
    ctx.fillStyle = '#8B5CF6';
    ctx.strokeStyle = '#FFFFFF';
    ctx.lineWidth = 2;

    // Corner handles
    const cornerHandles = [
      { x: selection.x, y: selection.y, position: 'top-left' },
      {
        x: selection.x + selection.width,
        y: selection.y,
        position: 'top-right',
      },
      {
        x: selection.x + selection.width,
        y: selection.y + selection.height,
        position: 'bottom-right',
      },
      {
        x: selection.x,
        y: selection.y + selection.height,
        position: 'bottom-left',
      },
    ];

    // Side handles
    const sideHandles = [
      {
        x: selection.x + selection.width / 2,
        y: selection.y,
        position: 'top',
      },
      {
        x: selection.x + selection.width,
        y: selection.y + selection.height / 2,
        position: 'right',
      },
      {
        x: selection.x + selection.width / 2,
        y: selection.y + selection.height,
        position: 'bottom',
      },
      {
        x: selection.x,
        y: selection.y + selection.height / 2,
        position: 'left',
      },
    ];

    // Draw all handles
    [...cornerHandles, ...sideHandles].forEach((pos) => {
      const isActive = activeHandle === pos.position;
      const displaySize = isActive ? handleSize * 1.2 : handleSize;

      ctx.beginPath();
      ctx.arc(pos.x, pos.y, displaySize, 0, Math.PI * 2);
      ctx.fill();
      ctx.stroke();
    });
  };

  useEffect(() => {
    drawOverlay();
  }, [selection]);

  const getHandle = (x: number, y: number) => {
    if (!selection) return null;
    const handleHitSize = 30;

    // Corner handles
    const cornerHandles = {
      'top-left': { x: selection.x, y: selection.y },
      'top-right': { x: selection.x + selection.width, y: selection.y },
      'bottom-right': {
        x: selection.x + selection.width,
        y: selection.y + selection.height,
      },
      'bottom-left': { x: selection.x, y: selection.y + selection.height },
    };

    // Side handles
    const sideHandles = {
      top: { x: selection.x + selection.width / 2, y: selection.y },
      right: {
        x: selection.x + selection.width,
        y: selection.y + selection.height / 2,
      },
      bottom: {
        x: selection.x + selection.width / 2,
        y: selection.y + selection.height,
      },
      left: { x: selection.x, y: selection.y + selection.height / 2 },
    };

    const allHandles = { ...cornerHandles, ...sideHandles };

    for (const [position, point] of Object.entries(allHandles)) {
      if (
        Math.abs(x - point.x) <= handleHitSize / 2 &&
        Math.abs(y - point.y) <= handleHitSize / 2
      ) {
        return position;
      }
    }
    return null;
  };

  const getCursor = (x: number, y: number) => {
    if (!selection) return 'default';

    const handle = getHandle(x, y);
    if (handle) {
      switch (handle) {
        case 'top-left':
          return 'nw-resize';
        case 'top-right':
          return 'ne-resize';
        case 'bottom-right':
          return 'se-resize';
        case 'bottom-left':
          return 'sw-resize';
        case 'top':
          return 'n-resize';
        case 'right':
          return 'e-resize';
        case 'bottom':
          return 's-resize';
        case 'left':
          return 'w-resize';
      }
    }

    return isInSelection(x, y) ? 'move' : 'default';
  };

  const isInSelection = (x: number, y: number) => {
    if (!selection) return false;
    return (
      x >= selection.x &&
      x <= selection.x + selection.width &&
      y >= selection.y &&
      y <= selection.y + selection.height
    );
  };

  const getMouseCoordinates = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (!canvasRef.current) return { x: 0, y: 0 };

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();

    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;

    return {
      x: (e.clientX - rect.left) * scaleX,
      y: (e.clientY - rect.top) * scaleY,
    };
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!canvasRef.current || !selection) return;

    const { x, y } = getMouseCoordinates(e);
    const handle = getHandle(x, y);

    if (handle) {
      setActiveHandle(handle);
      setDragStart({ x, y });

      // Set aspect ratio behavior based on handle type
      const isCornerHandle = [
        'top-left',
        'top-right',
        'bottom-right',
        'bottom-left',
      ].includes(handle);
      setMaintainAspectRatio(isCornerHandle);
    } else if (isInSelection(x, y)) {
      setIsDragging(true);
      setDragStart({ x: x - selection.x, y: y - selection.y });
    }
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!canvasRef.current || !selection) return;

    const { x, y } = getMouseCoordinates(e);

    const cursor = getCursor(x, y);
    canvasRef.current.style.cursor = cursor;

    if (!dragStart) return;

    if (activeHandle) {
      let newWidth = selection.width;
      let newHeight = selection.height;
      let newX = selection.x;
      let newY = selection.y;

      // Handle corner and side resizing
      switch (activeHandle) {
        case 'top-left':
        case 'bottom-left':
        case 'left': {
          const proposedWidth = selection.width + (selection.x - x);
          newWidth = proposedWidth;
          newX = x;
          break;
        }
        case 'top-right':
        case 'bottom-right':
        case 'right': {
          newWidth = x - selection.x;
          break;
        }
      }

      switch (activeHandle) {
        case 'top-left':
        case 'top-right':
        case 'top': {
          const proposedHeight = selection.height + (selection.y - y);
          newHeight = proposedHeight;
          newY = y;
          break;
        }
        case 'bottom-left':
        case 'bottom-right':
        case 'bottom': {
          newHeight = y - selection.y;
          break;
        }
      }

      // For side handles, only adjust one dimension
      if (activeHandle === 'left' || activeHandle === 'right') {
        newHeight = selection.height;
        newY = selection.y;
      } else if (activeHandle === 'top' || activeHandle === 'bottom') {
        newWidth = selection.width;
        newX = selection.x;
      }

      // Maintain aspect ratio for corner handles
      if (maintainAspectRatio) {
        // Use the current selection's aspect ratio instead of the stored one
        const currentSelectionRatio = selection.width / selection.height;

        // Determine which dimension to prioritize based on the handle
        if (
          ['top-left', 'top-right', 'bottom-right', 'bottom-left'].includes(
            activeHandle
          )
        ) {
          if (activeHandle === 'top-left' || activeHandle === 'bottom-left') {
            // For left corners, adjust width based on height
            newWidth = newHeight * currentSelectionRatio;
            // Adjust x position
            newX = selection.x + selection.width - newWidth;
          } else {
            // For right corners, adjust height based on width
            newHeight = newWidth / currentSelectionRatio;

            // Adjust y position for top handles
            if (activeHandle === 'top-right') {
              newY = selection.y + selection.height - newHeight;
            }
          }
        }
      }

      const minSize = 50;

      if (newWidth >= minSize && newHeight >= minSize) {
        const proposedSelection = {
          x: newX,
          y: newY,
          width: newWidth,
          height: newHeight,
        };

        const adjustedSelection = { ...proposedSelection };

        // Boundary checks
        if (adjustedSelection.x < 0) {
          adjustedSelection.width += adjustedSelection.x;
          adjustedSelection.x = 0;
        }

        if (adjustedSelection.y < 0) {
          adjustedSelection.height += adjustedSelection.y;
          adjustedSelection.y = 0;
        }

        if (
          adjustedSelection.x + adjustedSelection.width >
          canvasRef.current.width
        ) {
          adjustedSelection.width =
            canvasRef.current.width - adjustedSelection.x;
        }

        if (
          adjustedSelection.y + adjustedSelection.height >
          canvasRef.current.height
        ) {
          adjustedSelection.height =
            canvasRef.current.height - adjustedSelection.y;
        }

        if (
          adjustedSelection.width >= minSize &&
          adjustedSelection.height >= minSize
        ) {
          setSelection(adjustedSelection);
        }
      }
    } else if (isDragging) {
      const newX = Math.max(
        0,
        Math.min(x - dragStart.x, canvasRef.current.width - selection.width)
      );
      const newY = Math.max(
        0,
        Math.min(y - dragStart.y, canvasRef.current.height - selection.height)
      );

      setSelection({
        ...selection,
        x: newX,
        y: newY,
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setActiveHandle(null);
    setDragStart(null);
  };

  const resetAspectRatio = () => {
    if (!selection || !canvasRef.current) return;

    // Reset to original aspect ratio
    const originalAspectRatio = drawingWidth / drawingHeight;
    setCurrentAspectRatio(originalAspectRatio);

    // Adjust selection to match the original aspect ratio
    // Prioritize width and center the selection
    const newHeight = selection.width / originalAspectRatio;

    // Make sure the new height fits within the canvas
    const adjustedHeight = Math.min(newHeight, canvasRef.current.height);
    const adjustedWidth = adjustedHeight * originalAspectRatio;

    // Center the selection
    const newX = selection.x + (selection.width - adjustedWidth) / 2;
    const newY = selection.y + (selection.height - adjustedHeight) / 2;

    setSelection({
      x: Math.max(0, newX),
      y: Math.max(0, newY),
      width: adjustedWidth,
      height: adjustedHeight,
    });
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={onClose}>
      <Content onClick={(e) => e.stopPropagation()}>
        <CanvasWrapper>
          <Canvas
            ref={canvasRef}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
          />
        </CanvasWrapper>
        <Actions>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => selection && onAreaSelected(selection)}
          >
            Confirm
          </Button>
        </Actions>
      </Content>
    </Modal>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  pointer-events: auto;
`;

const CanvasWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.surface.secondary};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  overflow: hidden;
  position: relative;
  pointer-events: auto;
`;

const Canvas = styled.canvas`
  max-width: 100%;
  max-height: 70vh;
  object-fit: contain;
  touch-action: none;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  position: relative;
`;

import styled from 'styled-components';

import { zIndexOrder } from '../utils/consts';

export const Container = styled.div<{
  $width: number | string;
  $background: string | undefined | null;
}>`
  position: fixed;
  top: 50%;
  left: 0%;
  width: ${({ $width }) => $width};
  height: 100dvh;
  transform: translateY(-50%);
  z-index: ${zIndexOrder.CanvasZIndex};
  overflow: hidden;
  background: ${({ $background, theme }) =>
    $background || theme.surface.secondary};
`;

import {
  urqlClient,
  CompositeSceneFullData,
  UpdateCompositeSceneElementMutation,
} from '@vizcom/shared/data-access/graphql';

import { SyncedActionType } from '../../SyncedAction';

export const UpdateCompositeSceneElementAction: SyncedActionType<
  CompositeSceneFullData,
  {
    type: 'updateCompositeSceneElement';
    id: string;
    meshes?: any;
    name?: string;
  }
> = {
  type: 'updateCompositeSceneElement',
  optimisticUpdater: ({ payload }, scene) => {
    const element = scene.compositeSceneElements.nodes.find(
      (element) => element.id === payload.id
    );
    if (!element) {
      return;
    }

    element.meshes = payload.meshes ?? element.meshes;
    element.name = payload.name ?? element.name;
  },
  remoteUpdater: async ({ payload }) => {
    const res = await urqlClient.mutation(UpdateCompositeSceneElementMutation, {
      input: {
        id: payload.id,
        patch: {
          meshes: payload.meshes,
          name: payload.name,
        },
      },
    });

    if (res?.error) {
      throw new Error(
        `Error while updating scene, please retry. ${
          res.error.graphQLErrors[0]?.message ?? res.error.message
        }`
      );
    }
  },
  metaConstructor: (payload) => ({
    debounceId: `updateCompositeSceneElement|${payload.id}`,
    delay: 2000,
  }),
};

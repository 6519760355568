import React from 'react';
import { Team, useUpdateTeam } from '@vizcom/shared/data-access/graphql';
import {
  Button,
  Modal,
  ModalActions,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalTitle,
  Text,
  addToast,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';

export const ConfirmArchiveTeamModal = (props: {
  team: Team & { usersOnTeamsByTeamId: { totalCount: number } };
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { team, isOpen, setIsOpen } = props;
  const [updateTeamRes, updateTeam] = useUpdateTeam();

  const handleConfirm = async () => {
    const res = await updateTeam({
      input: {
        id: team.id,
        patch: {
          archived: true,
        },
      },
    });
    if (res.error) {
      return addToast('There was an error while archiving the team', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }
    addToast('Team archived');
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      loading={updateTeamRes.fetching}
      initialFocus
    >
      <ModalHeader>
        <ModalTitle>Are you sure you want to archive "{team.name}"?</ModalTitle>
        <ModalCloseButton />
      </ModalHeader>
      <ModalContent>
        <Text block>
          Archiving this team will remove edit access and hide it in the sidebar
          for all workspace members. Content in the team remains viewable, but
          new files cannot be created.
        </Text>
      </ModalContent>
      <ModalActions>
        <Button onClick={() => setIsOpen(false)} variant="secondary">
          Cancel
        </Button>
        <Button onClick={handleConfirm} variant="danger" data-autofocus="true">
          Archive team
        </Button>
      </ModalActions>
    </Modal>
  );
};

import { Route, useLocation, Routes, Navigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { LoggedOutAuthGuard } from '@vizcom/auth/ui';
import {
  setAccessToken,
  useWebConfig,
} from '@vizcom/shared/data-access/graphql';
import {
  SharedHelpCenter,
  VizcomLogo,
  addToast,
} from '@vizcom/shared-ui-components';
import { IconCircleQuestionmark } from '@vizcom/shared-utils-assets';

import { CarasoulContainer } from './Carousel/CarouselContainer';
import { ForgotPasswordPage } from './forgotPassword/ForgotPasswordPage';
import { ResetPasswordPage } from './resetPassword/ResetPasswordPage';
import { SignInPage } from './signIn/SignInPage';
import { SignUpWithTokenPage } from './signUpWithToken/SignUpWithTokenPage';

const AuthPagesLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const AuthPagesContent = styled.div`
  text-align: center;
  padding: 0 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @media (min-width: 768px) {
    width: 50%;
  }
`;

const LogoWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
`;

const HelpContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  cursor: pointer;
`;

const googleErrorMessageMap: Record<string, string> = {
  default: 'Google authentication failed',
  email_not_verified:
    'Your email is not verified. Please verify your email and try again.',
  sso_provider:
    'This email address domain is linked to a SSO provider. Please use this provider to continue.',
};

export function AuthPages() {
  const { hash } = useLocation();
  const { data } = useWebConfig();
  const theme = useTheme();

  if (hash && hash.startsWith('#access_token=')) {
    const accessToken = hash.slice('#access_token='.length);
    if (accessToken) {
      // auth guard will redirect to correct page
      setAccessToken(accessToken);
    }
  }

  if (hash && hash.startsWith('#google_auth_error=')) {
    const error = hash.slice('#google_auth_error='.length);
    const errorMessage =
      googleErrorMessageMap[error] || googleErrorMessageMap.default;

    // remove hash from url
    window.history.replaceState(null, '', window.location.pathname);

    addToast(errorMessage, {
      type: 'danger',
      id: error, // Prevents duplicate toasts
    });
  }

  return (
    <LoggedOutAuthGuard>
      <AuthPagesLayout>
        <AuthPagesContent>
          {data?.whiteLabelIconUrl ? (
            <LogoWrapper>
              <img
                src={data.whiteLabelIconUrl}
                alt="White label icon"
                style={{ maxHeight: 300, height: '100px', width: 'auto' }}
              />{' '}
            </LogoWrapper>
          ) : (
            <LogoWrapper>
              <VizcomLogo />
            </LogoWrapper>
          )}
          <Routes>
            <Route path="/" element={<SignInPage />} />
            <Route path="/register/token" element={<SignUpWithTokenPage />} />
            <Route
              path="/password-reset/:token"
              element={<ResetPasswordPage />}
            />
            <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </AuthPagesContent>
        <CarasoulContainer />
        <HelpContainer>
          <SharedHelpCenter>
            <IconCircleQuestionmark
              width="24"
              height="24"
              fill={theme.icon.secondary}
            />
          </SharedHelpCenter>
        </HelpContainer>
      </AuthPagesLayout>
    </LoggedOutAuthGuard>
  );
}

import { Dispatch, SetStateAction, useEffect } from 'react';
import styled from 'styled-components';
import {
  BlockStack,
  Button,
  GeneralSelectIcon,
  Text,
} from '@vizcom/shared-ui-components';

import { Drawing2dStudio } from '../../../../lib/useDrawingSyncedState';
import { ColorLibraryPicker } from '../../../AssetLibrary/ColorPicker';
import {
  WorkbenchStudioToolType,
  useWorkbenchStudioState,
} from '../../studioState';
import { InferenceSettings } from '../../useInference';

export const StyleReferenceModalColorSettings = ({
  setInferenceSettings,
  inferenceSettings,
  onClose,
  drawing,
}: {
  drawing: Drawing2dStudio;
  setInferenceSettings: Dispatch<SetStateAction<InferenceSettings>>;
  inferenceSettings: InferenceSettings;
  onClose: () => void;
}) => {
  const setStudioTool = useWorkbenchStudioState((s) => s.setTool);
  const studioState = useWorkbenchStudioState();

  const setInferenceSettingsColor = (color: string) => {
    setInferenceSettings((prev) => ({
      ...prev,
      colorReference: {
        ...prev.colorReference,
        color,
      },
    }));
  };

  useEffect(() => {
    setInferenceSettingsColor(
      inferenceSettings.colorReference.color || '#ff0000'
    );
  }, []);

  return (
    <BlockStack $gap={16}>
      <ColorLibraryPicker
        workbenchId={drawing.workbenchId}
        color={inferenceSettings.colorReference.color || '#ff0000'}
        onChange={setInferenceSettingsColor}
        onEyeDropperClicked={() => {
          studioState.setCanvasColorPickerSession({
            currentColor: inferenceSettings.colorReference.color || '#ff0000',
            callback: setInferenceSettingsColor,
          });
        }}
      />
      <OptionsContainer>
        <OptionContainer>
          <OptionLabel color="subtext">Fill</OptionLabel>
          <FillButton
            variant="secondary"
            size="M"
            onClick={() => {
              setStudioTool(WorkbenchStudioToolType.Lasso);
              onClose();
              studioState.setCanvasColorPickerSession(null);
            }}
          >
            <GeneralSelectIcon />
            Make a selection
          </FillButton>
        </OptionContainer>
      </OptionsContainer>
    </BlockStack>
  );
};

const OptionsContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  column-gap: 8px;
  row-gap: 16px;
`;

const OptionContainer = styled.div`
  display: grid;
  grid-template-columns: subgrid;
  grid-column: 1/4;
  align-items: center;
`;

const OptionLabel = styled(Text)`
  grid-column: 1;
`;

const FillButton = styled(Button)`
  grid-column: 2/4;
`;

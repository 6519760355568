import { formatDistance } from 'date-fns';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TrainPaletteMutation,
  publishTrackingEvent,
  urqlClient,
} from '@vizcom/shared/data-access/graphql';
import { assertExists } from '@vizcom/shared/js-utils';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import {
  Button,
  addToast,
  getCombinedErrorCode,
  useSelectedOrganization,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

import {
  SyncedActionPayloadFromType,
  SyncedActionType,
} from '../../SyncedAction';
import { ClientSideWorkbenchElementData } from '../../clientState';
import { elementById } from '../../utils';
import { CancelPaletteTrainingAction } from './cancelPaletteTrainingAction';

export const TrainPaletteAction: SyncedActionType<
  ClientSideWorkbenchElementData[],
  {
    type: 'trainPalette';
    id: string;
  },
  {
    imagesCount: number;
    paletteName: string;
  }
> = {
  type: 'trainPalette',
  optimisticUpdater: ({ payload }, elements) => {
    const element = elementById(elements, payload.id);
    if (!element || element.__typename !== 'WorkbenchElementPalette') return;

    element.status = 'training';
  },
  remoteUpdater: async ({ payload, meta }) => {
    assertExists(meta.custom);
    const res = await urqlClient.mutation(TrainPaletteMutation, {
      input: {
        id: payload.id,
      },
    });
    if (res?.error) {
      if (getCombinedErrorCode(res.error) === 'palette_storage_limit_reached') {
        addToast(
          `You have reached the maximum palette storage space for your organization. Delete existing trained palettes or upgrade your plan to train more palettes.`,
          {
            type: 'warning',
            cta: {
              Button: ({ ...props }) => {
                const organization = useSelectedOrganization();
                const navigate = useNavigate();
                const onClick = useCallback(() => {
                  props.onDismiss?.();
                  navigate(
                    paths.settings.organization.palette(
                      organization.data?.id ?? ''
                    )
                  );
                }, [navigate, organization.data?.id, props]);

                if (organization.data?.currentUserRole !== 'ADMIN') {
                  return null;
                }

                return (
                  <Button variant={props.variant} onClick={onClick}>
                    Upgrade
                  </Button>
                );
              },
            },
            duration: 10_000,
          }
        );
        return;
      }

      throw new Error(
        `Error while training palette, please retry. ${
          res.error.graphQLErrors[0]?.message ?? res.error.message
        }`
      );
    }

    if (res.data?.trainPalette.palette.estimatedTrainingEndTimestamp) {
      addToast(
        `${
          meta.custom.paletteName
        } will be ready in approximately ${formatDistance(
          new Date(res.data.trainPalette.palette.estimatedTrainingEndTimestamp),
          Date.now()
        )}`
      );
    }

    trackEvent('Train palette');
    publishTrackingEvent({
      type: 'TRAIN',
      data: {
        paletteId: payload.id,
        imagesCount: meta.custom.imagesCount,
      },
    });
  },
  metaConstructor: (payload, elements) => {
    const element = elementById(elements, payload.id);
    if (!element || element.__typename !== 'WorkbenchElementPalette')
      return { custom: { imagesCount: 0, paletteName: '' } };

    return {
      custom: {
        imagesCount: element.sourceImages.nodes.length,
        paletteName: element.name,
      },
    };
  },
  undoConstructor: ({ payload }) => {
    const undoPayload: SyncedActionPayloadFromType<
      typeof CancelPaletteTrainingAction
    > = {
      type: 'cancelPaletteTraining',
      id: payload.id,
    };
    return undoPayload;
  },
};

import { memo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  useUpdateWorkbench,
  useWorkbenchContent,
} from '@vizcom/shared/data-access/graphql';
import {
  Menu,
  MenuDivider,
  MenuItem,
  VizcomLogo,
  addToast,
  DuplicateWorkbenchModal,
  MoveWorkbenchModal,
  HomeIcon,
  Loader,
  formatErrorMessage,
  ToolbarButton,
  MenuIcon,
  Toolbar,
  ToolbarDivider,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

import { ClientSideWorkbenchElementData } from '../../lib/clientState';
import { useWorkbenchSyncedState } from '../../lib/useWorkbenchSyncedState';
import { useIsWorkbenchViewer } from '../../lib/utils';
import { HtmlOverlay } from '../utils/HtmlOverlay';
import { WorkbenchMenuEditItem } from './WorkbenchMenuEditItem';
import { WorkbenchMenuFileItem } from './WorkbenchMenuFileItem';
import { WorkbenchMenuViewItem } from './WorkbenchMenuViewItem';
import { WorkbenchNameInput } from './WorkbenchNameInput';

interface WorkbenchMenuProps {
  workbenchId: string;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
  hasUnsavedChanges: boolean;
  elements: ClientSideWorkbenchElementData[];
  canUndo: boolean;
  undoAction: () => void;
  canRedo: boolean;
  redoAction: () => void;
}

export const WorkbenchFileMenu = memo(
  ({
    workbenchId,
    hasUnsavedChanges,
    elements,
    canUndo,
    undoAction,
    canRedo,
    redoAction,
    handleAction,
  }: WorkbenchMenuProps) => {
    const navigate = useNavigate();
    const { data } = useWorkbenchContent(workbenchId);
    const [updateWorkbenchRes, updateWorkbench] = useUpdateWorkbench();
    const [isEditingName, setIsEditingName] = useState(false);
    const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);
    const [moveModalOpen, setMoveModalOpen] = useState(false);
    const isViewer = useIsWorkbenchViewer();

    const updateWorkbenchName = async (name: string) => {
      if (!workbenchId || data?.name === name || !name) {
        return;
      }

      const res = await updateWorkbench({
        id: workbenchId,
        patch: {
          name: name,
        },
      });

      if (res.error) {
        return addToast('Error while updating workbench name', {
          type: 'danger',
          secondaryText: formatErrorMessage(res.error),
        });
      }

      addToast(`Workbench successfully renamed.`);
    };

    return (
      <HtmlOverlay>
        <StyledToolbar position="top-left">
          <Menu
            renderLabel={(props, interactionProps) => (
              <ToolbarButton
                icon={<VizcomLogo />}
                tooltip="Workbench menu"
                buttonProps={{
                  style: { width: 'auto', paddingRight: 0, gap: '8px' },
                }}
                {...interactionProps}
              >
                <MenuIcon tooltip="" {...interactionProps} />
              </ToolbarButton>
            )}
          >
            <MenuItem
              label={
                <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                  <HomeIcon /> Go home
                </div>
              }
              onClick={() =>
                navigate(
                  data?.folder?.id ? paths.files.folder(data?.folderId) : '/'
                )
              }
            />

            <MenuDivider />

            {!isViewer && (
              <WorkbenchMenuFileItem
                workbenchId={workbenchId}
                setIsEditingName={setIsEditingName}
                setDuplicateModalOpen={setDuplicateModalOpen}
                setMoveModalOpen={setMoveModalOpen}
                elements={elements}
                handleAction={handleAction}
              />
            )}
            <WorkbenchMenuEditItem
              workbenchId={workbenchId}
              canUndo={canUndo}
              undoAction={undoAction}
              canRedo={canRedo}
              redoAction={redoAction}
              elements={elements}
              handleAction={handleAction}
            />
            <WorkbenchMenuViewItem elements={elements} />
            <MenuDivider />

            <MenuItem
              label="Documentation"
              onClick={() => window.open(`https://docs.vizcom.ai/`, '_blank')}
            />
            <MenuItem
              label="Account settings"
              onClick={() => navigate(paths.settings.account.profile())}
            />
          </Menu>

          <ToolbarDivider />

          <WorkbenchNameInput
            handleUpdate={updateWorkbenchName}
            value={data?.name}
            isEditing={isEditingName}
            setIsEditing={setIsEditingName}
          />
          <Loader
            tooltip="Saving..."
            active={hasUnsavedChanges || updateWorkbenchRes.fetching}
          />
        </StyledToolbar>
        <DuplicateWorkbenchModal
          isOpen={duplicateModalOpen}
          setIsOpen={setDuplicateModalOpen}
          workbenchId={workbenchId}
          onDuplicated={(newId) => {
            addToast('File was duplicated. Redirecting to the new file');
            navigate(paths.workbench.file(newId));
          }}
        />
        <MoveWorkbenchModal
          isOpen={moveModalOpen}
          setIsOpen={setMoveModalOpen}
          workbenchId={workbenchId}
          onMoved={() => {
            addToast('File was moved successfully');
          }}
        />
      </HtmlOverlay>
    );
  }
);

const StyledToolbar = styled(Toolbar)`
  width: 250px;
`;

import styled from 'styled-components';
import { useWebConfig } from '@vizcom/shared/data-access/graphql';

import { LoginAnchor } from '../LoginAnchor';
import AzureBaseLogo from '../assets/azure-logo.svg?react';

export const AzureLogo = styled(AzureBaseLogo)`
  cursor: pointer;
  width: 16px;
  height: 16px;
  color: white;
`;

export const LogInWithAzureButton = () => {
  const { data } = useWebConfig();

  if (!data?.loginWithAzure) {
    return null;
  }
  return (
    <AzureButton
      rel="noopener noreferrer"
      href={
        (import.meta.env.VITE_API_BASE_URL_OVERIDE ?? '/api/v1') + '/auth/azure'
      }
    >
      <span>Sign In With SSO</span>
    </AzureButton>
  );
};

export const AzureButton = styled(LoginAnchor)`
  border: none;
  background-color: ${({ theme }) => theme.azure.primary};
  color: #ffffff;

  &:hover {
    background-color: ${({ theme }) => theme.azure.hover};
  }
`;

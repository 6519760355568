import { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  CarretRightIcon,
  HistoryIcon,
  Tooltip,
} from '@vizcom/shared-ui-components';

export const CompositeSceneAIHistory = ({
  renders,
  setActiveRenders,
}: {
  renders: (ArrayBuffer | null)[];
  setActiveRenders: (renders: (ArrayBuffer | null)[]) => void;
}) => {
  const theme = useTheme();
  const [activeRendersIndex, setActiveRendersIndex] = useState<number | null>(
    0
  );
  const [expanded, setExpanded] = useState<boolean>(false);
  const [rendersCache, setRendersCache] = useState<
    (ArrayBuffer | null)[] | null
  >(null);
  const [historyStack, setHistoryStack] = useState<
    Array<(ArrayBuffer | null)[]>
  >([]);
  const historyStackMaxLength = 19;

  useEffect(() => {
    if (
      rendersCache === renders ||
      !renders ||
      historyStack.includes(renders)
    ) {
      return;
    }

    setHistoryStack((stack) => {
      const newStack = [...stack, renders].slice(-historyStackMaxLength);

      setActiveRendersIndex(newStack.length - 1);

      return newStack;
    });
    setRendersCache(renders);
  }, [renders, rendersCache]);

  const switchActivePreview = (
    event: React.PointerEvent<HTMLDivElement>,
    item: (ArrayBuffer | null)[],
    index: number
  ) => {
    if (event.buttons !== 1) {
      return;
    }

    setActiveRenders(item);
    setActiveRendersIndex(index);
  };

  return (
    <>
      {expanded && (
        <Tooltip tip="Hide History" position="top">
          <Button
            variant="tertiary"
            size="icon"
            onClick={() => setExpanded(false)}
          >
            <CarretRightIcon />
          </Button>
        </Tooltip>
      )}
      <HistoryList $expanded={expanded}>
        <Frames>
          {historyStack.map((item, index) => (
            <PreviewFrame
              key={index}
              $gradient={
                activeRendersIndex !== null
                  ? 1.0 -
                    Math.abs(activeRendersIndex - index) /
                      Math.min(historyStack.length, historyStackMaxLength)
                  : 0.0
              }
              onPointerDown={(event) => switchActivePreview(event, item, index)}
              onPointerMove={(event) => switchActivePreview(event, item, index)}
            />
          ))}
        </Frames>
        {expanded && (
          <HistoryLabel>
            <ArrowLeftIcon />
            <span>swipe</span>
            <ArrowRightIcon />
          </HistoryLabel>
        )}
      </HistoryList>
      {!expanded && (
        <Tooltip
          tip={
            historyStack.length > 0
              ? 'Show History'
              : 'Generate images to see history'
          }
          position="top"
        >
          <Button
            variant="tertiary"
            size="icon"
            disabled={historyStack.length === 0}
            onClick={() => setExpanded(true)}
          >
            <HistoryIcon style={{ color: theme.icon.primary }} />
          </Button>
        </Tooltip>
      )}
    </>
  );
};

const HistoryList = styled.div<{
  $expanded: boolean;
}>`
  position: relative;
  display: inline;
  margin-left: ${({ $expanded }) => ($expanded ? '0' : '-8px')};
  width: ${({ $expanded }) => ($expanded ? 200 : 0)}px;
  height: 100%;
  background-color: ${({ theme }) => theme.surface.secondary};
  border-radius: ${({ theme }) => theme.borderRadius.s};
  overflow: hidden;
  transition: width 0.3s ease;
`;

const Frames = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
`;

const HistoryLabel = styled.div`
  position: absolute;
  display: flex;
  gap: 8px;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.text.success};
  z-index: 2;
  pointer-events: none;

  &:before {
    position: absolute;
    top: 50%;
    left: 10px;
    width: 5px;
    height: 1px;
    transform: translateY(-50%);
    background-color: ${({ theme }) => theme.text.success};
    content: '';
    z-index: 2;
  }

  &:after {
    position: absolute;
    top: 50%;
    right: 10px;
    width: 5px;
    height: 1px;
    transform: translateY(-50%);
    background-color: ${({ theme }) => theme.text.success};
    content: '';
    z-index: 2;
  }
`;

const PreviewFrame = styled.div<{
  $gradient: number;
}>`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.surface.tertiary};

  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.deprecated.tertiary.disabled};
    opacity: ${({ $gradient }) => $gradient};
    z-index: 1;
    content: '';
  }
`;

import { shaderMaterial } from '@react-three/drei';
import { ThreeEvent, extend, useThree } from '@react-three/fiber';
import { useEffect, useRef } from 'react';
import {
  Color,
  ColorRepresentation,
  Mesh,
  OrthographicCamera,
  ShaderMaterial,
} from 'three';

import { WorkbenchContentRenderingOrder } from '../../../WorkbenchContent';
import { sdCircle, sdCross } from '../../../lib/glsl';
import { screenPositionToLayer } from '../../helpers';
import { VizcomRenderingOrderEntry } from '../../utils/threeRenderingOrder';
import { useLayersCompositor } from '../LayersCompositor/context';
import { useWorkbenchStudioState } from '../studioState';
import { EventMesh } from './EventMesh';

export const CanvasColorPicker = ({
  width,
  height,
}: {
  width: number;
  height: number;
}) => {
  const colorPickerCursorPreviewRef = useRef<Mesh | null>(null);
  const studioState = useWorkbenchStudioState();
  const layerCompositor = useLayersCompositor();
  const camera = useThree((state) => state.camera as OrthographicCamera);
  const events = useThree((state) => state.events);
  const cursor = useThree((state) => state.pointer);
  const eventPlaneRef = useRef<Mesh>(null!);

  useEffect(() => {
    const pointerEnter = () => {
      if (colorPickerCursorPreviewRef.current) {
        colorPickerCursorPreviewRef.current.visible = true;
      }
    };
    const pointerLeave = () => {
      if (colorPickerCursorPreviewRef.current) {
        colorPickerCursorPreviewRef.current.visible = false;
      }
    };

    const dom = events.connected as HTMLElement;
    if (!dom) {
      return;
    }
    dom.addEventListener('pointerenter', pointerEnter);
    dom.addEventListener('pointerleave', pointerLeave);

    return () => {
      dom.removeEventListener('pointerenter', pointerEnter);
      dom.removeEventListener('pointerleave', pointerLeave);
    };
  }, [events.connected]);

  // set initial position when first becoming active
  useEffect(() => {
    if (
      studioState.canvasColorPickerSession &&
      colorPickerCursorPreviewRef.current
    ) {
      const canvas = events.connected as HTMLCanvasElement;
      if (!events.connected) {
        return;
      }
      const rect = canvas.getBoundingClientRect();

      const x = ((cursor.x + 1) / 2) * rect.width + rect.left;
      const y = ((1 - cursor.y) / 2) * rect.height + rect.top;

      const position = screenPositionToLayer(
        [x, y],
        camera,
        eventPlaneRef.current,
        [width, height]
      );

      colorPickerCursorPreviewRef.current.position.set(
        position[0] - width / 2,
        height + position[1] * -1 - height / 2,
        10
      );

      const color = layerCompositor.getPixelColor(position[0], position[1]);

      const material = colorPickerCursorPreviewRef.current
        .material as ShaderMaterial;
      material.uniforms.selectedColor.value.setRGB(
        color[0] / 255,
        color[1] / 255,
        color[2] / 255
      );
      material.uniformsNeedUpdate = true;

      colorPickerCursorPreviewRef.current.visible = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studioState.canvasColorPickerSession]);

  if (!studioState.canvasColorPickerSession) {
    return null;
  }

  const getPointerCanvasPosition = (e: ThreeEvent<PointerEvent>) => {
    return screenPositionToLayer(
      [e.clientX, e.clientY],
      camera,
      eventPlaneRef.current,
      [width, height]
    );
  };

  return (
    <>
      <mesh
        ref={colorPickerCursorPreviewRef}
        onBeforeRender={(_r, _s, camera, _g, material) => {
          const { zoom } = camera as OrthographicCamera;
          const { uniforms } = material as ShaderMaterial;
          uniforms.scale.value = 1 / zoom;
        }}
      >
        <planeGeometry args={[2, 2]} />
        <colorPickerCursorPreviewMaterial
          transparent
          currentColor={studioState.canvasColorPickerSession.currentColor}
          selectedColor={studioState.canvasColorPickerSession.currentColor}
        />
      </mesh>
      <EventMesh
        drawingSize={[width, height]}
        ref={eventPlaneRef}
        eventMeshProps={{
          userData: {
            vizcomRenderingOrder: [
              {
                zIndex: WorkbenchContentRenderingOrder.indexOf('overlays'),
                escapeZIndexContext: true,
              } satisfies VizcomRenderingOrderEntry,
            ],
          },
          onPointerMove(event) {
            const position = getPointerCanvasPosition(event);
            const color = layerCompositor.getPixelColor(
              position[0],
              position[1]
            );

            if (colorPickerCursorPreviewRef.current) {
              colorPickerCursorPreviewRef.current.position.set(
                position[0] - width / 2,
                height + position[1] * -1 - height / 2,
                10
              );
              const material = colorPickerCursorPreviewRef.current
                .material as ShaderMaterial;
              material.uniforms.selectedColor.value.setRGB(
                color[0] / 255,
                color[1] / 255,
                color[2] / 255
              );
              material.uniformsNeedUpdate = true;
            }
          },
          onPointerDown(event) {
            event.stopPropagation();
            const position = getPointerCanvasPosition(event);
            const color = layerCompositor.getPixelColor(
              position[0],
              position[1]
            );
            const parsedColor = new Color();
            parsedColor.setRGB(color[0] / 255, color[1] / 255, color[2] / 255);
            if (studioState.canvasColorPickerSession !== null) {
              studioState.canvasColorPickerSession.callback(
                '#' + parsedColor.getHexString()
              );
            }
            studioState.setCanvasColorPickerSession(null);
          },
        }}
      />
    </>
  );
};

const ColorPickerCursorPreviewMaterialImpl = shaderMaterial(
  {
    currentColor: new Color(),
    selectedColor: new Color(),
    scale: 1,
    size: 32,
    ring: 4,
  },
  /* glsl */ `
uniform float scale;
uniform float size;
varying vec2 vUv;

void main() {
  vUv = position.xy * (size + 2.0);
  gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(vUv * scale, 0, 1.);
}`,
  /* glsl */ `
uniform float size;
uniform float ring;

${sdCircle}
${sdCross}

varying vec2 vUv;
uniform vec3 currentColor;
uniform vec3 selectedColor;

void main() {
  float dist = sdCircle(vUv, size);
  dist = abs(dist + ring + 1.0) - ring - 1.0;
  vec3 color = vUv.y > 0.0 ? selectedColor : currentColor;

  float distCross = sdCross(vUv, size * vec2(0.1, 0.01), 0.0);
  if (distCross < dist) {
    dist = distCross;
    color = vec3(0.0);
  }

  color = mix(
    color,
    mix(vec3(0.0), vec3(1.0), smoothstep(-1.0, 1.0, dist)),
    smoothstep(-2.0, 2.0, dist)
  );
  float alpha = 1.0 - smoothstep(-2.0, 2.0, dist);
  gl_FragColor = clamp(vec4(color, alpha), 0.0, 1.0);

  #include <tonemapping_fragment>
  #include <colorspace_fragment>
  #include <premultiplied_alpha_fragment>
  #include <dithering_fragment>
}
`
);

extend({
  ColorPickerCursorPreviewMaterial: ColorPickerCursorPreviewMaterialImpl,
});

type ColorPickerCursorPreviewMaterialType =
  JSX.IntrinsicElements['shaderMaterial'] & {
    currentColor: ColorRepresentation;
    selectedColor: ColorRepresentation;
  };

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      colorPickerCursorPreviewMaterial: ColorPickerCursorPreviewMaterialType;
    }
  }
}

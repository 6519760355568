import { encode, decode } from '@msgpack/msgpack';
import * as Sentry from '@sentry/react';
import { useRerender } from 'libs/shared/ui/components/src/lib/hooks/useRerender';
import { useEffect, useMemo, useRef, useState } from 'react';
import { z } from 'zod';
import {
  CompositeSceneFullData,
  getAccessToken,
} from '@vizcom/shared/data-access/graphql';
import {
  compositeSceneClientMessage,
  compositeSceneServerMessage,
  compositeScenePreviewResponse,
} from '@vizcom/shared/data-shape';

import { RenderingOption } from '../components/compositeScene/compositeSceneEditor/types';
import { cropBlobImage } from '../components/compositeScene/compositeSceneEditor/utils/cropBlobImage';

type ClientMessage = z.infer<typeof compositeSceneClientMessage>;

export const REALTIME_PREVIEW_CUSTOM_WORKFLOW_LOCALSTORAGE_KEY =
  'vizcom:realtimePreview:customWorkflow';

const getWebsocketUrl = (): string => {
  let wsUrl: string = '';

  wsUrl = `${location.protocol.replace('http', 'ws')}//realtime.${
    location.host.endsWith('.vercel.app') ? 'beta.vizcom.ai' : location.host
  }/composite_scene`;

  if (wsUrl.includes('localhost')) {
    wsUrl = wsUrl.replace('wss', 'ws').replace('4200', '3303');
    // .replace('realtime.', '');
  }

  return wsUrl;
};

const getBuffersFromImages = async (
  images: Array<Partial<Record<RenderingOption, Blob>>>
) => {
  const buffers: Record<RenderingOption, Uint8Array | null>[] = [];

  for (let imageSetIndex = 0; imageSetIndex < images.length; imageSetIndex++) {
    const imageSet = images[imageSetIndex];

    await Promise.all(
      Object.entries(imageSet).map(async ([imageType, payloadContent]) => {
        if (!buffers[imageSetIndex]) {
          buffers[imageSetIndex] = {
            color: null,
            depth: null,
            normal: null,
            depthPlusAO: null,
            sobel: null,
            sobelDepth: null,
          };
        }

        if (Object.keys(RenderingOption).includes(imageType)) {
          buffers[imageSetIndex][imageType as RenderingOption] = new Uint8Array(
            await (payloadContent as Blob).arrayBuffer()
          );
        }
      })
    );
  }

  return buffers;
};

// NOTE Helper-class ensuring simple sync-like syntax and internal connection / auth handling
class SyncSocket {
  socket: WebSocket | null = null;

  url: string;
  compositeSceneId: string;

  isAuthenticated = false;
  authenticationPromise: Promise<void> | null = null;
  idleTimeout: NodeJS.Timeout | null = null;

  constructor(url: string, compositeSceneId: string) {
    this.url = url;
    this.compositeSceneId = compositeSceneId;
  }

  setUrl(url: string) {
    if (this.url === url) return;

    this.url = url;

    this.isAuthenticated = false;
  }

  setCompositeSceneId(compositeSceneId: string) {
    if (this.compositeSceneId === compositeSceneId) return;

    this.compositeSceneId = compositeSceneId;

    this.isAuthenticated = false;
  }

  async ensureConnection() {
    if (
      this.socket &&
      this.socket.url === this.url &&
      this.socket.readyState === WebSocket.OPEN
    ) {
      return Promise.resolve();
    }

    if (this.socket && this.socket.readyState === WebSocket.CONNECTING) {
      await new Promise<void>((resolve) => {
        const resolveOnConnection = () => () => {
          resolve();

          this.socket!.removeEventListener('open', resolveOnConnection);
        };

        this.socket!.addEventListener('open', resolveOnConnection);
      });
    }

    this.isAuthenticated = false;

    this.socket = new WebSocket(this.url);

    this.socket.binaryType = 'arraybuffer';

    await new Promise<void>((resolve, reject) => {
      this.socket!.addEventListener('open', () => {
        resolve();
      });

      this.socket!.addEventListener('close', () => {
        this.dispose();

        reject();
      });
    });
  }

  async ensureAuth() {
    if (this.isAuthenticated) {
      return Promise.resolve();
    }

    if (this.authenticationPromise) {
      await this.authenticationPromise;

      return;
    }

    const accessToken = getAccessToken();

    if (!accessToken) {
      throw new Error('No access token registered');
    }

    const authRequest: ClientMessage = {
      type: 'auth',
      accessToken,
      compositeSceneId: this.compositeSceneId,
    };

    this.authenticationPromise = new Promise<void>((resolve, reject) => {
      this.expectOne(
        'authResponse',
        compositeSceneServerMessage,
        () => {
          this.isAuthenticated = true;
          this.authenticationPromise = null;

          resolve();
        },
        () => {
          // NOTE Request timed out
          this.dispose();

          reject();
        }
      );

      this.socket!.send(JSON.stringify(authRequest));
    });

    await this.authenticationPromise;
  }

  expectOne(
    messageType: string,
    parser: z.Schema,
    listener: (message: Zod.infer<typeof parser>) => void,
    failureListener: () => void
  ) {
    let requestTimeout: NodeJS.Timeout | null = null;
    let resolved = false;

    const callback = ({ data }: MessageEvent<string | ArrayBuffer>) => {
      try {
        let rawMessage;

        if (typeof data === 'string') {
          rawMessage = JSON.parse(data);
        } else if (data instanceof ArrayBuffer) {
          rawMessage = decode(data);
        }

        if (rawMessage.type !== messageType) {
          return;
        }

        const serverMessage = parser.parse(rawMessage);

        resolved = true;

        listener(serverMessage);
      } catch (error) {
        console.error('socket', 'error parsing server message', { error });

        failureListener();

        Sentry.captureException(error);
      }

      if (requestTimeout) {
        clearTimeout(requestTimeout);
      }

      this.socket!.removeEventListener('message', callback);

      this.idle();
    };

    this.socket!.addEventListener('message', callback);

    requestTimeout = setTimeout(() => {
      if (!this.socket || resolved) {
        return;
      }

      this.socket!.removeEventListener('message', callback);

      failureListener();

      this.idle();
    }, 30 * 1000);

    this.idle();
  }

  send(message: object) {
    if (!this.socket) {
      throw new Error('Socket is not open');
    }

    let payload: string | Uint8Array;

    if (
      Object.values(message).some(
        (value) => value instanceof ArrayBuffer || ArrayBuffer.isView(value)
      )
    ) {
      payload = encode(message);
    } else {
      payload = JSON.stringify(message);
    }

    this.socket.send(payload);

    this.idle();
  }

  idle() {
    if (this.idleTimeout) {
      clearTimeout(this.idleTimeout);
    }

    this.idleTimeout = setTimeout(() => {
      this.dispose();
    }, 10 * 1000);
  }

  dispose() {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }

    this.isAuthenticated = false;
    this.authenticationPromise = null;
  }
}

let syncSocket: SyncSocket | null = null;

export const useCompositeSceneRender = (compositeSceneId: string) => {
  const [renders, setRenders] = useState<(Uint8Array | null)[]>([]);
  const previewRequestedRef = useRef(false);
  const wsUrl = useMemo(() => getWebsocketUrl(), []);

  /**
   * NOTE Websockets are async and unpredictable, so previewRequestedRef remains a ref to ensure
   *      WS listeners always have the latest value.
   *      Using forceUpdateExternalProps pushes the latest state of previewRequestedRef to hook
   *      consumers.
   */
  const forceUpdateExternalProps = useRerender();

  useEffect(() => {
    if (syncSocket) {
      syncSocket.dispose();
      syncSocket = null;
    }
  }, []);

  const requestPreview = useMemo(
    () =>
      async ({
        compositeScene,
        getCanvasImagesForAllViews,
      }: {
        compositeScene: CompositeSceneFullData;
        getCanvasImagesForAllViews: () => Promise<
          Array<Partial<Record<RenderingOption, Blob>>>
        >;
      }) => {
        if (previewRequestedRef.current) {
          return;
        }

        try {
          previewRequestedRef.current = true;
          forceUpdateExternalProps();

          if (!syncSocket) {
            syncSocket = new SyncSocket(wsUrl, compositeSceneId);
          }

          syncSocket.setUrl(wsUrl);
          syncSocket.setCompositeSceneId(compositeSceneId);

          await syncSocket.ensureConnection();
          await syncSocket.ensureAuth();

          const images = await getCanvasImagesForAllViews();
          const buffers = await getBuffersFromImages(images);
          const customWorkflowRaw = localStorage.getItem(
            REALTIME_PREVIEW_CUSTOM_WORKFLOW_LOCALSTORAGE_KEY
          );
          const results: (Uint8Array | null)[] = Array(images.length).fill(
            null
          );

          for (
            let imageSetIndex = 0;
            imageSetIndex < buffers.length;
            imageSetIndex++
          ) {
            const { color } = buffers[imageSetIndex];

            await new Promise<void>((resolve) => {
              if (!syncSocket) return resolve();

              syncSocket.expectOne(
                'previewResponse',
                compositeScenePreviewResponse,
                async (response) => {
                  const aspectRatio =
                    compositeScene.width / compositeScene.height;
                  let resultWidth = 1024;
                  let resultHeight = 1024;

                  if (aspectRatio > 1) {
                    resultHeight = resultWidth / aspectRatio;
                  } else {
                    resultWidth = resultHeight * aspectRatio;
                  }

                  const croppedImage = await cropBlobImage({
                    blob: new Blob([response.image]),
                    width: resultWidth,
                    height: resultHeight,
                  });
                  const arrayBuffer = await croppedImage.arrayBuffer();
                  const typedArray = new Uint8Array(arrayBuffer);

                  results[imageSetIndex] = typedArray;

                  resolve();
                },
                () => {
                  // NOTE Request timed out

                  resolve();
                }
              );

              syncSocket.send({
                type: 'previewRequest',
                inputImage: color!,
                prompt: compositeScene.prompt,
                weight: compositeScene.influence,
                workflow: customWorkflowRaw
                  ? JSON.parse(customWorkflowRaw)
                  : null,
              });
            });
          }

          setRenders(results);

          previewRequestedRef.current = false;
          forceUpdateExternalProps();
        } catch (error) {
          previewRequestedRef.current = false;
          forceUpdateExternalProps();
        }
      },
    [wsUrl, compositeSceneId]
  );

  return {
    renders,
    setRenders,
    requestRendersUpdate: async (
      compositeScene: CompositeSceneFullData,
      getCanvasImagesForAllViews: () => Promise<
        Array<Partial<Record<RenderingOption, Blob>>>
      >
    ) => {
      await requestPreview({ compositeScene, getCanvasImagesForAllViews });
    },
    rendersRequested: previewRequestedRef.current,
  };
};

import { useThree } from '@react-three/fiber';
import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { OrthographicCamera } from 'three';

import { WorkbenchContentRenderingOrder } from '../../WorkbenchContent';
import { screenPositionToWorld } from '../helpers';
import { CustomText } from './CustomText';
import { VizcomRenderingOrderEntry } from './threeRenderingOrder';

export const FloatingMouseText = ({ children }: { children: string }) => {
  const theme = useTheme();
  const camera = useThree((s) => s.camera);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      const position = screenPositionToWorld(
        [e.clientX, e.clientY],
        camera as OrthographicCamera
      );
      setPosition({ x: position[0], y: position[1] });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <group
      userData={{
        vizcomRenderingOrder: [
          {
            zIndex: WorkbenchContentRenderingOrder.indexOf('overlays'),
          } satisfies VizcomRenderingOrderEntry,
        ],
      }}
    >
      <CustomText
        userData={{
          globalCursor: 'crosshair',
        }}
        font="Inter"
        fontSize={16}
        position={[position.x, position.y, 0]}
        color={theme.text.subtext}
        content={children}
        fontWeight={'medium'}
        anchorX="left"
        anchorY="top"
      />
    </group>
  );
};

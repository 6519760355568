import { ReactNode } from 'react';
import styled from 'styled-components';
import { logStep } from '@vizcom/shared/js-utils';
import {
  FitToScreenIcon,
  MinusIcon,
  PlusIcon,
  Text,
  Toolbar,
  ToolbarButton,
  useKeyboardShortcut,
  ToolbarDivider,
} from '@vizcom/shared-ui-components';

import { useCameraZoom } from './helpers';
import { Hotkey } from './studio/style';
import { HtmlOverlay } from './utils/HtmlOverlay';
import { CameraLimits, useMapControls } from './utils/mapControls/utils';

interface WorkbenchControlsProps {
  cameraLimits: CameraLimits;
  prependControls?: ReactNode;
  appendControls?: ReactNode;
  collapsed?: boolean;
  onFitToScreen: () => void;
}

export const ZOOM_CLICK_STEP = 0.05;

export const WorkbenchViewportControls = ({
  cameraLimits,
  prependControls,
  appendControls,
  collapsed,
  onFitToScreen,
}: WorkbenchControlsProps) => {
  const zoom = useCameraZoom();
  const controls = useMapControls();

  const onZoomIn = () => {
    controls.moveTo({
      zoom: logStep(
        zoom,
        ZOOM_CLICK_STEP,
        cameraLimits.zoomMin,
        cameraLimits.zoomMax
      ),
    });
  };

  const onZoomOut = () => {
    controls.moveTo({
      zoom: logStep(
        zoom,
        -ZOOM_CLICK_STEP,
        cameraLimits.zoomMin,
        cameraLimits.zoomMax
      ),
    });
  };

  useKeyboardShortcut('escape', onFitToScreen);
  useKeyboardShortcut(['+', '='], onZoomIn, { ctrl: true });
  useKeyboardShortcut('-', onZoomOut, { ctrl: true });
  useKeyboardShortcut(['+', '='], onZoomIn);
  useKeyboardShortcut('-', onZoomOut);

  return (
    <HtmlOverlay>
      <ViewportToolbar
        $collapsed={collapsed}
        position="bottom-right"
        style={{
          display: controls?.enabled ? 'flex' : 'none',
        }}
      >
        {prependControls}
        <ToolbarButton
          onClick={onFitToScreen}
          icon={<FitToScreenIcon />}
          tooltip={
            <>
              Fit to screen <Hotkey>Esc</Hotkey>
            </>
          }
        />
        <ToolbarDivider />
        <ZoomWrapper>
          <ToolbarButton
            onClick={onZoomOut}
            icon={<MinusIcon />}
            tooltip={
              <>
                Zoom out <Hotkey>-</Hotkey>
              </>
            }
          />
          <ZoomValue $zoom={zoom}>{Math.round(zoom * 100)}%</ZoomValue>
          <ToolbarButton
            onClick={onZoomIn}
            icon={<PlusIcon />}
            tooltip={
              <>
                Zoom in <Hotkey>+</Hotkey>
              </>
            }
          />
        </ZoomWrapper>

        {appendControls}
      </ViewportToolbar>
    </HtmlOverlay>
  );
};

const ViewportToolbar = styled(Toolbar)<{ $collapsed?: boolean }>`
  max-width: ${({ $collapsed }) => ($collapsed ? '48px' : '250px')};
  transition: max-width 0.3s;
  overflow: hidden;
`;

const ZoomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ZoomValue = styled(Text)<{ $zoom: number }>`
  width: 30px;
  transform: scaleX(${({ $zoom }) => ($zoom > 10 ? 0.7 : $zoom > 1 ? 0.8 : 1)});
  transform-origin: 0 17px;
  text-align: center;
  font-feature-settings: 'tnum';
`;

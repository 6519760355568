import { useStore } from '@react-three/fiber';
import styled from 'styled-components';
import { OrthographicCamera } from 'three';
import {
  DeleteWorkbenchElementsMutation,
  publishTrackingEvent,
  urqlClient,
} from '@vizcom/shared/data-access/graphql';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import {
  Button,
  MenuDivider,
  Text,
  triggerDirectModal,
} from '@vizcom/shared-ui-components';

import { PALETTE_NAME_HEIGHT } from '../../elements/palette/PaletteName';
import { useMapControls } from '../../utils/mapControls/utils';
import { Palette } from './PaletteSelector';

type LocalPaletteOptionsProps = {
  palette: Palette;
  onExit?: () => void;
  in2DStudio?: boolean;
};

export const LocalPaletteOptions = ({
  palette,
  onExit,
  in2DStudio,
}: LocalPaletteOptionsProps) => {
  const controls = useMapControls();
  const store = useStore();

  const handleGoToSourcePalette = () => {
    if (in2DStudio && onExit) {
      onExit();
    }
    // wait for exit of 2D studio in workbench before moving the camera
    setTimeout(() => {
      if (palette.workbenchElement) {
        const camera = store.getState().camera as OrthographicCamera;
        const zoomForWidth =
          (camera.right - camera.left) / palette.workbenchElement.width;
        const zoomForHeight =
          (camera.top - camera.bottom) / palette.workbenchElement.height;
        const zoom = Math.min(zoomForWidth, zoomForHeight) * 0.7;

        controls.moveTo({
          x: palette.workbenchElement.x,
          y: palette.workbenchElement.y + PALETTE_NAME_HEIGHT / 2,
          zoom,
        });
      }
    }, 150);
  };

  const handleDeletePalette = () => {
    return triggerDirectModal({
      title: `Delete ${palette.name}`,
      content: (
        <Text>
          Deleting this palette from the local library will delete the linked
          source palette as well.
        </Text>
      ),
      action: (
        <>
          <Button variant="tertiary" data-close-modal>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={async () => {
              const res = await urqlClient.mutation(
                DeleteWorkbenchElementsMutation,
                {
                  deleteWorkbenchElementsInput: {
                    ids: [palette.id],
                  },
                }
              );

              if (res?.error) {
                throw new Error(
                  `Error while deleting elements, please retry. ${
                    res.error.graphQLErrors[0]?.message ?? res.error.message
                  }`
                );
              }
              trackEvent('Delete Elements');

              publishTrackingEvent({
                type: 'DELETE_ELEMENTS',
                data: {
                  workbenchIds: palette.workbenchId
                    ? [palette.workbenchId]
                    : [],
                  elementIds: [palette.id],
                },
              });
            }}
            data-autofocus="true"
            data-close-modal
          >
            Delete
          </Button>
        </>
      ),
    });
  };

  return (
    <Container>
      <Button size="M" variant="tertiary" onClick={handleGoToSourcePalette}>
        Go to source
      </Button>
      <Button size="M" variant="tertiary" onClick={handleDeletePalette}>
        Delete...
      </Button>
      <MenuDivider />
    </Container>
  );
};

const Container = styled.div`
  max-width: 120px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

import { ResourceManager } from '../performance';

/**
 * Size threshold (in total pixels) below which we use simple comparison
 */
const SIMPLE_COMPARISON_THRESHOLD = 10000; // 100x100 pixels

/**
 * Simple pixel-by-pixel comparison for small canvases
 */
export const compareSmallCanvasStates = (
  prevState: ImageData | null,
  newState: ImageData
): boolean => {
  if (!prevState) return false;
  if (
    prevState.width !== newState.width ||
    prevState.height !== newState.height
  )
    return false;

  // Compare every 4th pixel (RGBA) for small canvases
  for (let i = 0; i < prevState.data.length; i += 4) {
    if (
      prevState.data[i] !== newState.data[i] ||
      prevState.data[i + 1] !== newState.data[i + 1] ||
      prevState.data[i + 2] !== newState.data[i + 2] ||
      prevState.data[i + 3] !== newState.data[i + 3]
    ) {
      return false;
    }
  }
  return true;
};

/**
 * Efficient canvas state hashing using djb2 algorithm
 * Samples a subset of pixels for performance while maintaining good uniqueness
 */
export const getCanvasStateHashDjb2 = (imageData: ImageData): number => {
  const { data, width, height } = imageData;
  let hash = 5381;

  // Sample every Nth pixel for performance
  const sampleRate = Math.max(1, Math.floor(Math.sqrt(width * height) / 100));

  for (let i = 0; i < data.length; i += sampleRate * 4) {
    // djb2 hash algorithm
    hash = (hash << 5) + hash + data[i];
    hash = (hash << 5) + hash + data[i + 1];
    hash = (hash << 5) + hash + data[i + 2];
    hash = (hash << 5) + hash + data[i + 3];
  }

  return hash >>> 0; // Convert to unsigned 32-bit integer
};

/**
 * Fast canvas state comparison using a grid sampling approach
 * Samples points in a grid pattern for better coverage while maintaining performance
 */
export const compareCanvasStates = (
  prevState: ImageData | null,
  newState: ImageData
): boolean => {
  if (!prevState) return false;
  if (
    prevState.width !== newState.width ||
    prevState.height !== newState.height
  )
    return false;

  // Use a grid sampling approach - sample points every N pixels
  const width = prevState.width;
  const height = prevState.height;
  const gridSize = Math.max(10, Math.floor(Math.sqrt(width * height) / 20)); // Adjust grid size based on canvas dimensions

  const stride = width * 4;

  // Sample points in a grid pattern
  for (let y = 0; y < height; y += gridSize) {
    for (let x = 0; x < width; x += gridSize) {
      const i = y * stride + x * 4;
      // Check all RGBA channels
      if (
        prevState.data[i] !== newState.data[i] || // R
        prevState.data[i + 1] !== newState.data[i + 1] || // G
        prevState.data[i + 2] !== newState.data[i + 2] || // B
        prevState.data[i + 3] !== newState.data[i + 3] // A
      ) {
        return false;
      }
    }
  }

  return true;
};

/**
 * Create an optimized blob from an image using WebGL acceleration when available
 * @param image - The image data or canvas element to convert
 * @param resourceManager - Resource manager for canvas pooling
 * @param options - Optional configuration for blob creation
 * @returns Promise resolving to an optimized blob
 */
export const createOptimizedBlob = async (
  image: ImageData | HTMLCanvasElement,
  resourceManager: ResourceManager,
  options: {
    format?: 'image/webp' | 'image/jpeg' | 'image/png';
    quality?: number;
    useWebGL?: boolean;
  } = {}
): Promise<Blob> => {
  const { format = 'image/webp', quality = 0.92, useWebGL = false } = options;

  const canvas = resourceManager.acquireCanvas(image.width, image.height);

  try {
    const ctx = canvas.getContext('2d', {
      alpha: true,
      willReadFrequently: false,
    });

    if (!ctx) {
      throw new Error('Could not get 2D context');
    }

    if (image instanceof ImageData) {
      ctx.putImageData(image, 0, 0);
    } else {
      ctx.drawImage(image, 0, 0);
    }

    // Convert to blob with specified format and quality
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error('Failed to create blob'));
          }
        },
        format,
        quality
      );
    });
  } finally {
    resourceManager.releaseCanvas(canvas);
  }
};

import { useThree } from '@react-three/fiber';
import { useDrag } from '@use-gesture/react';
import {
  useKeyPressedRef,
  useKeyboardShortcut,
} from '@vizcom/shared-ui-components';

import { useMapControls } from '../../utils/mapControls/utils';

export const useWorkbenchStudioPointerRotationGesture = () => {
  const domElement = useThree((s) => s.events.connected);
  const controls = useMapControls();

  const rKeyPressed = useKeyPressedRef('r');

  useDrag<PointerEvent>(
    (gesture) => {
      if (gesture.first && !rKeyPressed.current) {
        gesture.cancel();
        return;
      }
      if (gesture.canceled) {
        return;
      }
      gesture.event.preventDefault();
      gesture.event.stopPropagation();

      const pointerPosition = [
        (gesture.event.clientX / window.innerWidth) * 2 - 1,
        -(gesture.event.clientY / window.innerHeight) * 2 + 1,
      ];
      const pointerAngle = Math.atan2(pointerPosition[0], pointerPosition[1]);

      if (!gesture.memo) {
        return {
          lastAngle: pointerAngle,
        };
      }
      const { lastAngle } = gesture.memo;

      controls.moveTo({
        skipAnimation: true,
        relative: true,
        rotation: pointerAngle - lastAngle,
      });

      return {
        lastAngle: pointerAngle,
      };
    },
    {
      target: domElement,
      eventOptions: { passive: false, capture: true },
    }
  );

  useKeyboardShortcut('escape', () => {
    controls.moveTo({
      rotation: 0,
    });
  });
};

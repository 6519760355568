import {
  urqlClient,
  TriggerWorkbenchElementImg2ImgMutation,
} from '@vizcom/shared/data-access/graphql';
import { RateLimitQuotaDetails } from '@vizcom/shared/data-shape';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import { addToast } from '@vizcom/shared-ui-components';

import { useNewlyCreatedElements } from '../../../components/elements/newlyCreatedElementsState';
import { SyncedActionType } from '../../SyncedAction';
import { ClientSideWorkbenchElementData } from '../../clientState';
import { elementById } from '../../utils';

export type Img2ImgPlaceholderInput = {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  zIndex: number;
};

export const TriggerAiImg2ImgAction: SyncedActionType<
  ClientSideWorkbenchElementData[],
  {
    type: 'triggerAiImg2Img';
    elementId: string;
    // newly created placeholders which will then be replaced by a drawing element with the image result
    placeholders: Img2ImgPlaceholderInput[];
  }
> = {
  type: 'triggerAiImg2Img',
  optimisticUpdater: ({ payload }, elements) => {
    const sourceElement = elementById(elements, payload.elementId);
    if (sourceElement?.__typename === 'WorkbenchElementImg2Img') {
      payload.placeholders.forEach((placeholder) => {
        if (elementById(elements, placeholder.id)) {
          // server already responded with this element, nothing to do locally
          return;
        }
        elements.push({
          __typename: 'WorkbenchElementPlaceholder',
          updatedAt: '0',
          createdAt: '0',
          id: placeholder.id,
          type: 'inference',
          x: placeholder.x,
          y: placeholder.y,
          width: placeholder.width,
          height: placeholder.height,
          zIndex: placeholder.zIndex,
        });
      });
    }
  },
  remoteUpdater: async ({ payload }, workbenchId) => {
    const res = await urqlClient.mutation(
      TriggerWorkbenchElementImg2ImgMutation,
      {
        input: {
          id: payload.elementId,
          placeholders: payload.placeholders.map((placeholder) => ({
            ...placeholder,
            type: 'inference',
            workbenchId,
          })),
        },
      }
    );
    if (res?.error) {
      if (
        (res.error.graphQLErrors?.[0].extensions?.exception as any)?.rateLimit
      ) {
        const rateLimitInfo = (
          res.error.graphQLErrors?.[0].extensions?.exception as any
        )?.rateLimit as RateLimitQuotaDetails;
        addToast(
          `You have been generating too many images, please wait ${(
            rateLimitInfo.resetInMs / 1000
          ).toFixed(0)}s before trying again.`,
          {
            type: 'danger',
          }
        );
        return;
      } else if (
        (res.error.graphQLErrors?.[0]?.extensions?.exception as any)?.code ===
        'organization_forbidden_keyword'
      ) {
        addToast(
          `Your prompt contains a keyword forbidden by your workspace administrator. Please change it. If you believe it's a mistake, contact your workspace administrator.`,
          {
            type: 'danger',
          }
        );
        return;
      } else if (
        (res.error.graphQLErrors?.[0]?.extensions?.exception as any)?.code ===
        'trademarked_word'
      ) {
        addToast(
          `This prompt contains a trademarked word, if you think this is a mistake, contact us at support@vizcom.ai`,
          {
            type: 'danger',
          }
        );
        return;
      } else if (
        (res.error.graphQLErrors?.[0]?.extensions?.exception as any)?.code ===
        'offensive_word'
      ) {
        addToast(
          `This prompt contains offensive words, if you think this is a mistake, contact us at support@vizcom.ai`,
          {
            type: 'danger',
          }
        );
        return;
      }
      throw new Error(
        `Error using AI service, please retry. ${
          res.error.graphQLErrors[0]?.message ?? res.error.message
        }`
      );
    }
    trackEvent('Generate Image', {
      type: 'workbenchElementImg2Img',
    });
  },
  onAddedToQueue(action) {
    if (action.payload.placeholders.length) {
      useNewlyCreatedElements
        .getState()
        .registerNewlyCreatedElements(action.payload.placeholders);
    }
  },
};

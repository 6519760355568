import React, { useRef, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import {
  CreatePromptStyleReferenceMode,
  useCreateDrawingStyleReference,
  useDeleteStyleReference,
} from '@vizcom/shared/data-access/graphql';
import { assertIsEnumValue } from '@vizcom/shared/js-utils';
import {
  Divider,
  RangeInput,
  FileDropper,
  addToast,
  resizeImageToPixelCount,
  TrashIcon,
  Text,
  useKeyboardShortcut,
  formatErrorMessage,
  PlusIcon,
  browseForFiles,
  NumberInput,
  Button,
  GeneralSelectIcon,
  Checkbox,
} from '@vizcom/shared-ui-components';

import { Drawing2dStudio } from '../../../../lib/useDrawingSyncedState';
import {
  WorkbenchStudioToolType,
  useWorkbenchStudioState,
} from '../../studioState';
import { InferenceSettings } from '../../useInference';

export const StyleReferenceModalImageSettings = ({
  drawing,
  setInferenceSettings,
  inferenceSettings,
  isHideOptionsContainer,
  onClose,
}: {
  drawing: Drawing2dStudio;
  setInferenceSettings: Dispatch<SetStateAction<InferenceSettings>>;
  inferenceSettings: InferenceSettings;
  isHideOptionsContainer?: boolean;
  onClose: () => void;
}) => {
  const setStudioTool = useWorkbenchStudioState((s) => s.setTool);
  const [, createDrawingStyleReference] = useCreateDrawingStyleReference();
  const [, deleteStyleReference] = useDeleteStyleReference();

  const references = drawing.workbench?.styleReferences.nodes || [];

  const styleReferenceMode = inferenceSettings.referenceMode;
  assertIsEnumValue(
    styleReferenceMode,
    CreatePromptStyleReferenceMode,
    'can only be used with style reference mode'
  );

  const focusedIndex =
    references.findIndex(
      (ref) =>
        ref.id ===
        inferenceSettings.styleReference[styleReferenceMode].styleReferenceId
    ) || 0;
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const totalItems = references.length;
  const columns = 7;

  const scrollToFocusedItem = (index: number) => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      const focusedItem = scrollContainer.children[index + 1] as HTMLElement; // +1 to account for PlusButton
      if (focusedItem) {
        focusedItem.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest',
        });
      }
    }
  };

  const updateFocusAndSelectReference = (newIndex: number) => {
    console.log('newIndex', newIndex);
    setInferenceSettings((prev) => ({
      ...prev,
      styleReference: {
        ...prev.styleReference,
        [styleReferenceMode]: {
          ...prev.styleReference[styleReferenceMode],
          styleReferenceId: references[newIndex].id,
        },
      },
    }));
    scrollToFocusedItem(newIndex);
  };

  const selectNextItem = () => {
    const newIndex = (focusedIndex + 1) % totalItems;
    updateFocusAndSelectReference(newIndex);
  };

  const selectPreviousItem = () => {
    const newIndex = (focusedIndex - 1 + totalItems) % totalItems;
    updateFocusAndSelectReference(newIndex);
  };

  const selectNextRowItem = () => {
    const newIndex = (focusedIndex + columns) % totalItems;
    updateFocusAndSelectReference(newIndex);
  };

  const selectPreviousRowItem = () => {
    const newIndex = (focusedIndex - columns + totalItems) % totalItems;
    updateFocusAndSelectReference(newIndex);
  };

  useKeyboardShortcut('ArrowRight', selectNextItem);
  useKeyboardShortcut('ArrowLeft', selectPreviousItem);
  useKeyboardShortcut('ArrowDown', selectNextRowItem);
  useKeyboardShortcut('ArrowUp', selectPreviousRowItem);

  const uploadStyleReference = async (file: File) => {
    const name = file.name;
    let resizedImage;
    try {
      resizedImage = await resizeImageToPixelCount(
        file,
        512 * 512,
        'image/webp'
      );
    } catch (e) {
      return addToast(
        'This image is not supported, please try with another image',
        {
          type: 'danger',
        }
      );
    }
    const res = await createDrawingStyleReference({
      input: {
        styleReference: {
          workbenchId: drawing.workbenchId,
          imageName: name,
          imagePath: resizedImage.image,
        },
      },
    });
    if (res.error || !res.data?.createStyleReference?.styleReference) {
      return addToast(`Error while uploading image`, {
        secondaryText: formatErrorMessage(res.error),
        type: 'danger',
      });
    }
    const newReferenceId = res.data.createStyleReference.styleReference.id;
    setInferenceSettings((prev) => ({
      ...prev,
      styleReference: {
        ...prev.styleReference,
        [styleReferenceMode]: {
          ...prev.styleReference[styleReferenceMode],
          styleReferenceId: newReferenceId,
        },
      },
    }));
    scrollToFocusedItem(references.length);
  };

  const handleBrowseForFile = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const files = await browseForFiles({
        accept: 'image/*',
      });
      await Promise.all(
        files.map((file) => {
          uploadStyleReference(file);
        })
      );
    } catch (e) {
      addToast('Failed to import file', {
        type: 'danger',
      });
    }
  };

  const handlePasteFile = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const items = await navigator.clipboard.read();
      const data = [...items].filter((item) =>
        item.types.some((type) => type.includes('image'))
      );

      if (!data || data.length === 0) {
        addToast('No images found in clipboard', {
          type: 'danger',
        });
        return;
      }

      data.forEach(async (item) => {
        const imageType = item.types.find((type) => type.includes('image'));
        if (imageType) {
          const blob = await item.getType(imageType);
          uploadStyleReference(blob as File);
        }
      });
    } catch (err) {
      addToast('Failed to read clipboard contents', {
        type: 'danger',
      });
      return;
    }
  };

  const handleDeleteStyleReferenceClick =
    (id: string) => async (e: React.MouseEvent) => {
      e.stopPropagation();
      const res = await deleteStyleReference({ id });
      if (res.error) {
        return addToast(`Error while deleting image`, {
          type: 'danger',
          secondaryText: formatErrorMessage(res.error),
        });
      }
      const currentIndex = references.findIndex((ref) => ref.id === id);
      const nextIndex = (currentIndex + 1) % references.length;
      setInferenceSettings((prev) => ({
        ...prev,
        styleReference: {
          ...prev.styleReference,
          [styleReferenceMode]: {
            ...prev.styleReference[styleReferenceMode],
            styleReferenceId: references[nextIndex]?.id ?? null,
          },
        },
      }));
    };

  const activeReference = drawing.workbench?.styleReferences.nodes.find(
    (n) =>
      n.id ===
      inferenceSettings.styleReference[styleReferenceMode].styleReferenceId
  );

  return (
    <>
      <EmptyStateContainer
        as={FileDropper}
        browseForFileOnClick
        onFileSelected={uploadStyleReference}
      >
        {activeReference?.imagePath ? (
          <PopoverImageContainer>
            <PopoverImage
              src={activeReference.imagePath}
              alt={activeReference.imageName || 'No name'}
            />
            <Overlay
              className="overlay"
              as={FileDropper}
              browseForFileOnClick
              onFileSelected={uploadStyleReference}
            >
              <Button variant="secondary" size="M">
                Upload...
              </Button>
              <Button variant="secondary" size="M" onClick={handlePasteFile}>
                Paste
              </Button>
            </Overlay>
            <DeleteButton
              variant="secondary"
              size="icon"
              onClick={(e) => {
                e.stopPropagation();
                if (activeReference?.id) {
                  handleDeleteStyleReferenceClick(activeReference.id)(e);
                }
              }}
            >
              <TrashIcon />
            </DeleteButton>
          </PopoverImageContainer>
        ) : (
          <UploadContainer>
            <Button variant="secondary" size="M">
              Upload...
            </Button>
            <Button variant="secondary" size="M" onClick={handlePasteFile}>
              Paste
            </Button>
          </UploadContainer>
        )}
      </EmptyStateContainer>

      {!isHideOptionsContainer && (
        <>
          <OptionsContainer>
            <OptionContainer>
              <OptionLabel color="subtext">Strength</OptionLabel>
              <StrengthSlider>
                <RangeInput
                  value={
                    inferenceSettings.styleReference[styleReferenceMode]
                      .strength * 100
                  }
                  min={0}
                  max={100}
                  step={1}
                  onChange={(e) => {
                    const value =
                      (Math.round(Number(e.target.value) / 5) * 5) / 100;
                    setInferenceSettings((prev) => ({
                      ...prev,
                      styleReference: {
                        ...prev.styleReference,
                        [styleReferenceMode]: {
                          ...prev.styleReference[styleReferenceMode],
                          strength: value,
                        },
                      },
                    }));
                  }}
                />
              </StrengthSlider>
              <StrengthNumberInput
                value={
                  inferenceSettings.styleReference[styleReferenceMode]
                    .strength * 100
                }
                min={0}
                max={100}
                unit="%"
                setValue={(value: number) => {
                  const boundedValue = Math.min(Math.max(value, 0), 100);
                  setInferenceSettings((prev) => ({
                    ...prev,
                    styleReference: {
                      ...prev.styleReference,
                      [styleReferenceMode]: {
                        ...prev.styleReference[styleReferenceMode],
                        strength: boundedValue / 100,
                      },
                    },
                  }));
                }}
                dragArrows={false}
              />
            </OptionContainer>
            {styleReferenceMode === CreatePromptStyleReferenceMode.Precise && (
              <OptionContainer>
                <OptionLabel color="subtext">Mask Zoom</OptionLabel>
                <Checkbox
                  checked={
                    inferenceSettings.styleReference[styleReferenceMode]
                      .maskZoom
                  }
                  onClick={() => {
                    setInferenceSettings((prev) => ({
                      ...prev,
                      styleReference: {
                        ...prev.styleReference,
                        [styleReferenceMode]: {
                          ...prev.styleReference[styleReferenceMode],
                          maskZoom:
                            !inferenceSettings.styleReference[
                              styleReferenceMode
                            ].maskZoom,
                        },
                      },
                    }));
                  }}
                />
              </OptionContainer>
            )}
            <OptionContainer>
              <OptionLabel color="subtext">Fill</OptionLabel>
              <FillButton
                variant="secondary"
                size="M"
                onClick={() => {
                  setStudioTool(WorkbenchStudioToolType.Lasso);
                  onClose();
                }}
              >
                <GeneralSelectIcon $size="S" />
                Make a selection
              </FillButton>
            </OptionContainer>
          </OptionsContainer>
          <Divider />
        </>
      )}

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Text type="b2">Local assets</Text>
        <PlusIcon style={{ cursor: 'pointer' }} onClick={handleBrowseForFile} />
      </div>

      <ReferenceImageContainer ref={scrollContainerRef}>
        <ReferenceImages>
          {references.map((reference) => (
            <ImageContainer
              key={reference.id}
              $active={activeReference?.id === reference.id}
              onClick={(e) => {
                e.stopPropagation();
                setInferenceSettings((prev) => ({
                  ...prev,
                  styleReference: {
                    ...prev.styleReference,
                    [styleReferenceMode]: {
                      ...prev.styleReference[styleReferenceMode],
                      styleReferenceId: reference.id,
                    },
                  },
                }));
              }}
            >
              <ImageThumbnail
                src={reference.imagePath}
                alt={reference.imageName || 'Style reference'}
              />
            </ImageContainer>
          ))}
        </ReferenceImages>
      </ReferenceImageContainer>
    </>
  );
};

const PopoverImageContainer = styled.div`
  width: 100%;
  height: 140px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  position: relative;
  background-color: ${(p) => p.theme.surface.tertiary};

  &:hover .overlay {
    display: flex;
  }
`;

const PopoverImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: ${({ theme }) => theme.borderRadius.m};
`;

const Overlay = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: #fff;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  overflow: hidden;
`;

const DeleteButton = styled(Button)`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
`;

const ReferenceImageContainer = styled.div`
  max-height: 300px;
  overflow-y: scroll;
  padding: 0 16px 16px 16px;
  grid-column: 1/4;
  ${({ theme }) => theme.scrollbar.dark};
`;

const ReferenceImages = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px;
`;

const ImageContainer = styled.div<{ $active: boolean }>`
  position: relative;
  cursor: pointer;
  box-shadow: ${({ $active, theme }) =>
    $active ? `0 0 0 1px ${theme.deprecated.primary.default}` : 'none'};
  &:hover {
    box-shadow: 0 0 0 1px
      ${(p) =>
        p.$active
          ? p.theme.deprecated.primary.default
          : p.theme.deprecated.secondary.hover};
  }
  aspect-ratio: 1;
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.s};
  overflow: hidden;
`;

const ImageThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 140px;
  background-color: ${(p) => p.theme.surface.secondary};
  border-radius: ${({ theme }) => theme.borderRadius.l};
  color: #fff;
  cursor: pointer;
  border: 1px solid ${(p) => p.theme.deprecated.primary.default};
`;

const UploadContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  overflow: hidden;
`;

const OptionsContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  column-gap: 8px;
  row-gap: 16px;
`;

const OptionContainer = styled.div`
  display: grid;
  grid-template-columns: subgrid;
  grid-column: 1/4;
  align-items: center;
`;

const OptionLabel = styled(Text)`
  grid-column: 1;
`;

const StrengthSlider = styled.div`
  grid-column: 2;
`;

const StrengthNumberInput = styled(NumberInput)`
  grid-column: 3;
  background: ${({ theme }) => theme.surface.secondary};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  border: 1px solid transparent;
  width: 50px;
  &:hover {
    border: 1px solid ${({ theme }) => theme.deprecated.secondary.hover};
  }
`;

const FillButton = styled(Button)`
  grid-column: 2/4;
`;

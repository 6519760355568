import styled from 'styled-components';
import {
  RectangleIcon,
  EllipseIcon,
  LineIcon,
  ToolbarButton,
  ToolbarButtonState,
  Checkbox,
  Divider,
  Text,
} from '@vizcom/shared-ui-components';

import {
  WorkbenchStudioToolType,
  useWorkbenchStudioState,
} from '../../studioState';
import { ToolbarSlider } from '../ToolbarSlider';

export const ShapeMenu = () => {
  const {
    tool,
    setTool,
    shapeSettings: { opacity, setOpacity, fill, setFill, size, setSize },
  } = useWorkbenchStudioState();

  const getButtonState = (shapeTool: WorkbenchStudioToolType) =>
    tool === shapeTool
      ? ToolbarButtonState.ACTIVE
      : ToolbarButtonState.INACTIVE;

  return (
    <Container>
      <ButtonsRow>
        <ToolbarButton
          icon={<RectangleIcon />}
          state={getButtonState(WorkbenchStudioToolType.Rectangle)}
          onClick={() => setTool(WorkbenchStudioToolType.Rectangle)}
          tooltip="Rectangle"
        />
        <ToolbarButton
          icon={<EllipseIcon />}
          state={getButtonState(WorkbenchStudioToolType.Ellipse)}
          onClick={() => setTool(WorkbenchStudioToolType.Ellipse)}
          tooltip="Ellipse"
        />
        <ToolbarButton
          icon={<LineIcon />}
          state={getButtonState(WorkbenchStudioToolType.Line)}
          onClick={() => setTool(WorkbenchStudioToolType.Line)}
          tooltip="Line"
        />
      </ButtonsRow>
      <Divider />
      <SliderContainer>
        <ToolbarSlider
          style={{ flexGrow: 1 }}
          label="Stroke"
          value={size}
          setValue={setSize}
          min={1}
          max={250}
          step={1}
          unit="px"
          disabled={tool !== WorkbenchStudioToolType.Line && fill}
          isLogScale
        />
        <ToolbarSlider
          style={{ flexGrow: 1 }}
          label="Opacity"
          value={opacity}
          setValue={setOpacity}
          min={0}
          max={1}
          step={0.01}
          multiplier={100}
          unit="%"
        />
      </SliderContainer>
      <CheckboxContainer
        disabled={tool === WorkbenchStudioToolType.Line}
        onClick={() => setFill(!fill)}
      >
        <Text color="subtext" type="b1">
          Fill Shape
        </Text>
        <Checkbox
          checked={fill}
          disabled={tool === WorkbenchStudioToolType.Line}
          onClick={(e) => {
            e.stopPropagation();
            setFill(!fill);
          }}
        />
      </CheckboxContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-width: 176px;
  padding-bottom: 4px;
`;

const ButtonsRow = styled.div`
  display: flex;
  gap: 8px;
`;

const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const CheckboxContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  color: ${({ theme, disabled }) =>
    disabled ? theme.text.bodyDisabled : theme.text.body};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

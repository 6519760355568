import { DefaultToastOptions, Toaster } from 'react-hot-toast';

export const ToastsContainer = ({
  containerStyle,
  toastOptions,
  position = 'bottom-center',
}: {
  containerStyle?: React.CSSProperties;
  toastOptions?: DefaultToastOptions;
  position?: 'top-center' | 'bottom-center' | 'top-right' | 'bottom-right';
}) => {
  return (
    <Toaster
      containerStyle={{
        position: 'relative',
        inset: '0px',
        height: 'fit-content',
        userSelect: 'none',
        touchAction: 'none',
        WebkitTouchCallout: 'none',
        WebkitUserSelect: 'none',
        WebkitTapHighlightColor: 'transparent',
        ...containerStyle,
      }}
      position={position}
      toastOptions={{
        style: {
          color: 'inherit',
          backgroundColor: 'transparent',
          boxShadow: 'none',
          padding: 0,
          maxWidth: 'none',
          borderRadius: 0,
          ...toastOptions?.style,
        },
        ...toastOptions,
      }}
    />
  );
};

import type { VizcomExternalClientApi } from './types';

export const EXTERNAL_API_METHOD_DOCUMENTATION: Record<
  keyof VizcomExternalClientApi,
  { description: string; parameters: string; output: string }
> = {
  userIsLoggedIn: {
    description: 'Check if the user is logged in',
    parameters: '',
    output: `({ loading: boolean, loggedIn: boolean })`,
  },
  getCurrentWorkbench: {
    description:
      'Get information about the currently open workbench, throws an error if no workbench is open',
    parameters: '',
    output: `({ loading: boolean, data: { id: string, name: string } | null })`,
  },
  getCurrentDrawing: {
    description:
      'Get information about the currently open drawing, throws an error if no drawing is open',
    parameters: '',
    output: `({ loading: boolean, data: { id: string, name: string, layers: { id: string, name: string }[] } | null })`,
  },
  getCurrentDrawingLayerImageAsBlob: {
    description:
      'Get the image of a specific layer in the current drawing as a Blob, throws an error if no drawing is open, or ifthe layer is empty or not found',
    parameters: 'layerId: string - ID of the layer to retrieve',
    output: `Promise<{ image: Blob }>`,
  },
  getCurrentDrawingImageAsBlob: {
    description:
      'Get the complete image of the current drawing as a Blob, throws an error if no drawing is open or if the drawing is not ready yet',
    parameters: '',
    output: `Promise<{ image: Blob }>`,
  },
  importBlobsAsNewLayerInCurrentDrawing: {
    description:
      'Import image blobs as new layers in the current drawing, throws an error if no drawing is open',
    parameters:
      'blobs: Blob[] - Array of image or mesh blobs to import as layers',
    output: `Promise<{ id: string, name: string }[]>`,
  },
  documentation: {
    description:
      'Get the documentation for the external client API methods, including descriptions, parameters, and output types',
    parameters: '',
    output: ``,
  },
};

// This is a slimmed down version of the Drei image component: https://github.com/pmndrs/drei/blob/master/src/core/Image.tsx
// it removes zoom, grayscale, color overlay and opacity
// it adds a checker pattern in the background for part of the image which are fully transparent (outside the bounds of the image

import { shaderMaterial } from '@react-three/drei';
import { Color, extend } from '@react-three/fiber';
import * as React from 'react';
import { useTheme } from 'styled-components';
import * as THREE from 'three';
import { assertExists, assertUnreachable } from '@vizcom/shared/js-utils';
import { ErrorBoundary } from '@vizcom/shared-ui-components';

import { useImageTextures } from '../../lib/useImageTexture';
import { useImageDataTexture } from '../helpers';
import { ErrorPlaceholder } from './ErrorPlaceholder';
import { LoadingPlaceholder } from './LoadingPlaceholder';

export type ImageProps = Omit<JSX.IntrinsicElements['mesh'], 'scale'> & {
  scale?: number | [number, number];
  url: string | undefined | null | File | Blob | ImageData;
  opacity?: number;
};

type CustomImageMaterialType = JSX.IntrinsicElements['shaderMaterial'] & {
  scale?: readonly [number, number];
  imageBounds?: readonly [number, number];
  color?: Color;
  map: THREE.Texture;
};

type CheckboardMaterialType = JSX.IntrinsicElements['shaderMaterial'] & {
  scale?: readonly [number, number];
  checkboardSize?: number;
};

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      customImageMaterial: CustomImageMaterialType;
      checkboardMaterial: CheckboardMaterialType;
    }
  }
}

const CheckboardMaterialImpl = shaderMaterial(
  { scale: [1, 1], checkboardSize: 20, opacity: 1 },
  /* glsl */ `
    varying vec2 vUv;
    uniform vec2 scale;
    uniform float checkboardSize;

    void main() {
      gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(position, 1.);
      vUv = vec2(uv.x, 1. - uv.y) * scale / checkboardSize;
    }
  `,
  /* glsl */ `
  varying vec2 vUv;
  uniform float opacity;

  // Return a grey / white checkboard depending on the pixel position in the image
  vec3 transparentCheckboard(ivec2 pixelPosition) {
    return ((pixelPosition.x + pixelPosition.y) & 1) == 0 ? (
      vec3(1.)
    ) : (
      vec3(.9)
    );
  }

  void main() {
    gl_FragColor = vec4(
      transparentCheckboard(ivec2(vUv)),
      opacity
    );

    #include <tonemapping_fragment>
    #include <colorspace_fragment>
    #include <premultiplied_alpha_fragment>
    #include <dithering_fragment>
  }
  `
);
extend({ CheckboardMaterial: CheckboardMaterialImpl });

const ImageMaterialImpl = shaderMaterial(
  {
    scale: [1, 1],
    imageBounds: [1, 1],
    map: null,
    opacity: 1,
  },
  /* glsl */ `
  varying vec2 vUv;
  void main() {
    gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(position, 1.);
    vUv = uv;
  }
`,
  /* glsl */ `
  // mostly from https://gist.github.com/statico/df64c5d167362ecf7b34fca0b1459a44
  varying vec2 vUv;
  uniform vec2 scale;
  uniform vec2 imageBounds;
  uniform sampler2D map;
  uniform float opacity;

  vec2 aspect(vec2 size) {
    return size / min(size.x, size.y);
  }

  // Return a grey / white checkboard depending on the pixel position in the image
  vec4 transparentCheckboard(vec2 pixelPosition) {
    return mix(
      vec4(.9, .9, .9, 1.0),
      vec4(1. ,1., 1., 1.),
      float((int(pixelPosition.x) + int(pixelPosition.y)) % 2 == 0)
    );
  }

  void main() {
    vec2 s = aspect(scale);
    vec2 i = aspect(imageBounds);
    float rs = s.x / s.y;
    float ri = i.x / i.y;

    float stepComparison = float(rs < ri);
    vec2 new = mix(
      vec2(i.x * s.y / i.y, s.y),
      vec2(s.x, i.y * s.x / i.x),
      stepComparison
    );
    vec2 offset = mix(
      vec2((new.x - s.x) / 2.0, 0.0),
      vec2(0.0, (new.y - s.y) / 2.0),
      stepComparison
    ) / new;

    vec2 uv = vUv * s / new + offset;
    vec2 zUv = (uv - vec2(0.5, 0.5)) + vec2(0.5, 0.5);

    vec4 imagePixelColor = texture2D(map, zUv);
    vec2 pixelPosition = vUv * imageBounds / 20.0;

    // Apply the image color on top of the transparentCheckboard color by using the image transparency to mix it
    gl_FragColor = vec4(
      mix(transparentCheckboard(pixelPosition).rgb, imagePixelColor.rgb, imagePixelColor.a).rgb,
      opacity
    );

    #include <tonemapping_fragment>
    #include <colorspace_fragment>
    #include <premultiplied_alpha_fragment>
    #include <dithering_fragment>
  }
  `
);
extend({ CustomImageMaterial: ImageMaterialImpl });

const BlankImage = React.forwardRef<THREE.Mesh, ImageProps>((props, ref) => {
  const { scale = 1, children, ...rest } = props;

  const planeBounds = Array.isArray(scale)
    ? ([scale[0], scale[1]] as const)
    : ([scale, scale] as const);

  return (
    <mesh ref={ref} scale={[...planeBounds, 1]} {...rest}>
      <planeGeometry args={[1, 1, 1, 1]} />
      <checkboardMaterial
        scale={planeBounds}
        side={THREE.DoubleSide}
        transparent
        opacity={props.opacity}
      />
      {children}
    </mesh>
  );
});

const ImageFromUrl = React.forwardRef<
  THREE.Mesh,
  Omit<ImageProps, 'url'> & { url: string }
>((props, ref) => {
  const { scale = 1, url, children, ...rest } = props;

  const [texture] = useImageTextures([url]);
  assertExists(texture);
  const planeBounds = Array.isArray(scale)
    ? ([scale[0], scale[1]] as const)
    : ([scale, scale] as const);
  const imageBounds = [texture.image.width, texture.image.height] as const;

  return (
    <mesh ref={ref} scale={[...planeBounds, 1]} {...rest}>
      <planeGeometry args={[1, 1, 1, 1]} />
      <customImageMaterial
        transparent
        opacity={props.opacity}
        map={texture}
        scale={planeBounds}
        imageBounds={imageBounds}
        side={THREE.DoubleSide}
      />
      {children}
    </mesh>
  );
});

const ImageFromFileOrBlob = React.forwardRef<
  THREE.Mesh,
  Omit<ImageProps, 'url'> & { url: File | Blob }
>((props, ref) => {
  const { scale = 1, url, children, ...rest } = props;
  const [texture] = useImageTextures([url]);
  assertExists(texture);
  const planeBounds = Array.isArray(scale)
    ? ([scale[0], scale[1]] as const)
    : ([scale, scale] as const);
  const imageBounds = [texture.image.width, texture.image.height] as const;

  return (
    <mesh ref={ref} scale={[...planeBounds, 1]} {...rest}>
      <planeGeometry args={[1, 1, 1, 1]} />
      <customImageMaterial
        map={texture}
        scale={planeBounds}
        imageBounds={imageBounds}
        side={THREE.DoubleSide}
        transparent
        opacity={props.opacity}
      />
      {children}
    </mesh>
  );
});

const ImageFromImageData = React.forwardRef<
  THREE.Mesh,
  Omit<ImageProps, 'url'> & { url: ImageData }
>((props, ref) => {
  const { scale = 1, url, children, ...rest } = props;

  const texture = useImageDataTexture(props.url);

  const planeBounds = Array.isArray(scale)
    ? ([scale[0], scale[1]] as const)
    : ([scale, scale] as const);
  const imageBounds = [texture.image.width, texture.image.height] as const;

  return (
    <mesh ref={ref} scale={[...planeBounds, 1]} {...rest}>
      <planeGeometry args={[1, 1, 1, 1]} />
      <customImageMaterial
        map={texture}
        scale={planeBounds}
        imageBounds={imageBounds}
        side={THREE.DoubleSide}
        transparent
        opacity={props.opacity}
      />
      {children}
    </mesh>
  );
});

// Display an image in a r3f context
// While the image is loading, show a loading placeholder
// If the image fails to load, show an error placeholder
export const CustomImage = React.forwardRef<THREE.Mesh, ImageProps>(
  (props, ref) => {
    const theme = useTheme();
    const url = props.url;
    const fallback = <BlankImage {...props} ref={ref} />;

    if (!url) {
      return fallback;
    }

    return (
      <ErrorBoundary
        fallback={<ErrorPlaceholder {...props} color={theme.icon.error} />}
      >
        <React.Suspense fallback={<LoadingPlaceholder {...props} />}>
          {typeof url === 'string' ? (
            <ImageFromUrl {...props} url={url} ref={ref} />
          ) : url instanceof File || url instanceof Blob ? (
            <ImageFromFileOrBlob {...props} url={url} ref={ref} />
          ) : url instanceof ImageData ? (
            <ImageFromImageData {...props} url={url} ref={ref} />
          ) : (
            assertUnreachable(url)
          )}
        </React.Suspense>
      </ErrorBoundary>
    );
  }
);

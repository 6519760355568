import {
  HorizontalSwitcher,
  ListViewIcon,
  IconViewIcon,
} from '@vizcom/shared-ui-components';

export enum ViewMode {
  List = 'list',
  Icon = 'icon',
}

export const ViewModeSwitcher = ({
  selectedView,
  setSelectedView,
}: {
  selectedView: ViewMode;
  setSelectedView: (value: ViewMode) => void;
}) => {
  return (
    <HorizontalSwitcher
      value={selectedView}
      onChange={(value) => setSelectedView(value as ViewMode)}
      options={[
        { value: ViewMode.List, label: <ListViewIcon fill="white" /> },
        { value: ViewMode.Icon, label: <IconViewIcon fill="white" /> },
      ]}
      style={{ width: 65 }}
    />
  );
};

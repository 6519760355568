import { useState } from 'react';
import { Object3D, Vector3 } from 'three';
import { v4 as uuidv4 } from 'uuid';
import {
  CompositeSceneElement,
  CompositeSceneFullData,
} from '@vizcom/shared/data-access/graphql';
import {
  Divider,
  Menu,
  MenuItem,
  PlusIcon,
  Text,
  ToolbarButton,
} from '@vizcom/shared-ui-components';

import CameraIcon from '../../../assets/icons/camera.svg?react';
import { useCompositeSceneSyncedState } from '../../../lib/useCompositeSceneSyncedState';
import {
  Actions,
  Container,
  Content,
  Frame,
  HeaderPrimary,
  Section,
} from '../compositeSceneMenu/style';
import { SceneStructureNode } from './SceneStructureNode';

export const SceneStructureTree = ({
  compositeScene,
  handleAction,
}: {
  compositeScene: CompositeSceneFullData;
  handleAction: ReturnType<typeof useCompositeSceneSyncedState>['handleAction'];
}) => {
  const [showAddContextMenu, setShowAddContextMenu] = useState(false);

  return (
    <Container>
      <Frame $actions="top">
        <HeaderPrimary>
          <Text type="sh1">Layers</Text>
          <Actions>
            <Menu
              placement="left-start"
              renderLabel={(props, interactionProps) => (
                <ToolbarButton
                  icon={<PlusIcon />}
                  tooltip="More actions"
                  {...interactionProps}
                  onClick={(e) => {
                    e.stopPropagation();
                    (interactionProps.onClick as React.MouseEventHandler)(e);
                  }}
                  buttonProps={{ tabIndex: -1 }}
                />
              )}
              open={showAddContextMenu}
              setIsOpen={setShowAddContextMenu}
            >
              <MenuItem
                prependLabel={<CameraIcon style={{ width: 14, height: 14 }} />}
                label="Camera"
                onClick={() => {
                  const uuid = uuidv4();

                  const currentCameraPosition = new Vector3(
                    compositeScene.cameraPositionX,
                    compositeScene.cameraPositionY,
                    compositeScene.cameraPositionZ
                  );

                  const mock = new Object3D();
                  mock.position.copy(currentCameraPosition);
                  mock.lookAt(
                    compositeScene.cameraTargetX,
                    compositeScene.cameraTargetY,
                    compositeScene.cameraTargetZ
                  );
                  mock.updateMatrix();
                  mock.rotateY(Math.PI);

                  const mockDirection = mock.getWorldDirection(new Vector3());
                  mock.position.add(mockDirection.multiplyScalar(-0.5));

                  handleAction({
                    type: 'createCompositeSceneElement',
                    id: uuid,
                    name: 'Camera',
                    basicShape: 'Camera',
                  });
                  handleAction({
                    type: 'updateCompositeSceneElement',
                    id: uuid,
                    meshes: {
                      root: {
                        position: mock.position.toArray(),
                        quaternion: mock.quaternion.toArray(),
                        scale: [1.0, 1.0, 1.0],
                      },
                    },
                  });

                  setShowAddContextMenu(false);
                }}
              />
            </Menu>
          </Actions>
        </HeaderPrimary>
        <Divider />
        <Content>
          <Section>
            {compositeScene.compositeSceneElements.nodes.map((element) => (
              <SceneStructureNode
                key={element.id}
                compositeSceneElement={element as CompositeSceneElement}
                handleAction={handleAction}
              />
            ))}
          </Section>
        </Content>
      </Frame>
    </Container>
  );
};

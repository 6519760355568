import React, { createContext, useContext, useState } from 'react';
import styled from 'styled-components';
import {
  CaretDownIconRotatable,
  InlineFlex,
} from '@vizcom/shared-ui-components';

type AccordionProps = {
  children: React.ReactNode;
  open?: boolean;
  style?: React.CSSProperties;
};

type AccordionContextType = {
  disabled: boolean;
  setDisabled: (disabled: boolean) => void;
  isAccordionOpen: boolean;
  setIsAccordionOpen: (isAccordionOpen: boolean) => void;
};

const AccordionContext = createContext<AccordionContextType | null>(null);

export const useAccordion = () => {
  const context = useContext(AccordionContext);

  if (!context) {
    throw new Error('useAccordion must be used within an Accordion');
  }

  return context;
};

export const Accordion = ({
  children,
  open = false,
  style,
}: AccordionProps) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(open);
  const [disabled, setDisabled] = useState(false);

  return (
    <AccordionContext.Provider
      value={{ isAccordionOpen, setIsAccordionOpen, disabled, setDisabled }}
    >
      <AccordionContainer $isOpen={isAccordionOpen} style={{ ...style }}>
        {children}
      </AccordionContainer>
    </AccordionContext.Provider>
  );
};

const AccordionHeader = ({
  children,
  $sticky = true,
  $top = 0,
  style,
}: {
  children: React.ReactNode;
  $sticky?: boolean;
  $top?: number;
  style?: React.CSSProperties;
}) => {
  const { isAccordionOpen, setIsAccordionOpen, disabled } = useAccordion();

  return (
    <InlineFlex
      onClick={() => !disabled && setIsAccordionOpen(!isAccordionOpen)}
      style={{
        cursor: disabled ? 'default' : 'pointer',
        position: $sticky ? 'sticky' : 'relative',
        top: $sticky ? $top : 0,
        bottom: $sticky ? 0 : 'auto',
        ...style,
      }}
    >
      {!disabled && (
        <CaretDownIconRotatable $angle={isAccordionOpen ? 0 : -90} />
      )}
      {children}
    </InlineFlex>
  );
};

const AccordionBody = ({ children }: { children: React.ReactNode }) => {
  const { isAccordionOpen, setIsAccordionOpen, disabled } = useAccordion();

  return (
    <AccordionBodyContainer $isOpen={isAccordionOpen}>
      {children}
      {!disabled && (
        <BlurBottom
          $isOpen={isAccordionOpen}
          onClick={() => setIsAccordionOpen(true)}
        />
      )}
    </AccordionBodyContainer>
  );
};

const AccordionBodyContainer = styled.div<{ $isOpen: boolean }>`
  max-height: ${(props) => (props.$isOpen ? '1000px' : '70px')};
  transition: max-height 0.3s ease-out;
  position: relative;
  overflow: hidden;
`;

const AccordionContainer = styled.div<{ $isOpen: boolean }>`
  background-color: ${({ theme }) => theme.surface.secondary};
  padding: 10px;
  border-radius: 5px;
`;

const BlurBottom = styled.div<{ $isOpen: boolean }>`
  position: absolute;
  height: 30px;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    to bottom,
    transparent,
    ${({ theme }) => theme.surface.secondary}
  );

  transition: opacity 0.2s ease-out, transform 0.3s ease-out;
  transform: ${({ $isOpen }) =>
    $isOpen ? 'translateY(100%)' : 'translateY(0)'};

  :hover {
    cursor: pointer;
    opacity: 0.9;
    transition: opacity 0.3s ease-out;
  }
`;

Accordion.Body = AccordionBody;
Accordion.Header = AccordionHeader;

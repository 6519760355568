import {
  urqlClient,
  WorkbenchElementPlaceholderData,
  UpdateWorkbenchElementPlaceholderMutation,
} from '@vizcom/shared/data-access/graphql';

import { SyncedActionType } from '../../SyncedAction';
import { ClientSideWorkbenchElementData } from '../../clientState';
import { elementById } from '../../utils';

export const UpdatePlaceholderAction: SyncedActionType<
  ClientSideWorkbenchElementData[],
  {
    type: 'updatePlaceholder';
    elementId: string;
    placeholderType: WorkbenchElementPlaceholderData['type'];
  }
> = {
  type: 'updatePlaceholder',
  optimisticUpdater: ({ payload }, elements) => {
    const sourceElement = elementById(elements, payload.elementId);
    if (sourceElement?.__typename === 'WorkbenchElementPlaceholder') {
      sourceElement.type = payload.placeholderType;
    }
  },
  remoteUpdater: async ({ payload }) => {
    await urqlClient.mutation(UpdateWorkbenchElementPlaceholderMutation, {
      input: {
        id: payload.elementId,
        patch: {
          type: payload.placeholderType,
        },
      },
    });
  },
};

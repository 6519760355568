import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useSelectedOrganization } from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

const TabsContainer = styled.div`
  display: flex;
  gap: 32px;
  margin-bottom: 32px;
`;

const Tab = styled(Link)<{ $active: boolean }>`
  color: ${({ theme, $active }) =>
    $active ? theme.text.body : theme.text.subtext};
  text-decoration: none;
  padding-bottom: 8px;
  border-bottom: 2px solid
    ${({ theme, $active }) =>
      $active ? theme.deprecated.white : 'transparent'};

  &:hover {
    color: ${({ theme }) => theme.deprecated.white};
  }
`;

export const DataPageTabs = () => {
  const location = useLocation();
  const { data: organization } = useSelectedOrganization();

  if (!organization) return null;

  return (
    <TabsContainer>
      <Tab
        to={paths.settings.organization.accessLog(organization.id)}
        $active={location.pathname.includes(
          paths.settings.organization.accessLog(organization.id)
        )}
      >
        Access logs
      </Tab>
      <Tab
        to={paths.settings.organization.activityLog(organization.id)}
        $active={location.pathname.includes(
          paths.settings.organization.activityLog(organization.id)
        )}
      >
        Activity logs
      </Tab>
    </TabsContainer>
  );
};

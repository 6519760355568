import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { useCurrentUser } from '@vizcom/shared/data-access/graphql';
import { Toolbar, ToolbarDivider } from '@vizcom/shared-ui-components';

import { MultiplayerPresence } from '../../lib/useWorkbenchMultiplayer';
import { HtmlOverlay } from '../utils/HtmlOverlay';
import { CollaborationToolbarSubscriptionPlanIndicator } from './CollaborationToolbarSubscriptionPlanIndicator';
import { LargeListMultiplayerPresencesButton } from './LargeListMultiplayerPresencesButton';
import {
  MultiplayerPresenceIndicator,
  OwnPresenceIndicator,
} from './MultiplayerPresenceIndicator';
import { SelectedPresenceWindowIndicator } from './SelectedPresenceWindowIndicator';

export const WorkbenchCollaborationToolbar = (
  props: PropsWithChildren<{
    workbenchId: string;
    selectedPresence: MultiplayerPresence | undefined;
    setSelectedPresenceId: React.Dispatch<React.SetStateAction<string | null>>;
    selfPresence: MultiplayerPresence | null;
    multiplayerPresences: MultiplayerPresence[];
  }>
) => {
  const { data: user } = useCurrentUser();

  return (
    <>
      <HtmlOverlay>
        {props.selectedPresence && (
          <SelectedPresenceWindowIndicator presence={props.selectedPresence} />
        )}
        <StyledToolbar position="top-right">
          <PresenceContainer>
            {props.multiplayerPresences.length > 4 ? (
              <LargeListMultiplayerPresencesButton
                selfPresence={props.selfPresence}
                multiplayerPresences={props.multiplayerPresences}
                setSelectedPresenceId={props.setSelectedPresenceId}
                selectedPresence={props.selectedPresence}
              />
            ) : (
              <>
                {props.selfPresence && (
                  <OwnPresenceIndicator selfPresence={props.selfPresence} />
                )}
                {props.multiplayerPresences.slice(0, 4).map((presence) => (
                  <MultiplayerPresenceIndicator
                    key={presence.id}
                    presence={presence}
                    onSelect={() => props.setSelectedPresenceId(presence.id)}
                    selected={presence === props.selectedPresence}
                  />
                ))}
              </>
            )}
          </PresenceContainer>

          {user && (
            <>
              <Spacer />
              <ToolbarDivider />
              <CollaborationToolbarSubscriptionPlanIndicator
                workbenchId={props.workbenchId}
              />
            </>
          )}
          {props.children}
        </StyledToolbar>
      </HtmlOverlay>
    </>
  );
};

const StyledToolbar = styled(Toolbar)`
  width: 250px;
`;

const PresenceContainer = styled.div`
  min-width: 100px;
  display: flex;
  gap: 4px;
`;

const Spacer = styled.div`
  flex: 1;
`;

import { pick, omit } from 'lodash';
import {
  urqlClient,
  UpdateDrawingMutation,
  UpdateWorkbenchElementImg2ImgMutation,
  UpdateWorkbenchElementPlaceholderMutation,
  UpdateWorkbenchElementTextMutation,
  UpdateCompositeSceneMutation,
  UpdateWorkbenchElementPaletteMutation,
  UpdateWorkbenchElementMixMutation,
  UpdateVideoMutation,
  UpdateWorkbenchElementAnimateMutation,
} from '@vizcom/shared/data-access/graphql';
import { assertUnreachable } from '@vizcom/shared/js-utils';

import { getElementSize } from '../../../components/helpers';
import {
  SyncedActionPayloadFromType,
  SyncedActionType,
} from '../../SyncedAction';
import { ClientSideWorkbenchElementData } from '../../clientState';
import { elementById, elementIsDrawing } from '../../utils';

export const PositionAction: SyncedActionType<
  ClientSideWorkbenchElementData[],
  {
    type: 'position';
    elementId: string;
    x?: number;
    y?: number;
    height?: number;
    width?: number;
    zIndex?: number;
    final?: boolean;
  },
  {
    element: ClientSideWorkbenchElementData | null | undefined;
  }
> = {
  type: 'position',
  optimisticUpdater: ({ payload }, elements) => {
    const element = elementById(elements, payload.elementId);
    if (!element) {
      return;
    }
    if (elementIsDrawing(element)) {
      if (payload.width) {
        element.workbenchSizeRatio = payload.width / element.drawingWidth;
      }
    } else if ('width' in element && 'height' in element) {
      element.width = payload.width ?? element.width;
      element.height = payload.height ?? element.height;
    }
    element.x = payload.x ?? element.x;
    element.y = payload.y ?? element.y;
    element.zIndex = payload.zIndex ?? element.zIndex;
  },
  remoteUpdater: async ({ payload, meta }) => {
    const element = meta.custom?.element;
    if (!element) {
      return;
    }

    const actionPatch = pick(payload, ['height', 'width', 'x', 'y', 'zIndex']);
    let res;
    if (element.__typename === 'Drawing') {
      res = await urqlClient.mutation(UpdateDrawingMutation, {
        id: element.id,
        patch: {
          workbenchSizeRatio: payload.width
            ? payload.width / element.drawingWidth
            : element.workbenchSizeRatio,
          ...omit(actionPatch, ['height', 'width']),
        },
      });
    } else if (element.__typename === 'WorkbenchElementImg2Img') {
      res = await urqlClient.mutation(UpdateWorkbenchElementImg2ImgMutation, {
        input: {
          id: element.id,
          patch: actionPatch,
        },
      });
    } else if (element.__typename === 'WorkbenchElementPlaceholder') {
      res = await urqlClient.mutation(
        UpdateWorkbenchElementPlaceholderMutation,
        {
          input: {
            id: element.id,
            patch: actionPatch,
          },
        }
      );
    } else if (element.__typename === 'WorkbenchElementText') {
      res = await urqlClient.mutation(UpdateWorkbenchElementTextMutation, {
        input: {
          id: element.id,
          patch: actionPatch,
        },
      });
    } else if (element.__typename === 'CompositeScene') {
      res = await urqlClient.mutation(UpdateCompositeSceneMutation, {
        input: {
          id: element.id,
          patch: actionPatch,
        },
      });
    } else if (element.__typename === 'WorkbenchElementPalette') {
      res = await urqlClient.mutation(UpdateWorkbenchElementPaletteMutation, {
        input: {
          id: element.id,
          patch: actionPatch,
        },
      });
    } else if (element.__typename === 'WorkbenchElementMix') {
      res = await urqlClient.mutation(UpdateWorkbenchElementMixMutation, {
        input: {
          id: element.id,
          patch: actionPatch,
        },
      });
    } else if (element.__typename === 'Video') {
      res = await urqlClient.mutation(UpdateVideoMutation, {
        id: element.id,
        patch: actionPatch,
      });
    } else if (element.__typename === 'WorkbenchElementAnimate') {
      res = await urqlClient.mutation(UpdateWorkbenchElementAnimateMutation, {
        input: {
          id: element.id,
          patch: actionPatch,
        },
      });
    } else if (element.__typename === 'WorkbenchElementSection') {
      // Implement dragging sections
    } else {
      assertUnreachable(element);
    }

    if (res?.error) {
      throw new Error(
        `Error while moving element, please retry. ${
          res.error.graphQLErrors[0]?.message ?? res.error.message
        }`
      );
    }
  },
  metaConstructor: (payload, elements) => ({
    delay: payload.final ? undefined : 2000,
    debounceId: `position|${payload.elementId}`,
    custom: {
      element: elementById(elements, payload.elementId),
    },
  }),
  undoConstructor: ({ payload }, elements) => {
    const element = elementById(elements, payload.elementId);
    if (!element) {
      return;
    }

    const oldSize = getElementSize(element);
    const undoPayload: SyncedActionPayloadFromType<typeof PositionAction> = {
      type: 'position',
      elementId: payload.elementId,
      x: element.x,
      y: element.y,
      width: oldSize.width,
      height: oldSize.height,
      zIndex: element.zIndex,
      final: true,
    };

    return undoPayload;
  },
};

import styled, { keyframes } from 'styled-components';

import { zIndexOrder } from '../utils/consts';

export const ToolbarContainer = styled.div`
  z-index: ${zIndexOrder.UiZIndex};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  pointer-events: none;
  margin: auto;
`;

export const Toolbar = styled.div<{ $disabled?: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  gap: 8px;
  background: ${({ theme }) => theme.surface.primary};
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'all')};
  height: 48px;
`;

export const LoadingIndicatorAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const LoadingIndicator = styled.div<{ $active: boolean }>`
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  transition: ${(p) => (p.$active ? '100ms' : '1s')} all ease;
  opacity: ${(p) => (p.$active ? 1 : 0.25)};
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
    width: 20px;
    height: 20px;
    border: 2px solid ${(p) => p.theme.deprecated.tertiary.default};
    border-bottom-color: ${(p) => (p.$active ? 'transparent' : 'auto')};
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: ${LoadingIndicatorAnimation} ${(p) => (p.$active ? 1 : 0)}s
      linear infinite;
  }
`;

export const ToolbarOpacitySlider = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SliderIcon = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

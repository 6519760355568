import {
  urqlClient,
  UpdateDrawingMutation,
} from '@vizcom/shared/data-access/graphql';

import { SyncedActionType } from '../../SyncedAction';
import { Drawing2dStudio } from '../../useDrawingSyncedState';

export const UpdateDrawingSizeAction: SyncedActionType<
  Drawing2dStudio,
  {
    type: 'updateDrawingSize';
    width: number;
    height: number;
  }
> = {
  type: 'updateDrawingSize',
  optimisticUpdater: ({ payload }, drawing) => {
    drawing.width = payload.width;
    drawing.height = payload.height;
    drawing.initiated = true;
  },
  remoteUpdater: async ({ payload }, drawingId) => {
    const res = await urqlClient.mutation(UpdateDrawingMutation, {
      id: drawingId,
      patch: {
        width: payload.width,
        height: payload.height,
        initiated: true,
      },
    });
    if (res.error) {
      throw new Error(
        `Error while updating drawing size, please try again later. ${
          res.error.graphQLErrors[0]?.message ?? res.error.message
        }`
      );
    }
  },
  undoConstructor: (_, drawing) => {
    return {
      type: 'updateDrawingSize',
      width: drawing.width,
      height: drawing.height,
    };
  },
};

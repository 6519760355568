import { ThreeEvent, useThree } from '@react-three/fiber';
import { createUseGesture } from '@use-gesture/react';
import { useRef } from 'react';
import { Mesh, OrthographicCamera } from 'three';

import { screenPositionToLayer } from '../../../helpers';
import { isSelectionTool, useWorkbenchStudioState } from '../../studioState';
import { BrushSegment } from '../../types';
import { stabilizeInitialPressure, stabilizePoint } from './stabilization';

const useGesture = createUseGesture([]);

const LINE_TOOL_ANGLE_GRANULARITY = (Math.PI * 2) / 8; // 45°
const LINE_TOOL_CONSTRAINT_THRESHOLD = 10;
const MIN_DRAG_DISTANCE_TO_RECORD_POINT = 40;
const POINT_HISTORY_MAX_LENGTH = 10;

type SegmentedBrushProps = {
  drawingSize: [number, number];
  eventPlaneRef: React.MutableRefObject<Mesh>;
  onNewSegments: (
    event: ThreeEvent<PointerEvent>,
    segments: BrushSegment[]
  ) => void;
  onInitialPressureStabilized?: (segments: BrushSegment[]) => void;
  onStrokeEnd: (event: ThreeEvent<PointerEvent>) => void;
  preventFingerInteractions?: boolean;
};

type Constraint = {
  enabled: boolean;
  angle: number;
};

type Point = {
  screenPosition: [number, number];
  stabilizedScreenPosition: [number, number];
  position: [number, number];
  pressure: number;
  stabilizedPressure: number;
};

export const useBrushSegments = ({
  drawingSize,
  eventPlaneRef,
  onNewSegments,
  onInitialPressureStabilized = undefined,
  onStrokeEnd,
  preventFingerInteractions = false,
}: SegmentedBrushProps) => {
  const camera = useThree((s) => s.camera as OrthographicCamera);
  const { getToolSettings, resizing, tool } = useWorkbenchStudioState();
  const { toolStabilizer } = getToolSettings();
  const isDrawing = useRef(false);

  const initialPoint = useRef<[number, number]>([0, 0]);
  const lastDragPressure = useRef(0);
  const lastPressure = useRef(0);
  const lastUsedPoint = useRef<Point | null>(null);
  const gestureMemo = useRef<null | {
    constraint: Constraint;
    previousPoints: Point[];
    lastPrecisePointerEvents: [number, number][];
  }>(null);

  const getPressure = (event: ThreeEvent<PointerEvent>) => {
    return ['mouse', 'touch'].includes(event.pointerType) ? 1 : event.pressure;
  };

  const isProlongedDragEvent = (event: ThreeEvent<PointerEvent>) => {
    const dx = event.clientX - initialPoint.current[0];
    const dy = event.clientY - initialPoint.current[1];
    return Math.sqrt(dx ** 2 + dy ** 2) > MIN_DRAG_DISTANCE_TO_RECORD_POINT;
  };

  const drawSegment = (state: {
    event: ThreeEvent<PointerEvent>;
    isPointerDown?: boolean;
  }) => {
    if (resizing || !isDrawing.current) return;
    const event = state.event as unknown as ThreeEvent<PointerEvent>;
    const shiftKey = event.shiftKey;

    const { constraint, previousPoints, lastPrecisePointerEvents } =
      (gestureMemo.current || {
        constraint: { enabled: false, angle: 0 },
        previousPoints: [],
        lastPrecisePointerEvents: [],
      }) as {
        constraint: Constraint;
        previousPoints: Point[];
        lastPrecisePointerEvents: number[][];
      };
    if (constraint.enabled && !shiftKey) {
      constraint.enabled = false;
    }

    const newSegments = [] as BrushSegment[];
    // if getCoalescedEvents is not available, fallback to the event itself
    let precisePointerEvents = event.nativeEvent.getCoalescedEvents?.() || [
      event.nativeEvent,
    ];
    if (precisePointerEvents.length === 0) {
      precisePointerEvents = [event.nativeEvent];
    }

    // On iOS pointer events can be duplicated. Skip if current pointer events are identical to the previous ones
    if (
      lastPrecisePointerEvents.length === precisePointerEvents.length &&
      precisePointerEvents.every((event, i) => {
        return (
          event.clientX === lastPrecisePointerEvents[i][0] &&
          event.clientY === lastPrecisePointerEvents[i][1]
        );
      })
    ) {
      return;
    }
    precisePointerEvents.forEach((e) => {
      //Fix for Wacom + Firefox + Windows where multiple incorrect [0, 0] coords are issued just after the first point.
      //Discards points at [0, 0] if there is only one existing point. In practice this should not affect intentional drawings.
      if (previousPoints.length === 1 && e.clientX === 0 && e.clientY === 0) {
        return;
      }

      const previousPoint = previousPoints[previousPoints.length - 1];

      if (
        // Deduplicate PointerEvents (Safari)
        previousPoint &&
        previousPoint.screenPosition[0] === e.clientX &&
        previousPoint.screenPosition[1] === e.clientY &&
        // Allow duplicated point when is last and we have single previous point
        // This draws a dot when the user just taps without moving the pointer
        !(previousPoints.length === 1)
      ) {
        return;
      }

      if (
        !isSelectionTool(tool) &&
        !constraint.enabled &&
        previousPoint &&
        shiftKey
      ) {
        const dx = previousPoint.screenPosition[0] - e.clientX;
        const dy = previousPoint.screenPosition[1] - e.clientY;
        if (Math.sqrt(dx ** 2 + dy ** 2) < LINE_TOOL_CONSTRAINT_THRESHOLD) {
          return;
        }
        const angle =
          Math.round(Math.atan2(dy, dx) / LINE_TOOL_ANGLE_GRANULARITY) *
          LINE_TOOL_ANGLE_GRANULARITY;
        constraint.enabled = true;
        constraint.angle = angle;
      }

      let screenPosition: [number, number] = [e.clientX, e.clientY];
      if (constraint.enabled) {
        const dx = previousPoint.screenPosition[0] - e.clientX;
        const dy = previousPoint.screenPosition[1] - e.clientY;
        const h = Math.sqrt(dx ** 2 + dy ** 2);
        const a = Math.cos(Math.atan2(-dy, -dx) - constraint.angle) * h;
        screenPosition = [
          previousPoint.screenPosition[0] + Math.cos(constraint.angle) * a,
          previousPoint.screenPosition[1] + Math.sin(constraint.angle) * a,
        ];
      }

      const pressure = getPressure(event);
      const point: Point = {
        screenPosition,
        stabilizedScreenPosition: [...screenPosition],
        position: [0, 0], // This will be set after stabilization
        pressure,
        stabilizedPressure: pressure,
      };

      stabilizePoint(point, previousPoints, toolStabilizer);

      point.position = screenPositionToLayer(
        point.stabilizedScreenPosition,
        camera,
        eventPlaneRef.current,
        drawingSize
      );

      lastDragPressure.current = point.stabilizedPressure;
      if (isProlongedDragEvent(event)) {
        lastUsedPoint.current = point;
        lastPressure.current = lastDragPressure.current;
      }

      if (previousPoint) {
        newSegments.push({
          startPosition: [previousPoint.position[0], previousPoint.position[1]],
          startPressure: previousPoint.stabilizedPressure,
          endPosition: point.position,
          endPressure: point.stabilizedPressure,
        });
      } else {
        // First point
        newSegments.push({
          startPosition: [point.position[0], point.position[1]],
          startPressure: point.stabilizedPressure,
          endPosition: point.position,
          endPressure: point.stabilizedPressure,
        });
      }

      //When we have enough points, re-compute the pressure to correct the first points.
      //Segments with the correction are issued for the client code to redraw this part.
      if (
        onInitialPressureStabilized &&
        previousPoints.length === POINT_HISTORY_MAX_LENGTH - 1
      ) {
        stabilizeInitialPressure(previousPoints);
        onInitialPressureStabilized(
          previousPoints.slice(0, -1).map((prevPoint, i) => ({
            startPosition: [prevPoint.position[0], prevPoint.position[1]],
            startPressure: prevPoint.stabilizedPressure,
            endPosition: previousPoints[i + 1].position,
            endPressure: previousPoints[i + 1].stabilizedPressure,
          }))
        );
      }
      previousPoints.push(point);
      if (previousPoints.length > POINT_HISTORY_MAX_LENGTH) {
        previousPoints.shift();
      }
    });

    newSegments.length && onNewSegments(event, newSegments);

    gestureMemo.current = {
      constraint,
      previousPoints,
      lastPrecisePointerEvents: precisePointerEvents.map((e) => [
        e.clientX,
        e.clientY,
      ]),
    };
  };

  const bind = useGesture({
    onPointerDown: ({ event }) => {
      if (preventFingerInteractions && event.pointerType === 'touch') {
        return;
      }

      if (event.button === 2) return; // Ignore right-clicks

      const e = event as unknown as ThreeEvent<PointerEvent>;

      isDrawing.current = true;
      gestureMemo.current = null;

      initialPoint.current[0] = e.clientX;
      initialPoint.current[1] = e.clientY;

      drawSegment({ event: e, isPointerDown: true });
    },
    onPointerUp: (state) => {
      if (!isDrawing.current) {
        return;
      }

      const event = state.event as unknown as ThreeEvent<PointerEvent>;

      isDrawing.current = false;

      const screenPosition: [number, number] = [event.clientX, event.clientY];
      const position = screenPositionToLayer(
        screenPosition,
        camera,
        eventPlaneRef.current,
        drawingSize
      );
      const pressure = getPressure(event);
      const endPoint: Point = {
        screenPosition,
        stabilizedScreenPosition: screenPosition,
        position,
        pressure,
        stabilizedPressure: pressure,
      };
      const startPoint = lastUsedPoint.current;

      if (!isProlongedDragEvent(event)) {
        if (event.shiftKey && startPoint) {
          const newSegment: BrushSegment = {
            startPosition: startPoint.position,
            startPressure: lastPressure.current,
            endPosition: endPoint.position,
            endPressure: lastDragPressure.current,
          };
          onNewSegments(event, [newSegment]);
        }

        lastUsedPoint.current = endPoint;
        lastPressure.current = lastDragPressure.current;
      }

      onStrokeEnd(event);
      gestureMemo.current = null;
    },
    onPointerMove: (state) => {
      const event = state.event as unknown as ThreeEvent<PointerEvent>;
      drawSegment({ event });
    },
  });

  return {
    bind,
  };
};

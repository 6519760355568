import { FloatingTree } from '@floating-ui/react';
import { Dispatch, SetStateAction, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { ImageInferenceType } from '@vizcom/shared/data-access/graphql';
import {
  BlockStack,
  InfoIcon,
  NumberInput,
  OnboardingTourTooltipContent,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  Text,
  useLocalStorage,
  useShouldDisplayOnboardingTour,
  Checkbox,
} from '@vizcom/shared-ui-components';

import educationalTooltipVideo03 from '../../../assets/videos/educational-tooltip-03.mp4';
import { Drawing2dStudio } from '../../../lib/useDrawingSyncedState';
import {
  PaletteSelector,
  VERSION_SELECTIONS_KEY,
  VersionTag,
  getPaletteVersion,
  useAvailablePalettes,
} from '../../modals/PaletteSelector/PaletteSelector';
import { VersionedPalette } from '../../modals/PaletteSelector/PaletteVersionsMenu';
import {
  OnboardingStep,
  useOnboardingMultiStep,
} from '../../utils/OnboardingMultiStep';
import { VideoTooltip } from '../Toolbar/VideoTooltip';
import { InferenceSettings } from '../useInference';
import paletteSliderInfo from './assets/paletter_slider_x480.mp4';
import {
  MinimalInferenceSettings,
  useReferenceMode,
} from './utils/useReferenceMode';

interface PaletteSettingsProps {
  drawing: Drawing2dStudio;
  historyPreviewActive: boolean;
  setInferenceSettings: Dispatch<SetStateAction<InferenceSettings>>;
  inferenceSettings: Pick<
    InferenceSettings,
    | 'referenceMode'
    | 'styleReference'
    | 'colorReference'
    | 'publicPaletteId'
    | 'customModelId'
    | 'workbenchPaletteId'
    | 'userPaletteId'
    | 'imageInferenceType'
    | 'paletteInfluence'
    | 'colorCoherence'
  >;
  onExit: () => void;
}

export const PaletteSettings = ({
  drawing,
  historyPreviewActive,
  setInferenceSettings,
  inferenceSettings,
  onExit,
}: PaletteSettingsProps) => {
  const theme = useTheme();
  const [versionSelections] = useLocalStorage(
    VERSION_SELECTIONS_KEY,
    {} as Record<string, number>
  );
  const shouldDisplayTour = useShouldDisplayOnboardingTour();
  const { currentStep } = useOnboardingMultiStep();
  const [isNumberInputDragging, setIsNumberInputDragging] = useState(false);

  // Use the enhanced hook for reference mode logic
  const referenceMode = useReferenceMode(
    inferenceSettings as MinimalInferenceSettings,
    setInferenceSettings
  );

  // -------------------------------------------------------------------------
  // PALETTE SELECTION
  // -------------------------------------------------------------------------

  const availablePalettes = useAvailablePalettes(drawing.workbench?.id);

  /**
   * Determine the currently selected palette ID using the hook
   */
  const selectedPaletteId = referenceMode.getSelectedPaletteId();

  /**
   * Find the selected palette object from available palettes
   * This handles both direct matches and palettes with versions
   */
  const selectedPalette =
    (availablePalettes.find((p) => p.value === selectedPaletteId) ||
      availablePalettes.find(
        (palette) =>
          palette.versions &&
          palette.versions.find((v) => v.value === selectedPaletteId)
      )) ??
    availablePalettes[0];

  /**
   * Handle palette selection with the enhanced hook function
   */
  const handleSelectPalette = (paletteId: string, type: string) => {
    const tags =
      availablePalettes.find((p) => p.value === paletteId)?.tags ??
      availablePalettes.find(
        (p) => p.versions && p.versions.find((v) => v.value === paletteId)
      )?.tags ??
      [];

    // Use the hook's handleSelectPalette function with tags
    referenceMode.handleSelectPalette(paletteId, type, tags);
  };

  /**
   * Create the palette chip component for display
   */
  const paletteChip = (
    <ChipContainer>
      <ThumbnailWrapper>
        {selectedPalette.thumbnailPath && (
          <Thumbnail
            src={selectedPalette.thumbnailPath}
            alt={selectedPalette.name}
          />
        )}
      </ThumbnailWrapper>
      <PaletteName>{selectedPalette.name}</PaletteName>
      {selectedPalette.versions && selectedPalette.versions.length > 0 && (
        <VersionTag>
          v
          {getPaletteVersion(
            selectedPalette as VersionedPalette,
            selectedPaletteId
          ) ??
            versionSelections[selectedPalette.id] ??
            selectedPalette.versions.length + 1}
        </VersionTag>
      )}
    </ChipContainer>
  );

  /**
   * Determines if the palette selector should be disabled
   * Only disable when there's an actual reference selected or Color-Match is enabled
   */
  const isPaletteSelectorDisabled =
    historyPreviewActive ||
    referenceMode.isPaletteSelectionBlocked(inferenceSettings) ||
    inferenceSettings.colorCoherence;

  /**
   * Renders the appropriate palette selector based on whether it's disabled
   */
  const renderPaletteSelector = () => {
    // Only show the tooltip when palette selection is actually blocked
    if (
      referenceMode.isPaletteSelectionBlocked(inferenceSettings) ||
      inferenceSettings.colorCoherence
    ) {
      return (
        <RichTooltip noParentIntegration padding={14} placement="left">
          <RichTooltipTrigger>{paletteChip}</RichTooltipTrigger>
          <RichTooltipContent>
            <Text block>{referenceMode.getPaletteTooltipMessage()}</Text>
            <Text color="subtext" block>
              {referenceMode.getPaletteTooltipSubtext()}
            </Text>
          </RichTooltipContent>
        </RichTooltip>
      );
    }

    return (
      <FloatingTree>
        <RichTooltip
          noParentIntegration
          padding={10}
          trigger="click"
          placement="left"
          displayArrow={false}
          isOpen={historyPreviewActive ? false : undefined}
        >
          <RichTooltipTrigger>{paletteChip}</RichTooltipTrigger>
          <RichTooltipContent
            style={{
              padding: 0,
              borderRadius: theme.borderRadius.l,
              overflow: 'hidden',
            }}
          >
            <PaletteSelector
              workbenchId={drawing.workbench?.id}
              size="large"
              availablePalettes={availablePalettes}
              selectedPaletteId={selectedPaletteId}
              handleSelectPalette={handleSelectPalette}
              onExit={onExit}
              in2DStudio
            />
          </RichTooltipContent>
        </RichTooltip>
      </FloatingTree>
    );
  };

  /**
   * Renders the palette strength slider if applicable
   */
  const renderPaletteStrengthSlider = () => {
    if (inferenceSettings.imageInferenceType !== ImageInferenceType.Render) {
      return null;
    }

    const isPaletteStrengthDisabled = referenceMode.isPaletteStrengthDisabled(
      inferenceSettings,
      historyPreviewActive
    );

    return (
      <InfluenceSettingChip
        onClick={(e: React.MouseEvent) => e.stopPropagation()}
        style={{
          pointerEvents: isPaletteStrengthDisabled ? 'none' : 'all',
          opacity: isPaletteStrengthDisabled ? 0.5 : 1,
        }}
      >
        <RichTooltip
          noParentIntegration
          placement="top"
          trigger="hover"
          delay={{
            open: 500,
            close: 0,
          }}
          padding={8}
          isOpen={isNumberInputDragging ? false : undefined}
        >
          <RichTooltipTrigger>
            <div>
              <NumberInput
                value={Math.round(inferenceSettings.paletteInfluence * 100)}
                min={0}
                max={100}
                step={5}
                disabled={isPaletteStrengthDisabled}
                unit="%"
                setValue={(value) =>
                  setInferenceSettings((inferenceSettings) => ({
                    ...inferenceSettings,
                    paletteInfluence: value / 100,
                  }))
                }
                onChange={(value) => {
                  setInferenceSettings((inferenceSettings) => ({
                    ...inferenceSettings,
                    paletteInfluence: value / 100,
                  }));
                }}
                dragArrows={false}
                enableGestureSlider
                onDragStateChange={setIsNumberInputDragging}
              />
            </div>
          </RichTooltipTrigger>
          <RichTooltipContent>
            <Text>
              {isPaletteStrengthDisabled
                ? 'Palette strength adjustment is disabled in this mode'
                : 'Adjust the strength of the palette influence'}
            </Text>
          </RichTooltipContent>
        </RichTooltip>
      </InfluenceSettingChip>
    );
  };

  /**
   * Renders the color match option if applicable
   */
  const renderColorMatchOption = () => {
    if (inferenceSettings.imageInferenceType !== ImageInferenceType.Render) {
      return null;
    }

    const isColorMatchDisabled =
      referenceMode.isColorMatchDisabled(inferenceSettings);

    return (
      <OptionContainer>
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <OptionLabel type="b2" color="subtext">
            Color-Match
          </OptionLabel>
          <RichTooltip trigger="hover" placement="top" padding={16}>
            <RichTooltipTrigger>
              <StyledInfoIcon $size="S" />
            </RichTooltipTrigger>
            <RichTooltipContent>
              <Text>
                Render your image while preserving its color.
                <br />
                This will lock the palette to Realistic Product and disable
                Reference selection.
                {isColorMatchDisabled && (
                  <>
                    <br />
                    <br />
                    {referenceMode.getColorMatchDisabledMessage()}
                  </>
                )}
              </Text>
            </RichTooltipContent>
          </RichTooltip>
          <BetaBadge>Beta</BetaBadge>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            checked={inferenceSettings.colorCoherence}
            onClick={referenceMode.handleColorMatchToggle}
            disabled={isColorMatchDisabled}
          />
        </div>
      </OptionContainer>
    );
  };

  /**
   * Renders the palette header with appropriate tooltip
   */
  const renderPaletteHeader = () => {
    if (shouldDisplayTour && currentStep === 3) {
      return (
        <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
          <Text type="sh2">Palette</Text>
          <RichTooltip isOpen={true} placement="left" padding={88}>
            <RichTooltipTrigger>
              <StyledInfoIcon $size="S" />
            </RichTooltipTrigger>
            <OnboardingTourTooltipContent>
              <OnboardingStep
                video={educationalTooltipVideo03}
                title="Select a Palette"
                content="Choose from a number of styles to see your idea rendered in different ways."
              />
            </OnboardingTourTooltipContent>
          </RichTooltip>
        </div>
      );
    }

    return (
      <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
        <Text type="sh2">Palette</Text>
        <RichTooltip trigger="hover" placement="left" padding={16}>
          <RichTooltipTrigger>
            <StyledInfoIcon $size="S" />
          </RichTooltipTrigger>
          <RichTooltipContent>
            <VideoTooltip
              title="Palette"
              description="Select a rendering style and adjust the detail level for your generation"
              videoSrc={paletteSliderInfo}
              learnMoreUrl="https://docs.vizcom.ai/render-styles"
            />
          </RichTooltipContent>
        </RichTooltip>
      </div>
    );
  };

  return (
    <BlockStack
      $gap={16}
      style={{
        padding: 16,
        width: '100%',
      }}
    >
      {renderPaletteHeader()}

      <div
        style={{
          display: 'flex',
          gap: 8,
        }}
      >
        {/* Palette Selector */}
        <div
          style={{
            pointerEvents: isPaletteSelectorDisabled ? 'none' : 'all',
            opacity: isPaletteSelectorDisabled ? 0.5 : 1,
            flex: 1,
          }}
        >
          {renderPaletteSelector()}
        </div>

        {/* Palette Strength Slider */}
        {renderPaletteStrengthSlider()}
      </div>

      {/* Color Match Option */}
      {renderColorMatchOption()}
    </BlockStack>
  );
};

const StyledInfoIcon = styled(InfoIcon)`
  color: ${(p) => p.theme.icon.secondary};

  &:hover {
    color: ${({ theme }) => theme.icon.primary};
  }
`;

const ChipContainer = styled.div`
  display: flex;
  height: 34px;
  flex: 1;
  padding: 4px 2px 4px 4px;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  border: 1px solid transparent;
  background: ${({ theme }) => theme.surface.secondary};
  cursor: pointer;
  overflow: hidden;
  &:hover {
    border: 1px solid ${({ theme }) => theme.deprecated.secondary.hover};
  }
`;

const ThumbnailWrapper = styled.div`
  width: 24px;
  height: 24px;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.borderRadius.s};
  flex-shrink: 0;
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const PaletteName = styled.span`
  font-size: 12px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  margin-left: 8px;
  margin-right: 8px;
`;

const InfluenceSettingChip = styled.div`
  width: 50px;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  flex-shrink: 0;
  background: ${({ theme }) => theme.surface.secondary};
  border: 1px solid transparent;
  &:hover {
    border: 1px solid ${({ theme }) => theme.deprecated.secondary.hover};
  }
`;

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  user-select: none;
`;

const OptionLabel = styled(Text)`
  margin-right: auto;
`;

const BetaBadge = styled.span`
  color: ${({ theme }) => theme.text.subtext};
  font-size: 8px;
  padding-bottom: 6px;
  font-weight: 700;
  border-radius: 12px;
  text-transform: uppercase;
  letter-spacing: 0.8px;
`;

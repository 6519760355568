import { useForm, SubmitHandler } from 'react-hook-form';
import { useChangePassword } from '@vizcom/shared/data-access/graphql';
import {
  Button,
  LoadingLogoInset,
  TextInput,
  addToast,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';

import { usePasswordValidation } from '../../auth/usePasswordValidation';

type ChangePasswordInputs = {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirmation: string;
};

export const ChangePassword = () => {
  const [changePasswordRes, changePassword] = useChangePassword();

  const { register, handleSubmit, reset, formState, watch } =
    useForm<ChangePasswordInputs>({
      defaultValues: {
        currentPassword: '',
        newPassword: '',
        newPasswordConfirmation: '',
      },
    });

  const { valid, passwordChecklist } = usePasswordValidation(
    watch('newPassword'),
    watch('newPasswordConfirmation')
  );

  const handleChangePassword: SubmitHandler<ChangePasswordInputs> = async (
    data
  ) => {
    if (!valid) {
      return addToast('Password does not meet all requirements', {
        type: 'danger',
      });
    }
    if (changePasswordRes.fetching) {
      return;
    }
    if (data.newPassword !== data.newPasswordConfirmation) {
      return addToast(`Password confirm doesn't match the new password`, {
        type: 'danger',
      });
    }
    const res = await changePassword({
      input: {
        oldPassword: data.currentPassword,
        newPassword: data.newPassword,
      },
    });

    if (res.error) {
      return addToast('Error while changing password', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    } else {
      addToast(`Password changed successfully`);
      reset();
    }
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit(handleChangePassword)}
        style={{
          display: 'flex',
          marginTop: 24,
          flexDirection: 'column',
          width: '100%',
          gap: 12,
        }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 16,
          }}
        >
          <TextInput
            style={{ gridColumn: '1/3' }}
            required
            type="password"
            placeholder="Current password"
            disabled={changePasswordRes.fetching}
            $background="secondary"
            {...register('currentPassword')}
          />

          <TextInput
            required
            type="password"
            placeholder="New password"
            disabled={changePasswordRes.fetching}
            $background="secondary"
            {...register('newPassword')}
          />
          <TextInput
            required
            type="password"
            placeholder="Confirm new password"
            disabled={changePasswordRes.fetching}
            $background="secondary"
            {...register('newPasswordConfirmation')}
          />
          {watch('newPassword').length !== 0 && passwordChecklist}
        </div>

        <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
          <Button
            variant="primary"
            type="submit"
            disabled={changePasswordRes.fetching || !formState.isDirty}
          >
            Save
          </Button>
          <Button
            type="button"
            variant="tertiary"
            onClick={() => reset()}
            disabled={!formState.isDirty}
          >
            Cancel
          </Button>
        </div>
      </form>
      <LoadingLogoInset active={changePasswordRes.fetching} />
    </div>
  );
};

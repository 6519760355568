import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  CMD_KEY_PREFIX,
  HomeIcon,
  Import3DModelIcon,
  ImportImageIcon,
  MenuDivider,
  MenuItem,
  Text,
  Toolbar,
  VizcomLogo,
  addToast,
  browseForFiles,
  Menu,
  ToolbarButton,
  MenuIcon,
  ToolbarDivider,
  UpgradeIcon,
  useIsFree,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

import {
  copyImageToClipboard,
  duplicateSelectedLayers,
} from '../../../lib/drawingUtils';
import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../../lib/useDrawingSyncedState';
import { useIsWorkbenchViewer } from '../../../lib/utils';
import { HtmlOverlay } from '../../utils/HtmlOverlay';
import { WorkbenchNameInput } from '../../workbenchMenu/WorkbenchNameInput';
import { WorkbenchSettingsMenu } from '../Toolbar/WorkbenchSettingsMenu';
import { importBlobsToLayers } from '../WorkbenchStudioFileDropper';
import { useExportUtils } from '../lib/useExportUtils';
import { useSelectionApiStore } from '../selection/useSelectionApi';
import { cutSelection } from '../utils';
import { WorkbenchStudioMenuLayoutItem } from './WorkbenchStudioMenuLayoutItem';

interface WorkbenchStudioMenuProps {
  drawing: Drawing2dStudio;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  setShowOverlay: (show: boolean) => void;
  setActiveLayerId: (id: string | undefined) => void;
  activeLayerId: string | undefined;
  canUndo: boolean;
  canRedo: boolean;
  undoAction: () => void;
  redoAction: () => void;
  handlePaste: (event: React.MouseEvent) => void;
}

export const WorkbenchStudioMenu = (props: WorkbenchStudioMenuProps) => {
  const navigate = useNavigate();
  const isViewer = useIsWorkbenchViewer();
  const [isEditingName, setIsEditingName] = useState(false);
  const [loadingInsertMesh, setLoadingInsertMesh] = useState(false);
  const isFreePlan = useIsFree(props.drawing.id);

  const {
    isExporting,
    handleExportToVideo,
    handleExportToWatermarkFreeVideo,
    handleExportToPng,
    handleExportToUpscaledPng,
    exportPSD,
    exportAllModels,
    exportAll2DLayers,
  } = useExportUtils(props.drawing, isFreePlan);

  const updateDrawingName = (name: string) => {
    if (!name || name === props.drawing.name) return;
    props.handleAction({
      type: 'updateDrawing',
      name,
    });
  };

  const {
    drawing,
    handleAction,
    setShowOverlay,
    setActiveLayerId,
    activeLayerId,
  } = props;

  const handleUploadFile = async () => {
    const files = await browseForFiles({
      accept: 'image/*',
    });
    if (!files) {
      return;
    }
    setShowOverlay(true);
    importBlobsToLayers(
      files,
      drawing,
      handleAction,
      activeLayerId,
      setActiveLayerId
    );
    setShowOverlay(false);
  };

  const handleUpload3dModel = async () => {
    const files = await browseForFiles({
      accept: '.fbx,.glb,.gltf,.obj,.stl',
    });
    if (!files) {
      return;
    }
    setShowOverlay(true);
    setLoadingInsertMesh(true);
    importBlobsToLayers(
      files,
      drawing,
      handleAction,
      activeLayerId,
      setActiveLayerId
    );
    setShowOverlay(false);
    setLoadingInsertMesh(false);
  };

  const selectionApiStore = useSelectionApiStore();

  const handleDuplicate = () => {
    if (!activeLayerId || !drawing.isEditable) return;
    duplicateSelectedLayers(activeLayerId.split('/'), drawing, handleAction);
  };

  const isCopyDisabled =
    !activeLayerId ||
    !drawing.layers.nodes.find((l) => l.id === activeLayerId)?.imagePath;
  const isLayerOperationDisabled =
    !activeLayerId?.split('/').length || !drawing.isEditable;

  const isCutDisabled = isCopyDisabled || !selectionApiStore.getState().hasMask;

  const handleCopy = () => {
    const activeLayerIds = activeLayerId?.split('/') || [];
    const activeLayers = activeLayerIds
      .map((id) => drawing.layers.nodes.find((l) => l.id === id))
      .filter((l) => l?.imagePath);

    if (activeLayers.length === 0) {
      addToast('Selected layers are empty');
      return;
    }

    // If only one layer is selected, use existing single-layer copy logic
    if (activeLayers.length === 1 && activeLayers[0]?.imagePath) {
      copyImageToClipboard(
        activeLayers[0].imagePath,
        selectionApiStore.getState().getSelectionImage()
      );
      return;
    }
  };

  const handleCut = () => {
    const activeLayerIds = activeLayerId?.split('/') || [];
    const activeLayers = activeLayerIds
      .map((id) => drawing.layers.nodes.find((l) => l.id === id))
      .filter((l) => l?.imagePath);

    if (activeLayers.length === 0) {
      addToast('Selected layers are empty');
      return;
    }

    // If only one layer is selected, use existing single-layer cut logic
    const selectionData = selectionApiStore.getState().getSelectionImage();
    if (
      activeLayers.length === 1 &&
      activeLayers[0]?.imagePath &&
      selectionData
    ) {
      cutSelection(activeLayers[0], selectionData, handleAction);
      return;
    }
  };

  return (
    <HtmlOverlay>
      <StyledToolbar position="top-left">
        <Menu
          renderLabel={(props, interactionProps) => (
            <ToolbarButton
              icon={<VizcomLogo />}
              tooltip="Studio menu"
              buttonProps={{
                style: { width: 'auto', paddingRight: 0, gap: '8px' },
              }}
              {...interactionProps}
            >
              <MenuIcon tooltip="" {...interactionProps} />
            </ToolbarButton>
          )}
        >
          <MenuItem
            label={
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <HomeIcon /> Back to workspace
              </div>
            }
            onClick={() =>
              navigate(
                props.drawing.workbench?.folder?.id
                  ? paths.files.folder(props.drawing.workbench?.folder?.id)
                  : '/'
              )
            }
          />
          <MenuDivider />
          <MenuItem label="File">
            <MenuItem label="Open / Import">
              <MenuItem
                label={
                  <OptionContainer>
                    <ImportImageIcon />
                    <Text>Upload an image</Text>
                    <Text color="subtext">I</Text>
                  </OptionContainer>
                }
                onClick={handleUploadFile}
              />
              <MenuItem
                label={
                  <OptionContainer>
                    <Import3DModelIcon />
                    <Text>Upload a 3D model</Text>
                    <Text color="subtext">P</Text>
                  </OptionContainer>
                }
                onClick={handleUpload3dModel}
                disabled={loadingInsertMesh}
              />
            </MenuItem>
            <MenuItem
              label={
                <OptionContainer>
                  <Text>Export Image • 1x</Text>
                  <Size>
                    {props.drawing.width} x {props.drawing.height}
                  </Size>
                </OptionContainer>
              }
              onClick={handleExportToPng}
              disabled={isExporting}
            />
            <MenuItem
              label={
                <OptionContainer>
                  <Text>Export Image • 2x</Text>
                  {isFreePlan && <UpgradeIcon />}
                  <Size>
                    {props.drawing.width * 2} x {props.drawing.height * 2}
                  </Size>
                </OptionContainer>
              }
              onClick={handleExportToUpscaledPng}
              disabled={isExporting}
            />
            <MenuDivider />
            <MenuItem
              label={
                <OptionContainer>
                  <Text>
                    Export Video • <Text color="link">Layer time-lapse</Text>
                  </Text>
                  <Size>
                    {props.drawing.width} x {props.drawing.height}
                  </Size>
                </OptionContainer>
              }
              onClick={handleExportToVideo}
              disabled={isExporting}
            />
            {isFreePlan && (
              <MenuItem
                label={
                  <OptionContainer>
                    <Text>
                      Export Video •{' '}
                      <Text color="link">No Vizcom watermark</Text>
                    </Text>
                    <UpgradeIcon />
                    <Size>
                      {props.drawing.width} x {props.drawing.height}
                    </Size>
                  </OptionContainer>
                }
                onClick={handleExportToWatermarkFreeVideo}
                disabled={isExporting}
              />
            )}
            <MenuDivider />
            <MenuItem
              label={
                <OptionContainer>
                  <Text>Export PSD • 1x</Text>
                  <Size>
                    {props.drawing.width} x {props.drawing.height}
                  </Size>
                </OptionContainer>
              }
              onClick={exportPSD}
              disabled={isExporting}
            />
            <MenuDivider />
            <MenuItem
              label={
                <OptionContainer>
                  <Text>Export 2D • All layers</Text>
                  <Size>ZIP</Size>
                </OptionContainer>
              }
              onClick={exportAll2DLayers}
              disabled={isExporting}
            />
            <MenuItem
              label={
                <OptionContainer>
                  <Text>Export 3D • All models</Text>
                  {isFreePlan && <UpgradeIcon />}
                  <Size>ZIP</Size>
                </OptionContainer>
              }
              onClick={exportAllModels}
              disabled={isExporting}
            />
          </MenuItem>

          <MenuItem label="Edit">
            {/* History Operations */}
            <MenuItem
              label={
                <OptionContainer>
                  <Text color={!props.canUndo ? 'bodyDisabled' : undefined}>
                    Undo
                  </Text>
                  <Text color="subtext">{CMD_KEY_PREFIX}Z</Text>
                </OptionContainer>
              }
              onClick={props.undoAction}
              disabled={!props.canUndo}
            />
            <MenuItem
              label={
                <OptionContainer>
                  <Text color={!props.canRedo ? 'bodyDisabled' : undefined}>
                    Redo
                  </Text>
                  <Text color="subtext">{CMD_KEY_PREFIX}⇧Z</Text>
                </OptionContainer>
              }
              onClick={props.redoAction}
              disabled={!props.canRedo}
            />
            <MenuDivider />

            {/* Clipboard Operations */}
            <MenuItem
              label={
                <OptionContainer>
                  <Text color={isCopyDisabled ? 'bodyDisabled' : undefined}>
                    Copy
                  </Text>
                  <Text color="subtext">{CMD_KEY_PREFIX}C</Text>
                </OptionContainer>
              }
              onClick={handleCopy}
              disabled={isCopyDisabled}
            />
            <MenuItem
              label={
                <OptionContainer>
                  <Text color={isCutDisabled ? 'bodyDisabled' : undefined}>
                    Cut
                  </Text>
                  <Text color="subtext">{CMD_KEY_PREFIX}C</Text>
                </OptionContainer>
              }
              onClick={handleCut}
              disabled={isCutDisabled}
            />
            <MenuItem
              label={
                <OptionContainer>
                  <Text
                    color={!drawing.isEditable ? 'bodyDisabled' : undefined}
                  >
                    Paste
                  </Text>
                  <Text color="subtext">{CMD_KEY_PREFIX}V</Text>
                </OptionContainer>
              }
              onClick={props.handlePaste}
              disabled={!drawing.isEditable}
            />
            <MenuDivider />

            {/* Layer Operations */}
            <MenuItem
              label={
                <OptionContainer>
                  <Text
                    color={
                      isLayerOperationDisabled ? 'bodyDisabled' : undefined
                    }
                  >
                    Duplicate
                  </Text>
                  <Text color="subtext">{CMD_KEY_PREFIX}D</Text>
                </OptionContainer>
              }
              onClick={handleDuplicate}
              disabled={isLayerOperationDisabled}
            />
            <MenuItem
              label={
                <OptionContainer>
                  <Text
                    color={
                      isLayerOperationDisabled ? 'bodyDisabled' : undefined
                    }
                  >
                    Delete
                  </Text>
                  <Text color="subtext">Delete</Text>
                </OptionContainer>
              }
              onClick={() => {
                if (activeLayerId) {
                  handleAction({
                    type: 'updateBulkLayers',
                    deletedLayerIds: activeLayerId?.split('/'),
                  });
                }
              }}
              disabled={isLayerOperationDisabled}
            />
          </MenuItem>

          <WorkbenchSettingsMenu />

          <MenuDivider />
          <MenuItem
            label="Documentation"
            onClick={() => window.open(`https://docs.vizcom.ai/`, '_blank')}
          />
          <MenuItem
            label="Feature requests"
            onClick={() =>
              window.open(`https://vizcom.canny.io/feature-requests`, '_blank')
            }
          />
          <MenuItem
            label="Account settings"
            onClick={() => navigate(paths.settings.account.profile())}
          />

          {!isViewer && (
            <>
              <MenuDivider />
              <WorkbenchStudioMenuLayoutItem />
            </>
          )}
        </Menu>

        <ToolbarDivider />

        <WorkbenchNameInput
          handleUpdate={updateDrawingName}
          value={props.drawing.name}
          isEditing={isEditingName}
          setIsEditing={setIsEditingName}
        />
      </StyledToolbar>
    </HtmlOverlay>
  );
};

const StyledToolbar = styled(Toolbar)`
  width: 250px;
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

const Size = styled(Text)`
  margin-left: auto;
  color: ${({ theme }) => theme.text.subtext};
  text-align: right;
  font-feature-settings: 'calt';
  font-variant-numeric: tabular-nums;
`;

import { useFrame, useThree } from '@react-three/fiber';
import { useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { Texture, Mesh, DoubleSide } from 'three';
import { lerp } from 'three/src/math/MathUtils';
import {
  magicEraseImage,
  publishTrackingEvent,
} from '@vizcom/shared/data-access/graphql';
import {
  ToastIndicator,
  addToast,
  imageDataToBlob,
  useCanDrawWithFinger,
  useStateWithRef,
  useDocumentEventListener,
} from '@vizcom/shared-ui-components';

import { WorkbenchContentRenderingOrder } from '../../../WorkbenchContent';
import {
  DrawingLayer,
  useDrawingSyncedState,
} from '../../../lib/useDrawingSyncedState';
import { isMeshBasicMaterial, yFlipImageBuffer } from '../../helpers';
import { VizcomRenderingOrderEntry } from '../../utils/threeRenderingOrder';
import { LayerStaticView } from '../LayersCompositor/CompositeLayer';
import { LayerContent } from '../LayersCompositor/LayerContent';
import { useLayersCompositor } from '../LayersCompositor/context';
import { useBeforeActiveLayerChange } from '../lib/useActiveLayer';
import { useWorkbenchStudioState } from '../studioState';
import { imageDataIsTransparent } from '../utils';
import { BrushCursorPreview } from './BrushEngine/BrushCursorPreview';
import { useBrushSegments } from './BrushEngine/useBrushSegments';
import { useBrushStroke } from './BrushEngine/useBrushStroke';
import { EventMesh } from './EventMesh';
import {
  LayerTextureRenderer,
  LayerTextureRendererRef,
} from './LayerTextureRenderer';

interface MagicEraserProps {
  drawingSize: [number, number];
  layer: DrawingLayer;
  layerImage: Texture | undefined;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  zIndex: number;
}

export const MagicEraser = ({
  drawingSize,
  layer,
  layerImage,
  handleAction,
  zIndex,
}: MagicEraserProps) => {
  const theme = useTheme();
  const layerCompositor = useLayersCompositor();
  const gl = useThree((s) => s.gl);
  const { getToolSettings, symmetry } = useWorkbenchStudioState();
  const toolSettings = getToolSettings();
  const eventPlaneRef = useRef<Mesh>(null!);
  const eraseStrokeMeshRef = useRef<Mesh>(null!);
  const magicEraseResultMeshRef = useRef<Mesh>(null!);
  const { canDrawWithFinger } = useCanDrawWithFinger();
  const [loadingStartedAt, setLoadingStartedAt, loadingStartedAtRef] =
    useStateWithRef<null | number>(null);
  const layerTextureRendererRef = useRef<LayerTextureRendererRef>(null!);
  const [isMultiAreaMode, setIsMultiAreaMode] = useState(false);

  const brushStroke = useBrushStroke({
    drawingSize,
    color: '#ffffff',
    symmetry,
    toolSettings,
  });

  const cancelLoading = () => {
    setLoadingStartedAt(null);
    brushStroke.reset();
  };

  useDocumentEventListener('keydown', (e) => {
    if (e.key === 'Alt' || e.key === 'Option') {
      e.preventDefault();
      setIsMultiAreaMode(true);
    }
  });

  useDocumentEventListener('keyup', (e) => {
    if (e.key === 'Alt' || e.key === 'Option') {
      setIsMultiAreaMode(false);
      // When Alt is released, process all accumulated selections at once
      if (brushStroke.segmentsRef.current.length > 0) {
        processStrokes();
      }
    }
  });

  useBeforeActiveLayerChange(() => {
    cancelLoading();
  });

  // Process all accumulated strokes as a single magic erase operation
  const processStrokes = async () => {
    if (loadingStartedAtRef.current) {
      return;
    }

    const loadingStartedAt = Date.now();
    try {
      const combinedBrushStroke = brushStroke.getRenderTarget();
      if (!combinedBrushStroke) {
        return;
      }

      const [compositedImage, maskImage] = await Promise.all([
        layerCompositor
          .getCompositedImageAsync()
          .then((imageData) => imageDataToBlob(imageData)),
        (async () => {
          // we want exportTexture to be synchronous to simplify the rest of the logic of the brush handling
          // to do so, we read the pixels and store them in an image that then get asynchrounously converted to a png blob
          // just before sending to the server
          const imageData = new ImageData(drawingSize[0], drawingSize[1]);
          await gl.readRenderTargetPixelsAsync(
            combinedBrushStroke,
            0,
            0,
            drawingSize[0],
            drawingSize[1],
            imageData.data
          );
          if (imageDataIsTransparent(imageData)) {
            return null;
          }

          // flip the image upside down because readRenderTargetPixels read pixels from the bottom left corner
          yFlipImageBuffer(imageData.data, drawingSize[0]);
          return imageDataToBlob(imageData);
        })(),
      ]);

      if (!maskImage) {
        return;
      }
      setLoadingStartedAt(loadingStartedAt);

      publishTrackingEvent({
        type: 'MAGIC_ERASER_TRIGGERED',
        data: {},
      });

      const res = await magicEraseImage(compositedImage, maskImage);

      if (loadingStartedAtRef.current !== loadingStartedAt) {
        // operation was cancelled by the user, discard the output
        return;
      }

      // once we get the result from the server, we load the image in a new texture and then update the active layer image
      // by merging the layer existing image with the image returned by the server
      const img = new Image();
      img.src = `data:image/png;base64,${res.image}`;
      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
      });

      if (
        !magicEraseResultMeshRef.current ||
        !isMeshBasicMaterial(magicEraseResultMeshRef.current.material)
      ) {
        // magicEraseResultMeshRef.current can be null if the user quit magic eraser before we received the server response
        return;
      }
      if (!layerTextureRendererRef.current) {
        // user switched to another layer or quit 2D studio before we received the server response
        return;
      }

      const resultTexture = new Texture(img);
      resultTexture.needsUpdate = true;
      magicEraseResultMeshRef.current.material.map = resultTexture;
      magicEraseResultMeshRef.current.visible = true;

      handleAction({
        type: 'updateLayer',
        id: layer.id,
        data: {
          image: layerTextureRendererRef.current.exportTexture(),
        },
      });

      // this mesh is only used when exporting the layer image texture, once it's done and `updateLayer` is executed, we don't need it anymore
      // as the layer now contains the magic eraser result in its image
      magicEraseResultMeshRef.current.material.map = null;
      magicEraseResultMeshRef.current.visible = false;
      resultTexture.dispose();

      publishTrackingEvent({
        type: 'MAGIC_ERASER_SUCCESS',
        data: {
          latency: Date.now() - loadingStartedAt,
        },
      });
    } catch (e: any) {
      if (loadingStartedAtRef.current === loadingStartedAt) {
        addToast(`Error while processing magic eraser`, {
          type: 'danger',
          secondaryText: e?.message ?? 'unknown error',
        });
        publishTrackingEvent({
          type: 'MAGIC_ERASER_ERROR',
          data: {},
        });
      }
    } finally {
      if (loadingStartedAtRef.current === loadingStartedAt) {
        cancelLoading();
      }
    }
  };

  const { bind } = useBrushSegments({
    drawingSize,
    eventPlaneRef,
    onNewSegments: (event, segments) => {
      if (loadingStartedAt) {
        return;
      }
      brushStroke.addSegments(segments, false);
    },
    onStrokeEnd: () => {
      brushStroke.addSegments([], true);
      if (!isMultiAreaMode && brushStroke.segmentsRef.current.length > 0) {
        processStrokes();
      }
    },
    preventFingerInteractions: !canDrawWithFinger,
  });

  useFrame(() => {
    // Display the brush stroke with wave animation for the opacity
    if (
      !eraseStrokeMeshRef.current ||
      !isMeshBasicMaterial(eraseStrokeMeshRef.current.material)
    ) {
      return;
    }
    if (loadingStartedAt) {
      // Animate opacity during processing
      eraseStrokeMeshRef.current.material.opacity = lerp(
        0.8,
        0.6,
        (Math.cos((Date.now() - loadingStartedAt) / 300) + 1) / 2
      );
    } else {
      // Higher opacity in multi-area selection mode for better visibility
      eraseStrokeMeshRef.current.material.opacity = isMultiAreaMode ? 0.8 : 0.6;
    }
  });

  return (
    <>
      <BrushCursorPreview
        drawingSize={drawingSize}
        toolSize={toolSettings.toolSize}
        toolAspect={1}
        toolAngle={0}
        color={'#000000'}
      />

      <LayerContent
        id={layer.id}
        opacity={layer.opacity}
        blendMode={layer.blendMode}
        visible={layer.visible}
        zIndex={zIndex}
        type={'vizcom:toolLayerContent'}
      >
        {/* This renderer is used to merge the result of the magic eraser with the content of the existing active layer */}
        <LayerTextureRenderer
          width={drawingSize[0]}
          height={drawingSize[1]}
          ref={layerTextureRendererRef}
        >
          <mesh
            scale={[drawingSize[0], drawingSize[1], 1]}
            ref={magicEraseResultMeshRef}
            visible={false}
            userData={{
              vizcomRenderingOrder: [
                {
                  zIndex: WorkbenchContentRenderingOrder.indexOf('actions'),
                  escapeZIndexContext: true,
                } satisfies VizcomRenderingOrderEntry,
              ],
            }}
          >
            <planeGeometry args={[1, 1, 1, 1]} />
            <meshBasicMaterial transparent side={DoubleSide} opacity={1} />
          </mesh>
          <LayerStaticView
            layer={layer}
            texture={layerImage}
            drawingSize={drawingSize}
          />
        </LayerTextureRenderer>
      </LayerContent>

      <mesh
        scale={[drawingSize[0], drawingSize[1], 1]}
        ref={eraseStrokeMeshRef}
      >
        {/* This displays the eraser brush stroke in purple and gets a wave effect when loading */}
        <planeGeometry args={[1, 1, 1, 1]} />
        <meshBasicMaterial
          transparent
          side={DoubleSide}
          alphaMap={brushStroke.getTexture()}
          color={theme.text.link}
        />
      </mesh>

      <EventMesh
        drawingSize={drawingSize}
        eventMeshProps={bind() as any}
        ref={eventPlaneRef}
      />

      {loadingStartedAt && (
        <ToastIndicator
          text="Processing..."
          cta={{
            text: 'Cancel',
            action: () => {
              cancelLoading();
            },
          }}
          variant="loading"
        />
      )}
    </>
  );
};

import { shaderMaterial } from '@react-three/drei';
import { extend, useFrame } from '@react-three/fiber';
import { useRef } from 'react';
import { DoubleSide, ShaderMaterial, Texture } from 'three';

export enum MaskDisplayMode {
  MARCHING_ANTS,
  FILL,
}

// Display the current black/white mask as moving dashed lines
export const ActiveMask = ({
  maskTexture,
  drawingSize,
  mode,
  darkenNonSelected,
}: {
  maskTexture: Texture;
  drawingSize: [number, number];
  mode: MaskDisplayMode;
  darkenNonSelected?: boolean;
}) => {
  const matRef = useRef<ShaderMaterial>(null!);
  useFrame(() => {
    if (matRef.current && mode === MaskDisplayMode.MARCHING_ANTS) {
      matRef.current.uniforms.u_time.value = (performance.now() / 1000) % 1000;
    }
  });
  return (
    <mesh scale={[drawingSize[0], drawingSize[1], 1]}>
      <planeGeometry args={[1, 1, 1, 1]} />
      {mode === MaskDisplayMode.MARCHING_ANTS && (
        <marchingAntsMaskMaterial
          ref={matRef}
          key={MarchingAntsMaskMaterialImpl.key}
          transparent
          side={DoubleSide}
          u_selectionTexture={maskTexture}
          u_drawingSize={[drawingSize[0], drawingSize[1]]}
          u_resolution={[window.innerWidth, window.innerHeight]}
          u_time={0}
          u_darkenNonSelected={darkenNonSelected}
        />
      )}
      {mode === MaskDisplayMode.FILL && (
        <fillMaskMaterial
          ref={matRef}
          key={FillMaskMaterialImpl.key}
          transparent
          side={DoubleSide}
          u_selectionTexture={maskTexture}
          u_darkenNonSelected={darkenNonSelected}
        />
      )}
    </mesh>
  );
};

// Shader material used to render a black/white mask to moving dashed lines
const MarchingAntsMaskMaterialImpl = shaderMaterial(
  {
    u_selectionTexture: null,
    u_time: 0,
    u_drawingSize: [0, 0],
    u_resolution: [0, 0],
    u_darkenNonSelected: false,
  },
  /* glsl */ `
  varying vec2 v_uv;

  void main() {
    gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(position, 1.);
    v_uv = uv;
  }
`,
  /* glsl */ `
  varying vec2 v_uv;

  uniform sampler2D u_selectionTexture;
  uniform float u_time;
  uniform vec2 u_drawingSize;
  uniform vec2 u_resolution;

  uniform mat4 projectionMatrix;
  uniform mat4 modelMatrix;
  uniform bool u_darkenNonSelected;

  void main() {

    mat4 m = projectionMatrix * modelMatrix;

    float thickness = 1.0;
    float threshold = 0.5;
    vec3 delta = vec3(thickness / (m * vec4(u_drawingSize, 0.0, 0.0)).xy, 0.0);

    // Edge detection. Exterior of texture is considered black.
    float left = v_uv.x - 2.0 * delta.x <= 0.0 ? 0.0 : texture(u_selectionTexture, v_uv - delta.xz).r;
    float right = v_uv.x + 2.0 * delta.x >= 1.0 ? 0.0 : texture(u_selectionTexture, v_uv + delta.xz).r;
    float top = v_uv.y - 2.0 * delta.y <= 0.0 ? 0.0 : texture(u_selectionTexture, v_uv - delta.zy).r;
    float bottom = v_uv.y + 2.0 * delta.y >= 1.0 ? 0.0 : texture(u_selectionTexture, v_uv + delta.zy).r;
    float minValue = min(min(right, left), min(top, bottom));
    float maxValue = max(max(right, left), max(top, bottom));

    if (minValue < threshold && maxValue >= threshold) {
      //dash pattern for the line.
      float dotSize = 25.0;
      float speed = 35.0;
      float dashPattern = step(mod(gl_FragCoord.x + gl_FragCoord.y - u_time * speed, dotSize) / dotSize, 0.5);
      gl_FragColor = vec4(vec3(dashPattern), 1.0);
    } else {
      float mask = texture(u_selectionTexture, v_uv).r;
      // Transparent color for the rest.
      gl_FragColor = u_darkenNonSelected && mask < threshold ? vec4(0.0, 0.0, 0.0, 0.5) : vec4(0.0, 0.0, 0.0, 0.0);
    }
    //uncomment to see selection texture
    //gl_FragColor = texture(u_selectionTexture, v_uv);
  }
  `
);
extend({ MarchingAntsMaskMaterial: MarchingAntsMaskMaterialImpl });

type MarchingAntsMaskMaterialType = JSX.IntrinsicElements['shaderMaterial'] & {
  u_selectionTexture: Texture;
  u_time: number;
  u_drawingSize: [number, number];
  u_resolution: [number, number];
  u_darkenNonSelected?: boolean;
};

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      marchingAntsMaskMaterial: MarchingAntsMaskMaterialType;
    }
  }
}

const FillMaskMaterialImpl = shaderMaterial(
  {
    u_selectionTexture: null,
    u_darkenNonSelected: false,
  },
  /* glsl */ `
  varying vec2 v_uv;

  void main() {
    gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(position, 1.);
    v_uv = uv;
  }
`,
  /* glsl */ `
  varying vec2 v_uv;

  uniform sampler2D u_selectionTexture;
  uniform bool u_darkenNonSelected;

  void main() {
    float mask = texture(u_selectionTexture, v_uv).r;

    if (u_darkenNonSelected) {
      if (mask < 0.5) {
        gl_FragColor = vec4(vec3(0.0), 0.2);
        return;
      }
    }

    gl_FragColor = vec4(vec3(0.3, 0.3, 0.93), 0.5 * mask);
  }
  `
);
extend({ FillMaskMaterial: FillMaskMaterialImpl });

type FillMaskMaterialType = JSX.IntrinsicElements['shaderMaterial'] & {
  u_selectionTexture: Texture;
  u_darkenNonSelected?: boolean;
};

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      fillMaskMaterial: FillMaskMaterialType;
    }
  }
}

import { useCurrentUser } from '@vizcom/shared/data-access/graphql';
import { Text } from '@vizcom/shared-ui-components';

import {
  SettingsPageContainer,
  SettingsPageHeader,
  SettingsPageDivider,
} from '../components/SettingsPageLayout';
import { ChangePassword } from './ChangePassword';
import { DeleteAccount } from './DeleteAccount';
import { ProfileName } from './ProfileName';

export const ProfileSettingsPage = () => {
  const { data } = useCurrentUser();

  return (
    <SettingsPageContainer>
      <SettingsPageHeader>
        <Text style={{ fontSize: 18, fontWeight: 600 }}>Profile</Text>
        <Text color="subtext" type="b1">
          Manage your Vizcom profile
        </Text>
      </SettingsPageHeader>

      <SettingsPageDivider />

      <Text block type="sh2">
        Email
      </Text>
      <Text block type="b2" color="subtext" style={{ marginTop: 18 }}>
        {data?.email}
      </Text>

      <SettingsPageDivider />

      <Text block type="sh2">
        Name
      </Text>

      <ProfileName />

      <SettingsPageDivider />

      <Text block type="sh2">
        Password
      </Text>
      <ChangePassword />

      <SettingsPageDivider />

      <Text block type="sh2">
        Delete my account
      </Text>
      <DeleteAccount />
    </SettingsPageContainer>
  );
};

import { Dispatch, SetStateAction } from 'react';
import {
  CreatePromptStyleReferenceMode,
  ImageInferenceType,
} from '@vizcom/shared/data-access/graphql';
import { PublicPalette } from '@vizcom/shared/inference-worker-queues';

/**
 * Union type for all possible reference modes
 * Combines the enum values from CreatePromptStyleReferenceMode with the string literal 'color'
 */
export type ReferenceMode = CreatePromptStyleReferenceMode | 'color';

/**
 * Define the minimum properties required from InferenceSettings for our utilities
 */
export type MinimalInferenceSettings = {
  referenceMode: ReferenceMode;
  styleReference: Record<
    CreatePromptStyleReferenceMode,
    { styleReferenceId: string | null; strength: number; maskZoom?: boolean }
  >;
  colorReference: { color: string | null };
  imageInferenceType: ImageInferenceType;
  publicPaletteId?: string | null;
  customModelId?: string | null;
  workbenchPaletteId?: string | null;
  userPaletteId?: string | null;
  colorCoherence?: boolean;
  paletteInfluence?: number;
  prompt?: string;
};

/**
 * Type definitions for different reference types to improve type safety
 */
export type ImageStyleReference = {
  styleReferenceId: string | null;
  strength: number;
};

export type PreciseStyleReference = ImageStyleReference & {
  maskZoom: boolean;
};

export type ColorReference = {
  color: string | null;
};

/**
 * A custom hook that centralizes reference mode state management and utility functions
 * to simplify interaction with reference modes across components
 */
export function useReferenceMode(
  settings: MinimalInferenceSettings,
  setSettings: Dispatch<SetStateAction<any>>
) {
  // Expose the current reference mode for convenience
  const currentReferenceMode = settings.referenceMode;

  /**
   * Reference modes that always disable palette selection when they are active
   */
  const REFERENCE_MODES_THAT_ALWAYS_DISABLE_PALETTE: ReferenceMode[] = [
    'color',
    CreatePromptStyleReferenceMode.TryOn,
    CreatePromptStyleReferenceMode.Background,
  ];

  /**
   * Reference modes that disable palette selection only when they have an active reference
   */
  const REFERENCE_MODES_THAT_CONDITIONALLY_DISABLE_PALETTE: CreatePromptStyleReferenceMode[] =
    [
      CreatePromptStyleReferenceMode.Material,
      CreatePromptStyleReferenceMode.Precise,
    ];

  /**
   * Image-based reference modes
   */
  const IMAGE_REFERENCE_MODES: CreatePromptStyleReferenceMode[] = [
    CreatePromptStyleReferenceMode.Image,
    CreatePromptStyleReferenceMode.Background,
    CreatePromptStyleReferenceMode.Material,
    CreatePromptStyleReferenceMode.Precise,
    CreatePromptStyleReferenceMode.TryOn,
  ];

  /**
   * Check if a style reference is active for a specific mode
   * A reference is considered active when it has a styleReferenceId
   */
  const hasActiveStyleReference = (
    inferenceSettings: MinimalInferenceSettings,
    mode: CreatePromptStyleReferenceMode
  ): boolean => {
    return !!inferenceSettings.styleReference[mode]?.styleReferenceId;
  };

  /**
   * Check if color reference is active
   */
  const hasActiveColorReference = (
    inferenceSettings: MinimalInferenceSettings
  ): boolean => {
    return !!inferenceSettings.colorReference?.color;
  };

  /**
   * Determines if the current reference mode is an image-based reference mode
   */
  const isImageReferenceMode = (mode: ReferenceMode): boolean => {
    return IMAGE_REFERENCE_MODES.includes(
      mode as CreatePromptStyleReferenceMode
    );
  };

  /**
   * Determines if the current reference mode is a color reference mode
   */
  const isColorReferenceMode = (mode: ReferenceMode): boolean => {
    return mode === 'color';
  };

  /**
   * Determines if a reference mode is currently active and has a reference
   * @param inferenceSettings The current inference settings
   * @param currentMode The current reference mode
   * @param mode The reference mode to check
   * @returns True if the mode is active and has a reference
   */
  const isReferenceModeActiveWithReference = (
    inferenceSettings: MinimalInferenceSettings,
    currentMode: ReferenceMode,
    mode: ReferenceMode
  ): boolean => {
    if (currentMode !== mode) {
      return false;
    }

    if (mode === 'color') {
      return hasActiveColorReference(inferenceSettings);
    }

    return hasActiveStyleReference(inferenceSettings, mode);
  };

  /**
   * Check if any reference mode that always disables palette is active with a reference
   */
  const hasActiveAlwaysDisablingReference = (
    inferenceSettings: MinimalInferenceSettings,
    currentMode: ReferenceMode = currentReferenceMode
  ): boolean => {
    return REFERENCE_MODES_THAT_ALWAYS_DISABLE_PALETTE.some((mode) =>
      isReferenceModeActiveWithReference(inferenceSettings, currentMode, mode)
    );
  };

  /**
   * Check if any reference mode that conditionally disables palette is active with a reference
   */
  const hasActiveConditionallyDisablingReference = (
    inferenceSettings: MinimalInferenceSettings,
    currentMode: ReferenceMode = currentReferenceMode
  ): boolean => {
    return REFERENCE_MODES_THAT_CONDITIONALLY_DISABLE_PALETTE.some((mode) =>
      isReferenceModeActiveWithReference(inferenceSettings, currentMode, mode)
    );
  };

  /**
   * Determines if palette selection should be blocked based on the inference settings
   */
  const isPaletteSelectionBlocked = (
    inferenceSettings: MinimalInferenceSettings
  ): boolean => {
    const currentMode = inferenceSettings.referenceMode as ReferenceMode;

    return (
      inferenceSettings.imageInferenceType === ImageInferenceType.Render &&
      (hasActiveAlwaysDisablingReference(inferenceSettings, currentMode) ||
        hasActiveConditionallyDisablingReference(
          inferenceSettings,
          currentMode
        ))
    );
  };

  /**
   * Determines if color match should be disabled based on the inference settings
   */
  const isColorMatchDisabled = (
    inferenceSettings: MinimalInferenceSettings
  ): boolean => {
    const currentMode = inferenceSettings.referenceMode as ReferenceMode;

    return (
      isPaletteSelectionBlocked(inferenceSettings) ||
      isReferenceModeActiveWithReference(
        inferenceSettings,
        currentMode,
        'color'
      ) ||
      isReferenceModeActiveWithReference(
        inferenceSettings,
        currentMode,
        CreatePromptStyleReferenceMode.Background
      ) ||
      isReferenceModeActiveWithReference(
        inferenceSettings,
        currentMode,
        CreatePromptStyleReferenceMode.TryOn
      )
    );
  };

  /**
   * Determines if palette strength adjustment should be disabled
   */
  const isPaletteStrengthDisabled = (
    inferenceSettings: MinimalInferenceSettings,
    historyPreviewActive: boolean
  ): boolean => {
    const currentMode = inferenceSettings.referenceMode as ReferenceMode;

    return (
      historyPreviewActive ||
      isReferenceModeActiveWithReference(
        inferenceSettings,
        currentMode,
        'color'
      ) ||
      isReferenceModeActiveWithReference(
        inferenceSettings,
        currentMode,
        CreatePromptStyleReferenceMode.Background
      ) ||
      isReferenceModeActiveWithReference(
        inferenceSettings,
        currentMode,
        CreatePromptStyleReferenceMode.TryOn
      ) ||
      isReferenceModeActiveWithReference(
        inferenceSettings,
        currentMode,
        CreatePromptStyleReferenceMode.Material
      )
    );
  };

  /**
   * Determines if drawing influence adjustment should be disabled
   */
  const isTryOnOrBackgroundReference = (
    inferenceSettings: MinimalInferenceSettings
  ): { isTryOn: boolean; isBackground: boolean } => {
    const currentMode = inferenceSettings.referenceMode as ReferenceMode;

    return {
      isTryOn: isReferenceModeActiveWithReference(
        inferenceSettings,
        currentMode,
        CreatePromptStyleReferenceMode.TryOn
      ),
      isBackground: isReferenceModeActiveWithReference(
        inferenceSettings,
        currentMode,
        CreatePromptStyleReferenceMode.Background
      ),
    };
  };

  /**
   * Determines if reference image strength adjustment should be disabled
   */
  const isReferenceImageStrengthDisabled = (
    inferenceSettings: MinimalInferenceSettings,
    historyPreviewActive: boolean
  ): boolean => {
    const currentMode = inferenceSettings.referenceMode as ReferenceMode;

    return (
      historyPreviewActive ||
      isReferenceModeActiveWithReference(
        inferenceSettings,
        currentMode,
        CreatePromptStyleReferenceMode.TryOn
      ) ||
      isReferenceModeActiveWithReference(
        inferenceSettings,
        currentMode,
        CreatePromptStyleReferenceMode.Background
      )
    );
  };

  /**
   * Set a new reference mode
   */
  const setReferenceMode = (mode: ReferenceMode) => {
    setSettings((prev: any) => ({
      ...prev,
      referenceMode: mode,
    }));
  };

  /**
   * Clear the current reference
   */
  const clearReference = () => {
    if (isColorReferenceMode(settings.referenceMode)) {
      setSettings((prev: any) => ({
        ...prev,
        colorReference: {
          ...prev.colorReference,
          color: null,
        },
      }));
    } else if (isImageReferenceMode(settings.referenceMode)) {
      setSettings((prev: any) => ({
        ...prev,
        styleReference: {
          ...prev.styleReference,
          [prev.referenceMode]: {
            ...prev.styleReference[
              prev.referenceMode as CreatePromptStyleReferenceMode
            ],
            styleReferenceId: null,
          },
        },
      }));
    }
  };

  /**
   * Set a style reference
   */
  const setStyleReference = (
    referenceId: string | null,
    mode: CreatePromptStyleReferenceMode
  ) => {
    setSettings((prev: any) => ({
      ...prev,
      styleReference: {
        ...prev.styleReference,
        [mode]: {
          ...prev.styleReference[mode],
          styleReferenceId: referenceId,
        },
      },
    }));
  };

  /**
   * Set a color reference
   */
  const setColorReference = (color: string | null) => {
    setSettings((prev: any) => ({
      ...prev,
      colorReference: {
        ...prev.colorReference,
        color,
      },
    }));
  };

  /**
   * Set reference strength
   */
  const setReferenceStrength = (
    strength: number,
    mode: CreatePromptStyleReferenceMode
  ) => {
    setSettings((prev: any) => ({
      ...prev,
      styleReference: {
        ...prev.styleReference,
        [mode]: {
          ...prev.styleReference[mode],
          strength,
        },
      },
    }));
  };

  /**
   * Get detailed tooltip message for palette selection based on current reference mode
   */
  const getPaletteTooltipMessage = () => {
    const getMessageForReferenceMode = (
      mode: ReferenceMode,
      activeMessage: string = 'Palette selection is disabled',
      inactiveMessage: string = 'Palette selection is available'
    ): string => {
      return isReferenceModeActiveWithReference(
        settings,
        currentReferenceMode,
        mode
      )
        ? activeMessage
        : inactiveMessage;
    };

    switch (currentReferenceMode) {
      case 'color':
        return getMessageForReferenceMode(
          'color',
          'Palette disabled with active color reference'
        );
      case CreatePromptStyleReferenceMode.Background:
        return getMessageForReferenceMode(
          CreatePromptStyleReferenceMode.Background,
          'Palette disabled with active environment reference'
        );
      case CreatePromptStyleReferenceMode.TryOn:
        return getMessageForReferenceMode(
          CreatePromptStyleReferenceMode.TryOn,
          'Palette disabled with active try-on reference'
        );
      case CreatePromptStyleReferenceMode.Precise:
        return getMessageForReferenceMode(
          CreatePromptStyleReferenceMode.Precise,
          'Palette disabled with active precise color reference'
        );
      case CreatePromptStyleReferenceMode.Material:
        return getMessageForReferenceMode(
          CreatePromptStyleReferenceMode.Material,
          'Palette disabled with active reference material'
        );
      default:
        return isPaletteSelectionBlocked(settings)
          ? 'Palette selection is disabled'
          : 'Palette selection is available';
    }
  };

  /**
   * Get tooltip subtext for palette selection
   */
  const getPaletteTooltipSubtext = () => {
    if (settings.colorCoherence) {
      return 'Disable Color-Match to change the palette';
    }

    // Only show this message if palette selection is actually blocked
    if (isPaletteSelectionBlocked(settings)) {
      return 'Palettes cannot be mixed with active references';
    }

    return '';
  };

  /**
   * Get tooltip message for Color Match when disabled
   */
  const getColorMatchDisabledMessage = () => {
    // Simple direct approach to get messages for specific reference modes
    if (currentReferenceMode === 'color') {
      return hasActiveColorReference(settings)
        ? 'Color-Match is disabled when using an active color reference.'
        : 'Color-Match will be available after removing the color reference.';
    }

    if (currentReferenceMode === CreatePromptStyleReferenceMode.Background) {
      return hasActiveStyleReference(
        settings,
        CreatePromptStyleReferenceMode.Background
      )
        ? 'Color-Match is disabled when using an active environment reference.'
        : 'Color-Match will be available after removing the environment reference.';
    }

    if (currentReferenceMode === CreatePromptStyleReferenceMode.TryOn) {
      return hasActiveStyleReference(
        settings,
        CreatePromptStyleReferenceMode.TryOn
      )
        ? 'Color-Match is disabled when using an active try-on reference.'
        : 'Color-Match will be available after removing the try-on reference.';
    }

    // Default message for other reference modes
    return 'Color-Match is disabled when using this reference mode with an active reference.';
  };

  /**
   * Handle enabling/disabling Color Match with all necessary side effects
   */
  const handleColorMatchToggle = () => {
    if (!isColorMatchDisabled(settings)) {
      setSettings((prev: any) => ({
        ...prev,
        colorCoherence: !prev.colorCoherence,
        // When enabling color coherence, lock to Realistic Product and clear references
        ...(!prev.colorCoherence
          ? {
              publicPaletteId: PublicPalette.realisticProduct_v2,
              customModelId: undefined,
              workbenchPaletteId: undefined,
              userPaletteId: undefined,
              styleReference: {
                ...prev.styleReference,
                [CreatePromptStyleReferenceMode.Material]: {
                  strength: 1,
                  styleReferenceId: null,
                },
                [CreatePromptStyleReferenceMode.Precise]: {
                  strength: 1,
                  styleReferenceId: null,
                  maskZoom: false,
                },
                [CreatePromptStyleReferenceMode.TryOn]: {
                  strength: 1,
                  styleReferenceId: null,
                },
                [CreatePromptStyleReferenceMode.Image]: {
                  strength: 1,
                  styleReferenceId: null,
                },
                [CreatePromptStyleReferenceMode.Background]: {
                  strength: 1,
                  styleReferenceId: null,
                },
              },
              colorReference: {
                color: null,
              },
            }
          : {}),
      }));
    }
  };

  /**
   * Get the currently selected palette ID based on precedence
   */
  const getSelectedPaletteId = () => {
    return (
      settings.publicPaletteId ??
      settings.customModelId ??
      settings.workbenchPaletteId ??
      settings.userPaletteId ??
      PublicPalette.generalV2
    );
  };

  /**
   * Handle palette selection with all necessary side effects
   */
  const handleSelectPalette = (
    paletteId: string,
    type: string,
    tags?: string[]
  ) => {
    setSettings((prev: any) => {
      const newSettings = {
        ...prev,
        publicPaletteId: undefined,
        customModelId: undefined,
        workbenchPaletteId: undefined,
        userPaletteId: undefined,
        [type]: paletteId,
      };

      // If tags are provided and this is a workbench or user palette, update prompt
      if (tags && (type === 'workbenchPaletteId' || type === 'userPaletteId')) {
        const getNewPromptFromTags = (
          tags: string[],
          currentPrompt: string
        ) => {
          // This is a placeholder - you'll need to implement or import the actual function
          return currentPrompt;
        };

        newSettings.prompt = getNewPromptFromTags(tags, prev.prompt);
      }

      return newSettings;
    });
  };

  return {
    // Constants
    IMAGE_REFERENCE_MODES,
    REFERENCE_MODES_THAT_ALWAYS_DISABLE_PALETTE,
    REFERENCE_MODES_THAT_CONDITIONALLY_DISABLE_PALETTE,

    // Current state
    currentReferenceMode,

    // Utility functions
    isImageReferenceMode,
    isColorReferenceMode,
    hasActiveStyleReference,
    hasActiveColorReference,
    isReferenceModeActiveWithReference,
    isPaletteSelectionBlocked,
    isColorMatchDisabled,
    isPaletteStrengthDisabled,
    isTryOnOrBackgroundReference,
    isReferenceImageStrengthDisabled,

    // State management functions
    setReferenceMode,
    clearReference,
    setStyleReference,
    setColorReference,
    setReferenceStrength,
    handleColorMatchToggle,
    handleSelectPalette,
    getSelectedPaletteId,

    // Helper functions for UI messages
    getPaletteTooltipMessage,
    getPaletteTooltipSubtext,
    getColorMatchDisabledMessage,
  };
}

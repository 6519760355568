import { Text, triggerDirectModal } from '@vizcom/shared-ui-components';

export const MAX_LAYER_DEPTH = 2;

export const artists = [
  'Evelyn Abelson',
  'Affandi Koesoema',
  'Maryati Affandi',
  'Kartika Affandi-Koberl',
  'Abdullah ',
  'Sudjono Abdullah',
  'Basuki Abdullah',
  'Raden Saleh ',
  'Syarif Bustaman',
  'Lee Man Fong',
  'Mario Blanco',
  'Gde Sobrat',
  'Anak Agung',
  'Adrianus Wilhelmus Smit',
  'Arie Smit',
  'Hendra Gunawan',
  'Henk Ngantung',
  'Han Snel',
  'Popo Iskandar',
  'Jeihan Sukmantoro',
  'Marina Joesoef',
  'Heri Dono',
  'KSR Jendela',
  'Sunarni Puji Lestari',
  'Barli Sasmitawinata',
  'Delsy Syamsumar',
  'Itji Tarmizi',
  'Ferdinand Keller',
  'John Brown Abercromby',
  'Johann Ludwig Aberli',
  'Anna Maria Barbara Abesch',
  'Nikolaj Abraham Abildgaard',
  'Béla Apáti Abkarovics',
  'Béla Nagy Abodi',
  'Ruth Abrahams',
  'Herbert Abrams',
  'Ruth Abrams',
  'Philip Absolon',
  'Otto Abt',
  'Ismail Acar',
  'Bernard Accama',
  'Andreas Achenbach',
  'Oswald Achenbach',
  'Patrick Adam',
  'Elinor Proby Adams',
  'J. Ottis Adams',
  'John Clayton Adams',
  'Pat Adams',
  'Robert Adamson',
  'Sarah Gough Adamson',
  'Mary Adshead',
  'Jankel Adler',
  'Mór Adler',
  'Willem Aelst',
  'Pieter Aertsen',
  'Nadir Afonso',
  'Yaacov Agam',
  'Jacques-Laurent Agasse',
  'Knud Agger',
  'Gyula Aggházy',
  'Lubna Agha',
  'Christoph Ludwig Agricola',
  'Ai Xuan',
  'John Macdonald Aiken',
  'Ai-Mitsu',
  'Edgar Ainsworth',
  'Ivan Aivazovsky',
  'Tadeusz Ajdukiewicz',
  'Ras Akyem',
  'Francesco Albani',
  'Josef Albers',
  'Mariotto Albertinelli',
  'Giocondo Albertolli',
  'Ivan Albright',
  'Kazimierz Alchimowicz',
  'Tivadar Alconiere',
  'L. Alcopley',
  'Eileen Aldridge',
  'Pierre Alechinsky',
  'George Aleef',
  'Fyodor Alekseyev',
  'Mikoláš Aleš',
  'Cosmo Alexander',
  'John Alexander',
  'Larry D. Alexander',
  'Lena Alexander',
  'Else Alfelt',
  'Brian Alfred',
  'Jessie Algie',
  'Andrew Allan',
  'David Allan',
  'Griselda Allan',
  'Robert Weir Allan',
  'Rosemary Allan',
  'Daphne Allen',
  'Kathleen Allen',
  'Alessandro Allori',
  'Cristofano Allori',
  'Washington Allston',
  'Laura Theresa Alma-Tadema',
  'Lawrence Alma-Tadema',
  'Almeida Júnior',
  'Charles Alston',
  'Margareta Alströmer',
  'Rudolf von Alt',
  'Albrecht Altdorfer',
  'Altichiero',
  'John Altoon',
  'Pedro Álvarez Castelló',
  'Edmond Aman-Jean',
  'Tarsila do Amaral',
  'Christoph Amberger',
  'Friedrich von Amerling',
  'Cuno Amiet',
  'Jacopo Amigoni',
  'Rodolfo Amoedo',
  'Amor',
  'Anna Ancher',
  'Michael Ancher',
  'Marion Ancrum',
  'Werner Andermatt',
  'Sophie Gengembre Anderson',
  'Emma Andijewska',
  'Ion Andreescu',
  'Constantine Andreou',
  'Michael Andrews',
  'Anthony Angarola',
  'Marie Angel',
  'Heinz Anger',
  'Hermenegildo Anglada Camarasa',
  'Gaston Anglade',
  'Charles Angrand',
  'Sofonisba Anguissola',
  'Peggy Angus',
  'Rita Angus',
  'Albert Anker',
  'Margit Anna',
  'David Annand',
  'Louis AnquetEdward Armitagein',
  'Pieter Anraedt',
  'Lizzy Ansingh',
  'Mary Anne Ansley',
  'Horst Antes',
  'Cornelis Anthonisz',
  'Richard Anuszkiewicz',
  'Shigeru Aoki',
  'Chiho Aoshima',
  'Apelles',
  'Zvest Apollonio',
  'Karel Appel',
  'Leonard Appelbee',
  'Joseph Ignaz Appiani',
  'Félix Arauz',
  'Janet Archer',
  'Arent Arentsz',
  'Avigdor Arikha',
  'Abram Arkhipov',
  'István Árkossy',
  'Edward Armitage',
  'John Armleder',
  'Hazel Armour',
  'John Armstrong',
  'Georg Arnold-Graboné',
  'Jean Arp',
  'Eugenio de Arriba',
  'Richard Artschwager',
  'Asai Chū',
  'Pieter Jansz Asch',
  'Pamela Ascherson',
  'Ásgrímur Jónsson',
  'Dennis Ashbaugh',
  'Gigadō Ashiyuki',
  'Hans Asper',
  'Jan Asselijn',
  'Balthasar der Ast',
  'Nikolai Astrup',
  'Jean-Michel Atlan',
  'René Auberjonois',
  'Étienne Aubry',
  'Louis-François Aubry',
  'Mary Audsley',
  'John James Audubon',
  'Frank Auerbach',
  'Jules Robert Auguste',
  'Eric Auld',
  'George Ault',
  'Cassandra Austen',
  'Giuseppe Avanzi',
  'Edward Avedisian',
  'Hendrick Avercamp',
  'Milton Avery',
  'Edward Ben Avram',
  'Nikola Avramov',
  'Awataguchi Takamitsu',
  'Joan Ayling',
  'Ay-O',
  'Aya Goda',
  'Anton Ažbe',
  'Giovanni Bernardino Azzolini',
  'Giovanni Bernardino Mazzolini',
  'Giovanni Bernardino Asoleni',
  'Dirck Baburen',
  'Jacopo Baccarini',
  'Marcello Bacciarelli',
  'Irene Bache',
  'Augustin Meinrad Bächtiger',
  'Jacob Adriaensz Backer',
  'Margaret Backhouse',
  'Lucy Angeline Bacon',
  'Marjorie May Bacon',
  'Peggy Bacon',
  'Joseph Badger',
  'Ottó Baditz',
  'Jan de Baen',
  'Jo Baer',
  'William Jacob Baer',
  'Stanley Bahe',
  'Leonard Bahr',
  'Edward Bailey',
  'Alice Bailly',
  'David Bailly',
  'George Bain',
  'Robert Bain',
  'Thomas Baines',
  'Baiōken Eishun',
  'Edward Baird',
  'Kōno Bairei',
  'Enrico Baj',
  'James Ballantine',
  'Barbara Balmer',
  'Mario Bardi',
  'George Herbert Baker',
  'Normand Baker',
  'William Bliss Baker',
  'Ludolf Bakhuizen',
  'Léon Bakst',
  'Alesso Baldovinetti',
  'Hans Baldung',
  'Hendrick Balen',
  'Endre Bálint',
  'Rezső Bálint',
  'Pál Balkay',
  'Giacomo Balla',
  'Robert Ballagh',
  'Karl Ballmer',
  'Barbara Balmer',
  'János Nagy Balogh',
  'László Balogh',
  'Balthus',
  'Ernő Bánk',
  'Edward Mitchell Bannister',
  'Bapu',
  'Miklós Barabás',
  'Vladimir Baranov-Rossine',
  "Jacopo de' Barbari",
  'Giovanni Francesco Barbieri',
  'Jenő Barcsay',
  'James Bard',
  'Magdalene Bärens',
  'George Barker',
  'Lucette Barker',
  'Thomas Barker',
  'John Noble Barlow',
  'Gwen Barnard',
  'Ernie Barnes',
  'Robert C. Barnfield',
  'Henri Alphonse Barnoin',
  'Federico Barocci',
  'Aimé Barraud',
  'François Barraud',
  'George Barret',
  'Syd Barrett',
  'Mardi Barrie',
  'James Barry',
  'Hans von Bartels',
  'Richmond Barthé',
  'Lindsay Bartholomew',
  'Charles W. Bartlett',
  'Jennifer Bartlett',
  'Francesco Bartolozzi',
  'Rose Maynard Barton',
  'Andor Basch',
  'Gyula Basch',
  'Evaristo Baschenis',
  'Georg Baselitz',
  'Marie Bashkirtseff',
  'Franz Karl Basler-Kopp',
  'Jacopo Bassano',
  'Bartholomeus Bassen',
  'James Bateman',
  'Robert Bateman',
  'Robert Bateman',
  'Cafer Bater',
  'Maxwell Bates',
  'Pompeo Batoni',
  'John Nelson Battenberg',
  'Gyula Batthyány',
  'André Bauchant',
  'Auguste Baud-Bovy',
  'Lubin Baugin',
  'Edgar Schofield Baum',
  'Walter Emerson Baum',
  'Fritz Baumann',
  'Tilo Baumgärtel',
  'Armin Baumgarten',
  'Walter Bayes',
  'Wyke Bayliss',
  'James Baynes',
  'Thomas Mann Baynes',
  'Frédéric Bazille',
  'Reynolds Beal',
  'Sophia Beale',
  'Romare Bearden',
  'Penelope Beaton',
  'Robert Beauchamp',
  'André Beauneveu',
  'Cecilia Beaux',
  'Domenico di Pace Beccafumi',
  'Robert Bechtle',
  'Jasmine Becket-Griffith',
  'Clarice Beckett',
  'Max Beckmann',
  'Celia Frances Bedford',
  'Ignat Bednarik',
  'Vanessa Beecroft',
  'Tuvia Beeri',
  'Cornelis Pietersz Bega',
  'Abraham Begeyn',
  'Kamāl ud-Dīn Behzād',
  'Abraham Beijeren',
  'Zdzisław Beksiński',
  'Andrew Bell',
  'George Bell',
  'Gladys Kathleen Bell',
  'John Zephaniah Bell',
  'Leland Bell',
  'Vanessa Bell',
  'John Bellany',
  'Alexis Simon Belle',
  'Henri Bellechose',
  'Giovanni Bellini',
  'Gentile Bellini',
  'Jacopo Bellini',
  'Bernardo Bellotto',
  'George Bellows',
  'Ludwig Bemelmans',
  'Gyula Benczúr',
  'Marilyn Bendell',
  'Wilhelm Bendz',
  'Manfredi Beninati',
  'Jason Benjamin',
  'Martin Benka',
  'Frank Weston Benson',
  'Thomas Hart Benton',
  'Alexandre Benois',
  'Lajos Berán',
  'Jean Béraud',
  'Nicolaes Pieterszoon Berchem',
  'Gerrit Adriaenszoon Berckheyde',
  'Róbert Berény',
  'Marcelle Bergerol',
  'Christoffel den Berghe',
  'Carlos Berlanga',
  'Hans Eduard von Berlepsch-Valendas',
  'Helen Berman',
  'Émile Bernard',
  'Aurél Bernáth',
  'John E. Berninger',
  'Gian Lorenzo Bernini',
  'Morris Louis Bernstein',
  'William Berra',
  'Albert Bertelsen',
  'Johann Berthelsen',
  'Robert Bery',
  'Elsa Beskow',
  'Paul-Albert Besnard',
  'Eleanor Best',
  'László Beszédes',
  'Joseph Beuys',
  'Robert Bevan',
  'Henryka Beyer',
  'Riad Beyrouti',
  'Lujo Bezeredi',
  'Roman Bezpalkiv',
  'Bholekar Srihari',
  'Bian Jingzhao',
  'Bian Shoumin',
  'Alessandro Galli Bibiena',
  'Antonio Galli Bibiena',
  'Carlo Galli Bibiena',
  'Maria Oriana Galli Bibiena',
  'Heinrich Bichler',
  'George Biddle',
  'Candido Bido',
  'Johann Jakob Biedermann',
  'André Charles Biéler',
  'Ernest Biéler',
  'Adolf Bierbrauer',
  'Albert Bierstadt',
  'Helen Biggar',
  'Sándor Bihari',
  'Jan Bijlert',
  'Ivan Bilibin',
  'Edmond Bille',
  'Ejler Bille',
  'Charles Billich',
  'George Caleb Bingham',
  'Margaret Bingham',
  'Helen Binyon',
  'S J Birch',
  'Paul Bird',
  'Oswald Birley',
  'Peter Birmann',
  'Samuel Birmann',
  'Thierry Bisch',
  'Elmer Bischoff',
  'Tim Biskup',
  'Giuseppe Bernardino Bison',
  'Vilhelm Bissen',
  'Douglas Robertson Bisset',
  'Cornelis Bisschop',
  'Emil Bisttram',
  'Henri Biva',
  'Jemima Blackburn',
  'Ann Thetis Blacker',
  'Vivien Blackett',
  'Charles Blackman',
  'Basil Blackshaw',
  'Doris Blair',
  'John Blair',
  'Zelma Blakely',
  'Ralph Albert Blakelock',
  'Edmund Blampied',
  'Arnold Blanch',
  'Jacques Blanchard',
  'Ross Bleckner',
  'Johann Heinrich Bleuler',
  'Johann Ludwig Bleuler',
  'Carl Heinrich Bloch',
  'Anna Katharina Block',
  'Benjamin Block',
  'Josef Block',
  'Abraham Bloemaert',
  'Hendrick Bloemaert',
  'Flavia Blois',
  'Tina Blondell',
  'Hyman Bloom',
  'Pieter de Bloot',
  'Godfrey Blow',
  'Peter Blume',
  'Ditlev Blunck',
  'Robert Henderson Blyth',
  'David Gilmour Blythe',
  'Umberto Boccioni',
  'Anna Boch',
  'François Bocion',
  'Thomas Bock',
  'Arnold Böcklin',
  'Margaret Boden',
  'Karl Bodmer',
  'Paul Bodmer',
  'Zsolt Bodoni',
  'Edward Marshall Boehm',
  'George Bogart',
  'Krzysztof Boguszewski',
  'Pál Böhm',
  'Aaron Bohrod',
  'Louis-Léopold Boilly',
  'Maurice Boitel',
  'Ferdinand Bol',
  'Hans Bol',
  'Kees Bol',
  'Giovanni Boldini',
  'Boetius Adamsz Bolswert',
  'Irvin Bomb',
  'David Bomberg',
  'Camille Bombois',
  'Muirhead Bone',
  'Phyllis Bone',
  'Stephen Bone',
  'Isidore Bonheur',
  'Rosa Bonheur',
  'Hugo Sánchez Bonilla',
  'Claude Bonin-Pissarro',
  'Richard Parkes Bonington',
  'William Bonnar',
  'Pierre Bonnard',
  'Francesco Bonsignori',
  'Gerard ter Borch',
  'Paul-Émile Borduas',
  'Adolf Born',
  'Vladimir Borovikovsky',
  'József Borsos',
  'Miklós Borsos',
  'Sándor Bortnyik',
  'Samu Börtsök',
  'Kameda Bōsai',
  'Hieronymus Bosch',
  'Abraham Bosschaert',
  'Ambrosius Bosschaert',
  'Ambrosius Bosschaert II',
  'Johannes Bosschaert',
  'Ángel Botello',
  'Fernando Botero',
  'Andries Both',
  'Jan Dirksz Both',
  'Sandro Botticelli',
  'Eugène Boudin',
  'William-Adolphe Bouguereau',
  'Jack Boul',
  'Gustave Boulanger',
  'Doris Boulton-Maude',
  'Douglas Bourgeois',
  'Francis Bourgeois',
  'John Bourne',
  'Esaias Boursse',
  'Dieric Bouts',
  'Camille Bouvagne',
  'Carol Bove',
  'Erwin Bowien',
  'Eden Box',
  'Zlatyu Boyadzhiev',
  'Arthur Boyd',
  'Arthur Merric Boyd',
  'David Boyd',
  'Mary Syme Boyd',
  'Penleigh Boyd',
  'Jimmy Boyle',
  'Mark Boyle',
  'Olga Boznańska',
  'Dorothea Braby',
  'John Brack',
  'Robert Brackman',
  'Marie Bracquemond',
  'Dorothy Bradford',
  'Dorothy Elizabeth Bradford',
  'Leonaert Bramer',
  'Christian Hilfgott Brand',
  'Johann Christian Brand',
  'Peter Brandes',
  'Petr Brandl',
  'Warren Eugene Brandon',
  'Eugène Brands',
  'Józef Brandt',
  'Muriel Brandt',
  'Ruth Brandt',
  'Arnold Franz Brasz',
  'Maurice Braun',
  'Victor Brauner',
  'Robert J. Brawley',
  'Jan de Bray',
  'Dirck de Bray',
  'Phyllis Bray',
  'Salomon de Bray',
  'Carl Frederik von Breda',
  'Bartholomeus Breenbergh',
  'George Hendrik Breitner',
  'Quirijn Brekelenkam',
  'Brenner',
  'Louise Catherine Breslau',
  'Jules Breton',
  'David Brewster',
  'James E. Brewton',
  'Breyten Breytenbach',
  'Gabor Breznay',
  'József Breznay',
  'Alfred Thompson Bricher',
  'Ernest Briggs',
  'Henry Bright',
  'Pierre Brissaud',
  'Károly Brocky',
  'Louis le Brocquy',
  'Heinrich Brocksieper',
  'William Brodie',
  'Antoni Brodowski',
  'Sándor Brodszky',
  'Melchior Broederlam',
  'Arnold Bronckhorst',
  'Agnolo Bronzino',
  'Alexander Brook',
  'Peter Brook',
  'Bertram Brooker',
  'Allan Brooks',
  'Frank Leonard Brooks',
  'James Brooks',
  'Romaine Brooks',
  'Robert Brough',
  'Adriaen Brouwer',
  'Cecily Brown',
  'John Brown',
  'John Crawford Brown',
  'Lucy Madox Brown',
  'Francis Focer Brown',
  'Joan Brown',
  'Judith Brown',
  'Nyuju Stumpy Brown',
  'Kathleen Browne',
  'Mia Brownell',
  'Václav Brožík',
  'Patrick Henry Bruce',
  'Lajos Bruck',
  'Lodewijk Bruckman',
  'Marjorie Frances Bruford',
  'Arnold Brügger',
  'Élisabeth Vigée Le Brun',
  'Elizabeth York Brunton',
  'Robert Bryden',
  'Karl Bryullov',
  'Tadeusz Brzozowski',
  'Ernest Buckmaster',
  'Ota Bubeníček',
  'Emanuel Büchel',
  'Frank Buchser',
  'David Budd',
  'Karl Buesgen',
  'Bernard Buffet',
  'Vlaho Bukovac',
  'Norma Bull',
  'Johann Balthasar Bullinger',
  'Fritz Bultman',
  'John Elwood Bundy',
  'Dennis Miller Bunker',
  'Charles Ragland Bunnell',
  'Rupert Bunny',
  'Mykola Burachek',
  'Elbridge Ayer Burbank',
  'Charles E. Burchfield',
  'Jacob Burck',
  'Hans Burgkmair',
  'Francisco de Burgos Mantilla',
  'Max Buri',
  'Zdeněk Burian',
  'Rodney Joseph Burn',
  'Eugène Burnand',
  'Thomas Stuart Burnett',
  'Dorothy Burroughes',
  'Alberto Burri',
  'David Burton-Richardson',
  'Sergio Burzi',
  'Mildred Anne Butler',
  'Theodore Earl Butler',
  'Ambreen Butt',
  'Howard Butterworth',
  'John Button',
  'Louis Buvelot',
  'Willem Pieterszoon Buytewech',
  'Byeon Sang-byeok',
  'Mary Byfield',
  'John Byrne',
  'John Byrne',
  'Johann Rudolf Byss',
  'Vincenzo Cabianca',
  'Lindsay',
  'Francis Cadell',
  'James Cadenhead',
  'Paul Cadmus',
  'Pogus Caesar',
  'Cagnaccio di San Pietro',
  'Gustave Caillebotte',
  'Lawrence Calcagno',
  'Alexander Calder',
  'Alexander Milne Calder',
  'Alexander Stirling Calder',
  'John Cale',
  'Mary Callery',
  'Jacques Callot',
  'Abraham Calraet',
  'Luis Enrique Camej',
  'David Young Cameron',
  'Mary Cameron',
  'Gianfredo Camesi',
  'Steven Campbell',
  'Thomas Campbell',
  'Jacob Campen',
  'Robert Campin',
  'Govert Dircksz Camphuysen',
  'Canaletto',
  'Noe Canjura',
  'Cao Buxing',
  'Cao Zhibai',
  'Josef Čapek',
  'Jan de Cappelle',
  'Tom Carapic',
  'Caravaggio',
  'Arthur B. Carles',
  'Nancy Carline',
  'Richard Carline',
  'Sydney Carline',
  'Carlo Carlone',
  'Emil Carlsen',
  'John Fabian Carlson',
  'Rhea Carmi',
  'Nicolas Carone',
  'Emile Auguste Carolus-Duran',
  'Vittore Carpaccio',
  'Jane Carpanini',
  'Emily Carr',
  'Henry Carr',
  'Thomas Carr',
  'Carlo Carrà',
  'Annibale Carracci',
  'Agostino Carracci',
  'Ludovico Carracci',
  'Eugène Carrière',
  'Dora Carrington',
  'Joanna Carrington',
  'Leonora Carrington',
  'Alexander Carse',
  'Clarence Holbrook Carter',
  'Ramon Casas i Carbó',
  'Felice Casorati',
  'Judy Cassab',
  'Mary Cassatt',
  'Casson',
  'Humberto Castro',
  'Carlos Catasse',
  'George Catlin',
  'Patrick Caulfield',
  'Louis de Caullery',
  'Giovanni Paolo Cavagna',
  'Bernardo Cavallino',
  'Giorgio Cavallon',
  'Antonio Cavallucci',
  'Mirabello Cavalori',
  'Roger Cecil',
  'Vija Celmins',
  'Maximilian Cercha',
  'Avgust Černigoj',
  'Bartolomeo Cesi',
  'Paul Cézanne',
  'Paul Émile Chabas',
  'George Paul Chalmers',
  'Alfred Edward Chalon',
  'Brenda Chamberlain',
  'Chang Dai-chien',
  'Charles Joshua Chaplin',
  'Minerva J. Chapman',
  'Jean-Baptiste-Siméon Chardin',
  'Caroline Chariot-Dayez',
  'Michael Ray Charles',
  'Sam Charles',
  'Elizabeth Charleston',
  'Nicolas Toussaint Charlet',
  'Evan Charlton',
  'Felicity Charlton',
  'Chafik Charobim',
  'Louisa Chase',
  'William Merritt Chase',
  'Théodore Chassériau',
  'Russell Chatham',
  'Pierre Puvis de Chavannes',
  'Jules Chéret',
  'Evelyn Cheston',
  'Chen Chi',
  'Patrick Ching',
  'Derek Chittock',
  'Chinwe Chukwuogo-Roy',
  'Chen Chun',
  'Chen Daofu',
  'Chen Hong',
  'Chen Hongshou',
  'Chen Jiru',
  'Chen Lin',
  'Chen Lu',
  'Chen Rong',
  'Chen Yifei',
  'Cheng Jiasui',
  'Cheng Shifa',
  'Cheng Zhengkui',
  'Billy Childish',
  'Giorgio de Chirico',
  'Antonín Chittussi',
  'Adam Chmielowski',
  'Daniel Chodowiecki',
  'Choi Buk',
  'Dan Christensen',
  'Henry B. Christian',
  'Ernest William Christmas',
  'Petrus Christus',
  'Abdur Rahman Chughtai',
  'Frederick Edwin Church',
  'Betty Churcher',
  'Peter Churcher',
  'Winston Churchill',
  'Leon Chwistek',
  'Giovanni Battista Cipriani',
  'Antonio Ciseri',
  'Joze Ciuha',
  'Mikalojus Konstantinas Čiurlionis',
  'Franz Cižek',
  'George Claessen',
  'Pieter Claesz',
  'Alson S. Clark',
  'Edward Clark',
  'Jean Clark',
  'Walter Leighton Clark',
  'Joaquín Clausell',
  'John Clayton',
  'Francesco Clemente',
  'Grace Clements',
  'Chuck Close',
  'François Clouet',
  'Giorgio Giulio Clovio',
  'Henry Ives Cobb',
  'Juan Fernando Cobo',
  'Pieter Codde',
  'Charles Codman',
  'Isabel Codrington',
  'Jon Coffelt',
  'Nevin Çokay',
  'Dorothy Coke',
  'Ruth Collet',
  'William Coldstream',
  'Elsie Vera Cole',
  'Thomas Cole',
  'Robert Colescott',
  'Evert Collier',
  'John Collier',
  'Raphaël Collin',
  'Elisabeth Collins',
  'Jacob Collins',
  'Samuel Colman',
  'Jean Colombe',
  'Giovanni Battista Innocenzo Colombo',
  'Robert Colquhoun',
  'Jaime Colson',
  'Robert Combas',
  'Mario Comensoli',
  'Charles Conder',
  'William Congdon',
  'Charles Fremont Conner',
  'Kevin Connor',
  'William Conor',
  'John Constable',
  'Theo Constanté',
  'John Kingsley Cook',
  'May Louise Greville Cooksey',
  'Cassius Marcellus Coolidge',
  'Colin Campbell Cooper',
  'Emma Lampert Cooper',
  'Adriaen Coorte',
  'Constance Copeman',
  'John Singleton Copley',
  'Teresa Copnall',
  'Fern Coppedge',
  'Coppo di Marcovaldo',
  'Edward Corbett',
  'Lovis Corinth',
  'Mary Corkling',
  'Corneille',
  'Thomas Cornell',
  'Paul Cornoyer',
  'Jean-Baptiste-Camille Corot',
  'Correggio',
  'Pietro da Cortona',
  'Piero di Cosimo',
  'Francesco del Cossa',
  'Pierre Auguste Cot',
  'Colijn de Coter',
  'Jean-Yves Couliou',
  'Noel Counihan',
  'Glenys Cour',
  'Gustave Courbet',
  'Marie Courtois',
  'Thomas Couture',
  'John Covert',
  'Raymond Teague Cowern',
  'James Cowie',
  'Jan Cox',
  'Raymond Coxon',
  'Francesco Cozza',
  'Dirk Crabeth',
  'Wouter Pietersz Crabeth',
  'Frank Barrington Craig',
  'James Humbert Craig',
  'Lefevre James Cranstone',
  'Hugh Adam Crawford',
  'Fred Cress',
  'Susan Crile',
  'Carlo Crivelli',
  'Ivan Lacković Croata',
  'Charles Crodel',
  'Ray Crooke',
  'Jasper Francis Cropsey',
  'William Crosbie',
  'Jean Crotti',
  'Gonzalo Endara Crow',
  'William Crozier',
  'Robert Crozier',
  'István Csók',
  'Enzo Cucchi',
  'Cui Bai',
  'Cui Zizhong',
  'Constance Gordon-Cumming',
  'Charles Cundall',
  'Nora Cundell',
  'Rinaldo Cuneo',
  'John Steuart Curry',
  'Frances Currey',
  'Aelbert Cuyp',
  'Benjamin Gerritsz Cuyp',
  'Jacob Gerritsz. Cuyp',
  'Boleslaw Cybis',
  'Władysław Czachórski',
  'Jozef Czapski',
  'Szymon Czechowicz',
  'Alfons von Czibulka',
  'Béla Czóbel',
  'Tibor Czorba',
  'Tytus Czyżewski',
  'Richard Dadd',
  'Bernardo Daddi',
  'Pascal Dagnan-Bouveret',
  'Johan Christian Dahl',
  'Michael Dahl',
  'Helen Dahm',
  'Dai Jin',
  'Dai Xi',
  'Claire Dalby',
  'Roy Dalgarno',
  'Salvador Dalí',
  'Christen Dalsgaard',
  'Thomas Aquinas Daly',
  'Dietmar Damerau',
  'Ken Danby',
  "Vito D'Ancona",
  'Josef Dande',
  'Leonard Daniels',
  'Heinrich Danioth',
  'Nassos Daphnis',
  'Fritz von Dardel',
  'Nils von Dardel',
  'Jacques Daret',
  'William Dargie',
  'Charles-François Daubigny',
  'Honoré Daumier',
  'Max Dauthendey',
  'Gerard David',
  'Mary C. Davidson',
  'Ivor Davies',
  'Charles Harold Davis',
  'Eleanor Layfield Davis',
  'Mary DavisLady Davis',
  'Heinrich Maria Davringhausen',
  'Gladys Dawson',
  'Janet Dawson',
  'Gene Davis',
  'Ronald Davis',
  'Stuart Davis',
  'Adrienn Henczné Deák',
  'Édouard Debat-Ponsan',
  'Alison Debenham',
  'Jean Baptiste Debret',
  'Joseph DeCamp',
  'Michel De Caso',
  'Ettore Ted DeGrazia',
  'Raoul De Keyser',
  'Eric de Kolb',
  'Roy De Maistre',
  'Giuseppe De Nittis',
  'Michel Delacroix',
  'Beauford Delaney',
  'Joseph Delaney',
  'Robert Delaunay',
  'Sonia Delaunay-Terk',
  'Dirck Delen',
  'Willem Jacobsz Delff',
  'Santiago Martínez Delgado',
  'Paul Delvaux',
  'Jenny Eakin Delony',
  'Richard Demarco',
  'Grillo Demo',
  'Charles Demuth',
  'Valéria Dénes',
  'Maurice Denis',
  'Roger Wilson Dennis',
  'Christabel Dennison',
  'Brigid Derham',
  'Gyula Derkovits',
  'Martin Desjardins',
  'Paul Lucien Dessau',
  'Yehia Dessouki',
  'Édouard Detaille',
  'Serafino De Tivoli',
  'Anthony Devas',
  'Giorgio De Vincenzi',
  'Thomas Dewing',
  'Alexander Deyneka',
  'David Diao',
  'Emiliano Di Cavalcanti',
  'Jessie Alexandra Dick',
  'Robert Dickerson',
  'Edwin Dickinson',
  'Porfirio DiDonna',
  'Richard Diebenkorn',
  'Pieter Franciscus Dierckx',
  'Adolf Dietrich',
  'Mary Dignam',
  'Sam Dillemans',
  'Meredith Dillman',
  'Silvia Dimitrova',
  'Jim Dine',
  'Ding Guanpeng',
  'Ding Yunpeng',
  'Abidin Dino',
  'Dionisius',
  'Balázs Diószegi',
  'Paul Dirmeikis',
  'Eve Disher',
  'Alén Diviš',
  'Otto Dix',
  'William Dobell',
  'William Dobson',
  'Mstislav Dobuzhinsky',
  'Lois Dodd',
  'Isobelle Ann Dods-Withers',
  'Theo Doesburg',
  'Eppo Doeve',
  'Tommaso Dolabella',
  'László Dombrovszky',
  'Domenichino',
  'Óscar Domínguez',
  'Tadeusz Dominik',
  'Inshō Dōmoto',
  'David Donaldson',
  'János Donát',
  'Dong Qichang',
  'Dong Yuan',
  'Antonio Donghi',
  'Lambert Doomer',
  'Gustave Doré',
  'Kees Dongen',
  'Géza Dósa',
  'Dosso Dossi',
  'Gerrit Dou',
  'Jaroslav Doubrava',
  'Thomas Doughty',
  'Aaron Douglas',
  'William Fettes Douglas',
  'Thomas Millie Dow',
  'Rackstraw Downes',
  'Helen Thomas Dranga',
  'Pamela Drew',
  'William Dring',
  'Willem Drost',
  'Orshi Drozdik',
  'Malcolm Drummond',
  'Russell Drysdale',
  'Du Jin',
  'Du Qiong',
  'Albert Dubois-Pillet',
  'Nikolay Nikanorovich Dubovskoy',
  'Mario Dubsky',
  'Duccio',
  'Marcel Duchamp',
  'Raymond Duchamp-Villon',
  'Suzanne Duchamp-Crotti',
  'Jacob Duck',
  'Jack M. Ducker',
  'Jean Dufy',
  'Raoul Dufy',
  'Thomas Cantrell Dugdale',
  'Edward Dugmore',
  'Karel Dujardin',
  'Frank DuMond',
  'Henri-Julien Dumont',
  'Augustus Dunbier',
  'Brian Dunlop',
  'Anne Dunn',
  'Elizabeth Durack',
  'Asher Brown Durand',
  'Albrecht Dürer',
  'Cornelis Dusart',
  'Willem Cornelisz Duyster',
  'Geoffrey Dyer',
  'Anthony Dyck',
  'Floris Dyck',
  'Friedel Dzubas',
  'Aileen Eagleton',
  'Thomas Eakins  ',
  'Ralph Earl',
  'Augustus Earle',
  'Alfred East',
  'Christoffer Wilhelm Eckersberg',
  'Otto Eckmann',
  'Don Eddy',
  'Albert Edelfelt',
  'Denis Eden',
  'Ursula Edgcumbe',
  'Edith Edmonds',
  'Robert Edmonstone',
  'May de Montravel Edwardes',
  'Helen Edwards',
  'John Uzzell Edwards',
  'Gerbrand den Eeckhout',
  'Camilo Egas',
  'Maude Kaufman Eggemeyer',
  'Albin Egger-Lienz',
  'József Egry',
  'Ei-Q',
  'Louis Eilshemius',
  'Einar Hakonarson',
  'Eishōsai Chōki',
  'Ib Eisner',
  'Eizan Kikukawa',
  'Bouchta El Hayani',
  'Mildred Eldridge',
  'Ken Elias',
  'Pieter Janssens Elinga',
  'Harold Elliott',
  'Clifford Ellis',
  'Adam Elsheimer',
  'Arthur Webster Emerson',
  'Tracey Emin',
  'Paul Emmert',
  'Rosalie Emslie',
  'Cornelis Engebrechtsz',
  'Florence Engelbach',
  'Grace English',
  'Ron English',
  'Carlos Enríquez Gómez',
  'James Ensor',
  'Ben Enwonwu',
  'Sir Jacob Epstein',
  'Sven Erixson',
  'Hans Erni',
  'Max Ernst',
  'Rodolfo Escalera',
  'M. C. Escher',
  'Andrey Esionov',
  'Robert Lee Eskridge',
  'Jacob Esselens',
  'Richard Estes',
  'Bracha L. Ettinger',
  'William Etty',
  'Cerith Wyn Evans',
  'Dulah Marie Evans',
  'Vincent Evans',
  'Eamon Everall',
  'Allaert Everdingen',
  'Caesar Everdingen',
  'Philip Evergood',
  'Mikhail Evstafiev',
  'Peter Maxwell Ewart',
  'Julius Exner',
  "Barthélemy d'Eyck",
  'Hubert Eyck',
  'John Eyre',
  'Annabel Eyres',
  'Carl Eytel',
  'Bogi Fabian',
  'Barent Fabritius',
  'Carel Fabritius',
  'Johannes Fabritius',
  'Pietro Faccini',
  'Caesar Andrade Faini',
  'Frances C. Fairman',
  'Ian Fairweather',
  'Leila Faithfull',
  'Julian Fałat',
  'Aniello Falcone',
  'Alexandre Falguière',
  'Hans Falk',
  'Robert Falk',
  'Claire Falkenstein',
  'Fernando Amorsolo',
  'Fan Kuan',
  'Fan Qi',
  'Fang Congyi',
  'Farid Mansour',
  'Henri Fantin-Latour',
  'Dennis H. Farber',
  'Demetrios Farmakopoulos',
  'Mahmoud Farshchian',
  'Bernd Fasching',
  'Giovanni Fattori',
  'Jean Fautrier',
  'Daphne Fedarb',
  'Helmut Federle',
  'Franz Fedier',
  'Pavel Fedotov',
  'Paul Feeley',
  'Fei Danxu',
  'Hermann Feierabend',
  'Lyonel Feininger',
  'Robert Feke',
  'Adolf Fényes',
  'Béni Ferenczy',
  'Károly Ferenczy',
  'Noémi Ferenczy',
  'Amos Ferguson',
  'William Gouw Ferguson',
  'Christian Jane Fergusson',
  'John Duncan Fergusson',
  'Elsie Few',
  'Anna Findlay',
  'Celia Fiennes',
  'Pedro Figari',
  'Francesco Filippini',
  'Pavel Filonov',
  'Willy Finch',
  'Perle Fine',
  'Leonor Fini',
  'Ian Hamilton Finlay',
  'Hans Fischer',
  'Paul Gustave Fischer',
  'Janet Fish',
  'Alvan Fisher',
  'Hugo Anton Fisher',
  'James Montgomery Flagg',
  'Dennis Flanders',
  'Juan de Flandes',
  'Bernard Fleetwood-Walker',
  'Govert Flinck',
  'Sir William Russell Flint',
  'Michael Flohr',
  'Cornelia MacIntyre Foley',
  'John Fulton Folinsbee',
  'Lucio Fontana',
  'Victorine Foot',
  'Elizabeth Forbes',
  'Stanhope Forbes',
  'Mollie Forestier-Walker',
  'Melozzo da Forlì',
  'William Forsyth',
  'Graham Forsythe',
  'Charlton Fortune',
  'Marià Fortuny',
  'Johanna Marie Fosie',
  'Tsuguharu Foujita',
  'Cherryl Fountain',
  'Jean Fouquet',
  'Alexandre-Évariste Fragonard',
  'Jean-Honoré Fragonard',
  'Frahm',
  'Piero della Francesca',
  'François Louis Thomas Francia',
  'John Francis',
  'Sam Francis',
  'Hannah Frank',
  'Jane Frank',
  'Helen Frankenthaler',
  'Eva Frankfurther',
  'Manuel Franquelo',
  'Frank Frazetta',
  'Wilhelm Freddie',
  'Robert Freebairn',
  'Jane Freeman',
  'Jane Freilicher',
  'Charles Alphonse du Fresnoy',
  'Sigmund Freudenberger',
  'Friedrich Ritter von Friedländer-Malheim',
  'Hans Fries',
  'Pia Fries',
  'Frederick Carl Frieseke',
  'Lorentz Frölich',
  'Otto Frölicher',
  'Nicolas Froment',
  'Brian Froud',
  'Fu Baoshi',
  'Emil Fuchs',
  'Nick Fudge',
  'Fujishima Takeji',
  'Fujiwara Nobuzane',
  'Fujiwara Takanobu',
  'Francesco Furini',
  'Wilhelmina Weber Furlong',
  'Thomas Furlong',
  'Ludovit Fulla',
  'Violet Fuller',
  'Joseph von Führich',
  'John Russell Fulton',
  'Charles Furneaux',
  'Henry Fuseli',
  'Anna Füssli',
  'Johann Caspar Füssli',
  'Johann Kaspar Füssli',
  'Agnolo Gaddi',
  'Taddeo Gaddi',
  'Gai Qi',
  'Thomas Gainsborough',
  'Tamas Galambos',
  'Fede Galizia',
  'Ellen Gallagher',
  'Akseli Gallen-Kallela',
  'Giovanni Antonio Galli',
  'Byron Galvez',
  'Lattanzio Gambara',
  'Thomas Gambier Parry',
  'Henry Snell Gamley',
  'Antonio de la Gandara',
  'Gang Hui-an',
  'Gang Se-hwang',
  'Kishi Ganku',
  'Simon Gaon',
  'Gao Cen',
  'Gao Fenghan',
  'Gao Kegong',
  'Gao Qipei',
  'Gao Xiang',
  'Daniel Garber',
  'Víctor Manuel García Valdés',
  'Margaret Garland',
  'David Garner',
  'Joy Garnett',
  'Meredith Garniss',
  'Norman Garstin',
  'Lee Gatch',
  'Gatōken Shunshi',
  'Friedrich Gauermann',
  'Jakob Gauermann',
  'Giovanni Battista Gaulli',
  'Robert Gavin',
  'Nikolai Ge',
  'William Gear',
  'Andrew Geddes',
  'Margaret Geddes',
  'Ilka Gedő',
  'Geertgen tot Sint Jans',
  'Alison Geissler',
  'William Geissler',
  'Aert de Gelder',
  'Justus Gent',
  'Nick Gentry',
  'Ivan Generalić',
  'Artemisia Gentileschi',
  'Orazio Gentileschi',
  'Paul Georges',
  'Aleksandr Gerasimov',
  'Fernando Gerassi',
  'Abdullah Gërguri',
  'Jean-Léon Gérôme',
  'Kaff Gerrard',
  'Wojciech Gerson',
  'Richard Gerstl',
  'Mark Gertler',
  'Solomon Gessner',
  'Cristache Gheorghiu',
  'Jacob de Gheyn II',
  'Domenico Ghirlandaio',
  'Ridolfo Ghirlandaio',
  'Alberto Giacometti',
  'Diego Giacometti',
  'Giovanni Giacometti',
  'Khalil Gibran',
  'David Cooke Gibson',
  'John Gibson',
  'Stefan Gierowski',
  'Harald Giersing',
  'Aleksander Gierymski',
  'Maksymilian Gierymski',
  'Sanford Robinson Gifford',
  'H. R. Giger',
  'Araceli Gilbert',
  'Stephen Gilbert',
  'James Giles',
  'Colin Gill',
  'Gregory Gillespie',
  'James Gillick',
  'William George Gillies',
  'Harold Gilman',
  'Aleksander Gine',
  'Phyllis Ginger',
  'Charles Ginner',
  'Giorgione',
  'Giotto',
  'Frank J. Girardin',
  'François Girardon',
  'Marie-Suzanne Giroust',
  'Fritz Glarner',
  'Ilya Glazunov',
  'Albert Gleizes',
  'Charles Gleyre',
  'Oton Gliha',
  'John William Godward',
  'Hugo der Goes',
  'Leo Goetz',
  'Vincent Gogh',
  'Elias Goldberg',
  'Michael Goldberg',
  'Hilde Goldschmidt',
  'E. William Gollings',
  'Leon Golub',
  'Hendrik Goltzius',
  'Nuno Gonçalves',
  'Natalia Goncharova',
  'Gong Kai',
  'Gong Xian',
  'Eva Gonzalès',
  'Julio González',
  'Carl Arnold Gonzenbach',
  'Frederick Goodall',
  'Robert Goodnough',
  'Hilda May Gordon',
  'John Watson Gordon',
  'Constance Gordon-Cumming',
  'Spencer Gore',
  'Maria Johanna Görtz',
  'Jan Gossaert',
  'Caroline Gotch',
  'Adolph Gottlieb',
  'Leopold Gottlieb',
  'Maurycy Gottlieb',
  'Karl Otto Götz',
  'Hendrick Goudt',
  'Tom Gourdie',
  'Francisco Josè de Goya',
  'Jan Goyen',
  'Goyō Hashiguchi',
  'Igor Grabar',
  'Anton Graff',
  'Peter Benjamin Graham',
  'Eugenio Granell',
  'Duncan Grant',
  'James Ardern Grant',
  'Mary Grant',
  'Eugène Grasset',
  'Enrique Grau',
  'Nancy Graves',
  'Alasdair Gray',
  'Cleve Gray',
  'Norah Neilson Gray',
  'Edmund Greacen',
  'Pieter de Grebber',
  'El Greco',
  'Barbara Greg',
  'Jan Gregoor',
  'Louis Grell',
  'HAP Grieshaber',
  'Jan Griffier',
  'Robert Griffier',
  'Gwenny Griffiths',
  'Samuel Hieronymus Grimm',
  'Alexis Grimou',
  'John Atkinson Grimshaw',
  'Juan Gris',
  'Giuseppe Grisoni',
  'Konrad Grob',
  'Pieter Anthonisz. Groenewegen',
  'Ivan Grohar',
  'Mary Elizabeth Groom',
  'Catrin G Grosse',
  'Hugo Kārlis Grotuss',
  'Richard Gruelle',
  'Ernő Grünbaum',
  'Isaac Grünewald',
  'Matthias Grünewald',
  'Eduard von Grützner',
  'Gu Hongzhong',
  'Gu Kaizhi',
  'Gu Zhengyi',
  'Guan Daosheng',
  'Francesco Guardi',
  'Oswaldo Guayasamín',
  'Max Gubler',
  'Hans Gude',
  'Paul Guigou',
  'Albert Guillaume',
  'Armand Guillaumin',
  'Lajos Gulácsy',
  'Genco Gulan',
  'Olaf Gulbransson',
  'Ismail Gulgee',
  'Herbert James Gunn',
  'Guo Chun',
  'Guo Xi',
  'Nazmi Ziya Güran',
  'Elena Guro',
  'Alfred Richard Gurrey',
  'Ella Guru',
  'Philip Guston',
  'Gusukuma Seihō',
  'Albert Paris Gütersloh',
  'James Guthrie',
  'Kathleen Guthrie',
  'Robin Guthrie',
  'Judith Gutierrez',
  'Werner Gutzeit',
  'Jenő Gyárfás',
  'Líviusz Gyulai',
  'Joris der Haagen',
  'Cornelis Haarlem',
  'John Haberle',
  'Jan Hackaert',
  'Jacob Philipp Hackert',
  'Johannes Haensbergen',
  'Karl Hagedorn',
  'Karl Hagedopoorn',
  'Sally Haley',
  'Adélaïde Victoire Hall',
  'Patrick Hall',
  'Thomas Symington Halliday',
  'Hallsteinn Sigurðsson',
  'Dirck Hals',
  'Frans Hals',
  'Elaine Hamilton',
  'Gavin Hamilton',
  'Gawen Hamilton',
  'Maggie Hamilton',
  'Richard Hamilton',
  'Wilhelm Hammershøi',
  'Frederick Hammersley',
  'Hermione Hammond',
  'Nina Hamnett',
  'Han Gan',
  'Raymond Han',
  'Hanabusa Itchō',
  'Hanabusa Itchō II',
  'Jakob Emanuel Handmann',
  'Jakob Häne',
  'Adriaen Hanneman',
  'Armin Hansen',
  'Constantin Hansen',
  'William Harnett',
  'Henri Harpignies',
  'George Frederick Harris',
  'Lawren Harris',
  'Lawrence Harris',
  'Tracy Harris',
  'Marsden Hartley',
  'Archibald Standish Hartrick',
  'Hans Hartung',
  'Gertrude Harvey',
  'Harold Harvey',
  'Paul Harvey',
  'Hasegawa Settan',
  'Hasegawa Tōhaku',
  'Maryam Hashemi',
  'Hashimoto Gahō',
  'Childe Hassam',
  'Fathi Hassan',
  'Julius Hatofsky',
  'Julian Hatton',
  'Haukur Halldórsson',
  'Lars Jonson Haukaness',
  'Rudolf Hausner',
  'Sam Havadtoy',
  'Karel Havlíček',
  'Jane Hawkins',
  'David Ramsay Hay',
  'Peter Alexander Hay',
  'Gyoshū Hayami',
  'Colin Hayes',
  'Francesco Hayez',
  'Martin Johnson Heade',
  'Isobel Heath',
  'Ernest Hébert',
  'Jeanne Hébuterne',
  'Erich Heckel',
  'Willem Claeszoon Heda',
  'Cornelis de Heem',
  'Jan Davidsz. de Heem',
  'Egbert Heemskerck',
  'Henry Heerup',
  'Franz Hegi',
  'François Joseph Heim',
  'Wilhelm Heise',
  'Johannes Heisig',
  'Bettina Heinen-Ayech',
  'Jean Hélion',
  'Dirk Helmbreker',
  'Gottfried Helnwein',
  'Francis Helps',
  'Bartholomeus der Helst',
  'Jan Davidsz de Hem',
  'Andrew Henderson',
  'Elsie Henderson',
  'Jeremy Henderson',
  'John Henderson',
  'Joseph Henderson',
  'Keith Henderson',
  'Jean-Jacques Henner',
  'Joseph Morgan Henninger',
  'Robert Henri',
  'Rose Henriques',
  'David Eugene Henry',
  'Edward Lamson Henry',
  'George Henry',
  'Paul Henry',
  'Norman Hepple',
  'Auguste Herbin',
  'Hubert von Herkomer',
  'John Frederick Herring',
  'John Frederick Herring',
  'Louis Hersent',
  'Heinrich Herzig',
  'F. Scott Hess',
  'Carle Hessay',
  'Magnus Colcord Heurlin',
  'Jacob de Heusch',
  'Prudence Heward',
  'Elsie Dalton Hewland',
  'Cicely Hey',
  'Jean Hey',
  'Jan der Heyden',
  'Hugo Heyrman',
  'Edward Hicks',
  'Hidari Jingorō',
  'Kaii Higashiyama',
  'Amelia Robertson Hill',
  'David Octavius Hill',
  'Derek Hill',
  'Thomas Hill',
  'Nicholas Hilliard',
  'Charles Hinman',
  'Walter Haskell Hinton',
  'Sadamichi Hirasawa',
  "Un'ichi Hiratsuka",
  'Ikuo Hirayama',
  'Adolf Hirémy-Hirschl',
  'Hirosada II',
  'Hiroshige',
  'Damien Hirst',
  'Hishida Shunsō',
  'Hishikawa Moronobu',
  'D. Howard Hitchcock',
  'Sigrid Hjertén',
  'Prince Hoare',
  'Prince Hoare',
  'William Hoare',
  'Meindert Hobbema',
  'David Hockney',
  'Eliot Hodgkin',
  'Howard Hodgkin',
  'Frances Hodgkins',
  'Ferdinand Hodler',
  'Karl Hofer',
  'Margo Hoff',
  'Wlastimil Hofman',
  'Hans Hofmann',
  'Heinrich Hofmann',
  'William Hogarth',
  'Hokusai',
  'Francisco de Holanda',
  'Ambrosius Holbein',
  'Wenceslas Hollar',
  'Ruth Hollingsworth',
  'Itshak Holtz',
  'Winslow Homer',
  'Jin Homura',
  'Hiroshi Honda',
  "Gijsbert d'Hondecoeter",
  "Gillis d'Hondecoeter",
  "Melchior d'Hondecoeter",
  'Abraham Hondius',
  'Henricus Hondius II',
  'Willem Hondius',
  'Nathaniel Hone',
  'Hong Ren',
  'Villard de Honnecourt',
  'Gerald Honthorst',
  'Pieter de Hooch',
  'Samuel Dirksz Hoogstraten',
  'Chris Hooley',
  'Charles Hopkinson',
  'Carl Hoppe',
  'Edward Hopper',
  'Istvan Horkay',
  'Edward Atkinson Hornel',
  'Elmyr de Hory',
  'Oluf Høst',
  'Anna Hotchkis',
  'Gerard Houckgeest',
  'Ken Howard',
  'Ray Howard-Jones',
  'Youssef Howayek',
  'Adolf Hölzel',
  'Hristofor Žefarović',
  'Hu Jieqing',
  'Hu Zao',
  'Hu Zaobin',
  'Hua Yan',
  'Huang Binhong',
  'Huang Ding',
  'Huang Gongwang',
  'Huang Ji',
  'Huang Shen',
  'Huang Tingjian',
  'Jean Huber Voltaire',
  'Wolf Huber',
  'Erlund Hudson',
  'Juergen von Huendeberg',
  'Arthur Hughes',
  'Edward Robert Hughes',
  'Eleanor Hughes',
  'Hugh Hughes',
  'Robert Alwyn Hughes',
  'Friedensreich Hundertwasser',
  'Georgina Hunt',
  'William Holman Hunt',
  'John Kelso Hunter',
  'Beatrice Huntington',
  'M. F. Husain',
  'Jakub Husnik',
  'John Hutchison',
  'Vasile Hutopila',
  'John Hutton',
  'Pieter Huys',
  'Jan Huysum',
  'Ike no Taiga',
  'Leiko Ikemura',
  'Jörg Immendorff',
  'David Imms',
  'Domenico Induno',
  'Rudolph F. Ingerle',
  'John Stuart Ingle',
  'Jean Auguste Dominique Ingres',
  'George Inness',
  'James Dickson Innes',
  'INO',
  'Inoue Naohisa',
  'Dahlov Ipcar',
  'Albert Irvin',
  'Vincenzo Irolli',
  'Wilson Irvine',
  'Eugène Isabey',
  'Rolf Iseli',
  'Adriaen Isenbrant',
  'Kinichiro Ishikawa',
  'Jozef Israëls',
  'Itagaki Yoshio',
  'Itō Jakuchū',
  'Itō Ogura Yonesuke',
  'Itō Seiu',
  'Itō Shinsui',
  'Johannes Itten',
  'Alexander Ivanov',
  'Béla Iványi-Grünwald',
  'Oton Iveković',
  'Iwasa Matabei',
  'Josef Jackerson',
  'Jackson',
  'Francis Ernest Jackson',
  'Robert Jacobsen',
  'Lambert Jacobsz',
  'Yvonne Jacquette',
  'Jan Jahn',
  'Božidar Jakac',
  'Július Jakoby',
  'Rihard Jakopič',
  'Matija Jama',
  'Shani Rhys James',
  'Terrell James',
  'George Jamesone',
  'Alfred Janes',
  'Jang Seung-eop',
  'Louis Janmot',
  'Ruud Janssen',
  'Derek Jarman',
  'James Jarvaise',
  'Eero Järnefelt',
  'Karl Jauslin',
  'Alexej von Jawlensky',
  'Ruth Jên',
  'Viktor de Jeney',
  'William Jennys',
  'Alfred Jensen',
  'Jeong Seon',
  'Walther Jervolino',
  'Ji Sheng',
  'Jiang Tingxi',
  'Jiao Bingzhen',
  'Jin Nong',
  'Ferenc Joachim',
  'Chantal Joffe',
  'Jóhannes Geir Jónsson',
  'Jóhannes Sveinsson Kjarval',
  'Augustus John',
  'Gwen John',
  'Jasper Johns',
  'Mitchell Johnson',
  'Sargent Johnson',
  'Allen Jones',
  'Aneurin Jones',
  'Lois Mailou Jones',
  'Ludolf Leendertsz de Jongh',
  'Johan Jongkind',
  'Alexander Johnston',
  'Dorothy Johnstone',
  'Zoltán Joó',
  'Jacob Jordaens',
  'Asger Jorn',
  'Lily Delissa Joseph',
  'Josetsu',
  'Jean Jouvenet',
  'Ju Chao',
  'Ju Lian',
  'Donald Judd',
  'Jens Juel',
  'Júlíana Sveinsdóttir',
  'Grethe Jürgens',
  'Gabrijel Jurkić',
  'Kaburagi Kiyokata',
  'Ingrida Kadaka',
  'Charles S. KaelinWolf Kahn',
  'Kaigetsudō Anchi',
  'Kaigetsudō Ando',
  'Kailash Chandra Meher',
  'Jacob Kainen',
  'Willem Kalf',
  'Jitish Kallat',
  'Kamagurka',
  'Lev Lvovich Kamenev',
  'Kamisaka Sekka',
  'Kanbun Master',
  'Paul Kane',
  'Rajmund Kanelba',
  'Kanō Eitoku',
  'Kanō Hōgai',
  'Kanō Masanobu',
  'Kanō Mitsunobu',
  'Kanō Motonobu',
  'Kanō Naizen',
  'Kanō Sanraku',
  'Kanō Sansetsu',
  'Kanō Shōsenin',
  'Kanō Takanobu',
  "Kanō Tan'yū",
  'Kanō Tanshin',
  'Alexander Kanoldt',
  'Howard Kanovitz',
  'Nabil Kanso',
  'Tadeusz Kantor',
  'Leon Kapliński',
  'Edmond Xavier Kapp',
  'Bertalan Karlovszky',
  'Alfons Karpiński',
  'Jan Karpíšek',
  'Nikolai Alekseyevich Kasatkin',
  'Luigi Kasimir',
  'Vytautas Kasiulis',
  'János Kass',
  'Lajos Kassák',
  'Eizō Katō',
  'Tōichi Katō',
  'Nándor Katona',
  'Katsukawa Shunchō',
  "Katsukawa Shun'ei",
  'Katsukawa Shunkō I',
  'Katsukawa Shunsen',
  'Katsukawa Shunshō',
  'Alex Katz',
  'Hanns Katz',
  'Angelica Kauffman',
  'Isidor Kaufman',
  'František Kaván',
  'Kawabata Ryūshi',
  'Kawai Gyokudō',
  'Kawanabe Kyōsai',
  'Kawase Hasui',
  'John Kay',
  'Otis Kaye',
  'Ke Jiusi',
  'John Keane',
  'Carl Eugen Keel',
  'Adam Dario Keel',
  'Keisai Eisen',
  'Gusztáv Kelety',
  'Albert Keller',
  'Gerald Kelley',
  'Ellsworth Kelly',
  'Gerald Kelly',
  'Jane Kelly',
  'Paul Kelpe',
  'Jeka Kemp',
  'Richard Gordon Kendall',
  'Rockwell Kent',
  'Dóra Keresztes',
  'Károly Kernstok',
  'Georg Friedrich Kersting',
  'Morris Kestelman',
  'Thomas de Keyser',
  'I Ketut Soki',
  'Annabel Kidston',
  'Anselm Kiefer',
  'Michel Kikoine',
  'Kikuchi Yōsai',
  'Ada Gladys Killins',
  'Sarah Louisa Kilpack',
  'Kim Deuk-sin',
  'Kim Du-ryang',
  'Kim Eung-hwan',
  'Kim Hong-do',
  'Kim Hwan-gi',
  'Kim Jeong-hui',
  'Kim Myeong-guk',
  'Kim Tschang Yeul',
  'Charles Bird King',
  'Dorothy King',
  'Jessie M. King',
  'Eduardo Kingman',
  'Alison Kinnaird',
  'Jan Kip',
  'Ernst Ludwig Kirchner',
  'Raphael Kirchner',
  'Per Kirkeby',
  'Ryūsei Kishida',
  'Károly Kisfaludy',
  'Moïse Kisling',
  'Bálint Kiss',
  'Kitagawa Utamaro',
  'R. B. Kitaj',
  'Kiyohara Tama',
  'Roar Kjernstad',
  'Konrad Klapheck',
  'Mati Klarwein',
  'Paul Klee',
  'Yves Klein',
  'Joseph Kleitsch',
  'Heinrich Kley',
  'Karel Klíč',
  'Gustav Klimt',
  'Franz Kline',
  'Julius Klinger',
  'Max Klinger',
  'Hilma af Klint',
  'Juraj Julije Klović',
  'Ludwig Knaus',
  'Laura Knight',
  'Jesper Knudsen',
  'Kobayashi Kiyochika',
  'Martin Kober',
  'Ivana Kobilca',
  'Christen Købke',
  'Aleksander Kobzdej',
  'Ibrahim Kodra',
  'Robert Koehler',
  'Narashige Koide',
  'Ryōhei Koiso',
  'Junsaku Koizumi',
  'Oskar Kokoschka',
  'Andrei Kolkoutine',
  'Rudolf Koller',
  'Käthe Kollwitz',
  'Ludwik Konarzewski',
  'Ludwik Konarzewski Jr',
  'Jan Konůpek',
  'Béla Kondor',
  'Jacob Koninck',
  'Philip de Koninck',
  'Salomon Koninck',
  'Kōno Michisei',
  'Jeff Koons',
  'Frans Koppelaar',
  'Jaroslava Korol',
  'Aladár Körösfői-Kriesch',
  'Konstantin Korovin',
  'Koryusai Isoda',
  'Kose Kanaoka',
  'Jerzy Kossak',
  'Juliusz Kossak',
  'Wojciech Kossak',
  'Franciszek Kostrzewski',
  'József Koszta',
  'Albert Kotin',
  'Aleksander Kotsis',
  'Ivan Kramskoi',
  'Andre de Krayewski',
  'Albert Henry Krehbiel',
  'Jürg Kreienbühl',
  'Kristian Kreković',
  'Pinchus Kremegne',
  'Christian Krohg',
  'Per Krohg',
  'Leon Kroll',
  'Peder Severin Krøyer',
  'Marie Krøyer',
  'Mārtiņš Krūmiņš',
  'Alfred Krupa',
  'Alfred Freddy Krupa',
  'Martina Krupičková',
  'Izidor Kršnjavi',
  'Konrad Krzyżanowski',
  'Otakar Kubín',
  'Bohumil Kubista',
  'Alexander Kucharsky',
  'Arkhip Kuindzhi',
  'Igor Kufayev',
  'Kume Keiichiro',
  'Kun Can',
  'Yasuo Kuniyoshi',
  'Jan Kupecký',
  'František Kupka',
  'Alexander V. Kuprin',
  'Kuroda Seiki',
  'Yayoi Kusama',
  'Boris Kustodiev',
  'Kusumi Morikage',
  'Vilhelm Kyhn',
  'Willem Labeij',
  'Adélaïde Labille-Guiard',
  'Félix Labisse',
  'Márta Lacza',
  'Pieter Laer',
  'Pierre Laffillé',
  'Emile Lahner',
  'Annie Rose Laing',
  'Gerard de Lairesse',
  'Wifredo Lam',
  'Henry Lamb',
  'George Lambourn',
  'Aleksandr Ivanovich Laktionov',
  'Lam Qua',
  'Lan Ying',
  'Nicolas Lancret',
  'Myra Landau',
  'Ronnie Landfield',
  'Edwin Landseer',
  'Fitz Hugh Lane',
  'Giovanni Lanfranco',
  'Otto Lange',
  'Peter Lanyon',
  'Mikhail Larionov',
  'Julio Larraz',
  'Carl Larsson',
  'Ibram Lassaw',
  'Pieter Lastman',
  'Philip de László',
  'Rainer Maria Latzke',
  'William Langson Lathrop',
  'Robert Scott Lauder',
  'Marie Laurencin',
  'Jean-Paul Laurens',
  'John Lavery',
  'Andrew Law',
  'Edith Lawrence',
  'Jacob Lawrence',
  'Thomas Lawrence',
  'Jesús Mari Lazkano',
  'Gregorio Lazzarini',
  'Charles Le Brun',
  'Antoine Le Nain',
  'Louis Le Nain',
  'Mathieu Le Nain',
  'Charles Le Roux',
  'Henri Le Sidaner',
  'Benjamin Williams Leader',
  'Juan de Valdés Leal',
  'Georges Emile Lebacq',
  'Mikhail Lebedev',
  'Lennie Lee',
  'Jules Joseph Lefebvre',
  'Silvestro Lega',
  'Alphonse Legros',
  'Anton Lehmden',
  'Wilhelm Leibl',
  'Frederic Leighton',
  'Margaret Leiteritz',
  'Peter Lely',
  'Ulrich Leman',
  'Georges Lemmen',
  'August Lemmer',
  'Tamara de Lempicka',
  'Franz von Lenbach',
  'Leng Mei',
  'Robert Lenkiewicz',
  'Achille Leonardi',
  'Juliette Leong',
  'Leonardo da Vinci',
  'Stanislas Lépine',
  'Mikhail Yuryevich Lermontov',
  'Niels Lergaard',
  'Alfred Leslie',
  'Michael Leunig',
  'Leo Leuppi',
  'Emanuel Leutze',
  'Jack Levine',
  'Isaac Levitan',
  'Rafail Levitsky',
  'Dmitry Levitzky',
  'Bill Lewis',
  'Wyndham Lewis',
  'Aertgen Leyden',
  'Lucas Leyden',
  'Alfred Leyman',
  'Thyrza Anne Leyshon',
  'Judith Leyster',
  'André Lhote',
  'Li Cheng',
  'Li Di',
  'Li Fangying',
  'Li Gonglin',
  'Li Kan',
  'Li Keran',
  'Li Mei-shu',
  'Li Rongjin',
  'Li Shan',
  'Li Shida',
  'Li Shixing',
  'Li Song',
  'Li Tang',
  'Li Zai',
  'Liang Kai',
  'Liao Chi-chun',
  'Max Liebermann',
  'Irene Lieblich',
  'Josse Lieferinxe',
  'Jan Lievens',
  'Sándor Liezen-Mayer',
  'Maxwell Gordon Lightfoot',
  'Bruno Liljefors',
  'Limbourg brothers',
  'Lin Liang',
  'Lin Tinggui',
  'Amalia Lindegren',
  'Emil Lindenfeld',
  'Arlington Nelson Lindenmuth',
  'Allan Linder',
  'Lionel Lindsay',
  'Carl Walter Liner',
  'Johannes Lingelbach',
  'John Linnell',
  'Jean-Étienne Liotard',
  'Filippino LippiOleg Lipchenko',
  'Arthur Lismer',
  'Johann Liss',
  'Dirck der Lisse',
  'El Lissitzky',
  'Beatrice Ethel Lithiby',
  'Stephen Little',
  'Alexander Litovchenko',
  'Liu Haisu',
  'Liu Jue',
  'Liu Jun',
  'William Home Lizars',
  'Elizabeth Jane Lloyd',
  'Stefan Lochner',
  'William Mustart Lockhart',
  'Dorothy Lockwood',
  'Lojze Logar',
  'Paul Lohse',
  'Elfriede Lohse-Wächtler',
  'Germán Londoño',
  'Leonard Long',
  'McKendree Long',
  'Barbara Longhi',
  'Pietro Longhi',
  'Charles-André Loo',
  'Charles-Amédée-Philippe Loo',
  'Jean-Baptiste Loo',
  'Louis-Michel Loo',
  'Cándido López',
  'Melchior Lorck',
  'Christian August Lorentzen',
  'Pietro Lorenzetti',
  'Hew Lorimer',
  'John Henry Lorimer',
  'Lorenzo Lotto',
  'Károly Lotz',
  'Morris Louis',
  'Louisa Matthíasdóttir',
  'Claude Lorrain',
  'L. S. Lowry',
  'Lu Guang',
  'Lü Ji',
  'Lu Zhi',
  'Edward George Handel Lucas',
  'Edwin G. Lucas',
  'Maximilien Luce',
  'Lucebert',
  'Ștefan Luchian',
  'John Luke',
  'George Benjamin Luks',
  'Évariste Vital Luminais',
  'Juan Luna',
  'Henning Jakob Henrik Lund',
  'Lund',
  'Johan Lundbye',
  'Vilhelm Lundstrøm',
  'Luo Mu',
  'Luo Ping',
  'Luo Zhichuan',
  'Oskar Lüthy',
  'Laura Muntz Lyall',
  'Genevieve Springston Lynch',
  'Ma Lin',
  'Ma Shi',
  'Ma Quan',
  'Ma Wan',
  'Ma Yuan',
  'Ma Yuanyu',
  'Manabu Mabe',
  'Robert Walker Macbeth',
  'Henry Macbeth-Raeburn',
  'Robert MacBryde',
  'Dugald Sutherland MacColl',
  'Frances MacDonald',
  'Frances Macdonald',
  'Hamish MacDonald',
  'Stanton Macdonald-Wright',
  'James Pittendrigh MacGillivray',
  'William York Macgregor',
  'August Macke',
  'Esther Blaikie MacKinnon',
  'Charles Rennie Mackintosh',
  'Margaret Macdonald Mackintosh',
  'Daniel Maclise',
  'Chica Macnab',
  'Nicolette Macnamara',
  'Elizabeth MacNicol',
  'William MacTaggart',
  'Viktor Madarász',
  'Conroy Maddox',
  'Carlo Maderna',
  'Maeda Masao',
  'Maeda Seison',
  'Nicolaes Maes',
  'John Maggs',
  'Charles Mahoney',
  'Theodore Major',
  'Hans Makart',
  'Americo Makk',
  'Maki Haku',
  'Konstantin Makovsky',
  'Vladimir Makovsky',
  'Tadeusz Makowski',
  'Kees Maks',
  'Jacek Malczewski',
  'Estuardo Maldonado',
  'Władysław Malecki',
  'Anita Malfatti',
  'José Malhoa',
  'Maruja Mallo',
  'Jean Malouel',
  'Teobaldo Nina Mamani',
  'Cornelis de Man',
  'Jack C. Mancino',
  'Edna Mann',
  'Harrington Mann',
  'Johan Edvard Mandelberg',
  'Karel Mander',
  'Josef Mánes',
  'Alfred Manessier',
  'Miltos Manetas',
  'Joe Mangrum',
  'Jim Manley',
  'Alexander Mann',
  'Totte Mannes',
  'Paul Howard Manship',
  'George Manson',
  'James Bolivar Manson',
  'Andrea Mantegna',
  'Niklaus Manuel',
  'Adam Manyoki',
  'Julius Edvard Marak',
  'Franz Marc',
  'Conrad Marca-Relli',
  'Louis Marcoussis',
  'Adam Marczyński',
  'Brice Marden',
  'Ödön Márffy',
  'De Hirsh Margules',
  'Carlos Francisco Chang Marín',
  'John Marin',
  'Jacob Maris',
  'Matthijs Maris',
  'Willem Maris',
  'Yoshio Markino',
  'Terry Marks',
  'Csaba Markus',
  'Simon Marmion',
  'Jacques Maroger',
  'Luděk Marold',
  'Albert Marquet',
  'Jacob Marrel',
  'Luis Marsans',
  'Nicholas Marsicano',
  'Wilhelm Marstrand',
  'Agnes Martin',
  'Eugene J. Martin',
  'Fletcher Martin',
  'John Martin',
  'Knox Martin',
  'Carlo Martini',
  'Ndoc Martini',
  'Raúl Martínez',
  'Johannes Martini',
  'Simone Martini',
  'Andrey Yefimovich Martynov',
  'Maruyama Ōkyo',
  'Tommaso Masaccio',
  'Frans Masereel',
  'Vicente Juan Masip',
  'Stanisław Masłowski',
  'Masolino',
  'Alice Mason',
  'Emily Mason',
  'Frank Mason',
  'Jan Matejko',
  'Zsuzsa Máthé',
  'Matsui Fuyoko',
  'Matsumura Goshun',
  'Matsuno Chikanobu',
  'Quentin Matsys',
  'Roberto Matta',
  'Louisa Matthíasdóttir',
  'Jaakko Mattila',
  'Eszter Mattioni',
  'Karl Matzek',
  'Jeanne du Maurier',
  'Anton Mauve',
  'Paul Mavrides',
  'Peter Max',
  'Vassily Maximov',
  'John Maxwell',
  'Richard Mayhew',
  'Peter McArdle',
  'Charles McAuley',
  'James McBey',
  'Colin McCahon',
  'Sheila McClean',
  'Daphne McClure',
  'Mary McCrossan',
  'Frederick McCubbin',
  'Horatio McCulloch',
  'Mary McEvoy',
  'Rory McEwen',
  'Fanny McIan',
  'R. R. McIan',
  'Frank McKelvey',
  'John McLaughlin',
  'Bruce McLean',
  'William McTaggart',
  'Lewis Henry Meakin',
  'Dimitre Manassiev Mehandjiysky',
  'Robert Medley',
  'László Mednyánszky',
  'Józef Mehoffer',
  'Howard Mehring',
  'Bernardino Mei',
  'Mei Qing',
  'Victor Meirelles',
  'Jean-Louis-Ernest Meissonier',
  'Cor Melchers',
  'Vadym Meller',
  'Antoine Ignace Melling',
  'Arthur Melville',
  'Hans Memling',
  'Milton Menasco',
  'Menez',
  'Bernard Meninsky',
  'Barthélemy Menn',
  'Carlo Mense',
  'Adolph Menzel',
  'Alexey Merinov',
  'Betty Merken',
  'Pál Szinyei Merse',
  'Luc-Olivier Merson',
  'Hans Mertens',
  'Arnold Mesches',
  'Hendrik Willem Mesdag',
  'Jean Messagier',
  'Youri Messen-Jaschin',
  'Ken Messer',
  'Antonello da Messina',
  'Ludwig Mestler',
  'Ivan Meštrović',
  'Attila Meszlenyi',
  'Géza Mészöly',
  'Willard Metcalf',
  'Gabriel Metsu',
  'Jean Metzinger',
  'Johann Heinrich Meyer',
  'Otto Meyer-Amden',
  'Mi Fu',
  'Miao Fu',
  'Peter Michael',
  'Piotr Michałowski',
  'Henri Michaux',
  'Michelangelo Buonarroti',
  'Leo Michelson',
  'David Michie',
  'Colin Middleton',
  'Eugeen Mieghem',
  'Jan Miel',
  'Michiel Jansz. Mierevelt',
  'Jan Mieris',
  'Willem Mieris',
  'Abraham Mignon',
  'Daniël Mijtens',
  'Jay Milder',
  'Ksenia Milicevic',
  'John Everett Millais',
  'Manolo Millares',
  'William Miller',
  'Francis Davis Millet',
  'Jean-François Millet',
  'Lisa Milroy',
  'Min Zhen',
  'Luis Miranda',
  'Joan Miró',
  'Augustyn Mirys',
  'Fred Mitchell',
  'Stanley Matthew Mitruk',
  'Miyagawa Chōshun',
  'Miyagawa Isshō',
  'Miyagawa Shunsui',
  'Tracey Moberly',
  'Amedeo Modigliani',
  'Claes Corneliszoon Moeyaert',
  'László Moholy-Nagy',
  'Jan Miense Molenaer',
  'Luis Molinari',
  'Anton Möller',
  'Sylvia Molloy',
  'Joos de Momper',
  'Piet Mondrian',
  'Blanche Hoschedé Monet',
  'Claude Monet',
  'Paul Monnier',
  'Eugene Montgomery',
  'Albert Joseph Moore',
  'Frank Montague Moore',
  'Mona Moore',
  'Otto Morach',
  'Rodolfo Morales',
  'Thomas Moran',
  'Giorgio Morandi',
  'Jacob More',
  'Gustave Moreau',
  'Paulus Moreelse',
  'Ernest Morgan',
  'Harry Morley',
  'Camilo Mori',
  'Mori Sosen',
  'Yoshitoshi Mori',
  'Berthe Morisot',
  'Malcolm Morley',
  'Carey Morris',
  'Carl Morris',
  'James Morris',
  'Terry Morris',
  'George Morrison',
  'James Morrison',
  'John Lowrie Morrison',
  'Alberto Morrocco',
  'Samuel F. B. Morse',
  'Richard Mortensen',
  'Thomas Corsan Morton',
  'William H. Mosby',
  'Mary Moser',
  'Colin Moss',
  'John Mossman',
  'William Mossman',
  'Robert Motherwell',
  'Frederik de Moucheron',
  'Didier Mouron',
  'Charles Mozley',
  'Ivan Mrkvička',
  'Master MS',
  'Muqi',
  'Alphonse Mucha',
  'Georg Muche',
  'Olive Mudie-Cooke',
  'Muggur',
  'Pieter Mulier',
  'Pieter Mulier II',
  'Sheila Mullen',
  'Jan Müller',
  'Maler Müller',
  'Adolfo Müller-Ury',
  'Munakata Shikō',
  'Edvard Munch',
  'Gustaf Munch-Petersen',
  'Loren Munk',
  'Mihály Munkácsy',
  'Sir Alfred Munnings',
  'Glòria Muñoz',
  'Gabriele Münter',
  'Muqi',
  'Kagaku Murakami',
  'Takashi Murakami',
  'John Murdoch',
  'Bartolomé Esteban Murillo',
  'Dr. Atl',
  'Elizabet Murray',
  'Edo Murtić',
  'Italo Mus',
  'Saneatsu Mushanokōji',
  'Zoran Mušič',
  'Michiel Musscher',
  'Girolamo Muziano',
  'Grigoriy Myasoyedov',
  'Johannes Mytens',
  'Caroline Mytinger',
  'Nadim Karam',
  'Isabel Naftel',
  'Maud Naftel',
  'Paul Jacob Naftel',
  'Urakusai Nagahide',
  'Nagasawa Rosetsu',
  'Aaron Nagel',
  'Patrick Nagel',
  'István Nagy',
  'Matthijs Naiveu',
  'Naka Bokunen',
  'Nakahara Nantenbō',
  'Naondo Nakamura',
  'Tadashi Nakayama',
  'Nam Gye-u',
  'Carlos Trillo Name',
  'Naza',
  'Albert Namatjira',
  'Lisa Nankivil',
  'Nara Yoshitomo',
  'Oscar Rodríguez Naranjo',
  'Jørgen Nash',
  'Paul Nash',
  'Alexander Nasmyth',
  'Anne Nasmyth',
  'Barbara Nasmyth',
  'Charlotte Nasmyth',
  'Jane Nasmyth',
  'Patrick Nasmyth',
  'Jean-Marc Nattier',
  'Bruce Nauman',
  'Josef Navrátil',
  'Naza',
  'Radi Nedelchev',
  'Alice Neel',
  'Aert der Neer',
  'Eglon der Neer',
  'Almada Negreiros',
  'LeRoy Neiman',
  'Kristin Nelson',
  'Albert Nemethy',
  'Odd Nerdrum',
  'Ismael Nery',
  'Ugo Nespolo',
  'Mikhail Nesterov',
  'Caspar Netscher',
  'Louise Nevelson',
  'NEVERCREW',
  'Jessie Newbery',
  'Roy Newell',
  'Ni Duan',
  'Ni Tian',
  'Ni Yuanlu',
  'Ni Zan',
  'Jan Nieuwenhuys',
  'Ben Nicholson',
  'William Nicholson',
  'John Nicolson',
  'Caro Niederer',
  'Ejnar Nielsen',
  'Jan Nieuwenhuys',
  'Nína Tryggvadóttir',
  "Nishida Shun'ei",
  'Nishikawa Sukenobu',
  'Margaret Graeme Niven',
  'Nōami',
  'James Campbell Noble',
  'Robert Noble',
  'Isamu Noguchi',
  'Gavin Nolan',
  'Sidney Nolan',
  'Kenneth Noland',
  'Emil Nolde',
  'Reinier Nooms',
  'Jean-Pierre Norblin de La Gourdaine',
  'Max Magnus Norman',
  'Raymond Normand',
  'Vladimir Novak',
  'Guity Novin',
  'Zbigniew Nowosadzki',
  'Zoltán Nuridsány',
  'Arvid Nyholm',
  'Alejandro Obregón',
  'Bencho Obreshkov',
  'Jacques-François Ochard',
  'Jacob Ochtervelt',
  'Leonard Ochtman',
  'Ogata Gekkō',
  'Ogata Kenzan',
  'Ogata Kōrin',
  "Juan O'Gorman",
  'Yuki Ogura',
  "Dorothea Warren O'Hara",
  'Ohara Koson',
  'Doug Ohlson',
  'Michalis Oikonomou',
  'Okada Beisanjin',
  'Okada Hanko',
  'Okamoto Tarō',
  "Georgia O'Keeffe",
  "Aloysius O'Kelly",
  'Okuda Gensō',
  'Okumura Masanobu',
  'Okumura Togyu',
  'Jules Olitski',
  'Viktor Oliva',
  'Nathan Oliveira',
  'Ceferí Olivé',
  'Madge Oliver',
  'Francisco Oller',
  'Geoffrey Olsen',
  "Frank O'Meara",
  'Kōshirō Onchi',
  'Julian Onderdonk',
  'Tadashige Ono',
  'Maria Oosterwijk',
  'Bruce Onobrakpeya',
  'John Opie',
  'Andrea Orcagna',
  'William Quiller Orchardson',
  'Edward Otho Cresap Ord',
  'Bryan Organ',
  'Emil Orlik',
  'Aleksander Orłowski',
  'István Orosz',
  'José Clemente Orozco',
  'Sir William Orpen',
  'Manuel Ortiz de Zarate',
  'George Earl Ortman',
  'Erik Ortvad',
  'Walter Osborne',
  'Adriaen Ostade',
  'Isaac Ostade',
  'Ilya Ostroukhov',
  'Masamitsu Ōta',
  'Otake Chikuha',
  'Johann Friedrich Overbeck',
  'Amédée Ozenfant',
  'Auseklis Ozols',
  'László Paál',
  'Stephen Pace',
  'T. K. Padmini',
  'Derold Page',
  'Grace Pailthorpe',
  'Antonie Palamedesz',
  'Maties Palau Ferré',
  'Béla Pállik',
  'Kalervo Palsa',
  'Tom Palin',
  'Pamphilus',
  'Arthur Pan',
  'Pan Tianshou',
  'Pan Yuliang',
  'Giovanni Paolo Pannini',
  'Józef Pankiewicz',
  'Eduardo Paolozzi',
  'George Papazov',
  'Roberto Parada',
  'David Park',
  'Constance-Anne Parker',
  'John Parker',
  'Lawton S. Parker',
  'Ray Parker',
  'Parmigianino',
  'Antônio Parreiras',
  'Clara Weaver Parrish',
  'Maxfield Parrish',
  'Ulrika Pasch',
  'Ed Paschke',
  'Jules Pascin',
  'Odhise Paskali',
  'George Passantino',
  'Leonid Pasternak',
  'Jean-Baptiste Pater',
  'Emily Murray Paterson',
  'James Paterson',
  'Viola Paterson',
  'Joachim Patinir',
  'Károly Patkóda',
  'David Paton',
  'Joseph Noel Paton',
  'James McIntosh Patrickגש',
  'Ambrose McCarthy Patterson',
  'Gen Paul',
  'William McGregor Paxton',
  'Michael Gustavius Payne',
  'Charles Willson Peale',
  'James Peale',
  'Raphaelle Peale',
  'Rembrandt Peale',
  'Rubens Peale',
  'Titian Peale',
  'Max Pechstein',
  'Carl-Henning Pedersen',
  'Pedro Pedraja',
  'Olivia Peguero',
  'Lucia Peka',
  'Waldo Peirce',
  'Slobodan Pejić',
  'Amelia Peláez',
  'Fernand Pelez',
  'Gina Pellón',
  'Agnes Lawrence Pelton',
  'Sophie Pemberton',
  'Giovanni Pelliccioli',
  'Relja Penezic',
  'Vincent Pepi',
  'Samuel Peploe',
  'Matteo Pérez',
  'Christopher Perkins',
  'Constant Permeke',
  'Vasily Perov',
  'Lilla Cabot Perry',
  'Pietro Perugino',
  'Robert Storm Petersen',
  'Jean Petitot',
  'Jean Louis Petitot',
  'Roy Petley',
  'John F. Peto',
  'Soma Orlai Petrich',
  'Giuseppe Antonio Petrini',
  'John Pettie',
  'Valerie Petts',
  'Peter S. Pezzati',
  'Erik Pevernagie',
  'Theodor Philipsen',
  'Ammi Phillips',
  'L. Vance Phillips',
  'Tom Phillips',
  'Ramón Piaguaje',
  'Giovanni Battista Piazzetta',
  'Ramon Pichot',
  'Nicolaes Eliaszoon Pickenoy',
  'Joseph Pickett',
  'Otto Piene',
  'Patrick Pietropoli',
  'André Pijet',
  'Adam Pijnacker',
  'Otto Pilny',
  'Carl Gustaf Pilo',
  'Veno Pilon',
  'Robert Antoine Pinchon',
  'Howardena Pindell',
  'Xavier Blum Pinto',
  'Grytė Pintukaitė',
  'Pinturicchio',
  'Richard Pionk',
  'George Pirie',
  'Pisanello',
  'Joseph Pisani',
  'Camille Pissarro',
  'Lucien Pissarro',
  'Orovida Camille Pissarro',
  'Primrose Pitman',
  'Lari Pittman',
  'Giambattista Pittoni',
  'Antoni Pitxot',
  'Otto Placht',
  'Josefina Tanganelli Plana',
  'John Platt',
  'John Plumb',
  'Władysław Podkowiński',
  'Ihor Podolchak',
  'Egbert der Poel',
  'Cornelis Poelenburgh',
  'Louis Pohl',
  'Vasily Polenov',
  'Grace Polit',
  'Sigmar Polke',
  'Jackson Pollock',
  'Elizabeth Polunin',
  'Fay Pomerance',
  'Jacopo Pontormo',
  'Horatio Nelson Poole',
  'Willem de Poorter',
  'Liubov Popova',
  'Bertalan Pór',
  'Fairfield Porter',
  'Candido Portinari',
  'Marten Post',
  'Hendrik Gerritsz Pot',
  'Beatrix Potter',
  'Paulus Potter',
  'Fuller Potter',
  'Edward Henry Potthast',
  'William Didier-Pouget',
  'Nathaniel Pousette-Dart',
  'Richard Pousette-Dart',
  'Nicolas Poussin',
  'William Powhida',
  'Domenico Pozzi',
  'Andrea Pozzo',
  'Harvey Pratt',
  'Fred Precht',
  'Gregorio Prestopino',
  'Gaetano Previati',
  'Mary Elizabeth Price',
  'Gwilym Prichard',
  'Alice Prin',
  'John Quinton Pringle',
  'Dod Procter',
  'Ernest Procter',
  'František Jakub Prokyš',
  'Andrzej Pronaszko',
  'Samuel Prout',
  'John Skinner Prout',
  'Tadeusz Pruszkówski',
  'Witold Pruszkówski',
  'Illarion Pryanishnikov',
  'Pu Hua',
  'Louisa Puller',
  'Puru',
  'Fritz Puempin',
  'Johann Pucher',
  'Karl Pümpin',
  'Hovsep Pushman',
  'Sasha Putrya',
  'Patrick Pye',
  'Jacob Pynas',
  'Jan Pynas',
  'Qi Baishi',
  'Qian Du',
  'Qian Gu',
  'Qian Xuan',
  'Qiu Ying',
  'Qu Leilei',
  'Domenico Quaglio',
  'Arthur Quartley',
  'Enguerrand Quarton',
  'Pieter Jansz Quast',
  'Harvey Quaytman',
  'August Querfurt',
  'José Comas Quesada',
  'François Quesnel',
  'Annie Abernethie Pirie Quibell',
  'John Quidor',
  'Benito Quinquela Martín',
  'Quirizio di Giovanni da Murano',
  'Mirko Rački',
  'Anton Räderscheidt',
  'Ronald Rae',
  'Henry Raeburn',
  'Fiona Rae',
  'Carl Rahl',
  'Francesco Raibolini',
  'Victor Noble Rainbird',
  'Edi Rama',
  'Allan Ramsay',
  'Carlo Randanini',
  'Ivan Ranger',
  'Arabella Rankin',
  'Raphael',
  'Joseph Raphael',
  'Dorning Rasbotham',
  'Slava Raškaj',
  'Robert Rauschenberg',
  'Dirck de Quade Ravesteyn',
  'Hubert Ravesteyn',
  'Jan Antonisz Ravesteyn',
  'Rachel Reckitt',
  'Edward Willis Redfield',
  'Tommaso Redi',
  'Odilon Redon',
  'Pierre-Joseph Redouté',
  'Anne Redpath',
  'Paula Rego',
  'István Regős',
  'Don Reichert',
  'Flora Macdonald Reid',
  'George Reid',
  'John Robertson Reid',
  'Robert Reid',
  'Wenzel Lorenz Reiner',
  'Frederic Remington',
  'Ren Bonian',
  'Ren Renfa',
  'Ren Xiong',
  'Ren Xun',
  'Guido ReniPierre Roland Renoir',
  'Tibor Rényi',
  'Ilya Yefimovich Repin',
  'Marcos Restrepo',
  'Paul Resika',
  'István Réti',
  'Pablo Rey',
  'Maurice Reymond',
  'Joshua Reynolds',
  'J. Massey Rhind',
  'John Rhind',
  'John Stevenson Rhind',
  'William Birnie Rhind',
  'Théodule Ribot',
  'Sebastiano Ricci',
  'Suzy Rice',
  'Ceri Richards',
  'Jesse Richards',
  'William Trost Richards',
  'Mary Curtis Richardson',
  'Willy Bo Richardson',
  'Robert Richenburg',
  'William Blake Richmond',
  'Gerhard Richter',
  'Charles Ricketts',
  'Hyacinthe Rigaud',
  'Anne Rigney',
  'Penny Rimbaud',
  'Jean-Paul Riopelle',
  'József Rippl-Rónai',
  'Friedrich Ritter von Friedländer-Malheim',
  'Rafael Ritz',
  'Arturo Rivera',
  'Larry Rivers',
  'Hubert Robert',
  'David Roberts',
  'Tom Roberts',
  'Alexander Robertson',
  'Andrew Robertson',
  'Archibald Robertson',
  'Theodore Robinson',
  'Norman Rockwell',
  'Henryk Rodakowski',
  'Alexander Rodchenko',
  'Holger Roed',
  'Jørgen Roed',
  'Nicholas Roerich',
  'Svetoslav Roerich',
  'Kurt Roesch',
  'Claude Rogers',
  'Christian Rohlfs',
  'Charles Roka',
  'Fyodor Rokotov',
  'Charles Rollier',
  'Osvaldo Romberg',
  'Gillis Rombouts',
  'George Romney',
  'Martinus Rørbye',
  'Guy Rose',
  'Jan Henryk Rosen',
  'James Rosenquist',
  'Alexander Roslin',
  'Toros Roslin',
  'Alex Ross',
  'Bob Ross',
  'Clifford Ross',
  'Jacek Andrzej Rossakiewicz',
  'Dante Gabriel Rossetti',
  'Antonio Rotta',
  'Jack Roth',
  'Georges Rouault',
  'Théodore Rousseau',
  'Ker-Xavier Roussel',
  'Thomas Rowlandson',
  'Pierre Roy',
  'György Rózsahegyi',
  'Peter Paul Rubens',
  'Ernő Rubik',
  'Andrei Rublev',
  'Olaf Rude',
  'Hermann Rüdisühli',
  'Andrée Ruellan',
  'Jacob Ruisdael',
  'Alexander Runciman',
  'John Runciman',
  'Phillip Otto Runge',
  'Edward Ruscha',
  'Nadya Rusheva',
  'Santiago Rusiñol',
  'Vlady Kibalchich Russakov',
  'Charles Marion Russell',
  'Morgan Russell',
  'Jan Rustem',
  'Ferdynand Ruszczyc',
  'Rachel Ruysch',
  'Salomon Ruysdael',
  'Andrei Ryabushkin',
  'Anne Ryan',
  'Eve Ryder',
  'Ryūkōsai Jokei',
  'Betye Saar',
  'Gaetano Sabatini',
  'Jacques Sablet',
  'Pieter Jansz Saenredam',
  'Cornelis Saftleven',
  'Herman Saftleven',
  'Sara Saftleven',
  'Kay Sage',
  'Anne Said',
  'Jean-Pierre Saint-Ours',
  'Saitō Kiyoshi',
  'Sakai Hōitsu',
  'Adam Saks',
  'Emilio Grau Sala',
  'Tahir Salahov',
  'Stanislaw Samostrzelnik',
  'Joop Sanders',
  'Hans Sandreuter',
  'Bernat Sanjuan',
  'John Singer Sargent',
  'Michael Aloysius Sarisky',
  'Andrea del Sarto',
  'Arthur Sarkissian',
  'Martiros Saryan',
  'Wilhelm Sasnal',
  'Satake Yoshiatsu',
  'Junpei Satoh',
  'Raymond Saunders',
  'Antonio Saura',
  'Anne Savage',
  'Jacob Savery',
  'Roelant Savery',
  'Jenny Saville',
  'Konstantin Savitsky',
  'Alexei Kondratyevich Savrasov',
  'János Saxon-Szász',
  'Sawa Sekkyō',
  'Jan Sawka',
  'Fikret Muallâ Saygı',
  'Godfried Schalcken',
  'Louis Schanker',
  'Hugo Scheiber',
  'Egon Schiele',
  'Oszkar Tordai Schilling',
  'Jakub Schikaneder',
  'Helene Schjerfbeck',
  'Rudolf Schlichter',
  'Richard Schmid',
  'Georg Friedrich Schmidt',
  'Johann George Schmidt',
  'Hans Werner Schmidt',
  'Karl Schmidt-Rottluff',
  'Randall Schmit',
  'Stella Schmolle',
  'Julian Schnabel',
  'Wilhelm Schnarrenberger',
  'Gérard Ernest Schneider',
  'Georg Scholz',
  'Floris Schooten',
  'Otto Marseus Schrieck',
  'Georg Schrimpf',
  'Adolf Schrödter',
  'Daniel Schultz',
  'Ethel Schwabacher',
  'Carlos Schwabe',
  'Randolph Schwabe',
  'Hans Schwarz',
  'Kurt Schwitters',
  'Alexander Scott',
  'Caroline Lucy Scott',
  'Kathleen Scott',
  'Peter Scott',
  'Richard T. Scott',
  'Samuel Scott',
  'Scott Robertson',
  'Tom Scott RSA',
  'William Edouard Scott',
  'Eduardo Lefebvre Scovell',
  'Sean Scully',
  'Felipe Seade',
  'Otakar Sedloň',
  'Richard Sedlon',
  'Lasar Segall',
  'Daniel Seghers',
  'Gerard Seghers',
  'Hercules Seghers',
  'Kurt Seligmann',
  'Manuel Rendón Seminario',
  'Jacek Sempoliński',
  'Sengai',
  'Sohrab Sepehri',
  'Zinaida Serebriakova',
  'Valentin Aleksandrovich Serov',
  'Clément Serveau',
  'Bela Čikoš Sesija',
  'Sesshū Tōyō',
  'James Sessions American painter',
  'Henriett Seth F.',
  'Georges Seurat',
  'Gino Severini',
  'Joseph Severn',
  'Shang Xi',
  'Emily Shanks',
  'Charles Haslewood Shannon',
  'Shao Mi',
  'Harold Shapinsky',
  'Tōshūsai Sharaku',
  'Sylvester Shchedrin',
  'Millard Sheets',
  'Samuel Shelley',
  'Rupert Shephard',
  'Donald Sherwood',
  'Shen Che-Tsai',
  'Shen Quan',
  'Shen Shichong',
  'Shen Zhou',
  'Sheng Mao',
  'Sheng Maoye',
  'Shi Rui',
  'Shi Zhonggui',
  'Shiba Kōkan',
  'Shibata Zeshin',
  'Kitao Shigemasa',
  'Shōzō Shimamoto',
  'Kanzan Shimomura',
  'Siona Shimshi',
  'Shin Saimdang',
  'Shin Yun-bok',
  'Shingei',
  'Everett Shinn',
  'Shinoda Toko',
  'Ivan Shishkin',
  'Shitao',
  'Harry Shoulberg',
  'Shūbun Tenshō',
  'Shukei Sesson',
  'Daryush Shokof',
  'Kevin A. Short',
  'Ram Chandra Shukla',
  'Shunbaisai Hokuei',
  'Shunkōsai Hokushū',
  'Walter Sickert',
  'Gregorius Sickinger',
  'Henryk Siemiradzki',
  'Paul Signac',
  'Telemaco Signorini',
  'Ramón Silva',
  'Sim Sa-jeong',
  'Josef Šíma',
  'Hugo Simberg',
  'Jozef Simmler',
  'Sidney Simon',
  'Edward Simmons',
  'Enrique Simonet',
  'David Simpson',
  'Ruth Simpson',
  'William Simpson',
  'Oliver Sin',
  'Sin Wi',
  'David Alfaro Siqueiros',
  'Mario Sironi',
  'Alfred Sisley',
  'Michael Sittow',
  'Robert Sivell',
  'Archibald Skirving',
  'P.C. Skovgaard',
  'Antonín Slavíček',
  'Fyodor Slavyansky',
  'Sylvia Sleigh',
  'Max Slevogt',
  'Władysław Ślewiński',
  'Pieter Cornelisz Slingelandt',
  'Hamilton Sloan',
  'John Sloan',
  'Arie Smit',
  'Grace Cossington Smith',
  'Jack Smith',
  'Leon Polk Smith',
  'Matthew Smith',
  'Xanthus Russell Smith',
  'Franciszek Smuglewicz',
  'Eero Snellman',
  'Peter Snow',
  'Sylvia Snowden',
  'Joan Snyder',
  'Sō Shiseki',
  'Sōami',
  'Gerard Soest',
  'Koloman Sokol',
  'Xul Solar',
  'Josep Rovira Soler',
  'Anton Solomoukha',
  'Konstantin Somov',
  'Jens Søndergaard',
  'Song Maojin',
  'Song Xu',
  'Rajesh Soni',
  'David G. Sorensen',
  'Hendrik Martenszoon Sorgh',
  'Joaquín Sorolla',
  'John Souch',
  'Camille Souter',
  'Chaim Soutine',
  'Amadeo de Souza Cardoso',
  'Isaac Soyer',
  'Moses Soyer',
  'Raphael Soyer',
  'Austin Osman Spare',
  'Stanley Spencer',
  'Nancy Spero',
  'Carl Spitzweg',
  'Sebastian Spreng',
  'Jan Stanisławski',
  'Otto Stark',
  'Wojciech Korneli Stattler',
  'Christian W. Staudinger',
  'Karl Stauffer-Bern',
  'Henryk Stażewski',
  'Karel Štěch',
  'T. C. Steele',
  'John Steell',
  'Jan Steen',
  'Hendrik Steenwijk I',
  'Hendrik Steenwijk II',
  'Philip Wilson Steer',
  'Joe Stefanelli',
  'Johann Gottfried Steffan',
  'Georges Stein',
  'Jacob Steinhardt',
  'Eduard von Steinle',
  'Théophile Steinlen',
  'Juan Carlos Stekelman',
  'Frank Stella',
  'Joseph Stella',
  'Hedda Sterne',
  'Matej Sternen',
  'David Watson Stevenson',
  'Helen Stevenson',
  'Robert Macaulay Stevenson',
  'William Grant Stevenson',
  'Andrew Stevovich',
  'Julius LeBlanc Stewart',
  'LeConte Stewart',
  'Clyfford Still',
  'Tobias Stimmer',
  'Andries Stock Dutch Baroque painter',
  'Stan Stokes',
  'Matthias Stom',
  'Abraham Storck',
  'Thomas Stothard',
  'William Stott',
  'Hendrick Streeck',
  'Juriaen Streeck',
  'Arthur Streeton',
  'Bartholomeus Strobel',
  'Ancell Stronach',
  'Joseph Dwight Strong',
  'Bernardo Strozzi',
  'Drew Struzan',
  'Zofia Stryjenska',
  'Wladyslaw Strzeminski',
  'Gilbert Stuart',
  'George Stubbs',
  'Franz Stuck',
  'Walter Stuempfig',
  'January Suchodolski',
  'Serge Sudeikin',
  'Sudip Roy',
  'Alberto Sughi',
  'Sugimura Jihei',
  'Yasushi Sugiyama',
  'William Holmes Sullivan',
  'Altoon Sultan',
  'Sava Šumanović',
  'Kelly Sueda',
  'Sun Junze',
  'Sun Kehong',
  'Sun Long',
  'Victor Surbek',
  'Vasily Surikov',
  'Alan Sutherland',
  'David Macbeth Sutherland',
  'George B. Sutherland',
  'Graham Sutherland',
  'Carol Sutton',
  'Linda Sutton',
  'Marc-Aurèle de Foy Suzor-Coté',
  'Suzuki Harunobu',
  'Max Švabinský',
  'Max Walter Svanberg',
  'Eva Švankmajerová',
  'Svend Rasmussen Svendsen',
  'Sardar Sobha Singh',
  'Sergei Sviatchenko',
  'Herman Swanevelt',
  'Roger Swainston',
  'Sigurd Swane',
  'Albert Swinden',
  'Fedot Sychkov',
  'George Gardner Symons',
  'Bertalan Székely',
  'Adam Szentpétery',
  'Józef Szermentowski',
  'István Szőnyi',
  'Lili Árkayné Sztehló',
  'Jean Tabaud',
  'Enrique Tábara',
  'Augustus Vincent Tack',
  'Tadanori Yokoo',
  'Sophie Taeuber-Arp',
  'Hiroyuki Tajima',
  'Judy Takács',
  'Hiromitsu Takahashi',
  'Takahashi Yuichi',
  'Takehisa Yumeji',
  'Takeuchi Seihō',
  'Allen Butler Talcott',
  'Algernon Talmage',
  'Reuben Tam',
  'Rufino Tamayo',
  'Tanaka Isson',
  'Tang Di',
  'Tang Yifen',
  'Tang Yin',
  'Josefina Tanganelli Plana',
  'Yves Tanguy',
  'Tani Bunchō',
  'Henry O. Tanner',
  'Dorothea Tanning',
  'Tanomura Chikuden',
  'Tan Ting-pho',
  'Michel Tapié',
  'Antoni Tàpies',
  'Caterina Tarabotti',
  'Edmund Charles Tarbell',
  'Alain Tasso',
  'Vladimir Tatlin',
  'Jules Tavernier',
  'Tawaraya Sōtatsu',
  'Alasdair Grant Taylor',
  'Daniel Taylor',
  'Eric Taylor',
  'Persis Goodale Thurston Taylor',
  'Shirley Teed',
  'Afewerk Tekle',
  'Abraham den Tempel',
  'Carpoforo Tencalla',
  'Jan Tengnagel',
  'David Teniers',
  'David Teniers III',
  'Jason Teraoka',
  'Hendrick Terbrugghen',
  'Pietro Testa',
  'Wlodzimierz Tetmajer',
  'Mór Than',
  'Frits Thaulow',
  'Abbott Handerson Thayer',
  'Jan Theuninck',
  'Wayne Thiebaud',
  'Alma Thomas',
  'Brian Thomas',
  'Robert Thomas',
  'André Thomkins',
  'Bob Thompson',
  'Ernest Heber Thompson',
  'Ernst Thoms',
  'Adam Bruce Thomson',
  'Charles Thomson',
  'Tom Thomson',
  'William John Thomson',
  'William Thon',
  'Jens Jørgen Thorsen',
  'Ernő Tibor',
  'Giovanni Battista Tiepolo',
  'Louis Comfort Tiffany',
  'Lajos Tihanyi',
  'Walasse Ting',
  'Tintoretto',
  'James Tissot',
  'Titian',
  'Ettore Tito',
  'Alton Tobey',
  'Mark Tobey',
  'A. R. Middleton Todd',
  'Giuseppe Tominz',
  'Tomioka Tessai',
  'Bradley Walker Tomlin',
  'Hannah Tompkins',
  'Gentile Tondino',
  'Stanisław Tondos',
  'Henry Tonks',
  'Jacob Toorenvliet',
  'Rodolphe Töpffer',
  'Torii Kiyomasu',
  'Torii Kiyomasu II',
  'Torii Kiyomitsu',
  'Torii Kiyomoto',
  'Torii Kiyonaga',
  'Torii Kiyonobu I',
  'Toriyama Sekien',
  'Þórarinn B. Þorláksson',
  'Veikko Törmänen',
  'János Tornyai',
  'Joaquín Torres García',
  'Tosa Mitsunobu',
  'Tosa Mitsuoki',
  'Henri de Toulouse-Lautrec',
  'Pierre Toutain-Dorbec',
  'Toyen',
  'Toyohara Chikanobu',
  'Toyohara Kunichika',
  'Friedrich Traffelet',
  'Kurt Trampedach',
  'Bill Traylor',
  'Henric Trenk',
  'Vladimir Tretchikoff',
  'Carlos Trillo Name',
  'J. W. Tristram',
  'Vasily Andreevich Tropinin',
  'Clovis Trouille',
  'Jean-François de Troy',
  'Wilhelm Trübner',
  'John Trumbull',
  'Ivan Trush',
  'Tsuchida Bakusen',
  'Tsuji Kakō',
  'Tsukioka Yoshitoshi',
  'Israel Tsvaygenbaum',
  'Werner Tübke',
  'Tony Tuckson',
  'Thomas Tudor',
  'Henry Scott Tuke',
  'Mym Tuma',
  'Bernt Tunold',
  'John Doman Turner',
  'Glennray Tutor',
  'Laurits Tuxen',
  'Luc Tuymans',
  'John Henry Twachtman',
  'Stanley Twardowicz',
  'William Twigg-Smith',
  'Cy Twombly',
  'Dwight William Tryon',
  'Ralph Burke Tyree',
  'Raoul Ubac',
  'Paolo Uccello',
  'Aguri Uchida',
  'Géza Udvary',
  'Uemura Shōen',
  'Euan Uglow',
  'Fritz von Uhde',
  'Jacob der Ulft',
  'Ryuzaburo Umehara',
  'Unkoku Togan',
  'Michelangelo Unterberger',
  'Uragami Gyokudō',
  'Modest Urgell',
  'Federico Uribe',
  'Jenaro de Urrutia Olaran',
  'Lesser Ury',
  'Simon Ushakov',
  'Utagawa Hirokage',
  'Utagawa Hiroshige II',
  'Utagawa Kunimasa',
  'Utagawa Kunimasu',
  'Utagawa Kunisada',
  'Utagawa Kunisada II',
  'Utagawa Kunisada III',
  'Utagawa Kuniyoshi',
  'Utagawa Toyoharu',
  'Utagawa Toyokuni',
  'Utagawa Yoshiiku',
  'Utagawa Yoshitaki',
  'Utagawa Yoshitora',
  'Utagawa Yoshitsuya',
  'Jacob Utrecht',
  'Maurice Utrillo',
  'Moses Uyttenbroeck',
  'Dechko Uzunov',
  'Charles Uzzell-Edwards',
  'Perin del Vaga',
  'Aramenta Dianthe Vail',
  'Lajos Vajda',
  'Suzanne Valadon',
  'Víctor Manuel García Valdés',
  'János Valentiny',
  'Nina Petrovna Valetova',
  'Pierre Adolphe Valette',
  'Leo Valledor',
  'Félix Vallotton',
  'Louis Valtat',
  'Anthony Dyck',
  'Vincent Gogh',
  'Maksimilijan Vanka',
  'Bernard Orley',
  'Raja Ravi Varma',
  'Kazys Varnelis',
  'Remedios Varo',
  'Vladimír Vašíček',
  'Fyodor Vasilyev',
  'Apollinary Vasnetsov',
  'Viktor Vasnetsov',
  'Marie Vassilieff',
  'György Vastagh',
  'Serhii Vasylkivsky',
  'János Vaszary',
  'Gee Vaucher',
  'Alonso Vázquez',
  'Philipp Veit',
  'Verónica Ruiz de Velasco',
  'Diego Velázquez',
  'Jorge Velarde',
  'Adriaen de Velde',
  'Esaias de Velde',
  'Henry de Velde',
  'Petrus der Velden',
  'Alexey Venetsianov',
  'Adriaen de Venne',
  'Cornelis Verbeeck',
  'Dionisio Baixeras Verdaguer',
  'Louis Mathieu Verdilhan',
  'Vasily Vereshchagin',
  'Fernand Verhaegen',
  'Jan Verkolje',
  'Johannes Vermeer',
  'Frederik Vermehren',
  'Jan Cornelisz Vermeyen',
  'Horace Vernet',
  'Paolo Veronese',
  'Andrea del Verrocchio',
  'Johannes Cornelisz Verspronck',
  'Jan Victors',
  'Joseph-Marie Vien',
  'Maria Helena Vieira da Silva',
  'Egon von Vietinghoff',
  'Aníbal Villacís',
  'Juan Villafuerte',
  'Jacques Villon',
  'Romano Vio',
  'Eliseu Visconti',
  'Ivan Yakovlevich Vishnyakov',
  'Claes Jansz. Visscher',
  'Keraca Visulčeva',
  'Oswaldo Viteri',
  'Bartolomeo Vivarini',
  'Boris Vladimirski',
  'Simon de Vlieger',
  'Hendrick Cornelisz. Vliet',
  'Willem der Vliet',
  'Zygmunt Vogel',
  'Karl Völker',
  'Alfredo Volpi',
  'Robert William Vonnoh',
  'Clark Voorhees',
  'Lucas Vorsterman',
  'Wolf Vostell',
  'Simon Vouet',
  'Sebastian Vrancx',
  'Abraham de Vries',
  'Roelof Vries',
  'Cornelisz Hendriksz Vroom',
  'Hendrick Cornelisz Vroom',
  'Mikhail Vrubel',
  'Édouard Vuillard',
  'Beta Vukanović',
  'Marion Wachtel',
  'Yoshihiko Wada',
  'Alfons Walde',
  'Hilda Annetta Walker',
  'Karl Walser',
  'Edward Wadsworth',
  'Lionel Walden',
  'Ada Hill Walker',
  'Zygmunt Waliszewski',
  'Ottilie Maclaren Wallace',
  'Alfred Wallis',
  'Henry Wallis',
  'Kathleen Walne',
  'Cecile Walton',
  'Edward Arthur Walton',
  'Wang Duo',
  'Wang E',
  'Wang Fu',
  'Wang Guxiang',
  'Wang Hui',
  'Wang Jian',
  'Wang Lü',
  'Wang Meng',
  'Wang Mian',
  'Wang Shimin',
  'Wang Shishen',
  'Victor Wang',
  'Wang Wei',
  'Wang Wu',
  'Wang Ximeng',
  'Wang Yi',
  'Wang Yuan',
  'Wang Yuanqi',
  'Wang Zhenpeng',
  'Wang Zhongyu',
  'Walenty Wańkowicz',
  'Joan Warburton',
  'Everett Warner',
  'Laura Wheeler Waring',
  'Andy Warhol',
  'Watanabe Kazan',
  'Sadao Watanabe',
  'Watanabe Shōtei',
  'Shōzaburō Watanabe',
  'John William Waterhouse',
  'Billie Waters',
  'Alison Watt',
  'George Fiddes Watt',
  'James Cromar Watt',
  'Jean Antoine Watteau',
  'George Frederic Watts',
  'Henrik Weber',
  'Stokely Webster',
  'Jan Baptist Weenix',
  'Gerda Wegener',
  'Carel Weight',
  'Susan Weil',
  'J. Alden Weir',
  'John Ellsworth Weis',
  'Samuel Washington Weis',
  'Jerry Weiss',
  'Wojciech Weiss',
  'Neil Welliver',
  'Margaret Bruce Wells',
  'Albert Welti',
  'Albert J. Welti',
  'Wen Boren',
  'Wen Jia',
  'Wen Tong',
  'Wen Zhengming',
  'Wen Zhenheng',
  'Kurt Wenner',
  'Marianne von Werefkin',
  'Adriaen der Werff',
  'Pieter der Werff',
  'Joseph Werner',
  'Adolf Ulric Wertmüller',
  'Tom Wesselmann',
  'Benjamin West',
  'William Edward West',
  'Konstantin Westchilov',
  'Katerina Wilczynski',
  'Jacob Willemszoon de Wet',
  'Rogier der Weyden',
  'Edith Grace Wheatley',
  'John Laviers Wheatley',
  'Bessie Wheeler',
  'James McNeill Whistler',
  'Dee Whitcomb',
  'Brett Whiteley',
  'Félix Bódog Widder',
  'Charmion von Wiegand',
  'Hans Beat Wieland',
  'Cornelis Claesz Wieringen',
  'Antoine Wiertz',
  'Bjørn Wiinblad',
  'Thomas Wijck',
  'Jan Wijnants',
  'Jerry Wilkerson',
  'David Wilkie',
  'Abraham Willaerts',
  'Adam Willaerts',
  'Adolphe Willette',
  'Bedwyr Williams',
  'Charles Williams',
  'Christopher Williams',
  'David Dougal Williams',
  'Hugh William Williams',
  'Ivor Williams',
  'Kyffin Williams',
  'Neil Williams',
  'Penry Williams',
  'Harold Sandys Williamson',
  'Carel Willink',
  'Thornton Willis',
  'Jens Ferdinand Willumsen',
  'Donald Roller Wilson',
  'Richard Wilson',
  'Franz Xaver Winterhalter',
  'Sylvia Wishart',
  'Stanisław Witkiewicz',
  'Stanisław Ignacy Witkiewicz',
  'Emanuel de Witte',
  'Caspar Wittel',
  'Uwe Wittwer',
  'Emanuel Witz',
  'Konrad Witz',
  'Henry Otto Wix',
  'David Wojnarowicz',
  'Kazimierz Wojniakowski',
  'Witold Wojtkiewicz',
  'Caspar Wolf',
  'Adolf Wölfli',
  'John Wollaston',
  'John Wonnacott',
  'Toss Woollaston',
  'Christopher Wood',
  'Grant Wood',
  'Leona Wood',
  'Ursula Wood',
  'Charles H. Woodbury',
  'William Woodward',
  'Henry Woods',
  'Thomas Frederick Worrall',
  'Troels Wörsel',
  'Philips Wouwerman',
  'Cindy Wright',
  'John Michael Wright',
  'Andrzej Wróblewski',
  'Peter Wtewael',
  'JoWOnder',
  'Wu Bin',
  'Wu Changshuo',
  'Wu Daozi',
  'Wu Guanzhong',
  'Wu Hong',
  'Wu Li',
  'Wu Shixian',
  'Wu Wei',
  'Wu Zhen',
  'Wu Zuoren',
  'Paul Wunderlich',
  'Wuzhun Shifan',
  'Nathan Wyburn',
  'Jan Wyck',
  'Leon Wyczółkowski',
  'Andrew Wyeth',
  'Henriette Wyeth',
  'Jamie Wyeth',
  'N. C. Wyeth',
  'George Wyllie',
  'Stanisław Wyspiański',
  'Juliette Wytsman',
  'Rodolphe Wytsman',
  'Jean Xceron',
  'Xi Gang',
  'Xia Chang',
  'Xia Gui',
  'Xia Shuwen',
  'Xia Yong',
  'Xiang Shengmo',
  'Xiao Yuncong',
  'Xie He',
  'Xie Huan',
  'Xie Shichen',
  'Xie Sun',
  'Xu Beihong',
  'Xu Wei',
  'Xu Xi',
  'Xuande Emperor',
  'Ahmed Yacoubi',
  'Saul Yaffie',
  'Yamagata Hiro',
  'Yamaguchi Kayo',
  'Yamamoto Shōun',
  'Taro Yamamoto',
  'Kiyoshi Yamashita',
  'Tsuruko Yamazaki',
  'Yan Hui',
  'Yan Liben',
  'Yanagawa Nobusada',
  'Yanagawa Shigenobu',
  'Yang Borun',
  'Yang Buzhi',
  'Yang Jin',
  'Yang Weizhen',
  'Yao Tingmei',
  'Nikolai Yaroshenko',
  'Yukihiko Yasuda',
  'Sōtarō Yasui',
  'Ye Xin',
  'Jack Butler Yeats',
  'William Yellowlees',
  'Mary Agnes Yerkes',
  'Yi Inmun',
  'Yi Insang',
  'Yi Jaegwan',
  'Yi Yuanji',
  'Delmer J. Yoakum',
  'Joseph Yoakum',
  'Yokoyama Taikan',
  'Tetsugoro Yorozu',
  'Yosa Buson',
  'Jiro Yoshihara',
  'Chizuko Yoshida',
  'Yoshida Hanbei',
  'Hiroshi Yoshida',
  'Tōshi Yoshida',
  'Yu Zhiding',
  'Yuan Jiang',
  'Yuan Yao',
  'Yue Minjun',
  'Yun Du-seo',
  'Yun-Fei Ji',
  'Yun Shouping',
  'Konstantin Yuon',
  'Lisa Yuskavage',
  'Ossip Zadkine',
  'Kristian Zahrtmann',
  'Eugeniusz Żak',
  'Marcin Zaleski',
  'Domenico Zampieri',
  'Federico Zandomeneghi',
  'Zao Wou-Ki',
  'Harriet Zeitlin',
  'Wolfgang Zelmer',
  'Zeng Jing',
  'Karl Zerbe',
  'Zha Shibiao',
  'Zhan Ziqian',
  'Zhang Han',
  'Zhang Lu',
  'Zhang Sengyao',
  'Zhang Shengwen',
  'Zhang Shunzi',
  'Zhang Shuqi',
  'Zhang Wo',
  'Zhang Xiaogang',
  'Zhang Xuan',
  'Zhang Yan',
  'Zhang Yan',
  'Zhang Yin',
  'Zhang Zeduan',
  'Zhang Zongcang',
  'Zhao Mengfu',
  'Zhao Yong',
  'Zhao Yuan',
  'Zhao Zhiqian',
  'Zhao Zuo',
  'Hristofor Zhefarovich',
  'Zheng Xie',
  'Zhou Chen',
  'Zhou Fang',
  'Zhou Jichang',
  'Zhou Shuxi',
  'Zhou Wenjing',
  'Zhu Da',
  'Zhu Derun',
  'Stanislav Zhukovsky',
  'Mihály Zichy',
  'Félix Ziem',
  'Adrian Zingg',
  'Franciszek Żmurko',
  'Ernest Zobole',
  'Johann Zoffany',
  'Zahari Zograf',
  'Marguerite Zorach',
  'William Zorach',
  'Anders Zorn',
  'Zou Yigui',
  'Zou Zhe',
  'Larry Zox',
  'Jan Zrzavý',
  'Francesco Zuccarelli',
  'Federico Zuccari',
  'Ignacio Zuloaga',
  'Robert Zünd',
  'Francisco Zúñiga',
  'Francisco de Zurbarán',
  'SAGANS',
  'Andro.Meta',
  'Dsfefef',
  'Louis Wain',
  'Olivia De Berardinis',
  'Olivia',
  'peter mohrbacher',
  'ilya kuvshinov',
  'hajime sorayama',
  'wayne barlowe',
  'boris vallejo',
  'aaron horkey',
  'gaston bussiere',
  'craig mullins',
  'wlop',
  'ismail inceoglu',
  'cory loftis',
  'akihiko yoshida',
  'james gilleard',
  'atey ghailan',
  'makoto shinkai',
  'goro fujita',
  'peter mohrbacher',
  'greg rutkowski',
  'artgerm',
  'asaf hanuka',
  'caspar david friedrich',
  'charlie bowater',
  'david mann',
  'edward munch',
  'frida kahlo',
  'hassan mossoudy',
  'hayao miyazaki',
  'hazem taham',
  'H.R. Giger',
  'John Kenn mortense',
  'jose tapiro Y baro',
  'karol bak',
  'Katsushika hokusai',
  'Kim Tschng Yeul',
  'Ko Young Hoon',
  'Lemma Guya',
  'Lisa Frank',
  'Ralph Steadman',
  'Tony Diterlizzi',
  'Victto Ngai',
  'Wanda Gag',
  'Zeng Fanzhi',
  'Serge Birault',
  'A.J.Casson',
  'Aaron Douglas',
  'Aaron Horkey',
  'Aaron Jasinski',
  'Aaron Siskind',
  'Abbott Fuller Graves',
  'Abbott Handerson Thayer',
  'Abdel Hadi Al Gazzar',
  'Abed Abdi',
  'Abigail Larson',
  'Abraham Mintchine',
  'Abraham Pether',
  'Abram Efimovich Arkhipov',
  'Adam Elsheimer',
  'Adam Hughes',
  'Adam Martinakis',
  'Adam Paquette',
  'Adi Granov',
  'Adolf Hirémy Hirschl',
  'Adolf Hirémy-Hirschl',
  'Adolph Gottlieb',
  'Adolph Menzel',
  'Adonna Khare',
  'Adriaen Ostade',
  'Adriaen Outrecht',
  'Adrian Donoghue',
  'Adrian Ghenie',
  'Adrian Paul Allinson',
  'Adrian Smith',
  'Adrian Tomine',
  'Adrianus Eversen',
  'Afarin Sajedi',
  'Affandi',
  'Aggi Erguna',
  'Agnes Cecile',
  'Agnes Lawrence Pelton',
  'Agnes Martin',
  'Agostino Arrivabene',
  'Agostino Tassi',
  'Ai Weiwei',
  'Ai Yazawa',
  'Akihiko Yoshida',
  'Akira Toriyama',
  'Akos Major',
  'Akseli Gallen-Kallela',
  'Al Capp',
  'Al Feldstein',
  'Al Williamson',
  'Alain Laboile',
  'Alan Bean',
  'Alan Davis',
  'Alan Kenny',
  'Alan Lee',
  'Alan Moore',
  'Alan Parry',
  'Alan Schaller',
  'Alasdair McLellan',
  'Alastair Magnaldo',
  'Alayna Lemmer',
  'Albert Benois',
  'Albert Bierstadt',
  'Albert Bloch',
  'Albert Dubois-Pillet',
  'Albert Eckhout',
  'Albert Edelfelt',
  'Albert Gleizes',
  'Albert Goodwin',
  'Albert Joseph Moore',
  'Albert Koetsier',
  'Albert Kotin',
  'Albert Lynch',
  'Albert Marquet',
  'Albert Pinkham Ryder',
  'Albert Robida',
  'Albert Servaes',
  'Albert Tucker',
  'Albert Watson',
  'Alberto Biasi',
  'Alberto Burri',
  'Alberto Giacometti',
  'Alberto Magnelli',
  'Alberto Seveso',
  'Alberto Sughi',
  'Alberto Vargas',
  'Albrecht Anker',
  'Albrecht Durer',
  'Alec Soth',
  'Alejandro Burdisio',
  'Alejandro Jodorowsky',
  'Aleksey Savrasov',
  'Aleksi Briclot',
  'Alena Aenami',
  'Alessandro Allori',
  'Alessandro Barbucci',
  'Alessandro Gottardo',
  'Alessio Albi',
  'Alex Alemany',
  'Alex Andreev',
  'Alex Colville',
  'Alex Figini',
  'Alex Garant',
  'Alex Grey',
  'Alex Gross',
  'Alex Hirsch',
  'Alex Horley',
  'Alex Howitt',
  'Alex Katz',
  'Alex Maleev',
  'Alex Petruk',
  'Alex Prager',
  'Alex Ross',
  'Alex Russell Flint',
  'Alex Schomburg',
  'Alex Timmermans',
  'Alex Toth',
  'Alexander Archipenko',
  'Alexander Bogen',
  'Alexander Fedosav',
  'Alexander Jansson',
  'Alexander Kanoldt',
  'Alexander McQueen',
  'Alexander Millar',
  'Alexander Milne Calder',
  'Alexandr Averin',
  'Alexandre Antigna',
  'Alexandre Benois',
  'Alexandre Cabanel',
  'Alexandre Calame',
  'Alexandre Jacovleff',
  'Alexandre-Evariste Fragonard',
  'Alexandre-Évariste Fragonard',
  'Alexei Harlamoff',
  'Alexej von Jawlensky',
  'Alexey Kurbatov',
  'Alexis Gritchenko',
  'Alfred Augustus Glendening',
  'Alfred Cheney Johnston',
  'Alfred Eisenstaedt',
  'Alfred Guillou',
  'Alfred Heber Hutty',
  'Alfred Henry Maurer',
  'Alfred Kelsner',
  'Alfred Kubin',
  'Alfred Munnings',
  'Alfred Parsons',
  'Alfred Sisley',
  'Alfred Stevens',
  'Alfredo Jaar',
  'Algernon Blackwood',
  'Alice Bailly',
  'Alice Neel',
  'Alice Pasquini',
  'Alice Rahon',
  'Alison Bechdel',
  'Aliza Razell',
  'Allen Williams',
  'Allie Brosh',
  'Allison Bechdel',
  'Alma Thomas',
  'Alois Arnegger',
  'Alphonse Mucha',
  'Alphonse Osbert',
  'Alpo Jaakola',
  'Alson Skinner Clark',
  'Alvar Aalto',
  'Alvaro Siza',
  'Alvin Langdon Coburn',
  'Alyssa Monks',
  'Amadeo De Souza-Cardoso',
  'Amadou Opa Bathily',
  'Amanda Clark',
  'Amanda Sage',
  'Amandine Ray',
  'Ambrosius Benson',
  'Ambrosius Bosschaert',
  'Amédée Guillemin',
  'Amedee Ozenfant',
  'Amedeo Modigliani',
  'Amiet Cuno',
  'Aminollah Rezaei',
  'Amir Zand',
  'Amy Earles',
  'Amy Judd',
  'Amy Sillman',
  'Anato Finnstark',
  'Anatoly Metlan',
  'Anders Zorn',
  'Ando Fuchs',
  'Andre De Dienes',
  'Andre Derain',
  'Andre Kertesz',
  'Andre Kohn',
  'Andre Leblanc',
  'André Lhote',
  'André Masson',
  'Andre Norton',
  'Andre-Charles Boulle',
  'Andrea Kowch',
  'Andrea Mantegna',
  'Andreas Achenbach',
  'Andreas Franke',
  'Andreas Gursky',
  'Andreas Levers',
  'Andreas Rocha',
  'Andreas Vesalius',
  'Andrei Arinouchkine',
  'Andréi Arinouchkine',
  'Andrei Markin',
  'Andrew Atroshenko',
  'Andrew Ferez',
  'Andrew Hem',
  'Andrew Macara',
  'Andrew Robinson',
  'Andrew Wyeth',
  'Andrey Remnev',
  'Android Jones',
  'Andrzej Sykut',
  'Andy Fairhurst',
  'Andy Goldsworthy',
  'Andy Kehoe',
  'Andy Warhol',
  'Angela Barrett',
  'Angela Sung',
  'Angus McKie',
  'Anish Kapoor',
  'Anita Malfatti',
  'Anja Millen',
  'Anja Percival',
  'Anka Zhuravleva',
  'Ann Stookey',
  'Anna Ancher',
  'Anna Elena Balbusso',
  'Anna Bocek',
  'Anna Dittmann',
  'Anna Razumovskaya',
  'Anne Bachelier',
  'Anne Brigman',
  'Anne Dewailly',
  'Anne Mccaffrey',
  'Anne Packard',
  'Anne Rothenstein',
  'Anne Stokes',
  'Anne Sudworth',
  'Anne Truitt',
  'Anne-Louis Girodet',
  'Anni Albers',
  'Annibale Carracci',
  'Annick Bouvattier',
  'Annie Soudain',
  'Annie Swynnerton',
  'Ansel Adams',
  'Anselm Kiefer',
  'Antanas Sutkus',
  'Anthony Gerace',
  'Anthony Thieme',
  'Anthony Dyck',
  'Anto Carte',
  'Antoine Blanchard',
  'Antoine Verney-Carron',
  'Anton Corbijn',
  'Anton Domenico Gabbiani',
  'Anton Fadeev',
  'Anton Mauve',
  'Anton Otto Fischer',
  'Anton Pieck',
  'Anton Raphael Mengs',
  'Anton Semenov',
  'Antonello da Messina',
  'Antoni Gaudi',
  'Antonio Canova',
  'Antonio Donghi',
  'Antonio J. Manzanedo',
  'Antonio Mancini',
  'Antonio Mora',
  'Antonio Roybal',
  'Antony Gormley',
  'Apollinary Vasnetsov',
  'Apollonia Saintclair',
  'Aquirax Uno',
  'Archibald Thorburn',
  'Aries Moross',
  'Arik Brauer',
  'Aristarkh Lentulov',
  'Aristide Maillol',
  'Arkhyp Kuindzhi',
  'Arman Manookian',
  'Armand Guillaumin',
  'Armand Point',
  'Arnold Bocklin',
  'Arnold Böcklin',
  'Arnold Schoenberg',
  'Aron Demetz',
  'Aron Wiesenfeld',
  'Arshile Gorky',
  'Fitzpatrick',
  'Frahm',
  'Spiegelman',
  'Artem Chebokha',
  'Artemisia Gentileschi',
  'Artgerm',
  'Arthur Adams',
  'Arthur Boyd',
  'Arthur Dove',
  'Arthur Garfield Dove',
  'Arthur Hacker',
  'Arthur Hughes',
  'Arthur Lismer',
  'Arthur Rackham',
  'Arthur Radebaugh',
  'Arthur Sarnoff',
  'Arthur Stanley Wilkinson',
  'Arthur Streeton',
  'Arthur Tress',
  'Arthur Wardle',
  'Artur Bordalo',
  'Arturo Souto',
  'Artus Scheiner',
  'Ary Scheffer',
  'Asaf Hanuka',
  'Asger Jorn',
  'Asher Brown Durand',
  'Ashley Willerton',
  'Ashley Wood',
  'Atay Ghailan',
  'Atelier Olschinsky',
  'Atey Ghailan',
  'Aubrey Beardsley',
  'Audrey Kawasaki',
  'August Friedrich Schenck',
  'August Macke',
  'August Sander',
  'August von Pettenkofen',
  'Auguste Herbin',
  'Auguste Mambour',
  'Auguste Toulmouche',
  'Augustus Edwin Mulready',
  'Augustus Jansson',
  'Augustus John',
  'Austin Osman Spare',
  'Axel Törneman',
  'Ayami Kojima',
  'Ayan Nag',
  'Aykut Aydogdu',
  'Bakemono Zukushi',
  'Balthus',
  'Banksy',
  'Barbara Hepworth',
  'Barbara Kruger',
  'Barbara Stauffacher Solomon',
  'Barbara Takenaga',
  'Barclay Shaw',
  'Barkley L. Hendricks',
  'Barnett Newman',
  'Barry McGee',
  'Barry Windsor Smith',
  'Bart Sears',
  'Bartolome Esteban Murillo',
  'Basil Gogos',
  'Bastien Lecouffe-Deharme',
  'Bayard Wu',
  'Beatrix Potter',
  'Beauford Delaney',
  'Becky Cloonan',
  'Beeple',
  'Bella Kotak',
  'Ben Aronson',
  'Ben Goossens',
  'Ben Hatke',
  'Ben Nicholson',
  'Ben Quilty',
  'Ben Shahn',
  'Ben Templesmith',
  'Ben Wooten',
  'Benedetto Caliari',
  'Benedick Bana',
  'Benoit B. Mandelbrot',
  'Berend Strik',
  'Bernard Aubertin',
  'Bernard Buffet',
  'Bernardo Bellotto',
  'Bernardo Strozzi',
  'Berndnaut Smilde',
  'Bernie Wrightson',
  'Bert Hardy',
  'Bert Stern',
  'Berthe Morisot',
  'Bertil Nilsson',
  'Bess Hamiti',
  'Beth Conklin',
  'Bettina Rheims',
  'Bhupen Khakhar',
  'Bijou Karman',
  'Bill Brandt',
  'Bill Brauer',
  'Bill Carman',
  'Bill Durgin',
  'Bill Gekas',
  'Bill Henson',
  'Bill Jacklin',
  'Bill Medcalf',
  'Bill Sienkiewicz',
  'Bill Traylor',
  'Bill Viola',
  'Bill Ward',
  'Bill Watterson',
  'Billy Childish',
  'Bjarke Ingels',
  'Blek Le Rat',
  'Bo Bartlett',
  'Bo Chen',
  'Bob Byerley',
  'Bob Eggleton',
  'Bob Ross',
  'Bojan Jevtic',
  'Bojan Koturanovic',
  'Bordalo II',
  'Boris Grigoriev',
  'Boris Groh',
  'Boris Kustodiev',
  'Boris Vallejo',
  'Botero',
  'Brad Kunkle',
  'Brad Rigney',
  'Brandon Mably',
  'Brandon Woelfel',
  'Brenda Zlamany',
  'Brent Cotton',
  'Brent Heighton',
  'Brett Weston',
  'Brett Whiteley',
  'Brian Bolland',
  'Brian Despain',
  'Brian Froud',
  'Brian K. Vaughan',
  'Brian Kesinger',
  'Brian M. Viveros',
  'Brian Mashburn',
  'Brian Oldham',
  'Brian Stelfreeze',
  'Brian Sum',
  'Briana Mora',
  'Brice Marden',
  'Bridget Bate Tichenor',
  'Bridget Riley',
  'Briton Rivière',
  'Brooke DiDonato',
  'Brooke Shaden',
  'Brothers Grimm',
  'Brothers Hildebrandt',
  'Bruce Coville',
  'Bruce Munro',
  'Bruce Nauman',
  'Bruce Pennington',
  'Bruce Timm',
  'Bruno Catalano',
  'Bruno Munari',
  'Bruno Walpoth',
  'Bryan Hitch',
  'Burt Glinn',
  'Butcher Billy',
  'C. R. W. Nevinson',
  'Cagnaccio Di San Pietro',
  'Cai Guo-Qiang',
  'Callie Fink',
  'Camille Claudel',
  'Camille Corot',
  'Camille Pissarro',
  'Camille Vivier',
  'Camille Walala',
  'Camille-Pierre Pambu Bodo',
  'Canaletto',
  'Candido Portinari',
  'Caras Ionut',
  'Carel Willink',
  'Carl Barks',
  'Carl Eugen Keel',
  'Carl Gustav Carus',
  'Carl Holsoe',
  'Carl Kleiner',
  'Carl Larsson',
  'Carl Moll',
  'Carl Rungius',
  'Carl Spitzweg',
  'Carlo Bocchio',
  'Carlo Crivelli',
  'Carlo Dolci',
  'Carlo Scarpa',
  'Carlos Cruz-Diez',
  'Carlos Schwabe',
  'Carmen Saldana',
  'Carne Griffiths',
  'Carolina Herrera',
  'Carolyn Blish',
  'Carrie Ann Baade',
  'Carrie Graber',
  'Carrie Mae Weems',
  'Carson Grubaugh',
  'Carsten Holler',
  'Carsten Meyerdierks',
  'Casey Baugh',
  'Casey Childs',
  'Casey Weldon',
  'Caspar David Friedrich',
  'Cassius Marcellus Coolidge',
  'Catherine Hyde',
  'Catherine Nolin',
  'Cathy Wilkes',
  'Catrin Welz-Stein',
  'Cecil Beaton',
  'Cecilia Beaux',
  'Cecily Brown',
  'Cedric Peyravernay',
  'Cedric Seaut',
  'Cerith Wyn Evans',
  'CFA Voysey',
  'Chad Knight',
  'Chaïm Soutine',
  'Chantal Joffe',
  'Charles Addams',
  'Charles Angrand',
  'Charles Blackman',
  'Charles Burns',
  'Charles Camoin',
  'Charles Courtney Curran',
  'Charles Dana Gibson',
  'Charles Demuth',
  'Charles Dwyer',
  'Charles E. Burchfield',
  'Charles Ellison',
  'Charles Filiger',
  'Charles Ginner',
  'Charles Gwathmey',
  'Charles Le Brun',
  'Charles Liu',
  'Charles Maurice Detmold',
  'Charles Mellin',
  'Charles Philipon',
  'Charles Reiffel',
  'Charles Rennie Mackintosh',
  'Charles Robinson',
  'Charles Schridde',
  'Charles Schulz',
  'Charles Sheeler',
  'Charles Spencelayh',
  'Charles Tunnicliffe',
  'Charles Vess',
  'Charles Victor Thirion',
  'Charles Willson Peale',
  'Charles Wysocki',
  'Charles-Amable Lenoir',
  'Charles-Francois Daubigny',
  'Charley Harper',
  'Charlie Bowater',
  'Charline von Heyl',
  'Charly Amani',
  'Chase Stone',
  'Chen Zhen',
  'Chesley Bonestell',
  'Chie Yoshii',
  'Chiharu Shiota',
  'Chiho Aoshima',
  'Childe Hassam',
  'Ching Yeh',
  'Chip Zdarsky',
  'Chris Bachalo',
  'Chris Claremont',
  'Chris Cold',
  'Chris Cunningham',
  'Chris Dyer',
  'Chris Foss',
  'Chris Friel',
  'Chris LaBrooy',
  'Chris Leib',
  'Chris Mars',
  'Chris Moore',
  'Chris Ofili',
  'Chris Riddell',
  'Chris Samnee',
  'Chris Saunders',
  'Chris Turnham',
  'Chris Uminga',
  'Chris Allsburg',
  'Chris Ware',
  'Christian Boltanski',
  'Christian Dimitrov',
  'Christian Grajewski',
  'Christian Griepenkerl',
  'Christian Schad',
  'Christian Schloe',
  'Christian Wilhelm Allers',
  'Christine Ellger',
  'Christoffer Relander',
  'Christophe Jacrot',
  'Christophe Staelens',
  'Christophe Vacher',
  'Christopher Balaskas',
  'Christopher Jin Baron',
  'Christopher Ryan McKenney',
  'Christopher Wool',
  'Chuah Thean Teng',
  'Chuanxing Zeng',
  'Chuck Close',
  'Chung Shek',
  'Cicely Mary Barker',
  'Cildo Meireles',
  'Cindy Sherman',
  'Claes Oldenburg',
  'Claire Hummel',
  'Clara Ledesma',
  'Clara Miller Burd',
  'Clara Peeters',
  'Clarence Gagnon',
  'Clarence Holbrook Carter',
  'Claude Cahun',
  'Claude Lefèbvre',
  'Claude Lorrain',
  'Claude Melan',
  'Claude Monet',
  'Claudia Tremblay',
  'Clay Mann',
  'Clayton Crain',
  'Clemens Ascher',
  'Cleon Peterson',
  'Cliff Chiang',
  'Clifford Coffin',
  'Clint Cearley',
  'Clive Barker',
  'Clive Madgwick',
  'Clovis Trouille',
  'Clyde Caldwell',
  'Clyfford Still',
  'Coby Whitmore',
  'Coles Phillips',
  'Colin Campbell Cooper',
  'Colin Elder',
  'Colin Geller',
  'Conor Harrington',
  'Conrad Roset',
  'Conrad Shawcross',
  'Constantin Brancusi',
  'Constantin Joffe',
  'Cornelis Springer',
  'Cory Arcangel',
  'Cory Loftis',
  'Costa Dvorezky',
  'Craig Davison',
  'Craig McCracken',
  'Craig Mullins',
  'Craig Thompson',
  'Craigie Aitchison',
  'Craola',
  'Cuno Amiet',
  'Cyril Pontet',
  'Cyril Rolando',
  'Daan Roosegaarde',
  'Daido Moriyama',
  'Dain Yoon',
  'Dale Chihuly',
  'Damien Hirst',
  'Dan Colen',
  'Dan Flavin',
  'Dan Hillier',
  'Dan Kelby',
  'Dan Matutina',
  'Dan McPharlin',
  'Dan Mumford',
  'Dan Piraro',
  'Dan Witz',
  'Dana Schutz',
  'Danh Víµ',
  'Danh Vō',
  'Daniel Arsham',
  'Daniel Buren',
  'Daniel Clowes',
  'Daniel F. Gerhartz',
  'Daniel Garber',
  'Daniel Jaems',
  'Daniel Libeskind',
  'Daniel Lieske',
  'Daniel Merriam',
  'Daniel Ridgway Knight',
  'Daniela Uhlig',
  'Daniele Afferni',
  'Danielle Eckhardt',
  'Danny Flynn',
  'Danny Lyon',
  'Danny Roberts',
  'Dante Gabriel Rossetti',
  'Dao Le Trong',
  'Darek Zabrocki',
  'Daria Endresen',
  'Dariusz Klimczak',
  'Dariusz Zawadzki',
  'Darren Bacon',
  'Darwyn Cooke',
  'Daryl Mandryk',
  'Dave Coverly',
  'Dave Dorman',
  'Dave Gibbons',
  'Dave Heath',
  'Dave McKean',
  'Dave Rapoza',
  'Dave Stevens',
  'David A. Hardy',
  'David Aja',
  'David Alfaro Siqueiros',
  'David Aronson',
  'David B. Mattingly',
  'David Bailey',
  'David Bates',
  'David Bomberg',
  'David Bowie',
  'David Brayne',
  'David Brown Milne',
  'David Burdeny',
  'David Burliuk',
  'David Chipperfield',
  'David Choe',
  'David Cronenberg',
  'David Downton',
  'David Driskell',
  'David Finch',
  'David Goldblatt',
  'David Hammons',
  'David Hettinger',
  'David Hockney',
  'David Inshaw',
  'David LaChapelle',
  'David Ligare',
  'David Lynch',
  'David Malkin',
  'David Mann',
  'David McClellan',
  'David Michael Bowers',
  'David Mould',
  'David Nordahl',
  'David Normal',
  'David Palumbo',
  'David Plowden',
  'David Renshaw',
  'David Shrigley',
  'David Sims',
  'David Spriggs',
  'David Tindle',
  'David Tutwiler',
  'David Walker',
  'David Welker',
  'David Wiesner',
  'David Yarrow',
  'Davide Sasselli',
  'Davide Sorrenti',
  'Davis Marc',
  'Davis Stuart',
  'De Es Schwertberger',
  'Dean Cornwell',
  'Dean Ellis',
  'Death Burger',
  'Debbie Criswell',
  'Debbie Fleming Caffery',
  'Deborah Azzopardi',
  'Deborah Turbeville',
  'Dee Nickerson',
  'Deirdre Sullivan-Beeman',
  'Del Kathryn Barton',
  'Delphin Enjolras',
  'Demizu Posuka',
  'Denis Sarazhin',
  'Dennis Stock',
  'Denys Lasdun',
  'Derek Boshier',
  'Derek Gores',
  'Desmond Morris',
  'Diane Arbus',
  'Diane Dillon',
  'Dick Bickenbach',
  'Didier Barra',
  'Didier Lourenço',
  'Diego Dayer',
  'Diego Rivera',
  'Diego Velázquez',
  'Dima Dmitriev',
  'Dimitra Milan',
  'Dimitry Roulland',
  'Dino Valls',
  'Dmitri Danish',
  'Dmitry Kustanovich',
  'Dmitry Spiros',
  'Dmitry Vishnevsky',
  'Do Ho Suh',
  'Dod Procter',
  'Dom Qwek',
  'Don Bergland',
  'Don Blanding',
  'Don Bluth',
  'Don Lawrence',
  'Don Maitz',
  'Don Northrup',
  'Donald Judd',
  'Donald Pass',
  'Donato Giancola',
  'Donna Huanca',
  'Dora Carrington',
  'Dora Maar',
  'Dorina Costras',
  'Dorothea Lange',
  'Dorothea Sharp',
  'Dorothea Tanning',
  'Dorothy Johnstone',
  'Dorothy Lathrop',
  'Doug Aitken',
  'Doug Chiang',
  'Douglas Smith',
  'Dr. Seuss',
  'Drew Struzan',
  'Duffy Sheridan',
  'Duncan Fegredo',
  'Dusan Djukaric',
  'Dustin Nguyen',
  'Duy Huynh',
  'E. H. Shepard',
  'Eadweard Muybridge',
  'Earl Norem',
  'Eastman Johnson',
  'Ebru Sidar',
  'Echo Chernik',
  'Ed Benedict',
  'Ed Binkley',
  'Ed Brubaker',
  'Ed Emshwiller',
  'Ed Freeman',
  'Ed Mell',
  'Ed Myers',
  'Ed Piskor',
  'Ed Roth',
  'Ed Valigursky',
  'Eddie Campbell',
  'Eddie Colla',
  'Eddie Jones',
  'Eddie Mendoza',
  'Edgar Degas',
  'Edgar Maxence',
  'Edith Rimmington',
  'Edmund Dulac',
  'Edmund Leighton',
  'Edmund Tarbell',
  'Edmundo Senatore',
  'Edna Reindel',
  'Edoardo Tresoldi',
  'Edogawa Ranpo',
  'Edouard Manet',
  'Édouard Manet',
  'Edouard Riou',
  'Édouard Riou',
  'Édouard Vuillard',
  'Eduard Cortes',
  'Eduard Gaertner',
  'Eduard Veith',
  'Eduard Wiiralt',
  'Eduardo Kobra',
  'Edvard Munch',
  'Edward Atkinson Hornel',
  'Edward Bawden',
  'Edward Blair Wilkins',
  'Edward Burne-Jones',
  'Edward Cucuel',
  'Edward Gorey',
  'Edward Henry Potthast',
  'Edward Hersey',
  'Edward Hopper',
  'Edward John Poynter',
  'Edward Julius Detmold',
  'Edward Lear',
  'Edward Moran',
  'Edward Okuń',
  'Edward Poynter',
  'Edward Robert Hughes',
  'Edward Seago',
  'Edward Steichen',
  'Edward Wadsworth',
  'Edward Weston',
  'Edwin Austin Abbey',
  'Edwin Deakin',
  'Edwin Henry Landseer',
  'Edwin Lord Weeks',
  'Edwin Wurm',
  'Eero Saarinen',
  'Egon Schiele',
  'Eiichiro Oda',
  'Eiko Ojala',
  'Eileen Agar',
  'Eileen Gray',
  'Eilif Peterssen',
  'Einar Hákonarson',
  'Einer Johansen',
  'Ekaterina Panikanova',
  'El Anatsui',
  'El Greco',
  'El Lissitzky',
  'Elaine de Kooning',
  'Elba Damast',
  'Eleanor Fortescue-Brickdale',
  'Eleanor Vere Boyle',
  'Elena Paraskeva',
  'Elenore Abbott',
  'Elfriede Lohse-Wächtler',
  'Elias Ravanetti',
  'Elihu Vedder',
  'Elina Karimova',
  'Eliott Lilly',
  'Elisa Breton',
  'Elisabeth Sonrel',
  'Élisabeth Vigée Le Brun',
  'Élisabeth-Louise Vigée-Le Brun',
  'Eliseu Visconti',
  'Elizabeth Catlett',
  'Elizabeth Gadd',
  'Elke Vogelsang',
  'Ellen Gallagher',
  'Ellen Jewett',
  'Ellen von Unwerth',
  'Elliot Lilly',
  'Elliott Erwitt',
  'Ellsworth Kelly',
  'Elsa Beskow',
  'Elsa Bleda',
  'Elsie Driggs',
  'Emek Golan',
  'Emerico Imre Toth',
  'Emerson Silva',
  'Emery Hawkins',
  'Emil Alzamora',
  'Emil Carlsen',
  'Emil Ferris',
  'Emil Nolde',
  'Emila Medková',
  'Émile Bernard',
  'Emile Claus',
  'Emile Galle',
  'Émile Gallé',
  'Emilia Wilk',
  'Emiliano Di Cavalcanti',
  'Emiliano Ponzi',
  'Emilio Giuseppe Dossena',
  'Emily Balivet',
  'Emily Carr',
  'Emily Kame Kngwarreye',
  'Emma Geary',
  'Emma Ríos',
  'Emmanuel de Critz',
  'Emmanuel Shiu',
  'Emmanuelle Moureaux',
  'Emmy Bridgwater',
  'Emory Douglas',
  'Enki Bilal',
  'Enoch Bolles',
  'Enrique Tábara',
  'Ephraim Moses Lilien',
  'Eric Canete',
  'Eric Carle',
  'Eric Deschamps',
  'Eric Fischl',
  'Eric Ravilious',
  'Eric Wallis',
  'Eric Zener',
  'Erica Hopper',
  'Erich Heckel',
  'Erik Johansson',
  'Erik Jones',
  'Erin Hanson',
  'Erna Rosenstein',
  'Ernest Crichlow',
  'Ernest Lawson',
  'Ernest Meissonier',
  'Ernest Zacharevic',
  'Ernesto Neto',
  'Ernie Barnes',
  'Ernst Barlach',
  'Ernst Fuchs',
  'Ernst Haas',
  'Ernst Haeckel',
  'Ernst Ludwig Kirchner',
  'Ernst Wilhelm Nay',
  'Erol Otus',
  'Erwin Blumenfeld',
  'Esaias de Velde',
  'Esao Andrews',
  'Esteban Vicente',
  'Esther Rolick',
  'Etel Adnan',
  'Ethan Sciver',
  'Étienne Adolphe Piot',
  'Etienne Hebinger',
  'Étienne Maurice Falconet',
  'Étienne-Louis Boullée',
  'Étienne-Maurice Falconet',
  'Ettore Sottsass',
  'Ettore Tito',
  'Euan Uglow',
  'Eugen Bracht',
  'Eugène Atget',
  'Eugène Boudin',
  'Eugene Delacroix',
  'Eugene Galien-Laloue',
  'Eugène Girardet',
  'Eugène Grasset',
  'Eugene Leroy',
  'Eugene Tertychnyi',
  'Eugene von Guerard',
  'Eustache Le Sueur',
  'Eva Å vankmajerová',
  'Eva Hesse',
  'Eva Rothschild',
  'Eva Švankmajerová',
  'Eve Arnold',
  'Eve Ventrue',
  'Evelyn De Morgan',
  'Evelyn Dunbar',
  'Everett Raymond Kinstler',
  'Everett Shinn',
  'Evgeni Gordiets',
  'Evgeny Lushpin',
  'Ewald Heinrich Rübsaamen',
  'Ewald Rübsamen',
  'Eyvind Earle',
  'Ezra Stoller',
  'F Scott Hess',
  'Fabian Perez',
  'Fabio Hurtado',
  'Fairfield Porter',
  'Faith 47',
  'Faith Ringgold',
  'Fan Ho',
  'Fang Lijun',
  'Farel Dalrymple',
  'Fay Godwin',
  'Fay Helfer',
  'Felice Casorati',
  'Felicia Simion',
  'Felicien Rops',
  'Felipe Pantone',
  'Felix Gonzalez-Torres',
  'Felix Kelly',
  'Félix Labisse',
  'Felix Philipp Kanitz',
  'Felix Vallotton',
  'Fenghua Zhong',
  'Ferdinand du Puigaudeau',
  'Ferdinand Hodler',
  'Ferdinand Keller',
  'Ferdinand Knab',
  'Ferdinand Kessel',
  'Ferenc Pinter',
  'Fern Isabel Coppedge',
  'Fernand Cormon',
  'Fernand Fonssagrives',
  'Fernand Khnopff',
  'Fernand Leger',
  'Fernand Toussaint',
  'Fernando Amorsolo',
  'Fernando Botero',
  'Fernando Herenu',
  'Ferris Plock',
  'Filip Hodas',
  'Filippino Lippi',
  'Filippo Balbi',
  'Filippo Brunelleschi',
  'Fintan Magee',
  'Firmin Baes',
  'Fletcher Sibthorp',
  'Flora Borsi',
  'Florence Harrison',
  'Florian Nicolle',
  'Florianne Becker',
  'Floris Arntzenius',
  'Floris Jespers',
  'Fra Angelico',
  'Frances MacDonald',
  'Francesca Woodman',
  'Francesco Albani',
  'Francesco Borromini',
  'Francesco Clemente',
  'Francesco Guardi',
  'Francesco Hayez',
  'Francesco Solimena',
  'Francine Hove',
  'Francis Bacon',
  'Francis Coates Jones',
  'Francis Davis Millet',
  'Francis Newton Souza',
  'Francis Picabia',
  'Francisco De Goya',
  'Francisco de Zurbaran',
  'Francisco Goya',
  'Francisco Martín',
  'Franciszek Starowieyski',
  'Franck Tacito',
  'Franco Fontana',
  'François Boquet',
  'François Boucher',
  'François De Nomé',
  'François Marcela-Froideval',
  'Francois Schuiten',
  'Françoise Basset',
  'Frank Auerbach',
  'Frank Bramley',
  'Frank Cadogan Cowper',
  'Frank Cho',
  'Frank Frazetta',
  'Frank Gehry',
  'Frank Holl',
  'Frank Lloyd Wright',
  'Frank McCarthy',
  'Frank Miller',
  'Frank Quitely',
  'Frank Stella',
  'Frank Thorne',
  'Frank Tinsley',
  'Frank Weston Benson',
  'Frank Xavier Leyendecker',
  'Franklin Booth',
  'Franklin Carmichael',
  'Frans Floris',
  'Frans Hals',
  'Frans Snyders',
  'František Dvořák',
  'Frantisek Kupka',
  'Franz Kline',
  'Franz Lefler',
  'Franz Marc',
  'Franz Sedlacek',
  'Franz Stuck',
  'Franz Vohwinkel',
  'Franz West',
  'Franz Xaver Winterhalter',
  'Fred Calleri',
  'Fred Stein',
  'Fred Tomaselli',
  'Frederic Bazille',
  'Frederic Church',
  'Frédéric Fiebig',
  'Frederic Remington',
  'Frederick Arthur Bridgman',
  'Frederick Arthur Verner',
  'Frederick Carl Frieseke',
  'Frederick Cayley Robinson',
  'Frederick Goodall',
  'Frederick Judd Waugh',
  'Frederick Lord Leighton',
  'Frederick McCubbin',
  'Frederick Sandys',
  'Frida Kahlo',
  'Friedensreich Hundertwasser',
  'Frieke Janssens',
  'Frits Thaulow',
  'Frits den Berghe',
  'Fritz Bleyl',
  'Fritz Henle',
  'Fritz Scholder',
  'Fujii Yoshitoyo',
  'Fujishima Takeji',
  'Fuyuko Matsui',
  'Gabriel Dawe',
  'Gabriele Münter',
  'Gaetano Pesce',
  'Galan Pang',
  'Gareth Pugh',
  'Gary Larson',
  'Gaston Bussière',
  'Gediminas Pranckevicius',
  'Genndy Tartakovsky',
  'Geof Darrow',
  'Georg Jensen',
  'Georg Karl Pfahler',
  'George Ault',
  'George Cruikshank',
  'George Dionysus Ehret',
  'George Frederic Watts',
  'George Grosz',
  'George Herriman',
  'George Inness',
  'George Lucas',
  'George Luks',
  'George Stubbs',
  'George Tooker',
  'Georges de La Tour',
  'Georges Rouault',
  'Georges Seurat',
  "Georgia O'Keeffe",
  'Gerald Brom',
  'Gerda Wegener',
  'Gerhard Munthe',
  'Gerhard Richter',
  'Gertrude Abercrombie',
  'Giacomo Balla',
  'Gianluca Foli',
  'Gifford Beal',
  'Gil Elvgren',
  'Gilbert Stuart',
  'Gilberto "Soren" Zaragoza',
  'Giorgio De Chirico',
  'Giotto Di Bondone',
  'Giovanni Battista Bracelli',
  'Giovanni Battista Gaulli',
  'Giovanni Battista Piranesi',
  'Giovanni Battista Venanzi',
  'Giovanni da Udina',
  'Giuseppe Arcimboldo',
  'Giuseppe de Nittis',
  'Gjon Mili',
  'Glen Orbik',
  'Glenn Fabry',
  'Gloria Stoll Karn',
  'Go Nagai',
  'Gordon Browne',
  'Gordon Parks',
  'Goro Fujita',
  'Grace Cossington Smith',
  'Grace Popp',
  'Grandma Moses',
  'Grant Wood',
  'Grayson Perry',
  'Greg Girard',
  'Greg Hildebrandt',
  'Greg Rutkowski',
  'Greg Simkins',
  'Gregory Crewdson',
  'Guerrilla Girls',
  'Guido Borelli Da Caluso',
  'Guido Crepax',
  'Guillermo del Toro',
  'Guo Pei',
  'Gustaf Tenggren',
  'Gustav Klimt',
  'Gustave Buchet',
  'Gustave Courbet',
  'Gustave Doré',
  'Gustave Moreau',
  'Gustave de Woestijne',
  'Guy Billout',
  'Gwen John',
  'Gwenda Morgan',
  'H. R. (Hans Ruedi) Giger',
  'H. R. Giger',
  'H.P. Lovecraft',
  'Haddon Sundblom',
  'Hajime Sorayama',
  'Hal Foster',
  'Hale Woodruff',
  'Hanna-Barbera',
  'Hannah Hoch',
  'Hans Arnold',
  'Hans Baldung',
  'Hans Baluschek',
  'Hans Bellmer',
  'Harold McCauley',
  'Haroon Mirza',
  'Harriet Backer',
  'Harry Clarke',
  'Hasui Kawase',
  'Hayao Miyazaki',
  'Hayv Kahraman',
  'Hein Gorny',
  'Heinrich Kley',
  'Heinrich Lefler',
  'Heinz Edelmann',
  'Helen Frankenthaler',
  'Helene Knoop',
  'Helene Schjerfbeck',
  'Helio Oiticica',
  'Helmut Newton',
  'Hendrick Avercamp',
  'Hendrick Cornelisz Vroom',
  'Hendrick Goltzius',
  'Hendrik Kerstens',
  'Henri De Toulouse Lautrec',
  'Henri Fantin Latour',
  'Henri Matisse',
  'Henri Rousseau',
  'Henriette Grindat',
  'Henry Asencio',
  'Henry Fuseli',
  'Henry Moore',
  'Henry Moret',
  'Henry Ossawa Tanner',
  'Henry Raleigh',
  'Herbert List',
  'Herve Groussin',
  'Hervé Guibert',
  'Hethe Srodawa',
  'Hieronymus Bosch',
  'Hikari Shimoda',
  'Hilma AF Klint',
  'Hirohiko Araki',
  'Hiroshi Nagai',
  'Hiroshi Sugimoto',
  'Hiroshi Yoshida',
  'Honor C. Appleton',
  'Honoré Daumier',
  'Hope Gangloff',
  'Horace Vernet',
  'Hou China',
  'Howard Chandler Christy',
  'Howard Finster',
  'Howard Hodgkin',
  'Howard Pyle',
  'Hsiao-Ron Cheng',
  'Hubert Robert',
  'Hugh Ferriss',
  'Hugh Kretschmer',
  'Hundertwasser',
  'Hyacinthe Rigaud',
  'Iain Faulkner',
  'Ian McQue',
  'Ian Miller',
  'Ida Rentoul Outhwaite',
  'Igor Morski',
  'Igor Wolski',
  'Igor Zenin',
  'Ilya Kuvshinov',
  'Ilya Repin',
  'Incarcerated Jerkfaces',
  'Ingrid Baars',
  'Inio Asano',
  'Irma Stern',
  'Iryna Yermolova',
  'Isaac Cordal',
  'Isaac Levitan',
  'Ismail Inceoglu',
  'Issac Levitan',
  'Istvan Banyai',
  'Itō Jakuchū',
  'Ivan Aivazovski',
  'Ivan Aivazóvski',
  'Ivan Albright',
  'Ivan Bilibin',
  'Ivan Shishkin',
  'Iwan Baan',
  'J. J. Grandville',
  'J.C. Leyendecker',
  'J.M.W. Turner',
  'Jacek Yerka',
  'Jack Butler Yeats',
  'Jack Davis',
  'Jack Gaughan',
  'Jack Kirby',
  'Jackson Pollock',
  'Jacob Hashimoto',
  'Jacob Lawrence',
  'Jacob Ruisdael',
  'Jacques Le Moyne',
  'Jacques Nathan-Garamond ',
  'Jake Parker',
  'Jakub Rozalski',
  'James Abbott McNeill Whistler',
  'James C Christensen',
  'James Ensor',
  'James Gilleard',
  'James Gillray',
  'James Gurney',
  'James Jean',
  'James Montgomery Flagg',
  'James Paick',
  'James Stokoe',
  'James Thomas Watts',
  'James Tissot',
  'James Turrell',
  'Jamie Baldridge',
  'Jamie Hawkesworth',
  'Jamie Hewlett',
  'Jamie McKelvie',
  'Jamini Roy',
  'Jan Brett',
  'Jan Luyken',
  'Jan Pietersz Saenredam',
  'Jan Eyck',
  'Jane Graverol',
  'Jane Newland',
  'Janek Sedlar',
  'Jasmine Becket-Griffith',
  'Jason A. Engle',
  'Jason Chan',
  'Jason Edmiston',
  'Jasper Johns',
  'Jaume Plensa',
  'Jaya Suberg',
  'JC Leyendecker',
  'Jean Arp',
  'Jean Auguste Dominique Ingres',
  'Jean Bourdichon',
  'Jean Delville',
  'Jean Dubuffet',
  'Jean Fouquet',
  'Jean Giraud',
  'Jean Jullien',
  'Jean Marc Nattier',
  'Jean Metzinger',
  'Jean Nouvel',
  'Jean-Antoine Watteau',
  'Jean-Baptiste Monge',
  'Jean-François Millet',
  'Jean-Honoré Fragonard',
  'Jean-Léon Gérôme',
  'Jean-Louis Prevost',
  'Jean-Michel Basquiat',
  'Jean-Paul Riopelle',
  'Jeanloup Sieff',
  'Jeannette Guichard-Bunel',
  'Jed Henry',
  'Jef Wu',
  'Jeff Easley',
  'Jeff Goldblum',
  'Jeff Kinney',
  'Jeff Koons',
  'Jeff Legg',
  'Jeff Lemire',
  'Jeff Simpson',
  'Jeff Wall',
  'Jeffrey Catherine Jones',
  'Jeffrey Cruz (theCHAMBA)',
  'Jeffrey Smith',
  'Jeffrey T. Larson',
  'Jenny Saville',
  'JennyBird Alcantara',
  'Jeremiah Ketner',
  'Jeremy Geddes',
  'Jeremy Lipking',
  'Jeremy Mann',
  'Jerry Pinkney',
  'Jerry Siegel',
  'Jerzy Duda-Gracz',
  'Jesper Ejsing',
  'Jessica Rossier',
  'Jessica Woulfe',
  'Jessie Willcox Smith',
  'Jhonen Vasquez',
  'Jillian Tamaki',
  'Jim Burns',
  'Jim Davis',
  'Jim Lee',
  'Jim Mahfood',
  'Jim Woodring',
  'Jimmy Ernst',
  'Jimmy Lawlor',
  'Joachim Brohm',
  'Joan Miró',
  'Joan Tuset',
  'Joanna Kustra',
  'Joao Ruas',
  'Joaquín Sorolla',
  'Joe Bowler',
  'Joe De Mers',
  'Joe Fenton',
  'Joe Jusko',
  'Joe Madureira',
  'Joe Webb',
  'Joel Meyerowitz',
  'Joel Sternfeld',
  'Joey Chou',
  'Johann Wolfgang von Goethe',
  'Johannes Itten',
  'Johannes Vermeer',
  'Johannes Voss',
  'Johfra Bosschart',
  'John Anster Fitzgerald',
  'John Atherton',
  'John Atkinson Grimshaw',
  'John Bauer',
  'John Berkey',
  'John Blanche',
  'John Bratby',
  'John Cassaday',
  'John Constable',
  'John Currin',
  'John Duncan',
  'John Frederick Kensett',
  'John Harris',
  'John Howe',
  'John Hoyland',
  'John James Audubon',
  'John Kenn Mortensen',
  'John La Farge',
  'John Lavery',
  'John Martin',
  'John Perceval',
  'John Philip Falter',
  'John Salminen',
  'John Singer Sargent',
  'John Singleton Copley',
  'John Stezaker',
  'John Totleben',
  'John Wayne Gacy',
  'John Whitcomb',
  'John Wilhelm',
  'John William Waterhouse',
  'Jon Klassen',
  'Jon McCoy',
  'Jon Whitcomb',
  'Jordan Grimmer',
  'Jorge Jacinto',
  'Josan Gonzalez',
  'José Clemente Orozco',
  'Josef Albers',
  'Joseph Cornell',
  'Joseph Ducreux',
  'Joseph Lorusso',
  'Joseph Mallord William Turner',
  'Joseph Stella',
  'Josephine Wall',
  'Josh Kao',
  'Josh Keyes',
  'Jovana Rikalo',
  'Józef Mehoffer',
  'Juan Gris',
  'Judy Chicago',
  'Juergen Teller',
  'Jules Bastien-Lepage',
  'Julia Contacessi',
  'Julian Calle',
  'Juliana Huxtable',
  'Julie Bell',
  'Julie Blackmon',
  'Julie Mehretu',
  'Julien Delval',
  'Julius Horsthuis',
  'Jun Kaneko',
  'Junji Ito',
  'Justin Gerard',
  'Kadir Nelson',
  'Kaethe Butcher',
  'Kapwani Kiwanga',
  'Karel Appel',
  'Karel Thole',
  'Karen Wallis',
  'Karl Blossfeldt',
  'Karl Schmidt-Rottluff',
  'Karol Bak',
  'Kasia Nowowiejska',
  'Kate Beaton',
  'Kate Greenaway',
  'Käthe Kollwitz',
  'Kathryn Morris Trotter',
  'Kati Horna',
  'Katsuhiro Otomo',
  'Katsushika Hokusai',
  'Kawanabe Kyōsai',
  'Kaws',
  'Kay Nielsen',
  'Kay Sage',
  'Kazimir Malevich',
  'Kazuo Koike',
  'Kehinde Wiley',
  'Keith Haring',
  'Keith Negley',
  'Keith Parkinson',
  'Kelly Freas',
  'Kelly Mckernan',
  'Kelly Sue Deconnick',
  'Kelly Vivanco',
  'Ken Fairclough',
  'Ken Kelly',
  'Ken Sugimori',
  'Kengo Kuma',
  'Kenne Gregoire',
  'Kent Monkman',
  'Kentaro Miura',
  'Kevin Gnutzmans',
  'Kevin Sloan',
  'Kieron Gillen',
  'Kilian Eng',
  'Kim Jung Gi',
  'Kim Keever',
  'Kitagawa Utamaro',
  'Kitty Lange Kielland',
  'Klaus Burgle',
  'Klaus Janson',
  'Klaus Wittmann',
  'Kobayashi Kiyochika',
  'Konstantin Korovin',
  'Konstantin Yuon',
  'Koson Ohara',
  'Krenz Cushart',
  'Kris Kuksi',
  'Kuang Hong',
  'Kunisada',
  'Kuno Veeber',
  'Kurzgesagt',
  'L. Birge Harrison',
  'Lady Pink',
  'Larry Elmore',
  'Larry Poons',
  'Larry Sultan',
  'László Moholy-Nagy',
  'Laurel Burch',
  'Laurent Grasso',
  'Laurie Greasley',
  'Laurie Lipton',
  'Lawren Harris',
  'Lee Krasner',
  'Lee Madgwick',
  'Lee Quinones',
  'Leiji Matsumoto',
  'Leon Kossoff',
  'Leonardo Da Vinci',
  'Leonetto Cappiello',
  'Leonid Afremov',
  'Leonora Carrington',
  'Les Edwards',
  'Lesley Vance',
  'Leticia Gillett',
  'Liam Wong',
  'Liang Mark',
  'Lisa Frank',
  'Lisa Keene',
  'Liu Ye',
  'Liubov Sergeevna Popova',
  'Lois Baarle',
  'Loish',
  'Lorena Alvarez Gómez',
  'Lorenz Hideyoshi',
  'Loretta Lux',
  'Lori Earley',
  'Louis Comfort Tiffany',
  'Louis Glackens',
  'Louis Icart',
  'Louis Janmot',
  'Louis Rhead',
  'Louis Wain',
  'Louise Bourgeois',
  'Louise Dahl-Wolfe',
  'Lovis Corinth',
  'Luca Boni',
  'Lucian Freud',
  'Lucy Madox Brown',
  'Ludwig Mies der Rohe',
  'Luis Royo',
  'Luisa Russo',
  'Lynd Ward',
  'Lynda Barry',
  'Lynda Benglis',
  'Lyonel Feininger',
  'Lyubov Popova',
  'M.C. Escher',
  'M.W. Kaluta',
  'Mab Graves',
  'Maginel Wright Enright Barney',
  'Magnus Enckell',
  'Makoto Shinkai',
  'Malcolm Liepke',
  'Man Ray',
  'Mandy Disher',
  'Mao Hamaguchi',
  'Marat Latypov',
  'Marc Chagall',
  'Marc Davis',
  'Marc Samson',
  'Marc Simonetti',
  'Marcin Jakubowski',
  'Marco Mazzoni',
  'Marcus Selmer',
  'Marek Okon',
  'Margaret Brundage',
  'Margaret Macdonald Mackintosh',
  'Margaret Mee',
  'Margaux Valonia',
  'Maria Kreyn',
  'Maria Pascual Alberich',
  'Maria Sibylla Merian',
  'Marianne North',
  'Marianne von Werefkin',
  'Marie Guillemine Benoist',
  'Marie Spartali Stillman',
  'Marina Abramović',
  'Marius Borgeaud',
  'Marjane Satrapi',
  'Mark Arian',
  'Mark Briscoe',
  'Mark Brooks',
  'Mark Keathley',
  'Mark Lovett',
  'Mark Rothko',
  'Mark Ryden',
  'Mark Seliger',
  'Marsden Hartley',
  'Martin Ansin',
  'Martin Deschambault',
  'Martin John Heade',
  'Martin Johnson Heade',
  'Martin Kippenberger',
  'Martine Johanna',
  'Martiros Saryan',
  'Mary Anning',
  'Mary Blair',
  'Mary Cassatt',
  'Masaaki Masamoto',
  'Masaaki Sasamoto',
  'Masamune Shirow',
  'Mat Collishaw',
  'Mati Klarwein',
  'Matias Hannecke',
  'Matt Bors',
  'Matt Fraction',
  'Matt Groening',
  'Matthias Grünewald',
  'Matthias Jung',
  'Matti Suuronen',
  'Maurice Sendak',
  'Max Beckmann',
  'Max Dupain',
  'Max Ernst',
  'Max Pechstein',
  'Max Weber',
  'Maxfield Parrish',
  'Maximilian Pirner',
  'Maximilien Luce',
  'Maxwell Boas',
  'Mead Schaeffer',
  'Méret Oppenheim',
  'Meryl McMaster',
  'Michael Carson',
  'Michael Cheval',
  'Michael Deforge',
  'Michael Heizer',
  'Michael Hutter',
  'Michael Parkes',
  'Michael Sowa',
  'Michael Whelan',
  'Michal Karcz',
  'Michal Lisowski',
  'Michelangelo Buonarroti',
  'Michelangelo Merisi Da Caravaggio',
  'Mickalene Thomas',
  'Miho Hirano',
  'Mikalojus Konstantinas Ciurlionis',
  'Mike Campau',
  'Mike Deodato',
  'Mike Mayhew',
  'Mike Mignola',
  'Mike Winkelmann (Beeple)',
  'Mike Worrall',
  'Mikhail Larionov',
  'Mikhail Nesterov',
  'Mikhail Vrubel',
  'Mikko Lagerstedt',
  'Milo Manara',
  'Milton Avery',
  'Milton Caniff',
  'Milton Glaser',
  'Miriam Schapiro',
  'Moebius',
  'Mordecai Ardon',
  'Mort Kunstler',
  'Muxxi',
  'Nagel Patrick',
  'Nan Goldin',
  'Naoki Urasawa',
  'Naoko Takeuchi',
  'Naomi Okubo',
  'Naoto Hattori',
  'Natalia Goncharova',
  'Nathan Coley',
  'Nathan Wirth',
  'NC Wyeth',
  'Neil Boyle',
  'Neil Welliver',
  'Nele Zirnite',
  'NHK Animation',
  'Ni Chuanjing',
  'Nicholas Roerich',
  'Nick Knight',
  'Nick Sharratt',
  'Nick Silva',
  'Nicola Samori',
  'Nicolas de Stael',
  'Nicolas Delort',
  'Nicolas Mignard',
  'Nikolai Ge',
  'Nikolina Petolas',
  'Noah Bradley',
  'Nobuyoshi Araki',
  'Noelle Stevenson',
  'Noriyoshi Ohrai',
  'Norman Ackroyd',
  'Norman Bluhm',
  'Norman Foster',
  'Norman Rockwell',
  'Octavio Ocampo',
  'Odd Nerdrum',
  'Odilon Redon',
  'Ogawa Kazumasa',
  'Ohara Koson',
  'Olafur Eliasson',
  'Oleg Oprisco',
  'Olga Skomorokhova',
  'Olivier Bonhomme',
  'Olivier Valsecchi',
  'Ollie Hoff',
  'Os Gemeos',
  'Os Gêmeos',
  'Osamu Tezuka',
  'Oscar Domínguez',
  'Óscar Domínguez',
  'OSGEMEOS',
  'Oskar Fischinger',
  'Oskar Kokoschka',
  'Ossip Zadkine',
  'Otto Dix',
  'Otto Marseus Schrieck',
  'Pablo Picasso',
  'Pamela Colman Smith',
  'Paolo Roversi',
  'Paolo Veronese',
  'Pascal Blanche',
  'Pascale Campion',
  'Patrice Murciano',
  'Patricia Polacco',
  'Patrick Brown',
  'Patrick Caulfield',
  'Patrick Dougherty',
  'Patrick Heron',
  'Patrick Woodroffe',
  'Paul Barson',
  'Paul Cézanne',
  'Paul Chadeisson',
  'Paul Corfield',
  'Paul Delvaux',
  'Paul Gauguin',
  'Paul Gustav Fischer',
  'Paul Henry',
  'Paul Klee',
  'Paul Laffoley',
  'Paul Lehr',
  'Paul Ranson',
  'Paul Strand',
  'Paul Wonner',
  'Paula Modersohn-Becker',
  'Paulus Potter',
  'Pawel Kuczynski',
  'Peter Andrew Jones',
  'Peter Bagge',
  'Peter De Seve',
  'Peter Doig',
  'Peter Elson',
  'Peter Gric',
  'Peter Holme III',
  'Peter Howson',
  'Peter Kemp',
  'Peter Max',
  'Peter Milligan',
  'Peter Mohrbacher',
  'Peter Paul Rubens',
  'Peter Sculthorpe',
  'Peter Wileman',
  'Peter Zumthor',
  'Phil Foglio',
  'Phil Jimenez',
  'Phil Koch',
  'Phil Noto',
  'Philip Guston',
  'Philippe Druillet',
  'Philippe Parreno',
  'Pierre Bonnard',
  'Pierre Puvis de Chavannes',
  'Pierre-Auguste Renoir',
  'Piet Hein Eek',
  'Piet Mondrian',
  'Pieter Aertsen',
  'Pieter Claesz',
  'Pieter de Hooch',
  'Pieter Jansz Saenredam',
  'Piotr Jabłoński',
  'Pipilotti Rist',
  'Posuka Demizu',
  'Qian Xuan',
  'Qing Han',
  'Quentin Blake',
  'Quentin Tarantino',
  'Quint Buchholz',
  'Rafael Albuquerque',
  'Rafał Olbiński',
  'Raffaello Sanizo',
  'Raina Telgemeier',
  'Raja Ravi Varma',
  'Ralph Horsley',
  'Ralph McQuarrie',
  'Ralph Steadman',
  'Ramon Casas',
  'Randolph Caldecott',
  'Raphael Lacoste',
  'Raphael',
  'Raphaelle Peale',
  'Ravi Zupa',
  'Ray Caesar',
  'Ray Donley',
  'Raymond Briggs',
  'Raymond Duchamp-Villon',
  'Raymond Leech',
  'Raymond Swanland',
  'Rayner Alencar',
  'Rebeca Saray',
  'Rebecca Guay',
  'Rebecca Louise Law',
  'Rebecca Sugar',
  'Reginald Marsh',
  'Rembrandt Rijn',
  'Remedios Varo',
  'René Lalique',
  'Rene Laloux',
  'Rene Magritte',
  'RETNA (Marquis Lewis)',
  'Reylia Slaby',
  'RHADS',
  'Rich Davies',
  'Richard Burlet',
  'Richard Corben',
  'Richard Dadd',
  'Richard Deacon',
  'Richard Diebenkorn',
  'Richard Doyle',
  'Richard Eurich',
  'Richard Hamilton',
  'Richard Lindner',
  'Richard McGuire',
  'Richard Misrach',
  'Richard S. Johnson',
  'Richard Scarry',
  'Guidice',
  'ROA',
  'Rob Gonsalves',
  'Rob Liefeld',
  'Robby Cavanaugh',
  'Robert Antoine Pinchon',
  'Robert Chew',
  'Robert Childress',
  'Robert Crumb',
  'Robert Farkas',
  'Robert Hagan',
  'Robert Irwin',
  'Robert M Cunningham',
  'Robert Maguire',
  'Robert McCall',
  'Robert Mcginnis',
  'Robert Motherwell',
  'Robert Neubecker',
  'Robert Rauschenberg',
  'Robert S. Duncanson',
  'Robert Stivers',
  'Robert Vonnoh',
  'Robert William Hume',
  'Robert Williams',
  'Roberto Ferri',
  'Roberto Matta',
  'Roberto Parada',
  'Rockwell Kent',
  'Rodney Matthews',
  'Rodríguez ARS',
  'Roger Ballen',
  'Roger de La Fresnaye',
  'Roger Dean',
  'Rolf Armstrong',
  'Romero Britto',
  'Ron Mueck',
  'Ron Walotsky',
  'Ronald Balfour',
  'Ross Tran',
  'Roy Gjertson',
  'Roy Lichtenstein',
  'Roz Chast',
  'Ruan Jia',
  'Rudolf Freund',
  'Rufino Tamayo',
  'Rumiko Takahashi',
  'Russ Mills',
  'Russell Ayto',
  'Ruth Bernhard',
  'Ruxing Gao',
  'Ryan Hewett',
  'Ryan McGinley',
  'Ryan Stegman',
  'Ryohei Hase',
  'Sacha Goldberger',
  'Sailor Moon',
  'Sakai HoÌ„itsu',
  'Sally Mann',
  'Salomon Ruysdael',
  'Salvador Dali',
  'Sam Bosma',
  'Sam Kieth',
  'Sam Spratt',
  'Samuel Joseph Newsom',
  'Samuel Earp',
  'Samuel Melton Fisher',
  'Sandra Chevrier',
  'Sandro Botticelli',
  'Sandy Skoglund',
  'Saner Edgar',
  'Sanford Kossin',
  'Sangyeob Park',
  'Santiago Calatrava',
  'Santiago Caruso',
  'Sara Wollfalk',
  'Sarah Lucas',
  'Satoshi Kon',
  'Saturno Butto',
  'Saul Bass',
  'Saul Steinberg',
  'Saul Tepper',
  'Scarlett Hooft Graafland',
  'Scott Brundage',
  'Scott Listfield',
  'Scott Naismith',
  'Sean Scully',
  'Sean Yoro',
  'Seb Mckinnon',
  'Sebastian Errazuriz',
  'Serge Marshennikov',
  'Shaddy Safadi',
  'Shaun Tan',
  'Shawn Coss',
  'Sheilah Beckett',
  'Shepard Fairey',
  'Sherree Valentine Daines',
  'Shin Jeongho',
  'Shinji Aramaki',
  'Shintaro Kago',
  'Shohei Otomo',
  'Shotaro Ishinomori',
  'Shusei Nagaoko',
  'Sidney Nolan',
  'Silvestro Lega',
  'Simeon Solomon',
  'Simon Birch',
  'Simon Bisley',
  'Simon Stalenhag',
  'Simone Martini',
  'Sir James Guthrie',
  'Siya Oum',
  'Skottie Young',
  'Slim Aarons',
  'Sofonisba Anguissola',
  'Sonia Delaunay',
  'Sou Fujimoto',
  'Sparth',
  'Squeak Carnwath',
  'Stan Jan Berenstain',
  'Stan Lee',
  'Stanislav Poltavsky',
  'Stanisław Szukalski',
  'Stanley Donwood',
  'Stephan Martiniere',
  'Stephen Gammell',
  'Stephen Oakley',
  'Stephen Shore',
  'Stevan Dohanos',
  'Steve Argyle',
  'Steve Dillon',
  'Steve Ditko',
  'Steve Henderson',
  'Steve Lieber',
  'Steve McCurry',
  'Steven Belledin',
  'Storm Thorgerson',
  'Stuart Davis',
  'Stuart Haygarth',
  'Stuart Immonen',
  'Sue Bryce',
  'Susan Luo',
  'Susan Seddon Boulet',
  'Sven Nordqvist',
  'Syd Mead',
  'Sydney Edmunds',
  'Sydney Prior Hall',
  'Tadao Ando',
  'Taiyō Matsumoto',
  'Takashi Murakami',
  'Takato Yamamoto',
  'Takeshi Obata',
  'Tamara Lempicka',
  'Tan Zhi Hui',
  'Tara McPherson',
  'Tari Márk Dávid',
  'Tatsuro Kiuchi',
  'teamLab',
  'Ted Nasmith',
  'Ted Wallace',
  'Teophilus Tetteh',
  'Terada Katsuya',
  'Teresa Ramos',
  'Terry Oakes',
  'Terry Redlin',
  'Tex Avery',
  'theCHAMBA',
  'Theo Rysselberghe',
  'Théodore Géricault',
  'Thomas Allom',
  'Thomas Benjamin Kennington',
  'Thomas Blackshear',
  'Thomas Cole',
  'Thomas Dodd',
  'Thomas Eakins',
  'Thomas Gainsborough',
  'Thomas Kinkade',
  'Thomas Moran',
  'Thomas Rowlandson',
  'Thomas Saliot',
  'Thomas Struth',
  'Thomas Visscher',
  'Thomas W Schaller',
  'Thornton Oakley',
  'Tibor Nagy',
  'Till Freitag',
  'Tim Burton',
  'Tim Doyle',
  'Tim Hildebrandt',
  'Tintoretto',
  'Titian',
  'Todd McFarlane',
  'Todd Schorr',
  'Toei Animations',
  'Tokujin Yoshioka',
  'tokyogenso',
  'Tom Bagshaw',
  'Tom Hammick',
  'Tom Lovell',
  'Tom Roberts',
  'Tom Thomson',
  'Tom Whalen',
  'Tomasz Alen Kopera',
  'Tomer Hanuka',
  'Tomi Ungerer',
  'Tomma Abts',
  'Tomokazu Matsuyama',
  'Tony DiTerlizzi',
  'Tony Moore',
  'Toshiharu Mizutani',
  'Toumas Korpi',
  'Tove Jansson',
  'Tracey Emin',
  'Travis Louie',
  'Tristan Eaton',
  'Tsutomu Nihei',
  'Tyler Edlin',
  'Tyler Shields',
  'Tyler West',
  'Ub Iwerks',
  'Uemura Shoen',
  'Ul Di Rico',
  'Umberto Boccioni',
  'Utagawa Hiroshige',
  'Valerie Hegarty',
  'Vhils',
  'Victo Ngai',
  'Victor Adame Minguez',
  'Victor Brauner',
  'Victor Medina',
  'Victor Moscoso',
  'Victor Nizovtsev',
  'Victor Vasarely',
  'Victoria Crowe',
  'Viktor Vasnetsov',
  'Viktoria Gavrilenko',
  'Vincent Di Fate',
  'Vincent Tanguay',
  'Vincent Gogh',
  'Virgil Finlay',
  'Vito Acconci',
  'Vittorio Matteo Corcos',
  'Vivian Maier',
  'Viviane Sassen',
  'Vivienne Westwood',
  'Vladimir Kush',
  'W. Heath Robinson',
  'W.W. Denslow',
  'Wadim Kashin',
  'Walt Disney',
  'Walt Kelly',
  'Walter Crane',
  'Walter Kim',
  'Walter Langley',
  'Walter Percy Day',
  'Wangechi Mutu',
  'Warren Ellis',
  'Warwick Globe',
  'Wassily Kandinsky',
  'Wayne Barlowe',
  'Wendy Froud',
  'Wes Anderson',
  'Wilfredo Lam',
  'Will Barnet',
  'Will Eisner',
  'Willem de Kooning',
  'Willem Haecht',
  'William Blake',
  'William Eggleston',
  'William Etty',
  'William Gropper',
  'William Henry Hunt',
  'William Hogarth',
  'William Holman Hunt',
  'William Kentridge',
  'William Morris',
  'William S. Burroughs',
  'William Steig',
  'William Stout',
  'William Wegman',
  'William Zorach',
  'William-Adolphe Bouguereau',
  'Wim Crouwel',
  'Wim Wenders',
  'Winslow Homer',
  'Winsor McCay',
  'Wojciech Ostrycharz',
  'Wolf Kahn',
  'Wolfgang Tillmans',
  'Worthington Whittredge',
  'Yaacov Agam',
  'Yang Jialun',
  'Yanjun Cheng',
  'Yasuo Kuniyoshi',
  'Yasushi Nirasawa',
  'Yasutomo Oka',
  'Yayi Morales',
  'Yayoi Kusama',
  'Yiannis Moralis',
  'Yinka Shonibare',
  'Yohann Schepacz',
  'Yoji Shinkawa',
  'Yoshitaka Amano',
  'Yoshiyuki Tomino',
  'Yue Minjun',
  'Yuri Ivanovich Pimenov',
  'Yuumei',
  'Yves Klein',
  'Yves Tanguy',
  'Zack Snyder',
  'Zaha Hadid',
  'Zanele Muholi',
  'Zdzisław Beksiński',
  'Zeen Chin',
  'Zhang Kechun',
  'Zhelong Xu',
  'Zhichao Cai',
  'Zinaida Serebriakova',
  'Arthur Martins',
  'Scott Robertson',
  'Felix Godard',
  'Tetsuya Nomura',
  'Yoji Shinkawa',
  'Scott Watanabe',
  'Cameron Brenson',
  'Sahm Jafari',
  'alphonse mucha',
  'pablo picasso',
  'piet mondrian',
  'jackson pollock',
  'mark rothko',
  'georges braque',
  'andy warhol',
  'roy lichtenstein',
  'rené magritte',
  'frida kahlo',
  'dean cornwell',
  'craig mullins',
  'henri matisse',
  'johannes vermeer',
  'vincent gogh',
  'michelangelo',
  'leonardo da vinci',
  'georges seurat',
  'thomas cole',
  'greg rutkowski',
  'gustave dore',
  'ralph mcquarrie',
  'zdzislaw beksinski',
  'claude lorrain',
  'james gurney',
  'francisco goya',
  'pierre auguste renoir',
  'hayao miyazaki',
  'bill watterson',
  'claude monet',
  'edvard munch',
  'katsushika hokusai',
  'funko pop',
  'lucian freud',
  'yoji shinkawa',
  'utagawa kuniyoshi',
  'toshi yoshid',
  'diego rivera',
  'hatsune miku',
  'hieronymus bosch',
  'caravaggio',
  'gustave doré',
  'frank frazetta',
  'john constable',
  'salvador dali',
  'galen',
  'pieter brueghel',
  'annie leibovitz',
  'wes anderson',
  'frank gehry',
  'saul leiter',
  'dorothea lange',
  'terry richardson',
  'simon stålenhag',
  'banksy',
  'hanna barbera',
  'beatrix potter',
  'norman rockwell',
  'dr suess',
  'axel schaefer',
  'keith haring',
  'edward hopper',
  'lisa frank',
  'thomas kinkaid',
  'basquiat',
  'hr giger',
  'duffer brothers',
  'arcimboldo',
  'fellini',
  'keith haring',
  'tim burton',
  'mc escher',
  'robert hargreaves',
  'ivan shishkin',
  'albert bierstadt',
  'robert mccall',
  'samuel daniell',
  'james tissot',
  'Ingo Maurer',
  'Alexandre Touguet',
  'Lotta Jansdotter',
  'Gaetano Pesce',
  'Milton Glaser',
  'Claudia Owen',
  'Steven Holl',
  'Zoa Martinez',
  'Erik Spiekermann',
  'Marc Newson',
  'Sebastian Bergne',
  'Karim Rashid',
  'Marcel Wanders',
  'Marc Atlan',
];

export const promptContainsArtistName = (prompt: string): boolean => {
  for (let i = 0, l = artists.length; i < l; i++) {
    if (new RegExp(`\\b${artists[i]}\\b`, 'gi').exec(prompt) !== null) {
      triggerDirectModal({
        title:
          "We don't allow the use of artists' names for prompting without their permission.",
        content: (
          <>
            <Text block>
              We care deeply about building a product that respects the work of
              artists and designers. Use the learn more button to read our
              statement on ethical AI practices.
            </Text>
            <br />
            <a
              href="https://www.notion.so/vizcom/Vizcom-Ethical-AI-Statement-08056a4df90b414f84e840c58611077b"
              rel="noopener noreferrer"
              target="_blank"
            >
              Learn more
            </a>
          </>
        ),
      });
      return true;
    }
  }
  return false;
};

import { useMemo } from 'react';
import styled from 'styled-components';
import { Euler, MathUtils, Quaternion } from 'three';
import { CompositeSceneFullData } from '@vizcom/shared/data-access/graphql';

import { useCompositeSceneSyncedState } from '../../../lib/useCompositeSceneSyncedState';
import { getRootElementForMesh } from '../../utils/getRootElementForMesh';
import { useCompositeSceneEditorContext } from '../compositeSceneEditor/context';
import { CompositeSceneMesh } from '../compositeSceneEditor/types';
import { getCompositeSceneNodeUUID } from '../compositeSceneEditor/utils/compositeSceneNodeUUID';
import { VectorInput } from '../ui/VectorInput/VectorInput';

const quaternion = new Quaternion();
const euler = new Euler();

export const ObjectTransform = ({
  compositeScene,
  handleAction,
}: {
  compositeScene: CompositeSceneFullData;
  handleAction: ReturnType<typeof useCompositeSceneSyncedState>['handleAction'];
}) => {
  const { selected, setSelected } = useCompositeSceneEditorContext();
  const selectedElementRoot = useMemo(
    () => getRootElementForMesh(compositeScene, selected),
    [selected, compositeScene]
  );
  const selectedMesh = useMemo<CompositeSceneMesh | null>(() => {
    if (!selectedElementRoot || !selected) {
      return null;
    }

    return selectedElementRoot.meshes[getCompositeSceneNodeUUID(selected)];
  }, [selectedElementRoot, selected]);
  const eulerRotation = useMemo<[number, number, number]>(() => {
    if (!selectedMesh || !selectedMesh?.quaternion) {
      return [0.0, 0.0, 0.0];
    }

    quaternion.identity();
    quaternion.set(...selectedMesh.quaternion);

    return euler
      .setFromQuaternion(quaternion)
      .toArray()
      .slice(0, 3)
      .map((value) => MathUtils.radToDeg(value as number)) as [
      number,
      number,
      number
    ];
  }, [selectedMesh]);

  const updateTransform = (
    update: Partial<Omit<CompositeSceneMesh, 'deleted'>>
  ) => {
    if (!selected || !selectedElementRoot) {
      return;
    }

    const currentState =
      selectedElementRoot.meshes[getCompositeSceneNodeUUID(selected)];
    const updatedState = {
      ...currentState,
      ...update,
    };

    handleAction({
      type: 'updateCompositeSceneElement',
      id: selected.userData.rootId,
      meshes: {
        ...selectedElementRoot.meshes,
        [getCompositeSceneNodeUUID(selected)]: updatedState,
      },
    });

    const currentSelection = selected;

    setSelected(null);

    setTimeout(() => {
      setSelected(currentSelection);
    }, 100);
  };

  if (!selected) {
    return null;
  }

  return (
    <Wrapper>
      <VectorInput
        length={3}
        label="Position"
        value={selectedMesh?.position || [0.0, 0.0, 0.0]}
        allowReset
        resetValue={[0.0, 0.0, 0.0]}
        onChange={(update: [number, number, number]) => {
          updateTransform({
            position: [update[0], update[1], update[2]],
          });
        }}
      />
      <VectorInput
        length={3}
        label="Scale"
        value={selectedMesh?.scale || [1.0, 1.0, 1.0]}
        allowReset
        resetValue={[1.0, 1.0, 1.0]}
        onChange={(update: [number, number, number]) => {
          updateTransform({
            scale: [update[0], update[1], update[2]],
          });
        }}
      />
      <VectorInput
        length={3}
        label="Rotation"
        value={eulerRotation || [0.0, 0.0, 0.0]}
        allowReset
        resetValue={[0.0, 0.0, 0.0]}
        onChange={(update: [number, number, number]) => {
          euler.set(
            MathUtils.degToRad(update[0]),
            MathUtils.degToRad(update[1]),
            MathUtils.degToRad(update[2])
          );

          const quaternionRotation = quaternion.setFromEuler(euler).normalize();

          updateTransform({
            quaternion: quaternionRotation.toArray() as [
              number,
              number,
              number,
              number
            ],
          });
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: ${(p) => p.theme.text.body};
`;

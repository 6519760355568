import { useThree } from '@react-three/fiber';
import { useEffect, useMemo } from 'react';
import { CompositeSceneFullData } from '@vizcom/shared/data-access/graphql';

import {
  isEnvironmentPreset,
  getEnvironmentPreset,
  EnvironmentPresets,
} from '../utils/environments';

export const useImperativeSceneConfig = ({
  compositeScene,
}: {
  compositeScene: CompositeSceneFullData;
}) => {
  const three = useThree();

  useEffect(() => {
    const { scene } = three;

    return () => {
      if (scene.environment) {
        scene.environment.dispose();
      }
    };
  }, [three.scene]);

  const environment = useMemo(() => {
    if (
      compositeScene.sceneEnvironmentMapUrl &&
      isEnvironmentPreset(compositeScene.sceneEnvironmentMapUrl)
    ) {
      /**
       * NOTE For now we allow only environment presets
       */

      return getEnvironmentPreset(compositeScene.sceneEnvironmentMapUrl);
    }

    return EnvironmentPresets.Warehouse;
  }, [compositeScene.sceneEnvironmentMapUrl]);

  return {
    environment,
  };
};

import {
  urqlClient,
  CompositeSceneFullData,
  CreateCompositeSceneElementMutation,
} from '@vizcom/shared/data-access/graphql';
import { assertNotExists } from '@vizcom/shared/js-utils';

import { SyncedActionType } from '../../SyncedAction';

/**
 * NOTE Use basicShape to create a pre-defined type of composite scene elements
 */
export type CompositeSceneElementBasicShape = 'Camera';

export const CreateCompositeSceneElementAction: SyncedActionType<
  CompositeSceneFullData,
  {
    type: 'createCompositeSceneElement';
    id: string;
    name: string;
    modelFile?: Blob | File | null;
    basicShape?: CompositeSceneElementBasicShape | null;
  },
  {
    modelObjectUrl: string;
  }
> = {
  type: 'createCompositeSceneElement',
  optimisticUpdater: ({ payload, meta }, scene) => {
    if (
      scene.compositeSceneElements.nodes.find(
        (element) => element.id === payload.id
      )
    ) {
      // Already got the response from the server, nothing to do
      return;
    }

    if (payload.basicShape) assertNotExists(payload.modelFile);
    if (payload.modelFile) assertNotExists(payload.basicShape);

    scene.compositeSceneElements.nodes.push({
      id: payload.id,
      name: payload.name,
      modelPath: meta.custom ? meta.custom.modelObjectUrl : null,
      basicShape: payload.basicShape,
      meshes: {},
    });
  },
  metaConstructor: (payload) => {
    if (!payload.modelFile) {
      return {};
    }

    const modelObjectUrl = URL.createObjectURL(payload.modelFile);
    return {
      custom: {
        modelObjectUrl,
      },
    };
  },
  remoteUpdater: async ({ payload, meta }, compositeSceneId) => {
    const res = await urqlClient.mutation(CreateCompositeSceneElementMutation, {
      input: {
        compositeSceneElement: {
          compositeSceneId,
          id: payload.id,
          modelPath: payload.modelFile ?? null,
          name: payload.name,
          basicShape: payload.basicShape,
          meshes: {},
        },
      },
    });

    if (res?.error) {
      throw new Error(
        `Error while uploading model, please retry. ${
          res.error.graphQLErrors[0]?.message ?? res.error.message
        }`
      );
    }
  },
};

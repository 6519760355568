import { useMutation } from 'urql';
import { v4 as uuidv4 } from 'uuid';
import {
  DeleteUserPaletteMutation,
  publishTrackingEvent,
} from '@vizcom/shared/data-access/graphql';
import { assertExists, filterExists } from '@vizcom/shared/js-utils';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import {
  addToast,
  browseForFiles,
  Button,
  downloadFile,
  FileToZip,
  formatErrorMessage,
  imageToBlob,
  resizeImageToPixelCount,
  Text,
  triggerConfirmModal,
  zipFiles,
} from '@vizcom/shared-ui-components';

import { WORKBENCH_2D_STUDIO_IMAGE_MAX_PIXEL_COUNT } from '../../../constants';
import { ClientSideWorkbenchElementPalette } from '../../../lib/clientState';
import { useWorkbenchSyncedState } from '../../../lib/useWorkbenchSyncedState';

export const handleDownloadPaletteImages = async (
  element: ClientSideWorkbenchElementPalette
) => {
  if (!element.sourceImages?.nodes || element.sourceImages.nodes.length === 0) {
    return addToast('No images to export', { type: 'danger' });
  }
  try {
    const files: FileToZip[] = await Promise.all(
      element.sourceImages.nodes
        .filter((image) => image.imagePath)
        .map(async (image, index) => {
          return {
            name: `${element.name}-${index}.png`,
            data: await imageToBlob(image.imagePath, {
              convertToContentType: 'image/png',
            }),
          };
        })
    );

    const zipFile = await zipFiles(files);

    downloadFile(zipFile, element.name, 'zip');

    trackEvent('Image Export', {
      type: 'exportPaletteImages',
    });
  } catch {
    return addToast('An error occurred while downloading palette images', {
      type: 'danger',
    });
  }
};

export const handleAddImagesToPalette = async (
  paletteId: string,
  numExistingImages: number,
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'],
  selectedFiles?: File[]
) => {
  const files =
    selectedFiles ||
    (await browseForFiles({
      accept: 'image/*',
    }));

  const filesData = (
    await Promise.all(
      files.map(async (file) => {
        let resized;
        try {
          resized = await resizeImageToPixelCount(
            file,
            WORKBENCH_2D_STUDIO_IMAGE_MAX_PIXEL_COUNT
          );
        } catch (e: any) {
          addToast(
            `Error while importing file "${file.name}", please check it's a valid image.`,
            {
              type: 'danger',
              secondaryText: formatErrorMessage(e),
            }
          );
        }
        assertExists(resized);
        return { ...resized, name: file.name };
      })
    )
  ).filter(filterExists);

  if (!filesData.length) {
    return;
  }

  const sourceImages = limitSourceImages(filesData, numExistingImages);

  if (!sourceImages.length) {
    return;
  }

  handleAction({
    type: 'insertImagesToPalette',
    id: paletteId,
    sourceImages: sourceImages.map((file) => ({
      ...file,
      id: uuidv4(),
    })),
  });
};

export const getNewPromptFromTags = (tags: string[], prompt: string) => {
  const missingTags = tags.filter((tag) => !prompt.includes(tag));
  return [prompt, ...missingTags].filter((tag) => tag !== '').join(', ');
};

const PALETTE_SOURCE_IMAGE_LIMIT = 30;
export const limitSourceImages = <T,>(
  sourceImages: T[],
  numExistingImages: number
) => {
  const limit = PALETTE_SOURCE_IMAGE_LIMIT - numExistingImages;
  if (sourceImages.length <= limit) {
    return sourceImages;
  }
  addToast(
    `You can only add up to ${PALETTE_SOURCE_IMAGE_LIMIT} images to a palette, the rest were ignored.`
  );
  return sourceImages.slice(0, Math.max(limit, 0));
};

export const useRemovePaletteFromLibrary = (id: string) => {
  const [deleteUserPaletteRes, deleteUserPalette] = useMutation(
    DeleteUserPaletteMutation
  );

  const handleRemovePaletteFromLibrary = async () => {
    try {
      await triggerConfirmModal({
        title: 'Remove palette',
        content: (
          <Text block type="b1" color="subtext">
            The palette will be removed from My Library. The source palette will
            still be available in its original file location, if you choose to
            publish it again.
          </Text>
        ),
        confirmAction: (
          <Button style={{ flex: 1 }} variant="danger">
            Remove
          </Button>
        ),
      });
    } catch {
      return;
    }

    await deleteUserPalette({
      input: {
        id,
      },
    });

    if (deleteUserPaletteRes.error) {
      addToast('There was an error while removing this palette', {
        type: 'danger',
        secondaryText: deleteUserPaletteRes.error.message,
      });
      return;
    }

    trackEvent('Remove published palette');
    publishTrackingEvent({
      type: 'REMOVE_PUBLISHED_PALETTE_FROM_PERSONAL_LIBRARY',
      data: {
        paletteId: id,
      },
    });
  };

  return { handleRemovePaletteFromLibrary };
};

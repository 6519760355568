import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  OrganizationData,
  useUpdateOrganization,
} from '@vizcom/shared/data-access/graphql';
import {
  addToast,
  formatErrorMessage,
  LoadingLogoInset,
  Text,
} from '@vizcom/shared-ui-components';

export const PublicSharingSettings = ({
  organization,
}: {
  organization: OrganizationData;
}) => {
  const [updateOrganizationRes, updateOrganization] = useUpdateOrganization();

  const handleTogglePublicSharing = async () => {
    const newPublicSharing = !organization.publicSharing;

    const res = await updateOrganization({
      input: {
        id: organization.id,
        patch: {
          publicSharing: newPublicSharing,
        },
      },
    });

    if (res.error) {
      return addToast('Error while updating public sharing', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }

    return addToast(
      `Public sharing ${newPublicSharing ? 'enabled' : 'disabled'}`
    );
  };

  return (
    <div
      style={{
        padding: '0px 10px',
      }}
    >
      <LoadingLogoInset active={updateOrganizationRes.fetching} />

      <Text block type="sh1" style={{ marginBottom: 16 }}>
        Public Sharing
      </Text>

      <Text
        block
        type="b1"
        color="subtext"
        style={{ marginBottom: 16, maxWidth: 540 }}
      >
        Control public file sharing for your workspace. This setting determines
        whether users can generate public links for individual workbench files,
        allowing anyone with the link to view them. Enabling this option doesn't
        automatically share files; public access must be activated separately
        for each file. Note that disabling this setting will invalidate all
        existing public links. Use this to manage external access to your
        workspace files.{' '}
        <Link
          target="_blank"
          to="https://www.notion.so/vizcom/Vizcom-1-1-355aecbbdc7148cf8a2d01852686fd96?pvs=4"
        >
          <Text color="link" type="b1">
            Learn more
          </Text>
        </Link>
      </Text>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 16,
        }}
      >
        <Text>Enable public file sharing</Text>
        <Switch
          $enabled={organization.publicSharing}
          onClick={handleTogglePublicSharing}
        />
      </div>
    </div>
  );
};

export const Switch = styled.div<{ $enabled: boolean }>`
  width: 3rem;
  height: 1.5rem;
  border-radius: ${({ theme }) => theme.borderRadius.l};
  position: relative;
  background: ${(p) =>
    p.$enabled ? p.theme.deprecated.primary.hover : p.theme.surface.secondary};
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: ${({ $enabled }) => ($enabled ? '1.5rem' : '0')};
    width: 1.5rem;
    height: 1.5rem;
    border-radius: ${({ theme }) => theme.borderRadius.l};
    background: ${({ theme }) => theme.button.primary};
    transition: left 0.15s;
  }
`;

import { useFrame, useStore, useThree } from '@react-three/fiber';
import { memo, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { BaseEvent, EventDispatcher, MathUtils } from 'three';
import { boundNumber } from '@vizcom/shared/js-utils';
import { useStableCallback } from '@vizcom/shared-ui-components';

import { CameraLimits } from './utils';

const CAMERA_MOVE_DAMPING = 10;
const CONTROLLED_CAMERA_MOVE_DAMPING = 6;

interface MoveToParameters {
  x?: number;
  y?: number;
  zoom?: number;
  skipAnimation?: boolean;
  relative?: boolean;
  limits?: CameraLimits;
  rotation?: number;
  controlled?: boolean; // used to detect if the movement is not manual
}
export interface MoveToEvent extends BaseEvent {
  type: 'moveTo';
  params: MoveToParameters;
  goal: {
    x: number;
    y: number;
    zoom: number;
  };
}

export class MapControlsProto extends EventDispatcher<{
  moveTo: MoveToEvent;
  enabled: boolean;
  stopAnimating: () => void;
  disable: () => void;
  enable: () => void;
}> {
  constructor(
    public moveTo: (params: MoveToParameters) => void,
    public stopAnimating: () => void,
    public disable: () => void,
    public enable: () => void,
    public enabled: boolean
  ) {
    super();
  }
}

export const MapControls = memo(() => {
  const set = useThree((s) => s.set);
  const store = useStore();
  const [enabled, setEnabled] = useState(true);

  const animationState = useRef<null | {
    goalPositionX: number;
    goalPositionY: number;
    goalZoom: number;
    goalRotation: number;
    damping: number;
  }>(null);
  const moveTo = useStableCallback((p: MoveToParameters) => {
    if (!enabled) {
      return;
    }

    const camera = store.getState().camera;
    let targetX = animationState.current?.goalPositionX ?? camera.position.x;
    if (p.x !== undefined) {
      targetX = p.relative ? camera.position.x + p.x : p.x;
    }
    let targetY = animationState.current?.goalPositionY ?? camera.position.y;
    if (p.y !== undefined) {
      targetY = p.relative ? camera.position.y + p.y : p.y;
    }
    let targetZoom = animationState.current?.goalZoom ?? camera.zoom;
    if (p.zoom !== undefined) {
      targetZoom = p.relative ? camera.zoom + p.zoom : p.zoom;
    }
    let targetRotation =
      animationState.current?.goalRotation ?? camera.rotation.z;
    if (p.rotation !== undefined) {
      targetRotation = p.relative ? camera.rotation.z + p.rotation : p.rotation;
    }
    // always reset camera rotation to nearest 360 degrees when moving
    targetRotation = normalizeRotation(targetRotation);

    if (p.limits) {
      targetX = boundNumber(p.limits.xMin, targetX, p.limits.xMax);
      targetY = boundNumber(p.limits.yMin, targetY, p.limits.yMax);
      targetZoom = boundNumber(p.limits.zoomMin, targetZoom, p.limits.zoomMax);
    }
    if (p.skipAnimation) {
      animationState.current = null;
      camera.position.x = targetX;
      camera.position.y = targetY;
      camera.rotation.z = targetRotation;
      camera.zoom = targetZoom;
      camera.rotation.z = targetRotation;
      camera.updateProjectionMatrix();
    } else {
      animationState.current = {
        goalPositionX: targetX,
        goalPositionY: targetY,
        goalZoom: targetZoom,
        goalRotation: targetRotation,
        damping: p.controlled
          ? CONTROLLED_CAMERA_MOVE_DAMPING
          : CAMERA_MOVE_DAMPING,
      };
    }
    controls.dispatchEvent({
      type: 'moveTo',
      params: p,
      goal: {
        x: targetX,
        y: targetY,
        zoom: targetZoom,
      },
    });
  });
  const stopAnimating = useStableCallback(() => {
    animationState.current = null;
  });

  const enable = useStableCallback(() => {
    setEnabled(true);
  });

  const disable = useStableCallback(() => {
    setEnabled(false);
  });

  const controls = useMemo(
    () => new MapControlsProto(moveTo, stopAnimating, disable, enable, enabled),
    [moveTo, stopAnimating, enabled]
  );
  useLayoutEffect(() => {
    set({
      controls,
    });
  }, [set, controls]);

  useFrame(({ camera }, delta) => {
    if (animationState.current) {
      camera.position.x = MathUtils.damp(
        camera.position.x,
        animationState.current.goalPositionX,
        animationState.current.damping,
        delta
      );
      camera.position.y = MathUtils.damp(
        camera.position.y,
        animationState.current.goalPositionY,
        animationState.current.damping,
        delta
      );

      camera.zoom = MathUtils.damp(
        camera.zoom,
        animationState.current.goalZoom,
        animationState.current.damping,
        delta
      );
      camera.rotation.z = MathUtils.damp(
        camera.rotation.z,
        animationState.current.goalRotation,
        animationState.current.damping,
        delta
      );

      camera.updateProjectionMatrix();

      if (
        Math.abs(camera.zoom - animationState.current.goalZoom) > 0.001 ||
        Math.abs(camera.position.x - animationState.current.goalPositionX) >
          0.02 ||
        Math.abs(camera.position.y - animationState.current.goalPositionY) >
          0.02 ||
        Math.abs(camera.rotation.z - animationState.current.goalRotation) >
          0.001
      ) {
        // check if animation is finished
        // because of the damping, we need to check if the camera is close enough to the goal
        // if so, we can stop animating and we set the camera to the actual goal position
        return;
      }

      camera.position.x = animationState.current.goalPositionX;
      camera.position.y = animationState.current.goalPositionY;
      camera.zoom = animationState.current.goalZoom;
      camera.rotation.z = animationState.current.goalRotation;
      camera.updateProjectionMatrix();
      animationState.current = null;
    }
  });

  return null;
});

const normalizeRotation = (rotation: number) => {
  if (rotation < 0) {
    rotation += Math.PI * 2;
  }
  return ((rotation + Math.PI) % (2 * Math.PI)) - Math.PI;
};

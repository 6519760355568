import React, { useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { enhanceImage } from '@vizcom/shared/data-access/graphql';
import {
  Button,
  RegenerateIcon,
  RichTooltip,
  RichTooltipTrigger,
  RichTooltipContent,
  addToast,
  dismissToast,
  Clickable,
  DownloadIcon,
  resizeImageToCoverSizeBlob,
} from '@vizcom/shared-ui-components';

import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../../../lib/useDrawingSyncedState';
import { ControlsRow, ActionControls } from '../style';
import { HistoryEntry } from './RealtimeHistory';

interface RealtimeControlsProps {
  onRegenerate: () => Promise<void>;
  currentEntry: HistoryEntry | null;
  drawing: Drawing2dStudio;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  setActiveLayerId: (id: string | undefined) => void;
  createVideoFromHistory: (
    width: number,
    height: number,
    fps?: number
  ) => Promise<Blob | null>;
  videoExportIsExporting: boolean;
}

const EnhanceButton = styled(Clickable)`
  flex: 2;
  &:hover {
    background-color: ${({ theme, $active }) =>
      $active
        ? theme.surface.tertiary
        : theme.button.secondaryHover} !important;
    cursor: pointer !important;
  }
`;

const FullWidthButton = styled(Button)`
  flex: 1;
  justify-content: center;
`;

const IconButton = styled(Button)`
  flex: 0 0 auto;
`;

export const RealtimeControls: React.FC<RealtimeControlsProps> = ({
  onRegenerate,
  currentEntry,
  drawing,
  handleAction,
  setActiveLayerId,
  createVideoFromHistory,
  videoExportIsExporting,
}) => {
  const [isEnhancing, setIsEnhancing] = useState(false);
  const [isAddingLayer, setIsAddingLayer] = useState(false);

  const handleEnhance = async (previousLayerId?: string, isRetry = false) => {
    if (!currentEntry?.blob || isEnhancing) return;

    setIsEnhancing(true);
    const toastId = uuidv4();
    const toastMessage = isRetry
      ? 'Retry enhancing the image.'
      : 'Enhancing the image. Once done, it will be added to your layers.';

    addToast(toastMessage, {
      id: toastId,
      type: 'loading',
    });

    if (previousLayerId) {
      handleAction({
        type: 'updateBulkLayers',
        deletedLayerIds: [previousLayerId],
      });
    }

    try {
      // Resize the image to match the drawing dimensions while maintaining aspect ratio
      const resizedImage = await resizeImageToCoverSizeBlob(
        currentEntry.blob,
        drawing.width,
        drawing.height
      );

      const result = await enhanceImage(drawing.id, resizedImage, {
        isRetry,
      });

      // Create a new blob from the base64 image
      const response = await fetch(`data:image/png;base64,${result.image}`);
      const enhancedBlob = await response.blob();

      const id = uuidv4();

      handleAction({
        type: 'addLayer',
        layer: {
          id,
          name: `Realtime-Enhanced-${new Date().toLocaleTimeString()}`,
          visible: true,
          opacity: 1,
          blendMode: 'normal',
          isGroup: false,
          fill: '',
          placement: 'top',
          parentId: null,
          image: enhancedBlob,
        },
      });

      setActiveLayerId(id);
      dismissToast(toastId);

      addToast('Image enhanced successfully!', {
        duration: 3000,
        type: 'success',
      });
    } catch (error) {
      console.error('Error enhancing image:', error);
      dismissToast(toastId);
      addToast('Failed to enhance image. Please try again.', {
        duration: 3000,
        type: 'danger',
      });
    } finally {
      setIsEnhancing(false);
    }
  };

  const handleAddToLayers = async () => {
    if (!currentEntry?.blob || isAddingLayer) return;

    setIsAddingLayer(true);
    const toastId = uuidv4();

    addToast('Adding layer...', {
      id: toastId,
      type: 'loading',
    });

    try {
      // Resize the image to match the drawing dimensions while maintaining aspect ratio
      const resizedImage = await resizeImageToCoverSizeBlob(
        currentEntry.blob,
        drawing.width,
        drawing.height
      );

      const id = uuidv4();

      handleAction({
        type: 'addLayer',
        layer: {
          id,
          name: `Realtime-${new Date().toLocaleTimeString()}`,
          visible: true,
          opacity: 1,
          blendMode: 'normal',
          isGroup: false,
          fill: '',
          placement: 'top',
          parentId: null,
          image: resizedImage,
        },
      });

      setActiveLayerId(id);
      dismissToast(toastId);

      addToast('Layer added successfully!', {
        duration: 3000,
        type: 'success',
      });
    } catch (error) {
      console.error('Error adding layer:', error);
      dismissToast(toastId);
      addToast('Failed to add layer. Please try again.', {
        duration: 3000,
        type: 'danger',
      });
    } finally {
      setIsAddingLayer(false);
    }
  };

  const handleExportVideo = async () => {
    const toastId = uuidv4();
    addToast('Exporting video...', {
      id: toastId,
      type: 'loading',
    });

    try {
      const blob = await createVideoFromHistory(1920, 1080);
      if (blob) {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `realtime-export-${new Date().toISOString()}.webm`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        dismissToast(toastId);
        addToast('Video exported successfully!', { duration: 3000 });
      } else {
        throw new Error('Failed to create video');
      }
    } catch (error) {
      console.error('Error exporting video:', error);
      dismissToast(toastId);
      addToast('Failed to export video. Please try again.', {
        duration: 3000,
        type: 'danger',
      });
    }
  };

  return (
    <ControlsRow>
      <ActionControls>
        <RichTooltip>
          <RichTooltipTrigger>
            <IconButton
              size="icon"
              variant="bare"
              onClick={handleExportVideo}
              disabled={videoExportIsExporting}
            >
              <DownloadIcon
                style={{ opacity: videoExportIsExporting ? 0.5 : 1 }}
              />
            </IconButton>
          </RichTooltipTrigger>
          <RichTooltipContent>Download video</RichTooltipContent>
        </RichTooltip>
        <RichTooltip>
          <RichTooltipTrigger>
            <IconButton size="icon" variant="bare" onClick={onRegenerate}>
              <RegenerateIcon />
            </IconButton>
          </RichTooltipTrigger>
          <RichTooltipContent>Regenerate</RichTooltipContent>
        </RichTooltip>
        <RichTooltip>
          <RichTooltipTrigger>
            <EnhanceButton
              onClick={() => handleEnhance()}
              $active={!!currentEntry && !isEnhancing}
              style={{
                cursor: isEnhancing
                  ? 'not-allowed'
                  : currentEntry
                  ? 'pointer'
                  : 'default',
                opacity: isEnhancing ? 0.5 : 1,
              }}
            >
              {isEnhancing ? 'Enhancing...' : 'Enhance'}
            </EnhanceButton>
          </RichTooltipTrigger>
          <RichTooltipContent>Enhance and add to layers</RichTooltipContent>
        </RichTooltip>
        <RichTooltip>
          <RichTooltipTrigger>
            <FullWidthButton
              size="S"
              variant="primary"
              onClick={handleAddToLayers}
              disabled={!currentEntry || isAddingLayer}
              style={{ opacity: isAddingLayer ? 0.5 : 1 }}
            >
              {isAddingLayer ? 'Adding...' : 'Add'}
            </FullWidthButton>
          </RichTooltipTrigger>
          <RichTooltipContent>Add to layers</RichTooltipContent>
        </RichTooltip>
      </ActionControls>
    </ControlsRow>
  );
};

import { CompositeSceneFullDataFragment } from 'libs/shared/data-access/graphql/src/gql/graphql';
import { useNavigate } from 'react-router-dom';
import { useWorkbench } from '@vizcom/shared/data-access/graphql';
import {
  Button,
  CarretDownIcon,
  HomeIcon,
  Menu,
  MenuDivider,
  MenuItem,
  Model3dIcon,
  Text,
  Toolbar,
  VizcomLogo,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

export const WorkbenchCompositeSceneMenu = ({
  compositeScene,
}: {
  compositeScene: CompositeSceneFullDataFragment;
}) => {
  const navigate = useNavigate();
  const workbench = useWorkbench(compositeScene.workbenchId);

  return (
    <>
      <Toolbar position="top-left" style={{ width: '250px' }}>
        <Menu
          renderLabel={(props, interactionProps) => (
            <Button variant="tertiary" size="icon" {...interactionProps}>
              <VizcomLogo />
              <CarretDownIcon />
            </Button>
          )}
        >
          <MenuItem
            label={
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <HomeIcon /> Go home
              </div>
            }
            onClick={() =>
              navigate(
                workbench.data?.folder?.id
                  ? paths.files.folder(workbench.data?.folder?.id)
                  : '/'
              )
            }
          />
          <MenuDivider />
          <MenuItem
            label="Documentation"
            onClick={() => window.open(`https://docs.vizcom.ai/`, '_blank')}
          />
          <MenuItem
            label="Account settings"
            onClick={() => navigate(paths.settings.account.profile())}
          />
        </Menu>
        <Model3dIcon style={{ margin: '0 8px' }} />
        <Text type="sh2">3D Scene</Text>
      </Toolbar>
    </>
  );
};

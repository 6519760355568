import { memoize } from 'lodash';
import {
  BlendFunction,
  DepthEffect,
  EffectComposer,
  EffectPass,
  NormalPass,
  Pass,
  RenderPass,
  SSAOEffect,
  ShaderPass,
} from 'postprocessing';
import * as THREE from 'three';

// @ts-ignore
import EnvMapTexture from '../../../../assets/environments/empty_warehouse_01_1k.hdr.jpg';
import { ultraHdrLoader } from '../../../utils/three';
import { RenderingOption } from '../types';
import { hideVizcomUiObjects } from '../utils/hideVizcomUiObjects';
import { sobelShaderMaterial } from '../utils/sobelShader';

const perspectiveCamera = new THREE.PerspectiveCamera();
const renderer = new THREE.WebGLRenderer({
  preserveDrawingBuffer: true,
  alpha: true,
});
renderer.shadowMap.enabled = true;
const effectComposer = (() => {
  const composer = new EffectComposer(renderer);

  composer.addPass(new RenderPass());

  return composer;
})();
const effectPassConfig: Record<RenderingOption, Pass[]> = {
  [RenderingOption.color]: [new RenderPass()],
  [RenderingOption.depth]: [
    new RenderPass(),
    new EffectPass(
      undefined,
      new DepthEffect({
        inverted: true,
      })
    ),
  ],
  [RenderingOption.depthPlusAO]: [
    new RenderPass(),
    new EffectPass(
      undefined,
      new DepthEffect({
        inverted: true,
      }),
      new SSAOEffect(undefined, undefined, {
        blendFunction: BlendFunction.MULTIPLY,
        worldDistanceFalloff: undefined!,
        worldDistanceThreshold: undefined!,
        worldProximityFalloff: undefined!,
        worldProximityThreshold: undefined!,
        color: new THREE.Color(0x000000),
        intensity: 2.0,
      })
    ),
  ],
  [RenderingOption.normal]: [new NormalPass()],
  [RenderingOption.sobel]: [
    new RenderPass(),
    new ShaderPass(sobelShaderMaterial.clone(), 'tDiffuse'),
  ],
  [RenderingOption.sobelDepth]: [
    new RenderPass(),
    new EffectPass(
      undefined,
      new DepthEffect({
        inverted: true,
      })
    ),
    new ShaderPass(sobelShaderMaterial.clone(), 'tDiffuse'),
  ],
};

const loadHdri = memoize(async (url: string): Promise<THREE.Texture> => {
  const texture = await ultraHdrLoader.loadAsync(url);

  texture.mapping = THREE.EquirectangularReflectionMapping;

  return texture as unknown as THREE.Texture;
});

export type SnapshotConfigProps = {
  width?: number;
  height?: number;
  type?: 'png' | 'jpg';
  quality?: number;
  noHDRI?: boolean;
  postprocessing?: RenderingOption | null;
  environmentUrl?: string;
  toneMapping?: THREE.ToneMapping;
};

export const getImperativeSnapshot3D = async (
  scene: THREE.Scene,
  camera: THREE.Camera,
  config: SnapshotConfigProps
): Promise<Blob> => {
  const originalEnvironment = scene.environment;
  if (!config.noHDRI || config.environmentUrl) {
    // doing async operation first to keep the rest of this function synchronous
    // and prevent race-conditions when updating renderer settings
    scene.environment = await loadHdri(config.environmentUrl || EnvMapTexture);
  }

  const oldToneMapping = renderer.toneMapping;
  if (config.toneMapping) {
    renderer.toneMapping = config.toneMapping;
  }

  const hidden = hideVizcomUiObjects(scene);
  const viewportWidth = config.width || window.innerWidth;
  const viewportHeight = config.height || window.innerHeight;

  renderer.setSize(viewportWidth, viewportHeight);

  perspectiveCamera.copy(camera as THREE.PerspectiveCamera);
  perspectiveCamera.aspect = viewportWidth / viewportHeight;
  perspectiveCamera.updateProjectionMatrix();

  if (config.postprocessing) {
    effectComposer.removeAllPasses();

    for (const pass of effectPassConfig[config.postprocessing]) {
      effectComposer.addPass(pass);
    }

    effectComposer.setMainCamera(perspectiveCamera);
    effectComposer.setMainScene(scene);
    effectComposer.setRenderer(renderer);
    effectComposer.setSize(
      config.width || window.innerWidth,
      config.height || window.innerHeight
    );

    effectComposer.render();
  } else {
    renderer.render(scene, perspectiveCamera);
  }

  scene.environment = originalEnvironment;
  renderer.toneMapping = oldToneMapping;
  for (const element of hidden) {
    element.visible = true;
  }

  return new Promise<Blob>((resolve, reject) => {
    renderer.domElement.toBlob(
      (blob) => {
        if (!blob) {
          return reject(new Error('Failed to create a snapshot'));
        }
        resolve(blob);
      },
      config.type || 'jpg',
      config.quality || 100
    );
  });
};

import styled from 'styled-components';
import {
  Button,
  CheckIcon,
  MeatballIcon,
  Menu,
  MenuDivider,
  MenuItem,
} from '@vizcom/shared-ui-components';

import { Palette } from './PaletteSelector';

export type VersionedPalette = Palette & {
  versions: { value: string; version: number }[];
};

type Props = {
  selectedPaletteId: string;
  palette: VersionedPalette;
  handleSelectPalette: (
    paletteId: string,
    version: number,
    paletteValue: string,
    type: string
  ) => void;
};

export const PaletteVersionsMenu = ({
  selectedPaletteId,
  palette,
  handleSelectPalette,
}: Props) => {
  return (
    <Menu
      renderLabel={(props, interactionProps) => (
        <StyledMeatballButton
          {...interactionProps}
          variant="tertiary"
          size="icon"
        >
          <MeatballIcon />
        </StyledMeatballButton>
      )}
      placement="right"
    >
      {palette.versions.map(({ value, version }, i) => (
        <MenuItem
          key={version}
          prependLabel={
            <StyledCheckIcon $visible={selectedPaletteId === value} />
          }
          label={
            <>
              v{version} {i === 0 ? <New>NEW</New> : ''}
            </>
          }
          onClick={() => {
            handleSelectPalette(palette.id, version, value, palette.type);
          }}
          closeOnClick={false}
        />
      ))}
      <MenuItem
        prependLabel={
          <StyledCheckIcon $visible={selectedPaletteId === palette.value} />
        }
        label="v1"
        onClick={() => {
          handleSelectPalette(palette.id, 1, palette.value, palette.type);
        }}
        closeOnClick={false}
      />
      <MenuDivider />
      <Button
        variant="secondary"
        as="a"
        target="_blank"
        href="https://docs.vizcom.ai/render-styles"
      >
        View Details
      </Button>
    </Menu>
  );
};

const StyledMeatballButton = styled(Button)`
  margin-left: auto;
`;

const New = styled.span`
  color: ${(p) => p.theme.text.link};
`;

const StyledCheckIcon = styled(CheckIcon)<{ $visible: boolean }>`
  visibility: ${(p) => (p.$visible ? 'visible' : 'hidden')};
`;

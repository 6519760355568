import { createPortal } from '@react-three/fiber';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Matrix3, DoubleSide, Group, Texture } from 'three';
import { v4 as uuidv4 } from 'uuid';
import { useLastValue, useStableCallback } from '@vizcom/shared-ui-components';

//force an import from MaskedTransformMaterial to have maskedTransformMaterial
import { SyncedActionPayloadFromType } from '../../../../lib/SyncedAction';
import { MutateLocalStateAction } from '../../../../lib/actions/drawing/mutateLocalStateAction';
import {
  DrawingLayer,
  useDrawingSyncedState,
} from '../../../../lib/useDrawingSyncedState';
import { ResizePositionRotationPresenter } from '../../../utils/ResizePositionRotationPresenter';
import {
  findSnapGuides,
  getBestSnapXY,
  getSnapOffset,
  SnapGuide,
  SnapGuideLines,
} from '../../../utils/Snapping';
import { VizcomRenderingOrderEntry } from '../../../utils/threeRenderingOrder';
import { LayerContent } from '../../LayersCompositor/LayerContent';
import { useBeforeActiveLayerChange } from '../../lib/useActiveLayer';
import { ActiveMask, MaskDisplayMode } from '../../selection/ActiveMask';
import {
  useOnBeforeSelectionChange,
  useSelectionApiStore,
  useSubscribeToSelectionApi,
} from '../../selection/useSelectionApi';
import {
  LayerTextureRenderer,
  LayerTextureRendererRef,
} from '../LayerTextureRenderer';
import { MaskedTransformMesh } from './MaskedTransformMaterial';
import { initializeTransformTextures, useSelectionTransform } from './helpers';
import { LayerScale, useLayerResizeTransform } from './useLayerResizeTransform';
import { useLayerRotationTransform } from './useLayerRotationTransform';
import {
  LayerTranslation,
  useLayerTranslationTransform,
} from './useLayerTranslationTransform';

//force an import from MaskedTransformMaterial to have maskedTransformMaterial

interface LayerTransformProps {
  drawingSize: [number, number];
  layer: DrawingLayer;
  layerImage: Texture | undefined;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  filterHistory: ReturnType<typeof useDrawingSyncedState>['filterHistory'];
  zIndex: number;
}

export const LayerTransformControls = ({
  drawingSize,
  layer,
  layerImage,
  handleAction,
  filterHistory,
  zIndex,
}: LayerTransformProps) => {
  const layerTextureRendererRef = useRef<LayerTextureRendererRef>(null!);
  const resizerGroupRef = useRef<Group>(null!);
  const lastLayerImageRef = useRef(layerImage?.image);

  const selectionApiStore = useSelectionApiStore();
  const selectionTexture = useSubscribeToSelectionApi((state) => state.texture);
  const selectionTexture_treatEmptyMaskAsFull = useSubscribeToSelectionApi(
    (state) => state.texture_treatEmptyMaskAsFull
  );
  const selectionTextureVersion = useSubscribeToSelectionApi(
    (state) => state.version
  );

  const croppedTexture = useMemo(
    () =>
      initializeTransformTextures(
        layerImage,
        selectionApiStore.getState().getSelectionImage(),
        selectionApiStore.getState().texture,
        drawingSize
      ),
    [layerImage, drawingSize, selectionTextureVersion, selectionApiStore]
  );

  //These are the transforms applied to the layer at rest.
  //These values are then composed with the transfom from the controls.
  const [baseRotation, setBaseRotation] = useState(0);
  const [baseTranslation, setBaseTranslation] = useState<LayerTranslation>([
    0, 0,
  ]);
  const [baseScale, setBaseScale] = useState<LayerScale>([1, 1]);

  const [snapOffset, setSnapOffset] = useState<LayerTranslation>([0, 0]);
  const [snapGuides, setSnapGuides] = useState<SnapGuide[]>([]);

  //Indicates wether one of the above values has changed.
  const hasLayerChanged = useStableCallback(() => {
    return (
      baseRotation !== 0 ||
      baseTranslation[0] !== 0 ||
      baseTranslation[1] !== 0 ||
      baseScale[0] !== 1 ||
      baseScale[1] !== 1
    );
  });

  const resetBaseTransform = useStableCallback(() => {
    setBaseRotation(0);
    setBaseTranslation([0, 0]);
    setBaseScale([1, 1]);
  });

  const updateLayerImage = useCallback(
    (undoGroupId?: string) => {
      if (!layerTextureRendererRef.current) {
        return;
      }

      handleAction(
        {
          type: 'updateLayer',
          id: layer.id,
          data: {
            image: layerTextureRendererRef.current.exportTexture(),
          },
        },
        { undoGroupId }
      );
      resetBaseTransform();
    },
    [layer.id, handleAction]
  );

  useBeforeActiveLayerChange(() => {
    if (hasLayerChanged()) {
      updateLayerImage();
    }
  });

  // expose a stable ref to the last value of the transform state, used when generating the undo/redo actions
  const transformStateRef = useLastValue({
    baseRotation,
    baseTranslation,
    baseScale,
  });
  const getMutateLocalStateAction = useStableCallback(
    (editionFunction: () => void) => {
      const snapshot = {
        rotation: 0,
        translation: [0, 0] as LayerTranslation,
        scale: [1, 1] as LayerScale,
      };

      const action = {
        type: 'mutateLocalState',
        actionId: `transform_${uuidv4()}`,
        onExecute: () => {
          snapshot.rotation = transformStateRef.current.baseRotation;
          snapshot.translation = transformStateRef.current.baseTranslation;
          snapshot.scale = transformStateRef.current.baseScale;
          editionFunction();
        },
        undoConstructor: () =>
          getMutateLocalStateAction!(() => {
            setBaseRotation(snapshot.rotation);
            setBaseTranslation(snapshot.translation);
            setBaseScale(snapshot.scale);
          }),
      } as SyncedActionPayloadFromType<typeof MutateLocalStateAction>;
      return action;
    }
  );

  const { bindRotationHandle, resetRotationTransform, rotationTransform } =
    useLayerRotationTransform(
      useStableCallback((rotation: number) => {
        handleAction(
          getMutateLocalStateAction!(() => {
            setBaseRotation(baseRotation + rotation);
            resetRotationTransform();
          })
        );
      }),
      resizerGroupRef
    );
  const {
    bindTranslationHandle,
    resetTranslationTransform,
    translationTransform,
  } = useLayerTranslationTransform(
    useStableCallback((translation: LayerTranslation) => {
      if (computedTransform.rotation === 0) {
        const bboxInit = croppedTexture?.boundingBox;
        const x =
          (bboxInit?.centerDeltaX ?? 0) + baseTranslation[0] + translation[0];
        const y =
          (bboxInit?.centerDeltaY ?? 0) + baseTranslation[1] + translation[1];
        const w = (bboxInit?.width ?? 0) * baseScale[0];
        const h = (bboxInit?.height ?? 0) * baseScale[1];
        const boundingBox = {
          left: x - w / 2,
          right: x + w / 2,
          top: y - h / 2,
          bottom: y + h / 2,
        };

        const { guides } = findSnapGuides(
          [{ x: 0, y: 0, width: drawingSize[0], height: drawingSize[1] }],
          boundingBox
        );

        setSnapGuides(guides);

        const { bestSnapX, bestSnapY } = getBestSnapXY(guides, { x, y });

        setSnapOffset(
          getSnapOffset(bestSnapX, bestSnapY, boundingBox) as LayerTranslation
        );
      }
    }),
    useStableCallback((translation: LayerTranslation) => {
      handleAction(
        getMutateLocalStateAction!(() => {
          setBaseTranslation([
            baseTranslation[0] + translation[0] + snapOffset[0],
            baseTranslation[1] + translation[1] + snapOffset[1],
          ]);

          resetTranslationTransform();

          setSnapGuides([]);
          setSnapOffset([0, 0]);
        })
      );
    }),
    resizerGroupRef
  );
  const {
    bindResizeHandle,
    resetResizeTransform,
    scaleTransform,
    translationTransform: resizeTranslationTransform,
  } = useLayerResizeTransform(
    useStableCallback((scale: LayerScale, translation: LayerTranslation) => {
      handleAction(
        getMutateLocalStateAction!(() => {
          setBaseScale([baseScale[0] * scale[0], baseScale[1] * scale[1]]);
          setBaseTranslation([
            baseTranslation[0] + translation[0],
            baseTranslation[1] + translation[1],
          ]);
          resetResizeTransform();
        })
      );
    }),
    resizerGroupRef,
    (croppedTexture?.boundingBox.width ?? 0) * baseScale[0],
    (croppedTexture?.boundingBox.height ?? 0) * baseScale[1],
    baseRotation + rotationTransform
  );

  // using `useLayoutEffect` instead of `useEffect` to make sure we can export the texture before LayerTextureRenderer is unmounted
  // and we lose the reference to the updated texture
  useLayoutEffect(() => {
    return () => {
      if (hasLayerChanged()) {
        filterHistory((action) =>
          Boolean(
            action.payload.type === 'mutateLocalState' &&
              action.payload.actionId?.startsWith('transform_')
          )
        );
        const undoGroupId = uuidv4();
        updateLayerImage(undoGroupId);
        selectionApiStore.getState().editSelectionCanvas((ctx) => {
          ctx.putImageData(selectionToImageData(), 0, 0);
        }, undoGroupId);
      }
    };
  }, [layer.id]); // commit update when unmounting or when layer id changes

  useOnBeforeSelectionChange(() => {
    if (hasLayerChanged()) {
      filterHistory((action) =>
        Boolean(
          action.payload.type === 'mutateLocalState' &&
            action.payload.actionId?.startsWith('transform_')
        )
      );
      updateLayerImage();
    }
  });

  useEffect(() => {
    resetRotationTransform();
    resetTranslationTransform();
    resetResizeTransform();
  }, [selectionTextureVersion]);

  const { scene, selectionFBO, selectionToImageData } =
    useSelectionTransform(drawingSize);

  if (lastLayerImageRef.current !== layerImage?.image) {
    // when layer image changes, reset the transform
    // this let us keep the transforms while the new image is being passed through handleAction
    // and only reset the transform when the new image is ready
    // this synchronize both actions to prevent flickering
    lastLayerImageRef.current = layerImage?.image;
    resetResizeTransform();
    resetTranslationTransform();
    resetRotationTransform();
  }

  if (!croppedTexture?.texture) {
    return null;
  }

  const computedTransform = {
    rotation: baseRotation + rotationTransform,
    translation: [
      croppedTexture.boundingBox.centerDeltaX +
        baseTranslation[0] +
        translationTransform[0] +
        resizeTranslationTransform[0] +
        snapOffset[0],
      croppedTexture.boundingBox.centerDeltaY +
        baseTranslation[1] +
        translationTransform[1] +
        resizeTranslationTransform[1] +
        snapOffset[1],
    ],
    scale: [baseScale[0] * scaleTransform[0], baseScale[1] * scaleTransform[1]],
  };

  const inverseTransform = new Matrix3();
  inverseTransform
    .translate(
      -croppedTexture.boundingBox.centerDeltaX,
      -croppedTexture.boundingBox.centerDeltaY
    )
    .scale(computedTransform.scale[0], computedTransform.scale[1])
    .rotate(-computedTransform.rotation)
    .translate(
      computedTransform.translation[0],
      computedTransform.translation[1]
    )
    .invert();

  return (
    <>
      <SnapGuideLines snapGuides={snapGuides} />
      <group
        ref={resizerGroupRef}
        position={[
          computedTransform.translation[0],
          computedTransform.translation[1],
          0,
        ]}
        userData={{
          vizcomRenderingOrder: [
            {
              zIndex: 3,
            } satisfies VizcomRenderingOrderEntry,
          ],
        }}
      >
        {/* using another child group for the rotation to keep the parent group stable when rotation and prevent flickering of the pointer position because of worldToLocal conversion  */}
        <group rotation={[0, 0, computedTransform.rotation]}>
          <ResizePositionRotationPresenter
            active
            width={
              croppedTexture?.boundingBox.width * computedTransform.scale[0]
            }
            height={
              croppedTexture?.boundingBox.height * computedTransform.scale[1]
            }
            moveHandleMeshProps={bindTranslationHandle() as any}
            resizeHandleMeshPropsGetter={bindResizeHandle as any}
            rotationHandleMeshPropsGetter={bindRotationHandle as any}
            rotation={computedTransform.rotation}
          />
        </group>
      </group>
      <LayerContent
        id={layer.id}
        opacity={layer.opacity}
        blendMode={layer.blendMode}
        visible={layer.visible}
        zIndex={zIndex}
        type={'vizcom:toolLayerContent'}
      >
        <LayerTextureRenderer
          width={drawingSize[0]}
          height={drawingSize[1]}
          ref={layerTextureRendererRef}
        >
          {croppedTexture?.alphaMap && (
            <MaskedTransformMesh
              drawingSize={drawingSize}
              selectionTexture={selectionTexture}
              layerImage={layerImage}
              inverseTransform={inverseTransform}
            />
          )}
          {!croppedTexture?.alphaMap && (
            <group
              position={[
                computedTransform.translation[0],
                computedTransform.translation[1],
                0,
              ]}
              rotation={[0, 0, computedTransform.rotation]}
              userData={{
                vizcomRenderingOrder: [
                  {
                    zIndex: 1,
                  } satisfies VizcomRenderingOrderEntry,
                ],
              }}
            >
              <mesh
                scale={[
                  computedTransform.scale[0],
                  computedTransform.scale[1],
                  1,
                ]}
              >
                <planeGeometry
                  args={[
                    croppedTexture?.boundingBox.width,
                    croppedTexture?.boundingBox.height,
                  ]}
                />
                <meshBasicMaterial
                  map={croppedTexture?.texture}
                  transparent
                  depthTest={false}
                  side={DoubleSide}
                />
              </mesh>
            </group>
          )}
        </LayerTextureRenderer>
      </LayerContent>

      {croppedTexture.alphaMap && (
        <>
          {/*render transformed selection*/}
          {createPortal(
            <>
              <group
                position={[
                  computedTransform.translation[0],
                  computedTransform.translation[1],
                  0,
                ]}
                rotation={[0, 0, computedTransform.rotation]}
                userData={{
                  vizcomRenderingOrder: [
                    {
                      zIndex: 2,
                    } satisfies VizcomRenderingOrderEntry,
                  ],
                }}
              >
                <mesh
                  scale={[
                    computedTransform.scale[0],
                    computedTransform.scale[1],
                    1,
                  ]}
                >
                  <planeGeometry
                    args={[
                      croppedTexture?.boundingBox.width,
                      croppedTexture?.boundingBox.height,
                    ]}
                  />
                  <meshBasicMaterial
                    map={
                      croppedTexture?.alphaMap ||
                      selectionTexture_treatEmptyMaskAsFull
                    }
                    transparent
                    depthTest={false}
                    side={DoubleSide}
                  />
                </mesh>
              </group>
            </>,
            scene
          )}
          {/*display transformed selection*/}
          <ActiveMask
            drawingSize={drawingSize}
            maskTexture={selectionFBO.texture}
            mode={MaskDisplayMode.MARCHING_ANTS}
          />
        </>
      )}
    </>
  );
};

import { hexToRgba } from 'libs/shared/ui/components/src/lib/ColorPicker/utils/convert';
import { useState } from 'react';
import styled from 'styled-components';
import {
  useOrganization,
  AssetColorData,
  LibraryType,
  useAssetColors,
  useCreateWorkbenchColor,
  useWorkbenchFoldersIds,
  useCreateFolderColor,
} from '@vizcom/shared/data-access/graphql';
import {
  addToast,
  BlockStack,
  type ColorPickerBaseProps,
  ColorPicker as ColorPickerUI,
  Divider,
  FeatureFlagged,
  formatErrorMessage,
  InfiniteScrollPage,
  InlineFlex,
  VizcomLogoAnimated,
} from '@vizcom/shared-ui-components';

import { Cursors } from './AssetLibrary';
import { SaveColorButton } from './SaveColorButton';
import { SelectLibraryType } from './SelectLibraryType';
import { assetColorDataToHex } from './assetLibraryHelpers';
import { EmptyColorsState } from './colors/EmptyColorsState';
import { triggerCreateConfirmationModal } from './colors/triggerCreateConfirmationModal';

type ColorLibraryPickerProps = ColorPickerBaseProps & {
  workbenchId: string;
};

export const ColorLibraryPicker = ({
  color,
  onChange,
  onEyeDropperClicked,
  className,
  workbenchId,
}: ColorLibraryPickerProps) => {
  return (
    <BlockStack $gap={16}>
      <ColorPickerUI
        color={color}
        onChange={onChange}
        onEyeDropperClicked={onEyeDropperClicked}
        className={className}
      />
      <FeatureFlagged flag="ASSETS_LIBRARY">
        <Divider />
        <CompactColorLibrary
          color={color}
          workbenchId={workbenchId}
          onLibraryColorSelected={(color) =>
            onChange(assetColorDataToHex(color))
          }
        />
      </FeatureFlagged>
    </BlockStack>
  );
};

type CompactColorLibraryProps = {
  workbenchId: string;
  color: string;
  onLibraryColorSelected: (color: AssetColorData) => void;
};

const CompactColorLibrary = ({
  workbenchId,
  onLibraryColorSelected,
  color,
}: CompactColorLibraryProps) => {
  const [libraryType, setLibraryType] = useState<LibraryType>(
    LibraryType.Workbench
  );

  const [, createWorkbenchColor] = useCreateWorkbenchColor();
  const [, createFolderColor] = useCreateFolderColor();
  const { data: workbench } = useWorkbenchFoldersIds(workbenchId);
  const { data: organization } = useOrganization(workbench?.orgId);

  const [colorsCursors, setColorsCursors] = useState<Cursors>([null]);

  if (!workbench || !organization) return null;

  const onSaveColor = async () => {
    const { r, g, b } = hexToRgba(color);

    const payload = {
      name: color,
      red: r,
      green: g,
      blue: b,
    };

    try {
      await triggerCreateConfirmationModal(libraryType, organization.name);
    } catch {
      // User clicked cancel
      return;
    }

    const res =
      libraryType === LibraryType.Workbench
        ? await createWorkbenchColor({
            input: {
              workbenchAssetColor: {
                ...payload,
                workbenchId,
              },
            },
          })
        : await createFolderColor({
            input: {
              folderAssetColor: {
                ...payload,
                folderId:
                  libraryType === LibraryType.Personal
                    ? workbench.userFolderId!
                    : workbench.orgFolderId!,
              },
            },
          });

    if (res.error) {
      return addToast('Failed to save color', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }

    addToast('New color saved', { type: 'success' });
  };

  return (
    <BlockStack $gap={10}>
      <InlineFlex $gap={15}>
        <SelectLibraryType
          organization={organization}
          selectedLibraryType={libraryType}
          setSelectedLibraryType={setLibraryType}
          setColorsCursors={setColorsCursors}
        />
        <SaveColorButton onClick={onSaveColor} title="Save active color" />
      </InlineFlex>
      <ScrollableContainer>
        <ColorsResultPageContainer>
          {colorsCursors.map((cursor, index) => (
            <ColorsResultPage
              key={`${index}-${libraryType}`} // Re-create the component when the library type changes to prevent infinite scroll completion.
              workbenchId={workbenchId}
              cursor={cursor}
              libraryType={libraryType}
              setColorsCursors={setColorsCursors}
              onLibraryColorSelected={onLibraryColorSelected}
            />
          ))}
        </ColorsResultPageContainer>
      </ScrollableContainer>
    </BlockStack>
  );
};

type ColorsResultPageProps = {
  workbenchId: string;
  cursor: string | null;
  libraryType: LibraryType;
  setColorsCursors: React.Dispatch<React.SetStateAction<Cursors>>;
  onLibraryColorSelected: (color: AssetColorData) => void;
};

export const ColorsResultPage = ({
  workbenchId,
  cursor,
  libraryType,
  setColorsCursors,
  onLibraryColorSelected,
}: ColorsResultPageProps) => {
  const { data, pageInfo, fetching } = useAssetColors(
    libraryType,
    workbenchId,
    cursor
  );

  if (!data) return null;

  const { hasNextPage, endCursor } = pageInfo || {};

  if (data.length === 0 && !fetching) {
    return <EmptyColorsState />;
  }

  return (
    <InfiniteScrollPage
      loadMore={() => setColorsCursors((cursors) => [...cursors, endCursor])}
      hasNextPage={hasNextPage}
    >
      {data.map((color) => (
        <ColorLibraryItemStyled
          key={color.id}
          $red={color.red}
          $green={color.green}
          $blue={color.blue}
          onClick={() => onLibraryColorSelected(color)}
        />
      ))}
      {fetching && <VizcomLogoAnimated />}
    </InfiniteScrollPage>
  );
};

const ScrollableContainer = styled.div`
  max-height: 130px;
  overflow-y: scroll;
  ${({ theme }) => theme.scrollbar.dark}
`;

const ColorLibraryItemStyled = styled.div<{
  $red: number;
  $green: number;
  $blue: number;
}>`
  width: 28px;
  height: 28px;
  background-color: rgb(
    ${({ $red }) => $red},
    ${({ $green }) => $green},
    ${({ $blue }) => $blue}
  );
  border-radius: ${({ theme }) => theme.borderRadius.s};
  box-shadow: 0 0 0 2px ${({ theme }) => theme.surface.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
`;

const ColorsResultPageContainer = styled.div`
  display: grid;
  align-content: center;
  grid-template-columns: repeat(6, 1fr);
  gap: 8px 8px;
  padding: 2px 5px 10px 5px;
  justify-content: center;
  align-items: center;
  justify-items: center;
`;

import {
  RemoveImagesFromPaletteMutation,
  publishTrackingEvent,
  urqlClient,
} from '@vizcom/shared/data-access/graphql';
import { trackEvent } from '@vizcom/shared-data-access-analytics';

import {
  SyncedActionPayloadFromType,
  SyncedActionType,
} from '../../SyncedAction';
import { ClientSideWorkbenchElementData } from '../../clientState';
import { elementById } from '../../utils';
import { InsertImagesToPaletteAction } from './insertImagesToPaletteAction';

export const RemoveImagesFromPaletteAction: SyncedActionType<
  ClientSideWorkbenchElementData[],
  {
    type: 'removeImagesFromPalette';
    id: string;
    imageIds: string[];
  }
> = {
  type: 'removeImagesFromPalette',
  optimisticUpdater: ({ payload }, elements) => {
    const element = elementById(elements, payload.id);

    if (element?.__typename === 'WorkbenchElementPalette') {
      // TODO: optimisticUpdater
    }
  },
  remoteUpdater: async ({ payload }) => {
    const res = await urqlClient.mutation(RemoveImagesFromPaletteMutation, {
      input: {
        ids: payload.imageIds,
      },
    });

    if (res?.error) {
      throw new Error(
        `Error while removing images from palette, please retry. ${
          res.error.graphQLErrors[0]?.message ?? res.error.message
        }`
      );
    }
    trackEvent('Remove images from palette', {
      paletteId: payload.id,
      images: payload.imageIds.length,
    });
    publishTrackingEvent({
      type: 'REMOVE_IMAGES',
      data: {
        paletteId: payload.id,
        imagesCount: payload.imageIds.length,
      },
    });
  },
  undoConstructor: ({ payload }, elements) => {
    const element = elementById(elements, payload.id);
    if (!element || element.__typename !== 'WorkbenchElementPalette') {
      return null;
    }
    const sourceImages = element.sourceImages.nodes
      .filter((node) => payload.imageIds.includes(node.id))
      .map((node) => ({
        id: node.id,
        image: node.imagePath,
      }));

    const undoPayload: SyncedActionPayloadFromType<
      typeof InsertImagesToPaletteAction
    > = {
      type: 'insertImagesToPalette',
      id: payload.id,
      sourceImages,
    };
    return undoPayload;
  },
};

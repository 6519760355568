import { useThree } from '@react-three/fiber';
import { AutoPromptType } from 'libs/shared/data-access/graphql/src/gql/graphql';
import {
  ChangeEventHandler,
  MouseEventHandler,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import styled, { useTheme } from 'styled-components';
import { Group } from 'three';
import { v4 as uuidv4 } from 'uuid';
import { WorkbenchElementImg2ImgData } from '@vizcom/shared/data-access/graphql';
import { filterExists } from '@vizcom/shared/js-utils';
import {
  Button,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  TextArea,
  addToast,
} from '@vizcom/shared-ui-components';

import { Img2ImgPlaceholderInput } from '../../../lib/actions/workbench/triggerAiImg2ImgAction';
import { useWorkbenchSyncedState } from '../../../lib/useWorkbenchSyncedState';
import {
  GENERATED_IMAGES_MARGIN,
  isDraggingContext,
  useIsWorkbenchViewer,
} from '../../../lib/utils';
import { WorkbenchElementDragConnector } from '../../elementConnector/workbenchElementDragConnector';
import {
  getElementSize,
  getWorkbenchElementZPositionRange,
} from '../../helpers';
import {
  filterChildByWorkbenchElementUserData,
  findNearestParentObjectWithWorkbenchElementUserData,
} from '../../objectsUserdata';
import { promptContainsArtistName } from '../../studio/constants';
import { DescribeButton } from '../../studio/create/DescribeButton';
import { useAutoPrompt } from '../../studio/useAutoPrompt';
import { CustomHtml } from '../../utils/CustomHtml';
import { RoundedPlaneGeometry } from '../../utils/RoundedPlaneGeometry';
import { ViewportFlipGroup } from '../../utils/ViewportFlipGroup';
import { ZoomFallbackGroup } from '../../utils/ZoomFallbackGroup';
import { findFirstFreeSlotFromElements } from '../../utils/freeSlotFinders';
import { MIN_IMG_2_IMG_SIZE } from '../../utils/getContentSize';
import { WorkbenchElementImg2ImgExtra } from './WorkbenchElementImg2ImgExtra';

interface WorkbenchElementImg2ImgProps {
  workbenchId: string;
  element: WorkbenchElementImg2ImgData;
  focused: boolean;
  singleFocused: boolean;
  isDragging: boolean;
  isResizing: boolean;
  showSingleFocusedControls: boolean;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
  getDrawingImageData: (drawingId: string) => Promise<ImageData | null>;
}

export const WorkbenchElementImg2Img = ({
  element,
  singleFocused,
  workbenchId,
  isDragging,
  isResizing,
  showSingleFocusedControls,
  handleAction,
  getDrawingImageData,
}: WorkbenchElementImg2ImgProps) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const scene = useThree((s) => s.scene);
  const isDraggingRef = useContext(isDraggingContext);
  const theme = useTheme();
  const isViewer = useIsWorkbenchViewer();
  const [cursor, setCursor] = useState<[number | null, number | null] | null>(
    null
  );
  const ref = useRef<Group>(null);

  const { triggerAutoPrompt, cancelAutoPrompt, isGeneratingPrompt } =
    useAutoPrompt({
      workbenchPaletteId: element.workbenchPaletteId,
      inputType: AutoPromptType.Image,
      getCompositeImage: async () => {
        if (!element.sourceDrawingId) {
          return null;
        }

        return await getDrawingImageData(element.sourceDrawingId);
      },
      setPrompt: (text) => {
        handleAction({
          type: 'updateAiImg2Img',
          elementId: element.id,
          prompt: text,
        });
      },
      getSelectionImage: () => undefined,
    });

  useLayoutEffect(() => {
    if (inputRef.current) {
      const cursor1 = cursor?.[0] ?? inputRef.current.selectionStart;
      const cursor2 = cursor?.[1] ?? inputRef.current.selectionEnd;

      [inputRef.current.selectionStart, inputRef.current.selectionEnd] = [
        cursor1,
        cursor2,
      ];

      inputRef.current.value = element.prompt;
    }
  }, [element.prompt, cursor]);

  const handlePromptChange: ChangeEventHandler<HTMLTextAreaElement> = ({
    target: { value, selectionStart, selectionEnd },
  }) => {
    setCursor([selectionStart, selectionEnd]);
    handleAction({
      type: 'updateAiImg2Img',
      elementId: element.id,
      prompt: value,
    });
  };

  const handleSubmit: MouseEventHandler = (e) => {
    if (isDraggingRef.current) {
      return;
    }
    e.preventDefault();
    if (!element.sourceDrawingId) {
      addToast('You need to connect this block to an image first', {
        type: 'danger',
      });
      return;
    }

    if (promptContainsArtistName(element.prompt)) {
      return;
    }

    const placeholders: Img2ImgPlaceholderInput[] = [];
    handleAction((elements) => {
      const target = elements.find((el) => el.id === element.sourceDrawingId);
      if (target?.__typename !== 'Drawing') {
        return;
      }
      const width = target.drawingWidth * target.workbenchSizeRatio;
      const height = target.drawingHeight * target.workbenchSizeRatio;

      const placeholderIds = element.sourceImageInfluences
        .filter(filterExists)
        .map(() => uuidv4());

      const children = filterChildByWorkbenchElementUserData(scene, () => true);
      const els = children.map((object) => ({
        x: object.userData.elementX,
        y: object.userData.elementY,
        width: object.userData.elementWidth,
        height: object.userData.elementHeight,
      }));

      const opts = {
        firstSlotX:
          element.x + element.width / 2 + width / 2 + GENERATED_IMAGES_MARGIN,
        firstSlotY: element.y,
        slotWidth: width + GENERATED_IMAGES_MARGIN,
        slotHeight: height + GENERATED_IMAGES_MARGIN,
        maxElementPerLine: 4,
      };

      placeholderIds.forEach((id, i) => {
        const position = findFirstFreeSlotFromElements(
          [...els, ...placeholders],
          opts
        );
        const zRange = getWorkbenchElementZPositionRange(scene);

        placeholders.push({
          id,
          x: position[0],
          y: position[1],
          width,
          height,
          zIndex: isFinite(zRange[1]) ? zRange[1] + 1 : element.zIndex + 1,
        });
      });

      return {
        type: 'triggerAiImg2Img',
        elementId: element.id,
        placeholders,
      };
    });
  };

  const { width, height } = getElementSize(element);
  const contentScale = height / MIN_IMG_2_IMG_SIZE[1];

  const fallback = (
    <mesh position={[0, 0, 0]}>
      <RoundedPlaneGeometry width={width} height={height} radius={7.5} />
      <meshBasicMaterial color={theme.surface.primary} transparent />
    </mesh>
  );

  // element.sourceImageInfluences is (number | null)[]
  // The influence is either a single generation or a batch generation
  // of 2 to 4 images. The influence input allows unset values, so we
  // need to check if the array contains enough values to trigger the
  // generation (1 for single, 2-4 for batch).
  const generationDisabled =
    isViewer ||
    (element.sourceImageInfluences.length === 4 &&
      element.sourceImageInfluences.filter(filterExists).length < 2) ||
    (element.sourceImageInfluences.length === 1 &&
      element.sourceImageInfluences.filter(filterExists).length !== 1);

  const generateText =
    element.sourceImageInfluences.filter(filterExists).length < 2
      ? 'Generate'
      : `Generate ${
          element.sourceImageInfluences.filter(filterExists).length
        } images`;

  return (
    <>
      {showSingleFocusedControls && !isResizing && (
        <WorkbenchElementDragConnector
          element={element}
          handleAction={handleAction}
          triggerAutoPrompt={triggerAutoPrompt}
        />
      )}

      <ZoomFallbackGroup fallback={fallback}>
        <group ref={ref}>
          <CustomHtml
            transform
            occlude
            scaleOcclusionGeometry={false}
            scale={[contentScale, contentScale, 1]}
            parentScale={
              ref.current
                ? findNearestParentObjectWithWorkbenchElementUserData(
                    ref.current
                  )
                : undefined
            }
            geometry={
              <RoundedPlaneGeometry
                radius={16}
                width={width / contentScale}
                height={MIN_IMG_2_IMG_SIZE[1]}
              />
            }
          >
            <div
              style={{
                width: width / contentScale,
                height: MIN_IMG_2_IMG_SIZE[1],
                userSelect: 'none',
              }}
            >
              <Img2Img data-workbench-id={element.id}>
                <Title>
                  <span>Prompt</span>
                  <DescribeButton
                    showButton={!!element.sourceDrawingId}
                    isGeneratingPrompt={isGeneratingPrompt}
                    triggerAutoPrompt={triggerAutoPrompt}
                    cancelAutoPrompt={cancelAutoPrompt}
                  />
                </Title>
                <Img2ImgTextArea
                  ref={inputRef}
                  defaultValue={element.prompt}
                  onChange={handlePromptChange}
                  // workbench-role is used to programatically focus this field when creating a new img2img element
                  data-workbench-role="promptInput"
                  onKeyDown={(e) => e.stopPropagation()}
                  // prevent using the cursor to select text from dragging the element itself, if already focused
                  onPointerMove={(e) => {
                    const target = e.target as HTMLTextAreaElement;
                    if (singleFocused && !isDragging) {
                      e.stopPropagation();
                    } else if (isDragging) {
                      target.blur();
                    }
                  }}
                  placeholder="What are you creating?"
                  disabled={isViewer}
                  $isDragging={isDragging}
                  onFocus={() => {
                    cancelAutoPrompt();
                  }}
                />
                <RichTooltip placement="bottom" disabled={!generationDisabled}>
                  <RichTooltipTrigger>
                    <GenerateButton
                      onClick={(e) => {
                        if (!isDragging) {
                          handleSubmit(e);
                        }
                      }}
                      variant="primary"
                      size="M"
                      disabled={generationDisabled}
                      $isDragging={isDragging}
                    >
                      <span>{generateText}</span>
                    </GenerateButton>
                  </RichTooltipTrigger>
                  <RichTooltipContent style={{ color: theme.deprecated.white }}>
                    {element.sourceImageInfluences.length === 4
                      ? 'Drawing Influence must include 2-4 values'
                      : 'Drawing Influence must include 1 value'}
                  </RichTooltipContent>
                </RichTooltip>
              </Img2Img>
            </div>
          </CustomHtml>
        </group>
      </ZoomFallbackGroup>

      {!isDragging && singleFocused && !isViewer && !isResizing && (
        <ViewportFlipGroup position={[0, height / 2, 0]} pivot={element.y}>
          {({ flipped }) => (
            <CustomHtml style={{ pointerEvents: 'none' }}>
              <WorkbenchElementImg2ImgExtra
                element={element}
                workbenchId={workbenchId}
                handleAction={handleAction}
                flipped={flipped}
              />
            </CustomHtml>
          )}
        </ViewportFlipGroup>
      )}
    </>
  );
};

const Img2Img = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 16px;
  gap: 10px;
  background: ${({ theme }) => theme.surface.primary};
  border-radius: ${(p) => p.theme.borderRadius.m};
  color: ${({ theme }) => theme.text.body};
  cursor: move;
`;

const Img2ImgTextArea = styled(TextArea)<{ $isDragging: boolean }>`
  cursor: ${(p) => (p.$isDragging ? 'move' : 'text')};
  height: 100%;
`;

const GenerateButton = styled(Button)<{ $isDragging: boolean }>`
  cursor: ${(p) => (p.$isDragging ? 'move' : 'pointer')};
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;

  > div {
    font-weight: 400;
  }
`;

import { useTheme } from 'styled-components';
import {
  Button,
  CloseIcon,
  Import3DModelIcon,
  ToolbarDivider,
  Tooltip,
} from '@vizcom/shared-ui-components';

import CameraIcon from '../../../assets/icons/camera.svg';
import CheckIcon from '../../../assets/icons/check.svg';
import { useCompositeSceneEditorContext } from '../compositeSceneEditor/context';
import { Icon } from '../ui/Icon/Icon';
import { ToolbarContainer, Toolbar } from './style';

const GeneralToolbar = ({ onExit }: { onExit: () => void }) => {
  const { uploadInputRef } = useCompositeSceneEditorContext();
  const theme = useTheme();

  return (
    <>
      <Tooltip tip="Upload model (.fbx, .gltf, .glb, .stl, .obj)">
        <Button
          variant="tertiary"
          size="icon"
          onClick={() => {
            if (!uploadInputRef) {
              return;
            }

            uploadInputRef.click();
          }}
        >
          <Import3DModelIcon style={{ color: theme.icon.primary }} />
        </Button>
      </Tooltip>
      <ToolbarDivider />
      <Button onClick={onExit} variant="secondary" size="icon">
        <CloseIcon />
      </Button>
    </>
  );
};

const ActiveCameraToolbar = () => {
  const theme = useTheme();
  const { setActiveCamera } = useCompositeSceneEditorContext();

  return (
    <>
      <Button variant="tertiary" size="icon" disabled>
        <Icon icon={CameraIcon} color={theme.text.subtext} size={24} />
      </Button>
      <Button
        onClick={() => {
          setActiveCamera(null);
        }}
        variant="primary"
        size="icon"
        style={{
          backgroundColor: theme.deprecated.tertiary.default,
        }}
      >
        <Icon
          icon={CheckIcon}
          color={theme.deprecated.secondary.default}
          size={18}
        />
      </Button>
    </>
  );
};

export const CompositeSceneToolbar = ({ onExit }: { onExit: () => void }) => {
  const { activeCamera } = useCompositeSceneEditorContext();

  return (
    <ToolbarContainer>
      <Toolbar>
        {activeCamera ? (
          <ActiveCameraToolbar />
        ) : (
          <GeneralToolbar onExit={onExit} />
        )}
      </Toolbar>
    </ToolbarContainer>
  );
};

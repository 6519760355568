import styled from 'styled-components';
import { NumberInput, RangeInput, Text } from '@vizcom/shared-ui-components';

import { expScale, logScale } from './mathHelpers';

export const SLIDER_ID = 'toolbar-slider';

export const ToolbarSlider = ({
  isHideSliders,
  value,
  setValue,
  label,
  min = 0,
  max = 1,
  step = 0.01,
  unit,
  multiplier = 1,
  sliderWidth = 72,
  isLogScale = false,
  disabled = false,
  style,
  relativeCenter = undefined,
}: {
  isHideSliders?: boolean;
  value: number;
  setValue: (v: number) => void;
  label?: string;
  min?: number;
  max?: number;
  step?: number;
  unit?: string;
  multiplier?: number;
  sliderWidth?: number;
  isLogScale?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  relativeCenter?: number | undefined;
}) => {
  return (
    <SliderContainer id={SLIDER_ID} style={style}>
      {label && (
        <Text color="subtext" type="b1">
          {label}
        </Text>
      )}
      <InteractiveContainer>
        {!isHideSliders && (
          <RangeContainer sliderWidth={sliderWidth}>
            <RangeInput
              variant="primary"
              thickness="medium-thick"
              value={isLogScale ? logScale(value, min, max) : value}
              min={isLogScale ? 0 : min}
              max={isLogScale ? 1 : max}
              step={isLogScale ? 0.01 : step}
              onChange={({ target: { value } }) =>
                setValue(
                  isLogScale ? Math.round(expScale(+value, min, max)) : +value
                )
              }
              disabled={disabled}
              relativeCenter={relativeCenter}
            />
          </RangeContainer>
        )}
        <NumberContainer style={{ width: 46 }}>
          <NumberInput
            value={value * multiplier}
            setValue={(v) => setValue(v / multiplier)}
            min={min}
            max={max * multiplier}
            unit={unit}
            dragArrows={false}
            style={{ width: '100%' }}
            valueStyle={{ width: '100%' }}
            disabled={disabled}
          />
        </NumberContainer>
      </InteractiveContainer>
    </SliderContainer>
  );
};

const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
`;

const InteractiveContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const RangeContainer = styled.div<{ sliderWidth?: number }>`
  width: ${({ sliderWidth }) => sliderWidth}px;
`;

const NumberContainer = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.m};
  height: 24px;

  background-color: ${({ theme }) => theme.surface.secondary};

  display: flex;
  align-items: center;
  justify-content: center;
`;

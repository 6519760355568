import { useNavigate } from 'react-router-dom';
import {
  OrganizationData,
  OrganizationSubscriptionPlan,
  OrganizationTeamsUsers,
  TeamRole,
  useDeleteOrganizationInvite,
  useResendOrganizationInvite,
  useUpdateOrganizationInvite,
} from '@vizcom/shared/data-access/graphql';
import { capitalize } from '@vizcom/shared/js-utils';
import {
  TableRow,
  TableCell,
  ContextMenu,
  ContextMenuItem,
  MenuHorizontalIcon,
  Text,
  LoadingLogoInset,
  triggerConfirmModal,
  FormattedDate,
  Checkbox,
  SelectionCell,
  ActionsCell,
  addToast,
  formatErrorMessage,
  useCrisp,
  ConfirmationModalComponent,
  UserCircleIcon,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

import {
  MEMBERS_TABLE_SIZING,
  OrganizationInvite,
} from './OrganizationMembers';
import { UserTeamsComboBox } from './UserTeamsComboBox';
import {
  shouldDisplayLimitationErrorToast,
  showLimitationErrorToast,
} from './limitationToastMessage';
import { MemberRoleSelect } from './styles';

export const InvitedMemberRow = (props: {
  invite: OrganizationInvite;
  organization: OrganizationData;
  canEdit: boolean;
  selected: boolean;
  teams: OrganizationTeamsUsers;
  onSelectedChange: () => void;
}) => {
  const { invite, organization, canEdit, teams } = props;

  const navigate = useNavigate();
  const crisp = useCrisp();

  const [deleteRes, deleteInvite] = useDeleteOrganizationInvite();
  const [updateRes, updateOrganizationInvite] = useUpdateOrganizationInvite();
  const [resendRes, resendOrganizationInvite] = useResendOrganizationInvite();

  const handleInviteChangeRole = async (role: string) => {
    const res = await updateOrganizationInvite({
      input: {
        organizationId: organization.id,
        email: invite.email,
        patch: {
          role: role as any,
        },
      },
    });

    if (res.error) {
      if (shouldDisplayLimitationErrorToast(res.error)) {
        return showLimitationErrorToast(
          res.error,
          organization.id,
          navigate,
          crisp.openChat
        );
      }

      return addToast('Error changing role', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }

    return addToast('Role changed');
  };

  const handleDeleteInvite = async () => {
    try {
      await triggerConfirmModal({
        body: (onConfirm, onCancel) => (
          <ConfirmationModalComponent
            onConfirm={onConfirm}
            variant="danger"
            onCancel={onCancel}
            title={`Remove ${invite.email} invitation?`}
            description="This invitation will be deleted, and the related individual won't be able to join the workspace anymore. You can send a new invite at any time."
          />
        ),
      });
    } catch {
      // User clicked cancel
      return;
    }

    const res = await deleteInvite({
      input: {
        organizationId: organization.id,
        email: invite.email,
      },
    });

    if (res.error) {
      return addToast('Error while deleting invitation', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }

    if (
      invite.role !== TeamRole.Viewer &&
      organization.subscriptionPlan !== OrganizationSubscriptionPlan.Free &&
      !organization.manualSubscription
    ) {
      addToast('Invitation deleted', {
        duration: 5000,
        secondaryText: "You can remove a seat if you don't need it anymore.",
        cta: {
          text: 'Remove seat',
          url: paths.settings.organization.membersSubscriptionUpdate(
            organization.id
          ),
        },
      });
    } else {
      addToast('Invitation deleted', {
        secondaryText:
          "This individual won't be able to join the workspace anymore.",
      });
    }
  };

  const handleResendInvite = async () => {
    const res = await resendOrganizationInvite({
      input: {
        organizationId: organization.id,
        invitesEmails: [invite.email],
        usersIds: [],
      },
    });

    if (res.error) {
      addToast(`Error while resending invitation`, {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    } else {
      addToast('Invitation resent');
    }
  };

  return (
    <TableRow style={{ position: 'relative' }}>
      <SelectionCell>
        <Checkbox checked={props.selected} onClick={props.onSelectedChange} />
      </SelectionCell>

      <TableCell
        size={MEMBERS_TABLE_SIZING.name}
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          gap: 10,
          justifyContent: 'flex-start',
        }}
      >
        <UserCircleIcon $size="L" />
        <div
          style={{
            flex: 1,
            flexBasis: 0,
            overflow: 'hidden',
          }}
        >
          <Text block type="b2" ellipsis>
            Pending
          </Text>
          <Text
            block
            type="button3"
            color="subtext"
            ellipsis
            title={invite.email}
          >
            {invite.email}
          </Text>
        </div>
      </TableCell>
      <TableCell size={MEMBERS_TABLE_SIZING.dateAdded}>
        <FormattedDate date={invite.createdAt} />
      </TableCell>
      <TableCell size={MEMBERS_TABLE_SIZING.lastActive}>-</TableCell>
      <TableCell size={MEMBERS_TABLE_SIZING.teams}>
        <UserTeamsComboBox orgTeams={teams} user={invite} />
      </TableCell>
      <TableCell size={MEMBERS_TABLE_SIZING.role}>
        {canEdit ? (
          <MemberRoleSelect
            value={invite.role}
            onChange={(e) => handleInviteChangeRole(e.target.value)}
          >
            <option value="ADMIN">Admin</option>
            <option value="EDITOR">Editor</option>
            <option value="VIEWER">Viewer</option>
          </MemberRoleSelect>
        ) : (
          capitalize(invite.role)
        )}
      </TableCell>
      <ActionsCell>
        {canEdit && (
          <ContextMenu
            buttonProps={{
              variant: 'tertiary',
              size: 'icon',
            }}
            items={
              <>
                <ContextMenuItem onClick={() => handleDeleteInvite()}>
                  Delete invite
                </ContextMenuItem>
                <ContextMenuItem onClick={() => handleResendInvite()}>
                  Resend invite
                </ContextMenuItem>
              </>
            }
          >
            <MenuHorizontalIcon />
          </ContextMenu>
        )}
      </ActionsCell>
      <LoadingLogoInset
        active={deleteRes.fetching || updateRes.fetching || resendRes.fetching}
      />
    </TableRow>
  );
};

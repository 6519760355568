import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Text,
  useObjectURL,
  Divider,
} from '@vizcom/shared-ui-components';

import { useCompositeSceneEditorContext } from '../compositeSceneEditor/context';
import { CompositeSceneDebugSettings } from '../compositeSceneEditor/hooks/useCompositeSceneDebug';
import {
  Actions,
  Content,
  Frame,
  HeaderPrimary,
} from '../compositeSceneMenu/style';
import { Container, GenerationPreview } from './styles';

const RenderPreview = ({ render }: { render: Uint8Array | null }) => {
  const lastPreviewUrl = useObjectURL(render);

  return <img src={lastPreviewUrl} />;
};

export const CompositeSceneAIDockedView = ({
  renders,
  compositeSceneDebug,
}: {
  renders: (Uint8Array | null)[];
  compositeSceneDebug: CompositeSceneDebugSettings;
}) => {
  const { activePreview, debugViewport } = compositeSceneDebug;
  const showDebugAIRender = activePreview !== 'default' && debugViewport;
  const { aiPreviewMode, setAIPreviewMode } = useCompositeSceneEditorContext();

  return (
    <Container>
      <Frame $actions="bottom">
        <HeaderPrimary>
          <Text type="sh1">Generation</Text>
          <Actions>
            {aiPreviewMode.mode === 'docked' && (
              <ArrowRightIcon
                onClick={() => {
                  setAIPreviewMode({
                    mode: 'splitscreen',
                    splitScreenRatio: 0.33,
                  });
                }}
              />
            )}
            {aiPreviewMode.mode === 'splitscreen' && (
              <ArrowLeftIcon
                onClick={() => {
                  setAIPreviewMode({
                    mode: 'docked',
                  });
                }}
              />
            )}
          </Actions>
        </HeaderPrimary>
        {aiPreviewMode.mode === 'docked' && (
          <>
            <Divider />
            <Content>
              <GenerationPreview>
                {showDebugAIRender && <img src={debugViewport} />}
                {(renders || []).map((render, index) => (
                  <RenderPreview key={index} render={render} />
                ))}
              </GenerationPreview>
            </Content>
          </>
        )}
      </Frame>
    </Container>
  );
};

import { shaderMaterial } from '@react-three/drei';
import { extend } from '@react-three/fiber';
import { Color, ColorRepresentation, Texture } from 'three';

export const BrushCompositor = ({
  opacity,
  srcTexture,
  dstFill,
  dstTexture,
  maskTexture,
  isEraser,
}: {
  opacity: number;
  srcTexture?: Texture;
  dstFill?: ColorRepresentation;
  dstTexture?: Texture;
  maskTexture?: Texture;
  isEraser?: boolean;
}) => (
  <mesh>
    <planeGeometry args={[2, 2, 1, 1]} />
    <brushCompositorMaterial
      isEraser={isEraser ? 1 : 0}
      dstIsFill={dstFill && !dstTexture ? 1 : 0}
      dstFill={dstFill || 0}
      dstTexture={dstTexture || null}
      srcTexture={srcTexture || null}
      maskTexture={maskTexture || null}
      opacity={opacity}
    />
  </mesh>
);

const BrushCompositorMaterialImpl = shaderMaterial(
  {
    isEraser: 0,
    dstIsFill: 0,
    dstFill: new Color(),
    dstTexture: null,
    srcTexture: null,
    maskTexture: null,
    opacity: 1,
  },
  /* glsl */ `
varying vec2 vUV;

void main()	{
  vUV = uv;
  gl_Position = projectionMatrix * vec4(position, 1.0);
}
`,
  /* glsl */ `
uniform int isEraser;
uniform int dstIsFill;
uniform vec3 dstFill;
uniform sampler2D dstTexture;
uniform sampler2D srcTexture;
uniform sampler2D maskTexture;
uniform float opacity;

varying vec2 vUV;

void main()	{
  vec4 dst = dstIsFill == 1 ? vec4(dstFill, 1.0) : texture(dstTexture, vUV);
  vec4 src = texture(srcTexture, vUV);
  if (src.a > 0.0) src.rgb /= src.a;
  float src_alpha = src.a * opacity * texture(maskTexture, vUV).r;
  if (isEraser == 1) {
    gl_FragColor = vec4(dst.rgb, max(dst.a - src_alpha, 0.0));
  } else {
    gl_FragColor = vec4(
      (src.rgb * src_alpha + dst.rgb * dst.a * (1.0 - src_alpha)),
      src_alpha + dst.a * (1.0 - src_alpha)
    );
    if (gl_FragColor.a > 0.0) gl_FragColor.rgb /= gl_FragColor.a;
  }
}
`
);

extend({
  BrushCompositorMaterial: BrushCompositorMaterialImpl,
});

type BrushCompositorMaterialType = JSX.IntrinsicElements['shaderMaterial'] & {
  isEraser: number;
  dstIsFill: number;
  dstFill: ColorRepresentation;
  dstTexture: Texture | null;
  srcTexture: Texture | null;
  maskTexture: Texture | null;
  opacity: number;
};

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      brushCompositorMaterial: BrushCompositorMaterialType;
    }
  }
}

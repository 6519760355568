import { omit } from 'lodash';
import { create } from 'zustand';

interface NewlyCreatedElementsState {
  registerNewlyCreatedElements(
    elements: {
      id: string;
    }[]
  ): void;
  elements: Record<
    string,
    {
      createdAt: number;
    }
  >;
}

// When a new Drawing elements are created, we register them here to show a toast when they are outside of the camera view
// This can be extended in the future to add animations if needed
export const useNewlyCreatedElements = create<NewlyCreatedElementsState>(
  (set) => ({
    registerNewlyCreatedElements(elements) {
      set((state) => {
        const updatedElements = { ...state.elements };
        for (const element of elements) {
          updatedElements[element.id] = {
            createdAt: Date.now(),
          };
        }
        return { elements: updatedElements };
      });
    },
    elements: {},
  })
);

const newlyCreatedElementsCleanupDuration = 10 * 1000; // remove elements from the "newly created elements" collection after 10 seconds
setInterval(() => {
  const elementsToRemove = Object.entries(
    useNewlyCreatedElements.getState().elements
  )
    .filter(
      ([id, element]) =>
        element.createdAt < Date.now() - newlyCreatedElementsCleanupDuration
    )
    .map(([id]) => id);

  if (elementsToRemove.length) {
    useNewlyCreatedElements.setState((state) => ({
      elements: omit(state.elements, elementsToRemove),
    }));
  }
}, 1000);

import { useDraggable } from '@dnd-kit/core';
import React, { useCallback, useMemo, forwardRef } from 'react';
import styled from 'styled-components';
import {
  Button,
  RichTooltip,
  RichTooltipTrigger,
  RichTooltipContent,
  Text,
  OpenIcon,
} from '@vizcom/shared-ui-components';

import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../../../lib/useDrawingSyncedState';
import { RealtimeMode } from '../hooks/useRealtimeEngine';
import {
  Container,
  Header,
  ControlsRow,
  PreviewContainer,
  DragHandle,
} from '../style';
import { RealtimeControls } from './RealtimeControls';
import { HistoryEntry, RealtimeHistory } from './RealtimeHistory';
import { RealtimePreview } from './RealtimePreview';

const BetaBadge = styled.span`
  background-color: ${({ theme }) => theme.surface.secondary}99;
  color: ${({ theme }) => theme.text.body};
  font-size: 10px;
  font-weight: 700;
  padding: 2px 6px;
  border-radius: 12px;
  margin-left: 6px;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  backdrop-filter: blur(8px);
  border: 1px solid ${({ theme }) => theme.surface.secondary}99;
  box-shadow: 0 0 8px ${({ theme }) => theme.surface.secondary}33;
  animation: pulse 3s infinite;

  @keyframes pulse {
    0% {
      box-shadow: 0 0 8px ${({ theme }) => theme.surface.secondary}33;
    }
    50% {
      box-shadow: 0 0 12px ${({ theme }) => theme.surface.secondary}66;
    }
    100% {
      box-shadow: 0 0 8px ${({ theme }) => theme.surface.secondary}33;
    }
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;

const HeaderControls = styled.div`
  display: flex;
  gap: 4px;
`;

export interface RealtimeWindowContentProps {
  ref?: React.RefObject<HTMLDivElement>;
  position: { x: number; y: number };
  size: { width: number; height: number };
  isLocked: boolean;
  onLockChange: (locked: boolean) => void;
  realtimeHistory: HistoryEntry[];
  realtimeHistoryIndex: number;
  videoExportIsExporting: boolean;
  handleInferenceUpdate: () => Promise<void>;
  navigateHistory: (index: number) => void;
  clearHistory: () => void;
  getCurrentEntry: () => HistoryEntry | null;
  createVideoFromHistory: (
    width: number,
    height: number,
    fps?: number
  ) => Promise<Blob | null>;

  drawing: Drawing2dStudio;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  setActiveLayerId: (id: string | undefined) => void;
  layout: 'default' | 'classic' | 'stacked';
  paletteInfluence: number;
  onPaletteInfluenceChange: (value: number) => void;
  sourceImageInfluence: number;
  onSourceImageInfluenceChange: (value: number) => void;
  mode: RealtimeMode;
  onModeChange: (mode: RealtimeMode) => void;
}

export const RealtimeWindowContent = forwardRef<
  HTMLDivElement,
  RealtimeWindowContentProps
>(
  (
    {
      position,
      size,
      isLocked,
      onLockChange,
      realtimeHistory,
      realtimeHistoryIndex,
      videoExportIsExporting: isExporting,
      handleInferenceUpdate,
      navigateHistory,
      clearHistory,
      getCurrentEntry,
      createVideoFromHistory,
      drawing,
      handleAction,
      setActiveLayerId,
      layout,
    },
    ref
  ) => {
    const { attributes, listeners, setNodeRef, transform, isDragging } =
      useDraggable({
        id: 'realtime-window',
      });

    const handleDragHandleDoubleClick = useCallback(() => {
      onLockChange(!isLocked);
    }, [isLocked, onLockChange]);

    const style = useMemo(() => {
      const baseStyle = {
        width: `${size.width}px`,
        height: `${size.height}px`,
        transition: isLocked ? 'none' : undefined,
      };

      if (isLocked) {
        return {
          ...baseStyle,
          position: 'relative' as const,
          transform: 'none',
          left: 'auto',
          top: 'auto',
          margin: '0',
        };
      }

      return {
        ...baseStyle,
        position: 'fixed' as const,
        left: `${position.x}px`,
        top: `${position.y}px`,
        transform: transform
          ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
          : 'none',
        willChange: isDragging ? 'transform' : 'auto',
        zIndex: 10000001,
        margin: 'initial',
      };
    }, [
      isLocked,
      position.x,
      position.y,
      size.width,
      size.height,
      transform,
      isDragging,
    ]);

    const setRefs = useCallback(
      (element: HTMLDivElement | null) => {
        setNodeRef(element);
        if (typeof ref === 'function') {
          ref(element);
        } else if (ref) {
          ref.current = element;
        }
      },
      [setNodeRef, ref]
    );

    return (
      <Container
        id="realtime-window-container"
        ref={setRefs}
        style={style}
        $isLocked={isLocked}
        $isDragging={isDragging}
      >
        <DragHandle
          {...(isLocked ? {} : { ...attributes, ...listeners })}
          onDoubleClick={handleDragHandleDoubleClick}
          style={{
            cursor: isDragging ? 'grabbing' : 'grab',
          }}
          $isLocked={isLocked}
        >
          <TitleContainer>
            <Text type="sh2" style={{ fontSize: '13px', fontWeight: 600 }}>
              Live Render Preview
            </Text>
            <BetaBadge>Beta</BetaBadge>
          </TitleContainer>
          <HeaderControls>
            {layout !== 'classic' && (
              <RichTooltip>
                <RichTooltipTrigger>
                  <Button
                    size="icon"
                    variant="bare"
                    onClick={() => onLockChange(!isLocked)}
                    style={{
                      padding: '6px',
                      opacity: 0.8,
                      transition: 'opacity 0.2s ease',
                    }}
                  >
                    {isLocked ? (
                      <OpenIcon
                        style={{
                          opacity: isLocked ? 1 : 0.5,
                          width: '16px',
                          height: '16px',
                        }}
                      />
                    ) : (
                      <OpenIcon
                        style={{
                          width: '16px',
                          height: '16px',
                          transform: 'rotate(180deg)',
                          transition: 'transform 0.2s ease',
                        }}
                      />
                    )}
                  </Button>
                </RichTooltipTrigger>
                <RichTooltipContent>
                  {isLocked ? 'Unlock window' : 'Lock window'}
                  {!isLocked && (
                    <div
                      style={{
                        fontSize: '11px',
                        opacity: 0.7,
                        marginTop: '4px',
                      }}
                    >
                      Double-click title bar to lock
                    </div>
                  )}
                </RichTooltipContent>
              </RichTooltip>
            )}
          </HeaderControls>
        </DragHandle>

        <Header style={{ marginTop: '-4px' }}></Header>

        <PreviewContainer
          $isLocked={isLocked}
          style={{
            borderRadius: '12px',
            overflow: 'hidden',
            background: '${({ theme }) => theme.surface.secondary}11',
          }}
        >
          <RealtimePreview currentEntry={getCurrentEntry()} />
        </PreviewContainer>

        <RealtimeHistory
          history={realtimeHistory}
          currentIndex={realtimeHistoryIndex}
          onSelect={navigateHistory}
          onClear={clearHistory}
        />
        <ControlsRow>
          <RealtimeControls
            handleAction={handleAction}
            setActiveLayerId={setActiveLayerId}
            onRegenerate={handleInferenceUpdate}
            currentEntry={getCurrentEntry()}
            drawing={drawing}
            createVideoFromHistory={createVideoFromHistory}
            videoExportIsExporting={isExporting}
          />
        </ControlsRow>
      </Container>
    );
  }
);

import { useRef } from 'react';

type ThrottleQueueItem = () => Promise<void | unknown>;
// Manages a queue of asynchronous operations with optional concurrency control.
// Useful for throttling parallel operations like image loading or API calls.
// maxConcurrent Maximum number of concurrent operations allowed (0 for unlimited)
export const useThrottleQueue = (maxConcurrent?: number) => {
  const queue = useRef<ThrottleQueueItem[]>([]);
  const inProgressCount = useRef<number>(0);
  const processItem = async (item: ThrottleQueueItem) => {
    inProgressCount.current += 1;
    await item();
    inProgressCount.current -= 1;
    if (queue.current.length) {
      processItem(queue.current[0]);
      queue.current.shift();
    }
  };
  const push = (item: ThrottleQueueItem) => {
    if (
      maxConcurrent === undefined ||
      inProgressCount.current < maxConcurrent
    ) {
      processItem(item);
    } else {
      queue.current.push(item);
    }
  };
  return { push };
};

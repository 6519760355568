import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  useCurrentUser,
  useUpdateUser,
} from '@vizcom/shared/data-access/graphql';
import {
  Button,
  Text,
  TextInput,
  addToast,
  formatErrorMessage,
} from '@vizcom/shared-ui-components';

import { OnboardingContainer } from './OnboardingContainer';
import { OnboardingHeader } from './OnboardingHeader';
import { OnboardingStepIndicator } from './OnboardingStepIndicator';

type OnboardingProfilePageInputs = {
  name: string;
};

export const OnboardingProfilePage = () => {
  const navigate = useNavigate();
  const { data: currentUser } = useCurrentUser();
  const [updateUserRef, updateUser] = useUpdateUser();
  const { register, handleSubmit } = useForm<OnboardingProfilePageInputs>({
    values: {
      name: currentUser?.name ?? '',
    },
  });

  const onSubmit: SubmitHandler<OnboardingProfilePageInputs> = async (data) => {
    const res = await updateUser({
      id: currentUser!.id,
      patch: {
        name: data.name,
      },
    });
    if (res.error) {
      return addToast('Error while updating name', {
        type: 'danger',
        secondaryText: formatErrorMessage(res.error),
      });
    }
    navigate('/');
  };

  return (
    <OnboardingContainer>
      <OnboardingHeader />

      <Text type="h1" block>
        What is your name?
      </Text>
      <Text type="sh1" block style={{ marginTop: 8 }}>
        This is the name others will see in your shared workspace.
      </Text>

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 24,
          marginTop: 40,
          width: 400,
        }}
      >
        <div>
          <Text block type="sh2" style={{ marginBottom: 8 }}>
            Full name*
          </Text>
          <TextInput
            required
            $background="secondary"
            type="text"
            autoFocus
            {...register('name')}
          />
        </div>
        <Button
          variant="primary"
          type="submit"
          disabled={updateUserRef.fetching}
        >
          Continue
        </Button>
      </form>

      <OnboardingStepIndicator count={3} currentStep={0} />
    </OnboardingContainer>
  );
};

import { createCanvas } from '@vizcom/shared-ui-components';

import { psdImplementation, Source, Options } from './psd.implementation';

export const psd = async (sources: Source[], options: Options) => {
  const { ctx } = createCanvas(options.width, options.height, {
    willReadFrequently: true,
  });
  return psdImplementation(sources, options, ctx);
};

import {
  urqlClient,
  CompositeSceneFullData,
  UpdateCompositeSceneMutation,
} from '@vizcom/shared/data-access/graphql';

import { SyncedActionType } from '../../SyncedAction';

export const UpdateCompositeSceneAction: SyncedActionType<
  CompositeSceneFullData,
  {
    type: 'updateCompositeScene';

    width?: number;
    height?: number;

    cameraPositionX?: number;
    cameraPositionY?: number;
    cameraPositionZ?: number;

    cameraTargetX?: number;
    cameraTargetY?: number;
    cameraTargetZ?: number;

    cameraFov?: number;

    sceneEnvironmentMapUrl?: string;
    sceneEnvironmentAngle?: number;
    sceneEnvironmentGroundPlane?: boolean;
    sceneBackgroundColorHex?: string;

    prompt?: string;
    influence?: number;
    renderStyle?: string;
  }
> = {
  type: 'updateCompositeScene',
  optimisticUpdater: ({ payload }, scene) => {
    scene.width = payload.width ?? scene.width;
    scene.height = payload.height ?? scene.height;

    scene.cameraPositionX = payload.cameraPositionX ?? scene.cameraPositionX;
    scene.cameraPositionY = payload.cameraPositionY ?? scene.cameraPositionY;
    scene.cameraPositionZ = payload.cameraPositionZ ?? scene.cameraPositionZ;

    scene.cameraTargetX = payload.cameraTargetX ?? scene.cameraTargetX;
    scene.cameraTargetY = payload.cameraTargetY ?? scene.cameraTargetY;
    scene.cameraTargetZ = payload.cameraTargetZ ?? scene.cameraTargetZ;

    scene.cameraFov = payload.cameraFov ?? scene.cameraFov;
    scene.sceneEnvironmentMapUrl =
      payload.sceneEnvironmentMapUrl ?? scene.sceneEnvironmentMapUrl;
    scene.sceneEnvironmentAngle =
      payload.sceneEnvironmentAngle ?? scene.sceneEnvironmentAngle;
    scene.sceneEnvironmentGroundPlane =
      payload.sceneEnvironmentGroundPlane ?? scene.sceneEnvironmentGroundPlane;
    scene.sceneBackgroundColorHex =
      payload.sceneBackgroundColorHex ?? scene.sceneBackgroundColorHex;

    scene.prompt = payload.prompt ?? scene.prompt;
    scene.influence = payload.influence ?? scene.influence;
    scene.renderStyle = payload.renderStyle ?? scene.renderStyle;
  },
  remoteUpdater: async ({ payload }, compositeSceneId) => {
    const res = await urqlClient.mutation(UpdateCompositeSceneMutation, {
      input: {
        id: compositeSceneId,
        patch: {
          width: payload.width,
          height: payload.height,
          cameraPositionX: payload.cameraPositionX,
          cameraPositionY: payload.cameraPositionY,
          cameraPositionZ: payload.cameraPositionZ,
          cameraTargetX: payload.cameraTargetX,
          cameraTargetY: payload.cameraTargetY,
          cameraTargetZ: payload.cameraTargetZ,
          cameraFov: payload.cameraFov,
          sceneEnvironmentMapUrl: payload.sceneEnvironmentMapUrl,
          sceneEnvironmentAngle: payload.sceneEnvironmentAngle,
          sceneEnvironmentGroundPlane: payload.sceneEnvironmentGroundPlane,
          sceneBackgroundColorHex: payload.sceneBackgroundColorHex,
          prompt: payload.prompt,
          influence: payload.influence,
          renderStyle: payload.renderStyle,
        },
      },
    });

    if (res?.error) {
      throw new Error(
        `Error while updating scene, please retry. ${
          res.error.graphQLErrors[0]?.message ?? res.error.message
        }`
      );
    }
  },
  metaConstructor: () => {
    return {
      debounceId: 'updateCompositeScene',
      delay: 2000,
    };
  },
};

import { WorkbenchBasicDataFragment } from 'libs/shared/data-access/graphql/src/gql/graphql';
import { paths } from '@vizcom/shared-utils-paths';

import { addToast } from './Toast';

export function isPublicSharingEnabled(
  workbench: WorkbenchBasicDataFragment
): boolean {
  return Boolean(
    workbench.publicSharingEnabled &&
      workbench.folder?.organization?.publicSharing &&
      workbench.sharingSecret
  );
}

export async function copySharedLinkToClipboard(
  workbench: WorkbenchBasicDataFragment
): Promise<void> {
  if (!isPublicSharingEnabled(workbench)) {
    throw new Error('Public sharing is not enabled for this workbench');
  }

  const url = `${paths.workbench.file(workbench.id)}#sharing_secret=${
    workbench.sharingSecret
  }`;

  await navigator.clipboard.writeText(`${location.host}${url}`);

  addToast('Your link has been copied to your clipboard');
}

import { useFrame, useThree } from '@react-three/fiber';
import { debounce } from 'lodash';
import { useLayoutEffect, useMemo, useRef } from 'react';
import { OrthographicCamera } from 'three';
import { boundNumber } from '@vizcom/shared/js-utils';

import { workbenchCameraPositionStorageKey } from '../../constants';
import { MapControlsProto } from './mapControls/mapControls';
import { CameraLimits } from './mapControls/utils';

export const useLastWorkbenchCameraPosition = (
  workbenchId: string,
  cameraLimits: CameraLimits
) => {
  const camera = useThree((s) => s.camera as OrthographicCamera);
  const controls = useThree((s) => s.controls as any as MapControlsProto);

  // restore previous camera position or fit it to current elements
  useLayoutEffect(() => {
    if (!controls) {
      return;
    }
    const pos = localStorage.getItem(
      workbenchCameraPositionStorageKey(workbenchId)
    );
    let x, y, zoom;
    if (pos) {
      try {
        const parsed = JSON.parse(pos);
        x = parsed.x;
        y = parsed.y;
        zoom = parsed.zoom;
      } catch {}
    }
    if (x === undefined || y === undefined || zoom === undefined) {
      const { xMin, xMax, yMin, yMax, zoomMin, zoomMax } = cameraLimits;
      x = (xMin + xMax) / 2;
      y = (yMin + yMax) / 2;
      const zoomForWidth = (camera.right - camera.left) / (xMax - xMin);
      const zoomForHeight = (camera.top - camera.bottom) / (yMax - yMin);
      zoom = Math.min(zoomForWidth, zoomForHeight);
      zoom = boundNumber(zoomMin, zoom, zoomMax);
    }
    controls.moveTo({ x, y, zoom, skipAnimation: true, controlled: true });
    // when entering workbench focused on a drawing, the camera won't be initialized and 2D studio will need to reset its position when exiting
    // we set this flag to indicate to 2D studio if the last camera position should be used or not when exiting
    camera.userData.vizcomCameraWasInitialized = true;
  }, [controls]);

  // Save camera position to local storage
  const lastCameraPosition = useRef({
    x: 0,
    y: 0,
    zoom: 1,
  });

  const debouncedUpdate = useMemo(
    () =>
      debounce(({ x, y, zoom }: { x: number; y: number; zoom: number }) => {
        localStorage.setItem(
          workbenchCameraPositionStorageKey(workbenchId),
          JSON.stringify({
            x,
            y,
            zoom,
          })
        );
      }, 200),
    [workbenchId]
  );

  useFrame(({ camera }) => {
    if (
      camera.position.x !== lastCameraPosition.current?.x ||
      camera.position.y !== lastCameraPosition.current?.y ||
      camera.zoom !== lastCameraPosition.current?.zoom
    ) {
      debouncedUpdate({
        x: camera.position.x,
        y: camera.position.y,
        zoom: camera.zoom,
      });
    }

    lastCameraPosition.current = {
      x: camera.position.x,
      y: camera.position.y,
      zoom: camera.zoom,
    };
  });
};

import { TransitionStatus } from 'react-transition-group';
import styled, { css } from 'styled-components';

import { zIndexOrder } from '../utils/consts';

type WorkbenchSceneOverlayProps = {
  $state: TransitionStatus;
  $disabled: boolean;
  $position: WorkbenchSceneOverlayPosition;
  $width: number | string;
  $height: number | string;
};

export enum WorkbenchSceneOverlayPosition {
  'top' = 'top',
  'bottom' = 'bottom',
  'left' = 'left',
  'right' = 'right',
}

const WorkbenchSceneOverlayPositionStyle = css<
  WorkbenchSceneOverlayProps & {
    $position: WorkbenchSceneOverlayPosition;
  }
>`
  ${({ $position }) =>
    $position === WorkbenchSceneOverlayPosition.bottom
      ? `bottom: 1rem;`
      : `top: calc(1rem + 66px);`}
  ${({ $position }) =>
    $position === WorkbenchSceneOverlayPosition.right
      ? `right: 1rem;`
      : `left: 1rem;`}
  transform: ${({ $position, $state }) =>
    ({
      [WorkbenchSceneOverlayPosition.top]: {
        ['entering']: 'translateY(calc(-100% - 1rem))',
        ['entered']: 'translateY(0)',
        ['exiting']: 'translateY(0)',
        ['exited']: 'translateY(calc(-100% - 1rem))',
        ['unmounted']: 'translateY(calc(-100% - 1rem))',
      }[$state],
      [WorkbenchSceneOverlayPosition.right]: {
        ['entering']: 'translateX(calc(100% + 1rem))',
        ['entered']: 'translateX(0)',
        ['exiting']: 'translateX(0)',
        ['exited']: 'translateX(calc(100% + 1rem))',
        ['unmounted']: 'translateX(calc(100% + 1rem))',
      }[$state],
      [WorkbenchSceneOverlayPosition.bottom]: {
        ['entering']: 'translateY(calc(100% - 1rem))',
        ['entered']: 'translateY(0)',
        ['exiting']: 'translateY(0)',
        ['exited']: 'translateY(calc(100% - 1rem))',
        ['unmounted']: 'translateY(calc(100% - 1rem))',
      }[$state],
      [WorkbenchSceneOverlayPosition.left]: {
        ['entering']: 'translateX(calc(-100% - 1rem))',
        ['entered']: 'translateX(0)',
        ['exiting']: 'translateX(0)',
        ['exited']: 'translateX(calc(-100% - 1rem))',
        ['unmounted']: 'translateX(calc(-100% - 1rem))',
      }[$state],
    }[$position])};
`;

export const WorkbenchSceneOverlayWrapper = styled.div<WorkbenchSceneOverlayProps>`
  position: absolute;
  ${WorkbenchSceneOverlayPositionStyle};
  width: ${({ $width }) =>
    typeof $width === 'string' ? `calc(${$width} - 100px)` : `${$width}px`};
  height: ${({ $height }) =>
    typeof $height === 'string' ? `calc(${$height} - 100px)` : `${$height}px`};
  transition: transform 0.5s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  color: ${({ theme }) => theme.text.subtext};
  gap: 0.5rem;
  align-items: flex-start;
  z-index: ${zIndexOrder.UiZIndex};

  & > * {
    pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'all')};
  }
`;

import { DoubleSide, Texture } from 'three';
import { ErrorBoundary, ToastIndicator } from '@vizcom/shared-ui-components';

import {
  DrawingLayer,
  useDrawingSyncedState,
} from '../../../lib/useDrawingSyncedState';
import { VizcomRenderingOrderEntry } from '../../utils/threeRenderingOrder';
import { LayerContent } from './LayerContent';

interface CompositeLayerProps {
  layer: DrawingLayer;
  texture: Texture | undefined;
  active: boolean;
  drawingSize: [number, number];
  visible: boolean;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  filterHistory: ReturnType<typeof useDrawingSyncedState>['filterHistory'];
  zIndex: number;
}

export const CompositeLayer = (props: CompositeLayerProps) => {
  return (
    <ErrorBoundary
      logToSentry
      fallbackRender={({ error }) => (
        <ToastIndicator
          text={`
              There was an error loading the layer "${props.layer.name}",
              please retry or delete this layer.
            `}
          secondaryText={error?.message ?? 'unknown error'}
          variant="warning"
        />
      )}
    >
      <CompositeLayerContent {...props} />
    </ErrorBoundary>
  );
};

const CompositeLayerContent = ({
  layer,
  texture,
  drawingSize,
  visible,
  zIndex,
}: CompositeLayerProps) => {
  return (
    <group visible={visible}>
      <LayerContent
        id={layer.id}
        opacity={layer.opacity}
        blendMode={layer.blendMode}
        visible={layer.visible}
        zIndex={zIndex}
        type={'vizcom:compositedLayerContent'}
      >
        <LayerStaticView
          layer={layer}
          texture={texture}
          drawingSize={drawingSize}
        />
      </LayerContent>
    </group>
  );
};

//A simple non-interactive view of a layer
export function LayerStaticView({
  layer,
  drawingSize,
  texture,
}: {
  layer: DrawingLayer;
  drawingSize: [number, number];
  texture: Texture | undefined;
}) {
  const fill =
    layer.fill && layer.fill.match(/^#([0-9a-f]{6})$/i)
      ? layer.fill
      : undefined;
  return (
    <>
      {texture && (
        <mesh
          userData={{
            vizcomRenderingOrder: [
              {
                zIndex: 1,
              } satisfies VizcomRenderingOrderEntry,
            ],
          }}
          scale={[drawingSize[0], drawingSize[1], 1]}
        >
          <planeGeometry args={[1, 1, 1, 1]} />
          <meshBasicMaterial
            map={texture}
            transparent
            depthTest={false}
            side={DoubleSide}
          />
        </mesh>
      )}
      {fill && !layer.imagePath && (
        <mesh
          userData={{
            vizcomRenderingOrder: [
              {
                zIndex: 0,
              } satisfies VizcomRenderingOrderEntry,
            ],
          }}
          scale={[drawingSize[0], drawingSize[1], 1]}
        >
          <planeGeometry args={[1, 1, 1, 1]} />
          <meshBasicMaterial
            color={fill}
            transparent
            depthTest={false}
            side={DoubleSide}
          />
        </mesh>
      )}
    </>
  );
}

import styled from 'styled-components';

export const OnboardingContainer = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  background-color: ${(p) => p.theme.surface.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 40px 40px;
`;

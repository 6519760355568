import styled from 'styled-components';
import { capitalize } from '@vizcom/shared/js-utils';
import {
  FormattedDate,
  Text,
  useCrisp,
  useSelectedOrganization,
} from '@vizcom/shared-ui-components';

const Container = styled.div<{ $expired: boolean }>`
  padding: 10px 18px;
  margin-top: 10px;
  background-color: ${({ $expired, theme }) =>
    $expired ? theme.surface.danger : theme.surface.warning};
  border-radius: ${({ theme }) => theme.borderRadius.s};
  display: flex;
  justify-content: space-between;
`;

export const PocBanner = () => {
  const { data: organization } = useSelectedOrganization();
  const crisp = useCrisp();

  const handleContactSupport = () => {
    crisp.openChat();
  };

  if (!organization || (organization && !organization.poc)) {
    return null;
  }
  const expired = Boolean(
    organization.pocEndDate &&
      new Date(organization.pocEndDate).getTime() < new Date().getTime()
  );

  let bannerContent;
  if (organization.pocEndDate) {
    if (expired) {
      bannerContent = (
        <Text>
          <b>
            Your {capitalize(organization.subscriptionPlan)} plan POC has
            expired.
          </b>{' '}
          To make sure you don't lose access to your workspace, please contact
          our sales team.
        </Text>
      );
    } else {
      bannerContent = (
        <Text>
          Your POC ends <FormattedDate date={organization.pocEndDate} />.
        </Text>
      );
    }
  } else {
    bannerContent = (
      <Text>
        Thanks for testing Vizcom. You can contact our sales team for any
        questions.
      </Text>
    );
  }

  return (
    <Container $expired={expired}>
      {bannerContent}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          columnGap: 8,
          alignItems: 'center',
        }}
      >
        <Text
          onClick={handleContactSupport}
          color="body"
          style={{
            fontWeight: 600,
            textAlign: 'right',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          type="h2"
        >
          Contact sales
        </Text>
        <Text
          color="body"
          style={{
            fontWeight: 600,
            textAlign: 'right',
            userSelect: 'none',
          }}
          type="h2"
        >
          &#8599;
        </Text>
      </div>
    </Container>
  );
};

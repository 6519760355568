// @ts-ignore
import { ContactShadows } from '@react-three/drei';

// @ts-ignore
import ENV_PRESET_WAREHOUSE from '../../../../assets/environments/empty_warehouse_01_1k.hdr.jpg';
// @ts-ignore
import ENV_PRESET_FIELD from '../../../../assets/environments/hochsal_field_1k.hdr.jpg';
// @ts-ignore
import ENV_PRESET_SKY from '../../../../assets/environments/kloppenheim_06_puresky_1k.hdr.jpg';
// @ts-ignore
import ENV_PRESET_NIGHT from '../../../../assets/environments/moonless_golf_1k.hdr.jpg';
// @ts-ignore
import ENV_PRESET_STUDIO from '../../../../assets/environments/studio_small_09_1k.hdr.jpg';

export const EnvironmentPresets = {
  Warehouse: ENV_PRESET_WAREHOUSE,
  Field: ENV_PRESET_FIELD,
  Sky: ENV_PRESET_SKY,
  Night: ENV_PRESET_NIGHT,
  Studio: ENV_PRESET_STUDIO,
};

export const isEnvironmentPreset = (value: string) =>
  value.startsWith('preset:');

export const toEnvironmentPreset = (value: string) => `preset:${value}`;

export const getEnvironmentPreset = (value: string) =>
  EnvironmentPresets[
    value.replace('preset:', '') as keyof typeof EnvironmentPresets
  ];

export const getEnvironmentPresetLighting = (
  preset: string,
  withGroundPlane: boolean
): JSX.Element | null => {
  const presetId = preset.replace(
    'preset:',
    ''
  ) as keyof typeof EnvironmentPresets;

  if (presetId === 'Warehouse') {
    return (
      <>
        <directionalLight
          position={[2.0, 1.0, -0.5]}
          color={0xffffff}
          castShadow
          intensity={3.0}
          shadow-mapSize-width={2048}
          shadow-mapSize-height={2048}
          shadow-bias={-0.0001}
          shadow-radius={4}
        />
        {withGroundPlane && (
          <ContactShadows
            resolution={1024}
            position={[0, -0.01, 0]}
            scale={10.0}
            blur={1.0}
            opacity={0.5}
            far={20}
          />
        )}
      </>
    );
  } else if (presetId === 'Field') {
    return (
      <>
        <directionalLight
          position={[1.5, 1.0, 1.0]}
          color={0xffffff}
          castShadow
          intensity={3.0}
          shadow-mapSize-width={2048}
          shadow-mapSize-height={2048}
          shadow-bias={-0.0001}
          shadow-radius={4}
        />
        {withGroundPlane && (
          <ContactShadows
            resolution={1024}
            position={[0, -0.01, 0]}
            scale={10.0}
            blur={1.0}
            opacity={0.5}
            far={20}
          />
        )}
      </>
    );
  } else if (presetId === 'Sky') {
    // NOTE Pass
  } else if (presetId === 'Night') {
    // NOTE Pass
  } else if (presetId === 'Studio') {
    return (
      <>
        <directionalLight
          position={[2.0, 1.0, -2.0]}
          color={0xffffff}
          castShadow
          intensity={1.0}
          shadow-mapSize-height={2048}
          shadow-mapSize-width={2048}
          shadow-bias={-0.0001}
        />
        <directionalLight
          position={[1.5, 1.0, 1.0]}
          color={0xffffff}
          castShadow
          intensity={1.0}
          shadow-mapSize-height={2048}
          shadow-mapSize-width={2048}
          shadow-bias={-0.0001}
        />
        <directionalLight
          position={[-1.5, 1.0, 1.5]}
          color={0xffffff}
          castShadow
          intensity={1.0}
          shadow-mapSize-height={2048}
          shadow-mapSize-width={2048}
          shadow-bias={-0.0001}
        />
        {withGroundPlane && (
          <ContactShadows
            resolution={1024}
            position={[0, -0.01, 0]}
            scale={10.0}
            blur={1.0}
            opacity={0.5}
            far={20}
          />
        )}
      </>
    );
  }

  if (!withGroundPlane) {
    return null;
  }

  return (
    <ContactShadows
      resolution={2048}
      position={[0, -0.01, 0]}
      scale={10.0}
      blur={1.0}
      opacity={0.5}
      far={20}
    />
  );
};

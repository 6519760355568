import { useThree } from '@react-three/fiber';
import { WorkbenchElementSectionData } from '@vizcom/shared/data-access/graphql';
import { filterExists } from '@vizcom/shared/js-utils';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import {
  addToast,
  ContextMenuDivider,
  ContextMenuItem,
  dismissToast,
  downloadFile,
  imageToBlob,
  zipFiles,
} from '@vizcom/shared-ui-components';

import { useWorkbenchSyncedState } from '../../../lib/useWorkbenchSyncedState';
import { getSectionElements } from '../../elements/section/helpers';

export const WorkbenchElementSectionContextMenuItems = ({
  element,
  handleAction,
}: {
  element: WorkbenchElementSectionData;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
}) => {
  const { scene } = useThree();
  const sectionImages = getSectionElements(scene, element).filter(
    (el) => el.userData.elementTypename === 'Drawing'
  );

  const handleDownloadSectionImages = async () => {
    try {
      let loadingToastId: string | undefined;

      if (sectionImages.length > 5) {
        loadingToastId = addToast(
          `Preparing ${sectionImages.length} images for download...`,
          {
            type: 'loading',
          }
        );
      }

      const files = (
        await Promise.all(
          sectionImages.map(async (element, index) => {
            if (!element.userData.drawingThumbnailPath) {
              return null;
            }
            return {
              name: `${element.userData.drawingName}-${index}.png`,
              data: await imageToBlob(element.userData.drawingThumbnailPath, {
                convertToContentType: 'image/png',
              }),
            };
          })
        )
      ).filter(filterExists);

      if (files.length !== sectionImages.length) {
        addToast('Could not download image(s) that are being uploaded');
      }

      if (loadingToastId) {
        addToast(`Compressing ${files.length} images...`, {
          type: 'loading',
          id: loadingToastId,
        });
      }

      const zipFile = await zipFiles(files);
      downloadFile(zipFile, element.title, 'zip');

      if (loadingToastId) {
        dismissToast(loadingToastId);
      }
      addToast(`Successfully downloaded section images`, {
        type: 'success',
      });

      trackEvent('Image Export', {
        type: 'exportSectionImages',
      });
    } catch (e) {
      return addToast('An error occurred while downloading section images', {
        type: 'danger',
      });
    }
  };

  const handleRemoveSection = () => {
    handleAction({
      type: 'deleteElements',
      elementIds: [element.id],
    });
  };

  return (
    <>
      {sectionImages.length > 0 && (
        <>
          <ContextMenuItem onClick={handleRemoveSection}>
            Remove section
          </ContextMenuItem>
          <ContextMenuDivider />
          <ContextMenuItem onClick={handleDownloadSectionImages}>
            Download images
          </ContextMenuItem>
        </>
      )}
    </>
  );
};

import styled from 'styled-components';
import { SharedHelpCenter } from '@vizcom/shared-ui-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #000;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.text.body};
  margin-bottom: 2rem;
  padding: 0 3rem;
  text-align: center;
`;

const Text = styled.p`
  color: ${({ theme }) => theme.text.body};
  margin-bottom: 1rem;
`;

const Link = styled.a`
  color: ${({ theme }) => theme.text.link};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const MobileRedirect = () => {
  return (
    <Container>
      <Title>We're sorry, Vizcom is not supported on mobile devices.</Title>
      <Text>But don't worry, we're working on it!</Text>
      <Link href="https://www.vizcom.ai/">Go back to the landing page</Link>
      <SharedHelpCenter buttonProps={{ variant: 'tertiary' }} />
    </Container>
  );
};

import { UserPaletteDataFragment } from 'libs/shared/data-access/graphql/src/gql/graphql';
import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { useMutation } from 'urql';
import {
  publishTrackingEvent,
  PublishWorkbenchElementPaletteMutation,
  UpdateUserPaletteMutation,
} from '@vizcom/shared/data-access/graphql';
import { filterExists } from '@vizcom/shared/js-utils';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import {
  addToast,
  browseForFile,
  Button,
  imageToBlob,
  InfoIcon,
  Modal,
  resizeImageToCoverSizeBlob,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  Text,
  TextInput,
} from '@vizcom/shared-ui-components';

import checkered from '../../../assets/images/checkered.png';
import { RawImage } from '../../../lib/RawImage';
import { ClientSideWorkbenchElementPalette } from '../../../lib/clientState';
import { Palette } from '../../modals/PaletteSelector/PaletteSelector';

const usePaletteForm = (palette: {
  name: string;
  tags: (string | null)[] | undefined;
  thumbnailPath: string | null | undefined;
}) => {
  const [name, setName] = useState(palette?.name || '');
  const [tags, setTags] = useState<Tag[]>(
    (palette?.tags || [])
      .filter(filterExists)
      .map((tag) => ({ label: tag, value: true }))
  );
  const [thumbnail, setThumbnail] = useState<Blob | string | undefined | null>(
    palette?.thumbnailPath
  );

  return { name, setName, tags, setTags, thumbnail, setThumbnail };
};

export const EditPublishedPaletteModal: React.FC<{
  onClose: () => void;
  element: Palette | UserPaletteDataFragment;
}> = ({ onClose, element }) => {
  const theme = useTheme();
  const { name, setName, tags, setTags, thumbnail, setThumbnail } =
    usePaletteForm({
      name: element.name,
      tags: element.tags,
      thumbnailPath: element.thumbnailPath,
    });

  const [editRes, editPalette] = useMutation(UpdateUserPaletteMutation);

  const loading = editRes.fetching;

  const handleConfirm = async () => {
    await editPalette({
      input: {
        id: element.id,
        patch: {
          name,
          tags: tags.filter((tag) => tag.value).map((tag) => tag.label),
          thumbnailPath: thumbnail,
        },
      },
    });

    if (editRes.error) {
      addToast('There was an error while editing this palette', {
        type: 'danger',
        secondaryText: editRes.error.message,
      });
      return;
    }

    trackEvent('Edit published palette');
    publishTrackingEvent({
      type: 'EDIT_PUBLISHED_PALETTE',
      data: {
        paletteId: element.id,
      },
    });
    onClose();
  };

  return (
    <Modal
      isOpen={true}
      setIsOpen={onClose}
      style={{
        backgroundColor: theme.surface.primary,
        padding: '16px',
        minWidth: '320px',
        width: '320px',
      }}
      loading={loading}
    >
      <Container>
        <Text type="sh2">Edit details</Text>
        <ContainerRow>
          <ThumbnailUploader
            thumbnail={thumbnail}
            setThumbnail={setThumbnail}
          />
          <StyledTextInput
            type="text"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
            $background="secondary"
            placeholder="Palette Name"
          />
        </ContainerRow>
        <TagSelector tags={tags} setTags={setTags} />
        <Buttons>
          <Button type="button" variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button type="button" variant="primary" onClick={handleConfirm}>
            Save
          </Button>
        </Buttons>
      </Container>
    </Modal>
  );
};

export const PublishPaletteModal: React.FC<{
  onClose: () => void;
  element: ClientSideWorkbenchElementPalette;
}> = ({ onClose, element }) => {
  const theme = useTheme();
  const { name, setName, tags, setTags, thumbnail, setThumbnail } =
    usePaletteForm({
      name: element.name,
      tags: element.tags,
      thumbnailPath: element.thumbnailPath,
    });

  const [{ fetching }, publishPalette] = useMutation(
    PublishWorkbenchElementPaletteMutation
  );

  const handleConfirm = async () => {
    const response = await publishPalette({
      input: {
        sourcePaletteId: element.id,
        name,
        tags: tags.filter((tag) => tag.value).map((tag) => tag.label),
        thumbnailPath: thumbnail ? await imageToBlob(thumbnail) : undefined,
      },
    });

    if (response.error) {
      addToast('There was an error while publishing this palette', {
        type: 'danger',
        secondaryText: response.error.message,
      });
      return;
    }

    trackEvent('Publish palette');
    publishTrackingEvent({
      type: 'PUBLISH_PALETTE_TO_PERSONAL_LIBRARY',
      data: {
        paletteId: element.id,
        imagesCount: element.sourceImages.nodes.length,
      },
    });
    onClose();
  };

  return (
    <Modal
      isOpen={true}
      setIsOpen={() => {}}
      style={{
        backgroundColor: theme.surface.primary,
        padding: '16px',
        minWidth: '320px',
        width: '320px',
      }}
      loading={fetching}
    >
      <Container>
        <Text type="sh2">Add to My Library</Text>
        <ContainerRow>
          <ThumbnailUploader
            thumbnail={thumbnail}
            setThumbnail={setThumbnail}
          />
          <StyledTextInput
            type="text"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
            $background="secondary"
            placeholder="Palette Name"
          />
        </ContainerRow>
        <TagSelector tags={tags} setTags={setTags} />
        <Text type="b1" color="subtext">
          By adding this palette to My Library, it will only appear in your
          palette menu for you to use across workspace files.{' '}
          <Text
            color="link"
            as={Link}
            to="https://docs.vizcom.ai/make-your-own-palette"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </Text>
        </Text>
        <Buttons>
          <Button type="button" variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button type="button" variant="primary" onClick={handleConfirm}>
            Add
          </Button>
        </Buttons>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ContainerRow = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
`;

const StyledTextInput = styled(TextInput)`
  margin-left: 11px;
  height: 32px;
`;

const UploadBox = styled.div`
  position: relative;
  cursor: pointer;
  width: 48px;
  min-width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;

  canvas {
    width: 100%;
  }

  img {
    border-radius: ${(p) => p.theme.borderRadius.m};
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${(p) => p.theme.borderRadius.m};
`;

const HoverOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;

  ${UploadBox}:hover & {
    opacity: 1;
  }
`;

const Buttons = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 16px;
`;

const ThumbnailUploader: React.FC<{
  thumbnail: Blob | string | undefined | null;
  setThumbnail: (image: Blob) => void;
}> = ({ thumbnail, setThumbnail }) => {
  const handleUpload = useCallback(
    async (e: { stopPropagation: () => void }) => {
      e.stopPropagation();
      const file = await browseForFile({ accept: 'image/*' });
      if (file) {
        setThumbnail(await resizeImageToCoverSizeBlob(file, 512, 512));
      }
    },
    [setThumbnail]
  );

  return (
    <UploadBox onClick={handleUpload}>
      {!thumbnail ? (
        <Image src={checkered} alt="Palette thumbnail" />
      ) : (
        <RawImage data={thumbnail} />
      )}
      <HoverOverlay>
        <Text type="button3">Upload</Text>
      </HoverOverlay>
    </UploadBox>
  );
};

type Tag = {
  label: string;
  value: boolean;
};
const TagSelector = ({
  tags,
  setTags,
}: {
  tags: Tag[];
  setTags: (tags: Tag[]) => void;
}) => {
  const theme = useTheme();
  const toggleTag = (tag: Tag) => {
    setTags(
      tags.map((t) => {
        if (t.label === tag.label) {
          return { ...t, value: !t.value };
        }
        return t;
      })
    );
  };

  return (
    <div>
      <TagsHeader>
        <Text type="sh2" color="subtext">
          Tags
        </Text>
        <RichTooltip trigger="hover" noParentIntegration>
          <RichTooltipTrigger>
            <StyledInfoIcon />
          </RichTooltipTrigger>
          <RichTooltipContent style={{ color: theme.deprecated.white }}>
            Descriptors that appear in the prompt field when your palette has
            been selected
          </RichTooltipContent>
        </RichTooltip>
      </TagsHeader>
      <InputField>
        {tags.map((tag) => (
          <Tag
            key={tag.label}
            onClick={() => toggleTag(tag)}
            $disabled={!tag.value}
          >
            {tag.label}
          </Tag>
        ))}
      </InputField>
    </div>
  );
};

const StyledInfoIcon = styled(InfoIcon)`
  color: ${(p) => p.theme.text.subtext};

  &:hover {
    color: ${(p) => p.theme.icon.primary};
  }
`;

const TagsHeader = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 8px;
`;

const InputField = styled.div`
  background-color: ${(p) => p.theme.surface.secondary};
  border-radius: ${(p) => p.theme.borderRadius.m};
  max-height: 96px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 10px 16px;
`;

const Tag = styled.span<{ $disabled: boolean }>`
  display: inline-block;
  background-color: ${(p) =>
    p.$disabled ? p.theme.surface.secondary : p.theme.surface.tertiary};
  color: ${(p) => (p.$disabled ? p.theme.text.subtext : p.theme.text.body)};
  border: 1px solid ${(p) => p.theme.surface.tertiary};
  border-radius: ${(p) => p.theme.borderRadius.s};
  padding: 6px 8px;
  cursor: pointer;

  &:hover {
    background-color: ${(p) =>
      p.$disabled ? p.theme.surface.tertiary : p.theme.surface.secondary};
  }
`;

import { useStore } from '@react-three/fiber';
import styled from 'styled-components';
import { OrthographicCamera } from 'three';
import {
  Checkbox,
  MenuDivider,
  MenuItem,
  Text,
} from '@vizcom/shared-ui-components';

import { logStep } from '../../../../../../shared/js-utils/src';
import { ClientSideWorkbenchElementData } from '../../lib/clientState';
import { useWorkbenchFilters } from '../WorkbenchFilters/WorkbenchFiltersContext';
import { ZOOM_CLICK_STEP } from '../WorkbenchViewportControls';
import {
  WORKBENCH_MODE_CAMERA_ZOOM_LIMITS,
  getCameraLimitsFromWorkbenchElements,
  useMapControls,
} from '../utils/mapControls/utils';

export const WorkbenchMenuViewItem = (props: {
  elements: ClientSideWorkbenchElementData[];
}) => {
  const controls = useMapControls();
  const store = useStore();
  const workbenchFilters = useWorkbenchFilters();

  return (
    <MenuItem label="View">
      <MenuItem
        label="Zoom in"
        appendLabel={<Text color="subtext">+</Text>}
        onClick={() => {
          controls.moveTo({
            zoom: logStep(
              store.getState().camera.zoom,
              ZOOM_CLICK_STEP,
              WORKBENCH_MODE_CAMERA_ZOOM_LIMITS[0],
              WORKBENCH_MODE_CAMERA_ZOOM_LIMITS[1]
            ),
          });
        }}
      />
      <MenuItem
        label="Zoom out"
        appendLabel={<Text color="subtext">-</Text>}
        onClick={() => {
          controls.moveTo({
            zoom: logStep(
              store.getState().camera.zoom,
              -ZOOM_CLICK_STEP,
              WORKBENCH_MODE_CAMERA_ZOOM_LIMITS[0],
              WORKBENCH_MODE_CAMERA_ZOOM_LIMITS[1]
            ),
          });
        }}
      />
      <MenuItem
        label="Zoom to fit"
        onClick={() => {
          const cameraLimits = getCameraLimitsFromWorkbenchElements(
            props.elements
          );
          const camera = store.getState().camera as OrthographicCamera;
          const { xMin, xMax, yMin, yMax } = cameraLimits;
          const x = (xMin + xMax) / 2;
          const y = (yMin + yMax) / 2;
          const zoomForWidth = (camera.right - camera.left) / (xMax - xMin);
          const zoomForHeight = (camera.top - camera.bottom) / (yMax - yMin);
          const zoom = Math.min(zoomForWidth, zoomForHeight);
          controls.moveTo({ x, y, zoom });
        }}
      />
      <MenuDivider />
      <MenuItem
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          workbenchFilters.setFilter(
            'drawingWithMultipleLayers',
            !workbenchFilters.drawingWithMultipleLayers
          );
        }}
        label={
          <CheckboxAndLabel>
            <Checkbox
              checked={workbenchFilters.drawingWithMultipleLayers}
              onClick={() => undefined}
            />
            <LabelContainer>
              <Text>Highlight images with multiple layers</Text>
            </LabelContainer>
          </CheckboxAndLabel>
        }
      />
    </MenuItem>
  );
};

const CheckboxAndLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

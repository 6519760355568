import produce from 'immer';
import { useCallback, useMemo, useSyncExternalStore } from 'react';
import {
  Drawing,
  useDrawing,
  useDrawingUpdates,
} from '@vizcom/shared/data-access/graphql';
import { assertExists, memoizeLastCall } from '@vizcom/shared/js-utils';
import { useAlertOnUnload, useLastValue } from '@vizcom/shared-ui-components';

import { SyncQueueSynchronizer } from './SyncQueueSynchronizer';
import { SyncQueueAction, SyncQueueMeta } from './SyncedAction';
import { DrawingActionPayload, DrawingActionTypes } from './actions/drawing';
import { cachedLayerImagesByUrl } from './actions/drawing/updateLayer';
import { SyncQueue } from './syncQueue';

export interface DrawingLayer
  extends Omit<
    Drawing['layers']['nodes'][0],
    'orderKey' | 'meshPath' | 'imagePath'
  > {
  imagePath?: string | ImageData | Blob | null;
  meshPath?: string | Blob | null;
  orderKey: string;
}

export type Drawing2dStudio = Omit<Drawing, 'layers'> & {
  layers: {
    nodes: DrawingLayer[];
  };
};

const getOptimisticDrawing = memoizeLastCall(
  (
    drawing: Drawing2dStudio | null | undefined,
    queue: ReturnType<
      SyncQueue<Drawing2dStudio, DrawingActionPayload>['getQueue']
    >
  ) =>
    drawing &&
    produce(drawing, (draft) => {
      for (const action of queue) {
        if (typeof action === 'function') {
          action(draft);
        } else {
          const actionType = DrawingActionTypes.find(
            ({ type }) => type === action.payload.type
          );
          assertExists(
            actionType,
            `Action type ${action.payload.type} is not registered`
          );
          actionType.optimisticUpdater(action as any, draft);
        }
      }

      draft.layers.nodes.forEach((layer) => {
        // Inject the cached image data into the layer
        // this is useful when the imagePath as an ImageData or Blob is replaced by a string when we get the response from the server
        // in this case, we keep the reference to the original image data to prevent a flash of nothing while the image is loaded again from the URL
        // this happens when importing an image in 2D studio for example
        if (
          typeof layer.imagePath === 'string' &&
          cachedLayerImagesByUrl[layer.imagePath]
        ) {
          layer.imagePath = cachedLayerImagesByUrl[layer.imagePath];
        }
      });
    })
);

export const useDrawingSyncedState = (
  drawingId: string,
  syncQueueSynchronizer: SyncQueueSynchronizer
) => {
  const {
    data: drawingData,
    fetching,
    error,
  } = useDrawing(drawingId, {
    requestPolicy: 'cache-and-network',
  });
  const data = drawingData as Drawing2dStudio;
  const dataRef = useLastValue(data);

  useDrawingUpdates(drawingId);

  const syncQueue: SyncQueue<Drawing2dStudio, DrawingActionPayload> = useMemo(
    () =>
      new SyncQueue<Drawing2dStudio, DrawingActionPayload>(
        syncQueueSynchronizer,
        DrawingActionTypes,
        () => getOptimisticDrawing(dataRef.current, syncQueue.getQueue())!,
        drawingId,
        'drawing'
      ),
    [drawingId, syncQueueSynchronizer, dataRef]
  );

  useSyncExternalStore(syncQueue.listen, syncQueue.getQueue);
  const hasUnsavedChanges = syncQueue.getQueue().length > 0;
  useAlertOnUnload(hasUnsavedChanges);

  const optimisticDrawing = getOptimisticDrawing(data, syncQueue.getQueue());

  const handleAction = useCallback(
    (
      payload:
        | DrawingActionPayload
        | ((state: Drawing2dStudio) => DrawingActionPayload | undefined),
      meta: SyncQueueMeta = {}
    ) => {
      const finalPayload =
        typeof payload === 'function'
          ? payload(
              getOptimisticDrawing(dataRef.current, syncQueue.getQueue())!
            )
          : payload;
      if (!finalPayload) {
        return;
      }

      syncQueue.push(finalPayload, meta);

      return finalPayload;
    },
    [syncQueue, dataRef]
  );

  const undoAction = () => syncQueue.undoAction();
  const redoAction = () => syncQueue.redoAction();
  const filterHistory = (
    callback: (action: SyncQueueAction<DrawingActionPayload>) => boolean
  ) => syncQueue.filterHistory(callback);

  return {
    drawing: optimisticDrawing,
    handleAction,
    undoAction,
    redoAction,
    canUndo: syncQueue.history.length > 0,
    canRedo: syncQueue.redoHistory.length > 0,
    filterHistory,
    error,
    fetching,
    hasUnsavedChanges,
  };
};

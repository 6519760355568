import { Object3DProps, useFrame } from '@react-three/fiber';
import { useRef } from 'react';
import {
  DoubleSide,
  MathUtils,
  Mesh,
  MeshBasicMaterial,
  Object3D,
} from 'three';

import { RoundedPlaneGeometry } from '../../utils/RoundedPlaneGeometry';

export const AnimatedLoadingSvg = (props: Object3DProps) => {
  const groupRef = useRef<Object3D>(null);

  useFrame((state) => {
    if (groupRef.current) {
      groupRef.current.children.forEach((child, index) => {
        const angle = index * (Math.PI / 4); // 45 radians
        const radius = 4.5; // Distance from center

        child.position.x = Math.sin(angle) * radius;
        child.position.y = Math.cos(angle) * radius;
        child.rotation.z = -angle;

        const animationProgress =
          (state.clock.elapsedTime + index * 0.15) % 1.2;
        const opacity =
          animationProgress <= 0.15
            ? MathUtils.lerp(0.2, 1, animationProgress / 0.15)
            : 1;

        child.children.forEach((mesh) => {
          if (mesh instanceof Mesh) {
            const material = mesh.material as MeshBasicMaterial;
            material.opacity = opacity;
          }
        });
      });
    }
  });

  return (
    <object3D ref={groupRef} scale={[1, -1, 1]} {...props}>
      {Array.from({ length: 8 }).map((_, i) => (
        <group key={i}>
          <mesh>
            <RoundedPlaneGeometry width={1} height={3.5} radius={0.5} />
            <meshBasicMaterial
              color={0xff5106}
              transparent={true}
              side={DoubleSide}
              depthWrite={false}
            />
          </mesh>
        </group>
      ))}
    </object3D>
  );
};

import { suspend } from 'suspend-react';
import { Texture, TextureLoader } from 'three';
import { assertUnreachable, sleep } from '@vizcom/shared/js-utils';

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;
const loader = new TextureLoader();

export const loadImageWithRetry = async (
  url: string | undefined | null | File | Blob
) => {
  if (!url) {
    return undefined;
  }

  const loadImage = () =>
    new Promise<Texture>((resolve, reject) => {
      if (typeof url === 'string') {
        loader.load(
          url,
          (texture) => {
            resolve(texture);
          },
          () => {},
          (error) => reject(error)
        );
      } else if (url instanceof File || url instanceof Blob) {
        const reader = new FileReader();
        reader.onload = () => {
          const image = new Image();
          image.src = reader.result as string;
          image.onload = () => {
            const texture = new Texture(image);
            texture.needsUpdate = true;
            resolve(texture);
          };
        };
        reader.readAsDataURL(url);
      } else {
        assertUnreachable(url);
      }
    });

  let tryCount = 0;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    try {
      const res = await loadImage();
      return res;
    } catch (e) {
      await sleep(RETRY_DELAY);
      tryCount++;
      if (tryCount > MAX_RETRIES) {
        throw e;
      }
    }
  }
};

const loadImagesInParallel = async (
  _symbol: any,
  ...urls: (string | undefined | null | File | Blob)[]
) => {
  const promises = urls.map((url) => loadImageWithRetry(url));
  return await Promise.all(promises);
};

const useImageTexturesSymbol = Symbol();

// Inspired from https://github.com/pmndrs/drei/blob/master/src/core/useTexture.tsx
// and https://github.com/pmndrs/react-three-fiber/blob/master/packages/fiber/src/core/hooks.tsx#L124
// load an image from an url into a ThreeJs texture with a retry mechanism in case of failure
export const useImageTextures = (
  urls: (string | undefined | null | File | Blob)[]
): (Texture | undefined)[] => {
  const result = suspend(loadImagesInParallel, [
    useImageTexturesSymbol,
    ...urls,
  ]);

  return result;
};

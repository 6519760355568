import { useEffect, useState } from 'react';
import { Scene, Camera } from 'three';

import {
  SnapshotConfigProps,
  getImperativeSnapshot3D,
} from '../utils/getImperativeSnapshot3D';

export const useSnapshot3D = (
  scene: Scene | null,
  camera: Camera | null,
  config: SnapshotConfigProps & {
    debounce?: number;
  },
  dependencies: unknown[]
): Blob | null => {
  const [snapshot, setSnapshot] = useState<Blob | null>(null);

  useEffect(() => {
    if (!scene || !camera) {
      return;
    }

    const debounce = setTimeout(() => {
      if (!scene || !camera) {
        setSnapshot(null);
      } else {
        getImperativeSnapshot3D(scene, camera, config).then((snapshot) => {
          setSnapshot(snapshot);
        });
      }
    }, config.debounce ?? 300);

    return () => clearTimeout(debounce);
  }, [
    ...dependencies,
    config.height,
    config.height,
    config.noHDRI,
    config.quality,
    config.type,
    config.postprocessing,
    config.debounce,
    config.environmentUrl,
    config.toneMapping,
    scene,
    camera,
  ]);

  return snapshot;
};

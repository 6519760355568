import { FC, useDeferredValue, useMemo, useState } from 'react';
import { filterExists } from '@vizcom/shared/js-utils';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import {
  addToast,
  downloadFile,
  DownloadIcon,
  Toolbar,
  ToolbarButton,
  TrashIcon,
  useKeyboardShortcut,
} from '@vizcom/shared-ui-components';

import { ClientSideWorkbenchElementPalette } from '../../../lib/clientState';
import { useWorkbenchElementSelectionState } from '../../../lib/elementSelectionState';
import { useImageTextures } from '../../../lib/useImageTexture';
import { useWorkbenchSyncedState } from '../../../lib/useWorkbenchSyncedState';
import { useIsWorkbenchViewer } from '../../../lib/utils';
import { CustomHtml } from '../../utils/CustomHtml';
import { CustomImage } from '../../utils/CustomImage';
import { FocusIndicator } from '../../utils/FocusIndicator';
import { calculateGridLayout } from '../../utils/layoutHelpers';

interface PaletteImagesProps {
  element: ClientSideWorkbenchElementPalette;
  width: number;
  height: number;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
}

export const PaletteImages: FC<PaletteImagesProps> = ({
  element,
  width,
  height,
  handleAction,
}) => {
  const images = useDeferredValue(element.sourceImages?.nodes ?? []);
  const textures = useImageTextures(images.map((image) => image.imagePath));

  const elements = images
    .map((image, index) => {
      const texture = textures[index];
      if (!texture) {
        return null;
      }

      return {
        id: image.id,
        width: texture.image.width,
        height: texture.image.height,
        aspectRatio: texture.image.width / texture.image.height,
      };
    })
    .filter(filterExists);

  const gridElementsData = useMemo(
    () => calculateGridLayout(elements, width, height),
    [elements, width, height]
  );

  return (
    <>
      {gridElementsData.map((data) => {
        const image = images.find((img) => img.id === data.id);
        if (!image) {
          return null;
        }

        return (
          <PaletteImage
            key={data.id}
            image={{
              id: data.id,
              imagePath: image.imagePath,
              x: data.x - width / 2,
              y: -data.y + height / 2,
              scale: [data.width, data.height],
            }}
            handleAction={handleAction}
            element={element}
          />
        );
      })}
    </>
  );
};

const PaletteImage = ({
  image,
  handleAction,
  element,
}: {
  image: {
    id: string;
    imagePath: ClientSideWorkbenchElementPalette['sourceImages']['nodes'][0]['imagePath'];
    x: number;
    y: number;
    scale: [number, number];
  };
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
  element: ClientSideWorkbenchElementPalette;
}) => {
  const isViewer = useIsWorkbenchViewer();
  const singleFocused = useWorkbenchElementSelectionState(
    (s) => s.focusedElementsId === image.id
  );
  const [isHovered, setIsHovered] = useState(false);

  const handleRemoveImageFromPalette = () => {
    handleAction({
      type: 'removeImagesFromPalette',
      id: element.id,
      imageIds: [image.id],
    });
  };

  const handleDownloadImage = async () => {
    try {
      downloadFile(image.imagePath, 'image', 'png');

      trackEvent('Image Export', {
        type: 'exportPaletteImage',
      });
    } catch {
      return addToast('An error occurred while downloading palette image', {
        type: 'danger',
      });
    }
  };

  useKeyboardShortcut(['delete', 'backspace'], async () => {
    if (isViewer || !singleFocused || element.status !== 'idle') return;

    handleRemoveImageFromPalette();
  });

  return (
    <group position={[image.x, image.y, 0]}>
      <CustomImage
        scale={image.scale}
        url={image.imagePath}
        onClick={(e) => {
          e.stopPropagation();
          useWorkbenchElementSelectionState
            .getState()
            .setFocusedElementsId(image.id);
        }}
        onPointerEnter={(e) => {
          if (element.status === 'idle') {
            e.stopPropagation();
          }
          setIsHovered(true);
        }}
        onPointerLeave={(e) => {
          if (element.status === 'idle') {
            e.stopPropagation();
          }
          setIsHovered(false);
        }}
      />
      {!isViewer && element.status === 'idle' && (
        <>
          <FocusIndicator
            active={isHovered || singleFocused}
            width={image.scale[0]}
            height={image.scale[1]}
          />
          {singleFocused && (
            <CustomHtml
              style={{ pointerEvents: 'none' }}
              position={[0, image.scale[1] / 2 - 10, 0]}
            >
              <Toolbar position="centered-above">
                <ToolbarButton
                  icon={<DownloadIcon />}
                  tooltip="Download"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDownloadImage();
                  }}
                />
                <ToolbarButton
                  icon={<TrashIcon />}
                  tooltip="Remove"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveImageFromPalette();
                  }}
                />
              </Toolbar>
            </CustomHtml>
          )}
        </>
      )}
    </group>
  );
};

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  Button,
  HelpIcon,
  OnboardingStepName,
  OnboardingTooltip,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  ToolbarButton,
  ToolbarButtonState,
  ToolbarDivider,
  useCompleteOnboardingStep,
  useCrisp,
  useResetOnboardingStatus,
  useShouldDisplayOnboardingTooltip,
  Text,
  Divider,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

import { HotkeysMenuButton } from './hotkeys/HotkeysMenuButton';
import { WorkbenchMenuButton } from './workbenchMenu/WorkbenchMenuButton';

type Props = {
  context: 'Drawing' | 'CompositeScene' | 'Workbench';
  fullscreen?: boolean;
};

export const WorkbenchHelpMenu = ({ context, fullscreen = false }: Props) => {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const crisp = useCrisp();

  const resetOnboardingStatus = useResetOnboardingStatus();
  const completeOnboardingStep = useCompleteOnboardingStep();
  const shouldDisplayOnboardingTooltip = useShouldDisplayOnboardingTooltip(
    OnboardingStepName.AnyQuestions,
    OnboardingStepName.EnterSketch
  );

  return (
    <>
      <ToolbarDivider />

      {shouldDisplayOnboardingTooltip && !fullscreen ? (
        <OnboardingTooltip
          name={OnboardingStepName.AnyQuestions}
          previousStep={OnboardingStepName.BackInfiniteCanvas}
          title="If you have any questions, refer here"
          durationVisible={10000}
        >
          <Button
            size="icon"
            variant="tertiary"
            onClick={async () => {
              completeOnboardingStep(OnboardingStepName.AnyQuestions);
              setIsMenuOpen(true);
            }}
          >
            <HelpIcon />
          </Button>
        </OnboardingTooltip>
      ) : (
        <RichTooltip
          trigger="click"
          isOpen={isMenuOpen}
          onOpenChange={setIsMenuOpen}
          displayArrow={false}
          padding={14}
        >
          <RichTooltipTrigger>
            <ToolbarButton
              icon={<HelpIcon />}
              tooltip="Help"
              state={
                isMenuOpen
                  ? ToolbarButtonState.ACTIVE
                  : ToolbarButtonState.INACTIVE
              }
            />
          </RichTooltipTrigger>

          <RichTooltipContent style={{ overflow: 'hidden', padding: 0 }}>
            <Container>
              {/* Learning & Documentation */}
              <WorkbenchMenuButton
                variant="bare"
                onClick={async () => {
                  await resetOnboardingStatus();
                  if (context === 'Workbench') {
                    await completeOnboardingStep(
                      OnboardingStepName.BackInfiniteCanvas
                    );
                  }
                  setIsMenuOpen(false);
                }}
              >
                <Text>Get started</Text>
              </WorkbenchMenuButton>
              <WorkbenchMenuButton
                variant="bare"
                as="a"
                target="_blank"
                href="https://docs.vizcom.ai/"
                onClick={() => setIsMenuOpen(false)}
              >
                <Text>Documentation</Text>
              </WorkbenchMenuButton>
              <WorkbenchMenuButton
                variant="bare"
                as="a"
                target="_blank"
                href="https://www.youtube.com/channel/UCU-XOa74z1aGdZRyvRBQ6UQ/videos"
                onClick={() => setIsMenuOpen(false)}
              >
                <Text>Tutorials</Text>
              </WorkbenchMenuButton>
              <HotkeysMenuButton defaultTab={context} />

              <Divider />

              {/* Community & Support */}
              <WorkbenchMenuButton
                variant="bare"
                as="a"
                target="_blank"
                href="https://discord.gg/aJZZaJpPSZ"
                onClick={() => setIsMenuOpen(false)}
              >
                <Text>Join Discord</Text>
              </WorkbenchMenuButton>
              <WorkbenchMenuButton
                variant="bare"
                onClick={() => {
                  crisp.openChat();
                  setIsMenuOpen(false);
                }}
              >
                <Text>Contact support</Text>
              </WorkbenchMenuButton>

              <WorkbenchMenuButton
                variant="bare"
                as="a"
                target="_blank"
                href="https://vizcom.canny.io/feature-requests"
                onClick={() => setIsMenuOpen(false)}
              >
                <Text>Feature requests</Text>
              </WorkbenchMenuButton>

              <Divider />

              {/* Account & Legal */}
              <WorkbenchMenuButton
                variant="bare"
                onClick={() => navigate(paths.settings.account.profile())}
              >
                <Text>Account settings</Text>
              </WorkbenchMenuButton>
              <WorkbenchMenuButton
                variant="bare"
                as="a"
                target="_blank"
                href="https://www.vizcom.ai/terms-of-service"
                onClick={() => setIsMenuOpen(false)}
              >
                <Text>Legal summary</Text>
              </WorkbenchMenuButton>
            </Container>
          </RichTooltipContent>
        </RichTooltip>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: 4px;
  padding: 4px;
  width: 170px;
`;

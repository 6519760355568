import { capitalize } from 'libs/shared/js-utils/src/lib/string';
import { useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import {
  ImageInferenceType,
  OrganizationSubscriptionPlan,
  WorkbenchElementImg2ImgData,
  publishTrackingEvent,
} from '@vizcom/shared/data-access/graphql';
import { Img2ImgPaywallEventName } from '@vizcom/shared/data-shape';
import {
  PublicPalette,
  promptLegacyTypeToModeAndPalette,
} from '@vizcom/shared/inference-worker-queues';
import { PLANS_LIMITS } from '@vizcom/shared/plans-limit';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import {
  Button,
  CarretDownIcon,
  CheckIcon,
  ContextMenu,
  Dropdown,
  LockIcon,
  MenuHorizontalIcon,
  RichTooltip,
  RichTooltipContent,
  RichTooltipTrigger,
  Text,
  ToolbarDivider,
  useFeatureFlag,
  useLocalStorage,
  usePaywallModalState,
  useSelectedOrganization,
} from '@vizcom/shared-ui-components';

import { useWorkbenchSyncedState } from '../../../lib/useWorkbenchSyncedState';
import {
  PaletteSelector,
  VERSION_SELECTIONS_KEY,
  VersionTag,
  getPaletteVersion,
  useAvailablePalettes,
} from '../../modals/PaletteSelector/PaletteSelector';
import { VersionedPalette } from '../../modals/PaletteSelector/PaletteVersionsMenu';
import { SharedContextMenuItems } from '../../workbenchContextMenu/contextMenuItemsPerType/Shared';
import { getNewPromptFromTags } from '../palette/helpers';
import { ExtraPopup } from '../style';

const BATCH_PRESET = [1, 0.75, 0.5, 0.25];

export const WorkbenchElementImg2ImgExtra = ({
  element,
  workbenchId,
  flipped,
  handleAction,
}: {
  element: WorkbenchElementImg2ImgData;
  workbenchId: string;
  flipped: boolean;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
}) => {
  const availablePalettes = useAvailablePalettes(workbenchId);
  const theme = useTheme();
  const [showPaletteSelector, setShowPaletteSelector] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);
  const [versionSelections] = useLocalStorage(
    VERSION_SELECTIONS_KEY,
    {} as Record<string, number>
  );

  const handleChangeImageInferenceType = (value: string) => {
    handleAction({
      type: 'updateAiImg2Img',
      elementId: element.id,
      imageInferenceType: value as ImageInferenceType,
    });
  };

  const handleSourceImageInfluences = (
    sourceImageInfluences: (number | null)[]
  ) => {
    handleAction({
      type: 'updateAiImg2Img',
      elementId: element.id,
      sourceImageInfluences: sourceImageInfluences,
    });
  };

  const handleUpdateSingleInfluence = (value: number | null, index: number) => {
    handleSourceImageInfluences(
      element.sourceImageInfluences.map((influence, i: number) =>
        i === index ? (value === null ? value : value / 100) : influence
      )
    );
  };

  const imageInferenceType = element.legacyType
    ? (promptLegacyTypeToModeAndPalette(
        element.legacyType
      ).mode.toUpperCase() as ImageInferenceType)
    : element.imageInferenceType;
  const publicPaletteId = element.legacyType
    ? promptLegacyTypeToModeAndPalette(element.legacyType).publicPaletteId
    : element.publicPaletteId;

  const handleChangePalette = (paletteId: string, paletteType: string) => {
    handleAction((elements) => {
      const currentElement = elements.find(
        (el) => el.id === element.id
      ) as WorkbenchElementImg2ImgData;

      if (!currentElement) return;

      const updates: Partial<WorkbenchElementImg2ImgData> = {
        [paletteType]: paletteId,
      };

      if (
        paletteType === 'workbenchPaletteId' ||
        paletteType === 'userPaletteId'
      ) {
        const selectedPalette = availablePalettes.find(
          (p) => p.value === paletteId
        );
        if (selectedPalette?.tags) {
          updates.prompt = getNewPromptFromTags(
            selectedPalette.tags,
            currentElement.prompt
          );
        }
      }

      return {
        type: 'updateAiImg2Img',
        elementId: element.id,
        ...updates,
      };
    });
  };

  const currentPaletteId =
    publicPaletteId ||
    element.workbenchPaletteId ||
    element.customModelId ||
    element.userPaletteId ||
    PublicPalette.generalV2;

  const selectedPalette =
    availablePalettes.find((palette) => palette.value === currentPaletteId) ??
    availablePalettes.find(
      (palette) =>
        palette.versions &&
        palette.versions.find((v) => v.value === currentPaletteId)
    ) ??
    availablePalettes[0];

  return (
    <Wrapper $flipped={flipped} onPointerDown={(e) => e.stopPropagation()}>
      <Heading>
        Properties
        <ContextMenu
          buttonProps={{
            variant: 'secondary',
            size: 'icon',
          }}
          items={
            <SharedContextMenuItems
              workbenchId={workbenchId}
              elements={[element]}
              handleAction={handleAction}
            />
          }
        >
          <MenuHorizontalIcon />
        </ContextMenu>
      </Heading>

      <Actions>
        <Action>
          <h3>Mode</h3>
          <Dropdown
            value={imageInferenceType}
            setValue={handleChangeImageInferenceType}
            options={Object.values(ImageInferenceType).map(
              (imageInferenceType) => ({
                data: imageInferenceType,
              })
            )}
            buttonProps={{
              variant: 'secondary',
              style: {
                padding: '10px 8px',
                alignSelf: 'stretch',
                background: theme.surface.tertiary,
              },
            }}
            OptionComponent={(props) => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flex: 1,
                }}
              >
                <Text>{capitalize(props.option)}</Text>
              </div>
            )}
            optionToValueMapper={(value) => value}
          >
            <div style={{ textAlign: 'left' }}>
              <Text type="b2" style={{ whiteSpace: 'nowrap' }}>
                {capitalize(imageInferenceType)}
              </Text>
            </div>
          </Dropdown>
        </Action>

        <Action>
          <h3>Palette</h3>

          <PaletteSelectionChip
            variant="secondary"
            onClick={() => setShowPaletteSelector((prev) => !prev)}
            ref={ref}
          >
            <ThumbnailWrapper>
              {selectedPalette.thumbnailPath && (
                <Thumbnail
                  src={selectedPalette.thumbnailPath}
                  alt={selectedPalette.name}
                />
              )}
            </ThumbnailWrapper>
            <PaletteName>{selectedPalette.name}</PaletteName>
            {selectedPalette.versions &&
              selectedPalette.versions.length > 0 && (
                <VersionTag
                  style={{
                    backgroundColor: '#38373a',
                  }}
                >
                  v
                  {getPaletteVersion(
                    selectedPalette as VersionedPalette,
                    currentPaletteId
                  ) ??
                    versionSelections[selectedPalette.id] ??
                    selectedPalette.versions.length + 1}
                </VersionTag>
              )}
            <CarretDownIcon
              style={{
                marginLeft: 'auto',
                minWidth: '16px',
                transform: showPaletteSelector ? 'rotate(180deg)' : 'none',
                cursor: 'pointer',
              }}
            />
          </PaletteSelectionChip>
          {showPaletteSelector && (
            <ExtraPopup $top={200} $right={ref.current!.clientWidth - 220}>
              <PaletteSelector
                workbenchId={workbenchId}
                size="large"
                availablePalettes={availablePalettes}
                selectedPaletteId={currentPaletteId}
                handleSelectPalette={(paletteId, type) => {
                  setShowPaletteSelector(false);
                  handleChangePalette(paletteId, type);
                }}
              />
            </ExtraPopup>
          )}
        </Action>

        <Action>
          <h3>Drawing Influence</h3>
          <InfluenceInputDropdown
            element={element}
            handleSourceImageInfluences={handleSourceImageInfluences}
            handleUpdateSingleInfluence={handleUpdateSingleInfluence}
          />
        </Action>
      </Actions>
    </Wrapper>
  );
};

const InfluenceInputDropdown = ({
  element,
  handleSourceImageInfluences,
  handleUpdateSingleInfluence,
}: {
  element: WorkbenchElementImg2ImgData;
  handleSourceImageInfluences: (
    sourceImageInfluences: (number | null)[]
  ) => void;
  handleUpdateSingleInfluence: (value: number | null, index: number) => void;
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const theme = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const { data: selectedOrganization } = useSelectedOrganization();
  const { flagEnabled: batchExportOverride } = useFeatureFlag('BATCH_EXPORT');

  const canBatchGenerate =
    batchExportOverride ||
    PLANS_LIMITS[
      selectedOrganization?.subscriptionPlan ??
        OrganizationSubscriptionPlan.Free
    ].batchGenerate;

  const handleLocked = () => {
    trackEvent('Paywall', {
      action: 'img2img batch prompt Count',
      location: 'img2img',
    });
    publishTrackingEvent({
      type: Img2ImgPaywallEventName.IMG2IMG_PAYWALL_DISPLAYED,
      data: {
        action: 'prompt-count',
        location: 'img2img-prompt',
      },
    });
    usePaywallModalState.getState().trigger();
  };

  const getInfluenceSelected = (val: number | number[] | 'custom') => {
    if (val === 'custom') {
      if (element.sourceImageInfluences.length === 4) {
        return BATCH_PRESET.some(
          (influence, i) => influence !== element.sourceImageInfluences[i]
        );
      } else {
        return BATCH_PRESET.every(
          (influence) => influence !== element.sourceImageInfluences[0]
        );
      }
    }

    if (element.sourceImageInfluences.length === 1 && typeof val === 'number') {
      return val === element.sourceImageInfluences[0];
    }

    if (element.sourceImageInfluences.length === 4 && Array.isArray(val)) {
      return BATCH_PRESET.every(
        (influence, i: number) => influence === element.sourceImageInfluences[i]
      );
    }

    return false;
  };

  return (
    <>
      <InfluenceInputButton
        ref={ref}
        style={{
          border: dropdownOpen
            ? `1px solid ${theme.deprecated.primary.default}`
            : '1px solid transparent',
        }}
      >
        {element.sourceImageInfluences.map((influence, i: number) => (
          <InfluenceInput
            key={i}
            $wide={element.sourceImageInfluences.length === 1}
          >
            <StyledInput
              value={
                typeof influence === 'number' ? Math.round(influence * 100) : ''
              }
              placeholder="-"
              type="number"
              $wide={element.sourceImageInfluences.length === 1}
              onChange={(e) => {
                const newValue =
                  e.target.value === ''
                    ? null
                    : Math.round(Math.min(Math.max(0, +e.target.value), 100));
                handleUpdateSingleInfluence(newValue, i);
              }}
            />
            <span>%</span>
          </InfluenceInput>
        ))}
        <ToolbarDivider />
        <ContextMenu
          referenceElementRef={ref}
          onOpenStateChange={(open) => {
            setDropdownOpen(open);
          }}
          buttonProps={{
            variant: 'secondary',
            size: 'icon',
            style: {
              background: theme.surface.tertiary,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 8,
              height: '36px',
              padding: '10px 8px',
            },
          }}
          items={
            <InfluenceDropdown>
              <span>Single Generation</span>
              <Option
                onClick={() => {
                  handleSourceImageInfluences([1]);
                  setDropdownOpen(false);
                }}
              >
                {getInfluenceSelected(1) && <CheckIcon />}
                <span>100%</span>
              </Option>
              <Option
                onClick={() => {
                  handleSourceImageInfluences([0.75]);
                  setDropdownOpen(false);
                }}
              >
                {getInfluenceSelected(0.75) && <CheckIcon />}
                <span>75%</span>
              </Option>
              <Option
                onClick={() => {
                  handleSourceImageInfluences([0.5]);
                  setDropdownOpen(false);
                }}
              >
                {getInfluenceSelected(0.5) && <CheckIcon />}
                <span>50%</span>
              </Option>
              <Option
                onClick={() => {
                  handleSourceImageInfluences([0.25]);
                  setDropdownOpen(false);
                }}
              >
                {getInfluenceSelected(0.25) && <CheckIcon />}
                <span>25%</span>
              </Option>
              <Option
                onClick={() => {
                  handleSourceImageInfluences([null]);
                  setDropdownOpen(false);
                }}
              >
                {element.sourceImageInfluences.length === 1 &&
                  getInfluenceSelected('custom') && <CheckIcon />}
                <span>Custom</span>
              </Option>
              <span>Batch Generation</span>
              <RichTooltip
                trigger="hover"
                placement="right"
                disabled={canBatchGenerate}
              >
                <RichTooltipTrigger>
                  <Option
                    $disabled={!canBatchGenerate}
                    onClick={() => {
                      if (!canBatchGenerate) {
                        handleLocked();
                        return;
                      }

                      handleSourceImageInfluences(BATCH_PRESET);
                      setDropdownOpen(false);
                    }}
                  >
                    {!canBatchGenerate && <LockIcon />}
                    {getInfluenceSelected(BATCH_PRESET) && <CheckIcon />}
                    <span>100%, 75%, 50%, 25%</span>
                  </Option>
                </RichTooltipTrigger>
                <RichTooltipContent style={{ zIndex: 9 }}>
                  <Text>
                    Upgrade plan to batch <br /> generate images
                  </Text>
                </RichTooltipContent>
              </RichTooltip>
              <RichTooltip
                trigger="hover"
                placement="right"
                disabled={canBatchGenerate}
              >
                <RichTooltipTrigger>
                  <Option
                    $disabled={!canBatchGenerate}
                    onClick={() => {
                      if (!canBatchGenerate) {
                        handleLocked();
                        return;
                      }

                      handleSourceImageInfluences([null, null, null, null]);
                      setDropdownOpen(false);
                    }}
                  >
                    {!canBatchGenerate && <LockIcon />}
                    {element.sourceImageInfluences.length === 4 &&
                      getInfluenceSelected('custom') && <CheckIcon />}
                    <span>Custom</span>
                  </Option>
                </RichTooltipTrigger>
                <RichTooltipContent style={{ zIndex: 9 }}>
                  <Text>
                    Upgrade plan to batch <br /> generate images
                  </Text>
                </RichTooltipContent>
              </RichTooltip>
            </InfluenceDropdown>
          }
        >
          <CarretDownIcon
            onClick={() => setDropdownOpen((prev) => !prev)}
            style={{
              marginLeft: 'auto',
              minWidth: '16px',
              transform: dropdownOpen ? 'rotate(180deg)' : 'none',
              cursor: 'pointer',
            }}
          />
        </ContextMenu>
      </InfluenceInputButton>
    </>
  );
};

const InfluenceInputButton = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr) 1px max-content;
  width: 100%;
  padding: 0px;
  align-items: center;
  justify-content: flex-start;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  background: ${({ theme }) => theme.surface.tertiary};
  cursor: pointer;
`;

const InfluenceInput = styled.div<{ $wide: boolean }>`
  display: flex;
  grid-area: ${({ $wide }) => ($wide ? '1 / 1 / 2 / 5' : 'auto')};
  overflow: hidden;
  padding: 10px 8px;

  > span {
    color: ${({ theme }) => theme.text.subtext};
  }
`;

const StyledInput = styled.input<{ $wide?: boolean }>`
  background: none;
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.text.body};
  border: none;
  width: ${({ $wide }) => ($wide ? '100%' : '25px')};
  text-align: ${({ $wide }) => ($wide ? 'left' : 'center')};

  appearance: none;
  -moz-appearance: textfield;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const InfluenceDropdown = styled.div`
  width: 214px;
  background: ${({ theme }) => theme.surface.secondary};
  padding: 8px;
  gap: 4px;
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.borderRadius.m};

  > span {
    display: block;
    padding: 8px;
    color: ${({ theme }) => theme.text.subtext};
  }
`;

const Option = styled.div<{ $disabled?: boolean }>`
  display: grid;
  grid-template-columns: 16px 1fr;
  align-items: center;
  gap: 8px;
  color: ${({ theme, $disabled }) =>
    $disabled ? theme.text.subtext : theme.text.body};
  padding: 8px;
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  background: ${({ theme, $disabled }) =>
    $disabled ? theme.surface.tertiary : 'none'};

  > span {
    grid-column-start: 2;
  }

  &:hover {
    background: ${({ theme }) => theme.surface.tertiary};
  }
`;

// ----

const Wrapper = styled.div<{ $flipped: boolean }>`
  margin: 0;
  padding: 0;
  background: ${({ theme }) => theme.surface.secondary};
  color: ${({ theme }) => theme.icon.primary};
  border-radius: ${({ theme }) => theme.borderRadius.l};
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  transform: ${({ $flipped }) =>
    $flipped
      ? ' translate3d(-50%, 20px, 0);'
      : ' translate3d(-50%, calc(-20px - 100%), 0);'};
  pointer-events: all;
  width: 246px;
`;

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

const Action = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  > h3 {
    font-size: 12px;
    color: ${({ theme }) => theme.text.subtext};
  }
`;

const PaletteSelectionChip = styled(Button)`
  display: flex;
  width: 100%;
  padding: 4px 8px;
  align-items: center;
  justify-content: flex-start;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  background: ${({ theme }) => theme.surface.tertiary};
  cursor: pointer;

  :not(:disabled) {
    :hover,
    :focus,
    :active {
      background-color: rgba(57, 57, 57, 0.5);
    }
  }
`;

const ThumbnailWrapper = styled.div`
  width: 24px;
  height: 24px;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.borderRadius.s};
  flex-shrink: 0;
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const PaletteName = styled.span`
  font-size: 12px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 40px);
  margin-left: 8px;
  margin-right: 8px;
`;

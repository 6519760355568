import { Link, Navigate, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  OrganizationSubscriptionPlan,
  TeamRole,
  useOrganizationPaletteUsage,
  UseOrganizationsReturnOrgData,
} from '@vizcom/shared/data-access/graphql';
import {
  Banner,
  BlockStack,
  Button,
  Divider,
  InfoIcon,
  InlineFlex,
  LoadingLogo,
  LockIcon,
  ProgressBar,
  SparklesIcon,
  Text,
  UpgradeWidget,
  usePaywallModalState,
  useSelectedOrganization,
  WarningIcon,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

import {
  SettingsPageContainer,
  SettingsPageDivider,
  SettingsPageHeader,
} from '../components/SettingsPageLayout';

export const PaletteSettingsPage = () => {
  const navigate = useNavigate();

  const { data: organization, loading } = useSelectedOrganization();
  const { data: paletteUsage } = useOrganizationPaletteUsage(organization?.id);
  const { trigger } = usePaywallModalState();

  if (loading || !paletteUsage) return <LoadingLogo />;
  if (!organization) return <Navigate to="/" />;

  const isUserAdmin = organization.currentUserRole === TeamRole.Admin;
  const isEnterprisePlan =
    organization.subscriptionPlan === OrganizationSubscriptionPlan.Enterprise;
  const isFreePlan =
    organization.subscriptionPlan === OrganizationSubscriptionPlan.Free;
  const uniqueTrainedPaletteCount = paletteUsage.uniqueTrainedPaletteCount ?? 0;
  const paletteStorageLeft =
    paletteUsage.maxPaletteStorage - uniqueTrainedPaletteCount;
  const storageUsagePercentage =
    (uniqueTrainedPaletteCount / paletteUsage.maxPaletteStorage) * 100;

  return (
    <SettingsPageContainer>
      <SettingsPageHeader>
        <Text type="h3">Palettes</Text>
        <Text type="b1" color="subtext">
          Manage your palette storage
        </Text>
      </SettingsPageHeader>

      <SettingsPageDivider />

      {isFreePlan ? (
        <UpgradeWidgetWrapper>
          <UpgradeWidget
            variant="column"
            title="Ideate in your style with Palettes"
            description="Upgrade to a higher plan to unlock palettes and additional features"
            cta={{
              text: 'Upgrade',
              action: () => trigger('freeToPro'),
            }}
            $width="290px"
          />
        </UpgradeWidgetWrapper>
      ) : (
        <BlockStack $gap={20}>
          {/* Organization has no palette storage */}
          {paletteUsage.maxPaletteStorage === 0 && isEnterprisePlan ? (
            <Banner
              message="No palette storage included for your workspace"
              secondaryMessage={
                'Contact your sales representative to enable palettes'
              }
              type="danger"
              icon={<InfoIcon />}
              cta={
                isUserAdmin
                  ? {
                      text: 'Contact sales',
                      url: 'https://www.vizcom.ai/contact',
                      target: '_blank',
                    }
                  : undefined
              }
            />
          ) : (
            /* The organization has exceeded the palette storage limit */
            uniqueTrainedPaletteCount >= paletteUsage.maxPaletteStorage && (
              <Banner
                message="Oops, not storage space left!"
                secondaryMessage={
                  isEnterprisePlan
                    ? 'Delete existing palettes or contact your sales representative for more storage'
                    : 'Delete existing palettes or upgrade to Enterprise to unlock more palette storage'
                }
                type="danger"
                icon={<InfoIcon />}
                cta={
                  isUserAdmin
                    ? {
                        text: 'Upgrade',
                        action: () =>
                          navigate(
                            paths.settings.organization.subscription(
                              organization.id
                            )
                          ),
                      }
                    : undefined
                }
              />
            )
          )}

          {/* The organization is above 80% palette storage usage */}
          {storageUsagePercentage >= 80 && storageUsagePercentage < 100 && (
            <Banner
              message={`Heads up! ${paletteStorageLeft} palette storage left.`}
              secondaryMessage={
                isEnterprisePlan
                  ? 'Delete existing palettes or contact your sales representative for more storage'
                  : 'Delete existing palettes or upgrade to Enterprise to unlock more palette storage'
              }
              type="warning"
              icon={<WarningIcon />}
              cta={
                isUserAdmin
                  ? {
                      text: 'Upgrade',
                      action: () =>
                        navigate(
                          paths.settings.organization.subscription(
                            organization.id
                          )
                        ),
                    }
                  : undefined
              }
            />
          )}

          <CardContainer>
            <Text block type="h4">
              Palettes credits
            </Text>

            <BlockStack $gap={16} style={{ marginTop: 20 }}>
              <InlineFlex $alignItems="baseline">
                <Text color="body" style={{ fontWeight: 600, fontSize: 24 }}>
                  {uniqueTrainedPaletteCount}
                </Text>
                <Text color="subtext" style={{ fontSize: 14 }}>
                  /{paletteUsage.maxPaletteStorage} palettes used
                </Text>
              </InlineFlex>

              <Divider />

              <BlockStack $gap={8}>
                <ProgressBar
                  value={uniqueTrainedPaletteCount}
                  max={paletteUsage.maxPaletteStorage}
                  reverse
                />
              </BlockStack>
            </BlockStack>
          </CardContainer>

          <PurchasePaletteCredit
            organization={organization}
            isUserAdmin={isUserAdmin}
          />
        </BlockStack>
      )}
    </SettingsPageContainer>
  );
};

const PurchasePaletteCredit = (props: {
  organization: Pick<UseOrganizationsReturnOrgData, 'id' | 'subscriptionPlan'>;
  isUserAdmin: boolean;
}) => {
  if (
    props.organization.subscriptionPlan ===
    OrganizationSubscriptionPlan.Enterprise
  ) {
    return (
      <CardContainer>
        <InlineFlex $justifyContent="space-between">
          <InlineFlex $alignItems="center" style={{ flex: 1 }}>
            <LockIcon color="#808085" />
            <Text type="h4">Unlock more palette storage</Text>
          </InlineFlex>
          {props.isUserAdmin && (
            <Button
              size="S"
              variant="secondary"
              as={Link}
              target="_blank"
              to={'https://www.vizcom.ai/contact'}
            >
              Contact sales
            </Button>
          )}
        </InlineFlex>
        <BlockStack $gap={16} style={{ marginTop: 10 }}>
          <Text block color="subtext" style={{ marginTop: 8 }}>
            Contact your sales representative to unlock more palette storage
            space.
          </Text>
        </BlockStack>
      </CardContainer>
    );
  }

  return (
    <CardContainer>
      <InlineFlex $justifyContent="space-between">
        <InlineFlex $alignItems="center">
          <LockIcon color="#808085" />
          <Text type="h4">Unlock more palette storage</Text>
        </InlineFlex>
        {props.isUserAdmin && (
          <Button
            size="S"
            variant="secondary"
            as={Link}
            to={paths.settings.organization.subscription(props.organization.id)}
          >
            Upgrade
          </Button>
        )}
      </InlineFlex>

      <BlockStack $gap={10} style={{ marginTop: 10 }}>
        <Text block color="subtext">
          Upgrade your workspace to Enterprise to unlock more palette storage
          space.
        </Text>
        {props.organization.subscriptionPlan ===
          OrganizationSubscriptionPlan.Pro && (
          <>
            <Divider />
            <InlineFlex>
              <SparklesIcon $color="primary" />
              <Text>
                The ability to purchase palette credits for a Professional
                workspace is coming soon!
              </Text>
            </InlineFlex>
          </>
        )}
      </BlockStack>
    </CardContainer>
  );
};

const UpgradeWidgetWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 200px;
`;

const CardContainer = styled.div`
  width: 100%;
  padding: 18px;
  background-color: ${({ theme }) => theme.surface.secondary};
  border-radius: ${({ theme }) => theme.borderRadius.m};
`;

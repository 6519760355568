import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
  useUpdateFolder,
  useUpdateWorkbench,
} from '@vizcom/shared/data-access/graphql';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import {
  Button,
  Modal,
  ModalActions,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalTitle,
  addToast,
  formatErrorMessage,
  moveModalTarget,
  useSelectedOrganization,
} from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

import { TargetFolderSelector } from './TargetFolderSelector';

export const MoveModal = () => {
  const [modalState, setModalState] = useRecoilState(moveModalTarget);

  const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);
  const [workbenchRes, updateWorkbench] = useUpdateWorkbench();
  const [folderRes, updateFolder] = useUpdateFolder();
  const navigate = useNavigate();
  const loading = workbenchRes.fetching || folderRes.fetching;
  const selectedOrganization = useSelectedOrganization();

  const handleCloseModal = () => {
    setModalState((s) => ({
      ...s,
      open: false,
      preventRedirectAfterMove: null,
    }));
  };
  const handleConfirm = async () => {
    if (!selectedFolderId) {
      return;
    }
    if (modalState.type === 'workbench') {
      await updateWorkbench({
        id: modalState.id!,
        patch: {
          folderId: selectedFolderId,
        },
      });

      trackEvent('Drawing Moved');
    } else if (modalState.type === 'folder') {
      const res = await updateFolder({
        input: {
          id: modalState.id!,
          patch: {
            parentId: selectedFolderId,
          },
        },
      });

      if (res.error) {
        addToast('Error while moving file', {
          type: 'danger',
          secondaryText: formatErrorMessage(res.error),
        });
        return;
      }

      trackEvent('Folder Moved');
    }

    if (!modalState.preventRedirectAfterMove) {
      navigate(paths.files.folder(selectedFolderId));
    }
    handleCloseModal();
  };

  if (!selectedOrganization.data) {
    return null;
  }

  return (
    <Modal
      style={{ minWidth: 430 }}
      isOpen={modalState.open}
      setIsOpen={handleCloseModal}
    >
      <ModalHeader>
        <ModalTitle>
          Move {modalState?.type === 'workbench' ? 'File' : 'Folder'}
        </ModalTitle>
        <ModalCloseButton />
      </ModalHeader>

      <ModalContent style={{ marginTop: 0 }}>
        <TargetFolderSelector
          organizationId={selectedOrganization.data?.id}
          selectedFolderId={selectedFolderId}
          setSelectedFolderId={setSelectedFolderId}
        />
      </ModalContent>

      <ModalActions>
        <Button onClick={handleCloseModal} variant="secondary">
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleConfirm}
          disabled={!selectedFolderId || loading}
        >
          Move
        </Button>
      </ModalActions>
    </Modal>
  );
};

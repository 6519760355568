import { formatDistanceToNow } from 'date-fns';
import {
  urqlClient,
  CreateLayer3dFromDrawingMutation,
  ImageTo3dQualityType,
} from '@vizcom/shared/data-access/graphql';
import { RateLimitQuotaDetails } from '@vizcom/shared/data-shape';
import { trackEvent } from '@vizcom/shared-data-access-analytics';
import { addToast, imageDataToBlob } from '@vizcom/shared-ui-components';

import {
  filterExists,
  genTopOrderKey,
  LayerMetadata3d,
} from '../../../../../../../shared/js-utils/src';
import {
  SyncedActionPayloadFromType,
  SyncedActionType,
} from '../../SyncedAction';
import { Drawing2dStudio } from '../../useDrawingSyncedState';
import { UpdateBulkLayersAction } from './updateBulkLayers';

export const CreateLayer3dFromDrawing: SyncedActionType<
  Drawing2dStudio,
  {
    type: 'createLayer3dFromDrawing';
    id: string;
    name: string;
    qualityType: ImageTo3dQualityType;
    sourceImages: (
      | (() => ImageData)
      | string
      | ImageData
      | Blob
      | undefined
      | null
    )[];
    quadTopology?: boolean;
  },
  {
    orderKey: string;
    workbenchId: string;
  }
> = {
  type: 'createLayer3dFromDrawing',
  optimisticUpdater: ({ payload }, drawing) => {
    const existingLayer = drawing.layers.nodes.find((l) => l.id === payload.id);
    if (existingLayer) {
      existingLayer.name = payload.name;
      existingLayer.metadata3D = {
        generatedFrom2dTo3d: true,
      } as LayerMetadata3d;
    } else {
      drawing.layers.nodes.push({
        id: payload.id,
        name: payload.name,
        blendMode: 'normal',
        fill: '',
        opacity: 1,
        visible: true,
        metadata3D: {
          generatedFrom2dTo3d: true,
        },
        updatedAt: '0',
        createdAt: '0',
        isGroup: false,
        meshPath: null,
        orderKey: genTopOrderKey(drawing.layers.nodes),
        drawingId: drawing.id,
      });
    }
  },
  remoteUpdater: async ({ payload, meta }, drawingId) => {
    const images = await Promise.all(
      payload.sourceImages.map(async (image) => {
        if (!image) {
          return undefined;
        }

        if (image instanceof Blob) {
          return image;
        } else if (image instanceof ImageData) {
          return await imageDataToBlob(image);
        } else if (typeof image === 'string') {
          return image;
        } else {
          return imageDataToBlob(image());
        }
      })
    );

    const res = await urqlClient.mutation(CreateLayer3dFromDrawingMutation, {
      input: {
        drawingId,
        workbenchId: meta.custom!.workbenchId,
        layer: {
          blendMode: 'normal',
          id: payload.id,
          drawingId,
          fill: '',
          name: payload.name,
          opacity: 1,
          visible: true,
          orderKey: meta.custom!.orderKey,
        },
        qualityType: payload.qualityType,
        sourceImages: images.filter(filterExists),
        quadTopology: payload.quadTopology,
      },
    });
    if (res?.error) {
      if (
        (res.error.graphQLErrors?.[0]?.extensions?.exception as any)?.rateLimit
      ) {
        const rateLimitInfo = (
          res.error.graphQLErrors?.[0]?.extensions?.exception as any
        )?.rateLimit as RateLimitQuotaDetails;
        if (
          payload.qualityType === ImageTo3dQualityType.High_0 ||
          payload.qualityType === ImageTo3dQualityType.High_1
        ) {
          addToast(
            `You’ve reached your Detailed 3D Generation Limit. Don't worry, your limit will refresh ${formatDistanceToNow(
              new Date(Date.now() + rateLimitInfo.resetInMs),
              {
                addSuffix: true,
              }
            )}. You can still generate models using Standard 3D until then.`,
            {
              type: 'danger',
            }
          );
        } else {
          addToast(
            `You have been generating too many 3D models, please wait ${(
              rateLimitInfo.resetInMs / 1000
            ).toFixed(0)}s before trying again.`,
            {
              type: 'danger',
            }
          );
        }
      } else if (
        (res.error.graphQLErrors?.[0]?.extensions?.exception as any)?.code ===
        '2d-to-3d-resolution-not-supported'
      ) {
        addToast(
          `Image resolution is not supported, please use an image not too tall or wide`,
          { type: 'danger' }
        );
      } else {
        throw new Error(
          `Error using AI service, please retry. ${
            res.error.graphQLErrors[0]?.message ?? res.error.message
          }`
        );
      }
      return;
    }
    trackEvent('Generate Mesh', {
      type: 'createLayer3dFromDrawing',
    });
  },
  metaConstructor: (_, state) => {
    return {
      custom: {
        orderKey: genTopOrderKey(state.layers.nodes),
        workbenchId: state.workbenchId,
      },
    };
  },
  undoConstructor: ({ payload }) => {
    const undoPayload: SyncedActionPayloadFromType<
      typeof UpdateBulkLayersAction
    > = {
      type: 'updateBulkLayers',
      deletedLayerIds: [payload.id],
    };

    return undoPayload;
  },
};

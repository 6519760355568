import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { ImageTo3dQualityType } from '@vizcom/shared/data-access/graphql';
import { filterExists } from '@vizcom/shared/js-utils';
import { PLANS_LIMITS } from '@vizcom/shared/plans-limit';
import {
  Button,
  LockIcon,
  addToast,
  usePaywallModalState,
  Tooltip,
  Checkbox,
  Divider,
  Text,
  BlockStack,
} from '@vizcom/shared-ui-components';

import {
  Drawing2dStudio,
  useDrawingSyncedState,
} from '../../../lib/useDrawingSyncedState';
import { getSelectedVisibleLayers } from '../utils';

type Generate3dLayerMenuProps = {
  drawing: Drawing2dStudio;
  activeLayerId?: string;
  quadTopology: boolean;
  setQuadTopology: (value: boolean) => void;
  handleAction: ReturnType<typeof useDrawingSyncedState>['handleAction'];
  setActiveLayerId: (id: string | undefined) => void;
  onButtonClicked: () => void;
  getCompositedImage: () => ImageData;
};

export const Generate3dLayerMenu = ({
  drawing,
  activeLayerId,
  quadTopology,
  setQuadTopology,
  handleAction,
  setActiveLayerId,
  onButtonClicked,
  getCompositedImage,
}: Generate3dLayerMenuProps) => {
  const subscriptionPlan =
    drawing.workbench?.folder?.organization?.subscriptionPlan;
  const canGenerateHighQuality3d =
    subscriptionPlan &&
    PLANS_LIMITS[subscriptionPlan].highQuality3dGenerationLimitPerMonth > 0;

  // Valid selected layers for multiview 3d are visible layers with image path or selected group child layers of the same criteria
  const validMultiviewLayers = getSelectedVisibleLayers(
    drawing,
    activeLayerId?.split('/') || []
  );
  const handleGenerate3d = (quality: ImageTo3dQualityType) => {
    const layerWithImageCount = drawing.layers.nodes.filter(
      (l) => !!l.imagePath
    ).length;

    if (layerWithImageCount === 0) {
      return addToast(
        'Please add content to the canvas before generating a 3D model',
        {
          type: 'danger',
        }
      );
    }

    const name =
      quality === ImageTo3dQualityType.High_0
        ? `${quadTopology ? 'Quad sharp' : 'Sharp'} generated 3D model`
        : quality === ImageTo3dQualityType.High_1
        ? `${quadTopology ? 'Quad smooth' : 'Smooth'} generated 3D model`
        : 'Generated 3D model';

    const layerId = uuid();
    handleAction({
      type: 'createLayer3dFromDrawing',
      name,
      id: layerId,
      qualityType: quality,
      sourceImages:
        quality === ImageTo3dQualityType.MultiView_1
          ? validMultiviewLayers.map((l) => l.imagePath).filter(filterExists)
          : [getCompositedImage()],
      quadTopology,
    });
    setActiveLayerId(layerId);
    onButtonClicked();
  };

  const getMultiViewTooltip = () => {
    if (validMultiviewLayers.length < 2 || validMultiviewLayers.length > 5) {
      return `Please select between 2-5 visible layers with content`;
    }
    return '';
  };

  const isMultiViewDisabled =
    validMultiviewLayers.length < 2 || validMultiviewLayers.length > 5;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
      }}
    >
      <ActionButton
        variant="tertiary"
        onClick={() => handleGenerate3d(ImageTo3dQualityType.Basic)}
        size="M"
        disabled={quadTopology}
      >
        Generate 3D · Standard
      </ActionButton>
      {canGenerateHighQuality3d ? (
        <>
          <Divider />
          <ActionButton
            variant="tertiary"
            onClick={() => handleGenerate3d(ImageTo3dQualityType.High_0)}
            size="M"
          >
            Generate 3D · Detailed Sharp
          </ActionButton>
          <ActionButton
            variant="tertiary"
            onClick={() => handleGenerate3d(ImageTo3dQualityType.High_1)}
            size="M"
          >
            Generate 3D · Detailed Smooth
          </ActionButton>
        </>
      ) : (
        <PaywalledButton
          variant="tertiary"
          onClick={() => usePaywallModalState.getState().trigger()}
          size="M"
        >
          Generate 3D · High Quality
          <LockIcon />
        </PaywalledButton>
      )}
      {canGenerateHighQuality3d ? (
        isMultiViewDisabled ? (
          <Tooltip tip={getMultiViewTooltip()}>
            <ActionButton
              disabled={true}
              variant="tertiary"
              onClick={() => handleGenerate3d(ImageTo3dQualityType.MultiView_1)}
              size="M"
            >
              Generate 3D · Multi View
            </ActionButton>
          </Tooltip>
        ) : (
          <ActionButton
            disabled={false}
            variant="tertiary"
            onClick={() => handleGenerate3d(ImageTo3dQualityType.MultiView_1)}
            size="M"
          >
            Generate 3D · Multi View
          </ActionButton>
        )
      ) : (
        <PaywalledButton
          variant="tertiary"
          onClick={() => usePaywallModalState.getState().trigger()}
          size="M"
        >
          Generate 3D · Multi View
          <LockIcon />
        </PaywalledButton>
      )}

      {canGenerateHighQuality3d && (
        <>
          <Divider />

          <ActionButton
            variant="tertiary"
            onClick={() => setQuadTopology(!quadTopology)}
            size="M"
            style={{ height: 'auto' }}
          >
            <Checkbox
              checked={quadTopology}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                setQuadTopology(!quadTopology);
              }}
            />
            <BlockStack $gap={0} $alignItems="flex-start">
              <Text>Generate with quad topology</Text>
              <Text color="subtext">Loading times might be longer</Text>
            </BlockStack>
          </ActionButton>
        </>
      )}
    </div>
  );
};

const PaywalledButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  color: ${(p) => p.theme.text.subtext};
`;

const ActionButton = styled(Button)`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
`;

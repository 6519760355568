import { useEffect, useRef, useState, KeyboardEvent } from 'react';
import styled from 'styled-components';
import { Text, TextInput } from '@vizcom/shared-ui-components';

import { useIsWorkbenchViewer } from '../../lib/utils';

export const WorkbenchNameInput = ({
  handleUpdate,
  value,
  isEditing,
  setIsEditing,
}: {
  handleUpdate: (name: string) => void;
  value?: string;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
}) => {
  const [name, setName] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const isViewer = useIsWorkbenchViewer();

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (!e.repeat && e.key.toLowerCase() === 'enter') {
      setIsEditing(false);
      handleUpdate(name);
    }
  };

  useEffect(() => {
    if (isEditing) {
      setName(value ?? '');
      inputRef.current!.select();
    }
  }, [isEditing]);

  if (isEditing) {
    return (
      <StyledTextInput
        ref={inputRef}
        onBlur={() => {
          setIsEditing(false);
          handleUpdate(name);
        }}
        onChange={(e) => setName(e.target.value)}
        onKeyDown={handleKeyDown}
        onSubmit={(e) => e.stopPropagation()}
        onPointerDown={(e) => e.stopPropagation()}
        value={name}
      />
    );
  }

  return (
    <TruncatedName
      type="b1"
      ellipsis
      onClick={() => {
        if (!isViewer) {
          setIsEditing(true);
        }
      }}
    >
      {value ?? 'Untitled'}
    </TruncatedName>
  );
};

const TruncatedName = styled(Text)`
  grid-area: name;
  width: 100%;
  margin-left: -4px;
  padding: 10px 9px;

  :hover {
    background-color: ${({ theme }) => theme.button.secondaryHover};
    border-radius: ${({ theme }) => theme.borderRadius.m};

    cursor: text;
  }
`;

const StyledTextInput = styled(TextInput)`
  margin-left: -4px;
  padding-inline: 8px;
`;

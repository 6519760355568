import { useThree } from '@react-three/fiber';
import { WorkbenchContentFragment } from 'libs/shared/data-access/graphql/src/gql/graphql';
import { useState, useMemo, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { OrthographicCamera } from 'three';
import { WorkbenchElementDrawingData } from '@vizcom/shared/data-access/graphql';
import {
  imageUrlToImageData,
  useKeyboardShortcut,
  useStableCallback,
} from '@vizcom/shared-ui-components';

import { WorkbenchContentRenderingOrder } from '../WorkbenchContent';
import { ClientSideWorkbenchElementData } from '../lib/clientState';
import { useWorkbenchElementSelectionState } from '../lib/elementSelectionState';
import { MultiplayerPresence } from '../lib/useWorkbenchMultiplayer';
import { useWorkbenchSyncedState } from '../lib/useWorkbenchSyncedState';
import {
  elementIsMix,
  elementIsSection,
  useIsWorkbenchViewer,
} from '../lib/utils';
import { ResizerControls } from './ResizerControls';
import { WorkbenchCameraGestures } from './WorkbenchCameraGestures';
import { WorkbenchElement } from './WorkbenchElement';
import { WorkbenchElementsExtra } from './WorkbenchElementsExtra';
import { WorkbenchHelpMenu } from './WorkbenchHelpMenu';
import { WorkbenchViewportControls } from './WorkbenchViewportControls';
import { NewlyCreatedElementsToastIndicator } from './elements/NewlyCreatedElementsToastIndicator';
import {
  MixSourceDrawing,
  SourceDrawingData,
} from './elements/mix/WorkbenchElementMix';
import { PublishPaletteOnboardingTooltip } from './elements/palette/PublishPaletteOnboardingTooltip';
import { WORKBENCH_ELEMENT_SECTION_PLACEHOLDER } from './elements/section/WorkbenchElementSection';
import { getSectionElements } from './elements/section/helpers';
import { WORKBENCH_ELEMENT_TEXT_PLACEHOLDER } from './elements/text/WorkbenchElementText';
import { getElementsBoundingBox, getElementSize } from './helpers';
import { triggerDeleteElementsModal } from './modals/triggerDeleteElementsModal';
import { useWorkbenchToolState } from './toolbar/WorkbenchToolContext';
import { CustomHtml } from './utils/CustomHtml';
import { FloatingMouseText } from './utils/FloatingMouseText';
import { SnapGuide, SnapGuideLines } from './utils/Snapping';
import { ViewportFlipGroup } from './utils/ViewportFlipGroup';
import {
  getCameraLimitsFromWorkbenchElements,
  useMapControls,
} from './utils/mapControls/utils';
import { VizcomRenderingOrderEntry } from './utils/threeRenderingOrder';
import { useLastWorkbenchCameraPosition } from './utils/useLastWorkbenchCameraPosition';

export const WorkbenchCanvasContent = (props: {
  elements: ClientSideWorkbenchElementData[];
  workbench: WorkbenchContentFragment;
  editingElementId: string | null;
  multiplayerPresences: MultiplayerPresence[];
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
  setEditingElementId: (id: string | null) => void;
}) => {
  const [isResizing, setIsResizing] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isDropTarget, setIsDropTarget] = useState<string | null>(null);
  const [snapGuides, setSnapGuides] = useState<SnapGuide[]>([]);
  const controls = useMapControls();
  const camera = useThree((s) => s.camera as OrthographicCamera);
  const { focusedElementsId, setFocusedElementsId } =
    useWorkbenchElementSelectionState();
  const scene = useThree((s) => s.scene);
  const isViewer = useIsWorkbenchViewer();
  const { tool } = useWorkbenchToolState();
  const [searchParams] = useSearchParams();

  const { elements, handleAction } = props;

  useKeyboardShortcut(['delete', 'backspace'], async () => {
    if (isViewer) return;

    if (focusedElementsId) {
      const elementIds = focusedElementsId.split('/').filter((id) => id);
      const deletedElements = elements.filter((e) => elementIds.includes(e.id));
      const sectionElements = deletedElements.flatMap((deletedElement) =>
        getSectionElements(scene, deletedElement)
      );

      const elementsToDeleteIds = [
        ...deletedElements.map((e) => e.id),
        ...sectionElements.map((e) => e.userData.elementId),
      ].filter((id, index, self) => self.indexOf(id) === index);

      if (!elementsToDeleteIds.length) return;

      try {
        await triggerDeleteElementsModal(elementsToDeleteIds);
      } catch {
        return;
      }

      handleAction({
        type: 'deleteElements',
        elementIds: elementsToDeleteIds,
      });
    }
  });

  const onFitToScreen = () => {
    const { xMin, xMax, yMin, yMax } = cameraLimits;
    const x = (xMin + xMax) / 2;
    const y = (yMin + yMax) / 2;
    const zoomForWidth = (camera.right - camera.left) / (xMax - xMin);
    const zoomForHeight = (camera.top - camera.bottom) / (yMax - yMin);
    const zoom = Math.min(zoomForWidth, zoomForHeight);
    controls.moveTo({ x, y, zoom });
  };

  const getDrawingImageData = useStableCallback(async (drawingId: string) => {
    const drawing = elements.find((element) => element.id === drawingId) as
      | WorkbenchElementDrawingData
      | undefined;

    if (!drawing || !drawing.thumbnailPath) {
      return null;
    }

    const imagedata = await imageUrlToImageData(drawing.thumbnailPath);

    return imagedata;
  });

  const sourceDrawingsByMixId = useMemo(() => {
    return elements.reduce<Record<string, Record<string, SourceDrawingData>>>(
      (acc, element) => {
        if (elementIsMix(element)) {
          if (!acc[element.id]) {
            acc[element.id] = {};
          }

          (element.sourceDrawings as MixSourceDrawing[]).forEach((source) => {
            const drawing = elements.find(
              (el) => el.id === source.sourceDrawingId
            ) as WorkbenchElementDrawingData | undefined;
            if (!drawing) {
              return;
            }
            const { height, width } = getElementSize(drawing);
            acc[element.id][source.sourceDrawingId] = {
              thumbnail: drawing?.thumbnailPath,
              height,
              width,
            };
          });
        }
        return acc;
      },
      {} as Record<string, Record<string, SourceDrawingData>>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(
      elements
        .filter(elementIsMix)
        .map((element) =>
          (element.sourceDrawings as MixSourceDrawing[]).map(
            (source) => source.sourceDrawingId
          )
        )
    ),
  ]);

  const focusedElementsIds = focusedElementsId.split('/').filter((id) => id);
  const multiFocused = focusedElementsIds.length > 1;
  const focusedElements = elements.filter((element) =>
    focusedElementsIds.some((id) => id === element.id)
  );

  const { focusedGroupX, focusedGroupY, focusedGroupHeight } =
    getFocusedGroupPosition(focusedElements);

  const cameraLimits = getCameraLimitsFromWorkbenchElements(elements);
  useLastWorkbenchCameraPosition(props.workbench.id, cameraLimits);

  const getParentRenderingOrder = (element: ClientSideWorkbenchElementData) => {
    const sections = elements.filter(elementIsSection);

    const parents = sections
      .filter((section) => {
        if (section.id === element.id) {
          return false;
        }
        const size = getElementSize(element);
        const elementLeft = element.x - size.width / 2;
        const elementRight = element.x + size.width / 2;
        const elementTop = element.y - size.height / 2;
        const elementBottom = element.y + size.height / 2;

        const sectionLeft = section.x - section.width / 2;
        const sectionRight = section.x + section.width / 2;
        const sectionTop = section.y - section.height / 2;
        const sectionBottom = section.y + section.height / 2;

        return (
          elementLeft >= sectionLeft &&
          elementRight <= sectionRight &&
          elementTop >= sectionTop &&
          elementBottom <= sectionBottom
        );
      })
      .sort((a, b) => {
        const aSize = getElementSize(a);
        const bSize = getElementSize(b);
        return bSize.width * bSize.height - aSize.width * aSize.height;
      });

    return [
      ...parents.map((s) => ({
        zIndex: s.zIndex,
        conflictId: s.id,
      })),
    ];
  };

  // Responsible for handling the selection of focused elements.
  useEffect(() => {
    if (!controls) {
      return;
    }
    const focusedElementIds = searchParams.get('selected')?.split(',') ?? [];

    if (!focusedElementIds.length) {
      return;
    }

    setFocusedElementsId(focusedElementIds.join('/'));

    const boundingBox = getElementsBoundingBox(
      elements.filter((el) => focusedElementIds.includes(el.id))
    );

    const zoomForWidth = (camera.right - camera.left) / boundingBox.width;
    const zoomForHeight = (camera.top - camera.bottom) / boundingBox.height;
    const zoom = Math.min(zoomForWidth, zoomForHeight) * 0.8;

    controls.moveTo({
      x: boundingBox.x,
      y: boundingBox.y,
      zoom,
    });
  }, [controls]);

  return (
    <>
      <WorkbenchViewportControls
        cameraLimits={cameraLimits}
        appendControls={<WorkbenchHelpMenu context="Workbench" />}
        onFitToScreen={onFitToScreen}
      />
      <WorkbenchCameraGestures cameraLimits={cameraLimits} />
      <NewlyCreatedElementsToastIndicator elements={elements} />
      <group
        userData={{
          vizcomRenderingOrder: [
            {
              zIndex: WorkbenchContentRenderingOrder.indexOf('elements'),
            } satisfies VizcomRenderingOrderEntry,
          ],
        }}
      >
        {elements.map((element) => (
          <WorkbenchElement
            focused={focusedElementsId.includes(element.id)}
            singleFocused={focusedElementsId === element.id}
            isEditing={props.editingElementId === element.id}
            setEditingElementId={props.setEditingElementId}
            element={element}
            key={element.id}
            handleAction={handleAction}
            workbenchId={props.workbench.id}
            thumbnailDrawingId={props.workbench.thumbnailDrawingId}
            multiplayerPresences={props.multiplayerPresences}
            elementIsActive={
              !!props.multiplayerPresences.find(
                (presence) => presence.editingElementId === element.id
              )
            }
            isResizing={isResizing}
            isDragging={isDragging && focusedElementsId === element.id}
            isDropTarget={isDropTarget === element.id}
            setIsDropTarget={setIsDropTarget}
            setIsDragging={setIsDragging}
            setSnapGuides={setSnapGuides}
            sourceDrawingsThumbnails={sourceDrawingsByMixId[element.id]}
            getDrawingImageData={getDrawingImageData}
            getParentRenderingOrder={() => getParentRenderingOrder(element)}
          />
        ))}
      </group>
      <PublishPaletteOnboardingTooltip elements={elements} />
      <SnapGuideLines snapGuides={snapGuides} />
      {tool === 'text' && (
        <FloatingMouseText>
          {WORKBENCH_ELEMENT_TEXT_PLACEHOLDER}
        </FloatingMouseText>
      )}
      {tool === 'section' && (
        <FloatingMouseText>
          {WORKBENCH_ELEMENT_SECTION_PLACEHOLDER}
        </FloatingMouseText>
      )}
      <ResizerControls
        workbench={props.workbench}
        active={focusedElementsId.split('/').length > 0}
        elements={focusedElements}
        handleAction={handleAction}
        setIsResizing={setIsResizing}
        setSnapGuides={setSnapGuides}
      />
      {multiFocused && !isResizing && (
        <group
          position={[focusedGroupX, focusedGroupY, 0]}
          userData={{
            workbenchObjectType: 'multi-focused-element-container',
            elementX: focusedGroupX,
            elementY: focusedGroupY,
          }}
        >
          <ViewportFlipGroup
            pivot={focusedGroupY}
            position={[0, focusedGroupHeight / 2, 0]}
            userData={{
              workbenchObjectType: 'multi-focused-element-container',
              elementX: focusedGroupX,
              elementY: focusedGroupY,
            }}
          >
            {({ flipped }) => (
              <CustomHtml style={{ pointerEvents: 'none' }}>
                <WorkbenchElementsExtra
                  workbench={props.workbench}
                  handleAction={handleAction}
                  elements={focusedElements}
                  isDragging={isDragging}
                  flipped={flipped}
                />
              </CustomHtml>
            )}
          </ViewportFlipGroup>
        </group>
      )}
    </>
  );
};

function getFocusedGroupPosition(elements: ClientSideWorkbenchElementData[]) {
  let minX = Number.MAX_SAFE_INTEGER;
  let maxX = Number.MIN_SAFE_INTEGER;
  let minY = Number.MAX_SAFE_INTEGER;
  let maxY = Number.MIN_SAFE_INTEGER;
  let maxHeight = 0;

  // Finds the position and height of the highest element
  for (const element of elements) {
    minX = Math.min(minX, element.x);
    maxX = Math.max(maxX, element.x);
    const { height } = getElementSize(element);
    if (element.y + height > maxY + maxHeight) {
      maxY = element.y;
      maxHeight = height;
    }
    if (element.y - height / 2 < minY) {
      minY = element.y - height / 2;
    }
  }

  const midX = (minX + maxX) / 2;
  const midY = (minY + maxY + maxHeight / 2) / 2;
  const height = maxY + maxHeight / 2 - minY;

  return {
    focusedGroupX: midX,
    focusedGroupY: midY,
    focusedGroupHeight: height,
  };
}

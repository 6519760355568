import { useState } from 'react';
import styled from 'styled-components';
import {
  AssetColorData,
  LibraryType,
  useOrganization,
  useWorkbench,
} from '@vizcom/shared/data-access/graphql';
import {
  BlockStack,
  Divider,
  InlineFlex,
  SearchIcon,
  Text,
  TextInputIcon,
} from '@vizcom/shared-ui-components';
import { useDebouncedValue } from '@vizcom/shared-utils-hooks';

import { Accordion } from './Accordion';
import { ColorsResultPage } from './ColorsResultPage';
import { SelectLibraryType } from './SelectLibraryType';
import { ViewMode, ViewModeSwitcher } from './ViewModeSwitcher';
import { AssetColorMenu, PanelViewMode } from './colors/AddAssetColorMenu';

export type Cursors = Array<string | null>;

export const AssetLibrary = ({ workbenchId }: { workbenchId: string }) => {
  const [panelViewMode, setPanelViewMode] = useState<PanelViewMode>(
    PanelViewMode.View
  );
  const [selectedColor, setSelectedColor] = useState<AssetColorData | null>(
    null
  );
  const [isColorPanelOpen, setIsColorPanelOpen] = useState(false);
  const [colorsCursors, setColorsCursors] = useState<Cursors>([null]);
  const [view, setView] = useState<ViewMode>(ViewMode.Icon);
  const [libraryType, setLibraryType] = useState<LibraryType>(
    LibraryType.Workbench
  );

  const { data: workbench } = useWorkbench(workbenchId);
  const { data: organization } = useOrganization(
    workbench?.folder?.organization?.id
  );

  const [debouncedSearchText, searchText, setSearchText] = useDebouncedValue(
    '',
    500
  );

  if (!workbench || !organization) return null;

  const onColorSelected = (color: AssetColorData, viewMode: PanelViewMode) => {
    setSelectedColor(color);
    setPanelViewMode(viewMode);
    setIsColorPanelOpen(true);
  };

  return (
    <AssetLibraryContainer>
      <BlockStack $gap={10} style={{ padding: 10 }}>
        <TextInputIcon
          style={{
            minWidth: 200,
          }}
          icon={<SearchIcon />}
          $background="secondary"
          placeholder="Find assets..."
          type="text"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />

        <InlineFlex>
          <SelectLibraryType
            organization={organization}
            selectedLibraryType={libraryType}
            setSelectedLibraryType={setLibraryType}
            setColorsCursors={setColorsCursors}
          />
          <ViewModeSwitcher selectedView={view} setSelectedView={setView} />
        </InlineFlex>
      </BlockStack>

      <Divider />

      <ScrollableContainer>
        <Accordion style={{ marginTop: 10 }}>
          <InlineFlex>
            <Accordion.Header>
              <Text block>Colors</Text>
            </Accordion.Header>

            {/* Including the '+' Button */}
            <AssetColorMenu
              workbenchId={workbenchId}
              libraryType={libraryType}
              isOpen={isColorPanelOpen}
              setIsColorPanelOpen={setIsColorPanelOpen}
              panelViewMode={panelViewMode}
              selectedColor={selectedColor}
              setPanelViewMode={setPanelViewMode}
            />
          </InlineFlex>
          <Accordion.Body>
            <ColorsResultPageContainer $view={view}>
              {colorsCursors.map((cursor, index) => (
                <ColorsResultPage
                  key={`${index}-${libraryType}`} // Re-create the component when the library type changes to prevent infinite scroll completion.
                  view={view}
                  workbenchId={workbenchId}
                  cursor={cursor}
                  libraryType={libraryType}
                  setColorsCursors={setColorsCursors}
                  cursorIndex={index}
                  searchText={debouncedSearchText}
                  onColorSelected={onColorSelected}
                />
              ))}
            </ColorsResultPageContainer>
          </Accordion.Body>
        </Accordion>
      </ScrollableContainer>
    </AssetLibraryContainer>
  );
};

/*
 * Styles
 */
const ScrollableContainer = styled.div`
  padding: 0 10px;
  position: relative;
  overflow-y: scroll;
  ${({ theme }) => theme.scrollbar.dark}
`;

const AssetLibraryContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto auto 1fr;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.borderRadius.l};
`;

const ColorsResultPageContainer = styled.div<{ $view: ViewMode }>`
  display: grid;
  grid-template-columns: ${({ $view }) =>
    $view === ViewMode.Icon ? 'repeat(4, 1fr)' : '1fr'};
  row-gap: ${({ $view }) => ($view === ViewMode.Icon ? 10 : 5)}px;
  column-gap: 10px;
  padding: 5px 0;
`;

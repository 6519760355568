import { ThreeEvent } from '@react-three/fiber';
import { debounce } from 'lodash-es';
import { PointerEvent, Suspense, useCallback } from 'react';
import { useTheme } from 'styled-components';
import { WorkbenchElementTextData } from '@vizcom/shared/data-access/graphql';
import {
  CMD_KEY_NAME,
  ToolbarDivider,
  useDocumentEventListener,
  useKeyboardShortcut,
} from '@vizcom/shared-ui-components';

import { useContentEditableWithStableSelection } from '../../../lib/stableSelectionTextInputHooks';
import { useWorkbenchSyncedState } from '../../../lib/useWorkbenchSyncedState';
import { useIsWorkbenchViewer } from '../../../lib/utils';
import { WorkbenchElementExtra } from '../../WorkbenchElementExtra';
import { getElementSize } from '../../helpers';
import { CustomHtml } from '../../utils/CustomHtml';
import { CustomText } from '../../utils/CustomText';
import { getContentSize, getTextContentSize } from '../../utils/getContentSize';
import { WorkbenchElementColor } from '../WorkbenchElementColor';
import { TextInput } from '../style';
import { WorkbenchElementTextAlignment } from './extra/WorkbenchElementTextAlignment';
import { WorkbenchElementTextDecoration } from './extra/WorkbenchElementTextDecoration';
import { WorkbenchElementTextFont } from './extra/WorkbenchElementTextFont';
import {
  WorkbenchElementTextLink,
  WorkbenchElementTextLinkDetails,
} from './extra/WorkbenchElementTextLink';
import { WorkbenchElementTextSize } from './extra/WorkbenchElementTextSize';
// eslint-disable-next-line import/order
import '../../utils/fonts/fonts.css';

interface WorkbenchElementTextProps {
  workbenchId: string;
  element: WorkbenchElementTextData;
  singleFocused: boolean;
  isEditing: boolean;
  isDragging: boolean;
  isResizing: boolean;
  handleAction: ReturnType<typeof useWorkbenchSyncedState>['handleAction'];
  setEditingElementId: (id: string | null) => void;
}

export const WORKBENCH_ELEMENT_TEXT_PLACEHOLDER = 'Add text';

export const WorkbenchElementText = ({
  workbenchId,
  element,
  singleFocused,
  isEditing,
  isDragging,
  isResizing,
  handleAction,
  setEditingElementId,
}: WorkbenchElementTextProps) => {
  const theme = useTheme();
  const isViewer = useIsWorkbenchViewer();
  const { width, height } = getElementSize(element);
  const {
    contentHeight,
    id,
    content,
    lockWidth,
    y,
    style = {},
    link = '',
  } = element;
  const textScale = height / contentHeight;

  useKeyboardShortcut(
    'escape',
    (e) => {
      if (isEditing) {
        e.stopPropagation();
        setEditingElementId(null);
      }
    },
    {
      capture: true,
    }
  );

  const { bind, ref: textInputRef } = useContentEditableWithStableSelection(
    content,
    (content) => {
      const { newContentHeight, newContentWidth } = getContentSize(
        textInputRef.current
      );

      handleAction({
        type: 'updateText',
        elementId: id,
        content,
        contentHeight: newContentHeight,
        height: newContentHeight * textScale,
        width: lockWidth ? width : newContentWidth * textScale + 10, // +10 to prevent text from being cut off
        final: false,
      });
    },
    {
      selectAllContentOnMount: true,
    }
  );

  const handleChangeColor = debounce((color: string) => {
    handleAction(
      {
        type: 'updateText',
        elementId: id,
        final: true,
        style: {
          ...style,
          color,
        },
      },
      {
        skipHistory: true,
      }
    );
  }, 200);

  const updateFont = (font: string) => {
    if (!element.content || !element.content.length) {
      handleAction(
        {
          type: 'updateText',
          elementId: id,
          final: true,
          style: {
            ...style,
            fontFamily: font,
          },
        },
        {
          skipHistory: true,
        }
      );
      return;
    }

    const { newContentHeight, newContentWidth } = getTextContentSize(
      {
        ...element,
        style: {
          ...style,
          fontFamily: font,
        },
      },
      textScale
    );

    handleAction(
      {
        type: 'updateText',
        elementId: id,
        contentHeight: newContentHeight,
        height: newContentHeight * textScale,
        width: lockWidth ? width : newContentWidth * textScale,
        y: y + (height - newContentHeight * textScale) / 2,
        final: true,
        style: {
          ...style,
          fontFamily: font,
        },
      },
      {
        skipHistory: true,
      }
    );

    setEditingElementId(null);
  };

  const handleSetFontFamily = (font: string) => {
    if (font === style?.fontFamily) {
      return;
    }
    updateFont(font);
  };

  const handleSetTextSize = (size: number) => {
    if (size === textScale) {
      return;
    }

    handleAction({
      type: 'updateText',
      elementId: id,
      final: true,
      width: width * (size / textScale),
      height: height * (size / textScale),
    });
  };

  const handleSetTextAlign = (align: 'left' | 'center' | 'right') => {
    handleAction(
      {
        type: 'updateText',
        elementId: id,
        final: true,
        style: {
          ...style,
          textAlign: align,
        },
      },
      {
        skipHistory: true,
      }
    );
  };

  const setIsBold = (bold: boolean) => {
    handleAction(
      {
        type: 'updateText',
        elementId: id,
        final: true,
        style: {
          ...style,
          fontWeight: bold ? 600 : 400,
        },
      },
      {
        skipHistory: true,
      }
    );
  };

  const handleSetTextDecoration = useCallback(
    (decoration: 'underline' | 'line-through' | 'none') => {
      handleAction(
        {
          type: 'updateText',
          elementId: id,
          final: true,
          style: {
            ...style,
            textDecoration: decoration,
          },
        },
        {
          skipHistory: true,
        }
      );
    },
    [handleAction, id, style]
  );

  const handleSetTextLink = useCallback(
    (link: string) => {
      if (link === element.link) {
        return;
      }
      handleAction({
        type: 'updateText',
        elementId: id,
        final: true,
        link,
      });
    },
    [element.link, handleAction, id]
  );

  const handlePointerDown = (e: PointerEvent<HTMLDivElement>) => {
    if (isEditing) {
      e.stopPropagation();
    }
  };

  useDocumentEventListener(
    'keydown',
    (e) => {
      if (
        isEditing &&
        ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)
      ) {
        e.stopPropagation();
      }
    },
    {
      capture: true,
    }
  );

  useDocumentEventListener(
    'keydown',
    (e) => {
      if (isEditing && e.key === 'b' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        e.stopPropagation();
        setIsBold(!style?.fontWeight || style.fontWeight === 400);
      }
    },
    {
      capture: true,
    }
  );

  useDocumentEventListener(
    'keydown',
    (e) => {
      if (isEditing && e.key === 'u' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        e.stopPropagation();
        handleSetTextDecoration(
          style?.textDecoration === 'underline' ? 'none' : 'underline'
        );
      }
    },
    {
      capture: true,
    }
  );

  const textAlign = (style?.textAlign ?? 'center') as
    | 'left'
    | 'center'
    | 'right';

  const handleLinkClick = (e: ThreeEvent<MouseEvent>) => {
    if (!link?.length) {
      return;
    }
    // we can add more link validation here
    if (e[CMD_KEY_NAME]) {
      window.open(link, '_blank');
    }
  };

  return (
    <group onClick={handleLinkClick}>
      {isEditing && (
        <CustomHtml transform scale={[textScale, textScale, 1]}>
          <div
            style={{
              width: lockWidth ? width / textScale : 'fit-content',
              height: height / textScale,
              userSelect: 'none',
            }}
          >
            <TextInput
              // ID id required for selection by the resizer to get the size of the content
              // while mid-resize
              id={`text-${id}`}
              contentEditable
              onPointerDown={handlePointerDown}
              tabIndex={-1}
              style={{
                height: contentHeight,
                minWidth: lockWidth ? width / textScale : '30px',
                ...style,
              }}
              placeholder={WORKBENCH_ELEMENT_TEXT_PLACEHOLDER}
              onBlur={() => {
                if (isEditing) {
                  setEditingElementId(null);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Escape') {
                  setEditingElementId(null);
                }
              }}
              {...bind()}
            />
          </div>
        </CustomHtml>
      )}
      <group
        userData={{ workbenchObjectType: 'container', fixedAspectRatio: true }}
      >
        <Suspense fallback={null}>
          <CustomText
            content={
              content.length ? content : WORKBENCH_ELEMENT_TEXT_PLACEHOLDER
            }
            scale={[textScale, textScale, 1]}
            position={[0, height / 2, 0]}
            onDoubleClick={(e) => {
              e.stopPropagation();
              if (!singleFocused) {
                return;
              }
              setEditingElementId(element.id);
            }}
            visible={!isEditing}
            color={
              content.length === 0 ? theme.text.subtext : style?.color ?? '#000'
            }
            fontSize={style?.fontSize ?? 24}
            anchorX="center"
            anchorY="top"
            letterSpacing={-0.02}
            overflowWrap="break-word"
            whiteSpace="overflowWrap"
            maxWidth={lockWidth ? width / textScale : undefined}
            textAlign={textAlign}
            font={style?.fontFamily ?? 'Inter'}
            fontWeight={style?.fontWeight === 600 ? 'bold' : 'medium'}
            textDecoration={link?.length ? 'underline' : style?.textDecoration}
            lineHeight={1.3}
          />
        </Suspense>
      </group>
      {!isDragging && !isResizing && singleFocused && !isViewer && (
        <WorkbenchElementExtra
          workbenchId={workbenchId}
          element={element}
          position={[0, height / 2, 0]}
          pivot={element.y}
          handleAction={handleAction}
        >
          <WorkbenchElementColor
            color={style?.color}
            handleChangeColor={handleChangeColor}
            $right={435}
          />

          <ToolbarDivider />

          <WorkbenchElementTextFont
            font={style?.fontFamily}
            handleSetFontFamily={handleSetFontFamily}
            setEditing={() => setEditingElementId(id)}
          />

          <ToolbarDivider />

          <WorkbenchElementTextSize
            size={textScale}
            handleSetTextSize={handleSetTextSize}
          />

          <ToolbarDivider />

          <WorkbenchElementTextDecoration
            fontWeight={style?.fontWeight}
            setIsBold={setIsBold}
            handleSetTextDecoration={handleSetTextDecoration}
            textDecoration={style?.textDecoration}
          />

          <ToolbarDivider />

          <WorkbenchElementTextLink
            link={link ?? ''}
            setLink={handleSetTextLink}
            handleSetTextDecoration={handleSetTextDecoration}
            textDecoration={style?.textDecoration}
          />

          <ToolbarDivider />

          <WorkbenchElementTextAlignment
            textAlign={textAlign}
            handleSetTextAlign={handleSetTextAlign}
          />
        </WorkbenchElementExtra>
      )}
      {singleFocused && link && (
        <CustomHtml position={[0, -height, 0]}>
          <WorkbenchElementTextLinkDetails link={link} />
        </CustomHtml>
      )}
    </group>
  );
};

import {
  OrganizationSubscriptionPlan,
  useDrawing,
} from '@vizcom/shared/data-access/graphql';

export const useIsFree = (drawingId?: string) => {
  const drawingRes = useDrawing(drawingId);

  const plan =
    drawingRes.data?.workbench?.folder?.organization?.subscriptionPlan;

  return Boolean(plan) && plan === OrganizationSubscriptionPlan.Free;
};

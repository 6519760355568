import { omit } from 'lodash-es';
import {
  urqlClient,
  UpdateDrawingLayersMutation,
} from '@vizcom/shared/data-access/graphql';
import { imageDataToBlob } from '@vizcom/shared-ui-components';

import {
  SyncedActionPayloadFromType,
  SyncedActionType,
} from '../../SyncedAction';
import { Drawing2dStudio } from '../../useDrawingSyncedState';
import { MergeLayersAction } from './mergeLayers';

export const UnmergeLayersAction: SyncedActionType<
  Drawing2dStudio,
  {
    type: 'unmergeLayers';
    targetLayerId: string;
    targetWasGroup: boolean;
    sourceLayers: Drawing2dStudio['layers']['nodes'];
    originalImage?: string | ImageData | Blob | null;
    mergedImage: ImageData;
  }
> = {
  type: 'unmergeLayers',
  optimisticUpdater: ({ payload }, drawing) => {
    const targetLayer = drawing.layers.nodes.find(
      (layer) => layer.id === payload.targetLayerId
    );

    if (!targetLayer || !payload.originalImage) {
      return;
    }
    targetLayer.imagePath = payload.originalImage;
    targetLayer.isGroup = payload.targetWasGroup;

    payload.sourceLayers.forEach((layer) => {
      const existingLayer = drawing.layers.nodes.find((l) => l.id === layer.id);
      if (existingLayer) {
        existingLayer.name = layer.name;
        existingLayer.visible = layer.visible;
        existingLayer.opacity = layer.opacity;
        existingLayer.blendMode = layer.blendMode;
        existingLayer.fill = layer.fill;
        existingLayer.metadata3D = layer.metadata3D;
        existingLayer.orderKey = layer.orderKey;
      } else {
        drawing.layers.nodes.push({
          ...layer,
          drawingId: drawing.id,
          orderKey: layer.orderKey,
        });
      }
    });
  },
  remoteUpdater: async ({ payload }, drawingId) => {
    const newLayers = await Promise.all(
      payload.sourceLayers.map(async (layer) => ({
        ...omit(layer, 'image', 'anchor', 'updatedAt', 'createdAt'),
        imagePath:
          layer.imagePath instanceof ImageData
            ? await imageDataToBlob(layer.imagePath)
            : layer.imagePath,
      }))
    );

    const res = await urqlClient.mutation(UpdateDrawingLayersMutation, {
      input: {
        id: drawingId,
        layerUpdates: [
          {
            id: payload.targetLayerId,
            imagePath:
              payload.originalImage instanceof ImageData
                ? await imageDataToBlob(payload.originalImage)
                : payload.originalImage,
            isGroup: payload.targetWasGroup,
          },
        ],
        newLayers,
      },
    });

    if (res?.error) {
      throw new Error(
        `Error while unmerging layers, please retry. ${
          res.error.graphQLErrors[0]?.message ?? res.error.message
        }`
      );
    }
  },
  undoConstructor: ({ payload }) => {
    const undoPayload: SyncedActionPayloadFromType<typeof MergeLayersAction> = {
      type: 'mergeLayers',
      targetLayerId: payload.targetLayerId,
      sourceLayersId: payload.sourceLayers.map((l) => l.id),
      mergedImage: payload.mergedImage,
    };

    return undoPayload;
  },
};

import {
  useState,
  createContext,
  useContext,
  ReactNode,
  useMemo,
  ForwardedRef,
  forwardRef,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { CMD_KEY_NAME } from '@vizcom/shared-ui-components';
import { paths } from '@vizcom/shared-utils-paths';

import { DeleteMultipleModal } from '../Files/File/DeleteMultipleModal';
import { MoveMultipleModal } from '../Files/File/MoveMultipleModal';

export type SelectableItemType = 'workbench' | 'folder';
export type SelectedItem = {
  id: string;
  type: SelectableItemType;
  index: number;
};

type SelectedItemsContextValue = {
  selectedItems: SelectedItem[];
  setSelectedItems: Dispatch<SetStateAction<SelectedItem[]>>;
  handleCtrlClick: (
    id: string,
    type: SelectableItemType,
    index: number
  ) => void;
  handleShiftClick: (index: number) => void;
  handleClick: (id: string, type: SelectableItemType, index: number) => void;
  setMultipleDeleteModalOpen: Dispatch<SetStateAction<boolean>>;
  setMultipleMoveModalOpen: Dispatch<SetStateAction<boolean>>;
};

const SelectedItemsContext = createContext<
  SelectedItemsContextValue | undefined
>(undefined);

export const SelectedItemsProvider = ({
  children,
  getItems,
}: {
  children: ReactNode;
  getItems: () => SelectedItem[];
}) => {
  const [selectedItems, setSelectedItems] = useState<SelectedItem[]>([]);
  const [lastSelectedIndex, setLastSelectedIndex] = useState(0);
  const [deleteMultipleModalOpen, setMultipleDeleteModalOpen] = useState(false);
  const [multipleMoveModalOpen, setMultipleMoveModalOpen] = useState(false);

  const handleCtrlClick = useCallback(
    (id: string, type: SelectableItemType, index: number) => {
      if (selectedItems.find((item) => item.id === id)) {
        setSelectedItems((prevState) =>
          prevState.filter((item) => item.id !== id)
        );
        const closestIndex = selectedItems.reduce(
          (acc, curr) => {
            const closest = Math.abs(index - curr.index);
            if (closest < acc.value) {
              acc = { index: curr.index, value: closest };
            }
            return acc;
          },
          { index: 0, value: 0 }
        );
        setLastSelectedIndex(closestIndex.index);
        return;
      }
      setLastSelectedIndex(index);
      setSelectedItems([...selectedItems, { id, type, index }]);
    },
    [selectedItems, setSelectedItems, setLastSelectedIndex]
  );

  const handleShiftClick = useCallback(
    (index: number) => {
      window.getSelection()!.removeAllRanges();
      const items = getItems();
      const start = Math.min(lastSelectedIndex, index);
      const end = Math.max(lastSelectedIndex, index);
      setSelectedItems((prevItems) => {
        const newItems = [...prevItems];
        for (let i = start; i <= end; i++) {
          if (!newItems.find((item) => item.index === items[i].index)) {
            newItems.push(items[i]);
          }
        }
        return newItems;
      });
      setLastSelectedIndex(index);
    },
    [getItems, lastSelectedIndex, setSelectedItems, setLastSelectedIndex]
  );

  const handleClick = useCallback(
    (id: string, type: SelectableItemType, index: number) => {
      if (selectedItems.find((item) => item.id === id)) {
        setSelectedItems([]);
        return;
      }
      setLastSelectedIndex(index);
      setSelectedItems([{ id, type, index }]);
    },
    [selectedItems, setSelectedItems, setLastSelectedIndex]
  );

  const value = useMemo(
    () => ({
      selectedItems,
      setSelectedItems,
      handleCtrlClick,
      handleShiftClick,
      handleClick,
      setMultipleDeleteModalOpen,
      setMultipleMoveModalOpen,
    }),
    [
      selectedItems,
      handleCtrlClick,
      handleShiftClick,
      handleClick,
      setMultipleDeleteModalOpen,
      setMultipleMoveModalOpen,
    ]
  );

  return (
    <SelectedItemsContext.Provider value={value}>
      {children}
      <DeleteMultipleModal
        isOpen={deleteMultipleModalOpen}
        setIsOpen={setMultipleDeleteModalOpen}
        selectedItems={selectedItems}
        onComplete={() => setSelectedItems([])}
      />
      <MoveMultipleModal
        isOpen={multipleMoveModalOpen}
        setIsOpen={setMultipleMoveModalOpen}
        selectedItems={selectedItems}
        onComplete={() => setSelectedItems([])}
      />
    </SelectedItemsContext.Provider>
  );
};

export const useSelectedItems = (): SelectedItemsContextValue => {
  const context = useContext(SelectedItemsContext);
  if (!context) {
    throw new Error(
      'useSelectedItems must be used within a SelectedItemsProvider'
    );
  }
  return context;
};

interface SelectableItemProps {
  children: ReactNode;
  id: string;
  type: SelectableItemType;
  index: number;
}

export const SelectableItem = forwardRef(
  (props: SelectableItemProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { id, children, type, index } = props;
    const navigate = useNavigate();
    const { handleCtrlClick, handleShiftClick, handleClick } =
      useSelectedItems();

    const handleDoubleClick = (id: string) => {
      const url =
        type === 'workbench'
          ? paths.workbench.file(id)
          : paths.files.folder(id);

      navigate(url);
    };

    const handleCtrlDoubleClick = (id: string) => {
      const url =
        type === 'workbench'
          ? paths.workbench.file(id)
          : paths.files.folder(id);
      window.open(url, '_blank');
    };

    return (
      <div
        ref={ref}
        onDoubleClick={(e) => {
          e.preventDefault();
          if (e[CMD_KEY_NAME]) {
            handleCtrlDoubleClick(id);
            return;
          }
          handleDoubleClick(id);
        }}
        onClick={(e) => {
          e.preventDefault();
          if (e.shiftKey) {
            handleShiftClick(index);
            return;
          } else if (e[CMD_KEY_NAME]) {
            handleCtrlClick(id, type, index);
            return;
          }
          handleClick(id, type, index);
        }}
        onContextMenu={(e) => {
          e.preventDefault();
        }}
      >
        {children}
      </div>
    );
  }
);

SelectableItem.displayName = 'SelectableItem';
